import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PharmacyApiService } from 'sydmed/src/app/secure/pharmacy/services/pharmacy-api.service';

export interface RequestObject {
  open24HoursADay: boolean;
  open7DaysAWeek: boolean;
  providesFluShots: boolean;
  languageSelected: string;
  pharmacyName: string;
  distance: number;
  city: string;
  state: string;
  zipCode: string;
}
@Injectable()
export class SearchService {
  constructor(protected api: PharmacyApiService) { }
  getSearchResults(requestObject: RequestObject) {
    return this.api.searchPharmacy(requestObject).pipe(
      map((res: any) => res),
      catchError(error => throwError(error))
    );
  }
}
