<div class="uxd-theme-motif container max-width-elements margin-bottom-48 caregiver-info-container">
    <h1 tabindex="-1" class="margin-bottom-48 font-32">{{jsonLabels?.Page_Heading}}</h1>
    <form #careGiverForm="ngForm" [formGroup]="careGiverInfoForm" (ngSubmit)="careGiverInfoSubmission()">
        <div class="form-item margin-bottom-40">
            <label class="text-left font-16 font-semi-bold" id="lbl_txtFirstName"
                for="firstName">{{jsonLabels?.Field_Labels.First_Name}}</label>
            <input type="text" id="firstName" name="firstName"
                class="ant-input-long ant-text-input" formControlName="firstName" autocomplete="off" aria-required="true"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted || careGiverInfoForm.controls['firstName']?.touched) && careGiverInfoForm.controls['firstName']?.invalid }" aria-describedby="firstNameError">
            <ul id="firstNameError" class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted || careGiverInfoForm.controls['firstName']?.touched) && careGiverInfoForm.controls['firstName']?.invalid" aria-live="polite">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['firstName']?.errors.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.First_Name}}</span>
                    <span>{{jsonErrors?.Required_err}}</span>
                </li>
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['firstName']?.errors.invalidnamechar">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.First_Name}}</span>
                    <span>{{jsonErrors?.Lastname_Invalid_err}}</span>
                </li>
            </ul>
        </div>
        <div class="form-item margin-bottom-40">
            <label class="text-left font-16 font-semi-bold" id="lbl_txtLastName"
                for="lastName">{{jsonLabels?.Field_Labels.Last_Name}}</label>
            <input type="text" id="lastName" name="lastName"
                class="ant-input-long ant-text-input" formControlName="lastName" autocomplete="off" aria-required="true"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted || careGiverInfoForm.controls['lastName']?.touched) && careGiverInfoForm.controls['lastName']?.invalid }" aria-describedby="lastNameError">
            <ul id="lastNameError" class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted || careGiverInfoForm.controls['lastName']?.touched) && careGiverInfoForm.controls['lastName']?.invalid" aria-live="polite">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['lastName']?.errors.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.Last_Name}}</span>
                    <span>{{jsonErrors?.Required_err}}</span>
                </li>
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['lastName']?.errors.invalidnamechar">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.Last_Name}}</span>
                    <span>{{jsonErrors?.Lastname_Invalid_err}}</span>
                </li>
            </ul>
        </div>
        <div class="form-item margin-bottom-40">
            <label class="font-16 font-semi-bold" id="lbl_txtDob"
                for="dateOfBirth">{{jsonLabels?.Field_Labels.Date_of_Birth}}</label>
            <input type="text" name="dateOfBirth" autocomplete="off" id="dateOfBirth"
                maxlength="10" class="ant-input-long ant-text-input" formControlName="dateOfBirth" libDateFormat
                [preValue]="careGiverInfoForm.value.dateOfBirth" maxlength="10" aria-required="true"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted || careGiverInfoForm.controls['dateOfBirth']?.touched) && careGiverInfoForm.controls['dateOfBirth']?.invalid }" aria-describedby="dobError">
            <ul id="dobError" class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted || careGiverInfoForm.controls['dateOfBirth']?.touched) && careGiverInfoForm.controls['dateOfBirth']?.invalid" aria-live="polite">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['dateOfBirth']?.errors.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.Date_of_Birth}}</span>
                    <span>{{jsonErrors?.Required_err}}</span>
                </li>
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['dateOfBirth']?.errors.invalidDate">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonErrors?.DOB_Format_err}}</span>
                </li>
                <li class="font-12 text-error"
                    *ngIf="careGiverInfoForm.controls['dateOfBirth']?.errors.futureDate && !careGiverInfoForm.controls['dateOfBirth']?.errors.invalidDate">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonErrors?.DOB_Future_err}}</span>
                </li>
            </ul>
        </div>
        <div class="form-item margin-bottom-40">
            <label class="font-16 font-semi-bold" id="lbl_Phone"
                for="phoneNumber">{{jsonLabels?.Field_Labels.Phone}}</label>
            <input id="phoneNumber" type="text" formControlName="phone"
                class="ant-input-long ant-text-input" libPhoneFormat [preValue]="careGiverInfoForm.value.phone"
                placeholder="XXX-XXX-XXXX" aria-required="true"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted ||  careGiverInfoForm.controls['phone']?.touched) && careGiverInfoForm.controls['phone']?.invalid }" aria-describedby="phoneNumberError">
            <ul id="phoneNumberError" class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted ||  careGiverInfoForm.controls['phone']?.touched) && careGiverInfoForm.controls['phone']?.invalid" aria-live="polite">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['phone']?.errors?.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.Phone}}</span>
                    <span>{{jsonErrors.Required_err}}</span>
                </li>
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['phone']?.errors?.invalidPhone">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonErrors?.Phone_Format}}</span>
                </li>
            </ul>
        </div>
        <div class="form-item margin-bottom-40">
            <label class="font-16 font-semi-bold" id="lbl_PrimaryAddress"
                for="primaryAddress">{{jsonLabels?.Field_Labels.Primary_Address}}</label>
            <input id="primaryAddress" type="text" formControlName="primaryAddress"
                class="ant-input-long ant-text-input" aria-required="true"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted || careGiverInfoForm.controls['primaryAddress']?.touched) && careGiverInfoForm.controls['primaryAddress']?.invalid }" aria-describedby="addressError">
            <ul id="addressError" class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted || careGiverInfoForm.controls['primaryAddress']?.touched) && careGiverInfoForm.controls['primaryAddress']?.invalid" aria-live="polite">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['primaryAddress']?.errors?.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.Primary_Address}}</span>
                    <span>{{jsonErrors?.Required_err}}</span>
                </li>
            </ul>
        </div>
        <div class="form-item margin-bottom-40">
            <label class="font-16 font-semi-bold" id="lbl_SecondaryAddress"
                for="secondaryAddress">{{jsonLabels?.Field_Labels.Secondary_Address}}
                <span class="font-normal margin-left-8 font-12">{{jsonLabels?.Field_Labels.Optional_Label}}</span>
            </label>
            <input id="secondaryAddress" type="text" formControlName="secondaryAddress"
                class="ant-input-long ant-text-input" aria-required="false">
        </div>
        <div class="form-item margin-bottom-40">
            <label class="font-16 font-semi-bold" id="lbl_City"
                for="cityField">{{jsonLabels?.Field_Labels.City}}</label>
            <input id="cityField" type="text" formControlName="city"
                class="ant-input-long ant-text-input" aria-required="true"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted || careGiverInfoForm.controls['city']?.touched) && careGiverInfoForm.controls['city']?.invalid }" aria-describedby="cityError">
            <ul id="cityError" class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted || careGiverInfoForm.controls['city']?.touched) && careGiverInfoForm.controls['city']?.invalid" aria-live="polite">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['city']?.errors?.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.City}}</span>
                    <span>{{jsonErrors?.Required_err}}</span>
                </li>
            </ul>
        </div>
        <div class="form-item margin-bottom-40">
            <div data-uxd-dropdown-cmp formControlName="state" class="ant-lg-select form-item" [labelText]="jsonLabels?.Field_Labels.State"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted || careGiverInfoForm.controls['state']?.touched) && careGiverInfoForm.controls['state']?.invalid}"
                name="state" id="stateSelection" [items]="stateDropDown?.options" [defaultText]="'Please Select'"
                [isRequired]="true" [describedBy]="'forSelectItem'">
            </div>
            <ul class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted || careGiverInfoForm.controls['state']?.touched) && careGiverInfoForm.controls['state']?.invalid" role="alert">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['state']?.errors?.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonErrors?.State_Selection_Error}}</span>
                </li>
            </ul>
        </div>
        <div class="form-item margin-bottom-40">
            <label class="font-16 font-semi-bold" id="lbl_Zip" for="zipCode">{{jsonLabels?.Field_Labels.Zip}}</label>
            <input type="text" class="ant-text-input width-100" formControlName="zip"
                aria-required="true" name="zip"
                [ngClass]="{'invalid-input-field': (careGiverForm.submitted || careGiverInfoForm.controls['zip']?.touched) && careGiverInfoForm.controls['zip']?.invalid}"
                id="zipCode" [zipValue]="careGiverInfoForm.value.zip" libZipCodeFormat maxlength="10" aria-describedby="zipCodeError"/>
            <ul id="zipCodeError" class="ant-bar-chart-group text-left margin-top-14"
                *ngIf="(careGiverForm.submitted || careGiverInfoForm.controls['zip']?.touched) && careGiverInfoForm.controls['zip']?.invalid" aria-live="polite">
                <li class="font-12 text-error" *ngIf="careGiverInfoForm.controls['zip']?.errors?.required">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonLabels?.Field_Labels?.Zip}}</span>
                    <span>{{jsonErrors?.Required_err}}</span>
                </li>
                <li class="font-12 text-error"
                    *ngIf="careGiverInfoForm.controls['zip'].invalid && careGiverInfoForm.controls['zip'].touched && careGiverInfoForm.controls['zip'].errors.pattern">
                    <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                    <span>{{jsonErrors?.Zip_Format_err}}</span>
                </li>
            </ul>
        </div>
        <div class="flex-horizontal-end margin-bottom-48 margin-top-48">
            <button type="submit" class="btn btn-primary">{{jsonLabels?.Buttons?.Next}}</button>
        </div>
    </form>
</div>