import { Component, OnInit, Input, Inject } from "@angular/core";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";

@Component({
  selector: "app-dashboard-pharmacy-card",
  templateUrl: "./pharmacyCard.html",
  styleUrls: ["./pharmacyCard.scss"],
})
export class PharmacyCardComponent implements OnInit {
  public content: any;
  public pharmacyMenuData: any;

  constructor(private jsonService: JsonContentService) {}
  ngOnInit() {
    this.jsonService.getJSON("new-dashboard").subscribe((data) => {
      this.pharmacyMenuData = data.dashboard.pharmacyData;
      this.pharmacyMenuData.forEach(item => {
        if (item.restrictions.length > 0) {
          item.show = !item.restrictions.every((pharmacyRestrictions) =>
            data.restrictions.includes(pharmacyRestrictions)
          );
        }
      });
      this.content = {
        cardTitle: data.dashboard.labels.cardsTitle.pharmacy
      }
    });

  }
}
