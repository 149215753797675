<ng-container>
    <ng-container>
        <div id="route-img">
            <img class="bank_routing_num" role="img" [src]="'assets/images/my-profile-images/bank_routing_num.png'" alt="">
        </div>
        <br/>
        <div class="row" *ngIf="loader; else showCardForm">
            <div class=" tabbed-view-wrapper col-xs-12 loader">
                <uxd-spinner class="updating-loader"></uxd-spinner>
                <div>{{labels?.SavingLoaderText}}</div>
            </div>
        </div>
    </ng-container>
    <ng-template #showCardForm>
        <form [formGroup]="addBankForm">
            <div class="grid width-100">
                <ng-container>
                    <div class="grid-item width-100">
                        <label for="textfield">{{ labels?.Labels?.AccountType }}</label>
                    </div>
                    <div class="grid-item width-100">
                        <div class="width-100">
                            <div data-uxd-dropdown-cmp formControlName="accountType" class="ant-md-select mt-7 form-item
            ng-untouched ng-pristine ng-valid width-100" [labelText]="" name="accountSelect" id="accountSelect" [items]="selectAccountType.options"
                                required [defaultText]=" 'Select your account type'" [ngClass]="{ 'redBC': (form_accountType.dirty
            || form_accountType.touched) && (form_accountType.invalid || (form_accountType.value==='' ))}"></div>
                            <div class="error-list" *ngIf="(form_accountType.dirty || form_accountType.touched )
            && form_accountType.invalid">
                                <div *ngIf="form_accountType.errors.required">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5">
                                            <span>{{ labels?.Labels?.AccountType }}</span>
                                            <span>{{ errObj?.Required_err }}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="grid-item width-100">
                    <div class="half">
                        <div class="grid-item width-100">
                            <label>{{ labels?.Labels?.RoutingNumber }}</label>
                        </div>
                        <div class="width-100">
                            <input type="text" formControlName="routingNumber"  class="ant-text-input ant-input-long width-100"
                            />
                            <div class="error-list" *ngIf="(form_routingNumber.dirty || form_routingNumber.touched ) &&
            form_routingNumber.invalid">
                                <div *ngIf="form_routingNumber.errors.required; else showOtherCardErrs">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5">
                                            <span>{{ labels?.Labels?.RoutingNumber }}</span>
                                            <span>{{ errObj?.Required_err }}</span>
                                        </span>
                                    </span>
                                </div>
                                <ng-template #showOtherCardErrs>
                                    <div *ngIf="form_routingNumber.errors.minlength || form_routingNumber.errors.maxlength">
                                        <span class="success"><i class="motif-icon motif-delete"></i>
                                            <span class="motif-margin-left-5">
                                                <span>{{ errObj?.Card_Invalid_err }}</span>
                                            </span>
                                        </span>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="half pr-0">
                        <div class="grid-item width-100">
                            <label>{{ labels?.Labels?.AccountNumber }}</label>
                        </div>
                        <div class="width-100">
                            <input type="text" formControlName="accountNumber"  class="ant-text-input ant-input-long width-100"
                            />
                            <div class="error-list" *ngIf="(form_accountNumber.dirty || form_accountNumber.touched ) &&
            form_accountNumber.invalid">
                                <div *ngIf="form_accountNumber.errors.required || form_accountNumber.hasError(
            'whitespace'); else showOtherCardErrs">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5">
                                            <span>{{ labels?.Labels?.AccountNumber }}</span>
                                            <span>{{ errObj?.Required_err }}</span>
                                        </span>
                                    </span>
                                </div>
                                <ng-template #showOtherCardErrs>
                                    <div *ngIf="form_accountNumber.errors.minlength || form_accountNumber.errors.maxlength">
                                        <span class="success"><i class="motif-icon motif-delete"></i>
                                            <span class="motif-margin-left-5">
                                                <span>{{ labels?.Labels?.AccountNumber }}</span>
                                                <span>{{ errObj?.Required_err }}</span>
                                            </span>
                                        </span>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-item width-100">
                    <div class="half">
                        <div class="grid-item width-100">
                            <label>{{ labels?.Labels?.AccountHolderName }}</label>
                        </div>
                        <div class="width-100">
                            <input type="text" formControlName="accountHolderName" class="ant-text-input ant-input-long
            width-100" />
                            <div class="error-list" *ngIf="(form_accountHolderName.dirty || form_accountHolderName.touched
            ) && form_accountHolderName.invalid">
                                <div *ngIf="form_accountHolderName.errors.required">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5">
                                        <span>{{ labels?.Labels?.AccountHolderName }}</span>
                                        <span>{{ errObj?.Required_err }}</span>
                                    </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="half pr-0">
                        <div class="grid-item width-100">
                            <label>{{ labels?.Labels?.PaymentNickname }}</label>&nbsp; <span>{{labels?.Labels?.Optional}}</span>
                        </div>
                        <div class="width-100">
                            <input type="text" formControlName="paymentNickName" class="ant-text-input ant-input-long
            width-100" />
                            <div class="error-list" *ngIf="(form_paymentNickName.dirty || form_paymentNickName.touched
            ) && form_paymentNickName.invalid">
                                <div *ngIf="form_paymentNickName.errors.required">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5">
                                        <span>{{ labels?.Labels?.PaymentNickname }}</span>
                                        <span>{{ errObj?.Required_err }}</span>
                                    </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container>
                    <div class="grid-item width-100">
                        <div data-uxd-checkbox-input-cmp [definition]="checkboxBusiness" formControlName="isBusinessAccount"></div>
                    </div>
                    <div class="grid-item width-100">
                        <div data-uxd-checkbox-input-cmp [definition]="checkbox" [disabled]="autopay" formControlName="futureUse"></div>
                    </div>
                </ng-container>

                <div class="error" *ngIf="errMsg">
                    <div data-uxd-alert-cmp-v2 [alert]="{ alertType: 'negative' }">
                        <span [innerHTML]="errMsg"></span>
                    </div>
                </div>
                <br/>
                <br/>
                <div *ngIf="addressPaymentRadioGroup">
                    <br/>
                    <h5 class="motif-margin-bottom-5" *ngIf="labels">{{labels?.Labels?.AccountHolderAddress}}</h5>
                    <form name="address-payment-form">
                        <label *ngFor="let addressOption of addressPaymentRadioGroup?.options">
                              <input 
                                type="radio" 
                                name="addressOption" 
                                [(ngModel)]="selectedAddressId" 
                                [value]="addressOption.value" 
                              />
                              <span *ngIf="addressOption.payment && addressOption.payment.streetAddress1; else addressRadioOption">
                                <span class="motif-margin-left-3">{{ addressOption?.payment.streetAddress1 }}</span><br/>
                                <span class="motif-margin-left-7">{{ addressOption?.payment.city +",
           " + addressOption?.payment.state +","+ addressOption?.payment.zipCode}} </span>
                              </span>
                              <ng-template #addressRadioOption>
                                <span *ngIf="addressOption.label">
                                  <span class="motif-margin-left-3">{{ addressOption.label }}</span>
                                </span>
                              </ng-template>
                            </label>
                    </form>
                </div>

                <ng-container *ngIf="selectedAddressId==='addNewAddress'">
                    <form [formGroup]="addAddressForm" class="form-address width-100">
                        <div class="grid-item width-100">
                            <label for="textfield">{{ labels?.Labels?.AddressLine1 }}</label>
                        </div>
                        <div class="grid-item width-100">
                            <div class="width-100">
                                <input type="text" formControlName="addressLine1" class="ant-text-input ant-input-long width-100" />
                                <div class="error-list" *ngIf="(formAddressLine1.dirty || formAddressLine1.touched ) &&
            formAddressLine1.invalid">
                                    <div *ngIf="formAddressLine1.errors.required">
                                        <span class="success"><i class="motif-icon motif-delete"></i>
                                            <span class="motif-margin-left-5">
                                                <span>{{ labels?.Labels?.AddressLine1 }}</span>
                                                <span>{{ errObj?.Required_err }}</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid-item width-100">
                            <label for="textfield">{{ labels?.Labels?.AddressLine2 }}</label> &nbsp; <span>{{labels?.Labels?.Optional}}</span>
                        </div>
                        <div class="grid-item width-100">
                            <div class="width-100">
                                <input type="text" formControlName="addressLine2" class="ant-text-input ant-input-long
            width-100" placeholder="{{labels.Labels.AddressLinePlaceholder}}" />
                            </div>
                        </div>
                        <div class="grid-item width-100">
                            <div class="third">
                                <div class="grid-item width-100">
                                    <label for="textfield">{{ labels?.Labels?.ZIPCode }}</label>
                                </div>
                                <div class="grid-item width-100">
                                    <div class="width-100">
                                        <input type="text" formControlName="zipCode" class="ant-text-input ant-input-long width-100" />
                                        <div class="error-list" *ngIf="(formZipCode.dirty || formZipCode.touched) && formZipCode.invalid">
                                            <div class="error-list" *ngIf="(formZipCode.dirty || formZipCode.touched)
            && formZipCode.invalid">
                                                <div *ngIf="formZipCode.errors.required">
                                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                                        <span class="motif-margin-left-5">
                                                            <span>{{ labels?.Labels?.ZIPCode }}</span>
                                                            <span>{{ errObj?.Required_err }}</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="third ml-5">
                                <div class="grid-item width-100">
                                    <label for="textfield">{{ labels?.Labels?.City }}</label>
                                </div>
                                <div class="grid-item width-100">
                                    <div class="width-100">
                                        <input type="text" formControlName="city" class="ant-text-input ant-input-long width-100" />
                                        <div class="error-list" *ngIf="(formCity.dirty || formCity.touched) && formCity.invalid">
                                            <div *ngIf="formCity.errors.required">
                                                <span class="success"><i class="motif-icon motif-delete"></i>
                                                    <span class="motif-margin-left-5">
                                                        <span>{{ labels?.Labels?.City }}</span>
                                                        <span>{{ errObj?.Required_err }}</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="third ml-5">
                                <div class="grid-item width-100">
                                    <label for="textfield">{{ labels?.Labels?.State }}</label>
                                </div>
                                <div class="grid-item width-100">
                                    <div class="width-100">
                                        <div data-uxd-dropdown-cmp formControlName="state" class="ant-md-select mt-7 form-item
            ng-untouched ng-pristine ng-valid width-100" [labelText]="" name="monSelect" id="stateSelect" [items]="selectState.options"
                                            required [defaultText]=" 'Select State'" [ngClass]="{ 'redBC': (formState.dirty ||
            formState.touched) && (formState.invalid || (formState.value==='' ))}">
                                        </div>
                                        <div class="error-list" *ngIf="(formState.dirty || formState.touched) && formState.invalid">
                                            <div *ngIf="formState.errors.required">
                                                <span class="success"><i class="motif-icon motif-delete"></i>
                                                    <span class="motif-margin-left-5">
                                                        <span>{{ labels?.Labels?.State }}</span>
                                                        <span>{{ errObj?.Required_err }}</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>

                <div id="addCardBtnGroup" class="grid-item width-100 text-right">
                    <!-- show buttons based on the showButtons input passed -->
                    <ng-container *ngIf="showButtons">
                        <div class="button-group width-100">
                            <button type="button" (click)="onCancel()" class="btn btn-secondary">{{ labels?.Buttons.Cancel }}</button>
                            <button type="button" (click)="addCard()" data-analytics="" class="btn btn-primary">{{labels?.Buttons.Add}}</button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
    </ng-template>
</ng-container>