<div class="container margin-bottom">
  <div class="pa-monthly-stmt">
    <div class="half lr-pad">
      <div class="row">
        <h2 class="section-header-two" [innerHtml]="content.PwrAcctMonthlyStmt_lbls.PwrAcctMonthlyStmtHeader_lbl"></h2>
        <ng-container *ngIf="isStatementsApiLoading; else showForm">
          <uxd-spinner></uxd-spinner>
          <div [innerHtml]="content.Loading_lbl"></div>
        </ng-container>
        <ng-template #showForm>
          <div *ngIf="viewStatementErr" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span [innerHTML]="viewStatementErr"></span>
          </div>
          <ng-container *ngIf="montlyStmtsErr; else showMonthlyStmtsPage">
            <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
              <span [innerHTML]="montlyStmtsErr"></span>
            </div>
          </ng-container>
          <ng-template #showMonthlyStmtsPage>
            <ng-container *ngIf="statementsAvailable; else showNoStatementsMsg">
              <div class="ant-heading-six motif-margin-top-10 motif-margin-bottom-3" 
                [innerHtml]="content.PwrAcctMonthlyStmt_lbls.PwrAcctActStmtInstr_lbl"></div>
              <form #selectMontlyStmt="ngForm" (ngSubmit)="viewMonthlyStatement(selectedMonthId)" name="selectMontlyStmt">
                <div class="cmp-example-wrapper motif-margin-top-8">
                  <div class="form-item clearfix">
                    <div #lgSelect="ngModel" data-uxd-dropdown-cmp [(ngModel)]="selectedMonthId" class="ant-md-select form-item" [labelText]="selectStatement.title"
                      name="lgSelect" id="lgSelect" [items]="selectStatement.options" [isRequired]="true" [describedBy]="'for-monthly-statement'"
                      required>
                    </div>
                  </div>
                </div>
                <button id="downloadPdf" type="submit" class="btn btn-secondary motif-margin-top-6"
                [innerHtml]="content.PwrAcctMonthlyStmt_lbls.PwrAcctActSub_btn" 
                [attr.aria-label]="content.PwrAcctMonthlyStmt_lbls.PwrAcctActSub_btn"></button>
              </form>
            </ng-container>
            <ng-template #showNoStatementsMsg>
              <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}">
                <span [innerHTML]="content.PwrAcctMonthlyStmt_lbls.PwrAcctNoMonthlyStatements"></span>
              </div>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <ng-container *ngIf="hasPlanNotifications">
      <div class="divider"></div>
      <div class="half lr-pad">
        <div class="row">
          <h2 class="section-header-two" [innerHtml]="content.PwrAcctGoPaperLess_lbl.PwrAcctGoPaperLessHeader_lbl"></h2>
          <div class="ant-heading-six motif-margin-bottom-3" 
            [innerHtml]="content.PwrAcctGoPaperLess_lbl.PwrAcctGoPaperLessText1_lbl"></div>
          <div class="sign-up motif-margin-top-8">
            <button id="goPaperLess" class="btn btn-secondary" (click)="goPaperless()" [innerHtml]="content.PwrAcctGoPaperLess_lbl.SignUp_btn"
              aria-label="Sign up to go paperless"></button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
