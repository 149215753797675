import { Injectable } from "@angular/core";
import { CurrentBillData, PATransaction} from "../interfaces/power-account";
import { DateUtility } from "@anthem/mbrportal/utility/services/dateUtil";
import { BillSummary, HealthAccountSummary, Transaction } from 'gbd-models';
import {formatCurrency} from '../../../../../libs/currency-formatter/formatCurrency';

@Injectable({
  providedIn: "root"
})
export class PowerAccountModel {
  constructor( private dateUtility: DateUtility) {}

  buildPowerAccountSummary(PowerAccSummary: HealthAccountSummary): HealthAccountSummary{
    const{annualPowerAccountContribution,memberEstimatedAnnualContribution,remainingBalance,stateEstimatedAnnualContribution } = PowerAccSummary;
    const powerAccountSummaryResponse = {
      annualPowerAccountContribution: formatCurrency(annualPowerAccountContribution),
      memberEstimatedAnnualContribution: formatCurrency(memberEstimatedAnnualContribution),
      remainingBalance: formatCurrency(remainingBalance),
      stateEstimatedAnnualContribution: formatCurrency(stateEstimatedAnnualContribution),
      }
    return powerAccountSummaryResponse;
  }


  buildCurrentBill(rawCurrentBillRes: BillSummary): CurrentBillData {
    return rawCurrentBillRes?.totalDue > 0 ? {
      currentBill : formatCurrency(rawCurrentBillRes.totalDue),
      dueDate: this.dateUtility.formatDateMedium(rawCurrentBillRes.dueDate)
    } : { currentBill: '$0.00', dueDate: ''};
  }

  buildPowerAccountTransactions(rawTransactionsData: Transaction[]) {
    const transactionsData : PATransaction[] = rawTransactionsData.map(transactionObj => {
      const {claimId, remainingBalance, transactionDetails} = transactionObj;
      const {transactionDate,transactionType,transactionAmount,transactionStatus} = transactionDetails;
      const transactionModifiedObj: PATransaction = {
        date: this.dateUtility.formatDateMedium(transactionDate),
        description: transactionType,
        claimNumber: claimId ?? '',
        amount: formatCurrency(transactionAmount),
        balance: remainingBalance ? formatCurrency(remainingBalance) : '',
        status: transactionStatus
      }
      return transactionModifiedObj;
    });

    return transactionsData;
  }
}