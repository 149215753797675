
export enum CheckBoxNames {
    CARD_CHECKBOX = 'addcard-checkbox',
    CHECKING_CHECKBOX = 'addchecking-checkbox',
    CHECKING_BUSINESS_CHECKBOX = 'addchecking-business-checkbox'
}

export const DEFAULT_NUMBER_OF_MONTHS = 12;

export const DEFAULT_NUMBER_OF_YEARS = 20;

export enum AccountType {
    CREDITCARD = 'CREDIT_CARD',
    BANKING = 'BANK_ACCOUNT'
  }