import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-security-answer-validator',
  templateUrl: './security-answer-validator.component.html',
  styleUrls: ['./security-answer-validator.component.css']
})
export class SecurityAnswerValidatorComponent implements OnInit {
  showTooltipErrOnBlur: boolean = false;
  showTooltips1: boolean = false;
  showTooltips2: boolean = false;
  showTooltips3: boolean = false;

  @Input('answer1Id') answer1Id: string;
  @Input('Showanswer1') Showanswer1 : boolean;
  @Input('Showanswer1Required') Showanswer1Required : boolean;
  @Input('Showanswer1Requirement') Showanswer1Requirement: boolean;
  @Input('answer1RequiredLabel') answer1RequiredLabel: boolean;
  @Input('answer1RequirementLabel') answer1RequirementLabel: boolean;
  @Input('showTooltip1') showTooltip1: boolean;
  @Input('minLengthCss') minLengthCss: string;
  @Input('invalidNameCharCss') invalidNameCharCss: string;
  @Input('duplciateCss') duplciateCss: string;
  @Input('securityAnswerLengtherror') securityAnswerLengtherror: string;
  @Input('securityAnswerInvaliderror') securityAnswerInvaliderror: string;
  @Input('securityAnswerDuplicateerror') securityAnswerDuplicateerror: string;

  constructor() { }

  ngOnInit(): void {
  }

}
