<div *ngIf="showContent; else loadingOrError">
  <div class="row mt-65">
    <div class="ant-content-wrapper" role="region">
      <div class="motif-margin-left-5 motif-margin-right-5">
        <div class="cards-wrapper">
          <div class="cards-container">
            <div class="card large-card card-popover">
              <div class="medium-card">
                <div class="account-balance-overview" [ngClass]="{'no-pbm': !hasPBM}">
                  <app-account-balance-overview [labels]="pabOverview">
                  </app-account-balance-overview>
                </div>
              </div>
              <div class="container" *ngIf="!balanceError">
                <div *ngIf="hasPBM" class="medium-card motif-margin-top-20">
                  <div>
                    <app-account-balance-payment [totalBalance]="pbmBalance" [labels]="pabAccounts"
                      [heading]="pabAccounts.PBMHeading" [route]="pbmRoute" [analyticsTag]="pabAccounts.PBMAnalytics"
                      [hasPBM]="linkKeys.PBM">
                    </app-account-balance-payment>
                  </div>
                </div>
                <div class="medium-card motif-margin-top-20">
                  <app-account-balance-payment [totalBalance]="specialtyBalance" [labels]="pabAccounts"
                    [heading]="pabAccounts.SpecialtyHeading" [route]="specialtyRoute"
                    [analyticsTag]="pabAccounts.SpecialtyAnalytics" [hasPBM]="linkKeys.SPECIALTY">
                  </app-account-balance-payment>
                </div>
              </div>
              <div class="container row" *ngIf="balanceError">
                <div class="motif-margin-top-20" id="balance-error">
                  <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                    <span [innerHTML]="errors.GeneralSystemErrorWithoutCode"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingOrError>
  <div *ngIf="svcError; else loading" class="container" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
    <span [innerHTML]="svcError"></span>
  </div>
  <ng-template #loading>
    <div class="container">
      <div class="spinner">
        <uxd-spinner class="text-center"></uxd-spinner>
      </div>
    </div>
  </ng-template>
</ng-template>