import { Component, OnInit } from '@angular/core';
import { ContentHelper } from '@anthem/mbrportal/core/public_api';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';

@Component({
  selector: 'app-ahd-track-order-details-landing',
  templateUrl: './ahd-track-order-details-landing.component.html',
  styleUrls: ['./ahd-track-order-details-landing.component.css'],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }]
})
export class AhdTrackOrderDetailsLandingComponent implements OnInit {
  orderDetailsContent;
  constructor(private jsonSvc: JsonContentService, private _contentHelper: ContentHelper) {
    this.jsonSvc.getJSON('ahd-review-orders-page').subscribe(content => {
      this.orderDetailsContent = content;
      this._contentHelper.registerContent('ReviewOrderComponent', this.orderDetailsContent.ReviewOrderComponent);
      this._contentHelper.registerContent('VendorOrderDetailsPrepayComponent', this.orderDetailsContent.ReviewOrderComponent.orderPrescriptionDetails);
      this._contentHelper.registerContent('VendorPrescriptionListAccordionComponent', this.orderDetailsContent.ReviewOrderComponent.orderPrescriptionAccordion);
      this._contentHelper.registerContent('VendorOrderDetailsPostpayComponent', this.orderDetailsContent.ReviewOrderComponent.orderPrescriptionDetails);
      this._contentHelper.registerContent('VendorTrackShipmentSliderComponent', this.orderDetailsContent.ReviewOrderComponent.trackShipmentSlider);
      this._contentHelper.registerContent('VendorReviewOrderPaymentComponent', this.orderDetailsContent.ReviewOrderComponent.vendorReviewOrderPayment);
      this._contentHelper.registerContent('VendorOrderDetailsInvoiceComponent', this.orderDetailsContent.ReviewOrderComponent.orderPrescriptionDetails);
      this._contentHelper.registerContent('VendorReviewOrderReceiptComponent', this.orderDetailsContent.ReviewOrderComponent.orderPrescriptionDetails);
      this._contentHelper.registerContent('VendorPrescriptionListComponent', this.orderDetailsContent.ReviewOrderComponent.orderPrescriptionDetails.costSummary);
    });
   
   }

  ngOnInit(): void {

  }

}
