import { Component, OnInit, ViewChild } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { CallMeService } from "./../services/call-me.service";
import {  Router } from "@angular/router";
import { AlertService, InlineAlertContainerComponent } from "@anthem/uxd/alert";
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { FormValidators } from "sydmed/libs/custom-validators/src/lib/form-validators.class";
import CONSTANTS from '../utils/constants';

export enum CallTypeEnum {
  callMe = "Call+me",
  scheduleForLater = "Schedule+for+later"
}

@Component({
  selector: 'app-call-me',
  templateUrl: './call-me.component.html',
  styleUrls: ['./call-me.component.scss']
})
export class CallMeComponent implements OnInit {

  callMeForm = new FormGroup({
    inquiryType: new FormControl(null, [Validators.required]),
    scheduleCallType: new FormControl(null, [Validators.required]),
    scheduleCallDay: new FormControl(null, [Validators.required]),
    scheduleCallTime: new FormControl(null, [Validators.required]),
    phone: new FormControl(null, [Validators.required, FormValidators.PhoneNumberValidator]),
  });

  public disableButton: boolean = false;
  public labels: any = {};
  public errorLabels: any = {};
  public inquiryTypesOptions: any = [];
  public callTypeOptions: any = [];
  public callDayOptions: any = [];
  public callTimingOptions: any = [];
  public approximateTime: string = "";
  public isCallScheduled: boolean = false;
  public callTypeEnum = CallTypeEnum;
  public dialogStr: string;
  public alertPosition = 'static';
  resetError: any;
  showPhoneError: boolean = false;
  analytics = CONSTANTS.ANALYTICS;
  errorApi: any;
  @ViewChild(InlineAlertContainerComponent) child;

  constructor(
    private dataSvc: JsonContentService,
    private errCodeMapSvc: ErrorCodeMapperService,
    private callMeSvc: CallMeService,
    private router: Router,
    private _alert: AlertService
  ) { }

  ngOnInit() {
    this.subscribeFormChanges();
    this.fetchJsonLabels();
  }

  fetchJsonLabels() {
    this.dataSvc.getJSON('contact-us').subscribe(data => {
      this.labels = data.contactUs.labels.callMe;
      for (const i in this.labels) {
        if (typeof(this.labels[i]) === "object") {
          this.labels[i] = this.labels[i][JSON.parse(sessionStorage.getItem("sydMedMemberContext")).planCode]
        }
      }
      this.errorLabels = data.contactUs.labels.errorMessages;
      this.callTypeOptions = data.contactUs.labels.callTypes.map(x => {
        x.analytics = this.analytics.CALL_ME.CALL_TYPE[x.label];
        return x;
      });
      this.setInquiryTypesOptions();
    }, (error) => {
      this.errorApi = error;
    });
  }

  setInquiryTypesOptions() {
    this.callMeSvc.getCallMeInquiryTypes().subscribe((inquiryTypes: any) => {
      if (inquiryTypes.error) {
        this.disableButton = true;
        this.handleError(inquiryTypes.error, this.errorLabels.errorScheduleLater);
        this.callTypeOptions.length = 0;
        return false;
      }
      else {
        this.inquiryTypesOptions = inquiryTypes.map(x => {
          x.analytics = this.analytics.CALL_ME.INQUIRY_TYPES[x.label];
          return x;
        });
        this.disableButton = false;
      }
    })
  }

  subscribeFormChanges() {
    this.form_inquiryType.valueChanges.subscribe(res => {
      this.clearErrorAlert();
      if (res) {
        this.form_scheduleCallType.reset();
      }
    })

    this.form_scheduleCallType.valueChanges.subscribe((res: any) => {
      const inquiryType = this.inquiryTypesOptions.some(inquiryTypesOption => inquiryTypesOption.value === this.form_inquiryType.value);
      if(inquiryType){
        this.clearErrorAlert();
        if (res === this.callTypeEnum.scheduleForLater) {
          this.setApptDateTime();
          this.form_scheduleCallDay.markAsUntouched();
          this.form_scheduleCallDay.setValidators(Validators.required);
          this.form_scheduleCallDay.updateValueAndValidity();
          this.form_scheduleCallTime.markAsUntouched();
          this.form_scheduleCallTime.setValidators(Validators.required);
          this.form_scheduleCallTime.updateValueAndValidity();
        }
        else if (res === this.callTypeEnum.callMe) {
          this.setApproximateCallTime();
          this.form_scheduleCallDay.setValidators(Validators.nullValidator);
          this.form_scheduleCallDay.updateValueAndValidity();
          this.form_scheduleCallTime.setValidators(Validators.nullValidator);
          this.form_scheduleCallTime.updateValueAndValidity();
        }
      } else {
        this.form_inquiryType.markAsTouched(); 
      }
    });

    this.form_scheduleCallDay.valueChanges.subscribe(res => {
      if (res) {
        this.callTimingOptions = this.callMeSvc.getApptTimesByDate(res);
      }
    });
  }

  setApproximateCallTime() {
   this.disableButton = true;
    this.callMeSvc.getApproximateCallTime(this.form_inquiryType.value).subscribe(
      (data) => {
        if (data && data.body && data.body.estimatedWaitTime) {
          this.disableButton = false;
          this.approximateTime = data.body.estimatedWaitTime;
        }
      },
      (err: any) => {
        this.disableButton = true;
        this.clearErrorAlert();
        this._alert.info(this.errorLabels.errorCallMe, {
          regionName: 'inline-alerts',
          isDissmisable: true
        });
      }
    )
  }

  setApptDateTime() {
    this.disableButton = false;
    this.callMeSvc.getApptDateTime(this.form_inquiryType.value).subscribe(
      (data: any) => {
        if (data.error) {
          this.disableButton = true;
          this.handleError(data.error, this.errorLabels.errorScheduleLater);
          return false;
        }
        if (data && (data.dates || []).length) {
          this.disableButton = false;
          this.callDayOptions = data.dates;
        }
      },
      (err: any) => {
        this.disableButton = true;
        this.handleError(err.error, this.errorLabels.errorScheduleLater);
      }
    )
  }

  cancel() {
    this.isCallScheduled = false;
    this.router.navigate([this.labels.contactUsCallUsUrl]);
  }

  callMe() {

    if (this.callMeForm.invalid) {
      this.markFormGroupTouched(this.callMeForm);
      return;
    }
    let requestPayload = {};
    const selectedOption = this.inquiryTypesOptions.find(x => x.value === this.form_inquiryType.value);
    const inquiryType = selectedOption?.label;
    const queueId = selectedOption?.queueId;
    let dialogMessageStr = '';
    let selectedPhone = this.replaceAll(this.form_phone.value, '-', '');
    //when schedule for later 
    if (this.form_scheduleCallType.value === this.callTypeEnum.scheduleForLater) {
      //set message for Schedule later
      let dateAndTime = '';
      let selectedTime = '';
      this.callTimingOptions.find((item) => {
        if (item && item.value && item.value === this.form_scheduleCallTime.value) {
          dateAndTime = item.date;
          selectedTime = item.label;
        }
      });
      let selectedDate;
      this.callDayOptions.forEach(element => {
        let itemDate = new Date(element.value).toDateString();
        let scheduleSelectedDate = new Date(this.form_scheduleCallDay.value).toDateString();
        if (itemDate == scheduleSelectedDate) {
          selectedDate = element.label;
        }
      });
      dialogMessageStr = this.labels.contactUsSuccessScheduleLater.
        replace('[PHONE_NUMBER]', this.form_phone.value).
        replace('[CALL_REASON]', inquiryType).
        replace('[TIME]', selectedTime).
        replace('[DATE]', selectedDate);

      requestPayload = {
        apptDateTime: dateAndTime,
        inquiryType: inquiryType,
        language: "English",
        phoneNumber: selectedPhone,
        queueId: queueId
      }

    } else {
      dialogMessageStr = this.labels.contactUsSuccessCallMe.
        replace('[PHONE_NUMBER]', this.form_phone.value).
        replace('[CALL_REASON]', inquiryType);
      requestPayload = {
        inquiryType: inquiryType,
        language: "English",
        phoneNumber: selectedPhone,
        queueId: queueId
      }

    }
    this.dialogStr = dialogMessageStr;
    this.callMeSvc.callMe(requestPayload).subscribe(
      (data) => {
        this.isCallScheduled = true;
        window.scrollTo(0, 0);
      },
      (err) => {
        this.handleError(err.error, this.errorLabels.errorScheduleLater, false);
      }
    )
  }

  clearErrorAlert() {
    this.child.dismissAlertId(0);
  }

  handleError(err: any, errorMsg: any, showInfo: boolean = false) {
    this.clearErrorAlert();
    let errorCode = "0000";
    if (err && err.code) {
      errorCode = err.code;
    }
    if (showInfo) {
      this._alert.info(errorMsg + `${errorCode}`, {
        regionName: 'inline-alerts',
        isDissmisable: true
      });
    }
    else {
      this._alert.error(errorMsg + `${errorCode}`, {
        regionName: 'inline-alerts',
        isDissmisable: true
      });
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  replaceAll(string, find, replaceWith) {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return string.replace(new RegExp(escapedFind, 'g'), replaceWith);
  }

  focusOutPhoneError() {
    this.showPhoneError = true;
    if (this.callMeForm.invalid) {
      this.markFormGroupTouched(this.callMeForm);
      return;
    }
  }
  get form_inquiryType() { return this.callMeForm.get('inquiryType') }
  get form_scheduleCallType() { return this.callMeForm.get('scheduleCallType') }
  get form_scheduleCallDay() { return this.callMeForm.get('scheduleCallDay') }
  get form_scheduleCallTime() { return this.callMeForm.get('scheduleCallTime') }
  get form_phone() { return this.callMeForm.get('phone') }
}