<div [formGroup]="form">
  <div id="search-wrapper" class="form-item">
    <label>{{searchLabel}}</label>
    <input 
      id={{searchId}} 
      name="search" 
      formControlName="search" 
      type="text" 
      placeholder="{{placeholder}}" 
      class="ant-text-input" 
      [ngStyle]="{'border-color': showErrorMsg ? '#d20a3c' : '#949494' }" 
      (blur)="resetSearchFilter($event)"
      (keyup)="resetSearchFilter($event)"
    />
    <button *ngIf="showSearchIcon" attr.data-analytics="{{searchAnalytics}}" class="search-submit" (click)="searchForTerm($event)" [attr.aria-label]="'search'">
      <span id="searchIcon">
        <i class='motif-icon motif-search-1 span.fa'></i>
      </span>
    </button>
    <button *ngIf="!showSearchIcon" class="search-cancel" (click)="cancelSearch($event)" [attr.aria-label]="'Clear'">
      <span id="cancelSearchIcon" tabindex="0">
        <i class='motif-icon motif-cancel span.fa'></i>
      </span>
    </button>
  </div>

  <div class="error-list motif-margin-bottom-5" *ngIf="(search.dirty || search.touched) && search.invalid">
    <span class="success"><i class="motif-icon motif-delete"></i>
      <span class="motif-margin-left-5">
        <span>{{errorMessage}}</span>
      </span>
    </span>
  </div>
</div>