import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  //TODO: Variable to be set as true based on the session data of the member
  isParentOrGuardian = false;
  userDetails: any;
  private locale: string;

  constructor(
    private router: Router,
    private jsonSvc: JsonContentService,
    private localeService: LocaleService
  ) { }

  public jsonLabels;
  public jsonAriaLabel;

  ngOnInit(): void {
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Confirmation;
      this.jsonAriaLabel = data.RegistrationMFA.Labels.Login_Aria_Label;
    });
    this.userDetails = JSON.parse(window.sessionStorage.getItem('contactsModel'));
    if (this.userDetails?.memberResponse?.caregiverRequired) {
      this.isParentOrGuardian = true;
    }
    this.locale = this.localeService.getLocaleOverride();
    sessionStorage.clear();
    if (this.locale) {
      this.localeService.setLocaleOverride(this.locale);
    }
  }

  login() {
    this.router.navigate(['/public/login']);
  }

}
