import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-profile-block',
  templateUrl: './manage-profile-block.component.html',
  styleUrls: ['./manage-profile-block.component.scss']
})
export class ManageProfileBlockComponent implements OnInit {

  @Input() headingLabel: string;
  @Input() textLabel: string;
  @Input() linkText: string;
  @Input() link: string;
  @Input() analytics: string;
  @Input() profileRestriction: boolean;

  constructor() { }

  ngOnInit(): void { }

}
