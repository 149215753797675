import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-prescriptions',
  templateUrl: './view-prescriptions.component.html',
  styleUrls: ['./view-prescriptions.component.scss']
})
export class ViewPrescriptionsComponent implements OnInit {

  @Input() headingLabel: string;
  @Input() textLabel: string;
  @Input() linkText: string;
  @Input() link: string;
  @Input() analytics: string;

  constructor() { }

  ngOnInit(): void { }

}
