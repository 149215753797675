import { AccordianModule } from '@anthem/uxd/accordion';
import { AlertModule } from '@anthem/uxd/alert';
import { APP_BASE_HREF, CurrencyPipe, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from '@anthem/uxd/chart';
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { ClaimsContainerComponent } from './claimsContainer/claimsContainerCmp';
import { ClaimsDetailComponent } from './claimsDetail/claimsDetailCmp';
import { ClaimsFilterComponent } from './claimsFilter/claimsFilterCmp';
import { ClaimsRoutingModule } from './claimsRoutingModule';
import { ClaimsSummaryComponent } from './claimsSummary/claimsSummaryCmp';
import { DateUtility } from './util/dateUtil';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { ModalModule } from '@anthem/uxd/modal';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RadioModule } from '@anthem/uxd/radio';
import { UxDeprecatedModule, UxModalHelper } from '@anthem/uxd/deprecated';
import { UxHelper } from '@anthem/uxd/util';
import { InputModule } from '@anthem/uxd/input';
import { ListFilterPipe } from '@anthem/mbrportal/shared/pipes/listFilterPipe';
import { CurrencyUtility } from '@anthem/mbrportal/utility/services/currencyUtil';
import { ScrollToViewSvc } from '@anthem/mbrportal/shared/services/scrollToViewSvc';
import { OrderBy } from '@anthem/mbrportal/shared/pipes/orderBy';
import { FooterModule } from '@anthem/uxd/footer';
import { ClaimsPrintDetailComponent } from './claimsPrintDetail/claimsPrintDetailCmp';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';

@NgModule( {
  declarations: [
    ClaimsContainerComponent,
    ClaimsDetailComponent, 
    ClaimsFilterComponent,
    ClaimsSummaryComponent,
    ClaimsPrintDetailComponent
  ],
  imports: [
    AccordianModule,
    AlertModule,
    BrowserAnimationsModule,
    ChartModule,
    CheckboxModule,
    ClaimsRoutingModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    IndicatorModule,
    ModalModule,
    RadioModule,
    ReactiveFormsModule,
    UxDeprecatedModule,
    InputModule,
    FooterModule
  ],
  exports: [
    ClaimsContainerComponent,
    ClaimsSummaryComponent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    CurrencyPipe,
    CurrencyUtility,
    DatePipe,
    DateUtility,
    ListFilterPipe,
    OrderBy,
    ScrollToViewSvc,
    UxHelper,
    UxModalHelper,
    JsonContentService
  ],
  bootstrap: [ ClaimsContainerComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
} )
export class ClaimsModule { }
