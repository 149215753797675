import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DateUtility } from "@anthem/mbrportal/utility/services/dateUtil";
import { PowerAccountContent } from "../interfaces/power-account-content";
import { PowerAccountService } from "../services/power-account.service";
import { HealthAccountStatements } from 'gbd-models';
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";

@Component({
  selector: "app-power-account-monthly-stmt",
  templateUrl: "./power-account-monthly-stmt.component.html",
  styleUrls: ["./power-account-monthly-stmt.component.scss"]
})
export class PowerAccountMonthlyStmtComponent implements OnInit {
  public selectedMonthId: number;
  public montlyStmtsErr: string;
  public statementsAvailable: boolean = true;
  public isStatementsApiLoading: boolean = true;
  public viewStatementErr: string = '';

  selectStatement: any = {
    title: "",
    options: []
  };

  @Input("content") content: PowerAccountContent;
  @Input('hasPlanNotifications') hasPlanNotifications: boolean;

  constructor(
    private dateUtility: DateUtility,
    private powerAccountSvc: PowerAccountService,
    private router: Router,
    private errCodeMapSvc: ErrorCodeMapperService
  ) {}

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.setMonthlyStatements();
  }

  setMonthlyStatements() {
    this.isStatementsApiLoading = true;
    this.selectStatement.title = this.content.PwrAcctMonthlyStmt_lbls.PwrAcctActStmtDt_lbl;
    this.powerAccountSvc.getStatements().subscribe(
      (res: any) => {
        const pacStatements: HealthAccountStatements  = res.body;
        if (pacStatements?.statements?.length > 0) {
          pacStatements.statements.map(statementObj => {
            this.selectStatement.options.push({
              label: this.dateUtility.formatDobDate(statementObj.endDate),
              value: statementObj.id
            });
          });
          this.setDefaultMonth(this.selectStatement);
        } else {
          this.statementsAvailable = false;
        }
        this.isStatementsApiLoading = false;
      },
      (err: any) => {
        this.montlyStmtsErr = this.errCodeMapSvc.errorCodeMapper(err, this.content.ErrorMsgs);
        this.isStatementsApiLoading = false;
      }
    );
  }

  setDefaultMonth(statements: any) {
    if(statements.options.length) {
      this.selectedMonthId = statements.options[0].value;
    }
  }

  viewMonthlyStatement(statementId: number) {
    this.viewStatementErr = '';
    this.isStatementsApiLoading = true;
    this.powerAccountSvc.getPAStatement(statementId).subscribe(
      (res: any) => {
        if (res) {
          this.isStatementsApiLoading = false;

          let ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
            ie11 =
              navigator.userAgent.match(/Trident\/7.0/) &&
              navigator.userAgent.match(/rv:11/),
            ieEDGE = navigator.userAgent.match(/Edge/g),
            ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;
          let ipadIphone =
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPhone/i);

          const d = new Date();
          let fileName = "PDFmonthly_" + d.getTime() + ".pdf";
          let pdfBlob = new Blob([res], { type: "application/pdf" });

          //for Microsoft browsers (IE & Edge)
          if (ieVer > -1) {
            const nav = (window.navigator as any);
            if (nav && nav.msSaveOrOpenBlob) {
              nav.msSaveOrOpenBlob(pdfBlob, fileName);
              return;
            }
          } else {
            //for rest of the browsers
            let link = document.createElement("a");
            let url = URL.createObjectURL(pdfBlob);
            if (ipadIphone) {
              var openWindow = window.open(url);
              //Showing alert to diable popup blocker
              if (!openWindow) {
                this.isStatementsApiLoading = false;
                alert("Please disable the popup blocker");
              }
            } else if ("download" in link) {
              link.href = url;
              link.download = fileName;
              link.click();
            }
          }
        }
      },
      (err: any) => {
        this.isStatementsApiLoading = false;
        this.viewStatementErr = this.errCodeMapSvc.errorCodeMapper(err, this.content.ErrorMsgs);
      }
    );
  }

  goPaperless() {
    this.router.navigate([this.content.PwrAcctGoPaperLess_lbl.SignUpLink]);
  }
}
