import { Component, Input, OnDestroy } from "@angular/core";
import { ScrollToViewSvc } from "@anthem/mbrportal/shared/public_api";
import { Subscription } from "rxjs";
import * as claimDetailAPIData from '../assets/data/mock/claimsDetailMockData.json';
import { ClaimSummary } from "../interfaces/claims";
import { ClaimsContent } from '../interfaces/claimsContent';
import { ClaimsList } from "../models/claimsList";
import { ClaimsService } from "../services/claimsSvc";
import { DateUtility } from "../util/dateUtil";

/**
 * @description
 * This component is responsible for loading claim detail
 *
 * @function
 * loadClaimsDetail(index: number, claimsObject: any, searchSingleClaim: boolean = false): void
 * resetInMemClaimDetailCache(): void
 * private loadClaimsDetailWidget(classCode: string, selectedClaimIndex: number): void
 * private showMessage(type: string, message: string): void
 * ngOnDestroy()
 *
 * @example
 * ```html
 * <data-sydmed-claims-summary-cmp *ngIf="!claimSummaryLoader && !claimsErrorMsg" #claimsSummaryCmp [allClaims]="allClaims" [claims]="claims" [content]="content"></data-sydmed-claims-summary-cmp>
 * ```
 */

declare let _satellite: any;
declare let window: any;

@Component({
  selector: "data-sydmed-claims-summary-cmp",
  templateUrl: "./claimsSummary.html",
  styleUrls: ["../claimsSyd.scss"],
})
export class ClaimsSummaryComponent implements OnDestroy {
  @Input()
  claims: ClaimSummary[];

  @Input()
  content: ClaimsContent;

  @Input()
  isMini: boolean;

  allUserClaims: any;
  claimsDetailLoadingSpinner: boolean = false;
  claimsDetailServiceError: any = {};
  claimsErrorMsg: string;
  claimsErrorType: string;
  clmId: string;
  hasClaims: boolean = false;
  inMemClaimDetailCache: any = {};
  searchSingleClaim: boolean = false;
  private claimsObject: ClaimSummary;
  private index: number = 0;

  @Input()
  set allClaims(allClaims: ClaimSummary[]) {
    if (allClaims !== undefined && allClaims !== null && allClaims.length > 0) {
      this.hasClaims = true;
    } else {
      this.hasClaims = false;
    }
    this.allUserClaims = allClaims;
    this.inMemClaimDetailCache = {};
    this.claimsDetailServiceError = {};
  }

  private sub: Subscription = new Subscription();
  constructor(
    private claimsService: ClaimsService,
    private claimsList: ClaimsList,
    private scrollToViewSvc: ScrollToViewSvc,
    public dateUtil: DateUtility
  ) {}

  /**
   * @description This function is responsible for loading claimdetails from cache or by calling getClaimsDetail svc
   * @param index @type number, claim row index
   * @param claimsObject @type any, claim summary object, can be array or object
   * @param searchSingleClaim @type boolean with default value as false
   */
  loadClaimsDetail(index: number, claimsObject: any, searchSingleClaim: boolean = false): void {
    this.searchSingleClaim = searchSingleClaim;
    if (!Array.isArray(claimsObject)) {
      claimsObject.isExpanded = true;
      claimsObject.showClaimsPrintDetailPanel = false;
    }

    if (searchSingleClaim) {
      this.claims = this.allUserClaims = [claimsObject];
    } else if (Array.isArray(claimsObject)) {
      this.hasClaims = true;
      this.claims = this.allUserClaims = claimsObject;
    }

    let selectedClaimIndex = -1;
    if (index != -1) {
      selectedClaimIndex = this.allUserClaims.findIndex(
        (claimObj) => claimObj.clmUId === claimsObject.clmUId
      );
    }

    if (
      claimsObject.isExpanded &&
      this.inMemClaimDetailCache[selectedClaimIndex] !== true
    ) {
      this.claimsDetailServiceError[selectedClaimIndex] = false;
      this.claimsDetailLoadingSpinner = true;
      this.index = index;
      this.claimsObject = claimsObject;

      this.loadClaimsDetailWidget(claimsObject.classCode, selectedClaimIndex);
    }

    if (
      claimsObject.isExpanded &&
      this.inMemClaimDetailCache[selectedClaimIndex] === true
    ) {
      this.scrollToViewSvc.scrollDivToView("claim-" + index);
    }
  }

  /**
   * @description resetInMemClaimDetailCache() To reset claims detail cache
   * @returns {void}
   */
  resetInMemClaimDetailCache(): void {
    this.inMemClaimDetailCache = {};
  }

  /**
   * @description loadClaimsDetailWidget() This function is responsible to get specific claim details
   * by calling  getClaimsDetail() service
   * @param {string} classCode to get mock detail based on type passed
   * @param {number} selectedClaimIndex
   * @returns {void}
   */
  private loadClaimsDetailWidget(
    classCode: string,
    selectedClaimIndex: number
  ): void {
    this.claimsDetailLoadingSpinner = true;
    this.claimsErrorMsg = null;
    if (this.content.isLocal) {
      this.claimsDetailLoadingSpinner = false;
      let rawClaimDetail;
      rawClaimDetail =  claimDetailAPIData;
      this.allUserClaims = this.claimsList.getDetailedClaimsList(
        rawClaimDetail,
        this.allUserClaims,
        this.content,
        selectedClaimIndex
      );
      // Added timeout to prevent overlay between loading spinner and claims details information
      setTimeout(() => {
        this.inMemClaimDetailCache[selectedClaimIndex] = true;
      }, 50);
    } else {
      this.claimsService.getClaimDetail(this.claimsObject.clmUId).subscribe(
        (result: any) => {
          this.claimsDetailLoadingSpinner = false;
          this.allUserClaims = this.claimsList.getDetailedClaimsList(
            result.body.claims,
            this.allUserClaims,
            this.content,
            selectedClaimIndex
          );

          this.claims[selectedClaimIndex] = this.allUserClaims[
            selectedClaimIndex
          ];
          if (this.allUserClaims !== null && this.allUserClaims.length > 0) {
            this.hasClaims = true;
          }

          // Added timeout to prevent overlay between loading spinner and claims details information
          setTimeout(() => {
            this.inMemClaimDetailCache[this.index] = true;
            // calling scroll to view after the detail is loaded so that it can calculate the detail div width correctly
            this.scrollToViewSvc.scrollDivToView("claim-" + this.index);
          }, 50);
        },
        (error) => {
          this.showMessage("negative", this.content.error.claimServerError);
          this.claimsDetailLoadingSpinner = false;
          this.claimsDetailServiceError[selectedClaimIndex] = true;
        }
      );
    }

    //Analytic tags
    if (window.digitalData?.page?.pageInfo) {
      window.digitalData.page.pageInfo.claimId = this.claimsObject.clmId;
      window.digitalData.page.pageInfo.claimStatus = this.claimsObject.statusName;
      window.digitalData.page.pageInfo.claimType = this.claimsObject.classCode;
      if (typeof _satellite !== "undefined") {
        _satellite.track("URLchanged");
      }
    }
  }

  /**
   * @description Shows a message for error widget
   * @param {string} type
   * @param {string} message
   * @returns {void}
   */
  private showMessage(type: string, message: string): void {
    this.claimsErrorMsg = message;
    this.claimsErrorType = type;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
