<div class="uxd-theme-motif container margin-bottom-48">
    <ng-container *ngIf="loading">
        <uxd-spinner class="updating-loader"></uxd-spinner>
        <div>{{jsonLabels.Loading}}</div>        
    </ng-container>
    <div class="full-width-alert-spacer" *ngIf="aslError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span>{{aslError}}</span>
    </div>
    <div *ngIf="!loading" class="max-width-elements margin-bottom-24 reset-password-container">
        <div class="margin-bottom-20">
            <h1 tabindex="-1" class="font-18 font-bold margin-bottom-8">{{jsonLabels?.Username?.Heading}}</h1>
            <span class="font-16">{{username | titlecase}}</span>
        </div>
        <div class="margin-bottom-48">
            <p class="font-16 display-inline">{{jsonLabels?.Username?.Instructions}}</p>
            <a class="arrowLink font-bold" [attr.aria-label]="jsonLabels?.Username?.Login_Aria_Label" href="#/public/login">{{jsonLabels?.Username?.Login}}</a>
        </div>
        <h5 class="font-18 font-bold margin-bottom-24">{{jsonLabels?.Password?.Heading}}</h5>
        <form class="ng-untouched ng-pristine ng-invalid" [formGroup]="passwordForm" (ngSubmit)="resetPassword()"
            #pwdForm="ngForm">
                <lib-passwords-form formControlName="passwords"
                [pwdLabel]="jsonLabels?.Password.Field_Labels.New_Password"
                [confirmPwdLabel]="jsonLabels?.Password.Field_Labels.ReType_Password"
                [errObj]="jsonErrors"
                [toolTipObj]="jsonLabels?.Password.Helper_Text.Password.Content.PasswordTooltip"
                [formSubmitted]="(pwdForm.submitted)" [userName]="username" #passwordId>
            </lib-passwords-form>
                <div class="flex-horizontal-end margin-bottom-48">
                    <button type="submit" id="btnNext" data-analytics="resetPasswordRecovery" class="btn btn-primary margin-top-12">
                        {{jsonLabels?.Password.Buttons.Reset_Password}}
                    </button>
                </div>
        </form>
    </div>


</div>