import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, Renderer2, HostListener, ViewChildren, QueryList, SecurityContext, OnDestroy } from '@angular/core';
import { ChatbotComponent } from '../chatbot/chatbot.component';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { LiveChatService } from '../services/live-chat.service';
import CONSTANTS from '../utils/constants';
import { Router, NavigationEnd } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import "indexeddb-getall-shim";
import moment from 'moment-timezone';
import { ChatDetail, Restriction, StartChat, TargetChatSource } from 'gbd-models';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatbotService } from 'sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service';
import { ChatbotWatsonComponent } from '../chatbot-watson/chatbot-watson.component';
import { MessageTypeEnum, TypeOfOptionEnum } from '../utils/conversationEnums';

@Component({
  selector: 'app-chat-launcher',
  templateUrl: './chat-launcher.component.html',
  styleUrls: ['./chat-launcher.component.scss']
})
export class ChatLauncherComponent implements OnInit ,OnDestroy {
  @ViewChild('chatView', { static: false }) chatView: any;
  @ViewChild('chatBody') private myScrollContainer: ElementRef;
  @ViewChild('agentCancel') agentCancel: ElementRef;
  @ViewChild('agentDisconnect') agentDisconnect: ElementRef;
  @ViewChild('chatInput') chatInput: ElementRef;
  @ViewChild(ChatbotComponent) chatbot: ChatbotComponent;
  @ViewChild(ChatbotWatsonComponent) watsonchatbot: ChatbotWatsonComponent;
  @Input() unreadMessages: string;
  @Input() title: string;
  @Input() showChatbotBool: boolean;
  @Output() openChatBool = new EventEmitter<boolean>();
  @Output() outputActiveLauncher = new EventEmitter<HTMLElement>();
  @ViewChild('launcherButton', { static: false }) launcherButton: ElementRef;
  @ViewChildren('surveyQn') surveyQns: QueryList<ElementRef>;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.viewportWidth = window.innerWidth;
  }
  activeChatLauncher: HTMLElement;

  userScrolled: boolean = false;
  viewportWidth: any;
  content: any;
  labels: any;
  membername: object;
  //session expiry
  timer: number = CONSTANTS.CHAT_TIMER_END;
  alertTimer: number = CONSTANTS.CHAT_TIMER_ALERT;
  refreshInterval: number = CONSTANTS.CHAT_REFRESH_INTERVAL;
  countDownTimer: number = 0;
  setInactive: boolean = false;
  timerInterval: any = null;
  conversationStates = CONSTANTS.CONVERSATION_STATES;
  //interval
  interval: any = null;
  public isChatInitiated: boolean = false;
  public isTopicSelected: boolean = false;
  public isOptionSelected: boolean = false;
  public isLiveAgent: boolean = false;
  public isDisconnectCalled: boolean = false;
  public isRefreshCalled: boolean = false;
  public showConnectCancel: boolean = false;
  public showCancelConfirm: boolean = false;
  public showEndConfirm: boolean = false;
  public showSessionConfirm: boolean = false;
  public chatResponse: any = null;
  public nextPosition: any = null;
  public showError: boolean = false;
  public errorCode: string;
  public errorMessage: string;
  public loadingSpinner: boolean = false;
  public agentName: string = '';
  public surveyName: string = '';
  public locale: string = 'en-US';
  public chatBotBottomBoundary: number = 0;
  public chatToastBottomBoundary: number = 0;
  public showChatToast: boolean = false;
  public isChatToastDismissed: boolean = false;
  public toastMessage: string;
  public footerPosition: number;
  public errorCount: number= 0;
  //Messages
  public userText: string = '';
  public messages: any = [];
  public surveyQuestions: any = [];
  public isRestoreSession: boolean = false;
  public isInitialLoad: boolean = true;
  public hasAgentLeft: boolean = false;
  public hasChatClosed: boolean = false;
  public isReplyAllowed: boolean = true;
  public refreshCategories: boolean = true;
  public hasSurveyStarted: boolean = false;
  public isSurveyQuestionLoading: boolean = false;
  public watsonChatbotEnabled: boolean =false;
  public liveChatWithSelectedTopic;
  public isCloseLiveChatInvoked= false;
  public isLiveChatStarted = false;
  public loaderSpinnerMessage = '';
  public languageToggle: any = {
    optionText: "Spanish",
    id: "language",
    trueValue: 'es-US',
    labelText: "",
    falseValue: 'en-US',
    legendSrOnly: false,
    isSelected: true,
    textAlignRight: true
  };

  private _dom: HTMLElement;
  isCareCoordinatorChat: boolean = false;

  constructor(
    private jsonSvc: JsonContentService,
    private liveChatSvc: LiveChatService,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private dbService: NgxIndexedDBService,
    private sanitizer: DomSanitizer,
    private chatBotSvc: ChatbotService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => { this.positionChatIcon() }, 1000);
      }
    });
    this.getScreenSize();
  }

  ngOnInit(): void {
    this.showError =false;
    this.isLiveChatStarted = false;
    this.watsonChatbotEnabled = !this.jsonSvc.hasRestriction(Restriction.SHM_WATSON_CHATBOT, this.jsonSvc.getRestrictions());
    this.liveChatWithSelectedTopic = this.liveChatSvc.liveChatwithSeletcedTopic;
    this.jsonSvc.getJSON('chat', this.locale).subscribe(data => {
      this.content = data.chat;
      this.labels = this.content.labels;
      this.loaderSpinnerMessage = this.labels?.chatConnectingToLiveChat;
      this.languageToggle.optionText = this.labels.chatSpanish;
      this.updateChatHeaderTitle(this.labels.chatHeaderTitle);
      this.toastMessage = this.content.chatbot.toastMessage;
      this._dom.querySelector('.chat-launch-point').setAttribute('aria-label', this.labels.chatbotIconLabel);
      this._dom.querySelector('.chat-launch-point').setAttribute('data-analytics', CONSTANTS.CHATBOT_ANALYTICS.sydneyicon);
      this._dom.querySelector('.ant-collapse-chat-window').setAttribute('data-analytics', CONSTANTS.CHATBOT_ANALYTICS.chatbotminimize);
    });
    this._dom = this.elementRef.nativeElement;
    this.unreadMessages = sessionStorage.getItem('chatUnreadMessages') ? sessionStorage.getItem('chatUnreadMessages') : '';
    this.showChatbotBool = true;
    if (sessionStorage.getItem('activeChatSession') !== 'true') {
      this.clearSessionRestore();
    }
    if (sessionStorage.getItem('chatExpanded') === 'true') {
      setTimeout(() => { this.chatView?.openChatWindow(true); }, 1000);
    }
    if (!sessionStorage.getItem('chatExpanded') && this.unreadMessages === '') {
      this.showChatToast = true;
    }
    setTimeout(() => {
      if (!this.isChatToastDismissed) {
        this.showChatToast = false;
        this.unreadMessages = '1';
        sessionStorage.setItem('chatUnreadMessages', this.unreadMessages);
      }
    }, 15000);
    this.footerPosition = this.findFooterHeight();
    let chatTopic;

    this.chatBotSvc?.getChatOption()?.subscribe(data => {
      chatTopic = data;
      if (chatTopic && !this.isLiveChatStarted) {
        setTimeout(() => {
          if (this.watsonChatbotEnabled) {
            this.initiateDirectLiveChat(chatTopic);
          } else {
            if (chatTopic?.topic !== 'livechat') {
              this.initiateChat(chatTopic);
              this.liveChatWithSelectedTopic = this.liveChatSvc.liveChatwithSeletcedTopic;
            }
          }
        }, 300);
      }
    }, error => {
      this.showError = true;
    });

 
    this.isCareCoordinatorChat = !this.jsonSvc.hasRestriction(
      Restriction.SHM_CARE_COORDINATOR_CHAT,
      this.jsonSvc.getRestrictions()
    ) || false;
    
  }

  disableWatsonChatBot(status: boolean) {
    this.watsonChatbotEnabled = status;
  }

  ngAfterViewChecked() {
    if (!this.userScrolled && !this.isInitialLoad)
      this.scrollToBottom();
    if (this.unreadMessages !== '' && this.chatView.isOpen && !this.userScrolled)
      setTimeout(() => {
        this.unreadMessages = '';
        sessionStorage.setItem('chatUnreadMessages', '');
      }, 300);
    if (this.chatView.isOpen && (!sessionStorage.getItem('chatExpanded') || sessionStorage.getItem('chatExpanded') === 'false'))
      sessionStorage.setItem('chatExpanded', 'true');
    if (!this.chatView.isOpen && sessionStorage.getItem('chatExpanded') === 'true')
      sessionStorage.setItem('chatExpanded', 'false');
    if (this.chatView.isOpen && this.showChatToast)
      setTimeout(() => {
        this.showChatToast = false;
        this.isChatToastDismissed = true;
      }, 300);
    let currentFooterPosition = document.getElementsByClassName('uxd-footer')[0].getBoundingClientRect().top;
    if (currentFooterPosition !== this.footerPosition) {
      this.footerPosition = currentFooterPosition;
      setTimeout(() => { this.positionChatIcon(); }, 300);
    }
    if (this.watsonchatbot && this.watsonChatbotEnabled && sessionStorage?.getItem("activeChatSession") === 'true') {
      this.updateChatHeaderTitle(this.isReplyAllowed ? this.getChatTitle() :this.labels?.chatConnecting);
    }
  }

  /** @description Adds handling for scrolling while the chatbot is active.
   * The intent is to always display the chatbot outside of the page footer.
   */
  @HostListener('window:scroll', [])
  onScroll() {
    this.positionChatIcon();
  }

  /**
   * @description Contains the logic for positioning the chatbot outside of the footer,
   * used in the scrolling HostListener.
   */
  positionChatIcon() {
    // get page footer element
    let pageFooter: any = document.getElementsByClassName('uxd-footer')[0];
    if (!pageFooter) {
      return;
    }
    // get viewport height
    let viewportHeight = window.innerHeight;
    // get footer offset from viewport top
    let footerDocumentPosition = pageFooter.getBoundingClientRect().top;
    // position of footer in viewport
    if (footerDocumentPosition < viewportHeight) {
      this.chatBotBottomBoundary = viewportHeight - footerDocumentPosition + 24;
      this.chatToastBottomBoundary = viewportHeight - footerDocumentPosition + 120;
    } else {
      this.chatBotBottomBoundary = 0;
      this.chatToastBottomBoundary = 0;
    }
  }
  findFooterHeight() {
    let pageFooter: any = document.getElementsByClassName('uxd-footer')[0];
    if (!pageFooter) {
      return 0;
    }
    return pageFooter.getBoundingClientRect().top;
  }

  public dismissToast() {
    this.unreadMessages = '';
    this.isChatToastDismissed = true;
  }

  openChat() {
    this.activeChatLauncher = this.launcherButton ? this.launcherButton.nativeElement : null;
    this.openChatBool.emit(true);
    this.outputActiveLauncher.emit(this.activeChatLauncher);
  }
  public onChatBoxScroll(event) {
    if (event && (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)) {
      this.userScrolled = false;
    } else {
      this.userScrolled = true;
    }
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  scrollToTop(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = 0;
    } catch (err) { }
  }

  public fetchLocaleContent(): void {
    this.jsonSvc.getJSON('chat', this.locale, true).subscribe(data => {
      this.content = data.chat;
      this.labels = this.content.labels;
    });
  }

  public chatbotRedirect(option) {
    this.isOptionSelected = true;
    this.isInitialLoad = false;
    if (this.viewportWidth <= 480 && option.url) {
      this.chatView.closeChatWindow(false);
      /* this.toastMessage = this.content.chatbot.youAreNowOn.replace('{page}', option.name) + ' ' + this.content.chatbot.anythingElse;
      setTimeout(() => {this.showChatToast = true;}, 1000);
      setTimeout(() => {this.showChatToast = false;}, 10000); */
    }
    if (!option.url) {
      this.isLiveAgent = true;
      this.isReplyAllowed = false;
      this.locale = option.name === this.content.chatbot.chatLiveAgentES ? 'es-US' : 'en-US';
      if (this.jsonSvc.locale !== this.locale)
        this.fetchLocaleContent();
    }
  }
  public chatbotBubbleClicked(message) {
    this.isInitialLoad = false;
  }
  public chatbotFocusInput() {
    this.chatInput.nativeElement.focus();
  }

  public initiateLiveChatFromWatsonChatbot(livechatMetadata) {
    this.isCloseLiveChatInvoked = false ;
    this.isInitialLoad = false;
    this.userScrolled = false;
    this.nextPosition = livechatMetadata.nextPosition ?? 0;
    this.isChatInitiated = this.liveChatSvc.liveChatwithSeletcedTopic || livechatMetadata.conversationTopic ;
    const ERR_CODE = '0000';
    if (this.isChatInitiated) {
      this.isTopicSelected = true;
      this.refreshCategories = false;
      this.updateChatHeaderTitle(this.labels?.chatConnecting);
      // sets the viewport height
      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
      this.chatResponse = { conversationMetadata: livechatMetadata };
      this.watsonchatbot?.chatMessageList?.msg?.forEach(message => {
        if (message.text) {
          this.messages.push({
            text: message.text,
            isAgent: message.msgSubType !== MessageTypeEnum.USER
          });
        }
      });
      this.loadingSpinner = false;
      this.showError = false;
      this.showConnectCancel = true;
      this.loadingSpinner = false;
      this.callRefreshChat();
    } 
  }

  public initiateDirectLiveChat(category) {
    if (!this.isChatInitiated && this.watsonChatbotEnabled) {
      this.userScrolled = false ;
      const topic = category?.topic !== 'livechat' ? category?.topic : '';
      this.watsonchatbot.sendChat('Live Chat', 'Livechat', topic);
      this.chatbotBubbleClicked('clicked');
    } 
  }


  public initiateChat(category) {
    if (this.watsonChatbotEnabled) {
      return;
    }
    this.isInitialLoad = false;
    this.userScrolled = false;
    this.isChatInitiated = this.liveChatSvc.liveChatwithSeletcedTopic;
    const ERR_CODE = '0000';
    if (this.isChatInitiated && !this.isLiveChatStarted && !this.loadingSpinner) {
      this.loadingSpinner = true;
      this.isTopicSelected = true;
      this.refreshCategories = false;
      this.updateChatHeaderTitle(this.labels.chatConnecting);
      // sets the viewport height
      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });

      let requestObj: StartChat = {
        targetedSrc: TargetChatSource.LIVE_AGENT,
        liveChatInfo: {
          topic: category.topic ?? '',
          channelProvider: (category.channelProvider || '')
        }
      };
      this.liveChatSvc.startChat(requestObj, this.locale).subscribe(data => {
        this.showError = false;
        sessionStorage.setItem("agentConnecting", 'true');
        sessionStorage.setItem("activeChatSession", 'true');
        sessionStorage.setItem("conversationState", 'open');
        sessionStorage.setItem("selectedChatTopic", category.topic);
        this.isLiveChatStarted = true;
        this.showConnectCancel = true;
        this.chatResponse = data.body;

        this.loadingSpinner = false;
        this.callRefreshChat();
      }, error => {
        this.isLiveChatStarted = false;
        this.liveChatSvc.hasTopicsDisabled = false;
        this.isTopicSelected = false;
        this.loadingSpinner = false;
        this.updateChatHeaderTitle(this.labels.chatHeaderTitle);
        this.showError = true;
        this.errorCode = error.error ? error.error.code : ERR_CODE;
        this.errorMessage = this.content.errorMessages.chatConnectionError;
      });
    }
  }

  public callRefreshChat() {
    this.interval = setInterval(() => {
      if (this.isChatInitiated && !this.isDisconnectCalled && !this.isRefreshCalled) {
        this.refreshChat(this.nextPosition ? this.nextPosition : 0)
      }
    }, this.refreshInterval)

    this.continueTheChat();
  }

  continueTheChat() {
    this.resetTheTimer();
    this.startTheTimer();
  }

  public refreshChat(nextPosition: string) {
    let requestObj = {
      nextPosition: nextPosition,
      isLiveChatConversation: false
    }
    if (this.watsonChatbotEnabled) {
      requestObj = { ...requestObj, isLiveChatConversation: true }
    }
    this.isRefreshCalled = true;
    this.liveChatSvc.refreshChat(requestObj).subscribe(data => {
      this.errorCount = 0;
      this.isRefreshCalled = false;
      this.chatResponse = data.body;
      this.nextPosition = this.chatResponse.conversationMetadata.nextPosition;
      const conversationTopic = this.chatResponse.conversationMetadata?.conversationTopic;
      sessionStorage.setItem('nextPosition', this.nextPosition);
      if(conversationTopic && !sessionStorage.getItem("selectedChatTopic")){
        sessionStorage.setItem("selectedChatTopic", conversationTopic);
      }
      this.processConversationState(this.chatResponse);
    },
      error => {
        this.isRefreshCalled = false;
        this.errorCount++;
        if (this.watsonChatbotEnabled && this.errorCount > 6) {
          this.loadingSpinner = false;
          this.messages.push({
            text: this.content.chatbot?.defaultErrorMessage,
            isAgent: true
          });
          if (this.watsonChatbotEnabled) {
            this.watsonchatbot?.chatMessageList?.msg?.push({ text: this.content.chatbot?.defaultErrorMessage, typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, msgSubType: MessageTypeEnum.AGENT });
          }
          this.closeChatApi();
          this.resetTheTimer();
          this.resetTheView();
        }
      }
    );
  }

  public processConversationState(chatResponse: any) {
    if (chatResponse && chatResponse.conversationMetadata) {
      switch (chatResponse.conversationMetadata.conversationState) {
        case this.conversationStates.open:
          this.isReplyAllowed = false;
          if (this.watsonchatbot && this.watsonChatbotEnabled) {
            this.watsonchatbot.liveAgentChatStrated = true;
          }
          break;
        case this.conversationStates.agent_joined:
          this.showConnectCancel = false;
          this.showCancelConfirm = false;
          if (this.watsonchatbot && this.watsonChatbotEnabled) {
            this.watsonchatbot.liveAgentChatStrated = true;
          }
          if (!this.agentName || this.agentName !== this.chatResponse.conversationMetadata.agentName) {
            this.agentName = this.chatResponse.conversationMetadata.agentName;
            this.isReplyAllowed = true;
            this.updateChatHeaderTitle(this.getChatTitle());
            this.resetChatHeaderIcon();
            this.updateChatHeaderIcon(this.agentName);
            sessionStorage.setItem("agentConnecting", 'false');
            sessionStorage.setItem("agentName", this.agentName);
            sessionStorage.setItem('conversationState', 'agent_joined');
            if (this.locale === 'es-US') {
              this.sendSpanishChatNote();
            }
            setTimeout(() => { this.chatInput?.nativeElement?.focus(); }, 300);
          }
          if (this.chatResponse.uiBlocks?.length > 0) {
            this.chatResponse.uiBlocks.forEach(uiblock => {
              this.messages.push({
                text: uiblock.text.text,
                isAgent: true
              });
              this.userScrolled = false;
              this.dbService.add('messages', { message: uiblock.text.text, source: 'agent', timeStamp: moment().unix(), msgIndex: uiblock.index }).subscribe(
                () => { },
                error => { }
              );
              if (this.watsonChatbotEnabled) {
                this.watsonchatbot.chatMessageList?.msg?.push({ text: uiblock.text.text, typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, msgSubType: MessageTypeEnum.AGENT });
              }
              if (!this.chatView.isOpen) {
                this.unreadMessages = (this.unreadMessages === '9+' || this.unreadMessages === '9') ? '9+' : String(Number(this.unreadMessages) + 1);
                sessionStorage.setItem('chatUnreadMessages', this.unreadMessages);
              }
            })
          }
          break;
        case this.conversationStates.close || this.conversationStates.survey_close:
          sessionStorage.setItem('conversationState', 'close');
          sessionStorage.setItem('activeChatSession', 'false');
          if (sessionStorage.getItem('selectedChatTopic') === CONSTANTS.CHAT_WITH_COORDINATOR) {
            this.watsonchatbot.chatMessageList?.msg?.push({ text: this.labels?.careCoordinatorChatClose, 
            typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, 
            msgSubType: MessageTypeEnum.AGENT });
            this.liveChatSvc.refreshChatMenu(false);
          }
          sessionStorage.setItem('selectedChatTopic', '');
          sessionStorage.setItem("isLiveChatConversation", "false"); 
          this.hasChatClosed = true;
          this.isLiveChatStarted = false;
          clearInterval(this.timerInterval);
          clearInterval(this.interval);
          if (this.watsonChatbotEnabled && !this.isCloseLiveChatInvoked) {
            this.resetTheView();
            this.clearSessionRestore();
            this.watsonchatbot.sendChat(this.labels?.chatDisConnectingToLiveChat, this.labels?.chatDisConnectingToLiveChatTag);
            this.isCloseLiveChatInvoked = true;
            this.isReplyAllowed = true;
          } else {
            this.isReplyAllowed = false;
          }
          this.hasSurveyStarted = false;
          this.isChatInitiated =false;
          break;
        case this.conversationStates.survey_start:
          if (!this.hasSurveyStarted) {
            this.resetChatHeaderIcon();
            this.updateChatHeaderIcon();
            this.updateChatHeaderTitle(this.labels.chatSurveyHeaderMessage);
            this.userText = '';
            setTimeout(() => { this.surveyQns?.last?.nativeElement?.focus() }, 3000);
          }
          this.hasSurveyStarted = true;
          sessionStorage.setItem('hasSurveyStarted', 'true');
          this.hasAgentLeft = true;
          this.populateSurveyMessage();
          break;
        case this.conversationStates.agent_left:
          this.hasAgentLeft = true;
          break;
        default:
      }
    }
  }
  public populateSurveyMessage() {
    this.surveyName ='Survey';
    sessionStorage.setItem("surveyName", this.surveyName);
    for (let i = 0; i < this.chatResponse.uiBlocks.length; i++) {
      this.surveyQuestions.push({
        question: this.chatResponse.uiBlocks[i].title ?? this.chatResponse.uiBlocks[i]?.text?.text,
        answers:  this.chatResponse.uiBlocks[i].items ?? [],
        isDisabled: false,
        messageType: this.chatResponse.uiBlocks[i].messageType,
        selected: null,
        isAgent: true
      });
      if (this.watsonChatbotEnabled) {
        this.watsonchatbot.chatMessageList?.msg?.push({ text: this.chatResponse.uiBlocks[i].title ?? this.chatResponse.uiBlocks[i]?.text?.text, typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, msgSubType: MessageTypeEnum.SURVEY });
        this.isReplyAllowed = true;
      } else {
        this.isReplyAllowed = !(this.chatResponse.uiBlocks[i].messageType === "SURVEY" && this.chatResponse.uiBlocks[i]?.items?.length > 0);
        if (this.isReplyAllowed) {
          setTimeout(() => { this.chatInput?.nativeElement?.focus(); }, 300);
        }
      }
      if (!this.chatView.isOpen) {
        this.unreadMessages = (this.unreadMessages === '9+' || this.unreadMessages === '9') ? '9+' : String(Number(this.unreadMessages) + 1);
        sessionStorage.setItem('chatUnreadMessages', this.unreadMessages);
      }
    }

    if (this.chatResponse.uiBlocks?.length > 0) {
      this.isSurveyQuestionLoading = false;
      if (!this.isReplyAllowed) {
        setTimeout(() => { this.surveyQns?.last?.nativeElement?.focus() }, 1000);
      }
    }
  }

  public handleSurveyAnswer(repliedAnswerObject, surveyObj) {
    surveyObj.isDisabled = true;
    this.surveyQuestions.push({
      question: repliedAnswerObject.text,
      answers: [],
      isAgent: false
    });
    this.sendChat(repliedAnswerObject);
  }
  goBackToCategories() {
    this.agentName = '';
    this.surveyName ='';
    this.locale = 'en-US',
      this.fetchLocaleContent();
    this.resetTheTimer();
    this.resetTheView();
    this.clearSessionRestore();
    this.liveChatSvc.liveChatwithSeletcedTopic = true;
    this.liveChatSvc.hasTopicsDisabled = false;
  }

  /* TODO modify the member name logic based on the final member context implementation */
  getMemberName(): string {
    if (sessionStorage.sydMedMemberContext) {
      let memberContext = JSON.parse(sessionStorage.sydMedMemberContext);
      return memberContext.memberName.firstName;
    } else {
      return '';
    }
  }
  resetTheView() {
    this.isInitialLoad = true;
    sessionStorage.setItem("activeChatSession", 'false');
    this.resetFlags();
    this.updateChatHeaderTitle(this.labels.chatHeaderTitle);
    this.resetChatHeaderIcon();
    this.chatResponse = null;
    this.surveyQuestions = [];
    clearInterval(this.interval);
    clearInterval(this.timerInterval);
    this.agentName = null;
    this.surveyName = null;
    this.refreshCategories = true;
    if (!this.watsonChatbotEnabled) {
      this.scrollToTop();
    } 
    this.userText = '';
    this.messages = [];
    setTimeout(() => { this.chatInput?.nativeElement?.focus(); }, 300);
  }

  resetFlags() {
    this.hasAgentLeft = false;
    this.isTopicSelected = false;
    this.isChatInitiated = false;
    this.showConnectCancel = false;
    this.hasSurveyStarted = false;
    this.hasChatClosed = false;
    this.showError = false;
    this.userScrolled = false;
    this.showCancelConfirm = false;
    this.showSessionConfirm = false;
    this.showEndConfirm = false;
    this.isDisconnectCalled = false;
    this.isRefreshCalled = false;
    this.isReplyAllowed = true;
    this.isRestoreSession = false;
    this.isLiveAgent = false;
    this.isOptionSelected = false;
    this.liveChatWithSelectedTopic = true;
    if (this.watsonChatbotEnabled) {
      this.watsonchatbot.liveAgentChatStrated =false;
      this.watsonchatbot.liveChatRequestedWithTopic =false;
    }
  }
  public sendChat(repliedAnswerObject?) {
    const userText = this.userText.trim();
    this.userText = '';
    if (!this.isChatInitiated && this.watsonChatbotEnabled) {
      this.watsonchatbot.sendChat(userText);
    }
    else if (!this.isChatInitiated && !this.watsonChatbotEnabled && (userText && /\S/.test(userText))) {
      this.isInitialLoad = false;
      this.isLiveChatStarted = false;
      this.chatbot.chatbotRedirectUserInput(userText);
      return;
    }
    else if (this.isChatInitiated && (userText && /\S/.test(userText)) || repliedAnswerObject) {
      let requestObj = {
        messages: [
          {
            text: repliedAnswerObject ? repliedAnswerObject.text : userText,
            value: repliedAnswerObject ? repliedAnswerObject.text : userText,
            metadata: repliedAnswerObject ? {
              type: "SURVEY",
              data: repliedAnswerObject.metadata
            } : null
          }
        ],
        isLiveChatConversation: false
      }
      if (this.watsonChatbotEnabled) {
        requestObj = { ...requestObj, isLiveChatConversation: true }
        this.watsonchatbot.chatMessageList?.msg?.push({ text: requestObj?.messages?.[0]?.text, typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, msgSubType: MessageTypeEnum.USER });
      }

      this.liveChatSvc.sendChat(requestObj).subscribe(data => {
        this.chatResponse = data.body;
        if (!this.hasSurveyStarted) {
          this.messages.push({
            text: userText,
            isAgent: false
          });
          this.dbService.add('messages', {
            message: userText,
            source: 'user',
            timeStamp: moment().unix(),
            msgIndex: 0
          }).subscribe(() => { },
            error => { }
          );
        } else {
            this.surveyQuestions.push({
              question: userText,
              answers: '',
              userAnswer:requestObj?.messages?.[0]?.text,
              isDisabled: false,
              messageType:  MessageTypeEnum.USER,
              selected: null,
              isAgent: false
            })
          this.isSurveyQuestionLoading = true;
        }
      }, error => {
        this.showError = true;
        this.errorCode = error.error.exceptions ? error.error.exceptions[0].code : '';
      });
      this.continueTheChat();
    }

  }
  startTheTimer() {
    this.timerInterval = setInterval(() => {
      ++this.countDownTimer;
      const topic = sessionStorage.getItem("selectedChatTopic");

      // if the topic is "Chat with a Care Coordinator", we don't need to show session expire popup.
      if (this.countDownTimer === this.alertTimer && topic !== CONSTANTS.CHAT_WITH_COORDINATOR) {
        this.userScrolled = false;
        this.showSessionConfirm = true;
      }
      
      // if the topic is "Chat with a Care Coordinator", we need to skip the disconnect call from UI.
      if (this.countDownTimer >= this.timer && topic !== CONSTANTS.CHAT_WITH_COORDINATOR) {
        this.closeChatApi();
        this.resetTheTimer();
        this.resetTheView();
      }
    }, 1000)
  }
  resetTheTimer() {
    clearInterval(this.timerInterval)
    this.showSessionConfirm = false;
    this.countDownTimer = 0;
  }

  public disconnectChat() {
    this.showEndConfirm = true;
    this.userScrolled = false;
    setTimeout(() => { this.agentDisconnect?.nativeElement?.focus() }, 300);
  }
  public confirmEnd() {
    this.showEndConfirm = false;
    this.closeChatApi();
    sessionStorage.setItem("isLiveChatConversation", "false");
    this.liveChatSvc.refreshChatMenu(false);
  }

  public dismissEnd() {
    this.showEndConfirm = false;
  }
  public cancelConnect() {
    this.showCancelConfirm = true;
    this.userScrolled = false;
    setTimeout(() => { this.agentCancel?.nativeElement?.focus() }, 300);
  }

  public confirmCancel() {
    this.showCancelConfirm = false;
    this.closeChatApi();
  }

  public dismissCancel() {
    this.showCancelConfirm = false;
    this.showConnectCancel = true;
  }
  continueWithTheChat() {
    this.continueTheChat();
  }

  endTheChat() {
    this.closeChatApi();
    this.resetTheTimer();
    this.resetTheView();

  }
  
  public closeChatApi() {
    this.isLiveChatStarted = false;
    this.isDisconnectCalled = true;
    this.isLiveAgent = false;
    this.loadingSpinner = true;
    this.loaderSpinnerMessage = this.labels?.chatDisConnectingToLiveChatSpinnerMessage;
    this.liveChatSvc.closeChat().subscribe(data => {
      this.loaderSpinnerMessage = this.labels?.chatConnectingToLiveChat;
      this.liveChatSvc.liveChatwithSeletcedTopic = true;
      this.liveChatSvc.hasTopicsDisabled = false;
      this.isChatInitiated = false;
      this.resetTheView();
      this.clearSessionRestore();
      this.loadingSpinner = false;
      if (this.watsonChatbotEnabled && !this.isCloseLiveChatInvoked) {
        this.watsonchatbot.liveAgentChatStrated =false;
        this.watsonchatbot.liveChatRequestedWithTopic =false;
        this.liveChatSvc.liveChatwithSeletcedTopic = true;
        this.liveChatSvc.hasTopicsDisabled = false;
        this.isCloseLiveChatInvoked = true;
        this.watsonchatbot.sendChat(this.labels?.chatDisConnectingToLiveChat, this.labels?.chatDisConnectingToLiveChatTag);
        sessionStorage.setItem("isLiveChatConversation", "false"); 
        this.updateChatHeaderTitle(this.labels.chatHeaderTitle);
      }
    }, error => {
      this.loadingSpinner = false;
      this.loaderSpinnerMessage = this.labels?.chatConnectingToLiveChat;
      this.isChatInitiated = false;
      if (this.watsonChatbotEnabled) {
        this.liveChatSvc.liveChatwithSeletcedTopic = true;
        this.liveChatSvc.hasTopicsDisabled = false;
        this.watsonchatbot.liveAgentChatStrated =false;
        this.watsonchatbot.liveChatRequestedWithTopic =false;
        this.resetTheView();
        this.clearSessionRestore();
        if (!this.isCloseLiveChatInvoked) {
          this.isCloseLiveChatInvoked = true;
          sessionStorage.setItem("isLiveChatConversation", "false"); 
          this.watsonchatbot.sendChat(this.labels?.chatDisConnectingToLiveChat, this.labels?.chatDisConnectingToLiveChatTag, "", true);
          this.updateChatHeaderTitle(this.labels.chatHeaderTitle);
        }
      }
    });
  }

  handleChatRestore() {
    if (sessionStorage?.getItem("activeChatSession") === 'true') {
      this.isRestoreSession = true;
      this.isLiveAgent = true;
      if (sessionStorage.getItem('selectedChatTopic')) {
        this.isTopicSelected = true;
      }

      if (sessionStorage.getItem('hasSurveyStarted') === 'true') {
        this.isRestoreSession = false;
      }
      this.nextPosition = sessionStorage.getItem('nextPosition');
      this.unreadMessages = sessionStorage.getItem('chatUnreadMessages');
      let conversationState: string = '';
      switch (sessionStorage.getItem('conversationState')) {
        case 'open':
          conversationState = this.conversationStates.open;
          this.updateChatHeaderTitle(this.labels?.chatConnecting || 'Connecting...');
          this.showConnectCancel = true;
          break;
        case 'agent_joined':
          conversationState = this.conversationStates.agent_joined;
          this.updateChatHeaderTitle( this.getChatTitle() || this.labels.chatMemberServicesAgent);
          this.updateChatHeaderIcon();
          this.isReplyAllowed = true;
          break;
        case 'agent_left':
          conversationState = this.conversationStates.agent_left;
          this.hasAgentLeft = true;
          break;
        case 'close':
          conversationState = this.conversationStates.close;
          this.isChatInitiated = false;
          this.hasSurveyStarted = false;
          this.isRestoreSession = false;
          this.hasChatClosed = false;
          this.agentName = null;
          this.surveyName = null;
          break;
        default:
          conversationState = this.conversationStates.open;
      }
      this.chatResponse = {
        "conversationMetadata": {
          "channelType": "ASYNC",
          "transcriptFormat": "BLOCKKIT",
          "targetedSrc": "LIVEAGENT",
          "channelProvider": "LP",
          "conversationState": conversationState,
          "conversationTopic": sessionStorage.getItem('selectedChatTopic'),
          "nextPosition": this.nextPosition
        },
        "uiBlocks": []
      };
      if (this.isRestoreSession) {
        this.isLiveChatStarted = true;
        this.isChatInitiated = true;
        this.agentName = sessionStorage.getItem('agentName');
        this.surveyName = sessionStorage.getItem('surveyName');
        this.isInitialLoad = false;
        this.dbService.getAll('messages').subscribe(
          messages => {
            if (messages) {
              for (let i = 0; i < messages.length; i++) {
                let item: any = messages[i];
                this.messages.push({
                  text: item.message,
                  isAgent: item.source === 'agent'
                });
                if (this.watsonChatbotEnabled) {
                  if (item.source === 'agent') {
                    this.watsonchatbot.chatMessageList?.msg?.push({ text: item.message, typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, msgSubType: MessageTypeEnum.AGENT });
                  } else {
                    this.watsonchatbot.chatMessageList?.msg?.push({ text: item.message, typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, msgSubType: MessageTypeEnum.USER });
                  }
                }
              }
            }
          },
          error => { }
        );
      } else {
        this.closeChatApi();
      }
      if (this.isChatInitiated && this.isRestoreSession) {
        if (this.watsonChatbotEnabled) {
          this.initiateLiveChatFromWatsonChatbot(this.chatResponse?.conversationMetadata);
        } else {
          this.callRefreshChat();
        }
      }
    }
  }

  /**
   * Get the chat title based on the chat type
   * @returns 
   */
  getChatTitle(): string {
    const topic = sessionStorage.getItem('selectedChatTopic');
    return (this.isCareCoordinatorChat && topic === CONSTANTS.CHAT_WITH_COORDINATOR )  ? this.labels.careCoordinator : this.labels.chatMemberServicesAgent;
  }

  /**
   * Show the session expire confirmation
   * @returns 
   */
  shouldShowSessionConfirmation(): boolean {
    const topic = sessionStorage.getItem('selectedChatTopic');
    return this.showSessionConfirm && topic !== CONSTANTS.CHAT_WITH_COORDINATOR;
  }

  clearSessionRestore() {
    sessionStorage.removeItem('selectedChatTopic');
    sessionStorage.removeItem('agentName');
    sessionStorage.removeItem('surveyName');
    sessionStorage.removeItem('hasSurveyStarted');
    sessionStorage.removeItem('agentConnecting');
    sessionStorage.removeItem('conversationState');
    sessionStorage.removeItem('nextPosition');
    sessionStorage.removeItem('chatLanguage');
    sessionStorage.removeItem('chatUnreadMessages');
    sessionStorage.removeItem('activeChatSession');
    this.dbService.clear('messages').subscribe(() => { },
      error => { }
    );
  }
  public updateChatHeaderIcon(agentName?): void {
    const headerContainer = this._dom.querySelector('.ant-message-center-header');
    const logoDiv = this.renderer.createElement('div');
    if (agentName) {
      const agentInitial = agentName[0].toUpperCase();
      logoDiv.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(`<div class="agent-logo-small-wrapper">
                            <div class="agent-logo-small">
                              <div class="agent-logo-small-inner">
                                <span class="agent-init-small">${agentInitial}</span>
                              </div>
                            </div>
                          </div>`));
    } else {
      logoDiv.innerHTML = `<div class="agent-logo-small-wrapper survey-header-icon">
                                <span class="motif-icon motif-chat-bubble"></span>
                          </div>`;
    }
    headerContainer.insertBefore(logoDiv, headerContainer.firstChild);
  }
  public resetChatHeaderIcon(): void {
    const headerContainer = this._dom.querySelector('.ant-message-center-header');
    const childElt = this._dom.querySelector('.agent-logo-small-wrapper');
    if (childElt && childElt.parentNode.parentNode === headerContainer)
      headerContainer.removeChild(headerContainer.firstChild);
  }
  public updateChatHeaderTitle(title): void {
    const headerTitle = this._dom.querySelector('.ant-message-center-header-title');
    headerTitle.innerHTML = title;
  }
  public isMiddleBubble(i, messages): boolean {
    if (i !== 0 && (messages[i + 1] && messages[i + 1].isAgent === messages[i].isAgent)
      && (messages[i - 1] && messages[i - 1].isAgent === messages[i].isAgent))
      return true;
  }
  public isLastBubble(i, messages): boolean {
    if ((!messages[i + 1] && (messages[i - 1] && messages[i - 1].isAgent === messages[i].isAgent))
      || (messages[i + 1] && messages[i + 1].isAgent !== messages[i].isAgent
        && (messages[i - 1] && messages[i - 1].isAgent === messages[i].isAgent)))
      return true;
  }
  public isBottomBar(): boolean {
    return (this.showConnectCancel || (this.isChatInitiated && !this.loadingSpinner)
      || (this.isChatInitiated && this.hasChatClosed)) && !this.showError;
  }
  public isFullHeight(): boolean {
    return !this.isChatInitiated || this.loadingSpinner || this.showError;
  }
  public sendSpanishChatNote() {
    const requestObj = {
      messages: [
        {
          text: this.labels.chatSpanishNote,
          value: this.labels.chatSpanishNote,
          metadata: null
        }
      ]
    };
    this.liveChatSvc.sendChat(requestObj).subscribe();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.resetTheTimer();
    this.clearSessionRestore();
    this.isChatInitiated = false;
  }
}
