import { AlertService } from '@anthem/uxd/alert';
import { CheckBox } from './labels';
import { FormGroup } from '@angular/forms';

export class HelperProperties {

  constructor(
      private alertSvc: AlertService,
  ) { }

  scrollToTop(): void {
    window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth"
    });
}

  resetFormWithValues(form: FormGroup) {
      form.reset(form.value);
  }

  successAlert(label: string, regionName: string) {
      this.alertSvc.success(label, {
        regionName: regionName,
        isDissmisable: true
      });
  }

  setCheckboxParams(id: string, name: string, label: string) : CheckBox {
      return {
        id: id,
        name: name,
        label: label,
        trueValue: true,
        falseValue: false
      };
    }
}