import { Component, OnInit } from "@angular/core";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";


/**
 * @description
 * This component is responsible for loading myFhr Page and features
 * This is an entry component
 *
 * @function
 * ngOnInit()
 * 
 *  @example
 * ```html
 * <data-sydmed-my-fhr-container-cmp></data-sydmed-my-fhr-container-cmp>
 * ```
 */

@Component({
  moduleId: module.id,
  selector: "data-sydmed-my-fhr-container-cmp",
  templateUrl: "./myfhr.html",
  styleUrls: ["./myfhr.scss"],
})
export class MyFhrComponent implements OnInit {
  content: any;
  genericError: any;
  constructor(
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'My Family Health Record',
    });
  }

  ngOnInit() {
    this.setContent();
  }

  /**
   * @description setContent() responsible for gettingfetching content from json
   * using content service
   * @returns {void}
   */
  private setContent(): void {
    this.jsonSvc.getJSON('myfhr').subscribe(
      (content: any) => {
        this.content = content;
      },
      (error: any) => {
        this.genericError =
          'We are unable to complete your request at this time. Please try again later or <a href="#/secure/contact-us/call-us">contact us</a> for help.';
      }
    );
  }
}
