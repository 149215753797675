import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Labels, ErrorMsgs } from './models/labels';
import { NavPages } from './models/enum-values';
import { Restrictions } from './models/member-context';
import { CommunicationPreferencesContactInfo } from './models/communication-preferences-contact-info.model';
import { PreferenceContactInfo } from 'gbd-models/src/features/profile/commPrefs';

@Injectable({
  providedIn: 'root'
})
export class MyProfileDataService {

  constructor() { }

  private _labels: Labels;
  private _errorMsgs: ErrorMsgs;
  private _restrictions: Restrictions;
  private _stateLob: string;
  private successMsg: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private navActive: BehaviorSubject<string> = new BehaviorSubject<string>(NavPages.LOGIN_SECURITY);
  private contactInfoApiLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private contactInfoApiErr: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private contactInfoData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private profileAddressData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private apiCallInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private commPrefContactInfoData: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private commPrefData: CommunicationPreferencesContactInfo;
  private commPrefError: any;
  private isCommPrefError: boolean;
  private fetchingCommPrefData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private medicalProfileProgressFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private medicalProfileSetupFlag: boolean;
  private emailAddress: Subject<string> = new Subject<string>();
  private pharmacyEmailAddress: Subject<string> = new Subject<string>();
  private isTermedUserFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // Communication Preferences Setting from node service
  private gbdContactInfo: BehaviorSubject<PreferenceContactInfo> = new BehaviorSubject<PreferenceContactInfo>({});

  public set labels(labels: Labels) {
    this._labels = labels;
  }

  public get labels(): Labels {
    return this._labels;
  }

  public set stateLob(statelob: string) {
    this._stateLob = statelob;
  }

  public get stateLob(): string {
    return this._stateLob;
  }

  public set errorMsgs(errorMsgs: ErrorMsgs) {
    this._errorMsgs = errorMsgs;
  }

  public get errorMsgs(): ErrorMsgs {
    return this._errorMsgs;
  }

  public setRestrictions(restrictions: Restrictions) {
    this._restrictions = restrictions;
  }

  public getRestrictions(): Restrictions {
    return this._restrictions;
  }

  public setSuccessMsg(msg) {
    this.successMsg.next(msg);
  }

  public getSuccessMsg() {
    return this.successMsg;
  }

  public setNavActive(nav) {
    this.navActive.next(nav);
  }

  public getNavActive() {
    return this.navActive;
  }

  public setContactInfoApiLoader(loader: boolean) {
    this.contactInfoApiLoader.next(loader);
  }

  public getContactInfoApiLoader() {
    return this.contactInfoApiLoader;
  }

  public setContactInfoApiErr(err: string) {
    this.contactInfoApiErr.next(err);
  }

  public getContactInfoApiErr() {
    return this.contactInfoApiErr;
  }

  public setContactInfoData(dataObj: any) {
    this.contactInfoData.next(dataObj);
  }

  public getContactInfoData() {
    return this.contactInfoData;
  }

  public setProfileAddressData(dataObj: any){
    this.profileAddressData.next(dataObj);
  }

  public getProfileAddressData() {
    return this.profileAddressData;
  }

  public setApiCallInProgress(flag: boolean) {
    this.apiCallInProgress.next(flag);
  }

  public getApiCallInProgress() {
    return this.apiCallInProgress;
  }

  public setCommPrefContactInfoData(data: any) {
    this.commPrefContactInfoData.next(data);
  }

  public getCommPrefContactInfoData() {
    return this.commPrefContactInfoData;
  }

  public setCommPrefData(data: CommunicationPreferencesContactInfo) {
    this.commPrefData = data;
  }

  public getCommPrefData() {
    return this.commPrefData;
  }

  public getGBDContactInfo() {
    return this.gbdContactInfo;
  }

  public setGBDContactInfo(dataObj: PreferenceContactInfo) {
    this.gbdContactInfo.next(dataObj);
  }

  public setCommPrefError(error) {
    this.commPrefError = error;
  }

  public getCommPrefError() {
    return this.commPrefError;
  }

  public setIsCommPrefError(flag: boolean) {
    this.isCommPrefError = flag;
  }

  public getIsCommPrefError() {
    return this.isCommPrefError;
  }

  public setFetchingCommPrefData(flag: boolean) {
    this.fetchingCommPrefData.next(flag);
  }

  public getFetchingCommPrefData() {
    return this.fetchingCommPrefData;
  }

  public getMedicalProfileProgressFlag() {
    return this.medicalProfileProgressFlag;
  }

  public setMedicalProfileProgressFlag(flag: boolean) {
    this.medicalProfileProgressFlag.next(flag);
  }

  public getMedicalProfileSetupFlag() {
    return this.medicalProfileSetupFlag;
  }

  public setMedicalProfileSetupFlag(flag: boolean) {
    this.medicalProfileSetupFlag = flag;
  }

  public getEmailAddress() {
    return this.emailAddress;
  }

  public setEmailAddress(emailAddress: string) {
    this.emailAddress.next(emailAddress);
  }

  public getPharmacyEmailAddress() {
    return this.pharmacyEmailAddress;
  }

  public setPharmacyEmailAddress(pharmacyEmailAddress: string) {
    this.pharmacyEmailAddress.next(pharmacyEmailAddress);
  }

  public setIsTermedUserFlag(flag: boolean) {
    this.isTermedUserFlag.next(flag);
  }

  public getIsTermedUserFlag() {
    return this.isTermedUserFlag;
  }
}
