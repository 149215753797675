// Angular Imports
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// Sydmed SPA Imports
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';

// Message Center Imports
import { MessageCenterDataService } from './message-center-data.service';

// Motif Imports
import { AlertService, UniversalAlertType , InlineAlertContainerComponent } from '@anthem/uxd/alert';

@Component({
  selector: 'message-center',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class MessageCenterAppComponent implements OnInit, AfterViewInit {
  title = 'message-center';
  @ViewChild('errorAlert') errorAlertRef: InlineAlertContainerComponent;

  constructor(
    private route: ActivatedRoute,
    private dataSvc: MessageCenterDataService,
    public alert: AlertService,
    private interCommunicationService: InterCommunicationService
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Message Center'
    }); 
  }

  private jsonData: any;
  private subscriptions: Subscription[];
  public error: string;
  public showError: boolean;
  public showMenu: boolean;
  public showSortByDropDown: boolean;

  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;

  ngOnInit() {
    const jsonDataKey = 'jsonData';
    this.subscriptions = [];

    this.jsonData = this.route.snapshot.data[jsonDataKey];
    this.subscriptions.push(this.dataSvc.getShowSortByDropdown().subscribe(
      showDropdown => {
        this.showSortByDropDown = showDropdown;
      }));
    if (!this.jsonData) {
      this.error = 'Failed to load JSON Data';
      this.showError = true;
    } else {
      this.showError = false;
      this.showMenu = false;
      this.dataSvc.labels = this.jsonData.MessageCenter.Labels;
      this.dataSvc.errorMsgs = this.jsonData.MessageCenter.ErrorMsgs;
      this.dataSvc.setfolder('inbox');
      if (sessionStorage.sydMedMemberContext) {
        const memberContext = JSON.parse(sessionStorage.sydMedMemberContext);
        this.dataSvc.firstName = memberContext.memberName.firstName;
        this.dataSvc.lastName = memberContext.memberName.lastName;
        this.dataSvc.restrictions = memberContext.restrictions;
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.jsonData) {
      this.alert.error(this.error, {
        regionName: 'inline-alerts',
        politeness: 'assertive',
        isDissmisable: true
      });
    }
  }

  ngOnDestroy() {
    if(this.subscriptions) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }

  public openMenu() {
    this.showMenu = true;
  }

  public closeMenu() {
    this.showMenu = false;
  }

}
