import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chatbot-toast',
  templateUrl: './chatbot-toast.component.html',
  styleUrls: ['./chatbot-toast.component.scss']
})
export class ChatbotToastComponent implements OnInit {

  @Input() showChatToast:boolean;
  @Input() chatToastBottomBoundary:number = 0;  
  @Input() toastMessage: string;
  @Output() chatbotToastDismissed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  public dismissToast() {
    this.showChatToast = false;
    this.chatbotToastDismissed.emit(true);
  }
}
