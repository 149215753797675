import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { ClaimsSummaryComponent } from "../../claims/claimsSummary/claimsSummaryCmp";
import { Restriction } from "gbd-models";
import { ClaimFilterWidgetState, ClaimSummary, ClaimSummaryParam } from "../../claims/interfaces/claims";
import { ClaimsService } from "../../claims/services/claimsSvc";
import { ClaimClassCode } from "../../claims/models/ClaimClassCode";
import { DateUtility } from "../../claims/util/dateUtil";
import { Router } from "@angular/router";
import { ClaimsList } from "../../claims/models/claimsList";

@Component({
  selector: "app-my-claims",
  templateUrl: "./my-claims.component.html",
  styleUrls: ["./my-claims.component.scss"],
})
export class MyClaimsComponent implements OnInit {
  public content: any;
  @ViewChild("claimsSummaryCmp", { static: false })
  claimsSummaryCmp: ClaimsSummaryComponent;
  @Input()
  isMini: boolean = true;
  allClaims: ClaimSummary[];
  claims: ClaimSummary[];
  hasHistoricalClaims = false;
  hasPharmacy = true;
  claimsErrorMsg: string;
  claimSummaryLoader: boolean;
  widgetFilterState: ClaimFilterWidgetState = {
    date: 3,
  };
  constructor(private claimsList: ClaimsList,private jsonContentService: JsonContentService, private claimsService: ClaimsService, public dateUtil: DateUtility, private router: Router) {}

  ngOnInit(): void {
    this.jsonContentService.getJSON("new-dashboard").subscribe((data) => {
      this.content = data?.dashboard?.myHealthAndCare?.myClaims;
      this.widgetFilterState.date = this.content?.mini?.defaultDateRangeMonth ;
      this.hasHistoricalClaims = this.jsonContentService.hasRestriction(Restriction.SHM_HISTORICAL_CLAIMS, this.content?.restrictions);
      this.hasPharmacy = !this.jsonContentService.hasRestriction(Restriction.SHM_NO_PHARMACY, this.content?.restrictions);
    });
    this.getClaims();
  
  }
  goToClaims() {
    this.router.navigateByUrl('/secure/claims');
  }
  private getClaims(filterState?: ClaimFilterWidgetState): void {
    this.claimSummaryLoader = true;
    this.claimsErrorMsg = null;
   
    let params: ClaimSummaryParam = this.buildServiceParams(filterState);
    this.claims = this.allClaims = [];
      this.claimsService.getClaimsSummary(params).subscribe(
        (data: any) => {
          const claimList = this.claimsList?.getClaimsList(
            data?.body?.claims,
            this.content
          );
          if ( this.hasHistoricalClaims ) {
            this.allClaims = claimList ;
          } else if ( !this.hasPharmacy ) {
            this.allClaims = this.removePharmacy(claimList);
          } else {
            this.allClaims = claimList ;
          }
          this.claims = this.allClaims;
          this.claims = this.claims.length > this.content?.mini?.limit ? this.claims.slice(0, this.content?.mini?.limit) : this.claims;
          this.claimSummaryLoader = false;
        },
        (error: any) => {
          if(error.status === 404) {
            this.allClaims = this.claims = [];
            this.claimSummaryLoader = false;
          }else {
            this.claimsErrorMsg = this.content?.labels?.claimServerError;
            this.claimSummaryLoader = false;
          }
        }
      );
  }
  private buildServiceParams(
    filterState?: ClaimFilterWidgetState
  ): ClaimSummaryParam {
    let parameters: ClaimSummaryParam = {
      clmStartDt: '',
      clmEndDt: '',
      sort: '-clmStartDt',
    };
    let startDate: Date = new Date();
    let endDate: Date = new Date();

      startDate.setMonth(
        startDate.getMonth() - this.widgetFilterState.date
      );
      parameters.clmStartDt = this.dateUtil.getDatePart(
        startDate,
        "yyyy-MM-dd"
      );
      this.widgetFilterState.fromDate = this.dateUtil.getDatePart(
        startDate,
        "MM/dd/yyyy"
      );
      parameters.clmEndDt = this.dateUtil.getDatePart(endDate, "yyyy-MM-dd");
      this.widgetFilterState.toDate = this.dateUtil.getDatePart(
        endDate,
        "MM/dd/yyyy"
      );
    
    return parameters;
  }
  removePharmacy( claims: ClaimSummary[] ): ClaimSummary[] {
    return claims.filter((claim: ClaimSummary) => claim.classCode !== ClaimClassCode.PHARMACY);
  }
}
