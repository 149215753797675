import { Restriction } from 'gbd-models';

export interface LeftNavigation {
    LeftRailNavigationLabels: Array<Navigation>;
    sensitiveDrugsLabel?: string;
    drugAlertMessageLabel?: DrugAlertMessageLabel;
}

export interface DrugAlertMessageLabel {
  message: string;
  show?: boolean;
}

export interface Navigation {
    Header: string;
    Menu_Headings: Array<SideNavLink>;
  }
  
  export interface SideNavLink {
    Label: string;
    Url?: string;
    urlsByPlanCode?: any;
    urlsByClassId?: any;
    type: UrlType;
    target?: string;
    id?: string;
    restrictions?: Restriction[];
  }

  export enum UrlType {
      EXTERNAL = 'external',
      GENERIC = "generic"
  }

  export enum LeftNavItem {
    BIO_PLUS_INFORMATION = 'bioPlusInformation',
    BIO_PLUS_REQUEST = 'bioPlusRequest',
    FIND_A_PHARMACY = 'findAPharmacy'
  }