<div class="ctrlHolder errorMessages container" *ngIf="showError">
  <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
      <span [innerHTML]="error"></span>
  </div>
</div>

<div
  id="appointment_assistance_id"
  class="uxd-theme-motif container"
  *ngIf="formLabels && !isSubmitted"
>
  <h2 class="section-header-two" [innerHtml]="formLabels?.header"></h2>
  <form [formGroup]="appointmentAssistanceForm" libInvalidInputFocus>
    <div class="form-section">
      <!--first name -->
      <label>{{formLabels?.firstName}}</label>
      <input class="form-text-field" formControlName="firstName" type="text">
      <div *ngIf="appointmentAssistanceForm.controls['firstName']?.invalid && appointmentAssistanceForm.controls['firstName']?.touched" class="missing-data-error">
        <span class="motif-icon motif-delete delete-icon missing-data-error-icon"></span>
        <p class="error-message-text">{{getErrorMessage('firstName')}}</p>
      </div>
    </div>
    <!--last name -->
    <div class="form-section">
      <label>{{formLabels?.lastName}}</label>
      <input class="form-text-field" formControlName="lastName" type="text">
      <div *ngIf="appointmentAssistanceForm.controls['lastName']?.invalid && appointmentAssistanceForm.controls['lastName']?.touched" class="missing-data-error">
        <span class="motif-icon motif-delete delete-icon missing-data-error-icon"></span>
        <p class="error-message-text">{{getErrorMessage('lastName')}}</p>
      </div>
    </div>
    <!--member id -->
    <div class="form-section">
      <label>{{formLabels?.memberId}}</label>
      <input class="form-text-field" formControlName="memberId" type="text">
      <div *ngIf="appointmentAssistanceForm.controls['memberId']?.invalid && appointmentAssistanceForm.controls['memberId']?.touched" class="missing-data-error">
        <span class="motif-icon motif-delete delete-icon missing-data-error-icon"></span>
        <p class="error-message-text">{{getErrorMessage('memberId')}}</p>
      </div>
    </div>
    <!--phone number -->
    <lib-phone [id]="'phoneNumber'" formControlName="phoneNumber" [phoneLabel]="formLabels?.phoneNumber" [errObj]="errorMessages" [formSubmitted]="appointmentAssistanceForm.submitted"></lib-phone>
    <!--email -->
    <div class="form-section motif-margin-top-5">
      <label>{{formLabels?.email}}</label>
      <input class="form-text-field" formControlName="email" type="text">
      <div *ngIf="appointmentAssistanceForm.controls['email']?.invalid && appointmentAssistanceForm.controls['email']?.touched" class="missing-data-error">
        <span class="motif-icon motif-delete delete-icon missing-data-error-icon"></span>
        <p class="error-message-text">{{getErrorMessage('email')}}</p>
      </div>
    </div>
    <!--provider type -->
    <div class="form-section">
      <label>{{formLabels?.providerType}}</label>
      <p class="form-sublabel">{{formLabels?.providerTypeHelperText}}</p>
      <input class="form-text-field" formControlName="providerType" type="text">
    </div>
    <!--location -->
    <div class="form-section">
      <label>{{formLabels?.location}}</label>
      <input class="form-text-field" formControlName="location" type="text">
    </div>
    <!--transportation arrangement-->
    <div
      class="radio-btn-group motif-margin-bottom-10"
      [(ngModel)]="transportationArrangementRadioVal"
      [ngModelOptions]="{ standalone: true }"
      name="transportationArrangementRadioVal"
      data-uxd-radio-input-group-cmp
      [radioGroup]="transportationArrangementRadioGroup"
      (ngModelChange)="onChangeTransportationArrangementRadio()"
    ></div>
    <!--already contacted -->
    <div
      class="radio-btn-group motif-margin-bottom-10"
      [(ngModel)]="alreadyContactedRadioVal"
      [ngModelOptions]="{ standalone: true }"
      name="alreadyContactedRadioVal"
      data-uxd-radio-input-group-cmp
      [radioGroup]="alreadyContactedRadioGroup"
      (ngModelChange)="onChangeAlreadyContactedRadioValRadio()"
    ></div>
    <!--date picker -->
    <div *ngIf = "alreadyContactedRadioVal" class="form-section motif-margin-bottom-10 clearfix">
      <p>{{formLabels.selectedDate}}</p>
      <uxd-ant-date-picker required formControlName="contactedDate" [max]="max">
      </uxd-ant-date-picker>
    </div>
    <div class="button-group text-left">
      <button type="button" class="btn btn-secondary" [attr.data-analytics]="analyticsTags.cancelButton" (click)="onReturnHomeClick()">        
        {{formLabels?.cancelButton}}
      </button>
      <button type="submit" class="btn btn-primary" [attr.data-analytics]="analyticsTags.reviewAndSubmitButton" (click)="onSubmitClick()">
        {{formLabels?.reviewAndSubmit}}</button>
      </div>
    </form>
    <div class="grievanceNote">
      <p [innerHTML]="formLabels?.filingGrievanceHeader"></p>
      <a [attr.data-analytics]="analyticsTags.grievanceLink" routerLink="/secure/appeal-grievance" [target]="isMobile ? '_self' : '_blank'">
        {{formLabels?.grievanceLink}}
      </a>
    </div>
</div>

<div *ngIf="isSubmitted" class="container success-container">
  <div class="success-cards-container">
    <i class="fa fa-check-circle motif-margin-bottom-10 success-icon"></i>
    <h3 class="motif-margin-bottom-10">{{ successMessages.success }}</h3>
    <p>{{ successMessages.description }}</p>
    <button
      class="btn btn-primary ant-chat-pill motif-margin-top-5"
      (click)="onReturnHomeClick()"
      [attr.data-analytics]="analyticsTags.returnToHomeButton"
      type="button"
    >
      {{ successMessages.returnToHome }}
    </button>
  </div>
</div>
