import { ChangeDetectionStrategy,Component,Input,OnDestroy,forwardRef,} from "@angular/core";
import {ControlValueAccessor,FormBuilder,FormControl,FormGroup,NG_VALIDATORS,NG_VALUE_ACCESSOR,Validators,} from "@angular/forms";
import { ErrorMsgs, ConsentInformationLabels } from "../../models/labels";
import { ConsentInformationValues } from "../../models/formValues";
import { Subscription } from "rxjs";
import { FormValidators } from "sydmed/libs/custom-validators/src/lib/form-validators.class";

@Component({
  selector: "app-consent-information",
  templateUrl: "./consent-information.component.html",
  styleUrls: ["../../appeals-and-grievances.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConsentInformationComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConsentInformationComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentInformationComponent implements ControlValueAccessor, OnDestroy {
  @Input("appealsAndGrivencesFormSubmitted") appealsAndGrivencesFormSubmitted: boolean;
  @Input() errorMessageLabels: ErrorMsgs;
  @Input() consentLabels: ConsentInformationLabels;
  @Input("editable") editable: boolean = false;
  @Input("uploadAttachmentRestriction") uploadAttachmentRestriction: boolean = false;
  consentChecked: boolean = false;
  consentInformationForm: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder) {
    this.consentInformationForm = this.formBuilder.group({
      consentcheck:false,
      firstName: [""],
      lastName: [""],
      phoneNumber: [""],
    });
    this.consentInformationForm.markAsTouched();
    this.subscriptions.push(
      this.consentInformationForm.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  get value(): ConsentInformationValues {
    return this.consentInformationForm.value;
  }

  set value(value: ConsentInformationValues) {
    this.consentInformationForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (!value) {
      this.consentInformationForm.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.consentInformationForm.valid
      ? null
      : { consentInformation: { valid: false } };
  }

  checkstatus(event: any) {
    if (event?.target?.checked) {
      this.consentChecked = true;
      this.consentInformationForm.controls["firstName"].setValidators([
        Validators.required,
        FormValidators.NameValidator,
      ]);
      this.consentInformationForm.controls["lastName"].setValidators([
        Validators.required,
        FormValidators.NameValidator,
      ]);
    } else {
      this.consentChecked = false;
      this.consentInformationForm.controls["firstName"].clearValidators();
      this.consentInformationForm.controls["lastName"].clearValidators();
      this.consentInformationForm.reset();
    }
    this.consentInformationForm.controls["firstName"].updateValueAndValidity();
    this.consentInformationForm.controls["lastName"].updateValueAndValidity();
  }
}
