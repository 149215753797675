import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { NavigationHelper } from '@anthem/mbrportal/utility';
import { PcpContent, PCPDetail } from '../interfaces/pcpDetail';
import { PcpModel } from '../models/pcpModel';
import { PcpService } from '../services/pcpSvc';
import { default as pcpGetAPIMockData } from '../assets/data/mock/view-pcp.json';
import { Restriction } from 'gbd-models';

/**
 * @description
 * This component is responsible for loading all view pcp page
 * This is an entry component
 *
 * @function
 *  ngOnInit()
 *
 *  @example
 * ```html
 * 	<data-sydmed-pcp-view-cmp></data-sydmed-pcp-view-cmp>
 * ```
 */

@Component({
  selector: 'data-sydmed-pcp-view-cmp',
  templateUrl: './pcpViewCmp.html',
  styleUrls: ['../pcpSyd.scss'],
})
export class PcpViewComponent implements OnInit {
  @Output()
  pcpFetched: EventEmitter<any> = new EventEmitter<any>();

  @Input('isAssignPCP') isAssignPCP: boolean;

  content: PcpContent;
  genericError: string;
  pcpErrorMessage: string;
  pcpErrorType: string;
  pcpInfo: PCPDetail;
  pcpLoader: boolean;
  showPhone: boolean = false;
  enableChangePcp: boolean = true;
  isLockedIn = false;

  constructor(
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private navigationHelper: NavigationHelper,
    private pcpModel: PcpModel,
    private pcpService: PcpService
  ) {
    if(this.jsonSvc.market === 'INMCD') {
      this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: 'Primary Medical Provider',
      });
    }else if(this.jsonSvc.market === 'BCBSMN') {
      this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: 'Primary Care Provider/Primary Care Clinic',
      });
      let pageHeader = document.getElementsByClassName('page-head')[0];
      pageHeader.classList.add('extended');
    }
    else {
      this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: 'Primary Care Provider',
      });
    }
  }

  ngOnInit() {
    this.setContent();
  }

  /**
   * @description onAddUpdatePCP() to add new or upadte existing pcp
   * @retruns {void}
   */
  onAddUpdatePCP(): void {
    this.navigationHelper.navigateTo('/change-provider');
  }

  /**
   * @description getCurentPCP() get the current pcp detail from the API
   * @retruns {void}
   */
  private getCurentPCP(): void {
    this.pcpLoader = true;
    this.pcpErrorMessage = null;

    //Supress Change PCP button
    this.enableChangePcp = this.content.enableChangePcp == true;
    if(this.jsonSvc.hasRestriction(Restriction.SHM_VIEW_PCP, this.content?.restrictions)){
      this.enableChangePcp = false;
    }
    //Toggle showing phone number in subheader
    this.showPhone = this.content.showPhone;
    
    if (this.content.isLocal) {
      this.pcpInfo = this.pcpModel.buildPCPDetail(
        pcpGetAPIMockData,
        this.content.addressAriaPrefix
      );
      this.pcpLoader = false;
    } else {
      this.pcpService.getCurrentPCP().subscribe(
        (data: any) => {
          this.pcpInfo = this.pcpModel.buildPCPDetail(
            data.body,
            this.content.addressAriaPrefix
          );
          this.isLockedIn = data.body.lockedIn;
          this.pcpFetched.emit(this.isLockedIn);
          this.pcpLoader = false;
        },
        (error: any) => {
          if (error.status === 404) {
            this.pcpErrorMessage = this.content.error.noPcpAvailable;
          } else {
            this.pcpErrorMessage = this.content.error.genericError;
          }

          this.pcpLoader = false;
        }
      );
    }
  }

  /**
   * @description setContent() set the content within component attribute
   * @retrun {void}
   */
  private setContent(): void {
    this.genericError = '';
    this.jsonSvc.getJSON('pcp').subscribe(
      (content: PcpContent) => {
        this.content = content;
        this.getCurentPCP();
      },
      (error: any) => {
        this.genericError =
          'We are unable to complete your request at this time. Please contact member services.';
      }
    );
  }
}