import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'messageDate'
})
export class MessageDatePipe implements PipeTransform {

  constructor(
    private datepipe: DatePipe
  ) { }

  transform(value: string, time?: boolean) {
    const msgDate: Date = new Date(value);
    const currentDate = new Date();
    let date = '';
    if (msgDate.getDate() === currentDate.getDate() &&
      msgDate.getMonth() === currentDate.getMonth() &&
      msgDate.getFullYear() === currentDate.getFullYear()) {
      if (time) {
        date = 'Today\n' + this.datepipe.transform(msgDate, 'h:mm a');
      } else {
        date = 'Today';
      }
    } else if (msgDate.getDate() === currentDate.getDate() - 1 &&
      msgDate.getMonth() === currentDate.getMonth() &&
      msgDate.getFullYear() === currentDate.getFullYear()) {
        if (time) {
          date = 'Yesterday\n' + this.datepipe.transform(msgDate, 'h:mm a');
        } else {
          date = 'Yesterday';
        }
    } else {
      if (time) {
        date = this.datepipe.transform(msgDate, 'MM/dd/yyyy\nh:mm a');
      } else {
        date = this.datepipe.transform(msgDate, 'MM/dd/yyyy');
      }
    }
    return date;
  }

}
