<div class="container information-alert" *ngIf="displayList.length == 0" aria-live="polite">
    <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}" [attr.aria-label]="content.PaymentNoResults">{{content.PaymentNoResults}}</div>
</div>
<div class="container mt-48">
    <uxdAccordion class="accordion-wrapper uxd-expansion-table zebra-striping" [isAccordion]="true" *ngIf="displayList.length > 0">
        <div class="uxd-expansion-table" role="table" aria-live="polite">
            <div role="rowgroup" class="container pr-align">
                <div class="accordion-header-grid-container" role="row">
                    <div class="expansion-toggle payment-table-label" role="columnheader">
                        <span class="">&nbsp;</span>
                    </div>
                    <div class="paid-on payment-table-label">
                        <h3 class="" role="columnheader">{{content.PaidOn}}</h3>
                    </div>
                    <div class="account-type payment-table-label">
                        <h3 class="" role="columnheader">{{content.AccountType}}</h3>
                    </div>
                    <div class="amount payment-table-label">
                        <h3 class="" role="columnheader">{{content.Amount}}</h3>
                    </div>
                    <div class="status payment-table-label">
                        <h3 class="" role="columnheader">{{content.Status}}</h3>
                    </div>        
                </div>                
            </div>
            <div role="rowgroup">
            <uxd-expansion-panel *ngFor="let row of displayList; index as i" #panel="uxdExpansionPanel">
                <uxd-expansion-header [aria-label]="'View information for ' + row.AccountType">
                <div class="container clear-padding">
                    <div class="accordion-header-grid-container" role="row">
                        <div class="expansion-toggle" [attr.aria-label]="'View more info for ' + row.AccountType" role="cell">
                            <uxd-expansion-toggle>
                                <span class="motif-icon" [attr.aria-label]="panel.expanded ? 'Collapse' : 'Expand'+' information for ' + row.AccountType" [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
                            </uxd-expansion-toggle>
                        </div>
                        <div class="paid-on" role="cell">
                            <h4 class="mobile-label" role="columnheader">{{content.PaidOn}}</h4>
                            <p class="ant-font-r mb-lh" [attr.aria-label]="row.PaymentDate">{{ row.PaymentDate }}</p>
                        </div>
                        <div class="account-type" role="cell">
                            <h4 class="mobile-label" role="columnheader">{{content.AccountType}}</h4>
                            <p class="ant-font-r mb-lh" [attr.aria-label]="row.AccountType">{{ row.AccountType }}</p>
                        </div>
                        <div class="amount" role="cell">
                            <h4 class="mobile-label" role="columnheader">{{content.Amount}}</h4>
                            <p class="ant-font-r mb-lh" attr.aria-label="{{ row.PaymentAmount | currency:'USD' }}">{{ row.PaymentAmount | currency:'USD' }}</p>
                        </div>
                        <div class="status" role="cell">
                            <h4 class="mobile-label" role="columnheader">{{content.Status}}</h4>
                            <p class="ant-font-r mb-lh" [attr.aria-label]="row.Status">{{ row.Status }}</p>
                        </div>
                    </div>
                </div>
                </uxd-expansion-header>
                <app-payment-detail [paymentDetails]="row" [content]="content"></app-payment-detail>              
            </uxd-expansion-panel>
            </div>
        </div>
    </uxdAccordion>
</div>