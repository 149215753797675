<div class="mbr-page-wrapper">
	<div class="mbr-top-of-page-wrapper ant-new-nav-wrapper ant-secure-nav-overrides motif-nav">
		<div class="mbr-top-nav-container">
			<!-- header banner component -->
			<header>
				<app-secure-header></app-secure-header>
			</header>
			<!-- end header banner component -->

			<!-- header navigation component -->
			<nav>
				<public-language-toggle></public-language-toggle>
				<app-secure-navigation></app-secure-navigation>
			</nav>
			<!-- end header navigation component -->

		</div>
	</div>

	<main>
		<div class="tcp-middle-of-page-clearfix">
			<div class="page-header-wrapper" [ngClass]="{'public-widget':publicWidget}">
				<div class="container">
					<div class="row">
						<div id="mainContent" class="col-xs-12" *ngIf="!publicWidget && !dashboard">
							<div class="page-header-container">
								<h1 class="page-head" id="pageTitle" tabindex="-1">{{title}}</h1>
								<div class="page-header-text-container">
									<div class="page-header-text">
										<p class="paragraph" [innerHTML]="(description | async)"></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<div class="mbr-page-body motif-theme">
				<!-- <div class="container"> -->
					<div class="contentGroup">
	
						<div style="text-align:center">
						</div>
	
						<div class="view-container">
							<div ng-view class="view-frame"></div>
						</div>
	
						<router-outlet></router-outlet>

						<!-- add a single instance of sso form component to app to prevent duplicates-->
						<app-secure-sso-form></app-secure-sso-form>
					</div>
				<!-- </div> -->
			</div>
		</div>
		<!-- Chat component present on all pages after login -->
		<app-chat-launcher *ngIf="showChatIcon"></app-chat-launcher>
	</main>

	<!-- footer component -->
	<footer>
		<app-secure-footer></app-secure-footer>
	</footer>
	<!-- end footer component -->
</div>