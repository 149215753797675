import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsDataService {

  constructor() { }

  
  private successMsg: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public navActive: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public setSuccessMsg(msg) {
    this.successMsg.next(msg);
  }

  public getSuccessMsg() {
    return this.successMsg;
  }

  public setNavActive(nav) {
    this.navActive.next(nav);
  }

  public getNavActive() {
    return this.navActive;
  }

}
