<div id="message-center-compose" *ngIf="!showSuccessMessage">
  <inline-alert-container [name]="'message-subject-alerts'"></inline-alert-container>
  <h2 id="MsgCntrNewMessage_lbl">{{ labels.MsgCntrNewMsgHeading_lbl }}</h2>
  <form [formGroup]="composeMessageForm" #composeForm="ngForm" name="composeForm" class="new-message-form" (submit)="submitMessage(composeForm.form.valid)" formsubmit novalidate>
    <div class="msgBody">
      <section class="messageSubject">
        <div class="form-item clearfix">
          <div  ngDefaultControl #mdSelect="ngModel" data-uxd-dropdown-cmp [(ngModel)]="subjectDropDownModel" [ngModelOptions]="{standalone: true}" class="ant-md-select form-item" [labelText]="subjectDropDownDef.title"
            name="subjectSelect" id="subjectSelect" [items]="subjectDropDownDef.options"
            required (onChange)="changeSubject($event.value, $event.label)" [ngClass]="{error: subjectError}" [defaultText]="'Please Select'">
          </div>
          <div class="error-list" *ngIf="subjectError">
            <div>
              <span class="success"><i class="fas fa-times"></i>
                <span class="motif-margin-left-5">
                    <span [innerHtml]="errorMsgs.Required_Field_err"></span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>
      <div class="message-input-cont" *ngIf="!hideComposeMessage">
        <div class="message-input">
          <div class="ant-form-field">
            <textarea formControlName="MsgCntrMsgbody" title="Message body" name="MsgCntrMsgbody" [(ngModel)]="messageBody" minlength="1" maxlength="2000" required aria-invalid="false" placeholder="{{ labels.MsgCntrMsgBodyPlaceholder_lbl }}" required [ngClass]="{'invalidInput': (MsgCntrMsgbody.dirty || MsgCntrMsgbody.touched || composeForm.submitted) && MsgCntrMsgbody.invalid}"></textarea>
            <div class="error-list" *ngIf="(MsgCntrMsgbody.dirty || MsgCntrMsgbody.touched || composeForm.submitted) && MsgCntrMsgbody.invalid">
              <div *ngIf="MsgCntrMsgbody.errors.required">
                <span class="success"><i class="fas fa-times"></i>
                  <span class="motif-margin-left-5">
                      <span [innerHtml]="errorMsgs.Required_Field_err"></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <app-file-upload class = "attachmentUploadErrorAlert" [attachmentActionType]="attachmentActionType" [fileTypes]="fileTypes" *ngIf="!UploadAttachmentsEnabled" [attachFiles]="attachFiles" [attachmentErrorMsgs]="attachmentErrorMsgslabels"  [errorMsgs]="errorMsgsLabels"></app-file-upload>
          <div class="message-input-actions">
            <div class="message-input-actions-holder">
              <button type="button" class="deleteBtn" [attr.aria-label]="'Cancel composing new message'" (click)="delete()"><span class="motif-icon motif-trash"></span></button>
            </div>
            <div class="msgSubmit">
              <button type="submit" id="MsgCntrSendMsg_btn" class="btn" [disabled]="isSubmitDisabled">{{ isSubmitDisabled ? labels.MsgCntrSendingMsg_btn : labels.MsgCntrSendMsg_btn }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<inline-alert-container id="composeMessageAlerts" [name]="'compose-message-alerts'"></inline-alert-container>

<div id="successMessage" *ngIf="showSuccessMessage" class="text-center">
  <div><span class="motif-icon motif-checked"></span></div>
  <h2>{{ labels.MsgCntrSentSuccessHeading_lbl }}</h2>
  <p>{{ labels.MsgCntrSentSuccessBody_lbl }} <a class="successMessage" href="{{ labels.MsgCntrContactUsLink }}" (click)="closeSidePanel()">{{ labels.MsgCntrSentSuccessLink_lbl }}</a></p>
  <button class="btn" (click)="closeSidePanel()">{{ labels.MsgCntrSentSuccessClose_btn }}</button>
</div>

<ng-template #alertRef>
  <inline-alert-container #attachment role="alert" [name]="'inline-alert'"></inline-alert-container>
</ng-template>