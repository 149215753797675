import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { LtssResponse, OutOfPocketResponse  } from 'gbd-models';
const { costShare: endPoints} = UrlProperties.UrlProperties.endPoints;

@Injectable({
    providedIn: 'root'
})
export class CostShareService {
    protected hasOutOfPocketData$ = new BehaviorSubject<boolean>(true);
    public hasOutOfPocketData = this.hasOutOfPocketData$.asObservable();
    constructor(protected http: HttpClientService) {}
    public getMemberLiability(): Observable<LtssResponse> {
        return this.http.getObservable(endPoints.memberLiability).pipe(
            map(res => res.body),
            catchError(err => throwError(err))
        );
    }
    public getOutOfPocket(): Observable<OutOfPocketResponse> {
        return this.http.getObservable(endPoints.outOfPocket).pipe(
            map( res => {
                const data = res.body;
                if (Object.keys(data).length <= 1) {
                    this.hasOutOfPocketData$.next(false);
                    throwError('No data found');
                } else {
                    this.hasOutOfPocketData$.next(true);
                    return data;
                }
            }),
            catchError(err => throwError(err))
        );
    }

}
