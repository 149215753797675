import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrievancesFormComponent } from './grievances-form/grievances-form.component';
import { GrievancesFormPreviewComponent } from './grievances-form-preview/grievances-form-preview.component';
import { GrievancesFormConfirmationComponent } from './grievances-form-confirmation/grievances-form-confirmation.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
  { 
    path: 'secure/grievances-form', 
    component: GrievancesFormComponent,
    canActivate: [AuthGuardService] 
  },
  { 
    path: 'secure/grievances-form-preview', 
    component: GrievancesFormPreviewComponent,
    canActivate: [AuthGuardService] 
  },
  { 
    path: 'secure/grievances-form-confirmation', 
    component: GrievancesFormConfirmationComponent ,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnlineGrievancesRoutingModule { }
