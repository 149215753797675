import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LoginModel {

  constructor(
   
  ) { }

  private model: any = {
    memberInput: {
      memberResponse: {}
    }
  };


  public transformResponse(apiData) {
    if (apiData) {
      this.model.memberResponse = apiData;
    }
    return this.model;
  }



}
