<div class="container max-width-elements margin-bottom-48">
    <h1 tabindex="-1" class="font-32 margin-bottom-48">{{jsonLabels?.Page_Heading}}</h1>
    <h1 class="font-32 margin-bottom-48">{{jsonLabels?.Notes}}</h1>
    <div class="margin-bottom-48" *ngIf="isParentOrGuardian && jsonLabels?.Registration_Confirmation_Details">
        <p class="font-16 margin-bottom-24">{{jsonLabels.Registration_Confirmation_Details?.Registration_Waiting_Period}}</p>
        <p class="font-16 margin-bottom-24">{{jsonLabels.Registration_Confirmation_Details?.Call_Letter_Info}}</p>
        <p class="font-16 font-bold">{{jsonLabels.Registration_Confirmation_Details?.Notes}}</p>
    </div>
    <div class='flex-horizontal-end margin-top-48 margin-bottom-48'>
        <button type="button" class="btn btn-primary" (click)="login()"
            data-analytics="loginButtonConfirmReg" [attr.aria-label]="jsonAriaLabel">{{jsonLabels?.Login}}</button>
    </div>
</div>