import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorCodeMapperService {

  constructor() { }

  public errorCodeMapper(error, errorMsgs) {
    let errorMsg = '';
    let errorCode: number = null;

    if (error && error.error && error.error.code ) {
         errorCode = error.error.code;
    }

    if (error && error.error && error.error.errorCode && error.error.errorCode.code ) {
      errorCode = error.error.errorCode.code;
    }

    if (errorCode !== null && errorMsgs[errorCode]) {
      errorMsg = errorMsgs[errorCode];
    } else if (errorCode === null) {
      errorMsg = errorMsgs.GeneralSySError;
      const reg = new RegExp('error' + '\\s+' + 'code' + '\\s+' + ':' + '\\s+', 'i');
      errorMsg = errorMsg.replace(reg, '');
    } else {
      errorMsg = errorMsgs.GeneralSySError;
      const reg = new RegExp('error' + '\\s+' + 'code' + '\\s+' + ':' + '\\s+', 'i');
      errorMsg = errorMsg.replace(reg, 'Error code : ' + errorCode);
    }

    return errorMsg;
  }

  public errorCodeMapperV2(error, errorMsgs) {
    let errorMsg = '';
    let errorCode: string = null;

    if (error && error.error && error.error.code) {
         errorCode = error.error.code;
    }

    if (errorCode !== null && errorMsgs[errorCode]) {
      errorMsg = errorMsgs[errorCode];
    } else if (errorCode === null) {
      errorMsg = errorMsgs.GeneralSySError;
      const reg = new RegExp('error' + '\\s+' + 'code' + '\\s+' + ':' + '\\s+', 'i');
      errorMsg = errorMsg.replace(reg, '');
    } else {
      errorMsg = errorMsgs.GeneralSySError;
      const reg = new RegExp('error' + '\\s+' + 'code' + '\\s+' + ':' + '\\s+', 'i');
      errorMsg = errorMsg.replace(reg, 'Error code : ' + errorCode);
    }

    return errorMsg;
  }

  public errorCodeMapperV3(error, errorMsgs) {
    let errorMsg = '';
    let errorCode: string = null;

    if (error && error.code) {
         errorCode = error.code;
    }

    if (errorCode !== null && errorMsgs[errorCode]) {
      errorMsg = errorMsgs[errorCode];
    } else if (errorCode === null) {
      errorMsg = errorMsgs.GeneralSySError;
      const reg = new RegExp('error' + '\\s+' + 'code' + '\\s+' + ':' + '\\s+', 'i');
      errorMsg = errorMsg.replace(reg, '');
    } else {
      errorMsg = errorMsgs.GeneralSySError;
      const reg = new RegExp('error' + '\\s+' + 'code' + '\\s+' + ':' + '\\s+', 'i');
      errorMsg = errorMsg.replace(reg, 'Error code : ' + errorCode);
    }

    return errorMsg;
  }
}
