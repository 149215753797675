<div class="container motif-margin-0">
    <div class="display-block" *ngIf="!isLoading && paymentList.length == 0 && !isPaymentsError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}">
        <span>{{content.CurrentBillNoResults}}</span>
    </div>
    <div class="display-block" *ngIf="isPaymentsError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span>{{errorMsgs.GeneralSystemErrorWithoutCode}}</span>
    </div>
</div>
<div class="container" *ngIf="isLoading">
    <div class="row" id="search-loader">
        <uxd-spinner></uxd-spinner>
    </div>
</div>
<div class="container">
    <uxdAccordion class="accordion-wrapper uxd-expansion-table zebra-striping" [isAccordion]="true" *ngIf="paymentList.length > 0 && !isLoading">
        <div class="uxd-expansion-table" role="table" [attr.aria-label]="content.CurrentBills">
            <div role="rowgroup">
                <div class="accordion-header-grid-container" role="row">
                    <div class="expansion-toggle payment-table-label">
                        <span class="" role="columnheader">&nbsp;</span>
                    </div>
                    <div class="due-date payment-table-label">
                        <span class="" role="columnheader">{{content.DueDate}}</span>
                    </div>
                    <div class="account-type payment-table-label">
                        <span class="" role="columnheader">{{content.AccountType}}</span>
                    </div>
                    <div class="amount payment-table-label">
                        <span class="" role="columnheader">{{content.Amount}}</span>
                    </div>
                    <div class="status payment-table-label">
                        <span class="" role="columnheader">{{content.Status}}</span>
                    </div>
                    <div class="autopay payment-table-label">
                        <span class="" role="columnheader">{{content.Autopay}}</span>
                    </div>
                    <div class="paynow payment-table-label" role="columnheader">
                        <span class=""></span>
                    </div>
                </div>
            </div>
            <div role="rowgroup">
                <uxd-expansion-panel *ngFor="let row of paymentList; index as i" #panel="uxdExpansionPanel">
                    <uxd-expansion-header [aria-label]="'View information for ' + row.accountType">
                        <div class="container clear-padding">
                            <div class="accordion-header-grid-container" role="row">
                                <div class="expansion-toggle" role="cell">
                                    <uxd-expansion-toggle>
                                        <span class="motif-icon" [attr.aria-label]="panel.expanded ? 'Collapse' : 'Expand'+' information for ' + row.accountType" [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
                                    </uxd-expansion-toggle>
                                </div>
                                <div class="due-date" role="cell">
                                    <h4 class="mobile-label" role="columnheader">{{content.PaidOn}}</h4>
                                    <p class="ant-font-r mb-lh" attr.aria-label="{{row.dueDate | date:'MM/dd/yyyy'}}">{{row.dueDate | date:'MM/dd/yyyy'}}</p>
                                </div>
                                <div class="account-type" role="cell">
                                    <h4 class="mobile-label" role="columnheader">{{content.AccountType}}</h4>
                                    <p class="ant-font-r mb-lh" [attr.aria-label]="row.accountType">{{row.accountType}}</p>
                                </div>
                                <div class="amount" role="cell">
                                    <h4 class="mobile-label" role="columnheader">{{content.Amount}}</h4>
                                    <p class="ant-font-r mb-lh" attr.aria-label="{{row.amount | currency:'USD'}}">{{row.amount | currency:'USD'}}</p>
                                </div>
                                <div class="status" role="cell">
                                    <h4 class="mobile-label" role="columnheader">{{content.Status}}</h4>
                                    <p class="ant-font-r mb-lh" [attr.aria-label]="content.PaidInFull" *ngIf="row.paidInFull && !isCoronaActive">{{content.PaidInFull}}</p>
                                    <p class="ant-font-r mb-lh" [attr.aria-label]="content.PaymentPending" *ngIf="!row.autoPay && !row.showDue && !row.paidInFull && !isCoronaActive">{{content.PaymentPending}}</p>
                                    <p class="ant-font-r mb-lh" attr.aria-label="{{content.PaymentPending}} {{content.AutopayOn}}" *ngIf="row.autoPay && !isCoronaActive">{{content.PaymentPending}}</p>
                                    <p class="ant-font-r mb-lh" attr.aria-label="{{content.PaymentPending}} {{content.AutopayOn}}" *ngIf="row.autoPay && !isCoronaActive">{{content.AutopayOn}}</p>
                                    <p class="ant-font-r mb-lh" [attr.aria-label]="content.OverDue" *ngIf="row.overDueDays && !isCoronaActive">{{content.OverDue}}</p>
                                    <p class="ant-font-r mb-lh" [attr.aria-label]="content.PaymentDue" *ngIf="row.showDue && !isCoronaActive">{{content.PaymentDue}}</p>
                                    <p class="ant-font-r mb-lh" [attr.aria-label]="content.PaymentCollectionHold" *ngIf="isCoronaActive">{{content.PaymentCollectionHold}}</p>
                                </div>
                                <div class="autopay" role="cell">
                                    <p class="ant-font-r mb-lh" *ngIf="row.showAdd">
                                        <a href="javascript:void(0);" [ngClass]="isCoronaActive || row.amount == 0 ? 'disabled': ''" 
                                            (click)="autoPayAdd(row)" [attr.aria-label]="content.ScheduleAutopay + ' for ' + row.accountType" data-analytics = "schedAutoHomeBillPay" >{{content.ScheduleAutopay}}</a>
                                    </p>
                                    <p class="ant-font-r" *ngIf="row.autoPay">
                                        <a href="javascript:void(0);" [ngClass]="isCoronaActive || row.amount == 0 ? 'disabled': ''" 
                                            (click)="autoPayEdit(row)" [attr.aria-label]="content.EditAutopay + ' for ' + row.accountType" data-analytics = "editAutoHomeBillPay" >{{content.EditAutopay}}</a>
                                    </p>
                                    <p class="ant-font-r" *ngIf="row.autoPay">
                                        <a href="javascript:void(0);" [ngClass]="isCoronaActive || row.amount == 0  ? 'disabled': ''" 
                                            (click)="handleClick(row)" [attr.aria-label]="content.RemoveAutopay + ' for ' + row.accountType"data-analytics = "turnOffAutoHomeBillPay" >{{content.RemoveAutopay}}</a>
                                    </p>
                                </div>
                                <div class="paynow" role="cell">
                                    <p class="ant-font-r mb-lh" *ngIf="row.showPay">
                                        <button class="btn btn-secondary w-100" [style.opacity]="isCoronaActive ? '0.3': '1'" [disabled]="isCoronaActive || row.amount == 0"
                                            (click)="onPayNowClick(row)" data-analytics= "payNowHomeBillPay" [attr.aria-label]="content.btnPayNow + ' for ' + row.accountType">{{content.btnPayNow}}</button>
                                    </p>
                                </div>

                            </div>
                            <div data-uxd-alert-cmp-v2 class="ml-50" [alert]="{alertType: 'disaster'}" [attr.aria-label]="content.OverdueError" *ngIf="row.overDueDays && !isCoronaActive">{{content.OverdueError}}</div>
                        </div>
                    </uxd-expansion-header>
                    <app-current-bills-detail [paymentDetails]="row" [content]="content"></app-current-bills-detail>
                </uxd-expansion-panel>
            </div>
        </div>
    </uxdAccordion>
</div> 
<ng-template #removeAutopayAlert>
    <div class="delete-modal">
        <button class="close motif-icon motif-delete" aria-label="Close Modal" (click)="onClose()">            
        </button>
        <div class="modal-header">
            <h2>{{content.RemoveAutopay}}</h2>
        </div>
        <div class="modal-body">
            <p>{{content.RemoveAutopayAlertDesc}}</p>
            <p>{{content.RemoveAutopayConfirm}}</p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" [attr.aria-label]="content.NoCancel" (click)="onClose()">{{content.NoCancel}}</button>
            <button class="btn continue" [attr.aria-label]="content.YesRemove" (click)="confirmRemove()" data-analytics = "yesRemoveAutoModalBillPay" >{{content.YesRemove}}</button>
        </div>
    </div>
</ng-template>