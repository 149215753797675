import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShmMyProfileDataService {

  constructor() { }

  private labels: object;
  private memberName: object;
  private errorMessages: object;
  private _json: object;  

  public setLabels(labels) {
    this.labels = labels;
  }

  public getLabels() {
    return this.labels;
  }

  public setMemberName(memberName) {
    this.memberName = memberName;
  }

  public getMemberName() {
    return this.memberName;
  }

  public setErrorMessages(errorMessages) {
    this.errorMessages = errorMessages;
  }

  public getErrorMessages() {
    return this.errorMessages;
  }

  public set json(json: object) {
    this._json = json;
  }

  public get json(): object {
    return this._json;
  }
}
