
<div class="container content-container">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-xs-12 col-xxs-12 header">
            <h2 class="section-header-two">{{content?.header}}</h2>
        </div>
        <div class="col-xs-12 col-xxs-12 claims">
            <app-my-claims></app-my-claims>
        </div>
        <div class="col-xs-12 col-xxs-12 health-care">
            <div class="dashboard-card-container">
                <div class="right-container">
            <div *ngFor="let quickLink of quickLinks">
                <div *ngIf="quickLink.show" class="cards-container">
                    <div role="button" href="{{ quickLink.link }}" (click)="onClick(quickLink)" class="quick-link-card" [attr.data-analytics]="quickLink.analyticsTag">
                            <ng-container *ngIf="quickLink.image; else icon" >
                                <img [src]="quickLink.image" alt=""  class="care-img" />
                            </ng-container>
                            <ng-template #icon>
                                <span [ngClass]="quickLink.icon"></span>
                            </ng-template>
    
                        <div class="text-container">
                            <p>
                                <span class="quick-link-card-title">{{quickLink.title}}</span>
                            </p>
                            <p>
                                <span class="quick-link-card-description">{{quickLink.description}}</span>
                            </p>
                        </div>
                        <div class="card-icon motif-icon up-arrow-icon motif-angle-right"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    </div>
</div>