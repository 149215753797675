import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { OnlineGrievancesLabels, OnlineGrievancesConfirmationLabels, OnlineGrievancesErrorMsgs } from '../models/labels';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';

@Component({
  selector: 'app-grievances-form-confirmation',
  templateUrl: './grievances-form-confirmation.component.html',
  styleUrls: ['./grievances-form-confirmation.component.css']
})
export class GrievancesFormConfirmationComponent implements OnInit {

  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Grievance Form'
    });
  }

  public formLabels: OnlineGrievancesLabels;
  public confirmationLabels: OnlineGrievancesConfirmationLabels;
  public errorMsgs: OnlineGrievancesErrorMsgs;

  ngOnInit(): void {
    this.jsonSvc.getJSON('online-grievances').subscribe(
      (res) => {
        this.formLabels = res.OnlineGrievances.Form;
        this.confirmationLabels = res.OnlineGrievances.Sent;
        this.errorMsgs = res.OnlineGrievances.ErrorMsgs;
      }
    );
  }

  public returnButton() {
    const returnUrlKey = 'Return_btn_url';
    location.href =  this.confirmationLabels[returnUrlKey];
  }

}
