// Angular Imports
import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';

// Message Center Imports
import { MessageCenterLabels } from './../models/labels.interface';
import { MessageCenterDataService } from './../message-center-data.service';

// Motif Imports
import { SidePanel, ModalRef } from '@anthem/uxd/modal';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { HelperService } from '../../appeals-and-grievances/utils/helper.service';

@Component({
  selector: 'compose-button',
  templateUrl: './compose-button.component.html',
  styleUrls: ['./compose-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComposeButtonComponent implements OnInit {

  constructor(
    private dataSvc: MessageCenterDataService,
    private memberRepresentativeService: MemberRepresentativeService,
    private sidePanel: SidePanel,
    public helperService: HelperService,
  ) { }

  @ViewChild('sidePanelContent', { static: false })
  sidePanelContent: TemplateRef<HTMLElement>;

  public labels: MessageCenterLabels;
  private sidePanelRef: ModalRef<any, any>;
  public isDesignee: boolean = false;
  public isTermed: boolean = false;
  public hideComposeButton: boolean = false;

  ngOnInit(): void {
    this.labels = this.dataSvc.labels;
    this.isDesignee = this.memberRepresentativeService.isDesignee;
    this.hideComposeButton = this.dataSvc.hideComposeMessage;
    this.isTermed = this.dataSvc.checkForTermedRestrictions;
  }

  public composeMessage(direction: any) {
    this.sidePanelRef = this.sidePanel.open(direction, this.sidePanelContent);
    this.dataSvc.composeMessageSidePanel = this.sidePanelRef;
  }

  public onClose() {
    this.helperService.clearFileAttachments();
    this.sidePanelRef.close();
  }

}
