import { Component, EventEmitter, Inject, Injectable, Input, OnInit } from '@angular/core';
import { VendorCurrentOrderStatusComponent } from '@anthem/mbrportal-pharmacy/components/vendorCurrentOrderStatusCmp';
import { ContentHelper, EntryComponent, EventHandler, IContentErrorResponse, WCS_ATTRIBUTES } from '@anthem/mbrportal/core';
import { AppSession, IAppSession } from '@anthem/mbrportal/main';
import { Logger, NavigationHelper, DateUtility } from '@anthem/mbrportal/utility';
import { VendorCurrentOrderStatusModel } from '@anthem/mbrportal-pharmacy/models/vendorCurrentOrderStatusModel';
import { CurrentOrder } from '@anthem/mbrportal-pharmacy/interfaces/vendorCurrentOrderStatus';
import { Order } from '@anthem/mbrportal-pharmacy/interfaces/iOrders';
import { Features, checkFeatureFlag } from '@anthem/mbrportal/common';
import { AjaxRequest, BaseComponent, OrderBy } from '@anthem/mbrportal/shared';
import { Subscription } from 'rxjs';
import { VendorCurrentOrderStatusContent } from '@anthem/mbrportal-pharmacy/components/vendorCurrentOrderStatus.content';
import { AHDStatus } from '@anthem/mbrportal-pharmacy/enums/ahdStatus';
import { IHttpResponse } from '@anthem/mbrportal/http/public_api';
import * as AHDOrdersMock from "../assets/data/mock/ahd-orders";
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Router } from '@angular/router';
import { LeftNavigationService } from '../services/left-navigation.service';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { PharmacyNavigationUtility } from '@anthem/mbrportal-pharmacy/services/pharmacyNavigationUtility';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';

@Component({
  moduleId: module.id,
  selector: 'app-ahd-landing-page-orders',
  //templateUrl: './ahd-order-status.component.html',
  templateUrl: `../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/vendorCurrentOrderStatusCmp.html`,
  styleUrls: ['../../../../../../node_modules/@anthem/mbrportal-pharmacy/assets/styles/partials/checkOrderStatus.scss','./ahd-landing-page-orders.component.scss'],
  providers: [VendorCurrentOrderStatusModel,{ provide: ContentHelper, useClass: AHDContentHelper }]
})

export class AhdLandingPageOrdersComponent extends VendorCurrentOrderStatusComponent {
  // ordersMock: Order[] = AHDOrdersMock.ahdOrders.order as Order[]; uncomment to mock the getAllorders data
  constructor(
    public logger: Logger,
    public contentHelper: ContentHelper,
    @Inject(AppSession) public appSession: IAppSession,
    @Inject(WCS_ATTRIBUTES) attributes: { [k: string]: string },
    vendorCurrentOrderStatusModel: VendorCurrentOrderStatusModel,
    eventHandler: EventHandler,
    pharmacyNavigationUtility: PharmacyNavigationUtility,
    navigationHelper: NavigationHelper,
    protected contentService: JsonContentService,
    private router: Router,
    private _orderBy: OrderBy,
    private getAllOrdersService: ahdPharmacyService,
    private leftNavigationService: LeftNavigationService,
    private _dateUtility: DateUtility
  ) {
    super(logger, contentHelper, pharmacyNavigationUtility, appSession, attributes, vendorCurrentOrderStatusModel, eventHandler, navigationHelper);
    this.getLabels();
    // this.orders = this.ordersMock; uncomment to mock the getAllorders data
  }

  async ngOnInit() {
    if (this.leftNavigationService.isMini) 
    {
      this.isPharmacyHome = true;
    }
    let today = new Date();
    today.setMonth(today.getMonth(), today.getDate());
    const toDate = this._dateUtility.getDatePart(today, 'yyyy-MM-dd');
    today.setMonth(today.getMonth() - 6, today.getDate());
    const dateFilterFromDate = this._dateUtility.getDatePart(today, 'yyyy-MM-dd');
    await this.getAllOrdersService.getAHDOrders(dateFilterFromDate, toDate).then((response) => {
     //this.orders = response;
     this.orders = response?.data?.orders as Order[];
     this.orders = this.sortOrders(this.orders, 'orderDateNewest');
     this.isViewAllOrderButton = this.orders?.length > 1 ? true: false;
    }).catch(error =>{ console.log('error while loading orders', error)
    this.leftNavigationService.emitOrderData(true);
  });
    if (this.orders) {
      this.leftNavigationService.emitOrderData(true);
    }
    this.handleOrders();
    this.showCurrentOrderStatus = true;
    
    //Hiding delivery date link for now, as it is part of day 2 solution
    this.currentOrder?.forEach(order => {
      order.deliveryDateLink = false;
    });
  }

  sortOrders(orderList: Order[], sortKey?: string) {
    let sortCriteria: string;

    switch (sortKey) {
      case 'orderDateNewest': {
        sortCriteria = '-orderDate';
        break;
      }
      case 'orderDateOldest': {
        sortCriteria = 'orderDate';
        break;
      }
      default: {
        sortCriteria = '-orderDate';
        break;
      }
    }

    if (sortCriteria) {
      return this._orderBy.transform(orderList, [sortCriteria]) as Order[];
    }

    return undefined as unknown as Order[];
  }


  getLabels(): void {
    this.contentService.getJSON('ahd-order-status').subscribe(content => this.content = content);
  }

  handleNavigation(order?: CurrentOrder) {
    if (!order) {
      this.router.navigate(['/secure/ahd-pharmacy/prescriptions/orders']);
    }
    else {
      this.router.navigate(['/secure/ahd-pharmacy/prescriptions/orders/review-orders', order.orderId]);
    }
  }

}