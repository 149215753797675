import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';


const routes: Routes = [
  { path: 'secure/profile', redirectTo: 'secure/profile/login-security', pathMatch: 'full' },
  {
    path: 'secure/profile/login-security',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./login-and-contact/login-and-contact.module').then(
        m => m.LoginAndContactModule
      )
     }
    ]
  },
  {
    path: 'secure/profile/account-recovery',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./account-and-recovery/account-and-recovery.module').then(
        m => m.AccountAndRecoveryModule
      )
     }
    ]
  },
  {
    path: 'secure/profile/account-access',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./account-access/account-access.module').then(
        m => m.AccountAccessModule
      )
     }
    ]
  },
  {
    path: 'secure/profile/additional-insurance-policies',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./coordination-of-benefits/cob.module').then(m => m.CobModule)}   
     ]
  },
  {
    path: 'secure/profile/addresses',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./addresses/addresses.module').then(m => m.AddressesModule)}
    ]
  },
  {
    path: 'secure/profile/payments',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)}
    ]
  },
  {
    path: 'secure/profile/plan-notifications',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./plan-notifications/plan-notifications.module')
      .then(m => m.PlanNotificationsModule)}
    ]
  },
  {
    path: 'secure/profile/pharmacy-notifications',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./prescription-notifications/prescription-notifications.module')
      .then(m => m.PrescriptionNotificationsModule)}
    ]
    },
  {
    path: 'secure/profile/medical-profile',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./medical-profile/medical-profile.module').then(m => m.MedicalProfileModule)}
    ]
  },
  {
    path: 'secure/profile/language',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./language-preference/language-preference.module').then(m => m.LanguagePreferenceModule)}
    ]
  },
  {
    path: 'secure/profile/privacy-access',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./privacy-and-access/privacy-and-access.module').then(m => m.PrivacyAndAccessModule)}
    ]
  },
  {
    path: 'secure/profile/communication-preferences',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./utilization-management-notifications/utilization-management-grievance-appeals-notices.module').then(m => m.UtilizationManagementGrievanceAppealsNoticesModule)}
    ]
  },
  {
    path: 'secure/profile/address-details',
    component: AppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', loadChildren: () => import('./designee-address-details/address-details.module').then(m=>m.AddressDetailsModule)}
    ]
  }
];

@NgModule({
  imports: [
   RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}