<div class="container">
  <inline-alert-container [name]="'my-profile-alerts'" [alertType]="alertPosition"></inline-alert-container>
</div>
<div [ngClass]="showMobileView" class="container">
    <div class="row">
        <div class="col-12">
          <div  class="medical-profile-success">
              <inline-alert-container [name]="'inline-alert-dev-example'" [alertType]="alertPosition"></inline-alert-container>
          </div>
        </div>
      </div>
  <div class="tab-controls-container-sidebar">
    <sidebar-rail>
      <siderail-nav *ngIf="!hasDesigneeProfile">
        <h2 siderail-header class="siderail-header" [innerHTML]="labels?.DesigneeAccount?.Header" tabIndex="0"></h2>
        <button *ngFor="let nav of clonedDesigneeAccountHeadings"
          siderail-link
          [id]="'designee'+nav.Label"
          [innerHTML]="nav.Label"
          (click)="setNavPage(nav.Url)"
          [ngClass]="{'isActive': navPage === nav.Url}" 
          [attr.data-analytics]="''"
        ></button>
      </siderail-nav>
      <siderail-nav *ngIf="restrictions?.accountSettings">
        <h2 siderail-header class="siderail-header" [innerHTML]="labels?.AccountSettings.Header" tabIndex="0"></h2>
        <button *ngFor="let nav of labels?.AccountSettings.Menu_Headings"
          siderail-link
          [id]="'navid'+nav.Label"
          [innerHTML]="nav.Label"
          (click)="setNavPage(nav.Url)"
          [ngClass]="{'isActive': navPage === nav.Url}"
          [attr.data-analytics]="nav.Url === '/secure/profile/privacy-access' ? 'privacyAccessSubMenuProfile' : ''"
        ></button>
      </siderail-nav>
      <siderail-nav *ngIf="restrictions?.accountDetails ">
        <h2 siderail-header class="siderail-header" [innerHTML]="labels?.AccountDetails.Header" tabIndex="0"></h2>
        <button *ngFor="let nav of clonedAccountDetailsHeadings"
          siderail-link
          [id]="'navid'+nav.Label"
          [innerHTML]="nav.Label"
          (click)="setNavPage(nav.Url)"
          [ngClass]="{'isActive': navPage === nav.Url}"
        ></button>
      </siderail-nav>
      <siderail-nav *ngIf="showCommunicationPreferencesHeader">
        <h2 siderail-header class="siderail-header" [innerHTML]="labels?.CommunicationPreferences.Header" tabIndex="0"></h2>
        <button *ngFor="let nav of clonedCommPreferencesHeadings"
          siderail-link
          [id]="'navid'+nav.Label"
          [innerHTML]="nav.Label"
          (click)="setNavPage(nav.Url)"
          [ngClass]="{'isActive': navPage === nav.Url}"
        ></button>
      </siderail-nav>
    </sidebar-rail>
  </div>

  <div id="tab-content-container" tabindex="-1" class="accordian-container tab-content-container">
    <a href="javascript:void(0)" class="back-arrow" (click)="goToMenu()"><span class="motif-icon motif-left-arrow"></span> Menu</a>
      <ng-container>
          <router-outlet></router-outlet>
      </ng-container>
  </div>
</div>
