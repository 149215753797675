import { AlertModule } from '@anthem/uxd/alert';
import { AppealsAndGrievancesComponent } from './appeals-and-grievances.component';
import { AppealsAndGrievancesFormPreviewComponent } from './shared/appeals-and-grievances-form-preview/appeals-and-grievances-form-preview.component';
import { AppealsAndGrievancesRoutingModule } from './appeals-and-grievances-routing.module';
import { CommonModule } from '@angular/common';
import { DescribeYourSituationComponent } from './shared/describe-your-situation/describe-your-situation.component';
import { InputModule } from '@anthem/uxd/input';
import { IssueDescriptionComponent } from './shared/issue-description/issue-description.component';
import { MemberInformationComponent } from './shared/member-information/member-information.component';
import { ModalModule } from '@anthem/uxd/modal';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ReasonForRequestComponent } from './shared/reason-for-request/reason-for-request.component';
import { SharedComponentsModule } from 'sydmed/libs/shared-components/src/lib/shared-components.module';
import { SharedModule } from 'sydmed/libs/file-attachment/shared.module';
import { AppealsAndGrievancesFormConfirmationComponent } from './shared/appeals-and-grievances-form-confirmation/appeals-and-grievances-form-confirmation.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { ConsentInformationComponent } from './shared/consent-information/consent-information.component';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { AppealsAndGrievancesLandingComponent } from './shared/appeals-and-grievances-landing/appeals-and-grievances-landing.component';
import { GrievanceHealthOptionComponent } from './shared/grievance-health-option/grievance-health-option.component';
import { ReviewSlideoutComponent } from './shared/review-slideout/review-slideout.component';

@NgModule({
  declarations: [
    AppealsAndGrievancesComponent,
    AppealsAndGrievancesFormConfirmationComponent,
    AppealsAndGrievancesFormPreviewComponent,
    DescribeYourSituationComponent,
    FileUploadComponent,
    IssueDescriptionComponent,
    MemberInformationComponent,
    ReasonForRequestComponent,
    ConsentInformationComponent,
    AppealsAndGrievancesLandingComponent,
    GrievanceHealthOptionComponent,
    ReviewSlideoutComponent
  ],
  imports: [
    AlertModule,
    AlertModule,
    AppealsAndGrievancesRoutingModule,
    CommonModule,
    FormsModule,
    IndicatorModule,
    InputModule,
    ModalModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    SharedModule,
    CustomValidatorsModule 
  ],
  exports: [
    FileUploadComponent
  ]
})
export class AppealsAndGrievancesModule { }
