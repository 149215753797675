import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-alerts',
  templateUrl: './header-alerts.component.html',
  styleUrls: ['./header-alerts.component.scss']
})
export class HeaderAlertsComponent implements OnInit {
  @Input() message: string;
  @Input() linkLabel: string;
  @Input() ariaLabel: string;
  @Input() link: string;

  constructor() { }

  ngOnInit(): void {
  }

}
