import { VendorAllergySummaryContent } from '@anthem/mbrportal-profile/components/vendorAllergySummaryCmp.content';
import { GetPatientAllergiesDTO, PatientAllergiesDTO, UpdatePatientAllergyRequest, VendorAllergies, VendorMedicalCondition } from '@anthem/mbrportal-profile/interfaces/vendorAllergy';
import { UpdateHealthConditionDTO, VendorHealthConditions, VendorPatientHealthCondition } from '@anthem/mbrportal-profile/interfaces/vendorHealthConditions';
import { MedicalConditionContent, MedicalConditionSummaryWidgetState } from '@anthem/mbrportal-profile/interfaces/vendorWidgetState';
import { FormatAutoSuggestItem, VendorAllergyContent, VendorHealthConditionsContent } from '@anthem/mbrportal-profile/public_api';
import { Person, Profile } from '@sydney/models';


//TODO: below interfaces are duplicate between profile/pharmacy.
// duplicating to resolve the circular ref issues. this needs to be resolved later
interface Patient extends Person {
  id?: string;
}

interface CostDetails {
  costSaving?: number;
  estimatedCost: number;
}

interface DrugDetails {
  autoEnrollIndicator: boolean;
  costDetails?: CostDetails;
  directions: string;
  expirationDate: string;
  fullFilledBy: string;
  id: string; // orderDrugNDCDetailsID
  lastFilledDate: string;
  lastPricePaid: string;
  name: string;
  ndcNumber: string;
  numberOfDaysSupply: number;
  patientApproved?: boolean;
  patientTEResponse?: string;
  prescriberApproved?: boolean;
  prescriptionNumber: string;
  priorAuthRequired?: boolean;
  quantity: number;
  refillNumber: number;
  refillsLeft: number;
  strength?: string;
}

interface DrugSummary {
  costSavingMessage?: string;
  costSavings?: string;
  estimatedCostLabelValue?: string;
  genericEquivalentDrug?: DrugDetails;
  prescribedDrug: DrugDetails;
  prescriptionName?: string;
  therapeuticEquivalentDrugs?: DrugDetails[];
}

interface Prescription {
  drugSummaries: DrugSummary[];
  id: string; //drugRxNumber;
  patientDetails: Patient;
}

export const patientAllergies: GetPatientAllergiesDTO = {
  members: [
    {
      firstName: 'sam',
      lastName: 'amen',
      mcId: '1',
      allergies: [
        {
          name: 'Penicillin',
          id: 4328
        },
        {
          name: 'Peanuts',
          id: 4327
        },
        {
          name: 'Sulfa',
          id: 4324
        },
        {
          name: 'Acne',
          id: 4324
        }
      ]
    },
    {
      firstName: 'sam1',
      lastName: 'amen1',
      mcId: '2',
      allergies: [
        {
          name: 'Penicillin',
          id: 4328
        },
        {
          name: 'Peanuts',
          id: 4327
        },
        {
          name: 'Sulfa',
          id: 4324
        },
        {
          name: 'Acne',
          id: 4324
        }
      ]
    }
  ]
};

export const memberDetails: Profile.MedicalProfile[] = [
  {
    firstName: 'sam',
    lastName: 'amen',
    memberUid: '1',
    allergies: [
      {
        name: 'Penicillin',
        id: 4328,
        selected: true,
        default: false
      },
      {
        name: 'Peanuts',
        id: 4327,
        selected: true,
        default: false
      },
      {
        name: 'Sulfa',
        id: 4324,
        selected: true,
        default: false
      },
      {
        name: 'Acne',
        id: 4324,
        selected: true,
        default: false
      }
    ]
  },
  {
    firstName: 'sam1',
    lastName: 'amen1',
    memberUid: '2',
    allergies: [
      {
        name: 'Penicillin',
        id: 4328,
        selected: true,
        default: false
      },
      {
        name: 'Peanuts',
        id: 4327,
        selected: true,
        default: false
      },
      {
        name: 'Sulfa',
        id: 4324,
        selected: true,
        default: false
      },
      {
        name: 'Acne',
        id: 4324,
        selected: true,
        default: false
      }
    ]
  }
];

export const memberDetailsHealthConditions: Profile.MedicalProfile[] = [
  {
    firstName: 'sam',
    lastName: 'amen',
    memberUid: '1',
    healthConditions: [
      {
        name: 'Itching',
        id: 4328,
        selected: true,
        default: false
      },
      {
        name: 'Fever',
        id: 4327,
        selected: true,
        default: false
      }
    ]
  }
];

export const orderPrescriptionDetails: Prescription[] = [
  {
    drugSummaries: [],
    id: '1',
    patientDetails: {
      dateOfBirth: '1991/01/08',
      firstName: 'sam',
      id: '1',
      lastName: 'amen'
    }
  },
  {
    drugSummaries: [],
    id: '1',
    patientDetails: {
      dateOfBirth: '1991/01/08',
      firstName: 'sam1',
      id: '2',
      lastName: 'amen1'
    }
  }
];

export const patientDetails: Patient[] = [
  {
    dateOfBirth: '1991/01/08',
    firstName: 'sam',
    id: '1',
    lastName: 'amen'
  },
  {
    dateOfBirth: '1991/01/08',
    firstName: 'sam1',
    id: '2',
    lastName: 'amen1'
  }
];

export const orderPrescriptionDetailsHealthCondition: Prescription[] = [
  {
    drugSummaries: [],
    id: '1',
    patientDetails: {
      dateOfBirth: '1991/01/08',
      firstName: 'SKUSER',
      id: '1',
      lastName: 'USER1'
    }
  }
];

export const medicalProfilePatientDetails: Patient[] = [
  {
    dateOfBirth: '1991/01/08',
    firstName: 'SKUSER',
    id: '123S60057-10',
    lastName: 'USER1',
    middleName: ''
  }
];

export const filteredMemberDetails: PatientAllergiesDTO[] = [
  {
    firstName: 'sam',
    lastName: 'amen',
    mcId: '1',
    allergies: [
      {
        name: 'Penicillin',
        id: 4328
      },
      {
        name: 'Peanuts',
        id: 4327
      },
      {
        name: 'Sulfa',
        id: 4324
      },
      {
        name: 'Acne',
        id: 4324
      }
    ]
  },
  {
    firstName: 'sam1',
    lastName: 'amen1',
    mcId: '2',
    allergies: [
      {
        name: 'Penicillin',
        id: 4328
      },
      {
        name: 'Peanuts',
        id: 4327
      },
      {
        name: 'Sulfa',
        id: 4324
      },
      {
        name: 'Acne',
        id: 4324
      }
    ]
  }
];

export const filteredMemberDetailsHealthConditions: VendorPatientHealthCondition[] = [
  {
    firstName: 'SKUSER',
    lastName: 'USER1',
    mcId: '123S60057-10',
    healthConditions: [
      {
        name: 'Fever',
        id: 4328
      },
      {
        name: 'Nuasea',
        id: 4327
      }
    ]
  }
];

export const patientHealthConditionsSelected: Profile.MedicalProfile[] = [
  {
    healthConditions: [
      { id: 4328, name: 'Fever', selected: true, default: false },
      { id: 4327, name: 'Nuasea', selected: true, default: false }
    ],
    firstName: 'SKUSER',
    lastName: 'USER1',
    memberUid: '123S60057-10'
  }
];

export const emptyMemberDetails: Profile.MedicalProfile[] = [
  {
    firstName: 'sam',
    lastName: 'amen',
    memberUid: '1',
    allergies: []
  },
  {
    firstName: 'sam2',
    lastName: 'amen2',
    memberUid: '2'
  },
  {
    firstName: 'sam1',
    lastName: 'amen1',
    memberUid: '3',
    allergies: [
      {
        name: 'Penicillin',
        id: 4328,
        selected: true,
        default: false
      }
    ]
  }
];

export const allergySuggestions: VendorAllergies = {
  allergies: [
    {
      name: 'Penicillin',
      id: 4328
    },
    {
      name: 'Peanuts',
      id: 4327
    },
    {
      name: 'Sulfa',
      id: 4324
    },
    {
      name: 'Acne',
      id: 4324
    }
  ]
};

export const healthConditionSuggestions: VendorMedicalCondition[] = [
  {
    name: 'Itching',
    id: 4328
  },
  {
    name: 'Fever',
    id: 4327
  }
];

export const transformedHealthConditions = [
  {
    healthConditions: [Object({ id: 2, name: 'Itching', selected: true, default: false }), Object({ id: 214, name: 'Diabetes Mellitus', selected: true, default: false })],
    firstName: 'SKUSER',
    lastName: 'USER1',
    memberUid: '123S60057-10'
  }
];

export const healthConditionsDefault: VendorHealthConditions = {
  healthConditions: [
    {
      id: 1,
      name: 'Fever'
    },
    {
      id: 3,
      name: 'Not Feeling Well'
    }
  ]
};

export const formatAutoSuggestItems: FormatAutoSuggestItem[] = [
  {
    label: 'Penicillin',
    value: 4328
  },
  {
    label: 'Peanuts',
    value: 4327
  },
  {
    label: 'Sulfa',
    value: 4324
  },
  {
    label: 'Acne',
    value: 4324
  }
];

export const defaultAllergies: VendorAllergies = {
  allergies: [
    {
      name: 'Penicillin',
      id: 4328
    },
    {
      name: 'Peanuts',
      id: 4327
    },
    {
      name: 'Sulfa',
      id: 4324
    },
    {
      name: 'Acne',
      id: 4325
    }
  ]
};

export const selectedAllergies: Profile.MedicalCondition[] = [
  {
    name: 'Peanuts',
    id: 4327,
    selected: true,
    default: true
  },
  {
    name: 'Sulfa',
    id: 4324,
    selected: true,
    default: true
  },
  {
    name: 'Sulfa1',
    id: 4329,
    selected: true,
    default: false
  }
];

export const searchedAllergies: Profile.MedicalCondition[] = [
  {
    name: 'Sulfa1',
    id: 4329,
    selected: true,
    default: false
  }
];

export const searchedHealthCondition: Profile.MedicalCondition[] = [
  {
    name: 'Itching',
    id: 4329,
    selected: true,
    default: false
  }
];

export const defaultMedicalConditions: Profile.MedicalCondition[] = [
  {
    name: 'Penicillin',
    id: 4328,
    selected: false,
    default: true
  },
  {
    name: 'Peanuts',
    id: 4327,
    selected: true,
    default: true
  },
  {
    name: 'Sulfa',
    id: 4324,
    selected: true,
    default: true
  },
  {
    name: 'Acne',
    id: 4325,
    selected: false,
    default: true
  }
];

export const commaSeparatedAllergies = 'Penicillin, Peanuts, Sulfa, Acne';

export const vendorAllergyContent: VendorAllergyContent = {
  searchOtherAllergies: 'Search Other Allergies',
  noSuggestion: 'No suggestions found',
  search: 'Search',
  inputItem: {
    ariaLabel: 'Search',
    placeholder: 'Search'
  },
  allergiesFor: '',
  allergiesSubheader: '',
  errorMessages: {
    maxAllergyLimit: 'Maximum amount of allergies selected, please remove one to add an additional allergy',
    errorMessage: ''
  },
  currentSelectionTitle: '',
  loading: '',
  noneRadioButton: '',
  memberRadioButton: '',
  maxNumOfVisibleItems: 0,
  none: 'None',
  radioButtonRole: '',
  radioGroupName: '',
  removeText: 'remove searched allergy',
  saveButton: 'Save',
  cancelButton: 'Cancel',
  allergiesLabel: 'Allergies',
  checkBoxName: 'allergyCondition',
  radioButtonName: 'allergyChoice',
  successMessage: 'Allergies are successfully updated',
  analytics: {
    save: 'saveAllergiesPharmacyModal',
    cancel: 'cancelAllergiesPharmacyModal',
    noneOption: 'noneAllergiesPharmacyModal',
    hasAllergiesOption: 'hasAllergiesPharmacyModal',
    expandCondition: 'expandHealthMedProfileModal',
    maxMedicalConditions: 'maxConditionAddedMedProfileModal',
    selectMedicalCondition: 'selectConditionCheckMedProfileModal',
    deselectMedicalCondition: 'deselectConditionCheckMedProfileModal',
    deleteMedicalCondition: 'deleteConditionIconMedProfileModal',
    medicalConditionDropDown: 'selectConditionDropdwnMedProfileModal',
    profileCancelMedicalCondition: 'cancelBtnMedProfileModal',
    hasProfileMedicalCondition: 'haveConditionMedProfileModal',
    profileNoneCondition: 'noneConditionMedProfileModal',
    profileUpdateMedicalCondition: 'updateBtnMedProfileModal'
  }
};

export const vendorAllergySummaryContent: VendorAllergySummaryContent = {
  addAllergies: 'add Allergies',
  allergy: vendorAllergyContent,
  errorMessage: '',
  vendorError: {
    errorMessage: "Sorry, looks like something isn't working.",
    pageLevelErrorMessage: "Sorry, looks like something isn't working. Please give us some time to fix it. Then, try again.",
    pleaseTryAgain: 'Please try again.'
  },
  loading: 'loading',
  makeSelectionText: 'Please make a selection',
  member: 'member',
  noAllergies: 'No Allergies',
  title: 'Allergies',
  changeButton: 'change',
  analytics: {
    add: 'addAllergiesBtnReoRx',
    change: 'updateAllergiesBtnReoRx',
    noAllergies: 'noAllergiesBtnReoRx'
  }
};

export const medicalConditionSummaryWidgetState: Omit<MedicalConditionSummaryWidgetState, 'onNoAllergiesCheckboxClick' | 'onChangeAllergyClick' | 'vendorMedicalProfiles' | 'onNoAllergiesClick'> = {
  button: 'add Allergies',
  changeButton: 'change',
  noAllergiesText: 'No Allergies',
  columns: [
    {
      title: 'member'
    },
    {
      title: 'Allergies'
    }
  ],
  makeSelectionText: 'Please make a selection',
  title: 'Allergies',
  ajaxIndicator: 'SUCCESS',
  content: {
    analytics: {
      add: 'addAllergiesBtnReoRx',
      change: 'updateAllergiesBtnReoRx',
      noAllergies: 'noAllergiesBtnReoRx'
    },
    errorMessage: '',
    loading: '',
    pleaseTryAgain: ''
  }
};

export const medicalConditionWidgetStateContent: MedicalConditionContent = {
  analytics: {
    cancel: vendorAllergyContent.analytics?.cancel,
    hasAllergiesOption: vendorAllergyContent.analytics?.hasAllergiesOption,
    noneOption: vendorAllergyContent.analytics?.noneOption,
    save: vendorAllergyContent.analytics?.save,
    expandCondition: vendorAllergyContent.analytics?.expandCondition,
    maxMedicalConditions: vendorAllergyContent.analytics?.maxMedicalConditions,
    selectMedicalCondition: vendorAllergyContent.analytics?.selectMedicalCondition,
    deselectMedicalCondition: vendorAllergyContent.analytics?.deselectMedicalCondition,
    deleteMedicalCondition: vendorAllergyContent.analytics?.deleteMedicalCondition,
    medicalConditionDropDown: vendorAllergyContent.analytics?.medicalConditionDropDown,
    profileCancelMedicalCondition: vendorAllergyContent.analytics?.profileCancelMedicalCondition,
    hasProfileMedicalCondition: vendorAllergyContent.analytics?.hasProfileMedicalCondition,
    profileNoneCondition: vendorAllergyContent.analytics?.profileNoneCondition,
    profileUpdateMedicalCondition: vendorAllergyContent.analytics?.profileUpdateMedicalCondition
  },
  cancelButton: vendorAllergyContent.cancelButton,
  errorMessages: {
    errorMessage: vendorAllergyContent.errorMessages.errorMessage,
    maxMedicalConditionLimit: vendorAllergyContent.errorMessages.maxAllergyLimit
  },
  header: `${vendorAllergyContent.allergiesFor} ${memberDetails[0]?.firstName} ${memberDetails[0]?.lastName}`,
  inputItem: {
    ariaLabel: vendorAllergyContent.inputItem.ariaLabel,
    placeholder: vendorAllergyContent.inputItem.placeholder
  },
  medicalConditionsFor: vendorAllergyContent.allergiesFor,
  noSuggestion: vendorAllergyContent.noSuggestion,
  radioButtonRole: vendorAllergyContent.radioButtonRole,
  removeText: vendorAllergyContent.removeText,
  radioButtonDetails: {
    id: vendorAllergyContent.radioGroupName,
    name: vendorAllergyContent.radioGroupName,
    alignVertical: true,
    showLegendAsLabel: true,
    options: [
      {
        analytics: vendorAllergyContent.analytics.noneOption,
        id: vendorAllergyContent.noneRadioButton,
        label: vendorAllergyContent.none,
        value: false
      },
      {
        analytics: vendorAllergyContent.analytics.hasAllergiesOption,
        id: vendorAllergyContent.memberRadioButton,
        label: `${memberDetails[0]?.firstName} ${memberDetails[0]?.lastName} ${vendorAllergyContent.currentSelectionTitle}`,
        value: true
      }
    ]
  },
  saveButton: vendorAllergyContent.saveButton,
  search: vendorAllergyContent.search,
  searchOtherMedicalConditions: vendorAllergyContent.searchOtherAllergies,
  subheader: vendorAllergyContent.allergiesSubheader,
  accordianLabel: vendorAllergyContent.allergiesLabel,
  maxNumOfVisibleItems: vendorAllergyContent.maxNumOfVisibleItems,
  checkBoxName: 'allergyCondition',
  radioButtonName: 'allergyChoice',
  successMessage: 'Allergies are successfully updated'
};

export const allergySuggestionsWithALetter: VendorMedicalCondition[] = [
  {
    name: 'Acne',
    id: 4324
  }
];

export const healthConditionSuggestionsWithPLetter: VendorMedicalCondition[] = [
  {
    name: 'Penicillis',
    id: 4324
  }
];

export const updateAllergies: UpdatePatientAllergyRequest = {
  allergies: allergySuggestions.allergies,
  selectedMemberUid: 'wGvq4TkCX0isdjkLWwJnLUw-vCdEaDT9n_Q5wBguH9pro2W6Xl9tOtcnYFuRDz7tHysFfMSf'
};

export const updateHealthCondition: UpdateHealthConditionDTO = {
  healthConditions: healthConditionSuggestionsWithPLetter,
  selectedMemberUid: '1234'
};

export const updatedMedicalConditions: Profile.MedicalCondition[] = [
  {
    name: 'Penicillin',
    id: 4328,
    selected: true,
    default: true
  },
  {
    name: 'Peanuts',
    id: 4327,
    selected: true,
    default: true
  },
  {
    name: 'Sulfa',
    id: 4324,
    selected: false,
    default: true
  },
  {
    name: 'Acne',
    id: 4325,
    selected: false,
    default: true
  }
];

export const healthConditionContent: VendorHealthConditionsContent = {
  analytics: {
    save: 'saveAllergiesPharmacyModal',
    cancel: 'cancelAllergiesPharmacyModal',
    noneOption: 'noneAllergiesPharmacyModal',
    hasAllergiesOption: 'hasAllergiesPharmacyModal',
    expandCondition: 'expandHealthMedProfileModal',
    maxMedicalConditions: 'maxConditionAddedMedProfileModal',
    selectMedicalCondition: 'selectConditionCheckMedProfileModal',
    deselectMedicalCondition: 'deselectConditionCheckMedProfileModal',
    deleteMedicalCondition: 'deleteConditionIconMedProfileModal',
    medicalConditionDropDown: 'selectConditionDropdwnMedProfileModal',
    profileCancelMedicalCondition: 'cancelBtnMedProfileModal',
    hasProfileMedicalCondition: 'haveConditionMedProfileModal',
    profileNoneCondition: 'noneConditionMedProfileModal',
    profileUpdateMedicalCondition: 'updateBtnMedProfileModal'
  },
  errorMessages: {
    maxHealthConditionLimit: 'Maximum amount of health conditions selected, please remove one to add an additional health condition.',
    errorMessage: "Sorry, looks like something isn't working. Please give us some time to fix it. Then, try again."
  },
  inputItem: {
    ariaLabel: 'Search',
    placeholder: 'Search'
  },
  radioGroupName: 'healthConditionRbtn',
  radioButtonRole: 'Radio Button',
  removeText: 'remove searched health condition',
  search: 'Search',
  none: 'None',
  saveButton: 'Save',
  cancelButton: 'Cancel',
  noneRadioButton: 'noneHealthRadioButton',
  memberRadioButton: 'memberHealthRadioButton',
  healthConditionsFor: 'Health conditions for',
  healthConditionSubheader: 'We will need to know about any health conditions before we can complete this order. if no health conditions, please select None.',
  healthConditionLabel: 'Health conditions',
  maxNumOfVisibleItems: 3,
  searchOtherHealthConditions: 'Search Other Health Conditions',
  noSuggestion: 'No suggestions found',
  loading: 'Loading',
  currentSelectionTitle: 'has the following health conditions',
  checkBoxName: 'healthCondition',
  radioButtonName: 'healthChoice',
  successMessage: 'Health conditions are successfully updated'
};

export const healthConditionStateContent: MedicalConditionContent = {
  analytics: {
    save: 'saveAllergiesPharmacyModal',
    cancel: 'cancelAllergiesPharmacyModal',
    noneOption: 'noneAllergiesPharmacyModal',
    hasAllergiesOption: 'hasAllergiesPharmacyModal',
    expandCondition: 'expandHealthMedProfileModal',
    maxMedicalConditions: 'maxConditionAddedMedProfileModal',
    selectMedicalCondition: 'selectConditionCheckMedProfileModal',
    deselectMedicalCondition: 'deselectConditionCheckMedProfileModal',
    deleteMedicalCondition: 'deleteConditionIconMedProfileModal',
    medicalConditionDropDown: 'selectConditionDropdwnMedProfileModal',
    profileCancelMedicalCondition: 'cancelBtnMedProfileModal',
    hasProfileMedicalCondition: 'haveConditionMedProfileModal',
    profileNoneCondition: 'noneConditionMedProfileModal',
    profileUpdateMedicalCondition: 'updateBtnMedProfileModal'
  },
  cancelButton: healthConditionContent.cancelButton,
  errorMessages: {
    errorMessage: healthConditionContent.errorMessages.errorMessage,
    maxMedicalConditionLimit: healthConditionContent.errorMessages.maxHealthConditionLimit
  },
  header: `${healthConditionContent.healthConditionsFor} ${memberDetails[0]?.firstName} ${memberDetails[0]?.lastName}`,
  inputItem: {
    ariaLabel: healthConditionContent.inputItem.ariaLabel,
    placeholder: healthConditionContent.inputItem.placeholder
  },
  medicalConditionsFor: healthConditionContent.healthConditionsFor,
  noSuggestion: healthConditionContent.noSuggestion,
  radioButtonRole: healthConditionContent.radioButtonRole,
  radioButtonDetails: {
    id: 'healthConditionRbtn',
    name: healthConditionContent.radioGroupName,
    alignVertical: true,
    showLegendAsLabel: true,
    options: [
      {
        id: healthConditionContent.noneRadioButton,
        label: healthConditionContent.none,
        value: false
      },
      {
        id: healthConditionContent.memberRadioButton,
        label: `${memberDetails[0]?.firstName} ${memberDetails[0]?.lastName} ${healthConditionContent.currentSelectionTitle}`,
        value: true
      }
    ]
  },
  saveButton: healthConditionContent.saveButton,
  search: healthConditionContent.search,
  searchOtherMedicalConditions: healthConditionContent.searchOtherHealthConditions,
  subheader: healthConditionContent.healthConditionSubheader,
  accordianLabel: 'Health conditions',
  maxNumOfVisibleItems: healthConditionContent.maxNumOfVisibleItems,
  checkBoxName: 'healthCondition',
  radioButtonName: 'healthChoice',
  removeText: 'remove searched allergy',
  successMessage: 'Health conditions are successfully updated'
};

export const defaultHealthConditions = {
  healthConditions: [
    {
      id: 2,
      name: 'Itching'
    },
    {
      id: 3,
      name: 'Pruritus'
    },
    {
      id: 4,
      name: 'Loss of Appetite'
    },
    {
      id: 5,
      name: 'Anorexia'
    },
    {
      id: 6,
      name: 'Excessive Sweating'
    },
    {
      id: 7,
      name: 'Diaphoresis'
    },
    {
      id: 8,
      name: 'Not Feeling Well'
    },
    {
      id: 9,
      name: 'Malaise'
    },
    {
      id: 10,
      name: 'Coma'
    },
    {
      id: 11,
      name: 'Bluish Discoloration'
    },
    {
      id: 12,
      name: 'Cyanosis'
    },
    {
      id: 13,
      name: 'Fever'
    },
    {
      id: 14,
      name: 'Seizure'
    },
    {
      id: 15,
      name: 'Feeling Tense'
    },
    {
      id: 16,
      name: 'Nervousness'
    },
    {
      id: 17,
      name: 'Restlessness'
    },
    {
      id: 0,
      name: 'None'
    }
  ]
};

export const patientHealthConditions = {
  members: [
    {
      mcId: '123S60057-10',
      firstName: 'SKUSER',
      lastName: 'USER1',
      middleName: '',
      dob: '1971-01-01',
      healthConditions: [
        {
          id: 2,
          name: 'Itching'
        },
        {
          id: 214,
          name: 'Diabetes Mellitus'
        }
      ]
    }
  ]
};

export const patientHealthDTO: VendorPatientHealthCondition[] = [
  {
    healthConditions: [
      { name: 'Fever', id: 4328 },
      { name: 'Nuasea', id: 4327 }
    ],
    firstName: 'SKUSER',
    lastName: 'USER1',
    mcId: '123S60057-10'
  }
];

export const vendorPatientHealthCondition = [
  {
    healthConditions: [
      {
        id: 2,
        name: 'Itching'
      },
      {
        id: 214,
        name: 'Diabetes Mellitus'
      }
    ],
    firstName: 'SKUSER',
    lastName: 'USER1',
    mcId: '1'
  }
];

export const vendorPatientHealthConditionTransformed = [
  { id: 2, name: 'Itching', selected: true, default: false },
  { id: 214, name: 'Diabetes Mellitus', selected: true, default: false }
];
