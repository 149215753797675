import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)], //TODO Use Child Routes
  imports: [RouterModule.forChild(routes)],  
	exports: [RouterModule]
})
export class SydmedSharedRoutingModule { }