import { Injectable } from "@angular/core";
import { HttpClientService } from "sydmed/libs/http-client-service/src/public-api";
import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";
@Injectable({
  providedIn: 'root'
})
export class ThirdPartyInsuranceService {

  constructor(protected http: HttpClientService) { }

  // get third party insurances
  public getThirdPartyInsurance() {
    return this.http.getObservable(UrlProperties.UrlProperties.endPoints.thirdPartyInsurance);
  }
}
