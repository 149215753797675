import { Component, Input, OnInit } from '@angular/core';
@Component( {
  selector: 'app-prescription-list-header',
  templateUrl: './prescription-list-header.component.html',
  styleUrls: [ './prescription-list-header.component.scss' ]
} )
export class PrescriptionListHeaderComponent implements OnInit {
  @Input() member: string;
  @Input() dateRange: string;
  @Input() numberOfItems: number;
  @Input() content: string;
  @Input() searchTerm: string;

  constructor() { }
  ngOnInit(): void { }

  isValidNumber( num: number ) {
    return ( num !== undefined || num !== null );
  }

}
