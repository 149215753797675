import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';

import { TextTermsAndConditionsRoutingModule } from './text-terms-and-conditions-routing.module';
import { TextTermsAndConditionsComponent } from './text-terms-and-conditions.component';


@NgModule({
  declarations: [TextTermsAndConditionsComponent],
  imports: [
    CommonModule,
    TextTermsAndConditionsRoutingModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [TextTermsAndConditionsComponent]
})
export class TextTermsAndConditionsModule { }
