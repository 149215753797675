import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FocusManagementService } from '../../sydmed-shared/focus-management-service/focus-management.service';
//import { timingSafeEqual } from 'crypto'; // TODO

@Component({
  selector: 'shm-registration',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class RegistrationAppComponent implements OnInit {
  title = 'shm-registration';
  router: Router;
  activeRoute: ActivatedRoute;


  constructor(router: Router, activeRoute: ActivatedRoute, private focusManangementService: FocusManagementService) {
    this.router = router;
    this.activeRoute = activeRoute;
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      setTimeout(() => {
        this.focusManangementService.focusToHeader();
      })
    });
  }

  ngOnInit() {}

}