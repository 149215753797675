import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThirdPartyInsuranceComponent } from './third-party-insurance/third-party-insurance.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { AlertModule } from '@anthem/uxd/alert';
import { AccordianModule } from '@anthem/uxd/accordion';
import { ThirdPartyInsuranceRoutingModule } from './thid-party-insurance-routing.module';
import { ThirdPartyInsuranceDetailsComponent } from './third-party-insurance-details/third-party-insurance-details.component';
import { PhoneNumPipe } from 'sydmed/libs/common-pipes/src/lib/phone-number/phone-num.pipe';
import { OrderBy } from '@anthem/mbrportal/shared/pipes/orderBy';

@NgModule({
  declarations: [
    ThirdPartyInsuranceComponent,
    ThirdPartyInsuranceDetailsComponent
  ],
  imports: [
    CommonModule,
    ThirdPartyInsuranceRoutingModule,
    IndicatorModule,
    AlertModule,
    AccordianModule
  ],
  providers: [
    OrderBy,
    PhoneNumPipe
  ],
  bootstrap: [ThirdPartyInsuranceModule]
})
export class ThirdPartyInsuranceModule { }
