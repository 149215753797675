import { Component, OnInit, Input } from '@angular/core';
import { Labels } from '../interfaces/third-party-insurance-content';

@Component({
  selector: 'app-third-party-insurance-details',
  templateUrl: './third-party-insurance-details.component.html',
  styleUrls: ['./third-party-insurance-details.component.scss']
})
export class ThirdPartyInsuranceDetailsComponent implements OnInit {

  @Input() labels: Labels;
  @Input() details: any;

  ngOnInit(): void { }

}
