<div class="uxd-theme-motif container">
    <div class="margin-top-20 reg-top-1 flex-column-center">
        <div class="width-100 margin-bottom-48" *ngIf="aslError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{aslError}}</span>
        </div>
        <div class="width-100 margin-bottom-48" *ngIf="codeResent" data-uxd-alert-cmp-v2 [alert]="{alertType: 'positive'}">
            <span>{{jsonLabels?.Resend_Code_Success}}</span>
        </div>
    </div>
    <div class="max-width-elements">
        <h1 tabindex="-1" class="font-32 text-left margin-bottom-32">{{jsonLabels?.Page_Heading}}</h1>
        <form #validateOTPForm="ngForm" [formGroup]="ValidateOneTimePasscodeForm" class="text-center validate-otp-form-container"
        (ngSubmit)="validateCode()" id="ValidateOneTimePasscodeForm" name="ValidateOneTimePasscodeForm">

        <div class="margin-bottom-32">
            <div>

                <div class="mask-wrapper">
                    <label for="secCode" hidden>{{jsonLabels?.Field_Labels.Security_Code}}</label>
                    <input #secCode class="ant-input-long ant-text-input" id="secCode"
                        formControlName="secCode" name="secCode" type="password" aria-label="Enter security code" 
                        (keyup)="onChangeInput()" required aria-describedby="securityCodeError">
                    <span (click)="showHide(secCode, jsonLabels?.Field_Labels.Security_Code)" (keyup.enter)="showHide(secCode, jsonLabels?.Field_Labels.Security_Code)" tabindex="0" role ="button" aria-pressed="false" [attr.aria-label]="'unmask ' + jsonLabels?.Field_Labels.Security_Code"
                        class="fa md ant-info-icon ant-data-security-mask fa-eye-slash"><span class="sr-only">{{jsonLabels?.Field_Labels.Mask_Code}}</span>
                    </span>
                </div>

                <ul id="securityCodeError" class="ant-bar-chart-group text-left font-12 margin-top-14 error"
                    *ngIf="ValidateOneTimePasscodeForm.controls['secCode']?.touched && ValidateOneTimePasscodeForm.controls['secCode']?.invalid" aria-live="polite">
                    <li *ngIf="ValidateOneTimePasscodeForm.controls['secCode']?.errors.required">
                        <div>
                            <span class="fas fa-times font-14 margin-right-8"></span>
                            <span>{{jsonLabels?.Field_Labels.Security_Code}}</span>
                            <span>{{jsonErrors.Required_err}}</span>
                        </div>
                    </li>
                    <li *ngIf="ValidateOneTimePasscodeForm.controls['secCode']?.errors.minlength || ValidateOneTimePasscodeForm.controls['secCode']?.errors.maxlength">
                        <div>
                            <span class="fas fa-times font-14 margin-right-8"></span>
                            <span>{{jsonErrors.SecCode_length}}</span>
                        </div>
                    </li>
                </ul>
                <ul id="securityCodeError" class="ant-bar-chart-group text-left font-12 margin-top-14" *ngIf="incorrectSecurityCode" aria-live="polite">
                    <li>
                        <div>
                            <span class="fas fa-times font-14 margin-right-8"></span>
                            <span>{{jsonErrors.SecCode_err}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div>
            <div class="margin-bottom-32">
                <p class="text-left">{{jsonLabels?.Instructions[0]}}</p>
                <p class="text-left bold">
                    {{securityCodeModel.selected.maskedData}}</p>
                <p class="text-left">
                    {{jsonLabels?.Instructions[1]}} </p>
            </div>

            <div>

                <div
                    class="max-width margin-bottom-32">
                    <div class="form-item text-left margin-0">
                        <input type="radio" formControlName="rememberDevice" class="prOption font-16"
                            id="remember-device" name="rememberDevice" value="yes"
                            attr.data-analytics="{{jsonLabels?.Options.Remember_Device.Data_Analytics}}">

                        <label class="prLabel margin-0" for="remember-device"
                            [ngClass]="{'active':ValidateOneTimePasscodeForm.controls['rememberDevice'].value === 'yes', 'invalidInput': ValidateOneTimePasscodeForm.controls['rememberDevice']?.touched && ValidateOneTimePasscodeForm.controls['rememberDevice']?.invalid}">
                            <b class="font-16 validate-one-time-bold"
                                [ngClass]="{'invalid': ValidateOneTimePasscodeForm.controls['rememberDevice']?.touched && ValidateOneTimePasscodeForm.controls['rememberDevice']?.invalid}">{{jsonLabels?.Options.Remember_Device.Heading}}</b>
                            <p class="margin-left-24 margin-top-4">{{jsonLabels?.Options.Remember_Device.Description}}</p>
                        </label>
                    </div>
                </div>
            </div>

                <div>
                    <div
                        class="max-width margin-bottom-32">
                        <div class="form-item text-left margin-0">
                            <input type="radio" formControlName="rememberDevice" class="prOption font-16"
                                id="do-not-remember-device" name="rememberDevice" value="no"
                                attr.data-analytics="{{jsonLabels?.Options.Do_Not_Remember_Device.Data_Analytics}}">

                            <label class="prLabel margin-0" for="do-not-remember-device"
                                [ngClass]="{'active':ValidateOneTimePasscodeForm.controls['rememberDevice'].value === 'no', 'invalidInput': ValidateOneTimePasscodeForm.controls['rememberDevice']?.touched && ValidateOneTimePasscodeForm.controls['rememberDevice']?.invalid}">
                                <b class="font-16 validate-one-time-bold"
                                    [ngClass]="{'invalid': ValidateOneTimePasscodeForm.controls['rememberDevice']?.touched && ValidateOneTimePasscodeForm.controls['rememberDevice']?.invalid}">{{jsonLabels?.Options.Do_Not_Remember_Device.Heading}}</b>
                                <p class="margin-left-24 margin-top-4">{{jsonLabels?.Options.Do_Not_Remember_Device.Description}}</p>
                            </label>
                        </div>
                        <ul class="ant-bar-chart-group text-left font-12 margin-top-14 error"
                            *ngIf="ValidateOneTimePasscodeForm.controls['rememberDevice']?.touched && ValidateOneTimePasscodeForm.controls['rememberDevice']?.invalid" role="alert">
                            <li
                                *ngIf="ValidateOneTimePasscodeForm.controls['rememberDevice']?.errors.required">
                                <div>
                                    <span class="fas fa-times font-14 margin-right-8"></span>
                                    <span tabindex="0">{{jsonErrors.ARN_Required_err}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            <div class="flex-horizontal-start margin-bottom-48">
                <span class="margin-right-8" (click)="resendSecurityCode()">
                    <a class="ant-action-link" href="javascript:void(0)"
                        attr.data-analytics="{{jsonLabels?.Resend_Data_Analytics}}">
                        <span class="font-16">{{jsonLabels?.Resend_Link_Text}}<i class='fas fa-angle-right'></i></span>
                    </a>
                </span>
            </div>

            <div>
                <div class="form-item margin-bottom-70 flex-horizontal-end">
                    <button type="button" class="btn btn-secondary margin-right-16"
                        (click)="back()"
                        attr.data-analytics="{{jsonLabels?.Buttons.Back_Data_Analytics}}">{{jsonLabels?.Buttons.Back}}</button>
                    <button type="submit" class="btn btn-primary color"
                        attr.data-analytics="{{jsonLabels?.Buttons.Next_Data_Analytics}}">{{jsonLabels?.Buttons.Next}}</button>
                </div>
            </div>
        </div>
    </form>
    </div>

</div>