<div class="container" *ngIf="labels">
    <div class="lr-pad">
        <inline-alert-container [name]="'payment-alert'" [alertType]="alertPosition"></inline-alert-container>
    </div>
    <div class="lrt-pad" *ngIf="isCoronaActive">
        <div class="row">
            <div class="card large-card card-flat card-popover">
                <div class="row display-flex">
                    <div class="flex-size">
                        <span class="motif-icon motif-attention"></span>
                    </div>
                    <div class="col-md-11 col-sm-11">
                        <h2 [attr.aria-label]="labels.CoronaVirusUpdate.Heading"> {{labels.CoronaVirusUpdate.Heading}}</h2>
                        <p>{{labels.CoronaVirusUpdate.DescriptionEnglish}}</p>
                        <p>{{labels.CoronaVirusUpdate.DescriptionSpanish}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <br/>
    <div class="lr-pad">
        <div class="row">
            <h2 class="section-header-two" [attr.aria-label]="labels.CurrentBills">{{labels.CurrentBills}}</h2>
        </div>
    </div>
    <app-current-bills [isCoronaActive]="isCoronaActive" [errorMsgs]="errorMsgs" [content]="labels"></app-current-bills>
    
    <br/>
    <br/>
    <div class="lr-pad">
        <div class="row">
            <h2 class="section-header-two" [attr.aria-label]="labels.RecentPayments">{{labels.RecentPayments}}</h2>
            <p [attr.aria-label]="labels.RecentPaymentsDesc">{{labels.RecentPaymentsDesc}}</p>
        </div>
    </div>
    <div class="container motif-margin-0">          
        <div class="display-block" *ngIf="isPaymentsError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{errorMsgs.GeneralSystemErrorWithoutCode}}</span>
        </div>
    </div>
    <div class="container" *ngIf="isPaymentsLoading && !isPaymentsError">
        <div class="row" id="search-loader">
            <uxd-spinner ></uxd-spinner>
        </div>      
    </div>
    <app-payments-list [content]="labels" [displayList]="displayList" *ngIf="!isPaymentsLoading && !isPaymentsError"></app-payments-list>
    <div class="lr-pad">
        <div class="row">
            <p class="history-link">
                <span class="motif-icon motif-right-round"></span>
                <a routerLink="/secure/payment-history" [attr.aria-label]="labels.ViewPaymentHistory" data-analytics="">{{labels.ViewPaymentHistory}}</a>
            </p>
        </div>
    </div>
    <br/>
    <br/>
    <div class="lr-pad section">
        <div class="row">
            <h2 class="section-header-two">{{labels.OtherEasyWays.Heading}}</h2>
            <p>{{labels.OtherEasyWays.SubHeading}} <a href="{{labels.OtherEasyWays.MemberHandbookUrlLink}}" [attr.aria-label]="labels.OtherEasyWays.MemberHandbook" target="_blank" rel="noopener noreferrer">{{labels.OtherEasyWays.MemberHandbook}}</a></p>
        </div>
        <div class="row">
            <h3>{{labels.OtherEasyWays.MoneyGramHeading}}</h3>
            <p>{{labels.OtherEasyWays.MoneyGramDescription}} <a href="{{labels.OtherEasyWays.MoneyGramUrlLink}}" target="_blank" [attr.aria-label]="labels.OtherEasyWays.MoneyGramUrlLink" rel="noopener noreferrer">{{labels.OtherEasyWays.MoneyGram}}</a> <span class="motif-icon motif-external-link pl-1"></span> {{labels.OtherEasyWays.MoneyGramDescription2}}</p>
        </div>
        <br/>
        <div class="row">
            <h3>{{labels.OtherEasyWays.EmployerContributionHeading}}</h3>
            <p>{{labels.OtherEasyWays.EmployerContributionDescription}}</p>
        </div>
        <br/>
        <div class="row">
            <h3>{{labels.OtherEasyWays.HereToHelpHeading}}</h3>
            <p>{{labels.OtherEasyWays.HereToHelpDescription}}</p>
            <p class="mt-20"><a routerLink="{{labels.OtherEasyWays.ContactUsLink}}" [attr.aria-label]="labels.OtherEasyWays.ContactUs" >{{labels.OtherEasyWays.ContactUs}}</a></p>
        </div>
        <br/>
        <br/>
    </div>
</div>