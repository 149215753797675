import { Inject, Injectable } from '@angular/core';
import { IWindow } from '@anthem/mbrportal/core';
import { AppConfig, IAppSession } from '@anthem/mbrportal/main';
import { DataHelper } from '@anthem/mbrportal/shared';
import { Subject } from 'rxjs';

/**
 *  Acts as app session
 */
@Injectable()
export class TcpAppSession implements IAppSession {
  metaData: any = {
    brandCd: 'ABCBS',
    firstNm: 'John'
  };

  wcsHash: any = 'wcsHash';
  templateHash: string = '';
  claims: any = {};
  healthwellness: any = {};
  isDebugMode: boolean = false;
  isNg1Mode: boolean = true;
  landingPage: any = {
    alertShowed: false,
    alertClosed: false,
    commPrefAlert: false
  };

  hpoContractUid: string = '';
  omeMap: any = {};
  isNMCEligible: boolean = false;
  isVivrEligible: boolean = false;
  pageTitle: string = '';
  pageName: string = '';
  isIframeMode = false;
  iframeData: any = {
    urls: {},
    contractUid: ''
  };

  serverTime: any;
  timeStamp: any = {};
  isAlertOpened = false;
  metadataEvent = new Subject<any>();
  //TODO: incoportate new pre authentication changes and make authComplete false by default
  authComplete = true;
  webMDTermsAccepted: boolean = false;
  engageEvent = new Subject<any>();
  dashboardLoadComplete = false;
  isPartnerLogin: boolean = false;
  isMobileRequest: boolean = false;
  mobileOS: string = '';
  userLocation: any = null;
  isPFLoaded: boolean = false;
  showOnBoardingModal: boolean = false;
  dataFromPf: any = {};
  //temp change and will be removed eventually after CVS fixes at their end.
  rxList: any = null;

  constructor(private _appConfig: AppConfig, protected dataHlpr: DataHelper, @Inject('Window') private _window: IWindow) {}

  /**
   *  contruct logout url using hostname or brand combination
   *
   */
  getLogoutUrl(): string {
    let url = '';
    let logoutUrl = this._appConfig.web.rcpLogoutUrl;
    if (this.isVivrEligible && this.metaData.brandCd.toLowerCase() === 'abc') {
      logoutUrl = this._appConfig.web.vivr.caLogouturl;
    } else if (this.isVivrEligible) {
      logoutUrl = this._appConfig.web.vivr.logouturl;
    }
    // we are sending the brand code to extract the state to get state specific logout url Example BCBSKC or BCBSKS
    else if (this.metaData.brandCd) {
      logoutUrl = logoutUrl + '?brandCd=' + this.metaData.brandCd.toLowerCase();
    }
    url = logoutUrl.replace(/host/g, this._window.location.host);
    return url;
  }

  hasGAContent(): boolean {
    const eligibility: any = this.metaData.eligibility;
    if (eligibility && eligibility.length > 0) {
      for (let i: number = 0; i < eligibility.length; i++) {
        let planIndicators: Array<string> = eligibility[i].planIndicators;
        if (planIndicators && planIndicators.includes('HAS_GA_CONTENT')) {
          return true;
        }
      }
    }
    return false;
  }

  getPrimaryEligibility() {
    let rcpElig: any = [];
    let eligibility = this.metaData.eligibility;
    if (eligibility && eligibility.length > 0) {
      for (let i = 0; i < eligibility.length; i++) {
        if (eligibility[i].defaultContract) {
          rcpElig.push(eligibility[i]);
          break;
        }
      }
    }
    if (rcpElig.length) {
      return rcpElig[0];
    } else {
      return null;
    }
  }

  onOrOffExchange() {
    let ret: boolean = false;
    const features: any = this.metaData.features;
    if (!features || features.length === 0) {
      return ret;
    }

    if (features && (features.indexOf('ON_EXCHANGE') >= 0 || features.indexOf('OFF_EXCHANGE') >= 0)) {
      ret = true;
    }

    return ret;
  }

  onOrOffExchangeDefault() {
    let ret: boolean = false;
    let e: any = this.getPrimaryEligibility();
    if (!e || !e.planIndicators || e.planIndicators.length === 0) {
      return ret;
    }

    if (e.planIndicators && (e.planIndicators.indexOf('ON_EXCHANGE') >= 0 || e.planIndicators.indexOf('OFF_EXCHANGE') >= 0)) {
      ret = true;
    }

    return ret;
  }

  /** Returns true if member has active MED coverage
   * @param e: eligibility object
   */
  hasActiveMedical() {
    let ret = false;
    let e = this.getPrimaryEligibility();
    if (!e.coverage || e.coverage.length === 0) {
      return ret;
    }

    for (let i = 0; i < e.coverage.length; i++) {
      let product: any[] = e.coverage[i].product;
      if (!Array.isArray(product)) {
        continue;
      }
      let statusCode = e.coverage[i].statusCd;
      if (!this.dataHlpr.isObject(statusCode)) {
        continue;
      }
      if (statusCode.code === 'A') {
        for (let j = 0; j < product.length; j++) {
          if (Array.isArray(product[j].coverageType)) {
            let coverageType = product[j].coverageType;
            for (let k = 0; k < coverageType.length; k++) {
              if (coverageType[k].coverageTypeCd.code && coverageType[k].coverageTypeCd.code === 'MED') {
                ret = true;
                break;
              }
            }
          }
        }
      }
    }

    return ret;
  }

  hasMedical() {
    let ret = false;
    let e: any = this.getPrimaryEligibility();
    if (!e || !e.defaultCoverageTypeCd || e.defaultCoverageTypeCd.length === 0) {
      return ret;
    }

    for (let i = 0; i < e.defaultCoverageTypeCd.length; i++) {
      let covType: any = e.defaultCoverageTypeCd[i].coverageTypeCd.code;
      if (!this.dataHlpr.isEmptyString(covType) && this.dataHlpr.areEqualStrings(covType, 'MED')) {
        ret = true;
        break;
      }
    }

    return ret;
  }

  hasFutureActiveMedical() {
    let isFutureActive = false;
    let e = this.getPrimaryEligibility();
    if (!e.coverage || e.coverage.length === 0) {
      return isFutureActive;
    }

    for (const coverage of e.coverage) {
      const product = coverage.product;
      const statusCode = coverage.statusCd;
      if (statusCode.code === 'F' && this.dataHlpr.isNonEmptyArray(product)) {
        for (let j = 0; j < product.length; j++) {
          let coverageType = product[j].coverageType;
          for (let k = 0; k < coverageType.length; k++) {
            if (coverageType[k].coverageTypeCd.code && coverageType[k].coverageTypeCd.code === 'MED') {
              isFutureActive = true;
              break;
            }
          }
        }
      }
    }
    return isFutureActive;
  }

  getLoginUrl(brand: string = '') {
    let url = (<any>this._appConfig).web.loginEndpoints.anthemEndpoint;
    switch (brand || this.metaData.brandCd) {
      case 'ABC':
        url = (<any>this._appConfig).web.loginEndpoints.caEndpoint;
        break;
      case 'BCBSGA':
        url = (<any>this._appConfig).web.loginEndpoints.bcbsgaEndpoint;
        break;
      case 'EBCBS':
        url = (<any>this._appConfig).web.loginEndpoints.empireBlueEndpoint;
        break;
    }

    return url;
  }

  getAllRCPPlans() {
    let allRcpElig: any = [];
    if (this.metaData.eligibility && this.metaData.eligibility.length > 0) {
      this.metaData.eligibility.forEach((elg: any) => {
        if (elg.webChannelType && elg.webChannelType.code === 'RCP') {
          allRcpElig.push(elg);
        }
      });
    }
    return allRcpElig;
  }

  /*
    This method is a temporary method added for getting all plans(RCP & OCP), to support member migration
     testing in CI before enabling member routing rule. This will be removed once we complete member migration.
  */
  getAllPlans() {
    let allRcpElig: any = [];
    if (this.metaData.eligibility && this.metaData.eligibility.length > 0) {
      this.metaData.eligibility.forEach((elg: any) => {
        allRcpElig.push(elg);
      });
    }
    return allRcpElig;
  }

  isCarveOutCDH(): boolean {
    let eligibilities: Array<any> = this.getAllRCPPlans();
    let result: boolean = false;
    for (let i = 0; i < eligibilities.length; i++) {
      let eli = eligibilities[i];
      if (!Array.isArray(eli.coverage)) {
        continue;
      }
      for (let j = 0; j < eli.coverage.length; j++) {
        let cov = eli.coverage[j];
        if (!Array.isArray(cov.product)) {
          continue;
        }
        for (let k = 0; k < cov.product.length; k++) {
          let product = cov.product[k];
          if (!Array.isArray(product.coverageType)) {
            continue;
          }
          for (let l = 0; l < product.coverageType.length; l++) {
            let covType = product.coverageType[l];
            if (!Array.isArray(covType.coverageProgram)) {
              continue;
            }
            for (let m = 0; m < covType.coverageProgram.length; m++) {
              let covProgram = covType.coverageProgram[m];
              if (((covProgram ? covProgram.carveInd : '') + '').trim().toLowerCase() === 'Carveout-CDH'.toLowerCase()) {
                result = true;
                break;
              }
            }
          }
        }
      }
    }
    return result;
  }

  isSecureMessageSuppressed(): boolean {
    let elig = this.getPrimaryEligibility();
    if (elig && elig.planIndicators.indexOf('SUPPRESS_MESSAGECENTER') >= 0) {
      return true;
    }

    return false;
  }

  isJAA(): boolean {
    let ret: boolean = false;
    try {
      if (this.metaData.eligibility && this.metaData.eligibility.length > 0) {
        for (let i = 0; i < this.metaData.eligibility.length; i++) {
          let elg = this.metaData.eligibility[i];
          if (elg.coverage && elg.coverage.length > 0) {
            for (let j = 0; j < elg.coverage.length; j++) {
              let product = elg.coverage[j].product;
              if (product && product.length > 0) {
                for (let k = 0; k < product.length; k++) {
                  let jaaInd = product[k].jaaInd;
                  if (jaaInd.toUpperCase() === 'Y') {
                    return true;
                  }
                }
              }
            }
          }
        }
        return ret;
      }
    } catch (err) {
      ret = false;
    }
    return ret;
  }

  /**
   * Returns all Active RCP eligibilities
   */
  getActiveEligibilities(): Array<any> {
    let activeElis: any[];
    try {
      activeElis = [...this.getAllRCPPlans()];
      activeElis = activeElis.filter((a) => {
        return 'A' === (a.statusCd.code as string).toUpperCase();
      });
    } catch (err) {
      activeElis = [];
    }
    return activeElis;
  }

  /**
   * Returns all Future RCP eligibile coverages:ANREIMAGED-156269
   */
  getFutureEligibilities(): Array<any> {
    let futureElis: any[];
    try {
      futureElis = [...this.getAllRCPPlans()];
      futureElis = futureElis.filter((a) => {
        return 'F' === (a.statusCd.code as string).toUpperCase();
      });
    } catch (err) {
      futureElis = [];
    }
    return futureElis;
  }

  /**
   * checks if contract has JIA plan
   * @param contract
   */
  hasJIAInContract(contract: any): boolean {
    if (contract && contract.planIndicators && contract.planIndicators.length > 0) {
      return contract.planIndicators.includes('HAS_JIA');
    } else {
      return false;
    }
  }

  /**
   * @description Determines whether chat interactions go through chat bot or standard live chat flow,
   */
  hasChatBot(): boolean {
    let ret: boolean = true;
    const features: any = this.metaData.features;

    if (features && features.indexOf('SUPPRESS_CHATBOT') >= 0) {
      ret = false;
    }

    return ret;
  }
}
