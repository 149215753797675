import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitsComponent } from './benefits.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { Routes, RouterModule } from '@angular/router';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { BenefitsMemberInfoComponent } from './member-info/member-info.component';
import { NavigationModule } from '@anthem/uxd/navigation';
import { SidebarRailModule } from '@anthem/uxd/navigation';
import { VisionBenefitsComponent } from './vision-benefits/vision-benefits.component';
import { DentalBenefitsComponent } from './dental-benefits/dental-benfits.component';
import { TransportationBenefitsComponent } from './transportation-benefits/transportation-benefits.component';
import { PlanDocumentsComponent } from './plan-documents/plan-documents.component';
import { CommonPipesModule } from "sydmed/libs/common-pipes/src/lib/common-pipes.module";
const routes: Routes = [
    { path: 'secure/benefits', component: BenefitsComponent , pathMatch: 'full', canActivate: [AuthGuardService] } 
];

@NgModule({
    declarations: [
        BenefitsComponent,
        BenefitsMemberInfoComponent,
        DentalBenefitsComponent,
        PlanDocumentsComponent,
        TransportationBenefitsComponent,
        VisionBenefitsComponent,
    ],
    exports: [
        RouterModule,
        PlanDocumentsComponent
    ],
    providers: [
        JsonContentService
    ],
    imports: [
        CommonModule,
        NavigationModule,
        SidebarRailModule,
        RouterModule.forChild(routes),
        CommonPipesModule
    ]
})
export class BenefitsModule {}
