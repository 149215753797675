import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';


@Component({
  selector: 'app-message-us',
  templateUrl: './message-us.component.html',
  styleUrls: ['./message-us.component.scss']
})
export class MessageUsComponent implements OnInit {

  public labels;
  
  constructor(
    private dataSvc: JsonContentService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataSvc.getJSON('contact-us').subscribe(data => {
      this.labels = data.contactUs.labels.messageUs;
    }); 
  }

  goToMessageCenter() {
    this.router.navigate([this.labels.messageUsLinkUrl]);
  }

}
