<div [formGroup]="form" #emailForm="ngForm">
  <div cass="ctrlHolder">
    <div class="form-item">
      <label for={{emailId}} >{{emailLabel}}</label>
      <input id={{emailId}} name="email" [placeholder] = "placeholder" formControlName="email" type="text"
      [attr.aria-describedby]="emailId + '-errors'" aria-required="true" class="ant-text-input" />
    </div>
    <div id="{{emailId + '-errors'}}"
      class="error-list"
      *ngIf="(email.dirty || email.touched || formSubmitted) && email.invalid"
    >
      <div *ngIf="email.errors.required">
        <span class="error"><i class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
              <span [innerHtml]="emailLabel"></span>
              <span [innerHtml]="errorsObj.Required_err"></span>
          </span>
        </span>
      </div>
      <div *ngIf="email.errors.invalidEmail && !email.errors.required">
        <span class="error"><i class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
              <span [innerHtml]="errorsObj.Email_Format_err"></span>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
