<div class="pharmacy full-screen-gradient">
  <div class="container">
    <div class="row">
      <div class="ant-content-wrapper" role="region" *ngIf="content">
        <div class="cards-wrapper">
          <div class="cards-container">
            <div *ngIf="displayClaimsTile()" class="card medium-card card-flat" id="pharmacy-claims">
              <app-footer-tile 
                [tileId]="footerTopRight.TileId" 
                [headerIcon]="footerTopRight.HeaderIcon" 
                [headerText]="footerTopRight.HeaderText" 
                [hideLink]="footerTopRight.hideLink"
                [body1]="footerTopRight.Body1" 
                [body2]="footerTopRight?.Body2" 
                [linkIcon]="footerTopRight.LinkIcon" 
                [linkText]="footerTopRight.LinkText" 
                [link]="footerTopRight.Link" 
                [externalLink]="footerTopRight.ExternalLink"
                [analyticsTag]="footerTopRight.AnalyticsTag"
                [ariaLabel]="footerTopRight?.AriaLabel">
              </app-footer-tile>
            </div>
            <div class="card medium-card card-flat">
              <app-footer-tile 
                [tileId]="footerBottomLeft.TileId" 
                [headerIcon]="footerBottomLeft.HeaderIcon" 
                [headerText]="footerBottomLeft.HeaderText"
                [hideLink]="footerBottomLeft.hideLink"
                [body1]="footerBottomLeft.Body1" 
                [body2]="footerBottomLeft?.Body2" 
                [linkIcon]="footerBottomLeft.LinkIcon" 
                [linkText]="footerBottomLeft.LinkText" 
                [link]="footerBottomLeft.Link" 
                [externalLink]="footerBottomLeft.ExternalLink"
                [analyticsTag]="footerBottomLeft.AnalyticsTag"
                [ariaLabel]="footerBottomLeft?.AriaLabel">
              </app-footer-tile>
            </div>
            <div class="card medium-card card-flat">
              <app-footer-tile 
                [tileId]="footerBottomRight.TileId" 
                [headerIcon]="footerBottomRight.HeaderIcon" 
                [headerText]="footerBottomRight.HeaderText" 
                [hideLink]="footerBottomRight.hideLink"
                [body1]="footerBottomRight.Body1" 
                [body2]="footerBottomRight?.Body2" 
                [linkIcon]="footerBottomRight.LinkIcon" 
                [linkText]="footerBottomRight.LinkText" 
                [link]="footerBottomRight.Link" 
                [externalLink]="footerBottomRight.ExternalLink"
                [analyticsTag]="footerBottomRight.AnalyticsTag"
                [ariaLabel]="footerBottomRight?.AriaLabel">
              </app-footer-tile>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</div>
<div class="container motif-margin-bottom-10 disclaimer">
  <p class="body-lg"><em>{{copayDisclaimer}}</em></p>
  <br />
</div>