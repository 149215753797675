import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GetSecurityCodeComponent} from './get-security-code/get-security-code.component';
import {EnterSecurityCodeComponent} from './enter-security-code/enter-security-code.component';
import {AnswerSecurityQuestionsComponent} from './answer-security-questions/answer-security-questions.component';
import {ResetTempPasswordComponent} from './reset-temp-password/reset-temp-password.component';
import {SecureLoginComponent} from './secure-login/secure-login.component';
import { CaregiverAttestationComponent } from './caregiver-attestation/caregiver-attestation.component';
import { PublicAuthGuardService } from 'sydmed/libs/authorization/public-route-guard.service';



// Check if the routes other than HOME page have to be guarded from back and front buttons on browser. 

const routes: Routes = [
  { path: 'public/login/get-security-code', component: GetSecurityCodeComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/login/enter-security-code', component: EnterSecurityCodeComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/login/answer-security-questions', component: AnswerSecurityQuestionsComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/login/reset-temp-password/:id', component: ResetTempPasswordComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/login/secure-login', component: SecureLoginComponent, canActivate: [PublicAuthGuardService] },
  { path:'public/login/caregiver-attestation', component: CaregiverAttestationComponent, canActivate: [PublicAuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }