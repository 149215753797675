import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { Observable } from 'rxjs';
import { ProviderSearchRequestData } from '../interfaces/providerSearch';

@Injectable({
  providedIn: 'root',
})
export class PcpService {
  constructor(private httpSvc: HttpClientService) {}

  /**
   * @description getCurrentPCP() responsible for getting PCP get API response
   * @retruns {Observable<any[]>}
   */
  public getCurrentPCP(): Observable<any[]> {
    return this.httpSvc.getObservable(
      UrlProperties.UrlProperties.endPoints.pcp.viewPcp
    );
  }

  /**
   * @description searchProviders() is responsible for getting list of providers based on the search criteria
   * @retruns {Observable<any[]>}
   */
  public searchProviders(
    requestObj: ProviderSearchRequestData
  ): Observable<any[]> {
    return this.httpSvc.postObservable(
      UrlProperties.UrlProperties.endPoints.pcp.searchProviders,
      JSON.stringify(requestObj)
    );
  }

  /**
   * @description findProviders() is responsible for getting list of providers based on the search criteria,
   * this API currently only supports SC, but will support other WGS markets going forward
   * @retruns {Observable<any[]>}
   */
  public findProviders(
    requestObj: ProviderSearchRequestData
  ): Observable<any[]> {
    return this.httpSvc.postObservable(
      UrlProperties.UrlProperties.endPoints.pcp.findProviders,
      JSON.stringify(requestObj)
    );
  }

  /**
   * @description getAddresses() is responsible for getting the member's mailing address
   * @retruns {Observable<any[]>}
   */
  public getAddresses(): Observable<any[]> {
    return this.httpSvc.getObservable(
      UrlProperties.UrlProperties.endPoints.myProfile.getAddresses
    );
  }

  /**
   * @description getMemberZipCode() is responsible for getting the member's zip code
   * @retruns {Observable<any[]>}
   */
  public getChangePCPReasons(): Observable<any[]> {
    return this.httpSvc.getObservable(
      UrlProperties.UrlProperties.endPoints.pcp.changePcpReasons
    );
  }

  /**
   * @description assignPCP() is responsible assigning selected PCP to the member
   * @retruns {Observable<any[]>}
   */
  public assignPCP(providerId: string, termReasonCode:string): Observable<any[]> {
    const param = {
      providerId,
      termReasonCode
    };
    return this.httpSvc.postObservable(
      UrlProperties.UrlProperties.endPoints.pcp.updatePcp,
      param
    );
  }
}
