import { Component, OnInit, Input, OnDestroy, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { CartService } from '../services/cart.service';
import { Subscription } from 'rxjs';
import { AlertHelper } from '@anthem/uxd/alert';
import { NavigationHelper } from '@anthem/mbrportal/utility/services/navigationHelper';
import { SearchService } from '../search/search.service';
import { PharmacySessionService } from '../services/pharmacy-session.service';
import { PrescriptionsService } from 'sydmed/src/app/secure/pharmacy/services/prescriptions.service';
import { PrescriptionOrderRouteService } from 'sydmed/src/app/secure/pharmacy/services/prescription-order-route.service';
import { FluffyButtons, PrescriptionsTableLabels, PrescriptionsTableAnalytics, Summary } from '../interfaces/PrescriptionOrderContent';
import { PrescriptionsResponse, Prescription } from 'gbd-models';
import { Router } from '@angular/router';

enum BottleColor {
  OneRefills = 'red',
  HasRefills = 'green'
}

interface SelectAllButton {
  falseValue: boolean;
  id: string;
  isSelected: boolean;
  labelText?: string;
  legendSrOnly: boolean;
  optionText?: string;
  textAlignRight: boolean;
  trueValue: boolean;
}

@Component( {
  selector: 'app-prescriptions-list',
  templateUrl: './prescriptions-list.component.html',
  styleUrls: [ './prescriptions-list.component.scss' ]
} )
export class PrescriptionsListComponent implements OnInit, OnDestroy, AfterViewInit {

  // TODO: make search filter (text-highlight) CSS class configurable
  @Input() prescriptions: Prescription[];
  @Input() labels: PrescriptionsTableLabels;
  @Input() buttonLabel: string;
  @Input() buttons: FluffyButtons;
  @Input() isActionable;
  @Input() searchTerm: string;
  @Input() isPharmacyLanding;
  @Input() isCheckoutFlow = false;
  @Input() listHeaderContent;
  @Input() listSummaryContent: Summary;
  @Input() noPrescriptions;
  @Input() viewAllPrescriptionLink;
  @Input() hasviewAllPrescriptionLink;
  @Input() isAccordion = true;
  @Input() analytics: PrescriptionsTableAnalytics;
  @Input() isMini: false;
  @Output() removeRxFromOrder = new EventEmitter<string>();
  private sub: Subscription = new Subscription();
  prescriptionLabels = null;
  public isChecked: boolean;
  cartCount = 0;
  cartTotal = 0;
  public selectAllToggle: any;
  memberName: string;
  totalPrescriptions: number;
  totalEligiblePrescriptions = 0;
  cartRoute: string;
  addToCart: string;
  inCart: string;
  private _dom: HTMLElement;
  expandAnalytics: string;
  collapseAnalytics: string;
  selectAllButton: SelectAllButton;
  public buttonResize: string;

  checkbox: any = [];
  memberContext: any;
  isAllSelected: boolean;

  constructor(
    protected cartService: CartService,
    protected searchService: SearchService,
    protected memberNameService: PharmacySessionService,
    protected prescriptionService: PrescriptionsService,
    private _elementRef: ElementRef,
    protected routeSvc: PrescriptionOrderRouteService,
    protected router: Router,
    protected alertHelper: AlertHelper ,
    private navHelper: NavigationHelper ) { }

  ngOnInit(): void {
    this.addToCart = this.labels?.AddToCart;
    this.inCart = this.labels?.InCart;
    this.selectAllButton = {
      id: 'selectAllToggle',
      trueValue: true,
      labelText: this.labels?.SelectAll,
      falseValue: false,
      legendSrOnly: false,
      isSelected: true,
      textAlignRight: true
    };


    this.prescriptionLabels = this.labels;
    this.totalPrescriptions = this.prescriptions ? this.prescriptions.length : 0;
    this.memberName = this.memberNameService.memberName;
    this.sub.add ( this.prescriptionService.originalPrescriptions.subscribe( ( response: PrescriptionsResponse ) => {
      if ( response && response.prescriptions ) {
          response.prescriptions.forEach(p => {
          this.checkbox[p.uniqueRxId] = {
                        id: p.uniqueRxId,
                        label: p.isSelected ? this.inCart : this.addToCart,
                        name: 'selectCheckbox' + p.uniqueRxId,
                        trueValue: true,
                        falseValue: false,
                        analyticsText: (this.analytics) ? this.analytics.Checkbox : ''
                  };
        } );
      }
    } ) );

    this.sub.add(this.searchService.searchTerm.subscribe(
      (searchTerm: string) => {
        this.searchTerm = searchTerm;
      }));

    this.sub.add(this.cartService.cartSize.subscribe( (cartlength: number) => this.cartCount = cartlength ));
    this.sub.add(this.cartService.cartTotal.subscribe( (carttotal: number) => this.cartTotal = carttotal ));

    this.sub.add(this.prescriptionService.totalEligiblePrescriptions.subscribe (
      (totaleligible: number ) => {
        this.totalEligiblePrescriptions = totaleligible;
      } ));
    
    let drugsEligbleButNotinCart: Prescription[];

    if (this.prescriptions) {
      drugsEligbleButNotinCart =  this.prescriptions.filter( (prescription: Prescription) => this.checkEligibility(prescription) )
      .filter( (prescription: Prescription ) => !this.cartService.isInCart(prescription)
      );

      if (this.totalEligiblePrescriptions > 0){
        this.selectAllToggle = ( this.cartCount > 0 ) ? drugsEligbleButNotinCart.length === 0 : false;
      }

       this.isAllSelected =  ( this.totalEligiblePrescriptions > 0 && (drugsEligbleButNotinCart.length === 0) );
    }
    else
    if (this.isMini){
      this.alertHelper.openAlert(this.noPrescriptions, 'positive');
}
    this.updateRefillButtonSize('');

    this.sub.add(this.routeSvc.cartRoute.subscribe(route => {
      this.cartRoute = route;
    },
      err => console.log('error in nav component', err)));
    // console.log('totalEligiblePrescriptions', this.totalEligiblePrescriptions, 'isAllSelected', this.isAllSelected);
  }

  ngOnChanges() {
    if (this.analytics) {
      this.expandAnalytics = this.isActionable ? this.analytics.ExpandPBM : this.analytics.ExpandOther;
      this.collapseAnalytics = this.isActionable ? this.analytics.CollapsePBM : this.analytics.CollapseOther;
    }
  }
  ngAfterViewInit(){
    this._dom = this._elementRef.nativeElement ;
    if (this.prescriptions && this.isActionable && this.analytics &&  this._dom.querySelector('.ptOption')) {
      this._dom.querySelector('.ptOption').setAttribute('data-analytics', this.analytics?.SelectAll);
    }

    if (!this.isAccordion)
    {
      window.setTimeout(() => {
        const arr: HTMLCollection = document.getElementsByTagName('uxd-expansion-header');
        Array.from(arr).forEach((ele) => {
            ele.removeAttribute('aria-expanded');
            ele.removeAttribute('tabindex');
        });
      }, 500);
    }
  }
  updateRefillButtonSize(data: any) {
    this.buttonResize = '';
    const href: string = window.location.href.toLowerCase();

    if (href.indexOf('allprescriptions') >= 0) {
      this.buttonResize = 'all-prescriptions';
    }
    else {
      this.buttonResize = 'manage-prescriptions';
    }
  }

  isSpecialty(prescription: Prescription){
    return prescription.lineOfBusiness === 'SPECIALTY';
  }

  checkEligibility(prescription: Prescription) {
    return (prescription.eligibility.refill || prescription.eligibility.renew) &&
           (prescription.eligibility.digitalRefill === undefined || prescription.eligibility.digitalRefill);
  }

  numberOfRefills( refillsLeft: string ) {
    return ( refillsLeft === '' || refillsLeft === ' ' ) ? 0
      : parseInt( refillsLeft.trim(), 10 );
  }

  refillBottles( refillsLeft: string ): number[] {
    const bottles = this.oneRefill( refillsLeft ) ? 1
      : this.numberOfRefills( refillsLeft );
    return Array( bottles ).fill( 0 );
  }

  oneRefill( refillsLeft: string ): boolean {
    return this.numberOfRefills( refillsLeft ) === 1;
  }

  bottleColor( refillsLeft: string ) {
    return this.oneRefill( refillsLeft ) ? BottleColor.OneRefills : BottleColor.HasRefills;
  }

  uniqueRxId( index, item: Prescription ) {
    return item.uniqueRxId;
  }

  remove( uniqueRxId: string ) {
    this.removeRxFromOrder.emit(uniqueRxId);
  }
/** @description View all prescriptions */
  /**
   * @description viewAllPrecriptions() to navigate to all prescriptions page
   * @returns {void}
   */
  viewAllPrecriptions(): void {
    if (this.isMini && this.getNoPrescriptions) {
      this.navHelper.navigateTo('pharmacy/allprescriptions');
    }
  }

  getNoPrescriptions = () => (!this.prescriptions || this.prescriptions.length <= 0);
  checkBoxAriaLabel(prescription: Prescription) {
    const drugName  = prescription.drug.name;
    const inCartLabel = `${this.inCart}, ${drugName}`;
    const addToCartLabel = `${this.addToCart}, ${drugName}`;
    return prescription.isSelected ? inCartLabel : addToCartLabel;
  }

  onToggle(e, prescription: Prescription ) {
    (e.target.checked) ? this.cartService.addToCart(prescription) : this.cartService.removeItem(prescription.uniqueRxId);

    (e.target.checked) ? this.checkbox[prescription.uniqueRxId].label = this.inCart :
                         this.checkbox[prescription.uniqueRxId].label = this.addToCart;

    const selectedPrescriptionCount: number = this.prescriptions.filter((p: Prescription) => {
      return p.isSelected;
                                              }).length;
    this.selectAllToggle = (this.totalEligiblePrescriptions === selectedPrescriptionCount);
    this.sub.add(this.cartService.cartSize.subscribe( (cartlength: number) => this.cartCount = cartlength )); 
    this.sub.add(this.cartService.cartTotal.subscribe( (carttotal: number) => this.cartTotal = carttotal ));
  }
  onKeyboardToggle(e, prescription: Prescription) {
    e.target.checked = !(e.target.checked);
    (e.target.checked) ? prescription.isSelected = true : prescription.isSelected = false;
     (e.target.checked) ? this.cartService.addToCart(prescription) : this.cartService.removeItem(prescription.uniqueRxId);

    (e.target.checked) ? this.checkbox[prescription.uniqueRxId].label = this.inCart : this.checkbox[prescription.uniqueRxId].label = this.addToCart;

    if (!e.target.checked) { this.selectAllToggle = false; }

    this.sub.add(this.cartService.cartSize.subscribe((cartlength: number) => this.cartCount = cartlength));
    this.sub.add(this.cartService.cartTotal.subscribe((carttotal: number) => this.cartTotal = carttotal));
    e.stopPropagation();
    e.preventDefault();
  }

  onSelectAllToggle(e) {
     // console.log('e.target.checked', e.target.checked);

     if (e.target.checked === undefined){
      return;
     }

     this.prescriptions.forEach ( (prescription: Prescription)  => this.cartService.removeItem(prescription.uniqueRxId) );

     (e.target.nextSibling.className.includes('active')) ?
        this.prescriptions.filter( (prescription: Prescription) => this.checkEligibility(prescription) )
          .forEach( (prescription: Prescription) => this.cartService.addToCart(prescription) )
        : this.prescriptions.forEach( (prescription: Prescription) => prescription.isSelected = false );

     this.prescriptions.forEach (
          ( prescription: Prescription) => ( prescription.isSelected ) ?
            this.checkbox[prescription.uniqueRxId].label = this.inCart :
            this.checkbox[prescription.uniqueRxId].label = this.addToCart
          );

     this.sub.add(this.cartService.cartSize.subscribe( (cartlength: number) => this.cartCount = cartlength ));
     this.sub.add(this.cartService.cartTotal.subscribe( (carttotal: number) => this.cartTotal = carttotal ));
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }
}
