import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportFormsRoutingModule } from './support-forms-routing.module';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { SupportFormsComponent } from './support-forms.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormGroupModule } from '@anthem/uxd/form-group';


@NgModule({
  declarations: [ SupportFormsComponent ],
  imports: [
    CommonModule,
    SupportFormsRoutingModule
  ],
  exports: [ SupportFormsComponent ],
  providers: [  JsonContentService ]
})
export class SupportFormsModule { }
