<div class="flex-grid" *ngIf='isPharmacyLanding'>
    <div class="col">
    <ng-container>
      <h2 class="prescription-heading section-header-two">
          <span>{{ listHeaderContent  | si:{memberName: memberName} }}</span>
      </h2>
    </ng-container>
  </div>
</div>

<div class="flex-grid mb">
  <div class="col first-child-expand" *ngIf='isPharmacyLanding || isMini'>
    <div class="summary-text" *ngIf="totalEligiblePrescriptions && totalEligiblePrescriptions > 0">
       {{ listSummaryContent.PartOne }} <span class="total-prescriptions">{{totalEligiblePrescriptions}}</span> {{listSummaryContent.PartTwo}} 
    </div>    
    <div *ngIf="totalEligiblePrescriptions <= 0" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}">
      <span id="zero-prescriptions-msg" tabindex="0" class="summary-text no-prescription-color" [innerHTML]="noPrescriptions"></span>
      <a *ngIf="hasviewAllPrescriptionLink" href="javascript:void()" (click)="viewAllPrecriptions()" [innerText]="viewAllPrescriptionLink"></a>
    </div>
  </div>
  <div class="col right mc" *ngIf="isActionable && prescriptions && (prescriptions.length > 0)">
    <div data-tcp-toggle-button-group-cmp>
      <div
        data-tcp-toggle-button-cmp
        [(ngModel)]="selectAllToggle"
        [name]="'selectAllToggle'"
        [toggle]="selectAllButton"
        (click)="onSelectAllToggle($event)">
      </div>
    </div>
  </div>
</div>

<uxdAccordion class="accordion-wrapper uxd-expansion-table zebra-striping" [isAccordion]="true">
  <div class="uxd-expansion-table" role="table">
    <hr />
    <div role="rowgroup">
      <ng-container *ngFor="let prescription of prescriptions; index as i; trackBy:uniqueRxId">
        <uxd-expansion-panel  #panel="uxdExpansionPanel">
          <uxd-expansion-header [tabindex]="-1"  [aria-label]="!isPharmacyLanding && !isCheckoutFlow ? ((panel.expanded ? 'Collapse' : 'Expand') + ' information for prescription with ID ' + prescription.uniqueRxId) : ''">
            <!-- new flex layout -->
            <div class="accordion-header-grid-container" role="row">
              <div class="expansion-toggle">
                <uxd-expansion-toggle role="cell" *ngIf="!isPharmacyLanding && !isCheckoutFlow"
                [analytics]="panel.expanded ? collapseAnalytics : expandAnalytics">
                  <span class="motif-icon" [id]="'button-icon-'+ i" [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'" [attr.aria-label]="panel.expanded ? 'Collapse' : 'View more info for ' + prescription?.drug?.name "></span>
                </uxd-expansion-toggle>
              </div>
              <div class="rx-name" role="cell">
                <h3 class="ant-font-sb"
                    *ngIf="prescription?.drug?.dosage != '' else splitDrugForm"
                    [innerHtml]="prescription?.drug?.name.split(prescription?.drug?.dosage)[0] | textHighlight : searchTerm : 'ant-background-highlight-orange'">
                </h3>
                <span class="ant-font-r">{{ prescription?.drug?.dosage }} {{ prescription?.drug?.form }}</span>
                <ng-template #splitDrugForm>
                    <h5 class="ant-font-sb"
                        *ngIf="prescription?.drug?.dosage != '' else defaultDrugForm"
                        [innerHtml]="prescription?.drug?.name.split(prescription?.drug?.form)[0] | textHighlight : searchTerm : 'ant-background-highlight-orange'">
                    </h5>
                </ng-template>
                <ng-template #defaultDrugForm>
                  <h5 class="ant-font-sb"
                      [innerHtml]="prescription?.drug?.name | textHighlight : searchTerm : 'ant-background-highlight-orange'">
                  </h5>
                </ng-template>
                <p class="specialty" *ngIf="isSpecialty(prescription)">{{ labels?.SpecialtyDrug }}</p>
              </div>
              <div class="rx-supply" role="cell">
                <h4 class="ant-font-sb mb-f-left">{{ labels?.Supply }}</h4>
                <span class="ant-font-r mb-lh" *ngIf="prescription?.supply.quantity > 0 ">{{ prescription?.supply.duration }} days</span>
              </div>
              <div class="rx-refill" role="cell">
                <div class="ant-font-sb" *ngIf="isActionable || isCheckoutFlow">
                  <h4 class="ant-font-sb mb-f-left">{{ numberOfRefills(prescription?.remainingRefills) }} {{ labels?.RefillsLeft }}</h4>
                  <span class="ant-font-r mb-lh" *ngIf="prescription?.remainingRefills > 0; else zeroBottleDisplay">
                    <span class="ant-font-r mb-lh" *ngIf="prescription?.remainingRefills <= 4; else bottlesPlusDisplay">
                      <span class="ant-font-r mb-lh" *ngFor="let bottle of refillBottles(prescription?.remainingRefills)">
                        <span [style.color]="bottleColor(prescription?.remainingRefills)" class="motif-icon motif-prescription-pill-bottle" [ngClass]="prescription?.remainingRefills == 1 ? 'red-color-refill': ''" aria-hidden="true">
                        </span>
                      </span>
                    </span>
                    <ng-template #bottlesPlusDisplay>
                      <span class="ant-font-r mb-lh" *ngFor="let bottle of refillBottles('4')">
                        <span [style.color]="bottleColor(prescription?.remainingRefills)" class="motif-icon motif-prescription-pill-bottle" aria-hidden="true">
                        </span>
                      </span>
                      <span class="ant-font-r mb-lh">+</span>
                    </ng-template>
                  </span>
                  <ng-template #zeroBottleDisplay>
                    <span class="red-text ant-font-r mb-lh" id="zero-refills">0 </span>
                  </ng-template> 
                </div>
                <div class="ant-font-sb" *ngIf="!isActionable && !isCheckoutFlow">
                  <h4 class="ant-font-sb mb-f-left">{{ labels?.RefillsLeft }}</h4>
                  <span class="ant-font-r mb-lh">{{ prescription?.remainingRefills }}</span>
                </div>
              </div>
              <div class="rx-copay" role="cell">
                <h4 *ngIf='!isMini' class="ant-font-sb mb-f-left">{{ labels?.YourCopay }}</h4>
                <h4 *ngIf='isMini' class="ant-font-sb mb-f-left">{{ labels?.MiniYourCopay }}</h4>
                <span class="ant-font-r mb-lh" *ngIf="prescription?.estimatedCopay; else zeroCopay">{{ prescription?.estimatedCopay | currency: 'USD' }}</span>
              </div>
              <ng-template #zeroCopay>
                <span class="ant-font-r mb-lh">{{ 0 | currency: 'USD' }}</span>
              </ng-template>
              <div role="cell" class="add-to-cart" *ngIf="isActionable && !isCheckoutFlow">
                <div *ngIf="checkEligibility(prescription)">
                  <div data-uxd-checkbox-input-cmp class="ant-font-sb"
                    [(ngModel)]="prescription.isSelected" 
                    (click)="onToggle($event, prescription)"
                    (keydown.Space)="onKeyboardToggle($event, prescription)"
                    [definition]="checkbox[prescription.uniqueRxId]"
                    [optionalAriaLabel]="checkBoxAriaLabel(prescription)"
                    >
                  </div>
                </div>

                <!--Can not be filld online  -->
                <p class="ant-font-sb" *ngIf="prescription.eligibility.digitalRefill !== undefined && prescription.eligibility.digitalRefill === false">
                  {{labels?.CannotBeRefilledOnline}}
                </p>

                <!-- available for renewal messaging -->
                <p class="ant-font-sb" *ngIf="!(prescription.eligibility.refill || prescription.eligibility.renew) && (prescription.eligibility.digitalRefill === undefined || prescription.eligibility.digitalRefill) && prescription.nextFillDate">
                  {{labels?.NextAvailable}} {{prescription.nextFillDate}}
                </p>

                <!-- requires renewal messaging -->
                <p class="ant-font-sb" *ngIf="prescription.eligibility.renew && !prescription.eligibility.refill">
                  {{ labels?.RequiresRenewal }}
                </p>
              </div>
              <div class="directions add-to-cart" *ngIf="isCheckoutFlow && !isMini">
                <p class="ant-font-sb"><a class="primary-color" href="javascript:void(0)" (click)="remove(prescription.uniqueRxId)" [attr.data-analytics]="isSpecialty(prescription)? 'removePrescriptionLinkSpecShipmentRx':'removePrescriptionLinkPbmShipmentRx'"
                   [attr.aria-label]="'Remove ' + prescription?.drug?.name" (keyup.enter)="remove(prescription.uniqueRxId)">{{ buttons?.Remove }}</a></p>
                <p class="ant-font-sb" *ngIf="prescription.eligibility.renew && !prescription.eligibility.refill">{{ labels?.RequiresRenewal }}</p>
              </div>
              <div class="rx-alert flex-grid" *ngIf="isSpecialty(prescription) || prescription.shipsWithColdPack">
                <div class="col">
                  <div class="uxd-table-row">
                    <div class="full-width" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}"
                    *ngIf="!prescription.eligibility.digitalRefill && isSpecialty(prescription); else specialtyWithColdPackTextTemplate">
                      <span class="desktop">{{ labels?.IllegalSpecialtyText }}</span>
                      <span class="mobile" [innerHTML]="labels?.IllegalSpecialtyTextMobile"></span>
                    </div>
                    <ng-template #specialtyWithColdPackTextTemplate>
                      <div  class="full-width" *ngIf="isSpecialty(prescription) && prescription.shipsWithColdPack; else specialtyTextTemplate">
                      <div class="full-width" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}">
                        <span>{{ labels?.SpecialtyText }}</span>
                        </div>
                        <div class="full-width" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}">
                        <span>{{labels?.ColdPackText}}</span>
                        <span class="snowflake"></span>
                        <span>{{ labels?.ColdPackTextContinue}}</span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #specialtyTextTemplate>
                        <div class  ="full-width" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}"
                        *ngIf="isSpecialty(prescription); else coldPackTextTemplate">
                          <span>{{ labels?.SpecialtyText }}</span>
                        </div>
                    </ng-template>
                    <ng-template #coldPackTextTemplate>
                        <div class="full-width" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}"
                        *ngIf="prescription.shipsWithColdPack;">
                          <span>{{ labels?.ColdPackText }}</span>
                          <span class="snowflake"></span>
                          <span>{{ labels?.ColdPackTextContinue }}</span>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </uxd-expansion-header>
          <app-prescription-details *ngIf="!isPharmacyLanding && !isCheckoutFlow" [prescriptionDetails]="prescription" [labels]="prescriptionLabels" [searchTerm]="searchTerm"></app-prescription-details>
        </uxd-expansion-panel>
      </ng-container>
      
      <div *ngIf="isActionable">
        <div class="total-grid-container motif-margin-top-10 motif-margin-bottom-5">
          <div class="total"><p class="summary-total">{{ labels?.Total }}</p></div>
          <div class="value"><p class="summary-total">{{ cartTotal | currency: 'USD'}}</p></div>
        </div>

        <div class="flex-grid motif-margin-bottom-20">
          <div class="col right">
            <button class="btn btn-primary {{ buttonResize }}" *ngIf="cartCount > 0" routerLink="{{ cartRoute }}" attr.data-analytics="{{analytics.Refill}}">
              {{ buttons.Refill }}
            </button>
            <button class="btn btn-primary {{ buttonResize }}" *ngIf="cartCount == 0" disabled>{{ buttons.Refill }}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</uxdAccordion>
