import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { MyProfileDataService } from 'sydmed/src/app/secure/shm-my-profile/my-profile-data.service';
import { AccountAndRecoveryService } from 'sydmed/src/app/secure/shm-my-profile/account-and-recovery/services/account-and-recovery.service';
import { FormValidators } from 'sydmed/libs/custom-validators/src/public-api';
import { SecurityQuestionsModelService } from 'sydmed/src/app/secure/shm-my-profile/account-and-recovery/security-questions/security-questions-model.service';
import { Subscription, forkJoin } from 'rxjs';
import { AlertService, InlineAlertContainerComponent, UniversalAlertType } from '@anthem/uxd/alert';
import { MemberRepRegistration, ErrorMsgs, Loaders } from 'sydmed/src/app/secure/shm-my-profile//models/labels';
import { AccordionProperties } from 'sydmed/src/app/secure/shm-my-profile//models/accordion.properties';
import { SuccessRegion } from 'sydmed/src/app/secure/shm-my-profile/models/enum-values';
import { ExpandCollapseTheme } from '@anthem/uxd/accordion';
import { AccordionItemProperty } from 'sydmed/src/app/secure/shm-my-profile//models/accordion-item';
import { DropDownComponent } from '@anthem/uxd/dropdowns/dropdowns';
import { ProfileDetailService } from 'sydmed/src/app/secure/shm-my-profile/services/profile-details.service';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { RegistrationService } from '../../registration.service';
import { MemberRepRegistrationService as DesigneeRegService } from '../service/memberRepRegistration.service';
import { ICheckboxInput } from '@anthem/uxd/util';
import { MaskingService } from 'sydmed/libs/masking-service/src/lib/masking.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-member-rep-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss'],
})
export class SecurityQuestionsComponent implements OnInit {
  public primary: ExpandCollapseTheme = ExpandCollapseTheme.PRIMARY;
  public accordionObj: any;
  securityQuestionsErr: string;
  securityQuestionsForm: FormGroup;
  securityQuestions: Array<string> = [];
  selectedQuestions: Array<string> = [];
  loading: boolean = false;
  public aslError;
  public errorMessage: string;
  private subscriptions: Subscription[];
  accordionProperties: AccordionProperties;
  private questionAnswers: Array<object> = [];
  showTooltipErrOnBlur: boolean = false;
  showTooltips: boolean = false;
  showTooltips2: boolean = false;
  showTooltips3: boolean = false;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  @Input('jsonLabels') labels: MemberRepRegistration;
  @Input('jsonErrors') errorMsgs: ErrorMsgs;
  @Input('loaderLabels') loaderLabels: Loaders;
  @Output()
  emitQuestions: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('memberRepSecurityQuestionsAlert')
  memberRepSecurityQuestionsAlert: InlineAlertContainerComponent;
  @ViewChild('secQuesPanel', { read: ElementRef, static: false }) secQuesPanel: ElementRef<HTMLElement>;
  @ViewChild('question1Div') question1Div: DropDownComponent;
  @ViewChild('question2Div') question2Div: DropDownComponent;
  @ViewChild('question3Div') question3Div: DropDownComponent;

  constructor(
    private formBuilder: FormBuilder,
    private errCodeMapSvc: ErrorCodeMapperService,
    private MyProfileDataSvc: MyProfileDataService,
    private accAndRecSvc: AccountAndRecoveryService,
    private securityQuestionsModelService: SecurityQuestionsModelService,
    private alertSvc: AlertService,
    public profileSvc: ProfileDetailService,
    public httpSvc: HttpClientService,
    private regSvc: RegistrationService,
    private maskingSvc: MaskingService,
    private router: Router,
    private designeeRegService: DesigneeRegService,
  ) {
    this.accordionProperties = new AccordionProperties(this.alertSvc);
  }

  dropDown1: any = {
    title: '',
    options: [],
    selected: '',
    previouslySelected: '',
  };

  dropDown2: any = {
    title: '',
    options: [],
    selected: '',
    previouslySelected: '',
  };

  dropDown3: any = {
    title: '',
    options: [],
    selected: '',
    previouslySelected: '',
  };
  public checkbox: ICheckboxInput = {
    id: 'ux-guide-checkbox-1',
    name: 'agreeDesignee',
    label: '',
    isRequired: true,
    trueValue: true,
    falseValue: false,
  };

  ngOnInit() {
    this.loading = true;
    this.subscriptions = [];
    this.securityQuestionsForm = this.formBuilder.group({
      question1: [null, [Validators.required]],
      question2: [null, [Validators.required]],
      question3: [null, [Validators.required]],
      answer1: ['', [Validators.required, FormValidators.NameValidator, FormValidators.WhiteSpace, FormValidators.SecurityAnswersLengthValidator]],
      answer2: ['', [Validators.required, FormValidators.NameValidator, FormValidators.WhiteSpace, FormValidators.SecurityAnswersLengthValidator]],
      answer3: ['', [Validators.required, FormValidators.NameValidator, FormValidators.WhiteSpace, FormValidators.SecurityAnswersLengthValidator]],
    });
    this.getSecurityQuestions();
    this.setAccordionObj();
    this.dropDown1.title = this.labels?.Security_Questions.Field_Labels.Question_1;
    this.dropDown2.title = this.labels?.Security_Questions.Field_Labels.Question_2;
    this.dropDown3.title = this.labels?.Security_Questions.Field_Labels.Question_3;
    this.loading = false;
  }

  ngAfterViewInit() {}

  setAccordionObj() {
    this.accordionObj = {
      secQues: new AccordionItemProperty(),
    };

    this.accordionObj.secQues.headingContent = `<h5>${this.labels?.Security_Questions?.Page_Heading}</h5>`;
  }

  expandDiv(panel: any, isCancel: boolean, formDir) {
    this.securityQuestionsErr = null;
    // this.accordionProperties.expandDiv(formDir, this.securityQuestionsForm, this.secQuesPanel);
    this.secQuesPanel.nativeElement.querySelector('button').click();
    this.setDefaultSelect(formDir);
  }

  onKeyDown(event: KeyboardEvent, expanded: boolean, form) {
    this.accordionProperties.onKeyDown(event, expanded, form);
  }

  onClickExpansion(expanded: boolean, formDir) {
    this.accordionProperties.onClickExpansion(expanded, formDir);
    !expanded ? this.setDefaultSelect(formDir) : this.accordionProperties.scrollToTop();
  }

  setDefaultSelect(formDir) {
    if (this.selectedQuestions?.length > 0) {
      this.dropDown1.selected = this.selectedQuestions[0]['text'];
      this.dropDown2.selected = this.selectedQuestions[1]['text'];
      this.dropDown3.selected = this.selectedQuestions[2]['text'];

      for (let i = 1; i <= this.selectedQuestions.length; i++) {
        this.removeQuestions(i);
      }
    }

    // removing the default focus for the selected option on cancel
    if (this.question1Div && this.question1Div.activeOptionRef != undefined && this.question3Div.activeOptionRef.nativeElement) {
      this.question1Div.activeOptionRef.nativeElement.classList.remove('focus');
      this.question2Div.activeOptionRef.nativeElement.classList.remove('focus');
      this.question3Div.activeOptionRef.nativeElement.classList.remove('focus');
    }
  }

  submitSecurityQuestions(form, panel, formDir) {
    // add only emitSecurityQuestions() here for moving to next Page
    if (this.securityQuestionsForm.valid) {
      this.loading = true;
      this.questionAnswers = [];
      this.questionAnswers.push({ question: form.value.question1, answer: form.value.answer1.trim() });
      this.questionAnswers.push({ question: form.value.question2, answer: form.value.answer2.trim() });
      this.questionAnswers.push({ question: form.value.question3, answer: form.value.answer3.trim() });
      const requestObj = { questionAnswers: this.questionAnswers };
      this.emitSecurityQuestions();
      // API Service call for Submit Security Questions
    } else {
      this.securityQuestionsForm.markAllAsTouched();
      if (!this.securityQuestionsForm.controls['question1'].valid) {
        const inputfield = this.secQuesPanel?.nativeElement.querySelector('#question1_button') as HTMLElement;
        inputfield.focus();
      } else if (!this.securityQuestionsForm.controls['answer1'].valid) {
        const inputfield = this.secQuesPanel?.nativeElement.querySelector('#secAns1') as HTMLElement;
        inputfield.focus();
      } else if (!this.securityQuestionsForm.controls['question2'].valid) {
        const inputfield = this.secQuesPanel?.nativeElement.querySelector('#question2_button') as HTMLElement;
        inputfield.focus();
      } else if (!this.securityQuestionsForm.controls['answer2'].valid) {
        const inputfield = this.secQuesPanel?.nativeElement.querySelector('#secAns2') as HTMLElement;
        inputfield.focus();
      } else if (!this.securityQuestionsForm.controls['question3'].valid) {
        const inputfield = this.secQuesPanel?.nativeElement.querySelector('#question3_button') as HTMLElement;
        inputfield.focus();
      } else if (!this.securityQuestionsForm.controls['answer3'].valid) {
        const inputfield = this.secQuesPanel?.nativeElement.querySelector('#secAns3') as HTMLElement;
        inputfield.focus();
      }
    }
  }

  getSecurityQuestions() {
    this.loading = true;
    if (this.errorMessage) {
      this.clearAlert(this.memberRepSecurityQuestionsAlert);
    }
      this.designeeRegService.getSecurityQuestions().then(
        (res) => {
          this.loading = false;
          this.handleSecurityQuestionsRes(res);
        },
        (err) => {
          this.handleErrorResponse(err);
        }
      )
  }

  handleErrorResponse(error: any): void {
    this.loading = false;
    this.errorMessage = this.errCodeMapSvc.errorCodeMapper(
      error,
      this.errorMsgs
    );
    this.getSecurityQuestionsErrorAlert(this.errorMessage);
  }

  /**
   * @Description getSecurityQuestionsErrorAlert method used to alert error message
   * @param  {any} error
   * @returns void
   */
   getSecurityQuestionsErrorAlert(error: string): void {
    if (error && this.errorMsgs) {
      this.alertSvc.error(error, {
        regionName: 'member-rep-security-questions-alert',
        politeness: 'assertive',
        isDissmisable: true,
      });
      this.scrollToTop();
    }
  }

   // Method used to scroll top of the page
   scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

   /**
   * @Description clearAlert method used to clear error stack thrown from service failure
   * @returns void
   */
    clearAlert(alertType: InlineAlertContainerComponent): void {
      alertType.dismissAlertId(0);
    }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    sessionStorage.removeItem('securityQuestions');
  }

  handleSecurityQuestionsRes(res) {
    this.securityQuestions = res.body.questions; // 17 questions
    this.fillDropDowns(res.body.questions);
    sessionStorage.setItem('securityQuestions', JSON.stringify(this.securityQuestions));
    this.fixSelectAria();
  }

  fixSelectAria() {
    document.getElementById('question1_button').setAttribute('aria-labelledby', 'question1_legend question1_button-text');
    document.getElementById('question2_button').setAttribute('aria-labelledby', 'question2_legend question2_button-text');
    document.getElementById('question3_button').setAttribute('aria-labelledby', 'question3_legend question3_button-text');
  }

  fillDropDowns(securityQuestions) {
    for (let i in securityQuestions) {
      this.dropDown1.options.push({
        label: securityQuestions[i].charAt(0).toUpperCase() + securityQuestions[i].slice(1),
        value: securityQuestions[i],
      });
      this.dropDown2.options.push({
        label: securityQuestions[i].charAt(0).toUpperCase() + securityQuestions[i].slice(1),
        value: securityQuestions[i],
      });
      this.dropDown3.options.push({
        label: securityQuestions[i].charAt(0).toUpperCase() + securityQuestions[i].slice(1),
        value: securityQuestions[i],
      });
    }
    this.sortQuestions();
    this.setDefaultSelect({});
  }

  onChangeInput(questionNum) {
    this.securityQuestionsForm.controls['question1'].setValue(this.dropDown1.selected);
    this.securityQuestionsForm.controls['question2'].setValue(this.dropDown2.selected);
    this.securityQuestionsForm.controls['question3'].setValue(this.dropDown3.selected);
    this.securityQuestionsForm.controls['answer1'].setValue(this.securityQuestionsForm.controls['answer1'].value?.trim());
    this.securityQuestionsForm.controls['answer2'].setValue(this.securityQuestionsForm.controls['answer2'].value?.trim());
    this.securityQuestionsForm.controls['answer3'].setValue(this.securityQuestionsForm.controls['answer3'].value?.trim());
    FormValidators.uniqueChecker(this.securityQuestionsForm);
    this.removeQuestions(questionNum);
  }

  removeQuestions(questionNum) {
    if (questionNum === 1 && this.dropDown1.selected && this.dropDown1.selected !== this.dropDown1.previouslySelected) {
      for (let i in this.dropDown2.options) {
        if (this.dropDown2.options[i].value === this.dropDown1.selected) {
          this.dropDown2.options.splice(i, 1);
        }
      }
      for (let i in this.dropDown3.options) {
        if (this.dropDown3.options[i].value === this.dropDown1.selected) {
          this.dropDown3.options.splice(i, 1);
        }
      }

      if (this.dropDown1.previouslySelected) {
        this.dropDown2.options.push({
          label: this.dropDown1.previouslySelected.charAt(0).toUpperCase() + this.dropDown1.previouslySelected.slice(1),
          value: this.dropDown1.previouslySelected,
        });
        this.dropDown3.options.push({
          label: this.dropDown1.previouslySelected.charAt(0).toUpperCase() + this.dropDown1.previouslySelected.slice(1),
          value: this.dropDown1.previouslySelected,
        });
      }
      this.dropDown1.previouslySelected = this.dropDown1.selected;
    }

    if (questionNum === 2 && this.dropDown2.selected && this.dropDown2.selected !== this.dropDown2.previouslySelected) {
      for (let i in this.dropDown1.options) {
        if (this.dropDown1.options[i].value === this.dropDown2.selected) {
          this.dropDown1.options.splice(i, 1);
        }
      }
      for (let i in this.dropDown3.options) {
        if (this.dropDown3.options[i].value === this.dropDown2.selected) {
          this.dropDown3.options.splice(i, 1);
        }
      }

      if (this.dropDown2.previouslySelected) {
        this.dropDown1.options.push({
          label: this.dropDown2.previouslySelected.charAt(0).toUpperCase() + this.dropDown2.previouslySelected.slice(1),
          value: this.dropDown2.previouslySelected,
        });
        this.dropDown3.options.push({
          label: this.dropDown2.previouslySelected.charAt(0).toUpperCase() + this.dropDown2.previouslySelected.slice(1),
          value: this.dropDown2.previouslySelected,
        });
      }
      this.dropDown2.previouslySelected = this.dropDown2.selected;
    }

    if (questionNum === 3 && this.dropDown3.selected && this.dropDown3.selected !== this.dropDown3.previouslySelected) {
      for (let i in this.dropDown1.options) {
        if (this.dropDown1.options[i].value === this.dropDown3.selected) {
          this.dropDown1.options.splice(i, 1);
        }
      }
      for (let i in this.dropDown2.options) {
        if (this.dropDown2.options[i].value === this.dropDown3.selected) {
          this.dropDown2.options.splice(i, 1);
        }
      }

      if (this.dropDown3.previouslySelected) {
        this.dropDown1.options.push({
          label: this.dropDown3.previouslySelected.charAt(0).toUpperCase() + this.dropDown3.previouslySelected.slice(1),
          value: this.dropDown3.previouslySelected,
        });
        this.dropDown2.options.push({
          label: this.dropDown3.previouslySelected.charAt(0).toUpperCase() + this.dropDown3.previouslySelected.slice(1),
          value: this.dropDown3.previouslySelected,
        });
      }
      this.dropDown3.previouslySelected = this.dropDown3.selected;
    }
    this.sortQuestions();
  }

  sortQuestions() {
    this.dropDown1.options.sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0));
    this.dropDown2.options.sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0));
    this.dropDown3.options.sort((a, b) => (a.value > b.value ? -1 : a.value < b.value ? 1 : 0));
  }

  handleUpdateSecurityQuestionsRes(res, panel, formDir) {
    this.accordionProperties.successAlert(this.labels.SuccessMessages.SecurityQuesUpdateSuccess, SuccessRegion.REGION_NAME);
    this.loading = false;
    this.expandDiv(panel, true, formDir);
    this.accordionProperties.scrollToTop();
    this.selectedQuestions = res.selectedQuestions;
    this.setDefaultSelect(this.selectedQuestions);
  }

  handleUpdateSecurityQuestionsErr(err) {
    this.securityQuestionsErr = this.errCodeMapSvc.errorCodeMapper(err, this.errorMsgs);
    // console.log(err);
    this.loading = false;
    this.securityQuestionsForm.reset(this.securityQuestionsForm.value);
  }

  get answer1() {
    return this.securityQuestionsForm.get('answer1');
  }

  get answer2() {
    return this.securityQuestionsForm.get('answer2');
  }

  get answer3() {
    return this.securityQuestionsForm.get('answer3');
  }

  onFocus() {
    this.securityQuestionsForm.updateValueAndValidity();
    this.showTooltips = true;
    this.showTooltipErrOnBlur = false;
  }

  onBlur() {
    this.showTooltips = false;
    this.showTooltipErrOnBlur = true;
  }

  onFocus2() {
    this.securityQuestionsForm.updateValueAndValidity();
    this.showTooltips2 = true;
    this.showTooltipErrOnBlur = false;
  }

  onBlur2() {
    this.showTooltips2 = false;
    this.showTooltipErrOnBlur = true;
  }

  onFocus3() {
    this.securityQuestionsForm.updateValueAndValidity();
    this.showTooltips3 = true;
    this.showTooltipErrOnBlur = false;
  }

  onBlur3() {
    this.showTooltips3 = false;
    this.showTooltipErrOnBlur = true;
  }

  emitSecurityQuestions() {
    this.emitQuestions.emit(this.securityQuestionsForm);
  }

  cancelSecurityQuestion() {
    this.router.navigate(['public/registration/member-representative/security-code']);
  }
  // public showHide(id: string, label?: string) {
  //   let element = document.getElementById(id);
  //   this.maskingSvc.showHide(element, label);
  // }
}
