export enum InterventionAnalyticsTags {
  apiFailure = 'API_Failure',
  closeButton = 'Close Button',
  closeIcon = 'Close Icon',
  errorModalCloseButton = 'closeBtnErrorModalPredictiveProactiveDb',
  errorModalXIcon = 'closeIconErrorModalPredictiveProactiveDb',
  liveChatLink = 'Live Chat Link',
  interventionClickEvent = 'userInteractionPredictiveProactive',
  interventionDataLoad = 'dataLayerLoadPredictiveProactive',
  interventionErrorCode = 'errorCodePredictiveProactive',
  interventionModal = 'Predictive/Proactive Intervention Modal',
  interventionViewClickEvent = 'userInteractionViewModalPredictiveProactive',
  interventionViewDataLoad = 'dataLayerLoadViewModalPredictiveProactive',
  noButton = 'noViewModalPredictiveProactive',
  uuidNotFound = 'UUID_Not_Found',
  viewMoreLinkInMessageCenter = 'predictiveProactiveLinkMessages',
  yesButton = 'yesViewModalPredictiveProactive'
}
