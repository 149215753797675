<div id="pcp-change-container" class="container pharmacy" *ngIf="hasPcp; else PageError">
    <div *ngIf="!isAssignPcpSuccess">
        <div *ngIf="content; else loadingOrError">
            <form #searchProviderForm="ngForm" name="searchProviderForm" autocomplete="off" novalidate>
                <!-- Change PCP header start -->
                <h2 class="section-header-two" [innerHTML]="content.changePcp.header" id="pcp-change-pcp-header" tabindex="0" role="heading" aria-live="polite">
                </h2>
                <!-- Change PCP header end -->

                <!-- Change PCP sub header start -->
                <p [innerHTML]="content.changePcp.subHeader" tabindex="0" id="pcp-change-sub-header">
                </p>
                <!-- Change PCP sub header end -->

                <!-- Search Container start-->
                <div class="pcp-search-wrapper">
                    <!-- change pcp page loader start -->
                    <div class="pcp-loader-spinner" *ngIf="changePcpLoader">
                        <div id="pcp-change-loading-message">
                            <uxd-spinner></uxd-spinner>
                        </div>
                    </div>
                    <!-- change pcp page loader end -->
                    <div class="pcp-search-container" *ngIf="!changePcpLoader">
                        <!-- Search PCP Name Input-->
                        <div class="pcp-nw-container form-item">
                            <button class="pcp-search-icon" tabindex="-1">
                                <span class="motif-icon motif-search-1"></span>
                                <span class="sr-only" [innerHTML]="content.changePcp.searchByPcpName"></span>
                            </button>
                            <input #txtProviderName="ngModel" id="pcp-change-providerName" name="providerName"
                                [attr.aria-label]="content.changePcp.searchByPcpName" maxlength="150" type="text"
                                [(ngModel)]="searchProvider.providerName"
                                class="ant-text-input ant-search-input pcp-search-input"
                                [attr.placeholder]="content.changePcp.searchByPcpName"
                                [attr.aria-label]="content.changePcp.searchByPcpNameAria" (keyup.enter)="searchProviders()" />
                        </div>

                        <!-- Search Radius-->
                        <div class="pcp-search-radius form-item">
                            <div data-uxd-dropdown-cmp class="ant-lg-select" #providerSearchRadius="ngModel"
                                [(ngModel)]="searchProvider.range"
                                [attr.aria-label]="content.changePcp.searchRadius.ariaLabel" [labelText]="''"
                                [defaultText]="content.changePcp.searchRadiusLbl" name="providerSearchRadius"
                                class="pcp-change-provider-search-radius" [items]="content.changePcp.searchRadius.options"
                                required>
                            </div>
                        </div>
                        <!-- City or Zip -->
                        <div class="form-item pcp-zip-container search-pcp-input-container">
                            <input #txtZipCode="ngModel" id="pcp-change-zip-code" name="zipCode"
                                [attr.aria-label]="content.changePcp.cityOrZipAria" maxlength="20" type="text"
                                [(ngModel)]="searchProvider.zipCode"
                                class="ant-text-input ant-search-input pcp-search-input"
                                [ngClass]="{'ant-error-state': ((txtZipCode.touched && txtZipCode.invalid)||(txtZipCode.invalid && searchProviderForm.submitted))}" (keyup.enter)="searchProviders()"
                                [attr.placeholder]="content.changePcp.cityOrZip" required />
                            <div class="error-list error-padding" role="alert"
                                *ngIf="(txtZipCode.dirty || txtZipCode.touched || searchProviderForm.submitted) && txtZipCode.invalid">
                                <div *ngIf="txtZipCode.errors.required">
                                    <span class="success"><i class="fas fa-times"></i>
                                        <span class="motif-margin-left-5">
                                            <span [innerHtml]="content.changePcp.zipCodeRequiredError"></span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="filter-row ant-margin-top-20" *ngIf="!changePcpLoader">
                        <!-- Search button -->
                        <button [attr.data-analytics]="content.changePcp.analytics.searchButton" [attr.data-aria]="content.changePcp.searchButtonAria"
                            id="pcp-change-search-button-label" class="btn btn-primary" (click)="searchProviders()"
                            [innerHTML]="content.changePcp.searchButton">
                        </button>
                    </div>
                </div>
                <!-- Search Container end-->

                <hr aria-hidden="true">

                <!-- search loader start -->
                <div class="pcp-loader-spinner" id="pcp-change-search-loading-message" *ngIf="searchProviderLoader">
                    <uxd-spinner ></uxd-spinner>  
                </div>
                <!-- search loader end -->

                <!-- search provider error start -->
                <div *ngIf="!searchProviderLoader">
                    <div *ngIf="searchProviderErrorMessage" class="pcp-no-res-err-container" id="pcp-change-error-message">
                        <div class="motif-icon motif-search-1"></div>
                        <p [innerHTML]="content.changePcp.noSearchResultMessage"></p>
                        <p [innerHTML]="content.changePcp.noSearchResultDisclaimer"></p>
                    </div>
                </div>

                <div *ngIf="searchProviderAPIErrorMessage" class="pcp-warn-error vgr-margin-bottom-1" role="alert" aria-atomic="true">
                    <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}" id="pcp-change-search-error-message">
                        <span [innerHTML]="searchProviderAPIErrorMessage"></span>
                    </div>
                </div>

                <div *ngIf="zipCodeErrorMessage" class="pcp-warn-error vgr-margin-bottom-1" role="alert" aria-atomic="true">
                    <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}" id="pcp-invalid-zip-error-message">
                        <span [innerHTML]="zipCodeErrorMessage"></span>
                    </div>
                </div>
                <!-- search provider error end -->
            </form>
            <div class="provider-details-container" *ngIf="!searchProviderAPIErrorMessage && !zipCodeErrorMessage && providerList?.length > 0">
                <div *ngIf="!searchProviderLoader && !searchProviderErrorMessage">
                    <div class="row pcp-mg-bot-2">
                        <!-- Specialty Filter button-->
                        <div class="col-xxs-12 col-sm-6">
                            <button [attr.data-analytics]="content.changePcp.specialty.toggleFilterAnalytics"
                                id="pcp-change-specialty-filter-button" class="btn btn-secondary"
                                (click)="toggleFilterPanel()" [innerHTML]="content.changePcp.specialty.toggleFilterLbl" [attr.aria-label]="content.changePcp.specialty.toggleFilterAria">
                            </button>

                            <!-- Filter Panel -->
                            <div filter-panel-container class="filter-panel-container" *ngIf="displaySpecialtyFilterPanel"
                                #specialtyFilterPanel [attr.aria-hidden]="!displaySpecialtyFilterPanel">
                                <div filter-panel class="pcp-spl-filter-panel-wrapper">
                                    <div id="pcp-spl-filter-panel" class="filter-panel pcp-spl-filter-panel">
                                        <div class="filter-container pcp-spl-filter-container">
                                            <!-- Specialty options -->
                                            <p [id]="'pcp-change-select-specility-header'" tabindex="0" [innerHTML]="content.changePcp.specialty.header"></p> 
                                            <!-- select All start -->
                                            <div [id]="'pcp-change-select-specility-select-all'" data-uxd-checkbox-input-cmp [name]="'pcp-change-select-all-specialities'" [(ngModel)]="selectAllSpecialities"
                                            (ngModelChange)="toggleSpecialtySelectAll()" [definition]="{id: 'pcp-change-select-specility-select-all-input', name: 'pcp-change-select-all-specialities', label: content.changePcp.specialty.toggleSelectAllLabel, trueValue: true, falseValue: false, analyticsText: (selectAllSpecialities? content.changePcp.specialty.toggleSelectAllAnalytics : content.changePcp.specialty.toggleRemoveAllAnalytics)}"
                                            [optionalAriaLabel]="content.changePcp.specialty.toggleSelectAllLabelAria"></div>
                                            <!-- select All end -->
                                            <ng-container *ngFor="let specialty of specialtyList; let index = index;">
                                                <div data-uxd-checkbox-input-cmp #input [id]="'pcp-change-select-specility-' + index" 
                                                    [name]="'pcp-change-select-specility-' + index" [(ngModel)]="specialty.isSelected" (ngModelChange)="toggleSpecialtySelect()"
                                                    [definition]="{id: ('pcp-change-select-specility-input-' + index), name: ('pcp-change-select-specility-input-' + index), label: specialty.label, trueValue: true, falseValue: false}"
                                                    [optionalAriaLabel]="specialty.ariaLabel + ' ' + (index + 1) + ' of ' + specialtyList.length">
                                                </div>
                                            </ng-container>
                                        </div>
                                            <div class="filter-row pcp-spl-filter-row">
                                                <div class="pcp-spl-filter-btn-container">
                                                    <!-- Clear filter button -->
                                                    <button
                                                        [attr.data-analytics]="content.changePcp.specialty.clearFiltersButtonAnalytics"
                                                        id="pcp-change-clear-filter-button" class="btn btn-secondary btn-sm"
                                                        (click)="clearSpecialtyFilter()">
                                                        <span id="pcp-change-clear-filter-label" [attr.aria-label]="content.changePcp.specialty.clearFilterButtonAria"
                                                            [innerHTML]="content.changePcp.specialty.clearFilterButton"></span>
                                                    </button>
                                                    &nbsp;
                                                    <!-- Done filter button -->
                                                    <button
                                                        [attr.data-analytics]="content.changePcp.specialty.applyFiltersButtonAnalytics"
                                                        id="pcp-change-apply-filter-button" class="btn btn-primary btn-sm"
                                                        (click)="filterProviders()">
                                                        <span id="pcp-change-apply-filter-label" [attr.aria-label]="content.changePcp.specialty.applyFilterButtonAria"
                                                            [innerHTML]="content.changePcp.specialty.applyFilterButton"></span>
                                                    </button>
                                                </div>
                                            </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Sort by dropdown-->
                        <div class="col-xxs-12 col-sm-6">
                            <div class="pcp-spl-dstn">
                                <span class="pcp-spl-dstn-txt" [innerHTML]="content.changePcp.sortBy" id="pcp-change-sort-by-dropdown_legend"></span>
                                <div data-uxd-dropdown-cmp class="ant-md-select" [(ngModel)]="sortByDropdown"
                                    (ngModelChange)="sortProviders($event)"
                                    [attr.data-aria]="content.changePcp.sort.ariaLabel"
                                    [defaultText]="content.changePcp.sort.title" name="sortByDropdown"
                                    id="pcp-change-sort-by-dropdown" [items]="content.changePcp.sort.options" required>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- List and Tile View start -->
                    <div class="row pharmacy">
                        <div *ngIf="!searchProviderLoader && !searchProviderAPIErrorMessage && !zipCodeErrorMessage && searchResultMessage" aria-atomic="true" aria-live="polite" role="status"
                            class="no-print pcp-res-count" tabindex="0" id="pcp-change-list-view">
                            <span [attr.aria-label]="searchResultAriaMessage" [innerHTML]="searchResultMessage"></span>
                        </div>

                        <ant-tab-group class="minimal-tabbedview show-icons">
                            <div ant-tab [label]="content.changePcp.listView" [analytics]="content.changePcp.analytics.listViewBitton">
                                <ng-template ant-tab-label class="no-print">
                                    <span [attr.aria-label]="content.changePcp.listViewAria">
                                        <span [id]="'pcp-change-list-view-tab'" [innerText]="content.changePcp.listView"></span>
                                        <span class="motif-icon motif-todo-list"></span>
                                    </span>
                                </ng-template>
                                <ng-template ant-tab-content>
                                    <div *ngFor="let provider of providerList || []; let i = index; let first = first; let last = last;" class="row">
                                        <div class="col-xxs-12 col-xs-12 pcp-list-view-container" [id]="'pcp-change-row-wrapper-' + i">
                                            <!-- Provider Name, distance, address column -->
                                            <div #providers class="col-sm-4 col-xxs-12" [id]="'pcp-change-row-' + i" tabindex="0" [attr.aria-label]="provider.providerName + ' ' + provider.groupsName + ' ' + content.changePcp.sort.title + ' ' + provider.distance + ' ' + content.changePcp.milesAria + ' ' + provider.ariaAddress">
                                                <div [innerText]="provider.providerName" [id]="'pcp-change-list-view-provider-name-' + i"
                                                    [attr.aria-label]="provider.providerName" class="pcp-list-prov-nm">
                                                </div>

                                                <div *ngIf="provider.groupsName" [innerText]="provider.groupsName"
                                                    [id]="'pcp-change-list-view-groups-name-' + i"
                                                    [attr.aria-label]="provider.groupsName">
                                                </div>

                                                <div [id]="'pcp-change-list-view-provider-distance-' + i" class="pcp-list-dstn">
                                                    <span class="motif-icon motif-marker"></span>
                                                    <span
                                                        [innerHTML]="content.changePcp.sort.title + ' ' + provider.distance + ' ' + content.changePcp.miles"
                                                        class="pcp-list-dstn-txt"></span>
                                                </div>

                                                <div [id]="'pcp-change-list-view-provider-address-' + i" [innerHTML]="provider.address"
                                                    [attr.aria-label]="provider.ariaAddress">
                                                </div>

                                                <!-- Search PCP - Phone number -->
                                                <section *ngIf="provider.phoneNumber">
                                                    <span aria-hidden="true" class="motif-icon motif-phone"></span>
                                                    <a href="tel:{{provider.phoneNumber}}" class="pcp-phn-link mobile" [attr.aria-label]="provider.providerName + ' ' + content.phoneNumberAria + ' ' + provider.phoneNumber" [id]="'pcp-change-list-view-provider-phone-number-link-' + i" [attr.data-analytics]="content.changePcp.analytics.phoneNumberLink">                                                    
                                                        <span [id]="'pcp-change-list-view-provider-phone-number-' + i" [innerText]="provider.phoneNumber"></span>
                                                    </a>
                                                    <span class="pcp-phn-link-na desktop" [id]="'pcp-change-list-view-provider-phone-number-' + i" [innerText]="provider.phoneNumber"></span>
                                                </section>
                                            </div>

                                            <!-- Specialty column -->
                                            <div class="col-sm-4 col-xxs-12">
                                                <div *ngIf="provider.specialties"
                                                    [attr.aria-label]="content.changePcp.specialtyColumnAria + provider.providerName + ' ' + provider.specialties" tabindex="0"
                                                    [id]="'pcp-change-list-view-specialties-column-' + i">
                                                    <p [innerText]="content.changePcp.specialtyColumnLabel"></p>
                                                    <span [innerText]="provider.specialties"
                                                        [id]="'pcp-change-list-view-specialty-column-label-' + i"></span>
                                                </div>
                                            </div>

                                            <!-- Assign PCP column-->
                                            <div class="col-sm-4 col-xxs-12
                                            pcp-current-pcp-status">
                                            <p *ngIf="provider.isCurrentPCP" tabindex="0" [id]="'pcp-change-list-view-assign-pcp-button-' + i" [innerHTML]="content.changePcp.currentPcpText" [attr.aria-label]="content.currentPcpAria + provider.providerName"></p>
                                            <button *ngIf="!provider.isCurrentPCP" [attr.data-analytics]="content.changePcp.analytics.assignPcpButton"
                                                [attr.data-aria]="content.changePcp.assignPcpBtnAria" (click)="openAssignPcpPanel('right', provider)"
                                                [attr.aria-label]="provider.providerName + content.changePcp.assignPcpBtn"
                                                    [id]="'pcp-change-list-view-assign-pcp-button-' + i" class="btn btn-primary">
                                                    <span [innerHTML]="content.changePcp.assignPcpBtn"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>

                            <div ant-tab [label]="content.changePcp.tileView" [analytics]="content.changePcp.analytics.tileViewBitton">
                                <ng-template ant-tab-label class="no-print">
                                    <span [attr.aria-label]="content.changePcp.tileViewAria">
                                        <span [id]="'pcp-change-tile-view-tab'" [innerText]="content.changePcp.tileView"></span>
                                        <span class="motif-icon motif-bento-box"></span>
                                    </span>
                                </ng-template>
                                <ng-template ant-tab-content>
                                    <div class="pcp-tile-view-container cards-wrapper">
                                        <div class="card pcp-medium-card card-flat card-popover"
                                            *ngFor="let provider of providerList || []; let i = index">
                                            <div class="pcp-tile-content-container" tabindex="0" [attr.aria-label]="provider.providerName + ' ' + provider.groupsName + ' ' + content.changePcp.sort.title + ' ' + provider.distance + ' ' + content.changePcp.milesAria + ' ' + provider.ariaAddress + ' ' + content.changePcp.specialtyColumnAria + provider.providerName + ' ' + provider.specialties" [id]="'pcp-change-row-' + i">
                                                <div [innerText]="provider.providerName" [id]="'pcp-change-tile-view-provider-name-' + i"
                                                    class="pcp-tile-prov-nm" [attr.aria-label]="provider.providerName">
                                                </div>

                                                <div *ngIf="provider.groupsName" [innerText]="provider.groupsName"
                                                    [id]="'pcp-change-tile-view-groups-name-' + i" [attr.aria-label]="provider.groupsName">
                                                </div>

                                                <div [id]="'pcp-change-tile-view-provider-distance-' + i" class="pcp-tile-list-dstn">
                                                    <span class="motif-icon motif-marker"></span>
                                                    <span
                                                        [innerHTML]="content.changePcp.sort.title + ' ' + provider.distance + ' ' + content.changePcp.miles"
                                                        class="pcp-tile-dstn-txt"></span>

                                                </div>
                                                <div [id]="'pcp-change-tile-view-provider-address-' + i" [innerHTML]="provider.address"
                                                    [attr.aria-label]="provider.ariaAddress">
                                                </div>

                                                <!-- Search PCP - Phone number -->
                                                <section *ngIf="provider.phoneNumber">
                                                    <span aria-hidden="true" class="motif-icon motif-phone"></span>
                                                    <a href="tel:{{provider.phoneNumber}}" class="pcp-phn-link mobile" [attr.aria-label]="provider.providerName + ' ' + content.phoneNumberAria + ' ' + provider.phoneNumber" [id]="'pcp-change-tile-view-provider-phone-number-link-' + i" [attr.data-analytics]="content.changePcp.analytics.phoneNumberLink">                                                    
                                                        <span [id]="'pcp-change-tile-view-provider-phone-number-' + i" [innerText]="provider.phoneNumber"></span>
                                                    </a>
                                                    <span class="pcp-phn-link-na desktop" [id]="'pcp-change-tile-view-provider-phone-number-' + i" [innerText]="provider.phoneNumber"></span>
                                                </section>

                                                <div *ngIf="provider?.specialties"
                                                    [id]="'pcp-change-tile-view-specialties-column-' + i" class="motif-margin-top-5 vgr-margin-bottom-2">
                                                    <p [innerText]="content.changePcp.specialtyColumnLabel"></p>
                                                    <span [innerText]="provider.specialties"
                                                        [id]="'pcp-change-tile-view-specialty-column-label-' + i "></span>
                                                </div>
                                            </div>

                                            <!-- Assign PCP column-->
                                            <div class="pcp-current-pcp-status">
                                                <p *ngIf="provider.isCurrentPCP" tabindex="0" [id]="'pcp-change-tile-view-assign-pcp-button-' + i" class="bold" [innerHTML]="content.changePcp.currentPcpText" [attr.aria-label]="content.currentPcpAria + provider.providerName"></p>
                                                <button *ngIf="!provider.isCurrentPCP" [attr.data-analytics]="content.changePcp.analytics.assignPcpButton" [attr.data-aria]="content.changePcp.assignPcpBtnAria"
                                                    [attr.aria-label]="provider.providerName + content.changePcp.assignPcpBtn"
                                                    [id]="'pcp-change-tile-view-assign-pcp-button-' + i" class="btn btn-primary" (click)="openAssignPcpPanel('right', provider)">
                                                    <span [innerHTML]="content.changePcp.assignPcpBtn"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </ant-tab-group>

                        <!-- Load more start -->
                        <div class="pcp-loader-spinner" *ngIf="loadMoreLoader"
                        id="pcp-change-load-more-loading-message">
                            <uxd-spinner></uxd-spinner>
                        </div>
                        
                        <div *ngIf="isMoreDataAvailable && !loadMoreLoader" class="ant-margin-top-20 vgr-margin-bottom-2 text-center">
                            <button href="javascript:void()" [id]="'pcp-change-load-more-link'" [attr.data-analytics]="content.changePcp.analytics.loadMoreButton" class="btn btn-secondary" (click)="onLoadMoreClick()" [innerText]="content.changePcp.loadMore">
                            </button>
                        </div>
                        <!-- Load more end -->
                    </div>

                    <!-- List and Tile View end -->
                </div>
            </div>
        </div>
        <ng-template #loadingOrError>
            <div *ngIf="genericError; else loading" class="container" data-uxd-alert-cmp-v2
                [alert]="{alertType: 'negetive'}">
                <span id="pcp-change-authorization-error" [innerHTML]="genericError"></span>
            </div>
            <ng-template #loading>
                <div id="pcp-content-loader-spinner" data-uxd-data-loader-cmp="" class="container">
                    <span [innerHTML]="'Loading... please wait.'"></span>
                </div>
            </ng-template>
        </ng-template>
        <!-- Side panel for assign pcp-->
        <ng-template #sidePanelContent>
            <div class="modal-example">
                <div class="clearfix">
                    <button class="close" (click)="backToAssignPCP(); this.sidePanelRef.close();" [attr.aria-label]="content.changePcp.closeAria">
                        <span class="close-icon" [attr.title]="content.changePcp.close"></span>
                    </button>
                </div>
                <div class="modal-component-body">
                    <div class="claims-slide-in-margin">
                        <!-- Assign PCP header-->
                        <h2 [innerHTML]="content.assignPcp.header" [attr.aria-label]="content.assignPcp.header"
                            id="assign-pcp-header" tabindex="0"></h2>

                        <div id="current-pcp-detail" *ngIf="!(isServerError || isReasonError || pcpUpdatedTwiceError || pcpUpdatedError)">
                            <!-- Current PCP Detail-->
                            <data-sydmed-pcp-view-cmp [isAssignPCP]="true" (pcpFetched)="updateIsLockedIn($event)"></data-sydmed-pcp-view-cmp>

                            <!-- Choose Reason to change PCP start -->
                            <div class="pcp-change-reason" *ngIf="!fetchReasonLoader && !isLockedIn">
                                <p [innerHTML]="content.assignPcp.changePcpDisclaimer" tabindex="0"></p>
                                <div data-uxd-dropdown-cmp class="ant-md-select" [labelText]="content.assignPcp.chooseReason"
                                    name="changeReasonSelect" id="changeReasonSelect" [(ngModel)]="reasonCode" [items]="changeReasonsList" required
                                    (ngModelChange)="this.enableContinue = true;"  [defaultText]="content.assignPcp.pleaseSelect">
                                </div>
                            </div>
                            <!-- Choose Reason to change PCP end -->

                            <!-- Assign PCP API call loader-->
                            <div class="pcp-loader-spinner"  *ngIf="assignPcpLoader"
                            id="assign-pcp-progress-message">
                                <uxd-spinner></uxd-spinner>
                             </div>

                                <button *ngIf="!isLockedIn" class="btn btn-primary" id="pcp-assign-continue-button" [disabled]="!enableContinue"
                                    [attr.aria-label]="content.assignPcp.continue" [innerText]="content.assignPcp.continue"
                                    [attr.data-analytics]="content.assignPcp.analytics.continueAssignPcp" (click)="assignPCP()">
                                </button>
                            </div>
                       
                        <div *ngIf="isServerError || isReasonError || pcpUpdatedTwiceError || pcpUpdatedError" class="ant-margin-top-20 vgr-margin-bottom-1" role="alert" aria-atomic="true">
                            <!-- Display reason or server error -->
                            <div data-uxd-alert-cmp-v2 class="ant-margin-top-20 vgr-margin-bottom-1" [alert]="{alertType: 'negative'}" [attr.id]="'reasonErrorDiv'">
                                <span *ngIf="isReasonError" id="reasonError" [innerHTML]="content.error.reasonError"></span>
                                <span *ngIf="isServerError" id="serverError" [innerHTML]="content.error.genericError"></span>
                                <span *ngIf="pcpUpdatedTwiceError" id="pcpError" [innerHTML]="content.error.pcpUpdatedTwiceError"></span>
                                <span *ngIf="pcpUpdatedError" id="pcpUpdateError" [innerHTML]="content.error.pcpUpdatedError"></span>
                            </div>

                            <!-- Back to Assign PCP page button-->
                            <div class="ant-margin-top-20 ">
                                <button class="btn btn-primary" id="pcp-back-button" 
                                    [attr.aria-label]="content.assignPcp.back" [innerText]="content.assignPcp.back" [attr.data-analytics]="content.assignPcp.analytics.backButton" (click)="backToAssignPCP()"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <!-- Assign PCP Success section start -->
    <div *ngIf="isAssignPcpSuccess" id="assignPcpSuccess">
        <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'positive'}">
            <span [innerHTML]="content.assignPcp.confirmationMsg"></span>
        </div>
        <div class="cards-wrapper">
            <div class="card pcp-large-card card-flat card-popover">
                <span id="assign-success-msg" [innerHTML]="successMessage"></span>
                <div id="mailing-address" class="ant-margin-top-20 vgr-margin-bottom-1" [innerHTML]="mailingAddress"></div>
                <span id="assign-success-msg-disclaimer" [innerHTML]="content.assignPcp.successMsgDisclaimer"></span>
            </div>
        </div>
    </div>
    <!-- Assign PCP Success section end -->
</div>

<ng-template #PageError>
    <div class="container">
      <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span [innerHTML]="content?.error.genericPcpError"></span>
      </div>
    </div>  
</ng-template>