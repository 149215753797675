import { AfterViewChecked, Component,  OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { PaymentPortalService } from '.././services/payment-portal.service';
import { PaymentHelperService } from '.././services/payment-helper.service';
import { AlertService, UniversalAlertType, InlineAlertContainerComponent } from '@anthem/uxd/alert';
import { SidePanel, ModalRef } from '@anthem/uxd/modal';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { PaymentInfo } from '../interfaces/PaymentInfo';
import { PaymentAmountSelection } from '../interfaces/PaymentAmountSelection.enum';
import { Router } from '@angular/router';
import { browserRefresh } from '../../../app.component';
import { PaymentType as PaymentMethodType, BankPaymentType, PaymentConfirmation } from 'gbd-models';
import { ValidateBankAccountResponse, ValidationStatus } from 'gbd-models/src/features/validateBankAccount';
export const CONTRACT_CODE: Readonly<string> = '0';
export const GENERAL_ERROR: Readonly<string> = 'error';

@Component({
  selector: 'app-pay-now',
  templateUrl: './pay-now.component.html',
  styleUrls: ['./pay-now.component.scss']
})
export class PayNowComponent implements AfterViewChecked, OnInit, OnDestroy {

  bankAccountDeclinedMessage: string;
  labels: any;
  headerJson: any;
  earlyWarningLabel: string;
  earlyWarningLink: string;

  earlyWarningServiceDescription: string;
  errorMsgs: any;
  isEarlyWarningError = false;
  isBankAccountDeclinedError = false;
  isPaymentsLoading: boolean = false;
  isPaymentsError: boolean = false;
  isGenericError = false;
  isPaymentValid: boolean = false;
  disablePaymentButton: boolean = false;
  selectedDate: Date = new Date();
  selectedDateFormatted: string;
  dateDisplayFormat = 'MM/DD/YYYY';
  monthFormat = 'MMMM';
  weekDayFormat = 'dddd';
  inputDisabled = false;
  selectedWeekDay: string;
  selectedDay: string;
  selectedMonth: string;
  min: Date = new Date();
  max: Date;
  opened = false;
  isCalendarOpen = false;
  startAt: Date | null;
  startView = 'month';
  eventType: any = '';
  selectedPayment: PaymentInfo;
  selectedPaymentMethod: PaymentMethod = null;
  isPaymentMethodAlert: boolean = false;
  paymentMethodText: string = "";
  @ViewChild('sidePanelContent')
  sidePanelConent: TemplateRef<HTMLElement>;
  @ViewChild('sidePanelPayment')
  sidePanelPayment: TemplateRef<HTMLElement>;
  public sidePanelRef: ModalRef<any, any>;
  public selected: any = PaymentAmountSelection.MonthlyContribution;
  isOtherAmount: boolean = false;
  otherAmount: number = null;
  otherAmountInvalid: boolean = false;
  monthlyAmount: string;
  public radioGroup1;
  public radioGroup2;
  public AmountSelection: PaymentAmountSelection;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  @ViewChild(InlineAlertContainerComponent) child;
  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private _service: PaymentPortalService,
    private _helper: PaymentHelperService,
    private _alert: AlertService,
    private _sidePanel: SidePanel,
    public router: Router
  ) {
    this.jsonSvc.getJSON('payment-portal').subscribe(data => {
      this.labels = data.paymentPortal.labels;
      this.headerJson = data.paymentPortal.HeaderNav;
      this.errorMsgs = data.paymentPortal.errors;
      this.earlyWarningLabel = this.errorMsgs?.EarlyWarningServiceLabel;
      this.earlyWarningLink = this.errorMsgs?.EarlyWarningServiceLink;
      this.earlyWarningServiceDescription = this.errorMsgs?.EarlyWarningServiceDescription;

      this.interCommunicationService.raiseEvent({
        title: this.headerJson.HeaderTitle,
        message: this.headerJson.HeaderMessage
      });
    });
    this._helper.initiateSelectedPaymentMethod();
  }


  ngOnInit(): void {
    if (browserRefresh) {
      const res = JSON.parse(sessionStorage.getItem('billInfo'));
      if (res) {
        this.setPaymentDetails(res);
      }
    }
    this._helper.selectedPaymentMethod.subscribe((res: any) => {
      if (res) {
        if (res.error) {
          const errorCode = res?.error;
          this.setErrorMessages(errorCode);
          this.isPaymentMethodAlert = false;
          this.scrollToTop();
        }
        else if (res.paymentMethod) {
          this.setErrorMessages(null);
          this.selectedPaymentMethod = res.paymentMethod;
          if (this.selectedPaymentMethod.isBankAcc) {
            this.isPaymentValid = true;
          }
          else {
            this.selectedDate = new Date();
          }

          if (res.isAdded && !this.selectedPaymentMethod.isBankAcc) {
            this.paymentMethodText = this.errorMsgs.CCAddedMessage;
          }
          else if (!res.isAdded && !this.selectedPaymentMethod.isBankAcc) {
            this.paymentMethodText = this.errorMsgs.CCUpdatedMessage;
          }
          else if (!res.isAdded && this.selectedPaymentMethod.isBankAcc) {
            this.paymentMethodText = this.errorMsgs.BankAccUpdatedMessage;
          }
          else {
            this.paymentMethodText = this.errorMsgs.BankAccAddedMessage;
          }
          this.isPaymentMethodAlert = true;
        }

      }
    });

    this._helper.selectedPayment.subscribe((res: PaymentInfo) => {
      if (res) {
        this.setPaymentDetails(res);
      }

    });
  }

  private setPaymentDetails(res: PaymentInfo) {
    this.selectedPayment = res;
    this.monthlyAmount = this.selectedPayment.amount;
    this.radioGroup1 = {
      isOptional: false,
      alignVertical: true,
      id: 'rbtnGrp1',
      name: 'rbtnGrp1',
      legendSrOnly: true,
      options: [
        {
          label: "$" + this.selectedPayment.amount,
          value: PaymentAmountSelection.MonthlyContribution,
          analytics: 'analytics-one',
          ariaLabel: 'aria label one'
        }
      ]
    };
    this.radioGroup2 = {
      isOptional: false,
      alignVertical: true,
      id: 'rbtnGrp2',
      name: 'rbtnGrp2',
      legendSrOnly: true,
      options: [
        {
          label: this.labels?.OtherAmount,
          value: PaymentAmountSelection.OtherAmount,
          analytics: 'analytics-one',
          ariaLabel: 'aria label two'
        }
      ]
    };
  }

  ngAfterViewChecked() {
    if (this.isCalendarOpen && this.selectedPayment && this.selectedPayment.dueDate) {
      const ariaValue = this._helper.transformDueDate(this.selectedPayment.dueDate);
      const element = document.querySelector('[aria-label="' + ariaValue + '"]');
      if (element && element.className.indexOf('due-date') === -1) {
        element.className += " due-date";
      }
    }
  }

  openedStream(event: any) {
    this.eventType = 'calendar opened';
    this.isCalendarOpen = true;
  }
  closedStream(event: any) {
    this.eventType = 'calendar closed';
    this.isCalendarOpen = false;
  }

  dateChange(event: any) {
    this.eventType = 'selected date modified';
  }

  openSidePanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.sidePanelConent);
  }

  /** Event handler for click of close button  */
  onClose() {
    this.otherAmountInvalid = false;
    this.sidePanelRef.close();
  }

  onPanelClosedShowAlert($event: string) {
    alert("From pay now alert " + $event);
  }

  isCardExpired(monthYear: string) {
    let month = monthYear.split('/')[0];
    let year = monthYear.split('/')[1];
    if (month && year) {
      let currentDate = new Date();
      let date = new Date(+year, +month, 1, 0, 0, 0, 0);
      let dateNotInFuture = date < currentDate;
      if (dateNotInFuture)
        this.isPaymentValid = false;
      else
        this.isPaymentValid = true;
      return dateNotInFuture;
    }
  }
  isAmountSelected($event) {
    this.selected = $event.target.value;
    this.otherAmount = null;
  }

  onKeyUp() {
    if (this.otherAmount == null) {
      this.otherAmountInvalid = false;
    }
  }

  onUpdate() {
    if (this.selected == PaymentAmountSelection.OtherAmount) {
      if (this.otherAmount >= 1) {
        this.otherAmountInvalid = false;
      }
      else {
        this.otherAmountInvalid = true;
        return false;
      }
      this.selectedPayment.amount = this.otherAmount.toString();
      this.isOtherAmount = true;
    }
    else {
      this.selectedPayment.amount = this.monthlyAmount;
      this.isOtherAmount = false;
    }
    this.isPaymentMethodAlert = true;
    this.paymentMethodText = this.errorMsgs.PaymentMethodUpdated;
    this.onClose();
  }

  onAddPaymentMethod() {
    this.isPaymentsError = false;
    this.isPaymentMethodAlert = false;
    this.clearErrorAlert();
    this.sidePanelRef = this._sidePanel.open('right', this.sidePanelPayment);
  }

  public get AmountSelectionOption() {
    return PaymentAmountSelection;
  }

  scrollToTop(): void {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

  setErrorMessages(errorCode: string) {
    if (!errorCode) {
      this.isPaymentsError = false;
      this.isEarlyWarningError = false;
      this.isBankAccountDeclinedError = false;
    }

    if (errorCode === GENERAL_ERROR) {
      this.paymentMethodText = errorCode;
      this.isPaymentsError = true;
    } else {
      this.isPaymentMethodAlert = false;
      this.isPaymentsError = false;
      this.isEarlyWarningError = errorCode === ValidationStatus.DECLINED_2002;
      this.isBankAccountDeclinedError = errorCode === ValidationStatus.DECLINED_2001;
      this.bankAccountDeclinedMessage = this.isEarlyWarningError
        ? this.errorMsgs?.BankAccountDeclined_2002
          : this.errorMsgs?.BankAccountDeclined;
    }
  }

  submitPayment() {
    this.isPaymentMethodAlert = false;
    this.disablePaymentButton = true;
    if (this.selectedPaymentMethod.isBankAcc) {
      this.submitBankPayment();
    } else {
      this.submitCreditCardPayment();
    }
  }

  showGlobalError(message) {
    this._alert.error(message, {
      regionName: 'payment-alert',
      alertListType: 'queue',
      politeness: 'polite'
    });
  }

  ngOnDestroy() {
    const emptyPayment = null as PaymentMethod;
    this._helper.setSelectedPaymentMethod(emptyPayment, false, null);
  }

  clearErrorAlert() {
    this.child.dismissAlertId(0);
  }

  createPaymentPayload(paymentMethod) {
    return {
      bill: {
        amountDue: parseFloat(this.selectedPayment.amount),
        dueDate: this._helper.transformSelectedDate(this.selectedDate),
        hcid: this.selectedPayment.memberId,
        memberName: this.selectedPayment.memberName,
        minDue: parseInt(this.selectedPayment.amount, 10),
        paidToDate: null,
        overdue: this.selectedPayment.overDueDays,
        paymentAmount: parseInt(this.selectedPayment.amount, 10),
        paymentStatus: this.selectedPayment.paymentProcess,
        planName: this.selectedPayment.planName,
        memberSequenceNumber: this.selectedPayment.memberSequenceNumber,
        summaryBillNo: this.selectedPayment.summaryBillNo,
        planId: this.selectedPayment.planId,
        productId: this.selectedPayment.productId
      },
      paymentMethod
    };
  }

  submitBankPayment() {
    const paymentMethod = {
      tokenId: this.selectedPaymentMethod.tokenId,
      accountType: PaymentMethodType.BANK_ACCOUNT,
      accountDetails: {
        accountNumber: this.selectedPaymentMethod.tokenId ? null : this.selectedPaymentMethod.bankAccountNumber,
        routingNumber: this.selectedPaymentMethod.tokenId ? null : this.selectedPaymentMethod.routingNumber,
        accountName: this.selectedPaymentMethod.accountName,
        bankAccountType: this.selectedPaymentMethod.bankAccountType as BankPaymentType,
        companyName: null
      },
      accountHolderInfo: this.selectedPaymentMethod.accountHolderInfo,
    };

    const paymentPayload = this.createPaymentPayload(paymentMethod);

    this._service.makePayment(paymentPayload).subscribe(
      res => this.handlePaymentResponse(res, true),
      err => this.handlePaymentError(err)
    );
  }

  handlePaymentResponse(res, isBankAccount?: boolean) {
    if (isBankAccount && res?.validationStatus && !this.bankAccountValidated(res?.validationStatus)) {
      throw res?.validationStatus;
    }

    if (res?.body?.paymentConfirmation?.confirmation?.contractCode === CONTRACT_CODE) {
      const payConf = this._helper.mapBillToConfirm(this.selectedPayment, res, this.selectedPaymentMethod, this.labels);
      this.selectedPayment.confirmationNumber = res?.body?.paymentConfirmation?.confirmation?.confirmationNumber ?? null;
      this._helper.setPaymentConfirmation(payConf);
      this.disablePaymentButton = false;
      this.router.navigate(['/secure/make-payment/confirmation']);
    }
    else {
      throw GENERAL_ERROR;
    }
  }

  handlePaymentError(err) {
    const validationError = this.getValidationError(err);
    this.disablePaymentButton = false;
    if (validationError) {
      this.setErrorMessages(validationError);
    } else {
      this.showGlobalError(this.labels.PaymentFailure);
    }
    window.scrollTo(0, 0);
  }

  private bankAccountValidated(status: ValidationStatus) {
    return status?.toUpperCase() === ValidationStatus.ACCEPTED.toUpperCase();
  }

  submitCreditCardPayment() {
    const encryptedResult = this._helper.encryptCreditCard(this.selectedPaymentMethod.cardNumberFull);
    const accountDetails = this._helper.createAccountDetails( this.selectedPaymentMethod, encryptedResult );
    const paymentMethod = {
        accountType: PaymentMethodType.CREDIT_CARD,
        accountDetails,
        accountHolderInfo: this.selectedPaymentMethod.accountHolderInfo,
        tokenId: this.selectedPaymentMethod.tokenId
    };

    const paymentPayload = this.createPaymentPayload(paymentMethod);

    this._service.makePayment(paymentPayload).subscribe(
      res => this.handlePaymentResponse(res),
      err => this.handlePaymentError(err)
    );
  }

  private getValidationError(err) {
    const detail = err?.error?.detail;
    return (detail === ValidationStatus.DECLINED_2001 || detail === ValidationStatus.DECLINED_2002) ? detail : null;
  }

}