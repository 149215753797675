import { Injectable } from '@angular/core';
import { PaymentMethodOption } from '../payment-methods/PaymentMethodOption';
import { PaymentMethodRadioGroup } from '../payment-methods/PaymentMethodRadioGroup';
import { PaymentMethod } from '../interfaces/PaymentMethod';
@Injectable({ providedIn: 'root' })

export class PaymentMethodHelper {
  constructor() { }

  public createRadioGroup(PaymentMethodOptions: PaymentMethodOption[]): PaymentMethodRadioGroup {
    return {
      isOptional: false,
      alignVertical: true,
      id: 'PaymentMethodRadioGroup',
      name: 'PaymentMethodRadioGroup',
      label: 'radio input group array',
      legendSrOnly: true,
      options: PaymentMethodOptions
    };
  }

  public createPaymentMethodOptions(PaymentMethodList: PaymentMethod[], content: string, addNewValue: string): PaymentMethodOption[] {
    let PaymentMethodOptions: PaymentMethodOption[];
    if (PaymentMethodList){
      PaymentMethodOptions = PaymentMethodList.map((payment: PaymentMethod) => {
        return {
          payment,
          label: `${payment.accountName}`,
          value: payment.accountName,
          isDisabled: payment.isExpired
        };
      });
    }
    PaymentMethodOptions.push({
      paymentMethod: null,
      label: `${content} `,
      value: addNewValue,
      isDisabled: false
    });
    return PaymentMethodOptions;
  }

  public isExpired(dateString: string) {
    const apiDate = new Date(this.formatDate(dateString));
    const today = new Date();
    const isExpired = apiDate.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0) < 0;
    return isExpired ? isExpired : null;
  }

  public formatDate(value: string) {
    const dateParts = value.split('/');
    if (dateParts.length === 2 && value.length <= 7) {
      let ccMonth = (dateParts[0]);
      let ccYear = (dateParts[1]);
      const lastDayOfMonth = new Date(Number(ccYear), Number(ccMonth), 0).getDate();
      if (ccMonth.length === 1) {
        ccMonth = '0' + ccMonth;
      }
      if (ccYear.length === 2) {
        ccYear = '20' + ccYear;
      }

      return `${ccMonth}/${lastDayOfMonth}/${ccYear}`;
    }
  }

  public formatMonth(ccMonth: number) {
    if (ccMonth < 10) {
      return '0' + ccMonth;
    }
    return ccMonth;
  }
}
