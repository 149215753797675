import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { PaymentPortalService } from "./../services/payment-portal.service";
import { PaymentHelperService } from "./../services/payment-helper.service";
import { PaymentConfirmation } from '../interfaces/PayNow';
import { PageGeneratorService } from '@anthem/mbrportal/shared/services/pageGeneratorSvc';
import { OpenAttachment } from 'sydmed/src/app/sydmed-shared/open-attachment-helper/open-attachment-helper';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {  
  labels: any;
  headerJson: any;
  errorMsgs: any;
  isPaymentsError: boolean = false;
  selectedBill: PaymentConfirmation;
  public isMobile: boolean;
  @ViewChild('confirmationPrintBlock', { static: false })
  confirmationPrintBlock: ElementRef;
  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private pageGeneratorService: PageGeneratorService,
    private _service: PaymentPortalService,
    private _helper: PaymentHelperService,
    private openAttachment: OpenAttachment) {
      this.jsonSvc.getJSON('payment-portal').subscribe(data => {
        this.labels = data.paymentPortal.labels;
        this.headerJson = data.paymentPortal.HeaderNav;
        this.errorMsgs = data.paymentPortal.errors;
        this.interCommunicationService.raiseEvent({
          title: this.headerJson.HeaderTitle,
          message: this.headerJson.HeaderMessage
        });
      });
    }

  ngOnInit(): void {
    this.isMobile = this.openAttachment.isMobileDevice();
    this._helper.paymentConfirmation.subscribe(res => {
      this.selectedBill = res;
    });
  }
  printOrDownload(): void {
    let cssBlock = `
      <style type="text/css">
        html {
          font-size: 18px;
          font-family: 'Lato', sans-serif;
          color: #333333;
        }
        .no-print {
          display:none;
        }
        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
        }
        @font-face { 
          font-family: 'Montserrat'; 
          font-style: normal; 
          font-weight: 300; 
          src: local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v7/zhcz-_WihjSQC0oHJ9TCYL3hpw3pgy2gAi-Ip7WPMi0.woff) format('woff'); 
        }
        @media print {
          @page { size: portrait; }
          html {
            font-size: 18px;
            font-family: 'Lato', sans-serif;
            color: #333333;
          }
        }
        .bill-row {
          width: 95%;
          max-width: 800px;
          font-size: 14px;
          background-color: #f7f7f7;
          border-bottom: solid 1px #cccccc;
          border-top: solid 1px #cccccc;
          min-height: 160px;
        }
        .header-border {
          width: 95%;
          max-width: 800px;
        }
        h3 {
          font-size: 20px;
          margin: 23px 0px 33px 0px;
          font-family: 'Montserrat', sans-serif;
          font-weight: lighter;
        }
        h2 {
          margin: 0px 0px 12px 0px;
          padding: 0px;
          font-size: 16px;
          font-weight: bold;
        }
        h4 {
          font-family: Lato;
          font-size: 10.5px;
          font-weight: bold;
          line-height: 1.38;
          color: #333333;
        }
        .confirm-h3 {
          font-size: 12px;
          margin-left: 23px;
          font-family: 'Montserrat', sans-serif;
          font-weight: bold;
        }
        .confirmation-number {
            font-weight: 100;
        }
        p {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 14px;
        }
        .section-header-three {
          font-size: 16px;
          font-weight: 300;
        }
        .header-logo {
          width: auto;
          height: 87px;
          margin: 12px 0;
        }
        .logo-div {
          width: 95%;
          max-width: 836px;
          border-bottom: 2px solid #333333;
        }
        .print-cell {
          width: 30%;
          float: left;
          min-height:80px;
          padding-left: 14px;
        }
      </style>
    `;
    const printHtml = this.confirmationPrintBlock.nativeElement;
    this.pageGeneratorService.init();
    this.pageGeneratorService.title = this.labels.HeaderMessage;
    this.pageGeneratorService.isPrint = true;
    this.pageGeneratorService.cssAsString = cssBlock;    
    let logo = document.getElementsByClassName('header-logo')[0];
    this.pageGeneratorService.header = `<div class="logo-div"><img class="header-logo" src="${logo.getAttribute('src').substring(1)}" /></div>`;
    this.pageGeneratorService.body = printHtml.innerHTML;
    this.pageGeneratorService.bodyClass = 'container';
    this.pageGeneratorService.build();
    this.pageGeneratorService.generatePageInTab();
  }
}
