<div id="answer1Error" class="margin-top-14 font-12 error" *ngIf="Showanswer1" aria-live="polite">
    <div *ngIf="Showanswer1Required">
        <span class="fas fa-times font-14 margin-right-8"></span>
        <span>{{answer1RequiredLabel}}</span>
    </div>
    <div *ngIf="Showanswer1Requirement">
        <span class="fas fa-times font-14 margin-right-8"></span>
        <span>{{answer1RequirementLabel}}</span>
    </div>
</div>


<div *ngIf="showTooltip1" class="motif-margin-bottom-2 motif-margin-top-4">
    <ul class="toolTipColumn bullet-list long-bullet-error">
        <div class="motif-margin-left-5">
            <div>
                <li [ngClass]="minLengthCss">
                    <span>{{securityAnswerLengtherror}}</span>
                </li>
            </div>
        </div>
        <div class="motif-margin-left-5">
            <div>
                <li [ngClass]="invalidNameCharCss">
                    <span>{{securityAnswerInvaliderror}}</span>
                </li>
            </div>
        </div>
        <div class="motif-margin-left-5">
            <div>
                <li [ngClass]="duplciateCss">
                    <span>{{securityAnswerDuplicateerror}}</span>
                </li>
            </div>
        </div>
    </ul>
</div>