import { Injectable } from '@angular/core';
import { PrescriptionOrderRoutes } from '../enums/PrescriptionOrderRoutes';
import { BehaviorSubject } from 'rxjs';


interface ProgressSteps {
  MedicalProfile: string;
  SpecialtyQuestionnaire: string;
  ShipmentReview: string;
  SpecialtyShipmentReview: string;
  Confirmation: string;
}

@Injectable( {
  providedIn: 'root'
} )
export class PrescriptionOrderRouteService {
  protected previousUrl$: BehaviorSubject<string> = new BehaviorSubject<string>( null );
  previousUrl = this.previousUrl$.asObservable();

  protected nextRoute$: BehaviorSubject<string> = new BehaviorSubject<string>( PrescriptionOrderRoutes.MedicalProfile );
  public nextRoute = this.nextRoute$.asObservable();

  protected cartRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public cartRoute = this.cartRoute$.asObservable();

  constructor() {

  }

  setCartRoute(route: string) {
    this.cartRoute$.next(route);
  }

  setInitialCartRoute(lineOfBusiness: string, profileIsComplete: boolean = null) {
    let route: string = null;
    switch (lineOfBusiness) {
      case 'PBM':
        if (profileIsComplete) {
          route = PrescriptionOrderRoutes.ShipmentReview;
          break;
        }
        route = PrescriptionOrderRoutes.MedicalProfile;
        break;
      case 'SPECIALTY':
        route = PrescriptionOrderRoutes.SpecialtyQuestionnaire;
        break;
      case 'BOTH':
        if (profileIsComplete) {
          route = PrescriptionOrderRoutes.SpecialtyQuestionnaire;
          break;
        }
        route = PrescriptionOrderRoutes.MedicalProfile;
        break;
      default:
        route = PrescriptionOrderRoutes.MedicalProfile;
    }
    this.setCartRoute(route);
  }

  public setNextRoute( label: string, content: ProgressSteps ) {
    const nextRoute = this.getNextRoute( label, content );
    this.nextRoute$.next( nextRoute );
  }

  getNextRoute( label: string, content: ProgressSteps ) {
    switch ( label ) {
      case content.MedicalProfile:
        return PrescriptionOrderRoutes.MedicalProfile;
      case content.SpecialtyQuestionnaire:
        return PrescriptionOrderRoutes.SpecialtyQuestionnaire;
      case content.ShipmentReview:
        return PrescriptionOrderRoutes.ShipmentReview;
      case content.SpecialtyShipmentReview:
        return PrescriptionOrderRoutes.SpecialtyShipmentReview;
      default:
        return PrescriptionOrderRoutes.Confirmation;
    }
  }

  setPreviousUrl( url: string ) {
    this.previousUrl$.next( url );
  }

}
