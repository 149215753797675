import { Component, OnDestroy, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { PaymentMethodContent } from '../interfaces/PaymentMethodContent';
import { SidePanel, ModalRef } from '@anthem/uxd/modal';
import { PaymentMethodDataService } from '../services/payment-method-data.service';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { Subscription, Observable } from 'rxjs';


@Component( {
  selector: 'app-payment-method-block',
  templateUrl: './payment-method-block.component.html',
  styleUrls: [ './payment-method-block.component.scss' ]
} )
export class PaymentMethodBlockComponent implements OnDestroy, OnInit {
  @Input() content: PaymentMethodContent;
  @Input() isSpecialty: boolean;
  @Input() isCheckoutSpecialty: boolean;
  @Input() isCheckoutPbm: boolean;
  @Input() isCheckoutSuccess: boolean;
  @Input() noPaymentDueSpecialtyLabel: string;
  @Input() pbmPaymentMethod: PaymentMethod;
  selectedPaymentMethod: PaymentMethod;
  paymentMethods: PaymentMethod[];
  loading: boolean;
  isError: boolean;
  sidePanelRef: ModalRef<any, any>;

  @ViewChild( 'sidePanelContent', { static: false } )
  sidePanelContent: TemplateRef<HTMLElement>;
  protected sub: Subscription = new Subscription();

  constructor(
    protected paymentMethodService: PaymentMethodDataService,
    protected sidePanel: SidePanel ) { }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    const selectedPaymentSub = this.subscribeToSelectedPaymentMethod( this.isSpecialty );
    this.sub.add( selectedPaymentSub );

    if ( !this.isCheckoutSuccess ){
      const paymentMethodsSub = this.getPaymentMethods( this.isSpecialty );
      this.sub.add( paymentMethodsSub );
    }

    if (typeof(this.content?.block?.noSpecialty) === 'object'){
      this.content.block.noSpecialty = this.content.block.noSpecialty[JSON.parse(sessionStorage.getItem('sydMedMemberContext')).planCode];
    }
    if (typeof(this.content?.block?.noPBM) === 'object'){
      this.content.block.noPBM = this.content.block.noPBM[JSON.parse(sessionStorage.getItem('sydMedMemberContext')).planCode];
    }
  }
  addPayment() {
    this.sidePanelRef = this.sidePanel.open( 'right', this.sidePanelContent );
  }

  subscribeToSelectedPaymentMethod( isSpecialty: boolean = false ) {
    if ( isSpecialty ) {
      return this.subscribeToSelectedSpecialty();
    }
    return this.subscribeToSelectedPbm();
  }

  setSelectedPaymentMethod( selectedPaymentMethod: PaymentMethod ) {
    this.startLoading( false );
    if ( selectedPaymentMethod ) {
      this.selectedPaymentMethod = selectedPaymentMethod;
      this.startLoading( false );
    } else {
      this.handleError( true );
      this.startLoading( false );
    }

  }

  subscribeToSelectedSpecialty() {
    this.startLoading( true );
    return this.paymentMethodService.selectedSpecialtyMethod.subscribe(
      ( selectedPaymentMethod: PaymentMethod ) =>
        this.setSelectedPaymentMethod( selectedPaymentMethod ),
      error => {
        this.handleError( true );
        this.startLoading( false );
      } );
  }

  subscribeToSelectedPbm() {
    this.startLoading( true );
    return this.paymentMethodService.selectedPbmMethod.subscribe(
      ( selectedPaymentMethod: PaymentMethod ) => {

        this.setSelectedPaymentMethod( selectedPaymentMethod );
      },
      error => this.handleError( true ) );
  }

  getPaymentMethods( isSpecialty: boolean = false ) {
    this.startLoading( true );
    return this.paymentMethodService.getPaymentMethods( isSpecialty ).subscribe(
      ( paymentMethods: PaymentMethod[] ) => {
        this.paymentMethods = paymentMethods.map( item => item );
        this.startLoading( false );

      }, error => {
        this.handleError( true );
        this.startLoading( false );
      } );
  }

  closePanel( $event ) {
    this.sidePanelRef.close();
  }

  isExpired( dateString: string ) {
    const apiDate = new Date(this.formatDate(dateString));
    const today = new Date();
    return apiDate.setHours( 0, 0, 0, 0 ) - today.setHours( 0, 0, 0, 0 ) < 0;
  }

  public formatDate(value: string) {
    const dateParts = value.split('/');
    if (dateParts.length === 2 && value.length <= 7) {
      let ccMonth = (dateParts[0]);
      let ccYear = (dateParts[1]);
      const lastDayOfMonth = new Date(Number(ccYear), Number(ccMonth), 0).getDate();
      if (ccMonth.length === 1) {
        ccMonth = '0' + ccMonth;
      }
      if (ccYear.length === 2) {
        ccYear = '20' + ccYear;
      }

      return `${ccMonth}/${lastDayOfMonth}/${ccYear}`;
    }
  }

  startLoading( isLoading: boolean ) {
    this.loading = isLoading;
  }

  handleError( isError: boolean ) {
    this.isError = isError;
  }

}
