<div class="dental-benefits">
    <h2 class="section-header-two">{{content.title}}</h2>
    <div class="dental-plan" *ngFor="let plan of content.dentalPlans">
        <p class="plan-description">
            <span *ngIf="plan?.subtitle?.length" class="subtitle" [innerHTML]="plan.subtitle"></span>
            <br>
            <span [innerHTML]="plan.description"></span>
            <br>
            <span class="contact-information" [innerHTML]="plan.contactInformation" [attr.data-analytics]="content.analytics.dentalPlanLink"></span>
        </p>
    </div>
</div>