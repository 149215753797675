<div [attr.id]="'claimLine-details-print-' + claimDetails.clmId" #claimLineDetails>
    <div class="row printLogo">
        <div class="col-md-12">
            <img [attr.id]="'print-logo-' + claimDetails.clmId" alt="" src="./assets/images/brands/HEALTHYBLUE.png">
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h3 [innerHTML]="content.printDetail.heading" class="ant-claims-sliding-panel-title"></h3>
        </div>
        <div class="col-md-6">
            <h3 class="claims-case-header">
                <span [innerHTML]="content.detail.claimTrackingId"></span>&nbsp;
                <span class=" claims-case-number marked-content-for-screen-reader-detail"
                    [innerHTML]="claimDetails.clmId"
                    [attr.aria-label]="content.detail.claimTrackingId + claimDetails.clmId"></span>
            </h3>
        </div>
    </div>

    <!-- Print Pharmacy Deatils-->
    <div class="row" *ngIf="claimDetails.classCode === 'P'">
        <div class="col-md-6">
            <div class="prescript-data-list">
                <dl>
                    <div class="table-row-rx">
                        <div class="table-row-rx-sm">
                            <dt [innerHTML]="content.printDetail.serviceDate"></dt>
                            <dd [attr.id]="'claims-detail-service-date-print-' + claimDetails.clmId" [attr.aria-label]=""
                                [innerHTML]="claimDetails.startDate">
                            </dd>
                        </div>
                    </div>
                    <div class="table-row-rx">
                        <div class="table-row-rx-sm">
                            <dt [innerHTML]="content.printDetail.claimType"></dt>
                            <dd [attr.id]="'claims-detail-class-code-print-' + claimDetails.clmId"
                                [attr.aria-label]="claimDetails.claimType"
                                [innerHTML]="claimDetails.claimType">
                            </dd>
                        </div>
                    </div>
                    <div class="table-row-rx"
                        [attr.id]="'claims-detail-prescription-medication-name-print-' + claimDetails.clmId">
                        <div class="table-row-rx-sm">
                            <dt [innerHTML]="content.detail.prescription.medicationName"></dt>
                            <dd [attr.id]="'claims-detail-prescription-medication-name-value-print-' + claimDetails.clmId"
                                [innerHTML]="claimDetails.medicationLabelNm"></dd>
                        </div>
                    </div>
                    <div class="table-row-rx"
                        [attr.id]="'claims-detail-prescription-rx-number-print-' + claimDetails.clmId">
                        <div class="table-row-rx-sm">
                            <dt [innerHTML]="content.detail.prescription.rxNumber"></dt>
                            <dd [attr.id]="'claims-detail-prescription-rx-number-value-print-' + claimDetails.clmId"
                                [innerHTML]="claimDetails.prescriptionNbr"></dd>
                        </div>
                    </div>
                    <div class="table-row-rx"
                        [attr.id]="'claims-detail-prescription-prescribed-by-print-' + claimDetails.clmId"
                        *ngIf="claimDetails.prescribedBy !== content.notAvailable">
                        <div class="table-row-rx-sm">
                            <dt class="ng-star-inserted" [innerHTML]="content.detail.prescription.prescribedBy"></dt>
                            <dd [attr.id]="'claims-detail-prescription-prescribed-by-value-print-' + claimDetails.clmId"
                                class="ng-star-inserted" [innerHTML]="claimDetails.prescribedBy"></dd>
                        </div>
                    </div>

                    <div class="table-row-rx"
                        [attr.id]="'claims-detail-prescription-medication-quantity-print-' + claimDetails.clmId">
                        <div class="table-row-rx-sm">
                            <dt [innerHTML]="content.detail.prescription.medicationQuantity"></dt>
                            <dd [attr.id]="'claims-detail-prescription-medication-quantity-print-value-' + claimDetails.clmId"
                                [innerHTML]="claimDetails.quantity"></dd>
                        </div>
                    </div>

                    <div class="table-row-rx"
                        [attr.id]="'claims-detail-prescription-days-supply-print-' + claimDetails.clmId">
                        <div class="table-row-rx-sm">
                            <dt [innerHTML]="content.detail.prescription.daysSupply"></dt>
                            <dd [attr.id]="'claims-detail-prescription-days-supply-print-value-' + claimDetails.clmId"
                                [innerHTML]="claimDetails.supply"></dd>
                        </div>
                    </div>
                    <div class="table-row-rx" [attr.id]="'claims-detail-prescription-address-print-' + claimDetails.clmId"
                        *ngIf="claimDetails.pharmacyAddress !== content.notAvailable"
                        [attr.aria-label]="content.detail.prescription.address + ' ' + claimDetails.pharmacyAriaAddress"
                       >
                        <div class="table-row-rx-sm">
                            <dt class="ng-star-inserted" [innerHTML]="content.detail.prescription.address">Address</dt>
                            <dd [attr.id]="'claims-detail-prescription-address-print-value-' + claimDetails.clmId"
                                class="ng-star-inserted" [innerHTML]="claimDetails.pharmacyAddress"></dd>
                        </div>
                    </div>
                </dl>

            </div>
        </div>
        
        <div class="col-md-6">
            <div class="claims-detail-content claims-detail-timeline rx-claims-container">
                <div class="claims-rx-flex-container">
                    <dl>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.pharBilledLabel"></dt>
                            <dd [innerHTML]="claimDetails.billedAmountCurrency"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.amtCovered"></dt>
                            <dd [innerHTML]="claimDetails.coveredAmountCurrency"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.amtNotCovered"></dt>
                            <dd [innerHTML]="claimDetails.notCoveredAmountCurrency"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.amtPlanPaid"></dt>
                            <dd [innerHTML]="claimDetails.planPaidAmountCurrency"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.yourResponsibility"></dt>
                            <dd [innerHTML]="claimDetails.yourResponsibilityAmountCurrency"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.appliedTowardDed"></dt>
                            <dd [innerHTML]="claimDetails.ductibleAmountCurrency"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.amtCoinsurance"></dt>
                            <dd [innerHTML]="claimDetails.coinsuranceAmountCurrency"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [innerHTML]="content.detail.amtCopay"></dt>
                            <dd [innerHTML]="claimDetails.copayAmountCurrency"></dd>
                        </div>
                    </dl>
                    <ng-container *ngTemplateOutlet="statusTemplateRef; context: {claimDetails: claimDetails, content: content}"></ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- Print Medical Deatils end-->

    <!-- Print Medical Deatils-->
    <div class="row ant-claims-display-for-print" *ngIf="claimDetails.classCode === 'M'">
        <!-- <div class="row"> -->
            <div class="col-md-12">
                <div class="prescript-data-list-medical">
                    <dl>
                        <div class="table-row-rx claims-print-mg-4">
                            <dt [attr.id]="'claims-detail-service-date-label-print-' + claimDetails.clmId" [innerHTML]="content.detail.serviceDate"></dt>
                            <dd [attr.id]="'claims-detail-service-date-value-print-' + claimDetails.clmId" [innerHTML]="claimDetails.startDate"></dd>
                        </div>
                        <div class="table-row-rx">
                            <dt [attr.id]="'claim-detail-type-label-print-' + claimDetails.clmId"  [innerHTML]="content.printDetail.claimType">
                            </dt>
                            <dd [attr.id]="'claim-detail-type-value-print-' + claimDetails.clmId"
                            [innerHTML]="claimDetails.claimType">
                        </div>
                    </dl>
                </div>
            </div>
        
        <!-- Show me the Math section is being hidden for Medical claims as part of SYDMED-9364. In future, to display the section in print, we need to set enableShowMeTheMath to true in the content -->

        <div class="col-md-12  claims-detail-content claims-detail-math-container" *ngIf="claimDetails.classCode === 'M' && claimDetails.statusName !== 'Pending' && !content.detail.enableShowMeTheMath">
            <h4 class="section-header-three" [innerHTML]="content.detail.yourResponsibility + ' : ' + claimDetails.totalMbrResponsibilityAmt"></h4>
        </div>

        <div class="col-md-12 claims-detail-content claims-detail-math-container" *ngIf="content.detail.enableShowMeTheMath">
            <table class="table">
              <ng-container>
                <tr class="table-row">
                  <th class="table-header text-left" scope="col" [attr.id]="'med-services-billed-print-' + claimDetails.clmId"
                    [innerHTML]="content.printDetail.service"></th>

                  <th class="table-header" scope="col" [attr.id]="'med-billed-amounts-print-' + claimDetails.clmId"
                    [innerHTML]="content.detail.amtBilled"></th>

                  <th class="table-header" scope="col" [attr.id]="'med-amount-discounted-print-' + claimDetails.clmId"
                    [innerHTML]="content.detail.amtDiscounted"></th>

                  <th class="table-header" scope="col" [attr.id]="'med-amount-covered-print-' + claimDetails.clmId"
                    [innerHTML]="content.detail.amtCovered"></th>

                  <th class="table-header" scope="col" [attr.id]="'med-amount-not-covered-print-' + claimDetails.clmId"
                    [innerHTML]="content.detail.amtNotCovered"></th>

                  <th class="table-header" scope="col" [attr.id]="'med-your-responsibilities-print-' + claimDetails.clmId"
                    [innerHTML]="content.detail.yourResponsibility"></th>
                </tr>

                <tr class="table-row" *ngFor="let claimLine of claimDetails.lineItems; let _index = index;">
                    <td data-th="Service" scope="row" class="table-data claims-dtl-mbl-flex-dspl">
                    <div class="claims-service-text">
                    <span class="" [innerHTML]="claimLine.procedureCd.name">&nbsp;</span>
                    </div>
                    </td>
                    <td data-th="Codes" class="table-data claims-dtl-mbl-flex-dspl" *ngIf="!(claimLine.explanationCd.length > 0 )" innerHTML="N/A">
                    </td>
                    <td data-th="Amount Billed" [innerHTML]="claimLine.chargeAmt"
                    class="table-data claims-dtl-mbl-flex-dspl claims-col-zebra-striping claims-cell-alignment"></td>
                    <td data-th="Allow by Plan" [innerHTML]="claimLine.discountAmt"
                    class="table-data claims-dtl-mbl-flex-dspl claims-col-zebra-striping claims-cell-alignment"></td>
                    <td data-th="Amount Covered" [innerHTML]="claimLine.allowedAmt"
                    class="table-data claims-dtl-mbl-flex-dspl claims-col-zebra-striping claims-cell-alignment"></td>
                    <td data-th="Amount Not Covered" [innerHTML]="claimLine.disallowedAmt"
                    class="table-data claims-dtl-mbl-flex-dspl claims-col-zebra-striping claims-cell-alignment"></td>
                    <td data-th="Your Responsibility" class="table-data claims-dtl-mbl-flex-dspl claims-cell-alignment"
                    [innerHTML]="claimLine.mbrResponsibilityAmt">
                    </td>
                </tr>
                <tr class="table-row">
                    <td data-th="Service" scope="row" class="table-data claims-total-med" [innerHTML]="content.detail.total">
                    </td>
                    <td data-th="Amount Billed" class="table-data claims-dtl-mbl-flex-dspl claims-cell-alignment claims-total claims-total-headers" [innerHTML]="claimDetails.totalAmtBilled">
                    </td>
                    <td data-th="Allow by Plan" class="table-data claims-dtl-mbl-flex-dspl claims-cell-alignment claims-total claims-total-headers" [innerHTML]="claimDetails.totalAmtDiscounted">
                    </td>
                    <td data-th="Amount Covered" class="table-data claims-dtl-mbl-flex-dspl claims-cell-alignment claims-total claims-total-headers" [innerHTML]="claimDetails.totalAmtCovered">
                    </td>
                    <td data-th="Amount Not Covered" class="table-data claims-dtl-mbl-flex-dspl claims-cell-alignment claims-total claims-total-headers" [innerHTML]="claimDetails.totalAmtNotCovered"> 
                    </td>
                    <td data-th="Your Responsibility" class="table-data claims-dtl-mbl-flex-dspl claims-cell-alignment claims-total claims-total-headers claims-your-resp-mbl" [innerHTML]="claimDetails.totalMbrResponsibilityAmt">
                    </td>
                </tr>
            </ng-container>
          </table>
        </div>
        <div class="col-md-12 claims-med-margin" *ngIf="content.detail.enableShowMeTheMath">
            <div class="ant-font-bold" [attr.id]="'med-codes-billed-print-' + claimDetails.clmId" [innerHTML]="content.detail.codes"></div>
            <p *ngFor="let explation of explationCodeList; let _index=index;" [attr.id]="'claim-explanation-code-and-description' + _index" [innerHTML]="explation">
            </p>
        </div> 
         <!-- Claim Status Section start-->
        <div class="claims-flex-container">
        <ng-container *ngTemplateOutlet="statusTemplateRef; context: {claimDetails: claimDetails, content: content}"></ng-container>
        </div>
         <!-- Claim Status Section start-->
    </div>
    <!-- Print Medical Details end -->

</div>
<!-- Claim Print Details Markup End -->

<ng-template #statusTemplateRef let-claimDetails="claimDetails" let-content="content">
    <div class="prescript-data-list prescription-status-mg-top">
        <div class="table-row-rx">
            <dt [innerHTML]="content.printDetail.statusLbl"></dt>
            <dd *ngIf="claimDetails.statusName === 'Approved'"
                [innerHTML]="claimDetails.statusName + ' - ' + content.detail.approvedRemarks"
               ></dd>
            <dd *ngIf="claimDetails.statusName === 'Pending'"
                [innerHTML]="claimDetails.statusName + ' - ' + content.detail.pendingRemarks"
               ></dd>
            <dd *ngIf="claimDetails.statusName === 'Denied'"
                [innerHTML]="claimDetails.statusName + ' - ' + content.detail.deniedRemarks"
               ></dd>
        </div>
    </div>
</ng-template>