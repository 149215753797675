import { Component, OnInit, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { AuthEventsService } from 'sydmed/libs/authorization/authevents.service';
import { JsonContentService } from '../content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { ChatbotService } from '../chatbot-service/chatbot.service';
@Component({
  selector: 'app-secure-footer',
  templateUrl: './secure-footer.component.html',
  styleUrls: ['./secure-footer.component.css']
})
export class SecureFooterComponent implements OnInit, OnDestroy {
  public footerLinks;
  public jsonLabels;
  public liveChatLink: string;
  public liveChatBound: boolean = false;
  hasLiveChat :boolean = true;
  hasContactUs: boolean = true;
  @ViewChild('contactUs') contactUs: ElementRef;
  @ViewChild('liveChat') liveChat: ElementRef;
  classId: string;
  hasMNContents: boolean = false;
  liveChatListener: any;

  constructor(
    private jsonSvc: JsonContentService,
    private eventSvc: AuthEventsService,
    private renderer: Renderer2,
    private chatBotSvc: ChatbotService
  ) { }

  ngOnInit(): void {   
    this.jsonSvc.getJSON('footer').subscribe(data => {
      this.jsonLabels = data.labels
      this.footerLinks = data.links;
      if (sessionStorage.sydMedLoggedIn) {
        this.hasLiveChat = !this.jsonSvc.hasRestriction(Restriction.SHM_LIVE_CHAT, this.jsonSvc.getRestrictions());
      }
    });
    this.eventSvc.navMenuUpdated.subscribe(() => {
      this.jsonSvc.getJSON('footer', '', true).subscribe(jsonData => {
        this.jsonLabels = jsonData.labels
        this.footerLinks = jsonData.links;
      });
    });
    if (this.jsonSvc.getMarket() === 'BCBSMN') {
      this.hasMNContents = true;
    }
  }

  ngAfterContentChecked() {
    this.hasContactUs = !this.jsonSvc.hasRestriction(Restriction.SHM_CONTACT_US, this.jsonSvc.getRestrictions());
    if (this.contactUs) {
      if (window.location.href.toLowerCase().indexOf('/secure/') >= 0) {
        this.classId = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext')).classId; 
        this.renderer.setProperty(this.contactUs.nativeElement,'href', '/secure/contact-us');
        this.renderer.setProperty(this.liveChat.nativeElement,'href', 'javascript:void(0);');
        if (!this.liveChatBound) {
          // this.liveChatPopUp = this.liveChatPopUp.bind(this);
          this.liveChatListener = this.renderer.listen(this.liveChat.nativeElement, 'click', (event) => {
            this.liveChatPopUp(event);
          });
          this.liveChatBound = true;
        }
      } else {
        this.renderer.setProperty(this.contactUs.nativeElement,'href', this.footerLinks?.ContactUs);
        this.renderer.setProperty(this.liveChat.nativeElement,'href', '/public/login');
        if (this.liveChatBound) {
          this.liveChatListener;
          this.liveChatBound = false;
        }
      }
    }
  }

  liveChatPopUp(event) {
    this.chatBotSvc.launchChatBot(event);
  }

  showHKDays(jsonLabels, classId: string): boolean {
    if((jsonLabels && jsonLabels.HKdays) && (classId?.substring(0,2) === 'HK')) {
      return true;
    } else {
      return false;
    }
  }

  hasAppStoreLinks(footerLinks) {
    if (footerLinks?.appStoreLink || footerLinks?.googStoreLink) {
      return true;
    }else {
      return false;
    }
  }

  hasSocialMediaLinks(footerLinks) {
    if (footerLinks?.facebookLink || footerLinks?.blogLink || footerLinks?.twitterLink || footerLinks?.youTubeLink || footerLinks?.linkedInUrl) {
      return true;
    }else {
      return false;
    }
  }

  ngOnDestroy() {
    this.liveChatListener;
  }
}
