<div class="container landing-page motif-margin-top-10 margin-bottom-30">
    <span class="motif-heart-health motif-icon margin-top-75"></span>
    <div class="gnaquestion font-26 margin-top-24" [innerHtml]="landingPageLabels?.description"></div>
    <div>
        <form id="AppealsAndGrievancesLandingForm" [formGroup]="appealsAndGrievancesLandingForm"
            (ngSubmit)="submitAnswer()">
            <div class="max-width margin-top-20 margin-bottom-32">
                <span class="form-item text-left btn-space">
                    <input type="radio" formControlName="sendAnswer" (change)="onChangeInput('yes')" class="font-20" id="option-yes"
                        name="sendAnswer" value="yes">
                    <label class="btn-space" for="option-yes">
                        <p class="font-20 margin-left-8" [innerHtml]="landingPageLabels?.form?.RadioButtonYes"></p>
                    </label>
                </span>
                <span class="form-item text-left btn-space margin-left-80">
                    <input type="radio" formControlName="sendAnswer" (change)="onChangeInput('no')" class="font-20" id="option-no"
                        name="sendAnswer" value="no">
                    <label class="btn-space" for="option-no">
                        <p class="font-20 margin-left-8" [innerHtml]="landingPageLabels?.form?.RadioButtonNo"></p>
                    </label>
                </span>
            </div>
            <div class="button-group margin-top-30 margin-bottom-60" [ngClass]="!enableSubmit? 'disable-effect' : ''">
                <button type="submit" id="Submit_btn" class="btn btn-primary width-100 custom-btn-size"  [innerHtml]="defaultLabels?.form?.submit_btn"></button>
            </div>
        </form>
    </div>
</div>
<p *ngIf="landingPageLabels?.form?.DisclaimerUrl" class="container margin-bottom-60"><span [innerText]="landingPageLabels?.form?.DisclaimerPt1"></span><a
    href="{{ landingPageLabels?.form?.DisclaimerUrl }}" target="_blank" [innerText]="landingPageLabels?.form?.DisclaimerUrlText"></a><span [innerText]="landingPageLabels?.form?.DisclaimerPt2"></span></p>