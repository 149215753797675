import { Injectable } from '@angular/core';
import { LoginResponse, MemberType } from 'gbd-models';
import { SessionStorage } from '../storage-service/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class MemberRepresentativeService {
  // checking logged user is designee
  public get isDesignee(): boolean {
    const accountType = this.getAccountType();
    return accountType === MemberType.DESIGNEE;
  }

  // checking logged user is caregiver
  public get isCaregiver(): boolean {
    const accountType = this.getAccountType();
    return accountType === MemberType.CAREGIVER;
  }

  public get isPowerCaregiver(): boolean {
    const accountType = this.getAccountType();
    return accountType === MemberType.POWER_CAREGIVER;
  }

  getAccountType = (): string => {
    const sessionItem = sessionStorage.getItem(SessionStorage.MEMBER_CONTEXT);
    return JSON.parse(sessionItem).accountType;
  }
}
