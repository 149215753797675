<div class="container">
  <a href="javascript:void(0)" class="back-button" (click)="goBack()">
    <span class="motif-icon motif-left-arrow"></span> {{ labels?.Back_btn }}
  </a>
</div>
<hr>
<div class="container" *ngIf="error">
  <p [innerHtml]="errorMsg"></p>
</div>
<div class="container text-terms" *ngIf="!loading && !error">
  <h2 class="section-header-two">{{ labels.TextTermsHeading }}</h2>
  <p>{{ labels.IntroParagragh }}</p>
  <h3>{{ labels.OptInHeading }}</h3>
  <p [innerHtml]="labels.OptInParagraph"></p>
  <h3>{{ labels.StopHeading }}</h3>
  <p>{{ labels.StopParagraph }}</p>
  <ul>
    <li>{{ labels.StopList_item1 }}</li>
    <li>{{ labels.StopList_item2 }}</li>
  </ul>
  <h3>{{ labels.NeedHelpHeading }}</h3>
  <p>{{ labels.NeedHelpParagraph }}</p>
  <ul>
    <li>{{ labels.NeedHelpList_item1 }}</li>
    <li>{{ labels.NeedHelpList_item2 }}</li>
  </ul>
  <h2 class="section-header-two">{{ labels.FAQHeading }}</h2>
  <h3>{{ labels.CostHeading }}</h3>
  <p>{{ labels.CostParagraph }}</p>
  <h3>{{ labels.WhatIsSMSHeading }}</h3>
  <p>{{ labels.WhatIsSMSParagraph }}</p>
  <h3>{{ labels.SMSTextHeading }}</h3>
  <p>{{ labels.SMSTextParagraph }}</p>
  <h3>{{ labels.SMSMMSHeading }}</h3>
  <p>{{ labels.SMSMMSParagraph }}</p>
  <h3>{{ labels.SMSSupportHeading }}</h3>
  <p>{{ labels.SMSSupportParagraph }}</p>
  <h3>{{ labels.SMSFeeHeading }}</h3>
  <p>{{ labels.SMSFeeParagraph }}</p>
  <h3>{{ labels.CarriersHeading }}</h3>
  <p>{{ labels.CarriersParagraph }}</p>
  <h3>{{ labels.PrivacyHeading }}</h3>
  <p>{{ labels.PrivacyParagraph }} <a href="{{ labels.PrivacyLink }}">{{ labels.PrivacyLinkText }}</a></p>
  <h3>{{ labels.ShortCodeHeading }}</h3>
  <p>{{ labels.ShortCodeParagraph }}</p>
  <h3>{{ labels.KeywordHeading }}</h3>
  <p>{{ labels.KeywordParagraph }}</p>
  <h3>{{ labels.SMSLengthHeading }}</h3>
  <p>{{ labels.SMSLengthParagraph }}</p>
  <h3>{{ labels.QuestionsHeading }}</h3>
  <p>{{ labels.QuestionsParagraph }}</p>
</div>
