import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthorizationService } from "./authorization.service";

@Injectable({
  providedIn: "root",
})
export class PublicAuthGuardService implements CanActivate {
  private LOGINURL: string = "/public/login";
  private reroute: boolean = false;

  constructor(
    public router: Router,
    public authorizationService: AuthorizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    // All 3 public widgets
    if (
      state.url.includes("get-security-code") ||
      state.url.includes("answer-security-questions")
    ) {
      if (!sessionStorage.getItem("contactsModel")) {
        this.reroute = true;
      }
    }
    // All 3 public widgets
    if (state.url.includes("enter-security-code")) {
      if (!sessionStorage.getItem("recoveryModel")) {
        this.reroute = true;
      }
    }

    // Registration specific checks
    if (state.url.includes("registration")) {
      if (state.url.includes("account-recovery-number")) {
        if (!sessionStorage.getItem("contactsModel")) {
          this.reroute = true;
        }
      }

      if (state.url.includes("create-username-password")) {
        if (!sessionStorage.getItem("createUsernameVisited")) {
          this.reroute = true;
        }
      }

      if (state.url.includes("registration/security-questions")) {
        if (!sessionStorage.getItem("userModel")) {
          this.reroute = true;
        }
      }

      if (state.url.includes("review")) {
        if (!sessionStorage.getItem("userModel")) {
          this.reroute = true;
        }
      }

      if (state.url.includes("caregiver-relation-type")) {
        if (!sessionStorage.getItem("caregiverRelationTypeVisited")) {
          this.reroute = true;
        }
      }

      if (state.url.includes("caregiver-info")) {
        if (!sessionStorage.getItem("caregiverInfoVisited")) {
          this.reroute = true;
        }
      }
    }

    // FUP specific checks
    if (state.url.includes("forgot-username-or-password")) {
      if (state.url.includes("reset-password")) {
        if (!sessionStorage.getItem("username")) {
          this.reroute = true;
        }
      }
    }

    // Login specific checks
    if (state.url.includes("login")) {
      if (state.url.includes("caregiver-attestation")) {
        if (!sessionStorage.getItem("sydMedMemberContext")) {
          this.reroute = true;
        }
      }

      if (state.url.includes("reset-temp-password")) {
        if (!sessionStorage.getItem("resetTempPasswordVisited")) {
          this.reroute = true;
        }
      }
    }

    if (this.reroute) {
      this.router.navigateByUrl(this.LOGINURL);
    }

    return true;
  }
}
