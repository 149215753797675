import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

const endPoints = UrlProperties.UrlProperties.endPoints;

@Injectable({
    providedIn: 'root'
})
export class PaymentPortalService {

    constructor(
        protected http: HttpClientService
    ) { }

    getSummary() {
        return this.http.getObservable(endPoints.paymentPortal.getSummary, {});
    }
    getHistory(months: number = 24) {
        return this.http.getObservable(endPoints.paymentPortal.getHistory+'?months='+months, {});
    }
    getPdfUrl(documentId) {
        documentId = encodeURIComponent(documentId);
        return this.http.getObservable(endPoints.paymentPortal.viewBill +'?documentId='+  documentId,null,null,{responseType: 'arraybuffer'});
    }
    getMethods() {
        return this.http.getObservable(endPoints.paymentPortal.getMethods, {});
    }
    updateAutopay(req) {
        return this.http.putObservable(endPoints.paymentPortal.updateAutopay, req);
    }
    makePayment(req) {
        return this.http.postObservable(endPoints.paymentPortal.makePayment, req);
    }
    cancelPayment(req) {
        return this.http.postObservable(endPoints.paymentPortal.cancelPayment, req);
    }
    getAutopayDetails() {
        return this.http.getObservable(endPoints.paymentPortal.getAutopayDetails, {});
    }
    updatePaymentMethod(req) {
        return this.http.putObservable(endPoints.paymentPortal.updatePaymentMethod, req)
            .pipe(
                map(res => res?.body),
                catchError(error => throwError(error?.error?.detail))
            );
    }
}
