<div class="uxd-theme-motif container max-width-elements care-giver-relation-type-container margin-bottom-48">
    <h1 tabindex="-1" class="margin-bottom-48 font-32">{{jsonLabels?.Page_Heading}}</h1>
    <div class="">
        <div class="margin-bottom-48">
            <div class="font-18 font-bold margin-bottom-8">{{jsonLabels?.Fields?.Membername}}</div>
            <span class="font-16">{{memberInfo?.memberInput?.firstName}}, {{memberInfo?.memberInput?.lastName}}</span>
        </div>
    </div>
    <div class="margin-bottom-48">
        <h2 class="font-32 font-montserrat font-light">{{jsonLabels?.MemberDetails?.Member_Status_Records}}</h2>
    </div>
    <inline-alert-container #memberRelationType role="alert" [name]="'inline-alert'" [alertType]="'universalInlineAlert'"></inline-alert-container>
    <form name="basic-radio-button-form" [formGroup]="careGiverMemberTypeForm" (ngSubmit)="careGiverTypeSubmission()">
        <div class="caregiver-relation-type-buttons" formControlName="relationType" name="radioValue" data-uxd-radio-input-group-cmp [radioGroup]="memberRelationTypeRadioGroup" (change)="memberTypeChange($event)"></div>
        <div *ngIf="isLegalGuardian" class="row margin-left-0 margin-right-0 margin-top-4" data-uxd-checkbox-input-cmp [definition]="checkbox" formControlName="parentGuardianAccess" aria-labelledby="guardianAccessDescription"></div>
        <div *ngIf="isLegalGuardian" id="guardianAccessDescription">
            <p class="margin-top-24 font-16 margin-left-28">{{jsonLabels?.Parent_Guardian_Declaration?.Parent_Guardian_Declaration_Para_1}}</p>
            <p class="margin-top-24 font-16 margin-left-28">{{jsonLabels?.Parent_Guardian_Declaration?.Parent_Guardian_Declaration_Para_2}}</p>
            <p class="margin-top-24 font-16 margin-left-28">{{jsonLabels?.Parent_Guardian_Declaration?.Parent_Guardian_Declaration_Para_3}}
                <a class="registration-link font-bold" href="{{jsonLabels?.Terms_Of_Use_Link}}">{{jsonLabels?.Fields?.Terms_of_Use}}</a>.
            </p>
        </div>
        <div class="flex-horizontal-end margin-bottom-48 margin-top-48">
            <button *ngIf="isNotAValidOption" type="button" class="btn btn-secondary margin-right-16" (click)="back()">{{jsonLabels?.Buttons?.Back}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="!careGiverMemberTypeForm.valid">{{jsonLabels?.Buttons?.Next}}</button>
        </div>
      </form>
</div>