import { Injectable } from '@angular/core';
import { Prescription } from 'gbd-models';

@Injectable({
  providedIn: 'root'
})
export class DashboardDataService {

  constructor() { }

  checkEligibility( prescription ) {
    return ( prescription.eligibility?.refill || prescription.eligibility?.renew )
      && ( prescription.eligibility?.digitalRefill=== undefined || prescription.eligibility?.digitalRefill );
  }
}
