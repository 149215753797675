import { Injectable } from '@angular/core';
import { ContactInfoType, GrievanceType, GrievanceRequest } from 'gbd-models';

@Injectable({
  providedIn: 'root'
})
export class GrievancesDataService {

  constructor() { }

  private formLabels: object;
  private confirmationLabels: object;
  private errors: object;
  private formData: GrievanceRequest;
  private contactRadioVal: ContactInfoType;
  private grievanceForRadioVal: GrievanceType;

  public setFormLabels(formLabels) {
    this.formLabels = formLabels;
  }

  public getFormLabels() {
    return this.formLabels;
  }

  public setConfirmationLabels(confirmationLabels) {
    this.confirmationLabels = confirmationLabels;
  }

  public getConfirmationLabels() {
    return this.confirmationLabels;
  }

  public setErrors(errors) {
    this.errors = errors;
  }

  public getErrors() {
    return this.errors;
  }

  public setFormData(formData: GrievanceRequest) {
    this.formData = formData;
  }

  public getFormData() {
    return this.formData;
  }

  public setContactRadioVal(contactRadioVal: ContactInfoType) {
    this.contactRadioVal = contactRadioVal;
  }

  public getContactRadioVal() {
    return this.contactRadioVal;
  }

  public setGrievanceForRadioVal(grievanceForRadioVal: GrievanceType) {
    this.grievanceForRadioVal = grievanceForRadioVal;
  }

  public getGrievanceForRadioVal() {
    return this.grievanceForRadioVal;
  }

  public clearFormData(){
    const formData: GrievanceRequest = {
      claimNumber: '',
      dateOfService: '',
      email: '',
      grievanceDetail: '',
      grievanceFor: GrievanceType.GRIEVANCE_FOR_ME,
      grievanceForPerson: '',
      memberDob: '',
      memberId: '',
      memberName: '',
      phoneNumber: '',
      preferredModeOfCommunication: ContactInfoType.PHONE,
      providerName: ''
    }
    this.contactRadioVal = ContactInfoType.PHONE;
    this.grievanceForRadioVal = GrievanceType.GRIEVANCE_FOR_ME;
    this.formData = formData;
  }
}