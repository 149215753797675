import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ContactUsDataService } from './services/contact-us-data.service';
import { Subscription } from 'rxjs';

import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import CONSTANTS from './utils/constants';
import { Restriction } from 'gbd-models';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';

export enum TabId {
  LIVE_CHAT = 'tabTile01',
  MESSAGE_US = 'tabTile02',
  CALL_US = 'tabTile03'
}

@Component({
  selector: 'contact-us',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  
  onPopState(event) {
    window.location.reload();
  }

  private subscriptions: Subscription[];
  public labels: any;
  public successMsg: string;
  public navPage: number;
  public content: any;
  public contactUsEmergencyAlert: string;
  public showContactUsEmergencyAlert: boolean = true;
  public featureFlags: any;
  analytics = CONSTANTS.ANALYTICS;
  hasLiveChat: boolean;
  hasMessageCenter: boolean = true;
  isLiveChat: boolean = false;
  tabRoutes: any;
  public isCaregiver: boolean = false;
  public isTermedUser: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private dataService: ContactUsDataService,
    private memberRepresentativeService: MemberRepresentativeService
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Contact Us'
    });

    this.dataService.getNavActive().subscribe(
      nav => {
        this.navPage = nav;
      }
    );
  }

  ngOnInit() {
    this.subscriptions = [];
    this.hasLiveChat = !this.hasRestriction(Restriction.SHM_LIVE_CHAT);
    this.isCaregiver = this.memberRepresentativeService.isCaregiver;
    if(this.isCaregiver || !this.hasLiveChat){
      this.router.navigate(['/secure/contact-us/message-us']);
    } else {
      this.router.navigate(['/secure/contact-us/live-chat']);
    }
    this.subscriptions.push(this.dataService.getSuccessMsg().subscribe(
      succMsg => {
        this.successMsg = succMsg;
      }
    ));
    this.getLabels();
  }

  routeChangeHandler() {
    this.route.url.subscribe(
      () => {
        this.setEmergencyAlertStatus();
        this.setCurrentTabUrl(this.router.url);
      })
  }

  /*
  *  Feature Flagging Redirect(s) for Contact Us page
  */
  contactUsRedirect() {
    /* Redirect for schedule a call */
    if (!this.featureFlags.scheduleACall || this.isTermedUser) {
      const { callUs } = this.tabRoutes;
      this.setCurrentTabUrl(callUs)
      this.router.navigate([callUs]);
    }
  }

  setEmergencyAlertStatus() {
    if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.data) {
      this.showContactUsEmergencyAlert = this.route.snapshot.firstChild.data.emergencyAlert;
    } else {
      this.showContactUsEmergencyAlert = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getLabels() {
    this.dataSvc.getJSON('contact-us').subscribe(data => {
      this.labels = data.contactUs.labels.callUs;
      this.contactUsEmergencyAlert = data.contactUs.labels.contactUsEmergencyAlert;
      this.featureFlags = data.contactUs.labels.featureFlags;
      this.tabRoutes = data.contactUs.labels.tabRoutes;

      this.content = {
        tabView: {
          tabs: [
            {
              heading: this.labels.contactUsLiveChatHeader,
              tabId: TabId.LIVE_CHAT,
              isActive: false,
              analyticsText: this.analytics.TABS.LiveChat,
              route: this.tabRoutes.liveChat
            },
            {
              heading: this.labels.contactUsMessageUsHeader,
              tabId: TabId.MESSAGE_US,
              isActive: false,
              analyticsText: this.analytics.TABS.MessageUs,
              route: this.tabRoutes.messageUs
            },
            {
              heading: this.labels.contactUsCallUsHeader,
              tabId: TabId.CALL_US,
              isActive: false,
              analyticsText: this.analytics.TABS.CallUs,
              route: this.tabRoutes.callUs
            },
          ]
        }
      };

      if(!this.hasLiveChat) {
        this.content.tabView.tabs = this.content.tabView.tabs.filter(tab => tab.tabId !== TabId.LIVE_CHAT);
      }

      if(this.hasRestriction(Restriction.SHM_NO_MESSAGE_CENTER)) {
        this.hasMessageCenter = false;
        this.content.tabView.tabs = this.content.tabView.tabs.filter(tab => tab.tabId !== TabId.MESSAGE_US);
      }

      this.isTermedUser = this.checkForTermedRestrictions();
      this.setCurrentTabUrl(this.router.url);
      this.setEmergencyAlertStatus();
      this.routeChangeHandler();
      this.contactUsRedirect();
      return true;
    });
    return false;
  }

  setNavPage(selectedTab) {
    const tabId = selectedTab.getAttribute('aria-controls') || TabId.LIVE_CHAT;
    const tab = this.content.tabView.tabs.find(tab => tab.tabId === tabId);
    const route = tab?.route || this.tabRoutes.liveChat;
    this.dataService.setNavActive(tab.tabId);
    this.router.navigate([route]);
  }

  setCurrentTabUrl(pathToBe: string) {
    if ((pathToBe).indexOf('call-us') >= 0) {
      if (this.hasMessageCenter) {
        this.content.tabView.tabs.find(tab => tab.tabId === TabId.MESSAGE_US).isActive = false;
      }
      if (this.hasLiveChat) {
        this.content.tabView.tabs.find(tab => tab.tabId === TabId.LIVE_CHAT).isActive = false;
      }
      this.content.tabView.tabs.find(tab => tab.tabId === TabId.CALL_US).isActive = true;
      this.isLiveChat = false;
    } else if ((pathToBe).indexOf('message-us') >= 0 && this.hasMessageCenter) {
      this.content.tabView.tabs.find(tab => tab.tabId === TabId.CALL_US).isActive = false;
      if(this.hasLiveChat){
        this.content.tabView.tabs.find(tab => tab.tabId === TabId.LIVE_CHAT).isActive = false;
      }
      this.content.tabView.tabs.find(tab => tab.tabId === TabId.MESSAGE_US).isActive = true;
      this.isLiveChat = false;
    } else if ((pathToBe).indexOf('live-chat') >= 0 && this.hasLiveChat) {
      this.content.tabView.tabs.find(tab => tab.tabId === TabId.CALL_US).isActive = false;
      if (this.hasMessageCenter) {
        this.content.tabView.tabs.find(tab => tab.tabId === TabId.MESSAGE_US).isActive = false;
      }
      this.content.tabView.tabs.find(tab => tab.tabId === TabId.LIVE_CHAT).isActive = true;
      this.isLiveChat = true;
    }
    return false;
  }

  checkForTermedRestrictions(): boolean {
    return this.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS) 
      || this.hasRestriction(Restriction.TERMED_MEMBER_TWO_TO_FIVE_YEARS);
  }

  hasRestriction(restriction: Restriction): boolean {
    return this.dataSvc.hasRestriction(restriction, this.dataSvc.getRestrictions());
  }
}
