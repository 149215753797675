import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { AlertService, InlineAlertContainerComponent } from '@anthem/uxd/alert';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidateTokenResponse, ValidateTokenRequest, RepresentativeValidateOtpRequest, RepresentativeValidateOTPResponse, RepresentativeSendOtpResponse, RepresentativeSendOtpRequest, ValidateDesigneeInfoRequest } from 'gbd-models'

@Injectable({
  providedIn: 'root'
})
export class MemberRepRegistrationService {
  public textNumber: string;
  constructor(
    private httpClient: HttpClientService,
    private alertSvc: AlertService
    ) { }

  protected validateTokenData$: BehaviorSubject<ValidateTokenResponse> = new BehaviorSubject<ValidateTokenResponse>(null);
  public validateTokenData = this.validateTokenData$.asObservable();

  public validateOtpUrl = UrlProperties.UrlProperties.endPoints.registration.memberRepRegistration.validateOtp;
  public validateTokenUrl = UrlProperties.UrlProperties.endPoints.registration.memberRepRegistration.validateToken;
  public sendOtpUrl = UrlProperties.UrlProperties.endPoints.registration.memberRepRegistration.sendOtp;
  public setNewPassword = UrlProperties.UrlProperties.endPoints.registration.memberRepRegistration.registerAccount;

  
  callEligibilityAPI(data) {
    const url = UrlProperties.UrlProperties.endPoints.registration.eligibility;
    return this.httpClient.postObservable(url, data);
  }

  setTextNumber(number){
    this.textNumber = number;
  }

  async getSecurityQuestions() {
    const url = UrlProperties.UrlProperties.endPoints.registration.memberRepRegistration.getSecurityQuestions;
    const result = await this.httpClient.getObservable(url).toPromise();
    return result;
  }

  getTextNumber(){
    return this.textNumber;
  }

  resendSecurityCode(){
    //Intgeration need to implement.
  }

  validateDateOfBirth(validateInfo: ValidateDesigneeInfoRequest){
    return this.httpClient.postObservable(UrlProperties.UrlProperties.endPoints.registration.memberRepRegistration.validateDesigneeInfo, validateInfo);
  }

  sendOtp(requestBody:RepresentativeSendOtpRequest): Observable<RepresentativeSendOtpResponse>{
    return this.httpClient.postObservable(this.sendOtpUrl, requestBody );
  }

  validateOtp(requestBody:RepresentativeValidateOtpRequest): Observable<RepresentativeValidateOTPResponse>{
    return this.httpClient.postObservable(this.validateOtpUrl, requestBody );
  }

  validateToken(token: ValidateTokenRequest): Observable<ValidateTokenResponse>{
    return this.httpClient.postObservable(this.validateTokenUrl, token);
  }

  setValidateTokendata(dataObj: ValidateTokenResponse){
    this.validateTokenData$.next(dataObj);
  }

  getValidateTokenData(){
    return this.validateTokenData;
  }
  

  saveRegistrationData(requestBody){
    return this.httpClient.postObservable(this.setNewPassword, requestBody );
  }

  handleServiceError(error: string,errorMsgs: any): void {
    if (error && errorMsgs) {
      this.alertSvc.error(error, {
        regionName: 'member-rep-reg-error-alert',
        politeness: 'assertive',
        isDissmisable: true,
      });
      this.scrollToTop();
    }
  }

  // Method used to scroll top of the page
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  clearAlert(alertType: InlineAlertContainerComponent): void {
    alertType.dismissAlertId(0);
  }

}

