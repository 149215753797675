import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { PaymentPortalComponent } from "./payment-portal.component";
import { PaymentHistoryComponent } from "./payment-history/payment-history.component";
import { PayNowComponent } from "./pay-now/pay-now.component";
import { AutoPayComponent } from "./auto-pay/auto-pay.component";
import { PaymentConfirmationComponent } from "./payment-confirmation/payment-confirmation.component";

const routes: Routes = [
    {
        path: 'secure/make-payment',
        component: PaymentPortalComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'secure/payment-history',
        component: PaymentHistoryComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'secure/make-payment/pay-now',
        component: PayNowComponent,
        canActivate: [AuthGuardService]        
    },
    {
        path: 'secure/make-payment/auto-pay-add',
        component: AutoPayComponent,
        canActivate: [AuthGuardService]        
    },
    {
        path: 'secure/make-payment/auto-pay-edit',
        component: AutoPayComponent,
        canActivate: [AuthGuardService]        
    },
    {
        path: 'secure/make-payment/confirmation',
        component: PaymentConfirmationComponent,
        canActivate: [AuthGuardService]        
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: "enabled",
            onSameUrlNavigation: 'reload'
        })
    ],
    exports: [RouterModule]
})
export class PaymentPortalRoutingModule { }