import {Component, Input, OnInit} from '@angular/core';
import { Restriction } from 'gbd-models';
import {JsonContentService} from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { PaymentPortalService } from '../../services/payment-portal.service';
import {HttpResponse} from '@angular/common/http';
import { OpenAttachment } from 'sydmed/src/app/sydmed-shared/open-attachment-helper/open-attachment-helper';

@Component({
  selector: 'app-current-bills-detail',
  templateUrl: './current-bills-detail.component.html',
  styleUrls: ['./current-bills-detail.component.scss']
})
export class CurrentBillsDetailComponent implements OnInit {

  @Input() paymentDetails: any;
  @Input() content: any;
  constructor(private jsonSvc: JsonContentService, private _service: PaymentPortalService, private openAttachment: OpenAttachment) { }
  public showInvoice: boolean = true;
  public isLoading: boolean = false;
  private isMobile = this.openAttachment.deviceType();
  ngOnInit(): void {
    this.jsonSvc.getJSON('payment-portal').subscribe(data => {
      this.showInvoice = !this.jsonSvc.hasRestriction(Restriction.SHM_VIEW_BILL_PDF, data.restrictions) && !this.isMobile.includes('Android')
      })
  }

  viewInvoice(pdfBillAvailable: string): void {
    this.isLoading = true;
    this._service.getPdfUrl(pdfBillAvailable)
      .subscribe(
      (response: HttpResponse<ArrayBuffer>) => {
        this.isLoading = false;
        const url = this.openAttachment.generateUrl(response);
        if (this.isMobile) {
            const link = document.createElement('a');
            link.href = url;
            document.body.append(link);
            link.click();
            }
            else{
              window.open(url, "_blank", `noreferrer,noopener,height=${window.screen.height},width=${window.screen.width}`);
            }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
