<div #scrollContainer id="appealsAndGrivencesPreview" class="appealsAndGrievances modal-body border-bottom-0 padding-top-0">

    <h2 class="review-header font-32 margin-bottom-24" [innerHtml]="content?.defaults?.form?.next_btn"></h2>
    <div>
        <p class="font-14 margin-top-4" [innerHtml]="content?.defaults?.preview?.edit_your_information_heading"></p>
    </div>
    <ng-container *ngIf="!loading">
        <div class="form-without-input col-md-12 padding-0">
            <h3 class="margin-top-40 margin-bottom-24" [innerHtml]="content?.memberInformation?.header"></h3>

            <div class="col-md-6 margin-bottom-20">
                <label class="font-14 font-semi-bold" [innerHtml]="content?.memberInformation?.form.firstName"></label>
                <span class="font-12" [innerHtml]="memberData?.firstName"></span>
            </div>
            <div class="col-md-6 margin-bottom-20">
                <label class="font-14 font-semi-bold" [innerHtml]="content?.memberInformation?.form.lastName"></label>
                <span class="font-12" [innerHtml]="memberData?.lastName"></span>
            </div>
            <div class="col-md-6 margin-bottom-20">
                <label class="font-14 font-semi-bold" [innerHtml]="content?.memberInformation?.form.memberIdNumber"></label>
                <span class="font-12" [innerHtml]="memberData?.hcid"></span>
            </div>
            <div class="col-md-6 margin-bottom-20">
                <label class="font-14 font-semi-bold" [innerHtml]="content?.memberInformation?.form.memberPhoneNumber"></label>
                <span class="font-12" [innerHtml]="formValues?.memberInformation?.phoneNumber?.phone"></span>
            </div>
            <div class="col-md-6 margin-bottom-20">
                <label class="font-14 font-semi-bold" [innerHtml]="content?.defaults?.preview?.authorization_label"></label>
                <span class="font-12" [innerHtml]="formValues?.memberInformation?.authorizationNumber"></span>
            </div>
            <ng-container *ngIf="!isAppeal">
                <div class="col-md-12 margin-bottom-20">
                    <label class="font-14 font-semi-bold" [innerHtml]="content?.healthOption?.header"></label>
                    <span class="font-12" [innerHtml]="formValues?.healthOption?.optionCategory"></span>
                </div>
                <div class="col-md-12 margin-bottom-20">
                    <label class="font-14 font-semi-bold" [innerHtml]="content?.reasonForRequest?.form?.label"></label>
                    <span class="font-12 request-category" [innerHtml]="formValues?.reasonForRequests?.requestCategory"></span>
                </div>
                <div class="col-md-12 margin-bottom-20" *ngIf="formValues.reasonForRequests.requestCategory === 'other'">
                    <label class="font-14 font-semi-bold" [innerHtml]="content?.reasonForRequest?.form?.describeOtherReasonLabel"></label>
                    <p class="font-12" [innerHtml]="formValues?.reasonForRequests?.requestCategoryComments"></p>
                </div>
            </ng-container>  
            <ng-container *ngIf="isAppeal">
                <div class="col-md-12 margin-bottom-20">
                    <label class="font-14 font-semi-bold" [innerHtml]="content?.describeYourSituation?.header"></label>
                    <span class="font-12" [innerHtml]="formValues?.describeYourSituation?.requestCategory"></span>
                </div>
            </ng-container>  
        </div>

        <hr />

        <div class="form-without-input col-md-12 padding-0 margin-bottom-24">
            <h3 class="margin-top-40 margin-bottom-24" [innerHtml]="content?.descriptionOfSpecificIssue?.header"></h3>
            <p [innerHtml]="formValues?.issueDescription?.description"></p>

            <!-- Preview attachment files -->
            <ng-container class="col-md-12" *ngIf="!uploadAttachmentRestriction">
                <div class="message-center-file col-xs-6 col-sm-6 col-md-6"
                    *ngFor="let fileData of helperService.scannedFiles; trackBy:trackByFile">
                    <div class="message-center-file-description" *ngIf="fileData">
                        <div aria-hidden="true" class="file-details_attachment__icon margin-top-4" [ngSwitch]="true">
                            <span *ngSwitchCase="fileData?.fileType === 'pdf'" class="motif-icon motif-pdf"></span>
                            <span *ngSwitchCase="(fileData?.fileType === 'docx' || fileData?.fileType === 'doc')"
                                class="motif-icon motif-document"></span>
                            <span *ngSwitchCase="(fileData?.fileType === 'xls' || fileData?.fileType === 'xlsx')"
                                class="motif-icon motif-document"></span>
                        </div>
                        <div class="file-details_attachment">
                            <p class="font-14 file-details_attachment__filename" title="{{ fileData?.fileName }}">{{trimFileName(fileData?.fileName)}}
                            <br /><span class="font-12 file-details_attachment__filesize"> {{ fileData?.fileSize | filesize }}</span></p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngIf="formValues?.consentInformation?.consentcheck">
            <hr />
            <div class="form-without-input col-md-12 padding-0 margin-bottom-24">
                <h3 class="margin-top-40 margin-bottom-24" [innerHtml]="content?.consentInformation?.header"></h3>
                <div class="consent-info">
                    <div class="margin-bottom-40">
                        <span class="motif-icon motif-checked-2"></span>
                        <span class="font-14 font-semi-bold">{{content?.consentInformation?.subHeader}}</span>
                    </div>
                    <div class="row">
                        <div class="col-md-4 margin-bottom-40">
                            <label class="font-14 font-semi-bold" [innerHtml]="content?.consentInformation?.form.firstName"></label>
                            <span class="font-12" [innerHtml]="formValues?.consentInformation?.firstName"></span>
                        </div>
                        <div class="col-md-4 margin-bottom-40">
                            <label class="font-14 font-semi-bold" [innerHtml]="content?.consentInformation?.form.lastName"></label>
                            <span class="font-12" [innerHtml]="formValues?.consentInformation?.lastName"></span>
                        </div>
                        <div class="col-md-4 margin-bottom-40">
                            <label class="font-14 font-semi-bold" [innerHtml]="content?.consentInformation?.form.consentPhoneNumber"></label>
                            <span class="font-12" [innerHtml]="formValues?.consentInformation?.phoneNumber?.phone"></span>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </ng-container>

    <!-- Loader -->
    <ng-container *ngIf="loading">
        <div class="row">
            <div class="tabbed-view-wrapper col-xs-12 loader">
                <uxd-spinner class="custom-updating-loader"></uxd-spinner>
                <p class="text-center font-14">{{content?.defaultLabels?.preview?.submittingLoaderText}}</p>
            </div>
        </div>
    </ng-container>

    <div class="button-group margin-top-50 margin-bottom-20 text-align-right">
        <button type="button" id="Edit_btn" attr.data-analytics="{{labels?.preview?.editBtnAnalytics}}"
            class="btn btn-secondary custom-btn-size margin-bottom-20 margin-right-10 width-100" [disabled]="loading"
            [innerHtml]="content?.defaults?.form?.edit_btn" (click)="closeModal()"></button>
        <button type="button" attr.data-analytics="{{labels?.preview?.submitBtnAnalytics}}" id="Submit_btn"
            (click)="submitAppealsAndGrievancesForm()" [disabled]="loading"
            class="btn btn-primary custom-btn-size margin-bottom-20 width-100"
            [innerHtml]="content?.defaults?.form?.submit_btn"></button>
    </div>
    <!-- Alert display area -->
    <div class="margin-top-20 margin-bottom-10" *ngIf="serviceError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span [innerHtml]="serviceError"></span>
    </div>

</div>
