<div class="uxd-theme-motif container">
    <div class="margin-top-60 loading-spinner-alignment" *ngIf="loadingSpinner">
        <uxd-spinner></uxd-spinner>
    </div>
    <div class="reg-top-1 flex-column-center"  *ngIf="!loadingSpinner">
        <div class="ant-margin-top-20 width-100 full-width-alert-spacer" *ngIf="aslError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{aslError}}</span>
        </div>
    </div>
    <div class="create-username-password-container max-width-elements" *ngIf="!loadingSpinner">
        <h1 tabindex="-1" class="text-left font-32 margin-bottom-48">{{jsonLabels?.Page_Heading}}</h1>
        <form [formGroup]="accountInfoForm" (ngSubmit)="submitAccountInfo()" #pwdForm="ngForm">
            <div class="margin-bottom-40">
                <label for="emailAddress" class="font-16">{{jsonLabels?.Field_Labels.Email_Address}}  <span *ngIf="emailOptional" class="label-color font-12 font-normal"> Optional</span></label>
                <input (change)="onChangeInput()" id="emailAddress" name="emailAddress"
                    class="ant-input-long ant-text-input" type="text" maxlength="40"
                    formControlName="emailAddress" [attr.aria-required]="emailOptional ? 'false' : 'true'" aria-describedby="emailError">
                <ul id="emailError" class="ant-bar-chart-group text-left margin-top-14 error"
                    *ngIf="accountInfoForm.controls['emailAddress']?.touched && accountInfoForm.controls['emailAddress']?.invalid" aria-live="polite">
                    <li *ngIf="accountInfoForm.controls['emailAddress']?.errors?.required">
                        <span class="fas fa-times"></span>
                        <span>{{jsonLabels?.Field_Labels.Email_Address}}</span>
                        <span>{{jsonErrors.Required_err}}</span>
                    </li>
                    <li *ngIf="accountInfoForm.controls['emailAddress']?.errors?.invalidEmail">
                        <span class="fas fa-times"></span>
                        <span>{{jsonErrors.Email_Format_err}}</span>
                    </li>
                </ul>
            </div>
            <div class="margin-bottom-40">
                <label for="userName" class="font-16">{{jsonLabels?.Field_Labels.Username}}</label>
                <input (change)="onChangeInput()" (blur)="checkUserName()" id="userName"
                    class="ant-input-long ant-text-input" name="userName" type="text"
                    formControlName="userName" aria-required="true" aria-describedby="userNameError">
                <div id="userNameError" class="errors" *ngIf="true" aria-live="assertive">
                    <ul 
                        class="no-outline ant-bar-chart-group text-left margin-top-14 error"
                        *ngIf="accountInfoForm.controls['userName']?.touched && accountInfoForm.controls['userName']?.invalid || 
                            accountInfoForm.controls['userName']?.touched && accountInfoForm.errors?.fieldsmatch ||
                            !usernameAvailable && accountInfoForm.controls['userName']?.touched && !createUsernameModel.userNameCheck.checking">
                        <li *ngIf="accountInfoForm.errors?.fieldsmatch">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Password_err}}</span>
                            <span>{{jsonLabels?.Field_Labels.Password}}</span>
                        </li>
                        <li *ngIf="accountInfoForm.controls['userName']?.errors?.required">
                            <span class="fas fa-times"></span>
                            <span>{{jsonLabels?.Field_Labels.Username}}</span>
                            <span>{{jsonErrors.Required_err}}</span>
                        </li>
                        <li
                            *ngIf="accountInfoForm.controls['userName']?.errors?.minlength || accountInfoForm.controls['userName']?.errors?.maxlength">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Len_err}}</span>
                        </li>
                        <li
                            *ngIf="accountInfoForm.controls['userName']?.errors?.unHasTwoLtrPre">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Format_err}}</span>
                        </li>
                        <li *ngIf="accountInfoForm.controls['userName']?.errors?.unHasDot">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Dot_err}}</span>
                        </li>
                        <li
                            *ngIf="accountInfoForm.controls['userName']?.errors?.unHasDigitPrefix">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Num_err}}</span>
                        </li>
                        <li *ngIf="accountInfoForm.controls['userName']?.errors?.hasValidChars">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Invalid_err}}</span>
                        </li>
                        <li *ngIf="accountInfoForm.controls['userName']?.errors?.noSpace">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Space_err}}</span>
                        </li>
                        <li *ngIf="!usernameAvailable">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.UserName_Registered_err}}</span><br>
                            <span
                                class="validText">{{jsonLabels?.Field_Labels.Suggestions}}</span>
                            <br>
                            <span role="button" class="username-suggestion"
                                data-analytics="userSuggestOptionsReg"
                                *ngFor="let usernameSuggestion of usernameSuggestions"
                                (click)="replaceUsername(usernameSuggestion)"
                                data-analytics="userSuggestOptionsReg">{{usernameSuggestion}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="form-item margin-bottom-0">
                <lib-passwords-form (change)="onChangeInput()" formControlName="passwords"
                    [pwdLabel]="jsonLabels?.Field_Labels.Password"
                    [confirmPwdLabel]="jsonLabels?.Field_Labels.Confirm_Password"
                    [errObj]="jsonErrors"
                    [toolTipObj]="jsonLabels?.Helper_Text.Password.Content.PasswordTooltip"
                    [formSubmitted]="(pwdForm.submitted)"
                    [userName]="accountInfoForm.controls['userName']?.value" #passwordId>
                </lib-passwords-form>
            </div>
            <div class="form-item margin-bottom-40">
                <div class="flex-horizontal-start">
                    <label class="inline-block"
                        for="phone" class="font-bold">{{jsonLabels?.Field_Labels.ARN}}</label>
                    <span tabindex="0" role="button" aria-pressed="false" [attr.aria-label]="jsonLabels?.Field_Labels.ARNHelp + ' tooltip' "  
                        class="margin-left-8 margin-top-8 motif-icon motif-help blue-icon" ant-tooltip
                        message="{{jsonLabels?.Helper_Text.ARN.Content}}"
                        (click)="tooltipAlpha.toggle()"
                        (keyup.enter)="tooltipAlpha.toggle()"><span
                            class="sr-only">{{jsonLabels?.Field_Labels.ARNHelp}}</span></span>
                </div>

                <input (change)="onChangeInput()" id="ARN" type="text" id="phone"
                    formControlName="phone" class="ant-input-long ant-text-input" libPhoneFormat
                    [preValue]="accountInfoForm.value.phone" placeholder="XXX-XXX-XXXX" aria-required="true" aria-describedby="phoneNumberError">
                <ul id="phoneNumberError" class="ant-bar-chart-group text-left margin-top-8 error"
                    *ngIf="accountInfoForm.controls['phone']?.touched && accountInfoForm.controls['phone']?.invalid" aria-live="polite">
                    <li *ngIf="accountInfoForm.controls['phone']?.errors?.required">
                        <span class="fas fa-times"></span>
                        <span>{{jsonLabels?.Field_Labels.ARN}}</span>
                        <span>{{jsonErrors.Required_err}}</span>
                    </li>
                    <li *ngIf="accountInfoForm.controls['phone']?.errors?.invalidPhone">
                        <span class="fas fa-times"></span>
                        <span>{{jsonErrors.Phone_Format}}</span>
                    </li>
                </ul>
                <div class="margin-top-20 flex-column-start">
                    <div *ngIf="!isBCBSMNMarket()">
                    <input type="radio" formControlName="option" class="prOption" name="option"
                        id="rbtnGrp10" value="text" data-analytics="analytics-one"
                        (change)="onChangeInput()">
                    <label class="prLabel" id="lbl_rbtnGrp10" for="rbtnGrp10"
                        [ngClass]="{'active':accountInfoForm.controls['option'].value === 'text', 'invalidInput': accountInfoForm.controls['option']?.touched && accountInfoForm.controls['option']?.invalid}">{{jsonLabels?.Field_Labels.Text}}</label>
                    </div>
                    <input type="radio" formControlName="option" class="prOption" name="option"
                        id="rbtnGrp11" value="voice" data-analytics="analytics-two"
                        (change)="onChangeInput()">
                    <label class="prLabel" id="lbl_rbtnGrp11"
                        for="rbtnGrp11"
                        [ngClass]="{'active':accountInfoForm.controls['option'].value === 'voice', 'invalidInput': accountInfoForm.controls['option']?.touched && accountInfoForm.controls['option']?.invalid}">{{jsonLabels?.Field_Labels.Voice}}</label>

                    <p class="no-outline flex margin-top-24"
                        *ngIf="accountInfoForm.controls['option'].value === 'text'">
                        {{jsonLabels?.Helper_Text.ARN.Txt_Rate}}</p>
                    <ul class="ant-bar-chart-group text-left margin-top-16 error clear"
                        *ngIf="accountInfoForm.controls['option']?.touched && accountInfoForm.controls['option']?.invalid" role="alert">
                        <li *ngIf="accountInfoForm.controls['option']?.errors?.required">
                            <span class="fas fa-times"></span>
                            <span>{{jsonErrors.ARN_Required_err}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="margin-bottom-70 margin-top-48 flex-horizontal-end">
                <button type="button" class="btn btn-secondary margin-right-16" (click)="back()"
                data-analytics="backCreateUsernameReg">{{jsonLabels?.Buttons.Back}}</button>
                <button type="submit" data-analytics="nextCreateUsernameReg"
                class="btn btn-primary color">{{jsonLabels?.Buttons.Next}}</button>
            </div>
        </form>
    </div>
</div>