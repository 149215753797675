import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewDashboardContainerComponent } from './new-dashboard-container/new-dashboard.component';
import { QuickConnectButtonsComponent } from './quick-connect-buttons/quick-connect-buttons.component';
import { SeedSlideoutComponent } from './seed-slideout/seed-slideout.component';
import { AccordianModule } from '@anthem/uxd/accordion';
import { DashboardCardsContainerComponent } from './dashboard-cards-container/dashboard-cards-container.component';
import { NewDashboardMenuComponent } from './new-dashboard-menu/new-dashboard-menu.component';
import { IdCardsModule } from '../idcards/idcardsModule';
import { PharmacyCardComponent } from './pharmacyCard/pharmacyCard.component';
import { MyHealthCareComponent } from './my-health-care/my-health-care.component';
import { MyClaimsComponent } from './my-claims/my-claims.component';
import { ClaimsModule } from '../claims/claimsModule';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { PrescriptionsComponent } from './prescriptions/prescriptions.component';
import { AlertModule } from '@anthem/uxd/alert'; //alerts for asl errors
import { BenefitCardComponent } from './benefitCard/benefit-card.component';
import { DesigneeAccessComponent } from './designee-access/designee-access.component';
import { SydmedSharedModule } from '../../sydmed-shared/sydmed-shared.module';

@NgModule({
  declarations: [
    NewDashboardContainerComponent,
    QuickConnectButtonsComponent,
    SeedSlideoutComponent,
    DashboardCardsContainerComponent,
    BenefitCardComponent,
    NewDashboardMenuComponent,
    PharmacyCardComponent,
    MyHealthCareComponent,
    MyClaimsComponent,
    PrescriptionsComponent,
    DesigneeAccessComponent
  ],
  imports: [
    AlertModule,
    CommonModule,
    AccordianModule,
    IdCardsModule,
    ClaimsModule,
    IndicatorModule,
    AlertModule,
    SydmedSharedModule
  ]
})
export class NewDashboardModule { }
