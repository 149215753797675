import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HeaderNav } from 'sydmed/src/app/secure/pharmacy/interfaces/HeaderNav';
import { PrescriptionOrderRouteService } from 'sydmed/src/app/secure/pharmacy/services/prescription-order-route.service';
import { of, Subscription } from 'rxjs';
import { NavigationStart, Router, Event  } from '@angular/router';
import { FeatureToggleService } from 'sydmed/src/app/secure/pharmacy/services/feature-toggle.service';
import { PharmacyFeatures } from '../enums/PharmacyFeatureToggle';
import { map, mergeMap } from 'rxjs/operators';
import { MedicalProfileService } from 'sydmed/src/app/secure/pharmacy/services/medical-profile.service';
import { CartService } from 'sydmed/src/app/secure/pharmacy/services/cart.service';
import { PharmacyLob as LinesOfBusiness} from 'gbd-models';
import { ProgressBarService } from 'sydmed/src/app/secure/pharmacy-prescription-order/progress-bar/progress-bar.service';
import { CartSessionService } from '../services/cart-session.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
@Component( {
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: [ './header-nav.component.scss' ],

} )
export class HeaderNavComponent implements OnInit, OnDestroy {
  cartCount = 0;
  previousUrl$ = this.routeSvc.previousUrl;
  @Input() headerNav: HeaderNav;
  @Input() isCartDisplay = true;
  private sub: Subscription = new Subscription();
  enableLink = true;
  cartRoute: string;
  previousUrl: string;
  urlParam: string;
  loading = false;
  hasFindPharmacy:boolean = true;

  constructor(
    protected routeSvc: PrescriptionOrderRouteService,
    private router: Router,
    protected pharamcyToggleService: FeatureToggleService,
    protected cartService: CartService,
    protected cartSession: CartSessionService,
    protected profileService: MedicalProfileService,
    protected progressBar: ProgressBarService,
    private jsonSvc: JsonContentService
  ) {
       this.hasFindPharmacy = !this.jsonSvc.hasRestriction(Restriction.SHM_FIND_PHARMACY, this.jsonSvc.getRestrictions());
   }
  ngOnInit(): void {
    this.checkCartInSession();
    this.sub.add( this.routeSvc.cartRoute.subscribe( route => {
      this.cartRoute = route;
    }, err => console.log( 'error in nav component', err )
    ) );

    this.getPreviousUrl();

    const routersub = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          // console.log('router url in header nav', event.url);
          if (event.url && event.url.includes('confirmation')) {
            if (this.headerNav) {
              this.headerNav.showBack = false;
            }
          }
        }
      });
    this.sub.add(routersub);

    this.loading = true;
    this.getCartRoute();
  }


  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.headerNav?.prescriptionRouter]);
  }

  getPreviousUrl() {
    const urlSub = this.previousUrl$.subscribe( ( url: string ) => {
      if ( url ) {
        // console.log( 'Previous URL: ', url );
        const parts = url.split( ';' );
        if ( parts && parts.length === 2 ) {
          this.urlParam = parts[ parts.length - 1 ].split( '=' )[ 1 ];
          // console.log( 'Url Param: ', this.urlParam );
          this.previousUrl = parts[ 0 ];
        } else {
          this.urlParam = null;
          this.previousUrl = url;
        }
      }
    } );
    this.sub.add( urlSub );
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected getCartRoute() {
    const hasPbm = !this.jsonSvc.hasRestriction(Restriction.SHM_PHARMACY_CVS_PBM, this.jsonSvc.getRestrictions());
    const medicalProfileExists = hasPbm ? this.profileService.getMedicalProfileExists() : of(true);
    const cartRouteSub = medicalProfileExists.pipe(mergeMap(isComplete => {
      return this.cartService.lineOfBusiness.pipe(map(lob => ({lob, isComplete})));
    }))
    .subscribe( ({lob, isComplete}) => {
      this.setCartRoute(lob, isComplete);
    } );
    this.sub.add(cartRouteSub);
  }

  protected setCartRoute(lob: LinesOfBusiness, isComplete: boolean) {
    this.routeSvc.setInitialCartRoute( lob, isComplete );
  }

  protected checkCartInSession() {
    const cartCountSub = this.cartSession.loadCartSession().subscribe(
      cartSize => this.cartCount = cartSize
    );
    this.sub.add( cartCountSub );
  }

}
