import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrizettoCostCalculatorRoutingModule } from './trizetto-cost-calculator-routing.module';
import { TrizettoCostCalculatorComponent } from './trizetto-cost-calculator.component';

import { IndicatorModule } from '@anthem/uxd/indicators';
import { AlertModule } from '@anthem/uxd/alert';
import { SecureSsoModule } from '../sso/sso.module';

@NgModule({
  declarations: [TrizettoCostCalculatorComponent],
  imports: [
    CommonModule,
    TrizettoCostCalculatorRoutingModule,
    IndicatorModule,
    AlertModule,
    SecureSsoModule,
  ]
})
export class TrizettoCostCalculatorModule { }
