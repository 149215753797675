import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SydmedSharedRoutingModule } from './sydmed-shared-routing.module';
import { SharedModule } from '@anthem/mbrportal/shared';
import { FormsModule } from '@angular/forms';
import { CallInterventionsModalComponent } from './call-interventions/call-interventions-modal.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { ApiFailureComponent } from './api-failure/api-failure.component';

@NgModule({
  declarations: [
    CallInterventionsModalComponent,
    DateRangeFilterComponent,
    ApiFailureComponent
  ],
  imports: [
    CommonModule,
    SydmedSharedRoutingModule,
    SharedModule,
    IndicatorModule,
    FormsModule,
    DropdownModule
  ],
  exports: [ CallInterventionsModalComponent, DateRangeFilterComponent, ApiFailureComponent ]
})
export class SydmedSharedModule { }
