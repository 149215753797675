import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from'@angular/forms';
import { SendOneTimePasscodeComponent } from 'sydmed/libs/one-time-passcode/send-one-time-passcode/send-one-time-passcode.component';
import { ValidateOneTimePasscodeComponent } from 'sydmed/libs/one-time-passcode/validate-one-time-passcode/validate-one-time-passcode.component';
import { SecurityQuestionsComponent } from 'sydmed/libs/security-questions/security-questions/security-questions.component';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { AlertModule } from '@anthem/uxd/alert'; //alerts for asl errors
import { RadioModule } from '@anthem/uxd/radio'; 
import { SharedComponentsModule } from 'sydmed/libs/shared-components/src/lib/shared-components.module';
import { LanguageToggleComponent } from 'sydmed/src/app/public/language-toggle/language-toggle.component';
import { LanguageToggleService } from './language-toggle/language-toggle.service';

@NgModule({
  declarations: [
    LanguageToggleComponent,
    SendOneTimePasscodeComponent,
    ValidateOneTimePasscodeComponent,
    SecurityQuestionsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CustomValidatorsModule,
    AlertModule,
    RadioModule,
    SharedComponentsModule
  ],
  exports: [
    LanguageToggleComponent,
    SendOneTimePasscodeComponent,
    ValidateOneTimePasscodeComponent,
    SecurityQuestionsComponent,
    SharedComponentsModule
  ],
  providers: [LanguageToggleService]
})
export class PublicModule { }
