export class Step {
  get label(): string {
    return this._label;
  }
  private _label: string;
  private _id: string;

  get id(): string {
    return this._id;
  }
  
  get state(): string {
    return this._state;
  }
  private _state: string;

  constructor(label: string, initialState: string, id: string) {
    this._label = label;
    this._state = initialState;
    this._id = id;
  }

  setState(state: string): void {
    this._state = state;
  }
}
