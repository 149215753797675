import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[AlphaNumericOnly]'
})
export class AlphaNumericOnlyDirective {

  private event;

  constructor( ) { }

  @HostListener('keydown', ['$event'])
  alphaNumericOnly() {
    this.event = event;
      if (this.event.shiftKey) {
        if (this.event.key === 'Tab') { return true; }//shift+tab
        else if(this.event.keyCode >= 65 && this.event.keyCode <= 90) { return true; } //Uppercase alphabets A to Z
        else {
          event.preventDefault();
          return false;
        }          
      }
      if (this.event.ctrlKey) { //ctrl+a, ctrl+c , ctrl+v, ctrl+x
        if ([65, 67, 86, 88].indexOf(this.event.keyCode) > -1) return true;
      }
      // backspace, delete, enter, escape, left arrow, right arrow, tab
      if ([8, 46, 13, 27, 37, 39, 9].indexOf(this.event.keyCode) > -1) {
        return true;
      } else if (this.event.keyCode >= 48 && this.event.keyCode <= 57) { // numbers
        return true;
      } else if (this.event.keyCode >= 96 && this.event.keyCode <= 105) { // numpad number            
        return true;
      } else if (this.event.keyCode >= 65 && this.event.keyCode <= 90) { // alphabets from a to z            
        return true;
      } else {
        event.preventDefault();
        return false;
      }
  }

}
