import { AlertModule } from "@anthem/uxd/alert";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { MyFhrRoutingModule } from "./myFhrRoutingModule";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { UxDeprecatedModule } from '@anthem/uxd/deprecated';
import { MyFhrComponent } from './components/myfhr.component';
import { SecureSsoModule } from '../sso/sso.module';

@NgModule({
  declarations: [MyFhrComponent],
  imports: [AlertModule, SecureSsoModule, CommonModule, FormsModule, HttpClientModule, MyFhrRoutingModule, UxDeprecatedModule
  ],
  exports: [MyFhrComponent],
  providers: [],
  bootstrap: [MyFhrComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MyFhrModule {}
