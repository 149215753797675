import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptService {
  private headers: HttpHeaders;
  constructor(private http: HttpClient) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiKey = environment.apiKey;

    request = request.clone({
      withCredentials: true,
      setHeaders: {
        'X-MADT-AppId': 'portal',
        'X-MADT-AppVersion': '1.0.0',
        apiKey,
      },
    });

    return next.handle(request);
  }

  getImageObservable(url: string, isPDF: boolean, token: any): Observable<any> {
    const apiKey = environment.apiKey;
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-MADT-AppId', 'portal');
    this.headers = this.headers.append('X-MADT-AppVersion', '1.0.0');
    this.headers = this.headers.append('gbd-token', token);
    this.headers = this.headers.append('apiKey', apiKey);
    let params = new HttpParams();
    if (isPDF) {
      params = params.append('isPDF', 'true');
    }

    return this.http.get(url, {
      params,
      observe: 'response',
      responseType: 'blob',
      headers: this.headers,
    });
  }
}
