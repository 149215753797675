<div class="container">
    <div class="col-sm-3 left-side-container left-nav-closed document-hub-sidebar">
        <ul>
            <ng-container>
                <li class="sidebar-list-item">
                    <div uxdAccordion [isAccordion]="true" class="accordion-wrapper zebra-striping sub-menu-set">
                        <uxd-expansion-panel #panel class="accordion-style-two motif-claims-table-accordion sidebar-expansion-panel" [ngClass]="panel.expanded ?'bold-title':'unbold-title'">
                            <uxd-expansion-header>
                                <uxd-expansion-toggle>
                                    <div [ngClass]="{'isActive': selectedItem === sidebarContent?.planDocuments?.url || selectedItem === sidebarContent?.noticesOfAction?.url}" class="sub-menu-title" [innerHTML]="sidebarContent?.documents?.label" [attr.data-analytics]="analyticsTags?.documentsSideNavLink"></div>
                                    <span [ngClass]="panel.expanded ?'motif-angle-up':'motif-angle-down'" class="motif-icon" id="'button-icon" tabindex="0"></span>
                                </uxd-expansion-toggle>
                            </uxd-expansion-header>
                            <ng-container *ngIf="panel.expanded">
                                <a [ngClass]="{'isActive': selectedItem === sidebarContent?.planDocuments?.url}" href="javascript:void(0)" *ngIf="hasPlanDocuments" (click)="setPageNavigation(sidebarContent?.planDocuments?.url)" [innerHTML]="sidebarContent?.planDocuments?.subMenuLabel" class="sidebar-link submenu-link" [attr.data-analytics]="analyticsTags?.planDocumentsSideNavLink"></a>
                                <a [ngClass]="{'isActive': selectedItem === sidebarContent?.noticesOfAction?.url}" href="javascript:void(0)" *ngIf="hasNoticeOfAction && !isCaregiver" (click)="setPageNavigation(sidebarContent?.noticesOfAction?.url)" [innerHTML]="sidebarContent?.noticesOfAction?.subMenuLabel" class="sidebar-link submenu-link"></a>
                            </ng-container>
                        </uxd-expansion-panel>
                    </div>                
                </li>
                <li  *ngIf="hasForms" class="sidebar-list-item">
                    <a [ngClass]="{'isActive': selectedItem === sidebarContent?.forms?.url}" href="javascript:void(0)" (click)="setPageNavigation(sidebarContent?.forms?.url)" [innerHTML]="sidebarContent?.forms?.label" class="sidebar-link" [attr.data-analytics]="analyticsTags?.formsSideNavLink"></a>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="col-sm-9">
        <ng-container>
          <router-outlet></router-outlet>
        </ng-container>
      </div>
</div>