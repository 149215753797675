<div class="uxd-theme-motif container">
  <div tabindex="0" class="margin-top-20 reg-top-1 flex-column-center">
    <div class="width-100 full-width-alert-spacer" *ngIf="aslError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
      <span>{{aslError}}</span>
    </div>
    <div class="width-100 full-width-alert-spacer" *ngIf="noContacts" data-uxd-alert-cmp-v2 [alert]="{alertType: 'info'}">
      <span>{{jsonLabels?.No_Contact_Info_Available}}</span>
      <span *ngIf="selectionRequiredError">{{jsonErrors?.No_Contact_Info_err}}</span>
    </div>
  </div>
  <div class="max-width-elements send-otp-form-container">
    <h1 *ngIf="showForNoContacts()" tabindex="-1" class="text-left font-32 margin-bottom-32">{{jsonLabels?.Instructions[0]}}</h1>
    <h2 *ngIf="showForNoContacts()" class="font-light font-26 margin-bottom-44">{{jsonLabels?.Instructions[1]}}</h2>
    <form [formGroup]="sendOnetimePasscodeForm" name="sendOnetimePasscodeForm" (ngSubmit)="sendCode()">
      <div *ngIf="!noContacts">
        <div *ngIf="showText" class="font-bold margin-bottom-24">
          <div class="flex-horizontal-start">
            <div
              class="text-left icon-placement">
              <div
                [ngClass]="{'selected-color': textSelected, 'margin-bottom-8': !showEmail && !showVoice, 'margin-bottom-24':showEmail || showVoice, 'error': sendOnetimePasscodeForm.controls['sendCodeOption']?.touched && sendOnetimePasscodeForm.controls['sendCodeOption']?.invalid}">
                <span class="fas fa-mobile vertical-center margin-right-12 font-32"></span>
                <span class="font-16">{{jsonLabels?.Options.Text.Label}}</span>
              </div>
            </div>
            <div>
              <ul>
                <li class="uxd-sidenav-wrapper margin-left-60 margin-bottom-24" id="list{{item.id}}"
                  *ngFor="let item of accountRecoveryModel.contactInfo.text">
                  <input #text class="prOption" (change)="onChangeInput('text')" formControlName="sendCodeOption" type="radio"
                    id="text{{item.id}}" name="sendCodeOption" value="{{item.id}}"
                    attr.data-analytics="{{jsonLabels?.Options.Text.Data_Analytics}}">
                  <label
                    [ngClass]="{'active selected-color':text.checked, 'invalidInput': sendOnetimePasscodeForm.controls['sendCodeOption']?.touched && sendOnetimePasscodeForm.controls['sendCodeOption']?.invalid}"
                    class="margin-left-8 font-16 font-normal prLabel" for="text{{item.id}}">{{item.display}}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="showVoice" class="font-bold margin-bottom-24">
          <div class="flex-horizontal-start">
            <div class="text-left icon-placement">
              <div
                [ngClass]="{'selected-color': voiceSelected, 'margin-bottom-8': !showEmail, 'margin-bottom-24': showEmail, 'error': sendOnetimePasscodeForm.controls['sendCodeOption']?.touched && sendOnetimePasscodeForm.controls['sendCodeOption']?.invalid}">
                <span class="fas fa-phone vertical-center margin-right-12 font-32"></span>
                <span class="font-16">{{jsonLabels?.Options.Voice.Label}}</span>
              </div>
            </div>
            <div>
              <ul>
                <li class="uxd-sidenav-wrapper margin-left-60 margin-bottom-24" id="list{{item.id}}"
                  *ngFor="let item of accountRecoveryModel.contactInfo.voice">
                  <input #voice class="prOption" (change)="onChangeInput('voice')" formControlName="sendCodeOption" type="radio"
                    id="voice{{item.id}}" name="sendCodeOption" value="{{item.id}}"
                    attr.data-analytics="{{jsonLabels?.Options.Voice.Data_Analytics}}">
                  <label
                    [ngClass]="{'active selected-color':voice.checked, 'invalidInput': sendOnetimePasscodeForm.controls['sendCodeOption']?.touched && sendOnetimePasscodeForm.controls['sendCodeOption']?.invalid}"
                    class="margin-left-8 voice-label font-16 font-normal prLabel" for="voice{{item.id}}">{{item.display}}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="showEmail" class="font-bold margin-bottom-24">
          <div class="flex-horizontal-start">
            <div
              class="icon-placement">
              <div [ngClass]="{'selected-color': emailSelected, 'error': sendOnetimePasscodeForm.controls['sendCodeOption']?.touched && sendOnetimePasscodeForm.controls['sendCodeOption']?.invalid}" class="margin-bottom-8 flex-vertical-center">
                <span class="far fa-envelope vertical-center margin-right-12 font-32"></span>
                <span  class="font-16">{{jsonLabels?.Options.Email.Label}}</span>
              </div>
            </div>
            <div>
              <ul>
                <li class="uxd-sidenav-wrapper margin-left-60 margin-bottom-24" id="list{{item.id}}"
                  *ngFor="let item of accountRecoveryModel.contactInfo.email">
                  <div class="flex">
                    <input #email class="prOption" (change)="onChangeInput('email')" formControlName="sendCodeOption"
                      type="radio" id="email{{item.id}}" name="sendCodeOption" value="{{item.id}}"
                      attr.data-analytics="{{jsonLabels?.Options.Email.Data_Analytics}}">
                    <label
                      [ngClass]="{'active selected-color':email.checked, 'invalidInput': sendOnetimePasscodeForm.controls['sendCodeOption']?.touched && sendOnetimePasscodeForm.controls['sendCodeOption']?.invalid}"
                      class="margin-left-8 margin-bottom-24 email-label font-normal font-16 prLabel" for="email{{item.id}}">{{item.display}}</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="textAlertOpen">
          <div class="ant-inline-alert ant-alert-info col-md-8 col-sm-10 col-xs-12 col-xxs-12 margin-bottom-48 alert-max-width"
            ng-reflect-ng-class="ant-alert-info" id="inline-alert-0">
            <div  aria-label="jsonLabels?.Text_Info"  class="ant-inline-alert-body">
              <span aria-hidden="true" class="text-alert alert-icon"></span>
              <span role="alert" class="alert-message" id="inline-alert-0-message">
                {{jsonLabels?.Text_Info}}
              </span>
              <button type="button" aria-label="Dismiss alert" class="dismiss-button" id="inline-alert-0-dismiss"
                (click)="textAlertOpen = !textAlertOpen">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="emailAlertOpen">
          <div class="ant-inline-alert ant-alert-info col-md-8 col-sm-10 col-xs-12 col-xxs-12 margin-bottom-48 alert-max-width"
            ng-reflect-ng-class="ant-alert-info" id="inline-alert-0">
            <div class="ant-inline-alert-body">
              <span aria-hidden="true" class="alert-icon"></span>
              <span role="alert" class="alert-message" id="inline-alert-0-message">
                {{jsonLabels?.Email_Info}}
              </span>
              <button type="button" aria-label="Dismiss alert" class="dismiss-button email" id="inline-alert-0-dismiss"
                (click)="emailAlertOpen = !emailAlertOpen">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </div>
        <ul class="padding-left-0 text-left error font-12"
          *ngIf="sendOnetimePasscodeForm.controls['sendCodeOption']?.touched && sendOnetimePasscodeForm.controls['sendCodeOption']?.invalid" role="alert">
          <li *ngIf="sendOnetimePasscodeForm.controls['sendCodeOption']?.errors.required">
            <span class="fa fa-times font-14 margin-right-8"></span>
            <span>{{jsonErrors.ARN_Required_err}}</span>
          </li>
        </ul>
      </div>
      <div *ngIf="showArnLink()" class="text-left max-width-elements flex-horizontal-start">
        <button type="button" class="arrowLink text-left font-16 display-flex" (click)="secondaryValidationLink()"
          attr.data-analytics="{{jsonLabels?.Alternate_Link_Text_Analytics}}">{{jsonLabels?.Alternate_Link_Text}}
          <i class="fas fa-angle-right vertical-center"></i>
        </button>
      </div>
      <div *ngIf="feature === 'registration'" class="text-left max-width-elements flex-horizontal-start">
      </div>
      <div class="form-item margin-bottom-70 margin-top-48">
        <div class="flex-horizontal-end">
          <button type="button" class="btn btn-secondary margin-right-16 "
            (click)="back()" attr.data-analytics="{{jsonLabels?.Buttons.Back_Data_Analytics}}">
            {{jsonLabels?.Buttons.Back}}</button>
          <button *ngIf="showForNoContacts()" type="submit" class="btn btn-primary" 
            attr.data-analytics="{{jsonLabels?.Buttons.Next_Data_Analytics}}">
            {{jsonLabels?.Buttons.Next}}</button>
        </div>
      </div>
    </form>
  </div>
</div>