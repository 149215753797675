export enum AhdPharmacyRoutes {
    REVIEW_ORDER_DETAILS = 'prescriptions/orders/review-orders/:orderId',
    PROCESSING_ORDER_DETAILS = 'prescriptions/orders/processing-orders/:orderId',
    CHECK_ORDER_STATUS_PAGE = 'pharmacy/dashboard#order-status',
    CONTACT_US = 'member/contact-us',
    CONTACT_US_NEW = 'contact-us',
    REVIEW_ORDERS = 'pharmacy/dashboard#review-order', // Used for redirecting to review order page
    ALL_PRESCRIPTIONS = 'secure/ahd-pharmacy/allprescriptions',
    DASHBOARD = 'secure/ahd-pharmacy/dashboard', // Used for redirecting to dashboard
    DOWNLOAD = 'download/:serviceName', // Used for router to redirect to download component
    DOWNLOAD_ORDERS = '/secure/ahd-pharmacy/download/orders', // Used in Angular service to assemble url
    DOWNLOAD_PRESCRIPTIONS = '/secure/ahd-pharmacy/download/prescriptions', // Used in Angular service to assemble url
    LANDING_PAGE = 'dashboard',
    ORDER_REVIEW = 'order/review',
    ORDERS = 'secure/ahd-pharmacy/prescriptions/orders', 
    PHARMACY = 'secure/ahd-pharmacy',   
    SPECIALTY_INFO = 'bio-plus/specialty-information',
    SPECIALTY_REQUEST = 'bio-plus/specialty-request',   
    PRESCRIPTIONS = 'pharmacy/prescriptions'
}