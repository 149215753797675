export interface AHDLandingPageContent {
    New_Prescription_Alert : AlertContent;
    orderStatusLoadingMsg: string;
    bioPlusBanner?: BioPlusBannerContent;
}

export interface BioPlusBannerContent {
    messaage: string;
}

export interface AlertContent {
    descriptionLineOne: string,
    carelonRxPharmacyServiceNumber: string,
    descriptionLineTwo: string
}

export enum AHDFeatures {
    PHARMACY_BLANK_FILLDATE = 'temp-prescriptionLastBlankFillDate'
}

export const DEFAULT_DATE_RANGE_6MONTH: string = 'past6months';