import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { LiveChatService } from '../services/live-chat.service';
import CONSTANTS from '../utils/constants';
import { ChatDetail, HoursResponse, Restriction } from 'gbd-models';
import * as moment from 'moment-timezone';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';

@Component({
  selector: 'app-chat-topics',
  templateUrl: './chat-topics.component.html',
  styleUrls: ['./chat-topics.component.scss']
})
export class ChatTopicsComponent implements OnInit, OnChanges {

  @Input() refreshCategories: boolean;
  @Input() hasSurveyStarted: boolean = false;
  @Input() isChatInitiated: boolean;
  @Input() content: any;
  @Input() locale: string;
  @Input() memberName: string;
  @Input() isTopicSelected: boolean;
  @Input() isRestore: boolean;
  @ViewChild('helpToday') helpToday: ElementRef;
  @Output() chatTopicSelected: EventEmitter<ChatDetail> = new EventEmitter<ChatDetail>();
  
  public labels: any;
  public hours: HoursResponse;
  public available: ChatDetail[];
  public unavailable: ChatDetail[];
  public showError: boolean = false;
  public errorCode : string;
  public errorMessage: string;
  public selectedCategory: ChatDetail;
  public restoredCategory: string;
  public liveChatWithSelectedTopic: boolean;
  
  analytics = CONSTANTS.ANALYTICS;

  constructor(
    private liveChatSvc: LiveChatService,
    private jsonContentService: JsonContentService
  ) { }

  ngOnInit(): void {
    this.liveChatWithSelectedTopic = this.liveChatSvc.liveChatwithSeletcedTopic;
  }
  
  ngOnChanges(): void {
    if (this.refreshCategories) {
      this.fetchCategories();
      this.refreshCategories = false;
      this.isTopicSelected = this.isRestore ? true : false;
      setTimeout(() => {this.helpToday.nativeElement.focus();}, CONSTANTS.FOCUS_DELAY);
    }
    this.labels = this.content ? this.content.labels : {};
    this.errorMessage = this.content ? this.content.errorMessages.chatConnectionError : '';
    if (this.isTopicSelected && !this.selectedCategory) {
      this.restoredCategory = sessionStorage.getItem('selectedChatTopic');
    }
  }

  initiateChat(category): void {
    if (this.isRestore) {
      this.restoredCategory = null;
      this.isRestore = false;
    }
    if (this.isTopicSelected) return;
    this.isTopicSelected = true;
    this.selectedCategory = category;
    this.chatTopicSelected.emit(category);
  }

  getAnalyticsString(topicName: string) {
    topicName = topicName ? this.removeSpacesAndLowerCaseText(topicName) : '';
    return this.analytics[topicName] ? this.analytics[topicName] : '';
  }  
  public getTopics(topicName) {
    if (this.locale === 'es-US') {
      topicName = topicName ? this.removeSpacesAndLowerCaseText(topicName) : '';
      return this.labels[topicName] ?? '';
    } else {
      return topicName;
    }
  }
  private removeSpacesAndLowerCaseText(text: string) : string {
    return text.toLowerCase().replace(/\s/g, '');
  }
  fetchCategories() {
    this.showError = false;
    this.liveChatSvc.getChatHours().subscribe(data => {
      this.hours = data.body;
      this.hours.chatDetails.forEach(chatDetail => {
        if (this.jsonContentService.hasRestriction(
          Restriction.SHM_CARE_COORDINATOR_CHAT,
          this.jsonContentService.getRestrictions()
        ) &&
        chatDetail.topic === CONSTANTS.TOPICS.CHAT_WITH_A_COORIDNATOR) {
          this.hours.chatDetails.splice(this.hours.chatDetails.indexOf(chatDetail), 1);
        }
      })
      this.available = this.hours.chatDetails.filter(topic => topic.availableNow === true);
      this.unavailable = this.liveChatWithSelectedTopic ? this.hours.chatDetails.filter(topic => topic.availableNow === false) :
      this.hours.chatDetails.filter(topic => topic.availableNow === false && topic.topic === sessionStorage.getItem('selectedChatTopic'));
      if (sessionStorage.getItem('selectedChatTopic') && this.unavailable.length > 0 ) {
        this.liveChatSvc.liveChatwithSeletcedTopic = false;
      }
    }, error => {
      this.showError = true;
      this.errorCode = error.error ? error.error.code : '0000';
    });
    this.makeInterval();
  }
  makeInterval(){
    const todaysDate = new Date();
    const todaysDateInMinutes = todaysDate.getMinutes();
    const nextRun = (60*(30-todaysDateInMinutes))*1000;
    if (nextRun > 10000)
      setTimeout(() => {this.fetchCategories()}, nextRun);
  }
  public showTopicAvailability(chatDetail: ChatDetail) {
    let openHours = chatDetail.chatHours.regularHoursOfOperation.filter(x => x.openTime != 'CLOSED');
    if (openHours.length > 1) {
      return this.formatTime(openHours[0]) + ', ' + this.formatTime(openHours[1]) + '.';
    } else {
      return this.formatTime(openHours[0]) + '.';
    }
  }
  formatWeekdays(weekdays): string {
    let days = weekdays.split('-');
    if (this.locale === 'es-US') {
      return CONSTANTS.SPANISH_WEEKDAYS[days[0].toLowerCase()] + ' ' + this.labels.chatHoursThrough + ' ' + CONSTANTS.SPANISH_WEEKDAYS[days[1].toLowerCase()];
    } else {
      return days[0] + ' ' + this.labels.chatHoursThrough + ' ' + days[1];
    }
  }
  extractTimeDetails(openHours): any {
    const timeZone = moment.tz.guess() ? moment.tz.guess() : 'America/New_York';
    let momentObj = moment.tz(openHours, 'HH:mm:ss', 'America/New_York').tz(timeZone);
    let timezone = this.content.TIMEZONE_MAPPING[momentObj.format('z')];
    let time = momentObj.format('h:mm');
    let meridiem = this.content.MERIDIEM_MAPPING[momentObj.format('A')];
    return { time, meridiem, timezone }
  }
  formatTime(openHours): string {
    let openTime = this.extractTimeDetails(openHours.openTime);
    let closeTime = this.extractTimeDetails(openHours.closeTime);
    return openTime.time + ' ' + openTime.meridiem + ' ' + this.labels.chatHoursTo + ' ' + closeTime.time + ' '
       + closeTime.meridiem + ' ' + openTime.timezone + ', ' + this.formatWeekdays(openHours.scheduledDay);
  }
}
