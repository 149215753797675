import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { PaymentMethodOption } from '../interfaces/PaymentMethodOption';
import { PaymentMethodRadioGroup } from '../interfaces/PaymentMethodRadioGroup';
import { PaymentPortalService } from ".././services/payment-portal.service";
import { PaymentHelperService } from ".././services/payment-helper.service";
import { Subscription } from 'rxjs';
import { default as addCardJson } from "sydmed/libs/pharmacy-components/payment-methods/assets/data/cardJsonData.json";
import { default as addBankJson } from "sydmed/libs/pharmacy-components/payment-methods/assets/data/checkingJsonData.json";

import { PaymentType } from 'gbd-models';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
  @Input() content: any;
  @Input() showButtons: boolean;
  @Input() autopay: boolean;
  @Input() errObj: any;
  paymentList: PaymentMethod[];
  @Input() selectedPayment: PaymentMethod;
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();

  protected sub: Subscription = new Subscription();
  public paymentOptions: PaymentMethodOption[];
  public addressOptions: any[];
  public creditPaymentRadioGroup: PaymentMethodRadioGroup;
  public bankPaymentRadioGroup: PaymentMethodRadioGroup;
  public addressPaymentRadioGroup: any;
  public selectedId: string;
  public selectedAddressId: string;
  public showCreditList = false;
  public showBankList = false;
  public showAddressList = false;
  public showAddPayment = false;
  public addCardContent: any;
  public addBankContent: any;
  public addressList: any;
  public radioAddress;
  stateList: any = {
    title: "",
    options: []
  };
  constructor(
    private _helper: PaymentHelperService,
    private _service: PaymentPortalService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.selectedPayment) {
      this.selectedId = this.selectedPayment.accountName;
    }
    this.changeDetector.detectChanges();
    this.getPaymentMethods();
  }

  getPaymentMethods() {
    let paymentListSub: Subscription;
    this._service.getMethods().subscribe(res => {
      let paymentMethods = this._helper.mapPaymentMethods(res.body);
      this.addressList = paymentMethods.address;
      this.setPaymentList(paymentMethods.methods);
    });
    this.sub.add(paymentListSub);
  }

  setPaymentList(paymentList: PaymentMethod[]) {
    if (paymentList) {
      this.paymentList = paymentList.map(paymentMethod => paymentMethod);
    } else {
      this.selectedId = 'addNewCard';
    }
    this.createPaymentLists();
  }

  private createPaymentLists() {
    const addNewCreditCard = this.content?.Labels?.PaymentNewCard;
    const addNewChecking = this.content?.Labels?.PaymentNewBank;
    const addnewAddress = this.content?.Labels?.AddNewAddress;

    this.addCardContent = addCardJson.AddCardLabels;
    this.addBankContent = addBankJson.AddCheckingLabels;

    this.createRadioGroup(PaymentType.CREDIT_CARD, addNewCreditCard);
    this.createRadioGroup(PaymentType.BANK_ACCOUNT, addNewChecking);
    this.createRadioGroup(PaymentType.BANK_ACCOUNT, addnewAddress, true);

  }

  createRadioGroup(accountType: PaymentType, addNewLabel: string, isAddress: boolean = false) {
    if (!isAddress) {
      let payments: PaymentMethod[] = [];
      const addNewValue = (accountType === PaymentType.CREDIT_CARD) ? 'addNewCard' : 'addNewBank';

      if (this.paymentList && accountType === PaymentType.CREDIT_CARD) {
        payments = this.paymentList.filter((payment, index) => {
          
          if (!payment.isBankAcc) {
            payment.accountName = payment.creditCardType + "-" + payment.creditCardNumber + "_" + index;
            if (payment.preferredAccount ||
              (this.selectedPayment && this.selectedPayment.accountName === payment.accountName)) {
              this.selectedId = payment.accountName;
            }
            return payment;
          }
        });
      }
      else {
        payments = this.paymentList.filter((payment, index) => {
          if (payment.isBankAcc) {
            payment.accountName = payment.bankAccountType + "" + payment.routingNumber + "_" + index;
            if (payment.preferredAccount ||
              (this.selectedPayment && this.selectedPayment.accountName === payment.accountName)) {
              this.selectedId = payment.accountName;
            }
            return payment;
          }
        });
      }

      this.paymentOptions =
        this._helper.createPaymentMethodOptions(payments, addNewLabel, addNewValue);
      if (accountType === PaymentType.CREDIT_CARD) {
        this.creditPaymentRadioGroup = this._helper.createRadioGroup(this.paymentOptions);
        this.showCreditList = true;
      } else {
        this.bankPaymentRadioGroup = this._helper.createRadioGroup(this.paymentOptions);
        this.showBankList = true;
      }
    }
    else {
      this.addressOptions = this._helper.createAddressOptions(this.addressList, addNewLabel, "addNewAddress");
      this.addressPaymentRadioGroup = this._helper.createRadioGroup(this.addressOptions);
      this.showAddressList = true;
    }
  }

  updatePaymentMethod() {
    this.paymentList?.filter(payment => {
      if (this.selectedId === payment.accountName) {
        this._helper.setSelectedPaymentMethod(payment, false, null);
      }
    });
    this.onCancel();
  }

  onCancel() {
    this.closePanel.emit(true);
  }
}
