export interface CallInterventionsLabels {
    header: string;
    needtoReview: string;
    needHelp: string;
    close: string;
    footerContent: FooterContent;
    liveChatFooterContent: FooterContent;
    errorMsgs: ErrorMessageDetails;
}

export interface Placeholder {
    actionType: string;
    analyticsData: AnalyticsData;
    content: string;
    name: string;
    type: string;
    staticAttributes: {
        [key: string]: string;
    };
}

export interface ErrorMessageDetails {
    pleaseTryAgain: string,
    pleaseTryAgainErrorImg: string,
    pageLevelErrorMessage: string
}

export interface FooterContent {
    content: string;
    placeholders: Placeholder[];
}

export interface AnalyticsData {
    linkText: string;
}

export enum MessageUidExtension {
    INTERVENTION = 'intervention.ecomm.'
}