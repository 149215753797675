
<div class="row padding-top-30 landing-page" *ngIf="content">  
  <div class="col-sm-12 padding-left-0"> 
    <div class="ant-inline-alert ant-alert-info bio-plus-banner" *ngIf="showBioPlusPaymentAlert">
      <span class="alert-icon motif-icon motif-info"></span>
      <p class="alert-message" [innerHTML]="bioPlusAlertMessage"></p>
      <span class="alert-close motif-icon motif-delete" (click)="closeBioAlert()"></span>
    </div>
    <div class="ant-inline-alert ant-alert-info bio-plus-banner" *ngIf="showBioPlusBanner">
      <span class="alert-icon motif-icon motif-info"></span>
      <p class="alert-message" [innerHTML]="bioPlusBanner.message"></p>
      <span class="alert-close motif-icon motif-delete" (click)="closeAlert()"></span>
    </div>   
    <div class="margin-bottom-50 padding-left-0">
      <app-ahd-prescription-benefit-orders></app-ahd-prescription-benefit-orders>
    </div>
    <div class="margin-bottom-50 ahd-home-component">
      <app-ahd-prescription-home></app-ahd-prescription-home>
    </div>
    <div class="loader" data-uxd-data-loader-cmp *ngIf="isLoading && landingPageContent">
      <span [innerHTML]="landingPageContent?.orderStatusLoadingMsg"></span>
    </div>
    <div class="margin-bottom-50 padding-left-0">
      <app-ahd-landing-page-orders></app-ahd-landing-page-orders>
    </div>
    <div class="margin-bottom-50 padding-left-0" *ngIf="profileContent">
      <app-ahd-pharmacy-profile id="ahd-pharmacy-profile-card"></app-ahd-pharmacy-profile>
    </div>       
    <div class="margin-bottom-50 padding-left-0">
      <app-ahd-id-card-messages></app-ahd-id-card-messages>
    </div>
  </div>
</div>
