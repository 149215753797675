import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MemberPrescription } from 'sydmed/src/app/secure/pharmacy/interfaces/MemberPrescription';
import { PrescriptionsResponse } from 'gbd-models';

@Injectable( {
  providedIn: 'root'
} )
export class MockDataService {

  constructor() { }

  getMockData( isActionable = true ) {
    return isActionable ? this.getMockActionable() : this.getMockNonActionable();
  }

  getMockActionable() {
    return from( import( 'sydmed/src/app/secure/pharmacy-all-prescriptions/assets/mocks/prescriptions-large-response-data-v2.json' ) ).pipe(
      map( res => res as PrescriptionsResponse ),
      catchError(
        err => throwError( err ) )
    );
  }

  getMockNonActionable() {
    return from( import( 'sydmed/src/app/secure/pharmacy-all-prescriptions/assets/mocks/prescriptions-non-actionable.json' ) ).pipe(
      map( res => res.memberPrescriptions ),
      catchError(
        err => throwError( err ) )
    );
  }

  getMockPbmPaymentMethods() {
    return from(import('sydmed/src/app/secure/pharmacy-prescription-order/assets/mocks/accounts.response.json')).pipe(
      map(res => res.default),
      catchError(
        err => throwError(err))
    );
  }

  getMockSpecialtyPaymentMethods() {
    return from(import('sydmed/src/app/secure/pharmacy-prescription-order/assets/mocks/specialtyaccounts.response.json')).pipe(
      map(res => res.default),
      catchError(
        err => throwError(err))
    );
  }
}
