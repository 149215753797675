import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FileAttachmentService {
  fileList = [];
  isFileLimitExceeds = false;
  isFileSizeExceeds = false;
  isFileTypeError = false;
  isPasswordProtectedFile = false;
  errorMessage = "";
  noteDescription = "";
  errorMessages: any;
  enableAttachment: boolean;

  constructor() {}

  setJsonData(AttachmentErrorMsgs, NoteDescription, EnableFileAttachment) {
    this.errorMessages = AttachmentErrorMsgs;
    this.noteDescription = NoteDescription;
    this.enableAttachment = EnableFileAttachment;
  }

  fileSizeValidation(fileSize) {
    if (this.getFileSize(fileSize) > 5) {
      this.isFileSizeExceeds = true;
      this.setErrorMessages(this.errorMessages.FileSizeError);
    } else {
      let fileLimit = fileSize;
      this.fileList.forEach((file) => {
        fileLimit += file.fileSize;
      });
      if (this.getFileSize(fileLimit) > 10) {
        this.isFileLimitExceeds = true;
        this.setErrorMessages(this.errorMessages.TotalFileSizeError);
      }
    }
  }

  validateFile(fileTypes, file) {
    this.clearValidation();
    if (fileTypes.includes(file.fileType)) {
      this.fileSizeValidation(file.fileSize);
      if (!this.isFileLimitExceeds && !this.isFileSizeExceeds) {
        this.fileList.push(file);
        return file;
      }
    } else {
      this.isFileTypeError = true;
      this.setErrorMessages(this.errorMessages.FileTypeError);
    }
  }

  deleteFile(fileData) {
    this.fileList.splice(this.fileList.indexOf(fileData), 1);
  }

  clearValidation() {
    this.isFileTypeError = false;
    this.isFileLimitExceeds = false;
    this.isFileSizeExceeds = false;
    this.isPasswordProtectedFile = false;
    this.errorMessage = "";
  }

  getFileSize(fileSize: any) {
    return Math.floor((fileSize * 100) / (1024 * 1024)) / 100;
  }

  setErrorMessages(message: string) {
    this.errorMessage = message;
  }
}
