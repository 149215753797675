<div class="uxd-theme-motif container margin-bottom-40">
    <div class="uxd-theme-motif">
        <div *ngIf="aslError" class="ant-margin-top-20 width-100 full-width-alert-spacer" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{aslError}}</span>
        </div>
        <div class="margin-bottom-40 security-questions-container max-width-elements">
            <h1 tabindex="-1" class="text-left margin-bottom-32 font-32">{{jsonLabels?.Page_Heading}}</h1>
            <p class="font-16 line-height font-normal margin-bottom-40">{{jsonLabels?.Instructions}}</p>
            <form #questionsForm="ngForm" [formGroup]="securityQuestionsForm"
                (ngSubmit)="verifySecurityQuestions()" id="securityQuestionsForm"
                name="securityQuestionsForm">
                <div class="margin-bottom-48">
                    <div *ngFor="let questions of questionsModel; let i = index">
                        <div>
                            <p class="text-left font-18 margin-bottom-24">{{questions.question}}</p>
                            <div class="form-item margin-bottom-40">
                                <label class="text-left font-16 font-semiBold margin-bottom-4" for="{{i}}">{{jsonLabels?.Field_Labels.Answer}}</label>
                                <div class="mask-wrapper">
                                    <input #{{i}} (change)="onChangeInput()" type="password" aria-required="true" 
                                    class="ant-input-long ant-text-input" name="{{i}}" formControlName="{{i}}" [attr.aria-label]="questions.question + ' ' + jsonLabels?.Field_Labels.Answer" 
                                    id="{{i}}" [attr.aria-describedby]="'answerRequiredError' + i">
                                    <span (click)="showHide(i, jsonLabels?.Field_Labels.Answer)" (keyup.enter)="showHide(i, jsonLabels?.Field_Labels.Answer)" tabindex="0" role ="button" aria-pressed="false" [attr.aria-label]="'unmask ' + jsonLabels?.Field_Labels.Answer" 
                                        class="fa md ant-info-icon ant-data-security-mask fa-eye-slash"><span class="sr-only">{{jsonLabels?.Field_Labels.Mask_Answer}}</span></span>
                                </div>
                                <ul [attr.id]="'answerRequiredError' + i" class="font-12 margin-top-14"
                                    *ngIf="(questionsForm.submitted || securityQuestionsForm.controls[i]?.touched)  && securityQuestionsForm.controls[i]?.invalid" aria-live="polite">
                                    <li>
                                        <span class="fas fa-times font-14 margin-right-8"></span>
                                        <span>{{jsonLabels?.Field_Labels.Answer}}</span>
                                        <span>{{jsonErrors.Required_err}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        class="max-width margin-bottom-32">
                        <div class="form-item text-left margin-0">
                            <input type="radio" formControlName="rememberDevice" class="prOption font-16"
                                id="remember-device" name="rememberDevice" value="yes"
                                attr.data-analytics="{{jsonLabels?.Options.Remember_Device.Data_Analytics}}"
                                (change)="onChangeInput()">
    
                            <label class="prLabel margin-0" for="remember-device"
                                [ngClass]="{'active':securityQuestionsForm.controls['rememberDevice'].value === 'yes', 'invalidInput': securityQuestionsForm.controls['rememberDevice']?.touched && securityQuestionsForm.controls['rememberDevice']?.invalid}">
                                <b class="font-16 validate-one-time-bold"
                                    [ngClass]="{'invalid': securityQuestionsForm.controls['rememberDevice']?.touched && securityQuestionsForm.controls['rememberDevice']?.invalid}">{{jsonLabels?.Options.Remember_Device.Heading}}</b>
                                <p class="margin-left-24 margin-top-4">{{jsonLabels?.Options.Remember_Device.Description}}</p>
                            </label>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        class="max-width margin-bottom-32">
                        <div class="form-item text-left margin-0">
                            <input type="radio" formControlName="rememberDevice" class="prOption font-16"
                                id="do-not-remember-device" name="rememberDevice" value="no"
                                attr.data-analytics="{{jsonLabels?.Options.Do_Not_Remember_Device.Data_Analytics}}"
                                (change)="onChangeInput()">

                            <label class="prLabel margin-0" for="do-not-remember-device" tabindex="0"
                                [ngClass]="{'active':securityQuestionsForm.controls['rememberDevice'].value === 'no', 'invalidInput': securityQuestionsForm.controls['rememberDevice']?.touched && securityQuestionsForm.controls['rememberDevice']?.invalid}">
                                <b class="font-16 validate-one-time-bold"
                                    [ngClass]="{'invalid': securityQuestionsForm.controls['rememberDevice']?.touched && securityQuestionsForm.controls['rememberDevice']?.invalid}">{{jsonLabels?.Options.Do_Not_Remember_Device.Heading}}</b>
                                <p class="margin-left-24 margin-top-4">{{jsonLabels?.Options.Do_Not_Remember_Device.Description}}</p>
                            </label>
                        </div>
                        <ul class="ant-bar-chart-group text-left font-12 margin-top-14"
                            *ngIf="securityQuestionsForm.controls['rememberDevice']?.touched && securityQuestionsForm.controls['rememberDevice']?.invalid">
                            <li
                                *ngIf="securityQuestionsForm.controls['rememberDevice']?.errors.required">
                                <div>
                                    <span class="fas fa-times font-14 margin-right-8"></span>
                                    <span tabindex="0">{{jsonErrors.ARN_Required_err}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                    <div class="text-left button-group margin-top-40">
                        <div class="form-item">
                            <div class="flex-horizontal-end">
                                <button type="button" class="btn btn-secondary" (click)="back()" attr.data-analytics="{{jsonLabels?.Buttons.Back_Data_Analytics}}">{{jsonLabels?.Buttons.Back}}</button>
                                <button type="submit" class="btn btn-primary color" attr.data-analytics="{{jsonLabels?.Buttons.Next_Data_Analytics}}">{{jsonLabels?.Buttons.Next}}</button>
                            </div>
                        </div>
                    </div>
            </form>
        </div>
    </div>
</div>