import { Component, Input, OnInit } from '@angular/core';
import { RxPreAuthContent } from '../../pharmacy-dashboard/interfaces/PharmacyDashboardContent';

@Component({
  selector: 'app-prescription-pre-auth-alert',
  templateUrl: './prescription-pre-auth-alert.component.html',
  styleUrls: ['./prescription-pre-auth-alert.component.scss']
})
export class PrescriptionPreAuthAlertComponent implements OnInit {
  @Input() content: RxPreAuthContent;
  @Input() rxName: string;
  @Input() rxStatus: string;
  dismiss: boolean = false;
  ariaLabel: string;
  constructor() { }

  ngOnInit(): void {
    this.ariaLabel = this.content?.AriaLabel + this.rxName;
  }

  close(): void {
    this.dismiss = true;
  }
}
