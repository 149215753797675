<div id="third-party-insurance" class="container">
  <ng-container *ngIf="pageLoader; else showPageContent">
    <div class="ant-ui-widget-overlay"></div>
    <div class="ant-ajax-load">
      <uxd-spinner></uxd-spinner>
      <div>Loading, please wait…</div>
    </div>
  </ng-container>
  <ng-template #showPageContent>
    <div>
      <p id="description" class="motif-margin-top-14 motif-margin-bottom-11" [innerHtml]="content.labels.description"></p>
    </div>
    <ng-container *ngIf="isAPILoading; else showErrorOrData">
      <div>
        <uxd-spinner></uxd-spinner>
        <div [innerHtml]="content.labels.loading"></div>
      </div>
    </ng-container>
    <ng-template #showErrorOrData>
      <ng-container *ngIf="genericErr; else showPage">
        <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
          <span [innerHTML]="genericErr"></span>
        </div>
      </ng-container>
      <ng-template #showPage>
        <div class="motif-margin-bottom-21">
          <ng-container *ngIf="tpiError; else showTPIDetails">
            <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
              <span id="information" class="api-info" [innerHTML]="tpiError">
              </span>
            </div>
          </ng-container>
          <ng-template #showTPIDetails>
            <ng-container *ngIf="this.displayTPIDetails.length; else showNoData">


              <div class="container desktop-header">
                <!-- Desktop header -->
                <div class="col-xxs-12 col-xs-12 tpl-table-header" aria-hidden="true">
                  <div class="tpl-header-item">
                    <span class="tpl-summar-table-label" id="col-item-1"
                      [innerHTML]="content.labels.insuranceHeaders.nameOfInsured"></span>
                  </div>
                  <div class="tpl-header-item">
                    <span class="tpl-summar-table-label" id="col-item-2"
                      [innerHTML]="content.labels.insuranceHeaders.coverage"></span>
                  </div>
                  <div class="tpl-header-item">
                    <span class="tpl-summar-table-label" id="col-item-3"
                      [innerHTML]="content.labels.insuranceHeaders.term"></span>
                  </div>
                  <div class="tpl-header-item">
                    <span class="tpl-summar-table-label" id="col-item-4"
                      [innerHTML]="content.labels.insuranceHeaders.coveredBy"></span>
                  </div>
                </div>
              </div>

              <!-- Accordion goes here -->
              <uxdAccordion class="accordion-wrapper uxd-expansion-table zebra-striping" [isAccordion]="true">
                <div class="uxd-expansion-table" role="table">
                  <div role="rowgroup" class="sr-only">
                    <div role="row">
                      <span role="columnheader"
                        aria-sort="none">{{content.labels.insuranceHeaders.nameOfInsured}}</span>
                      <span role="columnheader" aria-sort="none">{{content.labels.insuranceHeaders.coverage}}</span>
                      <span role="columnheader" aria-sort="none">{{content.labels.insuranceHeaders.term}}</span>
                      <span role="columnheader" aria-sort="none">{{content.labels.insuranceHeaders.coveredBy}}</span>
                    </div>
                  </div>
                  <div role="rowgroup" class="container padding-left-0">
                    <uxd-expansion-panel *ngFor="let row of displayTPIDetails; index as i" #panel="uxdExpansionPanel">
                      <uxd-expansion-header [aria-label]="'View information for claim with ID ' + row.insurancePolicy">
                        <div class="uxd-table-row" role="row" [id]="'uxd-table-row-' + (i + 1)">
                          <div role="cell" [id]="'button-cell-'+ i">
                            <uxd-expansion-toggle>
                              <span class="motif-icon" [id]="'button-icon-'+ i"
                                [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
                            </uxd-expansion-toggle>
                          </div>
                          <div class="tpl-description-item" role="cell" [id]="'uxd-table-row-' + (i + 1) + '-cell-1'">
                            <p class="ant-font-sb tpl-header-item" data-before-content="Name of Insured">{{
                              row?.subscbrName }}</p>
                          </div>
                          <div class="tpl-description-item" role="cell" [id]="'uxd-table-row-' + (i + 1) + '-cell-2'">
                            <p class="ant-font-sb tpl-header-item" data-before-content="Coverage">{{
                              row?.coverageType }}</p>
                          </div>
                          <div class="tpl-description-item" role="cell" [id]="'uxd-table-row-' + (i + 1) + '-cell-3'">
                            <p class="ant-font-sb tpl-header-item" data-before-content="Term">{{ row?.coverageTermDate ?
                              row?.summaryDisplayTermDate : row?.coverageTermDate}}
                            </p>
                          </div>
                          <div class="tpl-description-item" role="cell" [id]="'uxd-table-row-' + (i + 1) + '-cell-4'">
                            <p class="ant-font-sb tpl-header-item" data-before-content="Covered by"> {{
                              row?.tplCompName }}</p>
                          </div>
                        </div>
                      </uxd-expansion-header>
                      <div class="ant-content-wrapper" role="region">
                        <div class="cards-wrapper">
                          <app-third-party-insurance-details [labels]="content.labels" [details]="displayTPIDetails[i]">
                          </app-third-party-insurance-details>
                        </div>
                      </div>

                    </uxd-expansion-panel>
                  </div>
                </div>
              </uxdAccordion>
              <!-- Accordion ends here -->

            </ng-container>
            <ng-template #showNoData>
              <div class="motif-margin-top-7" data-uxd-alert-cmp-v2 [alert]="{alertType: 'info'}">
                <span id="information" class="api-info" [innerHTML]="content.errorMessages.noOtherInsuranceAvailable">
                </span>
              </div>
            </ng-template>

            <div class="motif-margin-top-19">
              <p class="motif-margin-top-15"> {{content.labels.reportFraudDescription}}</p>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</div>