<div class="uxd-theme-motif">
  <div class="container">
    <div class="margin-top-60 loading-spinner-alignment" *ngIf="loadingSpinner">
      <uxd-spinner></uxd-spinner>
    </div>
    <div class="full-width-alert-spacer" *ngIf="aslError && !loadingSpinner" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
      <span>{{aslError}}</span>
    </div>
    <div class="max-width-elements" *ngIf="!loadingSpinner">
      <h1 tabindex="-1" class="text-left font-32 margin-bottom-48">{{jsonLabels?.Page_Heading}}</h1>
      <div class="form-item">
        <div *ngIf="isCaregiver">
          <h2 class="section-header-two">{{jsonLabels?.CaregiverFields.Member}}</h2>
          <label for="memberName">{{jsonLabels?.CaregiverFields.Name}}</label>
          <p id="memberName" class="margin-bottom-24">{{reviewModel?.registration?.lastName}}, {{reviewModel?.registration?.firstName}}</p>
          <label for="memberDob">{{jsonLabels?.CaregiverFields.Dob}}</label>
          <p id="memberDob" class="margin-bottom-40">{{reviewModel?.registration?.dob}}</p>

          <h2 class="section-header-two">{{jsonLabels?.CaregiverFields.Guardian}}</h2>
          <label for="caregiverName">{{jsonLabels?.CaregiverFields.Name}}</label>
          <p id="caregiverName" class="margin-bottom-24">{{reviewModel?.registration?.caregiverInformation?.lastName}}, {{reviewModel?.registration?.caregiverInformation?.firstName}}</p>
          <label for="caregiverDob">{{jsonLabels?.CaregiverFields.Dob}}</label>
          <p id="caregiverDob" class="margin-bottom-24">{{reviewModel?.registration?.caregiverInformation?.dateOfBirth}}</p>
          <label for="phoneNumber">{{jsonLabels?.CaregiverFields.Phone}}</label>
          <p id="phoneNumber" class="margin-bottom-24">{{reviewModel?.registration?.caregiverInformation?.phoneNumber}}</p>
          <label for="address">{{jsonLabels?.CaregiverFields.Address}}</label>
          <p id="address" class="margin-bottom-0">{{reviewModel?.registration?.caregiverInformation?.address?.streetAddress1}}</p>
          <p *ngIf="reviewModel.registration.caregiverInformation.address.streetAddress2" id="address">{{reviewModel?.registration?.caregiverInformation?.address?.streetAddress2}}</p>
          <label for="city" class="margin-top-24">{{jsonLabels?.CaregiverFields.City}}</label>
          <p id="city" class="margin-bottom-24">{{reviewModel?.registration?.caregiverInformation?.address?.city}}</p>
          <label for="state">{{jsonLabels?.CaregiverFields.State}}</label>
          <p id="state" class="margin-bottom-24">{{reviewModel?.registration?.caregiverInformation?.address?.state}}</p>
          <label for="zip">{{jsonLabels?.CaregiverFields.Zip}}</label>
          <p id="zip" class="margin-bottom-24">{{reviewModel?.registration?.caregiverInformation?.address?.zipCode}}</p>
          <label for="role">{{jsonLabels?.CaregiverFields.RoleLabel}}</label>
          <p id="role" class="margin-bottom-40">{{jsonLabels?.CaregiverFields.Role}}</p>
        </div>

        <span class="no-outline"><label>{{jsonLabels?.Fields.Username}}</label>
        <p  class="margin-bottom-24">{{reviewModel?.registration?.username}}</p></span>

        <span class="no-outline" *ngIf="reviewModel?.registration?.email"><label>{{jsonLabels?.Fields?.Email_Address}}</label>
        <p class="margin-bottom-24 no-capital" *ngIf="reviewModel?.registration?.email">{{reviewModel.registration.email}}</p></span>

        <span class="no-outline"><label>{{jsonLabels?.Fields?.Account_Recovery_Number}}</label>
        <p class="margin-bottom-24" x-ms-format-detection="none">{{reviewModel?.registration?.accountRecovery?.number}}
          ({{reviewModel?.registration?.accountRecovery?.type}})</p></span>

        <span class="no-outline"><label>{{jsonLabels?.Fields.Question_1}}</label>
        <p class="margin-bottom-24">{{reviewModel?.registration?.questionAnswers[0]?.question}}</p></span>

        <span class="no-outline"><label>{{jsonLabels?.Fields.Question_2}}</label>
        <p class="margin-bottom-24">{{reviewModel?.registration?.questionAnswers[1]?.question}}</p></span>

        <span class="no-outline"><label>{{jsonLabels?.Fields.Question_3}}</label>
        <p class="margin-bottom-24">{{reviewModel?.registration?.questionAnswers[2]?.question}}</p></span>

      </div>
      <div class="button-group margin-top-48">
        <div class="form-item margin-bottom-70">
          <div class="button-group flex">
            <button type="button" class="btn btn-secondary"
              (click)="back()" data-analytics="backButtonReviewSubmitReg">{{jsonLabels?.Buttons.Back}}</button>
            <button type="submit" class="btn btn-primary" (click)="submit()"
              data-analytics="nextButtonReviewSubmitReg">{{jsonLabels?.Buttons.Submit}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>