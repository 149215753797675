<div class="quick-buttons-container">
  <ng-container *ngFor="let quickButton of quickConnectButtons">
    <div *ngIf="quickButton.show" class="quick-connect-button-container">
      <button role="link" [id]="quickButton.id" [attr.data-analytics]="quickButton.analytics"
        (click)="onClick(quickButton)" class="quick-connect-button">
        <!-- Uses the img tag if the icon is not available within uxd pattern pattern library -->
        <ng-container *ngIf="quickButton.image; else icon">
          <img [src]="quickButton.image" alt="" />
        </ng-container>
        <!--use a span if icon for quick connect button is within uxd pattern library e.g motif-icon motif-id-card -->
        <ng-template #icon>
          <span [ngClass]="quickButton.icon"></span>
        </ng-template>
        <span class="quick-connect-label" [innerHTML]="quickButton.label"></span>
      </button>
    </div>
  </ng-container>
</div>