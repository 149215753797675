import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OnlineGrievancesRoutingModule } from './online-grievances-routing.module';
import { OnlineGrievancesComponent } from './online-grievances.component';
import { GrievancesFormComponent } from './grievances-form/grievances-form.component';
import { GrievancesFormConfirmationComponent } from './grievances-form-confirmation/grievances-form-confirmation.component';
import { GrievancesFormPreviewComponent } from './grievances-form-preview/grievances-form-preview.component';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { AlertModule } from '@anthem/uxd/alert';

import { RadioModule } from '@anthem/uxd/radio';


@NgModule({
  declarations: [
    OnlineGrievancesComponent,
    GrievancesFormComponent,
    GrievancesFormConfirmationComponent,
    GrievancesFormPreviewComponent
  ],
  imports: [
    CommonModule,
    OnlineGrievancesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CustomValidatorsModule,
    RadioModule,
    AlertModule
  ]
})
export class OnlineGrievancesModule { }
