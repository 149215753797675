import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { PartialErrorMessage } from 'sydmed/src/app/secure/pharmacy/interfaces/PartialErrorMessage';
import { ErrorMessage } from 'sydmed/src/app/secure/pharmacy/interfaces/ErrorMessage';
import { PharmacyPartialErrorCode, PharmacyPartialError } from 'gbd-models';


@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

    public errorMessageMappings: ErrorMessage;

    protected errorMessages$: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>( null );
    public errorMessages: Observable<Array<string>> = this.errorMessages$.asObservable();

    public errorType$: BehaviorSubject<string> = new BehaviorSubject<string>( null );
    public errorType: Observable<string> = this.errorType$.asObservable();

    constructor() { }

    setErrorMessages(errorMessages: Array<string>) {
        this.errorMessages$.next(errorMessages);
    }

    getErrorMessages(){
        return this.errorMessages$;
    }

    /**
     * ==================
     * Maps partial errors and returns error messages
     * ==================
     */
    mapPartialError(partialError: PharmacyPartialError){
        const errorMessages = [];
        this.errorType$.next('partialError');

        if (partialError && partialError.isPartialError) {
            errorMessages.push(this.mapErrorCode(partialError.errorCode));
        } else {
            errorMessages.push(this.mapErrorCode());
        }

        this.setErrorMessages(errorMessages);
    }

    /**
     * ==================
     * Maps error code and returns error messages
     * ==================
     */
    mapErrorCode(errorCode = '') {

        if (errorCode && this.errorMessageMappings.hasOwnProperty(errorCode)) {
            return this.errorMessageMappings[errorCode];
        } else if (errorCode) {
            return (this.errorMessageMappings.GeneralSystemError).concat('{' + errorCode + '}');
        } else {
            return this.errorMessageMappings.GeneralSystemErrorWithoutCode;
        }
    }
    /**
     * ==================
     * Maps the error based on parameters
     * ==================
     */
    mapError(error: ErrorMessage) {
        this.errorType$.next('apiError');
        this.mapErrors(error);
    }

    /**
     * ==================
     * Checks if any error exits and returns the error if so
     * ==================
     */
    hasErrorDescription(error, expectedDescription){
        return error && error.data && error.data.errorCode && error.data.errorCode.description &&
        error.data.errorCode.description.toLowerCase() === expectedDescription.toLowerCase();
    }

    /**
     * ==================
     * Maps error code and returns error messages
     * ==================
     */
    mapErrors(error) {
        const errorMessages = [];
        if (error && error.data && error.data.errorCode) {
            errorMessages.push(this.mapErrorCode(error.data.errorCode.code));
        } else if (error && error.data && error.data.exceptions) {
            error.data.exceptions.forEach(function (exception) {
            errorMessages.push(this.mapErrorCode(exception.code));
        }, this);
        } else if (error && error.status > 0) {
            errorMessages.push(this.mapErrorCode(error.status));
        } else {
            errorMessages.push(this.mapErrorCode());
        }
        this.setErrorMessages(errorMessages);
    }
}
