export enum PrescriptionOrderRoutes {
    MedicalProfile = '/secure/pharmacy/prescriptions/healthcheck',
    SpecialtyQuestionnaire = '/secure/pharmacy/prescriptions/specialtyquestionnaire',
    ShipmentReview = '/secure/pharmacy/prescriptions/review/pbm',
    SpecialtyShipmentReview = '/secure/pharmacy/prescriptions/review/specialty',
    Confirmation = '/secure/pharmacy/prescriptions/confirmation'
}

export enum PrescriptionOrderPages {
    MedicalProfile = 'Medical Profile',
    SpecialtyQuestionnaire = 'Questionnaire',
    ShipmentReview = 'PBM Shipment',
    SpecialtyShipmentReview = 'Specialty Shipment',
    Confirmation = 'Confirmation'
}