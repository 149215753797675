import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { ClaimsContainerComponent } from './claimsContainer/claimsContainerCmp';
import { JsonDataResolverService } from './json-data-resolver.service';

const routes: Routes = [
	{ path: 'secure/claims', component: ClaimsContainerComponent , pathMatch: 'full', resolve: {jsonData: JsonDataResolverService}, canActivate: [AuthGuardService] }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ClaimsRoutingModule { }
