import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaskingService {

  constructor() { }

  showHide(element: HTMLElement, label?: any) {
    if (element.getAttribute('type') === 'password') {
      element.setAttribute('type', 'text');
      element.nextElementSibling.setAttribute('aria-pressed', 'true');
      element.nextElementSibling.setAttribute('aria-label', `mask ${label}`);
      element.nextElementSibling.setAttribute('class', 'fa md ant-info-icon ant-data-security-mask fa-eye')
    } else {
      element.setAttribute('type', 'password');
      element.nextElementSibling.setAttribute('aria-pressed', 'false');
      element.nextElementSibling.setAttribute('aria-label', `unmask ${label}`);
      element.nextElementSibling.setAttribute('class', 'fa md ant-info-icon ant-data-security-mask fa-eye-slash')
    }
  }
}
