import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessOrderComponent } from '@anthem/mbrportal-pharmacy/components/processOrderCmp';
import { ProccessOrderModel } from '@anthem/mbrportal-pharmacy/models/processOrderModel';
import { DeliveryCostService } from '@anthem/mbrportal-pharmacy/services/deliveryCostSvc';
import { OrderPrescriptionService } from '@anthem/mbrportal-pharmacy/services/orderPrescriptionService';
import { updateOrderCostAndShippingService } from '@anthem/mbrportal-pharmacy/services/orderCostAndShippingSvc';
import { ContentHelper, EventHandler, IWindow } from '@anthem/mbrportal/core/public_api';
import { UrlHelper } from '@anthem/mbrportal/http/public_api';
import { AppSession, IAppSession } from '@anthem/mbrportal/main/public_api';
import { ScrollToViewSvc } from '@anthem/mbrportal/shared/public_api';
import { CurrencyUtility, Logger, NavigationHelper } from '@anthem/mbrportal/utility/public_api';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { VendorIntermediateScreenStyle } from '@anthem/mbrportal-pharmacy/interfaces/vendorIntermediateScreenComponentState';
import { AjaxRequest, Features, checkFeatureFlag } from '@anthem/mbrportal/common/public_api';
import { ContactDetails, VendorAddress, VendorDeliveryShippingDetails, VendorDeliverySubmitEventsType } from '@anthem/mbrportal-profile/public_api';
import { ActionEventsOnOrderPreferences } from '@anthem/mbrportal-pharmacy/interfaces/vendorOrderDetails';
import { InsuranceDetails, UpdateOrderPreferences } from '@anthem/mbrportal-pharmacy/interfaces/therapeuticPrescriptionOrder';
import { VendorCouponDetail } from '@anthem/mbrportal-pharmacy/interfaces/vendorCoupon';
import { AhdPrescriptionService } from '@anthem/mbrportal-pharmacy/services/ahdPrescriptionSvc';
import { EstimatedCostModel } from '@anthem/mbrportal-pharmacy/models/estimatedCostModel';

@Component({
  selector: 'app-ahd-review-processing',
  templateUrl: '../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/processOrder.html',
  styleUrls: ['../../../../../../node_modules/@anthem/mbrportal-pharmacy/assets/styles/partials/processOrder.scss','./ahd-review-processing.component.scss'],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }, { provide: OrderPrescriptionService, useClass: ahdPharmacyService }]
})
export class AhdReviewProcessingComponent extends ProcessOrderComponent implements OnInit {
   deliverCostSvc;
   _eventHandler;
  constructor( logger: Logger,
    contentHelper: ContentHelper,
    @Inject(AppSession) appSession: IAppSession,
     activatedRoute: ActivatedRoute,
     orderPrescriptionService: OrderPrescriptionService,
     proccessOrderModel: ProccessOrderModel,
     urlHlpr: UrlHelper,
     currencyUtility: CurrencyUtility,
    @Inject('Window')  window: IWindow,
     eventHandler: EventHandler,
     navigationHelper: NavigationHelper,
     scrollToViewSvc: ScrollToViewSvc,
     deliveryCostService: DeliveryCostService,
     ahdPrescriptionService: AhdPrescriptionService,
     estimatedCostModel: EstimatedCostModel,
     public router: Router,
     jsonSvc: JsonContentService) { 
      super( logger, contentHelper,appSession, activatedRoute, orderPrescriptionService, proccessOrderModel, urlHlpr, currencyUtility, window, eventHandler, navigationHelper, scrollToViewSvc, deliveryCostService, ahdPrescriptionService, estimatedCostModel);
      jsonSvc.getJSON('ahd-review-processing').subscribe(content => this.content = content.ProcessOrderComponent);
      this.deliverCostSvc = deliveryCostService;
      this._eventHandler = eventHandler;
      this._appSession.metaData.firstNm;
     }

     ngOnInit() {
      this.deliveryAddressSummaryState = {
        content: this.content.deliveryAddress,
        patientOrderDeliveryAddressID: ''
      };
      this.isEmulationInd = this._appSession.metaData.emulationInd;
      this.pageHeaderDetails.welcomeMessage = this.content.title;
      let orderIdArray = this.router.url.split('/');
      this.selectedOrderId = orderIdArray[orderIdArray.length-1];;
      this.vendorDeliveryDateState = {
        onPress: (event: string) => {
          this.prescriptionListDetails.orderDetails = updateOrderCostAndShippingService(this.orderDetails, this.deliverCostSvc, event);
        },
        deliveryTimelines: []
      };

      this.getOrderDetailsStatus = AjaxRequest.GET_REQUEST;
      this.getOrderDetails(this.selectedOrderId);
  
      this.vendorIntermediateScreenComponentState = {
        buttons: [
          {
            label: this.content.successScreen.viewOrderDetails,
            style: VendorIntermediateScreenStyle.PRIMARY,
            onPress: this.onIntermediateScreenButtonClick
          },
          {
            label: this.content.successScreen.pharmacyHome,
            style: VendorIntermediateScreenStyle.SECONDARY,
            onPress: this.onIntermediateScreenButtonClick
          }
        ],
        content: {
          icon: this.content.successScreen.successIcon,
          text: this.content.successScreen.success,
          subtext: this.content.successScreen.subtext
        },
        tracker: this.content.successScreen.trackingSteps,
        currentStep: 0
      };
      this.showCouponDetails = checkFeatureFlag(this._appSession, Features.AHD_COUPONS);
      this.showSaveForLater = this._appSession.metaData?.features?.includes(Features.AHD_SAVE_FOR_LATER);

    }


}
