<!-- header banner component -->
<div class="ant-top-wrapper no-cobrand {{brandClass}}">
	<div class="container">
		<div class="ant-top-nav">
			<ng-container *ngIf="isPublicSite; else secureBlock">
				<a [href]="brandRedirect">
					<img class="header-logo {{brandClass}}" src="{{brandLogo}}" title="{{brandAlt}}">
				</a>
			</ng-container>
			<ng-template #secureBlock>
				<a routerLink="{{brandRedirect}}">
					<img class="header-logo {{brandClass}}" src="{{brandLogo}}" title="{{brandAlt}}">
				</a>
			</ng-template>
			<i #menuButton id="menuButton" role="button" aria-label="Menu" tabindex="0"
				class="fas fa-bars hidden-lg hidden-md hidden-sm"></i>
			<button *ngIf="isLanguageChangeRestricted" id="language-toggle" class="btn btn-secondary toggle-btn"
				(click)="languageToggle()" (keyup.enter)="languageToggle()" tabindex="0">{{jsonLabels?.Language}}</button>
		</div>
	</div>
</div>
<!-- end header banner component -->