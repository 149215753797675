import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { forkJoin } from 'rxjs';
import { MyProfileDataService } from '../../shm-my-profile/my-profile-data.service';
import { ErrorMsgs, Loaders } from '../../shm-my-profile/models/labels';
import { NavPages } from '../../shm-my-profile/models/enum-values';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { CurrencyUtility, DateUtility, Logger, NavigationHelper } from '@anthem/mbrportal/utility';
import { ContentHelper, EventHandler, IWindow } from '@anthem/mbrportal/core';
import { PharmacyProfileAddressSvc } from '@anthem/mbrportal-profile/services/pharmacyProfileAddressSvc';
import { ProfileHelper, formatAddress } from '@anthem/mbrportal-profile/services/profileHlpr';
import { AppEvents, AppSession } from "@anthem/mbrportal/main";
import { DeliveryAddress } from '@sydney/models';
import { AjaxRequest, PlanIndicator } from '@anthem/mbrportal/common'; 
import { ScrollToViewSvc, SidePanelComponent, WcsContentFormatter } from '@anthem/mbrportal/shared';
import { VendorAllergyService, VendorDeliverySubmitEventsType } from '@anthem/mbrportal-profile';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { VendorPrescriptionReviewComponent } from '@anthem/mbrportal-pharmacy/components/vendorPrescriptionReviewCmp';
import { AlertHelper } from '@anthem/uxd/alert';
import { PrescriptionSubscriptionService } from '@anthem/mbrportal-pharmacy/services/prescriptionSubscriptionSvc';
import { PrescriptionDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/prescriptionDataSvcv2';
import { PlaceOrderModelV2 } from '@anthem/mbrportal-pharmacy/models/placeOrderModelV2';
import { ValidationModelV2 } from '@anthem/mbrportal-pharmacy/models/validationModelV2';
import { AhdPrescriptionService } from '@anthem/mbrportal-pharmacy/services/ahdPrescriptionSvc';
import { OrderDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/orderDataSvcV2';
import { VendorPrescriptionReviewModel } from '@anthem/mbrportal-pharmacy/models/vendorPrescriptionReviewModel';
import { VendorCouponDetail } from '@anthem/mbrportal-pharmacy/interfaces/vendorCoupon';
import { IBannerMemberDetails } from '@anthem/mbrportal-pharmacy/interfaces/iPrescriptionv2';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { VendorPaymentWalletService } from '@anthem/mbrportal-pharmacy/services/vendorPaymentWalletSvc';
import { AHDPaymentService } from '../services/ahd-payment.service';
import { AhdDeliveryAddressesService } from '../services/ahd-delivery-addresses.service';
import { CommunicationPreferencesSvc } from '@anthem/mbrportal-profile/services/communicationPreferencesSvc';
import { ShippingAddressDetails } from '@anthem/mbrportal-pharmacy/interfaces/therapeuticPrescriptionOrder';
import { VendorPaymentModel } from '@anthem/mbrportal-pharmacy/models/vendorPaymentModel';
import { OrderPrescriptionService } from '@anthem/mbrportal-pharmacy/services/orderPrescriptionService';
import { VendorManageAutoRefillService } from '@anthem/mbrportal-pharmacy/services/vendorManageAutoRefillSvc';
import { VirtualSpecialtyQuestionnaireService } from '@anthem/mbrportal-pharmacy/services/virtualSpecialtyQuestionsSvc';
import { VirtualSpecialtyQuestionnaireModel } from '@anthem/mbrportal-pharmacy/models/virtualSpecialtyQuestionnaireModel';
import { ProfileAddressValidationService } from '@anthem/mbrportal-pharmacy/services/profileAddressValidationSvc';
import { RxShippingAddressModel } from '@anthem/mbrportal-pharmacy/models/shippingAddressModel';
import { SearchProviderSvc } from '@anthem/mbrportal-search-provider/services/searchProviderSvc';
import { AHDFeatures } from '../models/ahd-landing-page.model';
import { CvsPharmacyService } from '@anthem/mbrportal-pharmacy/services/cvsPharmacySvc';
import { AhdCvsPharmacyService } from '../services/ahd-cvs-pharmacy.service';
import { QuestionnaireModelV2 } from '@anthem/mbrportal-pharmacy/models/questionnaireModelV2';
import { ChatbotService } from '../../../sydmed-shared/chatbot-service/chatbot.service';
import { NavigationStart, Router } from '@angular/router';
import { VendorPaymentWalletSharedService } from '@anthem/mbrportal-pharmacy/services/vendorPaymentWalletSharedSvc';
import { Features } from '@anthem/mbrportal/common';


import { TabNavLinks } from '@anthem/mbrportal-pharmacy/enums/navLinks';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
@Component({
  selector: 'app-ahd-review-order',
  templateUrl: `../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/vendorPrescriptionReview.html`,
  styleUrls: ['../../../../../../node_modules/@anthem/mbrportal-pharmacy/assets/styles/partials/vendorAHDReviewRefill.scss'],
  providers: [ ProfileHelper, PharmacyProfileAddressSvc, VendorPrescriptionReviewModel, VendorPaymentModel, VendorPaymentWalletSharedService,
    RxShippingAddressModel, SearchProviderSvc, CvsPharmacyService, AhdCvsPharmacyService, QuestionnaireModelV2,
    { provide: ContentHelper, useClass: AHDContentHelper },
    { provide: VendorPaymentWalletService, useClass: AHDPaymentService },
    { provide: PharmacyProfileAddressSvc, useClass: AhdDeliveryAddressesService },
    { provide: AhdPrescriptionService, useClass: ahdPharmacyService },
    { provide: CommunicationPreferencesSvc, useClass: ahdPharmacyService },
    { provide: VendorAllergyService, useClass: ahdPharmacyService },
    { provide: CvsPharmacyService, useClass: AhdCvsPharmacyService},
    { provide: SearchProviderSvc, useClass: AhdCvsPharmacyService },
    { provide: OrderPrescriptionService, useClass: AhdCvsPharmacyService }
  ]
})
export class AHDReviewOrderComponent extends VendorPrescriptionReviewComponent implements OnInit {

  @Input() errorMsgs: ErrorMsgs;
  @Input() labels: any;
  @Input() loaderLabels: Loaders;

  loading: boolean = false;
  labelsLoading: boolean = true;
  pbmRefillRenewPrescripitonList: IBannerMemberDetails[];

  sidePanelCmp: SidePanelComponent;
  outstandingAPIRequest: AjaxRequest;
  
  constructor(
    private router: Router,
    _logger: Logger,
    contentHelper: ContentHelper,
    appSession: AppSession,
    vendorPrescriptionReviewModel: VendorPrescriptionReviewModel,
    alertHelper: AlertHelper,
    private navigationHelper: NavigationHelper,
    prescriptionColdPackUpdateSvc: PrescriptionSubscriptionService,
    _prescriptionDataService: PrescriptionDataServiceV2,
    placeOrderModel: PlaceOrderModelV2,
    orderDataService: OrderDataServiceV2,
    scrollToView: ScrollToViewSvc,
    validationModel: ValidationModelV2,
    ahdPrescriptionService: AhdPrescriptionService,
    dateUtil: DateUtility,
    wcsFormatter: WcsContentFormatter,
    currencyUtility: CurrencyUtility,
    orderPharmacyService: OrderPrescriptionService,
    vendorManageAutoRefillSvc: VendorManageAutoRefillService,
    virtualSpecialtyQuestionnaireService: VirtualSpecialtyQuestionnaireService,
    virtualSpecialtyModel: VirtualSpecialtyQuestionnaireModel,
    eventHandler: EventHandler,
    _communicationPreferencesSvc: CommunicationPreferencesSvc,
    vendorPaymentModel: VendorPaymentModel,
    private chatBotSvc: ChatbotService,
    renderer: Renderer2,
    @Inject(DOCUMENT) document: Document,
    @Inject('Window') window: IWindow,
    protected titleService: InterCommunicationService,
  ) {
    super(_logger, contentHelper, appSession, vendorPrescriptionReviewModel, eventHandler, alertHelper, navigationHelper, prescriptionColdPackUpdateSvc, _prescriptionDataService, placeOrderModel, orderDataService, scrollToView, validationModel, ahdPrescriptionService, dateUtil, currencyUtility, wcsFormatter, orderPharmacyService, vendorManageAutoRefillSvc, virtualSpecialtyQuestionnaireService, virtualSpecialtyModel, vendorPaymentModel, renderer, document, window);
    appSession.timeStamp = new Date();
    appSession.metaData.features.push(PlanIndicator.HAS_RX);
    appSession.metaData.features.push(Features.AHD_COPAY_DISCLAIMER);
    // NOTE:this will be removed once delivery address home page error resolved
    // this.ahdDeliveryAddressesService.getDeliveryAddress(); 
    // this.deliveryAddressSummaryState = {
    //   content: this.content.deliveryAddress,
    //   isEditMode: true
    // };
   
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.slideOutPanelClose();
    }
  });
    this.pbmPrescriptionData = _prescriptionDataService.getPBMPrescriptionData();
    this.splPrescriptionData = _prescriptionDataService.getSPLPrescriptionData();

    // Needs refactoring for this as well

    this.pbmDeliveryAddressSubscription = eventHandler.get(VendorDeliverySubmitEventsType.VENDOR_DELIVERY_ADDRESS_PBM).subscribe((address: DeliveryAddress) => {
      if (address) {
        this.deliveryAddressId = address.addressId ?? '';
        this.pbmDeliveryAddress = formatAddress(address);
        this.deliveryAddress = {
          address1: address.streetAddress1,
          city: address.city,
          state: address.state.code,
          zipCode: address.zipCode
        };
      }
    });
    this.vendorCouponsSubscription = eventHandler.get(VendorDeliverySubmitEventsType.VENDOR_COUPONS).subscribe((coupon: VendorCouponDetail) => {
      if (coupon?.couponDetails?.length) {
        this.couponDetails = coupon.couponDetails;
      }
    });


    eventHandler.get(AppEvents[AppEvents.CHAT]).subscribe(() => {
      this.chatBotSvc.setChatOption({
        topic: this.labels.pageHeader.chatWithPharmacistTopic
      });
      sessionStorage.setItem('selectedChatTopic', this.labels.pageHeader.chatWithPharmacistTopic);
      this.chatBotSvc.launchChatBot();
    });
  }

  ngOnInit(): void {
    this._appSession.metaData.features.push(Features.AHD_STANDARD_REFILL_ESTIMATED_COST);
    this.content = this.labels;
    this.vendorPaymentWalletState = {
      content: this.content.vendorPaymentWallet,
      defaultPaymentSelection: false,
      restrictions: {
        bankAccount: true,
        defaultPaymentMethodSelection: true,
        deleteSlider: true
      }
    };

    this.deliveryAddressSummaryState = {
      content: this.content.deliveryAddress,
      patientOrderDeliveryAddressID: '',
      addressRestriction: {
        isPoBox: this.vendorPrescriptionReviewModel.widgetState.prescriptions.prescriptionsList.some((prescription) => prescription.drug.coldPack)
      }
    };
    super.ngOnInit();
  }

  /**
   * addMorePrescriptionsToCart
   * @description
   * navigate to pharmacy view prescriptions
   */
  addMorePrescriptionsToCart() {
    super.addMorePrescriptionsToCart();
    this.navigationHelper.navigateTo('/ahd-pharmacy/allprescriptions');
  }
  onCancelOrder() {
    super.onCancelOrder();
    this.navigationHelper.navigateTo('/ahd-pharmacy/allprescriptions');
  }
  goToPharmacyHomeButtonClick(button: string) {
    if (button === this.content.button.viewAllPrescriptions) {
      this.navigationHelper.navigateTo('/ahd-pharmacy/allprescriptions');
    } else {
      this.navigationHelper.navigateTo('/ahd-pharmacy/dashboard');
    }
  }
  slideOutPanelClose(){
    this.shippingAddressSidePanel.close();
  }

  updateBannerData(event: unknown): void {
    super.updateBannerData(event);
    const headers = this.getHeadersFromActiveTab(this.activeTabName);
    this.titleService.raiseEvent({ message: headers.mainHeader, title: 'HEADER_TITLE' });
    this.pageHeaderDetails = {
      ...this.pageHeaderDetails,
      welcomeMessage: headers.subHeader
    }
  }

  getHeadersFromActiveTab(activeTab: string) {
    switch(activeTab) {
      case TabNavLinks.REVIEW_CART:
        return {
          mainHeader: this.labels.mainHeaders.refills,
          subHeader: ''
        }
      case TabNavLinks.REVIEW_PHARMACY:
        return {
          mainHeader: this.labels.mainHeaders.refills,
          subHeader: this.labels.subHeaders.reviewPbm
        }
      case TabNavLinks.REVIEW_SPECIALITY:
        return {
          mainHeader: this.labels.mainHeader.refills,
          subHeader: this.labels.subHeaders.reviewSpecialty
        }
      case TabNavLinks.REVIEW_SUBMIT:
        return {
          mainHeader: this.labels.mainHeaders.orderSubmitted,
          subHeader: this.labels.subHeaders.orderSubmitted
        }
      default:
        return {
          mainHeader: this.labels.pageTitle,
          subHeader: ''
        }
    }   
  }

  onAddressSaveClick(shippingAddress: ShippingAddressDetails) {
    super.onAddressSaveClick(shippingAddress);
    if (this.isSpecialityPrescription) {
      this.specialityDeliveryAddressState = {
        ...this.specialityDeliveryAddressState,
        rows: [{
          field: this.defaultShippingAddress.address.address1,
          label: '',
        }]
      }
    }
  }
}
