import { AppConfig, AppSession, MetaDataHelper } from "@anthem/mbrportal/main";
import { SsoModule } from "@anthem/mbrportal/sso";
import { Http2Module, HttpClient2, FetchBackend, HttpCacheHandler, UrlHelper, HttpRequestUtil } from "@anthem/mbrportal/http";
import { FetchClient } from "@anthem/mbrportal/http/services/fetchClient";
import { CoreModule, WCS_ATTRIBUTES, ContentHelper, Cache, ComponentHelper, CookieReader, CookieWriter, EventHandler, PageHasChangeListner, LocalStorage, AttributeHelper, ElementHelper } from "@anthem/mbrportal/core";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { TabsModuleV2, TabsModule } from "@anthem/uxd/tabs";
import { NavigationModule } from "@anthem/uxd/navigation";
import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { SharedModule, DataHelper, WindowEventHandler, WcsApi, LabelService, ScriptLoader } from "@anthem/mbrportal/shared";
import {
  UtilityModule,
  NavigationHelper,
  CookieHelper,
  Logger,
  LogApi,
  AuditApi,
  AuditHelper,
  LogHelper,
  DateUtility,
  ArrayUtility,
  CurrencyUtility,
  DTM_UTILITY_INJECT_TOKEN
} from "@anthem/mbrportal/utility";
import { AlertModule, AlertHelper } from "@anthem/uxd/alert";
import { DOCUMENT } from "@angular/common";
import { TcpAppConfig } from "./appConfig";
import { TcpAppSession } from "./appSession";
import { DTMUtilityTcp } from "./dtmUtility";
import { UxModalHelper } from "@anthem/uxd/deprecated";
import { Modal, SidePanel } from "@anthem/uxd/modal";
import { Overlay } from "@angular/cdk/overlay";
import { Overlay2 } from "./uxd.overlay";
import { UxHelper } from "@anthem/uxd/util";
import { NavigationHelper2 } from "./navHlpr";
import { AppRequestInterceptor } from "./app-request.service";
import { AppInitializer } from "./appInit";

export function useFactory(dep: any) {
  return dep;
}

export function getWcsAttributes() {
  return {};
}

export function appInitOp(appInitializer: AppInitializer) {
  return () => {
    return appInitializer.init();
  };
}

export function getDocument() {
  return document;
}

export function getWindow() {
  return window;
}

export const NOT_CLOSABLE_MODALS: Array<string> = [];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    Http2Module,
    SharedModule,
    UtilityModule,
    SsoModule,
    AlertModule,
    TabsModule,
    TabsModuleV2,
    NavigationModule,
  ],
  providers: [
    {
      provide: DOCUMENT,
      useFactory: getDocument,
    },
    {
      provide: "Window",
      useFactory: getWindow,
    },
    {
      provide: "NOT_CLOSABLE_MODALS",
      useValue: NOT_CLOSABLE_MODALS,
    },
    TcpAppConfig,
    TcpAppSession,
    DTMUtilityTcp,
    {
      provide: DTM_UTILITY_INJECT_TOKEN,
      deps: [DTMUtilityTcp],
      useFactory: useFactory
    },
    {
      provide: AppConfig,
      deps: [TcpAppConfig],
      useFactory: useFactory,
    },
    {
      provide: AppSession,
      deps: [TcpAppSession],
      useFactory: useFactory,
    },
    MetaDataHelper,
    AlertHelper,
    UxModalHelper,
    Modal,
    {
      provide: Overlay,
      deps: [Overlay2],
      useFactory: useFactory,
    },
    SidePanel,
    {
      provide: "UXD_SIDEPANEL",
      deps: [SidePanel],
      useFactory: useFactory,
    },
    {
      provide: "UXD_MODAL",
      deps: [Modal],
      useFactory: useFactory,
    },
    UxHelper,
    {
      provide: WCS_ATTRIBUTES,
      useFactory: getWcsAttributes,
    },
    NavigationHelper2,
    {
      provide: NavigationHelper,
      deps: [NavigationHelper2],
      useFactory: useFactory,
    },
    AppRequestInterceptor,
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppInitializer],
      useFactory: appInitOp,
    },
    DataHelper,
    HttpClient2,
    FetchClient,
    FetchBackend,
    ContentHelper,
    HttpCacheHandler,
    UrlHelper,
    Cache,
    HttpRequestUtil,
    ComponentHelper,
    WindowEventHandler,
    CookieHelper,
    CookieReader,
    CookieWriter,
    EventHandler,
    PageHasChangeListner,
    Logger,
    LogApi,
    AuditApi,
    AuditHelper,
    LogHelper,
    WcsApi,
    LocalStorage,
    AttributeHelper,
    DateUtility,
    DatePipe,
    ElementHelper,
    ArrayUtility,
    LabelService,
    ScriptLoader,
    CurrencyUtility,
    CurrencyPipe
  ],
})
export class MbrportalRootModule {}
