<div id="pcp-container" class="container">
    <div *ngIf="content; else loadingOrError">
        <h2 *ngIf="!isAssignPCP" class="section-header-two" [innerHTML]="content.header" id="pcp-header" tabindex="0"
            role="heading" aria-live="polite">
        </h2>
        <!-- PCP header end -->

        <!-- PCP sub header start -->
        <p *ngIf="!isAssignPCP" id="pcp-sub-header">
          <span [innerHTML]="content.subHeader" [attr.aria-label]="content.subHeader" tabindex="0"></span>
          <span *ngIf="showPhone" class="mobile" [innerHTML]="content.disableChangePcpPhoneMobile"></span>
          <span *ngIf="showPhone" class="desktop" [innerHTML]="content.disableChangePcpPhoneDesktop"></span>
        </p>
        <!-- PCP sub header end -->

        <!-- pcp loading progress display start -->
        <div class="pcp-loader-spinner" *ngIf="pcpLoader" id="pcp-loading-message">
            <uxd-spinner></uxd-spinner>
        </div>
        <!-- pcp loading progress display end -->

        <div class="cards-wrapper" *ngIf="!pcpLoader">
            <div [ngClass]="isAssignPCP? '' : 'pcp-large-card card card-flat card-popover'">

                <!-- view PCP label start -->
                <h3 class="pcp-section-header-three" [innerHTML]="content.currentPcpLabel" id="pcp-detail-label"
                    tabindex="-1">
                </h3>
                <!-- view PCP label end -->

                <!-- PCP error start -->
                <div *ngIf="pcpErrorMessage" class="pcp-warn-error" role="alert" aria-atomic="true">
                    <div class="inline-alert-grid-container">
                      <span class="a-icon motif-icon motif-error"></span>
                      <p class="a-message" id="pcp-load-error" [innerHTML]="pcpErrorMessage"></p>
                    </div>
                </div>
                <!-- PCP error end -->

                <!-- PCP section start -->
                <div *ngIf="!pcpErrorMessage" tabindex="0"
                    [attr.aria-label]="content.currentPcpLabel + ' ' + pcpInfo.name + ' ' + pcpInfo.ariaAddress" class="pcp-contact-info">

                    <!-- PCP - Name -->
                    <div [id]="'pcp-name'" [innerHTML]="pcpInfo.name"></div>

                    <!-- PCP - Address -->
                    <div [id]="'pcp-address'" [innerHTML]="pcpInfo.address"></div>

                    <!-- PCP - Phone number -->
                    <section *ngIf="pcpInfo.phoneNumber" class="pcp-phone">
                        <span aria-hidden="true" class="motif-icon motif-phone"></span>
                        <a href="tel:{{pcpInfo.phoneNumber}}" class="pcp-phn-link mobile" 
                        [attr.aria-label]="content.phoneNumberAria + ' ' + pcpInfo.phoneNumber"
                        [id]="'pcp-phone-number-link'" [attr.data-analytics]="content.analytics.phoneNumberLink">                                                    
                            <span [id]="'pcp-phone-number'" [innerText]="pcpInfo.phoneNumber"></span>
                        </a>
                        <span class="pcp-phn-link-na desktop" [id]="'pcp-phone-number'" [innerText]="pcpInfo.phoneNumber"></span>
                    </section>

                </div>
                <!-- PCP section end -->

                <!-- Add/Update buttons start -->
                <div *ngIf="!isAssignPCP && enableChangePcp &&!isLockedIn" class="row ant-margin-top-20">
                    <div class="col-xxs-12 col-xs-12">
                        <button class="btn btn-primary" id="pcp-add-change-button"
                            [attr.aria-label]="content.addOrChangePcpButtonAria"
                            [innerText]="content.addOrChangePcpButton" (click)="onAddUpdatePCP()"
                            [attr.data-analytics]="content.analytics.addChangeAssignButton"></button>
                    </div>
                </div>
                <!-- Add/Update buttons end -->
                <p *ngIf="!enableChangePcp && !isLockedIn" [attr.aria-label]="content.disableChangePcpMessage + content.disableChangePcpPhoneDesktop">
                    <span [innerHTML]="content.disableChangePcpMessage"></span>
                    <span class="mobile" [innerHTML]="content.disableChangePcpPhoneMobile"></span>
                    <span class="desktop" [innerHTML]="content.disableChangePcpPhoneDesktop"></span>
                </p>
                <p *ngIf="isLockedIn" [attr.aria-label]="content.disableChangePcpMessageForLockedInUser">
                    <span [innerHTML]="content.disableChangePcpMessageForLockedInUser"></span>
                </p>

            </div>
        </div>

    </div>

    <ng-template #loadingOrError>
        <div *ngIf="genericError; else loading" class="container" data-uxd-alert-cmp-v2
            [alert]="{alertType: 'negetive'}">
            <span id="pcp-authorization-error" [innerHTML]="genericError"></span>
        </div>
        <ng-template #loading>
            <div id="pcp-content-loader-spinner" data-uxd-data-loader-cmp="" class="container">
                <span [innerHTML]="'Loading... please wait.'"></span>
            </div>
        </ng-template>
    </ng-template>
</div>