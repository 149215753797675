import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareCoordinatorComponent } from './care-coordinator/careCoordinatorCmp';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
	{ path: 'secure/care-team', component: CareCoordinatorComponent , pathMatch: 'full', canActivate: [AuthGuardService] } 
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CareTeamRoutingModule { }