import { Directive, forwardRef, Input } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";

const EMAIL_REGEX = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,63}(?:\.[a-zA-Z]{2})?)$/i;

/**
 *  Email input field validator
 *  set validEmail error on form field when invalid email format is entered.
 *
 *  ###Example
 *
 *  ```html
 *  <form>
 *      <input type="text" name="txtEmail" sydmed-multiple-email-valid-dir/>
 *      <span *ngIf="txtEmail.errors.validEmail">invlaid email format entered</span>
 *  </form>
 *  ```
 */

@Directive({
  selector: "[sydmed-multiple-email-valid-dir]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidatorDirective),
      multi: true,
    },
  ],
})
export class EmailValidatorDirective implements Validator {
  @Input() maxNumOfEmails: number = 0;

  validate(emailInputText: FormControl) {
    if (
      (emailInputText.errors &&
        !emailInputText.errors["validEmail"] &&
        !emailInputText.errors["maxEmailExceeded"] &&
        !emailInputText.errors["required"]) ||
      !emailInputText.value
    ) {
      return null;
    }

    return this.validateEmailAddresses(emailInputText);
  }

  protected validateEmailAddresses(emailInputText: FormControl): any {
    let emails = emailInputText.value.split(",");
    let fail: boolean = false;
    if (this.maxNumOfEmails !== 0 && emails.length > this.maxNumOfEmails) {
      return {
        maxEmailExceeded: true,
      };
    }
    if (emails.length > 1) {
      for (let i = 0; i < emails.length; i++) {
        emails[i] = emails[i].trim();
        if (!EMAIL_REGEX.test(emails[i])) {
          fail = true;
          break;
        }
      }
      return !fail ? null : { validEmail: true };
    } else {
      return EMAIL_REGEX.test(emailInputText.value.trim()) ? null : { validEmail: true };
    }
  }
}
