<div id="printIcon" *ngIf="numberOfItems > 0; else disablePrint">
    <a class="ant-action-link" href="javascript:void(0)" (click)="print()" >
        <span class="motif-icon motif-print"></span>
        <span class="ant-link-text">{{label}}</span>
    </a>
</div>
<ng-template #disablePrint>
    <div id="printIcon">
        <span class="motif-icon motif-print"></span>
        <span class="ant-link-text">{{label}}</span>
    </div>
</ng-template>