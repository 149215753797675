import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AccordianModule } from "@anthem/uxd/accordion";
import { TabsModule } from "@anthem/uxd/tabs";
import { DropdownModule } from "@anthem/uxd/dropdowns";
import { IndicatorModule } from "@anthem/uxd/indicators";
import { AlertModule } from "@anthem/uxd/alert";
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { AddCreditPaymentComponent } from "./add-credit-payment/add-credit-payment.component";
import { AddCheckingPaymentComponent } from "./add-checking-payment/add-checking-payment.component";
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';

@NgModule({
  declarations: [
    AddCreditPaymentComponent,
    AddCheckingPaymentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordianModule,
    TabsModule,
    DropdownModule,
    AlertModule,
    IndicatorModule,
    CheckboxModule,
    CustomValidatorsModule
  ],
  exports: [
    AddCreditPaymentComponent,
    AddCheckingPaymentComponent
  ]
})
export class PaymentMethodsModule {}
