import { PCPModule } from './../pcp/pcpModule';
import { AlertModule } from '@anthem/uxd/alert';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { CareCoordinatorComponent } from './care-coordinator/careCoordinatorCmp';
import { CareTeamRoutingModule } from './care-team-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from '@anthem/uxd/chart';
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { ModalModule } from '@anthem/uxd/modal';
import { RadioModule } from '@anthem/uxd/radio';
import { UxDeprecatedModule } from '@anthem/uxd/deprecated';
import { InputModule } from '@anthem/uxd/input';
import { SecureSsoModule } from '../sso/sso.module';

@NgModule( {
  declarations: [
    CareCoordinatorComponent
  ],
  imports: [
    AlertModule,
    CareTeamRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ChartModule,
    CheckboxModule,
    DropdownModule,
    IndicatorModule,
    ModalModule,
    RadioModule,
    UxDeprecatedModule,
    InputModule,
    SecureSsoModule,
    PCPModule
  ], 
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    JsonContentService
  ],
  bootstrap: [ CareCoordinatorComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
} )
export class CareTeamModule { }
