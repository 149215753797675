<ng-container *ngIf="!hasPartialPharmacy; else showPartialPharmacy">
  <div *ngIf="!isMini" class="container pharmacy">
  <app-header-nav [headerNav]="headerNav" [isCartDisplay]="isCartDisplay"></app-header-nav>
      
  <div class="inline-alert-grid-container" *ngIf="showAdvancedHomeDeliveryAlert">
    <span class="a-icon motif-icon motif-info"></span>
    <p class="a-message">{{alerts?.AdvancedHomeDelivery?.Label}}</p>
    <span class="a-close motif-icon motif-delete" (click)="closeAlert(alertName.AdvancedHomeDelivery)"></span>
  </div>
  <div class="inline-alert-grid-container" *ngIf="alerts?.CaliforniaPharmacyContact && showCaliforniaPharmacyAlert">
    <span class="a-icon motif-icon motif-info"></span>
    <p class="a-message">{{alerts?.CaliforniaPharmacyContact?.Label}} <a *ngIf="alerts?.CaliforniaPharmacyContact?.Link" [href]="alerts?.CaliforniaPharmacyContact?.Link" target="_blank" rel="noopener noreferrer">{{alerts?.CaliforniaPharmacyContact?.LinkLabel}}</a><span class="desktop">{{alerts?.CaliforniaPharmacyContact?.AfterLinkLabel}}</span><span class="mobile" [innerHTML]="alerts?.CaliforniaPharmacyContact?.AfterLinkLabelMobile"></span></p>
    <span class="a-close motif-icon motif-delete" (click)="closeAlert(alertName.CaliforniaPharmacyContact)"></span>
  </div>
  <div *ngIf="showCommPrefAlert && hasCommPreferences" id="commPrefs" class="container row">
    <app-dismissible-alert
    [message]="alerts?.CommPreferences?.Label" 
    [linkLabel]="alerts?.CommPreferences?.LinkLabel"
    [link]="alerts?.CommPreferences?.Link"
    [ariaLabel]="alerts?.CommPreferences?.AriaLabel"
    [alertName]="alertName.CommPreferences"
    (closeAlert)="closeAlert($event)"
    ></app-dismissible-alert>
  </div>

  <div *ngIf="showEmailAlert && hasCommPreferences" id="email" class="container row">
    <app-dismissible-alert
    [message]="alerts?.Email?.Label" 
    [linkLabel]="alerts?.Email?.LinkLabel"
    [link]="alerts?.Email?.Link"
    [ariaLabel]="alerts?.Email?.AriaLabel"
    [alertName]="alertName.Email"
    (closeAlert)="closeAlert($event)"
    ></app-dismissible-alert> 
  </div>

  <div *ngIf="hasPbm && showMedProfileAlert && hasAccountDetails" id="medProfile" class="container row">
    <app-dismissible-alert
      [message]="alerts?.MedicalProfile?.Label"
      [ariaLabel]="alerts?.MedicalProfile?.AriaLabel"
      [alertName]="alertName.MedicalProfile"
      [alertType]="alertType.INFO"
      [linkLabel]="alerts?.MedicalProfile?.LinkLabel"
      [link]="alerts?.MedicalProfile?.Link"
      (closeAlert)="closeAlert($event)"
    ></app-dismissible-alert>
  </div>

  <div *ngIf="content && hasRxPreAuthAlerts" id="rxPreAuth" class="container row">
    <ng-container *ngFor="let rxItem of rxPreAuthList">
      <app-prescription-pre-auth-alert [content]="content.RxPreAuthContent" [rxName]="rxItem.prescriptionName" [rxStatus]="rxStatuses[rxItem.status]"></app-prescription-pre-auth-alert>
    </ng-container>
  </div>

  <div class="pharmacy-banner" *ngIf="showZipDrugBanner">
    <div class="banner-content">
      <h4>{{ zipDrugBanner.Header }}</h4>
      <p>{{ zipDrugBanner.Message }}</p>
      <a href="{{ zipDrugBanner.FAQLink }}" class="ant-action-link">
        <span class="motif-icon motif-right-round">
          <p>Learn more</p>
        </span>
      </a>
    </div>
    <div class="banner-enroll">
      <a href="{{ zipDrugBanner.EnrollmentLink }}"><button class="btn btn-primary">Enroll Now</button></a>
    </div>
  </div>

  <div class="flex-grid">
    <div class="col mr-27 mb-49" *ngIf="content">
      <app-order-status
        [headingLabel]="headerBlockLeft.Heading"
        [textLabel]="headerBlockLeft.Text"
        [linkText]="headerBlockLeft.LinkText"
        [link]="headerBlockLeft.Link"
        [analytics]="headerBlockLeft.AnalyticsTag"
      >
      </app-order-status>
    </div>
    <div class="col mb-49" *ngIf="content">
      <app-view-prescriptions
        [headingLabel]="headerBlockMiddle.Heading"
        [textLabel]="headerBlockMiddle.Text"
        [linkText]="headerBlockMiddle.LinkText"
        [link]="headerBlockMiddle.Link"
        [analytics]="headerBlockMiddle.AnalyticsTag"
        >
      </app-view-prescriptions>
    </div>
    <div class="col ml-27" *ngIf="content">
      <app-manage-profile-block
        [headingLabel]="headerBlockRight.Heading"
        [textLabel]="headerBlockRight.Text"
        [linkText]="headerBlockRight.LinkText"
        [link]="headerBlockRight.Link"
        [analytics]="headerBlockRight.AnalyticsTag"
        [profileRestriction]="hasProfileRestriction"
        >
      </app-manage-profile-block>
    </div> 
  </div>
  <ng-template #LoadingOrError>
    <div *ngIf="isError; else Loading" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
      <span [innerHTML]="serviceError"></span>
    </div>
    <ng-template #Loading>
      <div>
        <uxd-spinner></uxd-spinner>
      </div>
        </ng-template>
      </ng-template>
  
      <ng-container *ngIf="prescriptions && listLabels && !loadingSpinner; else LoadingOrError">
        <app-prescriptions-list [labels]="listLabels" [prescriptions]="prescriptions" [isActionable]="true"
        [isAccordion]="false" [buttonLabel]="buttonLabel"[isPharmacyLanding]="isPharmacyLanding" [analytics]="analytics"
        [listHeaderContent]="listHeaderContent" [listSummaryContent]="listSummaryContent" [noPrescriptions]="zeroPrescriptionsContent" [buttons]="buttons">
        </app-prescriptions-list>
      </ng-container>
  
      <ng-container *ngIf="includeMarket">
        <app-prescription-tiles [ptContent]="ptContent"></app-prescription-tiles>
      </ng-container>
  
      <div class="row" *ngIf="!loadingSpinner && !balanceError && pabEnabled && pabContent">
        <app-account-balance
        [pabContent]="pabContent"
        [errors]="errors"
        [hasPBM]="hasPbm"
        [pbmBalance]="pbmBalance"
        [specialtyBalance]="specialtyBalance"
        ></app-account-balance>
      </div>
      <div *ngIf="balanceError && pabEnabled && pabContent">
        <app-account-balance
        [pabContent]="pabContent"
        [errors]="errors"
        [balanceError]="balanceError"
        ></app-account-balance>
      </div>
  
      <div class="row motif-margin-top-15" *ngIf="footerContent">
        <app-footer-wrapper [content]="footerContent" [restrictions]="restrictions"></app-footer-wrapper>
      </div>
    </div>
  
    <div *ngIf="isMini">
      <h2 class="prescription-heading section-header-two">
        <span>{{ miniListHeaderContent }}</span>
      </h2>
      <div *ngIf="prescriptions && listLabels && !loadingSpinner; else LoadingOrError">
        <app-prescriptions-list
        [analytics]="analytics"
        [buttons]="buttons"
        [isAccordion]="false"
        [isCheckoutFlow]="true"
         [isMini]="isMini"
        [labels]="listLabels"
        [listSummaryContent]="miniListSummaryContent"
        [noPrescriptions]="miniZeroPrescriptionContent"
        [viewAllPrescriptionLink]="viewAllPrescriptionContent"
        [hasviewAllPrescriptionLink]="hasviewAllPrescriptionLinkContent"
        [prescriptions]="prescriptions">
        </app-prescriptions-list>
      </div>
      <ng-template #LoadingOrError>
        <div *ngIf="isError; else Loading" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
          <span [innerHTML]="serviceError"></span>
        </div>
        <ng-template #Loading>
          <div>
            <uxd-spinner></uxd-spinner>
          </div>
        </ng-template>
      </ng-template>
      <div class="row">
        <div *ngIf="buttons" class="col text-center">
          <button class="btn btn-secondary mini-buttons" data-analytics="managePrescriptionsButtonHome"
          (click)="ManagePrescriptions()"> {{ buttons.ManagePrescriptions }}</button>
          <button class="btn btn-secondary motif-margin-left-9 mini-buttons" data-analytics="orderStatusButtonHome"
          (click)="ViewOrderStatus()"> {{ buttons.OrderStatus }}</button>
        </div>
      </div>
    </div>
  </ng-container>
  
  <ng-template #showPartialPharmacy>
    <div class="container pharmacy pharmacySearch motif-margin-top-10">
      <h1 class="heading">{{pharmacySearch?.Header}}</h1>
      <div class="motif-margin-top-4 pharmacy-search-body">
        <span [innerHTML]="pharmacySearch?.HeaderText"></span>
      </div>
      <div class="motif-margin-top-10 pharmacy-search-body">
        <span>{{pharmacySearch?.subHeader}}</span>
      </div>
      <div class="pharmacy-search-body">{{pharmacySearch?.Body}}</div>
      <div class="pharmacy-search-body">{{pharmacySearch?.Body1}}</div>
  
      <div class="search-button button-group">
        <!-- <button  (click)="location.href={{pharmacySearch?.findPharmacyLink}}; target = '_blank' "  class="btn btn-primary" [attr.data-analytics]="pharmacySearch?.AnalyticsTag">{{ pharmacySearch?.ButtonLink }}</button> -->
        <a type="button" href={{pharmacySearch?.findPharmacyLink}} target="_blank" class="btn btn-primary" [attr.data-analytics]="pharmacySearch?.AnalyticsTag">{{ pharmacySearch?.ButtonLink }}</a>
      </div>
    </div>
  </ng-template>