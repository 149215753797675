import { Component, Inject } from '@angular/core';
import { CheckoutFooterComponent } from '@anthem/mbrportal-navigation/components/checkoutFooterCmp';
import { ContentHelper, EventHandler } from '@anthem/mbrportal/core';
import { AppSession } from '@anthem/mbrportal/main/values/appSession';
import { AccessibilityHelper } from '@anthem/mbrportal/shared/public_api';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';

@Component({
    selector: 'app-ahd-cart',
    templateUrl: '../../../../../../node_modules/@anthem/mbrportal-navigation/views/checkoutFooter.html',
    styleUrls: ['../../../../../../node_modules/@anthem/mbrportal-navigation/assets/styles/partials/checkoutFooter.scss', './ahd-cart.component.scss'],
    providers: [{ provide: ContentHelper, useClass: AHDContentHelper }]
})
export class AHDCartComponent extends CheckoutFooterComponent {

    constructor(cartEvent: EventHandler, protected contentService: JsonContentService, accessibilityHelper: AccessibilityHelper, @Inject(AppSession) appSession: AppSession) {
        super(cartEvent, accessibilityHelper, appSession);
    }

}