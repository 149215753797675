export interface PayNowRequest {
    memberpaySubmitPayments: SubmitPayment[];
    newPaymentMethod: boolean;
	paymentType: PaymentType;
	payMetFutureUse: boolean;
	tokenId: string;
	paymentDate: string;
}

export interface SubmitPayment {
    paymentAmount: string;
	planID: string;
}

export interface CancelRequest {
	hcid: string;
	requestHeader: {
		userName: string;
		password: string;
	}
	paymentConfirmationNo: string;
}

export enum PaymentType {
    NONE = 'NONE',
    BANKING = 'BANKINGACCOUNT',
    CREDIT = 'CREDITDEBITCARD'
}

export enum AddressSelection {
    ADD_NEW_ADDRESS = 'addNewAddress'
}
export interface PaymentConfirmation {
    accountType: string;
    amount: string;
    paidOn: string;
    memberId: string;
    coverageStartDt: string;
    coverageEndDt: string;
    planId: string;
    confirmationNumber: string;
    paymentType: string;
}

export interface PIEData {
    keyID: string;
    phase: string;
}