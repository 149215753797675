import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { PowerAccountViewComponent } from './power-account-view/power-account-view.component';

const routes: Routes = [
  { path: 'secure/power-account', component: PowerAccountViewComponent, pathMatch: 'full', canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PowerAccountRoutingModule { }
