import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IAllPrescriptionResponse } from '@anthem/mbrportal-pharmacy/interfaces/iPrescription';
import { Observable, of, throwError } from 'rxjs';
import * as AHDOrdersMock from '../assets/data/mock/ahd-orders';
import { AhdArchivePrescriptionReq, IArchivePrescriptionReq } from '@anthem/mbrportal-pharmacy/interfaces/iPrescriptionv2';
import { VendorMedicalProfile } from '@anthem/mbrportal-profile/interfaces/vendorMedicalProfile';
import { DeliveryAddress } from '@sydney/models';
import { default as AHDAddressesMock } from "../assets/data/mock/profile-ahd-addresses.json";
import { OutstandingBalance } from '@anthem/mbrportal-pharmacy/interfaces/outstandingBalance';
import * as CommSettingMock from "../assets/data/mock/comm-settings";
import * as patientAllergiesMock from "../assets/data/mock/allergies-summary";
import { CommunicationPreferences, ContactDetails } from '@anthem/mbrportal-profile/interfaces/communicationPreferences';
import { PlaceOrderAhdRequest } from '@anthem/mbrportal-pharmacy/interfaces/iOrderPrescription';
import { UpdatePatientAllergyRequest } from '@anthem/mbrportal-profile/interfaces/vendorAllergy';
const {
  archivePrescription,
  prescriptions,
  unarchivePrescription,
  deliveryOptions,
  deliveryAddress,
  ahdGetOutstandingBalance,
  getAllOrders,
  ahdRefillsHistory,
  ahdAddressServiceability,
  ahdSubmitRefillRequest,
  allergies,
  commSettings,
  getMedicalProfile,
  validateAddress,
  getOrderDetail,
  ahdPaymentHistory,
  downloadPrescriptions,
  downloadOrders
} = UrlProperties.UrlProperties.endPoints.ahdPharmacy;

const { specialtyQuestions } = UrlProperties.UrlProperties.endPoints.pharmacy

import { ingenioPrescriptionsMockData } from '../assets/data/mock/ahd-Prescriptions';
import { SpecialtyQuestionnaireRequest } from '@anthem/mbrportal-pharmacy/interfaces/iQuestionnaire';

import { LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
import { OrderFilterParameters } from '@anthem/mbrportal-pharmacy/interfaces/iOrders';
import { DownloadServiceName } from '../models/ahd-download';
import { AhdPharmacyRoutes } from '../ahd-pharmacy-routes.enum';


@Injectable({
  providedIn: 'root'
})
export class ahdPharmacyService {
  constructor(
    protected http: HttpClientService,
    protected localeService: LocaleService
  ) { }

  addressesMock = AHDAddressesMock;
  commSettingMock = CommSettingMock.commPrefs;
  contactDetailsMock = CommSettingMock.patientContactDetails;
  patientAllergiesMock = patientAllergiesMock.patientAllergies;
  defaultAllegiesMock = patientAllergiesMock.defaultAllergies;
 
  addressServiceableCheck(data: DeliveryAddress) {
    return this.http.postObservable(ahdAddressServiceability, data)
      .pipe(
        map(res => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  createPharmacyAddress(address: DeliveryAddress) {
    return this.http.postObservable(deliveryAddress, address)
      .pipe(
        map(res => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  deleteVendorAddress(addressId: string) { 
    const url = `${deliveryAddress}?addressKey=${addressId}`;
    return this.http.deleteObservable(url)
      .pipe(
        map(res => {
          return { data: res }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  download(endDate: string, format: string, serviceName: string, startDate: string) {
    const service = DownloadServiceName[serviceName.toUpperCase()];
    const downloadServiceMap = new Map<DownloadServiceName, Observable<any>>([
      [DownloadServiceName.ORDERS, this.downloadAHDOrders(endDate, format, startDate)],
      [DownloadServiceName.PRESCRIPTIONS, this.downloadAHDPrescriptions(endDate, format, startDate)]
    ]);
    return downloadServiceMap.get(service);
  }

  downloadAHDOrders(endDate: string, format: string, startDate: string) {
    const url = `${downloadOrders}?startDate=${startDate}&endDate=${endDate}&format=${format}`;
    return this.http.getObservable(url)
      .pipe(
        map((res: HttpResponse<{data: number[]}>) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      );
  }

  downloadAHDPrescriptions(endDate: string, format: string, startDate: string) {
    const url = `${downloadPrescriptions}?startDate=${startDate}&endDate=${endDate}&format=${format}`;
    return this.http.getObservable(url)
      .pipe(
        map((res: HttpResponse<{data: number[]}>) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      );
  }


  getDeliveryAddress() {
    return this.http.getObservable(deliveryAddress)
      .pipe(
        map((res: HttpResponse<DeliveryAddress[]>) => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status)))
      .toPromise();
  }

  getUserPrescriptionsV2(startDate: string, endDate: string, selectedMbrUid?: string) {
    const getAllPrescriptionsUrl = `${prescriptions}?startDate=${startDate}&endDate=${endDate}`;

    return this.http.getObservable(getAllPrescriptionsUrl)
      .pipe(
        map((res: HttpResponse<IAllPrescriptionResponse>) => {
          if (res.status === 204) 
          {
            return { data: [] }
          };
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      ).toPromise();
     //Can be used to return mock data when prescriptions api is down
    //return Promise.resolve({data : ingenioPrescriptionsMockData});
  }

  getAHDPrescriptionDownloadUrl(toDate: string, fromDate: string, format: string) {        
    const downloadRoute = AhdPharmacyRoutes.DOWNLOAD_PRESCRIPTIONS;
    const hostName = location.host.toString();
    const protocol = `${location.protocol.toString()}//`;
    const params = `?startDate=${fromDate}&endDate=${toDate}&format=${format}`;
    const url = `${protocol}${hostName}${downloadRoute}${params}`;
    return url;
  }

  getMedicalProfile(mbrUid: string) {
    const getMedicalProfileUrl = `${getMedicalProfile}?name=$'mbrUid'&value=${mbrUid}&isQueryParam=$false`;

    return this.http.getObservable(getMedicalProfileUrl)
      .pipe(
        map((res: HttpResponse<VendorMedicalProfile>) => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      ).toPromise();

  }

  archivePrescription(data: AhdArchivePrescriptionReq) {
    return this.http.postObservable(archivePrescription, data)
      .pipe(
        map(res => res?.body),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  unarchivePrescription(unarchiveData: string) {
    return this.http.deleteObservable(unarchivePrescription, unarchiveData)
      .pipe(
        map(res => res?.body),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  updatePharmacyAddress(address: DeliveryAddress) {
    return this.http.putObservable(deliveryAddress, address)
      .pipe(
        map(res => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getAHDRefillsHistory(selectedMemberUid: string, gcnCode: string, uniqueRxId: string) {
    const getAhdRefillsHistory = `${ahdRefillsHistory}?rxNumber=${uniqueRxId}&gpicode=${gcnCode}`;
    return this.http.getObservable(getAhdRefillsHistory).pipe(
      map((res: HttpResponse<any>) => {
        return { data: res.body }
      }),
      catchError((error: HttpErrorResponse) => {
        error['data'] = {};
        throw (error);
      })
    ).toPromise();
  }

  //Failing the refill fistory for specialty drug until the API is ready!
  getRefillsHistory(selectedMemberUid: string, gcnCode: string, uniqueRxId: string) {
    return '';
  }
  getAHDOrders(startDate: string, endDate: string) {
    const getAllOrdersUrl = `${getAllOrders}?startDate=${startDate}&endDate=${endDate}`;
    return this.http.getObservable(getAllOrdersUrl).pipe(
      map((res: HttpResponse<any>) => {
        return { data: res.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    ).toPromise();;
    // return of({data:AHDOrdersMock.mockOrdersServerResponse}).toPromise();
  }

  getDefaultPharmacy() {
    // mocking till we get a solution
    const defaultPharmacyData = {
      data: {
        "pharmacyNumber": 5635632,
        "pharmacyId": "5635632",
        "pharmacyName": "FLORENCE PHCY & MEDICAL SUPPLY",
        "npiNumber": 1235462490,
        "storeId": 0,
        "address": "1422 E FLORENCE AVE",
        "city": "LOS ANGELES",
        "state": "CA",
        "zipCode": "90001",
        "phoneNumber": "323-277-9500",
        "distance": 0.12,
        "latitude": 33.974534,
        "longitude": -118.249055,
        "preferred": true,
        "maintenanceChoice": false,
        "ninetyDayRetail": false,
        "vaccineNetwork": false,
        "prefPharmInd": "N",
        "proximityNetworkInd": false,
        "pharmacyHoursOfOperation": {
          "open24hours": false,
          "sunday": "0000",
          "monday": "0918",
          "tuesday": "0918",
          "wednesday": "0918",
          "thursday": "0918",
          "friday": "0918",
          "saturday": "1016"
        },
        "pharmacyAdditionalDetails": {
          "spokenLanguages": "CHINESE,FRENCH,HINDI,OTHER,VIETNAMESE",
          "websiteURL": "florence-pharmacy.com",
          "stateLicense": "PHY50748",
          "physicalDisabilityInd": "Y",
          "county": "LOS ANGELES COUNTY",
          "cctInd": "N",
          "dispenseType": "INDEPENDENT RETAIL"
        },
        "pharmacyIndicator": "Closest"
      }
    };
    return of(defaultPharmacyData).toPromise();
  }

  getDownloadUrl(filterParameters: OrderFilterParameters, format: string) {
    const downloadRoute = AhdPharmacyRoutes.DOWNLOAD_ORDERS;
    const hostName = location.host.toString();
    const protocol = `${location.protocol.toString()}//`;
    const params = `?startDate=${filterParameters.startDate}&endDate=${filterParameters.endDate}&format=${format}`;
    const url = `${protocol}${hostName}${downloadRoute}${params}`;
    return url;
  }

  getPrescriptionDownloadUrlV2(toDate: string, fromDate: string, format: string) {
    let url = downloadPrescriptions;
    [
      {
        name: 'startDate',
        value: fromDate
      },
      {
        name: 'endDate',
        value: toDate
      }
    ].forEach((o) => {
      url = url + this.getUrlParam(o.name, o.value, url);
    });

    return url;
  }

  getUrlParam(name: string, value: string, url: string) {
    let param = '';
    if (url.toLowerCase().indexOf(name.toLowerCase() + '=') < 0) {
      if (url.indexOf('?') < 0) {
        param = '?';
      } else {
        param = '&';
      }

      param = param + name + '=' + value;
    }

    return param;
  }

  getVendorDeliveryShippingOptions() {

    return this.http.getObservable(deliveryOptions).pipe(
      map((res) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();

  }

  getOutstandingBalance() {
    return this.http.getObservable(ahdGetOutstandingBalance).pipe(
      map((res: HttpResponse<OutstandingBalance[]>) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  getCommunicationPreferences() {
    return this.http
      .getObservable(commSettings.getCommPrefs)
      .pipe(
        map((res) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  updateCommunicationPreferences(data: CommunicationPreferences) {
    return this.http
      .postObservable(commSettings.updateCommPrefs, data)
      .pipe(
        map((res) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getContactDetails() {
    return this.http
      .getObservable(commSettings.getAhdContactDetails)
      .pipe(
        map((res) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  updateContactDetails(data: ContactDetails) {
    return this.http
      .postObservable(commSettings.updateAhdContactDetails, data)
      .pipe(
        map((res) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getPatientAllergies() {
    return this.http.getObservable(allergies.getAhdPatientAllergies).pipe(
      map((res) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  getDefaultAllergies() {
    return this.http
      .getObservable(allergies.getAhdDefaultAllergies)
      .pipe(
        map((res) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getAllergyByName(allergyName: string, limit: string) {
    const getAllergyByUrl = `${allergies.getAhdAllergyByName}?allergyName=${allergyName}&limit=${limit}`;
    return this.http
      .getObservable(getAllergyByUrl)
      .pipe(
        map((res) => {
          return { data: res.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  updateAllergies(data: UpdatePatientAllergyRequest) {
    return this.http
      .postObservable(allergies.updateAhdAllergies, data)
      .pipe(
        map((res) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }


  placeRefillOrder(data: PlaceOrderAhdRequest) {
    return this.http.postObservable(ahdSubmitRefillRequest, data).pipe(
      map((res) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }


  validateAddress(address: DeliveryAddress) {
    return this.http.postObservable(validateAddress, address).pipe(
      map((res) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  getOrderDetails(orderId: string) {
    return this.http.getObservable(`${getOrderDetail}/${orderId}`).pipe(
      map((res) => {
        return { data: res?.body }
      }), catchError((error: HttpErrorResponse) => throwError(error.status))).toPromise();
  }

  getPaymentHistory(orderNumber: string, startDate?: string, endDate?: string) {
    return this.http
      .getObservable(`${ahdPaymentHistory}?orderNumber=${orderNumber}`)
      .pipe(
        map((res) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getSpecialtyQuestionsV2(data: SpecialtyQuestionnaireRequest) {
    return this.http.postObservable(specialtyQuestions, data).pipe(
      map((res: any) => {
        return { data: res.body };
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }
}

