import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { dashboardAppComponent } from './app.component';
import { DashboardContainerComponent } from './dashboard-container/dashboard-container/dashboard-container.component';
import { ConnectWithCareComponent } from './connect-with-care/connect-with-care/connect-with-care.component';
import { DashboardAlertsComponent } from './dashboard-alerts/dashboard-alerts/dashboard-alerts.component';
import { WelcomeGreetingComponent } from './welcome-greeting/welcome-greeting/welcome-greeting.component';
import { AlertModule } from '@anthem/uxd/alert';
import { JsonDataResolverService } from './json-data-resolver.service';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { PharmacyDashboardModule } from './../pharmacy-dashboard/pharmacy-dashboard.module';
import { DashboardDismissableAlertComponent } from './dashboard-dismissable-alert/dashboard-dismissable-alert/dashboard-dismissable-alert.component';
import { ClaimsModule } from '../claims/claimsModule';
import {DashboardModalComponent} from './dashboard-modals/dashboard-modals/dashboard-modal.component';
import { SharedModule } from '@anthem/mbrportal/shared';
import { SydmedSharedModule } from '../../sydmed-shared/sydmed-shared.module';

@NgModule({
  declarations: [
    dashboardAppComponent, 
    DashboardContainerComponent, 
    ConnectWithCareComponent, 
     DashboardAlertsComponent, 
     DashboardDismissableAlertComponent,
     DashboardModalComponent,
     WelcomeGreetingComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AlertModule,
    IndicatorModule,
    PharmacyDashboardModule,
    ClaimsModule,
    SharedModule,
    SydmedSharedModule
  ],
  exports: [ DashboardDismissableAlertComponent ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    JsonDataResolverService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [dashboardAppComponent]
})
export class DashboardModule { }