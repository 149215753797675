<div class="plan-component" *ngIf="content">
    <div class="member-info">
        <p class="date-effective-label">
            {{content.dateEffective}}
            <span class="date-effective">{{ effectiveDate }}</span>
        </p>
        <h2 class="member-name">
            {{(content.memberName | si:{ memberName: memberName}) | titlecase }} {{content.seperator}} {{activeStatus |
            titlecase}}
        </h2>
        <p class="member-id">{{content.memberId}} {{memberId}}</p>
    </div>
    <p class="access-plan-info">
        {{content.descriptionStart}}
        <a  class="plan-link" href="{{publicPageLink}}"
            target="{{content.publicLinkLabel}}" [attr.data-analytics]="content.analytics.publicPageLink">{{content.publicLinkLabel}}</a>
        {{content.descriptionEnd}}
    </p>
</div>