import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DescribeYourSituation, ErrorMsgs } from '../../models/labels';
import { RadioButtonGroupValues } from '../../models/formValues';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-describe-your-situation',
  templateUrl: './describe-your-situation.component.html',
  styleUrls: ['../../appeals-and-grievances.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DescribeYourSituationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DescribeYourSituationComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DescribeYourSituationComponent implements ControlValueAccessor, OnDestroy {
  @Input('formSubmitted') formSubmitted: boolean;
  @Input() errorMsgs: ErrorMsgs;
  @Input() labels: DescribeYourSituation;
  describeSituationForm: FormGroup;
  selectedRadioButton: string;
  inputEnabler: boolean;
  subscriptions: Subscription[] = [];
  @Input('disableEffect') disableEffect: boolean = false;

  constructor(public fb: FormBuilder) { 
    this.describeSituationForm = this.fb.group({
      requestCategory: ['', Validators.required]
    });

    this.subscriptions.push(
      this.describeSituationForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    ); 
  }

  get value(): RadioButtonGroupValues {
    return this.describeSituationForm.value;
  }

  set value(value: RadioButtonGroupValues) {
    this.describeSituationForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  selectItem(id){
      this.selectedRadioButton = id;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (!value) {
      this.describeSituationForm.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.describeSituationForm.valid ? null : { descriptionOfSpecificIssue: { valid: false } }
  }

}
