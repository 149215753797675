import { Component, OnInit, ViewChild } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { InlineAlertContainerComponent, AlertService } from '@anthem/uxd/alert';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ICheckboxInput } from '@anthem/uxd/util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-caregiver-relation-type',
  templateUrl: './caregiver-relation-type.component.html',
  styleUrls: ['./caregiver-relation-type.component.css']
})
export class CaregiverRelationTypeComponent implements OnInit {
  @ViewChild('memberRelationType') memberTypeAlert: InlineAlertContainerComponent;
  jsonLabels: any;
  jsonErrors: any;
  memberRelationTypeRadioGroup: any;
  memberType: string;
  careGiverMemberTypeForm: FormGroup;
  isNotAValidOption = false;
  isLegalGuardian = false;
  public checkbox: ICheckboxInput;
  memberInfo: any;
  
  constructor(
    private jsonSvc: JsonContentService,
    private formBuilder: FormBuilder,
    public alert: AlertService,
    private router: Router
    ) {}

  ngOnInit(): void {
    this.memberInfo = JSON.parse(window.sessionStorage.getItem('contactsModel'));
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Caregiver_Relation;
      this.jsonErrors = data.RegistrationMFA.Labels.Caregiver_Relation.Member_Type_Error_Messages;
      this.initializeRadioGroups();
      this.checkbox = {
        id: 'ux-guide-checkbox-1',
        name: 'parentGuardianAccess',
        label: this.jsonLabels.Request_Access_checkmark_Label,
        trueValue: true,
        falseValue: false
      };
    });
    this.careGiverMemberTypeForm = this.formBuilder.group({
      relationType: [],
      parentGuardianAccess: [false, [Validators.requiredTrue]]
    });
  }

  initializeRadioGroups() {
    this.memberRelationTypeRadioGroup = {
      isOptional: false,
      alignVertical: true,
      id: 'memberRelationRadioBtnGrp',
      name: 'memberRelationRadioBtnGrp',
      label: this.jsonLabels?.Type_Label,
      options: this.jsonLabels?.options
    }
  }

  memberTypeChange(event) {
    if (this.memberTypeAlert) {
      this.clearErrorAlert();
      this.memberTypeValidation(event);
    }
    else {
      this.memberTypeValidation(event);
    }
  }

  memberTypeValidation(event) {
    this.isNotAValidOption = false;
      if(event.target.value !== 'The parent/legal guardian of this member.') {
        if(this.careGiverMemberTypeForm.valid) {
          this.careGiverMemberTypeForm.get('parentGuardianAccess').setValue(false);
        }
        this.isLegalGuardian = false;
        if(event.target.value === 'I am the member.') {
          this.alert.error(this.jsonErrors.Member_below_Age18_Error, {
            regionName: "inline-alert",
            politeness: 'assertive'
          });
        }
        else {
          this.isNotAValidOption = true;
          this.alert.error(this.jsonErrors.No_Relation_Error, {
            regionName: "inline-alert",
            politeness: 'assertive'
          });
        }
      }
      else {
        this.isLegalGuardian = true;
      }
  }

  clearErrorAlert() {
    this.memberTypeAlert.dismissAlertId(0);
  }

  careGiverTypeSubmission() {
    sessionStorage.setItem("caregiverInfoVisited", "true");
    this.router.navigate(['public/registration/caregiver-info']);
  }

  back() {
    this.router.navigate(['public/registration/home']);
  }
}
