<div class="cards-wrapper" *ngIf="isCallScheduled; else showScheduleACall">
    <div class="cards-container container-fluid" role="alert">
        <div class="row">
            <div class="col-md-8 col-md-offset-2">
                <div class="card card-flat card-popover text-center mx-auto w-100">
                    <span class="motif-icon motif-checked font-125 gray-icon"></span>
                    <h2 class="ant-font-sb title">{{ labels.contactUsSuccess }}</h2>
                    <p class="text" for="dialogStr" id="lgSelectDiagLogStr">{{dialogStr}}</p>
                    <button class="btn btn-primary" (click)="cancel()">{{ labels.contactUsClose }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #showScheduleACall>
    <div class="motif-panel-wrapper">
        <div class="container">
            <div class="alert-pad">
                <ant-inline-alert [name]="'inline-alerts'"></ant-inline-alert>
            </div>
            <div class="row">
                <p class="align-center">{{ labels?.contactUsCallMeHeaderContentLine1 }} {{ labels?.contactUsCallMeHeaderContentLine2 }}</p>
                <div class="col-md-8 col-md-offset-2">
                    <form [formGroup]="callMeForm" autocomplete="off" name="selectForm" class="form uxd-theme-motif">
                        <div class="row">
                            <div class="col-md-6 mobile-mb">
                                <div class="cmp-example-wrapper">
                                    <div class="form-item clearfix">
                                        <div data-uxd-dropdown-cmp class="ant-lg-select form-item " [ngClass]="{'invalidBorder' : form_inquiryType.invalid && form_inquiryType.touched && form_inquiryType.errors.required}"
                                            [labelText]="labels.contactUsInquiryTypeSelectLabel" name="lgSelect" id="lgSelectInquiry"
                                            [items]="inquiryTypesOptions" required [defaultText]="labels.contactUsDefaultSelecOptionLabel"
                                            formControlName="inquiryType" *ngIf="labels.contactUsDefaultSelecOptionLabel">
                                        </div>
                                        <div class="errorRequired" *ngIf="form_inquiryType.invalid && form_inquiryType.touched && form_inquiryType.errors.required">
                                            <div>
                                                <i class="fas fa-times"></i>
                                            </div>
                                            <p class="pt-2 errorRed">{{errorLabels.errorSelectionRequired}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="cmp-example-wrapper">
                                    <div class="form-item clearfix">
                                        <div data-uxd-dropdown-cmp class="ant-lg-select form-item" [ngClass]="{'invalidBorder' : form_scheduleCallType.invalid && form_scheduleCallType.touched && form_scheduleCallType.errors.required}"
                                            [labelText]="labels.contactUsCallScheduleSelectLabel" name="lgSelect" id="lgSelectCallType"
                                            [items]="callTypeOptions" required [defaultText]="labels.contactUsDefaultSelecOptionLabel"
                                            formControlName="scheduleCallType" *ngIf="labels.contactUsDefaultSelecOptionLabel">
                                        </div>
                                        <div class="errorRequired" *ngIf="form_scheduleCallType.invalid && form_scheduleCallType.touched && form_scheduleCallType.errors.required">
                                            <div>
                                                <i class="fas fa-times"></i>
                                            </div>
                                            <p class="pt-2 errorRed">{{errorLabels.errorSelectionRequired}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="approximateTime && approximateTime.length && form_scheduleCallType.value === callTypeEnum.callMe">
                            <div class="col-md-12 text-center">
                                <p><i class="fa fa-phone fa-4x font-145 gray-icon"></i></p>
                                <p class="montserrat-sb">{{ approximateTime }} {{labels.contactUsMinutes}}</p>
                                <p>{{labels.contactUsCallConfirmation}}</p>
                            </div>
                        </div>
                        <div class="clearfix mt-30" *ngIf="form_scheduleCallType.value === callTypeEnum.scheduleForLater"></div>
                        <div class="row" *ngIf="form_scheduleCallType.value === callTypeEnum.scheduleForLater">
                            <div class="col-md-6">
                                <div class="cmp-example-wrapper">
                                    <div data-uxd-dropdown-cmp class="ant-lg-select form-item" [ngClass]="{'invalidBorder' : form_scheduleCallDay.invalid && form_scheduleCallDay.touched && form_scheduleCallDay.errors.required}"
                                        [labelText]="labels.contactUsCallDaySelectLabel" name="lgSelect" id="lgSelectDay" [items]="callDayOptions"
                                        required [defaultText]="labels.contactUsDefaultSelecOptionLabel" formControlName="scheduleCallDay">
                                    </div>
                                    <div class="errorRequired" *ngIf="form_scheduleCallDay.invalid && form_scheduleCallDay.touched && form_scheduleCallDay.errors.required">
                                        <div>
                                            <i class="fas fa-times"></i>
                                        </div>
                                        <p class="pt-2 errorRed">{{errorLabels.errorSelectionRequired}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="cmp-example-wrapper">
                                    <div class="form-item clearfix">
                                        <div data-uxd-dropdown-cmp class="ant-lg-select form-item" [ngClass]="{'invalidBorder' : form_scheduleCallTime.invalid && form_scheduleCallTime.touched && form_scheduleCallTime.errors.required}"
                                            [labelText]="labels.contactUsCallTimingsSelectLabel" name="lgSelect" id="lgSelectTime"
                                            [items]="callTimingOptions" required [defaultText]="labels.contactUsDefaultSelecOptionLabel"
                                            formControlName="scheduleCallTime">
                                        </div>
                                        <div class="errorRequired" *ngIf="form_scheduleCallTime.invalid && form_scheduleCallTime.touched && form_scheduleCallTime.errors.required">
                                            <div>
                                                <i class="fas fa-times"></i>
                                            </div>
                                            <p class="pt-2 errorRed">{{errorLabels.errorSelectionRequired}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix mt-15"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="cmp-example-wrapper">
                                    <div class="form-item clearfix">
                                        <label for="phone" class="text-bold">{{ labels.contactUsMobileNumberSelectLabel }}</label>

                                        <input id="phone" name="phone" type="text" (focusout)="focusOutPhoneError()" libPhoneFormat class="ant-text-input ant-lg-select"
                                            [ngClass]="{'invalidField' : form_phone.invalid && form_phone.touched && form_phone.errors.required}"
                                            placeholder="xxx-xxx-xxxx" formControlName="phone" minlength="12" maxlength="12"
                                        />
                                        <div class="errorRequired mt-4" *ngIf="form_phone.invalid && form_phone.touched && form_phone.errors.minlength && showPhoneError">
                                            <div>
                                                <i class="fas fa-times"></i>
                                            </div>
                                            <p class="pt-2 errorRed">{{errorLabels.errorPhoneRequired}}</p>
                                        </div>
                                        <div class="errorRequired mt-4" *ngIf="form_phone.invalid && form_phone.touched && form_phone.errors.required ">
                                            <div>
                                                <i class="fas fa-times"></i>
                                            </div>
                                            <p class="pt-2 errorRed">{{errorLabels.errorPhoneRequired}}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="row">
                            <div class="col-md-12 text-right mt-15">
                                <div class="button-group">
                                    <button class="btn btn-secondary" (click)="cancel()">{{ labels.contactUsCancel }}</button>
                                    <button class="btn btn-primary" (click)="callMe()" [disabled]="disableButton" data-analytics="callMeButtonContactUs">{{ labels.contactUsCallMe }}</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</ng-template>