export const shippingAddress = {
    addresses: [
      {
        addressUid: "630c655c-1f9c-4db1-9996-eaf2d2d01ef2",
        addressTypeCd: "PBM",
        address1: "3015 STAPLES MILLS RD",
        address2: "1000",
        city: "ST.JOE",
        state: "VA",
        zipCode: "23294",
        preferred: false
      },
      {
        addressUid: "57cd8674-f916-4207-9209-a735ea0ef4c8",
        addressTypeCd: "PBM",
        address1: "222222 W BROAD STREET",
        city: "HUDSON",
        state: "KS",
        zipCode: "67545",
        preferred: false,
        pharmacyIndicator: 'Primary'
      },
      {
        addressUid: "7f480bd8-e873-4693-b95e-cad0e8c44f9c",
        addressTypeCd: "PBM",
        address1: "444444",
        city: "HUDSON",
        state: "KS",
        zipCode: "67545",
        preferred: false
      }
    ]
  }
  

export const shippingOptions = {
  pbm: {
    standardShipping: {
      shippingCode: 'B',
      shippingCost: '0.0',
      beginDate: '2021-04-13',
      endDate: '2021-04-17'
    },
    nextDayShipping: {
      shippingCode: 'D',
      shippingCost: '23.0',
      beginDate: '2021-04-13',
      endDate: '2021-04-14'
    },
    secondDayShipping: {
      shippingCode: 'J',
      shippingCost: '17.0',
      beginDate: '2021-04-13',
      endDate: '2021-04-15'
    },
    responseMessage: { message: '', messageCode: '0', messageId: '0' }
  },
  specialty: {
    possibleDeliveryDates: [
      '2021-04-09',
      '2021-04-13',
      '2021-04-14',
      '2021-04-15',
      '2021-04-16',
      '2021-04-20',
      '2021-04-21',
      '2021-04-22',
      '2021-04-23',
      '2021-04-27',
      '2021-04-28',
      '2021-04-29',
      '2021-04-30',
      '2021-05-04',
      '2021-05-05',
      '2021-05-06',
      '2021-05-07',
      '2021-05-11',
      '2021-05-12',
      '2021-05-13',
      '2021-05-14',
      '2021-05-18',
      '2021-05-19',
      '2021-05-20',
      '2021-05-21'
    ]
  }
}