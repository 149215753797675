import { Injectable } from '@angular/core';
import { SessionType } from '../enums/SessionType';

@Injectable( {
  providedIn: 'root'
} )
export class PharmacySessionService {
  constructor() { }

  public isValidSession(): boolean {
    return this.getCurrentSession() !== null;
  }

  protected getCurrentSession(): string {
    return sessionStorage.getItem( SessionType.LOGGED_IN );
  }

  public get memberName(): string {
    if ( this.isValidSession() ) {
      const memberContext = this.getMemberContext(  );
      return memberContext.memberName.firstName;
    }
    return '';
  }

  getMemberContext() {
    const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    return memberContext;
  }

  getSessionItemByKey(key: string) {
    if (this.isValidSession()) {
      return JSON.parse(window.sessionStorage.getItem(key));
    }
  }

  setSessionItem(key: string, item: any) {
    if (this.isValidSession()) {
      window.sessionStorage.setItem(key, JSON.stringify(item));
    }
  }
}
