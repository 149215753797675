<!-- Filter Panel Open/Close Button -->
<button #filterPanelButton id="prior-auth-filter-button" class="btn btn-filter" [class.open]="filterPanelAlpha.isPanelVisible" #filterOrigin (click)="filterPanelAlpha.togglePanel($event)">
    <span [innerText]="labels.filterResults"></span>
    <span class="motif-icon" [ngClass]="filterPanelAlpha.isPanelVisible ? 'motif-sort-up-filled' : 'motif-sort-down-filled'"></span>
</button>

<!-- Filter Panel Content -->
<ant-filter-panel #filterPanelAlpha="filterPanel"
  [origin]="filterOrigin"
  [filterHeaderText]="'Data Filter'">
<div class="priorAuthFilter">
    <form #filterForm="ngForm" name="filterForm">
        <div class="filterButton pfSelect">
            <span class="sr-only" id="care-type-filter">{{ labels.SRFilterLabel }}</span>
            <div class="pfSelect-legend">{{ labels.FilterCareTypeLabel }}</div>
            <div class="uxd-filter-button-group" role="group" aria-labelledby="care-type-filter" uxd-select>
                <button #outpatientCareType class="uxd-filter-button" uxd-select-toggle>{{ labels.OutpatientCareTypeLabel }}</button>
                <button #inpatientCareType class="uxd-filter-button" uxd-select-toggle>{{ labels.InpatientCareTypeLabel }}</button>
            </div>
        </div>

        <div #filterDateRange="ngModel" data-uxd-dropdown-cmp [(ngModel)]="filterModelValue" class="ant-lg-select form-item" [labelText]="filterDropDown.title"
        name="filterDateRange" id="filterDateRange" [items]="filterDropDown.options" [isRequired]="true" [describedBy]="'forSelectItem'"
        required>
        </div>

        <div class="filter-button-group">
            <span class="reset-filter-link motif-margin-right-5 mobile-only">
                <a class="ant-action-link" href="javascript:void(0)" (click)="resetFilterParameters()">
                    <span class="motif-icon motif-recurring-appointment"></span>
                    <span class="ant-link-text">{{ labels.ResetFilterLabel }}</span>
                </a>
            </span>
            <div class="filter-buttons button-group text-right">
                <span class="reset-filter-link motif-margin-right-5 desktop-only">
                    <a class="ant-action-link" href="javascript:void(0)" (click)="resetFilterParameters()">
                        <span class="motif-icon motif-recurring-appointment"></span>
                        <span class="ant-link-text">{{ labels.ResetFilterLabel }}</span>
                    </a>
                </span>
                <span>
                    <button class="btn btn-secondary" (click)="closeFilterPanel()">{{ labels.CancelButtonLabel }}</button>
                    <button class="btn" (click)="applyFilter()">{{ labels.ApplyFiltersButtonLabel }}</button>
                </span>
            </div>
        </div>
    </form>
</div>
</ant-filter-panel>

<p id="filterParagraph">{{ labels.FilterDescriptionLabel }}</p>

<!-- Prior Authorization Accordions -->
<div *ngIf="summaryDataLoaded; else summaryLoadingOrError">
    <uxdAccordion class="accordion-wrapper uxd-expansion-table zebra-striping" [isAccordion]="true">
        <div class="uxd-expansion-table" role="table">
            <div role="rowgroup">
                <div role="row">
                    <span role="columnheader" aria-sort="none" class="sr-only">{{ labels.SRAccordionToggleTableHeader }}</span>
                    <span role="columnheader" aria-sort="none">{{ labels.RequestDateTableHeader }}</span>
                    <span role="columnheader" aria-sort="none">{{ labels.TrackingIdTableHeader }}</span>
                    <span role="columnheader" aria-sort="none">{{ labels.CareTypeTableHeader }}</span>
                </div>
            </div>

            <!-- Prior Authorization Summary Data -->
            <div role="rowgroup">
                <uxd-expansion-panel *ngFor="let row of authList; index as i" #panel="uxdExpansionPanel">
                    <uxd-expansion-header
                    [aria-label]="'View information for claim with ID ' + row.claimNumber">
                        <div class="uxd-table-row" role="row" [id]="'uxd-table-row-' + (i + 1)">
                            <div role="cell" class="toggle" [id]="'button-cell-'+ i">
                                <uxd-expansion-toggle>
                                    <span (click)="getDetails(row.authType, row.authID, panel.expanded, i)" class="motif-icon" [id]="'button-icon-'+ i"
                                    [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
                                </uxd-expansion-toggle>
                            </div>
                            <div class="uxd-table-cell cell-requestDate" role="cell" [id]="'uxd-table-row-' + (i + 1) + '-cell-2'">
                                <span role="columnheader" class="mobile-only" aria-sort="none">{{ labels.RequestDateTableHeader }}</span>
                                <p>{{ row.requestDate | date: 'MMMM d, y' }}</p>
                            </div>
                            <div class="uxd-table-cell" role="cell" [id]="'uxd-table-row-' + (i + 1) + '-cell-0'">
                                <span role="columnheader" class="mobile-only" aria-sort="none">{{ labels.TrackingIdTableHeader }}</span>
                                <p>{{ row.authID }}</p>
                            </div>
                            <div class="uxd-table-cell cell-service-date" role="cell" [id]="'uxd-table-row-' + (i + 1) + '-cell-1'">
                                <span role="columnheader" class="mobile-only" aria-sort="none">{{ labels.CareTypeTableHeader }}</span>
                                <p>{{ row.authType | titlecase}}</p>
                            </div>
                        </div>
                    </uxd-expansion-header>

                    <!-- Prior Authorization Detail Data -->
                    <div class="full-callout">
                        <div class="container">
                            <div class="row">
                                <div class="ant-content-wrapper" role="region">
                                    <div class="cards-wrapper" *ngIf="detailsDataLoaded; else detailsLoadingOrError">
                                        <div *ngIf="details[i]">
                                            <div class="cards-container" *ngFor="let service of details[i].services">
                                                <h4 class="section-header-three">{{ labels.ServiceInformation }}</h4>
                                                <div class="col-xs-12">
                                                    <div class="col-xs-6 prior-auth-details">
                                                        <div>
                                                            <p><strong>{{ labels.Status }}</strong></p>
                                                            <p>{{ service.statusCodeDesc }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.RequestingProviderNPI }}</strong></p>
                                                            <p>{{ service.requestProvider.npi }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.RequestingProvider }}</strong></p>
                                                            <p>{{ service.requestProvider.name }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.ServiceProviderNPI }}</strong></p>
                                                            <p>{{ service.serviceProvider.npi }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.ServiceProvider }}</strong></p>
                                                            <p>{{ service.serviceProvider.name }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.ServiceRequested }}</strong></p>
                                                            <p>{{ service.serviceCode }} - {{ service.serviceCodeDesc }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 prior-auth-details">
                                                        <div>
                                                            <p><strong>{{ labels.UnitsApproved }}</strong></p>
                                                            <p>{{ service.unitsAuthorized }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.UnitsPaid }}</strong></p>
                                                            <p>{{ service.unitsUsed }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.DateOfService }}</strong></p>
                                                            <p>{{ service.startDate | date: 'M/d/yy' }}</p>
                                                        </div>
                                                        <div>
                                                            <p><strong>{{ labels.EffectiveEndDate }}</strong></p>
                                                            <p>{{ service.endDate | date: 'M/d/yy' }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Prior Authorization Details Loading Graphic and Error Message -->
                                    <ng-template #detailsLoadingOrError>
                                        <div class="loadingOrError">
                                            <div *ngIf="detailsError; else detailsLoading" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negetive'}">
                                                <div>{{ detailsErrorMsg }}</div>
                                            </div>
                                            <ng-template #detailsLoading>
                                                <uxd-spinner class="col-xxs-12 col-xs-12"></uxd-spinner>
                                                <div [innerHTML]="labels.loading"></div>
                                            </ng-template>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </uxd-expansion-panel>
            </div>
        </div>
    </uxdAccordion>
</div>

<!-- Prior Authorization Summary Loading Graphic and Error Message -->
<ng-template #summaryLoadingOrError>
    <div *ngIf="summaryError; else summaryLoading" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negetive'}">
        <div>{{ summaryErrorMsg }}</div>
    </div>
    <ng-template #summaryLoading>
        <uxd-spinner class="col-xxs-12 col-xs-12"></uxd-spinner>
        <div [innerHTML]="labels.loading"></div>
    </ng-template>
</ng-template>