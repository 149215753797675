import { Injectable } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { PharmacyLineOfBusiness } from '../models/enum-values';
import { PBMCardRequestObj, SpecialtyCardRequestObj } from '../models/payment-methods';
import { Utils } from '../models/utils';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  constructor(
    private httpSvc: HttpClientService) { }

  // call to add card for PBM or specialty
  public addCard(pharmacyLineOfBusiness: string, requestObj: any) {
    if(Utils.isPbm(pharmacyLineOfBusiness)) {
      return this.addPBMCard(requestObj)
    } else if(pharmacyLineOfBusiness === PharmacyLineOfBusiness.SPECIALTY) {
        return this.addSpecialtyCard(requestObj)
      }
  }

  // add credit/debit card for PBM
  private addPBMCard(requestObj: PBMCardRequestObj) {
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.paymentMethods.creditcard, JSON.stringify(requestObj));
  }

  // add credit/debit card for specialty
  private addSpecialtyCard(requestObj: SpecialtyCardRequestObj) {
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.paymentMethods.accountsSpecialty, JSON.stringify(requestObj));
  }

  // call to add checking accounts for PBM and specialty
  public addCheckingAccount(pharmacyLineOfBusiness: string, requestObj) {
    if(Utils.isPbm(pharmacyLineOfBusiness)) {
      // add checking account for PBM
      return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.paymentMethods.checking, JSON.stringify(requestObj));
    } else if(pharmacyLineOfBusiness === PharmacyLineOfBusiness.SPECIALTY) {
        // add checking account for specialty
        return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.paymentMethods.accountsSpecialty, JSON.stringify(requestObj));
    }
  }

    // edit card for PBM and SPECIALTY
    public editCard(pharmacyLineOfBusiness: string, requestObj) {
      if(Utils.isPbm(pharmacyLineOfBusiness)) {
        // edit card for PBM
        return this.httpSvc.putObservable(UrlProperties.UrlProperties.endPoints.paymentMethods.creditcard, JSON.stringify(requestObj));        
      } else {
        // edit card for specialty
        return this.addSpecialtyCard(requestObj);
      }
    }
}
