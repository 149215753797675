import { AccordianModule } from '@anthem/uxd/accordion';
import { AlertModule } from '@anthem/uxd/alert';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { CommonPipesModule } from 'sydmed/libs/common-pipes/src/lib/common-pipes.module';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { InputModule } from '@anthem/uxd/input';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { ModalModule } from '@anthem/uxd/modal';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { OrderBy } from '@anthem/mbrportal/shared/pipes/orderBy';
import { PcpChangeComponent } from './change/pcpChangeCmp';
import { PcpRoutingModule } from './pcpRoutingModule';
import { PcpViewComponent } from './view/pcpViewCmp';
import { PhoneNumPipe } from 'sydmed/libs/common-pipes/src/lib/phone-number/phone-num.pipe';
import { RadioModule } from '@anthem/uxd/radio';
import { TabsModuleV2 } from '@anthem/uxd/tabs';
import { UxDeprecatedModule, UxModalHelper } from '@anthem/uxd/deprecated';
import { UxHelper } from '@anthem/uxd/util';

@NgModule( {
  declarations: [
    PcpViewComponent,
    PcpChangeComponent
  ],
  imports: [
    AccordianModule,
    AlertModule,
    BrowserAnimationsModule,
    CheckboxModule,
    CommonPipesModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    IndicatorModule,
    InputModule,
    ModalModule,
    PcpRoutingModule,
    RadioModule,
    ReactiveFormsModule,
    UxDeprecatedModule,
    TabsModuleV2
  ],

  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    JsonContentService,
    OrderBy,
    PhoneNumPipe,
    UxHelper,
    UxModalHelper
  ],
  bootstrap: [ PcpViewComponent, PcpChangeComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
} )
export class PCPModule { }
