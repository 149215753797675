import { Component, OnInit, Input } from '@angular/core';
import { CardContent } from '../model/labels';
@Component({
  selector: '[app-dashboard-cards-container]',
  templateUrl: './dashboard-cards-container.component.html',
  styleUrls: ['./dashboard-cards-container.component.scss']
})
export class DashboardCardsContainerComponent implements OnInit {
    @Input()
    cardContent: CardContent;
    @Input()
    isTermedMember: boolean;
    
    constructor() {}
    ngOnInit() {}
}