<div class="dashboard-card-header">
    <h2 class="cardTitle" [innerHTML]="cardContent?.cardTitle"></h2>
</div>
<div class="dashboard-card-container">
    <div class="left-container" *ngIf="!isTermedMember">
        <ng-content select="card-left-container"></ng-content>
    </div>
    <div [ngClass]="isTermedMember? 'right-termed-container' : 'right-container'">
        <ng-content select="card-right-container"></ng-content>
    </div>
</div>