<div class="container">
  <div
    *ngIf="genError"
    data-uxd-alert-cmp-v2
    [alert]="{ alertType: 'negative' }"
  >
    <span [innerHTML]="genError"></span>
  </div>
</div>
<div class="cards-wrapper container">
  <div class="cards-container">
    <div class="card card-flat card-hover">
      <div *ngIf="isSummaryAPILoading; else showCardContent">
        <uxd-spinner></uxd-spinner>
      </div>
      <ng-template #showCardContent>
        <div class="card-section">
          <div class="summary">
            <div class="columns">
              <div class="summary-label">
                <!-- <div class="pwrAccSummaryLbls">Your Contribution</div> -->
                <span class="motif-margin-right-5">
                  <div
                    class="pwrAccSummaryLbls"
                    [id]="'power-account-your-contribution'"
                  >
                    {{ content.PwrAcctSummary_lbls.PwrAcctYourConHeader_lbl }}
                  </div>
                  <P class="description">
                    {{
                      content.PwrAcctSummary_lbls
                        .EstimatedAnnualContribution
                    }}</P
                  >
                </span>
                <ng-template #showYourContributionContent>
                  <ng-container *ngIf="summaryError; else showYourContribution">
                    <div class="error-container">
                      <span class="error"
                        ><i class="motif-icon motif-delete"></i>
                        <span class="motif-margin-left-5">
                          <span
                            [innerHtml]="summaryError"
                            class="summaryError"
                          ></span>
                        </span>
                      </span>
                    </div>
                  </ng-container>
                  <ng-template #showYourContribution>
                    <div id="pwrAcctYourContribution">
                      <p class="amountText">
                        {{ displayPASummary.memberEstimatedAnnualContribution }}
                      </p>
                    </div>
                  </ng-template>
                </ng-template>

                <div
                  *ngIf="isSummaryAPILoading; else showYourContributionContent"
                ></div>
              </div>
            </div>
            <div class="columns state-contribution">
              <div class="summary-label">
                <!-- <div class="pwrAccSummaryLbls">State Contribution</div> -->
                <span class="motif-margin-right-5">
                  <div
                    class="pwrAccSummaryLbls"
                    [id]="'power-account-state-contribution'"
                  >
                    {{ content.PwrAcctSummary_lbls.PwrAcctStateConHeader_lbl }}
                  </div>
                  <p class="description">
                    {{
                      content.PwrAcctSummary_lbls
                        .PwrAcctStateConHeaderDescription_lbl
                    }}
                  </p>
                </span>
                <div
                  *ngIf="isSummaryAPILoading; else showStateContributionContent"
                ></div>
                <ng-template #showStateContributionContent>
                  <ng-container
                    *ngIf="summaryError; else showStateContribution"
                  >
                    <div class="error-container">
                      <span class="error"
                        ><i class="motif-icon motif-delete"></i>
                        <span class="motif-margin-left-5">
                          <span
                            [innerHtml]="summaryError"
                            class="summaryError"
                          ></span>
                        </span>
                      </span>
                    </div>
                  </ng-container>
                  <ng-template #showStateContribution>
                    <div id="pwrAcctStateConAmount">
                      <p class="amountText">
                        {{ displayPASummary.stateEstimatedAnnualContribution }}
                      </p>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </div>
            <div class="columns">
              <div class="summary-label">
                <span class="motif-margin-right-5">
                  <div
                    class="pwrAccSummaryLbls"
                    [id]="'power-account-account-balance'"
                  >
                    {{
                      content.PwrAcctSummary_lbls.PwrAcctAcctBalanceHeader_lbl
                    }}
                  </div>
                </span>
                <div
                  *ngIf="isSummaryAPILoading; else showAccountBalanceContent"
                ></div>
                <ng-template #showAccountBalanceContent>
                  <ng-container *ngIf="summaryError; else showAccountBalance">
                    <div class="error-container">
                      <span class="error"
                        ><i class="motif-icon motif-delete"></i>
                        <span class="motif-margin-left-5">
                          <span
                            class="summaryError"
                            [innerHtml]="summaryError"
                          ></span>
                        </span>
                      </span>
                    </div>
                  </ng-container>
                  <ng-template #showAccountBalance>
                    <div id="pwrAcctAcctBalanceAmount">
                      <p class="amount">
                        {{ displayPASummary.annualPowerAccountContribution }}
                      </p>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="current-bill">
            <div class="columns current-bill-summary">
              <div class="current-bill-label">
                <div class="pwrAccSummaryLbls motif-margin-bottom-7">
                  {{ content.PwrAcctSummary_lbls.PwrAcctCurrentAccountBalance }}
                </div>
                <p class="description">
                  {{
                    content.PwrAcctSummary_lbls
                      .PwrAcctCurrentAccountBalanceDescription
                  }}
                </p>
                <p class="amountCurrent">
                  {{ displayPASummary.remainingBalance }}
                </p>
              </div>
              <div
                *ngIf="isSummaryAPILoading; else showCurrentBillContent"
              ></div>
              <ng-template #showCurrentBillContent>
                <ng-container *ngIf="summaryError; else showCurrentBill">
                  <div class="error-container">
                    <span class="error"
                      ><i class="motif-icon motif-delete"></i>
                      <span class="motif-margin-left-5">
                        <span
                          class="summaryError"
                          [innerHtml]="currentBillError"
                        ></span>
                      </span>
                    </span>
                  </div>
                </ng-container>
              </ng-template>
              <div class="current-bill-due-label">
                <div class="pwrAccSummaryLbls">
                  {{ content.PwrAcctSummary_lbls.PwrAcctCurrentBill }}
                </div>
                <p class="current-bill-due">
                  {{ currentBillRes.currentBill }}
                </p>
              </div>
              <div class="make-payment-btn payment-btn" *ngIf="hasMakePayment">
                <button
                  id="makePayment"
                  class="btn btn-primary payment-btn"
                  (click)="makeAPayment()"
                >
                  {{ content.PwrAcctSummary_lbls.PwrAcctMakePymt_btn }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>