import { Component } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: '[app-chatbot-idcard-flipper-cmp]',
  template: '<div class="tcp-id-card-flipper" [class.flipped]="flipped"><ng-content></ng-content></div>'
})
export class ChatbotIdCardFlipperComponent {
  flipped = false;
}
