import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import {
  EnterSecurityCodeDesignee,
  ErrorMsgs,
  Loaders,
} from 'sydmed/src/app/secure/shm-my-profile/models/labels';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { MemberRepRegistrationService } from '../service/memberRepRegistration.service';
import {
  AlertService,
  UniversalAlertType,
  InlineAlertContainerComponent,
} from '@anthem/uxd/alert';
import { RepresentativeValidateOtpRequest, RepresentativeSendOtpRequest } from 'gbd-models';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { StorageService, SessionStorage } from 'sydmed/libs/storage-service/src/public-api';

@Component({
  selector: 'app-security-code',
  templateUrl: './security-code.component.html',
  styleUrls: ['./security-code.component.scss'],
})
export class SecurityCodeComponent implements OnInit {
  ValidateOneTimePasscodeForm: FormGroup;
  coderesent: boolean = false;
  incorrectSecurityCode: boolean = false;
  public labels: EnterSecurityCodeDesignee;
  public errorMessages: ErrorMsgs;
  public textNumber: string;
  public loading: boolean = false;
  public showAlreadyHaveACode: true;
  public loaders: Loaders;
  public errorMessage: string;
  public successMessage: string;
  public showSecurityCodePage: boolean = true;
  // Errors
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  @ViewChild('memberRepRegAlert')
  memberRepRegAlert: InlineAlertContainerComponent;
  constructor(
    private alertSvc: AlertService,
    private errCodeMapSvc: ErrorCodeMapperService,
    private formBuilder: FormBuilder,
    private jsonSvc: JsonContentService,
    private memberRepRegistrationService: MemberRepRegistrationService,
    private router: Router,
    private storage: StorageService
  ) {}

  ngOnInit(): void {
    this.ValidateOneTimePasscodeForm = this.formBuilder.group({
      secCode: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });
    this.setLabels();
    this.setTextNumber(); 
  }

  setLabels = () => {
    this.jsonSvc.getJSON('member-representative').subscribe((jsonResults) => {
      const { Labels } = jsonResults?.MemberRepRegistration;
      this.errorMessages = jsonResults.MemberRepRegistration?.ErrorMsgs;
      this.labels = Labels.Enter_Security_Code; 
      this.loaders = Labels.MemberRep_Registration.Loaders;
    });
  };

  /**
   * @Description resendSecurityCode method used to resend the security code to respective phone number.
   * @param  {} void 
   * @returns void
   */
  public resendSecurityCode(): void {
    this.ValidateOneTimePasscodeForm.reset();
    const requestBody: RepresentativeSendOtpRequest = {
      linkToken: sessionStorage.getItem('memberRepToken'),
      phone: this.storage.getSessionItem<string>(SessionStorage.REGISTERED_PHONE_NUMBER)
    };      
    this.loading = true;
    if (this.errorMessage || this.successMessage) {
      this.clearAlert(this.memberRepRegAlert);
    }
    this.memberRepRegistrationService.sendOtp(requestBody).subscribe(
      (res) => {
        this.successMessage = this.labels?.Resend_Code_Success;
        this.handleSuccessResponse();
      },
      (error) => {
        this.handleErrorResponse(error);
      }
    );
  }

  /**
   * @Description validateOtp method used to validate the otp
   * @param  {FormGroup} form
   * @returns void
   */
  validateOtp(form: FormGroup): void {
    if (form.valid) {
      const requestBody: RepresentativeValidateOtpRequest = {
        linkToken: sessionStorage.getItem('memberRepToken'),
        otpCode: form.value.secCode,
      };
      this.loading = true;
      if (this.errorMessage || this.successMessage) {
        this.clearAlert(this.memberRepRegAlert);
      }
      this.memberRepRegistrationService.validateOtp(requestBody).subscribe(
        (res) => {
          this.loading = false;
          this.router.navigate([
            'public/registration/member-representative/registration',
          ]);
        },
        (error) => {
          this.handleErrorResponse(error);
        }
      );
    } else {
      this.ValidateOneTimePasscodeForm.markAllAsTouched();
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      invalidFields[1].focus();
    }
  }

  previousPage() {
    sessionStorage.setItem('alreadyHaveSecurityCode', 'true');
    this.router.navigate([
      'public/registration/member-representative/phone-verification',
    ]);
  }

  setTextNumber() {
    this.textNumber = this.storage.getSessionItem<string>(SessionStorage.REGISTERED_PHONE_NUMBER)?.slice(-4);
  }

  handleErrorResponse(error: any): void {
    this.loading = false;
    this.errorMessage = this.errCodeMapSvc.errorCodeMapper(
      error,
      this.errorMessages
    );
    this.validateOtpErrorAlert(this.errorMessage);
  }

  handleSuccessResponse(): void {
    this.loading = false;
    this.alertSvc.success(this.successMessage, {
      regionName: 'member-rep-validate-otp-alert',
      politeness: 'assertive',
      isDissmisable: true,
    });
    this.scrollToTop();
  }

  /**
   * @Description validateOtpErrorAlert method used to alert error message
   * @param  {any} error
   * @returns void
   */
  validateOtpErrorAlert(error: string): void {
    if (error && this.errorMessages) {
      this.alertSvc.error(error, {
        regionName: 'member-rep-validate-otp-alert',
        politeness: 'assertive',
        isDissmisable: true,
      });
      this.scrollToTop();
    }
  }

  // Method used to scroll top of the page
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  /**
   * @Description clearErrorAlert method used to clear error stack throw from service failure
   * @returns void
   */
  clearAlert(alertType: InlineAlertContainerComponent): void {
    alertType.dismissAlertId(0);
  }
}
