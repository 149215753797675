import { AlertService } from '@anthem/uxd/alert';

export class AccordionProperties {

    constructor(
        private alertSvc: AlertService
    ) { }

    scrollToTop(): void {
        window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
        });
    };
    
    onKeyDown(event: KeyboardEvent, expanded: boolean, form) {
        if(event.code === 'Space' || event.code === 'Enter') {
            if(!expanded) {
            form.resetForm();
            }     
        }
    }

    onClickExpansion(expanded: boolean, formDir) {
        if(!expanded) {
            formDir.resetForm();
        } 
    }

    expandDiv(directive, form, formPanel) {
        form.reset();   
        directive.resetForm();
        formPanel.nativeElement.querySelector('button').click();
    }

    resetFormWithValues(form) {
        form.reset(form.value);
    }

    successAlert(label: string, regionName: string) {
        this.alertSvc.success(label, {
            regionName: regionName,
            isDissmisable: true
        });
    }
}