import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PriorAuthDataService } from '../prior-auth-data.service';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { PriorAuthorizationLabels, PriorAuthorizationErrorMessages } from '../models/labels';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';

@Component({
  selector: 'app-medical',
  templateUrl: './medical.component.html',
  styleUrls: ['./medical.component.scss']
})
export class MedicalComponent implements OnInit, AfterViewInit {

  constructor(
    private dataSvc: PriorAuthDataService,
    private httpSvc: HttpClientService,
    private errCodeSvc: ErrorCodeMapperService,
  ) { }

  filterDropDown: any;
  filterModelValue: string;

  public labels: PriorAuthorizationLabels;
  public errorMsgs: PriorAuthorizationErrorMessages;
  public authList: any;
  public summaryDataLoaded: boolean;
  public summaryError: boolean;
  public summaryErrorMsg: string;
  public details: Array<any>;
  public detailsDataLoaded: boolean;
  public detailsError: boolean;
  public detailsErrorMsg: string;

  @ViewChild('outpatientCareType') outpatientCareType: ElementRef;
  @ViewChild('inpatientCareType') inpatientCareType: ElementRef;
  @ViewChild('filterPanelButton') filterPanelButton: ElementRef;

  ngOnInit(): void {
    this.details = [];
    this.labels = this.dataSvc.labels;
    this.errorMsgs = this.dataSvc.errorMsgs;
    this.setupFilterDropdown();
    const dateRange = this.getDateRange('3');
    this.getSummary('outpatient', dateRange.fromDate, dateRange.currentDate);    
  }

  ngAfterViewInit() {
    this.resetFilterParameters();
  }

  private setupFilterDropdown() {
    this.filterDropDown = {
      title: this.labels.filterDateRange,
      options: [
        {
          label: this.labels.filter3Months,
          value: "3"
        },
        {
          label: this.labels.filter6Months,
          value: "6"
        },
        {
          label: this.labels.filter9Months,
          value: "9"
        },
        {
          label: this.labels.filter12Months,
          value: "12"
        }
      ]
    };

    this.filterModelValue = this.filterDropDown.options[0].value;
  }

  private getDateRange(months) {
    let currentDate = new Date();
    let fromDate = new Date();
    let currentDateStr = '';
    let fromDateStr = '';

    fromDate.setMonth(fromDate.getMonth() - months);
    
    currentDateStr = currentDate.getFullYear() + '-' +
                  ("0" + (currentDate.getMonth() + 1)).slice(-2) + '-' +
                  ("0" + currentDate.getDate()).slice(-2);
    
    fromDateStr = fromDate.getFullYear() + '-' +
                  ("0" + (fromDate.getMonth() + 1)).slice(-2) + '-' +
                  ("0" + fromDate.getDate()).slice(-2);
    
    return {
      'currentDate': currentDateStr,
      'fromDate': fromDateStr
    };
  }

  private getSummary(authType: string, fromDate: string, toDate: string) {
    this.summaryDataLoaded = false;
    this.summaryError = false;
    const endpoint = UrlProperties.UrlProperties.endPoints.authorizationStatus.summary + '?authType=' + authType + '&fromDate=' + fromDate + '&toDate=' + toDate;
    this.httpSvc.getObservable(endpoint).subscribe(
      (res: any) => {
        this.authList = res.body.authSummaryList;
        this.summaryDataLoaded = true;
      },
      (err: any) => {
        this.summaryError = true;
        this.summaryErrorMsg = this.errCodeSvc.errorCodeMapper(err, this.errorMsgs);
      }
    );
  }

  public getDetails(authType: string, authId: string, expanded: boolean, index: number) {
    // Do nothing if the details have already been loaded.
    if (expanded) {
      return;
    }

    if (this.details[index] !== undefined) {
      return;
    }

    // Load prior auth details
    this.detailsDataLoaded = false;
    this.detailsError = false;
    const endpoint = UrlProperties.UrlProperties.endPoints.authorizationStatus.details + '?authType=' + authType + '&authID=' + authId;
    this.httpSvc.getObservable(endpoint).subscribe(
      (res: any) => {
        this.details[index] = res.body;
        this.detailsDataLoaded = true;
      },
      (err: any) => {
        this.detailsError = true;
        if (err.error && err.error.message) {
          this.detailsErrorMsg = err.error.message;
        } else if (err.error && err.error.Message) {
          this.detailsErrorMsg = err.error.Message;
        }
      }
    );
  }

  public applyFilter() {
    // Get the Auth Type and Date Range from the Filter Panel
    let authType = 'outpatient';
    if (this.outpatientCareType.nativeElement.getAttribute('checked') === 'true') {
      authType = 'outpatient';
    } else if (this.inpatientCareType.nativeElement.getAttribute('checked') === 'true') {
      authType = 'inpatient';
    }

    const dateRange = this.getDateRange(this.filterModelValue);

    // Close the Filter Panel
    this.filterPanelButton.nativeElement.click();

    // Get the Summary Data using the Filter parameters
    this.getSummary(authType, dateRange.fromDate, dateRange.currentDate);
  }

  public closeFilterPanel() {
    this.filterPanelButton.nativeElement.click();
  }

  public resetFilterParameters() {
    // Set Outpatient as the default
    this.outpatientCareType.nativeElement.click();

    // Set Date Range to first value
    this.filterModelValue = this.filterDropDown.options[0].value;
  }

}
