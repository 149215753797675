import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { AuthEventsService } from 'sydmed/libs/authorization/authevents.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { ICheckboxInput } from '@anthem/uxd/util';
import { Modal, ModalRef } from '@anthem/uxd/modal';
import { TermsOfUseType, AcceptedTermsOfUseRequest, MemberType, Restriction} from 'gbd-models'
import { TermsOfUseService } from '../services/termsOfUse.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
@Component({
  selector: 'app-answer-security-questions',
  templateUrl: './answer-security-questions.component.html',
  styleUrls: ['./answer-security-questions.component.css']
})
export class AnswerSecurityQuestionsComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private touModal: Modal,
    private router: Router,
    private jsonSvc: JsonContentService,
    private termsOfUseSvc: TermsOfUseService,
    private errCodeSvc: ErrorCodeMapperService,
    private eventSvc: AuthEventsService
  ) { }

  AnswerSecurityQuestionsForm: FormGroup;
  public jsonLabels;
  public jsonErrors;
  public aslError: string;
  public endpoint = UrlProperties.UrlProperties.endPoints.login.answerSecurityQuestions;
  public checkbox: ICheckboxInput;
  private touModalRef: ModalRef<any, any>;
  public isChecked: boolean = false;
  public isSubmitted: boolean = false;
  public showTOU: boolean = false;
  public loadingSpinner = false;
  @ViewChild('touModalTemplate', { static: false }) touModalTemplate: TemplateRef<any>;

  ngOnInit(): void {
    this.jsonSvc.getJSON('login').subscribe(data => {
      this.jsonLabels = data.LoginMFA.Labels.Security_Questions;
      this.jsonErrors = data.LoginMFA.ErrorMsgs;
      this.checkbox = {
        id: "tou-checkbox",
        name: "tou-checkbox",
        label: this.jsonLabels?.PrivacyPolicyandTermsofUseModal.AgreeInfo,
        isRequired: true,
        trueValue: true,
        falseValue: false,
      };
    });
    this.AnswerSecurityQuestionsForm = this.formBuilder.group({
      sendCodeOption: ['', [Validators.required]],
    });
    this.showTOU = JSON.parse(sessionStorage.getItem('contactsModel')).memberResponse.showTOU;
  }

  openTouModal() {
    this.touModalRef = this.touModal.open(this.touModalTemplate);
}

onClose() {
  this.isSubmitted = false;
  this.isChecked = false;
  this.touModalRef.close();
  this.touModalRef.onDismiss.unsubscribe();
  this.touModalRef.onClose.unsubscribe();
}

onUpdateTou() {
  if(this.isChecked){
  const requestObj:AcceptedTermsOfUseRequest  = {
    touType : TermsOfUseType.GBD_TOU

  }
  this.onClose();
  this.loadingSpinner = true;
  this.termsOfUseSvc.updateTOU(requestObj).subscribe(
    (res: any) => {
      this.loadingSpinner = false;
      this.router.navigate(["/public/login/secure-login"]);
    },
    (err) => {
      this.loadingSpinner = false;
      this.aslError = this.errCodeSvc.errorCodeMapperV2(err, this.jsonErrors);
    }
  );
  }else{
    this.isSubmitted = true;
  }
}

  public verifyQuestions(response) {
    this.loadingSpinner = true;
    window.sessionStorage.setItem('sydMedMemberContext', JSON.stringify(response.body));
    window.sessionStorage.setItem('sydMedLoggedIn', 'true');
    window.sessionStorage.setItem('gbd-token', response.headers.get('gbd-token'));
    if (response && response.body && response?.body?.restrictions?.find(restriction => restriction === Restriction.CAREGIVER_REATTEST)) {
      this.loadingSpinner = false;
      this.router.navigate(['/public/login/caregiver-attestation']);
    } else {
      if (this.showTOU && response?.body?.accountType === MemberType.DESIGNEE) {
        this.loadingSpinner = false;
        this.openTouModal();
      } else {
        this.loadingSpinner = false;
        this.router.navigate(["/public/login/secure-login"]);
      }      
    }
  }

  public backButtonClicked(hasContactInfoAvailable) {
    if (hasContactInfoAvailable) {
      this.router.navigate(['/public/login/get-security-code']);
    } else {
      this.router.navigate(['/public/login']);
    }
  }
}
