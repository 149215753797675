import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-file-details",
  templateUrl: "./file-details.component.html",
  styleUrls: ["./file-details.component.scss"],
})
export class FileDetailsComponent implements OnInit {
  @Input() fileData: any;
  @Input() messageType = "";
  @Output() fileDelete: EventEmitter<any> = new EventEmitter(null);
  @Output() attachedFile: EventEmitter<any> = new EventEmitter(null);

  constructor() {}

  ngOnInit(): void {}

  deleteFile(fileDetails: object) {
    this.fileDelete.emit(fileDetails);
  }

  attchmentClick(file) {
    this.attachedFile.emit(file);
  }
}
