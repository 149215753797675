import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VcRecaptchaService {

  constructor() { }

  onLoadFunctionName = 'vcRecaptchaApiLoaded';
  response;
  private recaptchaObject;
  
  public getResKey() {
    return this.response;
  }

  public throwNoKeyException() {
    throw new Error('You need to set the "key" attribute to your public reCaptcha key. If you don\'t have a key, please get one from https://www.google.com/recaptcha/admin/create');
  }

  addRecaptchaScript(sitekey, recaptchaElement) {
      window['vcRecaptchaApiLoaded'] = () => {
      this.response = this.renderReCaptcha(sitekey, recaptchaElement);
    }
   
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(sitekey, recaptchaElement); return;}
      js = d.createElement(s); js.id = id;

      let collection = document.getElementsByTagName("script");
      for(let i = 0; i < collection.length; i++ ) {
        if (collection[i].hasAttribute('async') && collection[i].hasAttribute('defer') && collection[i].src.startsWith('h')) {
          js.src = collection[i].src;
        }
      }

      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }
  
  renderReCaptcha(sitekey, recaptchaElement) {
    setTimeout(function () {
      let iframe = document.getElementById('recaptcha').children[0].children[0].children[0];
      iframe.removeAttribute("role");
      if (sessionStorage.getItem("localeOverride") === "es-US" && iframe.getAttribute("src").indexOf('en') > -1) {
        iframe.setAttribute("src", iframe.getAttribute("src").replace("en", "es"));
      } else if (sessionStorage.getItem("localeOverride") !== "es-US" && iframe.getAttribute("src").indexOf('es') > -1) {
        iframe.setAttribute("src", iframe.getAttribute("src").replace("es", "en"));
      }
    }, 2000);
    try {
      
      window['grecaptcha'].render(recaptchaElement.nativeElement, {
        'sitekey' : sitekey,
        'callback': (response) => {
            this.recaptchaObject = response;
        },
        'expired-callback': this.timedReload
      });
    } catch(error) {}
  }
  
  timedReload() {
    //only for mobile devices of certain widths to fix recaptcha render size
    if (window.innerWidth >= 400 && window.innerWidth <= 440) {
      setTimeout(function () {
        let iframe = document.getElementById('recaptcha').children[0].children[0].children[0];
        let iframeSrc =  iframe.getAttribute('src');
        iframe.setAttribute('src', iframeSrc);

      }, 2000);
    }
  }
  
  private setRecaptchaResponse(recaptcha) {
    this.recaptchaObject = recaptcha;
  }

  getRecaptchaResponse() {
    return this.recaptchaObject;
  }

  reload(recaptchaElement) {
    window['grecaptcha'].reset(recaptchaElement);
  }

}
