import { NgModule } from '@angular/core';
import { PhoneNumPipe } from './phone-number/phone-num.pipe';
import { DatePipe } from './date/date.pipe';
import { StringInterpolationPipe } from './string-interpolation/string-interpolation.pipe';
import { ShowCurrentTimePipe } from './show-current-time/show-current-time.pipe';
import { DateStringPipe } from './date-string/date-string.pipe';
import { TimeFormat } from './date/time-format.pipe';

@NgModule({
  declarations: [PhoneNumPipe, DatePipe, DateStringPipe, StringInterpolationPipe, ShowCurrentTimePipe, TimeFormat],
  imports: [
  ],
  exports: [PhoneNumPipe, DatePipe, DateStringPipe, StringInterpolationPipe, ShowCurrentTimePipe, TimeFormat]
})

export class CommonPipesModule { }
