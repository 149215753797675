<div class="ctrlHolder errorMessages container" *ngIf="showError">
    <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span [innerHTML]="error"></span>
    </div>
</div>

<div id="grievance_id" class="form-preview uxd-theme-motif container" *ngIf="!showError">
    <h2 class="section-header-two" [innerHtml]="formLabels?.Header"></h2>
    
    <br>
    <p [innerHtml]="formLabels?.Intro1"></p>
    <br>
    <p [innerHtml]="formLabels?.Intro2"></p>
    <br>

    <button type="button" id="ReturnToProfile_btn" class="btn" [innerHtml]="formLabels?.ReturnToProfile_btn" (click)="returnToProfile()"></button>
    <br>

    <br>
    <h3 [innerHtml]="formLabels?.IntroHeader"></h3>
    <br>
    <p [innerHtml]="formLabels?.Intro3"></p>
    <br>

    <form (ngSubmit)="sendForm()">
        <div class="ctrlHolder">
            <label for="memberName" [innerHtml]="formLabels?.Name"></label>
            <input id="memberName" name="memberName" type="text" class="ant-text-input" [value]="previewData?.memberName" disabled>
        </div>

        <br>

        <div class="ctrlHolder">
            <label for="memberId" [innerHtml]="formLabels?.MemberId"></label>
            <input id="memberId" name="memberId" type="text" class="ant-text-input" [value]="previewData?.memberId" disabled>
        </div>

        <br>

        <div class="ctrlHolder">
            <label for="memberDob" [innerHtml]="formLabels?.MemberDob"></label>
            <input id="memberDob" name="memberDob" type="text" class="ant-text-input" [value]="previewData?.memberDob" disabled>
        </div>

        <br>

        <div class="radio-btn-group" disabled="true" [(ngModel)]="contactRadioVal" [ngModelOptions]="{standalone: true}" name="contactRadioValue" data-uxd-radio-input-group-cmp [radioGroup]="contactRadioGroup"></div>

        <br>

        <div class="ctrlHolder">
            <label for="emailAddress" [innerHtml]="formLabels?.EmailAddress"></label>
            <input id="emailAddress" name="email" type="text" class="ant-text-input" [value]="previewData?.email" disabled>
        </div>

        <br>

        <div class="ctrlHolder">
            <label for="phoneNumber" [innerHtml]="formLabels?.PhoneNumber"></label>
            <input id="phoneNumber" name="phone" type="text" class="ant-text-input" [value]="previewData?.phoneNumber" disabled>
        </div>

        <br>

        <div class="radio-btn-group" disabled="true" [(ngModel)]="grievanceForRadioVal" [ngModelOptions]="{standalone: true}" name="grievanceForRadioValue" data-uxd-radio-input-group-cmp [radioGroup]="grievanceForRadioGroup"></div>

        <br>

        <div class="ctrlHolder">
            <label for="grievanceForPerson" [innerHtml]="formLabels?.OtherName"></label>
            <input id="grievanceForPerson" name="grievanceForPerson" type="text" class="ant-text-input" [value]="previewData?.grievanceForPerson" disabled>
        </div>

        <br>

        <div class="ctrlHolder">
            <label for="claimNumber">{{ formLabels?.ClaimNumber }} <span class="optional">{{ formLabels?.Optional }}</span></label>
            <input id="claimNumber" name="claimNumber" type="text" class="ant-text-input" [value]="previewData?.claimNumber" disabled>
        </div>

        <br>

        <div class="ctrlHolder">
            <label for="providerName">{{ formLabels?.Doctor }} <span class="optional">{{ formLabels?.Optional }}</span></label>
            <input id="providerName" name="providerName" type="text" class="ant-text-input" [value]="previewData?.providerName" disabled>
        </div>

        <br>

        <div class="ctrlHolder">
            <label for="dateOfService">{{ formLabels?.ServiceDate }} <span class="optional">{{ formLabels?.Optional }}</span></label>
            <input id="dateOfService" name="dateOfService" type="text" class="ant-text-input" [value]="previewData?.dateOfService" disabled>
        </div>

        <br>

        <div class="ctrlHolder bigger">
            <label for="grievanceDetail">{{ formLabels?.MoreInformation }} <span class="optional">{{ formLabels?.Optional }}</span></label>
            <textarea id="grievanceDetail" name="grievanceDetail" [value]="previewData?.grievanceDetail" disabled></textarea>
        </div>

        <br>

        <p [innerHtml]="formLabels?.BtnExplanation"></p>

        <br>

        <div class="ctrlHolder button-group text-right">
            <button type="button" id="Edit_btn" class="btn btn-secondary" [innerHtml]="formLabels?.Edit_btn" [routerLink]="['/secure/grievances-form']"></button>
            <button type="submit" id="Send_btn" class="btn" [innerHtml]="formLabels?.Send_btn"></button>
            <button type="button" id="Cancel_btn" class="btn btn-secondary" [innerHtml]="formLabels?.Cancel_btn" (click)="cancelForm()"></button>
        </div>

    </form>
    <br><br>
    <p>{{ formLabels?.DisclaimerPt1 }}<a href="{{ formLabels?.DisclaimerUrl }}" target="_blank">{{ formLabels?.DisclaimerUrlText }}</a>{{ formLabels?.DisclaimerPt2 }}</p>
    <br><br>
</div>