import { Component, Input, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Router } from '@angular/router';
import { MenuItem } from '../model/labels';
import { DasboardService } from '../../dashboard/services/dashboard.service';
import { AlertApiData } from '../../dashboard/interfaces/iAlerts';

@Component({
    selector: '[app-dashboard-v2-menu]',
    templateUrl: './new-dashboard-menu.component.html',
    styleUrls: ['./new-dashboard-menu.component.scss']
})
export class NewDashboardMenuComponent implements OnInit {

    @Input() public menuData: MenuItem[] = [];
    @Input() public isTermedMember: boolean = false;
    public benefitsRenewData: any;
    public apiData: AlertApiData;
    public memberStatus : any;
    private publicBenefitUrl: string;

    constructor(
        private jsonSvc: JsonContentService,
        private router: Router,
        private dashBoardSvc: DasboardService
    ) { }

    ngOnInit() {
        this.getRenewalAlertData();
        this.jsonSvc.getJSON('new-dashboard').subscribe(data => {
            this.benefitsRenewData = data.dashboard.benefitsRenewData;
            this.memberStatus = data?.dashboard?.memberStatus;
            this.publicBenefitUrl = data?.dashboard?.benefitPublicUrl;
        });
    }

    navigateTo(route: string) {
        if (this.isTermedMember) {
            const memberContext =  JSON.parse(
                window.sessionStorage.getItem("sydMedMemberContext")
              );
            const planCode = memberContext.planCode;
            const publicPageLink = planCode ? (this.publicBenefitUrl[planCode] ? this.publicBenefitUrl[planCode] : this.publicBenefitUrl['default']) : this.publicBenefitUrl['default'];
            window.open(publicPageLink, '_blank').focus();
        } else {
            this.router.navigate([route]);
        }
    }

    onRenewButtonClick(event: MouseEvent, url: string): void {
        event.preventDefault();
        window.open(url, "_blank");
    }

    getRenewalAlertData() {
        this.dashBoardSvc.getalertsData()
            .then((response: any) => {
                if (response && response.body) {
                    this.apiData = response.body;
                }
            },
                error => {
                    this.apiData = { alerts: '', renewalDate: '' };
                })
    }
}