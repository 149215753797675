import { Injectable } from "@angular/core";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";

@Injectable({
  providedIn: "root",
})
export class EmulationService {
  constructor(private httpClient: HttpClientService) {}

  emulateUser(data) {
    const url = UrlProperties.UrlProperties.endPoints.emulation;
    return this.httpClient.postObservable(url, data);
  }
}
