import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { map, catchError } from 'rxjs/operators';
import { throwError, BehaviorSubject, of } from 'rxjs';

const medicalProfileApi = UrlProperties.UrlProperties.endPoints.medicalProfile.medicalProfile;

@Injectable({
  providedIn: 'root'
})
export class MedicalProfileService {
  protected profileComplete$ = new BehaviorSubject<boolean>(null);
  profileComplete = this.profileComplete$.asObservable();

  constructor(protected http: HttpClientService) { }

  setProfileComplete(complete: boolean) {
    this.profileComplete$.next(complete);
  }

  getMedicalProfileExists() {
    let profileSetup = JSON.parse(window.sessionStorage.getItem('medicalProfileComplete'));
    if(!(profileSetup === 'undefined' ||  profileSetup === null)){
      this.setProfileComplete(profileSetup);
      return of(profileSetup);
    }

    return this.http.getObservable(medicalProfileApi).pipe(map(res => {
      this.setProfileComplete(res.body.medProfiles.medicalProfileSetup);
      window.sessionStorage.setItem('medicalProfileComplete', res.body.medProfiles.medicalProfileSetup);
      return res.body.medProfiles.medicalProfileSetup as boolean;
    }),
      catchError(error => throwError(error))
    );
  }

}
