import { AlertService } from '@anthem/uxd/alert';
import { CheckBox } from './labels';

export class HelperProperties {

    constructor(
        private alertSvc: AlertService,
    ) { }

    scrollToTop(): void {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    setCheckboxParams(id: string, name: string, label: string): CheckBox {
        return {
            id: id,
            name: name,
            label: label,
            trueValue: true,
            falseValue: false
        };
    }
}