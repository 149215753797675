// Angular Imports
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// Message Center Imports
import { MessageCenterLabels } from './models/labels.interface';
import { MessageCenterErrorMessages } from './models/error-messages.interface';

// Motif Imports
import { ModalRef } from '@anthem/uxd/modal';
import { Restriction } from 'gbd-models';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';

@Injectable({
  providedIn: 'root'
})

export class MessageCenterDataService {

  constructor( private jsonSvc: JsonContentService) { }

  private _labels: MessageCenterLabels;
  private _errorMsgs: MessageCenterErrorMessages;
  private _firstName: string;
  private _lastName: string;
  private _fullName: string;
  private _restrictions: Array<string>;

  private memberName: string;
  private errorMessages: string;
  private _composeMessageSidePanel: ModalRef<any, any>;
  private _replyMessageSidePanel: ModalRef<any, any>;


  private folder: BehaviorSubject<string> = new BehaviorSubject<string>('inbox');
  private unreadCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private transcriptUnreadCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private sortBy: BehaviorSubject<object> = new BehaviorSubject<object>({ sort: 'received', order: 'desc' });
  private showSortByDropdown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _messageDetails: object;

  public set labels(labels: MessageCenterLabels) {
    this._labels = labels;
  }

  public get labels(): MessageCenterLabels {
    return this._labels;
  }

  public set errorMsgs(errorMsgs: MessageCenterErrorMessages) {
    this._errorMsgs = errorMsgs;
  }

  public get errorMsgs(): MessageCenterErrorMessages {
    return this._errorMsgs;
  }

  public set firstName(firstName: string) {
    this._firstName = firstName;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public set lastName(lastName: string) {
    this._lastName = lastName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get fullName(): string {
    if (this._firstName && this._lastName) {
      this._fullName = this._firstName + ' ' + this._lastName;
    }
    return this._fullName;
  }

  public set restrictions(restrictions: Array<string>) {
    this._restrictions = restrictions;
  }

  public get restrictions(): Array<string> {
    return this._restrictions;
  }

  public set composeMessageSidePanel(composeMessageSidePanel: ModalRef<any, any>) {
    this._composeMessageSidePanel = composeMessageSidePanel;
  }

  public get composeMessageSidePanel(): ModalRef<any, any> {
    return this._composeMessageSidePanel;
  }

  public set replyMessageSidePanel(replyMessageSidePanel: ModalRef<any, any>) {
    this._replyMessageSidePanel = replyMessageSidePanel;
  }

  public get replyMessageSidePanel(): ModalRef<any, any> {
    return this._replyMessageSidePanel;
  }

  public setfolder(folder: string) {
    this.folder.next(folder);
  }

  public getfolder() {
    return this.folder;
  }

  public setUnreadCount(unreadCount) {
    this.unreadCount.next(unreadCount);
  }

  public setTranscriptUnreadCount(transcriptUnreadCount) { 
    this.transcriptUnreadCount.next(transcriptUnreadCount);
  }

  public getUnreadCount() {
    return this.unreadCount;
  }

  public getTranscriptUnreadCount() {
    return this.transcriptUnreadCount;
  }
  
  public setShowSortByDropdown(value: boolean) {
    this.showSortByDropdown.next(value);
  }

  public getShowSortByDropdown() {
    return this.showSortByDropdown;
  }

  public set messageDetails(messageDetails: object) {
    this._messageDetails = messageDetails;
  }

  public get messageDetails(): object {
    return this._messageDetails;
  }

  public setSortBy(sortBy) {
    this.sortBy.next(sortBy);
  }

  public getSortBy() {
    return this.sortBy;
  }

  public setMemberName(memberName) {
    this.memberName = memberName;
  }

  public getMemberName() {
    return this.memberName;
  }

  public get hideComposeMessage(): boolean {
    return this.jsonSvc.hasRestriction(Restriction.SHM_MESSAGE_CENTER_VIEW_ONLY, this.jsonSvc.getRestrictions());
  }

  // checking logged user is termed
  public get checkForTermedRestrictions(): boolean {
    return this.jsonSvc.hasRestriction(Restriction.SHM_MESSAGE_CENTER_TERMED, this.jsonSvc.getRestrictions());
  }
}
