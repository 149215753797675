import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

@Component({
  selector: 'app-answer-security-questions',
  templateUrl: './answer-security-questions.component.html',
  styleUrls: ['./answer-security-questions.component.css']
})
export class AnswerSecurityQuestionsComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private jsonSvc: JsonContentService
  ) { }

  AnswerSecurityQuestionsForm: FormGroup;
  public jsonLabels;
  public jsonErrors;
  public endpoint = UrlProperties.UrlProperties.endPoints.forgotUsernamePassword.answerSecurityQuestions;

  ngOnInit(): void {
    this.jsonSvc.getJSON('forgot-username-password-mfa').subscribe(data => {
      this.jsonLabels = data.ForgotUsernamePasswordMFA.Labels.Security_Questions;
      this.jsonErrors = data.ForgotUsernamePasswordMFA.ErrorMsgs;
    });

    this.AnswerSecurityQuestionsForm = this.formBuilder.group({
      sendCodeOption: ['', [Validators.required]],
    });
    
   
  }

  public verifyQuestions(event) {
    this.router.navigate(['public/forgot-username-or-password/reset-password']);
  }
  
  public backButtonClicked(hasContactInfoAvailable: boolean) {
    if(hasContactInfoAvailable) {
      this.router.navigate(['public/forgot-username-or-password/get-security-code']);
    } else {
      this.router.navigate(['public/forgot-username-or-password/home']);
    }
  }

}
