import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TextTermsAndConditionsComponent } from './text-terms-and-conditions.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
  {
    path: 'secure/profile/pharmacytextprograms',
    component: TextTermsAndConditionsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TextTermsAndConditionsRoutingModule { }
