<div class="container alert-box"  id="dismissibleAlerts" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negetive'}">
  <span>{{ message }} </span>
  <a href="javascript:void(0)" routerLink='{{ link }}' tabindex="0" class="alert-link" [attr.aria-label]="ariaLabel">
    <span class="">{{ linkLabel }}</span>
  </a>
  <button aria-label="Dismiss alert" class="dismiss-button" type="button">
    <span aria-hidden="true">×</span>
  </button>
</div>

