import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class dashboardAppComponent {
  title = 'dashboard';

  constructor(private router: Router) {}
  
  ngOnInit() {
    this.router.navigateByUrl('/');
  }
    
}
