import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertType } from './AlertType';

@Component( {
  selector: 'app-dismissible-alert',
  templateUrl: './dismissible-alert.component.html',
  styleUrls: [ './dismissible-alert.component.scss' ]
} )
export class DismissibleAlertComponent implements OnInit {
  @Input() alertType: AlertType;
  @Input() alertName: string;
  @Input() ariaLabel: string;
  @Input() link: string;
  @Input() linkLabel: string;
  @Input() message: string;
  analyticsTag : string;

  @Output()
  closeAlert: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    if(this.alertName == 'MedicalProfile'){
      this.analyticsTag = 'profileAlertLandingRx';
    }else if(this.alertName == 'Email'){
      this.analyticsTag = 'updateEmailAlertLandingRx';
    }else{
      this.analyticsTag = 'commPrefAlertLandingRx';
    }
  }
  close() {
    this.closeAlert.emit(this.alertName);
  }

}
