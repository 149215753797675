import { Component, Input, OnInit } from '@angular/core';
import { CostShareContent } from '../models/CostShareContent';
import { Quarter } from '../models/Quarter';

@Component( {
  selector: 'app-out-of-pocket',
  templateUrl: './out-of-pocket.component.html',
  styleUrls: [ './out-of-pocket.component.scss' ]
} )
export class OutOfPocketComponent implements OnInit {
  @Input() content: CostShareContent;
  @Input() hasData: boolean;
  @Input() quarters: Quarter[];
  @Input() quarterTotal: number;
  @Input() annualTotal: number;
  @Input() annualCopay: number;
  @Input() isError: boolean;
  @Input() hasOnlyCopay: boolean;

  outOfPocketErrorMessage: string;
  quartersErrorMessage: string;
 constructor() { }

 ngOnInit(): void { 
  this.outOfPocketError();
 }

 outOfPocketError(){
  if (this.isError == true) {
    this.outOfPocketErrorMessage= this.content.errors.genericError;
    this.quartersErrorMessage= this.content.errors.quarterError;
  }
 }
}
