import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { ChatbotService } from 'sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service';

@Component({
  selector: 'app-connect-with-care',
  templateUrl: './connect-with-care.component.html',
  styleUrls: ['./connect-with-care.component.css']
})
export class ConnectWithCareComponent implements OnInit {

  constructor(
    private jsonSvc: JsonContentService,
    private chatBotSvc: ChatbotService
  ) { }
  public content = [];
  public benefitsLinks: string;
  public jsonLabels;
  public classId: string;
  public showconnectCare: boolean = true;
  planCode = '';
  memberContext: any;

  ngOnInit(): void {
    this.memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    this.jsonSvc.getJSON('connect-with-care').subscribe((data) => {
      this.jsonLabels = data.Labels;

      if (this.content) {
        this.content = [];
      }

      for (let i in data.items) {
        this.content.push(data.items[i])
      }
      const restrictions = this.jsonSvc.getRestrictions();
      if (this.jsonSvc.hasRestriction(Restriction.SHM_FIND_DOCTOR, restrictions)) {
        this.content.find(ele => ele.label === "Find a Doctor").enabled = false;
      }
      if (this.jsonSvc.hasRestriction(Restriction.SHM_CHANGE_PCP, restrictions)) {
        this.content.find(ele => ele.url === "/secure/pcp").enabled = false;
      }
      if (this.jsonSvc.hasRestriction(Restriction.SHM_LIVE_CHAT, restrictions)) {
        this.content.find(ele => ele.label === "Live Chat").enabled = false;
      }
      if (this.jsonSvc.hasRestriction(Restriction.SHM_ID_CARD_MENU, restrictions) || this.jsonSvc.hasRestriction(Restriction.VIEW_ID_CARD, restrictions)) {
        this.content.find(ele => ele.url === "/secure/id-card").enabled = false;
      }
      this.showconnectCare = !this.jsonSvc.hasRestriction(Restriction.SHM_CONNECT_WITH_CARE, restrictions);
    });
    this.classId = this.memberContext.classId;
    const stateLobs = ['DCMCD', 'GAMCD', 'IAMCD', 'TXMCD', 'TXSET', 'TNMCD', 'MDMCD', 'NJMCD', 'WAMCD', 'NYMCD', 'ARMCD', 'MNMCD', 'MNMMP', 'NCMCD'];
    if (stateLobs.includes(this.memberContext.stateLob)) {
      this.planCode = this.memberContext.planCode;
    }
  }

  isObject(val): boolean {
    return typeof val === "object";
  }

  liveChatPopUp(event) {
    this.chatBotSvc.launchChatBot(event);
  }
}
