import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

const endPoints = UrlProperties.UrlProperties.endPoints;

@Injectable({
  providedIn: 'root'
})
export class AccountBalanceService {

  protected pharmacyEndpoints = endPoints.pharmacy;

  amountPaid$: BehaviorSubject<number> = new BehaviorSubject<number>(0.00);
  amountPaid = this.amountPaid$.asObservable();
  constructor(protected http: HttpClientService) { }

  getAccountBalance() {
    const { accountBalanceApi } = this.pharmacyEndpoints;
    return this.http.getObservable(accountBalanceApi).pipe(map(res => {
      return res;
    }));
  }

  submitPayment(data, isPBM) {
    const { submitPBMPaymentUrl } = this.pharmacyEndpoints;
    const { submitSpecialtyPaymentUrl } = this.pharmacyEndpoints;
    return isPBM ? this.http.postObservable(submitPBMPaymentUrl, data) : this.http.postObservable(submitSpecialtyPaymentUrl, data);
  }
  setAmountPaid(amount: number) {
    this.amountPaid$.next(amount);
  }
}
