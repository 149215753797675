import { Component, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { ChatbotService } from 'sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { AhdDeliveryAddressesService } from '../services/ahd-delivery-addresses.service';
import { ContentHelper } from '@anthem/mbrportal/core';
import { NavigationEnd, Router  } from '@angular/router';

@Component({
  selector: 'app-ahd-review-order-landing',
  templateUrl: './ahd-review-order-landing.component.html',
  styleUrls: ['./ahd-review-order-landing.component.scss'],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }]
})
export class AhdReviewOrderLandingComponent implements OnInit {

  // CONTENT for specific page
  labels: any;
  loaderLabels: any;
  errorMsgs: any;

  isLoading: boolean = true;
  labelsLoading: boolean = false;
  profileContent: any;
  content: any;
  landingPageContent: any;
  vendorPrescriptionReviewContent: any;
  ahdPaymentWalletSummary: any;
  contentSubscription: Subscription;
  commSettingsContent: any;

  constructor(private jsonSvc: JsonContentService,
    private contentHelper: AHDContentHelper,
    private titleService: InterCommunicationService,
    private chatBotSvc: ChatbotService,
    private ahdDeliveryAddressesService: AhdDeliveryAddressesService,
    private router: Router
  ) { 
    router.events.subscribe((events) => {
      if (events instanceof NavigationEnd && events.id === 1) {
        this.router.navigate(['/secure/ahd-pharmacy/dashboard']);
      }
    });
  }


  ngOnInit(): void {
    this.ahdDeliveryAddressesService.getDeliveryAddress();
    this.getLabels();
  }

  getLabels() {
    this.contentSubscription = forkJoin([
      this.jsonSvc.getJSON('ahd-prescription-home'),
      this.jsonSvc.getJSON('ahd-order-status'),
      this.jsonSvc.getJSON('ahd-pharmacy-landing-page'),
      this.jsonSvc.getJSON('ahd-pharmacy-profile'),
      this.jsonSvc.getJSON('ahd-renew-refill')
    ]).subscribe(([prescriptionHomeContent, orderStatusContent, landingPageContent, profileContent, ProcessOrderComponent]) => {
      this.content = prescriptionHomeContent;
      this.landingPageContent = landingPageContent;
      this.profileContent = profileContent.pharmacyProfileCmp;
      this.vendorPrescriptionReviewContent = ProcessOrderComponent.ProcessOrderComponent;

      this.labels = ProcessOrderComponent.ProcessOrderComponent;
      this.loaderLabels = ProcessOrderComponent.Loaders;

      this.titleService.raiseEvent({ message: this.labels.pageTitle, title: 'HEADER_TITLE' });
      this.contentHelper.registerContent('VendorPageHeaderComponent', this.labels.checkoutPageHeader);
      this.contentHelper.registerContent('VendorDeliveryAddressSummaryComponent', ProcessOrderComponent.ProcessOrderComponent.deliveryAddress);
      this.contentHelper.registerContent('PharmacyProfileComponent', ProcessOrderComponent);
      this.contentHelper.registerContent('PrescriptionHomeComponentV2', this.content.PrescriptionHomeComponentV2);
      this.contentHelper.registerContent('VendorCurrentOrderStatusComponent', orderStatusContent);
      this.contentHelper.registerContent('VendorMedicalConditionComponent', ProcessOrderComponent.ProcessOrderComponent.healthConditionContent);    
      this.contentHelper.registerContent('VendorPrescriptionReviewComponent', ProcessOrderComponent.ProcessOrderComponent);
      this.contentHelper.registerContent('VendorAddressComponent', ProcessOrderComponent.ProcessOrderComponent.vendorAddressComponent);
      this.contentHelper.registerContent('RxShippingAddressComponent', ProcessOrderComponent.ProcessOrderComponent.rxShippingAddressComponent);
      this.contentHelper.registerContent('VendorPaymentWalletComponent', this.ahdPaymentWalletSummary);
      this.contentHelper.registerContent('VendorDeliveryDateComponent', ProcessOrderComponent.ProcessOrderComponent.deliveryDate);
      this.contentHelper.registerContent('VendorCommunicationSettingsSummaryComponent', this.commSettingsContent);
      this.contentHelper.registerContent('VendorAllergyComponent', ProcessOrderComponent.ProcessOrderComponent.vendorAllergy);
      this.contentHelper.registerContent('VendorManageSettingsComponent', ProcessOrderComponent.ProcessOrderComponent.vendorCommunicationSettings);
      this.contentHelper.registerContent('VendorContactDetailsComponent', ProcessOrderComponent.ProcessOrderComponent.vendorContactDetails);
    });
  }
  
}
