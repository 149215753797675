import { HttpParameterCodec, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from "@angular/core";
import { ContentHelper, IWindow } from "@anthem/mbrportal/core";
import { AppSession } from "@anthem/mbrportal/main";
import { BaseComponent } from "@anthem/mbrportal/shared/public_api";
import { Logger } from "@anthem/mbrportal/utility";
import { Subscription } from 'rxjs';
import { SsoService } from "./sso.service";
import { SsoEventService } from './ssoEvent.service';

@Component({
  selector: 'app-secure-sso-form',
  templateUrl: './ssoForm.component.html'
})
export class SecureSsoFormComponent extends BaseComponent {

    ssoParams: any;
    samlUrl: string;
    readyForSubmit: boolean = false;
    subscription1: Subscription;
  constructor(
    logger: Logger,
    contentHelper: ContentHelper,
    appSession: AppSession,
    private ssoEventSvc: SsoEventService,
    @Inject('Window') private _window: IWindow
  ) {
    super(logger, contentHelper, appSession);
    ssoEventSvc.ssoEvent.subscribe((value: any) => {
        this.handleEvent(value);
    });
  }

  ngOnDestroy() {
    if(this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  handleEvent(ssoEvent: any) {
      if(ssoEvent?.samlUrl && ssoEvent?.ssoParameters) {
        this.samlUrl = ssoEvent.samlUrl;
        let paramArray = [];
        Object.keys(ssoEvent.ssoParameters).forEach((key: any) => {
            let temp = {name: key, value: ssoEvent.ssoParameters[key]};
            paramArray.push(temp);
        });
        if(paramArray.length > 0) {
            this.ssoParams = paramArray;
        }
        this.processFormSubmit();
    }
  }

  get isMobileRequest(): boolean {
    if ((window?.location?.href?.toLowerCase().indexOf('ismobilerequest') >= 0)
    || (window.sessionStorage.getItem('isMobile') === 'true')) {
      return true;
    } 
      return false;
  }

  private processFormSubmit() {
    setTimeout(
      () => {
          (<any>this._window.document.forms)['ssoForm'].submit();
      },
      this._window.isIE ? 2000 : 100
    );
  }
}
