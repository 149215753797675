import { AttachmentErrorMsgsLabels, AttachFiles, DefaultLabels, DescribeYourSituation, IssueDescription, ErrorMsgs, GrievancesAndAppealsLabels, MemberInformationLabels,ConsentInformationLabels, ReasonForRequest, CancelModalPopup,HealthOptions } from './models/labels';
import { ChangeDetectorRef, Component, OnInit, ViewChild, TemplateRef, HostListener} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormType } from 'gbd-models';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { SidePanel, Modal, ModalRef } from '@anthem/uxd/modal';
import { Router, NavigationEnd } from '@angular/router';
import { FileAttachmentService } from 'sydmed/libs/file-attachment/services/file-attachment.service';
import { AlertService } from '@anthem/uxd/alert';
import { forkJoin } from 'rxjs';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { HelperService } from './utils/helper.service';
import { AttachmentActionType, Restriction } from 'gbd-models';

@Component({
  selector: 'app-appeals-and-grievances',
  templateUrl: './appeals-and-grievances.component.html',
  styleUrls: ['./appeals-and-grievances.scss'],
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class AppealsAndGrievancesComponent implements OnInit {

  // Form Labels
  public activePath: string;
  public appealsAndGrievancesForm: FormGroup =new FormGroup({});
  public attachmentActionType: AttachmentActionType;
  public attachFiles: AttachFiles;
  public cancelModalPopup: CancelModalPopup;
  public defaultLabels: DefaultLabels;
  public describeYourSituationLabels: DescribeYourSituation;
  public descriptionOfSpecificIssueLabels: IssueDescription; 
  public errorMsgsLabels: ErrorMsgs;
  public attachmentErrorMsgslabels: AttachmentErrorMsgsLabels;
  public formLabels: DefaultLabels;
  public labels: GrievancesAndAppealsLabels;
  public loading: boolean = false;
  public memberInformationLabels: MemberInformationLabels;
  public consentInformationLabels: ConsentInformationLabels;
  public reasonForRequestLabels: ReasonForRequest;
  public healthOptionLabels: HealthOptions;
  restrictions: string[];

  // MODAL
  @ViewChild('modalTemplate') modalTemplate: TemplateRef<any>;
  @ViewChild('cancelAppealsAndGrivencesAlert') cancelAppealsAndGrivencesAlert: TemplateRef<any>;
  @ViewChild('sidePanelContent', { static: false })
  sidePanelContent: TemplateRef<HTMLElement>;

  public modalRef: ModalRef<any, any>;
  public formValues: any;
  public editable: boolean = true;
  public fileTypes: string[];
  public previewScreenEnabled: boolean = false;
  public formError: boolean = false;
  public appealsAndGrievancesServiceError: any;
  public UploadAttachmentsEnabled: boolean;
  public sidePanelRef: ModalRef<any, any>;
  public slideDirection: any = 'right';

  constructor(
    private formBuilder: FormBuilder,
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private changeDetctionRef: ChangeDetectorRef,
    private router: Router,
    private modal: Modal,
    public alert: AlertService,
    public fileService: FileAttachmentService,
    private errCodeSvc: ErrorCodeMapperService,
    public helperService: HelperService,
    private sidePanel: SidePanel
    ) {
    this.activePath = this.router.url?.split("/")[2];
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: this.activePath === FormType.APPEALS.toLowerCase() ? 'Appeal Form' : 'Grievance Form'
    });

    // Appeals and Grievances FormGroup
    this.appealsAndGrievancesForm = this.formBuilder.group({
      memberInformation: ['', Validators.required],
      issueDescription: ['', Validators.required],
      consentInformation:['']
    });   

     // Add or Remove Form Controllers
     this.revokeFormControls();

     this.getJsonData();

     this.checkForRouteChange();

     this.restrictions = this.helperService.getRestrictions();
  }

  ngOnInit(): void {
    // GET Labels
    this.getLabels();

    // GET Error Labels
    this.getErrorMsgs();
    this.attachmentActionType = AttachmentActionType.GRIEVANCE_APPEAL;
    this.UploadAttachmentsEnabled = this.jsonSvc.hasRestriction(Restriction.SHM_GNA_UPLOAD_ATTACHMENT, this.restrictions);    
  }

  get memberInformation(){
    return this.appealsAndGrievancesForm.get("memberInformation");
  }

  getLabels(): void {
    this.loading = true;
    this.jsonSvc.getJSON('appeals-and-grievances').subscribe(response => {
      this.loading = false;
      this.defaultLabels = response.defaults;
      this.reasonForRequestLabels = response.reasonForRequest;
      this.memberInformationLabels = response.memberInformation;
      this.healthOptionLabels = response.healthOption;
      this.consentInformationLabels = response.consentInformation;
      this.descriptionOfSpecificIssueLabels = response.descriptionOfSpecificIssue; 
      this.describeYourSituationLabels = response.describeYourSituation;
      this.labels = (this.activePath === FormType.APPEALS.toLowerCase()) ? response.appeals : response.grievances;
      this.attachFiles = response.attachFiles;
      this.cancelModalPopup = response.cancelModalPopup;
    });
  }

  revokeFormControls(){
    if(this.activePath === FormType.APPEALS.toLowerCase()){
      this.appealsAndGrievancesForm.addControl('describeYourSituation', new FormControl('', Validators.required));
      this.appealsAndGrievancesForm.removeControl('reasonForRequests');
      this.appealsAndGrievancesForm.removeControl('healthOption');
    }else{
      this.appealsAndGrievancesForm.addControl('healthOption', new FormControl('', Validators.required));
      this.appealsAndGrievancesForm.addControl('reasonForRequests', new FormControl('', Validators.required));
      this.appealsAndGrievancesForm.removeControl('describeYourSituation');
    }
  }

  getErrorMsgs() {
    this.jsonSvc.getJSON('appeals-and-grievances-error-messages').subscribe(data => {
      this.errorMsgsLabels = data.ErrorMsgs;
      this.attachmentErrorMsgslabels = data.AttachmentErrorMsgs;
    });
  }

  ngAfterContentChecked() {
    this.changeDetctionRef.detectChanges();
  }

  // When Cancel button clicked should redirect to Dashboard page if user is loggedIn
  cancelCheck(){
    this.onCloseModal();
    this.clearAllFileAttachment();
    this.router.navigate([this.defaultLabels?.form.cancel_btn_url]) 
  }

  submitAppealsAndGrievancesForm() {
    if(!this.appealsAndGrievancesForm.value.consentInformation.firstName && !this.appealsAndGrievancesForm.value.consentInformation.consentcheck ){
      this.appealsAndGrievancesForm.controls['consentInformation'].setErrors(null);
    }
    if(this.appealsAndGrievancesForm.valid){
      this.formValues = this.appealsAndGrievancesForm.value;
      this.editable = false;
      this.formError = false;
      this.openPreview();
    } else {
      this.appealsAndGrievancesForm.markAllAsTouched();
      this.formError = true;
    }
  }

  // Model PopUp
  openModal(){
    this.modalRef = this.modal.open(this.modalTemplate);
    this.previewScreenEnabled = true;
  }

  openCancelModal(){
    this.previewScreenEnabled = true;
    this.modalRef = this.modal.open(this.cancelAppealsAndGrivencesAlert);
  }

  onCloseModal(){
    this.modalRef.close();
    this.modalRef.onDismiss.unsubscribe();
    this.modalRef.onClose.unsubscribe();
    this.previewScreenEnabled = false;
  }

  getJsonData() {
    this.loading = true;
    forkJoin([this.jsonSvc.getJSON('appeals-and-grievances'), this.jsonSvc.getJSON('appeals-and-grievances-error-messages')]).subscribe((res:any) => {
      if (res) {
        this.loading = false;
        const errorMessages = res[1].AttachmentErrorMsgs;
        const noteDescription = res[0].attachFiles.noteDescription;
        const enableAttachment = res[0].attachFiles.enableFileAttachment;
        this.fileTypes =  res[0].attachFiles.acceptedFileTypes;
        this.fileService.setJsonData(errorMessages, noteDescription, enableAttachment);
      }
    });
  }

  clearAllFileAttachment(){
    this.helperService.clearFileAttachments();
  }

  checkForRouteChange(){
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
        this.clearAllFileAttachment()
      }
    });
  }

  
  /**
   * @Description this function used to recognize the back button in browser and close the popup if it is already opened.
   * @param  {popstate'} 'window
   * @param  {} ['$event']
  */
  @HostListener('window:popstate', ['$event'])
  onPopState(event): void {
    if (this.previewScreenEnabled) {
      this.onCloseModal();
    }
  }

  /**
   * @Description onVirusScanFileAttachmentError method used to handle error throw from service while scanning and uploading the file 
   * @param  {any} error
   * @returns void
   */
  onVirusScanFileAttachmentError(error: any): void{
    if ( error && this.errorMsgsLabels ) {
      this.appealsAndGrievancesServiceError = this.errCodeSvc.errorCodeMapper(error, this.errorMsgsLabels);
      this.scrollToTop();
    }
  }

  // Method used to scroll top of the page
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
  openPreview() {
    this.sidePanelRef = this.sidePanel.open(this.slideDirection, this.sidePanelContent);
  }

  closePreview() {
    this.sidePanelRef.close();
  }
}

