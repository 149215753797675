import { ChatDetail } from './../interfaces/chatDetail';
import { Injectable } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { StartChat, RefreshConversationRequest } from 'gbd-models';
import * as mockTopics from 'sydmed/src/app/secure/contact-us/assets/mocks/mockHoursResponse.json'
import { of, BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LiveChatService {
  headers = { 'meta-senderapp': 'gbdmbr' };
  liveChatwithSeletcedTopic: boolean = true;
  hasTopicsDisabled: boolean = false;

  private readonly shouldRefreshChatMenuSubject = new BehaviorSubject<boolean>(false);
  shouldRefreshChatMenu$  = this.shouldRefreshChatMenuSubject.asObservable();

  constructor(private httpSvc: HttpClientService) { }

  refreshChatMenu(value: boolean) {
    this.shouldRefreshChatMenuSubject.next(value);
  }

  public getChatHours() {
    return this.httpSvc.getObservable(UrlProperties.UrlProperties.endPoints.liveChat.getHours, null, this.headers)
  }

  // keeping the mock call to use while service failure
  public getMockChatHours(){
    return of(mockTopics['default'])
  }

  public startChat(requestObj: StartChat, locale?: string) {
    let headers = this.headers;
    if (locale !== 'en-US') {
      headers['x-madt-language'] = 'es-US';
    }
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.liveChat.startChat, requestObj, headers)
  }

  public refreshChat(requestObj: RefreshConversationRequest) {
    let url = UrlProperties.UrlProperties.endPoints.liveChat.refreshChat;
    return this.httpSvc.postObservable(url, requestObj, this.headers)
  }

  public sendChat(requestObj: any) {
    let url = UrlProperties.UrlProperties.endPoints.liveChat.sendChat;
    return this.httpSvc.postObservable(url, requestObj, this.headers)
  }
  public closeChat() {
    let url = UrlProperties.UrlProperties.endPoints.liveChat.closeChat;
    return this.httpSvc.postObservable(url, {}, this.headers)
  }
}