import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { oneTimePasscodeModel } from '../one-time-passcode.model';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { ErrorCodeMapperService } from '../../../libs/error-code-mapper/src/lib/error-code-mapper.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
@Component({
  selector: 'send-one-time-passcode',
  templateUrl: './send-one-time-passcode.component.html',
  styleUrls: ['./send-one-time-passcode.component.css']
})
export class SendOneTimePasscodeComponent implements OnInit {

  @Output()
  linkClicked: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  backButtonClicked: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  nextButtonClicked: EventEmitter<any> = new EventEmitter<any>()
  @Input()
  jsonErrors: any;
  @Input()
  jsonLabels: any;
  @Input()
  url: string;
  @Input() feature: string;

  sendOnetimePasscodeForm: FormGroup;
  public accountRecoveryModel: any;
  public showText: boolean = true;
  public showEmail: boolean = true;
  public showVoice: boolean = true;
  public noContacts: boolean = true;
  public onlyEmail: boolean = true;
  public showError: boolean = false;
  public textSelected: boolean = false;
  public voiceSelected: boolean = false;
  public emailSelected: boolean = false;
  public textAlertOpen: boolean = false;
  public emailAlertOpen: boolean = false;
  public brand: string;
  public aslError: any;
  selectionRequiredError = false;
  
  constructor(
    private readonly formBuilder: FormBuilder,
    private _oneTimePasscodeModel: oneTimePasscodeModel,
    private httpClient: HttpClientService,
    private errCodeSvc: ErrorCodeMapperService,
    private jsonContentService: JsonContentService
    ) { }

  ngOnInit(): void {
    this.sendOnetimePasscodeForm = this.formBuilder.group({
      sendCodeOption: ['', [Validators.required]],
    });
    this.accountRecoveryModel = sessionStorage.getItem('contactsModel');
    this.accountRecoveryModel = JSON.parse(this.accountRecoveryModel); 
    this.accountRecoveryModel = (this.feature === 'registration') ? this._oneTimePasscodeModel.transformContactInfo(this.accountRecoveryModel,"ARN") : this._oneTimePasscodeModel.transformContactInfo(this.accountRecoveryModel,'');
    this.showText = this.jsonContentService.getHostMarket() === 'BCBSMN' ? false : this.accountRecoveryModel.display.showText;
    this.showEmail = this.accountRecoveryModel.display.showEmail;
    this.showVoice = this.accountRecoveryModel.display.showVoice;
    this.noContacts = this.accountRecoveryModel.display.noContacts;
    this.onlyEmail = this.accountRecoveryModel.display.onlyEmail;
  }

  public sendCode() {

    if (this.sendOnetimePasscodeForm.valid) {

      this.accountRecoveryModel = this._oneTimePasscodeModel.setSelectedInfo(this.accountRecoveryModel.contactInfo,
        this.sendOnetimePasscodeForm.controls['sendCodeOption'].value, '');
      const otpRequest = this._oneTimePasscodeModel.transformToOTPRequest(this.accountRecoveryModel);

      this.httpClient.postObservable(this.url, otpRequest).subscribe(
        (response: any) => {
          sessionStorage.setItem('recoveryModel', JSON.stringify(this.accountRecoveryModel));
          this.nextButtonClicked.emit();
        },
        error => {
          this.aslError = this.errCodeSvc.errorCodeMapper(error, this.jsonErrors);

        }
      )

    } else {
        if (this.noContacts && this.feature === 'registration') {
          this.selectionRequiredError = true;
        }
        else {
          this.selectionRequiredError = false;
          this.sendOnetimePasscodeForm.markAllAsTouched();
          let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
          invalidFields[1].focus();  
        }
    }

  }


  public secondaryValidationLink() {
    this.linkClicked.emit(this.sendOnetimePasscodeForm);
  }

  public back() {
    this.backButtonClicked.emit();
  }

  public showArnLink(): boolean {
    return ((this.onlyEmail === true || this.noContacts === true) && (this.feature === 'registration')) ? false : true;
  }

  public showForNoContacts() {
    return (this.noContacts === true && this.feature === 'registration') ? false : true;
  }

  public onChangeInput(optionType) {
    this.textSelected = false;
    this.voiceSelected = false;
    this.emailSelected = false;
    this.textAlertOpen = false;
    this.emailAlertOpen = false;
    if(optionType === "text") {
      this.textSelected = true;
      this.textAlertOpen = true;
    }
    if(optionType === "voice") {
      this.voiceSelected = true;
    }
    if(optionType === "email") {
      this.emailSelected = true;
      this.emailAlertOpen = true;
    }
  }
}
