import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ResetTempPasswordModel {

  constructor(

  ) { }

  private model: any = {
    username: undefined,
    password: undefined,
    newPassword: undefined
  };

  public updateModel(input, userName) {
    this.model.username = userName
    this.model.password = input.value.tempPassword;
    this.model.newPassword = input.value.passwords.password;

    return this.model;
  }
}
