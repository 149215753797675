import { Directive, Input, Renderer2, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * Zipcode formatter is used to format zipcode 
 * in 99999-9999 format
 */
@Directive({
  selector: '[formControlName][libZipCodeFormat]'
})
export class ZipCodeFormatDirective {

  private prevValue: string;
  private isDeleteButton: boolean;

  @Input()
  set zipValue(value: string) {
    this.prevValue = value;
  }

  private sub: Subscription;

  constructor(
    private readonly el: ElementRef,
    private readonly zipControl: NgControl,
    private readonly renderer: Renderer2
  ) { }

  @HostListener('keydown', ['$event'])
  checkForDelete(event) {
    if (event.which === 46 || event.key === 'Delete') {
      this.isDeleteButton = true;
    } else {
      this.isDeleteButton = false;
    }
  }

  ngOnInit() {
    this.zipValidate();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  zipValidate() {

    this.sub = this.zipControl.control.valueChanges.subscribe(data => {

      data = data ? data : '';
      // keep track of prev val
      const preInputValue: string = this.prevValue;
      // remove all chars except numbers
      let newVal = data.replace(/\D/g, '');

      let start = this.el.nativeElement.selectionStart;
      let end = this.el.nativeElement.selectionEnd;

      // when removed value from input
      if (preInputValue && (data.length < preInputValue.length)) {

        newVal = this.formatZip(newVal);

        this.zipControl.control.setValue(newVal, { emitEvent: false });

        // keep cursor the normal position after setting the input above.
        (preInputValue.charAt(start) === '-' && this.isDeleteButton) ?
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start + 1, end + 1) :
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);

        // when typed value in input
      } else {
        const removedD = data.charAt(start);

        newVal = this.formatZip(newVal);

        // check to see if typed in middle
        if (preInputValue && (preInputValue.length >= start)) {
          // change cursor according to special chars.
          if (removedD === '-') {
            start = start + 1;
            end = end + 1;
          }
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);
        } else {
          // +2 because of wanting standard typing
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start + 2, end + 2);
        }
        this.zipControl.control.setValue(newVal, { emitEvent: false });
      }
    });
  }

  formatZip(newVal) {
    if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,5})/, '$1');
    } else {
      newVal = newVal.replace(/^(\d{0,5})(.*)/, '$1-$2');
    }
    return newVal;
  }
}
