<!--Id card contact us view start-->
<div *ngIf="chatView" class="tcp-id-card-mini-widget">
<div class=" clearfix card-align-center">
  <div class="id-card-card clearfix">
    <div class="img-container clearfix sixteen-ten id-card-resize">
      <div app-chatbot-idcard-flipper-cmp class="tcp-id-card-flipper-dir ratio-content">
        <div [ngClass]="{ flipped: flipped }" class="flipper ng-isolate-scope horizontal" id="idcard">
          <div data-front class="ng-scope">
            <span class="ng-scope">
              <img id="frontIdcard" class="front" [src]="frontImageSrc" alt="ID Card Front Image" />
            </span>
          </div>
          <div data-back class="ng-scope">
            <span class="ng-scope">
              <img id="backIdcard" class="back" [src]="backImageSrc" alt="ID Card Back Image" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--Flip start-->
    <div class="id-card-controls-wrapper clearfix">
      <div class="id-card-card-controls pfCheckbox clearfix">
        <div class="zoom-container">
          <a class="icon-item" data-analytics="zoomIdCard" aria-label="Zoom" id="" href="javascript:void(0)"
            (click)="openModal()" tabindex="-1">
            <span class="fa fa-search-plus salmon fa-lg"></span>
            <span [innerText]="content?.labels?.zoom"></span>
          </a>
        </div>

        <div class="flip-container">
          <a class="icon-item flip-image" data-analytics="flipIdCard" aria-label="Flip" id="tcp-contact-flip"
            href="javascript:void(0)" (click)="flip()" tabindex="-1">
            <span class="fa fa-refresh salmon fa-lg"></span>
            <span [innerText]="content?.labels?.flip"></span>
          </a>
        </div>
      </div>
    </div>
    <!--Flip end-->
  </div>
</div>
</div>
<div hidden data-uxd-modal-cmp #idCardZoomModal="modal" class="id-card-modal modal-zoom fadeInDown animated"
  [modal]="{id: 'tcp-id-card-test-id-card-modal', cssClass: '', title: content?.modals?.zoom?.title}">
  <div class="modal-component-body ">
    <div>
      <div class="tcp-id-card-mini-widget clearfix list">
        <div app-chatbot-idcard-flipper-cmp class="tcp-id-card-flipper-dir ratio-content">
          <div class="flipper ng-isolate-scope horizontal" [ngClass]="{ flipped: flippedModal }" id="idcardModal">
            <div data-front class="ng-scope">
              <span class="ng-scope">
                <img id="frontIdcardModal" class="front" [src]="frontImageSrc" alt="ID Card Front Image" />
              </span>
            </div>
            <div data-back class="ng-scope">
              <span class="ng-scope">
                <img id="backIdcardModal" class="back" [src]="backImageSrc" alt="ID Card Back Image" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-component-footer">
    <div class="clearfix margin-top-15">
      <button class="btn modal-buttons"
        data-analytics="flipIdCardZoomModal" data-autofocus (click)="flip('modal');"
        [innerText]="content?.modals?.zoom?.buttons?.flip">
      </button>&nbsp;
      <button class="btn btn-secondary " 
        (click)="closeModal()" [innerText]="content?.modals?.zoom?.buttons?.close" data-lastfocus>
      </button>
    </div>
  </div>
</div>
