import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { PriorAuthDataService } from './prior-auth-data.service';
import { PriorAuthorizationLabels, PriorAuthorizationErrorMessages, PRIOR_AUTH_TABS, PriorAuthorizationAnalytics } from './models/labels';
import { Restriction } from 'gbd-models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-prior-authorization',
  templateUrl: './prior-authorization.component.html',
  styleUrls: ['./prior-authorization.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PriorAuthorizationComponent implements OnInit {
  readonly tabs = PRIOR_AUTH_TABS;
  public selectedTab: PRIOR_AUTH_TABS = PRIOR_AUTH_TABS.PHARMACY;
  public tabFromQuery: PRIOR_AUTH_TABS;
  constructor(
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private dataSvc: PriorAuthDataService,
    private activatedRoute: ActivatedRoute
  ) { 
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Prior Authorization'
    });
    this.tabFromQuery = this.activatedRoute.snapshot.queryParams?.tab;
    this.selectedTab = this.tabFromQuery ?? this.selectedTab;
  }

  public labels: PriorAuthorizationLabels;
  public errorMsgs: PriorAuthorizationErrorMessages;
  public error: boolean;
  public showMedicalTab: boolean = false;
  public showPharmacyTab: boolean = false;
  public analytics: PriorAuthorizationAnalytics;

  ngOnInit(): void {
    this.error = false;
    this.jsonSvc.getJSON('prior-authorization').subscribe(
      (res) => {
        if (res.PriorAuthorization) {
          this.dataSvc.labels = res.PriorAuthorization.Labels;
          this.dataSvc.errorMsgs = res.PriorAuthorization.ErrorMsgs;
          this.labels = res.PriorAuthorization.Labels;
          this.analytics = res.PriorAuthorization.analytics;
          this.errorMsgs = res.PriorAuthorization.ErrorMsgs;
          if(!this.jsonSvc.hasRestriction(Restriction.SHM_PRIOR_AUTH_MEDICAL, this.jsonSvc.getRestrictions())){
            this.showMedicalTab = true;
            this.selectedTab = this.tabFromQuery ?? PRIOR_AUTH_TABS.MEDICAL;
          }
          if(!this.jsonSvc.hasRestriction(Restriction.SHM_PRIOR_AUTH_PHARMACY, this.jsonSvc.getRestrictions())
           && !this.jsonSvc.hasRestriction(Restriction.SHM_NO_PHARMACY, this.jsonSvc.getRestrictions()) ){
            this.showPharmacyTab = true;
            this.selectedTab = this.tabFromQuery ? this. tabFromQuery : this.showMedicalTab ? PRIOR_AUTH_TABS.MEDICAL : PRIOR_AUTH_TABS.PHARMACY;
          }
        } else {
          this.error = true;
        }
      },
      (err) => {
        this.error = true;
      }
    );
  }
  changeTab(tab: PRIOR_AUTH_TABS) {
    this.selectedTab = tab;
  }

}
