import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GrievancesDataService } from '../grievances-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { OnlineGrievancesLabels, OnlineGrievancesErrorMsgs } from '../models/labels';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { ContactInfoType, GrievanceType, GrievanceRequest } from 'gbd-models';

@Component({
  selector: 'app-grievances-form-preview',
  templateUrl: './grievances-form-preview.component.html',
  styleUrls: ['./grievances-form-preview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GrievancesFormPreviewComponent implements OnInit {

  constructor(
    private dataSvc: GrievancesDataService,
    private router: Router,
    private httpSvc: HttpClientService,
    private errCodeSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Grievance Form'
    });

    router.events.subscribe((events) => {
      if (events instanceof NavigationEnd && events.id === 1) {
        this.router.navigate(['/secure/grievances-form']);
      }
    });
  }

  // Labels
  public formLabels: OnlineGrievancesLabels;
  public errorMsgs: OnlineGrievancesErrorMsgs;
  public error: string;
  public showError: boolean;

  // Form Data
  public previewData: GrievanceRequest;

  public contactRadioGroup: any;
  public contactRadioVal: ContactInfoType;

  public grievanceForRadioGroup: any;
  public grievanceForRadioVal: GrievanceType;

  ngOnInit(): void {
    this.showError = false;
    this.jsonSvc.getJSON('online-grievances').subscribe(
      (res) => {
        this.formLabels = res.OnlineGrievances.Form;
        this.errorMsgs = res.OnlineGrievances.ErrorMsgs;
        this.initRadioGroup();
      }
    );
    this.getFormData();
  }

  public initRadioGroup() {
    this.contactRadioVal = ContactInfoType.PHONE;
    this.contactRadioGroup = {
      isOptional: false,
      alignVertical: false,
      id: 'contactRadioBtnGrp',
      name: 'contactBtnGrp',
      label: this.formLabels?.Contact,
      options: [
        {
          label: this.formLabels?.ContactOptions.Phone,
          value: ContactInfoType.PHONE,
          analytics: 'analytics-one'
        },
        {
          label: this.formLabels?.ContactOptions.Email,
          value: ContactInfoType.EMAIL,
          analytics: 'analytics-two'
        }
      ]
    };

    this.grievanceForRadioVal = GrievanceType.GRIEVANCE_FOR_ME;
    this.grievanceForRadioGroup = {
      isOptional: false,
      alignVertical: false,
      id: 'grievanceForRadioBtnGrp',
      name: 'grievanceForBtnGrp',
      label: this.formLabels?.Party,
      options: [
        {
          label: this.formLabels?.PartyOptions.Me,
          value: GrievanceType.GRIEVANCE_FOR_ME,
          analytics: 'analytics-one'
        },
        {
          label: this.formLabels?.PartyOptions.SomeoneElse,
          value: GrievanceType.GRIEVANCE_FOR_SOMEONE_ELSE,
          analytics: 'analytics-two'
        }
      ]
    };
  }

  private getFormData() {
    const formData: any = this.dataSvc.getFormData();
    this.contactRadioVal = this.dataSvc.getContactRadioVal();
    this.grievanceForRadioVal = this.dataSvc.getGrievanceForRadioVal();

    formData.preferredModeOfCommunication = this.contactRadioVal;
    formData.grievanceFor = this.grievanceForRadioVal;

    if (formData) {
      this.previewData = formData;
    }
  }

  public sendForm() {
    const endpoint = UrlProperties.UrlProperties.endPoints.onlineGrievances.sendForm;
    this.httpSvc.postObservable(endpoint, this.previewData).subscribe(
      (res: any) => {
        this.dataSvc.clearFormData();
        this.router.navigate(['/secure/grievances-form-confirmation']);
      },
      err => {
        if (this.error === undefined && err !== undefined && this.errorMsgs !== undefined) {
          this.error = this.errCodeSvc.errorCodeMapper(err, this.errorMsgs);
        }

        this.showError = true;
      }
    );
  }

  public cancelForm() {
    const cancelUrlKey = 'Cancel_btn_url';
    location.href = this.formLabels[cancelUrlKey];
  }

  public returnToProfile() {
    const returnToProfileUrlKey = 'ReturnToProfile_btn_url';
    location.href = this.formLabels[returnToProfileUrlKey];
  }


}
