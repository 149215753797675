<div class="notices-of-action-container" *ngIf="!isLoading">
  <h2
    id="noa-header"
    tabindex="0"
    role="heading"
    aria-live="polite"
    class="section-header-two"
    [id]="noticesOfAction?.title"
  >
    {{ noticesOfAction?.title }}
  </h2>
  <div class="description margin-bottom-30" id="noticeOfActionDescription">{{ noticesOfAction?.description }}</div>
  <div>
    <span>{{noticesOfAction?.hotlineNumber}}</span>
    <span id="phoneNumber">{{noticesOfAction?.phoneNumber}}</span>
  </div>
  <div class="form-row">
          <div
            data-uxd-dropdown-cmp
            class="ant-lg-select form-item"
            name="drSelect"
            id="drSelect"
            [items]="dateRangeFilters?.options"
            required
            [defaultText]="defaultLabel"
            [labelText]="dateRangeFilters?.title"
            [(ngModel)]="dateRangeValue"
            (ngModelChange)="onDateRangeChange($event)"
          ></div>
  </div>
    
  <div *ngIf="!isLoading">
    <ng-container *ngIf="selectedRangeList?.length > 0">
      <h6 *ngIf="noaServerError">{{noticesOfAction?.displaying}} {{this.selectedRangeList.length}} {{noticesOfAction?.of}} {{this.totalSelectedRangeList.length}} {{noticesOfAction?.notices}}</h6>
      <table
        class="table zebra-stripes motif-margin-top-15"
        [ngClass]="{ 'table-border': false }"
      >
        <tr class="table-row vgr-divider-border-bottom">
          <div class="tooltip-container">
          <th
            class="table-header"
            scope="col"
            [innerHtml]="noticesOfAction?.tableLabel.noticeDate"
          >
        </th>
        <a
          href="javascript:void(0);"
          role="tooltip"
          class="ant-action-link motif-icon motif-help"
          data-uxd-tooltip-dir
          [attr.aria-label]="'tooltipNoticeDateHeader'"
          [definition]="{
            htmlContent:
            noticesOfAction?.tableLabel.noticeDateTooltip
          }"
          tabindex="1"
        >
        </a>
          </div>
          
          <th
            class="table-header"
            scope="col"
            [innerHtml]="noticesOfAction?.tableLabel.notice"
          ></th>
        </tr>
        <tr
          class="table-row vgr-divider-border-bottom"
          *ngFor="let row of selectedRangeList"
        >
          <td data-th="Date" scope="col" class="table-data">{{ row?.date }}</td>
          <td
            data-th="Name"
            class="table-data"
            (click)="onLetterClick(row)"
            [attr.data-analytics]="'analytics-' + row.id"
            [attr.data-test-id]="'test-' + row.id"
          >
            <div class="letter-spacing">
              <img
                [src]="noticesOfAction.pdfImage"
                alt="pdfIcon"
                class="icon"
              />
              <span class="letter">{{ noticesOfAction?.umLetter }}</span>
            </div>
          </td>
        </tr>
      </table>
      <div *ngIf="showLoadMoreButton" class="button-container">
        <button
        type="button"
        class="btn btn-secondary margin-bottom-30"
        (click)="onLoadMoreClick()"
        id="loadMore"
      >
        {{ noticesOfAction?.loadMore }}
      </button>
      </div>
    </ng-container>
  </div>
      <div
      *ngIf="!isLoading && noaDataNotFound && !noaServerError"
        [id]="noticesNotFound"
        data-uxd-alert-cmp-v2
        [alert]="{ alertType: 'info' }"
      >
        <span [innerHTML]="noticesOfAction?.errorMessage"></span>
      </div>
  <div *ngIf="!isLoading && noaServerError && !noaDataNotFound">
    <app-api-failure
    [imageSource] = "noticesOfAction.apiFailureError.image"
    [header]="noticesOfAction?.apiFailureError.header"
    [description]="noticesOfAction?.apiFailureError.description"
    [primaryButtonText]="noticesOfAction?.apiFailureError.buttonText"
    (primaryButtonClick) = "onClickContactUs()"></app-api-failure>
  </div>

  
</div>

<div class="spinner" *ngIf="isLoading">
  <uxd-spinner></uxd-spinner>
</div>

 <ng-template #fileDownloadFailModal>
    <app-api-failure
    [imageSource] = "noticesOfAction.downloadFileApiFailureError.image"
    [header]="noticesOfAction?.downloadFileApiFailureError.header"
    [description]="noticesOfAction?.downloadFileApiFailureError.description"
    [primaryButtonText]="noticesOfAction?.downloadFileApiFailureError.buttonText"
    (primaryButtonClick) = "onClickCloseDownloadApiFailureModal()"></app-api-failure>
  </ng-template>