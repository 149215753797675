import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ChatbotService } from "sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { Constants } from "../enums/constants";

@Component({
  selector: "app-seed-slideout",
  templateUrl: "./seed-slideout.component.html",
  styleUrls: ["./seed-slideout.component.scss"],
})
export class SeedSlideoutComponent implements OnInit {
  @Output("closeSlide") closeSlide: EventEmitter<any> = new EventEmitter();
  content: any;
  constructor(
    private chatBotSvc: ChatbotService,
    private jsonService: JsonContentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.jsonService.getJSON("new-dashboard").subscribe((data) => {
      this.content = data.dashboard.slideOut;
    });
  }
  navigate(event: any, accordian: any) {
    accordian.id === Constants.LIVE_CHAT
      ? this.routeToLiveChat(event, accordian.url)
      : this.router.navigateByUrl(accordian.url);
    this.onClose();
  }
  routeToLiveChat(event: any, url: string) {
    window.location.href = url;
    this.chatBotSvc.launchChatBot(event, true);
  }
  onClose() {
    this.closeSlide.emit();
  }
}
