import { Component, OnInit, ViewChildren, QueryList, Input } from "@angular/core";
import { PowerAccountService } from "../services/power-account.service";
import { Subscription } from "rxjs";
import { Router } from '@angular/router';
import { AntTooltipDirective } from '@anthem/uxd/tooltip';
import { CurrentBillData } from "../interfaces/power-account";
import { PowerAccountModel } from '../models/power-account.model';
import { PaymentPortalService } from '../../payment-portal/services/payment-portal.service';
import { PowerAccountContent } from "../interfaces/power-account-content";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";
import {HealthAccountSummary} from 'gbd-models';
@Component({
  selector: "app-power-account-summary",
  templateUrl: "./power-account-summary.component.html",
  styleUrls: ["./power-account-summary.component.scss"]
})
export class PowerAccountSummaryComponent implements OnInit {
  private subscriptions: Subscription[];
  public summaryError: string = '';
  public currentBillError: string = '';
  public genError: string = '';
  public isSummaryAPILoading: boolean = true;
  public isCurrentBillAPILoading: boolean = true;
  public displayPASummary: HealthAccountSummary;
  public currentBillRes: CurrentBillData;
  public accSummaryToolTip: AntTooltipDirective;
  public stateContributionToolTip: AntTooltipDirective;
  public yourContributionToolTip: AntTooltipDirective;
  public accSummaryToolTipIsToggled: boolean = false;
  public stateContributionToolTipIsToggled: boolean = false;
  public yourContributionToolTipIsToggled: boolean = false;
  @Input('hasMakePayment') hasMakePayment: boolean;

  @Input('content') content: PowerAccountContent;
  @ViewChildren(AntTooltipDirective) tooltips: QueryList<AntTooltipDirective>;

  constructor(
    private powerAccountSvc: PowerAccountService,
    private currentBillSvc: PaymentPortalService,
    private powerAccountModel: PowerAccountModel,
    public router: Router,
    private errCodeMapSvc: ErrorCodeMapperService
    ) {}

  ngOnInit(): void {
    this.subscriptions = [];
    this.getPowerAccountSummary();
    this.getPowerAccountCurrentBill();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.accSummaryToolTip = this.tooltips.toArray()[0];
    this.stateContributionToolTip = this.tooltips.toArray()[1];
    this.yourContributionToolTip = this.tooltips.toArray()[2];
  }

  accSummaryToolTipToggle(boolean: boolean) {
    this.accSummaryToolTipIsToggled = boolean;
  }

  stateContributionToolTipToggle(boolean: boolean) {
    this.stateContributionToolTipIsToggled = boolean;
  }

  yourContributionToolTipToggle(boolean: boolean) {
    this.yourContributionToolTipIsToggled = boolean;
  }

  getPowerAccountSummary() {
      this.subscriptions.push(
        this.powerAccountSvc.getPASummary().subscribe((res: any) => {
          this.displayPASummary = this.powerAccountModel.buildPowerAccountSummary(res?.body);
          this.isSummaryAPILoading = false;
        }, (err: any) => {
          this.summaryError = this.content.ErrorMsgs.PwrAcctSummaryInfoNotAvailable_err;
          this.genError = this.errCodeMapSvc.errorCodeMapper(err, this.content.ErrorMsgs);
          this.isSummaryAPILoading = false;        
        })
      );
  }

  getPowerAccountCurrentBill() {
      this.subscriptions.push(
        // getSummary API of payment portal
        this.currentBillSvc.getSummary().subscribe((res: any) => {
          this.hasMakePayment = Number(res.body?.totalDue) > 0;
          this.currentBillRes = this.powerAccountModel.buildCurrentBill(res?.body);          
          this.isCurrentBillAPILoading = false;
        }, (err: any) => {
          this.genError = this.errCodeMapSvc.errorCodeMapper(err, this.content.ErrorMsgs);
          this.currentBillError = this.content.ErrorMsgs.PwrAcctSummaryInfoNotAvailable_err;
          this.isCurrentBillAPILoading = false;
        })
      );
  }

  makeAPayment() {
    this.router.navigate([this.content.PwrAcctSummary_lbls.MakeAPaymentLink]);
  }
}