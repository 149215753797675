<ng-container>
    <div class="cc-and-subheader">
        <div class="width-100">
            <span class="motif-icon motif-cc-visa" aria-label="visa" role="img"></span>
            <span class="motif-icon motif-cc-mastercard" aria-label="mastercard" role="img"></span>
        </div>

        <div class="width-100">
            <h6>{{labels?.Headings?.PageSubtitle}}</h6>
        </div>
    </div>

    <ng-container *ngIf="loader; else showCardForm">
        <div class="row">
            <div class="tabbed-view-wrapper col-xs-12 loader">
                <uxd-spinner class="updating-loader"></uxd-spinner>
                <div>{{labels?.SavingLoaderText}}</div>
            </div>
        </div>
    </ng-container>
    <ng-template #showCardForm>
        <form [formGroup]="addCardForm" name="cardForm">
            <div class="grid">
                <div class="grid-item width-100">
                    <label for="textfield">{{ labels?.Labels?.CardNumber }}</label>
                </div>
                <div class="grid-item width-100">
                    <div class="width-100">
                        <input type="text" formControlName="cardNumber" maxlength="16" class="ant-text-input ant-input-long width-100" libNumbersOnly/>
                        <div class="error-list" *ngIf="(cardNumber.dirty || cardNumber.touched) && cardNumber.invalid">
                            <div *ngIf="cardNumber.errors.required; else showOtherCardErrs">
                                <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5 ">
                                        <span>{{ labels?.Labels.CardNumber }}</span>
                                        <span>{{ errObj?.Required_err }}</span>
                                    </span>
                                </span>
                            </div>
                            <ng-template #showOtherCardErrs>
                                <div *ngIf="cardNumber.errors.minlength || cardNumber.errors.maxlength">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5">
                                            <span>{{ errObj?.Card_Invalid_err }}</span>
                                        </span>
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <!-- card expiration -->
                <div class="grid-item width-100">
                    <label>{{ labels?.Labels?.CardExpiration }}</label>
                </div>

                <div class="grid-item width-100">
                    <div class="half">
                        <div class="width-100">
                            <div data-uxd-dropdown-cmp formControlName="month" class="ant-md-select form-item ng-untouched ng-pristine ng-valid width-100"
                                [labelText]="" name="monSelect" id="monSelect" [items]="selectMonth.options" required [defaultText]="'Month'"
                                (change)="checkValidExpiry()" [ngClass]="{'redBC': (formMonth.dirty || formMonth.touched) 
                                && (formMonth.invalid || (formMonth.value === ''))}" [attr.aria-label]="labels?.Labels?.CardExpiration"></div>
                            <div class="error-list" *ngIf="(formMonth.dirty || formMonth.touched) && formMonth.invalid">
                                <div *ngIf="formMonth.errors.required">
                                    <span class="motif-icon motif-delete"></span>
                                    <span class="motif-margin-left-5">{{ labels?.Labels?.Month }}</span>
                                    <span>{{ errObj?.Required_err }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid-item half">
                        <div class="width-100">
                            <div data-uxd-dropdown-cmp formControlName="year" class="ant-md-select form-item ng-untouched ng-pristine ng-valid width-100"
                                [labelText]="" name="yearSelect" id="yearSelect" [items]="selectYear.options" required [defaultText]="'Year'"
                                (change)="checkValidExpiry()" [ngClass]="{'redBC': (formYear.dirty || formYear.touched)
                        && (formYear.invalid || (formYear.value === ''))}"></div>
                            <div class="error-list motif-margin-top-2" *ngIf="(formYear.dirty || formYear.touched) && formYear.invalid">
                                <div *ngIf="formYear.errors.required">
                                    <span class="motif-icon motif-delete"></span>
                                    <span class="motif-margin-left-5">{{ labels?.Labels?.Year }}</span>
                                    <span>{{ errObj?.Required_err }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="error-list motif-margin-top-2 form-item clearfix expiry-error" *ngIf="formYear.touched && dateNotInFuture">
                    <div><span class="motif-icon motif-delete"></span><span class="motif-margin-left-5">{{ errObj.Date_Not_Valid }}</span></div>
                </div>

                <div class="grid-item width-100">
                    <div class="half">
                        <div class="width-100">
                            <label>{{ labels?.Labels?.AccountHolderName }}</label>
                            <input type="text" formControlName="accountHolderName"  class="ant-text-input ant-input-long width-100" />
                            <div class="error-list" *ngIf="(formAccountHolderName.dirty || formAccountHolderName.touched ) && formAccountHolderName.invalid">
                                <div *ngIf="formAccountHolderName.errors.required">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5 ">
                                            <span>{{ labels?.Labels?.AccountHolderName }}</span>
                                            <span>{{ errObj?.Required_err }}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid-item half">
                        <div class="width-100">
                            <label>{{ labels?.Labels?.PaymentNickname }} &nbsp; <span class="normal">{{labels?.Labels?.Optional}}</span>  </label>
                            <input type="text" formControlName="paymentNickName"  class="ant-text-input ant-input-long width-100" />
                            <div class="error-list" *ngIf="(paymentNickName.dirty || paymentNickName.touched ) && paymentNickName.invalid">
                                <div *ngIf="paymentNickName.errors.required">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5 ">
                                            <span>{{ labels?.Labels?.PaymentNickname }}</span>
                                            <span>{{ errObj?.Required_err }}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container>
                    <div class="grid-item width-100">
                        <div data-uxd-checkbox-input-cmp [definition]="checkbox" [disabled]="autopay" formControlName="futureUse"></div>
                    </div>
                </ng-container>

                <div class="error" *ngIf="errMsg">
                    <div data-uxd-alert-cmp-v2 [alert]="{ alertType: 'negative' }">
                        <span [innerHTML]="errMsg"></span>
                    </div>
                </div>
                <br/>
                <br/>
                <div *ngIf="addressPaymentRadioGroup">
                    <br/>
                    <h5 class="motif-margin-bottom-5" *ngIf="labels">{{labels?.Labels?.AccountHolderAddress}}</h5>
                    <form name="address-payment-form">
                        <label *ngFor="let addressOption of addressPaymentRadioGroup?.options">
                              <input 
                                type="radio" 
                                name="addressOption" 
                                [(ngModel)]="selectedAddressId" 
                                [value]="addressOption.value" 
                              />
                              <span *ngIf="addressOption.payment && addressOption.payment.streetAddress1; else addressRadioOption">
                                <span class="motif-margin-left-3">{{ addressOption?.payment.streetAddress1 }}</span><br/>
                                <span class="motif-margin-left-7">{{ addressOption?.payment.city +", " + addressOption?.payment.state + ", "+ addressOption?.payment.zipCode}} </span>
                              </span>
                              <ng-template #addressRadioOption>
                                <span *ngIf="addressOption.label">
                                  <span class="motif-margin-left-3">{{ addressOption?.label }}</span>
                                </span>
                              </ng-template>
                            </label>
                    </form>
                </div>

                <ng-container *ngIf="selectedAddressId === 'addNewAddress'">

                    <form [formGroup]="addAddressForm" class="form-address width-100">
                        <div class="grid-item width-100">
                            <label for="textfield">{{ labels?.Labels?.AddressLine1 }}</label>
                        </div>
                        <div class="grid-item width-100">
                            <div class="width-100">
                                <input type="text" formControlName="addressLine1" class="ant-text-input ant-input-long width-100" />
                                <div class="error-list" *ngIf="(formAddressLine1.dirty || formAddressLine1.touched ) && formAddressLine1.invalid">
                                    <div *ngIf="formAddressLine1.errors.required">
                                        <span class="success"><i class="motif-icon motif-delete"></i>
                                            <span class="motif-margin-left-5 ">
                                                <span>{{ labels?.Labels?.AddressLine1 }}</span>
                                                <span>{{ errObj?.Required_err }}</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid-item width-100">
                            <label for="textfield">{{ labels?.Labels?.AddressLine2 }} &nbsp;  </label>{{labels?.Labels?.Optional}}
                        </div>
                        <div class="grid-item width-100">
                            <div class="width-100">
                                <div class="width-100">
                                    <input type="text" formControlName="addressLine2" class="ant-text-input ant-input-long width-100"
                                    placeholder="{{labels?.Labels?.AddressLinePlaceholder}}" />
                                </div>
                            </div>
                        </div>
                        <div class="grid-item width-100">
                            <div class="third">
                                <div class="grid-item  width-100">
                                    <label for="textfield">{{ labels?.Labels?.ZIPCode }}</label>
                                </div>
                                <div class="grid-item  width-100">
                                    <div class="width-100">
                                        <input type="text" formControlName="zipCode" class="ant-text-input ant-input-long width-100" />
                                        <div class="error-list" *ngIf="(formZipCode.dirty || formZipCode.touched) && formZipCode.invalid">
                                            <div class="error-list" *ngIf="(formZipCode.dirty || formZipCode.touched) &&  formZipCode.invalid">
                                                <div *ngIf="formZipCode.errors.required">
                                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                                        <span class="motif-margin-left-5 ">
                                                            <span>{{ labels?.Labels?.ZIPCode }}</span>
                                                            <span>{{ errObj?.Required_err }}</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="third ml-5">
                                <div class="grid-item  width-100">
                                    <label for="textfield">{{ labels?.Labels?.City }}</label>
                                </div>
                                <div class="grid-item  width-100">
                                    <div class="width-100">
                                        <input type="text" formControlName="city" class="ant-text-input ant-input-long width-100" />
                                        <div class="error-list" *ngIf="(formCity.dirty || formCity.touched) && formCity.invalid">
                                            <div *ngIf="formCity.errors.required">
                                                <span class="success"><i class="motif-icon motif-delete"></i>
                                                    <span class="motif-margin-left-5 ">
                                                        <span>{{ labels?.Labels?.City }}</span>
                                                        <span>{{ errObj?.Required_err }}</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="third ml-5">
                                <div class="grid-item  width-100">
                                    <label for="textfield">{{ labels?.Labels?.State }}</label>
                                </div>
                                <div class="grid-item  width-100">
                                    <div class="width-100">
                                        <div data-uxd-dropdown-cmp formControlName="state" class="ant-md-select mt-7 form-item ng-untouched ng-pristine ng-valid width-100"
                                            [items]="selectState.options" [defaultText]="'Select State'" [ngClass]="{'redBC': (formState.dirty || formState.touched) 
                                            && (formState.invalid || (formState.value === ''))}">
                                        </div>
                                        <div class="error-list" *ngIf="(formState.dirty || formState.touched) && formState.invalid">
                                            <div *ngIf="formState.errors.required">
                                                <span class="success"><i class="motif-icon motif-delete"></i>
                                                    <span class="motif-margin-left-5 ">
                                                        <span>{{ labels?.Labels?.State }}</span>
                                                        <span>{{ errObj?.Required_err }}</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>

                <div id="addCardBtnGroup" class="grid-item width-100 text-right">
                    <!-- show buttons based on the showButtons input passed -->
                    <ng-container *ngIf="showButtons">
                        <div class="button-group width-100">
                            <button type="button" (click)="onCancel()" class="btn btn-secondary">{{ labels?.Buttons?.Cancel }}</button>
                            <button type="button" (click)="addCard()" class="btn btn-primary">{{labels?.Buttons?.Add}}</button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </form>
    </ng-template>
</ng-container>