import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from "@angular/common/http";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { HttpInterceptService } from "./http-intercept.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs/internal/observable/throwError";
import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";
import { environment } from '../environments/environment';
import { FaxIdCardAddressRequest, GetProfileDetailsResponse } from 'gbd-models';

@Injectable({
  providedIn: "root",
})
export class IdCardService {
  private hcid: string;
  private headers: HttpHeaders;
  private gbdToken: string;
  private token: string;

  constructor(
    private http: HttpClientService,
    private httpClient: HttpClient,
    private httpSvc: HttpInterceptService
  ) {}

  /**
   * @description downloadImage() Responsible for getting id card image from api
   * @param {any} res
   * @param {string} fileName
   * @returns {Observable<any>}
   */
  downloadImage(): Observable<any> {
    this.getMemCtx();
    return this.httpClient.get(UrlProperties.UrlProperties.endPoints.idCard.pdf, { observe: 'response', responseType: 'blob'} );
  }

  /**
   * @description handleResponse() Responsible for downloading ID Card in pdf
   * @param {any} response
   * @param {string} fileName
   * @returns {string}
   */
  handleResponse(response: any, hcid: string): string {
    if (response.status === 200) {
      let url = null;
      let urlCreator =
        window.URL ||
        (window as any).webkitURL ||
        (window as any).mozURL ||
        (window as any).msURL;
      const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
        ie11 =
          navigator.userAgent.match(/Trident\/7.0/) &&
          navigator.userAgent.match(/rv:11/),
        ieEDGE = navigator.userAgent.match(/Edge/g),
        ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;

      //Download the blob
      const blob = new Blob([response.body], { type: "blob" });
      const fileName: string = "IDCard_" + hcid + ".pdf";
      if (ieVer > -1) {
        const nav = (window.navigator as any);
        nav.msSaveBlob(blob, fileName);
      } else if (navigator.userAgent.match("CriOS")) {
        url = urlCreator.createObjectURL(blob);
        window.open(url);
      } else {
        let link = document.createElement("a");
        url = urlCreator.createObjectURL(blob);
        link.setAttribute("style", "display: none");
        if ("download" in link) {
          link.href = url;
          link.download = fileName;
          link.click();
        }
      }
      return url;
    }
  }

  /**
   * @description getIDCardImages() Responsible for getting id card image from api
   * @param {boolean} isFrontImage
   * @returns {Observable<any>}
   */
  getIDCardImages(isFrontImage: boolean): Observable<any> {
    let endPoint: string = UrlProperties.UrlProperties.endPoints.idCard.front;
    if (!isFrontImage) {
      endPoint = UrlProperties.UrlProperties.endPoints.idCard.back;
    }
    return this.getImageObservable(endPoint, false, this.gbdToken)
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

   /**
   * @description getTransientIdCardInfo() Responsible for getting Transient id card info detail
   * @returns {string}
   */
  public getTransientIdCardInfo(): Observable<any[]> {
      return this.http.getObservable(
        UrlProperties.UrlProperties.endPoints.idCard.transientIdCard
      );
   }

  /**
   * @description getUserInfo() Responsible for getting user info detail
   * @returns {Observable<GetProfileDetailsResponse>}
   */
  public getUserInfo(): Observable<GetProfileDetailsResponse> {
    const { profileDetails } = UrlProperties.UrlProperties.endPoints.myProfile;
    return this.http.getObservable(profileDetails)
      .pipe(
        map((response: HttpResponse<GetProfileDetailsResponse>) => response.body),
        catchError(error => throwError(error))
      );
  }
  /**
   * @description sendEmail() Responsible sending email
   * @returns {Observable<HttpResponse<Object>>}
   */
  public sendEmail(emailAddresses: {
    [key: string]: string[];
  }): Observable<HttpResponse<Object>> {
    this.getMemCtx();
    this.headers = new HttpHeaders();
     this.headers = this.headers.append("X-MADT-AppVersion", "1.1.1");
     this.headers = this.headers.append("X-MADT-AppId", "MCDSHM");
     this.headers = this.headers.append("gbd-token", this.gbdToken);

    return this.httpClient.post(
      UrlProperties.UrlProperties.endPoints.idCard.email,
      emailAddresses,
      { headers: this.headers, observe: "response" }
    );
  }

  /**
   * @description sendFax() Responsible sending fax
   * @returns {Observable<HttpResponse<Object>>}
   */
  public sendFax(faxRequest: FaxIdCardAddressRequest): Observable<HttpResponse<Object>> {
    this.getMemCtx();
    this.headers = new HttpHeaders();

    this.headers = this.headers.append("gbd-token", this.gbdToken);

    return this.httpClient.post(
      UrlProperties.UrlProperties.endPoints.idCard.fax,
      faxRequest,
      { headers: this.headers, observe: "response" }
    );
  }

  /**
   * @description sendMail() Responsible sending email
   * @returns {Observable<HttpResponse<Object>>}
   */
  public sendMail(): Observable<number> {
    return this.http
      .postObservable(
        UrlProperties.UrlProperties.endPoints.idCard.mail, null
      )
      .pipe(
        map((response: any) => {
          return response.status;
        }),
        catchError((err) =>
          throwError({ message: "error sending mail id card request" })
        )
      );
  }

  /**
   * @description getMemCtx() Responsible for getting member context from session storage
   * @returns {void}
   */
  private getMemCtx(): void {
    const memberContext = JSON.parse(
      window.sessionStorage.getItem("sydMedMemberContext")
    );
    this.hcid = memberContext.hcid;
    this.gbdToken = window.sessionStorage.getItem("gbd-token");
    this.token = memberContext.token;
  }
  getImageObservable ( url: string, isPDF: boolean, token: any ): Observable<any> {
    let params = new HttpParams();
    if ( isPDF ) {
      params = params.append( 'isPDF', 'true' );
    }
    return this.httpClient.get( url, { params, observe: 'response', responseType: 'blob', headers: this.headers } );
  }
}
