import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';

@Component({
  selector: 'app-search-results-card',
  templateUrl: './search-results-card.component.html',
  styleUrls: ['./search-results-card.component.css']
})
export class SearchResultsCardComponent implements OnInit {
  
  labels: any;

  constructor(
    private jsonContentService: JsonContentService
  ) { }

  ngOnInit(): void {
    this.jsonContentService.getJSON('community-resources').subscribe((data) => {
      if(data) {
        this.labels = data.communityResources?.searchResultsCard;
      }
    })
  }

}
