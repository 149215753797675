import { Component, OnInit, QueryList, ViewChildren, HostListener, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateUsernamePassword } from './create-username-password.model';
import { RegistrationService } from '../registration.service';
import { RegistrationDataService } from '../app.data.service';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { AntTooltipDirective } from '@anthem/uxd/tooltip';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-create-username-password',
  templateUrl: './create-username-password.component.html',
  styleUrls: ['./create-username-password.component.css']
})
export class CreateUsernamePasswordComponent implements OnInit, OnDestroy {

  constructor(
    private readonly formBuilder: FormBuilder,
    private createUsernamePassword: CreateUsernamePassword,
    private regSvc: RegistrationService,
    private router: Router,
    private dataSvc: RegistrationDataService,
    private errSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService
  ) { }

  @ViewChildren(AntTooltipDirective) tooltips: QueryList<AntTooltipDirective>;

  public tooltipAlpha: AntTooltipDirective;

  accountInfoForm: FormGroup;
  public loadingSpinner: boolean = false;
  public usernameAvailable = true;
  public createUsernameModel: any = [];
  public jsonLabels;
  public jsonErrors;
  public aslError;
  public usernameSuggestions = [];
  public radioGroup = {
    isOptional: false,
    alignVertical: false, //set to true to align vertically
    id: 'rbtnGrp1',
    name: 'rbtnGrp1',
    options: [
      {
        label: '',
        value: 'text',
        analytics: 'analytics-one'
      },
      {
        label: '',
        value: 'voice',
        analytics: 'analytics-two'
      },
    ]
  };
  public emailOptional = !JSON.parse(sessionStorage.contactsModel).memberResponse.emailRequired;
  private _subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this._subscriptions.push(
      this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Create_Username_Password;
      this.jsonErrors = data.RegistrationMFA.ErrorMsgs;
      this.radioGroup.options[0].label = this.jsonLabels.Field_Labels.Text;
      this.radioGroup.options[1].label = this.jsonLabels.Field_Labels.Voice;
    }));
    
    this.accountInfoForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(19), FormValidators.UnHasTwoLtrPre, FormValidators.UnHasDigitPrefix, FormValidators.UnHasDot, FormValidators.NoSpaceValidator, FormValidators.ValidCharsValidator]],
      emailAddress: ['', []],
      passwords: [],
      phone: ['', [Validators.required, FormValidators.PhoneNumberValidator]],
      option: ['', [Validators.required]], 
    });

    if (this.emailOptional) {
      this.accountInfoForm.get('emailAddress').setValidators([FormValidators.EmailValidator]);
    } else {
      this.accountInfoForm.get('emailAddress').setValidators([Validators.required,FormValidators.EmailValidator]);
    }

    if (sessionStorage.recoveryModel) {
      if (JSON.parse(sessionStorage.recoveryModel).selected.contactId === "ARN") {
        this.accountInfoForm.controls['phone'].setValue(JSON.parse(sessionStorage.recoveryModel).selected.newPhoneNumber.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3'));
        this.accountInfoForm.controls['option'].setValue(JSON.parse(sessionStorage.recoveryModel).selected.contactSubType);
      }
    }

    if(sessionStorage.getItem('securityQuestionsVisited') === 'true') {
      this.dataSvc.setCreateUserForm(this.accountInfoForm);
    }
  }

  ngAfterViewInit() {
    this.tooltipAlpha = this.tooltips.toArray()[0];
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isBCBSMNMarket()
  {
    return this.jsonSvc.getHostMarket() === 'BCBSMN';
  }

  submitAccountInfo() {
    this.onChangeInput();
    if(this.accountInfoForm.valid) {
      this.createUsernameModel.userNameCheck.checking = true;
      this.loadingSpinner = true;
      this._subscriptions.push(
        this.regSvc.checkUserNameAvailable(this.createUsernamePassword.transformUsernameData(this.accountInfoForm.value.userName))
          .subscribe(async res => {
            if(res.status === 204) {
              await this.regSvc.tokenize({data: JSON.stringify(this.createUsernameModel)}).then(response => {
                sessionStorage.setItem('userModel', response.body.token);
              })
              this.router.navigate(['public/registration/security-questions']);
            } else if (res?.body?.usernameSuggestions) {
              this.usernameSuggestions = res.body.usernameSuggestions;
              this.createUsernameModel.userNameCheck.checking = false;
              this.usernameAvailable = false;
              this.loadingSpinner = false;
            }
          },
          error => {
            this.aslError = this.errSvc.errorCodeMapper(error, this.jsonErrors);
            this.createUsernameModel.userNameCheck.checking = false;
            this.usernameAvailable = false;
            this.loadingSpinner = false;
          })
      );
    } else {
      this.accountInfoForm.markAllAsTouched();
      this.dataSvc.focusInvalid();
    }
  }
  
  back() {
    this.router.navigate(['public/registration/home']);
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
      this.router.navigate(["public/registration/home"]);
  }
  
  public onChangeInput() {
    this.createUsernameModel = this.createUsernamePassword.updateCreateUsernamePasswordModel(this.accountInfoForm);
  }
  
  checkUserName() {
    const passwordInput = document.getElementById('new-password');
    if(!this.accountInfoForm.controls['userName'].errors) {
      this.createUsernameModel = this.createUsernamePassword.resetUserNameCheck();
      this.createUsernameModel.userNameCheck.checking = true;
      this.regSvc.checkUserNameAvailable(this.createUsernamePassword.transformUsernameData(this.accountInfoForm.value.userName)).subscribe((res: any) => {
        if(res.status === 204) {
          this.createUsernameModel.userNameCheck.checking = false;
          this.createUsernameModel.userNameCheck.available = true;
          this.usernameAvailable = true;
        } else if (res?.body?.usernameSuggestions) {
          this.usernameSuggestions = res.body.usernameSuggestions;
          this.createUsernameModel.userNameCheck.checking = false;
          this.usernameAvailable = false;
        }
      },
      error => {
        this.aslError = this.errSvc.errorCodeMapper(error, this.jsonErrors);
        this.createUsernameModel.userNameCheck.checking = false;
        this.usernameAvailable = false;
      })
    }
  }

  public replaceUsername(suggestedUsername) {
    this.accountInfoForm.controls['userName'].setValue(suggestedUsername);
    this.checkUserName();
  }
}
