import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CallMeService } from "./services/call-me.service";
import { CallUsComponent } from './call-us/call-us.component';
import { MessageUsComponent } from './message-us/message-us.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { CallMeComponent } from "./call-me/call-me.component";
import { TabsModule } from '@anthem/uxd/tabs';
import { AlertModule } from '@anthem/uxd/alert';
import { AccordianModule } from '@anthem/uxd/accordion';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { InputModule } from '@anthem/uxd/input';
import { CommonPipesModule } from 'sydmed/libs/common-pipes/src/lib/common-pipes.module';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { IndicatorModule } from '@anthem/uxd/indicators';

@NgModule({
  declarations: [
    AppComponent,
    CallUsComponent,
    MessageUsComponent,
    LiveChatComponent,
    CallMeComponent,
],
  imports: [
    AppRoutingModule,
    CustomValidatorsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    CommonPipesModule,
    TabsModule,
    AccordianModule,
    AlertModule,
    DropdownModule,
    InputModule,
    IndicatorModule
  ],
  providers: [
    CallMeService,
    JsonContentService
  ],
  bootstrap: [AppComponent]
})

export class ContactUsModule { }
