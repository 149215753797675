import { ClaimsContent } from '../interfaces/claimsContent';
import { ClaimSummary, ClaimDetailsAPIResponse, Address, ProviderInfo, ClaimGenericCode, ClaimDetails, LineItems } from "../interfaces/claims";
import { CurrencyUtility } from "@anthem/mbrportal/utility/services/currencyUtil";
import { DateUtility } from "../util/dateUtil";
import { Injectable } from "@angular/core";
import { PharmacyClaimCode } from "../values/claimsConstants";
import { Claim, ClaimAmount } from 'gbd-models';

/**
 * @description
 * This model class is used to create a formatted result for claims summary & detail from the passed claim list model.
 *
 * @example
 * ```@function
 * buildClaimSummaryObject(rawClaimData: Claim, content: ClaimsContent): ClaimSummary
 * addDetailsInClaimsList(claim: ClaimDetails, claimsModel: ClaimDetailsAPIResponse, content: ClaimsContent): ClaimDetails
 *
 * ```
 */

@Injectable({
  providedIn: "root",
})
export class ClaimsModel {
  private content: ClaimsContent;
  private emptyString: string = "";
  private na: string;
  private pharmacyProviderAriaAddress: string;
  private providerNA: string;

  constructor(
    private currencyUtil: CurrencyUtility,
    private dateUtil: DateUtility
  ) {}

  /**
   * @description addDetailsInClaimsList() Get formatted claims detail object
   * @param {ClaimDetails} claim
   * @param {ClaimDetailsAPIResponse} claimsModel
   * @param {ClaimsContent} content
   * @returns {ClaimDetails}
   */
  addDetailsInClaimsList(
    claim: ClaimDetails,
    claimsModel: ClaimDetailsAPIResponse,
    content: ClaimsContent
  ): ClaimDetails {
    this.na =
      typeof content.notAvailable !== "undefined"
        ? content.notAvailable
        : "N/A";
    this.pharmacyProviderAriaAddress = this.na;
    claim.clmId = this.fmt(claimsModel.clmId);
    claim.clmUId = this.fmt(claimsModel.clmUId);
    (claim.startDate =
      this.fmt(claimsModel.clmStartDt) !== this.na
        ? this.dateUtil.getDatePart(claimsModel.clmStartDt, "MM/dd/yyyy")
        : this.na),
      (claim.endDate =
        this.fmt(claimsModel.clmEnddt) !== this.na
          ? this.dateUtil.getDatePart(claimsModel.clmEnddt, "MM/dd/yyyy")
          : this.na),
      (claim.clmProcessDt = this.fmt(claimsModel.clmProcessDt));
    claim.clmProcessDt =
      this.dateUtil.compareDates(claimsModel.clmProcessDt, claim.startDate) ===
      1
        ? this.na
        : claim.clmProcessDt;

    if (claimsModel.serviceLines) {
      claim.lineItems = this.fmt(
        this.setLineItems(claim, claimsModel.serviceLines)
      );
    }

    //Prescription details
    if (claimsModel.serviceLines && claimsModel.serviceLines.length) {
      if (this.fmt(claimsModel.serviceLines[0].pharmacyInfo) !== this.na) {
        claim.prescriptionNbr = this.fmt(
          claimsModel.serviceLines[0].pharmacyInfo.prescriptionNbr
        );
        claim.medicationLabelNm = this.fmt(
          claimsModel.serviceLines[0].pharmacyInfo.medicationLabelNm
        );
        if (claim.medicationLabelNm !== this.na) {
          claim.medicationLabelNm = this.capitalize(claim.medicationLabelNm);
        }
        claim.quantity =
          claimsModel.serviceLines[0].pharmacyInfo.dispensedQuantityNbr;
        claim.supply = claimsModel.serviceLines[0].pharmacyInfo.daysSupplyNbr;
      }
    }

    claim.pharmacyAddress = this.fmt(
      this.setPharmacyAddress(claimsModel.providerInfo)
    );
    claim.pharmacyAriaAddress = this.pharmacyProviderAriaAddress;
    claim.prescribedBy = this.fmt(
      this.setPrescribedBy(claimsModel.providerInfo)
    );
    return claim;
  }

  /**
   * @description buildClaimSummaryObject() Get formatted claims summary object
   * @param {Claim} rawClaims
   * @param {ClaimsContent} content
   * @returns {ClaimSummary}
   */
  buildClaimSummaryObject(
    rawClaimData: Claim,
    content: ClaimsContent
  ): ClaimSummary {
    if (typeof content !== "undefined") {
      this.content = content;
    }

    this.na =
      typeof this.content.notAvailable !== "undefined"
        ? this.content.notAvailable
        : "N/A";
    this.providerNA =
      typeof this.content.providerNA !== "undefined"
        ? this.content.providerNA
        : "Provider not available";
    let claim: ClaimSummary = {
      clmId: this.fmt(rawClaimData.clmId),
      clmUId: this.fmt(rawClaimData.clmUId),
      claimType: this.emptyString,
      startDate:
        this.fmt(rawClaimData.clmStartDt) !== this.na
          ? this.dateUtil.getDatePart(rawClaimData.clmStartDt, "MM/dd/yyyy")
          : this.na,
      endDate:
        this.fmt(rawClaimData.clmEndDt) !== this.na
          ? this.dateUtil.getDatePart(rawClaimData.clmEndDt, "MM/dd/yyyy")
          : this.na,
      patientName:
        this.fmt(rawClaimData.patient?.patientNm) !== this.na
          ? this.capitalize(rawClaimData.patient.patientNm)
          : this.na,
      dob: rawClaimData.patient?.dob
        ? "(" +
          this.dateUtil.getDatePart(rawClaimData.patient.dob, "MM/dd/yyyy") +
          ")"
        : this.emptyString,
      classCode: this.emptyString,
      classCodeDescription: this.emptyString,
      statusName: this.emptyString,
      statusDesc: this.emptyString,
      providerName: this.setProviderAttrs(rawClaimData),
      revisionIdentifier: this.fmt(rawClaimData.clmRevisionIdentifier),
      sourceSystemId: this.fmt(rawClaimData.sourceSystemId),
      isExpanded: false,
      eob: this.fmt(rawClaimData.eob),
    };
    if (rawClaimData.clmClassCd) {
      claim.claimType =
        this.fmt(rawClaimData.clmClassCd.code) !== this.na
          ? this.content.summary.claimTypeValues[rawClaimData.clmClassCd.code]
          : this.na;
      claim.classCode = this.fmt(rawClaimData.clmClassCd.code);
      claim.classCodeDescription = this.fmt(
        rawClaimData.clmClassCd.description
      );
    }
    if (rawClaimData.clmStatusCd) {
      claim.statusName = this.content.summary.statuses[
        this.fmt(rawClaimData.clmStatusCd.code)
      ];
      claim.statusDesc = this.fmt(rawClaimData.clmStatusCd.description);
    }
    this.setAmount(claim, rawClaimData.amount);

    return claim;
  }

  /**
   * @description setAmount() Get formatted amount along with currency
   * @param {ClaimSummary} claim
   * @param {ClaimAmount} amount
   * @returns {void}
   */
  private setAmount(claim: ClaimSummary, amount: ClaimAmount): void {
    if (amount && Object.keys(amount).length) {
      claim.billedAmount = this.formatAmount(amount.totalChargeAmt);
      claim.billedAmountCurrency =
        claim.billedAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.billedAmount)
          : this.na;

      claim.coveredAmount = this.formatAmount(amount.allowedAmt);
      claim.coveredAmountCurrency =
        claim.coveredAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.coveredAmount)
          : this.na;

      claim.notCoveredAmount = this.formatAmount(amount.nonCoveredAmt);
      claim.notCoveredAmountCurrency =
        claim.notCoveredAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.notCoveredAmount)
          : this.na;

      claim.planPaidAmount = this.formatAmount(amount.paidAmt);
      claim.planPaidAmountCurrency =
        claim.planPaidAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.planPaidAmount)
          : this.na;

      claim.yourResponsibilityAmount = this.formatAmount(
        amount.mbrResponsibilityAmt
      );
      claim.yourResponsibilityAmountCurrency =
        claim.yourResponsibilityAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.yourResponsibilityAmount)
          : this.na;

      claim.ductibleAmount = this.formatAmount(amount.deductibleAmt);
      claim.ductibleAmountCurrency =
        claim.ductibleAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.ductibleAmount)
          : this.na;

      claim.coinsuranceAmount = this.formatAmount(amount.coinsuranceAmt);
      claim.coinsuranceAmountCurrency =
        claim.coinsuranceAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.coinsuranceAmount)
          : this.na;

      claim.copayAmount = this.formatAmount(amount.copayAmt);
      claim.copayAmountCurrency =
        claim.copayAmount !== this.na
          ? this.currencyUtil.formatCurrency(claim.copayAmount)
          : this.na;
    } else {
      // TODO: Need to confirm default value in case of empty amount object or amount is null or undefined
      claim.billedAmount = claim.coveredAmount = claim.notCoveredAmount = claim.planPaidAmount = claim.yourResponsibilityAmount = claim.ductibleAmount = claim.coinsuranceAmount = claim.copayAmount =
        "0.00";

      claim.billedAmountCurrency = claim.coveredAmountCurrency = claim.notCoveredAmountCurrency = claim.planPaidAmountCurrency = claim.yourResponsibilityAmountCurrency = claim.ductibleAmountCurrency = claim.coinsuranceAmountCurrency = claim.copayAmountCurrency = this.na;
    }
  }

  /**
   * @description setLineItems() Get formatted claims detail object
   * @param {ClaimDetails} claim
   * @param {any} lineItems
   * @returns {LineItems[]}
   */
  private setLineItems(claim: ClaimDetails, lineItems: any): LineItems[] {
    lineItems = this.fmt(lineItems);

    if (lineItems === this.na) {
      return [];
    }

    let explanationObj: ClaimGenericCode = {};

    let totalMbrResponsibilityAmt = 0;
    let totalAmtBilled = 0;
    let totalAmtDiscounted = 0;
    let totalAmtCovered = 0;
    let totalAmtNotCovered = 0;

    for (let i = 0; i < lineItems.length; i++) {
      lineItems[i].mbrResponsibilityAmt = this.fmt(
        lineItems[i].mbrResponsibilityAmt
      );

      if (lineItems[i].mbrResponsibilityAmt !== this.na) {
        totalMbrResponsibilityAmt += +lineItems[i].mbrResponsibilityAmt;
        lineItems[i].mbrResponsibilityAmt = this.currencyUtil.formatCurrency(
          lineItems[i].mbrResponsibilityAmt
        );
      }

      lineItems[i].explanationCd = this.fmt(lineItems[i].explanationCd);
      if (lineItems[i].explanationCd !== this.na) {
        if (lineItems[i].explanationCd) {
          lineItems[i].explanationCd.code = this.fmt(
            lineItems[i].explanationCd.code
          );
          lineItems[i].explanationCd.description = this.fmt(
            lineItems[i].explanationCd.description
          );
        }

        for (let j = 0; j < lineItems[i].explanationCd.length; j++) {
          lineItems[i].explanationCd[j].code = this.fmt(
            lineItems[i].explanationCd[j].code
          );
          lineItems[i].explanationCd[j].description = this.fmt(
            lineItems[i].explanationCd[j].description
          );
          explanationObj[lineItems[i].explanationCd[j].code] =
            lineItems[i].explanationCd[j].description;
        }
      } else {
        lineItems[i].explanationCd = [];
      }

      lineItems[i].discountAmt = this.fmt(lineItems[i].discountAmt);
      if (lineItems[i].discountAmt !== this.na) {
        totalAmtDiscounted += +lineItems[i].discountAmt;
        lineItems[i].discountAmt = this.currencyUtil.formatCurrency(
          lineItems[i].discountAmt
        );
      }

      lineItems[i].chargeAmt = this.fmt(lineItems[i].chargeAmt);
      if (lineItems[i].chargeAmt !== this.na) {
        totalAmtBilled += +lineItems[i].chargeAmt;
        lineItems[i].chargeAmt = this.currencyUtil.formatCurrency(
          lineItems[i].chargeAmt
        );
      }

      lineItems[i].deductibleAmt = this.fmt(lineItems[i].deductibleAmt);

      lineItems[i].paidAmt = this.fmt(lineItems[i].paidAmt);

      if (lineItems[i].paidAmt !== this.na) {
        lineItems[i].paidAmt = this.currencyUtil.formatCurrency(
          lineItems[i].paidAmt
        );
      }

      lineItems[i].allowedAmt = this.fmt(lineItems[i].allowedAmt);
      if (lineItems[i].allowedAmt !== this.na) {
        totalAmtCovered += +lineItems[i].allowedAmt;
        lineItems[i].allowedAmt = this.currencyUtil.formatCurrency(
          lineItems[i].allowedAmt
        );
      }

      lineItems[i].disallowedAmt = this.fmt(lineItems[i].disallowedAmt);
      if (lineItems[i].disallowedAmt !== this.na) {
        totalAmtNotCovered += +lineItems[i].disallowedAmt;
        lineItems[i].disallowedAmt = this.currencyUtil.formatCurrency(
          lineItems[i].disallowedAmt
        );
      }

      lineItems[i].serviceStartDt = this.fmt(lineItems[i].serviceStartDt);
      lineItems[i].serviceEndDt = this.fmt(lineItems[i].serviceEndDt);

      lineItems[i].procedureCd = this.fmt(lineItems[i].procedureCd);

      if (lineItems[i].procedureCd !== this.na) {
        lineItems[i].procedureCd.name = this.fmt(lineItems[i].procedureCd.name);
      }

      lineItems[i].revenueCd = this.fmt(lineItems[i].revenueCd);

      if (lineItems[i].revenueCd !== this.na) {
        lineItems[i].revenueCd.name = this.fmt(lineItems[i].revenueCd.name);
      }

      lineItems[i].isExpand = false;
    }

    claim.totalMbrResponsibilityAmt = this.currencyUtil.formatCurrency(
      totalMbrResponsibilityAmt.toString()
    );
    claim.totalAmtBilled = this.currencyUtil.formatCurrency(
      totalAmtBilled.toString()
    );
    claim.totalAmtDiscounted = this.currencyUtil.formatCurrency(
      totalAmtDiscounted.toString()
    );
    claim.totalAmtCovered = this.currencyUtil.formatCurrency(
      totalAmtCovered.toString()
    );
    claim.totalAmtNotCovered = this.currencyUtil.formatCurrency(
      totalAmtNotCovered.toString()
    );

    return lineItems;
  }

  /**
   * @description setProviderAttrs() set provider attributes
   * @param {ClaimDetailsAPIResponse} rawClaimData
   * @returns {string}
   */
  private setProviderAttrs(rawClaimData: Claim): string {
    let providerName = this.providerNA;
    const classCode: string = rawClaimData.clmClassCd
      ? rawClaimData.clmClassCd.code
      : this.emptyString;
    if (
      classCode === PharmacyClaimCode &&
      rawClaimData.providerInfo?.pharmacyProvider
    ) {
      providerName = this.capitalize(
        rawClaimData.providerInfo.pharmacyProvider.pharmacyNm
      );
    } else if (rawClaimData.providerInfo?.provNm) {
      providerName = rawClaimData.providerInfo.provNm;
    }

    return providerName;
  }

  /**
   * @description setPharmacyAddress() set Pharmacy address
   * @param {ProviderInfo} provider
   * @returns {string}
   */
  private setPharmacyAddress(provider: ProviderInfo): string {
    let pharmacyAddress: Address = {
      addressLn1: this.emptyString,
      city: this.emptyString,
      state: this.emptyString,
      zipCd: this.emptyString,
    };

    if (
      this.fmt(provider) !== this.na &&
      this.fmt(provider.pharmacyProvider) !== this.na &&
      provider.pharmacyProvider.pharmacyAddress &&
      Object.keys(provider.pharmacyProvider.pharmacyAddress).length
    ) {
      pharmacyAddress.addressLn1 = this.fmt(
        provider.pharmacyProvider.pharmacyAddress.addressLn1
      );
      pharmacyAddress.city = this.fmt(
        provider.pharmacyProvider.pharmacyAddress.city
      );
      pharmacyAddress.state = this.fmt(
        provider.pharmacyProvider.pharmacyAddress.state
      );
      pharmacyAddress.zipCd = this.fmt(
        provider.pharmacyProvider.pharmacyAddress.zipCd
      );

      if (
        pharmacyAddress.addressLn1 !== this.na &&
        pharmacyAddress.city !== this.na &&
        pharmacyAddress.state !== this.na &&
        pharmacyAddress.zipCd !== this.na
      ) {
        this.pharmacyProviderAriaAddress =
          pharmacyAddress.addressLn1 +
          ", " +
          pharmacyAddress.city +
          ", " +
          pharmacyAddress.state +
          " " +
          pharmacyAddress.zipCd;
        return (
          this.capitalize(pharmacyAddress.addressLn1) +
          ",<br />" +
          this.capitalize(pharmacyAddress.city) +
          ", " +
          this.capitalize(pharmacyAddress.state) +
          " " +
          pharmacyAddress.zipCd
        );
      }
    } else {
      return this.na;
    }
  }

  /**
   * @description setPrescribedBy() set provider info
   * @param {ProviderInfo} provider
   * @returns {ClstringaimDetails}
   */
  private setPrescribedBy(provider: ProviderInfo): string {
    let prescribedBy: string = this.emptyString;
    if (
      this.fmt(provider) !== this.na &&
      this.fmt(provider.pharmacyProvider) !== this.na &&
      provider.pharmacyProvider.prescribedBy
    ) {
      prescribedBy = this.fmt(provider.pharmacyProvider.prescribedBy);
      if (prescribedBy !== this.na) {
        prescribedBy = this.capitalize(prescribedBy);
      }
    }
    return prescribedBy;
  }

  /**
   * @description formatAmount() responsible checking null undefined and blank values
   * @param {number} amountField
   * @returns {string}
   */
  private formatAmount(amountField: number): string {
    let formattedAmount: string =
      amountField || amountField === 0 ? amountField.toString() : this.na;
    return formattedAmount;
  }

  /**
   * @description capitalize() responsible for capitalize sentense or word passed
   * @param {string} input
   * @returns {string}
   */
  private capitalize(input: string): string {
    return input
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }

  /**
   * @description fmt() responsible checking null undefined and blank values
   * @param {any} rawValue
   * @returns {any}
   */
  private fmt(rawValue: any): any {
    if (!rawValue) {
      rawValue = this.na;
    }
    return rawValue;
  }
}
