<div class="account-balance-payment">
  <div class="row">
    <div class="col-sm-12">
      <h4>{{ heading }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="motif-margin-top-5">
      <div class="col-sm-12">
        <div class="total-balance">{{ convertToNumber(totalBalance) | currency }}</div>
        <div>{{ labels.Subheading }}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="motif-margin-top-5">
      <div class="col-sm-12">
        <div class="button-group" *ngIf="totalBalance !== '0' && totalBalance !== '0.00'">
          <button class="btn btn-secondary" (click)="routeToPayBalance()" [attr.data-analytics]="analyticsTag"> {{ labels.Action }}</button>
        </div>
        <div class="no-balance" *ngIf="totalBalance === '0' || totalBalance === '0.00'">
          <h4>{{ labels.NoAction }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
