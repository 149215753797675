<!-- TODO: Hard coded values will be removed after API integration -->
<div class="community-resources-results-card-container">
    <!-- Disabling the default behaviour of anchor tag, Routing url needs to be added while developing the functionality -->
    <a class="text-decoration-none font-bold font-18" href="javascript:void(0)">Food Pantry</a>
    <div class="flex-horizontal-start margin-top-12">
        <span class="font-14 margin-right-5">{{labels?.resultsBy_text}}</span>
        <!-- Disabling the default behaviour of anchor tag, Routing url needs to be added while developing the functionality -->
        <a class="font-16 text-decoration-none font-bold" href="javascript:void(0)">
            Fay Biccard Glick Neighborhood Center(FBGNC)
        </a>
    </div>
    <div class="results-info-container">
        <div class="results-info">
            <h6 class="community-resources-results-member-header font-12 margin-bottom-16">{{labels?.memberBenefit_Header}}</h6>
            <p class="resources-info vertical-line-clamp-2 mobile-vertical-clamp-5">Additional assistance is provided to families and individuals in need of emergency food throughout</p>
            <div class="results-highlights">
                <div class="flex-horizontal-start font-16">
                    <div class="service-title">{{labels?.mainService_label}}</div>
                    <p>help pay for food, government food benefits, nutrition education, parenting education</p>
                </div>
                <div class="margin-top-24 flex-horizontal-start font-16">
                    <div class="service-title">{{labels?.serving_label}}</div>
                    <p>all ages, covid19, female, pregnant, benefit recipients, low-income, caregivers</p>
                </div>
            </div>
            <div class="results-action-container margin-top-26">
                <button class="results-action-btn">
                    <span class="motif-icon motif-external-link margin-right-8"></span>
                    <span class="btn-text font-bold">{{labels?.shareOption_label}}</span>
                </button>
                <button class="results-action-btn margin-left-10">
                    <span class="motif-icon motif-pencil margin-right-8"></span>
                    <span class="btn-text font-bold">{{labels?.suggestOption_label}}</span>
                </button>
            </div>
        </div>
        <div class="results-next-steps-container">
            <div class="font-18">{{labels?.nextSteps_Heading}}</div>
            <!-- TODO: Need to add the conditional rendering based on API response, Created this template with all possible cases -->
            <div class="font-16 margin-top-12">
                <span>{{labels?.contactOrGotoText}}</span>
                <a class="font-16 text-decoration-none" href="javascript:void(0)">{{labels?.nearestLocation_label}}</a>
            </div>
            <div class="font-16 margin-top-12">
                <span>{{labels?.gotoText}}</span>
                <a class="font-16 text-decoration-none" href="javascript:void(0)">{{labels?.programWebsite_label}}</a>
                <span>{{labels?.orText_label}} {{labels?.contactOrGotoText}}</span>
                <a class="font-16 text-decoration-none" href="javascript:void(0)">{{labels?.nearestLocation_label}}</a>
            </div>
            <div class="font-16 margin-top-12">
                {{labels?.email_Heading}}
                <p class="font-16"><a class="text-decoration-none" href="mailto:INAnthemBurmese@anthem.com">INAnthemBurmese@anthem.com</a> to get services.</p>
            </div>
            <div class="margin-top-12 font-14">
                {{labels?.localArea_label}}
            </div>
            <div class="margin-top-12 time-schedule font-16">
                <span class="motif-icon motif-info margin-right-5"></span>
                <span>{{labels?.openSchedule_label}}</span>
                <span class="font-bold">8:00am - 5:00pm</span>
            </div>
        </div>
    </div>
</div>