import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { PaymentMethodRadioGroup } from '../interfaces/PaymentMethodRadioGroup';
import {
  AutopayPayload,
  PaymentType,
  AccountStatus,
} from '../interfaces/Autopay';
import { PaymentHistory } from '../interfaces/PaymentHistory';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { PaymentConfirmation,PIEData } from "../interfaces/PayNow";
import { PaymentInfo, PaymentStatus } from '../interfaces/PaymentInfo';
import {
  PaymentMethodOption,
  AddressOption,
} from '../interfaces/PaymentMethodOption';
import { PaymentHistory as PaymentHistoryResponse, PastPayment, PaymentType as PaymentMethodType, CreditCardPaymentType, CreditCardAccountDetails } from "gbd-models";
declare let PIE: any;
declare let ValidatePANChecksum: any;
declare let ProtectPANandCVV: any;

@Injectable()
export class PaymentHelperService {
  protected selectedPayment$: BehaviorSubject<PaymentInfo> = new BehaviorSubject<PaymentInfo>(
    null
  );
  public selectedPayment = this.selectedPayment$.asObservable();
  protected selectedPaymentMethod$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public selectedPaymentMethod = this.selectedPaymentMethod$.asObservable();
  protected paymentConfirmation$: BehaviorSubject<PaymentConfirmation> = new BehaviorSubject<PaymentConfirmation>(
    null
  );
  public paymentConfirmation = this.paymentConfirmation$.asObservable();
  protected reloadBills$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  public reloadBills = this.reloadBills$.asObservable();
  constructor() { }

  get getSelectedPaymentMethod() {
    return this.selectedPaymentMethod$.value;
  }

  setSelectedPayment(payment: PaymentInfo) {
    window.sessionStorage.setItem('billInfo',  JSON.stringify(payment));
    this.selectedPayment$.next(payment);
  }

  setSelectedPaymentMethod(
    paymentMethod: PaymentMethod,
    isAdded: boolean,
    error: any
  ) {
    const obj = {
      isAdded,
      paymentMethod,
      error,
    };
    this.selectedPaymentMethod$.next(obj);
  }

  initiateSelectedPaymentMethod() {
    this.selectedPaymentMethod$.next(null);
  }

  setPaymentConfirmation(payConf: PaymentConfirmation) {
    this.paymentConfirmation$.next(payConf);
  }
  setReloadBills(reload: boolean) {
    this.reloadBills$.next(reload);
  }

  public createRadioGroup(
    PaymentMethodOptions: PaymentMethodOption[]
  ): PaymentMethodRadioGroup {
    return {
      isOptional: false,
      alignVertical: true,
      id: 'PaymentMethodRadioGroup',
      name: 'PaymentMethodRadioGroup',
      label: 'radio input group array',
      legendSrOnly: true,
      options: PaymentMethodOptions,
    };
  }

  public createAddressOptions(AddressList: any[], content: string, addNewValue: string): AddressOption[] {
    let addressOptions: AddressOption[];
    if (AddressList) {
      addressOptions = AddressList.map((payment: any) => {
        return {
          payment,
          label: `${payment.streetAddress1}`,
          value: payment.streetAddress1,
          isDisabled: false
        };
      });
    }
    addressOptions.push({
      payment: null,
      label: `${content} `,
      value: addNewValue,
      isDisabled: false
    });
    return addressOptions;
  }

  public createPaymentMethodOptions(PaymentMethodList: PaymentMethod[], content: string, addNewValue: string): PaymentMethodOption[] {
    let PaymentMethodOptions: PaymentMethodOption[];
    if (PaymentMethodList) {
      PaymentMethodOptions = PaymentMethodList.map((payment: PaymentMethod) => {
        return {
          payment,
          label: payment.isBankAcc ? `${payment.bankAccountType + "-" + payment.bankAccountNumber}` : `${payment.creditCardType + "-" + payment.creditCardNumber}`,
          value: payment.accountName,
          isDisabled: false
        };
      });
    }
    PaymentMethodOptions.push({
      paymentMethod: null,
      label: `${content} `,
      value: addNewValue,
      isDisabled: false
    });
    return PaymentMethodOptions;
  }

  mapPaymentMethods(response) {
    let methods = [] as Array<PaymentMethod>;
    let address = [];
    let mergedResponse: any[] = response.paymentMethods;
    if (response) {
      if (mergedResponse) {
        mergedResponse.forEach(element => {
          let payment = {} as PaymentMethod;
          if (element.accountType == "BANK_ACCOUNT") {
            payment.isBankAcc = true;
            payment.bankAccountNumber = element.accountDetails.accountNumber;
            payment.bankAccountType = element.accountDetails.bankAccountType;
            payment.routingNumber = element.accountDetails.routingNumber;
            payment.tokenId = element.tokenId;
          }
          else {
            payment.isBankAcc = false;
            payment.creditCardNumber = element.accountDetails.accountNumber.substr(12);
            payment.cardNumberFull =  element.accountDetails.accountNumber;
            payment.creditCardType = element.accountDetails.cardType;
            payment.expirationDate = element.accountDetails.expirationMonth + "/" + element.accountDetails.expirationYear;
            payment.expirationMonth = element.accountDetails.expirationMonth;
            payment.expirationYear = element.accountDetails.expirationYear;
            payment.tokenId = element.tokenId;
          }
          payment.accountHolderInfo = element.accountHolderInfo;
          methods.push(payment);
          address.push(element.accountHolderInfo?.billingAddress);
        })
      }
    }
    return { methods, address };
  }

  mapPaymentMethodFromApi(response) {
    let methods = [] as Array<PaymentMethod>;
    if (response.paymentMethods) {
      response.paymentMethods.forEach((element) => {
        let payment = {} as PaymentMethod;
        if (element.accountType === PaymentMethodType.BANK_ACCOUNT) {
          payment.isBankAcc = true;
          payment.bankAccountType;
          payment.bankAccountNumber = element.accountDetails.accountNumber;
          payment.bankAccountType = element.accountDetails.bankAccountType;
          payment.routingNumber = element.accountDetails.routingNumber;
          payment.tokenId = element.tokenId;
        } else {
          payment.isBankAcc = false;
          payment.creditCardNumber = element.accountDetails.accountNumber.substr(12);
          payment.creditCardType = element.accountDetails.cardType;
          payment.expirationDate =
            element.accountDetails.expirationMonth + "/" + element.accountDetails.expirationYear;
          payment.tokenId = element.tokenId;
        }
        methods.push(payment);
      });
    }
    return methods;
  }

  transformDueDate(dueDate) {
    return moment(dueDate).format('MM/DD/YYYY');
  }
  transformSelectedDate(selectedDate) {
    return moment(selectedDate).format('YYYY-MM-DD');
  }
  transformToApi(
    selectedBill,
    isEditMode,
    paymentMethod,
    selDate
  ): AutopayPayload[] {
    let data = [];
    if (isEditMode) {
      data.push({
        createdBy: selectedBill.memberId,
        recurringPayStatus: 'ACTIVE',
        planID: selectedBill.planId,
        productID: selectedBill.productId,
        payDate: selDate,
        authMode: 'WEB',
        tokenID: paymentMethod.tokenId,
        paymentType:
          paymentMethod && paymentMethod.isBankAcc
            ? PaymentType.BANK
            : PaymentType.CREDIT,
      });
    } else {
      data.push({
        planID: selectedBill.planId,
        productID: selectedBill.productId,
        payDate: selDate,
        authMode: 'WEB',
        tokenID: paymentMethod.tokenId,
        paymentType:
          paymentMethod && paymentMethod.isBankAcc
            ? PaymentType.BANK
            : PaymentType.CREDIT,
      });
    }
    return data;
  }
  findAutopayMethod(methods, tokenIds): PaymentMethod {
    let payments: PaymentMethod[];
    payments = this.mapPaymentMethodFromApi(methods);
    if (tokenIds.length && tokenIds.length === 1) {
      return payments.find((x) => x.tokenId === tokenIds[0]);
    }
  }
  getAccountStatus(paymentMethod): AccountStatus {
    // 0 - not expired, 1 - expiring in 1 month, 2 - expired
    let cardStatus = AccountStatus.ACTIVE;
    if (paymentMethod && !paymentMethod.isBankAcc) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      const dateParts = paymentMethod.expirationDate.split('/');
      const ccMonth = parseInt(dateParts[0]);
      const ccYear = parseInt(dateParts[1]);
      if (
        ccYear > currentYear ||
        (ccYear === currentYear && ccMonth >= currentMonth)
      ) {
        if (
          (ccYear === currentYear &&
            (ccMonth - 1 === currentMonth || ccMonth === currentMonth)) ||
          (currentYear + 1 === ccYear && ccMonth === 1 && currentMonth === 12)
        ) {
          cardStatus = AccountStatus.ALMOST;
        }
      } else {
        cardStatus = AccountStatus.EXPIRED;
      }
    }
    return cardStatus;
  }
  mapPendingToHistory(item): PaymentHistory[] {
    let pending = [] as Array<PaymentHistory>;
    if(item.memberPayment?.paymentStatus === PaymentStatus.PENDING) {
      const payment: PaymentHistory = {
        AccountType: item.planName,
        BenefitPeriod: moment(item.billFromDate).format("MMM. D, YYYY") + ' - ' + moment(item.billToDate).format("MMM. D, YYYY"),
        MemberId: item.hcid ?? '',
        PaymentAmount: item.memberPayment.paymentAmount,
        PaymentDate: moment(item.memberPayment?.paymentDate).format('MM/DD/YYYY'),
        ConfirmationNumber: item.paymentTrackingNumber || '',
        PdfBillAvailable: item.pdfBillAvailable ?? null,
        Status: 'Pending',
        PaymentMode: item.pendingPayment?.accountType === PaymentMethodType.BANK_ACCOUNT ? 'Bank Account' : 'Credit/Debit Card'
      };
      pending.push(payment);
    }
    return pending;
  }

  mapBillToConfirm(bill, response, payment, content): PaymentConfirmation {
    const payConf: PaymentConfirmation = {
      accountType: bill.accountType,
      amount: bill.amount,
      memberId: bill.memberId,
      coverageStartDt: bill.coverageStartDt,
      coverageEndDt: bill.coverageEndDt,
      planId: bill.planId,
      paidOn: this.transformDueDate(new Date()),
      confirmationNumber: response?.body?.paymentConfirmation?.confirmation?.confirmationNumber ?? null,
      paymentType: payment.isBankAcc
        ? payment.bankAccountType+
        '-' +
        payment.bankAccountNumber.slice(-4)
        : payment.creditCardType +
        '-' +
        payment.creditCardNumber.slice(-4),
    };
    return payConf;
  }
  
  mapPaymentHistoryFromApi(response: PaymentHistoryResponse) {
    let paymentList = [] as Array<PaymentHistory>;
    const periodFormat = 'MMM. D, YYYY';
    if (response.completedPayments?.length) {
      response.completedPayments.forEach((element: PastPayment) => {
        let payment = {} as PaymentHistory;
        payment.AccountType = element.planName;
        payment.BenefitPeriod =
          moment(element.billFromDate).format(periodFormat) +
          ' - ' +
          moment(element.billToDate).format(periodFormat);
        payment.MemberId = element.hcid || '';
        payment.PaymentAmount = parseInt(element.amountPaid);
        payment.PaymentDate = element.datePaid;        
        payment.PaymentMode = element.paymentMode;
        payment.Status = 'Paid';
        if (element.pdfBillAvailable)
          payment.PdfBillAvailable = element.pdfBillAvailable;
        paymentList.push(payment);
      });
    }
    return paymentList;
  }

  encryptCreditCard(creditCardNumber) {
    const encryptfilesLoaded = this.encryptionfilesLoaded();
    let creditCardEncrypted = false;
    let creditCardValid = false;
    let cryptCard = '';
    let integrityCheckVal = '';

    if (encryptfilesLoaded) {
      if (ValidatePANChecksum(creditCardNumber)) {
        creditCardValid = true;
      }
      const response = ProtectPANandCVV(creditCardNumber, '', true);
      if (response != null && response !== undefined) {
         cryptCard = response[0];
         if (response.length > 2) {
           integrityCheckVal = response[2];
           creditCardEncrypted = true;
        }
      }
    }

    const PIEValues = this.getPIEVAlues();

    const encryptedResult = {
      creditCardEncrypted,
      creditCardValid,
      integrityCheckVal: integrityCheckVal || '',
      keyId: PIEValues.keyID,
      phase: PIEValues.phase,
      cryptCard: cryptCard || ''
    };
    return encryptedResult;

  }

  createAccountDetails( selectedPaymentMethod, encryptedResult ): CreditCardAccountDetails {
    let cc: CreditCardAccountDetails = {
      cardType: selectedPaymentMethod.creditCardType as CreditCardPaymentType,
      expirationMonth: selectedPaymentMethod.expirationMonth,
      expirationYear: selectedPaymentMethod.expirationYear,
      accountNumber: selectedPaymentMethod.tokenId ? null : selectedPaymentMethod.cardNumberFull,
      routingNumber: null,
      companyName: null,
    };
    if (selectedPaymentMethod.tokenId === '' || selectedPaymentMethod.tokenId === undefined ) {
      const ecc: CreditCardAccountDetails = {
        accountNumber: encryptedResult.cryptCard ,
        integrityCheck: encryptedResult.integrityCheckVal,
        keyID: encryptedResult.keyId || '',
        phaseID: encryptedResult.phase
      };
      cc = { ...cc, ...ecc };
    }
    return cc;
  }

  encryptionfilesLoaded() {
    if ((typeof PIE === 'undefined') || (typeof PIE.K === 'undefined') ||
     (typeof PIE.L === 'undefined') || (typeof PIE.E === 'undefined') || (typeof PIE.key_id === 'undefined') ||
      (typeof PIE.phase === 'undefined')) {
      return false;
    }
    return true;
  }
  getPIEVAlues() {
    let pieData: PIEData = {
      keyID: '',
      phase: ''
    };
    if (typeof (PIE) !== 'undefined') {
      pieData.keyID = PIE.key_id;
      pieData.phase = PIE.phase;
    }
    return pieData;
  }
}

