import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AccountBalanceService } from './services/account-balance.service';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { HeaderNav } from './../pharmacy/interfaces/HeaderNav';
import { PrescriptionsService } from 'sydmed/src/app/secure/pharmacy/services/prescriptions.service';
import { DatePipe } from '@angular/common';
import {   Prescription, PrescriptionsResponse } from 'gbd-models';
import { MemberPrescription } from '../pharmacy/interfaces/MemberPrescription';
import { of, Subscription } from 'rxjs';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { HeaderAlertsService } from './services/header-alerts.service';
import { AcknowledgementService } from 'sydmed/src/app/secure/pharmacy/services/acknowledgement.service';
import { Acknowledgement } from 'sydmed/src/app/secure/pharmacy/interfaces/Acknowledgement';
import { Router } from '@angular/router';
import { PharmacyRoutes } from '../pharmacy/PharmacyRoutes.enum';
import { MedicalProfileService } from 'sydmed/src/app/secure/pharmacy/services/medical-profile.service';
import { PharmacyPriorAuthorizationService } from 'sydmed/src/app/secure/prior-authorization/pharmacy-prior-authorization.service';
import { RX_STATUSES } from 'sydmed/src/app/secure/prior-authorization/models/labels';
import { PrescriptionOrderRouteService } from '../pharmacy/services/prescription-order-route.service';
import { ProgressBarService } from '../pharmacy-prescription-order/progress-bar/progress-bar.service';
import { catchError, map, mergeMap, retry } from 'rxjs/operators';
import { CartService } from 'sydmed/src/app/secure/pharmacy/services/cart.service';
import { DashboardContent, HeaderAlerts, Errors, Footer, HeaderTile, PayAccountBalance, PrescriptionTile, ChoosePharmacy, ZipDrugBanner } from './interfaces/PharmacyDashboardContent';
import { FluffyButtons, PrescriptionsTableLabels, Summary } from 'sydmed/src/app/secure/pharmacy/interfaces/PrescriptionOrderContent';
import { DashboardDataService } from './services/dashboard-data.service';
import {AlertType} from 'sydmed/src/app/secure/pharmacy/dismissible-alert/AlertType';
import {AlertName} from './AlertNames';
import { Restriction } from 'gbd-models';
import { TileId } from './footer-tile/tileId';
@Component( {
  selector: 'app-pharmacy-dashboard',
  templateUrl: './pharmacy-dashboard.component.html',
  styleUrls: [ './pharmacy-dashboard.component.scss' ],
  providers: [ DashboardDataService ]
} )
export class PharmacyDashboardComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();
  alertType = AlertType;
  alertName = AlertName;
  content: DashboardContent;
  acknowledgment: Acknowledgement;
  alerts: HeaderAlerts;
  errors: Errors;
  footerContent: Footer;
  hasAllPrescriptions = true;
  hasPbm = true;
  headerBlockLeft: HeaderTile;
  headerBlockMiddle: HeaderTile;
  headerBlockRight: HeaderTile;
  headerNav: HeaderNav;
  listLabels: PrescriptionsTableLabels;
  listHeaderContent: string;
  listSummaryContent: Summary;
  zeroPrescriptionsContent: string;
  miniZeroPrescriptionContent: string;
  viewAllPrescriptionContent: string;
  miniListHeaderContent: string;
  miniListSummaryContent: Summary;
  buttonLabel: string;
  buttons: FluffyButtons;
  pabContent: PayAccountBalance;
  ptContent: PrescriptionTile;
  pharmacySearch: ChoosePharmacy;
  serviceError: string;
  startDateParam: string;
  endDateParam: string;
  prescriptions: Prescription[];
  loadingSpinner = false;
  isError = false;
  isPharmacyLanding = true;
  showCommPrefAlert = false;
  showAdvancedHomeDeliveryAlert = false;
  showMedProfileAlert = false;
  showEmailAlert = false;
  showCaliforniaPharmacyAlert = false;
  isCartDisplay = true;
  lineOfBusiness: string;
  profileIsComplete: boolean;
  pbmBalance: string;
  specialtyBalance: string;
  balanceError: boolean;
  pabEnabled = true;
  analytics: any;
  includeMarket = false;
  hasviewAllPrescriptionLinkContent: string;
  hasProfileRestriction = false;
  restrictions: string[];
  zipDrugBanner: ZipDrugBanner;
  showZipDrugBanner = false;
  isZipDrugEligible = false;
  hasRxPreAuthAlerts: boolean = false;
  rxPreAuthList: any[];
  readonly rxStatuses = RX_STATUSES;

  @Input() 
  isMini: boolean = false;
  hasPharmacy: boolean;
  hasPartialPharmacy: boolean;
  hasAccountDetails: boolean = true;
  hasCommPreferences: boolean = true;

  constructor(
    protected accountBalanceService: AccountBalanceService,
    protected dashboardData: DashboardDataService,
    protected titleService: InterCommunicationService,
    protected contentSvc: JsonContentService,
    protected alertSvc: HeaderAlertsService,
    protected prescriptionService: PrescriptionsService,
    protected datePipe: DatePipe,
    protected acknowledgementSvc: AcknowledgementService,
    protected router: Router,
    protected medProfileSvc: MedicalProfileService,
    protected routeSvc: PrescriptionOrderRouteService,
    protected progressSvc: ProgressBarService,
    protected rxPriorAuthSvc: PharmacyPriorAuthorizationService,
    protected cart: CartService,
  ) { }

  ngOnInit(): void {
    const dataSub = this.setContent().pipe(
      mergeMap(() => this.getData()))
        .subscribe( ( res: PrescriptionsResponse ) => {
          this.getMemberPrescription(res);
        }, error => {
            this.isError = true;
            this.loadingSpinner = false;
    } );
    this.sub.add( dataSub );
    this.sub.add( this.getCartLob() );
    this.routeSvc.setPreviousUrl( `/${ PharmacyRoutes.Dashboard }` );
  }
  checkCommPreferences() {
    return this.alertSvc.getCommPreferencesExist().pipe( map( exists => {
      return this.showCommPrefAlert = exists ? false : true;
    } ), catchError( err => {
      this.showCommPrefAlert = true;
      return of( this.showCommPrefAlert );
    } ) );
  }
  
  getRxPriorAuth() {
    if (this.hasRxPreAuthAlerts) {
      const rxStartEndDate = this.getStartDateForPreAuth(60);
      const lastTwoWeek = new Date();
      lastTwoWeek.setDate(lastTwoWeek.getDate() - 15);
      return this.rxPriorAuthSvc.getRxPriorAuth(rxStartEndDate.fromDate, rxStartEndDate.currentDate).pipe( map( res => {
        this.rxPreAuthList = res.body.priorAuth.filter(preAuth => new Date(preAuth.updatedDate) > lastTwoWeek);
      }), catchError( err => {
        return of(err);
      }));
    } else {
      return of([]);
    }    
  }

  checkEmailAddress() {
    return this.alertSvc.getEmailAddressExists().pipe( map( exists => {
      return this.showEmailAlert = exists ? false : true;
    } ), catchError( err => {
      this.showEmailAlert = true;
      return of( this.showEmailAlert );
    } ) );
  }

  closeAlert(alertName) {
    switch (alertName) {
      case AlertName.AdvancedHomeDelivery:
        this.showAdvancedHomeDeliveryAlert = false;
        break;
      case AlertName.CaliforniaPharmacyContact:
        this.showCaliforniaPharmacyAlert = false;
        break;
      case AlertName.CommPreferences:
        this.showCommPrefAlert = false;
        break;
      case AlertName.Email:
        this.showEmailAlert = false;
        break;
      case AlertName.MedicalProfile:
        this.showMedProfileAlert = false;
        break;
      default:
        break;
    }
  }

  getData() {
    this.loadingSpinner = true;
    if(this.isMini) {
      return this.getPrescriptions().pipe(
        retry( 1 )
      );
    } else if(this.hasPartialPharmacy) {
        return of({prescriptions: []} as PrescriptionsResponse);
    } else if(!this.hasPbm) {
      return of(true).pipe(
        mergeMap( () => this.getAccountBalance() ),
        mergeMap( () => this.getRxPriorAuth() ),
        mergeMap( () => this.getPrescriptions() ),
        retry( 1 )
      );
    } else {
      return of(true).pipe(
        mergeMap( () => this.checkMedicalProfile() ),
        mergeMap( () => this.checkCommPreferences() ),
        mergeMap( () => this.checkEmailAddress() ),
        mergeMap( () => this.getAccountBalance() ),
        mergeMap( () => this.getRxPriorAuth() ),
        mergeMap( () => this.getPrescriptions() ),
        retry( 1 )
      );
    }
  }

  getMemberPrescription(res) {
    this.prescriptions = res.prescriptions;
    this.prescriptions = this.prescriptions.filter(
      ( prescription: Prescription ) => this.dashboardData.checkEligibility( prescription ) );
    this.prescriptionService.setPrescriptions( res );
    this.loadingSpinner = false;
  }

  getAccountBalance() {
    if (this.pabEnabled) {
      return this.accountBalanceService.getAccountBalance().pipe(
        map(response => {
          this.pbmBalance = response.body.pbmAccountInfo ? response.body.pbmAccountInfo.totalAccountBalance : '0';
          this.pbmBalance = this.pbmBalance < '0' ? '0' : this.pbmBalance;
          this.specialtyBalance = response.body.specialtyAccountInfo ? response.body.specialtyAccountInfo.totalAccountBalance : '0';
          this.specialtyBalance = this.specialtyBalance < '0' ? '0' : this.specialtyBalance;
          return true;
        }), catchError(error => {
          this.balanceError = true;
          return of(false);
        }));
    } else {
      return of(false);
    }
  }

  getCartLob() {
    return this.cart.lineOfBusiness.subscribe( lob => {
      this.lineOfBusiness = lob;
      this.routeSvc.setInitialCartRoute( this.lineOfBusiness, this.profileIsComplete );
      this.progressSvc.resetProgressSteps();
    } );
  }

  checkMedicalProfile() {
    return this.medProfileSvc.getMedicalProfileExists().pipe(
      map( ( response ) => {
        this.profileIsComplete = response;
        this.showMedProfileAlert = !this.profileIsComplete;
        return this.showMedProfileAlert;
      } ),
      catchError( error => {
        this.showMedProfileAlert = true;
        return of( this.showMedProfileAlert );
      } )
    );
  }

  getPrescriptions() {
    this.endDateParam = this.datePipe.transform( new Date(), 'yyyy-MM-dd' );
    this.startDateParam = this.datePipe.transform( new Date().setMonth( new Date().getMonth() - 24 ), 'yyyy-MM-dd' );
    this.progressSvc.clearProgressBar();
    return this.fetchPrescriptions( this.startDateParam, this.endDateParam, true );
  }
  fetchPrescriptions( startDate: string, endDate: string, isActionable: boolean ) {
    return this.prescriptionService.fetchPrescriptions( startDate, endDate, isActionable );
  }
  setContent() {
    return this.contentSvc.getJSON( 'pharmacy-dashboard' ).pipe( map(data => {
      this.pabEnabled = !this.contentSvc.hasRestriction(Restriction.SHM_PAY_ACCOUNT_BALANCE, data.restrictions);
      this.hasPbm = !this.contentSvc.hasRestriction(Restriction.SHM_PHARMACY_CVS_PBM, data.restrictions);
      this.hasPharmacy = !this.contentSvc.hasRestriction(Restriction.SHM_NO_PHARMACY, data.restrictions);
      this.hasPartialPharmacy = this.contentSvc.hasRestriction(Restriction.SHM_PARTIAL_PHARMACY, data.restrictions);
      this.hasProfileRestriction = this.contentSvc.hasRestriction(Restriction.SHM_PROFILE_MENU, data.restrictions);
      this.hasAllPrescriptions = !this.contentSvc.hasRestriction(Restriction.SHM_ALL_PRESCRIPTIONS, data.restrictions);
      this.hasRxPreAuthAlerts = !this.contentSvc.hasRestriction(Restriction.SHM_NO_PRIOR_AUTHORIZATION, data.restrictions)
                                    && !this.contentSvc.hasRestriction(Restriction.SHM_PRIOR_AUTH_PHARMACY, data.restrictions);
      if (this.hasProfileRestriction) {
        this.hasAccountDetails = false;
        this.hasCommPreferences = false;
      } else {
        this.hasAccountDetails = !this.contentSvc.hasRestriction(Restriction.SHM_PROFILE_DETAILS, data.restrictions);
        this.hasCommPreferences = !this.contentSvc.hasRestriction(Restriction.SHM_COMM_PREFERENCES, data.restrictions);
      }
      this.showZipDrugBanner = !this.contentSvc.hasRestriction(Restriction.SHM_ZIP_DRUG, data.restrictions) && this.isZipDrugEligible;
      this.content = data.PharmacyDashboard;
      const title = this.hasPartialPharmacy ? this.content.PartialPharmacyTitle : this.content.ManagePrescriptionsTitle;
      this.titleService.raiseEvent( {
        title: 'HEADER_TITLE',
        message: title
      } );
      this.showAdvancedHomeDeliveryAlert = !this.hasPbm && !this.contentSvc.hasRestriction(Restriction.SHM_PHARMACY_AHD_PBM, data.restrictions);
      this.alerts = this.content.Alerts;
      if (this.alerts.CaliforniaPharmacyContact) {
        this.showCaliforniaPharmacyAlert = true;
      }
      this.errors = this.content.Errors;
      this.headerBlockLeft = this.content.HeaderTiles.HeaderBlockLeft;
      this.headerBlockMiddle = this.content.HeaderTiles.HeaderBlockMiddle;
      this.headerBlockRight = this.content.HeaderTiles.HeaderBlockRight;
      this.footerContent = this.content.Footer;
      this.hideFooterTileLink(TileId.NEED_HELP, Restriction.SHM_NO_MESSAGE_CENTER, data.restrictions);
      this.hideFooterTileLink(TileId.FILL_SPECIALTY, Restriction.SHM_NO_MESSAGE_CENTER, data.restrictions);
      this.listLabels = this.content.PrescriptionsList.Labels;
      this.analytics = this.content.PrescriptionsList.Analytics;
      this.buttons = this.content.PrescriptionsList.Buttons;
      this.buttonLabel = this.content.PrescriptionsList.Buttons.Refill;
      this.listHeaderContent = this.content.PrescriptionsList.ListHeader.Heading;
      this.listSummaryContent = this.content.PrescriptionsList.ListHeader.Summary;
      this.zeroPrescriptionsContent = this.content.PrescriptionsList.ListHeader.Summary.Zero;
      this.miniListHeaderContent = this.content.PrescriptionsList.ListHeader.MiniHeading;
      this.miniListSummaryContent = this.content.PrescriptionsList.ListHeader.MiniSummary;
      this.miniZeroPrescriptionContent = this.content.PrescriptionsList.ListHeader.MiniSummary.Zero;
      this.viewAllPrescriptionContent = this.content.PrescriptionsList.ListHeader.viewAllPrescriptionLink;
      this.hasviewAllPrescriptionLinkContent = this.content.PrescriptionsList.ListHeader.hasviewAllPrescriptionLink;
      this.pabContent = this.content.PayAccountBalance;
      this.ptContent = this.content.PrescriptionTile;
      this.serviceError = this.content.Errors.GeneralSystemErrorWithoutCode;
      this.pharmacySearch = this.content.ChoosePharmacy;
      this.zipDrugBanner = this.content.ZipDrugBanner;
      this.restrictions = data.restrictions;
      this.setHeaderNav();
    }) );
  }
  setHeaderNav() {
    this.headerNav = {
      isbackToLink: false,
      backToLinkLabel: this.content.HeaderNav.ManagePrescriptions,
      backToRouterLink: '/' + PharmacyRoutes.Dashboard,
      cartLabel: this.content.HeaderNav.Cart,
      isPrescriptionsLink: this.hasAllPrescriptions,
      prescriptionLabel: this.content.HeaderNav.AllPrescriptions,
      prescriptionRouter: '/' + PharmacyRoutes.AllPrescriptions,
      isOrderStatusLink: true,
      orderStatusLabel: this.content.HeaderNav.OrderStatus,
      orderStatusRouter: '/' + PharmacyRoutes.Orders,
      findPharmacyLabel: this.content.HeaderNav.FindAPharmacy,
      findPharmacyRouter: '/' + PharmacyRoutes.FindPharmacy,
    };
  }
  ManagePrescriptions() {
    this.router.navigate( [ '/secure/pharmacy/dashboard' ] );
  }

  ViewOrderStatus() {
    this.router.navigate( [ '/secure/pharmacy/prescriptions/orders' ] );
  }

  getStartDateForPreAuth(days: number) {    
    const currentDate = new Date();
    let startDate = new Date();
    let currentDateStr = '';
    let startDateStr = '';

    startDate.setDate(startDate.getDate() - days);    
    currentDateStr = currentDate.toISOString().substring(0, 10);    
    startDateStr = startDate.toISOString().substring(0, 10);
    
    return {
      'currentDate': currentDateStr,
      'fromDate': startDateStr
    };
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  hideFooterTileLink(tileId: TileId, restriction: Restriction, restrictions: string[]) {
    const hide = this.contentSvc.hasRestriction(restriction, restrictions);
    for (const tile in this.footerContent.Tiles) {
      if (this.footerContent.Tiles[tile].TileId === tileId) {
        this.footerContent.Tiles[tile].hideLink = hide;
      }
    }
  }
}
