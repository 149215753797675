<div class="text-row" *ngIf="decisionLetterError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
    <span [innerHtml]="decisionLetterErrorMsg"></span>
</div>
<div class="text-row">
    <p>{{ labels.pharmacyLearnMoreDesc }}<a [routerLink]="labels.pharmacyLearnMoreLinkUrl">{{ labels.pharmacyLearnMoreLinkText }}</a></p>
</div>
<div class="priorAuthFilter">
    <form #filterForm="ngForm" name="filterForm">
        <div class="flex-prop">
        <div #filterDateRange="ngModel" data-uxd-dropdown-cmp [(ngModel)]="filterModelValue" class="ant-md-select form-item" [labelText]="filterDropDown.title"
        name="filterDateRange" id="filterDateRange" [items]="filterDropDown.options" [isRequired]="true" [describedBy]="'forSelectItem'"
        required>
        </div>

        <div class="filter-button-group">
            <div class="filter-buttons button-group text-right">
                <span>
                   <button class="btn btn-primary" [attr.data-analytics] = "analytics.applyButtonAuth" (click)="applyFilter()">{{ labels.pharmacyApply }}</button>
                </span>
            </div>
        </div>
    </div>
    </form>
</div>
    <div *ngIf="summaryDataLoaded; else summaryLoadingOrError">
        <uxdAccordion class="accordion-wrapper uxd-expansion-table zebra-striping" [isAccordion]="false">
            <div class="uxd-expansion-table" role="table">
                <div role="rowgroup">
                    <div class="accordion-header-grid-container" role="row">
                        <div class="request-date pharmacy-table-label">
                            <h3 role="columnheader" aria-sort="none">{{ labels.RequestDateTableHeader }}</h3>
                        </div>
                        <div class="prescription pharmacy-table-label">
                            <h3 role="columnheader" aria-sort="none">{{ labels.pharmacyPrescriptionHeader }}</h3>
                        </div>
                        <div class="status pharmacy-table-label">
                            <h3 role="columnheader" aria-sort="none">{{ labels.Status }}</h3>
                        </div>
                        <div class="reason pharmacy-table-label">
                            <h3 role="columnheader" aria-sort="none">{{ labels.pharmacyReasonHeader }}</h3>
                        </div>
                        <div class="faqs pharmacy-table-label">
                            <h3 role="columnheader" aria-sort="none"></h3>
                        </div>
                    </div>
                </div>
    
                <!-- Prior Authorization Summary Data -->
                <div role="rowgroup">
                    <uxd-expansion-panel *ngFor="let row of displayList; index as i" #panel="uxdExpansionPanel">
                        <uxd-expansion-header>
                            <div class="container clear-padding">
                                <div class="accordion-header-grid-container" role="row">      
                                    <div class="request-date" role="cell">
                                        <h4 class="mobile-label" role="columnheader">{{labels.RequestDateTableHeader}}</h4>
                                        <p>{{ row.requestDate | date: 'MMM. d, y' }}</p>
                                    </div>
                                    <div class="prescription" role="cell">
                                        <h4 class="mobile-label" role="columnheader">{{labels.pharmacyPrescriptionHeader}}</h4>
                                        <p>{{ row.prescriptionName }}</p>
                                    </div>
                                    <div class="status" role="cell">
                                        <h4 class="mobile-label" role="columnheader">{{labels.Status}}</h4>
                                        <p>{{ rxStatuses[row.status] }}</p>
                                    </div>
                                    <div class="reason" role="cell">
                                        <h4 class="mobile-label" role="columnheader">{{labels.pharmacyReasonHeader}}</h4>
                                        <p>{{ row.reason }}</p>
                                        <p *ngIf="row.decisionLetterId">
                                            <span class="motif-icon motif-pdf"></span> 
                                            <a (click)="viewDecisionLetter(row.decisionLetterId, row.authId)" [attr.data-analytics] = "analytics.downloadPdfAuth">{{labels.pharmacyViewLetter}}</a>
                                        </p>
                                    </div>
                                    <div class="faqs" role="cell">
                                        <p><a [routerLink]="labels.pharmacyLearnMoreLinkUrl" [attr.data-analytics] = "analytics.viewPrescriptionsAuth">{{labels.pharmacyViewFaqs}}</a></p>
                                    </div>
                                </div>
                            </div>
                        </uxd-expansion-header>                      
                    </uxd-expansion-panel>
                </div>
            </div>
        </uxdAccordion>
    </div>
    <div class="container lrb-pad">
        <div class="button-group load-more">
            <button [attr.aria-label]="labels.pharmacyLoadMore"
            *ngIf="prescriptionList && ((displayList && displayList.length > 0 && prescriptionList.length > displaySize) && (currentListIndex < prescriptionList.length))" 
            class="btn btn-primary motif-margin-top-0" [attr.data-analytics] = "analytics.loadMoreAuthText" type="button" (click)="loadMore()">{{labels.pharmacyLoadMore}}</button>
        </div>
    </div>

<!-- Prior Authorization Summary Loading Graphic and Error Message -->
<ng-template #summaryLoadingOrError>
    <div class="container lrb-pad" *ngIf="summaryError; else summaryLoading" data-uxd-alert-cmp-v2 [alert]="{alertType: alertType}">
        <div>{{ summaryErrorMsg }}</div>
    </div>
    <ng-template #summaryLoading>
        <uxd-spinner class="col-xxs-12 col-xs-12"></uxd-spinner>
        <div [innerHTML]="labels.loading"></div>
    </ng-template>
</ng-template>
<ng-container *ngIf="downloadFileLoader">
    <div class="ant-ui-widget-overlay">
    </div>
    <div class="ant-ajax-load ajax-request-loader">
      <uxd-spinner></uxd-spinner>
      <p [innerHTML]="labels.loading"></p>
    </div>
</ng-container>