import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DropDown, PayNowLabels } from "../models/labels";
import { PaymentMethod } from '../../interfaces/PaymentMethod';
import { AddressSelection } from '../../interfaces/PayNow';
import { PaymentMethodDataService } from 'sydmed/src/app/secure/pharmacy/services/payment-method-data.service';
import { AlertService } from "@anthem/uxd/alert";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";
import { HelperProperties } from "../models/helper.properties";
import { CheckBox } from '../models/labels';
import { PaymentHelperService } from "../../services/payment-helper.service";
import { PaymentPortalService } from "../../services/payment-portal.service";
import {
  CheckBoxNames,
  DEFAULT_NUMBER_OF_MONTHS,
  DEFAULT_NUMBER_OF_YEARS
} from "../models/enum-values";
import { Address, AddressType, CreditCardPaymentType, PaymentType, UpdatePaymentAction, UpdatePaymentMethodRequest } from 'gbd-models';
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";

@Component({
  selector: "app-add-credit-paynow",
  templateUrl: "./add-credit-paynow.component.html",
  styleUrls: ["./add-credit-paynow.component.scss"]

})
export class AddCreditPayNowComponent implements OnInit {
  addCardForm: FormGroup;
  addAddressForm: FormGroup;
  month: string = "";
  year: string = "";
  accountName: string;
  isChecked: boolean = false;
  helperProperties: HelperProperties;
  loader: boolean = false;
  errMsg: string = null;
  dateNotInFuture: boolean = false;

  selectMonth: DropDown = {
    title: "",
    options: []
  };

  selectYear: DropDown = {
    title: "",
    options: []
  };

  selectState: DropDown = {
    title: "",
    options: []
  };
  checkbox: CheckBox;
  isEdit: boolean = false;

  /**
   * Required inputs to be passed
   */
  // provide this parameter with false if buttons are not needed.
  @Input("showButtons") showButtons: boolean = true;
  @Input() autopay: boolean;
  labels: PayNowLabels;;
  @Input("errObj") errObj: any;
  @Input("paymentMethodId") paymentMethodId;
  @Input("selectedAddressId") selectedAddressId;
  @Input("addressPaymentRadioGroup") addressPaymentRadioGroup;
  @Input("creditPaymentRadioGroup") creditPaymentRadioGroup;
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    protected paymentDataSvc: PaymentMethodDataService,
    private _helper: PaymentHelperService,
    private _service: PaymentPortalService,
    private alertSvc: AlertService,
    private errorCodeMapperSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService

  ) {
    this.helperProperties = new HelperProperties(alertSvc);
    this.addCardForm = this.formBuilder.group({
      cardNumber: ["", [Validators.required, Validators.minLength(13), Validators.maxLength(16)]],
      accountHolderName: ["", [Validators.required]],
      month: ["", [Validators.required]],
      year: ["", [Validators.required]],
      paymentNickName: ["", [Validators.nullValidator]],
      futureUse: [this.isChecked]
    });
    this.addAddressForm = this.formBuilder.group({
      addressLine1: ["", [Validators.required]],
      addressLine2: ["", Validators.nullValidator],
      zipCode: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
    });
    this.getLabels();
  }

  getLabels() {
    this.jsonSvc.getJSON("pay-now").subscribe((data) => {
        this.errObj = data.ErrMsgs;
        this.labels = data.AddCardLabels;
        this.setCheckboxParams();
        this.setStateOptions();
    });
  }


  ngOnInit(): void {
    this.helperProperties.scrollToTop();
    this.setMonthOptions();
    this.setYearOptions();
    this.setCheckboxParams();
    if (this.addressPaymentRadioGroup && this.addressPaymentRadioGroup.options.length > 1) {
      this.selectedAddressId = this.addressPaymentRadioGroup.options[0].value;
    }

    this.cardNumber.valueChanges.subscribe(res => {
      if (res) {
        let val = res.trim();
        this.cardNumber.setValue(val, { emitEvent: false });
      }
    });
  }

  ngOnChanges(changes) {
    this.setDefaultValuesForInputs(changes);
    if (this.autopay) {
      this.form_futureUse.setValue(true);
    }
  }

  setDefaultValuesForInputs(changes) {
    if (!changes.hasOwnProperty("showCheckBox")) {
      this.form_futureUse.setValue(false);
    }
    this.form_futureUse.updateValueAndValidity();
  }

  setMonthOptions() {
    for (let i = 1; i <= DEFAULT_NUMBER_OF_MONTHS; i++) {
      if (i < 10) {
        this.selectMonth.options.push({ label: "0" + i, value: "0" + i });
      } else {
        this.selectMonth.options.push({ label: i.toString(), value: i.toString() });
      }
    }
  }

  setStateOptions() {
    this.selectState.options = this.labels?.StateValues;
  }

  setYearOptions() {
    const year = +new Date().getFullYear();
    for (let i = 0; i <= DEFAULT_NUMBER_OF_YEARS; i++) {
      this.selectYear.options.push({
        label: (year + i).toString(),
        value: (year + i).toString()
      });
    }
  }

  setCheckboxParams() {
    this.checkbox = this.helperProperties.setCheckboxParams(
      CheckBoxNames.CARD_CHECKBOX,
      CheckBoxNames.CARD_CHECKBOX,
      this.labels?.Labels?.CheckBoxMessage
    );
  }

  setSelectedPaymentMethodAndClose(paymentInfo: PaymentMethod, isAdded: boolean, error: any) {
    this._helper.setSelectedPaymentMethod(paymentInfo, isAdded, error);
    this.helperProperties.scrollToTop();
    this.onCancel();
  }

  getCardType(number) {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
      return CreditCardPaymentType.VISA;

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
      return CreditCardPaymentType.MASTERCARD;

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
      return CreditCardPaymentType.AMERICAN_EXPRESS;

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
      return CreditCardPaymentType.DISCOVER;

  }

  addCard() {
    if (this.addCardForm.invalid && this.selectedAddressId != AddressSelection.ADD_NEW_ADDRESS) { this.addCardForm.markAllAsTouched(); return; }

    if (this.addAddressForm.invalid && this.selectedAddressId === AddressSelection.ADD_NEW_ADDRESS) { this.markFormTouched(); return; }
      
    if (!this.form_futureUse.value) {
      // add new credit Card locally
      if (this.addCardForm.valid && this.formMonth.value && this.formYear.value && !this.dateNotInFuture) {
        let cardNum = this.cardNumber.value.slice(this.cardNumber.value.length - 4);
        let creditCard: PaymentMethod = {
          accountName: this.formAccountHolderName.value,
          creditCardNumber: cardNum,
          creditCardType: this.getCardType(this.cardNumber.value),
          expirationDate: this.formMonth.value + "/" + this.formYear.value,
          isBankAcc: false,
          expirationMonth: this.formMonth.value,
          expirationYear: this.formYear.value,
          cardNumberFull: this.cardNumber.value,
          routingNumber: null,
          accountHolderInfo: {
            billingAddress: this.getAddress(),
            isDefaultMethod: false,
            nameOnAccount: this.formAccountHolderName.value,
            nickname: this.paymentNickName.value
          }
        };
        this.setSelectedPaymentMethodAndClose(creditCard, false, null);
      }
    } else {
      if (this.addCardForm.valid) {
        this.loader = true;
        let paymentMethod: UpdatePaymentMethodRequest;
          paymentMethod = {
            action: UpdatePaymentAction.CREATE,
            paymentMethod: {
              accountDetails: {
                cardType: this.getCardType(this.cardNumber.value),
                expirationMonth: this.formMonth.value,
                expirationYear: this.formYear.value,
                accountNumber: this.cardNumber.value,
                routingNumber: null,
                companyName: null
              },
              accountHolderInfo: {
                billingAddress: this.getAddress(),
                isDefaultMethod: false,
                nameOnAccount: this.formAccountHolderName.value,
                nickname: this.paymentNickName.value
              },
              accountType: PaymentType.CREDIT_CARD
            }
          };
        this._service.updatePaymentMethod(paymentMethod).subscribe((res: any) => {
          if (res?.paymentMethod) {
            let cardNum = this.cardNumber.value.slice(this.cardNumber.value.length - 4);
            let payment: PaymentMethod = {
              accountName: "",
              creditCardNumber: cardNum,
              cardNumberFull: this.cardNumber.value,
              creditCardType: this.getCardType(this.cardNumber.value),
              expirationDate: this.formMonth.value + "/" + this.formYear.value,
              expirationMonth: this.formMonth.value,
              expirationYear: this.formYear.value,
              isBankAcc: false,
              tokenId: res.paymentMethod.tokenId,
              accountHolderInfo: paymentMethod.paymentMethod.accountHolderInfo
            };
            payment.accountName = this.getAccountName(payment);
            this.setSelectedPaymentMethodAndClose(payment, true, null);
          }
          else {
            this.setSelectedPaymentMethodAndClose(null, true, res.message.messageText);
          }
          this.loader = false;
        }, (error: any) => {
          this.loader = false;
          this.setSelectedPaymentMethodAndClose(null, false, "error");
        });
      }
      else {
        this.markFormTouched();
      }
    }
  }

  getAccountName(payment: PaymentMethod) {
    const options = (this.creditPaymentRadioGroup.options).filter((element, index) => index < (this.creditPaymentRadioGroup.options).length - 1);
    if (options.length > 0) {
      const accountName = options[options.length - 1].payment.accountName;
      const index = parseInt((accountName.split('_')[1])) + 1;
      return payment.creditCardType + "-" + payment.creditCardNumber + "_" + index;
    }
    else {
      return payment.creditCardType + "-" + payment.creditCardNumber + "_0";
    }
  }

  markFormTouched() {
    this.addCardForm.markAllAsTouched();
    this.addAddressForm.markAllAsTouched();
  }

  /**
  * Check if expiry date is in future
  */
  checkValidExpiry() {
    if (this.formMonth.value && this.formYear.value) {
      let currentDate = new Date();
      let date = new Date(+this.formYear.value, +this.formMonth.value, 1, 0, 0, 0, 0);
      this.dateNotInFuture = date < currentDate;
    }
  }

  onCancel() {
    this.closePanel.emit(true);
  }

  getAddress() {
    if (this.selectedAddressId === AddressSelection.ADD_NEW_ADDRESS && this.addAddressForm.valid) {
      return this.populateAddress(this.formCity.value, this.formState.value, this.formAddressLine1.value, this.formZipCode.value, this.formAddressLine2.value)
    } else {
      let selectedAddress = this.addressPaymentRadioGroup.options.find(addressOption => addressOption.value === this.selectedAddressId).payment;
      return this.populateAddress(selectedAddress.city, selectedAddress.state, selectedAddress.streetAddress1, selectedAddress.zipCode, selectedAddress.streetAddress2);
    }
  }
  populateAddress(city: string, state: string, streetAddress1: string, zipCode: string, streetAddress2?: string): Address {
    return {
      addressType: AddressType.HOME,
      city,
      state,
      streetAddress1,
      streetAddress2: streetAddress2 ?? "",
      zipCode
    }
  }

  get cardNumber() { return this.addCardForm.get("cardNumber") }
  get formAccountHolderName() { return this.addCardForm.get("accountHolderName") }
  get formMonth() { return this.addCardForm.get("month") }
  get formYear() { return this.addCardForm.get("year") }
  get paymentNickName() { return this.addCardForm.get("paymentNickName") }
  get form_futureUse() { return this.addCardForm.get("futureUse"); }
  get formAddressLine1() { return this.addAddressForm.get('addressLine1') }
  get formAddressLine2() { return this.addAddressForm.get('addressLine2') }
  get formZipCode() { return this.addAddressForm.get('zipCode') }
  get formCity() { return this.addAddressForm.get('city') }
  get formState() { return this.addAddressForm.get('state') }
}
