import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as jsonData from './assets/data/dashboard.json';
import {DashboardDataService } from './services/dashboard-data.service';

@Injectable({
  providedIn: 'root'
})

export class JsonDataResolverService implements Resolve<any> {

  constructor(private dataSvc: DashboardDataService) { }

  private labels: object;
  private memberName: object;
  private errorMessages: object;
  private jsonResolve: any;

  //TODO - Pulling JSON directly from assets folder
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
         this.jsonResolve =  this.getJsonAsObservable(jsonData)
        .pipe( 
          map((res: any) => {
            this.labels = res.Dashboard.Labels;
            this.memberName = res.membername;
            this.errorMessages = res.Dashboard.ErrorMsgs;
            this.dataSvc.setLabels(this.labels);
            this.dataSvc.setErrorMessages(this.errorMessages);
            return res.body;
          }),
          catchError((err) => {
            return of(null);
          })
        );
    return this.jsonResolve;
  }

  getJsonAsObservable(json: any): Observable<any> {
        return of(json.default);
  }
}
