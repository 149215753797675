<div class="flex-container header-section pharmacy-header-nav">
  <div *ngIf="headerNav && loading" class="header-previous">
    <div *ngIf="headerNav?.isbackToLink; else noBackToLink">
      <ng-container *ngIf="previousUrl && headerNav?.showBack; else BackToDashboard">
        <a *ngIf="urlParam; else NoParam" class="ant-action-link" id="back-link"
          [routerLink]="[previousUrl, {loB: urlParam}]" 
          
          >          
          <span class="motif-icon motif-arrows-long-left"></span>
          <span class="rx-back-link-text"> {{ headerNav.backLabel }}</span>
        </a>
        <ng-template #NoParam>
          <a class="ant-action-link" id="back-link"
          [routerLink]="[previousUrl]">
          <span class="motif-icon motif-arrows-long-left"></span>
          <span class="rx-back-link-text"> {{ headerNav.backLabel }}</span>
        </a>
        </ng-template>
      </ng-container>

      <ng-template #BackToDashboard>
        <a class="ant-action-link" data-analytics = "backManageTopNavRx" routerLink="{{ headerNav?.backToRouterLink }}">
          <span class="motif-icon motif-arrows-long-left"></span>
          <span class="rx-back-link-text"> {{ headerNav?.backToLinkLabel }}</span>
        </a>
      </ng-template>
    </div>
    <ng-template #noBackToLink>
      <div class="col-lg-7"></div>
    </ng-template>
  </div>
  <div class="flex-container">
    <div class="header-link-three">
      <div  *ngIf="hasFindPharmacy; else blankDisplay">
        <a class="ant-action-link" tabindex="0" style="cursor: pointer;" routerLink="{{ headerNav?.findPharmacyRouter }}" data-analytics="findPharmacyTopNavRx">
          <span class="rx-back-link-text">{{ headerNav?.findPharmacyLabel }}</span>
        </a>
    </div>
        <ng-template #blankDisplay>
          <div class="col-lg-2"></div>
        </ng-template>
    </div>

    <div class="header-link-one">
      <div *ngIf="headerNav?.isPrescriptionsLink; else blankDisplay">
        <a class="ant-action-link" tabindex="0" style="cursor: pointer;" (click)="reload()"  data-analytics="allPrescriptionsTopNavRx">
          <span class="rx-back-link-text">{{ headerNav?.prescriptionLabel }}</span>
        </a>
      </div>
      <ng-template #blankDisplay>
        <div class="col-lg-2"></div>
      </ng-template>
    </div>
    <div class="header-link-two">
      <div *ngIf="headerNav?.isOrderStatusLink; else blankDisplay">
        <a class="ant-action-link" data-analytics = "orderStatusTopNavRx" routerLink="{{ headerNav?.orderStatusRouter }}">
          <span class="rx-back-link-text">{{ headerNav?.orderStatusLabel }}</span>
        </a>
      </div>
      <ng-template #blankDisplay>
        <div class="col-lg-2"></div>
      </ng-template>
    </div>
    <div class="header-cart">
      <div *ngIf="isCartDisplay">
        <app-cart-icon [cartCount]="cartCount" [cartRouterLink]="cartRoute" data-analytics = "cartIconTopNavRx" [cartLabel]="headerNav?.cartLabel"></app-cart-icon>
      </div>
    </div>
  </div>
</div>
<hr class="hide-print"/>