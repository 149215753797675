import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PowerAccountService } from "../services/power-account.service";
import { Subscription } from "rxjs";
import { PATransaction, FilterState } from '../interfaces/power-account';
import { PowerAccountModel } from '../models/power-account.model';
import { DateUtility } from "@anthem/mbrportal/utility/services/dateUtil";
import { PowerAccountContent } from '../interfaces/power-account-content';
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";
import { AlertService } from '@anthem/uxd/alert';

@Component({
  selector: 'app-power-account-activity',
  templateUrl: './power-account-activity.component.html',
  styleUrls: ['./power-account-activity.component.scss']
})
export class PowerAccountActivityComponent implements OnInit {

  public isLoading: boolean = true;
  public transactionsData: PATransaction[];
  public transactionsDataDisplay: PATransaction[];
  public error: string = '';
  public filterMsg: string = '';
  public noResultsMsg: string = '';
  private subscriptions: Subscription[];

  @Input('content') content: PowerAccountContent;
  @Output() showUrgentTxt: EventEmitter<any> = new EventEmitter<any>();

  widgetFilterState: FilterState = {
    date: 3,
    isCustomDate: false,
    customDateLeft: '',
    customDateRight: ''
  };

  constructor(
    private powerAccountSvc: PowerAccountService,
    private powerAccountModel: PowerAccountModel,
    private dateUtil: DateUtility,
    private errCodeMapSvc: ErrorCodeMapperService,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.subscriptions = [];
    this.getPowerAccountTransactions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.alert.warn(this.content.Urgent_NoTransactionsMsg, {
      regionName: 'transaction-urgent-err',
      politeness: 'off'
    });
  }

  getPowerAccountTransactions() : void {
    this.setInitialDate();

    this.subscriptions.push(
      this.powerAccountSvc.getPATransactions().subscribe((res:any) => {
        this.transactionsData = this.powerAccountModel.buildPowerAccountTransactions(res?.body?.transactions);
        this.onFilterState(this.widgetFilterState);
        this.isLoading = false;
        this.showUrgentTxt.emit(true);
      }, (err: any) => {
        this.isLoading = false;
        this.error = this.errCodeMapSvc.errorCodeMapper(err, this.content.ErrorMsgs);
        this.showUrgentTxt.emit(false);
      })
    );
  }

  onFilterState(newFilterState: FilterState) {
    this.transactionsDataDisplay = this.transactionsData.filter(
      m => new Date(m.date) >= new Date(newFilterState.customDateLeft) && new Date(m.date) <= new Date(newFilterState.customDateRight)
    );
    // setting filter message
    if(this.transactionsDataDisplay.length > 0) {
      if(newFilterState.isCustomDate) {
        this.filterMsg = this.content.PwrAcctActivity_lbls.PwrAcctActCustomFilterMsg_lbl
        .replace('{0}', (this.transactionsDataDisplay.length).toString())
        .replace('{1}', (this.dateUtil.formatDateMedium(newFilterState.customDateLeft)))
        .replace('{2}', (this.dateUtil.formatDateMedium(newFilterState.customDateRight)));
      } else {
        this.filterMsg = this.content.PwrAcctActivity_lbls.PwrAcctActFilterMsg_lbl
        .replace('{0}', (this.transactionsDataDisplay.length).toString())
        .replace('{1}', (newFilterState.date).toString());
      }
    } else {
      if(newFilterState.date === 24) {
        this.noResultsMsg = this.content.PwrAcctActivity_lbls.PwrAcctNoTransactions1_lbl;
      } else {
        this.noResultsMsg = this.content.PwrAcctActivity_lbls.PwrAcctNoTransactions2_lbl;
      }
    }
  }

  setInitialDate() {
    let startDate = new Date();
    let endDate = new Date();

    startDate.setMonth(
      startDate.getMonth() - 3
    );

    // commenting this check here for now
    // if(startDate.getFullYear() < 2021) {
    //   startDate = new Date('01-01-2021');
    // }
    
    this.widgetFilterState.customDateLeft = this.dateUtil.getDatePart(startDate, "yyyy-MM-dd");
    this.widgetFilterState.customDateRight = this.dateUtil.getDatePart(endDate, "yyyy-MM-dd");
  }
}
