import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer-tile',
  templateUrl: './footer-tile.component.html',
  styleUrls: ['./footer-tile.component.scss']
})
export class FooterTileComponent implements OnInit {
  @Input() tileId: string;
  @Input() headerText: string;
  @Input() headerIcon: string;
  @Input() body1: string;
  @Input() body2: string;
  @Input() linkText: string;
  @Input() link: string;
  @Input() externalLink: string;
  @Input() linkIcon: string;
  @Input() analyticsTag: string;
  @Input() ariaLabel: string;
  @Input() hideLink: boolean;

  constructor() { }

  ngOnInit(): void {  }

  openExternalLink()
  {
    window.open(this.externalLink);
  }
}
