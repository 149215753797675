import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageGeneratorService } from '@anthem/mbrportal/shared/services/pageGeneratorSvc';
import { PaymentHistory } from '../interfaces/PaymentHistory';
import { CancelPaymentRequest, Restriction} from 'gbd-models';
import { PaymentPortalService } from '../services/payment-portal.service';
import { PaymentHelperService } from '../services/payment-helper.service';
import { Modal, ModalRef } from '@anthem/uxd/modal';
import { AlertService } from '@anthem/uxd/alert';
import {JsonContentService} from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { HttpResponse } from '@angular/common/http';
import { OpenAttachment } from 'sydmed/src/app/sydmed-shared/open-attachment-helper/open-attachment-helper';
@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss']
})
export class PaymentDetailComponent implements OnInit {

  @Input() paymentDetails: any;
  @Input() content: any;
  @ViewChild('paymentDetailsBlock', { static: false })
  paymentDetailsBlock: ElementRef;
  currentBill: PaymentHistory;
  public showInvoice: boolean = true;
  public isLoading: boolean = false;
  private _removeAutopayModalRef: ModalRef<any, any>;
  public isMobile: boolean;
  @ViewChild('cancelPaymentAlert') modalTemplate: TemplateRef<any>;
  constructor(private pageGeneratorService: PageGeneratorService,
    private _service: PaymentPortalService, private _helper: PaymentHelperService,
    private _modal: Modal, private _alert: AlertService, private jsonSvc: JsonContentService, private openAttachment: OpenAttachment) { }

  ngOnInit(): void {
    this.isMobile = this.openAttachment.isMobileDevice();
    this.jsonSvc.getJSON('payment-portal').subscribe(data => {
      this.showInvoice = !this.jsonSvc.hasRestriction(Restriction.SHM_VIEW_BILL_PDF, data.restrictions) && !this.openAttachment.deviceType().includes('Android')
      });
  }

  viewInvoice(pdfBillAvailable: string): void {
    this.isLoading = true;
    this._service.getPdfUrl(pdfBillAvailable)
      .subscribe(
      (response:HttpResponse<ArrayBuffer>) => {
        this.isLoading = false;
        const url = this.openAttachment.generateUrl(response);
        if (this.isMobile) {
            const link = document.createElement('a');
            link.href = url;
            document.body.append(link);
            link.click();
            }
            else{
              window.open(url, "_blank", `noreferrer,noopener,height=${window.screen.height},width=${window.screen.width}`);
            }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  open() {
      this._removeAutopayModalRef = this._modal.open(this.modalTemplate);
  }    
  handleClick(currentBill: PaymentHistory) {
      this.currentBill = currentBill;
      this.open();
  }
  confirmRemove() {
      this.cancelPayment(this.currentBill);
      this.onClose();
  }   
  onClose() {
      this._removeAutopayModalRef.close();
      this._removeAutopayModalRef.onDismiss.unsubscribe();
      this._removeAutopayModalRef.onClose.unsubscribe();
  }
  cancelPayment(billInfo: PaymentHistory) { // TODO: this should be pulled from getHistory, fix it after the getHistory integration.
  const PAYMENT_CANCELLED = 'Cancelled';
  const PAYMENT_CANCELLATION_SUCCESS = 'Success';

    const reqObj: CancelPaymentRequest = {
      paymentConfirmationNo: billInfo.ConfirmationNumber
    };
    this._service.cancelPayment(reqObj).subscribe((response) => {
      if (response.body.paymentCancelStatus === PAYMENT_CANCELLATION_SUCCESS) {
        billInfo.Status = PAYMENT_CANCELLED;
        billInfo.ConfirmationNumber = null;
        this.showGlobalSuccess(this.content.CancelPaymentSuccess);
        this._helper.setReloadBills(true);
      }  
    }, err => {
      this.showGlobalError(this.content.CancelPaymentError);
    });
  }
  showGlobalSuccess(message) {
      this._alert.success(message, {
          regionName: 'payment-alert',
          alertListType: 'queue',
          politeness: 'polite',
          isDissmisable: true
      });
  }
  showGlobalError(message) {
      this._alert.error(message, {
          regionName: 'payment-alert',
          alertListType: 'queue',
          politeness: 'polite'
      });
  }
  printOrDownload(): void {
    let cssBlock = `
      <style type="text/css">
        html {
          font-size: 18px;
          font-family: 'Lato', sans-serif;
          color: #333333;
        }
        .no-print {
          display:none;
        }
        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
        }
        @font-face { 
          font-family: 'Montserrat'; 
          font-style: normal; 
          font-weight: 300; 
          src: local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v7/zhcz-_WihjSQC0oHJ9TCYL3hpw3pgy2gAi-Ip7WPMi0.woff) format('woff'); 
        }
        @media print {
          @page { size: portrait; }
          html {
            font-size: 18px;
            font-family: 'Lato', sans-serif;
            color: #333333;
          }
        }
        .payment-detail-grid-container, .heading-row, .details-row {
          display: flex;
          justify-content: space-between;
          width: 90%;
          max-width: 800px;
          font-size: 14px;
        }
        .heading-row {
          padding: 18px;
          border-bottom: solid 1px #cccccc;
          font-weight: bold;
        }
        .details-row {
          padding: 18px;
          border-bottom: solid 1px #cccccc;
        }
        .details-row:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 15px 0 15px;
          border-color: #ffffff transparent transparent transparent;
          position: absolute;
          top: 31.5%;
          left: 400px;
          margin-left: -50px;
          z-index: 10;
        }
        .detail-box {
          padding: 18px;
          background-color: #f7f7f7;
          border-bottom: solid 1px #cccccc;
          width: 90%;
          max-width: 800px;
        }
        h3 {
          font-size: 20px;
          margin: 23px 0px 33px 0px;
          font-family: 'Montserrat', sans-serif;
          font-weight: lighter;
        }
        h4 {
          margin: 0px 0px 12px 0px;
          padding: 0px;
          font-size: 14px;
          font-weight: bold;
        }
        p {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 14px;
        }
        .section-header-three {
          font-size: 16px;
          font-weight: 300;
        }
        .header-logo {
          width: auto;
          height: 87px;
          margin: 12px 0;
        }
        .logo-div {
          width: 95%;
          max-width: 836px;
          border-bottom: 2px solid #333333;
        }
        .paid {
          width: 15%;
        }
        .account {
          width: 55%;
        }
        .status {
          width: 15%;
        }
        .amount {
          width: 15%;
        }
      </style>
    `;
    const printHtml = this.paymentDetailsBlock.nativeElement;
    this.pageGeneratorService.init();
    this.pageGeneratorService.title = this.content.HeaderMessage;
    this.pageGeneratorService.isPrint = true;
    this.pageGeneratorService.cssAsString = cssBlock;    
    let logo = document.getElementsByClassName('header-logo')[0];
    this.pageGeneratorService.header = `<div class="logo-div"><img class="header-logo" src="${logo.getAttribute('src').substring(1)}" /></div>`;
    this.pageGeneratorService.body = printHtml.innerHTML;
    this.pageGeneratorService.bodyClass = 'container';
    this.pageGeneratorService.build();
    this.pageGeneratorService.generatePageInTab();
  }
}