<div class="row">
    <div class="col-sm-12 motif-margin-bottom-22">
        <div class="cards-wrapper">
            <div class="cards-container container">
                <div class="card large-card">
                    <div class="row motif-margin-top-21">
                        <div class="col-sm-12 motif-margin-bottom-23" *ngIf="!loading; else showLoading">
                            <span class="delete-success">
                                <img src="./assets/images/success.svg" alt="success">
                            </span>
                            <span class="deleted-account">{{ labels?.OptOutSuccess }}</span>
                            <span class="optOutSuccessMessage">
                                <span>{{ labels?.OptOutSuccessMessage }}</span>
                            </span>
                        </div>
                        <ng-template #showLoading>
                            <uxd-spinner class="delete-account-confirm-spinner" *ngIf="loading"></uxd-spinner>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

