<div class="mcv2-folders" aria-label="Message folders, select folder to display messages">
  <div class="mcv2-list">
    <a href="javascript:void(0)" class="mcv2-flex mcv2-list-item" aria-label="Inbox" id="MsgCntrInboxFldr_lbl" [ngClass]="{active: folder === 'inbox'}" (click)="folderInit('inbox')">
      <span class="motif-icon motif-message"></span>
      <span class="li-txt">{{ labels["MsgCntrInboxFldr_lbl"] }}</span>
      <span class="unreadNum" *ngIf="unreadCount > 0">{{ unreadCount }}</span>
    </a>
    <a *ngIf="isChatTranscriptsEnabled" href="javascript:void(0)" class="mcv2-flex mcv2-list-item" aria-label="Chat Transcripts" id="MessageCenterChatTranscripts" [ngClass]="{active: folder === 'chatTranscripts'}" (click)="folderInit('chatTranscripts')">
      <span class="motif-icon motif-speech-bubble"></span>
      <span class="li-txt">{{ labels["MessageCenterChatTranscripts"] }}</span>
      <span class="unreadNum" *ngIf="transcriptUnreadCount > 0">{{ transcriptUnreadCount }}</span>
    </a>
    <a href="javascript:void(0)" *ngIf="!isDesignee && !isTermed && !hideSentArchiveButton" class="mcv2-flex mcv2-list-item" aria-label="Sent" id="MsgCntrSentFldr_lbl" [ngClass]="{active: folder === 'sent'}" (click)="folderInit('sent')">
      <span class="motif-icon motif-redo"></span>
      <span class="li-txt">{{ labels["MsgCntrSentFldr_lbl"] }}</span>
    </a>
    <a href="javascript:void(0)" *ngIf="!isDesignee && !isTermed && !hideSentArchiveButton" class="mcv2-flex mcv2-list-item" aria-label="Archived" id="MsgCntrArchivedFldr_lbl" [ngClass]="{active: folder === 'archived'}" (click)="folderInit('archived')">
      <span class="motif-icon motif-archived"></span>
      <span class="li-txt">{{ labels["MsgCntrArchivedFldr_lbl"] }}</span>
    </a>
  </div>
</div>
