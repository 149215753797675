<div class="footer-tile-grid-container">
  <div class="icon">
    <div class="{{ headerIcon }}" aria-hidden="true"></div>
  </div>
  <div class="title">
    <div class="motif-pad-left-3">{{ this.headerText }}</div>
  </div>
  <div class="desc">
      <div class="motif-pad-left-3 motif-pad-bottom-5">{{ body1 }}</div>
      <div class="motif-pad-left-3 motif-pad-bottom-5">{{ body2 }}</div>
  </div>
  <div class="link" *ngIf="!hideLink">
    <div *ngIf="link">
      <a href="javascript:void(0)" class="ant-action-link" routerLink="{{ link }}" [attr.data-analytics]="analyticsTag">
        <span class="motif-pad-left-3 {{ linkIcon }} "></span><span class="ant-link-text">{{ linkText }}</span>
      </a>
    </div>
    <div *ngIf="externalLink">
      <a (click)="openExternalLink()" (keyup.enter)="openExternalLink()" class="ant-action-link" tabindex="0" [attr.aria-label]="ariaLabel" [attr.data-analytics]="analyticsTag">
        <span class="motif-pad-left-3 {{ linkIcon }}"></span><span class="ant-link-text">{{ linkText }}</span>
      </a>
    </div>
  </div>
</div>