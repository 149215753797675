import { Injectable, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CartService } from './cart.service';
import { PrescriptionsService } from './prescriptions.service';
import { Cart } from '../interfaces/Cart';
import { BehaviorSubject, Subscription } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { PrescriptionsResponse, NonActionablePrescription,  Prescription, AllPrescriptionsInfo, PharmacyPartialError} from 'gbd-models';
import { PharmacySessionService } from './pharmacy-session.service';


@Injectable( {
  providedIn: 'root'
} )
export class CartSessionService implements OnDestroy {
  protected readonly sessionCartKey = 'prescription-cart';
  protected cartInSession$: BehaviorSubject<string[]> = new BehaviorSubject( [] );
  cartInSession = this.cartInSession$.asObservable();

  protected sub: Subscription;
  constructor(
    protected cartService: CartService,
    protected datePipe: DatePipe,
    protected api: PrescriptionsService,
    protected pharmacySession: PharmacySessionService
  ) {
    this.sub = new Subscription();
    this.subscribeToClearCartSession();
  }

  public loadCartSession() {
    return this.populateCartFromSession( this.getCartInSession() ).pipe(
      mergeMap( () => this.getCartSize() )
    );
  }

  getCartSize() {
    return this.cartService.cart.pipe(
      mergeMap( ( cart: Cart ) => {
        if ( cart && cart.prescriptions.length > 0 ) {
          const uniqueRxIdList = this.getUniqueRxIdList( cart.prescriptions );
          this.storeCartInSession( uniqueRxIdList );
        }
        return this.cartService.cartSize;
      } )
    );
  }

  protected subscribeToClearCartSession() {
    const clearCartSub = this.cartService.clearCartSession.subscribe(
      clearCart => {
        if ( clearCart ) {
          this.removeCartInSession();
        }
      } );
    this.sub.add( clearCartSub );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected populateCartFromSession( uniqueRxIdList: string[] = [] ) {
    return this.fetchPrescriptions().pipe(
   
      map( ( response: PrescriptionsResponse ) => {
        return response.prescriptions.filter(
          ( rx: Prescription ) => this.isInSession( rx, uniqueRxIdList ) )
          .map( ( rx: Prescription ) => this.addToCart( rx ) );
      } )
      );
  }

  protected addToCart( rx: Prescription ) {
    if ( rx ) {
      this.cartService.addToCart( rx );
    }
    return rx;
  }

  protected isInSession = ( rx: Prescription, uniqueRxIdList: string[] = [] ) => {
    return uniqueRxIdList.indexOf( rx.uniqueRxId ) > -1;
  }
  protected getUniqueRxIdList( rxList: Prescription[] ) {
    return rxList.map( ( rx: Prescription ) => rx.uniqueRxId );
  }
  protected fetchPrescriptions() {
    const endDate = this.datePipe.transform( new Date(), 'yyyy-MM-dd' );
    const startDate = this.datePipe.transform( new Date().setMonth( new Date().getMonth() - 24 ), 'yyyy-MM-dd' );
    return this.api.fetchPrescriptions( startDate, endDate, true );
  }
  protected storeCartInSession( uniqueRxIdList: string[] ) {
    if ( this.pharmacySession.isValidSession() ) {
      sessionStorage.setItem( this.sessionCartKey, JSON.stringify( uniqueRxIdList ) );
    }
  }
  protected getCartInSession(): string[] {
    if ( this.pharmacySession.isValidSession() ) {
      return JSON.parse( sessionStorage.getItem( this.sessionCartKey ) ) || [];
    }
  }

  public getCartSizeFromSession(): number {
    return this.getCartInSession().length;
  }
  protected removeCartInSession() {
    sessionStorage.removeItem( this.sessionCartKey );
  }

}
