<div [formGroup]="form">
  <div id="search-wrapper" class="form-item">
    <label for={{searchId}}>{{searchLabel}}</label>
    <input 
          id={{searchId}} 
          name="search"
          formControlName="search" 
          type="text" 
          placeholder="{{placeholder}}"
          class="ant-text-input" 
          [ngClass]="{'border-color': form.controls['search']?.touched }"
          (blur)="resetSearchFilter()"
    />
    <button *ngIf="showSearchIcon" attr.analytics="{{searchAnalytics}}" class="search-submit" (click)="searchOrders($event)">
      <span style="font-weight: bold; color: #007ea8;" id="searchIcon">
        <i class='motif-icon motif-search-1 span.fa'></i>
    </span>
    </button>
    <button *ngIf="!showSearchIcon" class="search-cancel" (click)="cancelSearch($event)">
      <span id="cancelSearchIcon" style="font-weight: bold; color: #007ea8;" tabindex="0">
        <i class='motif-icon motif-cancel span.fa'></i>
      </span>
    </button>
  </div>
  <div class="error-list" *ngIf="(search.dirty || search.touched) && search.invalid">
    <span class="success"><i class="motif-icon motif-delete"></i>
      <span class="motif-margin-left-5">
        <span>{{searchLabel}} {{errorMessage}}</span>
      </span>
    </span>
  </div>
</div>