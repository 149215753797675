<div>
    <div class="modal-header">
        <button class="close motif-icon motif-delete" aria-label="Close Modal" [attr.data-analytics]="labels.closeIconAnalytics" (click)="dismissModal()" >
        </button>
      </div>
      <div *ngIf = "labels.title" class="modal-title">
        <h2 class="font-26">{{labels.title}}</h2>
      </div>
      <div  *ngIf = "labels.body" class="modal-body">
        <p class="font-16"><span [innerHTML] = labels?.body></span></p>
      </div>
      <div class="modal-footer">
        <div *ngIf="labels.primaryBtn">
          <button
          class="btn continue btn-primary modal-btn"
          (click)="primaryButton()"
          [attr.data-analytics]="labels.closeBtnAnalytics"
      >{{labels?.primaryBtn}}</button>
        </div>
        <div *ngIf="labels.secondaryBtn">
          <button
          class="btn btn-secondary modal-btn"
          (click)="secondaryButton()"
          [attr.data-analytics]="labels.secondaryBtnAnalytics"
      >{{labels?.secondaryBtn}}</button>
    </div>
      <div *ngIf="labels.closeBtn">
        <button
        class="btn continue btn-primary modal-btn"
        (click)="dismissModal()"
        [attr.data-analytics]="labels.secondaryBtnAnalytics"
    >{{labels?.closeBtn}}</button>
    </div>
    </div>
    </div>