<div class="" *ngIf="labels">
    <div class="container lr-pad">
        <div class="row header-section">
            <a routerLink="/secure/make-payment" class="ant-action-link">
                <span class="motif-icon motif-arrows-long-left"></span>
                {{headerJson.ManagePayments}}
            </a>
        </div>
    </div>
    <hr class="header-border">
    <div class="container">
        <inline-alert-container [name]="'payment-alert'" [alertType]="alertPosition"></inline-alert-container>
    </div>
    <div class="container motif-margin-0">
        <div class="display-block" data-uxd-alert-cmp-v2 *ngIf="isPaymentMethodAlert" [alert]="{alertType: 'positive'}">
            <span>{{paymentMethodText}}</span>
        </div>
        <div class="display-block" *ngIf="isPaymentsError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{errorMsgs.GeneralSystemErrorWithoutCode}}</span>
        </div>
        <div class="display-block" *ngIf="isEarlyWarningError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <p>
                <span>{{bankAccountDeclinedMessage}}</span>
                <span><a class="alert-link" href="{{earlyWarningLink}}" target="_blank" rel="noopener noreferrer">{{earlyWarningLabel}}</a></span>
                <span>{{earlyWarningServiceDescription}}</span>
            </p>            
        </div>
        <div class="display-block" *ngIf="isBankAccountDeclinedError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <p>{{bankAccountDeclinedMessage}}</p>            
        </div>
    </div>
    <div class="container  lrt-pad">
        <div class="row">
            <h2 class="section-header-two">{{labels.ReadyToMakePaymentNow}}</h2>
            <p class="description-text">{{labels.MakePaymentDesc}}</p>
        </div>
    </div>
    <div class="container motif-margin-0">
        <hr/>
    </div>
    <div class="container" *ngIf="isPaymentsLoading && !isPaymentsError">
        <div class="row" id="search-loader">
            <uxd-spinner></uxd-spinner>
        </div>
    </div>
    <div class="container motif-margin-0" *ngIf="selectedPayment">
        <div class="row ml-2">
            <div class="col-md-3">
                <h6>{{labels.AccountType}}</h6>
                <p>{{selectedPayment.accountType}}</p>
            </div>
            <div class="col-md-3">
                <h6>{{labels.BenefitPeriod}}</h6>
                <p>{{selectedPayment?.coverageStartDt | date}} - {{selectedPayment?.coverageEndDt | date}}</p>
            </div>
            <div class="col-md-2">
                <h6>{{labels.MemberId}}</h6>
                <p>{{selectedPayment.memberId}}</p>
            </div>
            <div class="col-md-2">
                <h6>{{labels.DueDate}}</h6>
                <p>{{selectedPayment?.dueDate | date:'MM/dd/yyyy'}}</p>
            </div>
            <div class="col-md-2">
                <h6>{{labels.Amount}}</h6>
                <p>{{selectedPayment.amount | currency:'USD'}}</p>
            </div>
        </div>
        <div class="row ml-2">
            <div data-uxd-alert-cmp-v2 class="ml-rem" [alert]="{alertType: 'disaster'}" *ngIf="selectedPayment.overDueDays">{{labels.OverdueErrorPayNow}}</div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4">
                <div class="card large-card card-flat card-popover">
                    <div class="row display-flex">
                        <div class="flex-size">
                            <span class="motif-icon motif-cheap-2"></span>
                        </div>
                        <div class="col-md-11 col-sm-11">
                            <h4> {{labels.PaymentAmount}}</h4>
                            <br/>
                            <p>{{selectedPayment.amount | currency:'USD'}}</p>
                            <p *ngIf="isOtherAmount">{{labels.OtherAmount}}</p>
                            <p *ngIf="!isOtherAmount">{{labels.MonthlyContribution}}</p>
                            <br/>
                            <p><a href="javascript:;" (click)="openSidePanel()">{{labels.ChangeAmount}}</a> </p>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card large-card card-flat card-popover">
                    <div class="row display-flex">
                        <div class="col-md-12 col-sm-12 mb-7">
                            {{max}}
                            <h4><span class="motif-icon motif-calendar"></span> &nbsp;{{labels.PaymentWithDrawDate}}</h4>
                            <br/>
                            <p>{{labels.PaymentWithDrawDesc}}</p>
                            <br/>
                            <form #selectForm="ngForm" name="selectForm">
                                <div class="cmp-example-wrapper">
                                    <div class="form-item clearfix" [style.opacity]="selectedPayment.overDueDays || selectedPaymentMethod == null || (selectedPaymentMethod && !selectedPaymentMethod.isBankAcc) ? '0.3':''">
                                        <label>{{ labels.SelectDate }}</label>
                                        <uxd-ant-date-picker required name="selectedDate" [(ngModel)]="selectedDate" [dateFormat]="dateDisplayFormat"
                                        [disabled]='selectedPayment.overDueDays || (selectedPaymentMethod && !selectedPaymentMethod.isBankAcc)'
                                            [min]="min" [max]="selectedPayment?.dueDate" [opened]="opened" [startAt]='startAt' [startView]='startView'
                                            (opened)='openedStream($event)' (dateChange)='dateChange($event)' (closed)='closedStream($event)'>
                                        </uxd-ant-date-picker>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card large-card card-flat card-popover date-card">
                    <div class="row align-center">
                        <div class="col-md-12 col-sm-12">
                            <h4 class="day font-weight-bold">{{selectedDate | date:'EEEE'}},</h4>
                            <p class="day font-weight-300">{{selectedDate | date:'MMMM'}}</p>
                            <h1>{{selectedDate | date:'d'}}</h1>
                            <br/>
                            <p>{{labels.DateWithdrawnDesc}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card med-card card-flat card-popover">
                    <div class="row display-flex">
                        <div class="flex-mid-size">
                            <span class="motif-icon motif-bank-cards"></span>
                        </div>
                        <div class="col-md-11 col-sm-11">
                            <h4> {{labels.PaymentMethod}}</h4>
                            <br/>
                            <p *ngIf="selectedPaymentMethod && !selectedPaymentMethod.isBankAcc">{{labels.CreditCardType[selectedPaymentMethod.creditCardType]}}-{{selectedPaymentMethod.creditCardNumber}}</p>
                            <p *ngIf="selectedPaymentMethod && !selectedPaymentMethod.isBankAcc && !isCardExpired(selectedPaymentMethod.expirationDate)">{{labels.Expires}} {{selectedPaymentMethod.expirationDate}}</p>
                            <p class="red" *ngIf="selectedPaymentMethod && !selectedPaymentMethod.isBankAcc && isCardExpired(selectedPaymentMethod.expirationDate)">
                            {{labels.Expired}} {{selectedPaymentMethod.expirationDate}}</p>
                            <p *ngIf="selectedPaymentMethod && selectedPaymentMethod.isBankAcc">{{labels.BankAccountType[selectedPaymentMethod.bankAccountType]}}</p>
                            <p *ngIf="selectedPaymentMethod && selectedPaymentMethod.isBankAcc">{{selectedPaymentMethod.bankAccountNumber}}</p>
                            <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'disaster'}" *ngIf="!isPaymentValid || !selectedPaymentMethod">{{labels.SelectPaymentToContinueError}}</div>
                            <br/>
                            <p><a href="javascript:void(0);" (click)="onAddPaymentMethod()">{{ selectedPaymentMethod ? labels.ChangePayment : labels.AddPayment}}</a>                                </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-md-6"> </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <h5 class="font-weight-bold">{{labels.PaymentAmount}}</h5>
                    </div>
                    <div class="col-md-6">
                        <h5 class="font-weight-bold float-right">{{selectedPayment.amount | currency:'USD'}}</h5>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-md-12">
                        <p>{{labels.AcknowledgeDesc}}</p>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-8 text-align-right">
                        <p>
                            <a routerLink="/secure/make-payment" class="btn btn-cancel">{{labels.Cancel}}</a>
                            <button class="btn float-right" [disabled]="!isPaymentValid || !selectedPaymentMethod || disablePaymentButton" (click)="submitPayment()">{{labels.SubmitPayment}}</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
    </div>
</div>

<!-- Slide-In Panel Template -->
<ng-template #sidePanelPayment>
    <div class="modal-example">
        <div class="clearfix">
            <button class="close" (click)="onClose()" aria-label="Close">
                <span class="close-icon"></span><span hidden>Close Payment Methods</span>
            </button>
        </div>
        <div class="modal-component-body container">
            <div class="row">
                <div class="col-lg-1 col-md-1 col-sm-1">
                </div>
                <div class="col-lg-7 col-md-7 col-sm-10">
                    <app-payment-methods [content]="labels.PaymentMethodPanel" [selectedPayment]="selectedPaymentMethod" (closePanel)="onClose()">
                    </app-payment-methods>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<!-- Slide-In Panel Template -->
<ng-template #sidePanelContent>
    <div class="modal-example">
        <div class="clearfix">
            <button name="close" aria-label="Close" class="close" (click)="onClose()">
            <span class="close-icon"></span>
        </button>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-md-1 col-sm-1">
                </div>
                <div class="col-md-7 col-sm-10">
                    <h3 class="font-26">{{labels.SelectPaymentAmount}}</h3>
                    <br/>
                    <p>{{labels.PayYourAccount}}</p>
                    <br/>
                    <form name="basic-radio-button-form">
                        <div [(ngModel)]="selected" (change)="isAmountSelected($event)" name="radioValue" data-uxd-radio-input-group-cmp [radioGroup]="radioGroup1"></div>
                        <p class="pl-5-5">{{labels.MonthlyContribution}}</p>
                        <div [(ngModel)]="selected" (change)="isAmountSelected($event)" name="radioValue2" data-uxd-radio-input-group-cmp [radioGroup]="radioGroup2"></div>
                        <p class="pl-5-5">
                            <input name="otherAmountSelect" id="monSelect" [(ngModel)]="otherAmount" [disabled]="selected == AmountSelectionOption.MonthlyContribution"
                                min="1" step="0.01" type="number" class="ant-text-input full-width" [ngModelOptions]="{standalone: true}"
                                (keyup)="onKeyUp()"/>
                                </p>
                                <div *ngIf="otherAmountInvalid" class="pl-5-5">
                                    <span class="red"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5 ">
                                            <span>{{errorMsgs.OtherAmountError}}</span>
                                    </span>
                                    </span>
                                </div>
                    </form>
                    <br/>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-8 text-align-right">
                            <button class="btn btn-secondary" (click)="onClose()">{{labels.Cancel}}</button> &nbsp;&nbsp;&nbsp;&nbsp;
                            <button class="btn float-right" (click)="onUpdate()">{{labels.Update}}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-template>