import { Injectable } from '@angular/core';
import { Locale, LocaleService } from '../../sydmed-shared/locale-service/locale.service';


@Injectable()
export class LanguageToggleService {
    constructor(private localeService: LocaleService) {}
    public getLocale() {
        return this.localeService.getLocaleOverride();
    }

    toggleLanguage() {
        if (!this.getLocale()) {
            this.localeService.setLocaleOverride(Locale.ES_US);
        } else {
            this.localeService.removeLocaleOverride();
        }
        this.reload();
    }

    reload() {
        location.reload();
    }
}
