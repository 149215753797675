import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommunityResourcesComponent } from './community-resources/community-resources.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
  {
    path: 'secure/community-resources',
    component: CommunityResourcesComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunityResourcesRoutingModule { }
