<h2 class="section-header-three" [class.margin-top-45]="uploadAttachmentRestriction" [innerHtml]="consentLabels?.header"></h2>
<p class="font-bold font-14 margin-bottom-3" [innerHtml]="consentLabels?.description"></p>
<form [formGroup]="consentInformationForm" #consentForm="ngForm" id="consentInformationForm">
  <div class="margin-top-8">
    <input type="checkbox" formControlName="consentcheck" class="opt-out" (change)="checkstatus($event)" />
    <span class="margin-left-8" [innerHtml]="consentLabels?.form.checkBoxContent"></span>
  </div>
  <div class="font-bold font-16 margin-top-18" [ngClass]="!consentChecked ? 'disable-effect' : ''"
    [innerHtml]="consentLabels?.subHeader"></div>
  <div class="row form-without-input margin-top-24" [ngClass]="!consentChecked? 'disable-effect' : ''">
    <div class="col-md-4 margin-bottom-40">
      <label class="font-14 font-semi-bold" for="firstName" [innerHtml]="consentLabels?.form.firstName"></label>
      <input type="text" formControlName="firstName" name="firstName" aria-required="true" [ngClass]="appealsAndGrivencesFormSubmitted &&
      consentInformationForm.controls['firstName']?.invalid ? 'error-border' : ''"
        class="width-100 ant-text-input margin-bottom-16" id="firstName" aria-describedby="firstname-errors" [tabindex]="!consentChecked ? '-1' : '0'" />
      <!-- Error for fist name field starts-->
      <div *ngIf="(consentInformationForm.controls['firstName']?.dirty ||
            consentInformationForm.controls['firstName']?.touched) || appealsAndGrivencesFormSubmitted &&
          consentInformationForm.controls['firstName']?.invalid" class="error-list">
        <span *ngIf="consentInformationForm.controls['firstName']?.errors?.required" class="error"><i
            class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
            <span [innerHtml]="consentLabels?.form.firstName"></span>
            <span [innerHtml]="errorMessageLabels?.Required_err"></span>
          </span>
        </span>
        <span *ngIf="consentInformationForm.controls['firstName']?.errors?.invalidnamechar" class="error"><i
            class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
            <span [innerHtml]="consentLabels?.form.firstName"></span>
            <span [innerHtml]="errorMessageLabels?.Name_Invalid_err"></span>
          </span>
        </span>
      </div>
      <!-- Error for first name field ends-->
    </div>

    <div class="col-md-4 margin-bottom-40">
      <label class="font-14 font-semi-bold" for="lastName" [innerHtml]="consentLabels?.form.lastName"></label>
      <input type="text" formControlName="lastName" name="lastName" aria-required="true" [ngClass]="appealsAndGrivencesFormSubmitted &&
      consentInformationForm.controls['lastName']?.invalid ? 'error-border' : ''"
        class="width-100 ant-text-input margin-bottom-16" id="lastName" aria-describedby="lastname-errors" [tabindex]="!consentChecked ? '-1' : '0'" />
      <span *ngIf="consentInformationForm.controls['lastName']?.errors?.hasValidChars" class="error">
        <i class="motif-icon motif-delete"></i>
        <span class="motif-margin-left-5">
          <span class="errorMessage" [innerHtml]="errorMessageLabels?.AlphaNumericFormat"></span>
        </span>
      </span>
      <!-- Error for last name field starts-->
      <div
        *ngIf="(consentInformationForm.controls['lastName']?.dirty ||
            consentInformationForm.controls['lastName']?.touched) || appealsAndGrivencesFormSubmitted &&  consentInformationForm.controls['lastName']?.invalid"
        class="error-list">
        <span *ngIf="consentInformationForm.controls['lastName']?.errors?.required" class="error">
          <i class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
            <span [innerHtml]="consentLabels?.form.lastName"></span>
            <span [innerHtml]="errorMessageLabels?.Required_err"></span>
          </span>
        </span>
        <span *ngIf="consentInformationForm.controls['lastName']?.errors?.invalidnamechar" class="error"><i
            class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
            <span [innerHtml]="consentLabels?.form.lastName"></span>
            <span [innerHtml]="errorMessageLabels?.Name_Invalid_err"></span>
          </span>
        </span>
      </div>
      <!-- Error for last name field ends-->
    </div>

    <div class="col-md-4 margin-bottom-40">
      <lib-phone [id]="'phoneNumber'" formControlName="phoneNumber" [editable]="editable"
        [phoneLabel]="consentLabels?.form?.consentPhoneNumber" [errObj]="errorMessageLabels"
        [formSubmitted]="appealsAndGrivencesFormSubmitted && consentChecked" [phoneTabIndex]="consentChecked"></lib-phone>
    </div>
  </div>
</form>