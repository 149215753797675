import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLauncherComponent } from './chat-launcher/chat-launcher.component';
import { ChatModule as UXDChatModule} from '@anthem/uxd/chat';
import { ButtonModule} from '@anthem/uxd/button';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatTopicsComponent } from './chat-topics/chat-topics.component';
import { LiveChatService } from "./services/live-chat.service";
import { HttpClientModule } from '@angular/common/http';
import { CommonPipesModule } from 'sydmed/libs/common-pipes/src/lib/common-pipes.module';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { ChatbotToastComponent } from './chatbot-toast/chatbot-toast.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ChatbotHelperService } from "./services/chatbot-helper.service";
import { ChatbotWatsonComponent } from './chatbot-watson/chatbot-watson.component';
import { ChatBotService } from './services/chatbot.service';
import { ChatbotIdCardComponent } from './chatbot-idcard/chatbot-idcard.component';
import { UxDeprecatedModule } from '@anthem/uxd/deprecated';
import { SharedModule } from '@anthem/mbrportal/shared/sharedModule';
import { ChatbotIdCardFlipperComponent } from './chatbot-idcard/chatbot-idcard-flipper.component';
import { FormGroupModule }  from '@anthem/uxd/form-group';
import { AccordianModule } from '@anthem/uxd/accordion';
import { RadioModule } from '@anthem/uxd/radio';

const dbConfig: DBConfig  = {
  name: 'LiveChatDB',
  version: 1,
  objectStoresMeta: [{
    store: 'messages',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'message', keypath: 'message', options: { unique: false } },
      { name: 'source', keypath: 'source', options: { unique: false } },
      { name: 'timeStamp', keypath: 'timeStamp', options: { unique: false } },
      { name: 'msgIndex', keypath: 'msgIndex', options: { unique: false } }
    ]
  }]
};

@NgModule({
  declarations: [
    ChatLauncherComponent, 
    ChatTopicsComponent, 
    ChatbotToastComponent, 
    ChatbotComponent,
    ChatbotWatsonComponent,
    ChatbotIdCardComponent,
    ChatbotIdCardFlipperComponent
  ],
  imports: [
    CommonModule,
    UXDChatModule,
    HttpClientModule,
    CommonPipesModule,
    FormsModule, 
    ReactiveFormsModule,
    FormGroupModule,
    AccordianModule,
    ButtonModule,
    IndicatorModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    UxDeprecatedModule,
    SharedModule,
    RadioModule,
  ],
  exports: [
    ChatLauncherComponent,
    UXDChatModule,
    ChatbotIdCardComponent,
    ChatbotIdCardFlipperComponent,
    ChatbotWatsonComponent
  ],
  providers: [
    LiveChatService,
    JsonContentService,
    ChatbotHelperService,
    ChatBotService
    
  ]
})
export class ChatModule { }
