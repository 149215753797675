<div class="cards-wrapper mt-65">
    <div class="cards-container" *ngIf="ptContent">
        <!-- home delivery -->
        <div id="{{ ptContent.LeftTile.TileId }}" class="card medium-card card-popover">
            <div class="hd-rx-tile-grid-container">
                <div class="icon">
                    <div class="{{ ptContent.LeftTile.HeaderIcon }}" aria-hidden="true"></div>
                </div>
                <div class="title">
                    <div class="motif-pad-left-3">{{ ptContent.LeftTile.HeaderText }}</div>
                </div>
                <div class="desc">
                    <div class="motif-pad-left-3 motif-pad-bottom-5">{{ ptContent.LeftTile.Body1 }}</div>
                </div>
                <div class="link">
                <a href="javascript:void(0)" class="ant-action-link">
                    <span class="motif-pad-left-3 {{ ptContent.LeftTile.LinkIcon }}"></span><span class="ant-link-text">{{ ptContent.LeftTile.LinkText }}</span>
                </a>
                </div>
            </div>
        </div>
        <!-- start new prescription -->
        <div id="{{ ptContent.RightTile.TileId }}" class="card medium-card card-popover">
            <div class="hd-rx-tile-grid-container">
                <div class="icon">
                    <div class="{{ ptContent.RightTile.HeaderIcon }}" aria-hidden="true"></div>
                </div>
                <div class="title">
                    <div class="motif-pad-left-3">{{ ptContent.RightTile.HeaderText }}</div>
                </div>
                <div class="desc">
                    <div class="motif-pad-left-3 motif-pad-bottom-5">{{ ptContent.RightTile.Body1 }}</div>
                </div>
                <div class="link">
                    <a href="{{ptContent.RightTile.Link}}" class="ant-action-link">
                        <span class="motif-pad-left-3 {{ ptContent.RightTile.LinkIcon }}"></span><span class="ant-link-text">{{ ptContent.RightTile.LinkText }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>