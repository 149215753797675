import { Injectable } from "@angular/core";
import { JsonContentService } from "../content-service/json-content.service";
import { IContentErrorResponse } from "@anthem/mbrportal/core/public_api";
// import { widgetToContentMapper } from "./AHD-content-mapper";
let content1;

@Injectable({ providedIn: 'root' })
export class AHDContentHelper {
    protected contentStore: { [name: string]: unknown } = {};

    constructor(private jsonSvc: JsonContentService) {

    }

    registerContent<T = unknown>(name: string, content: T) {
        sessionStorage.setItem(name, JSON.stringify(content));
        return content;
    }

      getContent<T = unknown>(name: string): string {
        return "dummy";
    }

    getBrandContent<T = unknown>(name: string, subWidgetName: string = '', brand?: string, groupId?: string, subGroupId?: string) {        
        return JSON.parse(sessionStorage.getItem(name));
    }

    // Used by AHD code to get content error message from API Error response
    extractApiBusErrors(apiErrorResp: IContentErrorResponse = {}, errorCodeContent: { [k: string]: string }, defaultErrorCode: string, httpStatus: string = '') {
        let error: string | undefined = undefined;
        if (typeof apiErrorResp.data !== 'undefined' && (typeof apiErrorResp.errors === 'undefined' || typeof apiErrorResp.exceptions === 'undefined')) {
          apiErrorResp = apiErrorResp.data || {};
        }
    
        if (typeof apiErrorResp !== 'undefined' && typeof apiErrorResp.exceptions !== 'undefined' && apiErrorResp.exceptions !== null && Array.isArray(apiErrorResp.exceptions)) {
          apiErrorResp.exceptions.forEach((exception) => {
            if (
              typeof exception.type !== 'undefined' &&
              typeof exception.code !== 'undefined' &&
              (typeof errorCodeContent['ERR' + exception.code] !== 'undefined' || typeof errorCodeContent['err' + exception.code] !== 'undefined')
            ) {
              error = this.getErrorJsonProperty(errorCodeContent, exception.code);
            }
          });
        } else if (
          typeof apiErrorResp !== 'undefined' &&
          typeof apiErrorResp.exceptions !== 'undefined' &&
          apiErrorResp.exceptions !== null &&
          !Array.isArray(apiErrorResp.exceptions) &&
          typeof apiErrorResp.exceptions.code !== 'undefined' &&
          (typeof errorCodeContent['ERR' + apiErrorResp.exceptions.code] !== 'undefined' || typeof errorCodeContent['err' + apiErrorResp.exceptions.code] !== 'undefined')
        ) {
          error = this.getErrorJsonProperty(errorCodeContent, apiErrorResp.exceptions.code);
        } else if (
          typeof apiErrorResp !== 'undefined' &&
          typeof apiErrorResp.message !== 'undefined' &&
          typeof apiErrorResp.message.messageCode !== 'undefined' &&
          (typeof errorCodeContent['ERR' + apiErrorResp.message.messageCode] !== 'undefined' || typeof errorCodeContent['err' + apiErrorResp.message.messageCode] !== 'undefined')
        ) {
          error = this.getErrorJsonProperty(errorCodeContent, apiErrorResp.message.messageCode);
        } else if (typeof apiErrorResp !== 'undefined' && typeof apiErrorResp.errors !== 'undefined' && apiErrorResp.errors !== null && apiErrorResp.errors.length > 0) {
          apiErrorResp.errors.forEach((exception) => {
            if (typeof exception.code !== 'undefined') {
              error = this.getErrorJsonProperty(errorCodeContent, exception.code);
            }
          });
        } else if (
          typeof apiErrorResp !== 'undefined' &&
          typeof apiErrorResp.errorCode !== 'undefined' &&
          (typeof errorCodeContent['ERR' + apiErrorResp.errorCode] !== 'undefined' || typeof errorCodeContent['err' + apiErrorResp.errorCode] !== 'undefined')
        ) {
          error = this.getErrorJsonProperty(errorCodeContent, apiErrorResp.errorCode);
        }
    
        if (!error && httpStatus) {
          error = this.getErrorJsonProperty(errorCodeContent, httpStatus);
        }
    
        // if api error out with no specific error codes, show unknown error.
        if (!error || Object.keys(error).length === 0) {
          error = defaultErrorCode ? defaultErrorCode : '100';
        }
        
        return error;
      }
    
      protected getErrorJsonProperty(errorContent: { [k: string]: string }, errorCode: string): string {
        if (typeof errorContent['ERR' + errorCode] !== 'undefined') {
          return 'ERR' + errorCode;
        } else if (typeof errorContent['err' + errorCode] !== 'undefined') {
          return 'err' + errorCode;
        } else {
          return '';
        }
      }
}
