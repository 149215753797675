import { Component, OnInit } from "@angular/core";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { PowerAccountContent } from "../interfaces/power-account-content";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { Restriction } from 'gbd-models';

@Component({
  selector: "app-power-account-view",
  templateUrl: "./power-account-view.component.html",
  styleUrls: ["./power-account-view.component.scss"]
})
export class PowerAccountViewComponent implements OnInit {
  content: PowerAccountContent;
  genericErr: string;
  isCoronaActive: boolean = false;
  pageLoader: boolean = true;
  showUrgentMsg: boolean = true;
  hasMakePayment: boolean = true;
  hasPlanNotifications:boolean = true;

  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService
  ) {
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "POWER Account Activity"
    });
  }

  ngOnInit(): void {
    this.setContent();
  }

  /**
   * @description setContent() is responsible for fetching content from json
   * using content service
   * @returns {void}
   */
  private setContent(): void {
    this.genericErr = '';
    this.jsonSvc.getJSON('power-account').subscribe(
      (content: PowerAccountContent) => {
        this.content = content;
        this.pageLoader = false;
        this.isCoronaActive = this.jsonSvc.hasRestriction(Restriction.SHM_COVID, content.restrictions);
        this.hasMakePayment = !this.jsonSvc.hasRestriction(Restriction.SHM_BILL_PAY, content.restrictions);
        this.hasPlanNotifications = !this.jsonSvc.hasRestriction(Restriction.SHM_COMM_PREFERENCES, content.restrictions);
      },
      (error: any) => {
        this.genericErr = 'We are unable to complete your request at this time. Please contact member services.';
        this.pageLoader = false;
      }
    );
  }

  showUrgentTxt(showMsg: boolean) {
    this.showUrgentMsg = showMsg;
  }
}
