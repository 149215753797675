import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';


@Component({
  selector: 'app-enter-security-code',
  templateUrl: './enter-security-code.component.html',
  styleUrls: ['./enter-security-code.component.css']
})
export class EnterSecurityCodeComponent implements OnInit {

  constructor(
    private router: Router,
    private jsonSvc: JsonContentService
  ) { }

  public jsonLabels: any;
  public jsonErrors: any;
  public endpoint = UrlProperties.UrlProperties.endPoints.registration.validateOTP;
  userDetails: any;

  ngOnInit(): void {
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Enter_Security_Code;
      this.jsonErrors = data.RegistrationMFA.ErrorMsgs;
    });
    this.userDetails = JSON.parse(window.sessionStorage.getItem('contactsModel'));
  }

  validateOTP(event) {
    if (this.userDetails.memberResponse.caregiverRequired) {
      sessionStorage.setItem("caregiverRelationTypeVisited", "true")
      this.router.navigate(['public/registration/caregiver-relation-type']);
    }
    else {
      sessionStorage.setItem('createUsernameVisited', 'true');
      this.router.navigate(['public/registration/create-username-password']);
    }
  }

  goToSendSecurityCodePage() {
    this.router.navigate(['public/registration/get-security-code']);
  }

}