import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';


@Component({
  selector: 'get-security-code',
  templateUrl: './get-security-code.component.html',
  styleUrls: ['./get-security-code.component.css']
})
export class GetSecurityCodeComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private jsonSvc: JsonContentService
  ) { }

  GetSecurityCodeForm: FormGroup;
  public jsonLabels;
  public jsonErrors;
  public linkLabel;
  public url = UrlProperties.UrlProperties.endPoints.login.sendOtp;

  ngOnInit(): void {
    this.jsonSvc.getJSON('login').subscribe(data => {
      this.jsonLabels = data.LoginMFA.Labels.Get_Security_Code;
      this.jsonErrors = data.LoginMFA.ErrorMsgs;
      this.linkLabel = this.jsonLabels.Security_Questions_Link_Text;
    });

    this.GetSecurityCodeForm = this.formBuilder.group({
      sendCodeOption: ['', [Validators.required]],
    });
  }

  goToSecurityQuestions(){
    this.router.navigate(['/public/login/answer-security-questions']);
  }

  goToHomePage(){
    this.router.navigate(['/public/login']); 
  }

  goToEnterSecurityCodePage(){
     this.router.navigate(['/public/login/enter-security-code']);
  }
}
