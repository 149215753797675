<div #scrollContainer id="appealsAndGrivencesPreview" class="appealsAndGrievances ">

    <!-- Alert display area -->
    <div class="margin-top-20 margin-bottom-10" *ngIf="serviceError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span [innerHtml]="serviceError"></span>
    </div>

    <div class="section-header padding-bottom-40 padding-top-20 container">
        <div class="section-header padding-bottom-0 padding-top-0">
            <div class="section-header-three-line"></div>
            <h6 class="margin-0 font-16" [innerHtml]="defaultLabels?.subHeader"></h6>
            <h2 class="section-header-two font-22" [innerHtml]="labels?.header"></h2>
        </div>
    </div>
    <div class="modal-body border-bottom-0 padding-top-0">
        <p class="font-14 font-optional-color" [innerHtml]="labels?.formDescription"></p>
        <ul id="section-list-description" class="ext-desc">
            <li *ngFor="let lists of defaultLabels?.bulletDesc">
                <span class="font-optional-color" [innerHTML]="lists"></span>
            </li>
        </ul>

        <form id="AppealsAndGrievancesForm" [formGroup]="appealsAndGrievancesForm" novalidate
            #appealsAndGrievancForms="ngForm" (ngSubmit)="submitAppealsAndGrievancesForm()">
           
                <div *ngIf="editable;else memberInformationTemplate" class="form-without-input col-md-12 padding-0 margin-bottom-24">
                    <h2 class="section-header-three margin-top-30" [innerHtml]="memberInformationLabels?.header"></h2>

                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold" [innerHtml]="memberInformationLabels?.form.firstName"></label>
                        <span class="font-12" [innerHtml]="memberData?.firstName"></span>
                    </div>
                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold" [innerHtml]="memberInformationLabels?.form.lastName"></label>
                        <span class="font-12" [innerHtml]="memberData?.lastName"></span>
                    </div>
                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold" [innerHtml]="memberInformationLabels?.form.memberIdNumber"></label>
                        <span class="font-12" [innerHtml]="memberData?.hcid"></span>
                    </div>
                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold" [innerHtml]="memberInformationLabels?.form.memberPhoneNumber"></label>
                        <span class="font-12" [innerHtml]="formValues?.memberInformation?.phoneNumber?.phone"></span>
                    </div>
                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold" [innerHtml]="memberInformationLabels?.form.authorizationNumber"></label>
                        <span class="font-12" [innerHtml]="formValues?.memberInformation?.authorizationNumber"></span>
                    </div>
                </div>

            <!-- Member Information -->
            <ng-template #memberInformationTemplate>
                <app-member-information [appealsAndGrivencesFormSubmitted]="appealsAndGrievancForms.submitted"
                    formControlName="memberInformation" [memberLabels]="memberInformationLabels"
                    [errorMessageLabels]="errorMsgsLabels" [editable]="editable">
                </app-member-information>
            </ng-template>

             <!-- Grievance Health Option -->
             <app-grievance-health-option [formSubmitted]="appealsAndGrievancForms.submitted" formControlName="healthOption"
             [labels]="healthOptionLabels" [errorMsgs]="errorMsgsLabels" [disableEffect]="editable"
             *ngIf="activePath === 'grievance'">
            </app-grievance-health-option>

            <!-- Reason for Request -->
            <app-reason-for-request [formSubmitted]="appealsAndGrievancForms.submitted"  [disableEffect]="editable"
                formControlName="reasonForRequests" [labels]="reasonForRequestLabels" [defaultLabels]="defaultLabels"
                [errorMsgs]="errorMsgsLabels" *ngIf="activePath === 'grievance'; else appeal">
            </app-reason-for-request>

            <ng-template #appeal>
                <!-- Describe Your Situation -->
                <app-describe-your-situation [labels]="describeYourSituationLabels" [errorMsgs]="errorMsgsLabels"
                    [formSubmitted]="appealsAndGrievancForms.submitted" formControlName="describeYourSituation" [disableEffect]="editable">
                </app-describe-your-situation>
            </ng-template>

            <!-- Description of Specific Issue -->
            <app-issue-description [formSubmitted]="appealsAndGrievancForms.submitted" [defaultLabels]="defaultLabels"
                formControlName="issueDescription" [errorMsgs]="errorMsgsLabels" [labels]="labels"
                [descriptionLabels]="descriptionOfSpecificIssueLabels" [editable]="editable">
            </app-issue-description>

            <!-- Preview attachment files -->
            <ng-container class="col-md-12" *ngIf="!uploadAttachmentRestriction">
                <div class="message-center-file col-xs-6 col-sm-6 col-md-4 col-lg-3"
                    *ngFor="let fileData of helperService.scannedFiles; trackBy:trackByFile">
                    <div class="message-center-file-description" *ngIf="fileData">
                        <div aria-hidden="true" class="file-details_attachment__icon margin-top-4" [ngSwitch]="true">
                            <span *ngSwitchCase="fileData?.fileType === 'pdf'" class="motif-icon motif-pdf"></span>
                            <span *ngSwitchCase="(fileData?.fileType ==='docx' || fileData?.fileType ==='doc')"
                                class="motif-icon motif-document"></span>
                            <span *ngSwitchCase="(fileData?.fileType ==='xls' || fileData?.fileType ==='xlsx')"
                                class="motif-icon motif-document"></span>
                        </div>
                        <div class="file-details_attachment">
                            <p class="font-12 file-details_attachment__filename" title="{{ fileData?.fileName }}" [innerHTML]="trimFileName(fileData?.fileName)"></p>
                            <span class="font-12 file-details_attachment__filesize"> {{ fileData?.fileSize | filesize }}</span>
                        </div>
                    </div>
                </div>
                <div class="display-inline-block col-md-12 row">
                    <p *ngIf="fileService.enableAttachment" class="font-14" id="fileDescription">{{ attachFiles?.noteDescription }}</p>
                </div>
            </ng-container>

            <!-- Consent Information -->
            <div *ngIf="editable;else consentInformationTemplate" class="form-without-input col-md-12 padding-0">
                <h2 class="section-header-three margin-top-30" [innerHtml]="consentInformationLabels?.header"></h2>
                <p class="font-bold font-14 margin-bottom-3" [innerHtml]="consentInformationLabels?.description"></p>
                <div class="margin-top-8">
                    <input type="checkbox" [checked]="formValues?.consentInformation?.firstName" class="opt-out disable-effect">
                    <span class="margin-left-8" [innerHtml]="consentInformationLabels?.form.checkBoxContent"></span>
                </div>
                <div class="font-bold font-16 margin-top-18"
                    [ngClass]="!formValues?.consentInformation?.firstName ? 'disable-effect' : ''"
                    [innerHtml]="consentInformationLabels?.subHeader"></div>
                <div class="row form-without-input margin-top-24"
                    [ngClass]="!formValues?.consentInformation?.firstName ? 'disable-effect' : ''">
                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold" [innerHtml]="consentInformationLabels?.form.firstName"></label>
                        <span class="font-12" [innerHtml]="formValues?.consentInformation?.firstName"></span>
                    </div>
                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold" [innerHtml]="consentInformationLabels?.form.lastName"></label>
                        <span class="font-12" [innerHtml]="formValues?.consentInformation?.lastName"></span>
                    </div>
                    <div class="col-md-4 margin-bottom-40">
                        <label class="font-14 font-semi-bold"
                            [innerHtml]="consentInformationLabels?.form.consentPhoneNumber"></label>
                        <span class="font-12" [innerHtml]="formValues?.consentInformation?.phoneNumber?.phone"></span>
                    </div>
                </div>
            </div>
            
            <ng-template #consentInformationTemplate>
                <app-consent-information [appealsAndGrivencesFormSubmitted]="appealsAndGrievancForms.submitted"
                    formControlName="consentInformation" [consentLabels]="consentInformationLabels"
                    [errorMessageLabels]="errorMsgsLabels" [editable]="editable">
                </app-consent-information>
            </ng-template>

            <!-- Note Description -->
            <div class="display-inline-block margin-top-25">
                <p class="font-14" [innerHtml]="defaultLabels?.preview?.edit_your_information_heading"></p>
            </div>

             <!-- Loader -->
             <ng-container *ngIf="loading">
                <div class="row">
                    <div class="tabbed-view-wrapper col-xs-12 loader">
                        <uxd-spinner class="custom-updating-loader"></uxd-spinner>
                        <p class="text-center font-14">{{defaultLabels.preview?.submittingLoaderText}}</p>
                    </div>
                </div>
            </ng-container>

            <div class="button-group margin-top-50 margin-bottom-80 text-center">
                <button type="button" id="Edit_btn" attr.data-analytics="{{labels?.preview?.editBtnAnalytics}}" [disabled]="loading"
                    class="btn btn-primary width-100 custom-btn-size margin-bottom-20 margin-right-10"
                    [innerHtml]="defaultLabels?.form?.edit_btn" (click)="closeModal()"></button>
                <button type="submit" attr.data-analytics="{{labels?.preview?.submitBtnAnalytics}}" id="Submit_btn"
                    [disabled]="loading" (click)="submitAppealsAndGrievancesForm()"
                    class="btn btn-secondary width-100 custom-btn-size margin-bottom-20"
                    [innerHtml]="defaultLabels?.form?.submit_btn"></button>
            </div>
        </form>
    </div>
</div>