import { Component, OnInit } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { PriorAuthorizationLabels, PriorAuthorizationErrorMessages, PriorAuthorizationAnalytics } from '../models/labels';

@Component({
  selector: 'app-prior-auth-faqs',
  templateUrl: './prior-auth-faqs.component.html',
  styleUrls: ['./prior-auth-faqs.component.scss']
})
export class PriorAuthFaqsComponent implements OnInit {

  constructor(
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService
  ) { 
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Prior Authorization'
    });
  }
  public content: PriorAuthorizationLabels;
  public labels: any;
  public analytics: PriorAuthorizationAnalytics;
  public error: boolean = false;
  
  ngOnInit(): void {
    this.jsonSvc.getJSON('prior-authorization').subscribe(
    (res) => {
      if (res.PriorAuthorization) {
        this.labels = res.PriorAuthorization?.Labels?.pharmacyPreAuthFaqs;
        this.analytics = res.PriorAuthorization?.analytics;
      } else {
        this.error = true;
      }
    },
    (err) => {
      this.error = true;
    }
  );
  }

}
