import { AppRequestInterceptor } from "./app-request.service";
import { Injectable } from "@angular/core";
import { AppSession } from "@anthem/mbrportal/main";
import { HttpClient2 } from "@anthem/mbrportal/http";

@Injectable()
export class AppInitializer {
  constructor(
    private _appSession: AppSession,
    private _appReqInterceptor: AppRequestInterceptor,
    private _httpClient: HttpClient2
  ) {}

  init(): Promise<any> {
    this._httpClient.addRequestInterceptor(this._appReqInterceptor);
    const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    this._appSession.metaData = {
      mbrUid: memberContext?.mbrUid,
      brandCd: "ABCBS",
      features: [],
      eligibility: [],
    } as any; // TODO:: check this again

    this.setupServerTime();

    return Promise.resolve();
  }

  private setupServerTime() {
    this._appSession.serverTime = new Date();
  }
}
