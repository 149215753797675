import { Component, OnInit, OnDestroy } from '@angular/core';
import { ErrorMessageService } from './error-message.service';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit, OnDestroy {

  protected sub: Subscription = new Subscription();
  public alertPosition: any = "static";
  public errorMsg: string;

  constructor(public errorMessageService: ErrorMessageService) { }


  ngOnInit(): void {
    
    this.sub.add(
      this.errorMessageService.errorMessages.subscribe(
        (errorMessages: Array<string>) => {

          if(errorMessages){
            errorMessages.map((errorMessage) => {
              this.errorMsg = errorMessage;
            })
          }

        }
       )
    );

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
