import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-api-failure',
  templateUrl: './api-failure.component.html',
  styleUrls: ['./api-failure.component.scss']
})
export class ApiFailureComponent implements OnInit {
  @Input() imageSource: string;
  @Input() header: string;
  @Input() description: string;
  @Input() primaryButtonText: string;
  @Output() primaryButtonClick = new EventEmitter<void>();
  constructor() { }

  ngOnInit(): void {
  }
  onPrimaryButtonClick() {
    this.primaryButtonClick.emit();
  }
}
