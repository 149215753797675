import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class HelperService {

  public scannedFiles = [];
  public attachmentServiceError: string;
  constructor() { }

  addFile(file): void{
    this.scannedFiles.push(file);
  }

  deleteFile(fileData):void {
    this.scannedFiles.splice(this.scannedFiles.indexOf(fileData), 1);
  }

  clearFileAttachments(){
    this.scannedFiles = [];
  }

  getDocumentsDetails(){
    return this.scannedFiles.map(({documentNumber, receivedDateTime}) => (
       { documentId: documentNumber, createDateTime: receivedDateTime }
    ));
  }

  getFileDetails() {
    return this.scannedFiles.map(
      ({ documentNumber, fileName, fileSize, fileType, receivedDateTime }) => ({
        fileIdentifier: documentNumber,
        fileName: fileName,
        fileSize: fileSize,
        fileType: fileType,
        createDateTime: receivedDateTime,
      })
    );
  }
  
  getRestrictions() {
    if(!sessionStorage?.sydMedMemberContext) {
      return [];
    }
    const memberContext = JSON.parse(sessionStorage.sydMedMemberContext);
    const restrictions = memberContext?.restrictions || null;
    return restrictions ? restrictions : [];
  }

}
