import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[formControlName][libDateFormat]'
})
export class DateFormatDirective implements OnInit, OnDestroy {

  private prevValue: string;
  private isDeleteButton: boolean;

  @Input('preValue')
  set preValue(value: string) {
    this.prevValue = value;
  }

  private sub: Subscription;

  constructor(
    private readonly el: ElementRef,
    private readonly dateControl: NgControl,
    private readonly renderer: Renderer2
  ) { }

  @HostListener('keydown', ['$event'])
  checkForDelete(event) {
    if (event.which === 46 || event.key === 'Delete') {
      this.isDeleteButton = true;
    } else {
      this.isDeleteButton = false;
    }
  }

  ngOnInit() {
    this.dateValidate();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  dateValidate() {

    this.sub = this.dateControl.control.valueChanges.subscribe(data => {

      // keep track of prev val
      const preInputValue: string = this.prevValue;
      // remove all chars except numbers
      let newVal = data.replace(/\D/g, '');

      let start = this.el.nativeElement.selectionStart;
      let end = this.el.nativeElement.selectionEnd;

      // when removed value from input
      if (data.length < preInputValue.length) {

        newVal = this.formatDate(newVal);

        this.dateControl.control.setValue(newVal, { emitEvent: false });

        // keep cursor the normal position after setting the input above.
        (preInputValue.charAt(start) === '/' && this.isDeleteButton) ?
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start + 1, end + 1) :
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);

        // when typed value in input
      } else {
        const removedD = data.charAt(start);

        newVal = this.formatDate(newVal);

        // check to see if typed in middle
        if (preInputValue.length >= start) {
          // change cursor according to special chars.
          if (removedD === '/') {
            start = start + 1;
            end = end + 1;
          }
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);
        } else {
          // +2 because of wanting standard typing
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start + 2, end + 2);
        }
        this.dateControl.control.setValue(newVal, { emitEvent: false });
      }
    });
  }

  formatDate(newVal) {
    if (newVal.length <= 2) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1');
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2');
    } else {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(.*)/, '$1/$2/$3');
    }

    return newVal;
  }
}

