<p class="font-bold margin-top-25 font-14" [innerHtml]="labels?.header"></p>

<form [formGroup]="describeSituationForm">
  <ul class="rfr-Form-ul">
    <li class="uxd-sidenav-wrapper" id="list{{id}}" *ngFor="let item of labels?.form?.radioButtonsGroup; index as id">
      <input class="prOption" formControlName="requestCategory" type="radio" id="requestCategory{{id}}"
        name="requestCategory" [value]="item?.name" (change)="selectItem(id)">
      <label for="requestCategory{{id}}"
        [ngClass]="[selectedRadioButton === id || describeSituationForm?.value?.requestCategory === item?.name ? 'active': '', disableEffect ? 'disable-pointer-events' : '']"
        class="margin-left-8 font-14 font-normal prLabel text-justify" for="requestCategory{{id}}">{{item.name}}</label>
    </li>
  </ul>
  <div *ngIf="describeSituationForm.controls['requestCategory']?.invalid && formSubmitted"
    class=" text-left margin-top-14">
    <span *ngIf="describeSituationForm.controls['requestCategory']?.errors.required" class="error"><i
        class="motif-icon motif-delete"></i>
      <span class="motif-margin-left-5">
        <span [innerHtml]="errorMsgs?.Required_RadioButton_err"></span>
      </span>
    </span>
  </div>
</form>