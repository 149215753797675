import { Component, OnInit } from '@angular/core';
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { PaymentPortalService } from "./services/payment-portal.service";
import { PaymentHelperService } from "./services/payment-helper.service";
import { AlertService, UniversalAlertType } from "@anthem/uxd/alert";
import { PaymentHistory } from './interfaces/PaymentHistory';
import { Restriction } from 'gbd-models';

@Component({
  selector: 'app-payment-portal',
  templateUrl: './payment-portal.component.html',
  styleUrls: ['./payment-portal.component.scss']
})
export class PaymentPortalComponent implements OnInit {
  labels: any;
  headerJson: any;
  errorMsgs: any;
  restrictions: any;
  isCoronaActive: boolean = false;
  isPaymentsLoading: boolean = false;
  isPaymentsError: boolean = false;
  creditCardEncrypted: boolean = false;
  creditCardValid: boolean = false;
  paymentList: PaymentHistory[];
  displayList: PaymentHistory[];
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private _service: PaymentPortalService,
    private _alert: AlertService,
    private _helper: PaymentHelperService
  ) {
    this.getLabels();
  }
  getLabels() {
    this.jsonSvc.getJSON('payment-portal').subscribe(data => {
      this.labels = data.paymentPortal.labels;
      this.restrictions = data.restrictions;
      this.isCoronaActive = this.jsonSvc.hasRestriction(Restriction.SHM_COVID, this.restrictions);
      this.headerJson = data.paymentPortal.HeaderNav;
      this.errorMsgs = data.paymentPortal.errors;
      this.interCommunicationService.raiseEvent({
        title: this.headerJson.HeaderTitle,
        message: this.headerJson.HeaderMessage
      });
    });
  }

  ngOnInit(): void {
    this.isPaymentsLoading = true;
    this._service.getSummary().subscribe(res => {
      let pending: PaymentHistory[] = this._helper.mapPendingToHistory(res.body);
      if (pending.length)
        this.paymentList = pending;
      this.getHistory();
    }, err => {
      this.isPaymentsError = true;
    });
  }
  getHistory() {
    const monthsCount: number = 12;
    this._service.getHistory(monthsCount).subscribe(res => {
      if (this.paymentList && this.paymentList.length)
        this.paymentList = this.paymentList.concat(this._helper.mapPaymentHistoryFromApi(res.body));
      else
        this.paymentList = this._helper.mapPaymentHistoryFromApi(res.body);
      this.displayList = this.paymentList.slice(0, 10);
      this.isPaymentsLoading = false;
    }, err => {
      this.isPaymentsError = true;
    });
  }
}
