import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { default as routerUrls } from './url-restrictions.json'
import { SessionStorage, StorageService } from '../storage-service/src/public-api';

@Injectable({
    providedIn: 'root'
})

export class AuthorizationService {
    userIsLoggedIn: boolean = false;
    RouterDetails = routerUrls.RouterDetails;

    constructor(
        private _http: HttpClientService,
        private http: HttpClient,
        private storage: StorageService
    ) { }

    public isLoggedIn() {
        if (window.sessionStorage.getItem('sydMedLoggedIn') === 'true') {
            this.userIsLoggedIn = true;
        } else {
            this.userIsLoggedIn = false;
        }
        return this.userIsLoggedIn;
    }


    // Call to cloud services to reestablish session
    // cookie param is optional as mobile appears to be properly passing SMSESSION cookie now
    // This was held over in code in case we need to switch back to accepting it in the querystring
    
    public bootstrapUser(cookie?: string) {
        let params = new HttpParams();
        let requestHeaders = {
            'gbd-token': 'gbdToken'
        };
        const isMobile = this.storage.getSessionItem<boolean>(SessionStorage.IS_MOBILE);
        if (isMobile) {
            params = params.append('isMobileRequest', `${isMobile}`);
        }

        // Removing code that would have allowed passing the SMSESSION cookie in by querystring - 12/03/2020
        // if (cookie) {
        //     requestHeaders['gbd-smsession'] = `SMSESSION=${cookie}`;
        // }

        return this.http.get('/sydmedservices/public/auth/bootstrap',
            {
                params,
                headers: requestHeaders,
                observe: 'response'
            }).toPromise();
    }
    
    public hasNoRouterRestriction(url) {
        const restrictions = sessionStorage.sydMedMemberContext ? JSON.parse(sessionStorage.sydMedMemberContext).restrictions : [];
        const currentUrlRestrictions = this.RouterDetails.filter((ele) => ele.url === url.split("?")[0]);
        if (restrictions && restrictions.length > 0 && currentUrlRestrictions && currentUrlRestrictions.length > 0) {
            return this.isCurrentRouteHasNoRestriction(restrictions, currentUrlRestrictions[0].restrictions);
        }
        else {
            return true;
        }
    }

    isCurrentRouteHasNoRestriction(marketRestriction, RouterUrlRestriction) {
        let hasNoRestriction = true;
        marketRestriction.forEach(restriction => {
            if (RouterUrlRestriction.includes(restriction)) {
                hasNoRestriction = false;
            }
        });
        return hasNoRestriction;
    }

}