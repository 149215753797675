import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForgotUsernamePasswordDataService {

  constructor() { }

  public getAslKey(aslStatus) { //*** TO DO  Move to common
    if(aslStatus && aslStatus.body && aslStatus.body.environment) {
      if (aslStatus.body.environment === "PROD") {
        return "6LfwCSgTAAAAADCi2TZkrHGDklbzmsIQfpLd9b27";
      } else {
        return "6LexxicTAAAAABviGYnU9DL86k5jCxuhlwO8IfUM";
      }  
    }
  }

  public is2FAFlowRequired(response) {
    let Require2FA = false;
    if(response && response.threatAssessment) {
          // TODO: This logic will need to be solidified once enterprise service bahavior is known
    Require2FA = ((response.threatAssessment.status.toUpperCase() === "AUTHENTICATE" ? true : false) || (response.threatAssessment.suggestedAction.toUpperCase() === "2NDFACTOR" ? true : false));
    }
    return Require2FA;
  }

  public transformPasswordParams(data) {
    var passwordParams = {
      "newPassword": data
    };
    return passwordParams;
  }

}


