
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DismissibleAlertLabel } from "../../interfaces/iAlerts";

@Component({
    selector: "dashboard-dismissable-alert",
    templateUrl: "./dashboard-dismissable-alert.component.html",
    styleUrls: ["./dashboard-dismissable-alert.component.css"],
})
export class DashboardDismissableAlertComponent {

    @Output() onCloseAlertClick: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onAlertUrlClick: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Input() labels : DismissibleAlertLabel;

    constructor() {}


    onUrlClick() {
        this.onAlertUrlClick.emit();
    }

    close() {
        this.onCloseAlertClick.emit();
    }
}