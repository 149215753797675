import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Subscription } from "rxjs";
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { ThirdPartyInsuranceContent } from '../interfaces/third-party-insurance-content';
import { ThirdPartyInsuranceService } from '../services/third-party-insurance.service';
import { ThirdPartyInsuranceModel } from './../models/third-party-insurance.model';
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";

@Component({
  selector: 'app-third-party-insurance',
  templateUrl: './third-party-insurance.component.html',
  styleUrls: ['./third-party-insurance.component.scss']
})
export class ThirdPartyInsuranceComponent implements OnInit {

  private subscriptions: Subscription[];
  public content: ThirdPartyInsuranceContent;
  public genericErr: string;
  public pageLoader: boolean = true;
  public showUrgentMsg: boolean = true;
  public displayTPIDetails: any;
  public isAPILoading: boolean;
  public tpiError: string;
  public tpiNoDataInfo: string;

  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private thirdPartyInsuranceService: ThirdPartyInsuranceService,
    private thirdPartyInsuranceModel: ThirdPartyInsuranceModel,
    private errorCodeMapperService: ErrorCodeMapperService
  ) {
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "Third Party Insurance"
    });
  }

  ngOnInit(): void {
    this.subscriptions = [];
    this.setContent();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
 * @description setContent() is responsible for fetching content from json
 * using content service
 * @returns {void}
 */
  private setContent(): void {
    this.jsonSvc.getJSON('third-party-insurance').subscribe(
      (content: ThirdPartyInsuranceContent) => {
        this.content = content;
        this.pageLoader = false;
        this.getThirdPartyInsuranceDetails();
      },
      (error: any) => {
        this.genericErr = 'We are unable to complete your request at this time. Please contact member services.';
        this.pageLoader = false;
      }
    );
  }

  // get TPI(Third Party Insurance) details

  private getThirdPartyInsuranceDetails(): void {
    this.isAPILoading = true;
    this.subscriptions.push(
      this.thirdPartyInsuranceService.getThirdPartyInsurance().subscribe((res: any) => {
        this.displayTPIDetails = this.thirdPartyInsuranceModel.buildTPIDetails(res?.body?.memberOhiDetails);

        if (!this.displayTPIDetails.length) {
          this.tpiNoDataInfo = this.content.errorMessages.noOtherInsuranceAvailable;
        }
        this.isAPILoading = false;
      }, (err: any) => {
        this.tpiError = this.errorCodeMapperService.errorCodeMapper(err, this.content.errorMessages);
        this.isAPILoading = false;
      })
    );
  }
}
