<div class="motif-panel-wrapper container">
    <uxd-expansion-panel #panel="uxdExpansionPanel" class="motif-panel" expanded="true">
        <uxd-expansion-header [attr.aria-label]="labels?.contactUsCallMemberServiceHeader" [attr.aria-expanded]="panel?.expanded ? 'true' : 'false'">
            <uxd-expansion-toggle>
                <span class="motif-icon" [id]="'button-icon'" [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
            </uxd-expansion-toggle>
            <span *ngIf="!showCarelonRxTopic" >{{ labels?.contactUsCallMemberServiceHeader }}</span>
            <span *ngIf="showCarelonRxTopic">{{labels?.contactUsCallMemberServiceHeaderAboutBenefits}}</span>
        </uxd-expansion-header>
        <div class="ant-content-wrapper" role="region">
            <p class="mb-2">{{ labels?.contactUsCallMemberServiceContentLine1 }}</p>
            <p class="pt-5">{{ labels?.contactUsCallMemberServiceContentLine2 }}</p>
            <p class="pt-5" *ngIf="labels?.contactUsCallMemberServiceContentLine3">{{ labels?.contactUsCallMemberServiceContentLine3 }}</p>
            <p>
                <span [innerHTML]="labels?.contactUsCallMemberServiceContentContactOpt1"></span>
                <a *ngIf="labels?.contactUsCallMemberServiceContentContactLine1" href="tel:{{ labels?.contactUsCallMemberServiceContentContactLine1 }}" data-analytics="callMemServicesContactUs">{{ labels?.contactUsCallMemberServiceContentContactLine1 }}</a>
                <span class="ml-2" *ngIf="labels?.contactUsCallMemberServiceContentContactLine2">{{ labels?.contactUsCallMemberServiceContentContactLine2 }}</span>
                <span [innerHTML]="labels?.contactUsCallMemberServiceContentContacSub1"></span>
            </p>
            <p *ngIf="labels?.contactUsCallMemberServiceContentContactLineOpt2">
                <span [innerHTML]="labels?.contactUsCallMemberServiceContentContactOpt2"></span>
                <a href="tel:{{ labels?.contactUsCallMemberServiceContentContactLineOpt2 }}" data-analytics="callMemServicesContactUs">{{ labels?.contactUsCallMemberServiceContentContactLineOpt2 }}</a>
                <span class="ml-2">{{ labels?.contactUsCallMemberServiceContentContactLine2 }}</span>
                <span [innerHTML]="labels?.contactUsCallMemberServiceContentContacSub2"></span>
            </p>
            <p *ngIf="labels?.contactUsCallMemberServiceContentContactLine3">
                <span [innerHTML]="labels?.contactUsCallMemberServiceContentContactLine3"></span>
                <a href="tel:{{ labels?.contactUsCallMemberServiceContentContactLine4 }}"
                    data-analytics="callMemServicesContactUs">{{ labels?.contactUsCallMemberServiceContentContactLine4 }}</a>
                <span *ngIf="labels?.contactUsCallMemberServiceContentContactLine4">{{labels?.contactUsCallMemberServiceContentContactLine2}}</span>
            </p>
            <p *ngIf="labels?.contactUsCallMemberServiceContentContactOpt3">
                <span [innerHTML]="labels?.contactUsCallMemberServiceContentContactOpt3"></span>
                <a href="tel:{{ labels?.contactUsCallMemberServiceContentContactLineOpt3 }}" data-analytics="callMemServicesContactUs">{{ labels?.contactUsCallMemberServiceContentContactLineOpt3 }}</a>
                <span class="ml-2">{{ labels?.contactUsCallMemberServiceContentContactLine2 }}</span>
            </p>
            <p *ngIf="labels?.contactUsCallMemberServiceContentContactOpt4">
                <span [innerHTML]="labels?.contactUsCallMemberServiceContentContactOpt4"></span>
                <a href="tel:{{ labels?.contactUsCallMemberServiceContentContactLineOpt4 }}" data-analytics="callMemServicesContactUs">{{ labels?.contactUsCallMemberServiceContentContactLineOpt4 }}</a>
                <span class="ml-2">{{ labels?.contactUsCallMemberServiceContentContactLine2 }}</span>
            </p>
        </div>
    </uxd-expansion-panel>
    <uxd-expansion-panel *ngIf="showCallBackSection" #panel1="uxdExpansionPanel" class="motif-panel" expanded="true">
        <uxd-expansion-header [attr.aria-label]="labels?.contactUsRequestCallbackServiceHeader" [attr.aria-expanded]="panel1?.expanded ? 'true' : 'false'">
            <uxd-expansion-toggle>
                <span class="motif-icon" [id]="'button-icon'" [ngClass]="panel1.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
            </uxd-expansion-toggle>
            <span>{{ labels?.contactUsRequestCallbackServiceHeader }}</span>
        </uxd-expansion-header>
        <div class="ant-content-wrapper" role="region">
            <p>{{ labels?.contactUsRequestCallbackServiceContentLine1 }}</p>
            <p><a href="javascript:void(0);" (click)="scheduleCallClickHandler()" data-analytics="scheduleCallContactUs">{{ labels?.contactUsRequestCallbackServiceContentLine2 }}</a></p>
            <p *ngIf="labels?.contactUs24HoursNurseHelplineContentLine5">{{ labels?.contactUs24HoursNurseHelplineContentLine5 }}{{ labels?.contactUs24HoursNurseHelplineContentLine6 }}</p>
            <span *ngIf="labels?.contactUsRequestCallbackServiceContentCallBackDays">{{labels?.contactUsRequestCallbackServiceContentCallBackDays}}</span>
        </div>
    </uxd-expansion-panel>
    <uxd-expansion-panel  *ngIf="showCarelonRxTopic" #panel="uxdExpansionPanel" class="motif-panel" expanded="true">
        <uxd-expansion-header [attr.aria-label]="labels?.contactUsCallCarelonRxHeading" [attr.aria-expanded]="panel?.expanded ? 'true' : 'false'">
            <uxd-expansion-toggle>
                <span class="motif-icon" [id]="'button-icon'" [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'" data-analytics="expandTalkPatientServicesContactUs"></span>
            </uxd-expansion-toggle>
            <span>{{ labels?.contactUsCallCarelonRxHeading }}</span>
        </uxd-expansion-header>
        <div class="ant-content-wrapper" role="region">
            <p class="pt-5">{{ labels?.contactUsCallCarelonRxContent }}</p>
            <p>
                <a href="tel:{{ labels?.contactUsCallCarelonRxContentContactLine1 }}" data-analytics="phoneCarelonRxServicesContactUs">{{ labels?.contactUsCallCarelonRxContentContactLine1 }}</a>
            </p>
            <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}" >
                <span class="carelon-Alert-Important">{{ labels?.contactUsCarelonRxAlertImportant }}</span>
                <span>{{ labels?.contactUsCarelonRxAlertLine1 }}</span>

                <a href="javascript:void(0)" (click)="onIdCardLinkClick()" data-analytics="idCardCarelonRxServicesContactUs">{{ labels?.idCard}}</a>
                <span>{{ labels?.contactUsCarelonRxAlertLine2 }}</span>
            </div>
        </div>
    </uxd-expansion-panel>
    <uxd-expansion-panel *ngIf="!showTechnicalSupport" #panel3="uxdExpansionPanel" class="motif-panel" expanded="true">
        <uxd-expansion-header [attr.aria-label]="labels?.contactUsTechnicalSupportHeader" [attr.aria-expanded]="panel3?.expanded ? 'true' : 'false'">
            <uxd-expansion-toggle>
                <span class="motif-icon" [id]="'button-icon'" [ngClass]="panel3.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
            </uxd-expansion-toggle>
            <span>{{ labels?.contactUsTechnicalSupportHeader }}</span>
        </uxd-expansion-header>
        <div class="ant-content-wrapper" role="region">
            <p>{{ labels?.contactUsTechnicalSupportLine1 }}</p>
            <article *ngIf="(labels?.contactUsTechnicalSupportLine2)" class="mb-10">
                <a href="tel:{{labels?.contactUsTechnicalSupportLine2}}">{{ labels?.contactUsTechnicalSupportLine2 }}</a>
            </article>
            <p>{{ labels?.contactUsTechnicalSupportLine3 }}</p>
        </div>
    </uxd-expansion-panel>
    <uxd-expansion-panel *ngIf="showNurseHelpLine" #panel2="uxdExpansionPanel" class="motif-panel" expanded="true">
        <uxd-expansion-header [attr.aria-label]="labels?.contactUs24HoursNurseHelplineHeader" [attr.aria-expanded]="panel2?.expanded ? 'true' : 'false'">
            <uxd-expansion-toggle>
                <span class="motif-icon" [id]="'button-icon'" [ngClass]="panel2.expanded ? 'motif-angle-up' : 'motif-angle-down'"></span>
            </uxd-expansion-toggle>
            <span>{{ labels?.contactUs24HoursNurseHelplineHeader }}</span>
        </uxd-expansion-header>
        <div class="ant-content-wrapper" role="region">
            <p>{{ labels?.contactUs24HoursNurseHelplineContentLine1 }}</p>
            <article *ngIf="(labels?.contactUs24HoursNurseHelplineContentLine2 && labels?.contactUs24HoursNurseHelplineContentLineOpt1)" class="mb-10">
                <p>{{ labels?.contactUs24HoursNurseHelplineContentLine2 }}</p>
                <span [innerHTML]="labels?.contactUs24HoursNurseHelplineContentLineOpt1"></span>
                <a href="tel:{{labels?.contactUs24HoursNurseHelplineContentLine3}}" data-analytics="nurseHelpContactUs">{{ labels?.contactUs24HoursNurseHelplineContentLine3 }}</a>
                <span class="ml-2">{{ labels?.contactUs24HoursNurseHelplineContentLine4 }}</span>
            </article>
            <article *ngIf="!(labels?.contactUsCallMemberServiceContentContactOpt2 && labels?.contactUsCallMemberServiceContentContactOpt3) && (labels?.contactUs24HoursNurseHelplineContentLine2 && !labels?.contactUs24HoursNurseHelplineContentLineOpt1)" class="mb-10">
                <span [innerHTML]="labels?.contactUs24HoursNurseHelplineContentLine2"></span>
                <a href="tel:{{labels?.contactUs24HoursNurseHelplineContentLine3}}" data-analytics="nurseHelpContactUs">{{ labels?.contactUs24HoursNurseHelplineContentLine3 }}</a>
                <span class="ml-2">{{ labels?.contactUs24HoursNurseHelplineContentLine4 }}</span>
            </article>
            <article *ngIf="(!labels?.contactUs24HoursNurseHelplineContentLine2 && labels?.contactUs24HoursNurseHelplineContentLine3)" class="mb-10">
                <a href="tel:{{labels?.contactUs24HoursNurseHelplineContentLine3}}" data-analytics="nurseHelpContactUs">{{ labels?.contactUs24HoursNurseHelplineContentLine3 }}</a>
                <span class="ml-2">{{ labels?.contactUs24HoursNurseHelplineContentLine4 }}</span>
            </article>
            
        <article
         *ngIf="(labels?.contactUs24HoursNurseHelplineContentLine4 && labels?.contactUs24HoursNurseHelplineContentLine7)"
         class="mb-10">
         <span [innerHTML]="labels?.contactUsCallMemberServiceContentContactLine3"></span>
        <a href="tel:{{ labels?.contactUsCallMemberServiceContentContactLine4 }}"
        data-analytics="callMemServicesContactUs">{{ labels?.contactUsCallMemberServiceContentContactLine4 }}</a>
        <span class="ml-2">{{ labels?.contactUs24HoursNurseHelplineContentLine7 }}</span>
        </article>
            <article *ngIf="(labels?.contactUs24HoursNurseHelplineContentLineAlt2)" class="mb-10">
                <span [innerHTML]="labels?.contactUs24HoursNurseHelplineContentLineAlt2"></span>
                <a href="tel:{{labels?.contactUs24HoursNurseHelplineContentLineAlt3}}" data-analytics="nurseHelpContactUs">{{ labels?.contactUs24HoursNurseHelplineContentLineAlt3 }}</a>
                <span class="ml-2">{{ labels?.contactUs24HoursNurseHelplineContentLineAlt4 }}</span>
            </article>

            <article *ngIf="labels?.contactUsCallMemberServiceContentContactOpt2 && labels?.contactUsCallMemberServiceContentContactOpt3">
                <p [innerHTML]="labels?.contactUs24HoursNurseHelplineContentLine2"></p>
                <p>
                    {{ labels?.contactUsCallMemberServiceContentContactOpt1 }}
                    <a href="tel:{{labels?.contactUs24HoursNurseHelplineContentLine3}}" data-analytics="nurseHelpContactUs">{{ labels?.contactUs24HoursNurseHelplineContentLine3 }}</a> 
                    {{ labels?.contactUsCallMemberServiceContentContactLine2 }} {{ labels?.contactUs24HoursNurseHelplineContentLine5 }}
                </p>
                <p>
                     {{ labels?.contactUsCallMemberServiceContentContactOpt2 }} 
                    {{labels?.contactUsCallMemberServiceContentContactLine3}}
                    <a href="tel:{{labels?.contactUs24HoursNurseHelplineContentLine3}}" data-analytics="nurseHelpContactUs">{{ labels?.contactUs24HoursNurseHelplineContentLine3 }} </a> 
                    {{ labels?.contactUsCallMemberServiceContentContactLine2 }} {{ labels?.contactUs24HoursNurseHelplineContentLine5 }}
                </p>
                <p>
                    {{ labels?.contactUsCallMemberServiceContentContactOpt3 }} 
                    <a href="tel:{{labels?.contactUsCallMemberServiceContentContactLineOpt3}}" data-analytics="nurseHelpContactUs">{{labels?.contactUsCallMemberServiceContentContactLineOpt3}}</a> 
                    {{ labels?.contactUsCallMemberServiceContentContactLine2 }} {{ labels?.contactUs24HoursNurseHelplineContentLine5 }}
                </p>
                <p *ngIf="labels?.contactUsCallMemberServiceContentContactOpt4">
                    {{ labels?.contactUsCallMemberServiceContentContactOpt4 }} 
                    <a href="tel:{{labels?.contactUsCallMemberServiceContentContactLineOpt4}}" data-analytics="nurseHelpContactUs">{{labels?.contactUsCallMemberServiceContentContactLineOpt4}}</a> 
                    {{ labels?.contactUsCallMemberServiceContentContactLine2 }} {{ labels?.contactUs24HoursNurseHelplineContentLine5 }}
                </p>
                <span [ngClass]="labels?.contactUsCallMemberServiceContentContactOpt2 && labels?.contactUsCallMemberServiceContentContactOpt3 ? '' : 'ml-2'">{{ labels?.contactUs24HoursNurseHelplineContentLine4 }}</span>
            </article>
        </div>
    </uxd-expansion-panel>
</div>