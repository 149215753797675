import { AlertModule } from "@anthem/uxd/alert";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from "@angular/common";
import { CommonPipesModule } from 'sydmed/libs/common-pipes/src/lib/common-pipes.module';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { EmailValidatorDirective } from './directives/emailValidatorDirective';
import { FormGroupModule } from '@anthem/uxd/form-group';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { IdCardRoutingModule } from "./idCardRoutingModule";
import { IdcardsContainerComponent } from "./components/idCardsContainer.component";
import { IdCardService } from './services/idCardSvc';
import { InputModule } from '@anthem/uxd/input';
import { ModalModule } from '@anthem/uxd/modal';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { UxDeprecatedModule } from '@anthem/uxd/deprecated';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { PageGeneratorService, WindowModel } from '@anthem/mbrportal/shared/public_api';

@NgModule({
  declarations: [IdcardsContainerComponent, EmailValidatorDirective],
  imports: [AlertModule, BrowserAnimationsModule, CommonModule, CommonPipesModule, DropdownModule, FormGroupModule, FormsModule, HttpClientModule, IdCardRoutingModule, IndicatorModule, InputModule, ModalModule, ReactiveFormsModule, UxDeprecatedModule, CustomValidatorsModule
  ],
  exports: [IdcardsContainerComponent, EmailValidatorDirective],
  providers: [
    PageGeneratorService,
    WindowModel
  ],
  bootstrap: [IdcardsContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IdCardsModule {}
