import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
import { Restriction, States } from 'gbd-models';

@Injectable()
export class JsonContentService {
    public jsonContent = {};
    public locale = 'en-US';
    public market = '';
    private assetBlacklist = ['theme'];
    constructor(private http: HttpClient, private httpClient: HttpClientService, private localeService: LocaleService) {
    }

    public async getJSONv2(moduleName: string, locale?: string, forceReload?: boolean): Promise<any> {
        let market = this.getMarket();
        let defaultJson = '';
        let overrideJson = '';
        let content = '';
        let override: boolean = true;

        if (this.jsonContent[moduleName] && market === this.market && !forceReload) {
            return of(this.jsonContent[moduleName]);
        } else {
            this.market = market;
            defaultJson = await this.httpClient.getObservable(`/sydmedservices/public/content/Portal/${locale}/${moduleName}`).toPromise();
    if (market) {
        overrideJson = await this.httpClient.getObservable(`/sydmedservices/public/content/Portal/${locale}/${moduleName}_${market}`).toPromise().catch(error => {
            override = false;
        });
    }
    if (override) {
        content = this.mergeDeep( defaultJson['body'], overrideJson['body']);
        this.jsonContent[moduleName] = content;
    } else {
        content = defaultJson['body'];
        this.jsonContent[moduleName] = content;
    }
        return content;
    }
}

    /* Experimental dynamic content service */
    public getJSON(moduleName: string, locale?: string, forceReload?: boolean): Observable<any> {
        let defaultJson = '';
        let overrideJson = '';
        let plancodeOverrideJsonUrl = '';
        let defaultContentResponse: Observable<any>;
        let marketOverrideContentResponse: Observable<any>;
        let plancodeContentOverrideResponse: Observable<any>;
        let market = this.getMarket();
        let planCode = this.getPlanCode();

        let localeCheck = locale ? locale === this.locale : true;
        if (this.jsonContent[moduleName] && market === this.market && localeCheck && !forceReload) {
            return of(this.jsonContent[moduleName]);
        } else {
            this.market = market;
            let localeOverride = this.localeService.getLocaleOverride();
            if (localeOverride && this.hasRestriction(Restriction.SHM_LOCALE_SPANISH, this.getRestrictions())) {
                this.localeService.removeLocaleOverride();
                localeOverride = '';
            }
            if(localeOverride &&
                !this.assetBlacklist.includes(moduleName) || 
                (locale && locale !== 'en-US')) {
                if (locale !== 'en-US' && !localeOverride) {
                    localeOverride = locale;
                }
                defaultJson = `../assets/data/${localeOverride}/${moduleName}.json`;
                overrideJson = `../assets/data/${localeOverride}/${moduleName}_${market}.json`;
                plancodeOverrideJsonUrl = `../assets/data/${localeOverride}/${moduleName}_${planCode}.json`;
            } else {
                defaultJson = `../assets/data/${moduleName}.json`;
                
                if(market) {
                    overrideJson = `../assets/data/${moduleName}_${market}.json`;
                }
                if(planCode) {
                    plancodeOverrideJsonUrl = `../assets/data/${moduleName}_${planCode}.json`;
                }
            }

            const MISSING_OVERRIDE = 'Missing override';
            const MISSING_DEFAULT = 'Missing default';
            defaultContentResponse = this.http.get(defaultJson).pipe(
                catchError(error => of(MISSING_DEFAULT))
            );
            marketOverrideContentResponse = this.http.get(overrideJson).pipe(
                catchError(error => of(MISSING_OVERRIDE))
            );
            plancodeContentOverrideResponse = this.http.get(plancodeOverrideJsonUrl).pipe(
                catchError(error => of(MISSING_OVERRIDE))
            );

            return forkJoin([defaultContentResponse, marketOverrideContentResponse, plancodeContentOverrideResponse]).pipe(map((data: object) => {
                const [defaultContent, marketOverride, plancodeOverride] = [data[0], data[1], data[2]];
                if(defaultContent === MISSING_DEFAULT) { 
                    throw new Error(MISSING_DEFAULT);
                }
                
                if (marketOverride === MISSING_OVERRIDE && plancodeOverride === MISSING_OVERRIDE) {
                    return this.jsonContent[moduleName] = this.addRestrictions(defaultContent);                   
                }

                if (plancodeOverride === MISSING_OVERRIDE) {
                    return this.jsonContent[moduleName] = this.addRestrictions(this.mergeDeep(defaultContent, marketOverride));
                }
                const overrideContent = this.mergeDeep(marketOverride, plancodeOverride);
                return this.jsonContent[moduleName] = this.addRestrictions(this.mergeDeep(defaultContent, overrideContent));
                
            }),
            catchError(error => {
                console.log(error);
                return of(error);
            }));
        }        
    }
    /* function to do deep copy of objects to override default content with market specific content */
    public mergeDeep(...objects) {
        const isObject = obj => obj && typeof obj === 'object';
        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key];
                const oVal = obj[key];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    //TODO figure out a better way to handle Arrays
                    prev[key] = oVal;
                }
                else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = this.mergeDeep(pVal, oVal);
                }
                else {
                    prev[key] = oVal;
                }
            });
            return prev;
        }, {});
    }

    protected addRestrictions(content: object) {
        return {...content, restrictions: this.getRestrictions() };
    }

    public getPlanCode() {
        return sessionStorage.sydMedMemberContext && JSON.parse(sessionStorage.sydMedMemberContext)?.planCode;
    }

    /* function to get market TODO update logic based on member context logic in future */
    public getMarket(): string {
        let market = sessionStorage.sydMedMemberContext ? JSON.parse(sessionStorage.sydMedMemberContext).stateLob : this.getHostMarket();
        let planCode = sessionStorage.sydMedMemberContext && JSON.parse(sessionStorage.sydMedMemberContext)?.planCode;
        let marketsByBrand = ['FLMCD', 'MNMMP', 'MNMCD'];
        if (marketsByBrand.includes(market)) {
            market = JSON.parse(sessionStorage.sydMedMemberContext)?.marketingBrand;
        }
        else if(planCode === "NYMCD_MLTC") {
            market = "NYINT"
        }
        else if(planCode === "WNYMCD_WESTERN_NEW_YORK" || planCode === "WNYMCD_WESTERN_NEW_YORK_CHIP") {
            market = "WNY"
        }
        return market;
    }

    public getRestrictions() {
        return sessionStorage.sydMedMemberContext ? JSON.parse(sessionStorage.sydMedMemberContext).restrictions : [];
    }

    public hasRestriction(restriction: string, restrictions: string[]) {
        return restrictions?.length && restrictions?.includes(restriction);
    }

    public getHostMarket() {
        let market = '';
        const markets = {
            'healthybluene': 'NEMCD',
            'healthybluemo': 'MOMCD',
            'healthybluela': 'LAMCD',
            'healthybluesc': 'SCMCD',
            'healthybluenc': 'NCMCD',
            'anthem.com': 'Anthem',
            'amerigroup.com': 'Amerigroup',
            'empireblue.com': 'Anthem',
            'wellpoint.com': 'Wellpoint',
            'simplyhealthcareplans': 'SMPLY',
            'clearhealthalliance': 'CLEAR',
            'mydellchildrens': 'TXSET',
            'summitcommunitycare': 'ARMCD',
            'unicare': 'WVMCD',
            'mybcbswny': 'WNY',
            'publicprograms': 'BCBSMN',
        }
        for (let i in markets) {
            if (window.location.href.toLowerCase().indexOf(i) >= 0) {
                market = markets[i];
              }
        }
        return market;
    }

    public clearContents(): void {
        this.jsonContent = {};
        this.locale = '';
        this.market = '';
    }
}