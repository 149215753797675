import { Injectable } from '@angular/core';
import { PaymentMethodsService } from 'sydmed/libs/pharmacy-components/payment-methods/services/payment-methods.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { PbmAccount } from '../interfaces/PbmAccount';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { PbmPaymentMethod } from '../interfaces/PbmPaymentMethod';
import { LinesOfBusiness } from '../enums/LinesOfBusiness';
import { AddCardRequestObject } from '../interfaces/AddCardRequestObject';
import { SpecialtyAccount, SpecialtyApiResponse } from '../interfaces/SpecialtyAccount';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { SpecialtyPaymentMethod } from '../interfaces/SpecialtyPaymentMethod';
import { PaymentMethodHelper } from './payment-method-helper.service';
import { MockDataService } from 'sydmed/src/app/secure/pharmacy/services/mock-data.service';
import { PharmacyApiService } from './pharmacy-api.service';

@Injectable( {
  providedIn: 'root'
} )
export class PaymentMethodDataService {
  protected pbmAccount$: BehaviorSubject<PbmAccount> = new BehaviorSubject<PbmAccount>( null );
  public pbmAccount = this.pbmAccount$.asObservable();
  protected specialtyAccount$: BehaviorSubject<SpecialtyAccount> = new BehaviorSubject<SpecialtyAccount>( null );
  public specialAccount = this.specialtyAccount$.asObservable();
  protected pbmPaymentMethods$: BehaviorSubject<PaymentMethod[]> = new BehaviorSubject<PaymentMethod[]>( null );
  public pbmPaymentMethods = this.pbmPaymentMethods$.asObservable();
  protected specialtyPaymentMethods$: BehaviorSubject<PaymentMethod[]> = new BehaviorSubject<PaymentMethod[]>( null );
  public specialtyPaymentMethods = this.specialtyPaymentMethods$.asObservable();
  protected selectedPbmMethod$: BehaviorSubject<PaymentMethod> = new BehaviorSubject<PaymentMethod>( null );
  public selectedPbmMethod = this.selectedPbmMethod$.asObservable();
  protected selectedSpecialtyMethod$: BehaviorSubject<PaymentMethod> = new BehaviorSubject<PaymentMethod>( null );
  public selectedSpecialtyMethod = this.selectedSpecialtyMethod$.asObservable();
  protected apiEndPoints = UrlProperties.UrlProperties.endPoints.paymentMethods;
  protected paymentMethod: PaymentMethod;

  constructor(
    protected api: PharmacyApiService,
    protected paymentsApi: PaymentMethodsService,
    protected helperSvc: PaymentMethodHelper,
    protected mockApi: MockDataService ) { }

  public getPaymentMethods( isSpecialty: boolean = false ) {
    if ( isSpecialty ) {
      return this.getSpecialtyPaymentMethods();
    }
    return this.getPbmPaymentMethods();
  }

  public addNewPaymentMethod( paymentMethod: PaymentMethod, lineOfBusiness: LinesOfBusiness ) {
    ( lineOfBusiness === LinesOfBusiness.PBM ) ? this.setNewSelectedPBM( paymentMethod ) : this.setNewSpecialty( paymentMethod );
  }

  public addCardApiCall( card: AddCardRequestObject, lineOfBusiness: LinesOfBusiness ) { // TODO
    return this.paymentsApi.addCard( lineOfBusiness, card ).pipe( mergeMap( res => {
      const isSpecialty = lineOfBusiness === LinesOfBusiness.SPECIALTY;
      return this.getPaymentMethods( isSpecialty );
    } ), catchError( error => throwError( error ) ) );
  }

  public setPaymentMethods( paymentMethods: PaymentMethod[], isSpecialty: boolean = false ) {
    if ( isSpecialty ) {
      this.specialtyPaymentMethods$.next( paymentMethods );
    } else {
      this.pbmPaymentMethods$.next( paymentMethods );
    }
  }


  // ==== PBM METHODS ========
  protected getPbmPaymentMethods() {
    return this.getPbmAccount().pipe( map( ( pbmAccount: PbmAccount ) => {
      this.setPbmAccount( pbmAccount );
      const contractUid = pbmAccount.planLevelBalance[ 0 ].contractUid;
      const accountBalance = pbmAccount.planLevelBalance[ 0 ].accountBalance;
      const paymentMethods = this.mapPbm( pbmAccount.account, accountBalance, contractUid );
      this.setPaymentMethods( paymentMethods );
      if ( !this.selectedPbmMethod$.getValue() ) {
        this.setSelectedPaymentMethod( this.findPreferredPaymentMethod( paymentMethods ), false );
      }
      return paymentMethods;
    } ), catchError( error => throwError( error ) ) );
  }
  protected getSpecialtyPaymentMethods() {
    return this.getSpecialtyAccount().pipe( map( ( specialtyAccount: SpecialtyAccount ) => {
      if ( specialtyAccount && specialtyAccount.paymentMethods ) {
        const paymentMethods = this.mapSpecialty( specialtyAccount.paymentMethods );
        if ( !this.selectedSpecialtyMethod$.getValue() ) {
          this.setSelectedPaymentMethod( this.findPreferredPaymentMethod( paymentMethods ), true );
        }
        this.setSpecialtyPaymentMethods( paymentMethods );
        return paymentMethods;
      } else {
        return [];
      }
    } ), catchError( error => throwError( error ) ) );
  }
  setSpecialtyPaymentMethods( paymentMethods: PaymentMethod[] ) {
    this.specialtyPaymentMethods$.next( paymentMethods );
  }

  protected getPbmAccount() {
    return this.apiCall().pipe(
      // return this.getMockPbmData().pipe(
      map( ( res: PbmAccount ) => {
        return res;
      } ),
      catchError( error => throwError( error ) )
    );
  }

  public getSpecialtyAccount() {
    return this.apiCall( true ).pipe(
      map( ( res: SpecialtyAccount ) => {
        this.setSpecialtyAccount( res );
        return res;
      } ),
      catchError( error => throwError( error ) )
    );
  }
  public setSpecialtyAccount( account: SpecialtyAccount ) {
    this.specialtyAccount$.next( account );
  }

  protected setNewSelectedPBM( newPaymentMethod: PaymentMethod ) {
    const pbmAccount = this.pbmAccount$.getValue();
    if ( pbmAccount ) {
      const paymentMethods = this.appendPbmAccount( pbmAccount, newPaymentMethod );
      this.setPaymentMethods( paymentMethods );
      this.setSelectedPaymentMethod( newPaymentMethod, false );
    } else {
      this.setSelectedPaymentMethod( newPaymentMethod, false );
      this.setPaymentMethods( [ newPaymentMethod ] );
    }
  }

  // ==== SPECIALTY METHODS ==========
  appendSpecialAccount( specialAccount: SpecialtyAccount, newPaymentMethod: PaymentMethod ) {
    const paymentMethods = this.pbmPaymentMethods$.getValue();
    const newPaymentMethods: PaymentMethod[] = [ ...paymentMethods, newPaymentMethod ];
    const newAccount: SpecialtyAccount = {
      paymentMethods: newPaymentMethods,
      ...specialAccount
    };
    this.setSpecialtyAccount( newAccount );
    return newPaymentMethods;
  }

  setNewSpecialty( newPaymentMethod: PaymentMethod ) {
    const isSpecialty = true;
    const specialAccount: SpecialtyAccount = this.specialtyAccount$.getValue();
    if ( specialAccount ) {
      const updatePaymentMethods = this.appendSpecialAccount( specialAccount, newPaymentMethod );
      this.setSelectedPaymentMethod( newPaymentMethod, isSpecialty );
      this.setPaymentMethods( updatePaymentMethods, isSpecialty );
    } else {
      this.setSelectedPaymentMethod( newPaymentMethod, isSpecialty );
    }

  }

  appendPbmAccount( pbmAccount: PbmAccount, newPaymentMethod: PaymentMethod ) {
    const paymentMethods = this.pbmPaymentMethods$.getValue();
    const newPaymentMethods: PaymentMethod[] = [ ...paymentMethods, newPaymentMethod ];
    const newAccount: PbmAccount = {
      account: newPaymentMethods,
      ...pbmAccount
    };
    this.pbmAccount$.next( newAccount );
    return newPaymentMethods;
  }


  protected apiCall( isSpecialty: boolean = false ) {
    if ( isSpecialty ) {
      return this.api.getSpecialtyAccounts().pipe(
        map( ( res: SpecialtyApiResponse ) => {
          return res.patients[ 0 ];
        } ),
        catchError( error => throwError( 'Accounts API Failed: ', error ) )
      );
    }
    return this.api.getAccounts().pipe(
      map( res => {
        return res;
      } ),
      catchError( error => throwError( 'Accounts API Failed: ', error ) )
    );
  }

  protected setPbmAccount( pbmAccount: PbmAccount ) {
    this.pbmAccount$.next( pbmAccount );
  }


  public setSelectedPaymentMethod( paymentMethod: PaymentMethod, isSpecialty: boolean ) {
    if ( isSpecialty ) {
      this.selectedSpecialtyMethod$.next( paymentMethod );
    } else {
      this.selectedPbmMethod$.next( paymentMethod );
    }
  }

  protected findPreferredPaymentMethod( paymentMethods: PaymentMethod[] ) {
    return paymentMethods.filter(
      ( paymentMethod: PaymentMethod ) => paymentMethod.preferredAccount === true )[ 0 ];
  }


  protected mapPbm( pbmPaymentMethods: PbmPaymentMethod[], balance: string, contractUid: string): PaymentMethod[] {
    return pbmPaymentMethods.map( ( pbm: PbmPaymentMethod ) => {
      const { electronicPaymentAccountType: accountType } = pbm;
      const isExpired = pbm.expirationDate ? this.helperSvc.isExpired( pbm.expirationDate ) : null;
      // const expirationDate = pbm.expirationDate ? this.helperSvc.formatDate( pbm.expirationDate ) : '';
      const accountBalance = parseFloat(balance);
      const paymentMethod: PaymentMethod = {
        ...pbm,
        accountBalance,
        contractUid,
        expirationDate: pbm.expirationDate,
        accountType,
        isExpired,
      };
      return paymentMethod;
    } );
  }

  protected mapSpecialty( specialtyPaymentMethods: SpecialtyPaymentMethod[] ): PaymentMethod[] {
    if ( specialtyPaymentMethods ) {
      return specialtyPaymentMethods.map( ( specialty: SpecialtyPaymentMethod ) => {
        const { accountNumber, payment, primaryMethod } = specialty;
        const isExpired = specialty.expiredIndicator ? specialty.expiredIndicator : null;
        const lastFour = specialty.accountNumber.slice( accountNumber.length - 4 );
        const divider = specialty.payment.method === 'CHECK' ? 'ING-' : '-';
        const expiration = specialty.payment.method === 'CCARD' ?
          this.helperSvc.formatMonth( specialty.expiryDate.month ) + '/' + specialty.expiryDate.year : null;
        const paymentMethod: PaymentMethod = {
          accountNumber,
          accountName: payment.mopType ? payment.mopType + divider + lastFour : payment.method + divider + lastFour,
          accountType: payment.method === 'CCARD' ? 'CC' : 'ECP',
          preferredAccount: primaryMethod,
          expirationDate: expiration,
          isExpired,
          ...specialty
        };
        return paymentMethod;
      } );
    } else {
      return [];
    }
  }

  // TODO: Possible to type the payload since it's two different?
  public reloadPaymentList( lineOfBusiness: string, payload: any, isCreditCard: boolean ) {
    const isSpecialty = lineOfBusiness === LinesOfBusiness.SPECIALTY;
    const lob = lineOfBusiness === LinesOfBusiness.SPECIALTY ? LinesOfBusiness.SPECIALTY : LinesOfBusiness.PBM;
    let accountNumber: string;
    let month: string;
    let year: string;
    let expiration: string;

    this.getPaymentMethods( isSpecialty ).subscribe(
      ( paymentMethods: PaymentMethod[] ) => {
        if ( paymentMethods && paymentMethods.length > 0 ) {
          this.setPaymentMethods( paymentMethods, isSpecialty );
          if ( !isSpecialty ) {
            accountNumber = isCreditCard ? payload.creditCardNumber : payload.bankAccountNumber;
            month = isCreditCard ? payload.creditCardExpiryMonth : null;
            year = isCreditCard ? payload.creditCardExpiryYear : null;
          } else {
            const paymentDetails = payload.paymentMethodDetails;
            accountNumber = paymentDetails.accountNumber;
            month = isCreditCard ? paymentDetails.expiryDate.month : null;
            year = isCreditCard ? paymentDetails.expiryDate.year : null;
          }
          expiration = isCreditCard ? this.helperSvc.formatDate( month + '/' + year ) : null;

          if ( accountNumber ) {
            accountNumber = accountNumber.slice( accountNumber.length - 4 );

            const newPaymentMethod = paymentMethods.filter( payment => {
              const payAccountNum = payment.accountName.slice( payment.accountName.length - 4 );
              const payExpiration: string = payment.expirationDate ? this.helperSvc.formatDate( payment.expirationDate ) : null;

              if ( isCreditCard ) {
                if ( payAccountNum === accountNumber && payExpiration === expiration ) {
                  return payment;
                }
              } else {
                if ( payAccountNum === accountNumber ) {
                  return payment;
                }
              }
            } );
            if ( newPaymentMethod && newPaymentMethod.length > 0 ) {
              this.setSelectedPaymentMethod( newPaymentMethod[ 0 ], isSpecialty );
            }
          }
        }
      }, error => throwError( error ) );
  }

  public getMockPbmData() {
    return this.mockApi.getMockPbmPaymentMethods();
  }

}
