import { Injectable } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { BehaviorSubject, pipe, throwError } from 'rxjs';
import { DesigneeDetails } from 'gbd-models'
import * as AHDPaymentock from "../assets/data/mock/ahd-payment";
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BankRoutingNumberRequest, DeleteCardOrAccountRequest, PaymentWalletDetails, PaymentWalletResponse } from '@anthem/mbrportal-pharmacy/interfaces/vendorPaymentWallet';
import { DeliveryAddress, PaymentWalletDTO } from '@sydney/models';


const { ahdPayment, validateAddress } = UrlProperties.UrlProperties.endPoints.ahdPharmacy;
export enum Actions {
  add = 'ADD',
  delete = 'DEL',
  update = 'UPD'
}

@Injectable({
  providedIn: 'root',
})
export class AHDPaymentService {
  constructor(private httpSvc: HttpClientService) { }
  paymentMock = AHDPaymentock.paymentWallet;

  
  /**
   * Retrieves the payment details.
   * @returns A promise that resolves to an object containing the payment details.
   * @throws An error with the HTTP status code if the request fails.
   */
  public getPaymentDetails() {
    return this.httpSvc.getObservable(ahdPayment)
      .pipe(
        map((res: HttpResponse<PaymentWalletDetails>) => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error))
      ).toPromise();
  }


  /**
 Represents updateCardPaymentDetails function.
 @description This function gives you details of updateCardPaymentDetails.
*/
  updateCardPaymentDetails(card: PaymentWalletDTO) {
    return this.httpSvc.postObservable(ahdPayment+ '?action=' + Actions.update, card)
      .pipe(
        map((res: HttpResponse<PaymentWalletResponse>) => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      ).toPromise();
  }

  /**
   * Adds card payment details.
   * @param card - The payment wallet DTO containing card details.
   * @returns A promise that resolves to the response data.
   */
  addCardPaymentDetails(card: PaymentWalletDTO) {
    return this.httpSvc.postObservable(ahdPayment+ '?action=' + Actions.add, card)
      .pipe(
        map((res: HttpResponse<PaymentWalletResponse>) => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      ).toPromise();
  }

  /**
   * Validates the given delivery address.
   * @param address The delivery address to be validated.
   * @returns A promise that resolves to an object containing the validated address data.
   * @throws An error with the HTTP status code if the validation fails.
   */
  validateAddress(address: DeliveryAddress) {
    return this.httpSvc.postObservable(validateAddress, address)
      .pipe(
        map((res: HttpResponse<PaymentWalletResponse>) => {
          return { data: res.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      ).toPromise();
  }

  /**
  Represents deleteCardOrBankAccountDetails function.
  @description This function delete detail of card or bank account.
*/
  deleteCardOrBankAccountDetails(data: DeleteCardOrAccountRequest) {
    return this.httpSvc.deleteObservable(ahdPayment, data)
      .pipe(
        map((res: HttpResponse<PaymentWalletResponse>) => {
          return { data: res }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      ).toPromise();
  }

//TODO : This validateRoutingNumber method need to be integrated once node service is available.
  validateRoutingNumber(data: BankRoutingNumberRequest) {
    const responseBody = {
      memberPayException: {
        error: ''
      },
      routingNoDetails: {
        customerName: ' '
      },
      valid: true
    }
      return  { data : responseBody} ;
  }


}
