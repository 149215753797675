import { Inject, Injectable } from '@angular/core';
import { BaseApi2, HttpClient2, HttpMethod, IHttpResponse, IUrlParam } from '@anthem/mbrportal/http';
import { AppConfig, AppSession } from '@anthem/mbrportal/main';
import { CookieHelper } from '@anthem/mbrportal/utility';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { ssoTypes } from './sso.types.enum';
/**
 *  Supports wrapping sso API(s)
 */

@Injectable({
    providedIn: 'root'
  })
  export class SsoService {
    constructor(private httpSvc: HttpClientService) { }

  getSSOData( ssoId: string) {
    let url: string;
    const nodeSSOTypes: string[] = Object.values(ssoTypes);
    if(nodeSSOTypes.includes(ssoId)) {
      url = UrlProperties.UrlProperties.endPoints.sso.ssoNodeBase + ssoId;
    } else {
      url = UrlProperties.UrlProperties.endPoints.sso.ssoBase + ssoId;
    }
    return this.httpSvc.getObservable(url);
  }

  getSamlInfo(url:string, data: any) {
    return this.httpSvc.getObservable(url, data);
  }
}