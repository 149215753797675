import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Prescription } from 'gbd-models';
import { SearchService } from '../search/search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-prescription-details',
  templateUrl: './prescription-details.component.html',
  styleUrls: [ './prescription-details.component.scss' ]
})
export class PrescriptionDetailsComponent implements OnInit, OnDestroy {

  constructor( protected searchService: SearchService ) { }

  // TODO: make search filter (text-highlight) CSS class configurable
  @Input() prescriptionDetails: Prescription;
  @Input() labels;
  @Input() searchTerm: string;
  prescriberName: string;
  private sub: Subscription = new Subscription();

  ngOnInit(): void {

    this.sub.add(this.searchService.searchTerm.subscribe(
      (searchTerm: string) => {
        this.searchTerm = searchTerm;
      }));

    this.prescriberName = this.prescriptionDetails.prescriber.firstName + ' ' + this.prescriptionDetails.prescriber.lastName;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
