import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { TextTermsLabels } from './interfaces/labels.interface';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';

@Component({
  selector: 'app-text-terms-and-conditions',
  templateUrl: './text-terms-and-conditions.component.html',
  styleUrls: ['./text-terms-and-conditions.component.scss']
})
export class TextTermsAndConditionsComponent implements OnInit {

  constructor(
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private winloc: Location
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Pharmacy text messaging program'
    });
  }

  public labels: TextTermsLabels;
  public errorMsg: string;
  public loading: boolean;
  public error: boolean;

  ngOnInit(): void {
    this.loading = true;
    this.error = false;
    this.jsonSvc.getJSON('text-terms').subscribe(
      (res: any) => {
        this.labels = res.Labels;
        this.loading = false;
      },
      (err: any) => {
        this.loading = false;
        this.error = true;
        this.errorMsg =
          'We are unable to complete your request at this time. Please try again later or <a href="#/secure/contact-us/call-us">contact us</a> for help.';
      }
    );
  }

  public goBack() {
    this.winloc.back();
  }

}
