import { Injectable, EventEmitter} from "@angular/core";
import * as Titles from "./titles";
import { LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InterCommunicationService {

    data: any;
    private $description : BehaviorSubject<string> = new BehaviorSubject<string>('');
    public description = this.$description.asObservable();
    receivedFilter: EventEmitter<any>;
    constructor(private localeService: LocaleService) {
        this.receivedFilter = new EventEmitter<any>();
    }

    raiseEvent(data: any): void {
        const locale = this.localeService.getLocaleOverride();
        if (locale && locale === 'es-US') {
            const spanishTitle = Titles.TITLES.find(title => title.englishTitle === data.message);
            if (spanishTitle && spanishTitle.spanishTitle !== '') {
                data.message = spanishTitle.spanishTitle;
            }
        }
        this.data = data;
        this.receivedFilter.emit(data);
    }
    
    setDescription(description: string) {
        this.$description.next(description);
    }
}
