import { Injectable } from '@angular/core';
import { RegistrationDataService } from 'sydmed/src/app/public/registration/app.data.service';
import { VcRecaptchaService } from '../../../../../libs/vc-recaptcha/src/public-api';
 
@Injectable({
  providedIn: 'root'
})
 
export class ContactModel {
 
  constructor(
    private dataSvc: RegistrationDataService,
    private recaptcha: VcRecaptchaService
  ) { }
 
  private model: any = {
    memberInput: {
      acceptedTandC: false,
      dateOfBirth: "",
      hcid: "",
      lastName: "",
      firstName: "",
      recaptchaResponse: "",
      deviceFingerprint: {}
    },
    memberResponse: {},
    aslToken: "",
    setDefaults: true,
  };
 
  public updateAccountDetailsModel(AccountDetails, hasMemberId: boolean = false) { 
    const { acceptedTandC, dateOfBirth, hcid, planid, lastName, firstName } = AccountDetails.value;

    this.model = {
      memberInput: {
        acceptedTandC: acceptedTandC,
        dateOfBirth: dateOfBirth,
        lastName: lastName.trim(),
        firstName: firstName.trim(),
        recaptchaResponse: this.recaptcha.getRecaptchaResponse()
      },
    };

    if (hasMemberId) {
      this.model.memberInput['hcid'] = hcid;
      this.model.memberInput['planid'] = planid;
    }

  }
 
  public getAccountDetailsModel() {
    return this.model.memberInput;
  }
 
  public transformResponse(apiData) {
    if (apiData) {
      this.model.memberResponse = apiData;
    }
    return this.model;
  }
}