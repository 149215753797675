import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { Router } from '@angular/router';
import {DocumentHubLabels, DocumentsHubAnalyticsTags} from './models/labels';
import {MemberRepresentativeService} from 'sydmed/libs/member-representative-service/memberRepresentative-service';

@Component({
  selector: 'app-document-hub',
  templateUrl: './document-hub.component.html',
  styleUrls: ['./document-hub.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentHubComponent implements OnInit {
  public sidebarContent: any;
  public hasForms: boolean = false;
  public hasNoticeOfAction: boolean = false;
  public hasPlanDocuments: boolean = false;
  public analyticsTags: DocumentsHubAnalyticsTags;
  public header: string;
  public selectedItem: string = '';
  public isCaregiver: boolean = false;
  constructor(
    private interCommunicationService: InterCommunicationService,     
    private jsonSvc: JsonContentService,
    private router: Router,
    private memberRepresentativeService: MemberRepresentativeService
  ) {
    this.hasForms = this.hasRestrictions(Restriction.PLAN_DOCUMENTS_AND_FORMS);
    this.hasNoticeOfAction = this.hasRestrictions(Restriction.SHM_NOTICES_OF_ACTION);
    this.hasPlanDocuments = this.hasRestrictions(Restriction.SHM_PLAN_DOCUMENTS);
    this.isCaregiver = this.memberRepresentativeService.isCaregiver

    this.header = this.hasForms ? 'Documents & Forms' : 'Documents';
    this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: this.header
      });
}


private hasRestrictions(...restrictionTypes: Restriction[]): boolean {
    const restrictions = this.jsonSvc.getRestrictions();
    return restrictionTypes.some(type => !this.jsonSvc.hasRestriction(type, restrictions));
}

  setPageNavigation(url) {
    this.selectedItem = url;
    this.router.navigateByUrl(url);
  }

  ngOnInit(): void {
    this.jsonSvc.getJSON('document-hub').subscribe((res:DocumentHubLabels) => {
      this.sidebarContent = res.sidebar;
      this.analyticsTags  = res.analyticsTags;
    });
  }

}