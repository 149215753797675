import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss']
})
export class PaymentsListComponent implements OnInit {

  @Input() listMode: any;
  @Input() content: any;
  @Input() displayList: any[];
  constructor() { }

  ngOnInit(): void {
  }

}
