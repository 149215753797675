import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AuthEventsService {
navMenuUpdated: EventEmitter<any>;

    constructor() {
        this.navMenuUpdated = new EventEmitter<any>();
    }

    updateMenu(s: string) {
        this.navMenuUpdated.emit(s);
    }
}