<div class="button-group" *ngIf="!isDesignee && !hideComposeButton && !isTermed">
  <a href="javascript:void(0)" class="compose-icon" [attr.aria-label]="'Compose new message'" (click)="composeMessage('right')">
    <span class="motif-icon motif-delete"></span>
  </a>
  <button type="button" id="MsgCntrNewMsg_btn" (click)="composeMessage('right')" class="btn btn-secondary nav-compose">
    <span class="compose-txt">{{ labels["MsgCntrNewMessage_lbl"] }}</span>
  </button>
</div>

<ng-template #sidePanelContent>
  <div class="compose-modal">
    <div class="clearfix">
      <button class="close" [attr.aria-label]="'Close Modal'" (click)="onClose()">
        <span aria-hidden="true" class="close-icon"></span>
      </button>
    </div>
  </div>
  <app-compose-message></app-compose-message>
</ng-template>