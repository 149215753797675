export class FileDetails {
  fileName: string;
  fileType: string;
  fileSize: string;
  fileData?: string;
  
    constructor(name?: string, size?: string, type?: string, dataURL?: string) {
      this.fileName = name;
      this.fileSize = size;
      this.fileType = type;
      this.fileData = dataURL || '';
    }
  }