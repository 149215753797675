export enum FolderId {
    ARCHIVED = 'archived',
    INBOX = 'inbox',
    CHAT_TRANSCRIPTS = 'chatTranscripts',
    SENT = 'sent'
}

export enum FolderType {
    INBOX = 'inbox',
    DELETED = 'deleted',
    SAVED = 'saved',
    SENT = 'sent'
}
