import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationAppComponent } from './public/registration/app.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { LoginAppComponent } from './public/login/app.component';
import { MaintenanceComponent } from './public/maintenance/maintenance.component';


const routes: Routes = [
  { path: 'public/login', component: LoginAppComponent},
  { path: 'public/registration', component: RegistrationAppComponent },
  { path: 'public/maintenance', component: MaintenanceComponent },
  {
    path: 'secure/my-health-dashboard',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./secure/engage/engage.module').then((m) => m.MedicareEngageModule),
  },
  { path: 'login', pathMatch: 'full', redirectTo: 'public/login' },
  { path: 'forgot-username-password', pathMatch: 'full', redirectTo: 'public/forgot-username-or-password' },
  { path: 'maintenance', pathMatch: 'full', redirectTo: 'public/maintenance' },
  { path: 'register', pathMatch: 'full', redirectTo: 'public/registration' },
  { path: '', pathMatch: 'full', redirectTo: 'public/login' },
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes, { useHash: false, enableTracing: false, scrollPositionRestoration: "enabled" } ) ],
  exports: [ RouterModule ]
} )

export class AppRoutingModule { }
