<div class="full-callout">
    <div class="container">
        <div class="row">
            <div class="ant-content-wrapper" role="region">
                <div class="cards-wrapper">
                    <div class="cards-container">
                        <div>
                            <div class="flex-grid">
                                <div>
                                    <h4 class="section-header-three motif-margin-bottom-7">
                                        {{labels?.insuranceDetails?.thirdPartyInformation}}</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="grid-row">
                                <div class="grid-column">
                                    <dl *ngIf="details.tplInsuranceOrderCode">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.insuranceOrder}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh motif-margin-top-3">
                                            {{labels?.insuranceOrderValues[details.tplInsuranceOrderCode]}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.subscbrName">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.subscriberName}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            {{details.subscbrName}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.tplPhone">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.subscirberPhoneNumber}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            {{details.tplPhone}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.tplsubscbrId">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.subscriberId}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            {{details.tplsubscbrId}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.coverageType">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.coveragetype}}
                                        </dt>
                                        <dd class="ant-font-r  motif-margin-top-3">
                                            {{details.coverageType}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.coverageEffDate">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.coverageEffectiveDate}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            {{details.coverageEffDate}}
                                        </dd>
                                    </dl>
                                </div>
                                <div class="grid-column">
                                    <dl *ngIf="details.coverageTermDate">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.coverageTerm}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            {{details.coverageTermDate}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.groupNum">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.insuranceGroupNumber}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            {{details.groupNum}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.insurancePolicy">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.insurancePolicyNumber}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            {{details.insurancePolicy}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.tplCompName">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.thirdPartyInsurance}}
                                        </dt>
                                        <dd class="ant-font-r  motif-margin-top-3">
                                            {{details.tplCompName}}
                                        </dd>
                                    </dl>
                                    <dl *ngIf="details.tplAddr">
                                        <dt class="ant-font-sb mb-f-left">
                                            {{labels?.insuranceDetails?.thirdPartyInsuranceAddress}}
                                        </dt>
                                        <dd class="ant-font-r mb-lh  motif-margin-top-3">
                                            <div>{{ details.tplAddr?.addressLineOne}}<br /></div>
                                            <div
                                                *ngIf="details.tplAddr && details.tplAddr?.addressLineTwo && details.tplAddr.addressLineTwo.trim()">
                                                {{ details.tplAddr?.addressLineTwo}}<br /></div>
                                            <div>{{ details.tplAddr?.city}}, {{ details.tplAddr?.state}}{{ ' ' +
                                                details.tplAddr?.zipCode}}</div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>