<div class="text-center margin-bottom-64 margin-top-64">
    <img class="align-center margin-bottom-20" [src]="designeeAccessLabels?.designeeAccessImg" alt="Designee access"/>
    <h3 class="title">{{ designeeAccessLabels?.title }}</h3>
    <p class="margin-top-18" [innerHtml]="designeeAccessLabels?.description"></p>

    <div class="button-group margin-top-20">
        <button class="btn btn-secondary" [innerHTML]="designeeAccessLabels?.viewMessageBtn"
            (click)="onViewMessageBtnClick()"></button>
        <button class="btn btn-secondary" [innerHTML]="designeeAccessLabels?.updateProfileBtn"
            (click)="onUpdateProfileBtnClick()"></button>
    </div>
</div>