import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper//src/lib/error-code-mapper.service';
import { ResetTempPasswordModel } from './reset-temp-password.model';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

@Component({
  selector: 'app-reset-temp-password',
  templateUrl: './reset-temp-password.component.html',
  styleUrls: ['./reset-temp-password.component.scss']
})
export class ResetTempPasswordComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private router: Router,
    private route: ActivatedRoute,
    private errCodeSvc: ErrorCodeMapperService,
    private resetTempPasswordModel: ResetTempPasswordModel,
    private jsonSvc: JsonContentService
  ) { }

  resetTempPasswordForm: FormGroup;
  public aslError: any;
  public jsonLabels;
  public jsonErrors;
  public showPasswordSuccessView: boolean = false;
  public userName: any;
  public passwordFocused: boolean = false;

  ngOnInit(): void {
    this.jsonSvc.getJSON('login').subscribe(data => {
      this.jsonLabels = data.LoginMFA.Labels.Reset_Temp_Password;
      this.jsonErrors = data.LoginMFA.ErrorMsgs;
    });

    this.userName = this.route.snapshot.params.id;
    sessionStorage.removeItem('sydMedLoggedIn');
    sessionStorage.removeItem('asl-token');
    this.resetTempPasswordForm = this.formBuilder.group({
      tempPassword: ['', [Validators.required]],
      passwords: [],
      userName: [''],
    })
    this.resetTempPasswordForm.controls['userName'].setValue(this.userName);
  }

  public updatePassword() {
    if(this.resetTempPasswordForm.valid) {
      const endpoint = UrlProperties.UrlProperties.endPoints.login.resetTempPassword;
      const inputData = this.resetTempPasswordModel.updateModel(this.resetTempPasswordForm, this.userName);
  
      this.httpClient.postObservable(endpoint, inputData).subscribe(
        (response: any) => {
          sessionStorage.clear();
          this.showPasswordSuccessView = true;
        },
        error => {
          this.aslError = this.errCodeSvc.errorCodeMapper(error, this.jsonErrors);
        }
      )
    } else {
      this.resetTempPasswordForm.markAllAsTouched();
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();  
    }  
  }
  
  public goHome() {
    this.router.navigate(['/public/login']); 
  }

}
