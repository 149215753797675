export interface AutopayResponse {
    MemberPayRecurringPaymentDetails: {
        planID:	string;
        productID: string;
        pullIndicator: string;
        memberPayBillAcount: MemberPayBillAccount;
        payDate: number;
        createdBy: string;
        confirmStatus: string;
        authMode: string;
        emailId: string;
        recurringPayStatus:	string;
        endDate: string;
        errorMessage: string;
        startDate: string;
        recurringHealthCardId: string;
        tokenID: string;
        paymentType: string;
        firstName: string;
        lastName: string;
        brand: string;
    }
}

export interface MemberPayBillAccount {
    billDate: string;
    dueDate: string;
    paidToDate: string;
    minDue:	number;
    totalDue: number;
    billFrequency: string;
    memberPayment: MemberPayPayment;
    paymentStatus: MemberPayPaymentStatus;
    daysBeforeTermination: string;
    billCoveragePeriod: string;
    billFromDt: string;
    billToDt: string;
    billOverDueInd: string;
    coverageStatus: string;
    pdfBillAvailable: string;
    billSource: string;
    sbrUid: string;
    classId: string;
    planId: string;
    type: string;
    productDescription: string;
    dateEffective: string;
    dateTermination: string;
    brand: string;
    subGroupName: string;
    planName: string;
    productId: string;
    medicareDiscount: number;
    federalSubsidyAmt: number;
    stateSubsidyAmt: number;
    mbrResponsibilityAmt: number;
    daysBeforeDueDt: string;
    billOverDueDays: string;
}

export interface MemberPayPayment { 
    paymentDate: string;
    paymentAmount: number;
    paymentTrackingNo: string;
    paymentMethod: MemberPayPaymentMethod;
    paymentUserID: string;  
}

export interface MemberPayPaymentStatus {
    paymentProcess: string;
    payNowStatus: string;
    shortContent: string;
    showContent: string;
    contentDescription: string;
    mamStatusCode: string;
    canPayBillInd: string;
    recurringPaymentInd: string;       
}

export interface MemberPayPaymentMethod {
    bankAccountDetails: BankAccountDetails[];
    creditCardDetails: CreditCardDetails[];                 
}

export interface BankAccountDetails {
    bankAccountType: BankAccountType;
    routingNumber: string;
    bankAccountNumber: string;
    institutionName: string;
    nsfCount: number;
    accountHolderName: string;
    accountAddress1: string;
    accountAddress2: string;
    accountCity: string;
    accountState: string;
    accountPostalCode: string;
    accountCountryCode: string;
    accountNickname: string;
    tokenId: string;  
}

export interface CreditCardDetails {
    creditCardNumber: string;
    creditCardType: CreditCardType;
    expirationMonth: string;
    expirationYear: string;
    securityCode: string;
    accountHolderName: string;
    accountAddress1: string;
    accountAddress2: string;
    accountCity: string;
    accountState: string;
    accountPostalCode: string;
    accountNickname: string;
    tokenId: string;
    integrityCheck: string;
    keyID: string;
    phaseID: string;    
}

export enum BankAccountType {
    PERSONAL_CHECKING = 'PERSONAL_CHECKING', 
    PERSONAL_SAVINGS = 'PERSONAL_SAVINGS', 
    BUSINESS_CHECKING = 'BUSINESS_CHECKING', 
    BUSINESS_SAVINGS = 'BUSINESS_SAVINGS', 
    MONEY_MARKET = 'MONEY_MARKET'
}

export enum CreditCardType {
    MC = 'MC',
    VISA = 'VISA',
    DISC = 'DISC',
    AMEX = 'AMEX', 
    MASTERCARD = 'MASTERCARD'
}

export enum PaymentType {
    BANK = 'BANKACCOUNT',
    CREDIT = 'CREDITCARD'
}

export enum AutopayDate {
    INMCD = '05',
    KYMCD = '03'
}

export enum AutopayActions {
    ADD = 'ADD',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE'
}

export enum AccountStatus {
    ACTIVE = 0,
    ALMOST = 1,
    EXPIRED = 2
}

export interface AutopayPayload {
    createdBy?: string;
    recurringPayStatus?: string;
    planID: string;
    productID: string;
    payDate: string;
    authMode: string;
    tokenID: string;
    paymentType: string;
}

export interface AutopayRequestObj { 
    recurringPaymentDetails: AutopayPayload[];
    action: AutopayActions;
}