<div class="plan-documents">
    <h2 class="section-header-two">{{content?.title}}</h2>
    <p *ngIf="subTitle" class="padding-top-20 padding-bottom-20">{{content?.subTitle}}</p>
    <div class="section-body">
        <p class="description">
            <span class="handbook-title">{{content?.memberHandbook}}</span><br>
            {{content?.handbookDescription}}
        </p>
        <ul class="info-list">
            <li *ngFor="let info of content?.infoList">{{info}}</li>
        </ul>
        <a  class="handbook-link" href="{{handBookUrlLink}}"
            target="{{content?.memberHandbook}}" [attr.data-analytics]="content?.analytics?.planDocLink">{{content?.memberHandbook}}
            <span class="motif-icon motif-external-link info-icon"></span>
        </a>
    </div>
    <!-- TODO: condition will be updated once restriction is added for proof of Insurance-->
    <p class="margin-top-25 font-16" *ngIf="content?.proofOfInsuranceDescription?.length">
        <span class="font-bold"> {{content?.proofOfInsurance}} </span><br>
        <span  [innerHTML]="content?.proofOfInsuranceDescription"></span>
    </p>
</div>