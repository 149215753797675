import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyFhrComponent } from './components/myfhr.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
	{ path: 'secure/my-fhr', component: MyFhrComponent, pathMatch: 'full', canActivate: [AuthGuardService] } 
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyFhrRoutingModule { }