import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textHighlight'
})

export class TextHighlightPipe implements PipeTransform {
  transform(text: string, term: string, className: string): string {
    if (term && term.length >= 1 && text) {
      text = text + ''; // make sure text is a string
      const regex = new RegExp('(' + term + ')', 'gi');
      text = text.replace(regex, '<span class="' + className + '">$1</span>');
    }
    return text || '';
  }
}