import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthEventsService } from 'sydmed/libs/authorization/authevents.service';
import { JsonContentService } from '../content-service/json-content.service';
import { filter } from 'rxjs/operators';
import { Restriction } from "gbd-models";
import { Locale, LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
@Component({
  selector: 'app-secure-header',
  templateUrl: './secure-header.component.html',
  styleUrls: ['./secure-header.component.scss']
})

export class SecureHeaderComponent implements OnInit, OnDestroy {
  public userLoggedIn: boolean = false;
  public memberContextData: any;
  public marketingBrand: string;
  public jsonHeaderLogos;
  public jsonHeaderIcons;
  public jsonLabels;
  public locale: string = '';
  public favicons: {};
  public brandLogo: string = '';
  public faviconLinks: HTMLLinkElement[];
  public brandRedirect: string;
  public brandClass: string = '';
  public brandAlt: string = '';
  public isPublicSite: boolean = false;
  public hasNewDashboardRestriction: boolean = true;
  public hasLanguageRestriction: boolean;
  public isLanguageChangeRestricted: boolean = false;
  constructor(
    private eventSvc: AuthEventsService,
    private changeDetector: ChangeDetectorRef,
    private jsonSvc: JsonContentService,
    private localeService: LocaleService,
    private router: Router
  ) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      if (window.location.href.toLowerCase().indexOf('/secure/dashboard') >= 0 || window.location.href.toLowerCase().indexOf('/public/login') >= 0) {
        this.getHeaderContents();
      }
    });
   }

  ngOnInit(): void {
    this.getHeaderContents();
    if(window.sessionStorage.getItem('sydMedLoggedIn') === 'true'){
      this.localeOverride();
    }
    this.eventSvc.navMenuUpdated.subscribe(data => {
      this.updateNavMenu(data);
      this.checkUserLoggedIn();
      if(sessionStorage.getItem('sydMedLoggedIn') === 'true'){
        this.localeOverride();
      }
    });
  }

  ngAfterViewInit() {
    this.checkUserLoggedIn();
  } 

  checkUserLoggedIn() {
    this.userLoggedIn = sessionStorage.getItem('sydMedLoggedIn') === 'true' ? true : false;
    this.changeDetector.detectChanges();
  }

  localeOverride() {
    if (this.hasLanguageRestriction) {
      this.localeService.removeLocaleOverride();
    } else {
      this.localeService.setLocaleOverrideFromParameter();
    }

    const localeOverride = this.localeService.getLocaleOverride();
    if (localeOverride) {
      this.locale = localeOverride;
    }
  }

  getHeaderContents() {
    this.jsonSvc.getJSON('header').subscribe(data => {
      this.jsonHeaderLogos = data;
      this.jsonHeaderIcons = data.Icons;
      this.favicons = data.Favicons;
      this.jsonLabels = data.Labels;
      this.updateHeaderLogo('');
      this.createFaviconLink();
      this.hasLanguageRestriction = Boolean(this.jsonSvc.hasRestriction(Restriction.SHM_LOCALE_SPANISH, this.jsonSvc.getRestrictions()));
      this.hasNewDashboardRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_NEW_DASHBOARD, this.jsonSvc.getRestrictions());
      this.isLanguageChangeRestricted = this.userLoggedIn && !this.hasLanguageRestriction && !this.hasNewDashboardRestriction
    });
  }

  updateNavMenu(data: any) {
    this.updateHeaderLogo(data);
  }

  updateHeaderLogo(data) {
    this.brandLogo = './assets/images/brands/' + this.jsonHeaderLogos.image;
    this.brandClass = this.jsonHeaderLogos.class;
    this.brandAlt = this.jsonHeaderLogos.alt;


    if (window.location.href.toLowerCase().indexOf('secure') >= 0) {
      this.isPublicSite = false;
      this.brandRedirect = "/secure/dashboard";
    } else {
      this.isPublicSite= true;
      this.brandRedirect = this.jsonHeaderLogos.url;
    }
  }

  createFaviconLink() {
    let href = window.location.hostname.toLowerCase();
    let brand = '';
    for (let i in this.jsonHeaderIcons) {
      if (href.indexOf(i) >= 0) {
        brand = this.jsonHeaderIcons[i];
      }
    }
    for(let q in this.favicons) {
      document.querySelector(this.favicons[q].selector).setAttribute('href','./assets/images/favicon/' + brand + '/' + this.favicons[q].favicon)
    }
  }

  ngOnDestroy(): void {
    this.eventSvc.navMenuUpdated.unsubscribe();
  }

  languageToggle() {
    if (this.locale === '') {
      this.localeService.setLocaleOverride(Locale.ES_US);
    }
    else {
      this.localeService.removeLocaleOverride();
    }
    location.reload();
   }

}
