// Angular Imports
import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

// Message Center Imports
import { MessageCenterDataService } from './../message-center-data.service';

@Component({
  selector: 'sort-messages',
  templateUrl: './sort-messages.component.html',
  styleUrls: ['./sort-messages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SortMessagesComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private msgCntrDataService: MessageCenterDataService
  ) { }

  private subscriptions: Subscription[];
  public sortDropDownModel: any;
  public sortDropDownDef: any;

  ngOnInit(): void {
    this.subscriptions = [];
    this.sortDropDownDef = {
      title: 'Sort Messages By:',
      options: [
        {
          label: 'Date: Newest First',
          value: 'Date: Newest First'
        },
        {
          label: 'Date: Oldest First',
          value: 'Date: Oldest First'
        },
        {
          label: 'Subject: A - Z',
          value: 'Subject: A - Z'
        },
        {
          label: 'Subject: Z - A',
          value: 'Subject: Z - A'
        },
        {
          label: 'Read',
          value: 'Read'
        },
        {
          label: 'Unread',
          value: 'Unread'
        }
      ]
    };

    this.subscriptions.push(this.msgCntrDataService.getfolder().subscribe(
      folder => {
        if (folder === 'sent' || folder === 'archived') {
          this.sortDropDownDef.options = [
            {
              label: 'Date: Newest First',
              value: 'Date: Newest First'
            },
            {
              label: 'Date: Oldest First',
              value: 'Date: Oldest First'
            },
            {
              label: 'Subject: A - Z',
              value: 'Subject: A - Z'
            },
            {
              label: 'Subject: Z - A',
              value: 'Subject: Z - A'
            }
          ];
        } else {
          this.sortDropDownDef.options = [
            {
              label: 'Date: Newest First',
              value: 'Date: Newest First'
            },
            {
              label: 'Date: Oldest First',
              value: 'Date: Oldest First'
            },
            {
              label: 'Subject: A - Z',
              value: 'Subject: A - Z'
            },
            {
              label: 'Subject: Z - A',
              value: 'Subject: Z - A'
            },
            {
              label: 'Read',
              value: 'Read'
            },
            {
              label: 'Unread',
              value: 'Unread'
            }
          ];
        }

        this.setDefaultDropdown();
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    // Update sortDropDownModel after current change detection cycle is finished.
    Promise.resolve(null).then(() => this.setDefaultDropdown());
  }

  private setDefaultDropdown() {
    this.sortDropDownModel = this.sortDropDownDef.options[0].value;
  }

  public sortMessages(value) {
    let sortBy = {
      sort: 'received',
      order: 'desc'
    };

    switch (value) {
      case 'Date: Newest First':
        sortBy = {
          sort: 'received',
          order: 'desc'
        };
        break;
      case 'Date: Oldest First':
        sortBy = {
          sort: 'received',
          order: 'asc'
        };
        break;
      case 'Subject: A - Z':
        sortBy = {
          sort: 'subject',
          order: 'asc'
        };
        break;
      case 'Subject: Z - A':
        sortBy = {
          sort: 'subject',
          order: 'desc'
        };
        break;
      case 'Read':
        sortBy = {
          sort: 'read',
          order: 'desc'
        };
        break;
      case 'Unread':
        sortBy = {
          sort: 'read',
          order: 'asc'
        };
        break;
    }

    this.msgCntrDataService.setSortBy(sortBy);
  }

}
