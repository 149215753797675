<div class="benefits" *ngIf="content">
    <div class="left-side">
        <sidebar-rail>
            <siderail-nav>
                <h2 siderail-header class="siderail-header-plan header-highlighter"
                     tabIndex="0"> {{(content.memberInfo.memberName | si:{ memberName: memberName}) | titlecase }} </h2>
                <button class="sub-menu-button-plan" [data-div-id]="item.Label" #selectedView
                    id='selectedView' *ngFor="let item of benefitMenu" siderail-link [id]="'navid'+item.Label"
                    [innerHTML]="item.Label" (click)="benefitsService.scrollToComponent(item.Label,150)"
                    [class.hidden]="item.show"></button>
            </siderail-nav>
            <div class="menu-header-top"></div>
        </sidebar-rail>
        <div class="sub-menu">
            <h2 siderail-header class="siderail-header-additional" [innerHTML]="content.BenefitNavigation.AdditionalResource.Header" tabIndex="0"></h2>
            <button class="sub-menu-button-additional" *ngFor="let item of additionalResourcesMenu" siderail-link [id]="'navid'+item.Label" (click)="setNavPage(item.Url)" [class.hidden]="item.show" [ngClass]="{'isActive': navPage === item.Url}">
                <span class="sub-nav-main">
                    <span class="sub-menu-span" [innerHTML]="item.Label"></span>
                    <span class="motif-pad-left-6 motif-icon motif-right-round sub-menu-icon"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="right-side">
        <div class="cards-wrapper">
            <div id="Your Health Care Plan" class="card large-card card-flat card-popover">
                <benefits-member-info-component [content]="content.memberInfo"></benefits-member-info-component>
            </div>
            <div id="Vision Care" class="card large-card card-flat card-popover" *ngIf="visionBenefitsEnabled">
                <benefits-vision-component [content]="content.visionBenefits"></benefits-vision-component>
            </div>
            <div id="Dental Care" class="card large-card card-flat card-popover" *ngIf="dentalBenefitsEnabled">
                <benefits-dental-component [content]="content.dentalBenefits"></benefits-dental-component>
            </div>
            <div id="Transportation Services" class="card large-card card-flat card-popover"
                *ngIf="transportationBenefitsEnabled">
                <benefits-transportation-component
                    [content]="content.transportationBenefits"></benefits-transportation-component>
            </div>
            <div id="Plan Documents" class="card large-card card-flat card-popover">
                <benefits-plan-documents-component
                    [content]="content.planDocuments"></benefits-plan-documents-component>
            </div>
        </div>
    </div>
</div>