import { Injectable } from '@angular/core';
import { BehaviorSubject, from, of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { FeaturesByMarket, Feature } from '../interfaces/FeatureToggle';
import { PharmacySessionService } from './pharmacy-session.service';
import { PharmacyFeatures } from '../enums/PharmacyFeatureToggle';

const featureToggleApi = UrlProperties.UrlProperties.endPoints.pharmacy.featureToggle;

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  protected featuresByMarket$ = new BehaviorSubject<Feature[]>(null);
  stateLob: string;

  constructor(
    protected http: HttpClientService,
    protected sessionSvc: PharmacySessionService) { }



  getStateLoB() {
    const memberContext = this.sessionSvc.getMemberContext();
    return memberContext?.stateLob;
  }

}
