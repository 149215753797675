import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ReviewModel {
  constructor() {}

  private model;

  public caregiverModel;

  public getReviewModel = function () {
    return this.model;
  };

  public transformMemberInput(memberInput, memberDetails?) {
    if (memberInput) {
      this.model.registration.username = memberInput.userName;
      this.model.registration.password = memberInput.password;
      this.model.registration.email = memberInput.emailAddress;
      this.model.registration.accountRecovery.number =
        memberInput.accountRecoveryNumber.phoneNumber;
      this.model.registration.accountRecovery.type =
        memberInput.accountRecoveryNumber.phoneType;
      this.model.registration.questionAnswers[0].question =
        memberInput.question1;
      this.model.registration.questionAnswers[0].answer = memberInput.answer1;
      this.model.registration.questionAnswers[1].question =
        memberInput.question2;
      this.model.registration.questionAnswers[1].answer = memberInput.answer2;
      this.model.registration.questionAnswers[2].question =
        memberInput.question3;
      this.model.registration.questionAnswers[2].answer = memberInput.answer3;
    }
    if(memberDetails) {
      this.model.registration.dob = memberDetails.dateOfBirth;
      this.model.registration.firstName = memberDetails.firstName;
      this.model.registration.lastName = memberDetails.lastName;
    }
  }

  public transformCaregiverInput(memberInput) {
    this.model.registration['caregiverInformation'] = Object.assign(this.caregiverModel.caregiverInformation);
    this.model.registration.caregiverInformation.dateOfBirth = memberInput.dateOfBirth;
    this.model.registration.caregiverInformation.firstName = memberInput.firstName;
    this.model.registration.caregiverInformation.lastName = memberInput.lastName;
    this.model.registration.caregiverInformation.phoneNumber = memberInput.phone;
    this.model.registration.caregiverInformation.address.streetAddress1 = memberInput.primaryAddress;
    this.model.registration.caregiverInformation.address.streetAddress2 = memberInput.secondaryAddress;
    this.model.registration.caregiverInformation.address.city = memberInput.city;
    this.model.registration.caregiverInformation.address.state = memberInput.state;
    this.model.registration.caregiverInformation.address.zipCode = memberInput.zip;
    this.model.registration.caregiverInformation.type = CaregiverType.PARENT;
  }

  public initModel() {
    this.model = {
      registration: {
        firstName: "",
        lastName: "",
        dob: "",
        username: "",
        password: undefined,
        email: "",
        questionAnswers: [
          {
            question: "",
            answer: "",
          },
          {
            question: "",
            answer: "",
          },
          {
            question: "",
            answer: "",
          },
        ],
        accountRecovery: {
          number: "",
          type: "",
        },
      },
      displayAnswers: [
        {
          answer: "",
        },
        {
          answer: "",
        },
        {
          answer: "",
        },
      ],
    };
    this.caregiverModel = {
      caregiverInformation: {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        phoneNumber: "",
        hasCaregiverAttested: true,
        address: {
          city:'',
          country: "",
          state: "",
          streetAddress1: "",
          streetAddress2: "",
          zipCode: "",
        },
        type: ""
      }
    }
  }
}

export enum CaregiverType {
  GUARDIAN = 'guardian',
  PARENT = 'parent',
}