import { Injectable } from '@angular/core';
import { IAppConfig } from '@anthem/mbrportal/main';

//TODO: remove this when ready for dev testing
//import './tcpConfig.local.mock';

/**
 *  App configuration settings
 */
@Injectable()
export class TcpAppConfig implements IAppConfig {
  audit: any = undefined;
  restApi: any = undefined;
  web: any = undefined;
  wcs: any = undefined;
  logging: any = undefined;
  httpInterceptor: any = undefined;
  environment: any = undefined;
  featureToggle: any = undefined;

  constructor() {
    if (typeof window.tcpConfig !== 'undefined' && typeof window.tcpConfigSub !== 'undefined') {
      if (window.libConfigs) {
        for (const key in window.libConfigs.defs) {
          $.extend(true, window.tcpConfig, window.libConfigs.defs[key]);
        }

        for (const key in window.libConfigs.subs) {
          $.extend(true, window.tcpConfigSub, window.libConfigs.subs[key]);
        }
      }

      $.extend(true, window.tcpConfig, window.tcpConfigSub);

      this.audit = window.tcpConfig.audit;
      this.restApi = window.tcpConfig.restApi;
      this.web = window.tcpConfig.web;
      this.wcs = window.tcpConfig.wcs;
      this.logging = window.tcpConfig.logging;
      this.httpInterceptor = window.tcpConfig.httpInterceptor;
      this.environment = window.tcpConfig.environment;
      this.featureToggle = window.tcpConfig.featureToggle;
    }
  }
}
