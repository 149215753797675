import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';


@Component({
  selector: 'get-security-code',
  templateUrl: './get-security-code.component.html',
  styleUrls: ['./get-security-code.component.css']
})
export class GetSecurityCodeComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private jsonSvc: JsonContentService
  ) { }

  GetSecurityCodeForm: FormGroup;
  public jsonLabels;
  public jsonErrors;
  public linkLabel;
  public url = UrlProperties.UrlProperties.endPoints.forgotUsernamePassword.sendOtp;

  ngOnInit(): void {
    this.jsonSvc.getJSON('forgot-username-password-mfa').subscribe(data => {
      this.jsonLabels = data.ForgotUsernamePasswordMFA.Labels.Get_Security_Code;
      this.jsonErrors = data.ForgotUsernamePasswordMFA.ErrorMsgs;
      this.linkLabel = this.jsonLabels.Security_Questions_Link_Text;
    });
   
    this.GetSecurityCodeForm = this.formBuilder.group({
      sendCodeOption: ['', [Validators.required]],
    });
  }

  goToSecurityQuestions(event){
    this.router.navigate(['public/forgot-username-or-password/answer-security-questions']);
  }

  public goToHomePage() {
    this.router.navigate(['public/forgot-username-or-password/home']);
  }
  
  public goToEnterSecurityCodePage() {
    this.router.navigate(['public/forgot-username-or-password/enter-security-code']);
  }
}