import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ChatbotOption } from '../interfaces/chatbotOption';
import CONSTANTS from '../utils/constants';
import { Router } from '@angular/router';
import { ChatbotHelperService } from '../services/chatbot-helper.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, OnChanges {
  
  @Input() content: any;
  @Input() isOptionSelected: boolean;
  @Output() chatbotOptionSelected: EventEmitter<ChatbotOption> = new EventEmitter<ChatbotOption>();
  @Output() chatbotFocusInput: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren('chatDialog') chatDialogs:QueryList<ElementRef>;
  @ViewChildren('dialog') dialogs:QueryList<ElementRef>;
  
  public labels: any;
  public chatbotElements: any = [];
  public chatbotOptions: ChatbotOption[] = CONSTANTS.CHATBOT_OPTIONS;
  public selectedOption: ChatbotOption;
  public showNoResponse: boolean = false;
  public showBackToTopics: boolean = false;
  analytics = CONSTANTS.CHATBOT_ANALYTICS;
  memberContext: any;
  public hasSpanishChat: boolean = false;

  constructor(public router: Router, private helper: ChatbotHelperService) { }

  ngOnInit(): void {
    this.memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    if (this.memberContext?.stateLob && this.memberContext?.stateLob === "INMCD") {
      this.chatbotOptions[0].name = 'Change PMP';
    }
    if (this.memberContext?.stateLob && (this.memberContext?.stateLob === "MNMCD" || this.memberContext?.stateLob === "MNMMP")) {
      this.chatbotOptions.shift();
    }
    this.hasSpanishChat = !this.memberContext?.restrictions?.includes("SHM_SPANISH_CHAT");
    this.pushChatbotOptions();
  }

  ngOnChanges(): void {    
    this.labels = this.content ? this.content.chatbot : {};
    if (this.chatbotElements[0] && !this.chatbotElements[0].message) 
      this.chatbotElements[0].message = this.labels.helpToday;
    if (!this.hasSpanishChat) {
      this.chatbotOptions = this.chatbotOptions.filter(option => option.name != this.labels.chatLiveAgentES);
    }
    if (this.isOptionSelected === false) {
      this.chatbotElements = [];
      this.pushChatbotOptions();
    }
  }

  getAnalyticsString(topicName) {
    topicName = topicName ? topicName.toLowerCase().replace(/\s/g, '') : '';
    return this.analytics[topicName] ? this.analytics[topicName] : '';
  }

  chatbotRedirect(evt, option, i): void {
    this.isOptionSelected = true;
    this.chatbotElements.push({type: 'message', source: 'user', message: option.name});
    this.selectedOption = option;
    this.handleRedirect(this.selectedOption);
    this.chatbotElements[i].selected = true;
    this.chatbotOptionSelected.emit(option);
  }

  chatbotRedirectUserInput(userInput): void {
    let inputTrim = userInput.trim().toLowerCase();
    if (this.showBackToTopics) {
      this.backToTopics();
    }
    let buttonGp = this.chatbotElements.find(x => x.type === 'buttonGroup' && !x.selected);
    if (buttonGp) 
      this.chatbotElements.find(x => x.type === 'buttonGroup' && !x.selected).selected = true;
    let lastElement = this.chatbotElements[this.chatbotElements.length - 1];
    if (!lastElement.selected && (lastElement.type === 'dialog' || lastElement.type === 'chatDialog')) {
      if (inputTrim === 'yes' && lastElement.type === 'dialog') {
        this.showBotOptions(this.chatbotElements.length - 1);
        return;
      }
      if (inputTrim === 'yes' && lastElement.type === 'chatDialog') {
        this.launchLiveChat(this.chatbotElements.length - 1);
        return;
      }
      if (inputTrim === 'no') {
        this.endChatbot(this.chatbotElements.length - 1);
        return;
      }
      this.chatbotElements[this.chatbotElements.length - 1].selected = 1;
    }
    this.chatbotElements.push({type: 'message', source: 'user', message: userInput});
    this.helper.processUserInput(this.chatbotOptions, inputTrim).then(option => {
      if (option) {        
        this.selectedOption = option;
        this.handleRedirect(this.selectedOption);
        this.chatbotOptionSelected.emit(this.selectedOption);
      } else {
        this.chatbotElements.push({type: 'chatDialog', enabled: true});
        setTimeout(() => {this.chatDialogs?.last?.nativeElement.focus();}, 300);
      }
    });
  }

  handleRedirect(option): void {
    if (option.url) {
      this.router.navigate([option.url]).then((e) => {
        setTimeout(() => { 
          this.chatbotElements.push({type: 'dialog', page: option.name});
        }, 1000);
        setTimeout(() => {this.dialogs?.last?.nativeElement.focus();}, 1600);
      });
    }
  }

  showBotOptions(i): void {
    this.isOptionSelected = false;
    this.chatbotElements.push({ type: 'message', source: 'user', message: this.labels.chatbotYes });
    this.chatbotElements[i].selected = 'yes';
    this.pushChatbotOptions();
    this.chatbotFocusInput.emit();
  }

  endChatbot(i): void {
    this.chatbotElements.push({ type: 'message', source: 'user', message: this.labels.chatbotNo });
    this.chatbotElements[i].selected = 'no';
    this.isOptionSelected = false;
    this.showNoResponse = true;
    this.showBackToTopics = true;
  }

  launchLiveChat(i): void {
    this.chatbotElements.push({ type: 'message', source: 'user', message: this.labels.chatbotYes });
    this.chatbotElements[i].selected = 'yes';
    this.chatbotOptionSelected.emit(this.chatbotOptions.find(x => x.name === 'Chat with a Live Agent'));
  }
  liveChatNo(i): void {
    this.chatbotElements.push({ type: 'message', source: 'user', message: this.labels.chatbotNo });
    this.chatbotElements[i].selected = 'no';
    this.showBackToTopics = true;
  }

  backToTopics(): void {
    this.showNoResponse = false;
    this.showBackToTopics = false;
    this.chatbotElements = [];
    this.pushChatbotOptions();
    this.chatbotFocusInput.emit();
  }

  pushChatbotOptions(): void {
    if (this.chatbotElements.length === 0 || !(this.chatbotElements[this.chatbotElements.length - 1]?.type === 'buttonGroup')) {
      this.chatbotElements.push({ type: 'message', source: 'bot', message: this.labels.helpToday });
      this.chatbotElements.push({ type: 'buttonGroup', selected: false });
      if (this.hasSpanishChat && this.chatbotOptions.filter(option => option.name === this.labels.chatLiveAgentES).length === 0) {
        this.chatbotOptions.push(CONSTANTS.SPANISH_CHATOPTION);
      }
    }
  }
}
