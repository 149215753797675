<div class="cards-wrapper">
  <div class="cards-container">
    <div class="card medium-card card-flat" *ngIf="content && content.block">
      <span class="motif-icon motif-bank-cards"></span>
      <h3 class="description-title">{{content.block.heading}}</h3>
      <div *ngIf="isCheckoutSuccess && isCheckoutSpecialty; else checkConfirmationPBMBlock">
          <div class="description-text">
              <div class="row motif-pad-top-5 motif-pad-bottom-5 motif-pad-left-11">
                  <div data-uxd-alert-cmp-v2 class="col-xxs-12"
                      [alert]="{alertType: 'information'}">
                      <span>{{ noPaymentDueSpecialtyLabel }}</span>
                  </div>
              </div>
          </div>
      </div>
      <ng-template #checkConfirmationPBMBlock>
          <div *ngIf="isCheckoutSuccess && isCheckoutPbm; else NonConfirmationPage">
              <div class="description-text motif-pad-top-5 motif-pad-bottom-5 motif-pad-left-11">
                  <p>{{ pbmPaymentMethod.accountName }} </p>
                  <p *ngIf="pbmPaymentMethod.accountType == 'CC'">
                      <span>{{content.block.expirationDate }}</span>
                      <span>{{pbmPaymentMethod.expirationDate | datestring: 7: '0'}}</span>
                  </p>
              </div>
          </div>   
      </ng-template>                  
      <ng-template #NonConfirmationPage>
          <div *ngIf="!loading && selectedPaymentMethod; else LoadingOrNoPaymentMethod"
          class="description-text motif-pad-top-5 motif-pad-bottom-5 motif-pad-left-11">
          <p *ngIf="selectedPaymentMethod.accountName">
              {{selectedPaymentMethod.accountName}}
          </p>
          <p *ngIf="selectedPaymentMethod.expirationDate">
              <span
                  *ngIf="!isExpired(selectedPaymentMethod.expirationDate); else Expired">
                  {{content.block.expirationDate}} {{selectedPaymentMethod.expirationDate | datestring: 7: '0'}}
          </span>
              <ng-template #Expired>
                  <span class="red-text">{{content.block.expiredDate }} {{ selectedPaymentMethod.expirationDate | datestring: 7: '0'}}</span>
              </ng-template>
          </p>
          <a class="primary-color" href="javascript:void(0)" (click)="addPayment()" data-analytics="changePaymentPbmShipmentRx">{{content.block.linkText}}</a>
          </div>      
      </ng-template>
      <ng-template #LoadingOrNoPaymentMethod>
          <div *ngIf="!isError; else NoPaymentMethod" class="description-text col-lg-4 col-md-4 col-sm-4">
              <div class="row">
                  <uxd-spinner class="col-lg-2 col-md-2 col-sm-2"></uxd-spinner>
              </div>
          </div>
          <ng-template #NoPaymentMethod>
              <div class="description-text">
                  <div class="row motif-pad-top-5 motif-pad-bottom-5 motif-pad-left-11">
                      <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                          <span class="desktop" *ngIf="isSpecialty">{{content.block.noSpecialty}}</span>
                          <span class="mobile" *ngIf="isSpecialty" [innerHTML]="content.block.noSpecialtyMobile"></span>
                          <span class="desktop" *ngIf="!isSpecialty">{{content.block.noPBM}}</span>
                          <span class="mobile" *ngIf="!isSpecialty" [innerHTML]="content.block.noPBMMobile"></span>
                      </div>
                  </div>
                  <div class="row motif-pad-left-11">
                      <a class="primary-color" href="javascript:void(0)" data-analytics="updateSelectPayMethodRx" *ngIf="selectedPaymentMethod" (click)="addPayment()">{{content.block.linkText}}</a>
                      <a class="primary-color" href="javascript:void(0)" data-analytics="addNewPayMethodRx" *ngIf="!selectedPaymentMethod" (click)="addPayment()">{{content.block.linkTextAdd}}</a>
                  </div>
              </div>
          </ng-template>
      </ng-template>
    </div>
  </div>
</div>
<!-- Slide-In Panel Template -->
<ng-template #sidePanelContent>
  <div class="modal-example">
    <div class="clearfix">
      <button class="close" (click)="closePanel($event)" aria-label="Close">
        <span class="close-icon"></span><span hidden>Close Payment Methods</span>
      </button>
    </div>
    <div class="modal-component-body">
      <app-payment-methods
        *ngIf="content"
        [content]="content"
        [isSpecialty]="isSpecialty"
        [selectedPayment]="selectedPaymentMethod"
        (closePanel)="closePanel($event)"
        >
      </app-payment-methods>
    </div>
  </div>
</ng-template>

