import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DescribeYourSituation, ErrorMsgs } from '../../models/labels';
import { RadioButtonGroupValues } from '../../models/formValues';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-grievance-health-option',
  templateUrl: './grievance-health-option.component.html',
  styleUrls: ['../../appeals-and-grievances.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GrievanceHealthOptionComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrievanceHealthOptionComponent implements ControlValueAccessor, OnDestroy {
  @Input('formSubmitted') formSubmitted: boolean;
  @Input() errorMsgs: ErrorMsgs;
  @Input() labels: DescribeYourSituation;
  healthOptionForm: FormGroup;
  selectedRadioButton: string;
  inputEnabler: boolean;
  subscriptions: Subscription[] = [];
  @Input('disableEffect') disableEffect: boolean = false;

  constructor(public fb: FormBuilder) { 
    this.healthOptionForm = this.fb.group({
      optionCategory: ['', Validators.required]
    });

    this.subscriptions.push(
      this.healthOptionForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    ); 
  }

  get value(): RadioButtonGroupValues {
    return this.healthOptionForm.value;
  }

  set value(value: RadioButtonGroupValues) {
    this.healthOptionForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  selectItem(id){
      this.selectedRadioButton = id;
  }

  onChange: any = () => { };
  onTouched: any = () => { };


  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (!value) {
      this.healthOptionForm.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}