<div class="chat-body" *ngIf="labels">
    <div class="sydney-logo"></div>
    <div class="sydney-caption">{{ labels.iAmSydney }}</div>
    <div class="ant-chat-bubble ant-chat-bubble-bot">
        <p>{{ labels.chatbotWelcome }}</p>
    </div>

    <ng-template  ngFor let-element [ngForOf]="chatbotElements" let-i="index">
        <ng-container [ngSwitch]="element.type">
            <ng-container *ngSwitchCase="'message'">
                <div class="ant-chat-bubble" [ngClass]="{'ant-chat-bubble-bot': element.source === 'bot', 'ant-chat-bubble-user': element.source === 'user', 'last': i===0}">
                    <p>{{ element.message }}</p>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'buttonGroup'">
                <div class="category padding"></div>
                <div class="category messages clear" *ngFor="let option of chatbotOptions">
                    <div class="message">
                        <button class="ant-chat-pill"
                            attr.aria-label="{{option.name}}" [disabled]="element.selected"
                            (click)="chatbotRedirect($event, option, i)"
                            [attr.data-analytics] = "getAnalyticsString(option.name)">{{option.name}}
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'dialog'">                
                <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" role="group" attr.aria-labelledby="redirect-success-{{i}}" 
                    #redirectSuccess uxd-select tabindex="0" #dialog>
                    <p class="ant-action-list-label" id="redirect-success-{{i}}">
                        {{labels?.youAreNowOn | si:{page: element.page} }} {{labels?.anythingElse}}            
                    </p>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="showBotOptions(i)" aria-label="yes"
                    data-analytics="" [ngClass]="{'selected':element.selected == 'yes'}" [disabled]="element.selected">{{labels?.chatbotYes}}</button>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="endChatbot(i)" aria-label="no"
                    data-analytics="" [ngClass]="{'selected':element.selected == 'no'}" [disabled]="element.selected">{{labels?.chatbotNo}}</button>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'chatDialog'">                
                <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" role="group" attr.aria-labelledby="chat-suggestion-{{i}}" 
                    uxd-select tabindex="0" #chatDialog>
                    <p class="ant-action-list-label" id="chat-suggestion-{{i}}">
                        {{labels?.sorryLiveAgent}}            
                    </p>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="launchLiveChat(i)" aria-label="yes"
                    data-analytics="" [ngClass]="{'selected':element.selected == 'yes'}" [disabled]="element.selected">{{labels?.chatbotYes}}</button>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="liveChatNo(i)" aria-label="no"
                    data-analytics="" [ngClass]="{'selected':element.selected == 'no'}" [disabled]="element.selected">{{labels?.chatbotNo}}</button>
                </div>
            </ng-container>
        </ng-container>
    </ng-template>
    <div class="ant-chat-bubble ant-chat-bubble-bot" *ngIf="showNoResponse">
        <p>{{ labels?.gladToHelp }}</p>
    </div>
    <div class="back-button-box" *ngIf="showBackToTopics">
        <button class="back-to-topics motif-icon motif-arrows-long-up" (click)="backToTopics()">
            <span>{{ labels?.backToTopics }}</span>
        </button>
    </div>
</div>