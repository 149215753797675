import { ClaimsContent } from '../interfaces/claimsContent';
import { ClaimsModel } from './claimsModel';
import { ClaimSummary, ClaimDetailsAPIResponse, ClaimDetails, FilterAttribues } from '../interfaces/claims';
import { Injectable } from '@angular/core';
import { OTHER } from '../values/claimsConstants';
import { Claim } from 'gbd-models';

/**
 * @description
 * This model class is used to create list of claims for all the claims widgets after getting the data from all the rest APIs
 *
 * @example
 * @function
 * getClaimsList(data, content), getDetailedClaimsList(data, content, index): ClaimSummary[]
 * getDetailedClaimsList(rawClaimDetail: ClaimDetailsAPIResponse, claimsList: ClaimDetails[], content: ClaimsContent, selectedClaimIndex: number): ClaimDetails[]
 * downloadClaims(claims: ClaimSummary[], content: ClaimsContent, filename = 'Claims.csv', enableShowMeTheMath: string): void
 * sortArray(array: FilterAttribues[], columnName : string, sortOrder: { [key: string]: number }): void
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class ClaimsList {
  public claimsList: ClaimSummary[] = [];

  constructor(private claimsModal: ClaimsModel) { }

  /**
   * @description getClaimsList() Get formatted claim list
   * @param {Claim[]} rawClaims
   * @param {ClaimsContent} content
   * @returns {ClaimSummary[]}
   */
  getClaimsList(rawClaims: Claim[], content: ClaimsContent): ClaimSummary[] {
    this.claimsList = [];
    for (let i = 0; i < rawClaims.length; i++) {
      const claim = this.claimsModal.buildClaimSummaryObject(rawClaims[i], content );
      // Allowing only Approved, Pending & denied claims
      if ( claim.statusName && (claim.statusName.toUpperCase() === 'APPROVED' ||
          claim.statusName.toUpperCase() === 'PENDING' || claim.statusName.toUpperCase() === 'DENIED')) {
        this.claimsList.push(claim);
      }
    }
    return this.claimsList;
  }

  /**
   * @description getDetailedClaimsList() Get formatted claim details
   * @param {ClaimDetailsAPIResponse} rawClaimDetail
   * @param {ClaimDetails[]} claimsList
   * @param {ClaimsContent} content
   * @param {number} selectedClaimIndex
   * @returns {ClaimSummary[]}
   */
  getDetailedClaimsList( rawClaimDetail: ClaimDetailsAPIResponse, claimsList: ClaimDetails[], content: 
    ClaimsContent, selectedClaimIndex: number ): ClaimDetails[] {
    if (claimsList) {
      claimsList[selectedClaimIndex] = this.claimsModal.addDetailsInClaimsList(
        claimsList[selectedClaimIndex],
        rawClaimDetail,
        content
      );
    }
    return claimsList;
  }

  /**
   * @description downloadClaims() download claims selected with date range & filters if any
   * @param {ClaimSummary[]} claims
   * @param {ClaimsContent} content
   * @param {string} [filename = 'Claims.csv'] filename
   * @param {string} enableShowMeTheMath
   * @returns {void}
   */
  downloadClaims( claims: ClaimSummary[],content: ClaimsContent,filename = 'Claims.csv',
    enableShowMeTheMath: string ): void {
    const blob = new Blob(['\ufeff' + this.convertToCSV(claims, content, enableShowMeTheMath)],{ type: 
      'text/csv;charset=utf-8;' });
      let exportLink = document.createElement('a');
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 &&
        navigator.userAgent.indexOf('Chrome') == -1;
      //if Safari open in new window to save file
      if (isSafariBrowser) {
        exportLink.setAttribute('target', '_blank');
      }
      exportLink.setAttribute('href', URL.createObjectURL(blob));
      exportLink.setAttribute('download', filename);
      exportLink.style.visibility = 'hidden';
      document.body.appendChild(exportLink);
      exportLink.click();
      document.body.removeChild(exportLink);
  }

  /**
   * sortArray
   * @description
   * Responsible to sort Array on column name with custom order
   * @param {FilterAttribues[]} array
   * @param {string} columnName
   * @param { [key: string]: number } sortOrder
   * @return {void}
   */
  sortArray( array: FilterAttribues[], columnName: string, sortOrder: { [key: string]: number }): void 
  {
    array.sort((a: FilterAttribues, b: FilterAttribues): number => {
      let value1: string = a[columnName].trim().toUpperCase();
      let value2: string = b[columnName].trim().toUpperCase();
      if (isNaN(sortOrder[value1])) {
        value1 = OTHER;
      }
      if (isNaN(sortOrder[value2])) {
        value2 = OTHER;
      }
      return sortOrder[value1] - sortOrder[value2];
    });
  }

  /**
   * @description convertToCSV() download claim selected with date range & filters if any
   * @param {ClaimSummary[]} claims
   * @param {ClaimsContent} content
   * @param {boolean} enableShowMeTheMath
   * @returns {string}
   */
  private convertToCSV( claims: ClaimSummary[], content: ClaimsContent, enableShowMeTheMath: string): 
  string {
    let str = '';
    let row = '';
    
    content.filter.exportClaimsHeader.forEach((element: FilterAttribues) => {
      row += element.label + ',';
    });
    str += row + '\r\n';
    claims.forEach((claim: ClaimSummary) => {
      let line = '';
      content.filter.exportClaimsHeader.forEach((element: FilterAttribues) => {
        line += this.getCellValue( claim, element.value, enableShowMeTheMath, content ) + ',';
      });
      str += line + '\r\n';
    });
    return str;
  }

  /**
   * @description getCellValue() get value of selected header column from input list
   * @param {ClaimSummary} claims
   * @param {string} columnNames
   * @param {boolean} enableShowMeTheMath
   * @param {ClaimsContent} content
   * @returns {string}
   */
  private getCellValue( claim: ClaimSummary, columnNames: string, enableShowMeTheMath: string, content: 
    ClaimsContent ): string {
    let columnArray: string[] = columnNames.split('#');
    let cell: string = '';

    if (columnArray.length > 1) {
      for (var i = 0; i < columnArray.length; i++) {
        cell += (claim[columnArray[i]] === null || claim[columnArray[i]] === undefined ? '': 
        claim[columnArray[i]]) + ' ';
      }
    } else {
      if ( !enableShowMeTheMath && claim.classCode === 'M' && (columnArray[0] === 'coveredAmountCurrency' 
      || columnArray[0] === 'planPaidAmountCurrency' || columnArray[0] === 'notCoveredAmountCurrency')) {
        cell = content.notAvailable;
      } else {
        cell = claim[columnArray[0]] === null || claim[columnArray[0]] === undefined ? '' :
        claim[columnArray[0]];
      }
    }

    if (cell.search(/("|,|\n)/g) >= 0) {
      cell = cell.replace(/,/g, '');
    }
    return cell;
  }
}
