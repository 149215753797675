<form #dateRangeForm="ngForm" name="dateRangeForm" autocomplete="off" novalidate>
  <div class="claims-filter-widget-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-xxs-12">
          <div class="claims-filter-button-container">
            <div class="claims-filter-buttons">
              <!-- Filter Claims Panel -->
              <div class="filter-panel-wrapper claims-by-btns">
                <button [attr.aria-expanded]="displayFilter" [attr.data-analytics]="content.analytics.filterClaimsDropdownClaims"
                  #filterButton class="filter-panel-trigger filter-claims-button" [class.is-expanded]="displayFilter"
                  id="claim-filter-show-hide-filter-Button" [innerHTML]="content.filter.filterClaimsButton" panel-trigger
                  (click)="toggleFilterPanel()"></button>
                <!-- Filter Panel -->
                <div filter-panel-container class="filter-panel-container" *ngIf="displayFilter" #filterPanelContainer 
                  [attr.aria-hidden]="!displayFilter" (keyup.escape)="toggleFilterPanel()">
                  <div filter-panel>
                    <div class="filter-panel">
                      <div class="filter-container">
                        <!-- Filter By Type -->
                        <div *ngIf="claimTypes && claimTypes.length > 0" class="filter filter--type">
                          <p class="uxd-filter-label claims-filter-label-syd" id="claim-filter-by-claim-type-label"
                            [innerHTML]="content.filter.byClaimTypeLabel"></p>
                          <div class="uxd-filter-button-group" role="group"
                            aria-labelledby="filter-by-claim-type-label">
                            <button uxd-multi-select
                              [attr.data-analytics]="claimType.value === 'P' ? content.analytics.filterPharmacyClaims : claimType.value === 'M' ? content.analytics.filterMedicalClaims : ''"
                              class="uxd-filter-button" *ngFor="let claimType of claimTypes; let index = index;"
                              [attr.id]="'claim-filter-claimType-option-' + index"
                              [checked]="selectedClaimsType.indexOf(claimType.value) !== -1"
                              (click)="onClaimTypeChange(claimType.value)">
                              <span [attr.id]="'claim-filter-type-lbl-' + index" [innerHTML]="claimType.label"></span>
                            </button>
                          </div>
                        </div>
                        <div class="filter-row">
                          <div class="">
                            <!-- Filter By Date -->
                            <div class="filter filter--date">
                              <p class="uxd-filter-label claims-filter-label-syd" id="claim-filter-by-date-range-label"
                                [innerHTML]="content.filter.byDateRangeLabel"></p>
                              <div class="uxd-filter-button-group motif-margin-bottom-5" role="group"
                                aria-labelledby="filterByDateRange" uxd-select>
                                <ng-container  *ngFor="let date of dateRange; let i = index">
                                  <button class="uxd-filter-button claims-date-range-btn" uxd-select-toggle
                                    [attr.id]="'claim-filter-by-date-range-value-' + i"
                                    [attr.data-analytics]="date.analytics"
                                    [attr.aria-label]="date.value + ' ' + content.filter.dateFilter.monthsLabel"
                                    [innerHTML]="date.value + ' ' + content.filter.dateFilter.monthsLabel"
                                    [value]="date.value + ' ' + content.filter.dateFilter.monthsLabel" 
                                    (click)="onDateRangeChange(date.value, i)"
                                    (keyup.enter)="onDateRangeChange(date.value, i)"
                                    [class.is-checked]="selectedDateIndex === i">
                                  </button>
                                </ng-container>
                              </div>

                              <!-- Custom Date Range -->
                              <div data-tab data-heading="Datepicker">
                                <div class="dateRangeWrapper">
                                  <div class="dtPicker dtPicker--underline">
                                    <label class="dateRangeLabel sr-only" for="claim-filter-fromDateRange" [innerHTML]="content.filter. dateFilter.fromDateLabel"></label>
                                    <input name="fromDateRange" id="claim-filter-fromDateRange" type="text"
                                      class="pfText ant-text-input" #fromDateRange="ngModel"
                                      [(ngModel)]="claimFilter.fromDate" data-uxd-text-dir data-is-date="true"
                                      (blur)="validateDates(fromDateRange, toDateRange)" [maxDate]="fromMaxDate"
                                      [minDate]="twoYearAgo"/>
                                  </div>
                                  <div class="dtSpacer" [innerHTML]="content.filter.dateFilter.toLabel"></div>
                                  <div class="dtPicker dtPicker--underline">
                                    <label class="dateRangeLabel sr-only" for="claim-filter-toDateRange" [innerHTML]="content.filter.dateFilter.toDateLabel"></label>
                                    <input name="toDateRange" id="claim-filter-toDateRange" type="text" class="pfText ant-text-input"
                                      #toDateRange="ngModel" [(ngModel)]="claimFilter.toDate" data-uxd-text-dir
                                      data-is-date="true" maxlength="10" (blur)="validateDates(fromDateRange, toDateRange)" [maxDate]="today" [minDate]="toMinDate"/>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="fromDateRange.invalid" class="pull-left ant-full-width" role="alert">
                                <span id="claim-filter-fromDateRange-invalid-date" class="ant-form-item-error"
                                  *ngIf="fromDateRange.errors?.invalidDate" [innerHTML]="content.filter.dateFilter.invalidFromDate"></span>
                                <span id="claim-filter-fromDateRange-future-date" class="ant-form-item-error"
                                  *ngIf="fromDateRange.errors?.futureDate" [innerHTML]="content.filter.dateFilter.futureFromDate"></span>
                                <span id="claim-filter-fromDateRange-from-date-after-to-date" class="ant-form-item-error"
                                  *ngIf="fromDateRange.errors?.fromDateAfterToDate" [innerHTML]="content.filter.dateFilter.fromDateAfterToDate"></span>
                                <span id="claim-filter-fromDateRange-from-date-beyond-two-year" class="ant-form-item-error"
                                  *ngIf="fromDateRange.errors?.fromDateBeyondTwoYears" [innerHTML]="content.filter.dateFilter.fromDateBeyondTwoYears"></span>
                              </div>
                              <div *ngIf=" toDateRange.invalid" class="pull-left ant-full-width" role="alert">
                                <span id="claim-filter-toDateRange-invalid-date" class="ant-form-item-error"
                                  *ngIf="toDateRange.errors?.invalidDate" [innerHTML]="content.filter.dateFilter.invalidToDate"></span>
                                <span id="claim-filter-toDateRange-future-date" class="ant-form-item-error"
                                  *ngIf="toDateRange.errors?.futureToDate" [innerHTML]="content.filter.dateFilter.futureToDate"></span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="filter-row ant-margin-top-20">
                          <!-- Filter By Status -->
                          <div class="filter filter--status" *ngIf="statusCodes && statusCodes.length > 0">
                            <p class="uxd-filter-label claims-filter-label-syd" id="filterByStatus" [innerHTML]="content.filter.byStatusLabel"></p>
                            <div class="uxd-filter-button-group" role="group" aria-labelledby="filterByStatus"
                              uxd-select >
                              <button class="uxd-filter-button" uxd-multi-select 
                              [attr.data-analytics]="status.value === 'Denied' ? content.analytics.filterDeniedStatusClaims : status.value === 'Approved' ? content.analytics.filterApprovedStatusClaims : status.value === 'Pending' ? content.analytics.filterPendingStatusClaims : ''"
                              *ngFor="let status of statusCodes; let index = index;"
                              [checked]="selectedStatuses.indexOf(status.value) !== -1"
                              (click)="onClaimStatusChange(status.value)">
                                <span id="'status-option-' + index" class="status-button-text"
                                  [innerHTML]="status.label" role="checkbox"></span>
                              </button>
                            </div>
                          </div>
                          <div class="filter-col-b">
                            <!-- Filter By Provider -->
                            <div class="filter" *ngIf="providers && providers.length > 0">
                            <div *ngIf="providers && providers.length > 0"  data-uxd-dropdown-cmp [(ngModel)]="providerModel" class="ant-lg-select" [labelText]="content.filter.byProviderLabel"
                            (ngModelChange)="onProviderChange($event)" 
                            name="claimProviderSelect" id="claimProviderSelect" [items]="providers"
                            required [defaultText]="content.filter.providerDefaultText">
                           </div>
                            </div>
                          </div>
                        </div>
                        <hr>
                        <div class="filter-row">
                          <!-- Reset all filters link -->
                          <button [attr.data-analytics]="content.analytics.resetAllFiltersClaims" id="resetFilterBtn"
                            class="reset-filters" (click)="removeFilterOption()">
                            <span aria-hidden="true" class="reset-icon motif-icon motif-recurring-appointment"></span>
                            <span class="reset-filters__text" [innerHTML]="content.filter.resetAllFilters"></span>
                          </button>
                          <div>
                            <!-- Cancel button -->
                            <button [attr.data-analytics]="'cancelButtonClaims'" id="cancelFilterBtn" class="btn btn-secondary" (click)="toggleFilterPanel()">
                              <span id="claims-cancel-filter-lbl" [innerHTML]="content.filter.cancelFilter"></span>
                            </button>
                            &nbsp;
                            <!-- Apply filter button -->
                            <button apply-filter [attr.data-analytics]="content.analytics.applyFiltersButtonClaims" id="applyFilterBtn" class="btn btn-primary"
                              (click)="applyFilter(dateRangeForm)" [disabled]="fromDateRange.invalid || toDateRange.invalid">
                              <span id="claims-apply-filter-lbl" [innerHTML]="content.filter.applyFilters"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Search by claim number-->
              <div class="form-item search-claim-input-container claims-by-btns">
                <label for="claimId" [innerHTML]="content.filter.claimSearchPlaceHolder"></label>
                <input id="claimId" name="claimId" [(ngModel)]="claimId"
                  [attr.aria-label]="content.filter.searchClaimAriaLb + ' ' + content.filter.searchInstrurctionAria"
                  maxlength="40" (keydown)="keypressHandlingEvent($event)" type="text"
                  class="ant-text-input ant-search-input search-claim-input"
                  [attr.placeholder]="content.filter.claimSearchPlaceHolder" />
                <button id="searchClaimsBtn" [attr.data-analytics]="content.analytics.searchBtnAnalytics"
                  class="ant-search-button" (click)="searchClaims()">
                  <span aria-hidden="true" class="motif-icon motif-search-1"></span>
                  <span class="sr-only" [innerHTML]="content.filter.claimSearchPlaceHolder"></span>
                </button>
              </div>
              <!-- Export claims button -->
              <button [disabled]="!hasClaims" [attr.data-analytics]="content.analytics.exportIconClaims" id="exportClaimsBtn"
                class="btn btn-secondary export-claim-button claims-by-btns" (click)="exportClaimClick()">
                <div class="export-claim-button-content">
                  <span [innerHTML]="content.filter.exportClaimsButton" [attr.aria-label]="content.filter.exportClaimsButtonAria"></span>
                  <span aria-hidden="true" class="motif-icon motif-ms-excel"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>