import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError, from } from 'rxjs';
import { DataService } from './data.service';
import { DeliveryOrPickup, ShippingAddress, ShippingResponse } from 'gbd-models';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { PharmacyApiService } from 'sydmed/src/app/secure/pharmacy/services/pharmacy-api.service';
import { ShippingRequestObject } from 'sydmed/src/app/secure/pharmacy/interfaces/ShippingRequestObject';
import { SpecialtyShippingRequest } from 'sydmed/src/app/secure/pharmacy/interfaces/SpecialtyShippingRequest';
import { ValidAddressResponse } from 'sydmed/src/app/secure/pharmacy/interfaces/ValidAddressResponse';
import { ValidateSpecialtyAddress } from 'sydmed/src/app/secure/pharmacy/interfaces/ValidateSpecialtyAddress';
import { PharmacyShippingAddress } from '../../pharmacy/interfaces/PharmacyShippingAddress';
@Injectable( {
  providedIn: 'root'
} )

export class ShippingAddressService {
  protected addressList$: BehaviorSubject<ShippingAddress[]> = new BehaviorSubject<ShippingAddress[]>( [] );
  public addressList = this.addressList$.asObservable();
  protected specialtyShippingAddressList$: BehaviorSubject<ShippingAddress[]> = new BehaviorSubject<ShippingAddress[]>( [] );
  public specialtyShippingAddressList = this.specialtyShippingAddressList$.asObservable();
  protected specialtyDeliveryDatesList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>( [] );
  public specialtyDeliveryDatesList = this.specialtyDeliveryDatesList$.asObservable();
  protected shippingAddress$: BehaviorSubject<ShippingAddress> = new BehaviorSubject<ShippingAddress>( null );
  public shippingAddress = this.shippingAddress$.asObservable();
  protected specialtyShippingAddress$: BehaviorSubject<ShippingAddress> = new BehaviorSubject<ShippingAddress>( null );
  public specialtyShippingAddress = this.specialtyShippingAddress$.asObservable();
  protected apiEndpoints = UrlProperties.UrlProperties.endPoints;
  protected pharmacyAddress$: BehaviorSubject<PharmacyShippingAddress> = new BehaviorSubject<PharmacyShippingAddress>( null );
  public pharmacyAddress = this.pharmacyAddress$.asObservable();
  protected defaultShippingState$: BehaviorSubject<string> = new BehaviorSubject<string>( '' );
  public defaultShippingState = this.defaultShippingState$.asObservable();

  constructor(
    protected dataService: DataService,
    protected api: PharmacyApiService ) { }

  protected addressListValue() {
    return this.addressList$.getValue();
  }

  setNewAddress( address: ShippingAddress, isSpecialty ) {
    return this.api.addNewAddress( address ).pipe(
      map( res => {
        const list = isSpecialty ? this.specialtyShippingAddressList$.getValue() : this.addressList$.getValue();
        isSpecialty ? this.setSpecialtyAddressList( [ ...list, address ] ) : this.setAddressList( [ ...list, address ] );
        this.setAddress( address, isSpecialty );
        return true;
      } ),
      catchError( error => throwError( error ) )
    );
  }

  validateAddress( address: ShippingAddress, specialtyPayload: ValidateSpecialtyAddress, isSpecialty: boolean ) {
      return isSpecialty ? this.validateSpecialtyAddress(specialtyPayload): this.validatePBMAddress(address);
  }

  validatePBMAddress( address: ShippingAddress ) {
    const params = { ...address };
    return this.api.validateAddress( params ).pipe(
      map( ( res: ValidAddressResponse ) => {
        return res;
      } ),
      catchError( error => throwError( error ) ) );
  }

  validateSpecialtyAddress( specialtyPayload: ValidateSpecialtyAddress ) {
    return this.api.validateSpecialtyAddress( specialtyPayload ).pipe(
      map( ( res: ValidAddressResponse ) => {
        return res;
      } ),
      catchError( error => throwError( error ) ) );
  }

  isEmptyList() {
    return this.addressListValue().length === 0;
  }


  handleAddressListResponse( shipping: ShippingResponse, isSpecialty: boolean ) {
    const defaultAddressType = shipping.defaultAddressType;
    const addressList: ShippingAddress[] = shipping.addresses;
    isSpecialty ? this.setSpecialtyAddressList( addressList ) : this.setAddressList(addressList);
    const defaultAddress: ShippingAddress = this.getDefaultAddress( addressList, defaultAddressType );
    this.setAddress( defaultAddress, isSpecialty );
    if(isSpecialty) {
        this.getDefaultShippingState(addressList); 
    }
  }

  public getDefaultShippingState(addressList: ShippingAddress[]){
    const defaultShippingAddress: ShippingAddress = addressList.filter(
      ( address: ShippingAddress ) =>
        address?.addressUsageTypes !== undefined
          && address?.addressUsageTypes?.length > 0
            && address?.addressUsageTypes[0] === 'defaultShipping'
      )[ 0 ];

    defaultShippingAddress ? this.defaultShippingState$.next(defaultShippingAddress?.state) : 
                              this.defaultShippingState$.next('');
  }
  

  public getShippingInfo(requestObject: ShippingRequestObject) {
     return this.api.getShippingInfo( requestObject ).pipe( map(
       ( shippingInfo: ShippingResponse ) => {
    //  return from( import( '../assets/mocks/shipping-api-mock-response.json' ) ).pipe( map(
    //    ( shippingInfo: any ) => {
        this.handleAddressListResponse( shippingInfo, false );
        return shippingInfo;
      } ),
      catchError( error => throwError( error ) ) );
  }

  public getSpecialtyShippingInfo(requestObject: SpecialtyShippingRequest) {
     return this.api.getSpecialtyShippingInfo( requestObject ).pipe( map(
     ( shippingInfo: ShippingResponse ) => {

  //  return from( import( '../assets/mocks/shipping-api-mock-response.json' ) ).pipe(map(
  //    ( shippingInfo: any ) => {
        this.handleAddressListResponse( shippingInfo, true );
        return shippingInfo;
      } ),
      catchError( error => throwError( error ) ) );
  }

  setAddressList( addressList: ShippingAddress[] ) {
    this.addressList$.next( addressList );
  }

  setSpecialtyAddressList( addressList: ShippingAddress[] ) {
    this.specialtyShippingAddressList$.next( addressList );
  }

  setSpecialtyDeliveryDatesList( deliveryDates: string[] ) {
    this.specialtyDeliveryDatesList$.next( deliveryDates );
  }


  setAddress( address: ShippingAddress, isSpecialty: boolean ) {
    if ( isSpecialty ) {
      this.setSpecialtyAddress( address );
    } else {
      this.setPbmAddress( address );
    }
  }

  setPbmAddress( address: ShippingAddress ) {
    this.shippingAddress$.next( address );
  }

  setSpecialtyAddress( address: ShippingAddress ) {
    if ( address ) {
      this.specialtyShippingAddress$.next( address );
    }
  }

  fetchMockAddressList() {
    return this.dataService.getData<ShippingResponse>( 'shipping-api-mock-response' ).pipe(
      mergeMap( ( shippingInfo: ShippingResponse ) => {
        const shippingAddresses: ShippingAddress[] = shippingInfo.addresses;
        this.setAddressList( shippingAddresses );
        const defaultAddress: ShippingAddress = this.getDefaultAddress( shippingInfo.addresses, shippingInfo.defaultAddressType );

        this.setAddress( defaultAddress, false );

        return this.addressList;
      } ),
      catchError( error => throwError( error ) ) );
  }

  getDefaultAddress( addressArray: ShippingAddress[], defaultAddressType: string ): ShippingAddress {
    return addressArray.find(
      ( { addressType, shipToEligible } ) => addressType === defaultAddressType  && shipToEligible === true);
  }

  getEligibleAddresses( addressArray: ShippingAddress[] ) {
    return addressArray.filter(
      ( address: ShippingAddress ) => address.shipToEligible === true && address.poBox === false );
  }

  setPharmacyAddress( address: PharmacyShippingAddress ) {
    if ( address ) {
      address.addressType = DeliveryOrPickup.STORE_PICKUP;
      this.specialtyShippingAddress$.next( address );
    }
  }
}
