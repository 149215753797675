import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { SortByOptions } from '../../pharmacy/types/SortByOptions';
import { PrescriptionsSortBy } from '../../pharmacy/enums/PrescriptionsSortBy';


@Injectable({
  providedIn: 'root'
})
export class SortService {

  private sortBy$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public sortBy: Observable<string> = this.sortBy$.asObservable();
  constructor() { }

  setSortBy(sortByValue: SortByOptions) {
    this.sortBy$.next(sortByValue);
  }

  setPrescriptionsSortBy(sortByValue: PrescriptionsSortBy) {
    this.sortBy$.next(sortByValue);
  }

  sortDescending<T>(values: T[], fieldSelector?: (value: T) => string, filterType:string = ""): T[] {
      return this.sortAscending(values, fieldSelector, filterType).reverse();
  }

  sortAscending<T>(values: T[], fieldSelector?: (value: T) => string, filterType:string = ""): T[] {
      if (!fieldSelector) {
          return values.sort();
      }
      return values.sort((value1: T, value2: T) => {
          const field1 = fieldSelector(value1);
          const field2 = fieldSelector(value2);

          if (field1 === field2) {
              return 0;
          }

          if(filterType && filterType == 'date'){
            return (new Date(field1).getTime()) - (new Date(field2).getTime());
          }else{
            return field1 < field2 ? -1 : 1;  
          }
          
      });
  }
}
