import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { ListFilterPipe } from "@anthem/mbrportal/shared/pipes/listFilterPipe";
import { NavigationHelper } from '@anthem/mbrportal/utility/services/navigationHelper';
import 'd3';
import { Claim, Restriction } from 'gbd-models';
import { Subscription } from "rxjs";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { default as claimsAPIData } from '../assets/data/mock/claimsMockData.json';
import { ClaimsDataService } from "../claims-data.service";
import { ClaimsFilterComponent } from '../claimsFilter/claimsFilterCmp';
import { ClaimsSummaryComponent } from "../claimsSummary/claimsSummaryCmp";
import { ClaimFilterWidgetState, ClaimSummary, ClaimSummaryParam, FilterAttribues } from "../interfaces/claims";
import { ClaimsContent } from '../interfaces/claimsContent';
import { ClaimClassCode } from '../models/ClaimClassCode';
import { ClaimsList } from "../models/claimsList";
import { ClaimsService } from "../services/claimsSvc";
import { DateUtility } from "../util/dateUtil";
import { ClaimProviderColumn, ClaimStatusColumn, ClaimTypeColumn, ClaimTypeFilterLabel } from "../values/claimsConstants";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
/**
 * @description
 * This component is responsible for loading all claim features
 * This is an entry component
 *
 * @function
 * filterClaims(state: ClaimFilterWidgetState): void
 * onDateRangeUpdate(filterState: ClaimFilterWidgetState): void
 * resetFilter(resetFilterState: ClaimFilterWidgetState): void
 * searchClaims(claimId: string): void
 * viewMoreClaims(): void
 *
 *  @example
 * ```html
 * <data-sydmed-claims-container-cmp></data-sydmed-claims-container-cmp>
 * <data-sydmed-claims-container-cmp [isMini]="true"></data-sydmed-claims-container-cmp>
 * ```
 */

@Component({
  selector: "data-sydmed-claims-container-cmp",
  templateUrl: "./claimsContainer.html",
  styleUrls: ["../claimsSyd.scss"],
})
export class ClaimsContainerComponent implements OnInit, OnDestroy {
  @ViewChild("claimsSummaryCmp", { static: false })
  claimsSummaryCmp: ClaimsSummaryComponent;

  @ViewChild("filterCmp") filterCmp: ClaimsFilterComponent;

  @Input()
  isMini: boolean = false;

  allClaims: ClaimSummary[];
  claims: ClaimSummary[];
  claimsErrorMsg: string;
  claimsErrorType: string;
  claimsSummaryError: boolean = false;
  claimSummaryLoader: boolean;
  claimId: string;
  content: ClaimsContent;
  exportClaimsList: ClaimSummary[];
  filterDisclaimerMessage: string;
  countOfClaims: string;
  filterPreviousState: ClaimFilterWidgetState;
  genericError: string;
  numClaimsAvailable: number = 0;
  hasFinancialSummary: boolean = false;
  singleClaimMode: boolean = false;
  navigateToClaimDetail: boolean = false;
  initialClaimTypes: FilterAttribues[] = [];
  initialProviders: FilterAttribues[] = [];
  initialStatusCodes: FilterAttribues[] = [];
  isFilterChanged: boolean = false;
  isResetFilter: boolean = false;
  hasClaims: boolean = false;
  hasPharmacy = true;
  hasHistoricalClaims = false;
  claimUid: string;
  private subscriptions: Subscription[];

  widgetFilterState: ClaimFilterWidgetState = {
    date: 6,
  };
  totalAmtContent: string;
  paidByPlan = 0;
  paidByYou = 0;
  pieChartSubText: string;
  paidByPlanLabel: string;
  yourResponsibilityLabel: string;

  constructor(
    public dateUtil: DateUtility,
    private claimsList: ClaimsList,
    private claimsService: ClaimsService,
    private interCommunicationService: InterCommunicationService,
    private listFilterPipe: ListFilterPipe,
    private navHelper: NavigationHelper,
    private activatedRoute: ActivatedRoute,
    private dataSvc: ClaimsDataService,
    private jsonSvc: JsonContentService,
  ) {
    //TODO needs to be replaced
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "Claims",
    });
  }

  ngOnInit() {
    this.subscriptions = [];
    this.widgetFilterState.claimTempId = this.activatedRoute.snapshot.queryParams.claimsID || this.activatedRoute.snapshot.queryParams.ClaimsID ;
    this.setContent();
  }

  ngAfterViewInit() {
    if (this.widgetFilterState.claimTempId) {
      this.loadSingleClaimExperience(this.widgetFilterState.claimTempId);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  loadSingleClaimExperience(claimId: string): void {
    this.searchClaims(claimId);
  }
  /**
   * @description filterClaims() Responsible for filter claim list based on the current filter state and child component calling from
   * @param {ClaimFilterWidgetState} filter
   * @returns {void}
   */
  filterClaims(filter: ClaimFilterWidgetState): void {
    this.claimId = '';
    this.filterPreviousState = { ...this.widgetFilterState };
    this.singleClaimMode = false;
    this.isFilterChanged = false;
    this.closeClaimsDetail();
    let filteredClaims: ClaimSummary[] = this.allClaims;
    this.isFilterChanged =
      filter?.selectedClaimsType?.length > 0 || Boolean(filter?.selectedProvider);

    // apply selected filter
    if (filter?.selectedClaimsType?.length > 0) {
      filteredClaims = this.listFilterPipe.transform(
        filteredClaims,
        ClaimTypeColumn,
        filter.selectedClaimsType
      ) as ClaimSummary[];
    }

    if (filter?.selectedProvider) {
      filteredClaims = this.listFilterPipe.transform(
        filteredClaims,
        ClaimProviderColumn,
        filter.selectedProvider
      ) as ClaimSummary[];
    }

    if (filter?.selectedStatuses?.length > 0) {
      filteredClaims = this.listFilterPipe.transform(
        filteredClaims,
        ClaimStatusColumn,
        filter.selectedStatuses
      ) as ClaimSummary[];
    }

    this.exportClaimsList = this.claims = filteredClaims;
    this.numClaimsAvailable = filteredClaims.length;
    if (this.isFilterChanged && this.numClaimsAvailable > 0) {
      this.filterDisclaimerMessage = this.updateFilterMessage(filter);
    }

    if (this.numClaimsAvailable === 0) {
      this.showMessage("information", this.content.error.noClaimDataFilter);
    } else {
      this.claimsSummaryError = false;
      this.claimsErrorMsg = null;
    }
  }

  /**
   * @description onDateRangeUpdate() Get claim list based on the date range
   * @param {ClaimFilterWidgetState} filterState
   * @returns {void}
   */
  onDateRangeUpdate(filterState: ClaimFilterWidgetState): void {
    this.filterPreviousState = { ...this.widgetFilterState };
    if (this.isResetFilter && filterState.date === 6) {
      this.filterDisclaimerMessage = this.content.error.claimFilterDefaultMessage;
      this.isFilterChanged = false;
      this.isResetFilter = false;
    } else {
      this.filterDisclaimerMessage = this.updateFilterMessage(filterState);
      this.isFilterChanged = true;
    }

    this.getClaims(filterState);
  }

  /**
   * @description resetFilter() resetting filter option previous loaded state
   * @param {ClaimFilterWidgetState} resetFilterState
   * @returns {void}
   */
  resetFilter(resetFilterState: ClaimFilterWidgetState): void {
    this.isResetFilter = true;
    if (this.singleClaimMode || resetFilterState.date !== this.content.filter.defaultDateRangeMonth) {
      this.singleClaimMode = false;
      resetFilterState.date = this.content.filter.defaultDateRangeMonth;
      this.onDateRangeUpdate(resetFilterState);
    } else {
      this.filterClaims(resetFilterState);
    }
    this.widgetFilterState = resetFilterState;
  }

  /**
   * @description searchClaims() filter the claims from sleected date range based in Partail/fill claim id
   * @param {string} claimId
   * @returns {void}
   */
  searchClaims(claimId: string): void {
    if (!claimId?.trim()) {
      return;
    }
    this.claimId = '';
    this.singleClaimMode = true;
    const trimmedClmId: string = claimId ? claimId.trim() : claimId;
    this.claimsErrorMsg = null;
    let filteredClaims: ClaimSummary[] = [];
    if (trimmedClmId) {
      if (this.claims.length) {
        filteredClaims = this.claims.filter(
          (claim: ClaimSummary) =>
            claim.clmId.toLowerCase().indexOf(claimId.trim().toLowerCase()) !==
            -1
        );
      }
      this.numClaimsAvailable = filteredClaims.length;
    }
    if (this.claimsSummaryCmp) {
      this.claimsSummaryCmp.resetInMemClaimDetailCache();
      if (trimmedClmId && this.numClaimsAvailable > 1) {

        this.claimsSummaryCmp.loadClaimsDetail(-1, filteredClaims, false);
      } else if (claimId && filteredClaims.length === 1) {
        this.claimId = filteredClaims[0].clmId.trim().toUpperCase();
        this.claimsSummaryCmp.loadClaimsDetail(0, filteredClaims[0], true);
      } else if (trimmedClmId && filteredClaims.length === 0) {
        this.showMessage("information", this.content.error.searchClaimsNotFound);
      } else {
        this.numClaimsAvailable = this.claims.length;
        this.claimsSummaryCmp.loadClaimsDetail(-1, this.claims, false);
      }
    }
    this.exportClaimsList = filteredClaims;
  }

  /** @description View More Claims */
  /**
   * @description viewMoreClaims() to navigate to claims page
   * @returns {void}
   */
  viewMoreClaims(): void {
    if (this.isMini) {
      this.navHelper.navigateTo('/claims');
    }
  }

  /**
   * @description buildServiceParams() responsible for building claim summary api parameter
   * @param {ClaimFilterWidgetState} [filterState]
   * @return {ClaimSummaryParam}
   */
  private buildServiceParams(
    filterState?: ClaimFilterWidgetState
  ): ClaimSummaryParam {
    let parameters: ClaimSummaryParam = {
      clmStartDt: '',
      clmEndDt: '',
      sort: '-clmStartDt',
    };
    let startDate: Date = new Date();
    let endDate: Date = new Date();

    // Checking argument is of form type or number
    if (typeof filterState?.date === "object") {
      const fromDate = new Date(filterState.date.fromDateRange);
      const toDate = new Date(filterState.date.toDateRange);
      parameters.clmStartDt = this.dateUtil.getDatePart(fromDate, "yyyy-MM-dd");
      parameters.clmEndDt = this.dateUtil.getDatePart(toDate, "yyyy-MM-dd");
    } else if (typeof filterState?.date === "number") {
      startDate.setMonth(startDate.getMonth() - filterState.date);

      //Leap year adjustment
      if (startDate.getDate() === 29 && startDate.getMonth() === 2) {
        startDate.setDate(28);
      }
      parameters.clmStartDt = this.dateUtil.getDatePart(
        startDate,
        "yyyy-MM-dd"
      );
      this.widgetFilterState.fromDate = this.dateUtil.getDatePart(
        startDate,
        "MM/dd/yyyy"
      );
      parameters.clmEndDt = this.dateUtil.getDatePart(endDate, "yyyy-MM-dd");
      this.widgetFilterState.toDate = this.dateUtil.getDatePart(
        endDate,
        "MM/dd/yyyy"
      );
    } else {
      startDate.setMonth(
        startDate.getMonth() - this.widgetFilterState.date
      );
      parameters.clmStartDt = this.dateUtil.getDatePart(
        startDate,
        "yyyy-MM-dd"
      );
      this.widgetFilterState.fromDate = this.dateUtil.getDatePart(
        startDate,
        "MM/dd/yyyy"
      );
      parameters.clmEndDt = this.dateUtil.getDatePart(endDate, "yyyy-MM-dd");
      this.widgetFilterState.toDate = this.dateUtil.getDatePart(
        endDate,
        "MM/dd/yyyy"
      );
    }
    return parameters;
  }

  /**
   * @description closeClaimsDetail() responsible for collapsing existing open claim detail
   * @return {void}
   */
  private closeClaimsDetail(): void {
    if (this.claimsSummaryCmp && this.claimId !== null) {
      for (let row in this.claimsSummaryCmp.inMemClaimDetailCache) {
        this.allClaims[row].isExpanded = false;
      }
    }
  }

  /**
   * @description getDurationInYears() get duration in years for given from and to date
   * @param {string} fromDate
   * @param {string} toDate
   * @return {ClaimSummaryParam}
   */
  private getDurationInYears(fromDate: string, toDate: string) {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    return Math.floor(
      (endDate.valueOf() - startDate.valueOf()) / (1000 * 3600 * 24) / 365.25
    );
  }

  /**
   * @description getClaims() get the claims from the API and then sorts them by the service date (-clmStartDt)
   * @param {ClaimFilterWidgetState} [filterState]
   * @returns {void}
   */
  private getClaims(filterState?: ClaimFilterWidgetState, claimsData?: any): void {
    this.claimSummaryLoader = true;
    this.claimsErrorMsg = null;
    this.singleClaimMode = false;
    this.widgetFilterState.date = this.isMini? this.content.mini.defaultDateRangeMonth : this.content.filter.defaultDateRangeMonth;
    this.claims = this.allClaims = [];
    if (this.content.isLocal) {
      const claimList = this.claimsList.getClaimsList(
        claimsAPIData.claims as unknown as Claim[],
        this.content
      );
      if ( this.hasHistoricalClaims ) {
        this.allClaims = claimList ;
      } else if ( !this.hasPharmacy ) {
        this.allClaims = this.removePharmacy(claimList);
      }else {
        this.allClaims = claimList ;
      }
      this.claims = this.allClaims;
      this.setFilterOptions(this.claims, filterState);
      this.filterClaims(filterState);
      this.claimSummaryLoader = false;
      this.claimsSummaryError = false;
      if (this.isMini) {
        this.claims = this.claims.length > this.content.mini.limit ? this.claims.slice(0, this.content.mini.limit) : this.claims;
        this.numClaimsAvailable = this.claims.length;
        this.filterDisclaimerMessage = this.content.mini.countMessage.replace('[[count]]', this.numClaimsAvailable.toString()).replace('[[claims]]', this.numClaimsAvailable === 1 ? this.content.mini.claim : this.content.mini.claims);
      } else {
        this.filterDisclaimerMessage = this.content.error.claimFilterDefaultMessage;
      }
    } else {
      this.claimSummaryLoader = false;
      this.claimsSummaryError = false;
      this.getClaimsFromAPI(filterState, claimsData);
    }
  }

  getClaimsFromAPI(filterState?: ClaimFilterWidgetState, claimsData?: any) {
    let params: ClaimSummaryParam = this.buildServiceParams(filterState);
    if(filterState) {
      this.claimsService.getClaimsSummary(params).subscribe(
        (data: any) => this.handleApiResponse(data.body, filterState),
        (error: any) => this.handleApiError(error.status, filterState)
      );
    } else {
      this.handleApiResponse(claimsData.body ? claimsData.body : claimsData, filterState);
    }
  }

  handleApiResponse(data: any, filterState?: ClaimFilterWidgetState) {
    if (data !== '404' && data !== '500') {
      const claimList = this.claimsList.getClaimsList(data.claims, this.content);
      const sumBilledAmt = this.calculateTotal('billedAmount');
      this.allClaims = this.hasHistoricalClaims ? claimList : (!this.hasPharmacy ? this.removePharmacy(claimList) : claimList);
      this.claims = this.allClaims;
      this.calculateAmounts();
      this.setTotalAmtContent(sumBilledAmt);
      this.setFilterOptions(this.claims, filterState);
      this.hasClaims = this.allClaims.length > 0;
      this.setClaimsAndMessage(filterState);
      this.countOfClaims = this.content.financial.totalClaimsCount.replace('[[count]]', this.allClaims.length.toString());
      this.claimSummaryLoader = false;
      this.claimsSummaryError = false;
    } else {
      this.handleApiError(data, filterState);
    }
  }

  handleApiError(error: any, filterState?: ClaimFilterWidgetState) {
    if (error === '404' || error === 404) {
      this.claimsSummaryError = true;
      let noclaimsMessage: string = this.content.error.noClaimDataFilter;
      const validNumbers = [24, 3, 6, 12];
      let selectedDateRange = filterState?.date;
      const isNumber = typeof selectedDateRange === "number";
      const isValidNumber = isNumber && validNumbers.includes(selectedDateRange);
      const isObject = typeof selectedDateRange === "object";
      const durationInYears = isObject ? this.getDurationInYears(selectedDateRange.fromDateRange, selectedDateRange.toDateRange) : null;
      if (this.isMini) {
        noclaimsMessage = this.content.mini.noClaimsAvailable;
      } else if (
        isValidNumber || (isObject && durationInYears >= 0)) {
        noclaimsMessage = isNumber ? this.content.error.noClaimsAvailable.replace('[[range]]', selectedDateRange.toString()) : noclaimsMessage;
      } else {
        this.filterCmp?.removeFilterOption();
      }
      this.showMessage("information", noclaimsMessage);
    } else {
      this.navigateToClaimDetail = this.widgetFilterState.claimTempId ? true : false;
      this.claimsSummaryError = true;
      this.showMessage("negative", this.content.error.claimServerError);
    }
    this.allClaims = this.claims = [];
    this.hasClaims = false;
    this.claimSummaryLoader = false;
  }

  calculateAmounts() {
    this.paidByPlan = this.calculateTotal('planPaidAmount');
    this.paidByYou = this.calculateTotal('yourResponsibilityAmount', true);
  }

  calculateTotal(field: string, checkNA?: boolean) {
    return this.allClaims.map(claimSummary => claimSummary[field])
      .reduce((previousValue, currentValue) => {
        return previousValue + Number(checkNA && currentValue === 'N/A' ? '0' : currentValue);
      }, 0);
  }

  setTotalAmtContent(sumBilledAmt) {
    const sumBilledAmtUpdated = Number.isInteger(sumBilledAmt) ? sumBilledAmt : sumBilledAmt.toFixed(2);
    this.totalAmtContent = this.content.financial.textValue.replace('[[count]]', sumBilledAmtUpdated.toString());
  }

  setClaimsAndMessage(filterState?: ClaimFilterWidgetState) {
    if (this.isMini) {
      this.claims = this.claims.length > this.content.mini.limit ? this.claims.slice(0, this.content.mini.limit) : this.claims;
      this.numClaimsAvailable = this.claims.length;
      this.filterDisclaimerMessage = this.content.mini.countMessage.replace('[[count]]', this.allClaims.length.toString()).replace('[[claims]]', this.numClaimsAvailable === 1 ? this.content.mini.claim : this.content.mini.claims);
    } else if (filterState) {
      this.filterClaims(filterState);
    } else {
      this.exportClaimsList = this.claims;
      this.numClaimsAvailable = this.claims.length;
      this.filterDisclaimerMessage = this.content.error.claimFilterDefaultMessage;
    }
  }

  removePharmacy(claims: ClaimSummary[]): ClaimSummary[] {
    return claims.filter((claim: ClaimSummary) => claim.classCode !== ClaimClassCode.PHARMACY);
  }

  /**
   * @description setContent() responsible for gettingfetching content from json
   * using content service and calling getClaims()
   * @returns {void}
   */
  private setContent(): void {
    this.genericError = '';
    if (this.dataSvc.content && !this.isMini) {
      this.content = this.dataSvc.content;
      this.hasFinancialSummary = this.dataSvc.hasFinancialSummary;
      this.hasPharmacy = this.dataSvc.hasPharmacy;
      this.hasHistoricalClaims = this.dataSvc.hasHistoricalClaims;
      this.paidByPlanLabel = this.content.financial.paidByPlan;
      this.yourResponsibilityLabel = this.content.financial.yourResponsibility;
      this.pieChartSubText = `${this.content.financial.subText}${new Date().getFullYear()}`;
      if (this.dataSvc.claimsError) {
        this.handleApiError(this.dataSvc.claimsError, this.widgetFilterState.claimTempId ? this.widgetFilterState : undefined);
      } else if (this.dataSvc.claimsSummary) {
        this.hasClaims = this.dataSvc.claimsSummary.body?.claims?.length > 0;
        this.allClaims = this.dataSvc.claimsSummary;
        this.countOfClaims = this.content.financial.totalClaimsCount.replace('[[count]]', this.allClaims?.length?.toString() ?? '0');
        this.getClaims(undefined, this.dataSvc.claimsSummary);
      } else {
        this.getClaims();
        this.hasClaims = this.allClaims.length > 0;
      }
    } else  {
      this.jsonSvc.getJSON('claims').subscribe((content: ClaimsContent) => {
        this.content = content;
        this.hasFinancialSummary = this.jsonSvc.hasRestriction( Restriction.SHM_CLAIMS_FINANCIAL, content.restrictions );
        this.hasPharmacy = !this.jsonSvc.hasRestriction(Restriction.SHM_NO_PHARMACY, content.restrictions);
        this.hasHistoricalClaims = this.jsonSvc.hasRestriction(Restriction.SHM_HISTORICAL_CLAIMS, content.restrictions);
        if (this.hasFinancialSummary) {
          this.paidByPlanLabel = this.content.financial.paidByPlan;
          this.yourResponsibilityLabel = this.content.financial.yourResponsibility;
          this.pieChartSubText = `${this.content.financial.subText}${new Date().getFullYear()}`;
        }
        this.isMini ? this.getClaims({ date: this.content.mini.defaultDateRangeMonth }): this.getClaims({ date: this.content.filter.defaultDateRangeMonth });
        this.hasClaims = this.allClaims.length == 0 ? false: true;
        this.countOfClaims = this.content.financial.totalClaimsCount.replace('[[count]]', this.allClaims.length.toString());
      },(error: any) => {
        this.genericError = 'We are unable to complete your request at this time. Please contact member services.';
      });
    }
  }

  /**
   * @description setFilterOptions() get the list of available filter options from the claims list
   * @param {ClaimSummary[]} claimList
   * @param {ClaimFilterWidgetState} [state]
   */
  private setFilterOptions(
    claimList: ClaimSummary[],
    state?: ClaimFilterWidgetState
  ): void {
    this.initialClaimTypes = [];
    this.initialProviders = [];
    this.initialStatusCodes = [];

    const claimTypeList: Set<string> = new Set();
    const claimsProviderList: Set<string> = new Set();
    const claimsStatusList: Set<string> = new Set();

    for (let i = 0; i < claimList.length; i++) {
      const claimClassCode = claimList[i].classCode.toUpperCase();

      if (!claimTypeList.has(claimClassCode)) {
        claimTypeList.add(claimClassCode);
        this.initialClaimTypes.push({
          label: this.content.filter[ClaimTypeFilterLabel + claimClassCode],
          value: claimClassCode,
        });
      }

      const providerName: string = claimList[i].providerName;
      if (!claimsProviderList.has(providerName)) {
        claimsProviderList.add(providerName);
        this.initialProviders.push({
          label: providerName,
          value: providerName,
        });
      }

      const statusCode: string = claimList[i].statusName;
      if (!claimsStatusList.has(statusCode)) {
        claimsStatusList.add(statusCode);
        this.initialStatusCodes.push({
          label: statusCode,
          value: statusCode,
        });
      }
    }

    if (state) {
      this.widgetFilterState.selectedClaimsType = state.selectedClaimsType;
      this.widgetFilterState.selectedProvider = state.selectedProvider;
      this.widgetFilterState.selectedStatuses = state.selectedStatuses;
    }
  }

  /**
   * @description showMessage() Shows a message in the widget body
   * @param {string} type
   * @param {string} msg
   * @returns {void}
   */
  private showMessage(type: string, msg: string): void {
    this.claimsErrorMsg = msg;
    this.claimsErrorType = type;
  }

  private updateFilterMessage(filter: ClaimFilterWidgetState) {
    return this.content.error.claimFilterMessage.includes('[[months]]') ?
      this.content.error.claimFilterMessage.replace('[[months]]', filter.date) :
      this.content.error.claimFilterMessage;
  }
}
