<div *ngIf="showToggle" class="container-fluid  public-language-toggle" #topMenu >
  <div class="text-right ant-secondary-nav public-language-toggle-wrapper">
    <ul>
      <li>
        <a 
            class="menu-button public-language-toggle-link"
            id="public-language-toggle"
            (click)="toggleLanguage()" 
            (keyup.enter)="toggleLanguage()" 
            data-analytics="profileLinkTopNav"
            tabindex="0">
                {{content?.labels.language}}
        </a>
      </li>
    </ul>
    </div>
  </div>