import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { TrizettoCostCalculatorLabels, TrizettoCostCalculatorErrorMessages, checkCostCalculatorUrl } from './models/labels.interface';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';

@Component({
  selector: 'app-trizetto-cost-calculator',
  templateUrl: './trizetto-cost-calculator.component.html',
  styleUrls: ['./trizetto-cost-calculator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrizettoCostCalculatorComponent implements OnInit {

  constructor(
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private httpSvc: HttpClientService
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Treatment Cost Calculator'
    });
  }

  public contentLoaded: boolean;
  public samlLoaded: boolean;
  public error: boolean;
  public samlError: boolean;
  public labels: TrizettoCostCalculatorLabels;
  public errorMsgs: TrizettoCostCalculatorErrorMessages;
  public samlUrl: string;
  public samlErrorMsg: string;

  @ViewChild('ssoForm') ssoForm: ElementRef;

  ngOnInit(): void {
    this.initializeVariables();
    this.getLabels();
  }

  private initializeVariables() {
    this.contentLoaded = false;
    this.error = false;
  }

  private getLabels() {
    this.jsonSvc.getJSON('trizetto-cost-calculator').subscribe(
      (res) => {
        if (res.TrizettoCostCalculator) {
          this.contentLoaded = true;
          this.error = false;
          this.labels = res.TrizettoCostCalculator.Labels;
          this.errorMsgs = res.TrizettoCostCalculator.ErrorMessages;
        } else {
          this.contentLoaded = false;
          this.error = true;
        }
      },
      (err) => {
        this.contentLoaded = false;
        this.error = true;
      }
    );
  }

  /**
   * @Description hasSSOConfigOrStaticUrl method used to check sso or static Urls
   * @returns checkCostCalculatorUrl
   */
  get hasSSOConfigOrStaticUrl(): checkCostCalculatorUrl {
    const { staticUrls, Config } = this.labels;
    return {
      hasStaticUrls: staticUrls ? !!staticUrls.url.length: false,
      hasSsoLink: Config ? !!Config.SSOId.length: false,
    }
  }

}
