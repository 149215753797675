import { Component, OnInit, Input } from '@angular/core';
import { PriorAuthDataService } from '../prior-auth-data.service';
import { PriorAuthorizationLabels, PriorAuthorizationErrorMessages, RX_STATUSES, PriorAuthorizationAnalytics } from '../models/labels';
import { PharmacyPriorAuthorizationService } from '../pharmacy-prior-authorization.service';
import { AlertService } from '@anthem/uxd/alert';

@Component({
  selector: 'app-pharmacy',
  templateUrl: './pharmacy.component.html',
  styleUrls: ['./pharmacy.component.scss']
})
export class PharmacyComponent implements OnInit {
  constructor(
    private dataSvc: PriorAuthDataService,
    private rxPriorAuthSvc: PharmacyPriorAuthorizationService,
    public alert: AlertService,
  ) { }

  filterDropDown: any;
  filterModelValue: string;

  @Input() labels: PriorAuthorizationLabels;
  @Input() analytics: PriorAuthorizationAnalytics;
  public errorMsgs: PriorAuthorizationErrorMessages;
  public authList: any;
  public summaryDataLoaded: boolean;
  public summaryError: boolean;
  public summaryErrorMsg: string;
  public decisionLetterErrorMsg: string;
  public alertType: string;
  readonly rxStatuses = RX_STATUSES;
  displaySize: number = 10;
  currentListIndex: number = this.displaySize;
  prescriptionList: any[];
  displayList: any[];
  downloadFileLoader: boolean = false;
  decisionLetterError: boolean = false;

  ngOnInit(): void {
    this.summaryDataLoaded = true;
    this.labels = this.dataSvc.labels;
    this.errorMsgs = this.dataSvc.errorMsgs;
    this.setupFilterDropdown();
    const dateRange = this.getDateRange('6');
    this.getSummary( dateRange.fromDate, dateRange.currentDate);
  }

  private setupFilterDropdown() {
    this.filterDropDown = {
      title: this.labels.filterDateRange,
      options: [
        {
          label: this.labels.filter6Months,
          value: "6",
          analytics: this.labels.rxAnalyticsfilter6Months
        },
        {
          label: this.labels.filter12Months,
          value: "12",
          analytics: this.labels.rxAnalyticsfilter12Months
        },
        {
          label: this.labels.filter18Months,
          value: "18",
          analytics: this.labels.rxAnalyticsfilter18Months
        },
        {
          label: this.labels.filter24Months,
          value: "24",
          analytics: this.labels.rxAnalyticsfilter24Months
        }
      ]
    };

    this.filterModelValue = this.filterDropDown.options[0].value;
  }

  private getDateRange(months) {
    const currentDate = new Date();
    let fromDate = new Date();
    let currentDateStr = '';
    let fromDateStr = '';

    fromDate.setMonth(fromDate.getMonth() - months);
    if (months === '24'){
      fromDate.setDate(fromDate.getDate() + 1);
    }
    currentDateStr = currentDate.toISOString().substring(0, 10);    
    fromDateStr = fromDate.toISOString().substring(0, 10);
    return {
      'currentDate': currentDateStr,
      'fromDate': fromDateStr
    };
  }

  public getSummary( fromDate: string, toDate: string) {
    this.summaryDataLoaded = false;
    this.summaryError = false;
    this.rxPriorAuthSvc.getRxPriorAuth(fromDate, toDate).subscribe(
      (res: any) => {
        this.prescriptionList = res.body.priorAuth;
        this.prescriptionList.sort((a, b) => b.requestDate.localeCompare(a.requestDate));
        this.displayList = this.prescriptionList.slice(0, this.displaySize);
        this.currentListIndex = this.displaySize;
        this.summaryDataLoaded = this.prescriptionList.length > 0;
        if (this.prescriptionList.length === 0) {
          this.summaryError = true;
          this.summaryErrorMsg = this.errorMsgs.NoResults;
          this.alertType = 'info';
        }
      },
      (err: any) => {
        this.summaryError = true;
        this.alertType = 'negative';
        if (err.error && err.error.message) {
          this.summaryErrorMsg = err.error.message;
        } 
      }
    );
  } 

  public viewDecisionLetter(decisionLetterId: string, authId: string) {
    this.downloadFileLoader = true;
    this.rxPriorAuthSvc.getDecisionLetter(decisionLetterId).subscribe((response) => {
      let response64 = this.base64ToArrayBuffer(response.body.content);
      let pdfBlob = new Blob([response64], { type: 'application/pdf' });
      const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
            ie11 = navigator.userAgent.match(/Trident\/7.0/) && navigator.userAgent.match(/rv:11/),
            ieEDGE = navigator.userAgent.match(/Edge/g),
            ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;

      const ipadIphone = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
      const fileName = 'decision_letter_' + authId + '.pdf';
      //for Microsoft browsers (IE & Edge)
      if (ieVer > -1) {
        const nav = (window.navigator as any);
        if (nav && nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(pdfBlob, fileName);
          return;
        }
      } else {
        //for rest of the browsers
        let url = URL.createObjectURL(pdfBlob);
        if (ipadIphone) {
          //Showing Modal due to popup blocker
          if (!window.open(url, "_blank", "noreferrer,noopener")) {
            alert("Please disable the popup blocker");
          }
        } else {
          window.open(url, "_blank", "noreferrer,noopener");
        }
      }      
      this.downloadFileLoader = false;
      this.decisionLetterError = false;
    },
    (error) => {
      this.downloadFileLoader = false;
      this.decisionLetterError = true;
      this.decisionLetterErrorMsg = this.errorMsgs.ServiceError;
    });
  }

  base64ToArrayBuffer(base64:any):ArrayBuffer {
    var binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public applyFilter() {
    const dateRange = this.getDateRange(this.filterModelValue);
    // Get the Summary Data using the Filter parameters
    this.getSummary(dateRange.fromDate, dateRange.currentDate);
  }

  loadMore(): void {
    if (this.currentListIndex < this.prescriptionList.length) {
      this.displayList = [
        ...this.displayList,
        ...this.prescriptionList.slice(this.currentListIndex, (this.currentListIndex += this.displaySize))
      ];
    }
  }

}