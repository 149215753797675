import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClient } from '@angular/common/http';
import { ClaimSummaryParam, ClaimDetailsAPIResponse } from '../interfaces/claims';
import { Claim } from 'gbd-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor(
    private httpSvc: HttpClientService,
    private http: HttpClient
    ) { }

  public getClaimsSummary(params: ClaimSummaryParam): Observable<Claim[]>  {
    return this.httpSvc.getObservable(UrlProperties.UrlProperties.endPoints.claims.summary, params)
  }

  public getClaimDetail(clmUId: string): Observable<ClaimDetailsAPIResponse> {
    let url = UrlProperties.UrlProperties.endPoints.claims.details;
    url = url.replace('{clmId}', clmUId)
    return this.httpSvc.getObservable(url);
  }

  public getEOBFile(eobUid: string): Observable<any> {
    const url = `${UrlProperties.UrlProperties.endPoints.claims.getEob}?documentId=${eobUid}`
    return this.http.post(url, undefined);
  }
}