import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PabAccounts } from '../interfaces/PharmacyDashboardContent';

@Component({
  selector: 'app-account-balance-payment',
  templateUrl: './account-balance-payment.component.html',
  styleUrls: ['./account-balance-payment.component.scss']
})
export class AccountBalancePaymentComponent implements OnInit {
  @Input() totalBalance: string;
  @Input() labels: PabAccounts;
  @Input() heading: string;
  @Input() analyticsTag: string;
  @Input() route: string;
  @Input() hasPBM: string;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  routeToPayBalance() {
    this.router.navigate([this.route, {loB: this.hasPBM}]);
  }

  convertToNumber(data: string): number {
    return +data;
  }
}
