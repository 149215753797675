import { EventEmitter, Inject, Injectable } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

/**
 *  Supports passing info to ssoForm after sso Params are received from Api
 */

@Injectable({
    providedIn: 'root'
  })
  export class SsoEventService {
    ssoEvent: EventEmitter<any> = new EventEmitter<any>();
    constructor() { }

    emit(ssoParams: any) {
        if(ssoParams && ssoParams.samlUrl && ssoParams.ssoParameters){
            this.ssoEvent.emit(ssoParams);
        }
    }
}