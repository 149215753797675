import { ChangeDetectorRef, Component, forwardRef, OnDestroy, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, ControlValueAccessor, Validators, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';

@Component({
  selector: 'lib-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true,
    }
  ]
})
export class PhoneComponent implements ControlValueAccessor, OnDestroy, OnChanges {

  form: FormGroup;
  subscriptions: Subscription[] = [];
  @Input('phoneLabel') phoneLabel: string;
  //@Input('errObj') errorsObj: object; //TODO
  @Input('errObj') errorsObj: any;  
  @Input('formSubmitted') formSubmitted: boolean;
  @Input('id') phoneId: string;
  @Input('editable') editable: boolean = false;
  @Input('errorMessageOverride') errorMessageOverride: string;
  @ViewChild('phoneForm') phoneForm: any;
  @Input('placeHolder') placeHolder: string;
  @Input() phoneTabIndex: boolean = true;

  get value() {
    return this.form.value;
  }

  set value(value) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get phone() {
    return this.form.controls.phone;
  }

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      phone: ['', [Validators.required, FormValidators.PhoneNumberValidator]]
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { phone: { valid: false, }, };
  }

  reset() {
    this.phoneForm.resetForm();
    this.form.reset();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.formSubmitted?.currentValue && this.form.invalid) {
      this.form.markAllAsTouched();
    }
  }
}

