import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  private token = '';

  public getJsonUrl(widgetName) {
    const memCtxUrl = document.getElementsByTagName('script');
    for (const prop in memCtxUrl) {
      if ((memCtxUrl[prop].type === 'application/json') &&
          (memCtxUrl[prop].id.toLowerCase() === widgetName)) {
           console.log(memCtxUrl[prop]);
           return (memCtxUrl[prop].src);
         } else {
          continue;
         }
    }

    return null;
    /* let jsonFound = false;
    const promise = new Promise((resolve, reject) => {
      const memCtxUrl = document.getElementsByTagName('script');

      for (const prop in memCtxUrl) {
        if ((memCtxUrl[prop].type === 'application/json') &&
            (memCtxUrl[prop].id.toLowerCase() === widgetName)) {
             console.log(memCtxUrl[prop]);
             jsonFound = true;
             resolve(memCtxUrl[prop].src);
           } else {
            continue;
           }
      }
      if (!jsonFound) {
        reject();
      }
    });

    return promise; */
  }

  public getMemCtx(jsonUrl) {
    return this.http.get(jsonUrl, {observe: 'response'});
    /* const promise = new Promise((resolve, reject) => {
      this.http.get(jsonUrl, {observe: 'response'})
      .toPromise()
      .then(
        res => {
          if (res.status === 200 && typeof res.body === 'object') {
            resolve(res.body);
          } else {
            reject(res);
          }
        },
        err => {
          reject(err);
        }
      );
    });

    return promise; */
  }

  public setToken(token) {
    this.token = token;
  }

  public getToken() {
    return this.token;
  }
}
