import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SecurityQuestionsModelService {

  constructor() { }

  private questionAnswers: Array<object> = [];
 
  public setQuestionAnswers(question, answer) {
    if(this.questionAnswers.length >= 0) {
      this.questionAnswers.push({question: question, answer: answer});
    }
  }

  public getQuestionAnswers() {
    return {questionAnswers: this.questionAnswers};
  }
}
