<div class="chat-body" *ngIf="!liveAgentChatStrated && labels" #chatTranscript>
  <div class="sydney-logo"></div>
  <div class="sydney-caption">{{ labels.iAmSydney }}</div>
  <div *ngIf="showError" role="alert">
    <div class="ant-alert ant-negative">
      <div class="ant-alert-left"><span class="alert-icon alert-icon-negative"></span></div>
      <div class="ant-alert-main">
        <p><span><span id="pGet_error2" tabindex="0" class="api-errors" [innerHTML]="content?.chatbot?.defaultErrorMessage"></span></span></p>
      </div>
    </div>
  </div>
    <ng-template ngFor let-element [ngForOf]="chatMessageList.msg" let-i="index">
      <ng-container [ngSwitch]="element.typeOfOption">
        <ng-container *ngSwitchCase="'bubble_text'">
          <div *ngIf="element.msgSubType === 'USER'" class="ant-chat-bubble"
            [ngClass]="{'ant-chat-bubble-user': element.msgSubType === 'USER', 'last': i===0}">
            <p [innerHTML]="element.text"></p>
          </div>
          <div *ngIf="element.msgSubType !== 'USER'" class="ant-chat-bubble"
            [ngClass]="{'ant-chat-bubble-bot': element.msgSubType === 'BOT' || element.msgSubType === 'AGENT'|| element.msgSubType === 'SURVEY' , 'last': i===0}">
            <p [innerHTML]="element.text"></p>
            <span *ngFor="let link of element?.links" class="ant-chat-bubble-bot-wrapper-text-links">
              <p *ngIf="link.type === 'URL'">
                <a class="link_cursor" href="{{link.link}}" target="_blank" [innerHTML]="link.text"></a>
                <span class="motif-icon motif-external-link"></span>                
              </p>
              <p *ngIf="link.type === 'PHONE'">
                <a class="link_cursor" href="tel:{{link.link}}" target="_self" [innerHTML]="link.text"></a>
              </p>
            </span>  
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'error_text'">
          <div class="ant-chat-bubble ant-chat-bubble-bot">
            <div class="error message agent" [innerHTML]="element.text"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'bubble'">
          <div class="category padding"></div>
          <div *ngIf="i === lastMessageIndex">
            <div class="category messages clear" *ngFor="let button of element.buttons">
              <div class="message">
                <button class="ant-chat-pill" attr.aria-label="{{button.name}}" [disabled]="element.selected"
                  (click)="sendChat(button.text.text, button.value)" [innerHTML]="button.text.text">
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'dialog'">
          <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" role="group"
            attr.aria-labelledby="redirect-success-{{i}}" #redirectSuccess uxd-select tabindex="0" #dialog>
            <p class="ant-action-list-label" id="redirect-success-{{i}}">
              {{labels?.youAreNowOn | si:{page: element.page} }} {{labels?.anythingElse}}
            </p>
            <button class="ant-action-list-button" uxd-select-toggle (click)="showBotOptions(i)" aria-label="yes"
              data-analytics="" [ngClass]="{'selected':element.selected == 'yes'}"
              [disabled]="element.selected">{{labels?.chatbotYes}}</button>
            <button class="ant-action-list-button" uxd-select-toggle (click)="endChatbot(i)" aria-label="no"
              data-analytics="" [ngClass]="{'selected':element.selected == 'no'}"
              [disabled]="element.selected">{{labels?.chatbotNo}}</button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'chatDialog'">
          <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" role="group"
            attr.aria-labelledby="chat-suggestion-{{i}}" uxd-select tabindex="0" #chatDialog>
            <p class="ant-action-list-label" id="chat-suggestion-{{i}}">
              {{labels?.sorryLiveAgent}}
            </p>
            <button class="ant-action-list-button" uxd-select-toggle (click)="launchLiveChat(i)" aria-label="yes"
              data-analytics="" [ngClass]="{'selected':element.selected == 'yes'}"
              [disabled]="element.selected">{{labels?.chatbotYes}}</button>
            <button class="ant-action-list-button" uxd-select-toggle (click)="liveChatNo(i)" aria-label="no"
              data-analytics="" [ngClass]="{'selected':element.selected == 'no'}"
              [disabled]="element.selected">{{labels?.chatbotNo}}</button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'inlineIdcard'">
          <div class="id-card-list">
            <app-chatbot-idcard [frontIdcardBase64String]="element?.idCardInfo?.frontImgBase64String"
              [backIdcardBase64String]="element?.idCardInfo?.backImgBase64String"
              [content]="content?.chatbot?.idcardsMiniWidgetComponent">
            </app-chatbot-idcard>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'InlineClaims'">
          <ng-container *ngFor="let claim of element.claimInfo">
            <div class="ant-chat-bubble-bot-wrapper">
              <div class="ant-chat-bubble ant-chat-bubble-fullwidth ant-chat-bubble-bot ant-chat-bubble-bot-whitespacereset">
                <div class="ant-chat-claiminfo">
                  <div class="ant-chat-claiminfo-header">
                    <div class="col1">            
                      <h2>
                        <b><span class="ant-chat-claim-subheader-status" [innerHTML]="getClaimStatus(claim.claimStatus)"></span></b>
                        <span class="ant-chat-claim-subheader-claimnumber" [innerHTML]="'#'+claim.claimNumber"></span>
                        <span aria-hidden="true" [ngClass]="getStatusClass(claim.claimStatus)" class="ant-chat-claim-subheader-statusicon">
                          <span class="motif-icon" [ngClass]="getStatusIcon(claim.claimStatus)"></span>
                        </span>
                      </h2>
                      <div class="ant-chat-claim-location">
                        <span *ngIf="claim.providerName" class="motif-icon motif-icon-blue motif-clipboard"></span> <b><span class="sms-locationanddate-text"
                          [innerHTML]="claim.providerName"></span></b>
                      </div>
                      <div class="ant-chat-claim-date">
                        <span *ngIf="claim.claimStartDt" class="motif-icon motif-icon-blue motif-calendar"></span> <b><span class="sms-locationanddate-text"
                          [innerHTML]="claim.claimStartDt"></span></b>
                      </div>
                    </div>
                    <div class="col2">
                      <div class="ant-chat-claim-info-youpaycard-wrapper">
                        <div class="ant-chat-claim-info-youpaycard">
                          <h4 class="ant-chat-claim-info-statementtitle" [innerHTML]="labels.statement"></h4>
                          <span class="ant-chat-claim-info-youpay-title" [innerHTML]="labels.youPay"></span>
                          <span class="ant-chat-claim-info-youpay-amount">
                            <span class="ant-chat-claim-amount-dollarsign" [innerHTML]="labels.dollarSign"></span>
                            <span class="ant-chat-claim-amount-dollars"
                              [innerHTML]="claim.memberResponsibilityDollarAmt"></span>
                            <span class="ant-chat-claim-amount-cents" [innerHTML]="claim.memberResponsibilityCentAmt"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <uxd-expansion-panel #panel>
                    <uxd-expansion-header>
                      <uxd-expansion-toggle>
                        <span class="motif-icon motif-angle-down" [id]="'button-icon-claims'"
                          [ngClass]="{ 'open' : panel.expanded }"></span>
                        <span class="sr-only"></span>
                      </uxd-expansion-toggle>
                      <span class="expansion-title ant-heading-five" [innerHTML]="claim.statusDescriptionHeader"></span>
                    </uxd-expansion-header>
                    <p *ngIf="claim.claimStatus === claimStatusEnum.APPROVED || claim.claimStatus === claimStatusEnum.PENDING || claim.claimStatus === claimStatusEnum.PAID">
                      <span [innerHTML]="claim.statusDescriptionBody"></span>
                    </p>
                    <div class="ant-content-wrapper" role="region">
                      <div *ngIf="claim.deniedReasonCodes">
                        <span class="ant-chat-claim-denied-drawer" [innerHTML]="claim.deniedReasonCodes?.drawerName"></span>
                        <div *ngFor="let item of claim.deniedReasonCodes.items">
                          <p class="ant-chat-claim-denied-code" [innerHtml]="item.title"></p>
                          <p class="ant-chat-claim-denied-decription" [innerHTML]="item?.description"></p>
                          <p class="ant-chat-claim-denied-seperator"></p>
                        </div>
                      </div>                        
                      <br>
                    </div>
                    <p *ngIf="claim.claimDetailsUrl">
                      <span class="motif-icon motif-right-round"></span> <a class="link_cursor" [innerHTML]="labels.claimsDetails"
                        (click)="redirect({type:'navigation', value:claim.claimDetailsUrl, appUrlParams:{'clmid':claim.claimNumber}})"></a>
                    </p>
                  </uxd-expansion-panel>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  <div class="ant-chat-bubble ant-chat-bubble-bot" *ngIf="showNoResponse">
    <p>{{ labels?.gladToHelp }}</p>
  </div>
  <div class="ant-chat-bubble ant-chat-bubble-bot typing" aria-label="agent typing" *ngIf="loadingSpinner">
    <div></div>
  </div>
</div>
<div #sidePanelCmp data-tcp-sidepanel-cmp (onClose)="closeRenderModal()"
  [showCloseButton]="'true'">
  <div *ngIf="drawerInfo" class="chat-bot-slide-in">
    <div *ngIf="drawerInfo.type ==='limitation'">
      <h2 class="slide-in-header" [innerHTML]="drawerInfo.limitation?.drawerName"></h2>
      <h3 class="slide-in-header ant-heading-six" [innerHTML]="'<b>'+drawerInfo.limitation?.title">
      </h3>
      <div *ngFor="let item of drawerInfo.limitation?.items">
        <div data-uxd-expand-collapse-wcs>
          <div class="wcs-ec-heading-content">
            <h3 [innerHtml]="item.title"></h3>
          </div>
          <div class="wcs-ec-body-content" [innerHTML]="item?.description"></div> <br>
        </div>
      </div>
    </div>

    <div *ngIf="drawerInfo.type ==='rendermodal'">
      <div class="specialization-tab-results-list">
        <h2 class="slide-in-header ant-heading-two" [innerHTML]="'<b>'+drawerInfo.modalChatMessage?.text+'</b>'">
        </h2>
        <div class="ng-untouched ng-pristine ng-valid" *ngIf="radioOptions" data-uxd-radio-input-group-cmp
          [(ngModel)]="selectedRenderModalItem" ngDefaultControl name="radioGroup" [radioGroup]="radioOptions">
        </div>
        <br>
        <div *ngIf="radioOptions.options.length" class="chat-bot-slide-in-footer">
          <button class="btn btn-secondary" (click)="closeRenderModal()" [innerHTML]="content.chatbot?.renderModalCancelLabel"></button>
          <button [disabled]="!selectedRenderModalItem" (click)="handleRenderModalClick()"
            [innerHTML]="content.chatbot?.renderModalApplyLabel" class="btn btn-primary"></button>
        </div>
        <br>
        <h5 class="ant-heading-five" [innerHTML]="drawerInfo.modalChatMessage?.additionalInfo?.title"></h5>
        <br>
        <ng-container *ngFor="let infoSection of drawerInfo.modalChatMessage?.additionalInfo?.items;let i=index">
          <uxd-expansion-panel [expanded]="i === 0" #panel>
            <uxd-expansion-header>
              <uxd-expansion-toggle>
                <span class="motif-icon motif-angle-down" [id]="'button-icon'+i"
                  [ngClass]="{ 'open' : panel.expanded }"></span>
                <span class="sr-only"></span>
              </uxd-expansion-toggle>
              <span class="expansion-title ant-heading-five" [innerHTML]="infoSection.title"></span>
            </uxd-expansion-header>
            <div class="ant-content-wrapper" role="region">
              <div class="chat-agent-hours-closed" [innerHTML]="infoSection.highlightTitle"></div>
              <ng-container *ngFor="let subSection of infoSection.items">
                <h2 class="ant-heading-five" [innerHTML]="subSection.title"></h2>
                <ng-container *ngFor="let subSectionItem of subSection?.items">
                  <div class="chat-agent-hours-grid">
                    <div class="row">
                      <div class="col-xs-6">
                        <span [innerHTML]="subSectionItem.text"></span>
                      </div>
                      <div class="col-xs-6 text-right">
                        <span [innerHTML]="subSectionItem.value"></span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <br>
            </div>
          </uxd-expansion-panel>
        </ng-container>
      </div>
    </div>
    </div>
    </div>