<div class="ltss">
    <h2 class="section-header-two">{{content.memberLiability.title}}</h2>
    <p class="subtitle">{{content.memberLiability.subTitle}}</p>
    <div class="cards-wrapper">
        <div class="card large-card card-flat card-popover">
            <div class="total-container">
                <ng-container *ngIf="memberLiability; else isError">
                    <p class="total-label">{{content.memberLiability.amountToPayLabel}}</p>
                    <div class="right-container">
                        <p class="total"> {{memberLiability.amount | currency: 'USD'}}</p>
                        <p *ngIf="memberLiability.startDate && memberLiability.endDate">{{content.memberLiability.between}}
                            {{memberLiability.startDate |date: 'MMMM d'}} - {{memberLiability.endDate |date: 'MMMM d'}}
                        </p>
                    </div>
                </ng-container>
                <ng-template #isError>
                    <div *ngIf="memberLiabiltyError" class="container"  data-uxd-alert-cmp-v2
                          [alert]="{alertType: 'negative'}">
                         <span [innerHTML]="memberLiabiltyError"></span>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>