import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TrizettoCostCalculatorComponent } from './trizetto-cost-calculator.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
  {path: 'secure/treatment-cost-calculator', component: TrizettoCostCalculatorComponent, pathMatch: 'full', canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrizettoCostCalculatorRoutingModule { }
