<div *ngIf="!loading" id="appealsGrievances-id" class="uxd-theme-motif container appealsAndGrievances">

    <!-- Alert display area -->
    <div class="margin-top-20 margin-bottom-10" *ngIf="appealsAndGrievancesServiceError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span [innerHtml]="appealsAndGrievancesServiceError"></span>
    </div>

    <div class="section-header padding-bottom-40 padding-top-40">
        <div class="section-header-three-line"></div>
        <h6 class="margin-0" [innerHtml]="defaultLabels?.subHeader"></h6>
        <h2 class="section-header-two" [innerHtml]="labels?.header"></h2>
    </div>
    <p class="font-14 font-optional-color" [innerHtml]="labels?.formDescription"></p>
    <ul id="section-list-description" class="ext-desc">
        <li *ngFor="let lists of defaultLabels?.bulletDesc">
            <span class="font-optional-color" [innerHTML]="lists"></span>
        </li>
    </ul>

    <form id="AppealsAndGrievancesForm" [formGroup]="appealsAndGrievancesForm" novalidate #appealsAndGrievancForms="ngForm"
        (ngSubmit)="submitAppealsAndGrievancesForm()" libInvalidInputFocus>
    
        <!-- Member Information -->
        <app-member-information [appealsAndGrivencesFormSubmitted]="appealsAndGrievancForms.submitted"
            formControlName="memberInformation" [memberLabels]="memberInformationLabels"
            [errorMessageLabels]="errorMsgsLabels">
        </app-member-information>

         <!-- Grievance Health Option -->
         <app-grievance-health-option [formSubmitted]="appealsAndGrievancForms.submitted" formControlName="healthOption"
          [labels]="healthOptionLabels" [errorMsgs]="errorMsgsLabels"
          *ngIf="activePath === 'grievance'">
        </app-grievance-health-option>
    
        <!-- Reason for Request -->
        <app-reason-for-request [formSubmitted]="appealsAndGrievancForms.submitted" formControlName="reasonForRequests"
            [labels]="reasonForRequestLabels" [defaultLabels]="defaultLabels" [errorMsgs]="errorMsgsLabels"
            *ngIf="activePath === 'grievance'; else appeal">
        </app-reason-for-request>
    
        <ng-template #appeal>
            <!-- Describe Your Situation -->
            <app-describe-your-situation [labels]="describeYourSituationLabels" [errorMsgs]="errorMsgsLabels"
                [formSubmitted]="appealsAndGrievancForms.submitted" formControlName="describeYourSituation">
            </app-describe-your-situation>
        </ng-template>
    
        <!-- Description of Specific Issue -->
        <app-issue-description [formSubmitted]="appealsAndGrievancForms.submitted" [defaultLabels]="defaultLabels"
            formControlName="issueDescription" [errorMsgs]="errorMsgsLabels" [labels]="labels"
            [descriptionLabels]="descriptionOfSpecificIssueLabels">
        </app-issue-description>

        <!-- Attach Files  -->
        <app-file-upload [fileTypes]="fileTypes" *ngIf="!UploadAttachmentsEnabled" [attachmentActionType]="attachmentActionType" [attachFiles]="attachFiles" [attachmentErrorMsgs]="attachmentErrorMsgslabels" (opError)="onVirusScanFileAttachmentError($event)" [errorMsgs]="errorMsgsLabels"></app-file-upload>
       
        <!-- Consent Information -->
        <app-consent-information [appealsAndGrivencesFormSubmitted]="appealsAndGrievancForms.submitted"
            formControlName="consentInformation" [consentLabels]="consentInformationLabels"
            [errorMessageLabels]="errorMsgsLabels" [uploadAttachmentRestriction]="UploadAttachmentsEnabled">
        </app-consent-information>

        <div class="button-group margin-top-25 margin-bottom-80">
            <button type="submit"
                attr.data-analytics="{{labels?.form?.previewSendBtnAnalytics}}" id="Sent_btn"
                class="btn btn-primary width-100 custom-btn-size margin-bottom-20"
                [attr.aria-label]="defaultLabels?.form?.next_btn" [innerHtml]="defaultLabels?.form?.next_btn"></button>
            <button type="button" id="Cancel_btn"
                attr.data-analytics="{{labels?.form?.cancelBtnAnalytics}}"
                [attr.aria-label]="defaultLabels?.form?.cancel_btn"
                class="btn btn-secondary width-100 custom-btn-size margin-bottom-20"
                [innerHtml]="defaultLabels?.form?.cancel_btn" (click)="openCancelModal()"></button>
        </div>
    </form>
    
    <ng-template #sidePanelContent>
        <div>
            <div class="clearfix">
                <button class="close" (click)="closePreview()" aria-label="Close"><span class="close-icon"></span><span hidden>Close Preview</span></button>
            </div>
            <app-review-slideout [activePath]="activePath" (closeSlide)="closePreview()" [modalRef]="modalRef" [formValues]="formValues" [uploadAttachmentRestriction]="UploadAttachmentsEnabled">
            </app-review-slideout>
        </div>
    </ng-template>
    
    <ng-template #cancelAppealsAndGrivencesAlert>
        <div class="delete-modal modal-md padding-15">
            <button class="close motif-icon motif-delete delete-icon" aria-label="Close Modal" (click)="onCloseModal()" attr.data-analytics="{{labels?.cancelModal?.cancelBtnCancelModalAnalytics}}">
            </button>
            <div class="modal-body padding-30 margin-top-20">
                <h2 [innerHTML]="cancelModalPopup?.header"></h2>
                <p class="margin-top-10 font-14" [innerHTML]="cancelModalPopup?.description"></p>
                <div class="button-group cancelPopup-bottons border-none margin-top-30">
                    <button class="btn btn-secondary cancelPopup-btn" [attr.aria-label]="cancelModalPopup?.continue_btn" attr.data-analytics="{{labels?.cancelModal?.cancelBtnCancelModalAnalytics}}"
                        (click)="onCloseModal()" [innerHTML]="cancelModalPopup?.continue_btn"></button>
                    <button class="btn btn-primary continue cancelPopup-btn" attr.data-analytics="{{labels?.cancelModal?.imSureBtnCancelModalAnalytics}}"
                        [attr.aria-label]="cancelModalPopup?.confirm_btn" (click)="cancelCheck()"
                        [innerHTML]="cancelModalPopup?.confirm_btn"></button>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<!-- Loader -->
<ng-container *ngIf="loading">
    <div class="row">
        <div class="tabbed-view-wrapper col-xs-12 loader">
            <uxd-spinner class="custom-updating-loader"></uxd-spinner>
            <p class="text-center font-16" [innerHtml]="'Please wait, Page is loading...'"></p>
        </div>
    </div>
</ng-container>