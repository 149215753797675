import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileNavComponent } from './profile-nav/profile-nav.component';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { SharedComponentsModule } from 'sydmed/libs/shared-components/src/lib/shared-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NavigationModule } from '@anthem/uxd/navigation';
import { SidebarRailModule } from '@anthem/uxd/navigation';

import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { PaymentMethodsModule } from 'sydmed/libs/pharmacy-components/payment-methods/payment-methods.module';

import { InputModule } from '@anthem/uxd/input';
import { AlertModule } from '@anthem/uxd/alert';
import { UxHelper } from '@anthem/uxd/util';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { ProfileDetailService } from './services/profile-details.service';

@NgModule({
  declarations: [
    AppComponent,
    ProfileNavComponent,
  ],
  imports: [
    AppRoutingModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    SidebarRailModule,
    NavigationModule,
    CustomValidatorsModule,
    InputModule,
    AlertModule,
    IndicatorModule,
    DropdownModule,
    PaymentMethodsModule
  ],
  providers: [
    UxHelper,
    ProfileDetailService
    //{ provide: APP_BASE_HREF, useValue: '/' },
    //{ provide: HTTP_INTERCEPTORS, useClass: HttpClientService, multi: true }
    // DataResolverService
  ],
  bootstrap: [AppComponent]
})

export class ShmMyProfileModule { }
