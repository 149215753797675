import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { PhoneFormatDirective } from './phone-format.directive';
import { DateFormatDirective } from './date-format.directive';
import { AlphaNumericOnlyDirective } from './alpha-numeric-only.directive';
import { ZipCodeFormatDirective } from './zip-code-format.directive';
import { InvalidInputFocusDirective } from './invalid-input-focus.directive';

@NgModule({
  declarations: [NumbersOnlyDirective, PhoneFormatDirective, DateFormatDirective, AlphaNumericOnlyDirective, ZipCodeFormatDirective,InvalidInputFocusDirective],
  imports: [
    CommonModule
  ],
  exports: [NumbersOnlyDirective, PhoneFormatDirective, DateFormatDirective, AlphaNumericOnlyDirective, ZipCodeFormatDirective,InvalidInputFocusDirective]
})
export class CustomValidatorsModule { }
