import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DropDown, PayNowLabels } from '../models/labels';
import { PaymentMethodDataService } from 'sydmed/src/app/secure/pharmacy/services/payment-method-data.service';
import { AlertService } from '@anthem/uxd/alert';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { HelperProperties } from '../models/helper.properties';
import { CheckBox } from '../models/labels';
import { PaymentMethod } from '../../interfaces/PaymentMethod';
import { AddressSelection } from '../../interfaces/PayNow';
import { PaymentHelperService } from '../../services/payment-helper.service';
import { PaymentPortalService } from '../../services/payment-portal.service';
import { CheckBoxNames } from '../models/enum-values';
import { Address, AddressType, PaymentType, UpdatePaymentAction, UpdatePaymentMethodRequest } from 'gbd-models';
import { ValidationStatus } from 'gbd-models/src/features/validateBankAccount';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
@Component({
  selector: 'app-bank-paynow',
  templateUrl: './add-bank-paynow.component.html',
  styleUrls: ['./add-bank-paynow.component.scss']

})
export class AddBankPayNowComponent implements OnInit {
  addBankForm: FormGroup;
  addAddressForm: FormGroup;

  accountName: string;
  isChecked: boolean = false;
  helperProperties: HelperProperties;
  loader: boolean = false;
  errMsg: string = null;

  selectAccountType: DropDown = {
    title: '',
    options: []
  };

  selectState: DropDown = {
    title: '',
    options: []
  };
  checkbox: CheckBox;
  checkboxBusiness: CheckBox;
  isEdit: boolean = false;

  // provide this parameter with false if buttons are not needed.
  @Input('showButtons') showButtons: boolean = true;
  @Input() autopay: boolean;
  labels: PayNowLabels;
  // To add specialty card
  @Input('paymentMethodId') paymentMethodId;
  @Input('selectedAddressId') selectedAddressId;
  @Input('addressPaymentRadioGroup') addressPaymentRadioGroup;
  @Input('bankPaymentRadioGroup') bankPaymentRadioGroup;

  @Input() showSuccess = true;
  errObj: any;
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private _helper: PaymentHelperService,
    private _service: PaymentPortalService,
    protected paymentDataSvc: PaymentMethodDataService,
    private alertSvc: AlertService,
    private errorCodeMapperSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService
  ) {
    this.helperProperties = new HelperProperties(alertSvc);
    this.addBankForm = this.formBuilder.group({
      accountType: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]],
      accountNumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(12)]],
      accountHolderName: ['', [Validators.required]],
      paymentNickName: ['', [Validators.nullValidator]],
      isBusinessAccount: [this.isChecked],
      futureUse: [this.isChecked]
    });
    this.addAddressForm = this.formBuilder.group({
      addressLine1: ['', [Validators.required]],
      addressLine2: ['', Validators.nullValidator],
      zipCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
    })
    this.getLabels();
  }

  ngOnInit(): void {
    this.helperProperties.scrollToTop();
    this.setCheckboxParams();
    if (this.addressPaymentRadioGroup && this.addressPaymentRadioGroup.options.length > 1) {
      this.selectedAddressId = this.addressPaymentRadioGroup.options[0].value;
    }
    this.form_accountNumber.valueChanges.subscribe(res => {
      if (res) {
        this.form_accountNumber.setValue(res.trim(), { emitEvent: false });
      }
    });
    this.form_routingNumber.valueChanges.subscribe(res => {
      if (res) {
        this.form_routingNumber.setValue(res.trim(), { emitEvent: false });
      }
    });
  }

  getLabels() {
    this.jsonSvc.getJSON('pay-now').subscribe(data => {
     this.labels = data.AddCardLabels;
     this.errObj = data.ErrMsgs;
     this.setCheckboxParams();
     this.setAccountTypeOptions();
     this.setStateOptions();
   });
 }

  ngOnChanges(changes) {
    this.setDefaultValuesForInputs(changes);
    if (this.autopay) {
      this.futureUse.setValue(true);
    }
  }

  setDefaultValuesForInputs(changes) {
    if (!changes.hasOwnProperty('showCheckBox')) {
      this.futureUse.setValue(false);
    }
    this.futureUse.updateValueAndValidity();
  }

  setStateOptions() {
    this.selectState.options = this.labels?.StateValues;
  }

  setAccountTypeOptions() {
    this.selectAccountType.options = this.labels?.AccountTypeValues;
  }

  setCheckboxParams() {
    this.checkbox = this.helperProperties.setCheckboxParams(
      CheckBoxNames.CARD_CHECKBOX,
      CheckBoxNames.CARD_CHECKBOX,
      this.labels?.Labels?.CheckBoxMessage
    );
    this.checkboxBusiness = this.helperProperties.setCheckboxParams(
      CheckBoxNames.CHECKING_BUSINESS_CHECKBOX,
      CheckBoxNames.CHECKING_BUSINESS_CHECKBOX,
      this.labels?.Labels?.ThisBusinessBankingAccount
    );
  }

  setSelectedPaymentMethodAndClose(paymentInfo: PaymentMethod, isAdded: boolean, error: any) {
    this._helper.setSelectedPaymentMethod(paymentInfo, isAdded, error);
    this.helperProperties.scrollToTop();
    this.onCancel();
  }

  addCard() {
    if (this.addBankForm.invalid && this.selectedAddressId != AddressSelection.ADD_NEW_ADDRESS) { this.addBankForm.markAllAsTouched(); return; }

    if (this.addAddressForm.invalid && this.selectedAddressId === AddressSelection.ADD_NEW_ADDRESS) { this.markFormTouched(); return; }    

    if (!this.form_futureUse.value) {
      const selectedAddress = this.addressPaymentRadioGroup.options.find(addressOption => addressOption.value === this.selectedAddressId).payment;
      // add new credit Card locally
      if (this.addBankForm.valid) {
        const paymentMethod: PaymentMethod = {
          accountName: this.form_accountType.value,
          bankAccountNumber: this.form_accountNumber.value,
          bankAccountType: this.form_accountType.value,
          routingNumber: this.form_routingNumber.value,
          isBankAcc: true,
          tokenId: null,
          accountHolderInfo: {
            billingAddress: this.getAddress(),
            isDefaultMethod: false,
            nameOnAccount: this.form_accountHolderName.value,
            nickname: this.form_paymentNickName.value
          }
        };
        this.setSelectedPaymentMethodAndClose(paymentMethod, false, null);
      }

    } else {
      if (this.addBankForm.valid) {
        this.loader = true;
        let paymentPayload: UpdatePaymentMethodRequest;
        paymentPayload = {
            action: UpdatePaymentAction.CREATE,
            paymentMethod: {
              accountDetails: {
                routingNumber: this.form_routingNumber.value,
                bankAccountType: this.form_accountType.value,
                accountNumber: this.form_accountNumber.value,
              },
              accountHolderInfo: {
                billingAddress: this.getAddress(),
                isDefaultMethod: false,
                nameOnAccount: this.form_accountHolderName.value,
                nickname: this.form_paymentNickName.value
              },
              accountType: PaymentType.BANK_ACCOUNT
            }
        };


        this._service.updatePaymentMethod(paymentPayload).pipe(mergeMap((res) => {
          if (res?.bankAccountValidation?.validationStatus === ValidationStatus.ACCEPTED) {
            return of(res);
          }
          throw of(res?.bankAccountValidation?.validationStatus);

        })).subscribe((res) => {
          const updatePaymentMethodResponse = res?.paymentMethod;
          if (updatePaymentMethodResponse) {
            const paymentMethod: PaymentMethod = {
              accountHolderInfo: {
                billingAddress: updatePaymentMethodResponse?.accountAddress1,
                isDefaultMethod: false,
                nameOnAccount: updatePaymentMethodResponse?.accountHolderName
              },
              bankAccountNumber: updatePaymentMethodResponse?.bankAccountNumber,
              bankAccountType: updatePaymentMethodResponse?.bankAccountType,
              isBankAcc: true,
              routingNumber: updatePaymentMethodResponse?.routingNumber,
              tokenId: updatePaymentMethodResponse?.tokenId ?? ''
            };
            paymentMethod.accountName = updatePaymentMethodResponse?.accountName ?? this.getAccountName(paymentMethod);
            this.setSelectedPaymentMethodAndClose(paymentMethod, true, null);
          }
          this.loader = false;
        }, (error: any) => {
          this.loader = false;
          const errorCode = (error === ValidationStatus.DECLINED_2001 || error === ValidationStatus.DECLINED_2002) ? error : 'error';
          this.setSelectedPaymentMethodAndClose(null, false, errorCode);
        });
      }
      else {
        this.markFormTouched();
      }
    }

  }

  getAccountName(payment: PaymentMethod) {
    const options = (this.bankPaymentRadioGroup.options).filter((element, index) => index < (this.bankPaymentRadioGroup.options).length - 1);
    if (options.length > 0) {
      const accountName = options[options.length - 1].payment.accountName;
      const index = parseInt((accountName.split('_')[2])) + 1;
      return payment.bankAccountType + '' + payment.routingNumber + '_' + index;
    }
    else {
      return payment.bankAccountType + '' + payment.routingNumber + '_0';
    }

  }

  setErrorAndClose() {
    this._helper.initiateSelectedPaymentMethod();
    this.helperProperties.scrollToTop();
    this.onCancel();
  }

  markFormTouched() {
    this.addBankForm.markAllAsTouched();
    this.addAddressForm.markAllAsTouched();
  }

  onCancel() {
    this.closePanel.emit(true);
  }

  onPaste(event: ClipboardEvent, fieldName: string) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.trim();
    this.addBankForm.get(fieldName).setValue(trimmedText);
  }
  getAddress() {
    if (this.selectedAddressId === AddressSelection.ADD_NEW_ADDRESS && this.addAddressForm.valid) {
      return this.populateAddress(this.formCity.value, this.formState.value, this.formAddressLine1.value, this.formZipCode.value, this.formAddressLine2.value)
    } else {
      let selectedAddress = this.addressPaymentRadioGroup.options.find(addressOption => addressOption.value === this.selectedAddressId).payment;
      return this.populateAddress(selectedAddress.city, selectedAddress.state, selectedAddress.streetAddress1, selectedAddress.zipCode, selectedAddress.streetAddress2);
    }
  }
  populateAddress(city: string, state: string, streetAddress1: string, zipCode: string, streetAddress2?: string): Address {
    return {
      addressType: AddressType.HOME,
      city,
      state,
      streetAddress1,
      streetAddress2: streetAddress2 ?? '',
      zipCode
    }
  }

  get form_accountType() { return this.addBankForm.get('accountType') }
  get form_routingNumber() { return this.addBankForm.get('routingNumber') }
  get form_accountNumber() { return this.addBankForm.get('accountNumber') }
  get form_accountHolderName() { return this.addBankForm.get('accountHolderName') }
  get form_paymentNickName() { return this.addBankForm.get('paymentNickName') }
  get form_isBusinessAccount() { return this.addBankForm.get('isBusinessAccount') }
  get form_futureUse() { return this.addBankForm.get('futureUse') }
  get futureUse() { return this.addBankForm.get('futureUse'); }
  get formAddressLine1() { return this.addAddressForm.get('addressLine1') }
  get formAddressLine2() { return this.addAddressForm.get('addressLine2') }
  get formZipCode() { return this.addAddressForm.get('zipCode') }
  get formCity() { return this.addAddressForm.get('city') }
  get formState() { return this.addAddressForm.get('state') }
}

