<div >
    <h4 class="claims-header">{{content?.labels?.header}}</h4>
    <p class="claims-description" *ngIf="allClaims?.length>0; else noClaimsDescription">{{content?.labels?.description}}</p>
    <ng-template #noClaimsDescription><p class="claims-description">{{content?.labels?.noClaimsDescription}}</p></ng-template>
    <div class="row" *ngIf="claimSummaryLoader">
        <uxd-spinner></uxd-spinner>
        <div class="loading" [innerHTML]="content?.claimsLoading"></div>
    </div>
    <div class="cards-container" *ngIf="!claimSummaryLoader && claimsErrorMsg === null">
        <data-sydmed-claims-summary-cmp #claimsSummaryCmp *ngIf=" allClaims?.length>0;else showNoClaimsMessage"  [isMini]="isMini" [allClaims]="allClaims" [claims]="claims"  [content]="content"></data-sydmed-claims-summary-cmp>
        <ng-template #showNoClaimsMessage  class="show-message">
            <img [src]="content?.labels?.noClaimsImage" alt="" />
            <h6 class="no-claims">{{content?.labels?.noClaims}}</h6>
        </ng-template>
    </div>
    <div *ngIf="(!claimSummaryLoader && claimsErrorMsg)" [ngClass]="'clms-alert-resp col-xxs-12 col-xs-12'" data-uxd-alert-cmp-v2 [alert]="{alertType: claimsErrorType}">
        <span id="claims-err" [innerHTML]="claimsErrorMsg"></span>
    </div>
    <button class="btn btn-primary" [attr.aria-label]="content?.labels?.gotToClaimsBtn" (click)="goToClaims()" [attr.data-analytics]="content?.labels?.viewDetailBtnAnalytics">{{content?.labels?.gotToClaimsBtn}}</button>
</div>

