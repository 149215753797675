import { Injectable } from '@angular/core';
import { PriorAuthorizationLabels, PriorAuthorizationErrorMessages } from './models/labels';

@Injectable({
  providedIn: 'root'
})
export class PriorAuthDataService {

  constructor() { }

  private _labels: PriorAuthorizationLabels;
  private _errorMsgs: PriorAuthorizationErrorMessages;

  public set labels(labels: PriorAuthorizationLabels) {
    this._labels = labels;
  }

  public get labels(): PriorAuthorizationLabels {
    return this._labels;
  }

  public set errorMsgs(errorMsgs: PriorAuthorizationErrorMessages) {
    this._errorMsgs = errorMsgs;
  }

  public get errorMsgs(): PriorAuthorizationErrorMessages {
    return this._errorMsgs;
  }
}
