import { LiveChatService } from "./../../chat/services/live-chat.service";
import { Component, OnInit } from "@angular/core";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { ChatbotService } from "sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service";
import { ChatDetail, Restriction } from "gbd-models";
import { ChatLink, ChatAnalytics } from "../models/liveChat";
import CONSTANTS from "../utils/constants";

@Component({
  selector: "app-live-chat",
  templateUrl: "./live-chat.component.html",
  styleUrls: ["./live-chat.component.scss"],
})
export class LiveChatComponent implements OnInit {
  public labels;
  public showError = false;
  public isLoading = true;
  public availableTopics: ChatLink[];
  public hasTopicsDisabled: boolean = false;
  public showCarelonRxTopic: boolean = true;

  constructor(
    private dataSvc: JsonContentService,
    private chatBotSvc: ChatbotService,
    private liveChatService: LiveChatService
  ) { }

  ngOnInit(): void {
    this.liveChatService.hasTopicsDisabled = false;
    const isLiveChatConversation = sessionStorage.getItem("isLiveChatConversation");

    this.liveChatService.shouldRefreshChatMenu$.subscribe((value) => {
      this.hasTopicsDisabled = value;
    });
    this.hasTopicsDisabled = isLiveChatConversation === "true";
    this.getJsonLabels();
  }

  getJsonLabels() {
    // passing undefined for locale as it is an optional parameter, since we need to pass the force reload as true here
    this.dataSvc.getJSON("contact-us", undefined, true).subscribe((data) => {
      this.labels = data.contactUs.labels.liveChat;
      this.fetchCategories();
      for (const i in this.labels) {
        if (typeof this.labels[i] === "object") {
          this.labels[i] =
            this.labels[i][
            JSON.parse(sessionStorage.getItem("sydMedMemberContext")).planCode
            ];
        }
      }
      if (
        this.dataSvc.hasRestriction(
          Restriction.SHM_AHD,
          this.dataSvc.getRestrictions()
        )
      ) {
        this.showCarelonRxTopic = false;
      }
    });
  }

  liveChatPopUp(event) {
    this.chatBotSvc.launchChatBot(event);
  }
  fetchCategories() {
    const analyticTags: ChatAnalytics[] = this.labels?.chatTopics;
    if (analyticTags) {
      this.liveChatService.getChatHours().subscribe(
        (data) => this.mapChatTopics(analyticTags, data),
        (error) => {
          this.isLoading = false;
          this.showError = true;
        }
      );
    }
  }
  startChat(event, topic: ChatDetail) {
    const isLiveChatConversation = sessionStorage.getItem("isLiveChatConversation");
    if (isLiveChatConversation !== "true") {
      this.liveChatService.hasTopicsDisabled = true;
      this.hasTopicsDisabled = true;
      this.chatBotSvc.setChatOption(topic);
      sessionStorage.setItem("selectedChatTopic", topic.topic);
      this.liveChatService.liveChatwithSeletcedTopic = topic.availableNow;
    }
    this.liveChatPopUp(event);
  }
  getAnalytics(analyticTags: ChatAnalytics[], topic: string) {
    const { analytics } = analyticTags?.find((tag) => tag.topic === topic) ?? {
      analytics: "",
    };
    return analytics;
  }
  mapChatTopics(analyticsTags: ChatAnalytics[], data: any) {
    const chatDetails: ChatDetail[] = data?.body?.chatDetails;
    chatDetails.forEach(chatDetail => {
      if (this.dataSvc.hasRestriction(
        Restriction.SHM_CARE_COORDINATOR_CHAT,
        this.dataSvc.getRestrictions()
      ) &&
        chatDetail.topic === CONSTANTS.TOPICS.CHAT_WITH_A_COORIDNATOR) {
        chatDetails.splice(chatDetails.indexOf(chatDetail), 1);
      }
    })
    this.availableTopics = chatDetails.map((chatDetail) => {
      return {
        ...chatDetail,
        analytics: this.getAnalytics(analyticsTags, chatDetail.topic),
      };
    });
    this.isLoading = false;
  }
}
