<div class="" *ngIf="labels">
    <div class="container lr-pad">
        <div class="row header-section">
            <a routerLink="/secure/make-payment" class="ant-action-link" attr.aria-label="{{headerJson.ManagePayments}}">
                <span class="motif-icon motif-arrows-long-left"></span>
                {{headerJson.ManagePayments}}
            </a>
        </div>
    </div>
    <hr class="header-border">
    <div class="container">
        <inline-alert-container [name]="'payment-alert'" [alertType]="alertPosition"></inline-alert-container>
    </div>
    <div class="container motif-margin-0">
        <div class="display-block" data-uxd-alert-cmp-v2 *ngIf="isPaymentMethodAlert" [alert]="{alertType: 'positive'}">
            <span>{{paymentMethodText}}</span>
        </div>
        <div class="display-block" *ngIf="isPaymentsError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{errorMsgs.GeneralSystemErrorWithoutCode}}</span>
        </div>
        <div class="display-block" *ngIf="isEarlyWarningError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <p>
                <span>{{bankAccountDeclinedMessage}}</span>
                <span><a class="alert-link" href="{{earlyWarningLink}}" target="_blank" rel="noopener noreferrer">{{earlyWarningLabel}}</a></span>
                <span>{{earlyWarningServiceDescription}}</span>
            </p>            
        </div>
        <div class="display-block" *ngIf="isBankAccountDeclinedError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <p>{{bankAccountDeclinedMessage}}</p>            
        </div>
    </div>
    <div class="container  lrt-pad">
        <div class="row">
            <h2 class="section-header-two" *ngIf="!editMode">{{labels.AddAutoPayHeader}}</h2>
            <h2 class="section-header-two" *ngIf="editMode">{{labels.EditAutoPayHeader}}</h2>
            <p class="description-text">{{labels.MakePaymentDesc}}</p>
        </div>
    </div>
    <div class="container motif-margin-0">
        <hr/>
    </div>
    <div class="container" *ngIf="isPaymentsLoading && !isPaymentsError">
        <div class="row" id="search-loader">
            <uxd-spinner></uxd-spinner>
        </div>
    </div>
    <div class="container motif-margin-0">
        <div class="row ml-2">
            <div class="col-md-3">
                <p class="table-head">{{labels.AccountType}}</p>
                <p>{{selectedBill?.accountType}}</p>
            </div>
            <div class="col-md-3">
                <p class="table-head">{{labels.BenefitPeriod}}</p>
                <p>{{selectedBill?.coverageStartDt | date}} - {{selectedBill?.coverageEndDt | date}}</p>
            </div>
            <div class="col-md-2">
                <p class="table-head">{{labels.MemberId}}</p>
                <p>{{selectedBill?.memberId}}</p>
            </div>
            <div class="col-md-2">
                <p class="table-head">{{labels.DueDate}}</p>
                <p>{{selectedBill?.dueDate | date:'MM/dd/yyyy'}}</p>
            </div>
            <div class="col-md-2">
                <p class="table-head">{{labels.Amount}}</p>
                <p>{{selectedBill?.amount | currency:'USD'}}</p>
            </div>
        </div>
        <div class="row ml-2">
            <div data-uxd-alert-cmp-v2 class="ml-rem" [alert]="{alertType: 'disaster'}" *ngIf="selectedBill?.overDueDays > 0">{{labels.OverdueError}}</div>
        </div>
        <div class="row mt-5">
            <div class="col-md-4">
                <div class="card large-card card-flat card-popover">
                    <div class="row display-flex">
                        <div class="flex-size">
                            <span class="motif-icon motif-cheap-2"></span>
                        </div>
                        <div class="col-md-11 col-sm-11">
                            <h3>{{labels.PaymentAmount}}</h3>
                            <p>{{selectedBill?.amount | currency:'USD'}}</p>
                            <p>{{labels.MonthlyContribution}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card large-card card-flat card-popover">
                    <div class="row display-flex">
                        <div class="col-md-12 col-sm-12">
                            <h3><span class="motif-icon motif-calendar"></span> &nbsp;{{labels.PaymentWithDrawDate}}</h3>
                            <p>{{labels.PaymentWithdrawNote}}</p>
                            <div class="autopay-withdraw-footer">
                                <span class="motif-icon motif-recurring-appointment"></span>
                                <p>{{labels.AutoPayWithdrawMessage}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card large-card card-flat card-popover date-card">
                    <div class="row align-center">
                        <div class="col-md-12 col-sm-12">
                            <h3 class="day font-weight-bold">{{labels.AutoPayDateBlockMsg1}}</h3>
                            <p class="day font-weight-300">{{labels.AutoPayDateBlockMsg2}}</p>
                            <p class="autopay-date">{{labels.AutoPayMonthlyDate}}</p>
                            <p>{{labels.AutoPayDateMessage}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card med-card card-flat card-popover">
                    <div class="row display-flex">
                        <div class="flex-mid-size">
                            <span class="motif-icon motif-bank-cards"></span>
                        </div>
                        <div class="col-md-11 col-sm-11">
                            <h3>{{labels.PaymentMethod}}</h3>
                            <p *ngIf="selectedPaymentMethod && !selectedPaymentMethod.isBankAcc">{{labels.CreditCardType[selectedPaymentMethod.creditCardType]}}-{{selectedPaymentMethod.creditCardNumber}}</p>
                            <p *ngIf="selectedPaymentMethod && !selectedPaymentMethod.isBankAcc">Expires {{selectedPaymentMethod.expirationDate}}</p>
                            <p *ngIf="selectedPaymentMethod && selectedPaymentMethod.isBankAcc">{{labels.BankAccountType[selectedPaymentMethod.bankAccountType]}}</p>
                            <p *ngIf="selectedPaymentMethod && selectedPaymentMethod.isBankAcc">{{selectedPaymentMethod.bankAccountNumber}}</p>
                            <p *ngIf="selectedPaymentMethod">
                                <a href="javascript:void(0);" (click)="onAddPaymentMethod()" attr.aria-label="{{labels.ChangePayment}}">{{labels.ChangePayment}}</a>
                            </p>
                            <div class="inline-alert-grid-container" *ngIf="!selectedPaymentMethod">
                                <span class="a-icon motif-icon motif-delete"></span>
                                <p class="a-message">{{labels.SelectPaymentToContinueError}}</p>
                            </div>
                            <div class="inline-alert-grid-container" *ngIf="selectedPaymentMethod && cardExpiry > 0">
                                <span class="a-icon motif-icon motif-delete"></span>
                                <p class="a-message">{{cardExpiry === 1 ? labels.CardAboutToExpire.replace('{expDate}', selectedPaymentMethod.expirationDate) : labels.CardAlreadyExpired}}</p>
                            </div>
                            <p *ngIf="!selectedPaymentMethod">
                                <a href="javascript:void(0);" (click)="onAddPaymentMethod()" attr.aria-label="{{labels.AddPayment}}">{{labels.AddPayment}}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-md-6"> </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-9">
                        <h4 class="font-weight-bold">{{labels.AutopayPaymentAmount}}</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="font-weight-bold float-right">{{selectedBill?.amount | currency:'USD'}}</h4>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-md-12">
                        <p *ngIf="!editMode">{{labels.AutopayAcknowledgeAdd }}</p>
                        <p *ngIf="editMode">{{labels.AutopayAcknowledgeEdit }}</p>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-8 text-align-right">
                        <p>
                            <a routerLink="/secure/make-payment" class="cancel-link" attr.aria-label="{{labels.Cancel}}">{{labels.Cancel}}</a>
                            <button class="btn btn-primary float-right" *ngIf="!editMode" (click)="updateAutopay()" [disabled]="!selectedPaymentMethod || isSubmittedOnce" 
                                attr.aria-label="{{labels.AddAutopay}}" data-analytics = "addAutoSchedBillPay">{{labels.AddAutopay}}</button>
                            <button class="btn btn-primary float-right" *ngIf="editMode" (click)="updateAutopay()" [disabled]="!selectedPaymentMethod || isSubmittedOnce" 
                                attr.aria-label="{{labels.UpdateAutopay}}" data-analytics = "updateAutoChangeBillPay">{{labels.UpdateAutopay}}</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
    </div>
</div>

<ng-template #sidePanelPayment>
    <div class="modal-example">
        <div class="clearfix">
            <button class="close" (click)="onClose()" aria-label="Close">
                <span class="close-icon"></span><span hidden>Close Payment Methods</span>
            </button>
        </div>
        <div class="modal-component-body">
            <div class="row">
                <app-payment-methods [content]="labels.PaymentMethodPanel" [selectedPayment]="selectedPaymentMethod" 
                (closePanel)="onClose()" [autopay]="true">
                </app-payment-methods>
            </div>
        </div>
    </div>
</ng-template>