import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from "sydmed/libs/url-properties/src/lib/url-properties.class";

@Injectable({
  providedIn: 'root'
})
export class AccountAndRecoveryService {

  constructor(private httpSvc: HttpClientService) { }

  // update account and recovery number
  public updateRecoveryNumber(requestObj) {
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.myProfile.updatePhoneNum, requestObj)
  }

  // get security questions
  public getSecurityQuestions() {
    return this.httpSvc.getObservable(UrlProperties.UrlProperties.endPoints.myProfile.securityQuestions);
  }

  // update security questions
  public updateSecurityQuestions(requestObj) {
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.myProfile.submitSecurityQuestions, requestObj)
  }

  // delete user account
  public deleteUserAccount() {
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.myProfile.deleteAccount, null);
  }
}
