import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MyProfileDataService } from './my-profile-data.service';
import { Subscription } from 'rxjs';
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { MyProfileModel } from "./models/my-profile.model";
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { ContactInfoService } from './services/contact-info.service';
import { ProfileDetailService } from './services/profile-details.service';
import { ShmMyProfileDataService } from './shm-my-profile-data-service';
import { InterCommunicationService }  from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { CommunicationPreferencesContactInfo } from './models/communication-preferences-contact-info.model';
import { CommunicationPreferencesService } from './services/communication-preferences.service';
import { UniversalAlertType, InlineAlertContainerComponent } from '@anthem/uxd/alert';
import { filter } from 'rxjs/operators';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { NavPages } from './models/enum-values';
import { Restriction } from 'gbd-models';
import { GetCommPrefsResponse } from 'gbd-models/src/features/profile/commPrefs';

@Component({
  selector: 'my-profile',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  private subscriptions: Subscription[];
  public successMsg: string;
  public error: string;
  public showError: boolean;
  public labels: any;
  public errorMsgs: any;
  public loading: any;
  public showCommPref: any;
  public loginContactData: any;
  public apiProgress: boolean = false;
  private prevUrl: string;
  public commPrefs: GetCommPrefsResponse;

  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  @ViewChild(InlineAlertContainerComponent) antInlineAlert: InlineAlertContainerComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private myProfileDataSvc: MyProfileDataService,
    private httpSvc: HttpClientService,
    private loginContactModel: MyProfileModel,
    private dataSvc: ShmMyProfileDataService,
    private errCodeMapSvc: ErrorCodeMapperService,
    private interCommunicationService: InterCommunicationService,
    private contactInfoSvc: ContactInfoService,
    private profileDetailSvc: ProfileDetailService,
    private commPrefSvc: CommunicationPreferencesService,
    private jsonSvc: JsonContentService
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.prevUrl = event.url;
      });
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Profile'
    });
  }



  ngOnInit() {
    this.subscriptions = [];
    this.showError = false;
    this.getMemberContextDetails();
    this.setErrorMsgs()
    this.subscriptions.push(this.myProfileDataSvc.getSuccessMsg().subscribe(
      succMsg => {
        this.successMsg = succMsg;
      }
    ));
    this.subscriptions.push(this.myProfileDataSvc.getApiCallInProgress().subscribe(
      data => {
        this.apiProgress = data;
      }
    ));

    this.commPrefSvc.getCommPrefs().subscribe(
      (res) => {
        this.commPrefs = res.body ?? res;
        this.myProfileDataSvc.setGBDContactInfo(this.commPrefs.gbdContactInfo);
      }
    );  

    if(this.prevUrl === '/secure/profile') {
      this.myProfileDataSvc.setNavActive(NavPages.LOGIN_SECURITY);
      this.myProfileDataSvc.setContactInfoApiErr('');
      // this.contactInfoSvc.getContactInfo(true);
      // force invoke getcontactinfo API when navigating from parent URL by passing a true value. 
      this.profileDetailSvc.isDirty = true;
    }
    this.profileDetailSvc.getProfileDetails().subscribe(data => { 
      this.profileDetailSvc.mapToContactObj(data);
    });
  }

  setErrorMsgs() {
    this.jsonSvc.getJSON('error-messages').subscribe(data => {
      this.errorMsgs = data.ErrorMsgs;
    });
    this.setSubscriptions();
  }

  clearSuccessAlert() {
    // deleting the success messages from the stack
    setTimeout(()=>{
      if(this.checkAlertLength()) {
        for(let i =0; i < this.antInlineAlert.alerts.length; i++) {
          setTimeout(()=>{
            if(this.checkAlertLength()){
              this.antInlineAlert.dismissAlertId(i);
            }
          }, 85000); // 85 seconds
        }
      }
      this.clearSuccessAlert();
    }, 5000); // buffer 5 sec for each settimeout call
  }

  checkAlertLength() {
    return this.antInlineAlert && this.antInlineAlert.alerts && this.antInlineAlert.alerts.length;
  }

  ngAfterViewInit() {
    this.clearSuccessAlert();
  }

  private getMemberContextDetails() {
    if(window.sessionStorage.getItem('sydMedMemberContext')) {
      const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
      if(memberContext && memberContext.restrictions && memberContext.stateLob) {
        this.myProfileDataSvc.stateLob = memberContext.stateLob;
        this.myProfileDataSvc.setRestrictions(this.loginContactModel.restrictions((memberContext.restrictions).toString(), memberContext.stateLob));
        const termedUser = this.checkTermedMember();
        this.myProfileDataSvc.setIsTermedUserFlag(termedUser);
      }
    }
    // this.restrictions = this.myProfileDataSvc.getRestrictions();
  }

  ngOnDestroy() {
    if(this.subscriptions) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }


  private setSubscriptions(){

    let memberContactInfoSubcription:Subscription = this.contactInfoSvc.memberContactInfoSubject.subscribe((memberContactInfo)=> {

      this.loginContactModel.setContactInfo(memberContactInfo);
      this.loginContactData = this.loginContactModel.getContactInfo();
      this.myProfileDataSvc.setContactInfoData(this.loginContactData);
      this.showCommPref = this.loginContactModel.commPrefShowHide(this.loginContactData);

    });

    let memberContactInfoFetchErrorSubscription:Subscription = this.contactInfoSvc.memberContactInfoFetchError.subscribe((errorResponse)=> {
      this.showError = true;
      this.error = this.errCodeMapSvc.errorCodeMapper(errorResponse, this.errorMsgs);
      this.myProfileDataSvc.setContactInfoApiErr(this.error);
    });

    let isFetchingContactInfoSubscription:Subscription = this.contactInfoSvc.isFetchingContactInfo.subscribe((isFetchingContactInfo:boolean) => {
      this.myProfileDataSvc.setApiCallInProgress(isFetchingContactInfo);
      this.myProfileDataSvc.setContactInfoApiLoader(isFetchingContactInfo);

    });

    const commPrefsInfo: Subscription = this.commPrefSvc.getCommPrefs().subscribe(
      commPrefContactInfo => {
        this.myProfileDataSvc.setCommPrefContactInfoData(commPrefContactInfo.body ?? commPrefContactInfo);
      }
    );

    this.subscriptions.push(memberContactInfoSubcription);
    this.subscriptions.push(memberContactInfoFetchErrorSubscription);
    this.subscriptions.push(isFetchingContactInfoSubscription);
    this.subscriptions.push(commPrefsInfo);
  }

  checkTermedMember() {
    if (this.jsonSvc.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS, this.jsonSvc.getRestrictions()) ||
      this.jsonSvc.hasRestriction(Restriction.TERMED_MEMBER_TWO_TO_FIVE_YEARS, this.jsonSvc.getRestrictions()) ||
      this.jsonSvc.hasRestriction(Restriction.TERMED_MEMBER_MORE_THAN_FIVE_YEARS, this.jsonSvc.getRestrictions())) {
      return true;
    }
    return false;
  }
}
