import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GetSecurityCodeComponent } from './get-security-code/get-security-code.component';
import { EnterSecurityCodeComponent } from './enter-security-code/enter-security-code.component';
import { AnswerSecurityQuestionsComponent } from './answer-security-questions/answer-security-questions.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PublicAuthGuardService } from 'sydmed/libs/authorization/public-route-guard.service';
import { AccountDetailsComponent } from 'sydmed/libs/shared-components/src/lib/account-details/account-details.component';


const routes: Routes = [
  { path: 'public/forgot-username-or-password', redirectTo: "public/forgot-username-or-password/home" },
  { path: 'public/forgot-username-or-password/home', component: AccountDetailsComponent },
  { path: 'public/forgot-username-or-password/get-security-code', component: GetSecurityCodeComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/forgot-username-or-password/enter-security-code', component: EnterSecurityCodeComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/forgot-username-or-password/answer-security-questions', component: AnswerSecurityQuestionsComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/forgot-username-or-password/reset-password', component: ResetPasswordComponent, canActivate: [PublicAuthGuardService] },
  { path: 'public/forgot-username-or-password/confirmation', component: ConfirmationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
