import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceComponent } from './maintenance.component';
import { Routes, RouterModule } from '@angular/router';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { PublicAuthGuardService } from 'sydmed/libs/authorization/public-route-guard.service';

const routes: Routes = [
  {
    path: 'public/maintenance',
    canActivate: [PublicAuthGuardService],
    component: MaintenanceComponent
  }
];

@NgModule({
  declarations: [
    MaintenanceComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    IndicatorModule
  ],
  providers: []
})
export class MaintenanceModule {
  static forRoot(): ModuleWithProviders<MaintenanceModule> {
    return {
      ngModule: MaintenanceModule,
      providers: [PublicAuthGuardService]
    };
  }
 }
