import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MessageCenterAppComponent } from './app.component';
import { JsonDataResolverService } from './json-data-resolver.service';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
  {
    path: 'secure/message-center',
    component: MessageCenterAppComponent, resolve: {jsonData: JsonDataResolverService}, canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
