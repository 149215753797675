import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppendZipcodeService {

  constructor() { }

  public appendZipcodeToUrl(url: string, zipcode: string): string {
    if (zipcode && url.includes('zipcode')) {
      if(zipcode.length !== 5) {
        zipcode = zipcode.substring(0,5);
      }
      const updateZipcode = JSON.stringify(url).replace(/zipcode=/g, "zipcode=" + encodeURI(zipcode).replace(/\D/g,''));
      url = JSON.parse(updateZipcode);
    }
    return url;
  }
}
