import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { ModalRef, Modal } from '@anthem/uxd/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { isTodayInDateRange } from '../../util/dateUtil';
import { SessionStorage, StorageService} from 'sydmed/libs/storage-service/src/public-api';
import { Subject, Subscription } from 'rxjs';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { AHDUtils } from '../../../pharmacy/utils/ahd-pharmacy-utils';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-dashboard-container',
  templateUrl: './dashboard-container.component.html',
  styleUrls: ['./dashboard-container.component.css']
})
export class DashboardContainerComponent implements AfterViewInit, OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private jsonSvc: JsonContentService, 
    private _modal: Modal, 
    private router: Router, 
    private storage: StorageService,
    private memberRepresentativeService: MemberRepresentativeService,
  ) { }
  public content: any;
  public showClaims: boolean = true;
  public showPharmacy = true;
  public showBenefitsTile: boolean = true;
  public showBrandChangeAlert: boolean = false;
  public showCards: boolean = true;
  public nyIntegraTermedLabels;
  public nyIntegraTermedUser: boolean = false;
  public pharmacyVendorChangeLabels;
  public marketDecommissionLabels;
  public isAHDMember: boolean = false;
  public isMarketDecommissionModalVisible: boolean;
  public isTermedUser: boolean = false;
  public isCaregiver: boolean = false;
  public termedUserNavigaton: boolean = false;
  public showSwitcherMessagingTile: boolean = false;
  public isInterventionModalVisibile: boolean = false;
  public _modalRef: ModalRef<any, any>;
  public popup;
  public updatePharmacyAlert;
  public memberContext;
  public commercialLoginUrl: string;
  public callInterventionLabels;
  public interventionId: string;

  //for now is set to true but will remove it soon as we will get the value from the service
  public isInterventions: boolean = false;
  @ViewChild("appPopUpModal", { static: false }) appPopUpModal: TemplateRef<any>;
  @ViewChild('marketDecommissionModal', {static: false}) marketDecommissionModal: TemplateRef<any>;
  @ViewChild('interventionsModal', {static: false}) interventionsModal: TemplateRef<any>;
  @ViewChild("nyIntegraTermedModal", { static: false })
  nyIntegraTermedModal: TemplateRef<any>;
  @ViewChild("termedMedicaidMemberModal", { static: false }) termedMedicaidMemberModal: TemplateRef<any>;
  private _subscriptions: Subscription[] = [];
   /** Event that emits when the header is destroyed (ngOnDestroy) */
  protected readonly _destroyed = new Subject<void>();

  ngAfterViewInit(): void {
    this.route?.queryParams?.pipe(takeUntil(this._destroyed)).subscribe((params) => {
      this.interventionId = params.interventionId;
      if (this.interventionId) {
        this.isInterventionModalVisibile = true;
      }
    });
  }

  ngOnInit(): void {
    this.memberContext = JSON.parse(sessionStorage.getItem('sydMedMemberContext'));
    const commercialMemberEligibility = this.memberContext?.commercialMemberEligibility;
    let commercialBrandContentSubscription = this.jsonSvc.getJSON('commercialBrand').subscribe(data => {
        this.commercialLoginUrl = data.ABCBS;
        Object.keys(data).forEach(key => {
        if (key === commercialMemberEligibility?.brand) {
          this.commercialLoginUrl = data[key];
        }
      });
    });

    this._subscriptions.push(commercialBrandContentSubscription);

    let dashboardContentSubscription = this.jsonSvc.getJSON('dashboard').subscribe(data => {
      this.content = data.Dashboard.Labels;
      this.nyIntegraTermedLabels = data.Dashboard.Labels.Ny_Integra_Termed_Member;
      this.pharmacyVendorChangeLabels = data.Dashboard.Labels.pharmacyVendorChange;
      this.callInterventionLabels = data.Dashboard.Labels.callInterventions;
      this.popup = data.Dashboard.Labels.popup;
      this.updatePharmacyAlert = data.Dashboard.Labels.updatePharmacyAlert
      this.marketDecommissionLabels = data.Dashboard.Labels.marketDecommission;
      this.showSwitcherMessagingTile = !this.jsonSvc.hasRestriction(Restriction.SHM_SWITCHER_MESSAGING, data.restrictions) &&
      commercialMemberEligibility?.commercialActive;
      this.showCards = !this.jsonSvc.hasRestriction(Restriction.SHM_DASHBOARD_CARDS, data.restrictions);
      this.showPharmacy = !this.jsonSvc.hasRestriction(Restriction.SHM_NO_PHARMACY, data.restrictions) &&
                          !this.jsonSvc.hasRestriction(Restriction.SHM_PARTIAL_PHARMACY, data.restrictions);
      this.showBenefitsTile = !this.jsonSvc.hasRestriction(Restriction.SHM_ENGAGEMENT, data.restrictions);
      this.isTermedUser = this.jsonSvc.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS, data.restrictions) ||
                this.jsonSvc.hasRestriction(Restriction.TERMED_MEMBER_TWO_TO_FIVE_YEARS, data.restrictions);
      this.isCaregiver = this.memberRepresentativeService.isCaregiver;
      this.nyIntegraTermedUser = this.jsonSvc.hasRestriction(Restriction.SHM_NYINTEGRA_TERMED, data.restrictions) && this.isTermedUser;
      const isDashboardAlertClosed = sessionStorage.getItem("isDashboardAlertClosed");
      this.showBrandChangeAlert = !this.jsonSvc.hasRestriction(Restriction.SHM_BRAND_CHANGE_ALERT, data.restrictions) && isDashboardAlertClosed !=='true';
      this.isMarketDecommissionModalVisible = !this.jsonSvc.hasRestriction(Restriction.SHM_MARKET_DECOMMISSION , data.restrictions);
      this.route.queryParams.subscribe(params => {
      if (params.interventionId && !this.jsonSvc.hasRestriction(Restriction.SHM_CALL_INTERVENTION, data.restrictions)) {
        this.isInterventions = true;
      }
      })
      if(this.isInterventions && this.isInterventionModalVisibile) {
        this._modalRef = this._modal.open(this.interventionsModal);
      }
      if (this.content?.infoScreen?.length && !this.showBenefitsTile) {
        this.content.infoScreen = this.content.infoScreen.filter(tile => tile.url != "/secure/my-health-dashboard/my-rewards");
      }
      if(!this.storage.getSessionItem(SessionStorage.NEXT_URL)) {
        this.showTermedMemberInfo();
      }
      if(this.content.termedMemberCommercialEligibility.show && this.showSwitcherMessagingTile) {
        this.openTermedMemberPopUp();
      }
      if(this.isMarketDecommissionModalVisible && !this.storage.getSessionItem(SessionStorage.MARKET_DECOMMISSION))
      {
        this.storage.setSessionItem({key: SessionStorage.MARKET_DECOMMISSION, value: 'true' })
        this._modalRef = this._modal.open(this.marketDecommissionModal);
      }
      else
      {
        this.isMarketDecommissionModalVisible = this.storage.getSessionItem(SessionStorage.MARKET_DECOMMISSION);
      }
      if(this.popup.enabled){
        this.appPopUp();
      }
    });
    this._subscriptions.push(dashboardContentSubscription);
    this.isAHDMember = AHDUtils.isAHDMember();
    this.checkClaims();
  }
  
  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._destroyed.next();
    this._destroyed.complete();
  }
  
  checkClaims() {
    let memberContext = JSON.parse(sessionStorage.getItem('sydMedMemberContext'));
    if (this.jsonSvc.hasRestriction(Restriction.SHM_CLAIMS, memberContext?.restrictions)) {
      this.showClaims = false;
    }
  }

  appPopUp() {
    if (window.localStorage.getItem('seenPopUp_' + this.memberContext.analyticsUUID ) !== 'true' && isTodayInDateRange(this.popup.popUpDisabledDate)){
      this._modalRef = this._modal.open(this.appPopUpModal);
    }
  }

  openTermedMemberPopUp() {
    if (window.localStorage.getItem('seenTermedMemberPopUp_' + this.memberContext.analyticsUUID ) !== 'true'){
      this._modalRef = this._modal.open(this.termedMedicaidMemberModal);
    }
  }

  onCloseBrandChangeAlertClick() {
    this.showBrandChangeAlert = false;
    sessionStorage.setItem("isDashboardAlertClosed", "true");
  }

  closeModal() {
    localStorage.setItem('seenPopUp_' + this.memberContext.analyticsUUID , 'true');
    this.closeModalRef();
  }

  closeIntervention() {
    this.closeModalRef();
  }
  
  showTermedMemberInfo() {
      if(this.nyIntegraTermedUser) {
        this._modalRef = this._modal.open(this.nyIntegraTermedModal);
      };
  }

  closeModalRef(){
    this._modalRef.close();
    this._modalRef.onDismiss.unsubscribe();
    this._modalRef.onClose.unsubscribe();
  }

  closeTermedMemberModal() {
    localStorage.setItem('seenTermedMemberPopUp_' + this.memberContext.analyticsUUID , 'true');
    this.closeModalRef(); 
  }

  closeMarketDecommissionModal = () => {
    this.storage.setSessionItem({key: SessionStorage.MARKET_DECOMMISSION, value: 'false' })
    this.closeModalRef(); 
  }
  
  onBrandChangeAlertLearnMoreUrlClick(){
    window.open(this.content.dismissibleAlert.url, "_blank");
 }

  accessNewPlansForTermedMember() {
    window.open(this.commercialLoginUrl, "_blank");
    localStorage.setItem('seenTermedMemberPopUp_' + this.memberContext.analyticsUUID , 'true');
    this.closeModalRef();
  }

  goToClaims(){
    this.closeModalRef();
    this.router.navigate(['/secure/claims']);
  }
}