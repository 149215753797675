import { AppealsAndGrievancesConfirmationLabels, GrievancesAndAppealsLabels } from '../../models/labels';
import { Component, OnInit } from '@angular/core';
import { FormType } from 'gbd-models/src/features/appealsAndGrievance';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appeals-and-grievances-form-confirmation',
  templateUrl: './appeals-and-grievances-form-confirmation.component.html',
  styleUrls: ['./appeals-and-grievances-form-confirmation.component.scss']
})
export class AppealsAndGrievancesFormConfirmationComponent implements OnInit {

  public activePath: any;
  public confirmationLabels: AppealsAndGrievancesConfirmationLabels;
  public labels: GrievancesAndAppealsLabels;
  
  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private router: Router
  ) { 

    this.activePath = this.router.url?.split("/")[3] ;

    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: this.activePath === FormType.APPEALS.toLowerCase() ? 'Appeal Form' : 'Grievance Form'
    });
  }

  ngOnInit(): void {

    // Get Labels from Json 
    this.getLabels();
  }

  getLabels() {
    this.jsonSvc.getJSON('appeals-and-grievances').subscribe(response => {
      this.confirmationLabels = response.sent;
      this.labels = (this.activePath === FormType.APPEALS.toLowerCase()) ? response.appeals : response.grievances;
    });
  }
}
