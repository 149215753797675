const CONSTANTS = {
    ANALYTICS: {
        CALL_ME: {
            INQUIRY_TYPES: {
                "Find or change a doctor": "findDrReasonContactUs",
                "Prescription information": "rxInformationReasonContactUs",
                "Benefits question": "benefitReasonContactUs",
                "Order an ID card": "orderIdCardReasonContactUs",
                "Billing question": "billingReasonContactUs",
                "Update my address": "updateAddressReasonContactUs",
                "Change my phone number": "changeNumberReasonContactUs",
                "Web Support": "webSupportReasonContactUs",
                "Other": "otherReasonContactUs"
            },
            CALL_TYPE: {
                "Call me": "callMeTimeContactUs",
                "Schedule for later": "scheduleLaterTimeContactUs",
            }
        },
        TABS: {
            "CallUs": "callUsTabContactUs",
            "MessageUs": "messageUsTabContactUs",
            "LiveChat": "liveChatTabContactUs"
        }
        
    },
    TOPICS: {
        CHAT_WITH_A_COORIDNATOR: "Chat with a coordinator",
    }
}

export default CONSTANTS;

