import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { AHDPharmacyContent } from '../models/ahdIdCardMessagesContent';
import { ContentHelper } from '@anthem/mbrportal/core/public_api';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';

@Component({
  selector: 'app-ahd-id-card-messages',
  templateUrl: './ahd-id-card-messages.component.html',
  styleUrls: ['./ahd-id-card-messages.component.scss'],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }],
})
export class AhdIdCardMessagesComponent implements OnInit {

  public content: AHDPharmacyContent;

  constructor (
    private jsonSvc: JsonContentService,
  ) { }

  ngOnInit(): void {
    this.subscribeToContent();
  }

  subscribeToContent() {
    this.jsonSvc.getJSON('ahd-id-card-messages').subscribe(data => {
      this.content = data.AHD_Pharmacy;
    })
  }

}
