<div class="member-status" *ngIf="isTermedMember">
    <span id="member-status-span" class="inactive">{{memberStatus?.inactive}}</span>
  </div>
<ng-container *ngFor="let item of menuData">
    <div class="newdashboard-menu-container" *ngIf="item.show" [attr.data-analytics]="item.analytics">
        <div class="newdashboard-menu-row" (click)="navigateTo(item.url)">
            <div class="newdashboard-menu-icon">
                <span *ngIf="item.icon" [ngClass]="item.icon" class="motif-icon"></span>
                <img *ngIf="item.assetIcon" [src]="item.assetIcon"/>
            </div>
            <div class="newdashboard-menu-text-container">
                <div class="newdashboard-menu-title">{{item.title}}</div>
                <div class="newdashboard-menu-details">{{item.details}}</div>
            </div>
            <div class="newdashboard-menu-nav-icon">
                <span [ngClass]="item.navIcon" class="motif-icon"></span>
            </div>
        </div>
        <div class="renew-container" *ngIf="item.showRenew && (apiData?.renewalDate && apiData?.renewalDate.length)">
            <span class="renew-text">{{benefitsRenewData.renewLabel}}</span>
            <div class="button-container">
                <button class="renew-button" type="button" (click)="onRenewButtonClick($event, item.renewURL)">{{benefitsRenewData.renewButton}}</button> 
            </div> 
        </div>
    </div>
</ng-container>