import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DashboardModal} from '../../interfaces/dashboardModals';
@Component({
  selector: 'app-dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss'],
})
export class DashboardModalComponent {
  @Input() labels: DashboardModal;
  @Output() onDismissModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSecondaryBtnClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onPrimaryBtnClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  dismissModal() {
    this.onDismissModal.emit();
  }
  primaryButton() {
    this.onPrimaryBtnClick.emit();
  }
  secondaryButton() {
    this.onSecondaryBtnClick.emit();
  }
}