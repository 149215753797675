import { Component, OnInit, Input } from '@angular/core';
import { CostShareContent } from '../models/CostShareContent';
import { LtssResponse } from 'gbd-models';

@Component({
  selector: 'app-member-liability',
  templateUrl: './member-liability.component.html',
  styleUrls: ['./member-liability.component.scss'],
})
export class MemberLiabilityComponent implements OnInit {

  @Input() content: CostShareContent; 
  @Input() memberLiability: LtssResponse;
  @Input() isError: boolean;
  memberLiabiltyError: string;

  constructor() { }

  ngOnInit(): void { 
    this.memberLiabilityError();
  }

  memberLiabilityError(){
    if (this.isError == true) {
      this.memberLiabiltyError = this.content.errors.genericErrorLtss;
    } 
  }
}
