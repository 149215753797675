import { Injectable } from "@angular/core";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";
import { AppointmentAssistance} from 'gbd-models';


@Injectable({
  providedIn: 'root'
})
export class AppointmentAssistanceServices {

    constructor(private httpSvc: HttpClientService) { }

    //submit Appointment Assistance Request form
    public submitAppointmentAssistanceRequest(requestObj: AppointmentAssistance) {
      return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.appointmentAssistance.submitAppointmentAssistanceRequest, requestObj)
    }
}