<div class="container uxd-theme-motif wrapper">
  <h2 *ngIf="content">{{content.Labels.PageSubtitle}}</h2>
  <p class="desktop" *ngIf="content">{{content.Labels.PageSubheading}}</p>
  <p class="mobile" [innerHTML]="content.Labels.PageSubheadingMobile" *ngIf="content"></p>
  <div *ngIf="showCreditList; else loadCreditList">
    <fieldset>
    <legend class="motif-margin-bottom-5 legend-sub-heading" *ngIf="content">{{content.Labels.CreditCardsHeading}}</legend>
    <form name="credit-card-payment-form">
      <label *ngFor="let paymentOption of creditPaymentRadioGroup?.options" [attr.disabled]="paymentOption?.payment?.isExpired" [ngClass]="{'radio-disabled': paymentOption?.payment?.isExpired}" >
        <input 
          type="radio" 
          name="creditPaymentOption" 
          [(ngModel)]="selectedId" 
          [value]="paymentOption.value" 
          [attr.disabled]="paymentOption?.payment?.isExpired"
          />
        <span *ngIf="paymentOption.payment && paymentOption.payment.accountName; else addOption">
          <span *ngIf="!paymentOption?.payment?.isExpired; else Expired">
            <span class="motif-margin-left-3">{{ paymentOption?.payment.accountName }}</span><br>
            <span class="motif-margin-left-7">{{content.block.expirationDate}} </span>
            <span>{{paymentOption.payment.expirationDate | datestring: 7: '0'}}</span>
          </span>
          <ng-template #Expired>
            <span class="motif-margin-left-3 red-text">{{ paymentOption?.payment.accountName }}</span><br>
            <span class="motif-margin-left-7 red-text">{{content.block.expiredDate}}</span>
            <span class="red-text">{{paymentOption.payment.expirationDate | datestring: 7: '0'}}</span>
          </ng-template>
        </span>
        <ng-template #addOption>
          <span *ngIf="paymentOption.label">
            <span class="motif-margin-left-3">{{ paymentOption.label }}</span>
          </span>
        </ng-template>
      </label>
    </form>
  </fieldset>
</div>
  <ng-template #loadCreditList>
          <uxd-spinner></uxd-spinner>
  </ng-template>

  <div class="motif-margin-top-10">
    <div *ngIf="selectedId === 'addNewCard'">
      <app-add-credit-payment 
        #addCreditPayment
        id="addCard"
        [showCheckBox]="false" 
        [showButtons]="true"
        [showSuccess]="false"
        [lineOfBusiness]="lineOfBusiness" 
        [labels]="addCardContent" 
        [errObj]="addCardErrorMessages" 
        (isCancel)="onCancel($event)"
        >
      </app-add-credit-payment>
    </div>
  </div>

  <div *ngIf="showBankList; else loadBankList">
    <fieldset>
    <legend class="motif-margin-bottom-5 legend-sub-heading" *ngIf="content">{{content.Labels.BankAccountsHeading}}</legend>
    <form name="banking-payment-form">
      <label *ngFor="let paymentOption of bankPaymentRadioGroup?.options">
        <input 
          type="radio" 
          name="bankPaymentOption" 
          [(ngModel)]="selectedId" 
          [value]="paymentOption.value" 
        />
        <span *ngIf="paymentOption.payment && paymentOption.payment.accountName; else addOption">
          <span class="motif-margin-left-3">{{ paymentOption?.payment.accountName }}</span>
        </span>
        <ng-template #addOption>
          <span *ngIf="paymentOption.label">
            <span class="motif-margin-left-3">{{ paymentOption.label }}</span>
          </span>
        </ng-template>
      </label>
    </form>
  </fieldset>
 </div>
  
  <ng-template #loadBankList>
          <uxd-spinner></uxd-spinner>
  </ng-template>

  <div *ngIf="selectedId === 'addNewBank'">
    <app-add-checking-payment
      [showCheckBox]="false"
      [showButtons]="true"
      [showSuccess]="false"
      [lineOfBusiness]="lineOfBusiness"
      [labels]="addBankContent"  
      [errObj]="addCheckingErrorMessages"
      (isCancel)="onCancel($event)"
      >
    </app-add-checking-payment>
  </div>

  <div class="motif-margin-top-10 motif-margin-bottom-10">
    <ng-container *ngIf="content">
      <div *ngIf="selectedId !== 'addNewCard' && selectedId !== 'addNewBank'" class="button-group">
        <button type="button" (click)="onCancel($event)" class="btn btn-secondary">{{content.Labels.Cancel}}</button>
        <button 
          [class.disabled]="!selectedId || selectedId === ''"
          type="button" 
          class="btn btn-primary"
          (click)="updatePaymentMethod()"
          data-analytics="updateSelectPayMethodRx" 
          >
          {{content.Labels.Update}}
        </button>
      </div>
    </ng-container>
    <!-- <button (click)="reloadList()">reload</button> -->
  </div>
</div>
