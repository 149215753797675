<div class="" *ngIf="labels">
    <div class="container lr-pad">
        <div class="row header-section">
            <a [routerLink]="labels.backLinkUrl" [queryParams]="{ tab:'pharmacy' }" class="ant-action-link" [attr.aria-label]="labels.backLinkText" [attr.data-analytics] = "analytics.goBackLinkText">
                <span id="long-left-arrow-back-button" class="motif-icon motif-arrows-long-left"></span>
                {{labels.backLinkText}}
            </a>
        </div>
    </div>
    <hr class="header-border">
    <div class="container margin-top-37">    
        <h2 class="section-header-two">{{labels.faqFirstQn}}</h2>
        <p>{{labels.faqFirstAnswerPara1}}</p>
        <p>{{labels.faqFirstAnswerPara2}}</p>    
        <h2 class="section-header-two">{{labels.faqSecondQn}}</h2>
        <p>{{labels.faqSecondAnswerPara1}}</p>
        <p>{{labels.faqSecondAnswerPara2}}</p>   
        <h2 class="section-header-two">{{labels.faqThirdQn}}</h2>
        <p>{{labels.faqThirdAnswerPart1}}<a [routerLink]="labels.backLinkUrl" [queryParams]="{ tab:'pharmacy' }" [attr.data-analytics] = "analytics.azRefLinkAuth">{{labels.faqThirdAnswerLinkText}}</a>{{labels.faqThirdAnswerPart2}}</p>
        <h2 class="section-header-two">{{labels.faqTableQn}}</h2>
        <div class="table-container">
            <table class="table-zebra-striping">
                <tr>
                    <th><h3>{{labels.faqTableHeaderStatus}}</h3></th>
                    <th class="definition"><h3>{{labels.faqTableHeaderDefinition}}</h3></th>
                </tr>
                <tr *ngFor="let row of labels.faqTableRows">
                    <td>{{row.status}}</td>
                    <td class="definition">{{row.definition}}</td>
                </tr>
            </table>
        </div>
        <p class="footnote">{{labels.faqFooterNote}}<a [routerLink]="labels.faqFooterNotePharmacyLink" [attr.data-analytics] = "analytics.footerNotePharmacyLink">{{labels.faqFooterNotePharmacyText}}</a></p>
    </div>
</div>