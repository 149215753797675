// Angular Imports
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

// Message Center Imports
import { MessageCenterLabels } from './../models/labels.interface';
import { MessageCenterDataService } from './../message-center-data.service';
import { Restriction } from 'gbd-models';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';

@Component({
  selector: 'folder-menu',
  templateUrl: './folder-menu.component.html',
  styleUrls: ['./folder-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FolderMenuComponent implements OnInit, OnDestroy {

  constructor(
    private dataSvc: MessageCenterDataService,
    private jsonSvc: JsonContentService,
    private memberRepresentativeService: MemberRepresentativeService
  ) { }

  // Declare variables
  private subscriptions: Subscription[];
  public labels: MessageCenterLabels;
  public folder: string;
  public unreadCount: number;
  public transcriptUnreadCount: number;
  public isChatTranscriptsEnabled: boolean;
  public isDesignee: boolean = false;
  public isTermed: boolean = false;
  public hideSentArchiveButton: boolean = false;

  ngOnInit(): void {
    // Initialize variables
    this.subscriptions = [];
    this.labels = this.dataSvc.labels;
    this.isDesignee = this.memberRepresentativeService.isDesignee;
    this.isTermed = this.dataSvc.checkForTermedRestrictions;
    this.hideSentArchiveButton = this.dataSvc.hideComposeMessage;
    this.subscriptions.push(this.dataSvc.getfolder().subscribe(
      folder => {
        this.folder = folder;
      }
    ));

    this.subscriptions.push(this.dataSvc.getUnreadCount().subscribe(
      unreadCount => {
        this.unreadCount = unreadCount;
      }
    ));

    this.subscriptions.push(this.dataSvc.getTranscriptUnreadCount().subscribe(
      unread => {
        this.transcriptUnreadCount = unread;
      }
    )); 

    this.checkChatTranscript();    
  }

  public checkChatTranscript(){
    this.isChatTranscriptsEnabled = !this.jsonSvc.hasRestriction(Restriction.SHM_CHAT_TRANSCRIPTS, this.jsonSvc.getRestrictions());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public folderInit(folder) {
    this.dataSvc.setfolder(folder);
  }

}
