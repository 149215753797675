import { Component, Inject, OnInit } from '@angular/core';
import { ReviewOrderComponent } from '@anthem/mbrportal-pharmacy/components/reviewOrderCmp';
import { VendorReviewOrderModel } from '@anthem/mbrportal-pharmacy/models/vendorReviewOrderModel';
import { OrderPrescriptionService } from '@anthem/mbrportal-pharmacy/services/orderPrescriptionService';
import { VendorPaymentWalletService } from '@anthem/mbrportal-pharmacy/services/vendorPaymentWalletSvc';
import { ContentHelper, EventHandler, IWindow } from '@anthem/mbrportal/core';
import { UrlHelper } from '@anthem/mbrportal/http/public_api';
import { AppSession } from '@anthem/mbrportal/main/public_api';
import { DateUtility, Logger, NavigationHelper } from '@anthem/mbrportal/utility/public_api';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { Router } from '@angular/router';
import { OrderWidgetState } from '@anthem/mbrportal-pharmacy/enums/vendorOrders';
import { AhdPharmacyRoutes } from '../ahd-pharmacy-routes.enum';
import { Subscription, forkJoin } from 'rxjs';
import { AccessibilityHelper } from '@anthem/mbrportal/shared/public_api';
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/public-api";

@Component({
  selector: 'app-ahd-track-order-details',
  templateUrl: `../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/reviewOrder.html`,
  styleUrls: ['../../../../../../node_modules/@anthem/mbrportal-pharmacy/assets/styles/partials/reviewOrder.scss', './ahd-track-order-details.component.scss'],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }, { provide: OrderPrescriptionService, useClass: ahdPharmacyService }, { provide: VendorPaymentWalletService, useClass: ahdPharmacyService}]
})
export class AhdTrackOrderDetailsComponent extends ReviewOrderComponent implements OnInit {
  content: any;
  _contentHelper;
  contentSubscription: Subscription;
   orderDetailsContent;
   createDate: string;
   private titleSubscription: Subscription;
  constructor(logger: Logger,
    contentHelper: ContentHelper,
    @Inject(AppSession) appSession: AppSession,
    eventHandler: EventHandler,
    private titleService: InterCommunicationService,
    orderPharmacyService: OrderPrescriptionService,
    reviewOrderModel: VendorReviewOrderModel,
    @Inject('Window') window: IWindow,
    urlHlpr: UrlHelper,
    navigationHelper: NavigationHelper,
    accessibilityHelper: AccessibilityHelper,
    public router: Router,
    public jsonSvc: JsonContentService,
    public pharmacyservice: ahdPharmacyService,
    private dateHelper: DateUtility,
    ) { 
      super(logger, contentHelper, appSession, eventHandler, orderPharmacyService, reviewOrderModel, window, urlHlpr, navigationHelper, accessibilityHelper);
   
     this._contentHelper = contentHelper;
     this.contentSubscription = jsonSvc.getJSON('ahd-review-orders-page').subscribe(([orderDetailsContent]) => {
         contentHelper.registerContent('VendorPrescriptionListComponent', orderDetailsContent.costSummary);
         contentHelper.registerContent('VendorPaymentWalletComponent', orderDetailsContent.vendorPaymentContent);     
    });
  }

  ngOnInit(): void {
    this.paymentId = undefined;
    this.isIntermediateScreenStateBuilding = false;
    let orderIdArray = this.router.url.split('/');
    this.orderId = orderIdArray[orderIdArray.length-1]; // hard coded for testing
    this.paymentSuccessSubscription = this.paymentSuccessEvent.subscribe((paymentId: string) => {
      this.onPaymentSuccess([paymentId]);
    });
    this.getOrderDetails();
    this.getCreatedDate(); 
  }
  
  gotoMyOrders() {
    this.pharmacyTabbedViewHeaderEvent.emit([]);
    this.router.navigateByUrl(AhdPharmacyRoutes.ORDERS);
  }

  ngOnDestroy(): void {
    this.titleService.setDescription('');
    this.titleSubscription.unsubscribe();
    this.paymentSuccessSubscription.unsubscribe();
    this.contentSubscription.unsubscribe();
  }

  getCreatedDate(){
    this.pharmacyservice.getOrderDetails(this.orderId).then(order => {
      this.createDate = order.data.orderCreatedDate;
      this.setPageTitle();
    },
    (error) => {
       this.setPageTitle();
    });
  }

  setPageTitle() {
    this.titleSubscription = this.jsonSvc.getJSON('ahd-review-orders-page').subscribe(content => {
      this.titleService.raiseEvent({ message: content.pageTitle, title: 'HEADER_TITLE'});
      const description = `${content.ReviewOrderComponent.orderIdLabel} ${this.orderId}&nbsp;<br>${content.ReviewOrderComponent.orderDateLabel} ${this.createDate ? this.dateHelper.getDatePart(this.createDate, 'MM/dd/yyyy') : ''}`;
      this.titleService.setDescription(description);
    });
  }
}
