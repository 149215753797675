import { Injectable } from '@angular/core';
import { VcRecaptchaService } from '../../../../libs/vc-recaptcha/src/public-api';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root'
})

/*
When you write @Injectable(providedIn: 'root') this means that the service in singletion for whole application and you can inject in anywhere in the application.

When you want to make service singleton only for an exact module, you need to assign your module as the parameter to the providedIn - @Injectable(providedIn: MyModule)

The only other action required in order to use the @Injectable decorated service is to import it and constructor inject it and that's it. No need to import it in the AppModule.
*/
export class RegistrationDataService {

  constructor(
    private recaptcha: VcRecaptchaService,
    private regSvc: RegistrationService
  ) { }

  private bRequire2FA: boolean;
  retVal: boolean;

  public is2FAFlowRequired(response) {
    this.bRequire2FA = false;
    if(response && response.threatAssessment) {
          // TODO: This logic will need to be solidified once enterprise service bahavior is known
    this.bRequire2FA =
      (response.threatAssessment.status.toUpperCase() === "AUTHENTICATE"
        ? true
        : false) ||
      (response.threatAssessment.suggestedAction.toUpperCase() === "2NDFACTOR"
        ? true
        : false);
    }
    return this.bRequire2FA;
  }

  public convertStringToBoolean(strToEval) {
     this.retVal = false;

     if (typeof strToEval !== "undefined" && strToEval !== null) {
       switch (strToEval.toString().toLowerCase().trim()) {
         case "true":
         case "yes":
         case "1":
         case "on":
           this.retVal = true;
           break;
         default:
           this.retVal = false;
       }
     }
     return this.retVal;
}

public focusInvalid() {
  let invalidFields = document.getElementsByClassName('ng-invalid');
  if(invalidFields[1]) {
    const invalidCheckbox = invalidFields[1].querySelector('input');
    const invalidDropdown = invalidFields[1].querySelector('button');
    if (invalidCheckbox) {
      invalidCheckbox.focus();
    }
    if (invalidDropdown) {
      invalidDropdown.focus();
    } else {
      (invalidFields[1] as HTMLElement).focus();  
    } 
  }
}

setAcctDetailsForm(form) {
  let accountDetails = JSON.parse(sessionStorage.getItem('contactsModel')).memberInput;
  form.controls['acceptedTandC'].setValue(accountDetails.acceptedTandC);
  form.controls['dateOfBirth'].setValue(accountDetails.dateOfBirth);
  form.controls['hcid'].setValue(accountDetails.hcid);
  form.controls['lastName'].setValue(accountDetails.lastName);
  form.controls['firstName'].setValue(accountDetails.firstName);
  sessionStorage.setItem("createUsernameVisited", 'false');
}

  async setCreateUserForm(form) {
  let usernamePassword;
  await this.regSvc.decrypt({token: sessionStorage.getItem('userModel')}).then(res => {
    usernamePassword = JSON.parse(res.body).memberInput;
  })
  form.controls['userName'].setValue(usernamePassword.userName);
  form.controls['emailAddress'].setValue(usernamePassword.emailAddress);
  form.controls['phone'].setValue(usernamePassword.accountRecoveryNumber.phoneNumber);
  form.controls['option'].setValue(usernamePassword.accountRecoveryNumber.phoneType);
  sessionStorage.setItem("securityQuestionsVisited", 'false');
}

  async setSecurityQuestForm(form) {
  let securityQuestions;
  await this.regSvc.decrypt({token: sessionStorage.getItem('reviewDetails')}).then(res => {
    securityQuestions = JSON.parse(res.body);
  })
  form.controls['question1'].setValue(securityQuestions.question1);
  form.controls['question2'].setValue(securityQuestions.question2);
  form.controls['question3'].setValue(securityQuestions.question3);
  form.controls['answer1'].setValue(securityQuestions.answer1);
  form.controls['answer2'].setValue(securityQuestions.answer2);
  form.controls['answer3'].setValue(securityQuestions.answer3);
  sessionStorage.setItem("reviewVisited", 'false');
}

}
