import { Injectable } from "@angular/core";
import { HttpClient2 } from '@anthem/mbrportal/http';
import { AppConfig } from '@anthem/mbrportal/main';
import { GeocodeApi, IAddressByZipResponse } from "@anthem/mbrportal/shared/services/geocodeApi";

@Injectable()
export class AHDGeocodeApi extends GeocodeApi {
  constructor(_httpClient: HttpClient2, private _AHDappCon: AppConfig) {
    super(_httpClient, _AHDappCon);
  }

  getAddressByZip<T = IAddressByZipResponse>(zip: string, sendRawResponse = false): Promise<T> {
    const add: IAddressByZipResponse = {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: zip ?? '',
      latitude: '',
      longitude: '',
      country: ''
    };
    return Promise.resolve(add as unknown as T);
  }
}