import { Component, Input } from "@angular/core";
import { ContentHelper } from "@anthem/mbrportal/core";
import { AppSession } from "@anthem/mbrportal/main";
import { BaseComponent } from "@anthem/mbrportal/shared/public_api";
import { Logger } from "@anthem/mbrportal/utility";
import { SsoService } from "./sso.service";
import { SsoEventService } from './ssoEvent.service';

@Component({
  selector: 'app-secure-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SecureSsoLinkComponent extends BaseComponent {
  @Input()
  analytics: boolean;

  @Input()
  ariaLabel: boolean;

  @Input()
  isButton: boolean;

  @Input()
  ssoId: string;

  @Input()
  isTopNav: boolean;

  @Input()
  linkText: string;

  @Input()
  actionUrl: string;

  constructor(
    _logger: Logger,
    _contentHelper: ContentHelper,
    _appSession: AppSession,
    private ssoApi: SsoService,
    private ssoEventService: SsoEventService
  ) {
    super(_logger, _contentHelper, _appSession);
  }

  getSSOParams() {
    if (this.ssoId) {
        this.ssoApi.getSSOData(this.ssoId).subscribe((result: any) => {
          if(result?.body?.ssoParameters && result?.body?.samlUrl) {
            this.ssoEventService.emit({samlUrl: this.generateSamlUrl(result.body.samlUrl), ssoParameters: this.generateSsoParams(result?.body?.ssoParameters)})
          }
        });
    }
  }

  generateSamlUrl(samlUrl: string) {
    let hostname = window.location.hostname;
    let hostnameArr = hostname.match(/[^\.]*\.com$/);
    if(hostnameArr) {
      return samlUrl.replace(/[^\.]*\.com/, `${hostnameArr[0]}`);
    }
  }

  generateSsoParams(ssoParams: any) {
    let actionUrl = this.actionUrl;
    if(actionUrl) {
      if(actionUrl.substr(0,1) === '/') {
        actionUrl = actionUrl.substr(1);
      }
      ssoParams.RelayState += actionUrl;
    }
    return ssoParams;
  }
}
