import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';


@Component({
  selector: "app-date-range-filter",
  templateUrl: "./date-range-filter.component.html",
  styleUrls: ["./date-range-filter.component.scss"],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() dateRangeFilters;
  dateRangeValue: string;
  defaultLabel = '';
  constructor(private datepipe: DatePipe) { }

  ngOnInit(): void {
  }
  getDateFormat = ( date: Date ) => {
    return this.datepipe.transform( date, 'yyyy-MM-dd' );
  }

  getDateRangename = ( dateRangeVal: string ) => {
    return this.dateRangeFilters.options.filter( object => object.value == dateRangeVal )[ 0 ].label.toLowerCase();
  }

  onDateRangeChange = () => {
    //TO-D0
  }

  setstartDate = ( selectedrange: string ) => {
    //TO-DO
  }
}