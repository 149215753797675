import { Injectable } from "@angular/core";
import { PhoneNumPipe } from 'sydmed/libs/common-pipes/src/lib/phone-number/phone-num.pipe';
import { OrderBy } from '@anthem/mbrportal/shared/pipes/orderBy';
import moment from 'moment-timezone';

@Injectable({
    providedIn: "root"
})
export class ThirdPartyInsuranceModel {

    constructor(private orderBy: OrderBy, private phoneNum: PhoneNumPipe) { }

    buildTPIDetails(rawTPIData: any): any {

        let tpiDetailsArr = [];

        for (let i = 0; i < rawTPIData.ohiDetailsList.length; i++) {
            const ohiIndividualInsurance = {
                subscbrName: this.getName(rawTPIData.memberInfo),
                /**
                 * ohiOrderCode returns None and Unknown values randomly for PR and UKN codes, and there was no confirmation yet
                 * on which one should be referred as Unknown and None. As of now,
                 * It was decided to have the Unknown Value shown for both the ordercodes PR and UNK
                 */
                tplInsuranceOrderCode: rawTPIData.ohiDetailsList[i].ohiOrderCode !== 'PR' ? rawTPIData.ohiDetailsList[i].ohiOrderCode : 'UNK',
                coverageType: rawTPIData.ohiDetailsList[i].ohiTypeDesc,
                coverageTermDate: this.getDate(rawTPIData.ohiDetailsList[i].termDate, true),
                summaryDisplayTermDate: this.formatDisplayDate(rawTPIData.ohiDetailsList[i].termDate),
                coverageEffDate: this.getDate(rawTPIData.ohiDetailsList[i].effDate),
                groupNum: this.getFormattedGroupId(rawTPIData.ohiDetailsList[i].ohiGroupId),
                insurancePolicy: rawTPIData.ohiDetailsList[i].ohiPolicyId,
                tplCompName: rawTPIData.ohiDetailsList[i].ohiCompanyName,
                tplSubscbrName: this.getName(rawTPIData.ohiDetailsList[i].secondaryMemberInfo),
                tplAddr: rawTPIData.ohiDetailsList[i].ohiAddress,
                tplPhone: this.formatPhoneNumber(rawTPIData.ohiDetailsList[i].ohiAddress.phone),
                tplsubscbrId: rawTPIData.ohiDetailsList[i].secondaryMemberInfo.subscriberId
            };

            tpiDetailsArr.push(ohiIndividualInsurance);
        }

        return this.sortTPIArray(tpiDetailsArr);
    }


    getName(memberName: any) {
        if (memberName.firstName || memberName.lastName) {
            return memberName.firstName + " " + memberName.lastName;
        }
        return;
    }

    getFormattedGroupId(groupId: string) {
        if (groupId) {
            /**
             * splitting the groupid based on the first occurence of # and capturing 
             * the rest of the text if groupID has # in it                
             */
            return groupId.includes('#') ? groupId.split(/#(.+)/)[1] : groupId;
        }
        return;
    }

    /***
     * checkDefaultDate flag is passed as true in case of termination date. 
     * 
     * The termination date will display if it is equal to or greater than the logged in member's current date
     * (if the termed date is in the past, SOA doesn't returns it)
     * If a default term date such as 9999-12-31 or 2199-12-31 is returned, no date will be displayed on UI
     */
    getDate(date: Date, checkDefaultDate?: boolean) {
        if (date) {
            const dateObj: Date = new Date(date + "T00:00:00");
            if (checkDefaultDate) {
                let dateNow = new Date();
                dateNow.setHours(0, 0, 0, 0);
                // check for the default dates 9999-12-31 and 2199-12-31
                if (((new Date("9999-12-31T00:00:00")).valueOf() === dateObj.valueOf()) || ((new Date("2199-12-31T00:00:00")).valueOf() === dateObj.valueOf())) {
                    return "";
                }
            }
            return [('0' + (dateObj.getMonth() + 1)).slice(-2), ('0' + dateObj.getDate()).slice(-2), dateObj.getFullYear()].join('/');
        } else {
            return "";
        }
    }

    formatPhoneNumber(phone: string) {
        return phone ? this.phoneNum.transform(phone) : '';
    }

    formatDisplayDate(date: string) {
        return date ? moment(new Date(date + "T00:00:00")).format('MMMM D, YYYY') : '';
    }

    // sorts array based on the 'tplInsuranceOrderCode'
    sortTPIArray(tpiDetails: Array<any>) {
        return tpiDetails ? this.orderBy.transform(tpiDetails, 'tplInsuranceOrderCode') : tpiDetails;
    }
}
