import { Injectable } from '@angular/core';
import { SessionKey } from '../models/memberRepSession';
import { MemberType } from 'gbd-models'

@Injectable({
    providedIn: 'root'
})

export class MemberRepSession {

    // Need to Move this to Validate token page while integration and pull the value from service
    setMemberType(memberType: MemberType) {
        sessionStorage.setItem(SessionKey.MEMBER_TYPE, memberType);
    }

    // Need to change this while integration
    getMemberType(): MemberType {
        const memberType = sessionStorage.getItem(SessionKey.MEMBER_TYPE);
        return MemberType[memberType];
    }

    setMemberRepToken(token: string){
        return sessionStorage.setItem(SessionKey.MEMBER_TOKEN, token);
    }

    getMemberRepToken(): string{
       return sessionStorage.getItem(SessionKey.MEMBER_TOKEN);

    }

}