import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'si'
})
export class StringInterpolationPipe implements PipeTransform {

  transform(text: string, args?: any): string {
    const replaceText = (text, args) => {
      if(Object.keys(args || {}).length > 0) {
        for (const property in args) {
          text = text ? text.replace(`{${property}}`, args[property]) : text
        }
        return text
      } else {
        return ''
      }
    }

    return replaceText(text, args)
  }

}
