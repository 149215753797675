import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/public-api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrievancesAndAppealsLandingLabels, DefaultLabels } from "../../models/labels";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";

@Component({
  selector: "app-appeals-and-grievances-landing",
  templateUrl: "./appeals-and-grievances-landing.component.html",
  styleUrls: ["../../appeals-and-grievances.scss"],
})
export class AppealsAndGrievancesLandingComponent implements OnInit {
  public activePath: string;
  public landingPageLabels: GrievancesAndAppealsLandingLabels;
  public defaultLabels: DefaultLabels;
  public appealsAndGrievancesLandingForm: FormGroup;
  public showAppealsForm: boolean = false;
  public enableSubmit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private router: Router
  ) {
    
    this.appealsAndGrievancesLandingForm = this.formBuilder.group({
      sendAnswer: ["", [Validators.required]],
    });

    this.activePath = this.router.url?.split("/")[2];
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "Grievance / Appeal Form",
    });
  }

  getLabels(): void {
    this.jsonSvc.getJSON("appeals-and-grievances").subscribe((response) => {
      this.defaultLabels = response.defaults;
      this.landingPageLabels = response.landingInformation;
    });
  }

  submitAnswer() {
    if (this.showAppealsForm === true) {
      this.router.navigateByUrl("secure/appeal");
    } else {
      this.router.navigateByUrl("secure/grievance");
    }
  }

  onChangeInput(ans: any) {
    this.enableSubmit = true;
    if (ans === "yes") {
      this.showAppealsForm = true;
    } else {
      this.showAppealsForm = false;
    }
  }

  ngOnInit() {
    this.getLabels();
  }
}
