import { Component, Input, OnInit } from '@angular/core';
import { VisionBenefitsContent } from '../interfaces/benefitsContent';

@Component({
    selector: 'benefits-vision-component',
    templateUrl: './vision-benefits.component.html',
    styleUrls: ['./vision-benefits.component.scss']
})
export class VisionBenefitsComponent implements OnInit {
    @Input() content: VisionBenefitsContent;
    constructor() { }

    ngOnInit(): void {
    }

}
