
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ReferrerService {
    constructor() {}

    public referredFromExternalSite() {
        return document.referrer && document.referrer.indexOf(location.protocol + '//' + location.host) !== 0;
    }

    public referredFromSpanishSite() {
        return document.referrer && (document.referrer.includes('es-') || document.referrer.includes('espanol'));
    }
}
