import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
@Injectable({
    providedIn: 'root'
  })
export class OpenAttachment {
    generateUrl(response:  HttpResponse<ArrayBuffer>) {
        const contentType = response.headers.get("Content-Type");
        const pdfBlob = new Blob([response.body], { type: contentType });
        return URL.createObjectURL(pdfBlob);
    }
    deviceType() {
        return navigator.userAgent;
    }
    isMobileDevice(): boolean {
        return /iPad|iPhone|Android/i.test(navigator.userAgent);
      }
}