/**
 * <lib-email> selector has to pass the email label name, error object and formSubmitted flag, emailID
 *  as input
 */

import { Component, forwardRef, OnDestroy, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, ControlValueAccessor, Validators, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';

@Component({
  selector: 'lib-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailComponent implements ControlValueAccessor, OnDestroy, OnChanges {
  form: FormGroup;
  subscriptions: Subscription[] = [];
  @Input('emailLabel') emailLabel: string;
  //@Input('errObj') errorsObj: object; //TODO
  @Input('errObj') errorsObj: any;  
  @Input('formSubmitted') formSubmitted: boolean;
  @Input('id') emailId: string;
  @Input('placeholder') placeholder: string = "";
  
  @ViewChild('emailForm') emailForm: any;

  get value() {
    return this.form.value;
  }

  set value(value) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get email() {
    return this.form.controls.email;
  }

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, FormValidators.EmailValidator]]
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { email: { valid: false, }, };
  }
  
  reset() {
    this.emailForm.resetForm();
    this.form.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formSubmitted = changes.formSubmitted.currentValue;
    if(changes.formSubmitted.currentValue && this.form.invalid) {
      this.form.markAllAsTouched();
    }
  }
}
