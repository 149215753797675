import { Component, OnInit, ElementRef, ViewChild, Inject, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationDataService } from 'sydmed/src/app/public/registration/app.data.service';
import { ContactModel } from './account-details.model';
import { RegistrationService, RegistrationRouteParam } from 'sydmed/src/app/public/registration/registration.service';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { AslStatusService } from 'sydmed/libs/asl-status/src/lib/asl-status.service';
import { VcRecaptchaService } from 'sydmed/libs/vc-recaptcha/src/public-api';
import { MaskingService } from 'sydmed/libs/masking-service/src/lib/masking.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { ICheckboxInput } from '@anthem/uxd/util';
import { Modal, ModalRef } from '@anthem/uxd/modal';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { LOCALE_KEY, LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
import { LinkSourceType, RegistrationValidateTokenRequest, RegistrationValidateTokenResponse } from 'gbd-models';
import { map } from 'rxjs/operators';
import { SessionStorage } from 'sydmed/libs/storage-service/src/public-api';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css'],
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class AccountDetailsComponent implements OnInit {

  @ViewChild('recaptcha', { static: false }) set recaptchaContent(recaptchaElement: ElementRef) {
    // Add recaptcha script after element has been rendered in the dom.
    if (recaptchaElement) {
      this.recaptchaElement = recaptchaElement;      
      this.recaptcha.addRecaptchaScript(this.recaptchaKey, this.recaptchaElement);
      this.recaptcha.timedReload(); 
    }         
  };

  recaptchaElement: ElementRef;

  constructor(
    private readonly formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dataSvc: RegistrationDataService,
    private recaptcha: VcRecaptchaService,
    private aslStatusSvc: AslStatusService,
    private contactsModel: ContactModel,
    private regSvc: RegistrationService,
    private errSvc: ErrorCodeMapperService,
    private maskingSvc: MaskingService,
    private jsonSvc: JsonContentService,
    private _modal: Modal,
    private http: HttpClientService,
    private localeService: LocaleService,
    @Inject('Window') window: Window
  ) {}

  @ViewChild('ToULink') termsOfUseLink: ElementRef;
  @ViewChild('cardImg') cardImg: ElementRef;
  @ViewChild('modalTemplate') modalTemplate: TemplateRef<any>;

  private _modalRef: ModalRef<any, any>;

  AccountDetailsForm: FormGroup;
  public acctDetailsModel: any;
  public requestObject;
  public aslError: any;
  public jsonLabels;
  public jsonErrors;
  public termsOfUseJson;
  public exampleIdCardJson;
  private require2FA: boolean = false;
  private sessionModel: any;
  public brand: string;
  public isAnthem: boolean = false;
  idTypesDropdown: any;
  private locale: string;
  isRegistration: boolean = false;
  idType: string;
  idLabel: string;
  isLoading = true;
  validToken: boolean = false;
  validateTokenRequest: RegistrationValidateTokenRequest;
  validateTokenResponse: RegistrationValidateTokenResponse;
  showRegistrationHeader: boolean = false;

  public checkbox: ICheckboxInput = {
    id: 'ux-guide-checkbox-1',
    name: 'acceptedTandC',
    label: '',
    isRequired: true,
    trueValue: true,
    falseValue: false
  };

  public isChecked: boolean;
  jsonAriaLabel: any;
  recaptchaEnabled = false;
  recaptchaKey: string;

  async ngOnInit() {
    if (this.isRegistrationPage()) {
      this.isRegistration = true;
    }
    
    const statusResponse = await this.aslStatusSvc.getAsychStatus();
    const { recaptchaEnabled, recaptchaKey } = statusResponse?.body;
    this.recaptchaEnabled = recaptchaEnabled ?? false;
    this.recaptchaKey = recaptchaKey;

    if (this.isRegistration) {
      this.validateTokenRequest = this.getLinkToken();
      if (this.validateTokenRequest.linkToken) {
        this.validateTokenResponse = await this.regSvc.validateToken(this.validateTokenRequest);
        this.validToken = this.validateTokenResponse.isValidToken;
      }
      await this.setRegistrationContent();
    } else {
      await this.setForgotUserNamePasswordContent();
    }

    if (this.isAnthemSite()) {
      this.isAnthem = true;
    }
    const createUsernameVisited = sessionStorage.getItem('createUsernameVisited') === 'true'
    if (this.isRegistration && createUsernameVisited && !this.validToken) {
      this.dataSvc.setAcctDetailsForm(this.AccountDetailsForm);
    }
    this.locale = this.localeService.getLocaleOverride()
    const itemForSession = []; 
    if(this.validToken) {
      itemForSession.push({key: SessionStorage.GBD_TOKEN, value: sessionStorage.getItem(SessionStorage.GBD_TOKEN)});
    }

    if (this.locale) {
      itemForSession.push({key: LOCALE_KEY, value: this.locale});
    }
    sessionStorage.clear();
    this.setItemsInSession(itemForSession);
    this.setFormDetails();
    this.isLoading = false;
  }

  private isAnthemSite() {
    return window.location.href.toLowerCase().indexOf('anthem.com') >= 0 && window.location.href.toLowerCase().indexOf('publicprograms.') < 0;
  }
  private isRegistrationPage() {
    return window.location.href.toLowerCase().indexOf('registration') >= 0;
  }

  getLinkToken(): RegistrationValidateTokenRequest {
    const queryParamMap = this.route.snapshot.queryParamMap;
    const sourceType = queryParamMap.get(RegistrationRouteParam.SOURCE_TYPE) as LinkSourceType;
    const linkToken = queryParamMap.get(RegistrationRouteParam.TOKEN);
    return { linkToken, sourceType };
  }

  ngAfterViewInit() {
    this.recaptcha.timedReload();
  }

  private setForgotUserNamePasswordContent() {
    return this.jsonSvc.getJSON('forgot-username-password-mfa').pipe(map(content => {
      const { ErrorMsgs, Labels } = content?.ForgotUsernamePasswordMFA;
      this.jsonLabels = Labels.Account_Details;
      this.jsonErrors = ErrorMsgs;
      this.idTypesDropdown = Labels.Account_Details.ID_TYPES_DROPDOWN;
      this.idType = Labels.Account_Details.ID_TYPES_DROPDOWN.options[0].value;
      this.idLabel = Labels.Account_Details.ID_TYPES_DROPDOWN.options[0].label;
      this.showRegistrationHeader = this.jsonLabels.Page_Heading;
    })).toPromise();
  }

  private setRegistrationContent() {
    return this.jsonSvc.getJSON('registration-mfa').pipe(map(content => {
      const { ErrorMsgs, Labels } = content?.RegistrationMFA;
      this.jsonLabels = Labels.Account_Details;
      this.jsonErrors = ErrorMsgs;
      this.checkbox.label = this.jsonLabels.Field_Labels.Agree;
      this.jsonAriaLabel = Labels.Login_Aria_Label;
      this.idType = Labels?.Account_Details?.ID_TYPES_DROPDOWN?.options[0].value;
      this.idLabel = Labels?.Account_Details?.ID_TYPES_DROPDOWN?.options[0].label;
      this.idTypesDropdown = Labels?.Account_Details?.ID_TYPES_DROPDOWN;
      this.showRegistrationHeader = (this.validToken && this.jsonLabels.Non_Hcid_Page_Heading) || (!this.validToken && this.jsonLabels.Page_Heading);
    })).toPromise();
  }

  setFormDetails() {
    this.AccountDetailsForm = this.getDefaultForm();
    if (this.isRegistration) {
      if (this.validToken) {
        this.removeFormControls(['hcid', 'rid', 'planid', 'idType']);
      }

      this.AccountDetailsForm.addControl('acceptedTandC', this.formBuilder.control(false, [Validators.requiredTrue]));
    }
    if (this.recaptchaEnabled) {
      this.AccountDetailsForm.addControl('recaptchaResponse', this.formBuilder.control('', [Validators.required]));
      this.AccountDetailsForm.get('recaptchaResponse').updateValueAndValidity();      
    }
  }

  public checkEligibility() {
    this.onChangeInput();
    this.acctDetailsModel = this.contactsModel.getAccountDetailsModel();
    if (this.AccountDetailsForm.valid) {
      // call here to update recaptcha in model
        for (let i in this.idTypesDropdown.options) {
          if (this.idTypesDropdown.options[i].value !== this.idType) {
            delete this.acctDetailsModel[this.idTypesDropdown.options[i].value];
          }
        }
        if (this.acctDetailsModel.planid) {
          this.acctDetailsModel.hcid = this.acctDetailsModel.planid;
          delete this.acctDetailsModel.planid;
        }          
      
      const eligibilityRequest = {...this.acctDetailsModel };
      return this.callEligibilityAPi(eligibilityRequest);
      // *** TO DO Throw error message
    } else {
      this.AccountDetailsForm.markAllAsTouched();
      if (this.isRegistration) {
        this.dataSvc.focusInvalid();
      } else {
        let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[1].focus();
      }
    }
  }

  private callEligibilityAPi(data) {
    if (this.isRegistration) {  
      this.regSvc.callEligibilityAPI(data, this.validToken).subscribe(
        response => {
          sessionStorage.setItem('asl-token', response.headers.get('asl-token'));
          sessionStorage.setItem('contactsModel', JSON.stringify(this.contactsModel.transformResponse(response.body)));
          this.require2FA = this.dataSvc.is2FAFlowRequired(response.body);
          if (!this.require2FA) {
            sessionStorage.setItem('disabled2FAUser', 'true');
            if (response.body?.caregiverRequired) {
              sessionStorage.setItem('caregiverRelationTypeVisited', 'true')
              this.router.navigate(['public/registration/caregiver-relation-type']);
            }
            else {
              sessionStorage.setItem('createUsernameVisited', 'true');
              this.router.navigate(['public/registration/create-username-password']);
            }
          } else {
            sessionStorage.setItem('disabled2FAUser', 'false');
            this.router.navigate(['public/registration/get-security-code']);
          }
        },
        error => {
          this.callErrorHandler(error);
        }
      );
    } else {
      const url = UrlProperties.UrlProperties.endPoints.forgotUsernamePassword.eligibility;
      this.http.postObservable(url, data).subscribe(
        response => {
          if (response.status === 200) {
            sessionStorage.setItem('asl-token', response.headers.get('asl-token'));
            sessionStorage.setItem('contactsModel', JSON.stringify(this.contactsModel.transformResponse(response.body)));
            this.require2FA = this.dataSvc.is2FAFlowRequired(response.body);
            if (!this.require2FA) {
              this.router.navigate(['public/forgot-username-or-password/answer-security-questions']);
            } else {
              this.router.navigate(['public/forgot-username-or-password/get-security-code']);
            }
          }
        },
        error => {
          this.callErrorHandler(error);
        }
      );
    }
  }

  callErrorHandler(error) {
    if (this.recaptchaEnabled) {
      this.recaptcha.reload(this.recaptchaElement);
    }
    this.aslError = this.errSvc.errorCodeMapper(error, this.jsonErrors);
  }

  public onChangeInput(id?: string) {
    this.idLabel = this.idTypesDropdown.options.find(option => option.value === this.idType)?.label;
    this.enableIdTypeDropdown();

    if (id) {
      this.AccountDetailsForm.controls[this.idType].setValue(id);
    }

    this.contactsModel.updateAccountDetailsModel(this.AccountDetailsForm, !this.validToken);
    if (this.recaptcha.getRecaptchaResponse()) {
      this.AccountDetailsForm.controls['recaptchaResponse'].setValue(this.recaptcha.getRecaptchaResponse())
    }
  }

  enableIdTypeDropdown() {
    for (let i in this.idTypesDropdown.options) {
      if (this.idTypesDropdown.options[i].value !== this.idType) {
        this.AccountDetailsForm.controls[this.idTypesDropdown.options[i].value]?.disable();
        this.AccountDetailsForm.controls[this.idTypesDropdown.options[i].value]?.setValue('');
      } else {
        this.AccountDetailsForm.controls[this.idTypesDropdown.options[i].value]?.enable();
      }
    }
  }

  public showHide(element: HTMLElement, label?: string) {
    this.maskingSvc.showHide(element, label);
  }

  open() {
    this._modalRef = this._modal.open(this.modalTemplate);
  }

  onClose() {
    this._modalRef.close();
    this._modalRef.onDismiss.unsubscribe();
    this._modalRef.onClose.unsubscribe();
  }

  private getDefaultForm(): FormGroup {
    return this.formBuilder.group({
      dateOfBirth: ['', [Validators.required, FormValidators.ValidDateValidator, FormValidators.BirthDateValidator]],
      deviceFingerprint: {},
      firstName: ['', [Validators.required, FormValidators.MemberNameValidator]],
      hcid: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      idType: [this.idType, [Validators.required]],
      lastName: ['', [Validators.required, FormValidators.MemberNameValidator]],
      planid: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]    
    });
  }

  private removeFormControls(controlNames: string[]) {
    controlNames.forEach(controlName => {
      this.AccountDetailsForm.removeControl(controlName);
    });
  }

  private setItemsInSession(items: {key: string, value: string}[]) {
    items.forEach(item => {
      sessionStorage.setItem(item.key, item.value);
    });
  }

}