import { PrescriptionContent, BenefitOrdersContent } from "@anthem/mbrportal-pharmacy/components/prescriptionBenefitOrdersCmp.content";
import { UrlType } from "./LeftNavigation";

export interface AHDBenefitOrderContent extends BenefitOrdersContent {
    key: BenefitOrdersContentKey;
}

export interface PrescriptionBenefitOrdersContent extends PrescriptionContent {
    urlsByPlanCode: UrlsByPlanCode;
    urlType: UrlType;
}

export interface UrlsByPlanCode {
    [key: string]: string;
}

export enum BenefitOrdersContentKey {
    PHARMACY_BENEFITS = 'PHARMACY_BENEFITS',
    PHARMACY_ORDERS ='PHARMACY_ORDERS'
}

