import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";
import { AcceptedTermsOfUseRequest } from 'gbd-models'
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseService {
    constructor(private httpSvc: HttpClientService) {}
    
    public updateTOU(requestObj: AcceptedTermsOfUseRequest) {
        const url = UrlProperties.UrlProperties.endPoints.login.updateTOU;
        return this.httpSvc.postObservable(url,requestObj);
      }
}