import { Injectable } from "@angular/core";
import { HttpClientService } from "sydmed/libs/http-client-service/src/public-api";
import { HttpClient } from "@angular/common/http";
import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";
import { HealthAccountStatements } from 'gbd-models';
import { Observable } from "rxjs/internal/Observable";

const powerAccEndPoints = UrlProperties.UrlProperties.endPoints.powerAccount;

@Injectable({
  providedIn: "root"
})
export class PowerAccountService {
  constructor(protected http: HttpClientService, private httpClient: HttpClient) { }

  // get power account summary
  public getPASummary() {
    return this.http.getObservable(powerAccEndPoints.powerSummary);
  }

  // get power account transactions for current year and previous year
  public getPATransactions() {
    return this.http.getObservable(powerAccEndPoints.powerTransactions);
  }

  // get power account monthly statements
  public getStatements(): Observable<HealthAccountStatements> {
    return this.http.getObservable(powerAccEndPoints.powerAccountStatements);
  }

  // get power account PDF statement
  public getPAStatement(statementID: number): Observable<any> {
    return this.httpClient.get(powerAccEndPoints.powerAccountPDFStatement + statementID, { responseType: 'arraybuffer' as 'json' });
  }
}