import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { SupportFormsContent, SupportForms } from './support-forms-content';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';

@Component({
  selector: 'app-support-forms',
  templateUrl: './support-forms.component.html',
  styleUrls: ['./support-forms.component.scss']
})

export class SupportFormsComponent implements OnInit {
  content: SupportForms;
  protected subscription = new Subscription();
  contentError = false;

  constructor(private jsonSvc: JsonContentService,
              protected contentService: JsonContentService,
              protected titleService: InterCommunicationService,
    ) { 
      this.titleService.raiseEvent( {
        title: 'HEADER_TITLE',
        message: 'Forms'
      } );
  
  }

  public loading: boolean;

  ngOnInit(): void {
    this.loading = true;
    const labelsSubscription = this.subscribeToLabels();
    this.subscription.add( labelsSubscription );
  }

  subscribeToLabels() {
    return this.contentService.getJSON( 'support-forms' ).subscribe(
      ( data: SupportFormsContent ) => {
        if ( data ) {
          this.content = data.SupportForms;
          this.loading = false;
        }
      }, error => this.contentError = true );
  }

}