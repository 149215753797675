<div class="" *ngIf="labels">
    <div class="container lr-pad">
        <div class="row header-section">
            <a routerLink="/secure/make-payment" class="ant-action-link" [attr.aria-label]="headerJson.ManagePayments">
                <span class="motif-icon motif-arrows-long-left"></span>
                {{headerJson.ManagePayments}}
            </a>
        </div>
    </div>
    <hr class="header-border">
    <div class="container">
        <inline-alert-container [name]="'payment-alert'" [alertType]="alertPosition"></inline-alert-container>
    </div>
    <div class="container lrt-pad">
        <div class="row">
            <h2 class="section-header-two" [attr.aria-label]="labels.YourPaymentHistory">{{labels.YourPaymentHistory}}</h2>
            <p class="description-text" [attr.aria-label]="labels.PaymentHistoryDesc">{{labels.PaymentHistoryDesc}}</p>
        </div>
    </div>
    <div class="container motif-margin-0">          
        <div class="display-block" *ngIf="isPaymentsError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}" [attr.aria-label]="errorMsgs.GeneralSystemErrorWithoutCode">
            <span>{{errorMsgs.GeneralSystemErrorWithoutCode}}</span>
        </div>
    </div>
    <div class="container" *ngIf="isPaymentsLoading && !isPaymentsError">
        <div class="row" id="search-loader">
            <uxd-spinner></uxd-spinner>
        </div>      
    </div>
    <div class="container">
        <div class="row motif-margin-bottom-13" #paymentRecords>
            <app-payments-list [content]="labels" [displayList]="displayList" *ngIf="!isPaymentsLoading && !isPaymentsError"></app-payments-list>
        </div>
    </div>    
    <div class="container lrb-pad">
        <div class="button-group load-more">
            <button [attr.aria-label]="labels.LoadMore"
            *ngIf="paymentList && ((displayList && displayList.length > 0 && paymentList.length > displaySize) && (currentListIndex < paymentList.length))" 
            class="btn btn-secondary motif-margin-top-0" type="button" (click)="loadMore()">{{labels.LoadMore}}</button>
        </div>
    </div>
</div>