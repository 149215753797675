<div class="out-of-pocket">
    <h2 class="section-header-two">{{content.outOfPocket.title}}</h2>
    <p class="subtitle">{{content.outOfPocket.subTitle}}</p>
    <div class="cards-wrapper">
        <div class="card large-card card-flat card-popover" *ngIf="hasData; else noData">
        <div *ngIf="!hasOnlyCopay">
            <div class="total-container"  *ngIf="!isError; else quarterTotalError">
                <p class="total-label">{{content.outOfPocket.quarterTotalLabel}}</p>
                <p class="total">{{ quarterTotal | currency: 'USD'}}</p>
            </div>
            <ng-template #quarterTotalError>
                <div *ngIf="outOfPocketErrorMessage"  class="container width-container" data-uxd-alert-cmp-v2
                      [alert]="{alertType: 'negative'}">
                     <span [innerHTML]="outOfPocketErrorMessage"></span>
                </div>
            </ng-template>
            <div  *ngIf="!isError; else quartersError" class="quarters">
                <div class="quarter" *ngFor="let quarter of quarters">
                    <p class="quarter-title">{{quarter.title}}</p>
                    <p class="quarter-amount">{{quarter.amount | currency: 'USD'}}</p>  
                    <p class="quarter-subtitle">{{content.outOfPocket.quarterSubTitle}}</p>                  
                </div>               
            </div>
            <ng-template #quartersError>
               <div class = "quarters">
                <div class="quarter" *ngFor="let quarter of content.outOfPocket.quarterTitles; let i=index">
                    <p class="quarter-title">{{quarter}}</p>  
               
                  <span class="error-container">  <p class="quarter-x">{{content.outOfPocket.X}} </p>   
                   <span class ="quarter-errors" [innerHTML]="quartersErrorMessage"></span>
                 </span>
                </div>   
               </div>
            </ng-template>
        </div>
            <div class="total-container"  *ngIf="!isError; else annualTotalError">
                <p class="total-label">{{content.outOfPocket.annualTotalLabel}}</p>
                <p class="total">{{hasOnlyCopay? (annualCopay | currency: 'USD') : (annualTotal | currency: 'USD')}}</p>
            </div>
            <ng-template #annualTotalError>
                <div *ngIf="outOfPocketErrorMessage" class="container width-container" data-uxd-alert-cmp-v2
                      [alert]="{alertType: 'negative'}">
                     <span [innerHTML]="outOfPocketErrorMessage"></span>
                </div>
            </ng-template>
        </div>
        <ng-template #noData>
            <div class="card large-card card-flat card-popover">
                <div *ngIf="outOfPocketErrorMessage"  class="container width-container" data-uxd-alert-cmp-v2
                  [alert]="{alertType: 'negative'}">
                 <span [innerHTML]="outOfPocketErrorMessage"></span>
                </div>
            </div>            
        </ng-template>
    </div>
</div>