<ng-container *ngIf="pageLoader; else showPageContent">
  <div class="ant-ui-widget-overlay"></div>
  <div class="ant-ajax-load">
    <uxd-spinner></uxd-spinner>
    <div>Loading, please wait…</div>
  </div>
</ng-container>
<ng-template #showPageContent>
  <ng-container *ngIf="genericErr; else showPage">
    <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span [innerHTML]="genericErr"></span>
    </div>
  </ng-container>
  <ng-template #showPage id="power-account">
    <div class="container" *ngIf=isCoronaActive>
      <div class="card large-card card-flat card-popover">
          <div class="row display-flex">
              <div class="flex-size">
                  <span class="motif-icon motif-attention"></span>
              </div>
              <div class="col-md-11 col-sm-11">
                  <h4 [innerHtml]="content?.CoronaVirusUpdate?.Heading"></h4>
                  <p class="motif-margin-top-4" [innerHtml]="content?.CoronaVirusUpdate?.EnglishText_lbl"></p>
                  <p [innerHtml]="content?.CoronaVirusUpdate?.SpanishText_lbl"></p>
              </div>
          </div>
      </div>
    </div>
    <div>
      <div class="container lr-pad">
        <div class="row">
          <h2 class="section-header-two" [innerHtml]="content?.PwrAcctSummary_lbls.PwrAcctSummaryHeader_lbl"></h2>
        </div>
      </div>
      <app-power-account-summary [content]="content" [hasMakePayment]="hasMakePayment"></app-power-account-summary>
      <div class="container lr-pad">
        <div class="row">
          <h2 class="section-header-two motif-margin-top-15" 
          [innerHtml]="content?.PwrAcctActivity_lbls.PwrAcctActHeader_lbl"></h2>
          <ng-container *ngIf="showUrgentMsg">
            <inline-alert-container [name]="'transaction-urgent-err'" [alertType]="'universalInlineAlert'"></inline-alert-container>
          </ng-container>
        </div>
      </div>
      <app-power-account-activity [content]="content" (showUrgentTxt)="showUrgentTxt($event)"></app-power-account-activity>
      <div class="vgr-divider-border-bottom motif-margin-bottom-19 motif-margin-top-12"></div>
      <app-power-account-monthly-stmt [content]="content" [hasPlanNotifications]="hasPlanNotifications"></app-power-account-monthly-stmt>
    </div>
  </ng-template>
</ng-template>
