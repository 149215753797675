<div [formGroup]="form" #pwdForm="ngForm" class="custom-display">
  <div class="new-password-container margin-bottom-40">
    <div class="form-item">
      <label for="new-password">{{ pwdLabel }}</label>

      <div class="mask-wrapper">
          <input class="ant-text-input" id="new-password" aria-describedby="new-password-errors" aria-required="true"
          (focus)="onFocus()" (blur)="onBlur()" [ngClass]="{'inputInvalid': (password.dirty || password.touched || formSubmitted) && (form?.hasError('userNameMatched') || form?.hasError('consecChars'))}"
          #input #pwdMask #pwd formControlName="password" name="pwd" data-tcp-password-valid-dir type="password" required />
          <span id="pwd-mask" class="align-eye" data-uxd-security-mask-cmp [definition]="{maskedInput: pwdMask, ariaLabelMasked: ('Unmask '+pwdLabel), ariaLabelUnMasked: ('Mask '+pwdLabel)}"></span>
      </div>

    </div>
    <div
      class="error-list pwd-errors-align"
      id="new-password-errors" aria-live="polite">
      <div *ngIf="(password.dirty || password.touched || formSubmitted) && (password.invalid || form?.hasError('userNameMatched') || form?.hasError('consecChars'))">
      <div *ngIf="(password?.errors?.required); else showOtherErrs">
        <span class="error"><i class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
              <span [innerHtml]="pwdLabel"></span>
              <span [innerHtml]="errorsObj.Required_err"></span>
          </span>
        </span>
      </div>
      <ng-template #showOtherErrs>
          <div *ngIf="(password.touched || formSubmitted) && showTooltipErrOnBlur && (password.invalid || form?.hasError('userNameMatched') || form?.hasError('consecChars'))">
            <span class="error"><i class="motif-icon motif-delete"></i>
              <span class="motif-margin-left-5">
                <span>{{errorsObj.Password_Requirement_Not_Met}}</span>
              </span>
            </span>
          </div>
      </ng-template>
    </div>
    <div *ngIf="showTooltips || (password.dirty || password.touched || formSubmitted) && (password.invalid || form?.hasError('userNameMatched') || form?.hasError('consecChars'))" class="motif-margin-bottom-2 motif-margin-top-4">
      <ul class="toolTipColumn bullet-list long-bullet-error">
          <div class="motif-margin-left-5">
            <div>
                <li [ngClass]="(!password.value) ? 'plainText' : (password.errors && (password.errors.oneLowercase || password.errors.oneUppercase || 
                password.errors.oneNumber || password.errors.oneSpecChar) && (password.dirty)) ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid'">
                  <span>{{toolTipObj[0]}}</span>
                </li>
            </div>
          </div>
          <div class="motif-margin-left-5">
            <div>
                <li [ngClass]="(!password.value)  ? 'plainText' : (password.errors && password.errors.minlength ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')" >
                  <span>{{toolTipObj[1]}}</span>
                </li>
            </div>
          </div>
          <div class="motif-margin-left-5">
            <div>
                <li [ngClass]="(!password.value) ? 'plainText' : (password.errors && (password.errors.maxlength) ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')">
                  <span>{{toolTipObj[2]}}</span>
                </li>
            </div>
        </div>
        <div class="motif-margin-left-5">
          <div>
              <li [ngClass]="(!password.value) ? 'plainText' : (password.errors && (password.errors.pwdConsecChar) ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')">
                <span>{{toolTipObj[4]}}</span>
              </li>
          </div>
        </div>
        <div class="motif-margin-left-5">
          <div>
              <li [ngClass]="(!password.value) ? 'plainText' : (password.errors && (password.errors.noSpace) ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')">
                <span>{{toolTipObj[3]}}</span>
              </li>
          </div>
        </div>
        <div class="motif-margin-left-5">
          <div>
              <li [ngClass]="(!password.value) ? 'plainText' : (form.hasError('consecChars') ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')">
                <span>{{toolTipObj[5]}}</span>
              </li>
          </div>
        </div>
        <div class="motif-margin-left-5">
          <div>
              <li [ngClass]="(!password.value) ? 'plainText' : (form.hasError('userNameMatched') ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')">
                <span>{{toolTipObj[6]}}</span>
              </li>
          </div>
        </div>
        <div *ngIf="password.errors && password.errors.pwdInvalidChar" class="motif-margin-left-5">
          <div>
              <li [ngClass]="(!password.value) ? 'plainText' : (password.errors && (password.errors.pwdInvalidChar) ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')">
                <span>{{toolTipObj[7]}}</span>
              </li>
          </div>
        </div>
      </ul>
    </div>
    </div>
  </div>

  <div class="confirm-password-container margin-bottom-40">
    <div class="form-item">
      <label for="confirm-password" [innerHtml]="confirmPwdLabel"></label>
      <div>
        <div class="mask-wrapper">
            <input class="ant-text-input" id="confirm-password" aria-describedby="confirm-password-errors" aria-required="true"
            #input #cnfpwdMask #cnfpwd formControlName="confirmPassword" name="cnfpwd" data-tcp-password-valid-dir type="password" required />
            <span id="cnfpwd-mask" class="align-eye" data-uxd-security-mask-cmp [definition]="{maskedInput: cnfpwdMask, ariaLabelMasked: ('Unmask '+confirmPwdLabel), ariaLabelUnMasked: ('Mask '+confirmPwdLabel)}"></span>
        </div>
      </div>
    </div>
    <div class="error-list pwd-errors-align" id="confirm-password-errors" aria-live="polite">
      <div *ngIf="(confirmPassword.dirty || confirmPassword.touched || formSubmitted) &&
          confirmPassword.invalid &&
          confirmPassword.errors.required; else showOtherErr">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
                <span [innerHtml]="confirmPwdLabel"></span>
                <span [innerHtml]="errorsObj.Required_err"></span>
            </span>
          </span>
      </div>
      <ng-template #showOtherErr>
        <div *ngIf="password.touched && confirmPassword.touched && form.hasError('notMatched')">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span [innerHtml]="pwdLabel"></span> and
              <span [innerHtml]="confirmPwdLabel"></span>
              <span [innerHtml]="errorsObj.Password_Match_err"></span>
            </span>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
