<div class="uxd-theme-motif container"  *ngIf="showSecurityCodePage">
    <div *ngIf="loading" class="row">
        <uxd-spinner class="updating-loader"></uxd-spinner> 
        <div>{{loaders?.PageLoader}}</div>
      </div>
    <div class="reg-top-1 flex-column-center" *ngIf="coderesent">
        <div class="width-100 margin-bottom-48" data-uxd-alert-cmp-v2 [alert]="{ alertType: 'positive' }">
            <span>{{labels?.Resend_Code_Success}}</span>
        </div>
    </div>
    <div id="errorMessageAlerts" class="error width-100 margin-0">
        <inline-alert-container #memberRepRegAlert role="alert" [name]="'member-rep-validate-otp-alert'" [alertType]="alertPosition"></inline-alert-container>
    </div>
    <div *ngIf="!loading" class="max-width-elements">
        <h1 tabindex="-1" class="font-32 text-left margin-bottom-32">
            {{labels?.Page_Heading}}
        </h1>
        <form #validateOTPForm="ngForm" [formGroup]="ValidateOneTimePasscodeForm" class="margin-top-32" (ngSubmit)="validateOtp(ValidateOneTimePasscodeForm)" id="ValidateOneTimePasscodeForm" name="ValidateOneTimePasscodeForm">
            <label class="font-16 font-semi-bold" for="secCode">{{labels?.Security_Code}}</label>
            <input #secCode class="width-100 ant-text-input" id="secCode" formControlName="secCode" name="secCode" aria-label="Enter security code" required aria-describedby="securityCodeError" />

            <ul id="securityCodeError" class="ant-bar-chart-group text-left font-12 margin-top-14 error" *ngIf="ValidateOneTimePasscodeForm.controls['secCode']?.touched && ValidateOneTimePasscodeForm.controls['secCode']?.invalid" aria-live="polite">
                <li *ngIf="ValidateOneTimePasscodeForm.controls['secCode']?.errors.required">
                    <div>
                        <span class="fas fa-times font-14 margin-right-8"></span>
                        <span>{{labels?.Security_Code}}</span>
                        <span>{{errorMessages?.Required_err}}</span>
                    </div>
                </li>
                <li *ngIf="ValidateOneTimePasscodeForm.controls['secCode']?.errors.minlength || ValidateOneTimePasscodeForm.controls['secCode']?.errors.maxlength || ValidateOneTimePasscodeForm.controls['secCode']?.errors.pattern">
                    <div>
                        <span class="fas fa-times font-14 margin-right-8"></span>
                        <span>{{errorMessages?.SecCode_length}}</span>
                    </div>
                </li>
            </ul>
            <ul id="securityCodeError" class="ant-bar-chart-group text-left font-12 margin-top-14" *ngIf="incorrectSecurityCode" aria-live="polite">
                <li>
                    <div>
                        <span class="fas fa-times font-14 margin-right-8"></span>
                        <span>{{errorMessages?.SecCode_err}}</span>
                    </div>
                </li>
            </ul>

            <div class="margin-top-8">
                <p class="security-code-instructions">
                    <span>{{labels?.Instructions[0]}} ***_***_{{textNumber}}.</span>
                    <span>{{labels?.Instructions[1]}}</span>
                </p>
            </div>
            <div class="flex-horizontal-start margin-bottom-40 margin-top-24">
                <span class="margin-right-8" (click)="resendSecurityCode()">
                <a class="ant-action-link" href="javascript:void(0)" attr.data-analytics="''">
                  <span class="font-16 code-link font-semi-bold">{{labels?.Resend_Link_Text}} <i
                      class="fas fa-angle-right"></i></span>
                </a>
                </span>
            </div>

            <div class="form-item flex-horizontal-end">
                <button type="button" class="btn btn-secondary margin-right-16" (click)="previousPage()"
                [attr.data-analytics]="labels?.Analytics?.Back">{{ labels?.Buttons?.Back }}</button>
                <button type="submit" class="btn btn-primary" type="submit" [attr.data-analytics]="labels?.Analytics?.Next">{{labels?.Buttons?.Next}}</button>
            </div>
        </form>
    </div>
</div>