import { Injectable } from '@angular/core';
import {
  Option,
  PcpContent,
  PCPDetail,
  PCPDetailAPIResponse,
  SearchProviderDetail,
  SearchProviderAPIResponse
} from '../interfaces/pcpDetail';
import { PhoneNumPipe } from 'sydmed/libs/common-pipes/src/lib/phone-number/phone-num.pipe';
import {
  ProviderSearchRequestData,
  ReasonCodeOption,
} from '../interfaces/providerSearch';
import { AutomaticTerminationCode } from '../values/pcpConstants';
import { Address, MemberAddresses } from 'gbd-models';
import { SessionStorage } from 'sydmed/libs/storage-service/src/public-api';

/**
 * @description
 * This model class is used to create a formatted result for claims summary widget from the getClaims API request.
 *
 * @function
 * buildPCPDetail(rawPcpDetail: PCPDetailAPIResponse, content: PcpContent): PCPDetail
 * buildProviderList(rawProviderData: SearchProviderAPIResponse[]) : SearchProviderDetail[]
 * buildSearchParams(searchProvider: ProviderSearchRequestData, addressList: Address[]): ProviderSearchRequestData
 * buildReasonCodeList(changeReasonList: ReasonCodeOption[]): Option[]
 * removeHtmlFromString(stringWithHtml: string): string
 * private convertArrayToString(groups: string[]): string
 * private format(value: any): any
 * private formatAddress(address: Address) : string
 * private formatZipCode(zipCode: string): string
 */

@Injectable({
  providedIn: 'root',
})
export class PcpModel {
  private content: PcpContent;
  private na: string;
  providerList: SearchProviderDetail[] = [];
  specialities: string[] = [];

  constructor(private phoneNumPipe: PhoneNumPipe) {}

  /**
   * @description buildPCPDetail() responsible for getting formatted PCP detail
   * @param {PCPDetailAPIResponse} rawPcpDetail
   * @param {PcpContent} content
   * @retruns {PCPDetail}
   */
  buildPCPDetail(
    rawPcpDetail: PCPDetailAPIResponse,
    content: string
  ): PCPDetail {
    const pcpDetail: PCPDetail = {
      address: this.formatAddress(rawPcpDetail.address),
      name: this.format(rawPcpDetail.name),
      phoneNumber:
        this.format(rawPcpDetail.phoneNumber) !== this.na
          ? this.phoneNumPipe.transform(rawPcpDetail.phoneNumber)
          : '',
    };
    pcpDetail.ariaAddress = this.removeHtmlFromString(
      content + ' ' + this.formatAddress(rawPcpDetail.address)
    );
    return pcpDetail;
  }

  /**
   * @description buildProviderList() responsible for getting formatted search provider details
   * @param {SearchProviderAPIResponse[]} rawProviderData
   * @retruns {SearchProviderDetail[]}
   */
  buildProviderList(
    rawProviderData: SearchProviderAPIResponse[],
    content: PcpContent
  ): SearchProviderDetail[] {
    this.providerList = [];

    for (let i = 0; i < rawProviderData.length; i++) {
      const provider: SearchProviderDetail = {
        address: this.formatAddress(rawProviderData[i].provider.address),
        distance: this.format(rawProviderData[i].distance),
        isCurrentPCP: rawProviderData[i].currentPCP,
        isEligible: rawProviderData[i].eligible,
        phoneNumber:
          this.format(rawProviderData[i].provider.phoneNumber) !== this.na
            ? this.phoneNumPipe.transform(
                rawProviderData[i].provider.phoneNumber
              )
            : '',
        providerId: rawProviderData[i].provider.providerId,
        groupsName: this.convertArrayToString(
          rawProviderData[i].provider.groups
        ),
        providerName: rawProviderData[i].provider.name,
        specialties: this.convertArrayToString(
          rawProviderData[i].provider.specialties
        ),
      };

      provider.ariaAddress = this.removeHtmlFromString(
        content.addressAriaPrefix + ' ' + provider.address
      );
      this.specialities = [
        ...this.specialities,
        ...rawProviderData[i].provider.specialties,
      ];
      this.providerList.push(provider);
    }
    return this.providerList;
  }

  /**
   * @description buildReasonCodeList() used to create key/vaue pair to display reasons
   * @param {ReasonCodeOption[]} changeReasonList
   * @retruns {Option[]}
   */
  buildReasonCodeList(changeReasonList: ReasonCodeOption[]): Option[] {
    const reasonList = [];

    if (changeReasonList) {
      for (let i = 0; i < changeReasonList.length; i++) {
        if (changeReasonList[i].code !== AutomaticTerminationCode) {
          reasonList.push({
            label: changeReasonList[i].description,
            value: changeReasonList[i].code,
          });
        }
      }
    }
    return reasonList;
  }

  /**
   * @description buildSearchParams() used to fetch first 5 digits of zip code and mailing address
   * @param {Address[]} addressList
   * @retruns {string}
   */
  buildSearchParams(
    searchProvider: ProviderSearchRequestData,
    memberAddresses: MemberAddresses
  ): ProviderSearchRequestData {
    searchProvider.zipCode = memberAddresses?.home?.zipCode;
    searchProvider.mailingAddress = memberAddresses?.mailing ? this.formatAddress(memberAddresses?.mailing) : '';
  
    return searchProvider;
  }

  /**
   * @description removeHtmlFromString() used to getting address in expected format
   * @param {string} stringWithHtml
   * @retruns string
   */
  removeHtmlFromString(stringWithHtml: string): string {
    const textElement: HTMLElement = document.createElement('div');
    textElement.innerHTML = stringWithHtml;
    return textElement.textContent || textElement.innerText || '';
  }

  /**
   * @description convertArraToString() concatenate all array strings to single string
   * @param {string[]} groups
   * @retruns {string}
   */
  private convertArrayToString(groups: string[]): string {
    return groups?.join(', ');
  }

  /**
   * @description format() used to check if a value is null, undefined or blank
   * @param {any} value
   * @retruns {any}
   */
  private format(value: any): any {
    if (!value) {
      value = this.na;
    }
    return value;
  }

  /**
   * @description formatAddress() used to getting address in expected format
   * @param {string} address
   * @retruns string
   */
  private formatAddress(address: Address): string {
    
    
    let streetAddress1 = address.streetAddress1;
    let streetAddress2 = address.streetAddress2;

    return (
      streetAddress1 +
      (streetAddress2?.trim() ? ', ' + streetAddress2 : '') +
      ',<br />' +
      address.city +
      ', ' +
      address.state +
      ' ' +
      this.formatZipCode(address.zipCode)
    );
  }

  /**
   * @description formatZipCode() used to fetch first 5 digits of the zipcode
   * @param {string} zipCode
   * @retruns string
   */
  private formatZipCode(zipCode: string): string {
    if (zipCode) {
      if (zipCode.length === 9) {
        zipCode = zipCode.substring(0, 5);
      }
    }
    return zipCode;
  }
}
