import { Injectable } from "@angular/core";
import { timeout } from "rxjs/operators";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { UrlProperties } from "sydmed/libs/url-properties/src/lib/url-properties.class";
import { UpdateIntervention } from "./intervention";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Locale, LocaleService } from "sydmed/src/app/sydmed-shared/locale-service/locale.service";

@Injectable({
  providedIn: "root",
})
export class DasboardService {
  constructor(
    private http: HttpClientService,
    private httpClient: HttpClient,
    private localeService: LocaleService
  ) {}

  headers = { 'meta-senderapp': 'gbdmbr' };

  getMessageCount() {
    return this.http.getObservable(
      UrlProperties.UrlProperties.endPoints.landingPage.messages
    );
  }

  getalertsData() {
    return this.http
      .getObservable(UrlProperties.UrlProperties.endPoints.landingPage.alerts)
      .pipe(timeout(30000))
      .toPromise();
  }

  getIntervention(interventionId: string) {
    const locale = this.localeService.getLocaleOverride();
    this.headers['x-madt-language'] = locale !== Locale.ES_US ? Locale.EN_US : locale;

    let url = UrlProperties.UrlProperties.endPoints.landingPage.getIntervention;
    interventionId = decodeURIComponent(interventionId);
    const params = new HttpParams().set("id", interventionId);
    return this.httpClient.get(url, { params, headers: this.headers }).toPromise();
  }

  updateIntervention(requestBody: UpdateIntervention) {
    return this.http
      .postObservable(
        UrlProperties.UrlProperties.endPoints.landingPage.updateIntervention,
        requestBody
      )
      .toPromise();
  }
}
