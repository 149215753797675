import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IdcardsContainerComponent } from './components/idCardsContainer.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
	{ path: 'secure/id-card', component: IdcardsContainerComponent , pathMatch: 'full', canActivate: [AuthGuardService] } 
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class IdCardRoutingModule { }