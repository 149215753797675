
export const enum Locale {
    EN_US = 'en-US',
    ES_US = 'es-US'
}

export const LOCALE_KEY = 'localeOverride';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    constructor() {}

    public getLocaleOverride(): string {
        return sessionStorage.getItem(LOCALE_KEY) ?? '';
    }

    public setLocaleOverride(locale: string) {
        sessionStorage.setItem(LOCALE_KEY, locale);
    }

    public removeLocaleOverride() {
        sessionStorage.removeItem(LOCALE_KEY);
    }

    public setLocaleOverrideFromParameter() {
        const languageOverride: string = this.languageFromParameter;
    
        if (languageOverride) {
          sessionStorage.setItem("localeOverride", languageOverride);
        }
      }
    
    get languageFromParameter(): string {
        const searchParams = new URLSearchParams(window?.location?.search);
    
        if (searchParams?.has("language")) {
          return searchParams.get("language");
        }
    } 

}
