import { AccordianModule } from '@anthem/uxd/accordion';
import { AccountBalanceSummaryComponent } from '@anthem/mbrportal-pharmacy/components/accountBalanceSummaryCmp';
import { ActionItemsComponent } from '@anthem/mbrportal-pharmacy/components/actionItemsCmp';
import { ActionItemsModel } from '@anthem/mbrportal-pharmacy/models/actionItemsModel';
import { AHDCartComponent } from './ahd-cart/ahd-cart.component';
import { AHDContentHelper } from '../../sydmed-shared/ahd-content-helper/ahd-content-helper';
import { AhdDownloadComponent } from './ahd-download/ahd-download.component';
import { AHDGeocodeApi } from './services/ahd-geo-code-api.service';
import { AhdIdCardMessagesComponent } from './ahd-id-card-messages/ahd-id-card-messages.component';
import { AhdLandingPageOrdersComponent } from './ahd-landing-page-orders/ahd-landing-page-orders.component';
import { AhdLeftRailNavComponent } from './ahd-left-rail-nav/ahd-left-rail-nav.component';
import { AhdMyOrdersPageComponent } from './ahd-my-orders-page/ahd-my-orders-page.component';
import { AhdMyOrdersPageComponentContainer } from './ahd-my-orders-page-container/ahd-my-orders-page-container.component';
import { AhdPatientPreferenceService } from '@anthem/mbrportal-pharmacy/services/ahdPatientPreferenceSvc';
import { AhdPharmacyProfileComponent } from './ahd-pharmacy-profile/ahd-pharmacy-profile.component';
import { AhdPharmacyRoutingModule } from './ahd-pharmacy-routing.module';
import { ahdPharmacyService } from './services/ahd-pharmacy.service';
import { AhdPrescriptionBenefitOrdersComponent } from './ahd-prescription-benefit-orders/ahd-prescription-benefit-orders.component';
import { AHDPrescriptionDataService } from './services/ahd-prescription-data.service';
import { AHDPrescriptionHomeComponent } from './ahd-prescription-home/ahd-prescription-home.component';
import { AhdPrescriptionHomeComponentContainer } from './ahd-prescription-home-container/ahd-prescription-home-container.component';
import { AhdPrescriptionService } from '@anthem/mbrportal-pharmacy/services/ahdPrescriptionSvc';
import { AhdPrintPageModel } from '@anthem/mbrportal-pharmacy/models/ahdPrintPageModel';
import { AHDReviewOrderComponent } from './ahd-review-order/ahd-review-order.component';
import { AhdReviewOrderLandingComponent } from './ahd-review-order-landing/ahd-review-order-landing.component';
import { AhdReviewProcessingComponent } from './ahd-review-processing/ahd-review-processing.component';;
import { AhdTherapeuticEquivalentService } from '@anthem/mbrportal-pharmacy/services/ahdTherapeuticEquivalentSvc';
import { AhdTrackOrderDetailsComponent } from './ahd-track-order-details/ahd-track-order-details.component';
import { AhdTrackOrderDetailsLandingComponent } from './ahd-track-order-details-landing/ahd-track-order-details-landing.component';
import { AlertModule } from '@anthem/uxd/alert';
import { AmountFrequencyCmp } from '@anthem/mbrportal-pharmacy/components/amountFrequencyCmp';
import { AutopayCancelConfirmationComponent } from '@anthem/mbrportal-pharmacy/components/setupCancelAutoPayConfirmationCmp';
import { AutopayConfirmationComponent } from '@anthem/mbrportal-pharmacy/components/setupAutopayConfirmationCmp';
import { BannerComponentV2 } from '@anthem/mbrportal-pharmacy/components/bannerCmpV2';
import { BillingPaymentComponent } from '@anthem/mbrportal-pharmacy/components/billingPaymentCmp';
import { BillingPaymentModel } from '@anthem/mbrportal-pharmacy/models/billingPaymentModel';
import { BillPharmacyPaymentMethodsComponent } from '@anthem/mbrportal-pharmacy/components/billPharmacyPaymentMethodsCmp';
import { BrowserModule } from "@angular/platform-browser";
import { ButtonModule } from '@anthem/uxd/button';
import { CartComponent } from '@anthem/mbrportal-pharmacy/components/cartCmp';
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { CheckOrderStatusComponent } from '@anthem/mbrportal-pharmacy/components/checkOrderStatusCmp';
import { CheckoutEmailCmp } from '@anthem/mbrportal-pharmacy/components/checkoutEmailCmp';
import { ClinicalQuestionnaireAPI } from '@anthem/mbrportal-pharmacy/services/clinicalQuestionnaireSvc';
import { CommonModule } from '@angular/common';
import { CommonModule2 } from '@anthem/mbrportal/common';
import { CommunicationPreferencesSvc } from '@anthem/mbrportal-profile/services/communicationPreferencesSvc';
import { ContentHelper, CoreModule } from '@anthem/mbrportal/core';
import { CouponUploadRequestInterceptor } from '@anthem/mbrportal-pharmacy/services/uploadCouponsRequestInterceptor';
import { CpccBadgesComponent } from '@anthem/mbrportal-pharmacy/components/cpccBadgesCmp';
import { CurrentPrescriptionsOrderComponent } from '@anthem/mbrportal-pharmacy/components/currentPrescriptionsOrderCmp';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CvsPaymentService } from '@anthem/mbrportal-pharmacy/services/cvsPaymentSvc';
import { CvsPharmacyService } from '@anthem/mbrportal-pharmacy/services/cvsPharmacySvc';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DeliveryCostService } from '@anthem/mbrportal-pharmacy/services/deliveryCostSvc';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { DrugInteractionCheckerComponent } from '@anthem/mbrportal-pharmacy/components/drugInteractionCheckerCmp';
import { DrugService } from '@anthem/mbrportal-pharmacy/services/ahdDrugSvc';
import { FormGroupModule } from '@anthem/uxd/form-group';
import { FormsModule } from '@angular/forms';
import { GeocodeApi, SharedModule } from '@anthem/mbrportal/shared';
import { HomeDeliveryPrescriptionComponent } from '@anthem/mbrportal-pharmacy/components/homeDeliveryPrescriptionCmp';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { InnetworkSearchComponent } from '@anthem/mbrportal-pharmacy/components/innetworkSearchCmp';
import { InnetworkSearchModel } from '@anthem/mbrportal-pharmacy/models/innetworkSearchModel';
import { LinkViewBodyComponent } from '@anthem/mbrportal-pharmacy/components/linkViewBodyCmp';
import { LinkViewComponent } from '@anthem/mbrportal-pharmacy/components/linkViewComponent';
import { MailOrderConfirmationComponentV2 } from '@anthem/mbrportal-pharmacy/components/mailOrderConfirmationCmpV2';
import { MailOrderReviewComponentV2 } from '@anthem/mbrportal-pharmacy/components/mailOrderReviewCmpV2';
import { ManageAutoCancelComponent } from '@anthem/mbrportal-pharmacy/components/manageAutoCancelCmp';
import { ManagePrescriptionsComponentV2 } from '@anthem/mbrportal-pharmacy/components/managePrescriptionsCmpV2';
import { ManagePrescriptionsModel } from '@anthem/mbrportal-pharmacy/models/managePrescriptionsModel';
import { MedicalProfileComponent } from '@anthem/mbrportal-pharmacy/components/medicalProfileCmp';
import { MedicalProfileComponentV2 } from '@anthem/mbrportal-pharmacy/components/medicalProfileCmpV2';
import { MedicalProfilesModel } from '@anthem/mbrportal-pharmacy/models/medicalProfileModel';
import { ModalModule } from '@anthem/uxd/modal';
import { NavigationModule, SidebarRailModule } from '@anthem/uxd/navigation';
import { NewPrescriptionOrderReviewComponent } from '@anthem/mbrportal-pharmacy/components/newPrescriptionOrderReviewCmp';
import { OnBoardingCarouselComponent } from '@anthem/mbrportal-pharmacy/components/onBoardingCarouselCmp';
import { OpportunityDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/rxOpportunityDataSvcV2';
import { OrderDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/orderDataSvcV2';
import { OrderDeliveryAddressComponent } from '@anthem/mbrportal-pharmacy/components/orderDeliveryAddressCmp';
import { OrderDeliveryAddressModel } from '@anthem/mbrportal-pharmacy/models/orderDeliveryAddressModel';
import { OrderModel } from '@anthem/mbrportal-pharmacy/models/orderModel';
import { OrderPrescriptionDetailsComponent } from '@anthem/mbrportal-pharmacy/components/orderPrescriptionDetailsCmp';
import { OrderPrescriptionDetailsModel } from '@anthem/mbrportal-pharmacy/models/orderPrescriptionDetailsModel';
import { OrderPrescriptionService } from '@anthem/mbrportal-pharmacy/services/orderPrescriptionService';
import { OrdersListModel } from '@anthem/mbrportal-pharmacy/models/ordersListModel';
import { OrdersSortModel } from '@anthem/mbrportal-pharmacy/models/ordersSortModel';
import { PaginatorModule } from '@anthem/uxd/paginator';
import { PartnerLoginComponent } from '@anthem/mbrportal-pharmacy/components/partnerLoginCmp';
import { PayAccBalanceModel } from '@anthem/mbrportal-pharmacy/models/payAccBalanceModel';
import { PayPharmacyAccountBalanceComponent } from '@anthem/mbrportal-pharmacy/components/payPharmacyAccBalanceCmp';
import { PayPharmacyAccountBalanceConfirmationComponent } from '@anthem/mbrportal-pharmacy/components/payPharmacyAccBalanceConfirmationcmp';
import { PaySpecialtyAccountBalanceCmp } from '@anthem/mbrportal-pharmacy/components/paySpecialtyAccountBalanceCmp';
import { PaySpecialytAccountBalConfirmationCmp } from '@anthem/mbrportal-pharmacy/components/paySpecialytAccountBalConfirmationCmp';
import { PharamacySavingsBannerComponent } from '@anthem/mbrportal-pharmacy/components/pharamacySavingsBannerCmp';
import { PharamacySavingsComponent } from '@anthem/mbrportal-pharmacy/components/pharamacySavingsCmp';
import { PharamacySavingsDetailComponent } from '@anthem/mbrportal-pharmacy/components/pharamacySavingsDetailCmp';
import { PharamacySavingsModel } from '@anthem/mbrportal-pharmacy/models/pharamacySavingsModel';
import { PharmacyCarveoutModule } from '@anthem/mbrportal-pharmacy-carveout';
import { PharmacyHomeDeliveryContainerComponent } from '@anthem/mbrportal-pharmacy/components/pharmacyHomeDeliveryContainerCmp';
import { PharmacyLandingPageComponent } from "./ahd-pharmacy-landing/ahd-pharmacy-landing.component";
import { PharmacyNavigationUtility } from '@anthem/mbrportal-pharmacy/services/pharmacyNavigationUtility';
import { PharmacyPaymentHistoryComponent } from '@anthem/mbrportal-pharmacy/components/pharmacyPaymentHistoryCmp';
import { PharmacyPaymentHistoryModel } from '@anthem/mbrportal-pharmacy/models/pharmacyPaymentHistoryModel';
import { PharmacyProfileAddressSvc, ProfileModule } from '@anthem/mbrportal-profile';
import { PharmacySearchLinkComponent } from '@anthem/mbrportal-pharmacy/components/pharmacySearchLinkCmp';
import { PharmacySpecialtyDeliveryContainerComponent } from '@anthem/mbrportal-pharmacy/components/pharmacySpecialtyDeliveryContainerCmp';
import { PharmacySubnavMenuComponent } from '@anthem/mbrportal-pharmacy/components/pharmacySubnavMenuCmp';
import { PharmacySvc } from '@anthem/mbrportal-pharmacy/services/pharmacySvc';
import { PharmacyTabbedViewComponent } from '@anthem/mbrportal-pharmacy/components/pharmacyTabbedViewCmp';
import { PharmacyTabbedViewModel } from '@anthem/mbrportal-pharmacy/models/pharmacyTabbedViewModel';
import { PharmacyTabbedViewService } from '@anthem/mbrportal-pharmacy/services/pharmacyTabbedViewSvc';
import { PlaceOrderModelV2 } from '@anthem/mbrportal-pharmacy/models/placeOrderModelV2';
import { PrescriberModel, PrescriberSearchModel } from '@anthem/mbrportal-pharmacy/models/prescriberSearchModel';
import { PrescriberSearchComponent } from '@anthem/mbrportal-pharmacy/components/prescriberSearchCmp';
import { PrescriptionAuthInfoComponent } from '@anthem/mbrportal-pharmacy/components/prescriptionAuthInfoCmp';
import { PrescriptionBannerComponent } from '@anthem/mbrportal-pharmacy/components/prescriptionBannerCmp';
import { PrescriptionBannerModelV2 } from '@anthem/mbrportal-pharmacy/models/prescriptionBannerModelV2';
import { PrescriptionBenefitOrdersComponent } from '@anthem/mbrportal-pharmacy/components/prescriptionBenefitOrdersCmp';
import { PrescriptionDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/prescriptionDataSvcv2';
import { PrescriptionDateRangeComponent } from '@anthem/mbrportal-pharmacy/components/ddlPrescriptionDateRangeCmp';
import { PrescriptionDiscountComponent } from '@anthem/mbrportal-pharmacy/components/prescriptionDiscountCmp';
import { PrescriptionFilterComponentV2 } from '@anthem/mbrportal-pharmacy/components/prescriptionFilterCmpV2';
import { PrescriptionHomeComponentV2 } from '@anthem/mbrportal-pharmacy/components/prescriptionHomeCmpV2';
import { PrescriptionListComponentV2 } from '@anthem/mbrportal-pharmacy/components/prescriptionListCmpV2';
import { PrescriptionListModel } from '@anthem/mbrportal-pharmacy/models/prescriptionListModel';
import { PrescriptionListModelV2 } from '@anthem/mbrportal-pharmacy/models/prescriptionListModelV2';
import { PrescriptionModel } from '@anthem/mbrportal-pharmacy/models/prescriptionModel';
import { PrescriptionModelV2 } from '@anthem/mbrportal-pharmacy/models/prescriptionModelV2';
import { PrescriptionOrderConfirmationV2 } from '@anthem/mbrportal-pharmacy/components/prescriptionOrderConfirmationCmpV2';
import { PrescriptionPipe } from '@anthem/mbrportal-pharmacy/pipes/prescriptionPipe';
import { PrescriptionReviewComponentV2 } from '@anthem/mbrportal-pharmacy/components/prescriptionReviewCmpV2';
import { PrescriptionReviewRowViewComponent } from '@anthem/mbrportal-pharmacy/components/prescriptionReviewRowViewCmp';
import { PrescriptionSearchComponentV2 } from '@anthem/mbrportal-pharmacy/components/prescriptionSearchCmpV2';
import { PrescriptionSortModelV2 } from '@anthem/mbrportal-pharmacy/models/prescriptionSortModelV2';
import { PrescriptionSubscriptionService } from '@anthem/mbrportal-pharmacy/services/prescriptionSubscriptionSvc';
import { PrescriptionWidgetConfig } from '@anthem/mbrportal-pharmacy/models/prescriptionWidgetConfig';
import { PriceAMedicationDetailsComponentV2 } from '@anthem/mbrportal-pharmacy/components/priceMedicationDetailsCmpV2';
import { PriceMedCostDetailsCmp } from '@anthem/mbrportal-pharmacy/components/priceMedCostDetailsCmp';
import { PriceMedicationDetailsModelV2 } from '@anthem/mbrportal-pharmacy/models/priceMedicationDetailsModelV2';
import { PriceMedicationModelV2 } from '@anthem/mbrportal-pharmacy/models/priceMedicationModelV2';
import { PriceMedMailServiceDetailComponentV2 } from '@anthem/mbrportal-pharmacy/components/priceMedMailServiceDetailCmpV2';
import { PriceMedSearchComponentV2 } from '@anthem/mbrportal-pharmacy/components/priceMedSearchCmpV2';
import { PriceMedStorePickupDetailComponentV2 } from '@anthem/mbrportal-pharmacy/components/priceMedStorePickupDetailCmpV2';
import { PriceMedSuggestComponent } from '@anthem/mbrportal-pharmacy/components/priceMedSuggestCmpV2';
import { PriceMedTherapeuticAltrntvesComponentV2 } from '@anthem/mbrportal-pharmacy/components/priceMedTherapeuticAltrntvesCmpV2';
import { PriorAuthAlertsComponent } from '@anthem/mbrportal-pharmacy/components/priorAuthAlertsCmp';
import { ProccessOrderModel } from '@anthem/mbrportal-pharmacy/models/processOrderModel';
import { ProcessOrderComponent } from '@anthem/mbrportal-pharmacy/components/processOrderCmp';
import { ProfileAddressValidationService } from '@anthem/mbrportal-pharmacy/services/profileAddressValidationSvc';
import { ProfilePayMethodsComponent } from '@anthem/mbrportal-pharmacy/components/profilePayMethodsCmp';
import { QuestionnaireModelV2 } from '@anthem/mbrportal-pharmacy/models/questionnaireModelV2';
import { RadioFilterComponent } from '@anthem/mbrportal-pharmacy/components/radioFilterCmp';
import { RadioModule } from '@anthem/uxd/radio';
import { ReimbursementsModule } from '@anthem/mbrportal-reimbursements';
import { ReviewOrderComponent } from '@anthem/mbrportal-pharmacy/components/reviewOrderCmp';
import { RxCarveoutComponentV2 } from '@anthem/mbrportal-pharmacy/components/rxCarveoutCmpV2';
import { RxCreditCardExpiryDateFormatDirective } from '@anthem/mbrportal-pharmacy/formatters/creditCardExpDateFormatterDir';
import { RxCreditCardExpiryDateValidatorDirective } from '@anthem/mbrportal-pharmacy/validators/creditCardExpDateValidatorDir';
import { RXCreditCardValidatorDirective } from '@anthem/mbrportal-pharmacy/validators/rxCreditCardValidatorDir';
import { RxPaymentMethodsComponent } from '@anthem/mbrportal-pharmacy/components/payMethodsCmp';
import { RxPayMethodsModel } from '@anthem/mbrportal-pharmacy/models/payMethodsModel';
import { RxProfileShippingAddressComponent } from '@anthem/mbrportal-pharmacy/components/profileShippingAddressCmp';
import { RxShippingAddressComponent } from '@anthem/mbrportal-pharmacy/components/shippingAddressCmp';
import { RxShippingAddressModel } from '@anthem/mbrportal-pharmacy/models/shippingAddressModel';
import { SavingsOpportunityComponent } from '@anthem/mbrportal-pharmacy/components/savingsOpportunityCmp';
import { SavingsOpportunityDetails } from '@anthem/mbrportal-pharmacy/components/savingsOpportunityDetailsCmp';
import { SavingsOpportunityModel } from '@anthem/mbrportal-pharmacy/models/savingsOpportunityModel';
import { SearchProviderModel } from '@anthem/mbrportal-search-provider/models/searchProviderModel';
import { SearchProviderSvc } from '@anthem/mbrportal-search-provider/services/searchProviderSvc';
import { SetupAutopayComponent } from '@anthem/mbrportal-pharmacy/components/setupAutopayCmp';
import { SetupAutoPayModel } from '@anthem/mbrportal-pharmacy/models/setupAutoPayModel';
import { SpecialtyCostReliefBannerComponent } from '@anthem/mbrportal-pharmacy/components/specialtyCostReliefBannerCmp';
import { SpecialtyCostReliefDrugListComponent } from '@anthem/mbrportal-pharmacy/components/specialtyCostReliefDrugListCmp';
import { SpecialtyCostReliefService } from '@anthem/mbrportal-pharmacy/services/specialtyCostReliefSvc';
import { SpecialtyFirstFillComponent } from '@anthem/mbrportal-pharmacy/components/specialtyFirstFillCmp';
import { SpecialtyPharmacyComponent } from '@anthem/mbrportal-pharmacy/components/specialtyPharmacyCmp';
import { SpecialtyPharmacyModel } from '@anthem/mbrportal-pharmacy/models/specialtyPharmacyModel';
import { SpecialtyQuestionnaireComponentV2 } from '@anthem/mbrportal-pharmacy/components/specialtyQuestionnaireCmpV2';
import { SplitPaymentValidatorDirective } from '@anthem/mbrportal-pharmacy/validators/splitPaymentValidatorDir';
import { SsoModule } from '@anthem/mbrportal/sso';
import { StorePickUpDateTimeModel } from '@anthem/mbrportal-pharmacy/models/storePickUpDateTimeModel';
import { SwitchNinetyNewPrescriptionComponent } from '@anthem/mbrportal-pharmacy/components/switchNinetyNewPrescriptionCmp';
import { SwitchNinetyNewPrescriptionModel } from '@anthem/mbrportal-pharmacy/models/switchNinetyNewPrescriptionModel';
import { TabsModule, TabsModuleV2 } from '@anthem/uxd/tabs';
import { TermsOfUseComponent } from '@anthem/mbrportal-pharmacy/components/termsOfUseCmp';
import { TestPageComponent } from '@anthem/mbrportal-pharmacy/components/testPageCmp';
import { TherapeuticOrderComponent } from '@anthem/mbrportal-pharmacy/components/therapeuticOrderCmp';
import { TherapeuticOrderListComponent } from '@anthem/mbrportal-pharmacy/components/therapeuticOrderListCmp';
import { TherapeuticOrderModel } from '@anthem/mbrportal-pharmacy/models/therapeuticOrderModel';
import { ToggleSwitchModule } from '@anthem/uxd/toggle-switch';
import { TooltipModule } from '@anthem/uxd/tooltip';
import { TouModel } from '@anthem/mbrportal-pharmacy/models/touModel';
import { TwoDigitDecimalFormatDirective } from '@anthem/mbrportal-pharmacy/formatters/twoDigitDecimalFormatterDir';
import { UtilityModule } from '@anthem/mbrportal/utility';
import { UxDeprecatedModule } from '@anthem/uxd/deprecated';
import { ValidationModelV2 } from '@anthem/mbrportal-pharmacy/models/validationModelV2';
import { VendorAddMorePrescriptionsComponent } from '@anthem/mbrportal-pharmacy/components/vendorAddMorePrescriptionsCmp';
import { VendorAHDReviewRefillComponent } from '@anthem/mbrportal-pharmacy/components/vendorAHDReviewRefillCmp';
import { VendorCouponComponent } from '@anthem/mbrportal-pharmacy/components/vendorCouponComponent';
import { VendorCouponModel } from '@anthem/mbrportal-pharmacy/models/vendorCouponModel';
import { VendorCouponService } from '@anthem/mbrportal-pharmacy/services/vendorCouponSvc';
import { VendorCurrentOrdersComponent } from '@anthem/mbrportal-pharmacy/components/vendorCurrentOrdersCmp';
import { VendorCurrentOrdersModel } from '@anthem/mbrportal-pharmacy/models/vendorCurrentOrdersModel';
import { VendorCurrentOrderStatusComponent } from '@anthem/mbrportal-pharmacy/components/vendorCurrentOrderStatusCmp';
import { VendorCurrentOrderStatusModel } from '@anthem/mbrportal-pharmacy/models/vendorCurrentOrderStatusModel';
import { VendorIntermediateScreenCmp } from '@anthem/mbrportal-pharmacy/components/vendorIntermediateScreenCmp';
import { VendorManageAutoComponent } from '@anthem/mbrportal-pharmacy/components/vendorManageAutoCmp';
import { VendorManageAutoEnrollmentComponent } from '@anthem/mbrportal-pharmacy/components/vendorManageAutoEnrollmentCmp';
import { VendorManageAutoPartialErrorComponent } from '@anthem/mbrportal-pharmacy/components/vendorManageAutoPartialErrorCmp';
import { VendorManageAutoRefillModel } from '@anthem/mbrportal-pharmacy/models/vendorManageAutoRefillModel';
import { VendorManageAutoRefillService } from '@anthem/mbrportal-pharmacy/services/vendorManageAutoRefillSvc';
import { VendorManageAutoReviewComponent } from '@anthem/mbrportal-pharmacy/components/vendorManageAutoReviewCmp';
import { VendorNavigationComponent } from '@anthem/mbrportal-pharmacy/components/vendorNavigationCmp';
import { VendorOpportunityDataService } from '@anthem/mbrportal-pharmacy/services/vendorOpportunityDataService';
import { VendorOrderDetailsInvoiceComponent } from '@anthem/mbrportal-pharmacy/components/vendorOrderDetailsInvoiceCmp';
import { VendorOrderDetailsPostpayComponent } from '@anthem/mbrportal-pharmacy/components/vendorOrderDetailsPostpayCmp';
import { VendorOrderDetailsPrepayComponent } from '@anthem/mbrportal-pharmacy/components/vendorOrderDetailsPrepayCmp';
import { VendorOrderProgressComponent } from '@anthem/mbrportal-pharmacy/components/vendorOrderStatusProgressCmp';
import { VendorPageHeaderComponent } from '@anthem/mbrportal-pharmacy/components/vendorPageHeaderCmp';
import { VendorPaymentHistoryComponent } from '@anthem/mbrportal-pharmacy/components/vendorPaymentHistoryCmp';
import { VendorPaymentModel } from '@anthem/mbrportal-pharmacy/models/vendorPaymentModel';
import { VendorPaymentWalletComponent } from '@anthem/mbrportal-pharmacy/components/vendorPaymentWalletCmp';
import { VendorPaymentWalletService } from '@anthem/mbrportal-pharmacy/services/vendorPaymentWalletSvc';
import { VendorPaymentWalletSummaryComponent } from '@anthem/mbrportal-pharmacy/components/vendorPaymentWalletSummaryCmp';
import { VendorPharmacyCardComponent } from '@anthem/mbrportal-pharmacy/components/vendorPharmacyCardComponent';
import { VendorPharmacyProfileComponent } from '@anthem/mbrportal-pharmacy/components/vendorPharmacyProfileCmp';
import { VendorPrescriberSummaryComponent } from '@anthem/mbrportal-pharmacy/components/vendorPrescriberSummaryCmp';
import { VendorPrescriptionListAccordionComponent } from '@anthem/mbrportal-pharmacy/components/vendorPrescriptionListAccordionCmp';
import { VendorPrescriptionListComponent } from '@anthem/mbrportal-pharmacy/components/vendorPrescriptionListCmp';
import { VendorPrescriptionReviewComponent } from '@anthem/mbrportal-pharmacy/components/vendorPrescriptionReviewCmp';
import { VendorPrescriptionReviewModel } from '@anthem/mbrportal-pharmacy/models/vendorPrescriptionReviewModel';
import { VendorPreviouslyFilledPrescriptionsComponent } from '@anthem/mbrportal-pharmacy/components/vendorPreviouslyFilledPrescriptionsCmp';
import { VendorPreviouslyFilledPrescriptionsModel } from '@anthem/mbrportal-pharmacy/models/vendorPreviouslyFilledPrescriptionsModel';
import { VendorPriceMedicationComponent } from '@anthem/mbrportal-pharmacy/components/vendorPriceMedicationCmp';
import { VendorPriceMedicationDrugPriceService } from '@anthem/mbrportal-pharmacy/services/vendorPriceMedicationDrugPriceService';
import { VendorPriceMedicationMailDrugComponent } from '@anthem/mbrportal-pharmacy/components/vendorPriceMedicationMailDrugCmp';
import { VendorPriceMedicationMailDrugModel } from '@anthem/mbrportal-pharmacy/models/vendorPriceMedicationMailDrugModel';
import { VendorPriceMedicationModel } from '@anthem/mbrportal-pharmacy/models/vendorPriceMedicationModel';
import { VendorPriceMedicationSearchDrugComponent } from '@anthem/mbrportal-pharmacy/components/vendorPriceMedicationSearchDrugCmp';
import { VendorPriceMedicationSearchModel } from '@anthem/mbrportal-pharmacy/models/vendorPriceMedicationSearchModel';
import { VendorPriceMedicationService } from '@anthem/mbrportal-pharmacy/services/vendorPriceMedicationSvc';
import { VendorRenewRefillComponent } from '@anthem/mbrportal-pharmacy/components/vendorRenewRefillCmp';
import { VendorRenewRefillModel } from '@anthem/mbrportal-pharmacy/models/vendorRenewRefillModel';
import { VendorRequestMedicationComponent } from '@anthem/mbrportal-pharmacy/components/vendorRequestMedicationCmp';
// removing this to test if bamboo build will be successful
// import { VendorRequestMedicationConfirmationComponent } from '@anthem/mbrportal-pharmacy/components/VendorRequestMedicationConfirmationCmp';
import { VendorRetailStoreComponent } from '@anthem/mbrportal-pharmacy/components/vendorRetailStoreCmp';
import { VendorRetailStoreModel } from '@anthem/mbrportal-pharmacy/models/vendorRetailStoreModel';
import { VendorReviewOrderModel } from '@anthem/mbrportal-pharmacy/models/vendorReviewOrderModel';
import { VendorReviewOrderPaymentComponent } from '@anthem/mbrportal-pharmacy/components/vendorReviewOrderPaymentCmp';
import { VendorReviewOrderReceiptComponent } from '@anthem/mbrportal-pharmacy/components/vendorReviewOrderReceiptCmp';
import { VendorReviewTransferComponent } from '@anthem/mbrportal-pharmacy/components/vendorReviewTransferCmp';
import { VendorReviewTransferContainerComponent } from '@anthem/mbrportal-pharmacy/components/vendorReviewTransferContainerCmp';
import { VendorReviewTransferDetailsComponent } from '@anthem/mbrportal-pharmacy/components/vendorReviewTransferDetailsCmp';
import { VendorReviewTransferModel } from '@anthem/mbrportal-pharmacy/models/vendorReviewTransferModel';
import { VendorReviewTransferPartialFailureComponent } from '@anthem/mbrportal-pharmacy/components/vendorReviewTransferPartialFailureCmp';
import { VendorSearchPrescriberComponent } from '@anthem/mbrportal-pharmacy/components/vendorSearchPrescriberCmp';
import { VendorSpecialityPrescriptionDateComponent } from '@anthem/mbrportal-pharmacy/components/vendorSpecialityPrescriptionDateCmp';
import { VendorTherapeuticAlternativeDrugComponent } from '@anthem/mbrportal-pharmacy/components/vendorTherapeuticAlternativeDrugCmp';
import { VendorTherapeuticAlternativeDrugModel } from '@anthem/mbrportal-pharmacy/models/vendorTherapeuticAlternativeDrugModel';
import { VendorTrackShipmentSliderComponent } from '@anthem/mbrportal-pharmacy/components/vendorTrackShipmentSliderCmp';
import { VendorTransferMultipleRxComponent } from '@anthem/mbrportal-pharmacy/components/vendorTransferMultipleRxCmp';
import { VendorTransferPrescriptionsService } from '@anthem/mbrportal-pharmacy/services/vendorTransferPrescriptionsService';
import { ViewSpecialtyAccountBalanceCmp } from '@anthem/mbrportal-pharmacy/components/viewSpecialtyAccountBalanceCmp';
import { VirtualSpecialtyQuestionnaireModel } from '@anthem/mbrportal-pharmacy/models/virtualSpecialtyQuestionnaireModel';
import { VirtualSpecialtyQuestionnaireService } from '@anthem/mbrportal-pharmacy/services/virtualSpecialtyQuestionsSvc';
import { VirtualSpecialtyQuestionnaireComponent } from '@anthem/mbrportal-pharmacy/components/virtualSpecialtyQuestionnaireCmp';
import { VendorManageAutoPrescriptionShortDetailComponent } from '@anthem/mbrportal-pharmacy/components/vendorManageAutoPrescriptionShortDetailCmp';
import { AhdDeliveryAddressesService } from './services/ahd-delivery-addresses.service';
import { SearchProviderModule } from '@anthem/mbrportal-search-provider/searchProviderModule';
import { VendorPaymentWalletSharedService } from '@anthem/mbrportal-pharmacy/services/vendorPaymentWalletSharedSvc';
import { SpecialtyInformationComponent } from './bio-plus/specialty-information/specialty-information.component';
import { SpecialtyRequestComponent } from './bio-plus/specialty-request/specialty-request.component';



@NgModule({
  declarations: [
    AccountBalanceSummaryComponent,
    ActionItemsComponent,
    AHDCartComponent,
    AhdDownloadComponent,
    AhdIdCardMessagesComponent,
    AhdLandingPageOrdersComponent, AhdLeftRailNavComponent,
    AhdLeftRailNavComponent,
    AhdMyOrdersPageComponent,
    AhdMyOrdersPageComponentContainer,
    AhdPharmacyProfileComponent,
    AhdPrescriptionBenefitOrdersComponent,
    AHDPrescriptionHomeComponent,
    AhdPrescriptionHomeComponentContainer,
    AHDReviewOrderComponent,
    AhdReviewOrderLandingComponent,
    AhdReviewProcessingComponent,
    AhdTrackOrderDetailsComponent,
    AhdTrackOrderDetailsLandingComponent,
    AmountFrequencyCmp,
    AutopayCancelConfirmationComponent,
    AutopayConfirmationComponent,
    BannerComponentV2,
    BillingPaymentComponent,
    BillPharmacyPaymentMethodsComponent,
    CartComponent,
    CheckOrderStatusComponent,
    CheckoutEmailCmp,
    CpccBadgesComponent,
    CurrentPrescriptionsOrderComponent,
    DrugInteractionCheckerComponent,
    HomeDeliveryPrescriptionComponent,
    InnetworkSearchComponent,
    LinkViewBodyComponent,
    LinkViewComponent,
    MailOrderConfirmationComponentV2,
    MailOrderReviewComponentV2,
    ManageAutoCancelComponent,
    ManagePrescriptionsComponentV2,
    MedicalProfileComponent,
    MedicalProfileComponentV2,
    NewPrescriptionOrderReviewComponent,
    OnBoardingCarouselComponent,
    OrderDeliveryAddressComponent,
    OrderPrescriptionDetailsComponent,
    PartnerLoginComponent,
    PayPharmacyAccountBalanceComponent,
    PayPharmacyAccountBalanceConfirmationComponent,
    PaySpecialtyAccountBalanceCmp,
    PaySpecialytAccountBalConfirmationCmp,
    PharamacySavingsBannerComponent,
    PharamacySavingsComponent,
    PharamacySavingsDetailComponent,
    PharmacyHomeDeliveryContainerComponent,
    PharmacyLandingPageComponent,
    PharmacyPaymentHistoryComponent,
    PharmacySearchLinkComponent,
    PharmacySpecialtyDeliveryContainerComponent,
    PharmacySubnavMenuComponent,
    PharmacyTabbedViewComponent,
    PrescriberSearchComponent,
    PrescriptionAuthInfoComponent,
    PrescriptionBannerComponent,
    PrescriptionBenefitOrdersComponent,
    PrescriptionDateRangeComponent,
    PrescriptionDiscountComponent,
    PrescriptionFilterComponentV2,
    PrescriptionHomeComponentV2,
    PrescriptionListComponentV2,
    PrescriptionOrderConfirmationV2,
    PrescriptionPipe,
    PrescriptionReviewComponentV2,
    PrescriptionReviewRowViewComponent,
    PrescriptionSearchComponentV2,
    PriceAMedicationDetailsComponentV2,
    PriceMedCostDetailsCmp,
    PriceMedMailServiceDetailComponentV2,
    PriceMedSearchComponentV2,
    PriceMedStorePickupDetailComponentV2,
    PriceMedSuggestComponent,
    PriceMedTherapeuticAltrntvesComponentV2,
    PriorAuthAlertsComponent,
    ProcessOrderComponent,
    ProfilePayMethodsComponent,
    RadioFilterComponent,
    ReviewOrderComponent,
    RxCarveoutComponentV2,
    RxCreditCardExpiryDateFormatDirective,
    RxCreditCardExpiryDateValidatorDirective,
    RXCreditCardValidatorDirective,
    RxPaymentMethodsComponent,
    RxProfileShippingAddressComponent,
    RxShippingAddressComponent,
    SavingsOpportunityComponent,
    SavingsOpportunityDetails,
    SetupAutopayComponent,
    SpecialtyCostReliefBannerComponent,
    SpecialtyCostReliefDrugListComponent,
    SpecialtyFirstFillComponent,
    SpecialtyPharmacyComponent,
    SpecialtyQuestionnaireComponentV2,
    SplitPaymentValidatorDirective,
    SwitchNinetyNewPrescriptionComponent,
    TermsOfUseComponent,
    TestPageComponent,
    TherapeuticOrderComponent,
    TherapeuticOrderComponent,
    TherapeuticOrderListComponent,
    TwoDigitDecimalFormatDirective,
    VendorAddMorePrescriptionsComponent,
    VendorAddMorePrescriptionsComponent,
    VendorAHDReviewRefillComponent,
    VendorCouponComponent,
    VendorCurrentOrdersComponent,
    VendorCurrentOrderStatusComponent,
    VendorIntermediateScreenCmp,
    VendorIntermediateScreenCmp,
    VendorManageAutoComponent,
    VendorManageAutoEnrollmentComponent,
    VendorManageAutoPrescriptionShortDetailComponent,
    VendorManageAutoPartialErrorComponent,
    VendorManageAutoReviewComponent,
    VendorNavigationComponent,
    VendorOrderDetailsInvoiceComponent,
    VendorOrderDetailsPostpayComponent,
    VendorOrderDetailsPrepayComponent,
    VendorOrderProgressComponent,
    VendorPageHeaderComponent,
    VendorPaymentHistoryComponent,
    VendorPaymentWalletComponent,
    VendorPaymentWalletComponent,
    VendorPaymentWalletSummaryComponent,
    VendorPharmacyCardComponent,
    VendorPharmacyProfileComponent,
    VendorPrescriberSummaryComponent,
    VendorPrescriptionListAccordionComponent,
    VendorPrescriptionListComponent,
    VendorPrescriptionReviewComponent,
    VendorPrescriptionReviewComponent,
    VendorPreviouslyFilledPrescriptionsComponent,
    VendorPreviouslyFilledPrescriptionsComponent,
    VendorPriceMedicationComponent,
    VendorPriceMedicationComponent,
    VendorPriceMedicationMailDrugComponent,
    VendorPriceMedicationMailDrugComponent,
    VendorPriceMedicationSearchDrugComponent,
    VendorPriceMedicationSearchDrugComponent,
    VendorRenewRefillComponent,
    VendorRequestMedicationComponent,
    // VendorRequestMedicationConfirmationComponent,
    VendorRetailStoreComponent,
    VendorRetailStoreComponent,
    VendorReviewOrderPaymentComponent,
    VendorReviewOrderReceiptComponent,
    VendorReviewTransferComponent,
    VendorReviewTransferContainerComponent,
    VendorReviewTransferDetailsComponent,
    VendorReviewTransferPartialFailureComponent,
    VendorSearchPrescriberComponent,
    VendorSpecialityPrescriptionDateComponent,
    VendorTherapeuticAlternativeDrugComponent,
    VendorTrackShipmentSliderComponent,
    VendorTransferMultipleRxComponent,
    ViewSpecialtyAccountBalanceCmp,
    ViewSpecialtyAccountBalanceCmp,
    VirtualSpecialtyQuestionnaireComponent,
    SpecialtyInformationComponent,
    SpecialtyRequestComponent
  ],
  imports: [AccordianModule,
    AhdPharmacyRoutingModule,
    AlertModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    CommonModule2,
    CoreModule,
    DashboardModule,
    DropdownModule,
    FormGroupModule,
    FormsModule,
    IndicatorModule,
    ModalModule,
    PaginatorModule,
    // PharmacyCarveoutModule,
    ProfileModule,
    SearchProviderModule,
    NavigationModule,
    RadioModule,
    // SearchProviderModule,
    SharedModule,
    // SsoModule,
    TabsModule,
    TabsModuleV2,
    ToggleSwitchModule,
    TooltipModule,
    UxDeprecatedModule,
    UtilityModule,
    // ReimbursementsModule,
    BrowserModule,
    SidebarRailModule
  ],
  exports: [PharmacyLandingPageComponent, AhdReviewOrderLandingComponent],
  providers: [AHDContentHelper,
    { provide: CommunicationPreferencesSvc, useClass: ahdPharmacyService },
    { provide: PharmacyProfileAddressSvc, useClass: ahdPharmacyService },
    { provide: SearchProviderSvc, useClass: AhdDeliveryAddressesService },
    { provide: GeocodeApi, useClass: AHDGeocodeApi },
    ActionItemsModel,
    AhdPatientPreferenceService,
    VirtualSpecialtyQuestionnaireService,
    VirtualSpecialtyQuestionnaireModel,
    VendorPaymentWalletSharedService,
    AhdPrescriptionService,
    AhdPrintPageModel,
    AhdTherapeuticEquivalentService,
    BillingPaymentModel,
    ClinicalQuestionnaireAPI,
    CouponUploadRequestInterceptor,
    CvsPaymentService,
    CvsPharmacyService,
    DeliveryCostService,
    DrugService,
    InnetworkSearchModel,
    ManagePrescriptionsModel,
    MedicalProfilesModel,
    OpportunityDataServiceV2,
    OrderDataServiceV2,
    OrderDeliveryAddressModel,
    OrderModel,
    OrderPrescriptionDetailsModel,
    OrderPrescriptionService,
    OrdersListModel,
    OrdersSortModel,
    PayAccBalanceModel,
    PharamacySavingsModel,
    PharmacyNavigationUtility,
    PharmacyPaymentHistoryModel,
    // PharmacySearchFilterModel,
    PharmacySvc,
    PharmacyTabbedViewModel,
    PharmacyTabbedViewService,
    PlaceOrderModelV2,
    PrescriberModel,
    PrescriberSearchModel,
    PrescriptionBannerModelV2,
    { provide: PrescriptionDataServiceV2, useClass: AHDPrescriptionDataService },
    PrescriptionListModel,
    PrescriptionListModelV2,
    PrescriptionModel,
    PrescriptionModelV2,
    PrescriptionSortModelV2,
    PrescriptionSubscriptionService,
    PrescriptionWidgetConfig,
    PriceMedicationDetailsModelV2,
    PriceMedicationModelV2,
    ProccessOrderModel,
    ProfileAddressValidationService,
    QuestionnaireModelV2,
    RxPayMethodsModel,
    RxShippingAddressModel,
    SavingsOpportunityModel,
    SearchProviderModel,
    SearchProviderSvc,
    SetupAutoPayModel,
    SpecialtyCostReliefService,
    SpecialtyPharmacyModel,
    StorePickUpDateTimeModel,
    SwitchNinetyNewPrescriptionModel,
    TherapeuticOrderModel,
    TouModel,
    ValidationModelV2,
    VendorCouponModel,
    VendorCurrentOrdersModel,
    VendorCurrentOrderStatusModel,
    VendorManageAutoRefillModel,
    VendorManageAutoRefillService,
    // VendorMappingSevice,
    VendorOpportunityDataService,
    VendorPaymentModel,
    VendorPaymentWalletService,
    VendorPaymentWalletSummaryComponent,
    VendorPrescriptionReviewModel,
    VendorPreviouslyFilledPrescriptionsModel,
    VendorPriceMedicationDrugPriceService,
    VendorPriceMedicationMailDrugModel,
    VendorPriceMedicationModel,
    VendorPriceMedicationSearchDrugComponent,
    VendorPriceMedicationSearchModel,
    VendorPriceMedicationService,
    VendorPriceMedicationMailDrugModel,
    VendorReviewOrderModel,
    VendorRenewRefillModel,
    VendorRetailStoreModel,
    VendorReviewTransferModel,
    VendorTherapeuticAlternativeDrugModel,
    VendorTransferPrescriptionsService,
    VendorPriceMedicationMailDrugModel,
    VendorReviewOrderModel,
    VendorCouponService],
  bootstrap: [PharmacyLandingPageComponent, AhdReviewOrderLandingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AhdPharmacyModule {
  static entryComponents: any = [

  ];
}
