<div class="state-header maintenance" style="padding:0px;">
      <div class="masthead " style="background-image: url(https://www.static-anthem.com/dam/masthead_blue.svg)">
        <header>
            <h1 class="headline-underline animate">
                {{jsonLabels?.HeadingLabel}}   
            </h1>
        </header>
    </div>
    <main style="position: relative;z-index: 1;">
        <div class="container">
            <div class="col-xxs-12 col-xs-10 col-md-12 col-xs-offset-1 col-md-offset-0">
                <div class="row">
                    <div class="content-container maintenance-content">
                        <div class="content col-xxs-10 col-xxs-offset-1">
                            <div style="text-align: center;">{{jsonLabels?.MaintenanceMessageLine1}}</div>
                            <div style="text-align: center;">&nbsp;</div>
                            <div>{{jsonLabels?.MaintenanceMessageLine2}}</div>
                            <div style="text-align: center;">{{jsonLabels?.MaintenanceMessageLine3}} </div>
                            <div style="text-align: center;">&nbsp;</div>
                            <div>{{jsonLabels?.MaintenanceMessageLine4}}</div>
                            <div>&nbsp;</div>
                            <div style="text-align: center;">{{jsonLabels?.MaintenanceMessageLine5}}</div>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

</div>