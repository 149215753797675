import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { oneTimePasscodeModel } from '../../../../libs/one-time-passcode/one-time-passcode.model';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { HttpResponse } from '@angular/common/http';
import { RegistrationValidateTokenRequest, RegistrationValidateTokenResponse } from 'gbd-models';

export enum RegistrationRouteParam {
  SOURCE_TYPE = 'sourceType',
  TOKEN = 'token',

}

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(
    private httpClient: HttpClientService,
    private _getSecurityCodeModel: oneTimePasscodeModel,
    ) { }

  callEligibilityAPI(data, hasToken = false) {
    const url = UrlProperties.UrlProperties.endPoints.registration.eligibility;
    const { tokenEligibility } = UrlProperties.UrlProperties.endPoints.registration
    if (hasToken) {
      return this.httpClient.postObservable(tokenEligibility, data);
    }
    return this.httpClient.postObservable(url, data);
  }

  callOtpAPI(data) {
    const url = UrlProperties.UrlProperties.endPoints.registration.sendOtp;
    sessionStorage.setItem('recoveryModel', JSON.stringify(data));  //***TODO Handle better */
    return this.httpClient.postObservable(url, JSON.stringify(this._getSecurityCodeModel.transformToOTPRequest(data, "ARN")));
  }

  checkUserNameAvailable(data) {
    const url = UrlProperties.UrlProperties.endPoints.registration.checkUsernameAvailibility;
    return this.httpClient.postObservable(url, JSON.stringify(data));

  }

  async getSecurityQuestions() {
    const url = UrlProperties.UrlProperties.endPoints.registration.getSecurityQuestions;
    const result = await this.httpClient.getObservable(url).toPromise();
    return result;
  }

  submitRegistration(data) {
    const url = UrlProperties.UrlProperties.endPoints.registration.submitRegistration;
    return this.httpClient.postObservable(url, data);
  }

  async tokenize(data) {
    const url = UrlProperties.UrlProperties.endPoints.registration.tokenize;
    const result = await this.httpClient.postObservable(url, data).toPromise();
    return result
  }

  async decrypt(data) {
    const url = UrlProperties.UrlProperties.endPoints.registration.decrypt;
    const result = await this.httpClient.postObservable(url, data).toPromise();
    return result;
  }  

  validateToken(request: RegistrationValidateTokenRequest): Promise<RegistrationValidateTokenResponse> {
    const url = UrlProperties.UrlProperties.endPoints.registration.validateToken;
    return this.httpClient.postObservable(url, request).pipe(
        map((response: HttpResponse<RegistrationValidateTokenResponse>) => response.body),
        catchError(error => of({isValidToken: false}))
    ).toPromise();
  }
}
