<button (click)="open()" class="modal-btn">show modal</button>
<ng-template #modalTemplate>
  <div class="map-info-modal-wrapper">
    <div class="map-info-modal-container">
      <div class="map-info-close-btn-container">
        <button class="close-button" aria-label="Close Modal" (click)="onClose()">
          <span aria-hidden="true" class="motif-icon motif-delete"></span>
        </button>
      </div>
      <h3 class="font-26 font-light margin-top-8">Food Pantry</h3>
      <p class="font-14 margin-top-16">
        Fay Biccard Glick Neighborhood Center(FBGNC)
      </p>
      <h5 class="font-size-22 font-semi-bold margin-top-24">Hours</h5>
      <div class="margin-top-8 font-16">
        <span class="font-bold">Open Now </span>
        <span>until 4:30</span>
      </div>
      <ul class="weekly-schedule-list margin-top-12">
        <li>
          <span class="weekly-list-label">Sunday</span>
          <span>closed</span>
        </li>
        <li>
          <span class="weekly-list-label">Monday</span>
          <span>1:00pm - 4:30pm</span>
        </li>
        <li>
          <span class="weekly-list-label">Tuesday</span>
          <span>9:00pm - 4:30pm</span>
        </li>
        <li>
          <span class="weekly-list-label">Wednesday</span>
          <span>9:00pm - 4:30pm</span>
        </li>
        <li>
          <span class="weekly-list-label">Thursday</span>
          <span>9:00pm - 4:30pm</span>
        </li>
        <li>
          <span class="weekly-list-label">Friday</span>
          <span>9:00pm - 4:30pm</span>
        </li>
        <li>
          <span class="weekly-list-label">Saturday</span>
          <span>closed</span>
        </li>
      </ul>
      <div class="get-in-touch-btn-container margin-top-48">
        <button class="btn btn-secondary margin-right-16">Call</button>
        <button class="btn btn-primary">Website</button>
      </div>
    </div>
  </div>
</ng-template>