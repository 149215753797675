<div class="container">
  <div #pfContainerElement></div>
  <div id="errorMessageAlerts" class="error width-100">
    <inline-alert-container #findCareErrorAlert role="alert" [name]="'find-care-error-alert'"
      [alertType]="alertPosition"></inline-alert-container>
  </div>

  <div data-uxd-data-loader-cmp *ngIf="isLoading">
    <span [innerHTML]="jsonLabels?.loading"></span>
  </div>
</div>