import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';

import { ErrorCodeMapperService } from './../../error-code-mapper/src/lib/error-code-mapper.service';
import { SecurityQuestionsModel } from './security-questions.model';
import { MaskingService } from '../../masking-service/src/lib/masking.service';
import { RegistrationService } from 'sydmed/src/app/public/registration/registration.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

const { login: loginEndpoints } = UrlProperties.UrlProperties.endPoints;

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.css']
})
export class SecurityQuestionsComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private errCodeSvc: ErrorCodeMapperService,
    public securityQuestionsModel: SecurityQuestionsModel,
    private maskingSvc: MaskingService,
    private regSvc: RegistrationService
  ) { }

  @Output()
  linkClicked: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  backButtonClicked: EventEmitter<any> = new EventEmitter<any>()
  @Input()
  jsonErrors: any;
  @Input()
  jsonLabels: any;
  @Input()
  endpoint: string;


  securityQuestionsForm: FormGroup;
  public questionsModel: any;
  public aslError: string;
  public inputData: object;
  private accountDetails: any;
  private httpOptions;
  public isLogin: boolean;

  ngOnInit(): void {
    if (this.endpoint === loginEndpoints.answerSecurityQuestions) {
      this.isLogin = true;
    }
    this.securityQuestionsForm = this.formBuilder.group({
      0: ['', [Validators.required]],
      1: ['', [Validators.required]],
      rememberDevice: ['', [Validators.required]]
    });


    this.accountDetails = sessionStorage.getItem('contactsModel');
    this.accountDetails = JSON.parse(this.accountDetails)
    this.questionsModel = this.securityQuestionsModel.transformQuestionsData(this.accountDetails.memberResponse.questions);

  }


  public verifySecurityQuestions() {
    if (this.securityQuestionsForm.valid) {
      if (this.isLogin) {
        this.inputData = this.securityQuestionsModel.transformQuestionsInputData(this.questionsModel);
      } else {
        this.inputData = this.securityQuestionsModel.transformQuestionsInputDataFUP(this.questionsModel);
      }
      this.httpClient.postObservable(this.endpoint, this.inputData).subscribe(
        async (response: any) => {
          if (this.isLogin) {
            sessionStorage.setItem('asl-token', response.body.token);
          } else {
            await this.regSvc.tokenize({data:response.body.username}).then(response => {
              sessionStorage.setItem('username', response.body.token);
            })
          }
          this.linkClicked.emit(response);
        },
        error => {
          if (this.isLogin) {
            this.aslError = this.errCodeSvc.errorCodeMapperV2(error, this.jsonErrors)
          } else {
            this.aslError = this.errCodeSvc.errorCodeMapper(error, this.jsonErrors);
          }
        }
      )
    } else {
      this.securityQuestionsForm.markAllAsTouched();
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();
    }
  }

  public back() {
    // Determine if there is contact info on file for the member. If there is no contact info, they should be sent back to the start
    // If there is contact info that can be used for OTP, they should be sent to the OTP request screen
    const hasContactInfoAvailable = this.memberHasContactInfoAvailable(this.accountDetails.memberResponse.memberContactDetails);
    this.backButtonClicked.emit(hasContactInfoAvailable);
  }

  public onChangeInput() {
    this.questionsModel = this.securityQuestionsModel.updateQuestionsModel(this.securityQuestionsForm.value, this.questionsModel);
  }

  memberHasContactInfoAvailable(contactDetails) {
    var bHasContactInfo = true;
    if ((contactDetails === undefined) || (contactDetails === null) || (contactDetails.length < 1)) {
      bHasContactInfo = false;
    }
    return bHasContactInfo;
  }

  public showHide(id: string, label?: string) {
    let element = document.getElementById(id);
    this.maskingSvc.showHide(element, label);
  }
}
