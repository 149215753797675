<div class="rx-id-card-messages-container container">
    <div class="vgr-pharm-access-questions-container card-container">
       <div class="vgr-pharm-access-questions-wrap">
          <div class="vgr-pharm-access-id">
             <div class="cmp-icon">
                <span class="fa fa-user"></span>
             </div>
             <h2>{{ content?.idcard?.label }}</h2>
             <p class="vgr-pharm-width">{{ content?.idcard?.description }}</p>
             <div class="cmp-button">
                <a class="btn btn-secondary" data-analytics="accessIdCardDbRx" href="secure/id-card"
                   id="rx-access-id-card-link" role="button" tabindex="0">{{ content?.idcard?.view }}</a>
             </div>
          </div>
          <div class="vgr-pharm-have-questions">
             <div class="cmp-icon">
                <span class="fa fa-comments"></span>
             </div>
             <h2>{{ content?.haveQuestion?.label }}</h2>
             <p class="vgr-pharm-width">{{ content?.haveQuestion?.description }}</p>
             <div class="cmp-button">
                <a class="btn btn-secondary" data-analytics="sendUsMessageDbRx"
                   href="secure/message-center" id="rx-send-message-link" role="button" tabindex="0">{{ content?.haveQuestion?.message }}</a>
             </div>
          </div>
       </div>
    </div>
 </div>