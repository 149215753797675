import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PaymentMethodsService } from "../services/payment-methods.service";
import { PaymentMethodDataService } from 'sydmed/src/app/secure/pharmacy/services/payment-method-data.service';
import { AlertService } from "@anthem/uxd/alert";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";
import { HelperProperties } from "../models/helper.properties";
import { CheckBox } from '../models/labels';
import {
  CheckBoxNames,
  SuccessRegion
} from "../models/enum-values";
import { Utils } from "../models/utils";
import { default as CheckingJsonData } from "../assets/data/checkingJsonData.json";
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';

@Component({
  selector: "app-add-checking-payment",
  templateUrl: "./add-checking-payment.component.html",
  styleUrls: ["./add-checking-payment.component.scss"]

})
export class AddCheckingPaymentComponent implements OnInit {
  addCheckingAccountForm: FormGroup;
  isChecked: boolean = false;
  checkbox: CheckBox;
  loading: boolean = false;
  helperProperties: HelperProperties;
  payLoad: any;
  errMsg: string = "";

  // pass this parameter with false if checkbox is not required
  @Input("showCheckBox") showCheckBox: boolean = true;
  // provide this parameter with false if buttons are not needed.
  @Input("showButtons") showButtons: boolean = true;
  @Input("labels") labels: any = CheckingJsonData;
  @Input("errObj") errObj: any;
  @Input("lineOfBusiness") lineOfBusiness;
  @Input() showSuccess = true;
  @Output() isCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private paymentMethodsSvc: PaymentMethodsService,
    protected paymentDataSvc: PaymentMethodDataService,
    private alertSvc: AlertService,
    private errorCodeMapperSvc: ErrorCodeMapperService
  ) {
    this.helperProperties = new HelperProperties(alertSvc);
    this.addCheckingAccountForm = this.formBuilder.group({
      routingNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9)
        ]
      ],
      accountNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(4)
        ]
      ],
      firstName: ["", [Validators.required, FormValidators.WhiteSpace]],
      lastName: ["", [Validators.required, FormValidators.WhiteSpace]],
      textCheckbox: [this.isChecked]
    });
  }

  ngOnInit(): void {
    this.helperProperties.scrollToTop();
    this.setCheckboxParams();
  }

  ngOnChanges(changes) {
    this.setDefaultValuesForInputs(changes);
  }

  setDefaultValuesForInputs(changes) {
    if (!changes.hasOwnProperty("showCheckBox")) {
      this.textCheckbox.setValue(false);
      this.textCheckbox.updateValueAndValidity();
    }
    if (!changes.hasOwnProperty("labels")) {
      this.labels = CheckingJsonData.AddCheckingLabels;
    }
    if (!changes.hasOwnProperty("errObj")) {
      this.errObj = CheckingJsonData.ErrMsgs;
    }
  }

  setCheckboxParams() {
    this.checkbox = this.helperProperties.setCheckboxParams(
      CheckBoxNames.CHECKING_CHECKBOX,
      CheckBoxNames.CHECKING_CHECKBOX,
      this.labels.Labels.DefaultMethodInfo,
    )
  }

  addCheckingAccount(form) {
    if (form.valid) {
      this.loading = true;

      if (this.lineOfBusiness) {
        this.lineOfBusiness = this.lineOfBusiness.toUpperCase();
        this.payLoad = Utils.getPayloadForChecking(
          this.lineOfBusiness,
          form.value
        );
      }

      this.paymentMethodsSvc
        .addCheckingAccount(this.lineOfBusiness, this.payLoad)
        .subscribe(
          (res: any) => {
            if (this.showSuccess) {
              this.loading = false;
              this.helperProperties.successAlert(
                this.labels.SuccessMessages.PaymentAddSuccessful,
                SuccessRegion.REGION_NAME
              );
            } else {
              // reload payment service
              this.paymentDataSvc.reloadPaymentList(this.lineOfBusiness, this.payLoad, false);
              this.loading = false;
            }
            this.onCancel();
          },
          (err: any) => {
            this.loading = false;
            this.errMsg = this.errorCodeMapperSvc.errorCodeMapper(err, this.errObj);
          }
        );
    } else {
      this.addCheckingAccountForm.markAllAsTouched();
    }
  }

  onCancel() {
    this.isCancel.emit(true);
  }

  // getters for form fields
  get routingNumber() {
    return this.addCheckingAccountForm.get("routingNumber");
  }

  get accountNumber() {
    return this.addCheckingAccountForm.get("accountNumber");
  }

  get lastName() {
    return this.addCheckingAccountForm.get("lastName");
  }

  get firstName() {
    return this.addCheckingAccountForm.get("firstName");
  }

  get textCheckbox() {
    return this.addCheckingAccountForm.get("textCheckbox");
  }
}
