import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventHandler } from '@anthem/mbrportal/core/services/eventHandler';
import { ShippingAddress, ShippingResponse } from 'gbd-models';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { ValidAddressResponse } from '../../pharmacy/interfaces/ValidAddressResponse';
import { SpecialtyQuestionnaireRequest } from '@anthem/mbrportal-pharmacy/interfaces/iQuestionnaire';
import { ShippingAddressService } from '../../pharmacy-prescription-order/services/shipping-address.service';
import { PrescriptionDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/prescriptionDataSvcv2';
import { ValidateSpecialtyAddress } from '../../pharmacy/interfaces/ValidateSpecialtyAddress';
const { orderdetails, submitAHDPayment } = UrlProperties.UrlProperties.endPoints.ahdPharmacy;
const { addNewAddress, specialtyShipping, validateAddress } =
  UrlProperties.UrlProperties.endPoints.pharmacy;
 const { updateAddress } = UrlProperties.UrlProperties.endPoints.myProfile;
 const { specialtyQuestions } = UrlProperties.UrlProperties.endPoints.pharmacy;

const requestObject = {
  orderType: 'refillOnlyOrder',
  coldPack: true,
  includeSpecialtyDates: true,
  validateOrder: [
    {
      rxOrder: [
        {
          prescriptionInfo: [
            '', //TODO:  once we got solutions we will update UniqueRXID
          ],
        },
      ],
      pharmacyPickUpStoreState: '',
    },
  ],
};

@Injectable({
  providedIn: 'root',
})
export class AhdCvsPharmacyService {

  constructor(
    protected http: HttpClientService,
    private eventHandler: EventHandler,
    private shippingAddressService: ShippingAddressService,
    private _prescriptionDataService: PrescriptionDataServiceV2,
  ) {}

  validateAddress(specialtyAddressPayload: ShippingAddress) {
    const orderdetails = this._prescriptionDataService.getSPLPrescriptionData();
    const rxIds: string[] = [];
    orderdetails?.refill?.forEach((refillPrescription)=>{  
      rxIds.push(refillPrescription.uniqueRxId);
    });
    orderdetails?.renew?.forEach((renewPrescription) => {
      rxIds.push(renewPrescription.uniqueRxId);
    })
    const requestObject: ValidateSpecialtyAddress = {
      validateAddress: specialtyAddressPayload,
      validateOrder: [{
        rxOrder: [
          {
            prescriptionInfo: rxIds,
          }
        ],
        pharmacyPickUpStoreState: '',
        shippingAddressState: specialtyAddressPayload.state,
        defaultAddressState: '',
      }],
    };
    return this.shippingAddressService.validateSpecialtyAddress(requestObject).pipe(
      map( ( response: ValidAddressResponse ) => {
        return {data : response, status : response?.status };
      } ),
      catchError( error =>      
        throwError( error ) ) ).toPromise();
  }

  async getDefaultPharmacy() {
    return await this.http.postObservable(specialtyShipping, requestObject).pipe(
        map((res: HttpResponse<ShippingResponse>) => {
          return {
            data: res?.body?.addresses.find(
              (e) => e.addressType == res?.body.defaultAddressType
            ),
            status : res.status
          };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getShippingAddress() {
    return this.http
      .postObservable(specialtyShipping, requestObject)
      .pipe(
        map((res: HttpResponse<ShippingResponse>) => {
          const add = res.body.addresses[0];
          let addresses = [];
          const transformedData =  {
              addressUid: this.getAddressUid(),
              // addressTypeCd?: string;
              address1: add.line1,
              address2: add.line2,
              preferred: true,
              city: add.city,
              state: add.state,
              zipCode: add.zipCode,
              usageTypeCode: 2,
              isPOBoxAddress: add.poBox,
          }
          addresses.push(transformedData);
          return { data: {addresses:addresses} };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getAddressUid():string {
    return Math.floor(100000 + Math.random() * 900000).toString();
  }

  addUpdateDefaultAddress(address: any) {
    const add = address.data;
    const request = {
      address: {
        city: add.address.city,
        state: add.address.state,
        streetAddress1: add.address.address1,
        streetAddress2: add.address.address2,
        zipCode: add.address.zipCode
      },
      type: "PHARMACY"
    }
    return this.http
      .postObservable(updateAddress, request)
      .pipe(
        map((res: HttpResponse<any>) => {
          return { data: res?.body };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  getshippingoptions() {
    return this.http
      .postObservable(specialtyShipping, requestObject)
      .pipe(
        map((res: HttpResponse<ShippingResponse>) => {
          return { data: res?.body?.shippingOptions };
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
      .toPromise();
  }

  
  getOrderDetails(orderId:string) { 
    return this.http.getObservable(orderdetails+orderId).pipe(
      map((res: HttpResponse<any>) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  submitPayment(payment:PaymentRequest) { 
    return this.http.postObservable(submitAHDPayment,payment).pipe(
      map((res: HttpResponse<any>) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  getSpecialtyQuestions(data: SpecialtyQuestionnaireRequest) {
    return this.http.postObservable(specialtyQuestions, data).pipe(
      map((res: any) => {
        return {data: res.body};
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }
}
