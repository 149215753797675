import { NgModule } from '@angular/core';
import { AlertModule } from '@anthem/uxd/alert';
import { CommonModule } from '@angular/common';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PharmacySearchComponent } from './pharmacy-search.component';
import { PharmacyApiService } from 'sydmed/src/app/secure/pharmacy/services/pharmacy-api.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { FormGroupModule } from '@anthem/uxd/form-group';
import { InputModule } from '@anthem/uxd/input';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { ShippingAddressService } from '../pharmacy-prescription-order/services/shipping-address.service';
import {PharmacySessionService} from 'sydmed/src/app/secure/pharmacy/services/pharmacy-session.service';
@NgModule( {
  declarations: [ PharmacySearchComponent ],
  imports: [
    AlertModule,
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    FormGroupModule,
    InputModule,
    IndicatorModule
  ],
  exports: [ PharmacySearchComponent ],
  providers: [ PharmacyApiService, JsonContentService, ShippingAddressService,PharmacySessionService ]
} )
export class PharmacySearchModule { }
