import { Injectable } from '@angular/core';
import { Prescription } from 'gbd-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Cart } from '../interfaces/Cart';
import { PharmacyLob as LinesOfBusiness} from 'gbd-models';

const emptyCart: Cart = {
  checkoutLineOfBusiness: null,
  prescriptions: []
};

@Injectable( {
  providedIn: 'root'
} )
export class CartService {
  protected cart$: BehaviorSubject<Cart> = new BehaviorSubject<Cart>( emptyCart );
  public cart: Observable<Cart> = this.cart$.asObservable();
  protected cartSize$: BehaviorSubject<number> = new BehaviorSubject<number>( 0 );
  public cartSize: Observable<number> = this.cartSize$.asObservable();
  protected cartTotal$: BehaviorSubject<number> = new BehaviorSubject<number>( 0 );
  public cartTotal: Observable<number> = this.cartTotal$.asObservable();
  protected lineOfBusiness$: BehaviorSubject<LinesOfBusiness> = new BehaviorSubject<LinesOfBusiness>( LinesOfBusiness.PBM );
  public lineOfBusiness: Observable<LinesOfBusiness> = this.lineOfBusiness$.asObservable();
  protected hasPBMColdPack$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  public hasPBMColdPack = this.hasPBMColdPack$.asObservable();
  protected hasSplColdPack$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  public hasSplColdPack = this.hasSplColdPack$.asObservable();
  protected showEmptyCart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false);
  public showEmptyCart = this.showEmptyCart$.asObservable();
  protected clearCartSession$: BehaviorSubject<boolean> = new BehaviorSubject( false );
  public clearCartSession = this.clearCartSession$.asObservable();
  constructor() { }

  protected setCart( cart: Cart ) {
    this.cart$.next( cart );
    this.cartSize$.next( cart.prescriptions.length );
    this.cartTotal$.next( this.cartTotalAmt( cart ) );
  }

  cartTotalAmt( cart: Cart ) {
    return cart.prescriptions.reduce( ( acc, prescription ) => acc + this.parseString( prescription?.estimatedCopay ) , 0 );
  }

  parseString = ( amount ) => {
    return  !isNaN(amount)
     ? Number( amount.replace(',', '') ) : 0 ;
  }
  public addToCart( prescription: Prescription ) {
    const { checkoutLineOfBusiness, prescriptions } = this.cart$.getValue();
    const updatedLineOfBusiness = this.determineLineOfBusiness( prescription.lineOfBusiness, checkoutLineOfBusiness );
    this.lineOfBusiness$.next( updatedLineOfBusiness );
    if ( !this.isInCart( prescription ) ) {
      const updatedPrescriptions = [ ...prescriptions, prescription ];
      prescription.isSelected = true;
      this.setCart( {
        checkoutLineOfBusiness: updatedLineOfBusiness,
        prescriptions: updatedPrescriptions
      } );
      this.hasPBMColdPack$.next( this.checkForColdPack( updatedPrescriptions, false ) );
      this.hasSplColdPack$.next( this.checkForColdPack( updatedPrescriptions, true ) );
      this.setShowEmptyCart(false);
      this.clearCartSession$.next(false);
    }
  }

  isInCart( prescription: Prescription ) {
    return this.cart$.getValue().prescriptions.filter(
      ( rx: Prescription ) => rx.uniqueRxId === prescription.uniqueRxId ).length > 0;
  }

  public currentLineOfBusiness() {
    return this.cart$.getValue().checkoutLineOfBusiness;
  }

  public removeAll() {
    this.setCart( emptyCart );
    this.hasPBMColdPack$.next( false );
    this.hasSplColdPack$.next( false );
    this.lineOfBusiness$.next( null );
    this.clearCartSession$.next(true);
    this.setShowEmptyCart(true);
  }

  public removeItem( uniqueRxIdToRemove: string ) {
    const { prescriptions } = this.cart$.getValue();

    const updatedPrescriptions = prescriptions.filter(
      ( item: Prescription ) => item.uniqueRxId !== uniqueRxIdToRemove );

    if ( updatedPrescriptions.length === 0 ) {
      this.removeAll();
      return [];
    } else {
      const updatedLineOfBusiness = this.getNewLineOfBusiness( updatedPrescriptions );
      this.lineOfBusiness$.next( updatedLineOfBusiness );
      this.setCart( {
        checkoutLineOfBusiness: updatedLineOfBusiness,
        prescriptions: updatedPrescriptions
      } );
      this.hasPBMColdPack$.next( this.checkForColdPack( updatedPrescriptions, false ) );
      this.hasSplColdPack$.next( this.checkForColdPack( updatedPrescriptions, true ) );
    }
    return updatedPrescriptions;
  }

  protected getNewLineOfBusiness( prescriptions: Prescription[] ) {
    return prescriptions.map( ( item: Prescription, ) => item.lineOfBusiness )
      .reduce<LinesOfBusiness>( ( previous: LinesOfBusiness, current: LinesOfBusiness ) =>
        this.determineLineOfBusiness( current, previous ), null );
  }

  checkForColdPack( prescriptions: Prescription[], isSpecialty: boolean ) {
    const lob = isSpecialty ? LinesOfBusiness.SPECIALTY: LinesOfBusiness.PBM;
    return prescriptions.filter(
      ( prescription: Prescription ) => prescription.lineOfBusiness === lob &&  prescription.shipsWithColdPack ).length > 0;
  }

  setShowEmptyCart(status: boolean){
    this.showEmptyCart$.next(status);
  }

  protected determineLineOfBusiness( newLineOfBusiness: string, currentLineOfBusiness: LinesOfBusiness ): LinesOfBusiness {
    const lineOfBusiness: LinesOfBusiness = LinesOfBusiness[ newLineOfBusiness.toUpperCase() ];
    return lineOfBusiness === currentLineOfBusiness
      || currentLineOfBusiness === null ?
      lineOfBusiness : LinesOfBusiness.BOTH;
  }
}


