<div id="grievance_success" class="text-center" role="alert">
    <div class="popup-alert">
        <em class="far fa-check-circle"></em>
        <h3 [innerHtml]="confirmationLabels?.SuccessHeader"></h3>
    </div>

    <p [innerHtml]="confirmationLabels?.Message"></p>

    <button type="button" id="Go_btn" class="btn orangeBtn" [innerHtml]="confirmationLabels?.Return_btn" (click)="returnButton()"></button>
</div>
