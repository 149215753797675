<div>
  <div class="mbr-bottom-of-page">
    <div class="uxd-footer">
      <div class="footer-container">
        <div class="footer-flex">
          <div id="levels" class="footer-block footer-block-0" data-block-index="0">
            <div class="footer-info footer-col-1" *ngIf="jsonLabels?.member">
              <div class="member-services" *ngIf="!jsonLabels?.nyTimings">
                <div class="mobile-block mobile-block-1" x-ms-format-detection="none">
                  <h2 class="footer-info-header bold">{{jsonLabels?.member}} </h2>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumber">{{jsonLabels.memberServicesPhoneNumber}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumberHIP">{{jsonLabels.memberServicesPhoneNumberHIP}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumberHCC">{{jsonLabels.memberServicesPhoneNumberHCC}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumberPathwaysToAging">{{jsonLabels.memberServicesPhoneNumberPathwaysToAging}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumberFAMIS">{{jsonLabels.memberServicesPhoneNumberFAMIS}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumberTENCARE">{{jsonLabels.memberServicesPhoneNumberTENCARE}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumberStarKids">{{jsonLabels?.memberServicesPhoneNumberStarKids}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.memberServicesPhoneNumberECF">{{jsonLabels.memberServicesPhoneNumberECF}}</span>
                  <span class="footer-info-content footer-phone bold" *ngIf="jsonLabels?.HKMemberServicesPhoneNumber && classId?.substring(0,2) === 'HK'; else showSMPLYPhoneNumber">{{jsonLabels.HKMemberServicesPhoneNumber}}</span>
                  <ng-template #showSMPLYPhoneNumber>
                    <span class="footer-info-content footer-phone bold">{{jsonLabels.SMPLYmemberServicesPhoneNumber}}</span>
                  </ng-template>
                </div>
                <div class="mobile-block">
                  <span class="footer-info-content footer-time bold">{{jsonLabels?.time}}</span>
                  <span *ngIf="showHKDays(jsonLabels, classId); else ShowHKdays" class="footer-info-content footer-days bold">{{jsonLabels?.HKdays}}</span>
                  <ng-template #ShowHKdays>
                    <span class="footer-info-content footer-days bold">{{jsonLabels?.days}}</span>
                  </ng-template>
                  <span class="footer-info-content footer-days bold" *ngIf="jsonLabels?.memberServicesPhoneNumberCCC">{{jsonLabels?.memberServicesPhoneNumberCCC}}</span>
                </div>
              </div>
              <div class="member-services" *ngIf="jsonLabels?.nyTimings">
                <div class="mobile-block mobile-block-1" x-ms-format-detection="none">
                  <h2 class="footer-info-header bold">{{jsonLabels?.member}} </h2>
                  <span class="footer-info-content footer-time bold">{{jsonLabels?.nyTimings}}</span>
                  <h2 class="footer-info-header bold margin-top-20">{{jsonLabels?.mltcMember}} </h2>
                  <span class="footer-info-content footer-time bold">{{jsonLabels?.nyMltcTimings}}</span>                
                </div>
              </div>
              <div class="footer-translation">
                <span class="footer-info-content footer-col-break">{{jsonLabels?.translation}}</span>
              </div>
              <ul class="width-100">
                <!-- <li><a #contactUs *ngIf="footerLinks?.ContactUs === '/secure/contact-us/message-us'; else elseBlock" routerLink="/secure/contact-us/message-us" data-analytics="contactUsFooter">{{jsonLabels?.contactUs}}</a></li>
                <ng-template #elseBlock> -->
                  <li [style.display] ="hasContactUs ? 'block' : 'none'"><a #contactUs href="{{footerLinks?.ContactUs}}" data-analytics="contactUsFooter">{{jsonLabels?.contactUs}}</a></li>
                <!-- </ng-template> -->
                <li [style.display] ="hasLiveChat ? 'block' : 'none'"><a #liveChat href="" data-analytics="liveChatFooter">{{jsonLabels?.liveChat}}</a></li>
              </ul>
            </div>
            <nav class="footer-nav" aria-label="Footer" [ngClass]="{'width-100':footerLinks?.limitedLinks}" *ngIf="!hasMNContents; else MNContents">
              <ul class="footer-col-links footer-col-2"
              [ngClass]="{'flex width-100':footerLinks?.limitedLinks}">
                <li *ngIf="!footerLinks?.PrivacyEngLink; else privacyLanguageLinks"><a href="{{footerLinks?.PrivacyLink}}"
                    data-analytics="privacyPolicyFooter">{{jsonLabels?.privacy}}</a></li>
                <ng-template #privacyLanguageLinks>
                  <li *ngIf="footerLinks?.PrivacyEngLink"><a href="{{footerLinks?.PrivacyEngLink}}"
                    data-analytics="privacyPolicyFooter">
                    <span>{{jsonLabels?.privacy}}</span>
                    </a></li>
                  <li *ngIf="footerLinks?.PrivacyEspLink"><a href="{{footerLinks?.PrivacyEspLink}}"
                    data-analytics="privacyPolicyFooter">
                    <span>{{jsonLabels?.privacy}} - {{jsonLabels?.spanish}}</span>
                    </a></li>
                </ng-template>
                <li *ngIf="footerLinks?.QualityLink"><a href="{{footerLinks?.QualityLink}}"
                    data-analytics="qualityStandardsLinkFooter">{{jsonLabels?.qStandards}}</a></li>
                <li *ngIf="footerLinks?.ALink"><a href="{{footerLinks?.ALink}}"
                    data-analytics="accessibilityLinkFooter">{{jsonLabels?.accessibility}}</a></li>
                <li *ngIf="footerLinks?.FWLink; else rfwaLinks">
                  <a href="{{footerLinks?.FWLink}}" data-analytics="wfaFooter">{{ jsonLabels?.fwa}}</a>
                </li>
                <ng-template #rfwaLinks>
                  <li><a href="{{footerLinks?.RFWLink}}" data-analytics="wfaFooter">{{ jsonLabels?.rfwa }}</a></li>
                </ng-template>
                <li><a href="{{footerLinks?.TermsOfUse}}" data-analytics="termsOfUseFooter">{{jsonLabels?.terms}}</a></li>
                <li *ngIf="footerLinks?.cyberattack"><a href="{{footerLinks?.cyberattack}}" data-analytics="anthemCyberAttackFooter">{{jsonLabels?.cyberattack}}</a></li>
              </ul>
              <ul class="footer-col-links footer-col-3" *ngIf="jsonLabels?.member">
                <li *ngIf="footerLinks?.PrivacyGuidanceEngLink"><a href="{{footerLinks?.PrivacyGuidanceEngLink}}" data-analytics="PrivacyGuidanceEngLinkFooter">
                  <span>{{jsonLabels?.privacyguidanceeng}} </span>
                  <div class="footer-info-content">{{jsonLabels?.english}} <img alt="" src="./assets/images/pdf.png" /></div></a></li>
                <li *ngIf="footerLinks?.PrivacyGuidanceEspLink"><a href="{{footerLinks?.PrivacyGuidanceEspLink}}" data-analytics="PrivacyGuidanceEspLinkFooter">
                  <span>{{jsonLabels?.privacyguidanceeng}} </span>
                  <div class="footer-info-content">{{jsonLabels?.spanish}} <img alt="" src="./assets/images/pdf.png" /></div></a></li>
                <li *ngIf="footerLinks?.HelpLink"><a href="{{footerLinks?.HelpLink}}" data-analytics="helpOtherLangLinkFooter">
                  <span *ngIf="jsonLabels?.HKhelp && classId?.substring(0,2) === 'HK'; else showHKhelp">{{jsonLabels?.HKhelp}}</span>
                  <ng-template #showHKhelp>
                    <span>{{jsonLabels?.help}} </span>
                  </ng-template>
                  <img alt="" src="./assets/images/pdf.png" /></a></li>
                <li *ngIf="footerLinks?.HelpEngLink"><a href="{{footerLinks?.HelpEngLink}}" data-analytics="helpEnglishLangLinkFooter">
                  <span>{{jsonLabels?.help}} </span>
                  <div class="footer-info-content">{{jsonLabels?.english}} <img alt="" src="./assets/images/pdf.png" /></div></a></li>
                <li *ngIf="footerLinks?.HelpSpLink"><a href="{{footerLinks?.HelpSpLink}}" data-analytics="helpSpanishLangLinkFooter">
                  <span>{{jsonLabels?.help}} </span>
                  <div class="footer-info-content">{{jsonLabels?.spanish}} <img alt="" src="./assets/images/pdf.png" /></div></a></li>
                <li *ngIf="footerLinks?.NdEngLink"><a href="{{footerLinks?.NdEngLink}}" data-analytics="nonDiscEngLinkFooter">
                  <span>{{jsonLabels?.diseng}} </span>
                  <div class="footer-info-content">{{jsonLabels?.english}} <img alt="" src="./assets/images/pdf.png" /></div></a></li>
                <li *ngIf="footerLinks?.NdEspLink"><a href="{{footerLinks?.NdEspLink}}" data-analytics="nonDiscSpanLinkFooter">
                  <span>{{jsonLabels?.disesp}} </span>
                  <div class="footer-info-content">{{jsonLabels?.spanish}} <img alt="" src="./assets/images/pdf.png" /></div></a></li>        
              </ul>
            </nav>
            <ng-template #MNContents>
              <nav class="footer-nav footer-columns" aria-label="Footer">
                <ul class="footer-col-links footer-col-2">
                  <li *ngIf="footerLinks?.agentLink"><a href="{{footerLinks?.agentLink}}"
                      data-analytics="forAgentsFooter">{{jsonLabels?.agent}}</a></li>
                  <li *ngIf="footerLinks?.employersLink"><a href="{{footerLinks?.employersLink}}"
                      data-analytics="forEmployersFooter">{{jsonLabels?.employers}}</a></li>
                  <li *ngIf="footerLinks?.providersLink"><a href="{{footerLinks?.providersLink}}"
                      data-analytics="forProvidersFooter">{{jsonLabels?.providers}}</a></li>
                  <li *ngIf="footerLinks?.bccLink">
                    <a href="{{footerLinks?.bccLink}}" data-analytics="retailCentersFooter">{{jsonLabels?.bcc}}</a>
                  </li>
                  <li><a href="{{footerLinks?.HelpLink}}" data-analytics="helpFooter">{{jsonLabels?.help}}</a></li>
                </ul>
                <ul class="footer-col-links footer-col-3">
                  <li *ngIf="footerLinks?.PrivacyLink"><a href="{{footerLinks?.PrivacyLink}}" data-analytics="privacyPolicyFooter">
                    {{jsonLabels?.privacy}}</a></li>
                  <li *ngIf="footerLinks?.NdEngLink"><a href="{{footerLinks?.NdEngLink}}" data-analytics="nonDiscEngLinkFooter">
                    {{jsonLabels?.diseng}}</a></li> 
                </ul>
              </nav>
            </ng-template>
          </div>
          <div id="socials" class="footer-block footer-block-1" data-block-index="1" *ngIf="hasAppStoreLinks(footerLinks) || hasSocialMediaLinks(footerLinks)">
            <div class="footer-social">
              <div class="social">
                <div class="social-col-header" *ngIf="footerLinks?.appStoreLink || footerLinks?.googStoreLink">
                  <p class="social-header-text font-14 font-normal">{{jsonLabels?.download}}</p>
                  <div class="store-badges">
                    <a href="{{footerLinks?.appStoreLink}}" data-analytics="medicaidAppAppleFooter" target="_blank" rel="noopener noreferrer" *ngIf="footerLinks?.appStoreLink">
                      <img class="apple" alt="{{jsonLabels?.download}} on App Store" src="./assets/images/app-store-badge-en.png" />
                    </a>
                    <a href="{{footerLinks?.googStoreLink}}" data-analytics="medicaidAppGoogleFooter" target="_blank" rel="noopener noreferrer" *ngIf="footerLinks?.googStoreLink">
                      <img class="google" alt="{{jsonLabels?.download}} on Google Play" src="./assets/images/google-play-badge-en.png" />
                    </a>
                  </div>
                </div>
                
                <div id="socialMedia" *ngIf="hasSocialMediaLinks(footerLinks)" class="social-icons">
                  <div *ngIf="footerLinks?.twitterLink" class="social-container">
                    <a href="{{footerLinks?.twitterLink}}" target="_blank" rel="noopener noreferrer"
                      data-analytics="twitterIconFooter">
                      <span class="media-icon media-icon-twitter social-icon-footer"></span>
                      <span class="footer-link display-none-mobile">{{jsonLabels?.twitter}}</span>
                    </a>
                  </div>
                  <div *ngIf="footerLinks?.facebookLink" class="social-container facebook-icon">
                    <a href="{{footerLinks?.facebookLink}}" target="_blank" rel="noopener noreferrer"
                      data-analytics="facebookIconFooter">
                      <span class="media-icon media-icon-facebook social-icon-footer"></span>
                      <span class="footer-link display-none-mobile">{{jsonLabels?.facebook}}</span>
                    </a>
                  </div>
                  <div *ngIf="footerLinks?.youTubeLink" class="social-container">
                    <a href="{{footerLinks?.youTubeLink}}" target="_blank" rel="noopener noreferrer"
                      data-analytics="youtubeIconFooter">
                      <span class="media-icon media-icon-youtube social-icon-footer"></span>
                      <span class="footer-link display-none-mobile">{{jsonLabels?.youTube}}</span>
                    </a>
                  </div>
                  <div *ngIf="footerLinks?.linkedInUrl" class="social-container">
                    <a href="{{footerLinks?.linkedInUrl}}" target="_blank" rel="noopener noreferrer"
                      data-analytics="linkedInIconFooter">
                      <img class="social-icon-footer" alt="{{jsonLabels?.linkedIn}}" src="./assets/images/linkedIn.svg" />
                      <span class="footer-link display-none-mobile">{{jsonLabels?.linkedIn}}</span>
                    </a>
                  </div>
                  <div *ngIf="footerLinks?.blogLink" class="social-container blog-icon">
                    <a href="{{footerLinks?.blogLink}}" target="_blank" rel="noopener noreferrer"
                      data-analytics="blogFooter">
                      <span class="media-icon motif-rss social-icon-footer"></span>
                      <span class="footer-link display-none-mobile">{{jsonLabels?.blog}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="copyRights" class="footer-block footer-block-2" data-block-index="2">
            <div class="footer-bottom">
              <div class="bottom-content">
                <p class="bottom-text">{{jsonLabels?.copyright}}</p>
                <p class="bottom-text">{{jsonLabels?.license}}</p>
                <p class="bottom-text">{{jsonLabels?.disclaimer}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>