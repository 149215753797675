import { Inject, Injectable } from '@angular/core';
import { HttpMethod, IHttpRequest, IHttpRequestInterceptor } from '@anthem/mbrportal/http';
import { AppConfig, AppSession } from '@anthem/mbrportal/main';
import { v4 as uuid } from 'uuid';

/**
 *  Global Http request interceptor for the application.
 */
@Injectable()
export class AppRequestInterceptor implements IHttpRequestInterceptor {
  constructor(private _appConfig: AppConfig, @Inject(AppSession) private _appSession: AppSession) {}

  /**
   *  Set all API requests cacheable, if request http method is GET and not excluded through configuration.
   *  Set all API requests Content-type header to application/json if this header is missing.
   *  Set all API requests Authorization header with app session metadata token.
   */
  transform(requestOptions: IHttpRequest): IHttpRequest {
    if ((requestOptions.url || '').toLowerCase().indexOf(this._appConfig.restApi.nodeBaseUrl) >= 0) {
      if (this._appConfig.restApi.caching.enabled) {
        let excludeRegexStr = this._appConfig.restApi.caching.excludePathsRegex;
        let excludeRegex = new RegExp(excludeRegexStr, 'gi');
        if (requestOptions.method === HttpMethod.Get && !requestOptions.url.match(excludeRegex) && typeof requestOptions.cacheable === 'undefined') {
          requestOptions.cacheable = true;
        }
      } else {
        requestOptions.cacheable = false;
      }

      if (
        this._appConfig.httpInterceptor.contentType &&
        (!requestOptions.headers ||
          !requestOptions.headers.find((h) => h.name === 'Content-Type')) &&
        !requestOptions.isMultiPartRequest
      ) {
        requestOptions.headers = requestOptions.headers || [];
        requestOptions.headers.push({
          name: 'Content-Type',
          value: 'application/json'
        });
        requestOptions.headers.push({
          name: 'gbd-token',
          value: window.sessionStorage.getItem('gbd-token') || ''
        });
        requestOptions.headers.push({
          name: 'transactionid',
          value: uuid()
        });
      }

      if (this._appConfig.restApi.timeout && typeof requestOptions.timeout === 'undefined' && requestOptions.method === HttpMethod.Get) {
        requestOptions.timeout = this._appConfig.restApi.timeout;
      }
    }

    if (requestOptions.trimSpaces !== false) {
      requestOptions.trimSpaces = this._appConfig.httpInterceptor.trimSpaces;
    }

    return requestOptions;
  }
}
