import { Component, forwardRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Input, SimpleChanges, OnChanges, Output, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, ControlValueAccessor, NG_VALIDATORS, FormControl, Validators } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { SearchService } from './search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {
  form: FormGroup;
  subscriptions: Subscription[] = [];
  @Input() searchLabel: string;
  @Input() placeholder: string;
  @Input() searchId: string;
  @Input() errorMessage: any;
  @Input() searchAnalytics: string;
  @Input() showSearchIcon: boolean;
  @Input() orderNumberParam: string;
  showErrorMsg = false;

  get value() {
    return this.form.value;
  }

  set value(value) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get search() {
    return this.form.controls.search;
  }

  constructor(private formBuilder: FormBuilder, private searchSvc: SearchService, private _detector: ChangeDetectorRef) {
    this.form = this.formBuilder.group({
      search: ['', [Validators.minLength(2)]]
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

    this.subscriptions.push(
      this.searchSvc.resetSearch.subscribe((reset: boolean) => {
        if (reset) {
          this.clearFilter();
          this.toggleReset(false);
        }
      })
    );
 }

 ngAfterViewInit() {
  if (this.orderNumberParam) {
    this.form.controls['search'].setValue(this.orderNumberParam);
  }
 }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null || value === '') {
      this.clearFilter();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { search: { valid: false, }, };
  }

  onBlur() {
    this.onTouched();
  }

  searchForTerm(event) {
    if (this.search.value && this.form.valid) {
      this.searchSvc.searchForTerm(this.search.value);
      this.showErrorMsg = false;
    } else {
      this.showErrorMsg = true;
    }
    this.showSearchIcon = false;
  }

  cancelSearch($event) {
    this.clearFilter();
    this.showErrorMsg = false;
  }

  clearFilter() {
    this.form.reset();
    this.searchSvc.cancelSearch();
    this.showSearchIcon = true;
    this._detector.detectChanges();
  }

  public resetSearchFilter($event) {
    if (!this.search.value || this.search.value === '') {
      this.toggleReset(true);
    }
  }

  toggleReset(value: boolean) {
    this.searchSvc.setResetSearch(value);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
