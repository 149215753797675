import { AppealsAndGrievancesComponent } from './appeals-and-grievances.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppealsAndGrievancesFormConfirmationComponent } from './shared/appeals-and-grievances-form-confirmation/appeals-and-grievances-form-confirmation.component';
import { AppealsAndGrievancesLandingComponent } from './shared/appeals-and-grievances-landing/appeals-and-grievances-landing.component';

const routes: Routes = [
  {
    path: 'secure/appeal-grievance',
    canActivate: [AuthGuardService],
    component: AppealsAndGrievancesLandingComponent
  },
  {
    path: 'secure/appeal',
    canActivate: [AuthGuardService],
    component: AppealsAndGrievancesComponent
  },
  {
    path: 'secure/grievance',
    canActivate: [AuthGuardService],
    component: AppealsAndGrievancesComponent
  },
  {
    path:'secure/form-confirmation/appeal',
    canActivate: [AuthGuardService],
    component: AppealsAndGrievancesFormConfirmationComponent
  },
  {
    path:'secure/form-confirmation/grievances',
    canActivate: [AuthGuardService],
    component: AppealsAndGrievancesFormConfirmationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppealsAndGrievancesRoutingModule { }
