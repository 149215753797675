import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmulationComponent } from './emulation.component';


const routes: Routes = [
  { path: 'secure/emulation', component: EmulationComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmulationRoutingModule { }
