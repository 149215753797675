<div class="expand-bg">
    <div class="container ant-content-wrapper" role="region">
        <div class="container" *ngIf="isLoading">
            <div id="search-loader">
                <uxd-spinner></uxd-spinner>
            </div>
        </div>
        <div class="detail-box">
            <h4 class="section-header-three" [attr.aria-label]="content.BillDetails">{{content.BillDetails}}</h4>
            <div class="inline-alert-grid-container" *ngIf="!paymentDetails.PdfBillAvailable && showInvoice">
                <span class="a-icon motif-icon motif-delete"></span>
                <p class="a-message">{{content.InvoiceMissingWarning1}} <a routerLink="{{content.InvoiceMissingLink}}">{{content.InvoiceMissingWarning2}}</a> {{content.InvoiceMissingWarning3}}</p>
            </div>
            <div class="payment-detail-grid-container" role="row">
                <div class="benefit-period" role="cell">
                    <h4 class="" role="columnheader">{{content.BenefitPeriod}}</h4>
                    <p class="ant-font-r mb-lh" attr.aria-label="{{paymentDetails.coverageStartDt | date}} - {{paymentDetails.coverageEndDt | date}}">{{paymentDetails.coverageStartDt | date}} - {{paymentDetails.coverageEndDt | date}}</p>
                </div>
                <div class="member-id" role="cell">
                    <h4 class="" role="columnheader">{{content.MemberId}}</h4>
                    <p class="ant-font-r mb-lh" [attr.aria-label]="paymentDetails.memberId">{{paymentDetails.memberId}}</p>
                </div>
                <div *ngIf="showInvoice" class="view-invoice" role="cell">
                    <button class="btn btn-secondary" [attr.aria-label]="content.ViewInvoice" 
                    [disabled]="!paymentDetails.PdfBillAvailable || isLoading" (click)="viewInvoice(paymentDetails.PdfBillAvailable)" data-analytics = "viewInvoiceHomeBillPay">{{content.ViewInvoice}}</button>
                </div>
            </div>
        </div>
    </div>
</div>