import { Injectable } from "@angular/core";
import { ChatbotOption, Action, Block, ChatDirective, ChatMessage, ChatMessageWithMetadata, ChatOption, ChatOptions, ChatResponse, Item } from '../interfaces/chatbotOption';
import { TypeOfOptionEnum } from "../utils/conversationEnums";

@Injectable()
export class ChatbotHelperService {
  constructor() {
  }

  JaroWinkler(s1: string, s2: string) {
    let m = 0;
    // Exit early if either are empty.
    if (s1.length === 0 || s2.length === 0) {
      return 0;
    }
    // Exit early if they're an exact match.
    if (s1 === s2) {
      return 1;
    }
    let range = (Math.floor(Math.max(s1.length, s2.length) / 2)) - 1;
    let s1Matches = new Array(s1.length);
    let s2Matches = new Array(s2.length);
    for (let i = 0; i < s1.length; i++) {
      let low = (i >= range) ? i - range : 0;
      let high = (i + range <= s2.length) ? (i + range) : (s2.length - 1);
      for (let j = low; j <= high; j++) {
        if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
          ++m;
          s1Matches[i] = s2Matches[j] = true;
          break;
        }
      }
    }
    // Exit early if no matches were found.
    if (m === 0) {
      return 0;
    }
    // Count the transpositions.
    let k = 0;
    let n_trans = 0;
    for (let i = 0; i < s1.length; i++) {
      if (s1Matches[i] === true) {
        let j = k;
        while (j < s2.length) {
          if (s2Matches[j] === true) {
            k = j + 1;
            break;
          }
          j++;
        }
        if (s1[i] !== s2[j]) {
          ++n_trans;
        }
      }
    }
    let weight = (m / s1.length + m / s2.length + (m - (n_trans / 2)) / m) / 3;
    let l = 0;
    let p = 0.1;
    if (weight > 0.7) {
      while (s1[l] === s2[l] && l < 4) {
        ++l;
      }
      weight = weight + l * p * (1 - weight);
    }
    return weight;
  }

  async processUserInput(allRedirects, inputText): Promise<ChatbotOption> {
    let maxWords = 1;
    allRedirects.forEach(redirect => {
      redirect.keywords.forEach(tag => {
        let wordsInTag = tag.split(" ").length;
        maxWords = wordsInTag > maxWords ? wordsInTag : maxWords;
      });
      redirect.totalWeight = 0.0;
    });
    let inputTags = inputText ? inputText.split(" ") : [];
    // break up input into phrases of equal word counts to those in the keywords
    let inputPhrasesByWordCount = new Array<Array<string>>();
    for (let i = 0; i < maxWords; i++) {
      let inputPhrases = new Array<string>();
      let wordCount = i + 1;
      for (let j = 0; j <= inputTags.length - wordCount; j++) {
        let inputPhrase = "";
        for (let k = 0; k < wordCount; k++) {
          if (k > 0) {
            inputPhrase = inputPhrase.concat(" ");
          }
          inputPhrase = inputPhrase.concat(inputTags[j + k]);
        }
        inputPhrases[j] = inputPhrase;
      }
      inputPhrasesByWordCount[i] = inputPhrases;
    }

    const getTagWeights = async (redirect): Promise<ChatbotOption> => {
      redirect.keywords.forEach(tag => {
        let inputPhrases = inputPhrasesByWordCount[tag.split(" ").length - 1];
        inputPhrases.forEach((inputPhrase, index) => {
          if (inputPhrase.length > 1) {
            let phraseWeight = this.JaroWinkler(tag, inputPhrase);
            if (phraseWeight >= 0.85 && phraseWeight > redirect.totalWeight) {
              redirect.totalWeight = phraseWeight;
            }
          }
        });
      });
      return redirect;
    }
    const filledRedirectPromises = allRedirects.map(getTagWeights);
    const filledRedirects: ChatbotOption[] = await Promise.all(filledRedirectPromises)
    let bestMatch = { keywords: Array<string>(), totalWeight: 0.0, url: '', name: '' };
    filledRedirects.forEach(redirect => {
      if (redirect.totalWeight > bestMatch.totalWeight) {
        bestMatch = redirect;
      }
    });

    if (bestMatch.totalWeight > 0) {
      return bestMatch;
    } else {
      return null;
    }
  }

 getActions(uiBlocks: Block[]): Action[] {
    const actions: Action[] = [];
    (uiBlocks as any[]).forEach((block) => {
      if (block.type === 'actions' && 'elements' in block) {
        block.elements.forEach((element) => {
          if (element.type === 'button') {
            actions.push({
              value: element.value,
              text: {
                text: element.text
              },
              adobetag: element.adobetag
            });
          }
        });
      }
    });
    return actions;
  }

  buildchatBotMessageList(data: ChatResponse): ChatMessage[] {
    const msgList: ChatMessage[] = [];
    if (data?.uiBlocks) {
      const actions = this.getActions(data.uiBlocks);
      (data?.uiBlocks || []).forEach((blocks: Block) => {
        let messages: ChatMessage[];
        // add length check of an array
        if (data?.directives) {
          messages = this.blockKitModel(blocks, actions, data?.directives) as ChatMessage[];
        } else {
          messages = this.blockKitModel(blocks, actions) as ChatMessage[];
        }
        messages.forEach((message: ChatMessage) => {
          if (
            message?.text ||
            [TypeOfOptionEnum.BUBBLE,TypeOfOptionEnum.INLINE_SYDNEY_BANNER, TypeOfOptionEnum.INLINE_EOB, TypeOfOptionEnum.INLINE_IDCARD, TypeOfOptionEnum.INLINE_CLAIMS].includes(message?.typeOfOption as TypeOfOptionEnum)
          ) {
            msgList.push(message);
          }
        });
      });
    }
    let lastBubbleIndex: number = -1;
    for (let i = 0; i < msgList?.length; ++i) {
      if (msgList[i] && msgList[i].typeOfOption === TypeOfOptionEnum.BUBBLE && i > lastBubbleIndex) {
        lastBubbleIndex = i;
      }
    }
    for (let i = 0; i < msgList?.length; ++i) {
      if (msgList[i] && msgList[i].typeOfOption === TypeOfOptionEnum.BUBBLE && i !== lastBubbleIndex) {
        msgList[i].typeOfOption = TypeOfOptionEnum.BUBBLE_TEXT;
      }
    }
    return msgList;
  }

  public blockKitModel(blocks: Block, actions: Action[], chatbotDirectives?: ChatDirective[]): ChatMessage[] {
    const botMessageList: ChatMessage[] = [];
    if (blocks?.type === 'section' && blocks?.text?.type === 'bubble_text') {
        botMessageList.push({
          links: blocks.links,
          text: blocks.text.text,
          msgSubType: blocks.messageType,
          typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT,
          options: [],
          directives: this.getDirectives(chatbotDirectives)
        });
    } else if(blocks?.type === 'actions'){
      const options = [];
      (actions || []).forEach((action)=>{
          if (action?.text && action?.value) {
            options.push({
              text: action.text.text,
              value: action.value,
              metadata: {},
              adobetag: action.adobetag?.replace(/ /g, '')
            });
          }
        }
      );
      botMessageList.push({
        text: '',
        msgSubType: blocks.messageType,
        typeOfOption: TypeOfOptionEnum.BUBBLE,
        buttons: options,
        directives: this.getDirectives(chatbotDirectives)
      });
    }else if (blocks?.type === 'MultipleInfo' && blocks?.items) {
      botMessageList.push({
        text: blocks.type,
        msgSubType: blocks.messageType,
        typeOfOption: TypeOfOptionEnum.ACCORDIAN,
        options: [{ options: blocks.items.map((item) => ({ text: item.text, value: item.value, metadata: {} })) }],
        directives: this.getDirectives(chatbotDirectives)
      });
    } else if (blocks?.type === 'GeneralViewAll' || blocks?.type === 'PanelList') {
      const options: ChatOption[] = [];
      (blocks.items as Item[])?.forEach((option) => {
        options.push({
          text: option?.text,
          value: option?.value,
          metadata: {}
        });
      });
      botMessageList.push({
        text: blocks.title ?? blocks.text?.text,
        msgSubType: blocks.messageType,
        typeOfOption: blocks.renderInModal ? TypeOfOptionEnum.RENDER_IN_MODAL : TypeOfOptionEnum.BUBBLE,
        options: [{ options }],
        directives: this.getDirectives(chatbotDirectives),
        listItems: options,
        additionalInfo: blocks.additionalInfo
      });

    } else if (blocks?.type === 'PanelLiveAgent') {
      const message: ChatMessageWithMetadata = {
        text: 'LiveAgentConversation',
        msgSubType: blocks.messageType,
        typeOfOption: TypeOfOptionEnum.CONVERSATION_START,
        metadata: blocks?.metadata,
        options: [{ options: [] }]
      };
      botMessageList.push(message);
    } else if (blocks?.type === 'InlineIdcard') {
      botMessageList.push({
        text: '',
        msgSubType: blocks.messageType,
        typeOfOption: TypeOfOptionEnum.INLINE_IDCARD,
        metadata: blocks?.metadata,
        idCardInfo: {
          frontImgBase64String: blocks?.contentData?.idcard?.frontImgBase64String,
          backImgBase64String: blocks?.contentData?.idcard?.backImgBase64String,
          idCardIdentifier: blocks?.contentData?.idcard?.idCardIdentifier
        },
        options: [{ options: [] }],
        directives: this.getDirectives(chatbotDirectives)
      });
    } else if (blocks?.type === 'InlineSydneyApp') {
      botMessageList.push({
        text: '',
        msgSubType: blocks.messageType,
        typeOfOption: TypeOfOptionEnum.INLINE_SYDNEY_BANNER,
        options: [{ options: [] }],
        directives: {
          type: 'navigation',
          value: blocks?.contentData?.sydneyapp?.syneyAppUrl
        }
      });
    } else if (blocks?.type === TypeOfOptionEnum.INLINE_CLAIMS) {
      botMessageList.push({
        text: '',
        msgSubType: blocks.messageType,
        typeOfOption: TypeOfOptionEnum.INLINE_CLAIMS,
        claimInfo: blocks?.contentData?.claims,
        options: [{ options: [] }]
      } as ChatMessage);
    }
    return botMessageList;
  }

 getDirectives(chatbotDirectives?: ChatDirective[]): ChatDirective | undefined {
    let directives: ChatDirective | undefined;
    (chatbotDirectives || []).forEach((directive) => {
      if ((directive?.type === 'navigation' || directive?.type === 'livechat') && directive?.value) {
        directives = {
          type: directive.type,
          value: directive.value,
          appUrlParams: directive.appUrlParams,
          adobetag: directive.adobetag
        };
      }
    });
    return directives;
  }
}