import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { FupAppComponent } from './app.component';
import { GetSecurityCodeComponent } from './get-security-code/get-security-code.component';
import { EnterSecurityCodeComponent } from './enter-security-code/enter-security-code.component';
import { AnswerSecurityQuestionsComponent } from './answer-security-questions/answer-security-questions.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PublicModule } from '../public.module';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { CheckboxModule } from '@anthem/uxd/checkbox'; //checkbox on account details
import { AccordianModule } from '@anthem/uxd/accordion'; //Standalone accordion for account details
import { AlertModule } from '@anthem/uxd/alert'; //alerts for asl errors

import { DropdownModule } from '@anthem/uxd/dropdowns';
import { ModalModule } from '@anthem/uxd/modal';
import { IndicatorModule } from '@anthem/uxd/indicators';

@NgModule({
  declarations: [
    FupAppComponent,
    GetSecurityCodeComponent,
    EnterSecurityCodeComponent,
    AnswerSecurityQuestionsComponent,
    ResetPasswordComponent,
    ConfirmationComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PublicModule,
    CustomValidatorsModule,
    CheckboxModule,
    AccordianModule,
    AlertModule,
    DropdownModule,
    ModalModule,
    IndicatorModule
  ],
  providers: [],
  bootstrap: [FupAppComponent]
})
export class ForgotUsernamePasswordModule { }
