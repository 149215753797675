import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { QuickLink } from '../model/labels';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-health-care',
  templateUrl: './my-health-care.component.html',
  styleUrls: ['./my-health-care.component.scss']
})
export class MyHealthCareComponent implements OnInit {

  public content: any;
  public quickLinks: QuickLink[] = [];

  constructor(
    private jsonService: JsonContentService, private router: Router) { }

  ngOnInit(): void {
    this.jsonService.getJSON("new-dashboard").subscribe((data) => {
      this.content = data?.dashboard?.myHealthAndCare?.labels;
      for (let quickLink in data.dashboard.careQuickLinks) {
        this.quickLinks.push(data.dashboard.careQuickLinks[quickLink]);
      }
      this.quickLinks.forEach(quickLink => { quickLink.show = !quickLink.restrictions.every(linkRestrictions => data.restrictions.includes(linkRestrictions)); });
    });
  }
  onClick(quickLink: QuickLink) {
    this.router.navigateByUrl(quickLink.link);

  }
}
