import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchService, RequestObject } from './search.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { PharmacySearchContent, PharmacySearch, DistanceOption } from './PharmacySearchContent';
import { Subscription } from 'rxjs';
import { SearchHelperService, FormControlNames } from './search-helper.service';
import { Pharmacy } from 'gbd-models';
import { ShippingAddressService } from '../pharmacy-prescription-order/services/shipping-address.service';
import { PharmacySessionService } from 'sydmed/src/app/secure/pharmacy/services/pharmacy-session.service';
import { PharmacyShippingAddress } from '../pharmacy/interfaces/PharmacyShippingAddress';
@Component({
  selector: 'app-pharmacy-search',
  templateUrl: './pharmacy-search.component.html',
  styleUrls: ['./pharmacy-search.component.scss'],
  providers: [SearchService, SearchHelperService]
})
export class PharmacySearchComponent implements OnInit, OnDestroy {
  @Output() closeSlideOut = new EventEmitter<boolean>();
  content: PharmacySearch;
  controlNames = FormControlNames;
  contentError = false;
  defaultDistance: DistanceOption;
  distanceOptions: DistanceOption[];
  searchTerm: string;
  showSearchIcon = true;
  searchForm: FormGroup;
  requestObject: RequestObject;
  protected sub = new Subscription();
  distance: DistanceOption;
  isLoading = false;
  pharmacyList: Pharmacy[];
  isError = false;
  invalidCityState = false;
  isApiCalled = false;
  displaySize = 4;
  currentListIndex = 4;
  dataAvailable = false;
  displayList: Pharmacy[] = [];
  outOfStateZip = false;
  invalidSearchTerm = false;
  invalidData = false;
  constructor(
    protected addressService: ShippingAddressService,
    protected helper: SearchHelperService,
    protected searchService: SearchService,
    protected contentService: JsonContentService,
    protected membercontextService: PharmacySessionService
  ) { }
  ngOnInit(): void {
    this.requestObject = this.initialRequestObject();
    const contentSub = this.subscribeToContent();
    this.sub.add(contentSub);
    this.isApiCalled = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  // === Actions
  onEnter(event) {
    event.target.blur();
    this.search(event);
  }
  close() {
    this.closeSlideOut.emit(true);
  }

  cancelSearch(event) {
    this.showSearchIcon = true;
    this.searchForm.reset();
    this.invalidCityState = false;
    this.getField(this.controlNames.distance).setValue(10);
    this.pharmacyList = null;
    this.displayList = [];
    this.isApiCalled = false;
    this.outOfStateZip = false;
    this.invalidSearchTerm = false;
    this.invalidData = false;
  }

  loadMore() {
    if (this.currentListIndex < this.pharmacyList.length) {

      this.displayList = [
        ...this.displayList,
        ...this.pharmacyList.slice(this.currentListIndex, (this.currentListIndex += this.displaySize))
      ];
    }

  }

  search(event?) {
    this.isLoading = true;
    this.pharmacyList = null;
    this.searchTerm = this.getField(this.controlNames.searchField).value;
    if (this.getField(this.controlNames.distance).value instanceof Object) {
      this.requestObject.distance = this.getField(this.controlNames.distance).value.value;
    } else {
      this.requestObject.distance = this.getField(this.controlNames.distance).value;
    }
    if (this.searchTerm) {
      this.showSearchIcon = false;
      this.validateAndSearch();
    } else {
      this.showIsRequired(this.controlNames.searchField);
      this.isLoading = false;
      this.isApiCalled = false;
    }

  }
  searchPharmacies(requestObject: RequestObject) {
    this.searchService.getSearchResults(requestObject).subscribe((pharmacyList) => {
      if (Array.isArray(pharmacyList)) {
        this.displayList = [];
        this.pharmacyList = null;
        this.isLoading = false;
        this.isApiCalled = true;
        this.filterList(pharmacyList);
      } else {
        this.invalidData = true;
        this.searchForm.get(this.controlNames.searchField).setErrors({ invalidData: true });
        this.isLoading = false;
        this.isError = false;
      }

    },
      error => {
        this.isLoading = false;
        this.isError = true;
      });
  }

  filterList(pharmacyList: Pharmacy[]) {
    const memberContext = this.membercontextService.getMemberContext();
    let lengthBefore = 0;
    let filteredList = [];
    lengthBefore = pharmacyList.length;
    this.pharmacyList = pharmacyList;
    if (lengthBefore === 0) {
      this.outOfStateZip = false;
    } else {
      filteredList = this.pharmacyList = pharmacyList.filter((element) => {
        return (memberContext.stateLob.substring(0, 2)).toLowerCase().indexOf(element.address.state.toLowerCase()) > -1;
      });

      if (filteredList.length > 0) {
        this.outOfStateZip = false;
        this.pharmacyList = filteredList.map((pharmacy: Pharmacy, index: number) => {
          if (index < this.displaySize) {
            this.displayList.push(pharmacy);
          }
          return pharmacy;
        });
      } else {
        this.outOfStateZip = true;
        this.searchForm.get(this.controlNames.searchField).setErrors({ outOfStateZip: true });
      }
      this.isLoading = false;
      this.isError = false;
    }
  }

  select(pharmacy: Pharmacy) {
    const address: PharmacyShippingAddress = {
      line1: pharmacy.formattedName,
      line2: pharmacy.address.streetAddress1,
      city: pharmacy.address.city,
      state: pharmacy.address.state,
      zipCode: pharmacy.address.zipCode,
      pharmacyName: pharmacy.formattedName,
      pharmacyNumber: pharmacy.pharmacyNumber,
      storeNumber: `${pharmacy.id}`
    };
    this.addressService.setPharmacyAddress(address);
    this.close();
  }
  // === End of Actions

  // Subscriptions
  subscribeToContent() {
    return this.contentService.getJSON('pharmacy-search').subscribe(
      (content: PharmacySearchContent) => {
        if (content) {
          this.content = content.PharmacySearch;
          this.distanceOptions = this.content.distanceOptions;
          this.defaultDistance = this.helper.getDefaultDistance(this.content);
          this.searchForm = this.setupForm(this.defaultDistance);
          this.onChanges();
        }
      }, error => this.contentError = true);
  }

  onChanges() {

    const formSub = this.getField(this.controlNames.distance).valueChanges.subscribe(val => {
      this.currentListIndex = 4;
      this.isApiCalled = false;
      this.outOfStateZip = false;
      this.invalidSearchTerm = false;
      this.invalidData = false;
      if (this.searchForm.get(this.controlNames.searchField).hasError) {
        this.searchForm.get(this.controlNames.searchField).setErrors({ required: null });
        this.searchForm.get(this.controlNames.searchField).updateValueAndValidity();
      }
      this.search();
    });
    this.sub.add(formSub);
  }

  setupForm(defaultOption: DistanceOption) {
    return new FormGroup({
      searchField: new FormControl('', [Validators.required, Validators.minLength(4)]),
      distance: new FormControl(defaultOption)
    });
  }

  getField(fieldName: string) {
    return this.searchForm.get(fieldName);
  }

  showIsRequired(fieldName: string) {
    return !this.fieldIsValid(fieldName) && this.fieldTouched(fieldName);
  }

  fieldIsValid(fieldName: string) {
    return this.searchForm.get(fieldName).valid === true;
  }
  fieldTouched(fieldName: string) {
    return this.searchForm.get(fieldName).touched === true;
  }

  initialRequestObject(): RequestObject {
    return {
      open24HoursADay: false,
      open7DaysAWeek: false,
      providesFluShots: false,
      languageSelected: 'english',
      pharmacyName: '',
      distance: 20.0,
      city: '',
      state: '',
      zipCode: '',
    };
  }

  validateAndSearch() {
    this.invalidCityState = false;
    this.requestObject.city = '';
    this.requestObject.state = '';
    this.requestObject.zipCode = '';
    this.requestObject.pharmacyName = 'CVS';
    if (/^\d+$/.test(this.searchTerm)) {
      if (this.searchTerm.length !== 5) {
        this.invalidSearchTerm = true;
        this.searchForm.get(this.controlNames.searchField).setErrors({ invalidSearchTerm: true });
        this.invalidCityState = this.outOfStateZip = false;
        this.showIsRequired(this.controlNames.searchField);
        this.isLoading = false;
      } else {
        this.requestObject.zipCode = this.searchTerm;
        this.searchPharmacies(this.requestObject);
      }
    } else {
      const searchCityState = this.searchTerm.split(',');
      if (searchCityState && searchCityState.length > 1) {
        const city = searchCityState[0].toUpperCase().trim();
        const state = searchCityState[1].toUpperCase().trim();
        this.requestObject.city = city.split('.').join('');
        this.requestObject.state = state.split('.').join('');
        this.searchPharmacies(this.requestObject);
      } else {
        this.searchForm.get(this.controlNames.searchField).setErrors({ citystateInvalid: true });
        this.invalidCityState = true;
        this.isLoading = false;
      }
    }
  }


}
