<div class="pharmacy full-callout">
  <div class="container">
    <div class="row">
      <div class="ant-content-wrapper" role="region">
        <div class="cards-wrapper">
          <div class="cards-container">

            <!-- new flex layout -->
            <div class="order-header-details-wrapper">
              <div class="flex-grid">
                <div class="col">
                  <h4 class="order-details section-header-three">{{ labels?.PrescriptionDetails }}</h4>
                </div>
              </div>
            </div>
            <div class="order-prescription-details-wrapper">
              <div class="accordion-expansion-grid-container">
                <dl class="last-filled">
                  <dt class="ant-font-sb mb-f-left">
                    {{ labels?.LastFilled }}
                  </dt>
                  <dd class="ant-font-r mb-lh" *ngIf="prescriptionDetails.lastFillDate">
                    {{ prescriptionDetails?.lastFillDate }}
                  </dd>
                </dl>
                <dl class="rx-expires">
                  <dt class="ant-font-sb mb-f-left">
                    {{ labels?.RxExpires }}
                  </dt>
                  <dd class="ant-font-r mb-lh">
                    {{ prescriptionDetails?.expirationDate }}
                  </dd>
                </dl>
                <dl class="rx-number">
                  <dt class="ant-font-sb mb-f-left">
                    {{ labels?.RxNumber }}
                  </dt>
                  <dd class="ant-font-r mb-lh"
                        *ngIf="prescriptionDetails.rxNumber"
                        [innerHtml]="prescriptionDetails?.rxNumber | textHighlight : searchTerm : 'ant-background-highlight-orange'">
                </dd>
                </dl>
                <dl class="prescribed-by">
                  <dt class="ant-font-sb mb-f-left-block">
                    {{ labels?.PrescribedBy }}
                  </dt>
                  <dd class="ant-font-r mb-lh"
                        [innerHtml]="prescriberName| titlecase | textHighlight : searchTerm : 'ant-background-highlight-orange'">
                </dd>
                </dl>
                <dl class="filled-by">
                  <dt class="ant-font-sb mb-f-left-block">
                    {{ labels?.FilledBy }}
                  </dt>
                  <dd class="ant-font-r" *ngIf="prescriptionDetails.lastFillDate">
                    {{ prescriptionDetails?.fulfilledBy }}
                  </dd>
                </dl>
                <dl class="quantity">
                  <dt class="ant-font-sb mb-f-left">
                    {{ labels?.Quantity }}
                  </dt>
                  <dd class="ant-font-r mb-lh" *ngIf="prescriptionDetails?.supply?.quantity != '' else zeroQuantity">
                    {{  prescriptionDetails.supply.quantity | number:'1.0-0' }}
                  </dd>
                  <ng-template #zeroQuantity>
                    <dd class="ant-font-r ">0</dd>
                  </ng-template>
                </dl>
                <dl class="ndc">
                  <dt class="ant-font-sb mb-f-left">
                    {{ labels?.NDC }}
                  </dt>
                  <dd class="ant-font-r mb-lh" *ngIf="prescriptionDetails.rxNumber">
                    {{ prescriptionDetails?.drug?.ndcNumber }}
                  </dd>
                </dl>
                <dl class="directions">
                  <dt class="ant-font-sb mb-f-left-block">
                    {{ labels?.Directions }}
                  </dt>
                  <dd class="ant-font-r mb-lh">
                    {{ prescriptionDetails?.dosageDirections }}
                  </dd>
                </dl>
              </div>
            </div>
            <!-- /new flex layout -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>