<div id="id-card-container">
  <div *ngIf="content; else loadingOrError" class="idcard" [ngClass]="!isDashboard ? 'container' : ''">
    <!-- Id card zoom modal -->
    <div data-uxd-modal-cmp #zoomModal="modal" hidden [modal]="{id: 'id-card-zoom-modal', title: content.zoom.header}">
      <div class="modal-component-body">
        <div class="imageContainerZoom">
          <div class="front zoom" [ngClass]="{ active: flipped }">
            <img [src]="idCardFront" id="image" alt="ID card image front" />
          </div>
          <div class="secondimg zoom" [ngClass]="{ active: !flipped }">
            <img [src]="idCardBack" id="image" alt="ID card image back " />
          </div>
        </div>
      </div>

      <div class="modal-component-footer">
        <button data-lastfocus class="btn btn-secondary" id="id-card-zoom-modal-close-button"
          [attr.data-analytics]="content.zoom.modal.closeButtonAnalytics"
          [attr.aria-label]="content.zoom.modal.closeButtonAria" (mousedown)="closeZoomModal()"
          (click)="closeZoomModal()" [innerText]="content.zoom.modal.closeButton"></button>
        <button class="btn btn-primary" data-analytics="flipCardZoomModalIdCard" id="id-card-zoom-modal-flip-button"
          [attr.data-analytics]="content.zoom.modal.flipButtonAnalytics"
          [attr.aria-label]="content.zoom.modal.flipButtonAria" (click)="flipCard(flipped)"
          [innerText]="content.zoom.modal.flipButton"></button>
      </div>
    </div>
    
    <!-- ID Card loader -->
    <div data-uxd-data-loader-cmp="" *ngIf="idCardLoading" [ngClass]="!isDashboard ? 'container' : ''">
      <span [innerHTML]="content.view.loadingMessage"></span>
    </div>
    <div *ngIf="!isDashboard; else dashboardIDCard">

      <!-- ID Card modal actions success message -->
      <div *ngIf="modalSuccessMessage && hideViewAfterAPIfailure" class="id-card-success-message" data-uxd-alert-cmp-v2
        [alert]="{alertType: 'positive'}">
        <span id="id-card-modal-success-message" class="error-message" [innerHTML]="modalSuccessMessage"></span>
        <span class="fa fa-remove" (keyup.enter)="closeSuccessMessage()" (click)="closeSuccessMessage()"
          [attr.aria-label]="content.view.aria.closeButton" tabindex="0"></span>
      </div>
      <!-- ID Card header -->
      <div *ngIf="!idCardLoading && !errorMessage && transientIdCardService">
        <h2 class="section-header-two" id="id-card-header" [innerHtml]="content?.view?.header"></h2>
      
        <!-- ID Card instructions -->
        <p id="id-card-instructions" [innerHtml]="content?.view?.instructions"></p>
      </div>

      <div *ngIf="!idCardLoading && errorMessage === '' && transientIdCardService" class="row">
        <!-- Id Card options desktop -->
        <div class="icon and button container" id="navBar">
          <button *ngIf="hasPrint" class="icon-item" id="id-card-print-button" [attr.data-analytics]="content.view.analytics.print"
            [attr.aria-label]="content.view.aria.print + memberName" (click)="printIdCard()">
            <span class="motif-icon motif-print image-bg"></span>
            <span class="Stand-alone-link-wit" [innerHtml]="content.view.print"></span>
          </button>
          <button *ngIf="hasEmailId" class="icon-item" id="id-card-email-button" [attr.data-analytics]="content.view.analytics.email"
            [attr.aria-label]="content.view.aria.email + memberName" (click)="openEmailModal()">
            <span class="motif-icon motif-message image-bg"></span>
            <span class="Stand-alone-link-wit" [innerHtml]="content.view.email"></span>
          </button>
          <button *ngIf="hasFaxId" class="icon-item" id="id-card-fax-button" [attr.data-analytics]="content.view.analytics.fax"
            [attr.aria-label]="content.view.aria.fax + memberName" (click)="openFaxModal()">
            <span id="route-img">
              <img class="fax-img" role="img" [src]="'assets/images/idcards/send-fax-icon.png'" alt="fax-icon">
            </span>
            <span class="Stand-alone-link-wit" [innerHtml]="content.view.fax"></span>
          </button>
          <button *ngIf="hasMailId" class="icon-item" id="id-card-mail-button" [attr.data-analytics]="content.view.analytics.mail"
            [attr.aria-label]="content.view.aria.mail + memberName" (click)="openMailModal()">
            <span class="motif-icon motif-sent image-bg"></span>
            <span class="Stand-alone-link-wit" [innerHtml]="content.view.mail"></span>
          </button>

          <button *ngIf="hasDownload" class="icon-item" id="id-card-download-button" [attr.data-analytics]="content.view.analytics.download"
            [disabled]="loadingDownloadResult" [attr.aria-label]="content.view.aria.download + memberName"
            (click)="downloadIdCard()">
            <span class="motif-icon motif-download image-bg"></span>
            <span class="Stand-alone-link-wit" [innerHtml]="content.view.download"></span>
          </button>
        </div>

        <!-- Id Card options mobile -->
        <div class="card-dropdown col-xxs-12 col-sm-6">
          <div data-uxd-dropdown-cmp class="ant-md-select id-card-md-select" [(ngModel)]="idCardOption"
            (ngModelChange)="onNavOptionClick($event)" [attr.data-aria]="content.view.options.ariaLabel"
            [defaultText]="content.view.options.title" name="idCardOption" id="id-card-options"
            [items]="content.view.options.items" required>
          </div>
        </div>
      </div>

      <!-- Transient Id Card display -->
      <div *ngIf="idCardError && !transientIdCardService">
        <div class="transient-id-card-container" *ngIf= "!idCardLoading && errorMessage === ''">
          <div class="identification-number">{{content.transientIdCard.identificationNumber}}</div>
          <div class="transientDataId">{{transientIdCardResponse?.hcId}}</div> 
          <div class="idcard-medical">{{content.transientIdCard.medical}}</div>
          <div class="transientData">
            <div class="transientDataGroup">
              <span>{{content.transientIdCard.group}}</span>
              <span class="transientResponseGroupId">{{transientIdCardResponse?.groupId}}</span>
            </div>
            <div class="transientDataRxbin">
              <span>{{content.transientIdCard.rxBIN}}</span>
              <span class="transientResponserxBin">{{transientIdCardResponse?.rxInfo?.bin}}</span>
            </div>
            <div class="transientDataRxpcn">
              <span>{{content.transientIdCard.rxPCN}}</span>
              <span class="transientResponserxPcn">{{transientIdCardResponse?.rxInfo?.pcn}}</span>
            </div>
            <div class="transientDataRxgroup">
              <span>{{content.transientIdCard.rxGroup}}</span>
              <span class="transientResponserxGroup">{{transientIdCardResponse?.rxInfo?.group}}</span>
            </div>
            <div class="transientDataInfo">{{content.transientIdCard.info}}</div>
          </div>
        </div>
        
      </div>

      <!-- Id Card error message -->
      <div class="id-card-error-wrapper"
        *ngIf="(!idCardLoading && errorMessage) || (!loadingDownloadResult && downloadErrorMessage)">
        <div data-uxd-alert-cmp-v2 [alert]="{ alertType: 'negative' }" id="id-card-error">
          <span [innerHTML]="errorMessage || downloadErrorMessage"></span>
        </div>
      </div>

      <div *ngIf="!idCardLoading && errorMessage === '' && transientIdCardService">
        <hr>
        <!-- Id Card images -->
        <ng-container *ngTemplateOutlet="idcardImage"></ng-container>
      
        <!-- Id Card zoom & flip buttons -->
        <div class="idcard-options-container">
          <div class="option-container flip-container">
            <ng-container *ngTemplateOutlet="zoomFlipContainer"></ng-container>
          </div>
        </div>
      </div>

      <!-- Id card email modal -->
      <div data-uxd-modal-cmp #emailModal="modal" hidden class="modal fade"
        [modal]="{id: 'id-card-email-modal', title: content.email.modal.header}">
        <div class="modal-component-body">

          <form #emailForm="ngForm" name="emailForm" novalidate id="id-card-modal-email-form">

            <div class="floatLeft clearfix email-address-container">
                  <label for="myEmails" id="id-card-modal-mail-address">
                    {{content.email.modal.inputLabel}}
                  </label>
                  <input #input #emailAddr="ngModel" type="text" id="id-card-email-modal-emailText" name="myEmails"
                  class="ant-text-input ant-has-notes" sydmed-multiple-email-valid-dir [maxNumOfEmails]="3"
                  minlength="5" [(ngModel)]="memberEmailAddress" 
                  (keypress)="onEmailEnter($event)"
                  (blur)="onEmailEnter($event)" required/>
                  <div class="margin-bottom-0 clearfix vgr-margin-topbottom-1 email-instructions" id="id-card-email-modal-email-instruction"
            [innerHtml]="content.email.modal.inputInstructions"></div>
      <div *ngIf="emailAddr.errors" class="floatLeft clearfix email-address-container">
        
        <div class= "font-12 has-error" *ngIf=emailAddr.errors.required>
          <span class="motif-icon motif-delete motif-margin-right-3"></span>
          {{content.email.messages.required}}</div>
          <div class= "has-error" *ngIf=emailAddr.errors.validEmail>
            <span class="motif-icon motif-delete motif-margin-right-3"></span>
            {{content.email.messages.invalid}}</div>
            <div class= "has-error" *ngIf=emailAddr.errors.maxEmailExceeded>
              <span class="motif-icon motif-delete motif-margin-right-3"></span>
              {{content.email.messages.failureTooManyEmails}}</div>
            </div>
            </div>       

            <div class="email-message-container clearfix ">
              <div class="margin-top-14 item" id="id-card-email-modal-email-description" [innerHtml]="content.email.modal.modalBody"> </div>

              <div *ngIf="modalErrorMessage" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                <span id="id-card-modal-email-error-message" [innerHtml]="modalErrorMessage"></span>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-component-footer">
          <button data-lastfocus class="btn btn-secondary" [attr.aria-label]="content.email.modal.cancelButton"
            (mousedown)="closeEmailModal()" (click)="closeEmailModal()"
            [innerText]="content.email.modal.cancelButton"></button>
          &nbsp;
          <button class="btn btn-primary" id="id-card-email-modal-submit-btn"
            [attr.data-analytics]="content.email.modal.sendButtonAnalytics"
            [attr.aria-label]="content.email.modal.sendButtonAria"
            (click)="emailIdCardSubmit()"
            [disabled]="emailForm.invalid || loadingModalResult" [innerText]="content.email.modal.sendButton">
          </button>
        </div>
      </div>

      <!-- Id card fax modal -->
      <div data-uxd-modal-cmp #faxModal="modal" hidden class="modal fade"
        [modal]="{id: 'id-card-fax-modal', title: content.fax.modal.header}">

        <div class="modal-component-body">
          <form #faxForm="ngForm" [formGroup]="faxFormValue" name="faxForm" novalidate id="id-card-modal-fax-form">
            <!-- Fax Number -->
              <div class="floatLeft clearfix fax-address-container">
                <label for="faxNumber" id="id-card-modal-fax-address">{{content.fax.modal.inputFax}}</label>
                <input id="faxNumber" type="text" name="faxNumber" libNumbersOnly formControlName="memberFaxNumber"
                  maxlength="10" class="ant-text-input ant-input-long input-fax" id="id-card-fax-modal-faxText" required aria-required="true" />
        
                <div class="error-list motif-margin-bottom-2 font-12"
                  *ngIf="(memberFaxNumber.dirty || memberFaxNumber.touched || faxForm.submitted) && memberFaxNumber.invalid">
                  <div *ngIf="memberFaxNumber.errors.required; else showOtherErrors">
                    <span class="success"><i class="motif-icon motif-delete"></i>
                      <span class="motif-margin-left-4">
                        <span> {{content.fax.messages.faxRequired}}</span>
                      </span>
                    </span>
                  </div>
                  <ng-template #showOtherErrors>
                    <div *ngIf="memberFaxNumber.errors.minlength || memberFaxNumber.errors.maxlength">
                      <span class="success"><i class="motif-icon motif-delete"></i>
                        <span class="motif-margin-left-4">
                          <span>{{content.fax.messages.faxInvalid}}</span>
                        </span>
                      </span>
                    </div>
                  </ng-template>
                </div>
              </div>
            <!-- Recipient's Name -->
            <div class="floatLeft clearfix recipient-address-container">
              <label for="recipientName" class="motif-margin-top-5" id="id-card-modal-recipient-name">{{content.fax.modal.inputRecipient}}</label>
                <input id="recipientName" type="text" name="recipientName" formControlName="memberRecipientName"
                  class="ant-text-input ant-input-long width-100" required aria-required="true" />
        
                <div class="error-list motif-margin-top-1 font-12"
                  *ngIf="(memberRecipientName.dirty || memberRecipientName.touched || faxForm.submitted) && memberRecipientName.invalid">
                  <div *ngIf="memberRecipientName.errors.required">
                    <span class="success"><i class="motif-icon motif-delete"></i>
                      <span class="motif-margin-left-4">
                        <span> {{content.fax.messages.recipientRequired}}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
        
            <div class="fax-message-container clearfix ">
              <div class="margin-top-14 item" id="id-card-fax-modal-fax-description" [innerHtml]="content.fax.modal.modalBody">
              </div>
              <div *ngIf="modalErrorMessage" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                <span id="id-card-modal-fax-error-message" [innerHtml]="modalErrorMessage"></span>
              </div>
            </div>
        
          </form>
        </div>


        <div class="modal-component-footer">
          <button data-lastfocus class="btn btn-secondary" [attr.aria-label]="content.fax.modal.cancelButton"
            (mousedown)="closeFaxModal()" (click)="closeFaxModal()"
            [innerText]="content.fax.modal.cancelButton"></button>
          &nbsp;
          <button class="btn btn-primary" id="id-card-fax-modal-submit-btn"
            [attr.data-analytics]="content.fax.modal.sendButtonAnalytics"
            [attr.aria-label]="content.fax.modal.sendButtonAria"
            (click)="faxIdCardSubmit()"
            [disabled]="faxForm.invalid || loadingModalResult" [innerText]="content.fax.modal.sendButton">
          </button>
        </div>
      </div>

      <!-- Id card mail modal -->
      <div data-uxd-modal-cmp #mailModal="modal" hidden class="modal fade"
        [modal]="{id: 'id-card-mail-modal', title: content.mail.modal.header}">
        <div class="modal-component-body">
          <p id="id-card-modal-mail-instructions" [innerHtml]="content.mail.modal.instructions"></p>
          <div *ngIf="address" id="addressForm" class="address-form">
            <input type="radio" [checked]="true" name="addressRadio" id="id-card-modal-mail-address-radio"
              aria-labelledby="id-card-modal-mail-address"/>

            <label for="id-card-modal-mail-address-radio" id="id-card-modal-mail-address">
              <span [innerHtml]="address.streetAddress1"></span>
              <span [innerHtml]="address.streetAddress2"></span>&nbsp;

              <div [innerHtml]="address.city + ', ' + address.state + ' ' + address.zipCode"></div>
            </label>
          </div>
          <div class="card-error" *ngIf="modalErrorMessage || contactInfoErrorMessage">
            <div data-uxd-alert-cmp-v2 [alert]="{ alertType: 'negative' }">
              <span id="id-card-modal-mail-error-message" [innerHtml]="modalErrorMessage || contactInfoErrorMessage">
              </span>
            </div>
          </div>
        </div>

        <div class="modal-component-footer">
          <button data-lastfocus class="btn btn-secondary" id="id-card-mail-close-button"
            [attr.aria-label]="content.email.modal.cancelButton" (mousedown)="closeMailModal()" (click)="closeMailModal()"
            [innerText]="content?.mail.modal.cancelButton"></button>
          &nbsp;
          <button class="btn btn-primary" id="id-card-mail-modal-send-btn"
            [attr.data-analytics]="content.email.modal.sendButtonAnalytics"
            [attr.aria-label]="content.email.modal.sendButtonAria" (click)="mailIdCardSubmit()"
            [disabled]="loadingModalResult || !address" [innerText]="content.mail.modal.sendButton"></button>
        </div>
      </div>
    </div>

    

    <ng-template #dashboardIDCard>
      <div *ngIf="!idCardLoading" class="dashboard-idcard-container">
        <div class="member-status">
          <span id="member-status-span" [class]="isTermedMember ? 'inactive' : 'active'">{{isTermedMember? content.view.inactive : content.view.active}}</span>
        </div>
        <!-- ID Card-->
        <div *ngIf="idCardFront && idCardBack && !isTermedMember; else newTransientIdCard">
          <!-- Id Card images -->
          <div class="dashboard-id-card">
            <div class="block">
              <div class="front side">
                <img class="id-card-image" id="id-card-front-image" [src]="idCardFront" alt="ID card image front" />
              </div>
              <div class="back side">
                <img class="id-card-image" iid="id-card-back-image" [src]="idCardBack" alt="ID card image back" />
              </div>
            </div>
          </div>
        
          <!-- Id Card zoom & flip & Get your ID card buttons -->
          <div class="idcard-options-container">
            <div class="option-container get-your-id-card">
              <a class="get-your-id-card-link" [attr.data-analytics]="content.view.analytics.getYourIdCard"
                href="javascript:void(0)" (click)="navigateToGetIdCard()" [attr.aria-label]="content.view.aria.getYourIdCard + memberName">
                <img src="assets/images/idcards/Right-Round.svg"/>
                <span [innerHtml]="content.view.getYourIdCard"> </span>
              </a>
            </div>
            <div class="option-container flip-container">
              <ng-container *ngTemplateOutlet="zoomFlipContainer"></ng-container>
            </div>
          </div>
        </div>

        <ng-template #newTransientIdCard>
          <div *ngIf="!idCardLoading">
            <div class="wrapper">
              <div *ngIf="!transientIdCardService && memberData; else idCardNotAvailableOrTermedMember">
                <div class="transient-id-card">
                  <div class="idcard-not-available">
                    <span>{{content.transientIdCard.idCardNotAvailable}}</span>
                    <div class="separator"></div>
                  </div>
                  <div class="member-info">
                    <div class="col">
                      <div class="group">
                        <span>{{content.transientIdCard.name}}</span>
                        <span>{{memberData.firstName}} {{memberData.lastName}}</span>
                      </div>
                      <div class="group">
                        <span>{{content.transientIdCard.groupNumber}}</span>
                        <span>{{memberData.groupId}}</span>
                      </div>
                    </div>
                  <div class="col">
                      <div class="group">
                        <span>{{content.transientIdCard.memberId}}</span>
                        <span>{{memberData.hcid}}</span>
                      </div>
                      <div class="group">
                        <span>{{content.transientIdCard.coverageType}}</span>
                        <span>{{content.transientIdCard.medical}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #idCardNotAvailableOrTermedMember>
                <div *ngIf= "isTermedMember; else idCardNotAvailable" class="error-container" >
                  <img src="assets/images/idcards/InvalidMedicalCard.svg"/>
                  <span class="title">{{content.errorMsgs.inactiveIdCard}}</span>
                  <span class="message">{{content.errorMsgs.inactiveIdCardDesc}}</span>
                </div>
  
                <ng-template #idCardNotAvailable>
                  <div class="error-container" >
                    <img src="assets/images/idcards/DisplayIssue.svg"/>
                    <span class="title">{{content.errorMsgs.newTransientLoadingError}}</span>
                    <span class="message" [innerHTML]="content.errorMsgs.newTransientLoadingErrorDesc"></span>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
    
    <ng-template #idcardImage>
      <div class="id-card">
        <div class="block">
          <div class="front side" *ngIf="idCardFront && !errorMessage">
            <img class="id-card-image" id="id-card-front-image" [src]="idCardFront" alt="ID card image front" />
          </div>
          <div class="back side">
            <img class="id-card-image" iid="id-card-back-image" [src]="idCardBack" alt="ID card image back" />
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #zoomFlipContainer>
      <a class="zoomlink" id="id-card-zoom-link" [attr.data-analytics]="content.view.analytics.zoom"
        href="javascript:void(0)" (click)="openZoomModal()" [attr.aria-label]="content.view.aria.zoom + memberName">
        <span class="fa fa-search-plus salmon fa-lg"></span>
        <span [innerHtml]="content.view.zoom"> </span>
      </a>
  
      <a class="flip-image" id="id-card-flip-card" [attr.data-analytics]="content.view.analytics.flip"
        href="javascript:void(0)" (click)="flipCard(flipped)" [attr.aria-label]="content.view.aria.flip + memberName">
        <span class="fa fa-refresh salmon fa-lg"> </span>
        <span [innerHtml]="content.view.flip"> </span>
      </a>
    </ng-template>
  </div>

  <!-- Id card content error modal -->
  <ng-template #loadingOrError>
    <div *ngIf="genericError; else loading" class="container" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negetive'}">
      <span id="id-card-content-error" [innerHTML]="genericError"></span>
    </div>
    <ng-template #loading>
      <div data-uxd-data-loader-cmp="" class="container">
        <span [innerHTML]="'Loading... please wait.'"></span>
      </div>
    </ng-template>
  </ng-template>
</div>
