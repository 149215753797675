import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {  MetaDataHelper } from "@anthem/mbrportal/main/public_api";
import {  ScriptLoader } from "@anthem/mbrportal/shared/public_api";
import { Subscription, forkJoin } from "rxjs";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { FindCareService } from "./services/findCare.service";
import { FindCareMetadataResponse } from "gbd-models";
import { environment } from 'sydmed/src/environments/environment'
import { InlineAlertContainerComponent } from "@anthem/uxd/alert/inline-alert/inline-alert-container/inline-alert-container.component";
import { AlertService, UniversalAlertType } from "@anthem/uxd/alert";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/public-api";
import { ErrorMsgs, FindCare } from "../shm-my-profile/models/labels";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/public-api";


@Component({
  moduleId: module.id,
  selector: "find-care-component",
  templateUrl: "./find-care.component.html",
  styleUrls: ["./find-care.component.scss"],
})


export class FindCareComponent implements OnInit, OnDestroy  {
  @Input()
  pfVersion: string = '';
  @Input()
  pfSelectedContractUid: string = '';
  content: any;
  genericError: any;
  isPFLoaded: boolean = false;
  pfPage: string = 'FAD';
  public isLoading: boolean = true;
  isError: boolean = false;
  isSecure: boolean = false;
  alphaPrefix: string = '';
  selectedMbrUid: string = '';
  selectedContractUid: string = '';
  medicareLink: string = '';
  memberContext: any;
  public error: string;
  public errorMsgs: ErrorMsgs;
  public jsonLabels: FindCare;
  @ViewChild('pfContainerElement', { static: false }) public pfElement: ElementRef;
  @ViewChild('findCareErrorAlert') findCareErrorAlert: InlineAlertContainerComponent;
  protected _subscription: Subscription;
  private el: HTMLElement;
  private metadata: string;
  isPcpFlow: string;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL
  constructor(
    private _scriptLoader: ScriptLoader,
    private findCareMetaDataSvc: FindCareService,
    private jsonSvc: JsonContentService,
    private alertSvc: AlertService,
    private errCodeMapSvc: ErrorCodeMapperService,
    private interCommunicationService: InterCommunicationService,

    @Inject(DOCUMENT) private document: Document,
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Find Care',
    });
  }

    ngOnInit() {
    this.getLabels(); 
    }

    getLabels() {
      forkJoin([this.jsonSvc.getJSON('find-care'),this.jsonSvc.getJSON('error-messages')]).subscribe((jsonResults) => {
        this.jsonLabels = jsonResults[0].findCare;
        this.errorMsgs = jsonResults[1].ErrorMsgs;
        this.loadSecure();
      });
    }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this.removePfElementReference();
  }
 
  removePfElementReference() {
    if (this.pfElement) {
      if (typeof this.pfElement.nativeElement.remove === 'function') {
        this.pfElement.nativeElement.remove();
      } else {
        if (this.pfElement && this.pfElement.nativeElement && this.pfElement.nativeElement.parentNode) {
          this.pfElement.nativeElement.parentNode.removeChild(this.pfElement.nativeElement);
        }
      }
    }
  }
 
 
  loadSSO(metadata: unknown) {
      this.loadPFLib().then(
        () => {
          this.isPFLoaded = true;
          setTimeout(() => {
            this.createCustomPfElement(metadata as string);
          }, 100);
        },
        () => {
          this.isError = true;
        }
      );
  }
  clearErrorAlert():void {
    this.findCareErrorAlert.dismissAlertId(0);
  }

 
  loadSecure() {
    this.findCareMetaDataSvc.getFindCareMetadata().subscribe(
      (res: any) => {
        this.isLoading = false;
        this.metadata = this.getMetaDataStr(res?.body);
        this.loadSSO(this.metadata);
      },
      (err) => {
        this.isLoading = false;
        this.isError = true;
        this.error = this.errCodeMapSvc.errorCodeMapper(err, this.errorMsgs);
        this.findCareServiceErrorHandler(this.error);

      }
    );
  }

  //These are callback for the custom events from find a doctor page which will change in futre
  loadStart(e: unknown) {
    this.isLoading = false;
  }
 
  loadSuccess(e: unknown) {
    //TODO: Should load the PF screen
  }

  findCareServiceErrorHandler (error: string):void {
    if (error && this.errorMsgs) {
      this.alertSvc.error(error, {
        regionName: 'find-care-error-alert',
        alertListType: 'queue',
        politeness: 'assertive'
      });
      this.scrollToTop();
    }
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
 
  loadError(_e: unknown) {
    this.clearErrorAlert();
    this.isLoading = false;
    const e = _e as { detail: { type: string } };
    if (e && e.detail && e.detail.type && e.detail.type === 'PF_APP_LOAD_ERR') {
      this.isError = true;
      this.error = this.errCodeMapSvc.errorCodeMapper(e, this.errorMsgs);
      this.findCareServiceErrorHandler(this.error);
    }
  }
 
  loadPFLib() {
    return this._scriptLoader.loadExternalScript(environment.pfLoaderUrl, 'pfLoader');
  }


  getMetaDataStr(result: FindCareMetadataResponse) {
    let metaData: string = '';
    let dpUrl: string = 'fad.search-providers';
    this.memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    if (result) {
      metaData = '{';
      result ? (metaData += '"brand": "' + this.memberContext.marketingBrand + '",') : (metaData += '"brand": "ABCBS",');
      result.hcid? (metaData += '"hcid": "' + result.hcid + '",') : (metaData += '"hcid": "",');
      result.mbrUid
        ? (metaData += '"mbrUid": "' + (result.mbrUid) + '",')
        : (metaData += '"mbrUid": "",');
      result.username ? (metaData += '"userName": "' + result.username + '",') : (metaData += '"userName": "",');
      result.firstName ? (metaData += '"firstName": "' + result.firstName + '",') : (metaData += '"firstName": "",');
      result.lastName ? (metaData += '"lastName": "' + result.lastName + '",') : (metaData += '"lastName": "",');
      result.groupId ? (metaData += '"groupId": "' + result.groupId + '",') : (metaData += '"groupId": "",');
      result.sourceSystem ? (metaData += '"sourceSystem": "' + result.sourceSystem + '",') : (metaData += '"sourceSystem": "",');
      (metaData += '"idToken": "' + result.idToken + '",') ?? (metaData += '"idToken": "",');
      metaData += '"isSecureState": true,';
      metaData += '"isWebView": false,';
      metaData += '"locale": "' + this.getLocale() + '",';
      dpUrl ? (metaData += '"dplUrl": "' + this.jsonLabels?.dplUrl + '"') : (metaData += '"dplUrl": ""');
      metaData += ' }';
    }
    return metaData;
  }

 
    createCustomPfElement(metadata: string) {
    this.el = document.createElement('pf-element');
    this.el.setAttribute('metadata', metadata);
    this.el.addEventListener('onPfAppError', (e: unknown) => {
      this.loadError(e);
    });
    this.el.addEventListener('onPfAppLoadSuccess', (e: unknown) => {
      this.loadSuccess(e);
    });
    this.el.addEventListener('onPfAppLoadStart', (e: unknown) => {
      this.loadStart(e);
    });
    this.pfElement.nativeElement.appendChild(this.el);
  }

  getLocale() {
    if(sessionStorage.getItem("localeOverride") === 'es-US') {
      return "es_US";
    }
    // default to english
    return "en_US";
  }
}
