import { Component, forwardRef, OnDestroy, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, ControlValueAccessor, Validators, NG_VALIDATORS, FormControl, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';

export interface PasswordValues {
  password: string;
  confirmPassword: string;
}

const MatchPasswordsValidator: ValidatorFn = (fg: FormGroup) => {
  const password = fg.get('password').value;
  const confirmPassword = fg.get('confirmPassword').value;
  if(password === '' && confirmPassword === null) {
    return null;
  } else {
    return password === confirmPassword
    ? null
    : { notMatched: true };
  }
};

@Component({
  selector: 'lib-passwords-form',
  templateUrl: './pwd-retype-pwd.component.html',
  styleUrls: ['./pwd-retype-pwd.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PwdRetypePwdComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PwdRetypePwdComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PwdRetypePwdComponent implements ControlValueAccessor, OnDestroy, OnChanges {
  form: FormGroup;
  subscriptions: Subscription[] = [];
  showTooltips: boolean = false;
  showTooltipErrOnBlur: boolean = false;

  @Input('pwdLabel') pwdLabel: string;
  @Input('confirmPwdLabel') confirmPwdLabel: string;
  //@Input('errObj') errorsObj: object; //TODO
  @Input('errObj') errorsObj: any;  
  @Input('toolTipObj') toolTipObj: any;
  @Input('formSubmitted') formSubmitted: boolean;
  @Input('userName') user: string;

  @ViewChild('pwdForm') pwdForm: any;

  get value(): PasswordValues {
    return this.form.value;
  }

  set value(value: PasswordValues) {
    this.form.setValue(value);    
    this.onChange(value);
    this.onTouched();
  }

  get password() {
    return this.form.controls.password;
  }

  get confirmPassword() {
    return this.form.controls.confirmPassword;
  }

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), FormValidators.OneUppercaseValidator, FormValidators.OneLowercaseValidator, FormValidators.OneNumberValidator, FormValidators.OneSpecialCharValidator, FormValidators.NoSpaceValidator, FormValidators.PwdConsecutiveCharValidator, FormValidators.PwdInvalidCharValidator]],
      confirmPassword: ['', Validators.required]
    },
    { validator: [MatchPasswordsValidator, this.NotMatchUserNameValidator, this.ConsecCharOfUserName]},
    );

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { passwords: { valid: false, }, };
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.formSubmitted && changes.formSubmitted.currentValue && this.form.invalid) {
      this.formSubmitted = changes.formSubmitted.currentValue;
      this.form.markAllAsTouched();
    }
  }

  reset() {
    this.pwdForm.resetForm();
    this.form.reset();
  }

  private NotMatchUserNameValidator: ValidatorFn = (fg: FormGroup) => {
    const password = fg.get('password')? fg.get('password').value : ''; 

    let validationErr = null;

    if(password === '' && this.user === '') {
      return null;
    } else {
      validationErr = this.validateUNMatchPassword(password);
    }

    return validationErr;
  };

  validateUNMatchPassword(password) {
    return ((password ? password.toLowerCase(): '') !== (this.user ? this.user.toLowerCase() : '')) ? null : { userNameMatched: true};
  }

  private ConsecCharOfUserName: ValidatorFn = (fg: FormGroup) => {
    const password = fg.get('password')? fg.get('password').value : ''; 

    let validationErr = null;
    if(password === '' || this.user === '') {
      return null;
    } else {
      validationErr = this.validateUNConsecutiveCharOfPassword(password);
    }

    return validationErr;
  };

  validateUNConsecutiveCharOfPassword(password) {
    let valid: boolean = true;
    let subStr: string = '';

    if(password && this.user) {
      const userName = this.user;
      this.user.split('').forEach(function(value1, key1) {
        if (key1 + 3 > userName.length) {
          return;
        }
        subStr = userName.substring(key1, key1 + 3).toLowerCase();
        if (password.toLowerCase().indexOf(subStr) >= 0) {
          valid = false;
          return;
        }
      });
    }
    return valid ? null : { consecChars: { valid } };
  }

  onFocus() {
    this.form.updateValueAndValidity();
    this.showTooltips = true;
    this.showTooltipErrOnBlur = false;
  }

  onBlur() {
    this.showTooltips = false;
    this.showTooltipErrOnBlur = true;
  }
}
