<div class="" *ngIf="labels">
    <div class="container lr-pad">
        <div class="row header-section">
            <a routerLink="/secure/make-payment" class="ant-action-link" [attr.aria-label]="headerJson.ManagePayments">
                <span class="motif-icon motif-arrows-long-left"></span>
                {{headerJson.ManagePayments}}
            </a>
        </div>
    </div>
    <hr class="header-border">
    <div class="confirmation-print-details" #confirmationPrintBlock>
        <div class="print-only" aria-hidden="true">
            <h3>{{labels.HeaderMessage}}</h3>
        </div>
        <div class="container lrt-pad">
            <div class="row">
                <h2 class="section-header-two">{{labels.ConfirmationTitle}}</h2>
                <p class="description-text">{{labels.ConfirmationDesc}}</p>
            </div>
            <div class="row print-link no-print">
                <a *ngIf="!isMobile" href="javascript:void(0);" (click)="printOrDownload()" class="ant-action-link"><span class="motif-icon motif-print"></span> {{labels.ConfirmationPrint}}</a>
            </div>
        </div>
        <div class="container motif-margin-0">          
            <div class="display-block" *ngIf="isPaymentsError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                <span>{{errorMsgs.GeneralSystemErrorWithoutCode}}</span>
            </div>
        </div>
        <div class="container lrt-pad">
            <div class="row motif-margin-top-6">
                <hr class="header-border">
                <span class="motif-icon motif-ok confirmation-tick"></span>
                <h3 class="confirm-h3">{{labels.ConfirmationNumber}} <span class="confirmation-number">{{selectedBill?.confirmationNumber}}</span></h3>
            </div>
        </div>
        <div class="container">
            <div class="row ml-2 bill-row">
                <div class="col-md-2 print-cell">
                    <h4 role="columnheader">{{labels.AccountType}}</h4>
                    <p>{{selectedBill?.accountType}}</p>
                </div>
                <div class="col-md-2 print-cell">
                    <h4 role="columnheader">{{labels.BenefitPeriod}}</h4>
                    <p>{{selectedBill?.coverageStartDt | date}} - {{selectedBill?.coverageEndDt | date}}</p>
                </div>
                <div class="col-md-2 print-cell">
                    <h4 role="columnheader">{{labels.MemberId}}</h4>
                    <p>{{selectedBill?.memberId}}</p>
                </div>
                <div class="col-md-2 print-cell">
                    <h4 role="columnheader">{{labels.AmountPaid}}</h4>
                    <p>{{selectedBill?.amount | currency:'USD'}}</p>
                </div>
                <div class="col-md-2 print-cell">
                    <h4 role="columnheader">{{labels.ConfirmationPaymentMethod}}</h4>
                    <p>{{selectedBill?.paymentType}}</p>
                </div>
                <div class="col-md-2 print-cell">
                    <h4 role="columnheader">{{labels.PaidOn}}</h4>
                    <p>{{selectedBill?.paidOn}}</p>
                </div>
            </div>
        </div>
    </div>
</div>