<div class="uxd-theme-motif container">
    <div class="row vertical-center">
        <div class="full-width-alert-spacer error-alert-spacer" *ngIf="aslError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{aslError}}</span>
        </div>
        <div class="account-details-container" *ngIf="!isLoading">
                <h1 *ngIf="showRegistrationHeader" tabindex="-1" class="text-left font-32 margin-bottom-48">{{validToken ?
                    jsonLabels.Non_Hcid_Page_Heading : jsonLabels.Page_Heading}}</h1>
                <form [formGroup]="AccountDetailsForm" (ngSubmit)="checkEligibility()" id="regStep1Form"
                    name="regStep1Form">

                    <div class="col-xxs-12">
                        <div class="row partial-row">
                            <ng-container *ngIf="!validToken">
                                <div class="col-xxs-12" *ngIf="isAnthem">
                                    <div class="">
                                        <div class="form-item margin-bottom-40" *ngIf="idTypesDropdown">
                                            <div class="flex-vertical-center">
                                                <label class="margin-bottom-0"
                                                    for="lgSelect">{{idTypesDropdown?.title}}</label>
                                                <button class="border-none background-none"
                                                    [attr.aria-label]="idTypesDropdown?.title + ' tooltip'" type="button"
                                                    (click)="open()">
                                                    <span class="motif-icon motif-help"></span>
                                                </button>
                                            </div>
                                            <div #lgSelect="ngModel" data-uxd-dropdown-cmp [(ngModel)]="idType"
                                                class="form-item width-100" [attr.aria-label]="idTypesDropdown?.title"
                                                name="lgSelect" id="lgSelect" [items]="idTypesDropdown?.options"
                                                [isRequired]="true" required [ngModelOptions]="{standalone: true}"
                                                (click)="onChangeInput()" (keyup)="onChangeInput()">
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-xxs-12">
                                    <div class="form-item margin-bottom-40">
                                        <div class="flex-vertical-center margin-bottom-6">
                                            <label id='lbl_txtIdNo' class="margin-bottom-0"
                                                [for]="idType">{{idLabel}}</label>
                                            <button *ngIf="!isAnthem"
                                                class="border-none background-none flex-vertical-center"
                                                [attr.aria-label]="idLabel + ' tooltip'" type="button" (click)="open()">
                                                <span class="motif-icon motif-help"></span>
                                            </button>
                                        </div>
                                        <div class="mask-wrapper">
                                            <div *ngIf="idType === 'hcid'">
                                                <input #hcid (change)="onChangeInput(hcid.value)" type="password" id="hcid"
                                                    aria-required="true" class="ant-input-long ant-text-input"
                                                    AlphaNumericOnly name="hcid" formControlName="hcid"
                                                    aria-describedby="idError">
                                                <span (click)="showHide(hcid, idLabel)"
                                                    (keyup.enter)="showHide(hcid, idLabel)" id="btnidmask" tabindex="0"
                                                    role="button" aria-pressed="false"
                                                    [attr.aria-label]="'unmask ' + idLabel"
                                                    class="fa md ant-info-icon ant-data-security-mask fa-eye-slash"><span
                                                        class="sr-only">{{jsonLabels?.Field_Labels.MaskIDCard}}</span></span>
                                            </div>
    
    
                                            <div *ngIf="idType === 'planid'">
                                                <input #planid (change)="onChangeInput(planid.value)" type="password"
                                                    id="planid" aria-required="true" class="ant-input-long ant-text-input"
                                                    AlphaNumericOnly name="planid" formControlName="planid"
                                                    aria-describedby="idError">
                                                <span (click)="showHide(planid, idLabel)"
                                                    (keyup.enter)="showHide(planid, idLabel)" id="btnidmask" tabindex="0"
                                                    role="button" aria-pressed="false"
                                                    [attr.aria-label]="'unmask ' + idLabel"
                                                    class="fa md ant-info-icon ant-data-security-mask fa-eye-slash"><span
                                                        class="sr-only">{{jsonLabels?.Field_Labels.MaskIDCard}}</span></span>
                                            </div>
                                        </div>
    
                                        <ul id="idError" class="ant-bar-chart-group text-left font-12 margin-top-14 error"
                                            *ngIf="(AccountDetailsForm.controls[idType]?.touched && AccountDetailsForm.controls[idType]?.invalid)"
                                            aria-live="polite">
                                            <li *ngIf="AccountDetailsForm.controls[idType]?.errors?.required">
                                                <div>
                                                    <span class="fas fa-times font-14 margin-right-8"></span>
                                                    <span>{{idLabel}}</span>
                                                    <span>{{jsonErrors.Required_err}}</span>
                                                </div>
                                            </li>
                                            <li *ngIf="AccountDetailsForm.controls[idType]?.errors?.minlength 
                                                    || AccountDetailsForm.controls[idType]?.errors?.maxlength">
                                                <span class="fas fa-times font-14 margin-right-8"></span>
                                                <span>{{idLabel}}</span>
                                                <span>{{jsonErrors.Password_Len_err}}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="col-xxs-12">
                                <div class="form-item margin-bottom-40">
                                    <label id="lbl_txtDob"
                                        for="dateOfBirth">{{jsonLabels?.Field_Labels.Date_of_Birth}}</label>
                                    <input (keyup)="onChangeInput()" type="text" name="dateOfBirth" aria-required="true"   
                                        autocomplete="off" id="dateOfBirth" maxlength="10"
                                        class="ant-input-long ant-text-input" formControlName="dateOfBirth"
                                        libDateFormat [preValue]="AccountDetailsForm.value.dateOfBirth"
                                        maxlength="10" aria-describedby="dobError">

                                    <ul id="dobError" class="ant-bar-chart-group text-left font-12 margin-top-14 error"
                                        *ngIf="(AccountDetailsForm.controls['dateOfBirth']?.touched) && AccountDetailsForm.controls['dateOfBirth']?.invalid" aria-live="polite">
                                        <li *ngIf="AccountDetailsForm.controls['dateOfBirth']?.errors.required">
                                            <span class="fas fa-times font-14 margin-right-8"></span>
                                            <span>{{jsonLabels?.Field_Labels.Date_of_Birth}}</span>
                                            <span>{{jsonErrors.Required_err}}</span>
                                        </li>
                                        <li *ngIf="AccountDetailsForm.controls['dateOfBirth']?.errors.invalidDate">
                                            <span class="fas fa-times font-14 margin-right-8"></span>
                                            <span>{{jsonErrors.DOB_Format_err}}</span>
                                        </li>
                                        <li
                                            *ngIf="AccountDetailsForm.controls['dateOfBirth']?.errors.futureDate && !AccountDetailsForm.controls['dateOfBirth']?.errors.invalidDate">
                                            <span class="fas fa-times font-14 margin-right-8"></span>
                                            <span>{{jsonErrors.DOB_Future_err}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="col-xxs-12">
                        <div class="row partial-row">
                            <div class="col-xxs-12">
                                <div class="form-item margin-bottom-40">
                                    <label class="text-left" id="lbl_txtFirstName"
                                        for="firstName">{{jsonLabels?.Field_Labels.First_Name}}</label>
                                    <input (change)="onChangeInput()" type="text" id="firstName" name="firstName" aria-required="true"   
                                        class="ant-input-long ant-text-input" formControlName="firstName"
                                        autocomplete="off" aria-describedby="firstNameError">

                                    <ul id="firstNameError" class="ant-bar-chart-group text-left font-12 margin-top-14 error"
                                        *ngIf="(AccountDetailsForm.controls['firstName']?.touched) && AccountDetailsForm.controls['firstName']?.invalid" aria-live="polite">
                                        <li *ngIf="AccountDetailsForm.controls['firstName']?.errors.required">
                                            <span class="fas fa-times font-14 margin-right-8"></span>
                                            <span>{{jsonLabels?.Field_Labels.First_Name}}</span>
                                            <span>{{jsonErrors.Required_err}}</span>
                                        </li>
                                        <li
                                            *ngIf="AccountDetailsForm.controls['firstName']?.errors.invalidnamechar">
                                            <span class="fas fa-times font-14 margin-right-8"></span>
                                            <span>{{jsonLabels?.Field_Labels.First_Name}}</span>
                                            <span>{{jsonErrors.Lastname_Invalid_err}}</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div class="col-xxs-12">
                                <div class="form-item margin-bottom-40">
                                    <label class="text-left" id="lbl_txtLastName"
                                        for="lastName">{{jsonLabels?.Field_Labels.Last_Name}}</label>
                                    <input (change)="onChangeInput()" type="text" id="lastName" name="lastName" aria-required="true"   
                                        class="ant-input-long ant-text-input" formControlName="lastName"
                                        autocomplete="off" aria-describedby="lastnameError">

                                    <ul id="lastnameError" class="ant-bar-chart-group text-left font-12 margin-top-8 error"
                                        *ngIf="(AccountDetailsForm.controls['lastName']?.touched) && AccountDetailsForm.controls['lastName']?.invalid" aria-live="polite">
                                        <li *ngIf="AccountDetailsForm.controls['lastName']?.errors.required">
                                            <span class="fas fa-times font-14 margin-right-8"></span>
                                            <span>{{jsonLabels?.Field_Labels.Last_Name}}</span>
                                            <span>{{jsonErrors.Required_err}}</span>
                                        </li>
                                        <li *ngIf="AccountDetailsForm.controls['lastName']?.errors.invalidnamechar">
                                            <span class="fas fa-times font-14 margin-right-8"></span>
                                            <span>{{jsonLabels?.Field_Labels.Last_Name}}</span>
                                            <span>{{jsonErrors.Lastname_Invalid_err}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-left col-xxs-12 margin-bottom-24" id="termsCheckbox" *ngIf="isRegistration">
                        <div class="row inline">
                            <div data-uxd-checkbox-input-cmp [definition]="checkbox" formControlName="acceptedTandC"
                                class="checkbox-margin" [optionalAriaLabel]="jsonLabels?.Field_Labels.Agree + ' ' + jsonLabels?.Field_Labels.Terms_of_Use">
                            </div>
                            &nbsp;<a #ToULink id="ToULink" class="registration-link"
                                href="{{jsonLabels?.Terms_Of_Use_Link}}">{{jsonLabels?.Field_Labels.Terms_of_Use}}</a>
                        </div>
                        <ul id="termsOfUseError" class="ant-bar-chart-group font-12 text-center error"
                            *ngIf="(AccountDetailsForm.controls['acceptedTandC']?.touched) && AccountDetailsForm.controls['acceptedTandC']?.invalid" role="alert">
                            <li class="text-left"
                                *ngIf="AccountDetailsForm.controls['acceptedTandC']?.errors.required">
                                <span class="fas fa-times font-14 margin-right-8"></span>
                                <span>{{jsonErrors.TermsOfUse_err}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="text-left margin-bottom-48" *ngIf="recaptchaEnabled">
                        <label for="g-recaptcha-response" hidden>{{jsonLabels?.Recaptcha}}</label>
                        <div #recaptcha id="recaptcha"></div>
                    </div>

                    <div class="text-right margin-bottom-70 button-group inline col-xxs-12">
                        <div class="text-left">
                            <span class="font-16">{{jsonLabels?.Registered}}</span>
                            <div class="margin-top-8">
                                <a routerLink="/public/login" class="registration-link text-left"
                                    data-analytics="loginLinkReg" [attr.aria-label]="jsonAriaLabel">
                                    <i class="motif-icon motif-right-round vertical-center font-24"></i>
                                    <span class="font-16">{{jsonLabels?.Login}}</span>
                                </a>
                            </div>
                        </div>
                        <div class="text-right margin-left-auto">
                            <button class="btn" type="submit" id="btnNext" class="btn btn-primary"
                                data-analytics="nextPersonalInfoReg">{{jsonLabels?.Next_Button_Text}}</button>
                        </div>
                    </div>
                </form>
        </div>
    </div>
</div>

<ng-template #modalTemplate>
    <div class="id-card-examples-modal">
        <div class="text-right">
            <button class="close-button" aria-label="Close Modal" (click)="onClose()">
                <span aria-hidden="true" class="motif-icon motif-delete"></span>
            </button>
        </div>
      <div>
        <h2 class="font-22 modal-margins margin-bottom-16 header">{{jsonLabels?.Member_ID.Header}}</h2>
      </div>
      <div class="">
        <p class="font-14 modal-margins margin-bottom-24">{{jsonLabels?.Member_ID.Member_ID_Content}}</p>
        <img class="modal-margins margin-bottom-32" #cardImg id="cardImg" src="{{jsonLabels?.Member_ID.Example_Id_Card_Link}}" alt="ID Card Example">
    </div>
    </div>
  </ng-template>

  <div *ngIf="isLoading">
    <uxd-spinner></uxd-spinner>
  </div>