
import { Component, OnInit } from '@angular/core';
import { AuthEventsService } from 'sydmed/libs/authorization/authevents.service';
import { Router } from '@angular/router';
import { SessionStorage, StorageService} from 'sydmed/libs/storage-service/src/public-api';

declare var _satellite: any;

@Component({
  selector: 'app-secure-login',
  templateUrl: './secure-login.component.html',
  styleUrls: ['./secure-login.component.css']
})
export class SecureLoginComponent implements OnInit {

  constructor(
    private eventSvc: AuthEventsService,
    private router: Router,
    private storage: StorageService
  ) { }


  ngOnInit(): void {
    this.eventSvc.updateMenu(window.sessionStorage.getItem('sydMedLoggedIn').toString());
    if (typeof _satellite !== 'undefined') {
      _satellite.track('loginSuccess');
    }
    sessionStorage.removeItem('gbdsmc');
    sessionStorage.removeItem('recoveryModel');
    sessionStorage.removeItem('contactsModel');
    let nextURL = this.storage.getSessionItem<string>(SessionStorage.NEXT_URL);
    if (nextURL) {
      this.router.navigateByUrl(nextURL);
    } else {
      this.router.navigate(['/secure/dashboard']);
    }
  }
}
