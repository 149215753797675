<div class="motif-theme">
  <div class="motif-margin-right-0 motif-margin-left-0 mobile-padding" *ngIf="content; else LoadingOrContentError">
    <div class="motif-margin-bottom-4" >
      <div class="motif-margin-bottom-5">
        <h2>{{content.headings.pageTitle}}</h2>
      </div>
      <div>
        <p>{{content.headings.pageSubheading}}</p>
      </div>
    </div>
    <div class="row" *ngIf="isLoading">
      <div id="search-loader">
        <uxd-spinner ></uxd-spinner>
      </div>      
    </div>
     
    <div id="search-wrapper" class="form-item" *ngIf="!isLoading">
      <form [formGroup]="searchForm">
        <div class="col-md-4 col-lg-4 col-sm-6 mobile-padding motif-pad-left-0">
          <label  [ngClass]="(invalidCityState || outOfStateZip || invalidData || invalidSearchTerm)? 'invalid-label':'valid-label'" for="pharmacy-search-field">{{content.searchForm.searchForLocation}}</label>
          <input id="pharmacy-search-field" name="pharmacy-search-field" formControlName="searchField" 
            type="text" (keyup.enter)="onEnter($event)"
            placeholder="{{content.searchForm.searchPlaceHolder}}" class="ant-text-input"
          />
          <button *ngIf="showSearchIcon" class="search-submit" type="button" (click)="search($event)">
            <span id="searchIcon">
              <i class='motif-icon motif-search-1 span.fa'></i>
            </span>
          </button>
          <button *ngIf="!showSearchIcon" class="search-cancel" type="button" (click)="cancelSearch($event)">
            <span id="cancelSearchIcon" tabindex="0">
              <i class='motif-icon motif-cancel span.fa'></i>
            </span>
          </button>
          <div *ngIf="showIsRequired(controlNames.searchField) && (!invalidCityState  && !outOfStateZip && !invalidData && !invalidSearchTerm ) && !(pharmacyList && pharmacyList.length>=0)">
            <div class="required-message motif-pad-top-5">
              <span class="motif-icon motif-delete motif-margin-right-5"></span>
              <span>{{content.searchForm.validationMessage}}</span>
            </div>
          </div>
          <div *ngIf="invalidCityState">
           <div class="required-message motif-pad-top-5">
            <span class="motif-icon motif-delete motif-margin-right-5 "></span>
            <span>{{content.ErrorMsgs.InvalidStateandCity}}</span>
            </div>
          </div>
          <div *ngIf="invalidSearchTerm || invalidData">
           <div class="required-message motif-pad-top-5">
            <span class="motif-icon motif-delete motif-margin-right-5"></span>
            <span>{{content.ErrorMsgs.NoDataFound}}</span>
            </div>
          </div>
          <div *ngIf="isApiCalled">
          <div *ngIf="((requestObject.city && requestObject.state) || requestObject.zipCode) && ( outOfStateZip && !invalidSearchTerm && !invalidData)">
           <div class="required-message motif-pad-top-5">
            <span class="motif-icon motif-delete motif-margin-right-5"></span>
            <span>{{content.ErrorMsgs.InvalidAddressExisting}}</span>
            </div>
           </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-4 col-sm-6 mobile-padding">
        <div data-uxd-dropdown-cmp class="ant-sm-select form-item" formControlName="distance" name="distanceSelect"
          id="distanceSelect" [items]="distanceOptions" [defaultText]="defaultDistance.label"
          [labelText]="content.searchForm.distance"></div>
      </div>
      <div class = "pharmacies-message-container" *ngIf="pharmacyList && !outOfStateZip && !invalidSearchTerm && !invalidData">
        <p class ='pharmacies-message'>{{pharmacyList.length}}
            <span *ngIf="pharmacyList.length === 0 || pharmacyList.length > 1">{{content.pharmacySearchLabels.pharmacyWithS}} </span>
            <span *ngIf="pharmacyList.length === 1">{{content.pharmacySearchLabels.pharmacyWithoutS}} </span>
            <span>{{requestObject.distance}} {{content.resultList.milesLowerCase}} {{content.pharmacySearchLabels.localStore}} </span>
            <span *ngIf="requestObject.zipCode">{{requestObject.zipCode}}</span>
            <span *ngIf="requestObject.city && requestObject.state">{{requestObject.city}},{{requestObject.state}}</span>
          </p>
      </div>

    </form>
      <div class="motif-margin-0">          
        <div class="display-block" *ngIf="isError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
          <span>{{content.ErrorMsgs.GeneralSystemErrorWithoutCode}}</span>
        </div>
      </div>
      <div class="row">
        <div class = "row information-alert" *ngIf="pharmacyList && !outOfStateZip && !invalidSearchTerm && !invalidData">
          <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}" *ngIf="pharmacyList.length === 0"> {{content.resultList.noResults}}</div>
          <hr class="motif-margin-bottom-5 motif-margin-top-5"/>
        </div>
        <table class="table" *ngIf="!isLoading && !isError">
          <tr *ngFor="let pharmacy of displayList" class="table-row">
            <td class="table-data ">
              <div class="motif-margin-top-2 mobile-margin">
                <h4 *ngIf="pharmacy.formattedName" class="pharmacy-font">{{pharmacy.formattedName}}</h4>
                <p class="motif-margin-bottom-0">{{pharmacy.address.streetAddress1}}</p>
                <p>{{pharmacy.address.city}}, {{pharmacy.address.state}} {{pharmacy.address.zipCode}}</p>
              </div>
            </td>
            <td class="table-data">
              <div class="motif-margin-top-2 mobile-margin">
                <p>{{pharmacy.distance}}{{content.resultList.milesTitleCase}}</p>
              </div>
            </td>
            <td class="table-data">
              <div class="motif-margin-top-2 mobile-margin">
                <div class="button-group">
                  <button class="btn btn-secondary btn-mobile" type="button" (click)="select(pharmacy)">{{content.buttons.select}}</button>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="row">
        <div class="button-group text-left motif-margin-top-0 col-md-5 col-lg-5 col-sm-6">
          <button 
          *ngIf="((displayList && displayList.length > 0 && pharmacyList.length > displaySize) && (currentListIndex < pharmacyList.length))" 
            class="btn btn-secondary motif-margin-top-0" type="button" (click)="loadMore()">{{content.buttons.loadMore}}</button>

        </div>
        <div class="button-group text-right col-md-4 col-lg-4 col-sm-6">
          <button class="btn  motif-margin-0" type="button" (click)="close()">{{content.buttons.cancel}}</button>
        </div>

      </div>
    </div>
  </div>

</div>

<ng-template #LoadingOrContentError>
  <div class="container row" *ngIf="contentError; else Loading">
    <div data-uxd-alert-cmp-v2 class="col-lg-4 col-md-4 col-sm-4" [alert]="{alertType: 'negative'}">
     <span>We are unable to complete your request at this time. Please try again later or
        contact member services</span>
    </div>
  </div>
  <ng-template #Loading>
    <div class="row" >
      <uxd-spinner class="col-lg-2 col-md-2 col-sm-2"></uxd-spinner>
    </div>
  </ng-template>
</ng-template> 