import { BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ChatDetail, Restriction } from 'gbd-models';
import CONSTANTS from '../../secure/chat/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService implements OnDestroy {

  public chatTopic$: BehaviorSubject<ChatDetail> = new BehaviorSubject<ChatDetail>( null );
  public gbdMenuConfig$: BehaviorSubject<string[][]> = new BehaviorSubject<string[][]>( null );
  public chatTopic = this.chatTopic$.asObservable();
  public gbdMenuConfig = this.gbdMenuConfig$.asObservable();
  constructor() { }

  launchChatBot(event? : any ,fromMenuNavigation = false) {
    let element = document.querySelector(".chat-launch-point");
    if(event){
      event?.preventDefault();
      if (element) {
        let clickEvent;
        if (typeof(event) === 'function') {
          clickEvent = new Event('click');
        } else {
          clickEvent = document.createEvent('Event');
          clickEvent.initEvent('click', true, true);
        }
        element.dispatchEvent(clickEvent);
      }
    } else {
      const _event = new MouseEvent('click', {bubbles: true});
      if (element) {
        element.dispatchEvent(_event);
        _event.preventDefault();
      }
    } 
    
    if (fromMenuNavigation) {
      this.setChatOption({ topic: "livechat" });
    }
  }

  setMenuConfig(menuConfig: string[][]) {
    this.gbdMenuConfig$.next(menuConfig);
  }

  getMenuConfig() {
    this.gbdMenuConfig;
  }

  setChatOption(ChatTopic: ChatDetail){
    this.chatTopic$.next(ChatTopic);
  }

  getChatOption() {
    return this.chatTopic;
  }
  ngOnDestroy(): void {
    this.chatTopic$?.unsubscribe();
    this.gbdMenuConfig$?.unsubscribe();
  }

  /**
   * Handle care coordinator chat from
   * Menu navigation
   * @param event 
   */
  startCareCoordinatorChat(event){
    event?.preventDefault();
  
    this.setChatOption({
      topic: CONSTANTS.CHAT_WITH_COORDINATOR
    });
    sessionStorage.setItem('selectedChatTopic', CONSTANTS.CHAT_WITH_COORDINATOR);
    this.launchChatBot();
  }
}
