<div *ngIf="loadingSpinner">
<div class="ant-help-container" *ngIf="showDashboardAlerts">
  <div class="ant-btn-group-wrapper">
    <div id="psDropdownddl-grt" class="ant-main-div">
      <ul role="list">
        <ng-container *ngFor="let item of content.alerts  let i = index">
          <div *ngIf="item.enabled">
          <li class="greeting-menu-items" role="listitem" *ngIf="i < 4">
            <a #greetItem *ngIf="item.type === 'external' && !isObject(item.action)" href="{{item.action}}" [attr.data-analytics]="item.analyticsTag">
              <span class="link-text">{{item.label}}</span>
              <div>
                <span class="link-text">{{item?.labelLineTwo}}</span>
              </div>
            </a>
            <a #greetItem *ngIf="item.type === 'external' && isObject(item.action)" href="{{item.action[classId.substring(0,2)] ? item.action[classId.substring(0,2)] : item.action['default']}}" [attr.data-analytics]="item.analyticsTag">
              <span class="link-text">{{item.label}}</span>
              <div>
                <span class="link-text">{{item?.labelLineTwo}}</span>
              </div>
            </a>
            <a #greetItem *ngIf="item.type === 'internal'" [routerLink]="item.action" [queryParams]="{id:item?.params}" [attr.data-analytics]="item.analyticsTag">
              <span class="link-text">{{item.label}}</span>
              <div>
                <span class="link-text">{{item?.labelLineTwo}}</span>
              </div>
            </a>
          </li>
          </div>
        </ng-container>
        <div class="assistance-alert-item" *ngIf="assistanceSupport?.enabled && !hasChatRestriction">
          <li class="greeting-menu-items" role="listitem">
            <span class="font-16 font-normal">{{assistanceSupport?.assistanceLabel}}</span>
            <a #greetItem #liveChat href="" (click)="liveChatPopUp($event)" data-analytics="chatLiveAgentWelcomeHome">
              <span class="link-text">{{assistanceSupport?.liveChatLabel}}</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</div>
<ng-container *ngIf="!hasMessageCenterRestriction">
  <div class="message-center-block message-center-notification-container" *ngIf="displayMessageCenterBlock">
    <div class="message-icon" aria-hidden="true">
      <span [class.no-messages]="numUnreadMessages !== 0" class="motif-icon motif-message"></span>
      <div *ngIf="numUnreadMessages !== 0" class="message-badge">{{numUnreadMessages}}
      </div>
    </div>
    <div class="message-text">
      <div id="greeting-unread-messages">
        <span>{{jsonLabels?.YouHave_lbl}}</span>
        <span class="greetings-unread-messages">{{numUnreadMessages}}</span>
        <span>{{jsonLabels?.InboxMessages_lbl}}</span>
      </div>
      <div class="message-center-link">
        <a [href]="jsonLabels?.messageCenterLink_lbl" [attr.aria-describedby]="jsonLabels?.messageCenterAria_lbl" data-analytics="messageCenterLinkWelcomeHome">
          {{jsonLabels?.messageCenterLinkText_lbl}}</a>
      </div>
    </div>
  </div>
</ng-container>
<ng-template  #showErrorMessage>
  <div class="message-center-link message-center-block">  <!--TODO*** Change after UX review -->
    <a [href]="jsonLabels?.messageCenterLink_lbl" [attr.aria-describedby]="jsonLabels?.messageCenterAria_lbl">
      {{jsonLabels?.messageCenterLinkText_lbl}}</a>
  </div>
  
</ng-template>

</div>

<uxd-spinner *ngIf="!loadingSpinner"></uxd-spinner>