/**
 * accepts dates in ISO 8601 (yyyy-mm-dd) format
 * returns a boolean indicating whether today's date is prior to endDate, or within the specified dates if startDate is provided
 * startDate is inclusive, endDate is not inclusive
 */
export const isTodayInDateRange = (endDate: string, startDate?: string): boolean => {
    const today = new Date();
    //if we have startDate, return true if currentDate is between startDate and endDate
    if(startDate){
        return new Date(startDate) <= today && today < new Date(endDate);
    }
    //return true if currentDate is prior to endDate
    return today < new Date(endDate);
  }