export enum NavPages {
  LOGIN_SECURITY = "/secure/profile/login-security",
  PRIVACY_ACCESS = "/secure/profile/privacy-access",
  ACCOUNT_RECOVERY = "/secure/profile/account-recovery",
  ACCOUNT_ACCESS = "/secure/profile/account-access",
  ADDITIONAL_INSURANCE_POLICY = "/secure/profile/additional-insurance-policies/report",
  ADDRESS_DETAILS = "/secure/profile/address-details",
  ADDRESSES = "/secure/profile/addresses",
  ADDRESSES_AHD = "/secure/profile/ahd-addresses",
  COMMUNICATION_PREFERENCES = "/secure/profile/communication-preferences",
  LOGIN_CONTACT = "/secure/profile/login-security",
  PAYMENTS = "/secure/profile/payments",
  PLAN_NOTIFICATIONS = "/secure/profile/plan-notifications",
  PRESCRIPTION_NOTIFICATIONS = "/secure/profile/pharmacy-notifications",
  MEDICALPROFILE = "/secure/profile/medical-profile",
  LANGUAGE = "/secure/profile/language",
}

export enum TextTypes {
  VOICE = "voice",
  TEXT = "text",
}

export enum LoginContactUpdateValues {
  HOME = "home",
  PHARMACY = "pharmacy",
  GENERAL = "general",
  SECURITY = "security",
  ARN = "arn",
  MAILING = "mailing",
  SMS = "SMS",
}

export enum SuccessRegion {
  REGION_NAME = "success-alert",
}

export enum AddInsurance {
  ADDITIONAL_INSURANCE_POLICY = "ADDITIONAL_INSURANCE_POLICY",
  GENERAL_INSURANCE_POLICY_DETAIL = "GENERAL_INSURANCE_POLICY_DETAIL",
  ADDITIONAL_INSURANCE_DETAILS = "ADDITIONAL_INSURANCE_DETAILS",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
}

export enum AdditionalInsurancePoliciesRoutes {
  REPORT_ADDITIONAL_INSURANCE = "/secure/profile/additional-insurance-policies/report",
  SUBMITTED_POLICIES = "/secure/profile/additional-insurance-policies/policies",
}

export enum AdditionalInsurancePoliciesTabIds {
  REPORT_ADDITIONAL_INSURANCE = "tabTile01",
  SUBMITTED_POLICIES = "tabTile02",
}

export enum COBInsuranceStatus {
  ACTIVE = "active",
  APPROVED = "approved",
  IN_REVIEW = "inReview",
  NEEDS_ATTENTION = "needsAttention",
}

export enum InsurancePlanType {
  MEDICAL = "medical",
}

export enum FileTypes {
  PDF = "pdf",
  DOC = "doc",
  DOCX = "docx",
  XLS = "xls",
  XLSX = "xlsx",
}

export enum FileErrors {
  TYPE = 'isFileTypeError', 
  SIZE = 'isFileSizeExceeds', 
  LIMIT = 'isFileLimitExceeds', 
  PASSWORD = 'isPasswordProtectedFile'
}
