import { AlertModule } from "@anthem/uxd/alert";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { UxDeprecatedModule } from '@anthem/uxd/deprecated';
import { SecureSsoModule } from '../sso/sso.module';
import { FindCareComponent } from "./find-care.component";
import { FindCareRoutingModule } from "./find-care.routing.module";
import { IndicatorModule } from "@anthem/uxd/indicators";

@NgModule({
  declarations: [FindCareComponent],
  imports: [AlertModule, IndicatorModule, SecureSsoModule, CommonModule, FormsModule, HttpClientModule, FindCareRoutingModule, UxDeprecatedModule
  ],
  exports: [FindCareComponent],
  providers: [],
  bootstrap: [FindCareComponent]
})
export class FindCareModule {}
