<div class="container">
    <ng-container>
        <ng-container *ngIf="isLoading; else showTransactionsContent">
            <uxd-spinner></uxd-spinner>
            <div [innerHtml]="content.Loading_lbl"></div>
        </ng-container>
        <ng-template #showTransactionsContent>
            <ng-container *ngIf="error; else showTransactions">
                <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                    <span [innerHTML]="error"></span>
                </div>
                </ng-container>
                <ng-template #showTransactions>
                <div id="pwrAcctAcctTransactions">
                        <div *ngIf="transactionsDataDisplay.length > 0" class="ant-heading-six motif-margin-top-5 motif-margin-bottom-8" [innerHtml]="filterMsg"></div>
                        <app-power-account-date-filter [content]="content" [widgetFilterState]="widgetFilterState" (newFilterState)="onFilterState($event)"></app-power-account-date-filter>
                    <ng-container *ngIf="transactionsDataDisplay.length > 0; else showChangeFilterMsg">
                        <table class="table zebra-stripes motif-margin-top-15" [ngClass]="{ 'table-border': false }">
                            <tr class="table-row vgr-divider-border-bottom">
                                <th class="table-header" scope="col" 
                                    [innerHtml]="content?.PwrAcctActivity_lbls.PwrAcctTableHeader_lbls.PwrAcctActDate_lbl"></th>
                                <th class="table-header" scope="col" 
                                    [innerHtml]="content?.PwrAcctActivity_lbls.PwrAcctTableHeader_lbls.PwrAcctActDescription_lbl"></th>
                                <th class="table-header" scope="col" 
                                    [innerHtml]="content?.PwrAcctActivity_lbls.PwrAcctTableHeader_lbls.PwrAcctActMedClaimNum_lbl"></th>
                                <th class="table-header" scope="col" 
                                    [innerHtml]="content?.PwrAcctActivity_lbls.PwrAcctTableHeader_lbls.PwrAcctActAmount_lbl"></th>
                                <th class="table-header" scope="col" 
                                    [innerHtml]="content?.PwrAcctActivity_lbls.PwrAcctTableHeader_lbls.PwrAcctActBalance_lbl"></th>
                                <th class="table-header" scope="col" 
                                    [innerHtml]="content?.PwrAcctActivity_lbls.PwrAcctTableHeader_lbls.PwrAcctActStatus_lbl"></th>
                            </tr>
                            <tr class="table-row vgr-divider-border-bottom" *ngFor="let transaction of transactionsDataDisplay">
                                <td data-th="Date" scope="col" class="table-data">{{ transaction.date }}</td>
                                <td data-th="Description" class="table-data">{{ transaction.description }}</td>
                                <td data-th="Medical Claim Number" class="table-data">{{ transaction.claimNumber }}</td>
                                <td data-th="Amount (+/-)" class="table-data">{{ transaction.amount }}</td>
                                <td data-th="Balance" class="table-data">{{ transaction.balance }}</td>
                                <td data-th="Status" class="table-data">{{ transaction.status }}</td>
                            </tr>
                        </table>
                    </ng-container>
                    <ng-template #showChangeFilterMsg>
                        <div class="motif-margin-top-9" data-uxd-alert-cmp-v2 [alert]="{alertType: 'information'}">
                            <span [innerHtml]="noResultsMsg"></span>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </ng-template>
    </ng-container>
</div>
