import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { PaymentPortalService } from ".././services/payment-portal.service";
import { PaymentHelperService } from ".././services/payment-helper.service";
import { AlertService, UniversalAlertType } from "@anthem/uxd/alert";
import { SidePanel, ModalRef } from '@anthem/uxd/modal';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { PaymentInfo } from '../interfaces/PaymentInfo';
import { AccountStatus, AutopayActions, AutopayDate, PaymentType } from '../interfaces/Autopay';
import { Router } from '@angular/router';
import { UpdateAutopayRequest } from 'gbd-models';
import { ValidationStatus } from 'gbd-models/src/features/validateBankAccount';
import { browserRefresh } from '../../../app.component';
@Component({
  selector: 'app-auto-pay',
  templateUrl: './auto-pay.component.html',
  styleUrls: ['./auto-pay.component.scss']
})
export class AutoPayComponent implements OnInit, OnDestroy {
  labels: any;
  headerJson: any;
  errorMsgs: any;
  isPaymentsLoading: boolean = false;
  isPaymentsError: boolean = false;
  selectedBill: PaymentInfo;
  selectedPaymentMethod: PaymentMethod;
  editMode: boolean = false;
  isPaymentMethodAlert: boolean = false;
  paymentMethodText: string = "";
  isPaymentValid: boolean = false;
  isSubmittedOnce: boolean = false;
  cardExpiry: AccountStatus;
  bankAccountDeclinedMessage: string;
  isBankAccountDeclinedError = false;
  isEarlyWarningError = false;
  earlyWarningServiceDescription: string;
  earlyWarningLink: string;
  earlyWarningLabel: string;

  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  @ViewChild('sidePanelPayment') sidePanelPayment: TemplateRef<HTMLElement>;
  public sidePanelRef: ModalRef<any, any>;
  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private _service: PaymentPortalService,
    private _helper: PaymentHelperService,
    private _alert: AlertService,
    private router: Router,
    private _sidePanel: SidePanel
  ) {
    this.jsonSvc.getJSON('payment-portal').subscribe(data => {
      this.labels = data.paymentPortal.labels;
      this.headerJson = data.paymentPortal.HeaderNav;
      this.errorMsgs = data.paymentPortal.errors;
      this.earlyWarningLabel = this.errorMsgs?.EarlyWarningServiceLabel;
      this.earlyWarningLink = this.errorMsgs?.EarlyWarningServiceLink;
      this.earlyWarningServiceDescription = this.errorMsgs?.EarlyWarningServiceDescription;
      this.interCommunicationService.raiseEvent({
        title: this.headerJson.HeaderTitle,
        message: this.headerJson.HeaderMessage
      });
    });
  }

  ngOnInit(): void {
    if (browserRefresh) {
      const res = JSON.parse(sessionStorage.getItem('billInfo'));
      if (res) {
        this.selectedBill = res;
      }
    }
    if (this.router.url && this.router.url.indexOf('/auto-pay-edit') > -1) {
      this.editMode = true;
      this.fetchPaymentMethods();
    }
    this._helper.selectedPayment.subscribe(res => {
      if (res) {
        this.selectedBill = res;
      }
    });
    this._helper.selectedPaymentMethod.subscribe((res: any) => {
      if (res) {
        if (res.error) {
          this.setErrorMessages(res.error);
        }
        else if (res.paymentMethod) {
          this.isSubmittedOnce = false;
          this.selectedPaymentMethod = res.paymentMethod;
          if (this.selectedPaymentMethod.isBankAcc) {
            this.isPaymentValid = true;
          }
          if (res.isAdded && !this.selectedPaymentMethod.isBankAcc) {
            this.paymentMethodText = this.errorMsgs.CCAddedMessage;
          }
          else if (!res.isAdded && !this.selectedPaymentMethod.isBankAcc) {
            this.paymentMethodText = this.errorMsgs.CCUpdatedMessage;
          }
          else if (!res.isAdded && this.selectedPaymentMethod.isBankAcc) {
            this.paymentMethodText = this.errorMsgs.BankAccUpdatedMessage;
          }
          else {
            this.paymentMethodText = this.errorMsgs.BankAccAddedMessage;
          }
          this.isPaymentMethodAlert = res.isAdded ? true : false;
          this.cardExpiry = this._helper.getAccountStatus(this.selectedPaymentMethod);
          this.isPaymentValid = this.cardExpiry < 2 ? true : false;
        }
      }
    });
  }
  fetchPaymentMethods() {
    this._service.getMethods().subscribe(res => {
      this.selectedPaymentMethod = this._helper.findAutopayMethod(res.body, res.body.recurringPaymentTokenIds);
      this.cardExpiry = this._helper.getAccountStatus(this.selectedPaymentMethod);
      this.isPaymentValid = this.cardExpiry < 2 ? true : false;
    });
  }
  updateAutopay() {
    const reqObj: UpdateAutopayRequest = {
      payDate: AutopayDate.INMCD,
      paymentType: this.selectedPaymentMethod.isBankAcc ? PaymentType.BANK : PaymentType.CREDIT,
      planID: this.selectedBill.planId,
      productID: this.selectedBill.productId,
      recurType: this.editMode ? AutopayActions.UPDATE : AutopayActions.ADD,
      tokenId: this.selectedPaymentMethod.tokenId
    };
    this._service.updateAutopay(reqObj).subscribe(res => {
      this.showGlobalSuccess(this.labels.AutopayAddSuccess);
      window.scrollTo(0, 0);
      this.isSubmittedOnce = true;
    }, err => {
      this.showGlobalError(this.labels.AutopayAddFailure);
      window.scrollTo(0, 0);
    });
  }
  showGlobalSuccess(message) {
    this._alert.success(message, {
      regionName: 'payment-alert',
      alertListType: 'queue',
      politeness: 'polite',
      isDissmisable: true
    });
  }
  showGlobalError(message) {
    this._alert.error(message, {
      regionName: 'payment-alert',
      alertListType: 'queue',
      politeness: 'polite'
    });
  }
  onAddPaymentMethod() {
    this.sidePanelRef = this._sidePanel.open('right', this.sidePanelPayment);
  }
  onClose() {
    this.sidePanelRef.close();
  }
  ngOnDestroy() {
    const emptyPayment = null as PaymentMethod;
    this._helper.setSelectedPaymentMethod(emptyPayment, false, null);
  }

  setErrorMessages(errorCode: string) {
    if (!errorCode) {
      this.isPaymentsError = false;
      this.isEarlyWarningError = false;
      this.isBankAccountDeclinedError = false;
    }

    if (errorCode === 'error') {
      this.paymentMethodText = errorCode;
      this.isPaymentsError = true;
    } else {
      this.isPaymentMethodAlert = false;
      this.isPaymentsError = false;
      this.isEarlyWarningError = errorCode === ValidationStatus.DECLINED_2002;
      this.isBankAccountDeclinedError = errorCode === ValidationStatus.DECLINED_2001;
      this.bankAccountDeclinedMessage = this.isEarlyWarningError
        ? this.errorMsgs?.BankAccountDeclined_2002
          : this.errorMsgs?.BankAccountDeclined;
    }
  }
}
