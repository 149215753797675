import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ChatbotOption, ChatDirective, ChatMessage, ChatMessageList, ConversationMetadata, RadioArray, RenderModalItem } from '../interfaces/chatbotOption';
import CONSTANTS from '../utils/constants';
import { Router } from '@angular/router';
import { ChatbotHelperService } from '../services/chatbot-helper.service';
import { ChatBotService } from '../services/chatbot.service';
import { ChatDetail, StartChat, TargetChatSource } from 'gbd-models';
import { interval, Subscription } from 'rxjs';
import { startWith, takeWhile } from 'rxjs/operators';
import { ClaimStatusEnum, MessageTypeEnum, TypeOfOptionEnum } from '../utils/conversationEnums';
import { SidePanelComponent } from '@anthem/mbrportal/shared';
import { NavigationHelper2 } from 'sydmed/src/app/mbrportal-dependencies/navHlpr';
import { IUrlParam } from '@anthem/mbrportal/http/interfaces/iUrlParam';
import { ChatbotService } from 'sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { LiveChatService } from '../services/live-chat.service';


@Component({
  selector: 'app-chatbot-watson',
  templateUrl: './chatbot-watson.component.html',
  styleUrls: ['./chatbot-watson.component.scss']
})
export class ChatbotWatsonComponent implements OnInit, OnChanges ,OnDestroy {

  @Input() content: any;
  @Input() isOptionSelected: boolean;
  @Output() chatbotOptionSelected: EventEmitter<ChatbotOption> = new EventEmitter<ChatbotOption>();
  @Output() chatbotOptionClicked: EventEmitter<String> = new EventEmitter<String>();
  @Output() transferToLiveChat: EventEmitter<ConversationMetadata> = new EventEmitter<ConversationMetadata>();
  @Output() chatbotFocusInput: EventEmitter<void> = new EventEmitter<void>();
  @Output() disableWatsonChatBot: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @ViewChildren('chatDialog') chatDialogs: QueryList<ElementRef>;
  @ViewChildren('dialog') dialogs: QueryList<ElementRef>;
  @ViewChild('chatView', { static: false }) chatView: any;
  @ViewChild('sidePanelCmp', { static: false }) sidePanelCmp: SidePanelComponent;
  @ViewChild('chatTranscript', { static: false }) _chatTranscript: ElementRef;
  @Input() unreadMessages: string;

  public isChatInitiated: boolean = false;
  userScrolled: boolean = false;
  public showError: boolean = false;
  public errorCode: string;
  public errorMessage: string;
  public loadingSpinner: boolean = false;
  public isRefreshCalled: boolean = false;
  public labels: any;
  public chatbotElements: any = [];
  public chatbotOptions: ChatbotOption[] = CONSTANTS.CHATBOT_OPTIONS;
  public selectedOption: ChatbotOption;
  public showNoResponse: boolean = false;
  public locale: string = 'en-US';
  public showSessionConfirm: boolean = false;
  public chatResponse: any = null;
  public messages: any = [];
  public messageList: ChatMessage[] = [];
  public chatMessageList: ChatMessageList = { msg: [] };
  public _chatSubscription: Subscription;
  public redirectionMenu: any = [];
  public benefitUrl: any = [];
  planCode: string = '';
  readonly claimStatusEnum = ClaimStatusEnum;
  scrollHeight: number = 2000;
  chatBoxHeight: number = 0;
  pollingInProgress: boolean = false;
  drawerInfo: RenderModalItem;
  radioOptions: RadioArray;
  public errorCount = 0;
  public refreshCount = 0;
  countDownTimer: number = 0;
  setInactive: boolean = false;
  timerInterval: any = null;
  alertTimer: number = CONSTANTS.CHAT_TIMER_ALERT;
  refreshInterval: number = CONSTANTS.CHAT_REFRESH_INTERVAL;
  timer: number = CONSTANTS.CHAT_TIMER_END;
  interval: any = null;
  analytics = CONSTANTS.CHATBOT_ANALYTICS;
  memberContext: any;
  public hasSpanishChat: boolean = false;
  selectedRadioOption: boolean = false;
  selectedRenderModalItem: string = '';
  liveAgentChatStrated: boolean = false;
  liveChatRequestedWithTopic: boolean = false;


  constructor(public router: Router, private helper: ChatbotHelperService, 
    private chatbotSvc: ChatBotService, private _navHelper: NavigationHelper2, 
    private chatBotService: ChatbotService, private contentService: JsonContentService,    
    private liveChatService: LiveChatService) { }

  ngOnInit(): void {
    this.memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    const ERR_CODE = '0000';
    this.hasSpanishChat = !this.memberContext?.restrictions?.includes("SHM_SPANISH_CHAT");
    let requestObj: StartChat = {
      targetedSrc: TargetChatSource.BOT,
      chatBotInfo: {
        language: 'EN',
        chatbotVersion: '1.0'

      }
    };
    this.liveChatRequestedWithTopic = false;
    this.loadingSpinner = true;
    this.liveAgentChatStrated = false;
    this.chatBotService?.gbdMenuConfig?.subscribe((data) => {
      this.redirectionMenu = data;
    }, error => {
      this.redirectionMenu = [];
    });

    this.contentService.getJSON('connect-with-care').subscribe((data) => {
      this.benefitUrl = data;
    }, error => {
      this.benefitUrl = [];
    })

    this.chatbotSvc.startChat(requestObj, this.locale).subscribe(data => {
      this.chatMessageList.conversationMetadata = data.body?.conversationMetadata || {};
      this.chatMessageList.conversationMetadata.isIntentEnded = true;
      this.showError = false;
      if (this.chatMessageList.conversationMetadata?.statusCode === 500) {
        this.disableWatsonChatBot.emit(false);
      } else {
        sessionStorage.setItem("isLiveChatConversation", 'false');
        sessionStorage.setItem("isWatsonChatEnabled", 'true');
        if (sessionStorage.getItem("activeChatSession") !== "true") {
          this.sendChat('menu', 'menu', "", true);
        } else {
          this.loadingSpinner = false;
        }      
      }
    }, error => {
      this.disableWatsonChatBot.emit(false);
      this.loadingSpinner = false;
      this.showError = true;
      this.errorCode = error.error ? error.error.code : ERR_CODE;
      this.errorMessage = this.content?.errorMessages?.chatConnectionError;
    });
  }

  ngOnChanges(): void {
    this.labels = this.content ? this.content.chatbot : {};
    if (this.chatbotElements[0] && !this.chatbotElements[0].message)
      this.chatbotElements[0].message = this.labels.helpToday;
    if (!this.hasSpanishChat) {
      this.chatbotOptions = this.chatbotOptions.filter(option => option.name != this.labels.chatLiveAgentES);
    }
  }

  callRefreshChat() {
    this.errorCount = 0;
    this.pollingInProgress = true;
    this._chatSubscription = interval(this.refreshInterval)
      .pipe(
        startWith(1),
        takeWhile(() => this.loadingSpinner || !this.chatMessageList?.conversationMetadata?.isIntentEnded)
      )
      .subscribe(() => {
        this.refreshChat()
      },);
  }

  processChatBotMessages() {
    this.loadingSpinner = false;
    this.liveChatRequestedWithTopic = false ;
    this.messageList = this.helper.buildchatBotMessageList(this.chatResponse);
    this.chatMessageList.conversationMetadata = this.chatResponse?.conversationMetadata;
    this.chatMessageList.conversationMetadata.isIntentEnded = true;
    (this.messageList || []).forEach((element: ChatMessage) => {
      this.chatMessageList?.msg?.push(element);
    });
    const isIntentEnded = this.chatMessageList?.conversationMetadata?.isIntentEnded || false;
    if (isIntentEnded) {
      this._chatSubscription.unsubscribe();
    }
    this.handleRenderModal();
    this.handleLiveAgentMetadata();
    this.handleDirectives();
  }

  openInfoSidePanel(option: RenderModalItem) {
    this.selectedRadioOption = option.type === 'limitation' || false;
    this.drawerInfo = option;
    this.sidePanelCmp.open('right');
  }

  closeRenderModal() {
    this.sidePanelCmp.close();
    if (this.radioOptions.options?.length && !this.selectedRadioOption) {
      this.selectedRadioOption = true;
      this.sendChat('No Response', 'noResponse');
    }
  }

  handleRenderModalClick() {
    const selectedItem = this.radioOptions.options?.filter((item) => item.isSelected === true);
    if (selectedItem) {
      this.selectedRadioOption = true;
    }
    this.sendChat(
      selectedItem?.[0]?.label,
      selectedItem?.[0]?.value
    );
    this.closeRenderModal();
  }

  handleRenderModal() {
    const renderModal: ChatMessage | undefined = this.messageList.find((block: ChatMessage) => {
      return block.typeOfOption === TypeOfOptionEnum.RENDER_IN_MODAL;
    });
    if (renderModal) {
      this.radioOptions = { alignVertical: true, isOptional: false, id: 'rbtRendermodalSection', name: 'rbtRendermodalSection', label: ' ', options: [] };
      this.selectedRadioOption = false;
      this.selectedRenderModalItem = '';
      const message: ChatMessage = {
        text: renderModal.text,
        msgSubType: MessageTypeEnum.BOT,
        options: renderModal.options,
        typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT,
        listItems: renderModal.listItems,
        additionalInfo: renderModal.additionalInfo
      };
      renderModal.listItems?.map((item) => {
        this.radioOptions.options?.push({
          label: item.text,
          value: item.value,
          isSelected: false
        });
      });

      if (this.radioOptions.options.length) {
        this.chatMessageList.msg?.push(message);
      }
      this.openInfoSidePanel({ modalChatMessage: message, type: 'rendermodal' });
    }
  }

  get lastMessageIndex() {
    return this.chatMessageList.msg?.length - 1;
  }

  sendChat(userInputText: string, userInputValue?: string, topic: string = "", suppressDisplay: boolean = false) {
    if (!this.liveChatRequestedWithTopic) {
      if (userInputValue?.toLowerCase() === 'closelivechat') {
        this.liveAgentChatStrated = false;
      }
      let requestObj = {
        messages: [
          {
            text: userInputText?.trim(),
            value: userInputValue?.trim() || userInputText?.trim(),
            metadata: null
          },
        ],
        isLiveChatConversation: false
      }
      let sendRequest = requestObj;
      this.isRefreshCalled = true;
      if (!suppressDisplay) {
        this.chatMessageList?.msg?.push({ text: userInputText, typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT, msgSubType: 'USER' });
      }
      if (topic) {
        requestObj.messages[0].metadata = { type: "BOT", data: { "liveAgentTopic": topic } }
        this.liveChatRequestedWithTopic = true;
      } else {
        this.liveChatRequestedWithTopic = false;
      }
      this.loadingSpinner = true;
      this.chatbotSvc.sendChat(sendRequest).subscribe(data => {
        this.chatResponse = data.body;
        this.callRefreshChat();
      },
        error => {
          this.loadingSpinner = false;
          this.liveChatRequestedWithTopic = false;
          const message: ChatMessage = {
            text: this.content.chatbot?.sendMessageFailed,
            msgSubType: MessageTypeEnum.BOT,
            typeOfOption: TypeOfOptionEnum.ERROR_TEXT
          };
          this.chatMessageList?.msg?.push(message);
          this.liveChatService.refreshChatMenu(false);
          
        }
      );
      this.chatbotOptionClicked.emit('clicked');
    }
  }

  handleLiveAgentMetadata() {
    const liveAgentConversation: ChatMessage | undefined = this.messageList.find((block: ChatMessage) => {
      return block.typeOfOption === TypeOfOptionEnum.CONVERSATION_START;
    });
    if (liveAgentConversation && liveAgentConversation.metadata) {
      const message: ChatMessage = {
        text: this.content?.beWithYouShortly,
        msgSubType: MessageTypeEnum.BOT,
        typeOfOption: TypeOfOptionEnum.AGENT_CONVERSATION,
      };
      sessionStorage.setItem("isLiveChatConversation", 'true');
      this.chatMessageList.msg?.push(message);
      this.liveAgentChatStrated = true;
      this.transferToLiveChat.emit(liveAgentConversation.metadata);
      this.liveChatRequestedWithTopic = false ;
      this.chatBotService.launchChatBot();
    }
  }

  refreshChat() {
    let requestObj = {
      nextPosition: "0",
      isLiveChatConversation: false
    }
    this.showError = false;
    this.isRefreshCalled = true;
    let refreshRequestInProgress: boolean = false;

    if (!refreshRequestInProgress) {
      refreshRequestInProgress = true
      this.chatbotSvc.refreshChat(requestObj).subscribe(data => {
        this.errorCount = 0;
        this.isRefreshCalled = false;
        this.pollingInProgress = false;
        refreshRequestInProgress = false;
        this.chatResponse = data.body;
        if (this.chatResponse?.uiBlocks?.length > 0) {
          this.chatbotOptionClicked.emit('clicked');
          this.refreshCount = 0;
          this.processChatBotMessages();
        } else {
          this.refreshCount++;
          //polling is stopping if we do not get response after 30 secs
          if (this.refreshCount > 15) {
            this.liveChatRequestedWithTopic = false;
            const lastElement =
              (this.chatMessageList?.msg?.length as number) > 2 && this.chatMessageList?.msg?.[this.chatMessageList?.msg?.length - 1].typeOfOption === TypeOfOptionEnum.BUBBLE;
            if (!lastElement) {
              this.refreshCount = 0;
              this.loadingSpinner = false;
              const message: ChatMessage = {
                text: this.content.chatbot?.longWaitingMessage,
                msgSubType: MessageTypeEnum.BOT,
                typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT
              };
              this.chatMessageList?.msg?.push(message);
              this.chatMessageList.conversationMetadata.isIntentEnded = true;
              this.liveChatService.refreshChatMenu(false);
              sessionStorage.setItem("isLiveChatConversation", 'false');
            }
          }
        }
      }, error => {
        this.isRefreshCalled = false;
        this.pollingInProgress = false;
        refreshRequestInProgress = false;
        this.errorCount++;
        if (this.errorCount > 6) {
          this.liveChatRequestedWithTopic = false;
          this.loadingSpinner = false;
          const message: ChatMessage = {
            text: this.content?.chatbot?.defaultErrorMessage,
            msgSubType: MessageTypeEnum.BOT,
            typeOfOption: TypeOfOptionEnum.BUBBLE_TEXT
          };
          this.chatMessageList?.msg?.push(message);
          this.chatMessageList.conversationMetadata.isIntentEnded = true;
          this._chatSubscription?.unsubscribe();
        }
      }
      );
    }
  }

  handleDirectives() {
    if (this.messageList?.[0]?.directives && this.messageList?.[0]?.directives?.type && this.messageList?.[0]?.directives?.value) {
      switch (this.messageList?.[0]?.directives.type) {
        case 'navigation': {
          this.redirect(this.messageList[0].directives);
          break;
        }
      }
    }
  }

  redirect(_target?: ChatDirective) {
    const target = _target?.value?.toLowerCase() || 'go_to_contactus';
    const queryParams = new Array<IUrlParam>();
    let redirectTarget = this.content.chatbot?.redirection?.[target];
    let externalUrl;
    if (redirectTarget) {
      if (_target?.appUrlParams) {
        (Object.entries(_target?.appUrlParams) || [])?.forEach((param) => {
          if (this.content.allowedParameters.includes(param[0])) {
            const queryParam: IUrlParam = {
              name: param[0],
              value: param[1] as string,
              isQueryParam: true
            };
            queryParams.push(queryParam);
          }
        });
      }

      if (target === 'go_to_find_care') {
        externalUrl = this.redirectionMenu.
          map(item => item?.subLevel)
          .flat()
          .filter(subItem => subItem?.id === 'findADoctor')?.[0];
          externalUrl.default = 'go_to_find_care';
      } else if (target === 'go_to_find_pharmacy') {
        externalUrl = this.redirectionMenu.
          map(item => item?.subLevel)
          .flat()
          .filter(subItem => subItem?.id === 'findAPharmacy')?.[0];
          externalUrl.default = 'go_to_find_care';
      } else if (target?.includes('publicsite')) {
        externalUrl = {
          type: "external",
          url: redirectTarget
        }
      } else if (target === 'go_to_privacy' && this.content?.labels?.chatPrivacyLinkURL) {
        externalUrl = {
          type: "external",
          url: this.content?.labels?.chatPrivacyLinkURL
        }
      } else if (target === 'go_to_benefits_overview') {
        const classId = this.memberContext?.classId?.substring(0, 2);
        const urlList = this.benefitUrl?.items?.ViewBenefits?.url;
        const stateLobs = ['DCMCD', 'GAMCD', 'IAMCD', 'TXMCD', 'TXSET', 'TNMCD', 'MDMCD', 'NJMCD', 'WAMCD', 'NYMCD', 'ARMCD', 'MNMCD', 'MNMMP', 'NCMCD'];
        if (stateLobs.includes(this.memberContext.stateLob)) {
          this.planCode = this.memberContext.planCode;
        }
        const urlsByPlanCode = urlList[this.planCode || classId] || urlList['default'];
        externalUrl = {
          type: "external",
          url: urlsByPlanCode || this.benefitUrl.items.ViewBenefits?.url
        }
      }
      if (externalUrl?.type === "external") {
        if (externalUrl.urlsByPlanCode) {
          externalUrl.url = externalUrl.urlsByPlanCode[this.memberContext?.planCode] || externalUrl.urlsByPlanCode['default'];
        } else if (typeof externalUrl?.url === 'object' && externalUrl?.url[this.memberContext?.classId?.substring(0,2)]) {
          externalUrl.url = externalUrl?.url?.[this.memberContext?.classId?.substring(0,2)] || externalUrl.url['default'];
        }
        if (!externalUrl.url) {
          this._navHelper.navigateTo(redirectTarget, queryParams);
        } else {
          window.open(externalUrl.url, '_blank');
        }
      } else {
        if (queryParams.length > 0) {
          queryParams.push({ name: 'chatBotRedirect', value: 'true', isQueryParam: true });
        }
        this._navHelper.navigateTo(redirectTarget, queryParams);
      }
    }
  }

  ngOnDestroy(): void {
    this.liveAgentChatStrated = false;
    this._chatSubscription?.unsubscribe();
  }
  getClaimStatus(status: ClaimStatusEnum) {
    return  status === ClaimStatusEnum.APPROVED || status === ClaimStatusEnum.PAID ? this.labels.claimApproved : status;
  }
  getStatusClass(status: ClaimStatusEnum) {
    switch (status) {
      case ClaimStatusEnum.APPROVED:
      case ClaimStatusEnum.PAID:
        return 'ant-chat-status-approved ant-chat-claim-status-approved';
      case ClaimStatusEnum.DENIED:
        return 'ant-chat-status-denied ant-chat-claim-status-denied';
      default:
        return 'ant-chat-status-pending ant-chat-claim-status-pending';
    }
  }
  getStatusIcon(status: ClaimStatusEnum) {    
    switch (status) {
      case ClaimStatusEnum.APPROVED:
      case ClaimStatusEnum.PAID:
        return 'motif-checkmark';
      case ClaimStatusEnum.DENIED:
        return 'chat-icon-exclamation';
      default:
        return 'motif-clock';
    }
  }
}
