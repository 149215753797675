<ng-container>
    <form [formGroup]="addCheckingAccountForm" (ngSubmit)="addCheckingAccount(addCheckingAccountForm)" #checkingForm="ngForm"
        name="checkingForm" class='checking-form'>

        <ng-container>
            <div id="route-img">
                <img class="bank_routing_num" role="img" [src]="'assets/images/my-profile-images/bank_routing_num.png'" alt="">
            </div>
            <ng-container *ngIf="loading; else showCheckingForm">
                <div class="row">
                    <div class="tabbed-view-wrapper col-xs-12 loader">
                        <uxd-spinner class="updating-loader"></uxd-spinner>
                        <div>{{labels.SavingLoaderText}}</div>
                    </div>
                </div>
            </ng-container>
            <ng-template #showCheckingForm>
                <div class="grid">
                    <div class="grid-item half pr">
                        <div class="width-100"><label for="txtRouteNumber">{{labels.Labels.RoutingNumber}}</label>
                            <input id="txtRouteNumber" type="tel" name="txtRouteNumber" libNumbersOnly formControlName="routingNumber" maxlength="9"
                                class="ant-text-input ant-input-long width-100" required [attr.aria-describedby]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-routing-number-errors' : 'add-new-specialty-payment-routing-number-errors'"
                                aria-required="true" />

                            <div class="error-list motif-margin-top-5" [id]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-routing-number-errors' : 'add-new-specialty-payment-routing-number-errors'"
                                *ngIf="(routingNumber.dirty || routingNumber.touched || checkingForm.submitted) && routingNumber.invalid">
                                <div *ngIf="routingNumber.errors.required; else showOtherErrors">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5">
                                    <span>{{labels.Labels.RoutingNumber}}</span><span>{{errObj.Required_err}}</span>
                                    </span>
                                    </span>
                                </div>
                                <ng-template #showOtherErrors>
                                    <div *ngIf="routingNumber.errors.minlength || routingNumber.errors.maxlength">
                                        <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5">
                                        <span>{{errObj.RoutingMinLengthErr}}</span>
                                        </span>
                                        </span>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="grid-item half pl">
                        <div class="width-100"><label for="txtAcctNumber">{{labels.Labels.AccountNumber}}</label>
                            <input id="txtAcctNumber" type="tel" name="txtAcctNumber" formControlName="accountNumber" maxlength="20" class="ant-text-input ant-input-long width-100"
                                libNumbersOnly required [attr.aria-describedby]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-account-number-errors' : 'add-new-specialty-payment-account-number-errors'"
                                aria-required="true" />

                            <div class="error-list motif-margin-top-5" [id]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-account-number-errors' : 'add-new-specialty-payment-account-number-errors'"
                                *ngIf="(accountNumber.dirty || accountNumber.touched || checkingForm.submitted) && accountNumber.invalid">
                                <div *ngIf="accountNumber.errors.required; else showOtherAccErrs">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5">
                                    <span>{{labels.Labels.AccountNumber}}</span><span>{{errObj.Required_err}}</span>
                                    </span>
                                    </span>
                                </div>
                                <ng-template #showOtherAccErrs>
                                    <div *ngIf="accountNumber.errors.minlength">
                                        <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5">
                                        <span>{{errObj.AccountNumLengthErr}}</span>
                                        </span>
                                        </span>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="grid-item half pr">
                        <div class="width-100"><label for="txtFName">{{labels.Labels.FirstName}}</label>
                            <input id="txtFName" type="text" name="txtFName" formControlName="firstName" class="ant-text-input ant-input-long width-100"
                                required [attr.aria-describedby]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-firstName-errors' : 'add-new-specialty-payment-firstName-errors'"
                                aria-required="true" />

                            <div class="error-list motif-margin-top-5" [id]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-firstName-errors' : 'add-new-specialty-payment-firstName-errors'"
                                *ngIf="(firstName.dirty || firstName.touched || checkingForm.submitted) && firstName.invalid">
                                <div *ngIf="(firstName.errors.required || firstName.errors.whitespaces)">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5">
                                    <span>{{labels.Labels.FirstName}}</span><span>{{errObj.Required_err}}</span>
                                    </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid-item half pl">
                        <div class="width-100"><label for="txtLName">{{labels.Labels.LastName}}</label>
                            <input id="txtLName" type="text" name="txtLName" formControlName="lastName" class="ant-text-input ant-input-long width-100"
                                required [attr.aria-describedby]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-lastName-errors' : 'add-new-specialty-payment-lastName-errors'"
                                aria-required="true" />

                            <div class="error-list motif-margin-top-5" [id]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-lastName-errors' : 'add-new-specialty-payment-lastName-errors'"
                                *ngIf="(lastName.dirty || lastName.touched || checkingForm.submitted) && lastName.invalid">
                                <div *ngIf="(lastName.errors.required || lastName.errors.whitespaces)">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5">
                                    <span>{{labels.Labels.LastName}}</span><span>{{errObj.Required_err}}</span>
                                    </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid-item width-100">
                        <!-- show checkbox based on the showCheckBox input passed -->
                        <ng-container *ngIf="showCheckBox">
                            <div data-uxd-checkbox-input-cmp [definition]="checkbox" formControlName="textCheckbox"></div>
                        </ng-container>
                    </div>

                    <ng-container>
                        <div class="error" *ngIf="errMsg">
                            <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                                <span [innerHTML]="errMsg"></span>
                            </div>
                        </div>
                    </ng-container>

                    <div class="grid-item width-100 text-right">
                        <!-- show buttons based on the showButtons input passed -->
                        <ng-container *ngIf="showButtons">
                            <div class="button-group width-100">
                                <button type="button" (click)="onCancel()" class="btn btn-secondary">{{labels.Buttons.Cancel}}</button>
                                <button type="submit" data-analytics="addNewPayMethodRx" class="btn btn-primary">{{labels.Buttons.Add}}</button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
            <br>
        </ng-container>
    </form>
</ng-container>