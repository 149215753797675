import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetUrlByPlancodeService {

  constructor() { }
  public getUrlByPlanCodeOrClassId(key: string, urls: any): string {
    for (let url in urls) {
      if(url === key) {
          return urls[url];
      }
  }
    return urls.default;
  }
}
