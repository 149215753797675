// @dynamic
export class UrlProperties {
  public static get UrlProperties() {
    return {
      endPoints: {
        appealsAndGrievance: {
          submitANGForm : '/sydmedservices/secure/members/submitANG',
          uploadFileAttachment : '/sydmedservices/secure/members/uploadAttachment'
        },
        appointmentAssistance: {
          submitAppointmentAssistanceRequest: '/sydmedservices/secure/members/forms/appointment-assistance',
        },
        assessments: {
          getAssessments: '/sydmedservices/secure/assessments'
        },
        documentHub:{
          noa: '/sydmedservices/secure/documents',
          downloadpdf: '/sydmedservices/secure/securemessaging/downloadmessagettachment',
        },
        secureMessaging: {
          composeMessage: '/sydmedservices/secure/messages/compose',
          status: '/sydmedservices/secure/messages/status',
          messages: '/sydmedservices/secure/messages',
          subjects: '/sydmedservices/secure/messages/subjects',
          downloadAttachment: '/sydmedservices/secure/securemessaging/downloadmessagettachment',
          updateMessage: '/sydmedservices/secure/messages/update'
        },
        myProfile: {
          checkUsernameAvailability: '/sydmedservices/secure/username/validation',
          cvsAddressValidation : '/sydmedservices/secure/pharmacy/shipping/pbmAddressValidation',
          getAddresses: '/sydmedservices/secure/profile/addresses',
          updateAddress: '/sydmedservices/secure/profile/address',
          updateEmail: '/sydmedservices/secure/profile/email',
          updatePassword: '/sydmedservices/secure/profile/updatePassword',
          updatePhoneNum: '/sydmedservices/secure/profile/arn',
          updateGeneralSmsNum: '/sydmedservices/secure/profile/smsnumber/general',
          deleteSmsNum : '/sydmedservices/aslproxy/secure/api/v1/members/contact-info/sms-numbers',
          securityQuestions : '/sydmedservices/secure/security/getquestions',
          submitSecurityQuestions : '/sydmedservices/secure/profile/securityQuestions',
          profileDetails: '/sydmedservices/secure/profile/details',
          getWrittenLanguages: '/sydmedservices/secure/profile/commPrefs/writtenLanguage',
          UpdateWrittenLanguage: '/sydmedservices/secure/profile/commPrefs/writtenLanguage',
          deleteAccount: '/sydmedservices/secure/members/deleteAccount',
          designeeDetails: '/sydmedservices/secure/profile/member-rep',
          deleteDesigneeDetails: '/sydmedservices/secure/profile/member-rep/delete',
          memberRepDetails: '/sydmedservices/secure/profile/member-rep',
          deleteMemberRepresentative: '/sydmedservices/secure/profile/member-rep/delete',
        },
        pharmacy: {
          accounts: '/sydmedservices/secure/pharmacy/accounts/pbm',
          specialtyAccounts: '/sydmedservices/secure/pharmacy/accounts/specialty',
          specialtyQuestions: '/sydmedservices/secure/pharmacy/specialtyquestions',
          orders: '/sydmedservices/secure/pharmacy/orders',
          pharmacySearch: '/sydmedservices/secure/pharmacy/pharmacies',
          prescriptions: '/sydmedservices/secure/pharmacy/prescriptions',
          shipping: '/sydmedservices/secure/pharmacy/shipping/pbm',
          specialtyShipping: '/sydmedservices/secure/pharmacy/shipping/specialty',
          addNewAddress: '/sydmedservices/secure/profile/address',
          validateAddress: '/sydmedservices/secure/pharmacy/shipping/pbmAddressValidation',
          validateSpecialtyAddress: '/sydmedservices/secure/pharmacy/shipping/specialtyAddressValidation',
          checkout: '/sydmedservices/secure/pharmacy/place-orders',
          featureToggle: '/sydmedservices/public/content/PORTAL_PHARMACY_CONFIG',
          paymenthistory: '/sydmedservices/secure/pharmacy/paymenthistory',
          contactInfoApi: '/sydmedservices/secure/profile/details',
          accountBalanceApi: '/sydmedservices/secure/pharmacy/accounts/balance',
          submitPBMPaymentUrl: '/sydmedservices/secure/pharmacy/payments/pbm',
          submitSpecialtyPaymentUrl: '/sydmedservices/secure/pharmacy/payments/specialty',
          medicationSearch: '/sydmedservices/aslproxy/secure/api/v3/ingeniorx/drugs',
          commPrefs: '/sydmedservices/secure/profile/commPrefs',
          acknowledgment: '/sydmedservices/aslproxy/secure/api/v1/users/acknowledgment'          
        },
        ahdPharmacy: {
          submitAHDPayment: '/sydmedservices/secure/pharmacy/payments',
          orderdetails: '/sydmedservices/secure/pharmacy/orders/',
          archivePrescription: "/sydmedservices/secure/pharmacy/prescriptions/archive",
          getAllOrders: "/sydmedservices/secure/pharmacy/orders/allOrders",
          getOrderDetail:"/sydmedservices/secure/pharmacy/orders",
          prescriptions: "/sydmedservices/secure/pharmacy/prescriptions/allPrescriptions",
          unarchivePrescription: "/sydmedservices/secure/pharmacy/prescriptions/unarchive",
          deliveryOptions: "/sydmedservices/secure/pharmacy/shippingOptions",
          deliveryAddress: "/sydmedservices/secure/profile/pharmacy/addresses",
          ahdPayment: "/sydmedservices/secure/profile/pharmacy/paymentMethods",
          ahdPaymentHistory: '/sydmedservices/secure/pharmacy/payments/paymentHistory',
          downloadPrescriptions: "/sydmedservices/secure/pharmacy/prescriptions/download",
          downloadOrders: "/sydmedservices/secure/pharmacy/orders/download",
          ahdGetOutstandingBalance: "/sydmedservices/secure/pharmacy/payments/outstandingBalance",
          ahdAddressServiceability: "/sydmedservices/secure/profile/pharmacy/addresses/serviceable",
          getMedicalProfile: '/sydmedservices/secure/profile/pharmacy/medicalProfile',
          ahdRefillsHistory: '/sydmedservices/secure/pharmacy/prescriptions/refillsHistory',
          validateAddress: "/sydmedservices/secure/profile/pharmacy/addresses/validate",
          allergies:{
            getAhdAllergyByName:"/sydmedservices/secure/profile/pharmacy/allergies/search",
            getAhdPatientAllergies: "/sydmedservices/secure/profile/pharmacy/allergies",
            getAhdDefaultAllergies:"/sydmedservices/secure/profile/pharmacy/allergies/default",
            updateAhdAllergies:"/sydmedservices/secure/profile/pharmacy/allergies",
          },
          commSettings: {
            getCommPrefs: "/sydmedservices/secure/profile/pharmacy/communicationPreferences",
            updateCommPrefs: "/sydmedservices/secure/profile/pharmacy/communicationPreferences",
            getAhdContactDetails: "/sydmedservices/secure/profile/pharmacy/contactDetails",
            updateAhdContactDetails: "/sydmedservices/secure/profile/pharmacy/contactDetails",
          },
          ahdSubmitRefillRequest: "/sydmedservices/secure/pharmacy/orders/submitRefill"
        },
        authorizationStatus: {
          summary: '/sydmedservices/secure/preauth/summary',
          details: '/sydmedservices/secure/preauth/details',
          rxPreAuth: '/sydmedservices/secure/preauth/pharmacy/details',
          decisionLetter: '/sydmedservices/secure/preauth/decisionLetter'
        },
        onlineGrievances: {
          sendForm: '/sydmedservices/secure/members/grievance'
        },
        idCard: {
          back: '/sydmedservices/secure/idcard/back',
          email: '/sydmedservices/secure/idcard/email',
          fax: '/sydmedservices/secure/idcard/fax',
          front: '/sydmedservices/secure/idcard/front',
          idCard: '/sydmedservices/secure/idcard',
          mail: '/sydmedservices/secure/idcard/mail',
          pdf: '/sydmedservices/secure/idcard/pdf',
          transientIdCard: '/sydmedservices/secure/idcard?transientIdCardFallback=true'
        },
        liveChat: {
          closeChat: '/sydmedservices/secure/chat/disconnect',
          getHours: '/sydmedservices/secure/chat/hours',
          refreshChat: '/sydmedservices/secure/chat/refresh',
          sendChat: '/sydmedservices/secure/chat/send',
          startChat: '/sydmedservices/secure/chat/start'
        },
        paymentMethods: {
          accounts: '/sydmedservices/secure/pharmacy/accounts/pbm',
          accountsSpecialty: '/sydmedservices/secure/pharmacy/accounts/specialty', 
          checking: '/sydmedservices/secure/pharmacy/accounts/checking',
          creditcard: '/sydmedservices/secure/pharmacy/accounts/creditcard'
        },
        claims: {
          summary: '/sydmedservices/secure/claims',
          details: '/sydmedservices/secure/claims/{clmId}',
          getEob: '/sydmedservices/secure/claims/eob'
        },
        requestCallBack: {
          inquiries: '/sydmedservices/secure/clickToCall/inquiryTypes',
          callTime: '/sydmedservices/secure/clickToCall/waitTime',
          apptTime: '/sydmedservices/secure/clickToCall/appointmentTimes',
          scheduleCall: '/sydmedservices/secure/clickToCall/submitCallback'
        },
        pcp: {
          viewPcp: '/sydmedservices/secure/profile/pcp',
          updatePcp: '/sydmedservices/secure/profile/pcp',
          searchProviders: '/sydmedservices/aslproxy/secure/api/v1/providers',
          findProviders: '/sydmedservices/secure/providers/find',
          changePcpReasons: '/sydmedservices/secure/profile/pcp/termReasons'
        },
        medicalProfile: {
          medicalProfile: '/sydmedservices/secure/pharmacy/medicalProfile'
        },
        sso: {
          ssoBase: '/sydmedservices/aslproxy/secure/api/v1/members/sso/',
          ssoNodeBase: '/sydmedservices/secure/sso/'
        },
        careCoordinator: {
          getCareCoordinator: '/sydmedservices/secure/carecoordinator'
        },
        cob:{
          insuranceDetails: '/sydmedservices/secure/cob/details',
          uploadDocument: '/sydmedservices/secure/members/uploadAttachment'
        },
        findCare: {
          findCareMetadata: '/sydmedservices/secure/findCare/find-care-metadata'
        },
        registration: {
          eligibility: '/sydmedservices/public/registration/v2/eligibility',
          checkUsernameAvailibility: '/sydmedservices/public/registration/username/validation',
          getSecurityQuestions: '/sydmedservices/public/security/questions',
          sendOtp: '/sydmedservices/public/registration/v2/otp-request',
          submitRegistration: '/sydmedservices/public/registration/register',
          validateOTP: '/sydmedservices/public/registration/v2/otp-validate',
          tokenize: '/sydmedservices/public/tokenize',
          decrypt: '/sydmedservices/public/decrypt',
          memberRepRegistration: {
            getSecurityQuestions: '/sydmedservices/public/security/questions',
            sendOtp: '/sydmedservices/public/registration/member-rep/send-otp',
            validateDesigneeInfo: '/sydmedservices/public/registration/designee/validate-info',
            validateOtp: '/sydmedservices/public/registration/member-rep/validate-otp',
            validateToken: '/sydmedservices/public/registration/member-rep/validate-token',
            registerAccount: '/sydmedservices/public/registration/member-rep/register'
          },
          tokenEligibility: '/sydmedservices/public/registration/v2/eligibility-token',
          validateToken: '/sydmedservices/public/registration/validate-token'
        },
        login: {
          authenticateUser: '/sydmedservices/public/auth/v2/login',
          resetTempPassword: '/sydmedservices/public/password/reset',
          attestation: '/sydmedservices/secure/auth/caregiver/attestation',
          sendOtp: '/sydmedservices/public/auth/v2/otp-request',
          validateOTP: '/sydmedservices/public/auth/v2/otp-validate',
          answerSecurityQuestions: '/sydmedservices/public/auth/answers/validation',
          updateTOU: '/sydmedservices/public/login/terms-of-use'

        },
        forgotUsernamePassword: {
          answerSecurityQuestions: '/sydmedservices/public/fup/answers/validation',
          eligibility: '/sydmedservices/public/fup/v2/identifyMember',
          sendOtp: '/sydmedservices/public/fup/v2/otp-request',
          resetPassword: '/sydmedservices/public/password/reset',
          validateOTP: '/sydmedservices/public/fup/v2/otp-validate'
        },
        landingPage: {
          messages: '/sydmedservices/secure/messages/status?folder=inbox',
          alerts: '/sydmedservices/secure/alerts',
          getIntervention: '/sydmedservices/secure/intervention',
          updateIntervention: '/sydmedservices/secure/intervention/update-intervention',
          chatUnreadMessage: '/sydmedservices/secure/chat/unread-messages',
        },
        paymentPortal: {
          cancelPayment: '/sydmedservices/secure/billpay/payments/cancel',
          getAutopayDetails: '/sydmedservices/secure/billpay/payments/recurring',
          getHistory: '/sydmedservices/secure/billpay/paymentsHistory',
          getMethods: '/sydmedservices/secure/billpay/payments/methods',
          getSummary: '/sydmedservices/secure/billpay/bills/summary',
          makePayment: '/sydmedservices/secure/billpay/payments/submit',
          updateAutopay: '/sydmedservices/secure/billpay/recurringPayments/update',
          updatePaymentMethod: '/sydmedservices/secure/billpay/paymentMethods/v2/update',
          viewBill: '/sydmedservices/secure/billpay/getPdfInvoice',
        },
        emulation: '/sydmedservices/public/auth/emulation',
        powerAccount: {
          powerAccountPDFStatement: '/sydmedservices/secure/pac/pdfStatement/',
          powerAccountStatements: '/sydmedservices/secure/pac/statements',
          powerSummary: '/sydmedservices/secure/pac/summary',	
          powerTransactions: '/sydmedservices/secure/pac/transactions'
        },
        appStatus: '/sydmedservices/public/app/status',
        patientPay: '/sydmedservices/secure/v1/patientpay/obligation',
        costShare: {
          outOfPocket: '/sydmedservices/secure/costshare/out-of-pocket',
          memberLiability: '/sydmedservices/secure/costshare/ltss'
        },
        thirdPartyInsurance: '/sydmedservices/secure/ohi'
      }
    };
  }
}
