import { AbstractControl, FormGroup } from '@angular/forms';

export class FormValidators {
    static PhoneNumberValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {

        if (control.value === '') {
            return null;
        } else {
            const valid = /^([\(]?\d{3}[\)]?[-. ])(\d{3}[-. ]\d{4})$/.test(control.value);

            return valid
                ? null
                : { invalidPhone: { valid: false } };
        }
    }

    static EmailValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const regEx = new RegExp(
                '^([a-zA-Z0-9_\\-\\.]+)' +
                '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)' +
                '|(([a-zA-Z0-9\\-]+\\.)+))' +
                '([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$'
            );
            const valid = regEx.test(control.value) && /^[\s\S]{0,40}$/.test(control.value);

            return valid
                ? null
                : { invalidEmail: { valid: false, value: control.value } };
        }
    }

    static ValidDateValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {

            let valid = /^(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)\d\d$/.test(control.value);
            let splitDate = control.value.split('/');
            let listOfMaxDays = [31,28,31,30,31,30,31,31,30,31,30,31];

            //checks if year is a leap year
            if ( (!(splitDate[2] % 4) && splitDate[2] % 100) || !(splitDate[2] % 400) ) {
                listOfMaxDays[1] = 29;
            }

            //checks provide day of the month against the max valid day of that month
            if (splitDate[1] > listOfMaxDays[splitDate[0]-1]) {
                valid = false;
            }

            return valid
                ? null
                : { invalidDate: { valid: false, value: control.value } };
        }
    }

    static BirthDateValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {

            let currentDate = new Date();
            let date = new Date(control.value);
            let isFuture = (date < currentDate);

            return isFuture
                ? null
                : { futureDate: { isFuture: false, value: control.value } };
        }
    }

    static WhiteSpace(
        control: AbstractControl
    ): { [key: string]: any } | null {

        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;

        return isValid ? null : { whitespaces: { valid: false } };
    }

    static NameValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {

        if (control.value === '') {
            return null;
        } else {
            const valid = /^[a-zA-Z 0-9!@\$\*\?\|]*$/g.test(control.value);

            return valid
                ? null
                : { invalidnamechar: { valid: false, value: control.value } };
        }
    }

    static MemberNameValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {

        if (control.value === '') {
            return null;
        } else {
            const valid = /^[a-zA-Z 0-9!@\$\*\?\|\-\']*$/g.test(control.value);

            return valid
                ? null
                : { invalidnamechar: { valid: false, value: control.value } };
        }
    }

    static  uniqueChecker(securityQuestionsForm) {
        let answers = [];
        answers.push(securityQuestionsForm.controls['answer1'], securityQuestionsForm.controls['answer2'], securityQuestionsForm.controls['answer3']);
        answers.forEach((ans1, index1) => {
          answers.some((ans2, index2) => {
            if (index1 !== index2
              && ans1?.value?.toLowerCase().trim()
              && ans2?.value?.toLowerCase().trim()
              && ans1?.value?.toLowerCase().trim() === ans2?.value?.toLowerCase().trim()) {
              ans1.setErrors({ hasDuplicate: true });
              ans2.setErrors({ hasDuplicate: true });
            }
          });
        });
      }

    static SecurityAnswersLengthValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = control.value ? /(.){4,}/.test((control.value).trim()) : control.value;
            return valid
                ? null
                : { secanslength: { valid: false, value: control.value } };
        }
    }

    static UsernameValidator(control: AbstractControl):
    { [key: string]: any } {
        if(control.get('userName').value === '' || control.get('password').value === '') {
            return null;
        } else {
            let valid = true;
            if ( control.get('userName').value === control.get('password').value){
                valid = false;
            }

            return valid
                ? null
                : { fieldsmatch: { valid: false, value: control.get('userName').value === control.get('password').value } };
        }
    }

    static ConfirmPasswordValidator(control: AbstractControl):
    { [key: string]: any } {
        if(control.get('password').value === '' || control.get('retypePassword').value === '') {
            return null;
        } else {
            let valid = false;
            if ( control.get('password').value === control.get('retypePassword').value){
                valid = true;
            }

            return valid
                ? null
                : { passwordmatches: { valid: true, value: control.get('password').value === control.get('retypePassword').value } };
        }
    }

    static FieldsMatchValidator(controlName: string, matchingControlName: string): { [key: string]: any } {
        return (formGroup: FormGroup) => {
            const control: AbstractControl = formGroup.controls[controlName];
            const matchingControl: AbstractControl = formGroup.controls[matchingControlName];
            
            if (matchingControl.errors && !matchingControl.errors.notMatched) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
            if (typeof control.value === 'string' && typeof matchingControl.value === 'string') {
                // if Both control values are string
                this.SetErrorForFieldsMatchingValidator(control.value, matchingControl.value, matchingControl);
            } else if (typeof control.value === 'object' && typeof matchingControl.value === 'object') {
                // if Both control values are object
                this.SetErrorForFieldsMatchingValidator(Object.values(control.value)[0], Object.values(matchingControl.value)[0], matchingControl);
            } else if (typeof control.value === 'string' && typeof matchingControl.value === 'object') {
                // if control value is string and matchingControl is object
                this.SetErrorForFieldsMatchingValidator(control.value, Object.values(matchingControl.value)[0], matchingControl);
            } else {
                // if control value is object and matchingControl is string
                this.SetErrorForFieldsMatchingValidator(Object.values(control.value)[0], matchingControl.value, matchingControl);
            }            
        }
    }

    static SetErrorForFieldsMatchingValidator(controlValue, matchingControlValue, matchingControl: AbstractControl): { [key: string]: any } {
        if (controlValue !== matchingControlValue) {
            // set error on matchingControl if validation fails
            matchingControl.setErrors({ notMatched: true });
            return { notMatched: { valid: true, value: controlValue === matchingControlValue } };
        } else {
            matchingControl.setErrors(null);
            return null;
        }
    }

    static ConsecutiveChars(control: AbstractControl):
    { [key: string]: any } {
        if(control.get('password').value === '' || control.get('userName').value === '') {
            return null;
        } else {
            let valid = true;

            for(let i=0; (i+3) <  control.get('userName').value.length; i++) {
                for(let j=0; (j+3) < control.get('password').value.length; j++) {
                    if(control.get('userName').value.substring(i , i+3) === control.get('password').value.substring(j, j+3)) {
                        valid = false;
                    }
                }
            }

            return valid
                ? null
                : { consecChars: { valid } };
        }
    }

    static OneUppercaseValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(?=.*[A-Z])/.test(control.value);
            
            return valid
                ? null
                : { oneUppercase: { valid: false, value: control.value } }
        }
    }

    static OneLowercaseValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(?=.*[a-z])/.test(control.value);
            
            return valid
                ? null
                : { oneLowercase: { valid: false, value: control.value } };
        }
    }

    static OneNumberValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(?=.*[0-9])/.test(control.value);

            return valid
                ? null
                : { oneNumber: { valid: false, value: control.value } }
        }
    }

    static OneSpecialCharValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(?=.*[$!@.])/.test(control.value);
            
            return valid
                ? null
                : { oneSpecChar: { valid: false, value: control.value } };
        }
    }

    static NoSpaceValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = !/\s/.test(control.value);
            
            return valid
                ? null
                : { noSpace: { valid: false, value: control.value } };
        }
    }

    static ValidCharsValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(^[a-zA-Z0-9$!@.]+$)/.test(control.value);
            
            return valid
                ? null
                : { hasValidChars: { valid: false, value: control.value } };
        }
    }

    static UnHasTwoLtrPre(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = !/^[a-zA-Z]{2}\d+$/.test(control.value);
            
            return valid
                ? null
                : { unHasTwoLtrPre: { valid: false, value: control.value } };
        }
    }

    static UnHasDigitPrefix(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = !/^\d/.test(control.value);
            
            return valid
                ? null
                : { unHasDigitPrefix: { valid: false, value: control.value } };
        }
    }

    static UnHasDot(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = !/(^\.|\.$)/.test(control.value);
            
            return valid
                ? null
                : { unHasDot: { valid: false, value: control.value } };
        }
    }

    // TODO - added for shm-my-profile
    static PwdConsecutiveCharValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(.)\1{2,}/.test(control.value);
            
            return !valid
                ? null
                : { pwdConsecChar: { valid: false, value: control.value } };
        }
    }

    static PwdInvalidCharValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(^[a-zA-Z0-9$!@.]+$)/.test(control.value);
            
            return valid
                ? null
                : { pwdInvalidChar: { valid: false, value: control.value } };
        }
    }    

    static MinimumTwoCharValidator(
      control: AbstractControl
    ): { [key: string]: any } | null {
      if (control.value === '') {
        return null;
      } else {
        const valid = /^.{2,}$/.test(control.value);

        return valid
          ? null
          : { minimumTwoCharValidator: { valid: false, value: control.value } };
      }
    }

    static AlphaNumericCharsValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(^[a-zA-Z0-9]+$)/.test(control.value);
            
            return valid
                ? null
                : { hasValidChars: { valid: false, value: control.value } };
        }
    }

    static OnlyAlphaCharsValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(^[a-zA-Z]+$)/.test(control.value);
            
            return valid
                ? null
                : { hasAlphaChars: { valid: false, value: control.value } };
        }
    }

    static OnlyNumericCharsValidator(
        control: AbstractControl
    ): { [key: string]: any } | null {
        if (control.value === '') {
            return null;
        } else {
            const valid = /(^[0-9]+$)/.test(control.value);
            
            return valid
                ? null
                : { hasNumericChars: { valid: false, value: control.value } };
        }
    }
}
