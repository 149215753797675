import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentPortalComponent } from './payment-portal.component';
import { AlertModule } from '@anthem/uxd/alert';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormGroupModule } from '@anthem/uxd/form-group';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { AccordianModule } from '@anthem/uxd/accordion';
import { DropdownModule } from "@anthem/uxd/dropdowns";
import { ModalModule } from '@anthem/uxd/modal';
import { AntDatepickerModule } from '@anthem/uxd/ant-datepicker';
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { PaymentPortalRoutingModule } from "./payment-portal-routing.module";
import { PaymentPortalService } from "./services/payment-portal.service";
import { PaymentsListComponent } from './payments-list/payments-list.component';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PaymentHelperService } from './services/payment-helper.service';
import { CurrentBillListComponent } from "./current-bills/current-bills.component";
import { CurrentBillsDetailComponent } from "./current-bills/current-bills-detail/current-bills-detail.component";
import { PayNowComponent } from "./pay-now/pay-now.component";
import { AutoPayComponent } from './auto-pay/auto-pay.component';
import { RadioModule } from '@anthem/uxd/radio';
import { PaymentMethodsComponent } from "./payment-methods/payment-methods.component";
import { AddCreditPayNowComponent } from "./add-payment-methods/add-credit-paynow/add-credit-paynow.component";
import { AddBankPayNowComponent } from "./add-payment-methods/add-bank-paynow/add-bank-paynow.component";
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';

@NgModule({
  declarations: [PaymentPortalComponent, PaymentsListComponent, PaymentDetailComponent, CurrentBillListComponent,
    CurrentBillsDetailComponent, PaymentHistoryComponent,
    AddCreditPayNowComponent,
    AddBankPayNowComponent,
    PayNowComponent, AutoPayComponent, PaymentMethodsComponent, PaymentConfirmationComponent],
  imports: [
    CommonModule,
    PaymentPortalRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FormGroupModule,
    AlertModule,
    IndicatorModule,
    AccordianModule,
    AntDatepickerModule,
    ModalModule,
    RadioModule,
    DropdownModule,
    CheckboxModule
  ],
  exports: [],
  providers: [PaymentPortalService, PaymentHelperService]
})
export class PaymentPortalModule { }
