import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '@anthem/uxd/deprecated';

@Component({
  selector: 'app-chatbot-idcard',
  templateUrl: './chatbot-idcard.component.html',
  styleUrls: ['./chatbot-idcard.component.scss']
})
export class ChatbotIdCardComponent implements OnInit {
  @Input()
  frontIdcardBase64String?: string;

  @Input()
  backIdcardBase64String?: string;

  @Input() content: any;

  @ViewChild('idCardZoomModal', { static: false })
  idCardZoomModal: ModalComponent;
  frontImageSrc: string;
  backImageSrc: string;
  chatView: boolean = false;
  flippedModal: boolean = false;
  flipped: boolean = false;

  constructor() {
  }

  ngOnInit() {
    try {
      this.frontImageSrc = this.frontIdcardBase64String ? `data:image/png;base64,${this.frontIdcardBase64String}` : '';
      this.backImageSrc = this.backIdcardBase64String ? `data:image/png;base64,${this.backIdcardBase64String}` : '';
      if (this.frontImageSrc) {
        this.chatView = true;
      }
    } catch (e) {
      this.chatView = false;
    }
  }

  flipCard(flipped: boolean): void {
    this.flipped = !flipped;
    document.querySelector('.side')?.classList.toggle('rotated');
    document.querySelector('.back')?.classList.toggle('rotated');
  }


  openModal() {
    this.idCardZoomModal?.show();
  }

  closeModal() {
    this.idCardZoomModal?.hide();
  }

  flip(type?: string) {
    if (type === 'modal') {
      this.flippedModal = !this.flippedModal;
    } else {
      this.flipped = !this.flipped;
    }
  }
}
