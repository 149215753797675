import { MemberRepSession } from './../member-representative/service/memberRepSession.service';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { Loaders } from "sydmed/src/app/secure/shm-my-profile/models/labels";
import { ValidateTokenRequest, ValidateTokenResponse } from "gbd-models";
import { MemberRepRegistrationService } from "../member-representative/service/memberRepRegistration.service";
import { RegistrationService } from "../registration.service";

@Component({
  selector: 'app-validate-token',
  templateUrl: './validate-token.component.html',
  styleUrls: ['./validate-token.component.scss'],
})
export class ValidateTokenComponent implements OnInit {
  public loaders: Loaders;
  public loading: boolean = true;
  public errorMessage: string;
  public token: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private jsonSvc: JsonContentService,
    private designeeRegService:MemberRepRegistrationService,
    private regSvc: RegistrationService,
    private memberRepSession: MemberRepSession
  ) {
  
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.token) {
        this.validateToken(queryParams.token);
      }
      else {
        this.loading = false;
        this.jsonSvc.getJSON('error-messages').subscribe((jsonResults) => {
          this.errorMessage = jsonResults.ErrorMsgs.loadingError;
        })
      }
    });
  }

 /**
   * @Description validateToken used to validate the tokens.
   * @returns void
   */
  validateToken(token: string) {
    const tokenRequest: ValidateTokenRequest = {
      linkToken: token
    }
    this.designeeRegService.validateToken(tokenRequest).subscribe(
      async (res: any) => {
        this.loading = false;
        this.designeeRegService.setValidateTokendata(res);
          await this.regSvc.tokenize({data: res?.body?.username}).then(response => {
            sessionStorage.setItem('username', response.body.token);
          })
        sessionStorage.setItem('memberRepToken', token);
        this.memberRepSession.setMemberType(res?.body?.memberType);
        this.router.navigate(['public/registration/member-representative/phone-verification']);
      },
      (error) => {
        this.router.navigate(['public/registration/member-rep/error-page']);
      }
    )
  }
}
