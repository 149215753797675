import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { Router } from '@angular/router';
import { RegistrationDataService } from '../app.data.service';

@Component({
  selector: 'app-caregiver-info',
  templateUrl: './caregiver-info.component.html',
  styleUrls: ['./caregiver-info.component.css']
})
export class CaregiverInfoComponent implements OnInit {
  jsonLabels: any;
  jsonErrors: any;
  careGiverInfoForm: FormGroup;
  stateDropDown:{title:string, options:{label:string,value:string}[]};
  isStateDropDownEnabled;

  constructor(
    private jsonSvc: JsonContentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private registrationDataSvc: RegistrationDataService
  ) { }

  ngOnInit(): void {
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Caregiver_Info;
      this.jsonErrors = data.RegistrationMFA.ErrorMsgs;
      this.setStateValues();
    });
    this.careGiverInfoForm = this.formBuilder.group({
      firstName: ['',[Validators.required, FormValidators.NameValidator]],
      lastName: ['',[Validators.required, FormValidators.NameValidator]],
      dateOfBirth: ['', [Validators.required, FormValidators.ValidDateValidator, FormValidators.BirthDateValidator]],
      phone: ['', [Validators.required, FormValidators.PhoneNumberValidator]],
      primaryAddress: ['', Validators.required],
      secondaryAddress: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern("^(\\d{5}-\\d{4}|\\d{9}|\\d{5})$")]]
    });
    
  }

  setStateValues():void {
    this.stateDropDown = {title: this.jsonLabels.Field_Labels.State, options: this.jsonLabels.StateValues}; 
  }

  careGiverInfoSubmission() {
    if (this.careGiverInfoForm.valid) {
      sessionStorage.setItem('careGiverInfo', JSON.stringify(this.careGiverInfoForm.value));
      sessionStorage.setItem('createUsernameVisited', 'true');
      this.router.navigate(['public/registration/create-username-password']);
    }
    else {
      this.registrationDataSvc.focusInvalid();
    }
  }
}
