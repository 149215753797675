import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileDetailsComponent } from './components/file-details/file-details.component';
import { FormsModule } from '@angular/forms';
import { FilesizePipe } from './pipes/filesize.pipe';

@NgModule({
  declarations: [
    FileInputComponent,
    FileDetailsComponent,
    FilesizePipe],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    FileInputComponent,
    FileDetailsComponent,
    FilesizePipe,
    CommonModule
  ]
})
export class SharedModule { }
