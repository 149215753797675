<div class="row padding-top-30">
  <div class="col-sm-12 padding-left-0">
    <!-- <button (click)="startChat($event)" role="button" class="chat-with-pharmacist" data-analytics="chatbotpharmacist" >
        <span class="motif-icon motif-technical_support chat-with-pharmacist-icon"></span><p class="chat-with-pharmacist-paragraph">Chat With a Pharmacist</p>
        <app-chat-launcher></app-chat-launcher>
    </button> -->
    <div *ngIf="vendorPrescriptionReviewContent">
      <app-ahd-review-order [errorMsgs]="errorMsgs" [labels]="labels"
        [loaderLabels]="loaderLabels"></app-ahd-review-order>
    </div>
  </div>
</div>