import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(dateVal: string, args?: any): string {

    function addSlashes(value) {

      const date = value.replace(/[^0-9\/]/gi, '');
      if (date.match(/^\d{2}$/) !== null) {
        value = date + '/';
      } else if (date.match(/^\d{2}\/\d{2}$/) !== null) {
        value = date + '/';
      } else if (/^\d{3,4}\/?\d{0,}$/.test(date)) {
        value = value.substring(0, 2) + '/' + value.substring(2, value.length);
      } else if (/^\d{2}\/\d{2,}$/.test(date)) {
        value = value.substring(0, 2) + '/' + value.substring(3, 5) + '/' + value.substring(5, value.length);
      }
      return value;
    }
    return addSlashes(dateVal);
  }

}
