<div class="support-forms-container">
  <h2
    id="noa-header"
    tabindex="0"
    role="heading"
    aria-live="polite"
    class="section-header-two"
  >
    {{ content.labels.forms }}
  </h2>
  <div class="container">
    <div class="row">
      <div class="support-forms-top">
        <div class="text-style-1">
          {{ content.labels.support
          }}<a href="javascript:void(0);" target="_blank" (click)="onMemberServiceClick()">{{
            content.labels.memberServices
          }}</a>
        </div>
      </div>
      <h4 class="support-forms-headline">
        {{ content.labels.heading }}
      </h4>
      <ul>
        <li 
          class="link-container"
          *ngFor="let item of content.supportFormsLinks"
        >
          <img src="../../../assets//images/pdf.svg" class="icon-pdf" />
          <a
            href="{{ item.url }}"
            class="support-forms-doc"
            target="_blank"
            rel="noopener noreferrer"
            >{{ item.link }}</a
          >
        </li>
      </ul>
      <div class="support-forms-end-links">&nbsp;</div>
    </div>
  </div>
</div>
