<div class="uxd-theme-motif container">       
    <div class="reg-top-1 flex-column-center">
        <div class="ant-margin-top-20 width-100 full-width-alert-spacer" *ngIf="aslError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{aslError}}</span>
        </div>
    </div>
    <div class="max-width-elements">
        <h1 tabindex="-1" class="font-32 margin-bottom-48 text-left">{{jsonLabels?.Page_Heading}}</h1>
        <form [formGroup]="securityQuestionsForm" (ngSubmit)="sendToReview()">
            <div class="question-answer-container">
                <div class="form-item margin-bottom-40">
                    <div class="form-item clearfix">
                        <div #lgSelect="ngModel" data-uxd-dropdown-cmp
                            [(ngModel)]="dropDown1.selected" class="ant-lg-select form-item"
                            [labelText]="dropDown1.title" name="question1" id="question1"
                            [items]="dropDown1.options" [ngModelOptions]="{standalone: true}"
                            (click)="onChangeInput(1)" (keyup)="onChangeInput(1)" required [defaultText]="'Select One'">
                        </div>
                    </div>
                    <ul class="margin-top-14 font-12 error"
                        *ngIf="securityQuestionsForm.controls['question1']?.touched && securityQuestionsForm.controls['question1']?.invalid">
                        <li *ngIf="securityQuestionsForm.controls['question1']?.errors?.required">
                            <span class="fas fa-times font-14 margin-right-8"></span>
                            <span>{{jsonLabels?.Field_Labels.Question_1}}</span>
                            <span>{{jsonErrors.Required_err}}</span>
                        </li>
                    </ul>
                </div>
                <div class="form-item margin-bottom-40">
                    <label for="secAns1">{{jsonLabels?.Field_Labels.Answer_1}}</label>
                    <div class="mask-wrapper">
                        <input class="ant-text-input" #secAns1 formControlName="answer1" name="answer1"
                            (change)="onChangeInput(0)" id="secAns1" type="password" aria-required="true" aria-describedby="answer1Error"  (focus)="onFocus()" (blur)="onBlur()" >
                        <span (click)="showHide('secAns1', jsonLabels?.Field_Labels.Answer_1)" (keyup.enter)="showHide('secAns1', jsonLabels?.Field_Labels.Answer_1)" tabindex="0" role ="button" aria-pressed="false" [attr.aria-label]="'unmask ' + jsonLabels?.Field_Labels.Answer_1" 
                            class="fa md ant-info-icon ant-data-security-mask fa-eye-slash"></span>
                    </div>

                    <app-security-answer-validator [answer1Id]="'answer1Error'"
                        [Showanswer1]="securityQuestionsForm.controls['answer1']?.touched && securityQuestionsForm.controls['answer1']?.invalid"
                        [Showanswer1Required]="securityQuestionsForm.controls['answer1']?.errors?.required" [Showanswer1Requirement]="securityQuestionsForm.controls['answer1']?.errors?.minlength || securityQuestionsForm.controls['answer1']?.errors?.invalidnamechar
                                        || securityQuestionsForm.controls['answer1']?.errors?.hasDuplicate"
                        [answer1RequiredLabel]="jsonErrors && jsonErrors.Sec_Required_err" [answer1RequirementLabel]="jsonErrors && jsonErrors.SecAns_requirement"
                        [showTooltip1]="(showTooltips || securityQuestionsForm.controls['answer1'].dirty || securityQuestionsForm.controls['answer1']?.touched ) && (securityQuestionsForm.controls['answer1'].errors )"
                        [minLengthCss]="(!securityQuestionsForm.controls['answer1'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer1']?.errors?.minlength ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                        [invalidNameCharCss]="(!securityQuestionsForm.controls['answer1'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer1']?.errors?.invalidnamechar ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                        [duplciateCss]="(!securityQuestionsForm.controls['answer1'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer1']?.errors?.hasDuplicate? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                        [securityAnswerLengtherror]="jsonErrors && jsonErrors.SecAns_Len_err"
                        [securityAnswerInvaliderror]="jsonErrors && jsonErrors.SecAns_Invalid_err"
                        [securityAnswerDuplicateerror]="jsonErrors && jsonErrors.Unique_answer"
                        >
                    </app-security-answer-validator>

                </div>
            </div>
            <div class="question-answer-container">
                <div class="form-item margin-bottom-40">
                    <div class="form-item clearfix">
                        <div #lgSelect="ngModel" data-uxd-dropdown-cmp
                            [(ngModel)]="dropDown2.selected" class="ant-lg-select form-item"
                            [labelText]="dropDown2.title" name="question2" id="question2"
                            [items]="dropDown2.options" [ngModelOptions]="{standalone: true}"
                            (click)="onChangeInput(2)" (keyup)="onChangeInput(2)" required [defaultText]="'Select One'">
                        </div>
                    </div>
                    <ul class="margin-top-14 font-12 error"
                        *ngIf="securityQuestionsForm.controls['question2']?.touched && securityQuestionsForm.controls['question2']?.invalid">
                        <li *ngIf="securityQuestionsForm.controls['question2']?.errors?.required">
                            <span class="fas fa-times font-14 margin-right-8"></span>
                            <span>{{jsonLabels?.Field_Labels.Question_2}}</span>
                            <span>{{jsonErrors.Required_err}}</span>
                        </li>
                    </ul>
                </div>
                <div class="form-item margin-bottom-40">
                    <label for="secAns2"
                        class="answers">{{jsonLabels?.Field_Labels.Answer_2}}</label>
                    <div class="mask-wrapper">
                        <input class="ant-text-input" #secAns2 formControlName="answer2" name="answer2" (focus)="onFocus2()" (blur)="onBlur2()" 
                            (change)="onChangeInput(0)" id="secAns2" type="password" aria-required="true" aria-describedby="answer2Error">
                        <span (click)="showHide('secAns2', jsonLabels?.Field_Labels.Answer_2)" (keyup.enter)="showHide('secAns2', jsonLabels?.Field_Labels.Answer_2)"  tabindex="0" role ="button" aria-pressed="false" [attr.aria-label]="'unmask ' + jsonLabels?.Field_Labels.Answer_2" 
                            class="fa md ant-info-icon ant-data-security-mask fa-eye-slash"></span>
                    </div>

                <app-security-answer-validator [answer1Id]="'answer2Error'"
                    [Showanswer1]="securityQuestionsForm.controls['answer2']?.touched && securityQuestionsForm.controls['answer2']?.invalid"
                    [Showanswer1Required]="securityQuestionsForm.controls['answer2']?.errors?.required" 
                    [Showanswer1Requirement]="securityQuestionsForm.controls['answer2']?.errors?.minlength || securityQuestionsForm.controls['answer2']?.errors?.invalidnamechar
                                    || securityQuestionsForm.controls['answer2']?.errors?.hasDuplicate"
                    [answer1RequiredLabel]="jsonErrors && jsonErrors.Sec_Required_err" [answer1RequirementLabel]="jsonErrors && jsonErrors.SecAns_requirement"
                    [showTooltip1]="(showTooltips2 || securityQuestionsForm.controls['answer2'].dirty || securityQuestionsForm.controls['answer2']?.touched ) && (securityQuestionsForm.controls['answer2'].errors )"
                    [minLengthCss]="(!securityQuestionsForm.controls['answer2'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer2']?.errors?.minlength ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                    [invalidNameCharCss]="(!securityQuestionsForm.controls['answer2'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer2']?.errors?.invalidnamechar ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                    [duplciateCss]="(!securityQuestionsForm.controls['answer2'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer2']?.errors?.hasDuplicate? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                    [securityAnswerLengtherror]="jsonErrors && jsonErrors.SecAns_Len_err"
                    [securityAnswerInvaliderror]="jsonErrors && jsonErrors.SecAns_Invalid_err"
                    [securityAnswerDuplicateerror]="jsonErrors && jsonErrors.Unique_answer"
                    >
                </app-security-answer-validator>
                </div>
            </div>
            <div class="question-answer-container">
                <div class="form-item margin-bottom-40">
                    <div class="form-item clearfix">
                        <div #lgSelect="ngModel" data-uxd-dropdown-cmp
                            [(ngModel)]="dropDown3.selected" class="ant-lg-select form-item"
                            [labelText]="dropDown3.title" name="question3" id="question3"
                            [items]="dropDown3.options" [ngModelOptions]="{standalone: true}"
                            (click)="onChangeInput(3)" (keyup)="onChangeInput(3)" required [defaultText]="'Select One'">
                        </div>
                    </div>
                    <ul class="margin-top-14 font-12 error"
                        *ngIf="securityQuestionsForm.controls['question3']?.touched && securityQuestionsForm.controls['question3']?.invalid">
                        <li *ngIf="securityQuestionsForm.controls['question3']?.errors?.required">
                            <span class="fas fa-times font-14 margin-right-8"></span>
                            <span>{{jsonLabels?.Field_Labels.Question_3}}</span>
                            <span>{{jsonErrors.Required_err}}</span>
                        </li>
                    </ul>
                </div>
                <div class="form-item margin-bottom-40">
                    <label for="secAns3"
                        class="answers">{{jsonLabels?.Field_Labels.Answer_3}}</label>
                    <div class="mask-wrapper">
                        <input class="ant-text-input" #secAns3 formControlName="answer3" name="answer3"
                            (change)="onChangeInput(0)" (focus)="onFocus3()" (blur)="onBlur3()" id="secAns3" type="password" aria-required="true" aria-describedby="answer3Error">
                        <span (click)="showHide('secAns3', jsonLabels?.Field_Labels.Answer_3)" (keyup.enter)="showHide('secAns3', jsonLabels?.Field_Labels.Answer_3)" tabindex="0" role ="button" aria-pressed="false" [attr.aria-label]="'unmask ' + jsonLabels?.Field_Labels.Answer_2" 
                            class="fa md ant-info-icon ant-data-security-mask fa-eye-slash"><span class="sr-only">{{jsonLabels?.Field_Labels.MaskAnswer}}</span></span>
                    </div>
                    <app-security-answer-validator [answer1Id]="'answer3Error'"
                    [Showanswer1]="securityQuestionsForm.controls['answer3']?.touched && securityQuestionsForm.controls['answer3']?.invalid"
                    [Showanswer1Required]="securityQuestionsForm.controls['answer3']?.errors?.required" 
                    [Showanswer1Requirement]="securityQuestionsForm.controls['answer3']?.errors?.minlength || securityQuestionsForm.controls['answer3']?.errors?.invalidnamechar
                                    || securityQuestionsForm.controls['answer3']?.errors?.hasDuplicate"
                    [answer1RequiredLabel]="jsonErrors && jsonErrors.Sec_Required_err" [answer1RequirementLabel]="jsonErrors && jsonErrors.SecAns_requirement"
                    [showTooltip1]="(showTooltips3 || securityQuestionsForm.controls['answer3'].dirty || securityQuestionsForm.controls['answer3']?.touched ) && (securityQuestionsForm.controls['answer3'].errors )"
                    [minLengthCss]="(!securityQuestionsForm.controls['answer3'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer3']?.errors?.minlength ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                    [invalidNameCharCss]="(!securityQuestionsForm.controls['answer3'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer3']?.errors?.invalidnamechar ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                    [duplciateCss]="(!securityQuestionsForm.controls['answer3'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer2']?.errors?.hasDuplicate? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                    [securityAnswerLengtherror]="jsonErrors && jsonErrors.SecAns_Len_err"
                    [securityAnswerInvaliderror]="jsonErrors && jsonErrors.SecAns_Invalid_err"
                    [securityAnswerDuplicateerror]="jsonErrors && jsonErrors.Unique_answer"
                    >
                </app-security-answer-validator>
                </div>
            </div>
            <div class="form-item margin-bottom-70 margin-top-48">
                <div class="flex-horizontal-end">
                    <button type="button" class="btn btn-secondary margin-right-16"
                        (click)="back()" data-analytics="backButtonSecQuestionsReg">{{jsonLabels?.Buttons.Back}}</button>
                    <button type="submit"
                        class="btn btn-primary" data-analytics="nextButtonSecQuestionsReg">{{jsonLabels?.Buttons.Next}}</button>
                </div>
            </div>
        </form>
    </div>
</div>