import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CreateUsernamePassword {

    constructor() { }

    private model: any = {
        memberInput: {
            userName: "",
            password: "",
            retypePassword: "",
            emailAddress: "",
            accountRecoveryNumber: {
                phoneNumber: "",
                phoneType: ""
            },
			question1: "",
			answer1: "",
			question2: "",
            answer2: "",
            question3: "",
			answer3: "",
        },
        userNameData: {
            username: ""
        },
        userNameCheck: {
            checking: false,
            loading: false,
            available: null
        },
    }

    public getCreateUsernamePasswordModel() {
        return this.model;
    }

    public updateCreateUsernamePasswordModel(data) {
        this.model = {
            memberInput: {
                userName: data.value.userName,
                password: data.value.passwords?.password,
                retypePassword: data.value.passwords?.confirmPassword,
                emailAddress: data.value.emailAddress,
                accountRecoveryNumber: {phoneNumber: data.value.phone,
                                        phoneType: data.value.option},
                question1: "",
			    answer1: "",
			    question2: "",
                answer2: "",
                question3: "",
			    answer3: "",
            },
            userNameData: {
                username: data.value.userName,
            },
            userNameCheck: {
                checking: this.model.userNameCheck.checking,
                loading: this.model.userNameCheck.loading,
                available: this.model.userNameCheck.available
            }
        }
        return this.model;
    }

    public updateSecurityQuestions(model, data) {
        data.value.answer1 ? model.memberInput.answer1 = data.value.answer1: '';
        data.value.answer2 ? model.memberInput.answer2 = data.value.answer2: '';
        data.value.answer3 ? model.memberInput.answer3 = data.value.answer3: '';
        data.value.question1 ? model.memberInput.question1 = data.value.question1: '';
        data.value.question2 ? model.memberInput.question2 = data.value.question2: '';
        data.value.question3 ? model.memberInput.question3 = data.value.question3: '';
    }

    transformUsernameData(username) {
        this.model.userNameData.username = username;
        return this.model.userNameData;
    }

    resetUserNameCheck() {
        this.model.userNameCheck.checking = false;
        this.model.userNameCheck.loading = false;
        this.model.userNameCheck.available = null;
        return this.model;
    }

}
