<div class="connect-with-care-container" *ngIf="showconnectCare">
    <div class="connect-care-container">
        <h2 class="no-outline section-header-two">{{jsonLabels?.Header}}</h2>
        <div class="callout-container">
            <div  *ngFor="let item of content" class="callout-component">
                <div class="callout-link" *ngIf="item.enabled">
                    <div class="callout-row">
                        <div aria-hidden="true" class="callout-icon" id="callout-link-5-icon"><span callout-icon=""
                                [class]="item.class"></span></div>   
                        <div class="callout-text">
                            <h3 class="no-outline section-header-three section-header-three-small">{{item.label}}</h3>
                            <p *ngIf="(memberContext?.stateLob === 'FLMCD' && memberContext?.classId.substring(0,2) === 'HK' && item.analyticsTag === 'benefitsLinkConnectHome'); else templateName" class="no-outline callout-paragraph" >{{item.healthy_kids_text}}</p>
                            <ng-template #templateName>
                                <p class="no-outline callout-paragraph" >{{item.text}}</p>
                            </ng-template>
                            <a *ngIf="item.type === 'internal' && item.url" class="callout-link" routerLink="{{item.url}}" [attr.aria-label]="item.aria_label" [attr.data-analytics]="item.analyticsTag" target="{{item.target}}">{{item.link}}</a>
                            <a *ngIf="item.type === 'internal' && !item.url" class="callout-link" href="" [attr.aria-label]="item.aria_label" [attr.data-analytics]="item.analyticsTag" target="{{item.target}}" (click)="liveChatPopUp($event)">{{item.link}}</a>
                            <a *ngIf="!isObject(item.url) && item.type === 'external'" class="callout-link" href="{{item.url}}" [attr.aria-label]="item.aria_label" [attr.data-analytics]="item.analyticsTag" target="{{item.target}}">{{item.link}}</a>
                            <a *ngIf="isObject(item.url) && item.type === 'external' && !planCode" class="callout-link" href="{{item.url[classId.substring(0,2)] ? item.url[classId.substring(0,2)] : item.url['default'] }}" [attr.aria-label]="item.aria_label" [attr.data-analytics]="item.analyticsTag" target="{{item.target}}">{{item.link}}</a>
                            <a *ngIf="isObject(item.url) && item.type === 'external' && planCode" class="callout-link" href="{{item.url[planCode] ? item.url[planCode] : item.url['default'] }}" [attr.aria-label]="item.aria_label" [attr.data-analytics]="item.analyticsTag" target="{{item.target}}">{{item.link}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>