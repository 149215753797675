import { ContactDetail } from "@anthem/mbrportal-pharmacy/enums/vendorSelectedPrescriber";
import {
  CommunicationPreferences,
  ContactDetails,
  Notifications,
} from "@anthem/mbrportal-profile/interfaces/communicationPreferences";

export const data: Notifications[] = [
 // Notifications.ORDER_STATUS,
  //Notifications.REFILL,
];
export const commPrefs: CommunicationPreferences = {
  termsAndConditionsAccepted: true,
  commPreferences: [
    {
      communicationChannel: "9879879877",
      communicationChannelTypeCode: "Text",
      communicationChannelTypeDescription: "Text",
      notifications: data,
    },
    {
      communicationChannel: "test@test.com",
      communicationChannelTypeCode: "Email",
      communicationChannelTypeDescription: "Email",
      notifications: data,
    },
    {
      communicationChannel: "",
      communicationChannelTypeCode: "Push",
      communicationChannelTypeDescription: "Push",
      notifications: data,
    },
  ],
} as CommunicationPreferences;

export const patientContactDetails: ContactDetails = {
  email: "ROBIN.JOSE@CARELON.COM",
  phone: '789789999',
  text: '12iuii'
}