import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Router } from '@angular/router';
import { QuickConnectButton } from '../model/labels';


@Component({
  selector: 'app-quick-connect-buttons',
  templateUrl: './quick-connect-buttons.component.html',
  styleUrls: ['./quick-connect-buttons.component.scss']
})
export class QuickConnectButtonsComponent implements OnInit {
  quickConnectButtons: QuickConnectButton[] = [];

  constructor(
    private jsonSvc: JsonContentService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.jsonSvc.getJSON('new-dashboard').subscribe(data => {
      for (let quickButton in data.dashboard.quickButtons) {
        this.quickConnectButtons.push(data.dashboard.quickButtons[quickButton]);
      }      this.quickConnectButtons.forEach(quickButton => { quickButton.show = !quickButton.restrictions.some(buttonRestrictions => data.restrictions.includes(buttonRestrictions)); });
    }
    );
  }

  onClick(buickButton: QuickConnectButton) {
    this.router.navigateByUrl(buickButton.url);
  }
}