import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DropDown } from "../models/payment-methods";
import { PaymentMethodsService } from "../services/payment-methods.service";
import { PaymentMethodDataService } from 'sydmed/src/app/secure/pharmacy/services/payment-method-data.service';
import { AlertService } from "@anthem/uxd/alert";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service";
import { HelperProperties } from "../models/helper.properties";
import { CheckBox } from '../models/labels';
import {
  SuccessRegion,
  CheckBoxNames,
  DEFAULT_NUMBER_OF_MONTHS,
  DEFAULT_NUMBER_OF_YEARS
} from "../models/enum-values";
// import { AddCreditCard } from "../models/labels";
import { default as CCJsonData } from "../assets/data/cardJsonData.json";
import { Utils } from "../models/utils";


@Component({
  selector: "app-add-credit-payment",
  templateUrl: "./add-credit-payment.component.html",
  styleUrls: ["./add-credit-payment.component.scss"]
 
})
export class AddCreditPaymentComponent implements OnInit {
  addCardForm: FormGroup;
  month: string = "";
  year: string = "";
  accountName: string;
  isChecked: boolean = false;
  helperProperties: HelperProperties;
  loader: boolean = false;
  errMsg: string = null;
  expiredDate: boolean = false;
  expiredYear: boolean = false;
  optionColor: boolean = false;
  counter: number = 0;

  selectMonth: DropDown = {
    title: "",
    options: []
  };

  selectYear: DropDown = {
    title: "",
    options: []
  };

  checkbox: CheckBox;
  isEdit: boolean = false;

  /**
   * Required inputs to be passed
   */
  // pass this parameter with false if checkbox is not required
  @Input("showCheckBox") showCheckBox: boolean = true;
  // provide this parameter with false if buttons are not needed.
  @Input("showButtons") showButtons: boolean = true;
  @Input("labels") labels: any = CCJsonData;
  @Input("errObj") errObj: any;
  @Input("lineOfBusiness") lineOfBusiness;
  // To add specialty card
  @Input("paymentMethodId") paymentMethodId;

  // pass this if it needs editing the card expiry details
  @Input("editDataObj") editDataObj;
  @Input() showSuccess = true;

  @Output() isCancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("cardnumber") cardnumber: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private paymentMethodsSvc: PaymentMethodsService,
    protected paymentDataSvc: PaymentMethodDataService,
    private alertSvc: AlertService,
    private errorCodeMapperSvc: ErrorCodeMapperService
  ) {
    this.helperProperties = new HelperProperties(alertSvc);
    this.addCardForm = this.formBuilder.group({
      cardNumber: ["",[Validators.required, Validators.minLength(13), Validators.maxLength(16)]],
      textCheckbox: [this.isChecked]
    });
  }

  ngOnInit(): void {
    this.helperProperties.scrollToTop();
    this.setMonthOptions();
    this.setYearOptions();
    this.setCheckboxParams();
  }

  ngOnChanges(changes) {
    this.setDefaultValuesForInputs(changes);
  }

  setDefaultValuesForInputs(changes) {
    if (!changes.hasOwnProperty("showCheckBox")) {
      this.textCheckbox.setValue(false);
    } else if(this.editDataObj && this.editDataObj.data && this.editDataObj.data.preferredAccount) {
      this.textCheckbox.setValue(this.editDataObj.data.preferredAccount);        
    }
    this.textCheckbox.updateValueAndValidity();

    if (!changes.hasOwnProperty("labels")) {
      this.labels = CCJsonData["AddCardLabels"];
    }
    if (!changes.hasOwnProperty("errObj")) {
      this.errObj = CCJsonData.ErrMsgs;
    }
    if (changes.hasOwnProperty("editDataObj")) {
      if (this.editDataObj.data) {
        this.setDataForEditCard(this.editDataObj.data);
      }
    }
  }

  /**
   * Set function to generate 12 months in the dropdown
   */
  setMonthOptions() {
    // hardcoding to display 12 months in the dropdown
    for (let i = 1; i <= DEFAULT_NUMBER_OF_MONTHS; i++) {
      if (i < 10) {
        this.selectMonth.options.push({ label: "0" + i, value: "0" + i });
      } else {
        this.selectMonth.options.push({ label: i.toString(), value: i.toString()  });
      }
    }
  }

  /**
   * Set function to Generate upto 20 years in the dropdown
   */
  setYearOptions() {
    //Get the credit card year on file to start year count 
    // hardcoding to display 20 years in the dropdown from current year
    let counterYear = new Date().getFullYear();
    for (let i = 0; i <= DEFAULT_NUMBER_OF_YEARS; i++) {
      this.selectYear.options.push({
        label: (counterYear + i).toString(),
        value: (counterYear + i).toString(),
      });
    }
  }

  setDataForEditCard(cardObj) {
    // let cardNumControl = this.addCardForm.get("cardNumber");
    this.cardNumber.clearValidators();
    this.cardNumber.updateValueAndValidity();

    this.accountName = Utils.getAccountNameForEdit(cardObj.accountName);
    this.month = cardObj.expirationDate.split("/")[0];
    this.year = cardObj.expirationDate.split("/")[1];
    this.paymentMethodId = cardObj.paymentMethodId;
    this.checkValidExpiry();
  }

  setCheckboxParams() {
    this.checkbox = this.helperProperties.setCheckboxParams(
      CheckBoxNames.CARD_CHECKBOX,
      CheckBoxNames.CARD_CHECKBOX,
      this.labels.Labels.DefaultMethodInfo
    );
  }

  addCard(form) {
    if (form.valid && this.month && this.year && !this.expiredDate) {
      this.loader = true;

      if (this.lineOfBusiness) {
        this.lineOfBusiness = this.lineOfBusiness.toUpperCase();

        if (this.editDataObj) {
          this.editCardAPI(Utils.getPayloadForEditCard(
            this.lineOfBusiness,
            form,
            this.editDataObj.data.accountName,
            this.month,
            this.year,
            this.paymentMethodId
          ));
        } else {
          this.addCardAPI(Utils.getPayloadForAddCard(
            this.lineOfBusiness,
            form,
            this.month,
            this.year,
            this.paymentMethodId
          ));
        }
      }
    } else {
      this.addCardForm.markAllAsTouched();
      if(!this.addCardForm.controls['cardNumber'].valid){
        this.cardnumber.nativeElement.focus();
      }
      else if(Number(this.month) < 1)
      {
        document.getElementById("monSelect_button").focus();
      }
      else if(Number(this.year) < 1)
      {
        document.getElementById("yearSelect_button").focus();
      }

    }
  }

  addCardAPI(payLoad) {
    // console.log('made it to the API');
    this.paymentMethodsSvc.addCard(this.lineOfBusiness, payLoad).subscribe(
      (res: any) => {
        this.handleRes(payLoad);
      },
      (err: any) => {
        this.handleErr(err);
      }
    );
  }

  editCardAPI(payLoad) {
    this.paymentMethodsSvc.editCard(this.lineOfBusiness, payLoad).subscribe(
      (res: any) => {
        this.handleRes(payLoad);
      },
      (err: any) => {
        this.handleErr(err);
      }
    );
  }

  handleRes(payLoad: any) {
    if (this.showSuccess) {
      this.loader = false;
      this.setSuccessMessage();
    } else {
      // reload payment service
      this.paymentDataSvc.reloadPaymentList(this.lineOfBusiness, payLoad, true);
      this.loader = false;
    }
    this.onCancel();
  }

  handleErr(err) {
    this.loader = false;
    this.helperProperties.resetFormWithValues(this.addCardForm);
    this.errMsg = this.errorCodeMapperSvc.errorCodeMapper(err, this.errObj);
  }

  /**
   * Setting the success message based on if 
   * its an editing a card/ adding a new card
   */
  setSuccessMessage() {
    // checking if the editDataObj exists to set updatesuccess message
    if (this.editDataObj) {
      this.helperProperties.successAlert(
        this.labels.SuccessMessages.UpdatePaymentSuccessful,
        SuccessRegion.REGION_NAME
      );
    } else {
      this.helperProperties.successAlert(
        this.labels.SuccessMessages.PaymentAddSuccessful,
        SuccessRegion.REGION_NAME
      );
    }
  }

  /**
   * Check if expiry date is in future
   */
  checkValidExpiry() {   
    let selectedDate = new Date(Number(this.year), Number(this.month), 1, 0, 0, 0, 0);
    let selectedYear = Number(this.year);
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();

    this.expiredDate = selectedDate < currentDate;   
    this.expiredYear = currentYear > selectedDate.getFullYear();   

    //Edit mode - if selected year is in the past and year is not zero 
    //Add mode - year is zero 
    if(selectedYear < currentYear && Number(this.year)!==0)
    {
      //Month and Year border and text
      document.getElementById("monSelect_button-text").style.color="#d20a3c";
      document.getElementById("monSelect_button").style.borderColor="#d20a3c";        
      document.getElementById("yearSelect_button-text").style.color="#d20a3c";
      document.getElementById("yearSelect_button").style.borderColor="#d20a3c";              
      //Month options text
      for(this.counter=0;this.counter < 12;++this.counter){
        var optionName = "monSelect_option-" + this.counter + "_text";
        document.getElementById(optionName).style.color="#d20a3c";            
      }
      let difference = currentYear - selectedYear;
      for(this.counter=0;this.counter < difference;++this.counter){
        var optionName = "yearSelect_option-" + this.counter + "_text";
        document.getElementById(optionName).style.color="#d20a3c";            
      }
    }
    //if selected year is the current year
    else if(selectedYear == currentYear) 
    {
      //Month and Year border and text
      document.getElementById("yearSelect_button").style.borderColor="black";        
      document.getElementById("yearSelect_button-text").style.color="black";
      document.getElementById("monSelect_button").style.borderColor="black";        
      //Month options text
      for(this.counter=0;this.counter < 12;++this.counter){
        var optionName = "monSelect_option-" + this.counter + "_text";
        if(this.counter < currentMonth){
          document.getElementById(optionName).style.color="#d20a3c";            
        }else{
          document.getElementById(optionName).style.color="black";            
        }
      }
      //adjust border and text color
      if(Number(this.month) <= currentDate.getMonth()){          
        document.getElementById("monSelect_button-text").style.color="#d20a3c";
        document.getElementById("monSelect_button").style.borderColor="#d20a3c";                
      }
      else
      {
        document.getElementById("monSelect_button-text").style.color="black";
        document.getElementById("monSelect_button").style.borderColor="black";                
      }
    }
    //if selected year is in the future
    else if(selectedYear > currentYear)
    {
      //Month and Year dropdown and text
      document.getElementById("monSelect_button-text").style.color="black";
      document.getElementById("monSelect_button").style.borderColor="black";                
      document.getElementById("yearSelect_button-text").style.color="black";
      document.getElementById("yearSelect_button").style.borderColor="black";        
      //Month options text
      for(this.counter=0;this.counter < 12;++this.counter){
        var optionName = "monSelect_option-" + this.counter + "_text";
        document.getElementById(optionName).style.color="black";            
      }
    }
  }

  onCancel() {
    this.isCancel.emit(true);
  }

  get cardNumber() {
    return this.addCardForm.get("cardNumber");
  }

  get textCheckbox() {
    return this.addCardForm.get("textCheckbox");
  }

}
