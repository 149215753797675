<div class="font-group col-sm-9 col-md-6 pharmacy-cart">
  <span *ngIf="cartRouterLink !== null && cartCount; else disableLink" >
    <a href="javascript:void(0)" routerLink="{{ cartRouterLink }}" tabindex="0" class="ant-action-link link-text"
        [attr.aria-label]="ariaLabel | si:{x: cartCount}">
        <span *ngIf="cartCount" id="cartCount">{{ cartCount }}</span>
        <span class="motif-icon motif-shopping-cart link-text primary-color"></span>
        <span id="cartLabel">{{ cartLabel }}</span>
    </a>
  </span>
  <ng-template #disableLink>
    <span class="disabledCart">
      <span class="motif-icon motif-shopping-cart"></span>
      <span id="disabledCartLabel">{{ cartLabel }}</span>
    </span>
  </ng-template>
</div>