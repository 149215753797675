<div class="container" *ngIf="hasCareCoordinator; else PageError">
    <div *ngIf="content; else loadingOrError">

        <!-- Care Team header start -->
        <h2 class="section-header-two" [attr.aria-label]="content.header + memberData.memberName" [innerText]="content.header + memberData.memberName"></h2>
        <!-- Care Team header end -->

        <!-- Care Team sub header start -->
        <p [innerHTML]="content.subHeader"[attr.aria-label]="content.subHeader" class="care-coordinator-sub-header"></p>
        <!-- Care Team sub header end -->

        <!-- Care Team MCG header start, applies only to TXMMP -->
        <p [innerHTML]="content.subHeaderTwo"[attr.aria-label]="content.subHeaderTwo" class="care-coordinator-sub-header"></p>
        <!-- Care Team MCG header end -->

        <!-- Care Team loader start -->
        <div class="care-coordinator-loader" data-uxd-data-loader-cmp *ngIf="careTeamLoader">
            <span [innerHTML]="content.loadingMessage"></span>
        </div>
         <!-- Care Team loader end -->

        <!-- Care Team tiles start-->
        <div class="pharmacy care-team-container pcp-tile-view-container cards-wrapper" *ngIf="!careTeamLoader">

            <!-- Display tile for PCP start -->
            <div class="card pcp-medium-card card-flat card-popover" *ngIf="pcpInfo" tabindex="0" [attr.aria-label]="content.currentPcpLabel + ' ' + pcpInfo.name + ' ' + pcpInfo.ariaAddress">
                <div class="care-team-tile-content-container-II">
                    <!-- PCP - Name -->
                    <div class="care-team-coordinator-container">
                        <div id="pcp-name" class="care-team-name care-team-align-I" [innerHTML]="pcpInfo.name"></div>
                        <div class="care-team-align-II">
                            <span class="care-team-banner-text" [innerText]="content.pcpBanner"></span>
                        </div>
                    </div>

                    <!-- PCP - Address -->
                    <div class="care-team-address" *ngIf="pcpInfo.address">
                        <span class="motif-icon motif-marker"></span>
                        <div id="pcp-address" class="care-team-contact-info" [innerHTML]="pcpInfo.address"></div>
                    </div>

                    <!-- PCP - Phone number -->
                    <div id="pcp-phno-mobile" class="care-team-contact-info mobile" *ngIf="pcpInfo.phoneNumber">
                        <span aria-hidden="true" class="motif-icon motif-phone"></span>
                        <a href="tel:{{pcpInfo.phoneNumber}}" class="care-team-phn-link"
                            [attr.aria-label]="content.phoneNumberAria + ' ' + pcpInfo.phoneNumber"
                            [attr.data-analytics]="content.analytics.phoneNumberLinkPcp">
                            <span [innerText]="pcpInfo.phoneNumber"></span>
                        </a>
                    </div>
                    <div id="pcp-phno-desktop" class="care-team-contact-info desktop" *ngIf="pcpInfo.phoneNumber">
                        <span aria-hidden="true" class="motif-icon motif-phone"></span>
                        <span [innerText]="pcpInfo.phoneNumber"></span>
                    </div>
                </div>

                <!-- PCP - Change PCP Button -->
                <div class="pcp-care-team-btn-container" *ngIf="enableChangePcp && !isLockedIn">
                    <button id="pcp-button" class="btn btn-primary"
                        [attr.aria-label]="content.aria.changePcpButton"
                        [innerText]="content.changePcp" (click)="onAddUpdatePCP()"
                        [attr.data-analytics]="content.analytics.changePcpButton">
                    </button>
                </div>
                <p *ngIf="!enableChangePcp || isLockedIn" [attr.aria-label]="content.disableChangePcpMessage + content.disableChangePcpPhoneDesktop">
                    <span [innerHTML]="content.disableChangePcpMessage"></span>
                    <span class="mobile" [innerHTML]="content.disableChangePcpPhoneMobile"></span>
                    <span class="desktop" [innerHTML]="content.disableChangePcpPhoneDesktop"></span>
                </p>
            </div>
            <!-- Display tile for PCP end -->

            <!-- Display tile for primary care coordinator start -->
            <div class="card pcp-medium-card card-flat card-popover" *ngIf="careTeamDetail && careTeamDetail.primaryCareCoordinator && enableCareCoordinator" tabindex="0" [attr.aria-label]="content.primaryCCLabel + ' ' + careTeamDetail.primaryCareCoordinator.name">
                <div class="care-team-tile-content-container-II">
                    <!-- Primary Care Coordinator - Name -->
                    <div class="vgr-margin-bottom-half care-team-coordinator-container">
                        <div id="cc-name" class="care-team-name care-team-align-I" [innerText]="careTeamDetail.primaryCareCoordinator.name"></div>
                        <div>
                            <span class="care-team-banner-text" [innerText]="content.careCoordinatorBanner"></span>
                        </div>
                    </div>

                    <!-- Primary Care Coordinator - Phone number -->
                    <div id="cc-phno-mobile" class="care-team-contact-info vgr-margin-bottom-half mobile" *ngIf="careTeamDetail.primaryCareCoordinator.phoneNumber">
                        <span aria-hidden="true" class="motif-icon motif-phone"></span>
                        <a href="tel:{{ careTeamDetail.primaryCareCoordinator.phoneNumber }}" class="care-team-phn-link"
                            [attr.aria-label]="content.phoneNumberAria + ' ' + careTeamDetail.primaryCareCoordinator.phoneNumber"
                            [attr.data-analytics]="content.analytics.phoneNumberLinkPrimarcyCC">
                            <span [innerText]="careTeamDetail.primaryCareCoordinator.phoneNumber"></span>
                        </a>
                    </div>
                    <div id="cc-phno-desktop" class="care-team-contact-info vgr-margin-bottom-half desktop" *ngIf="careTeamDetail.primaryCareCoordinator.phoneNumber">
                        <span aria-hidden="true" class="motif-icon motif-phone"></span>
                        <span [innerText]="careTeamDetail.primaryCareCoordinator.phoneNumber"></span>
                    </div>

                    <!-- Primary Care Coordinator - Email -->
                    <div id="cc-email" class="care-team-contact-info vgr-margin-bottom-half" *ngIf="careTeamDetail.primaryCareCoordinator.email">
                        <span aria-hidden="true" class="motif-icon motif-message"></span>
                        <a href = "mailto: {{ careTeamDetail.primaryCareCoordinator.email }}">
                            <div class="care-team-contact-info vgr-margin-bottom-1" [attr.aria-label]="content.emailAria + ' ' + careTeamDetail.primaryCareCoordinator.email"
                            [innerHTML]="careTeamDetail.primaryCareCoordinator.email"></div>
                        </a>
                    </div>
                </div>
                <!-- Send a Message button SSO to ASCA-->
                <app-secure-sso *ngIf="enableSendMsg" [ssoId]="content.ascaSsoLinks.subType" [analytics]="content.analytics.sendMessageButton" [ariaLabel]="content.aria.sendMessageButton + ' ' + careTeamDetail.primaryCareCoordinator.name" [isButton]="true" class="syd-link syd-program-link" [linkText]="content.ascaSsoLinks.linkText"></app-secure-sso>
                <!-- Enable chat with care coordinator-->
                <button *ngIf="enableChatWithCareCoordinator" (click)="startCareCoordinatorChat($event)"
                    [attr.data-analytics]="content.analytics.careCoordinatorChat"
                    class="btn btn-secondary">{{content.chatWithCareCoordinator}}</button>
            </div>
            <!-- Display tile for primary care coordinator end -->

            <!-- Display tile for co managing care coordinator start -->
            <div class="card pcp-medium-card card-flat card-popover" *ngIf="careTeamDetail && careTeamDetail.coCareCoordinatorList && careTeamDetail.coCareCoordinatorList.length > 0 && enableCoCareCoordinator">
                <div class="vgr-margin-bottom-1 co-manager-cc">
                    <span class="care-team-banner-text" [innerHTML]="content.coManagingCareCoordinatorBanner"></span>
                </div>
                <div [ngClass]="careTeamDetail.coCareCoordinatorList.length > 3 ? 'care-team-tile-content-container' : 'care-team-tile-content-container-II'">
                    <div *ngFor="let coordinator of careTeamDetail.coCareCoordinatorList || []; let i = index">
                    
                        <!-- Co Managing Care Coordinator - Name -->
                        <div [id]="'care-team-cc-name' + i" class="care-team-name co-manager vgr-margin-bottom-half" [innerHTML]="coordinator.name" [attr.aria-label]="content.coManagingCCLabel + ' ' + coordinator.name"></div>

                        <!-- Co Managing Care Coordinator - Phone number -->
                        <div class="care-team-contact-info vgr-margin-bottom-half mobile" *ngIf="coordinator.phoneNumber">
                            <span aria-hidden="true" class="motif-icon motif-phone"></span>
                            <a href="tel:{{ coordinator.phoneNumber }}"
                                class="care-team-phn-link"
                                [attr.aria-label]="content.phoneNumberAria + ' ' + coordinator.phoneNumber" [attr.data-analytics]="content.analytics.phoneNumberLinkCC"
                                [id]="'care-team-cc-phone-number-link' + i">
                                <span [innerText]="coordinator.phoneNumber"></span>
                            </a>
                        </div>
                        <div id="cmco-phno-desktop" class="care-team-contact-info vgr-margin-bottom-half desktop" *ngIf="coordinator.phoneNumber">
                            <span aria-hidden="true" class="motif-icon motif-phone"></span>
                            <span [innerText]="coordinator.phoneNumber"></span>
                        </div>

                        <!-- Co Managing Care Coordinator - Email -->
                        <div id="cmco-email" class="care-team-contact-info vgr-margin-bottom-half" *ngIf="coordinator.email">
                            <span aria-hidden="true" class="motif-icon motif-message"></span>
                            <a href = "mailto: {{ coordinator.email }}">
                                <div [id]="'care-team-cc-address' + i" [attr.aria-label]="content.emailAria + ' ' + coordinator.email" class="care-team-contact-info vgr-margin-bottom-1" [innerHTML]="coordinator.email"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Display tile for co managing care coordinator end -->
        </div>
        <!-- Care Team tiles end-->

        <div class="ng-star-inserted vgr-margin-bottom-2" *ngIf="(pcpErrorStatus && !enableCareCoordinator) || (pcpErrorStatus && careCoordinatorErrorStatus)">
            <!-- Display no results section if neither PCP nor care coordinator is assigned-->
            <div *ngIf="((pcpErrorStatus === 404 && !enableCareCoordinator) || (pcpErrorStatus === 404 && careCoordinatorErrorStatus === 404)); else apiError" class="pcp-no-res-err-container" [attr.aria-label]="content.noResultsMessage + ' ' + content.noResultsMessage">
                <div class="motif-icon motif-search-1"></div>
                <p class="care-team-no-results" [innerHTML]="content.noResults"></p>
                <div *ngIf="enableChangePcp && !isCaregiver">
                    <p [innerHTML]="content.noResultsMessage"></p>
                    <a class="vgr-margin-bottom-1" href="javascript:void(0);" [attr.aria-label]="content.aria.assignPcpLink" (click)="onAddUpdatePCP()" [attr.data-analytics]="content.analytics.assignPcpLink" [innerHTML]="content.assignPcp"></a>
                </div>
                <p *ngIf="!enableChangePcp && !isCaregiver" [attr.aria-label]="content.disableChangePcpMessage + content.disableChangePcpPhoneDesktop">
                    <span [innerHTML]="content.disableChangePcpMessage"></span>
                    <span class="mobile" [innerHTML]="content.disableChangePcpPhoneMobile"></span>
                    <span class="desktop" [innerHTML]="content.disableChangePcpPhoneDesktop"></span>
                </p>
                <p *ngIf="!enableChangePcp && isCaregiver">
                    <span [innerHTML]="content.noResultsMessage"></span>
                </p>
            </div>

            <!-- API Error Message -->
            <ng-template #apiError class="pcp-warn-error vgr-margin-bottom-1" role="alert" aria-atomic="true">
                <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                    <span [innerHTML]="content.careTeamErrorMessage"></span>
                </div>
            </ng-template>
        </div>
    </div>
    <ng-template #loadingOrError>
        <div *ngIf="genericError; else loading" class="container" data-uxd-alert-cmp-v2
            [alert]="{alertType: 'negetive'}">
            <span [innerHTML]="genericError"></span>
        </div>
        <ng-template #loading>
            <div class="loading">
                <div class="care-coordinator-content-loader" data-uxd-data-loader-cmp="" class="container">
                    <span [innerHTML]="'Loading... please wait.'"></span>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>

<ng-template #PageError>
    <div class="container">
      <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span [innerHTML]="content?.genericError"></span>
      </div>
    </div>  
  </ng-template>