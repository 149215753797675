import { Component, Input } from '@angular/core';
@Component( {
  selector: 'app-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: [ './cart-icon.component.scss' ]
} )
export class CartIconComponent {
  @Input() cartRouterLink: string;
  @Input() cartLabel: string;
  ariaLabel = 'Cart, {x} items in cart';
  @Input() cartCount = 0;
  constructor() { }
}
