import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-grievances',
  template: '',
  styleUrls: []
})
export class OnlineGrievancesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
