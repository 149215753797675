<div class="container">
    <div *ngIf="contentLoaded; else loadingOrError">
        <h2 class="section-header-two">{{ labels.CostCalculatorHeading }}</h2>
        <p class="padding-bottom-32">{{ labels.CostCalculatorIntroParagraph }}</p>
        <div class="cost-calculator-btn">
            <app-secure-sso *ngIf="hasSSOConfigOrStaticUrl.hasSsoLink" [ssoId]="labels?.Config?.SSOId" [isButton]="true" [linkText]="labels?.CalculateCostButton">
            </app-secure-sso>
            <a *ngIf="hasSSOConfigOrStaticUrl.hasStaticUrls" [href]="labels?.staticUrls?.url" class="btn btn-primary" [attr.target]="labels?.staticUrls?.target" [attr.data-analytics]="labels?.CalculateCostButtonAnalytics">
                <span aria-hidden="true" [innerText]="labels?.CalculateCostButton"></span>
            </a>
        </div>
    </div>
    <ng-template #loadingOrError>
        <div *ngIf="error; else loading" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negetive'}">
            <div>Failed to load JSON content.</div>
        </div>
        <ng-template #loading>
            <uxd-spinner class="col-xxs-12 col-xs-12"></uxd-spinner>
            <div class="loading-text" [innerHTML]="'Loading... please wait.'"></div>
        </ng-template>
    </ng-template>
</div>