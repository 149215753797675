<div class="container prescriptions-container">
  <div class="flex-item col-md-6">
    <!--header section-->
    <div class="header-section">
      <div class="prescription-header">
        <b>{{ content.prescriptionHeader }}</b>
      </div>
    </div>
    <div class="body">
      <div class="description">
        {{description}}
      </div>
      <div
        *ngIf="!eligiblePrescriptions.length"
        class="prescription-header no-prescription-message"
      >
        <div id="errorMessageAlerts" class="error">
          <inline-alert-container
            #noPrescriptionsAlert
            role="alert"
            [name]="'no-prescription-message-alert'"
            [alertType]="alertPosition"
          ></inline-alert-container>
        </div>
      </div>

      <!-- table section-->
      <div class="table-section">
        <table class="table" *ngFor="let prescription of eligiblePrescriptions">
          <tr class="table-header">
            <th class="table-header" scope="col">
              {{ prescription.drug.drugName }}
            </th>
            <th class="table-header" scope="col">
              {{ content.fullFilledBy }}
            </th>
            <th class="table-header" scope="col">
              {{ content.refillsLeft }}
            </th>
          </tr>
          <tr class="table-row">
            <td class="table-data">{{ memberName }} ({{ dateOfBirth }})</td>
            <td class="table-data">{{ prescription.fulfilledBy }}</td>
            <td class="table-data">{{ prescription.refillsLeft }}</td>
          </tr>
        </table>
      </div>

      <!-- mini view section-->

      <div class="mini-widget-section margin-top-20">
        <div *ngFor="let prescription of eligiblePrescriptions">
          <div class="prescription-details-section margin-top-15">
            <span id="drugName"
              ><b>{{ prescription.drug.drugName }}</b></span
            >
            <span id="memberDeatils">{{ memberName }} ({{ dateOfBirth }})</span>
            <span id="fullFilledBy"
              ><b>{{ content.fullFilledBy }}</b>
              {{ prescription.fulfilledBy }}</span
            >
            <span id="refillsLeft"
              ><b>{{ content.refillsLeft }}</b>
              {{ prescription.refillsLeft }}</span
            >
            <span id="line"></span>
          </div>
        </div>
      </div>
    </div>
    <!-- button section-->
    <div class="button-section margin-bottom-50 margin-top-25">
      <button class="btn btn-primary" (click)="navigateToViewprescriptions()" data-analytics="viewAllPresPharmDb">
        {{ content.primaryButtonName }}
      </button>
    </div>
  </div>
</div>
