import { Component, OnInit } from '@angular/core';
import { SpecialtyInformation } from '../../models/bioplus.model';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { BenefitsService } from '../../../benefits/services/benefits.service';

@Component({
  selector: 'app-specialty-information',
  templateUrl: './specialty-information.component.html',
  styleUrls: ['./specialty-information.component.scss']
})
export class SpecialtyInformationComponent implements OnInit {

  public labels: SpecialtyInformation;

  constructor(private jsonService: JsonContentService, private titleService: InterCommunicationService, public benefitsService: BenefitsService) { }

  ngOnInit(): void {
    this.getLabels();
  }

  getLabels(): void{
    this.jsonService.getJSON("bioplus-specialty-information").subscribe((data) => {
      this.titleService.raiseEvent({ message: data.pageTitle, title: 'HEADER_TITLE' });
      this.labels = data;
    })
  }
}
