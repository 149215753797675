
export class DtmCategoryInfoTcp {
  channel = "";
  subChannel1 = "";
  subChannel2 = "";
}

export class DtmPageInfoTcp {
  pageName = "";
  assetId = "";
  destinationURL = "";
  referringURL = "";
}

export class DtmDigitalDataTcp {
  data = {
    environment: "",
    page: {
      pageInfo: new DtmPageInfoTcp(),
      category: new DtmCategoryInfoTcp(),
      errorCode: "",
      errorMessage: "",
      search: {
        topicsSearchQuery: ""
      }
    },
    customer: {},
    version: {
      versionInfo: {
        dataLayerVersion: "1.2",
      },
    },
  };

  setPageData() {}

  setSessionData() {}
}
