import { AppealsAndGrievanceServices } from '../../services/appeals-and-grievances.service';
import { AttachFiles, ErrorMsgs } from '../../models/labels';
import { AttachmentErrorMsgsLabels } from './../../models/labels';
import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { FileAttachmentService } from 'sydmed/libs/file-attachment/services/file-attachment.service';
import { FileDetails } from 'sydmed/libs/file-attachment/models/fileData';
import { AttachmentActionType, FileFormats, FormType, UploadAttachmentException, UploadAttachmentRequest } from 'gbd-models';
import { HelperService } from '../../utils/helper.service';
import { HttpClient } from '@angular/common/http';
import { InlineAlertContainerComponent, AlertService, UniversalAlertType } from '@anthem/uxd/alert';
import { Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['../../appeals-and-grievances.scss']
})

export class FileUploadComponent implements OnInit {  
  //Display Logic Properties
  @Input() attachmentActionType: AttachmentActionType;
  @Input() attachFiles: AttachFiles;
  @Input() attachmentErrorMsgs: AttachmentErrorMsgsLabels;
  @Input() errorMsgs: ErrorMsgs;
  @Input() fileTypes: string[];
  @Output() opError: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('attachment') attachmentAlert: InlineAlertContainerComponent;
  public activePath: string;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  public downloadFileLoader: boolean = false;
  public enableFileAttachmentLabel: boolean = true;
  public fileLoader: boolean = false;
  public maskChar: string = '.';
  public uploadAttachmentRestriction: boolean = false;
  fileUploadScanError: boolean = false;

  constructor(
    public alert: AlertService,
    private appealsAndGrievanceService: AppealsAndGrievanceServices,
    private changeDetectionRef: ChangeDetectorRef,
    private httpClient: HttpClient,
    private router: Router,
    public fileService: FileAttachmentService,
    public helperService: HelperService
  ) {
    const URLPath = this.router.url?.split("/")[2];
    this.activePath = URLPath === FormType.APPEALS.toLowerCase() ? FormType.APPEALS : FormType.GRIEVANCE;
  }

  ngOnInit(): void {
  }
  
   /**
   * @Description checkForPasswordProtected used to check the file for password protected
   * if it is password protected it will throw error else it will scan and upload the file to Backend
   * @param  {} event
   */
  checkForPasswordProtected(event):void {
    const reader = new FileReader();
    reader.readAsArrayBuffer(event);
    reader.onload = () => {
    const files = new Blob([reader.result], {type: 'application/pdf'});
      files.text().then(x => {
        if (x.includes("Encrypt") || x.substring(x.lastIndexOf("<<"), x.lastIndexOf(">>")).includes("/Encrypt")) {
          this.fileService.setErrorMessages(this.attachmentErrorMsgs.PasswordProtectedFileError);
          this.fileLoader = true;
          this.PasswordProtectedErrorAlert();
        } else {
          if (this.attachmentAlert) {
            this.fileService.clearValidation();
          }
          this.addFile(event);
        }
      });
    }
  }

  PasswordProtectedErrorAlert(){
    if (this.attachmentAlert) {
      this.fileService.clearValidation();
      this.clearErrorAlert();
    }
      this.fileService.isPasswordProtectedFile = true;
      this.fileLoader = false;
      this.changeDetectionRef.detectChanges();
      this.alert?.error(this.fileUploadScanError ? this.attachmentErrorMsgs.FileUploadError:this.attachmentErrorMsgs.PasswordProtectedFileError, {
        regionName: "inline-alert",
        isDissmisable: true
      });
  }

  /**
   * @Description addFile used to upload and scan the file attachments
   * @param  {} event
   */
  addFile(event): void {
    this.fileUploadScanError= false;
    this.convertFileIntoBase64String(event).subscribe((base64) => {
      const file = this.fileService.validateFile(
        this.fileTypes,
        new FileDetails(
          event.name,
          event.size,
          event.name.substring(event.name.lastIndexOf(".") + 1).toLowerCase(),
          base64
        )
      );
      if (file) {
        this.fileLoader = true;
        const requestObject: UploadAttachmentRequest = {
          fileAttachmentActionType: this.attachmentActionType,
          fileName: file.fileName,
          fileMimeType: FileFormats[file.fileType.toString().toUpperCase()],
          fileSize: file.fileSize,
          formType: this.activePath,
          fileData: file.fileData
        }
        this.appealsAndGrievanceService.uploadFileAttachment(requestObject).subscribe(
          (res: any) => {
            const fileDetails = { ...res.body, ...file };
            this.helperService.addFile(fileDetails);
            this.fileLoader = false;
          },
          err => {
            if(err?.error?.code === UploadAttachmentException.FILE_NOT_VALID){
              this.opError.next(err); //go back to parent object to handle error (Appeals and grievances component)
              this.fileUploadScanError = true
              this.PasswordProtectedErrorAlert();
            } else {
              this.opError.next(err);
              this.fileLoader = false;
            }
          }
        );
      }
      this.changeDetectionRef.detectChanges();
      if (this.attachmentAlert) {
        this.clearErrorAlert();
        this.alert.error(this.fileService.errorMessage, {
          regionName: "inline-alert",
          isDissmisable: true
        });
      }
    });
  }
  
  clearErrorAlert() {
    this.attachmentAlert.dismissAlertId(0);
  }

  trackByFile(index: number, file: any) {
    return file;
  }

  /**
   * @description  convertFileIntoBase64String() Responsible for returning the base64string 
   * @param {File} inputfile
   * @returns Observable<string>
   */
  convertFileIntoBase64String(file : File) : Observable<string> {
    /*
    * We use a ReplaySubject because we only need to calculate the file once.
    * e.g. If we subscribed multiple times to the same return subject of this method, it doesn’t need to recalculate the base64 since it’s deterministic (The same file is always the same Base64 string).
    */ 
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    reader.onerror = (event) => console.error(event);
    return result;
  }

  focusToAttach() {
    document.getElementById('attachButton').focus();
  }

  /**
   * @Description trimFileName method is used to trim and mask the attachments file name if the file is >25 characters
   * @param filename parameter is the file name
   * @param limit is used to trim the character if the filename exceeds the limit
   * @returns masked file name 
   */
  trimFileName(filename, limit = 25): string {
    const nonMaskableLength =  Math.floor((filename.length*30)/100);
    const split = filename.indexOf(".");
    const name = filename.substring(0, split);
    const ext = filename.substring(split);
    let firstTrimName = name.substring(0, nonMaskableLength);
    let lastTrimName = name.slice(-4);
  
    if (name.length > limit) {
      firstTrimName = firstTrimName.padEnd(nonMaskableLength + 3, this.maskChar);
      return firstTrimName + lastTrimName + ext;
    } else {
      return filename;
    }
  }

  /**
   * @Description deleteFile method used to delete the file from file attachments
   * TODO: Needs to Integrate API to delete the file from storage
   * @param fileDetails object needs to be passed to delete the file
   */
  deleteFile(fileDetails: object): void {
    this.helperService.deleteFile(fileDetails);
  }
    
  /**
   * @Description downloadFileAttachment method used to download the files from service using dcn number 
   * @param  {} file
   * @returns void
   */
  downloadFileAttachment(file): void {
    this.downloadFileLoader = true;
    const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.downloadAttachment +
      "?dcn=" + file.documentNumber;
    this.httpClient
      .get(endpoint, {
        responseType: "arraybuffer",
        observe: "response",
      })
      .subscribe(
        (response: any) => {
          const contentType = response.headers.get("Content-Type");
          const ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
            ie11 =
              navigator.userAgent.match(/Trident\/7.0/) &&
              navigator.userAgent.match(/rv:11/),
            ieEDGE = navigator.userAgent.match(/Edge/g),
            ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;

          const ipadIphone =
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPhone/i);
          const fileName = file.fileName;
          const pdfBlob = new Blob([response.body], { type: contentType });
          //for Microsoft browsers (IE & Edge)
          if (ieVer > -1) {
            const nav = (window.navigator as any);
            if (nav && nav.msSaveOrOpenBlob) {
              nav.msSaveOrOpenBlob(pdfBlob, fileName);
              return;
            }
          } else {
            //for rest of the browsers
            var link = document.createElement("a");
            let url = URL.createObjectURL(pdfBlob);
            if (ipadIphone) {
              //Showing Modal due to popup blocker
              if (!window.open(url, "_blank", "noreferrer,noopener")) {
                alert("Please disable the popup blocker");
              }
            } else if ("download" in link) {
              link.href = url;
              link.download = fileName;
              link.click();
              if (file.fileType && file.fileType.toLowerCase() === "pdf") {
                window.open(url, "_blank", "noreferrer,noopener");
              }
            }
          }
          this.downloadFileLoader = false;
        },
        (error) => {
          this.opError.next(error);
          this.downloadFileLoader = false;
        }
      );
  }

}
