import { Component, OnInit } from "@angular/core";
import { ContentHelper } from "@anthem/mbrportal/core";
import { Subscription, forkJoin } from "rxjs";
import { AHDContentHelper } from "sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";

@Component({
  selector: "app-ahd-my-orders-page-container.component",
  templateUrl: "./ahd-my-orders-page-container.component.html",
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }]
})
export class AhdMyOrdersPageComponentContainer implements OnInit {
  contentSubscription: Subscription;
  myOrdersContent: any;

  constructor( 
  public contentHelper: ContentHelper,
  private jsonSvc: JsonContentService
  ) {}

  ngOnInit(): void {
    this.contentSubscription = forkJoin([
      this.jsonSvc.getJSON('ahd-my-orders-page'),
      this.jsonSvc.getJSON('ahd-order-status'), 
      this.jsonSvc.getJSON('ahd-review-orders-page'),
      this.jsonSvc.getJSON('ahd-review-processing')
    ]).subscribe(([ myOrdersContent, orderStatusContent, orderDetailsContent, reviewProcessingContent]) => {
      this.myOrdersContent = myOrdersContent;
        this.contentHelper.registerContent('CheckOrderStatusComponent', myOrdersContent.CheckOrderStatusComponent);   
        this.contentHelper.registerContent('PrescriberSearchComponent', myOrdersContent.PrescriberSearchComponent);
        this.contentHelper.registerContent('VendorCurrentOrderStatusComponent', orderStatusContent);
        this.contentHelper.registerContent('ReviewOrderComponent', orderDetailsContent.ReviewOrderComponent);
        this.contentHelper.registerContent('ProcessOrderComponent', reviewProcessingContent.ProcessOrderComponent);
    });
  }
}