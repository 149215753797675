import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { MemberRepRegistration, ErrorMsgs, Loaders } from 'sydmed/src/app/secure/shm-my-profile/models/labels';
import { ICheckboxInput } from '@anthem/uxd/util';
import { AccordionProperties } from 'sydmed/src/app/secure/shm-my-profile/models/accordion.properties';
import { AlertService, InlineAlertContainerComponent, UniversalAlertType } from '@anthem/uxd/alert';
import { RegistrationService } from '../../registration.service';
import { RepresentativeRegisterAccountRequest, SecurityQuestionAnswer, ValidateDesigneeInfoRequest, MemberType } from 'gbd-models';
import { MemberRepSession } from '../service/memberRepSession.service'
import { MemberRepRegistrationService } from '../service/memberRepRegistration.service';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { SessionStorage, StorageService} from 'sydmed/libs/storage-service/src/public-api';


const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
const caregiverFormGroup = {
  passwords: ['',[Validators.required]]
}
const designeeFormGroup = {
  dateOfBirth: ['', [Validators.required, FormValidators.BirthDateValidator]],
  passwords: ['',[Validators.required]],
  agreeDesignee: [false, [Validators.requiredTrue]],
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  public labels: MemberRepRegistration;
  public MemberTypes = MemberType;
  public errorMessages: ErrorMsgs;
  public loaderLabels: Loaders;
  jsonAriaLabel: any;
  public jsonLabels;
  public jsonErrors;
  public aslError: any;
  isCaregiver: boolean = false;
  memberRepresentativeForm: FormGroup;
  accordionProperties: AccordionProperties;
  public accordionObj: any;
  public isChecked: boolean;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  public showSecurityQuestions: boolean = false;
  public registrationPage: boolean = false;
  public showRegistrationPage: boolean = false;
  public hideUserNameInput: boolean = false;
  public loading: boolean = false;
  public disableNext: boolean = false;
  public userName: string;
  public showReviewPage = false;
  public securityQuestions: SecurityQuestionAnswer[] = [];
  @ViewChild('memberRepRegisterAlert')
  memberRepRegisterAlert: InlineAlertContainerComponent;
  dateDisplayFormat = DATE_DISPLAY_FORMAT;
  startAt: Date | null;
  max: Date = new Date();
  opened = false;
  isCalendarOpen = false;
  startView = 'month';
  eventType: any = '';
  memberType: string;
  confirmationMessage: string;
  public checkbox: ICheckboxInput = {
    id: 'ux-guide-checkbox-1',
    name: 'agreeDesignee',
    label: '',
    isRequired: true,
    trueValue: true,
    falseValue: false,
  };
  public reviewQuestionsSelected:any = {
    accountRecoveryNumber: '',
    securityQuestion1: '',
    securityQuestion2: '',
    securityQuestion3: ''
  }
  constructor(
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private jsonSvc: JsonContentService,
    private errSvc: ErrorCodeMapperService,
    private regSvc: RegistrationService,
    private memberRepSession: MemberRepSession,
    private memberRepRegistrationService: MemberRepRegistrationService,
    private memberRepresentativeService: MemberRepresentativeService,
    private alertSvc: AlertService,
    private storage: StorageService
  ) { }
  
  ngOnInit(): void {
    // Need to Move this to Validate token page while integration and pull the value from service
    if(sessionStorage.getItem('memberRepToken')){
    this.setMemberType();
    this.setLabels();
    this.setFormDetails();
    }
  }

  setLabels = () => {
    forkJoin([this.jsonSvc.getJSON('member-representative'), this.jsonSvc.getJSON('registration-mfa'), this.jsonSvc.getJSON('error-messages')])
      .subscribe(([memberRepContent, regMFAContent, errorMessages]) => {
        const { Labels } = memberRepContent.MemberRepRegistration;
        const { Labels: mfaLabels, ErrorMsgs } = regMFAContent.RegistrationMFA
        this.labels = Labels.MemberRep_Registration;
        this.loaderLabels = Labels.MemberRep_Registration.Loaders;
        this.jsonLabels = mfaLabels.Create_Username_Password;
        this.jsonErrors = ErrorMsgs;
        this.errorMessages = errorMessages.ErrorMsgs;
        this.registrationPage = true;
        this.showSecurityQuestions = true;
        const { Caregiver_Registration_Update , Registration_Update } = this.labels;
        const { Page_Heading } = Caregiver_Registration_Update;
        this.confirmationMessage = this.memberType === MemberType.CAREGIVER ? Page_Heading : Registration_Update;
      });
  };

  setMemberType() {
    this.memberType = this.memberRepSession.getMemberType(); 
  }

  setFormDetails() {
    const formGroup = this.memberType === MemberType.CAREGIVER ? caregiverFormGroup : designeeFormGroup;
    this.memberRepresentativeForm =  this.formBuilder.group(formGroup);
    if(this.memberType === MemberType.CAREGIVER){
      this.isCaregiver = true;
    }
  }

  submitDesigneeRegistration() {
    if (this.memberRepresentativeForm.valid) {
      this.registrationPage = false;
      this.showReviewPage = true;
    } else {
      this.memberRepresentativeForm.markAllAsTouched();
    }
  }

  async validateSecQuestions(event) {
    this.showRegistrationPage = true;
    this.showSecurityQuestions = false;
    if (this.jsonErrors) {
      this.memberRepRegistrationService.clearAlert(this.memberRepRegisterAlert);
    }
    await this.regSvc.decrypt({token: sessionStorage.getItem('username')}).then(res => {
      this.userName = res?.body;
    },
    (err) => {
      this.handleErrorResponse(err);
    });
    this.reviewQuestionsSelected.accountRecoveryNumber = this.storage.getSessionItem<string>(SessionStorage.REGISTERED_PHONE_NUMBER);
    const securityQuestions = event.value;
    this.transformSecurityQuestions(event.value);
    if(securityQuestions) {
      this.reviewQuestionsSelected.securityQuestion1 = this.capitalizeFirstLetter(securityQuestions.question1);
      this.reviewQuestionsSelected.securityQuestion2 = this.capitalizeFirstLetter(securityQuestions.question2);
      this.reviewQuestionsSelected.securityQuestion3 = this.capitalizeFirstLetter(securityQuestions.question3);
    }
  }

  capitalizeFirstLetter(securityQuestion: string): string {
    return securityQuestion.charAt(0).toUpperCase() + securityQuestion.slice(1);
  }

  transformSecurityQuestions = (securityQuestions): SecurityQuestionAnswer[] => {
    this.securityQuestions.push({question: securityQuestions.question1, answer: (securityQuestions.answer1).trim()});
      this.securityQuestions.push({question: securityQuestions.question2, answer: (securityQuestions.answer2).trim()});
      this.securityQuestions.push({question: securityQuestions.question3, answer: (securityQuestions.answer3).trim()});
      return this.securityQuestions;
  }

  public onChangeInput(id?: string) {
    this.enableInput();
  }

  enableInput() {}

  loginPage() {
    this.router.navigate(['/public/login']);
  }

  passwordFormBackEvent(memberRepresentativeForm: FormGroup) {
    this.showRegistrationPage = false;
    this.resetForm(memberRepresentativeForm);
    this.showSecurityQuestions = true;
  }

  openedStream(event: any) {
    this.eventType = this.labels.Calender_Opened_Message;
    this.isCalendarOpen = true;
  }
  closedStream(event: any) {
    this.eventType = this.labels.Calender_Closed_Message;
    this.isCalendarOpen = false;
  }

  dateChanges(event: any) {
    if (this.memberRepresentativeForm.controls.dateOfBirth.value) {
      const dateOfBirth = this.memberRepresentativeForm.controls.dateOfBirth.value.format('YYYY-MM-DD');
      const request: ValidateDesigneeInfoRequest = { memberDateOfBirth: dateOfBirth };
      this.disableNext = true;
      this.memberRepRegistrationService.validateDateOfBirth(request).subscribe(
        (res) => {
          this.disableNext = false;
        },
        (error) => {
          this.memberRepresentativeForm.get('dateOfBirth').setErrors({ dateMismatch: true });
          this.disableNext = false;
        }
      );
    }
    this.eventType = this.labels.Date_Change_Message;
  } 

  backToPasswordSetPage(memberRepresentativeForm: FormGroup) {
    this.showRegistrationPage = true;
    this.registrationPage = true;
    this.resetForm(memberRepresentativeForm);
  }

  setNewPasswordErrorAlert(error: string): void {
    if (error && this.errorMessages) {
      this.alertSvc.error(error, {
        regionName: 'member-rep-register-alert',
        politeness: 'assertive',
        isDissmisable: true,
      });
      this.memberRepRegistrationService.scrollToTop();
    }
  }

  handleErrorResponse(error: any): void {
    this.loading = false;
    this.jsonErrors = this.errSvc.errorCodeMapper(
      error,
      this.errorMessages
    );
    this.setNewPasswordErrorAlert(this.jsonErrors);
  }

  resetForm(form: FormGroup) {
    form.reset();
    form.markAsPristine();
    form.markAsUntouched();
  }

  submitRegistration(registrationData) {
    this.showReviewPage = false;
    this.loading = true;
    const requestPayload: RepresentativeRegisterAccountRequest = {
      linkToken: sessionStorage.getItem('memberRepToken'),
      password: registrationData?.value?.passwords.password,
      questionAnswers: this.securityQuestions
    };
    if (this.jsonErrors) {
      this.memberRepRegistrationService.clearAlert(this.memberRepRegisterAlert);
    } 
    this.memberRepRegistrationService.saveRegistrationData(requestPayload).subscribe(
      (res) => {
        this.handleSuccessResponse(); 
      },
      (error) =>{
        this.showReviewPage = true;
        this.registrationPage = false;
        this.handleErrorResponse(error);
      }
      );
  }

  handleSuccessResponse(): void {
        this.loading = false;
        this.registrationPage = false;
        this.showReviewPage = false;
        window.sessionStorage.clear();
        this.jsonSvc.clearContents();
  }

  ngAfterViewInit() {}
}
