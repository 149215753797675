import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { PaymentMethod } from '../interfaces/PaymentMethod';
import { PaymentMethodOption } from './PaymentMethodOption';
import { PaymentMethodRadioGroup } from './PaymentMethodRadioGroup';
import { PaymentMethodDataService } from '../services/payment-method-data.service';
import { PaymentMethodHelper } from '../services/payment-method-helper.service';
import { PaymentMethodContent } from '../interfaces/PaymentMethodContent';
import { Subscription } from 'rxjs';
import { AddCreditCard } from 'sydmed/libs/pharmacy-components/payment-methods/models/labels';
import { AddCheckingAccount } from 'sydmed/libs/pharmacy-components/payment-methods/models/labels';
import { PharmacyLineOfBusiness } from 'sydmed/libs/pharmacy-components/payment-methods/models/enum-values';

enum AccountType {
  CREDITCARD = 'CC',
  BANKING = 'ECP'
}

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  @Input() content: PaymentMethodContent;
  @Input() showButtons: boolean;
  @Input() errObj: any;
  @Input() lineOfBusiness: string;
  @Input() isSpecialty: boolean;
  paymentList: PaymentMethod[];
  @Input() selectedPayment: PaymentMethod;
  @Output() closePanel: EventEmitter<any> = new EventEmitter<any>();
  addCardErrorMessages: any;
  addCheckingErrorMessages: any;
  protected sub: Subscription = new Subscription();
  public paymentOptions: PaymentMethodOption[];
  public creditPaymentRadioGroup: PaymentMethodRadioGroup;
  public bankPaymentRadioGroup: PaymentMethodRadioGroup;
  public selectedId: string;
  public showCreditList = false;
  public showBankList = false;
  public showAddPayment = false;
  public addCardContent: AddCreditCard;
  public addBankContent: AddCheckingAccount;

  constructor(
    protected contentService: JsonContentService,
    protected paymentSvc: PaymentMethodDataService,
    protected helperSvc: PaymentMethodHelper,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.selectedPayment) {
      this.selectedId = this.selectedPayment.accountName;
    }
    this.changeDetector.detectChanges();
    this.lineOfBusiness = this.isSpecialty ?
      PharmacyLineOfBusiness.SPECIALTY : PharmacyLineOfBusiness.PBM;

    if (typeof(this.content?.Labels?.PageSubheading) === 'object'){
        this.content.Labels.PageSubheading =
        this.content.Labels.PageSubheading[JSON.parse(sessionStorage.getItem('sydMedMemberContext')).planCode];
    }
    this.getPaymentMethodLabels();
    this.getPaymentMethods();
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  getPaymentMethods() {
    let paymentListSub: Subscription;
    if (this.isSpecialty) {
      paymentListSub = this.paymentSvc.specialtyPaymentMethods.subscribe(
        (paymentMethods: PaymentMethod[]) => {
          this.setPaymentList(paymentMethods);
        }
      );
    } else {
      paymentListSub = this.paymentSvc.pbmPaymentMethods.subscribe(
        (paymentMethods: PaymentMethod[]) => {
          this.setPaymentList(paymentMethods);
        }
      );
    }
    this.sub.add(paymentListSub);
  }

  getPaymentMethodLabels() {
    const cardLabelSub = this.contentService.getJSON('cardJsonData').subscribe(content => {
      this.addCardContent = content.AddCardLabels;
      this.addCardErrorMessages = content.ErrMsgs;
    });
    this.sub.add(cardLabelSub);

    const checkingLabelSub = this.contentService.getJSON('checkingJsonData').subscribe(content => {
      this.addBankContent = content.AddCheckingLabels;
      this.addCheckingErrorMessages = content.ErrMsgs;
    });
    this.sub.add(checkingLabelSub);

  }

  setPaymentList(paymentList: PaymentMethod[]) {
    if (paymentList) {
      this.paymentList = paymentList.map(paymentMethod => paymentMethod);
    }
    this.createPaymentLists();
  }

  private createPaymentLists() {
    const addNewCreditCard = this.content?.Labels?.PaymentNewCard;
    const addNewChecking = this.content?.Labels?.PaymentNewBank;


    this.createRadioGroup(AccountType.CREDITCARD, addNewCreditCard);
    this.createRadioGroup(AccountType.BANKING, addNewChecking);
  }

  createRadioGroup(accountType: AccountType, addNewLabel: string) {
    let payments: PaymentMethod[] = [];
    const addNewValue = (accountType === AccountType.CREDITCARD) ? 'addNewCard' : 'addNewBank';

    if (this.paymentList) {
      payments = this.paymentList.filter(payment => {
        if (payment.accountType === accountType) {
          if (payment.preferredAccount ||
            (this.selectedPayment && this.selectedPayment.accountName === payment.accountName)) {
            if (!this.isSpecialty) {
              this.selectedId = payment.accountName;
            }
          }
          return payment;
        }
      });
    }

    this.paymentOptions =
      this.helperSvc.createPaymentMethodOptions(payments, addNewLabel, addNewValue);
    if (accountType === AccountType.CREDITCARD) {
      this.creditPaymentRadioGroup = this.helperSvc.createRadioGroup(this.paymentOptions);
      this.showCreditList = true;
    } else {
      this.bankPaymentRadioGroup = this.helperSvc.createRadioGroup(this.paymentOptions);
      this.showBankList = true;
    }
  }

  updatePaymentMethod() {
    if (this.paymentList) {
      this.paymentList.filter(payment => {
        if (this.selectedId === payment.accountName) {
          this.paymentSvc.setSelectedPaymentMethod(payment, this.isSpecialty);
        }
      });
    }
    this.closePanel.emit(true);
  }

  onCancel($event) {
    this.closePanel.emit(true);
  }
}
