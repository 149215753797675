import { Component, Input, OnInit } from "@angular/core";
import { MemberInfoContent } from "../interfaces/benefitsContent";
import { BenefitsService } from "../services/benefits.service";
import {
  LocaleService,
  Locale,
} from "sydmed/src/app/sydmed-shared/locale-service/locale.service";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { Restriction } from "gbd-models";

@Component({
  selector: "benefits-member-info-component",
  templateUrl: "./member-info.component.html",
  styleUrls: ["./member-info.component.scss"],
})
export class BenefitsMemberInfoComponent implements OnInit {
  @Input() content: MemberInfoContent;
  activeStatus: string = "";
  effectiveDate: string;
  memberId: string = "";
  memberName: string = "";
  public classId: string;
  planCode = "";
  memberContext: any;
  publicPageLink: string;
  public isTermedMember: boolean = false

  constructor(
    private benefitsService: BenefitsService,
    private localeService: LocaleService,
    private jsonService: JsonContentService
  ) {}

  ngOnInit(): void {
    this.memberContext = JSON.parse(
      window.sessionStorage.getItem("sydMedMemberContext")
    );
    this.isTermedMember = this.jsonService.hasRestriction(Restriction.TERMED_MEMBER_LESS_THAN_TWO_YEARS, this.jsonService.getRestrictions())
    || this.jsonService.hasRestriction(Restriction.TERMED_MEMBER_TWO_TO_FIVE_YEARS, this.jsonService.getRestrictions());
    this.activeStatus = this.isTermedMember? this.content.inactive : this.content.active;    
    this.memberId = this.benefitsService.getMemberId();
    this.memberName = this.benefitsService.getMemberFullName();
    const date = this.benefitsService.getEffectiveDate();
    const locale = this.localeService.getLocaleOverride() || Locale.EN_US;
    this.effectiveDate = this.getFormattedDate(date, locale);
    this.classId = this.memberContext.classId;
    const stateLobs = [
      "DCMCD",
      "GAMCD",
      "IAMCD",
      "TXMCD",
      "TXSET",
      "TNMCD",
      "MDMCD",
      "NJMCD",
      "WAMCD",
      "NYMCD",
      "ARMCD",
      "MNMCD",
      "MNMMP",
      "NCMCD",
      "WVMCD"
    ];
    if (stateLobs.includes(this.memberContext.stateLob)) {
      this.planCode = this.memberContext.planCode;
    }

    if (this.isObject(this.content.url)) {
      if (!this.planCode) {
        this.publicPageLink = this.content.url[this.classId.substring(0, 2)] || this.content.url['default'];
      }
    } else {
      this.publicPageLink = this.content.url;
    }
    this.publicPageLink = this.isObject(this.content.url) &&  this.planCode ? (this.content.url[this.planCode] ? this.content.url[this.planCode] : this.content.url['default']) : this.publicPageLink;
  }

  getFormattedDate(date: string, locale: string): string {
    const utcDate = new Date(date);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    const day = localDate.getDate();
    const month = localDate.toLocaleDateString(locale, { month: "short" });
    const year = localDate.getFullYear();
    return `${month}. ${day}, ${year}`;
  }

  isObject(val): boolean {
    return typeof val === "object";
  }
}
