import { AccountBalanceComponent } from './account-balance/account-balance.component';
import { AccountBalanceOverviewComponent } from './account-balance-overview/account-balance-overview.component';
import { AccountBalancePaymentComponent } from './account-balance-payment/account-balance-payment.component';
import { AlertModule } from '@anthem/uxd/alert';
import { CommonModule } from '@angular/common';
import { FooterTileComponent } from './footer-tile/footer-tile.component';
import { FooterWrapperComponent } from './footer-wrapper/footer-wrapper.component';
import { HeaderAlertsComponent } from './header-alerts/header-alerts.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { ManageProfileBlockComponent } from './manage-profile-block/manage-profile-block.component';
import { NewPrescriptionComponent } from './new-prescription/new-prescription.component';
import { NgModule } from '@angular/core';
import { OrderStatusComponent } from './order-status/order-status.component';
import { PharmacyDashboardComponent } from './pharmacy-dashboard.component';
import { PharmacyModule } from '../pharmacy/pharmacy.module';
import { Routes, RouterModule } from '@angular/router';
import { ViewPrescriptionsComponent } from './view-prescriptions/view-prescriptions.component';
import { PrescriptionTilesComponent } from './prescription-tiles/prescription-tiles.component';



const routes: Routes = [
  {
    path: '',
    component: PharmacyDashboardComponent
  },
];

@NgModule( {
  declarations: [
    OrderStatusComponent,
    ViewPrescriptionsComponent,
    NewPrescriptionComponent,
    AccountBalanceComponent,
    PharmacyDashboardComponent,
    ManageProfileBlockComponent,
    FooterWrapperComponent,
    FooterTileComponent,
    AccountBalanceOverviewComponent,
    AccountBalancePaymentComponent,
    HeaderAlertsComponent,
    PrescriptionTilesComponent
  ],
  imports: [
    CommonModule,
    PharmacyModule,
    AlertModule,
    IndicatorModule,
    RouterModule.forChild( routes )
  ],
  exports: [ 
    RouterModule,
    PharmacyDashboardComponent ]
} )
export class PharmacyDashboardModule { }
