import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { MemberContactInfo, MemberAddress } from '../models/v3-member-contact-info.model';
import { Subject, Subscription, Observable } from 'rxjs';
import { CVSAddressValidationResponse, CVSAddressValidationRequest } from '../models/v3-ingeniorx-cvsaddress-validation';
import { ShippingAddress } from 'gbd-models';

@Injectable({
  providedIn: 'root'
})
export class ContactInfoService implements OnInit, OnDestroy {

  //original v3 member contact-info response
  //public memberContactInfo : MemberContactInfo;

  memberContactInfoSubject:Subject<MemberContactInfo> = new Subject<MemberContactInfo>();
  isFetchingContactInfo:Subject<boolean> = new Subject<boolean>();
  memberContactInfoFetchError:Subject<any> = new Subject<any>();


  memberContactInfo:MemberContactInfo;
  private _isFetchingContactInfo:boolean = false;
  private memberContactInfoSubcription:Subscription;

  constructor(private httpSvc: HttpClientService) {
    //called from here since services Angular doesn't implement ngOnInit lifecyle function.
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.memberContactInfoSubcription.unsubscribe();
  }

  ngOnInit(): void {
    this.memberContactInfoSubcription = this.memberContactInfoSubject.subscribe((memberContactInfo)=> {
      this.memberContactInfo = memberContactInfo;
    });
  }




  updateAddress(address:MemberAddress):Observable<any> {
    const url = UrlProperties.UrlProperties.endPoints.myProfile.updateAddress;
    //reframe request payload
    const request = {
      address: {
        city: address.city,
        county: address.county,
        state: address.state,
        streetAddress1: address.lineOne,
        streetAddress2: address.lineTwo,
        zipCode: address.zipCode
      },
      type: address.addressType
    }
    return this.httpSvc.postObservable(url, request);
  }

  validateAddress(address:ShippingAddress):Observable<any> {
    const url = UrlProperties.UrlProperties.endPoints.myProfile.cvsAddressValidation;
    return this.httpSvc.postObservable(url, address);
  }
}
