import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommunityResourcesRoutingModule } from './community-resources-routing.module';
import { CommunityResourcesComponent } from './community-resources/community-resources.component';
import { SearchResultsCardComponent } from './components/search-results-card/search-results-card.component';
import { MapInfoModalComponent } from "./components/map-info-modal/map-info-modal.component";
import { SearchResultsInfoComponent } from './components/search-results-info/search-results-info.component';
import { AlertModule } from '@anthem/uxd/alert'; //alerts for asl errors


@NgModule({
  declarations: [
    CommunityResourcesComponent,
    SearchResultsCardComponent,
    MapInfoModalComponent,
    SearchResultsInfoComponent
  ],
  imports: [
    CommonModule,
    CommunityResourcesRoutingModule,
    AlertModule
  ],
  bootstrap: [CommunityResourcesComponent]
})
export class CommunityResourcesModule { }
