import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteAccountConfirmComponent } from './delete-account-confirm.component';
import { DeleteDesigneeAccountComponent } from './delete-designee-account/delete-designee-account.component';
import { Routes, RouterModule } from '@angular/router';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { AlertModule } from '@anthem/uxd/alert';
import { PublicAuthGuardService } from 'sydmed/libs/authorization/public-route-guard.service';

const routes: Routes = [
  {
    path: 'public/delete-account-success',
    canActivate: [PublicAuthGuardService],
    component: DeleteAccountConfirmComponent
  },
  {
    path: 'public/delete-designee-account-success',
    canActivate: [PublicAuthGuardService],
    component: DeleteDesigneeAccountComponent
  }
];

@NgModule({
  declarations: [
    DeleteAccountConfirmComponent,
    DeleteDesigneeAccountComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    IndicatorModule,
    AlertModule
  ],
  providers: []
})
export class DeleteAccountConfirmModule {
  static forRoot(): ModuleWithProviders<DeleteAccountConfirmModule> {
    return {
      ngModule: DeleteAccountConfirmModule,
      providers: [PublicAuthGuardService]
    };
  }
 }
