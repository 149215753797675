// Creating this empty containers to resolve undefined class error in test cases. Not able to load prescriptionHomeComponentV2 directly.

import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-ahd-prescription-home-container.component",
  templateUrl: "./ahd-prescription-home-container.component.html"
})
export class AhdPrescriptionHomeComponentContainer implements OnInit {
 

  constructor( 
  ) {}

  ngOnInit(): void {
   
  }
  
}