import { Component, OnInit, Inject } from "@angular/core";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { DrugAlertMessageLabel, LeftNavItem, LeftNavigation, Navigation, UrlType } from "../models/LeftNavigation";
import { Router, NavigationEnd } from "@angular/router";
import { LeftNavigationService } from "../services/left-navigation.service";
import { filter } from 'rxjs/operators';
import { GetUrlByPlancodeService } from "sydmed/libs/get-url-by-plancode/get-url-by-plancode-classId.service";
import { AppendZipcodeService } from "sydmed/libs/append-zipcode/append-zipcode.service";
import { Subscription } from "rxjs";
import { ContentHelper } from "@anthem/mbrportal/core";
import { AHDContentHelper } from "sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper";
import { Restriction } from "gbd-models";

@Component({
  moduleId: module.id,
  selector: "app-ahd-left-rail-nav",
  templateUrl: "./ahd-left-rail-nav.component.html",
  styleUrls: ["./ahd-left-rail-nav.component.scss"],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }],
})
export class AhdLeftRailNavComponent implements OnInit {
  public labels: Navigation[];
  public alertContent: any;
  public restrictions: Restriction[];
  // For mobile view - defaults to onload CSS
  showMobileView: string = "back-arrow-onload";
  private prevUrl: string;
  drugAlertMessageLabel: DrugAlertMessageLabel;
  navPage: string;
  showDismissableAlert: boolean = true;
  showNewPrescriptionAlert: boolean = true;
  showSensitiveDrugsLabel: string;
  constructor(
    private jsonSvc: JsonContentService,
    private router: Router,
    private leftNavigationSerive: LeftNavigationService,
    private getUrlByPlanCodeService: GetUrlByPlancodeService,
    private appendZipCodeService: AppendZipcodeService,
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.prevUrl = event.url;
      });
    this.leftNavigationSerive.getNavActive().subscribe((nav) => {
      this.navPage = nav;
    });
  }

  ngOnInit(): void {
    this.leftNavigationSerive.setNavActive(this.navPage);
    this.getLabels();
    this.showDismissableAlert = sessionStorage.getItem('showDismissableAlert') !== 'false';
    const ua = navigator.userAgent;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      if(this.prevUrl === '/secure/ahd-pharmacy') {
        this.goToContent();
      } else {
        this.goToMenu();
      }
    }
  }

  ngAfterViewChecked() { // Solution to remove left navigation menu from checkout flow.
    if(this.router.url.includes('/secure/ahd-pharmacy/order/review')) {
      const query = window.matchMedia("(max-width: 480px)");
      this.hideLeftMenu(query);
    }
  }
  hideLeftMenu(query) {
    if (query.matches) { // If media query matches
      document.getElementById('tab-controls-container-sidebar').style.display = 'none';
    } else{
      document.getElementById('tab-controls-container-sidebar').style.display = 'block';
    }
  }

  // For mobile view when naviation on menu is clicked
  goToContent() {
    this.showMobileView = "side-menu-click";
  }

  // For mobile view - when left arrow is clicked
  goToMenu() {
    this.showMobileView = "back-arrow-onload";
  }

  getLabels() {
    this.jsonSvc.getJSON("ahd-left-rail-nav").subscribe((data: LeftNavigation) => {
      this.labels = data.LeftRailNavigationLabels;
      this.restrictions = this.jsonSvc.getRestrictions();
      this.showSensitiveDrugsLabel = data.sensitiveDrugsLabel;
      this.labels = this.removeRestrictedNavItems(this.labels);
      if(data.drugAlertMessageLabel.show) {
        this.drugAlertMessageLabel = data.drugAlertMessageLabel;
        const allNavigationLinks = this.labels.reduce((prev, next) => prev.concat(next.Menu_Headings), []);
        this.setNavPage(allNavigationLinks.find(obj => obj.id === 'findAPharmacy'), { isClicked: false });
      }
    });
  
    this.jsonSvc.getJSON('ahd-pharmacy-landing-page').subscribe(data => {
      this.alertContent = data;
    })
;  }

  setNavPage(val: any, options: { isClicked: boolean}) {
    if(val.Url){
      this.navPage = val.Url;
    }
    if(val.id === LeftNavItem.FIND_A_PHARMACY) {   
      this.handlePharmacyNavigation(val);
    }
    if(options.isClicked) {
      this.setPageNavigation(val);
      this.leftNavigationSerive.setNavActive(this.navPage);
    }
  }

  handlePharmacyNavigation(val: any) {
    const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    const { zipCode, planCode } = memberContext;

    if(val.urlsByPlanCode){
      this.navPage = this.getUrlByPlanCodeService.getUrlByPlanCodeOrClassId(planCode, val.urlsByPlanCode);
    }
    else if (val.urlsByClassId){
      const classId = memberContext.classId.substring(0,2);
      this.navPage = this.getUrlByPlanCodeService.getUrlByPlanCodeOrClassId(classId, val.urlsByClassId)
    }
    this.appendZipCodeToUrl(this.navPage,zipCode)
  }

  appendZipCodeToUrl(url: string, zipCode: string) {
    this.navPage = this.appendZipCodeService.appendZipcodeToUrl(url, zipCode);
    this.updateDrugAlertMessage(this.navPage);
  }
  
  updateDrugAlertMessage(url: string) {
    const drugAlert = {
      message: this.drugAlertMessageLabel.message.replace('[[url]]', url)
    }
    this.drugAlertMessageLabel = drugAlert;
  }

  setPageNavigation(val) {
    if (val.type === UrlType.EXTERNAL) {
      window.open(this.navPage);
      this.navPage = this.router.url;
    } else {
      this.router.navigateByUrl(this.navPage);
    }
    this.goToContent();
  }

  private removeRestrictedNavItems(navItems: Navigation[]): Navigation[] {   
    return navItems.map((navItem) => {
      navItem.Menu_Headings = navItem.Menu_Headings.filter((item) => {
          return !this.hasRestrictions(item.restrictions);
      });
      return navItem;
    });
  }

  private hasRestrictions(restrictions: Restriction[]): boolean {
    return restrictions?.some((restriction) => this.jsonSvc.hasRestriction(restriction, this.restrictions));
  }
    
  closeAlert() {
    this.showNewPrescriptionAlert = false;
  }

  onCloseDismissableAlertClick() {
    this.showDismissableAlert = false;
    sessionStorage.setItem('showDismissableAlert', 'false');
  }

  ngOnDestroy() {
    this.leftNavigationSerive.setNavActive('0');
  }
}
