import { Component, OnInit } from '@angular/core';
import { MyProfileDataService } from '../my-profile-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavPages } from '../models/enum-values';
import { ProfileNavigationLabels, SideNavLink } from '../models/labels';
import { Restrictions } from '../models/member-context';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { UniversalAlertType, AlertService } from '@anthem/uxd/alert';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { Restriction } from 'gbd-models';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';

@Component({
  selector: 'profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss']
})
export class ProfileNavComponent implements OnInit {

  navPage: string;
  public labels: ProfileNavigationLabels;
  public restrictions: Restrictions;
  // For mobile view - defaults to onload CSS
  showMobileView: string = 'back-arrow-onload';
  clonedAccountDetailsHeadings: Array<SideNavLink>;
  clonedCommPreferencesHeadings: Array<SideNavLink>;
  clonedDesigneeAccountHeadings: Array<SideNavLink>;
  public errorMsgs: any;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  private prevUrl: string;
  public hasPrivacyAndAccess: boolean = false;
  public hasAccountAccess: boolean = false;
  public hasDesigneeProfile: boolean = false;
  public hasPaymentAccess: boolean = false;
  public hasAdditionalInsurancePolicy: boolean = false;
  public isTermedUser: boolean = false;
  //adding a flag temporarily as back end is not ready
  public featureFlagShowNotices:boolean=false;
  public accountType: string;
  public designee:boolean = false;
  public hideAccountRecovery: boolean = false;
  showCommunicationPreferencesHeader = false;
  constructor(
    private memberRepresentativeService: MemberRepresentativeService,
    private myProfileDataService: MyProfileDataService,
    private router: Router,
    private jsonSvc: JsonContentService,
    private _alert: AlertService,
    private interCommunicationService: InterCommunicationService
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.prevUrl = event.url;
      });

    this.myProfileDataService.getNavActive().subscribe(
      nav => {
        this.navPage = nav;
      }
    );
    
   this.myProfileDataService.getIsTermedUserFlag().subscribe(
      flag => {
        this.isTermedUser = flag;
      }
    );
 
    this.hasPrivacyAndAccess = !this.jsonSvc.hasRestriction(Restriction.SHM_PRIVACY_ACCESS, this.jsonSvc.getRestrictions());
    this.hasAccountAccess = !this.jsonSvc.hasRestriction(Restriction.SHM_ACCOUNT_ACCESS, this.jsonSvc.getRestrictions());
    this.hasDesigneeProfile = !this.memberRepresentativeService.isDesignee;
    this.hideAccountRecovery = this.jsonSvc.hasRestriction(Restriction.SHM_ACCOUNT_SETTINGS, this.jsonSvc.getRestrictions());
    this.hasPaymentAccess = !this.jsonSvc.hasRestriction(Restriction.SHM_PROFILE_PAYMENTS, this.jsonSvc.getRestrictions());
    this.hasAdditionalInsurancePolicy = !this.jsonSvc.hasRestriction(Restriction.SHM_COORDINATION_OF_BENEFITS, this.jsonSvc.getRestrictions());
  }

  ngOnInit(): void {
    let memberContext = JSON.parse(sessionStorage.getItem('sydMedMemberContext'));
    this.accountType = memberContext?.accountType;
    this.setErrorMsgs();
    this.interCommunicationService.receivedFilter.subscribe(res => {
      if (res) {
        window.scrollTo(0, 0);
        if (res.isProfileUpdated && res.status) {
          this.showAlert(this.errorMsgs.Medical_Profile_Updated);
        } else {
          this.showAlert(this.errorMsgs.Medical_Profile_Added);
        }
      }
    });
    this.getLabelsAndRestrictions();
    const ua = navigator.userAgent;

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      if(this.prevUrl === '/secure/profile') {
        this.goToMenu();
      } else {
        this.goToContent();
      }
    }
  }

  showAlert(content: string) {
    if (this._alert && content) {
      this._alert.success(content, {
        regionName: 'inline-alert-dev-example',
        alertListType: 'queue',
        politeness: 'polite',
        isDissmisable: true
      });
    }
  }

  setErrorMsgs() {
    this.jsonSvc.getJSON('error-messages').subscribe(data => {
      this.errorMsgs = data.ErrorMsgs;
    });
  }

  setNavRestrictions() {
    /**
     *  removing the payments, medical profile, prescription notifications
     *  from navigation on pharmacy restriction
     */
    this.clonedAccountDetailsHeadings = Object.assign([], this.labels.AccountDetails.Menu_Headings);
    this.clonedCommPreferencesHeadings = Object.assign([], this.labels.CommunicationPreferences.Menu_Headings);
    this.clonedDesigneeAccountHeadings = Object.assign([], this.labels.DesigneeAccount.Menu_Headings);
    // Temporarily hide Plan Notifications for NC Day 1, turn back on for Day 2
    const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    if (memberContext.stateLob && memberContext.stateLob === "NCMCD") {
      this.clonedCommPreferencesHeadings =
          this.clonedCommPreferencesHeadings.filter(nav => nav.Url !== NavPages.PLAN_NOTIFICATIONS);
    }
    // End of NC Day 1 Plan Notifications code
    if (this.restrictions) {
      if (!this.restrictions.pharmacy) {
        // if member does NOT have pharmacy, remove payments and medical profile from account headings
        this.clonedAccountDetailsHeadings = this.clonedAccountDetailsHeadings.filter((nav) => {
          return nav.Url !== NavPages.PAYMENTS && nav.Url !== NavPages.MEDICALPROFILE;
        });
      }
      if (!this.restrictions.pharmacyPBM) {
        // if member does NOT have PBM, remove medical profile from account headings
        this.clonedAccountDetailsHeadings = this.clonedAccountDetailsHeadings.filter((nav) => {
          return nav.Url !== NavPages.MEDICALPROFILE;
        });
      }
      if (!this.restrictions.pharmacy || !this.restrictions.pharmacyPBM) {
        // if member does NOT have pharmacy or PBM, remove prescriptions notifications from comm pref headings
        this.clonedCommPreferencesHeadings =
          this.clonedCommPreferencesHeadings.filter(nav => nav.Url !== NavPages.PRESCRIPTION_NOTIFICATIONS);
      }
      if(!this.restrictions.designeeCommPref){
        this.clonedCommPreferencesHeadings = this.clonedCommPreferencesHeadings.filter(nav => nav.Url !== NavPages.COMMUNICATION_PREFERENCES);
      }
      if (!this.restrictions.cp) {
        this.clonedCommPreferencesHeadings =
          this.clonedCommPreferencesHeadings.filter(nav => nav.Url !== NavPages.PLAN_NOTIFICATIONS);
      }
      if (!this.restrictions.writtenLanguagePref) {
        this.clonedCommPreferencesHeadings =
          this.clonedCommPreferencesHeadings.filter(nav => nav.Url !== NavPages.LANGUAGE);
      }
      if (!this.hasPrivacyAndAccess || this.isTermedUser) {
        this.labels.AccountSettings.Menu_Headings =
        this.labels.AccountSettings.Menu_Headings.filter(nav => nav.Url !== NavPages.PRIVACY_ACCESS);
      }
      if (!this.hasAccountAccess) {
        this.labels.AccountSettings.Menu_Headings =
        this.labels.AccountSettings.Menu_Headings.filter(nav => nav.Url !== NavPages.ACCOUNT_ACCESS);
      }
      if (this.hideAccountRecovery) {
        this.labels.AccountSettings.Menu_Headings = this.labels.AccountSettings.Menu_Headings.filter(nav => nav.Url !== NavPages.ACCOUNT_RECOVERY);
      }
      if (!this.restrictions.accountSettings) {
        this.labels.AccountSettings.Menu_Headings = [] ;
      }
      if (!this.restrictions.accountDetails) {
        this.clonedAccountDetailsHeadings = [] ;
      }
      if (!this.restrictions.prescriptionNotifications) {
        this.clonedCommPreferencesHeadings = this.clonedCommPreferencesHeadings.filter(nav => nav.Url !== NavPages.PRESCRIPTION_NOTIFICATIONS); ;
      }
      if (!this.restrictions.communicationPref) {
        this.clonedCommPreferencesHeadings = [] ;
      }
      if (!this.hasDesigneeProfile) {
        this.restrictions.accountSettings = this.hasDesigneeProfile;
        this.restrictions.accountDetails = this.hasDesigneeProfile;
      }
      if (!this.hasPaymentAccess) {
        this.clonedAccountDetailsHeadings = this.clonedAccountDetailsHeadings.filter((nav) => {
          return nav.Url !== NavPages.PAYMENTS;
        });
      }
      if(!this.hasAdditionalInsurancePolicy) {
        this.clonedAccountDetailsHeadings = this.clonedAccountDetailsHeadings.filter((nav) => {
          return nav.Url !== NavPages.ADDITIONAL_INSURANCE_POLICY;
        });
      }
      if(this.prevUrl === '/secure/profile' || this.prevUrl === '/secure/profile/login-security') {
        this.checkRestrictions()
      }
      this.showCommunicationPreferencesHeader = (( this.restrictions?.cp ||  this.restrictions?.designeeCommPref || this.restrictions?.pharmacy || this.restrictions?.writtenLanguagePref) && this.restrictions?.communicationPref) && !this.isTermedUser && this.clonedCommPreferencesHeadings.length > 0;
    }
  }

  getLabelsAndRestrictions() {
    this.jsonSvc.getJSON('profile-navigation').subscribe(data => {
      this.labels = data.ProfileNavigation;
      this.restrictions = this.myProfileDataService.getRestrictions();
      this.setNavRestrictions();
    });
  }

  setNavPage(val) {
    this.navPage = val;
    this.myProfileDataService.setNavActive(val);
    this.setPageNavigation();
  }

  setPageNavigation() {
    this.router.navigate([this.navPage]);
    this.goToContent();
  }

  // For mobile view when naviation on menu is clicked
  goToContent() {
    this.showMobileView = 'side-menu-click';
  }

  // For mobile view - when left arrow is clicked
  goToMenu() {
    this.showMobileView = 'back-arrow-onload';
  }

  
  checkRestrictions() {
    let profileSubMenuItems = [];
    if (!this.restrictions.accountSettings) {
      if (!this.restrictions.accountDetails) {
        profileSubMenuItems = this.clonedCommPreferencesHeadings;
      } else {
        profileSubMenuItems = this.clonedAccountDetailsHeadings.concat(
          this.clonedCommPreferencesHeadings
        );
      }
    }
    if (profileSubMenuItems && profileSubMenuItems.length > 0) {
      this.setNavPage(profileSubMenuItems[0]);
    }
  }
  
}
