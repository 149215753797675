import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, NG_VALIDATORS } from '@angular/forms';
import { DefaultLabels, IssueDescription, ErrorMsgs, GrievancesAndAppealsLabels } from '../../models/labels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-issue-description',
  templateUrl: './issue-description.component.html',
  styleUrls: ['../../appeals-and-grievances.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IssueDescriptionComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IssueDescriptionComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssueDescriptionComponent implements ControlValueAccessor,OnDestroy {

  @Input('formSubmitted') formSubmitted: boolean;
  @Input() defaultLabels: DefaultLabels;
  @Input() descriptionLabels: IssueDescription;
  @Input() errorMsgs: ErrorMsgs;
  descriptionForSpecificForm: FormGroup;
  subscriptions: Subscription[] = [];
  @Input('editable') editable: boolean = false;
  @Input() labels: GrievancesAndAppealsLabels;
  
  constructor(public fb: FormBuilder) { 
    this.descriptionForSpecificForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(5000)]]
    });

    this.subscriptions.push(
      this.descriptionForSpecificForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );

  }

  get descriptionControl() {
    return this.descriptionForSpecificForm.controls.description;
  }

  get value() {
    return this.descriptionForSpecificForm.value;
  }

  set value(value) {
    this.descriptionForSpecificForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (!value) {
      this.descriptionForSpecificForm.reset();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.descriptionForSpecificForm.valid ? null : { description: { valid: false, }, };
  }

}
