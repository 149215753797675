import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class AslStatusService {

  constructor(private http: HttpClientService) { }

  public async getAsychStatus() {
    const asyncResult = await this.http.getObservable(UrlProperties.UrlProperties.endPoints.appStatus).toPromise();
    return asyncResult;
  }
  
}
