import { Component, OnInit } from '@angular/core';
import { DasboardService } from '../../services/dashboard.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { DashboardDataService } from '../../services/dashboard-data.service';
import { AlertApiData } from "../../interfaces/iAlerts"
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { ChatbotService } from 'sydmed/src/app/sydmed-shared/chatbot-service/chatbot.service';

@Component({
  selector: 'app-dashboard-alerts',
  templateUrl: './dashboard-alerts.component.html',
  styleUrls: ['./dashboard-alerts.component.css']
})
export class DashboardAlertsComponent implements OnInit {

  constructor(
    private errCodeSvc: ErrorCodeMapperService,
    private dashBoardSvc: DasboardService,
    private dataSVc: DashboardDataService,
    private jsonSvc: JsonContentService,
    private chatBotSvc: ChatbotService
  ) { }
  public content = {
    alerts: []
  };
  public jsonLabels: any;
  public numUnreadMessages: number = 0;
  public aslError: string;
  public messageCenterAvailable: boolean = false;
  public loadingSpinner: boolean = false;
  public apiData: AlertApiData; //Remove after making service call. 
  public classId: string;
  displayMessageCenterBlock: boolean = true;
  assistanceSupport: any;
  public hasProfileRestriction: boolean = false;
  public hasProfileSettingsRestriction: boolean = false;
  public hasMessageCenterRestriction: boolean = false;
  public hasEngagementRestriction: boolean = false;
  public hasChatRestriction: boolean = false;
  public hasPharmacyRestriction: boolean = false;
  public hasPCPRestriction: boolean = false;
  public hasViewPCPRestriction: boolean = false;
  public showDashboardAlerts: boolean = true;

  async ngOnInit() {
    this.jsonSvc.getJSON('alerts').subscribe(data => {
      this.displayMessageCenterBlock = data?.MessageCenter.enabled;
      this.assistanceSupport = data?.Assistance;
      this.hasProfileRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_PROFILE_MENU, this.jsonSvc.getRestrictions());
      this.hasProfileSettingsRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_PROFILE_SETTINGS, this.jsonSvc.getRestrictions());
      this.hasMessageCenterRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_NO_MESSAGE_CENTER, this.jsonSvc.getRestrictions());
      this.hasEngagementRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_ENGAGEMENT, this.jsonSvc.getRestrictions());
      this.hasChatRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_LIVE_CHAT, this.jsonSvc.getRestrictions());
      this.hasPharmacyRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_NO_PHARMACY, this.jsonSvc.getRestrictions());
      this.hasPCPRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_CHANGE_PCP, this.jsonSvc.getRestrictions());
      this.hasViewPCPRestriction = this.jsonSvc.hasRestriction(Restriction.SHM_VIEW_PCP, this.jsonSvc.getRestrictions());
      this.showDashboardAlerts = !this.jsonSvc.hasRestriction(Restriction.SHM_DASHBOARD_ALERTS, this.jsonSvc.getRestrictions());

      if (this.content) {
        this.content.alerts = [];
      }
      for (let i in data.alerts) {
        if ((this.hasProfileRestriction || this.hasProfileSettingsRestriction) && data.alerts[i].action === '/secure/profile') {
          continue;
        }
        if ((this.hasEngagementRestriction) && data.alerts[i].action === '/secure/my-health-dashboard/programs') {
          continue;
        }
        if ((this.hasPharmacyRestriction) && data.alerts[i].action === '/secure/pharmacy/dashboard') {
          continue;
        }
        if (((this.hasPCPRestriction || this.hasViewPCPRestriction) && data.alerts[i].action === '/secure/change-provider')) {
          continue;
        }
        this.content.alerts.push(data.alerts[i])
      }
    });

    this.jsonSvc.getJSON('dashboard').subscribe(data => {
      this.jsonLabels = data.Dashboard.Labels;
    });

    this.classId = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext')).classId;
    this.getMessageCount();
    await this.getAlertsData();
    this.setSexInMemberContext();
    this.dataSVc.setRenewalDate(this.apiData.renewalDate);
    this.dataSVc.transformAlerts(this.content.alerts, this.apiData.alerts)
    this.content.alerts = this.dataSVc.trimAlerts(this.content.alerts);
  }
  setSexInMemberContext() {
    let memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    memberContext.sex = this.apiData?.sex;
    window.sessionStorage.setItem('sydMedMemberContext', JSON.stringify(memberContext));
  }

  getMessageCount() {
    this.dashBoardSvc.getMessageCount().subscribe((response: any) => {
      if (response && response.body && response.body.unreadCount) {
        this.numUnreadMessages = response.body.unreadCount;
      }
    },
      error => {
        this.aslError = this.errCodeSvc.errorCodeMapper(error, this.dataSVc.getErrorMessages());
        this.messageCenterAvailable = !this.messageCenterAvailable;
      })
  }

  isObject(val): boolean {
    return typeof val === "object";
  }

  async getAlertsData() {
    await this.dashBoardSvc.getalertsData().then((response: any) => {
      this.loadingSpinner = true; //***TODO  Move to interceptor
      if (response && response.body) {
        this.apiData = response.body;
      }
    },
      error => {
        this.loadingSpinner = true;  //***TODO  Move to interceptor
        this.apiData = {"alerts": '', "renewalDate": ''};
        this.aslError = this.errCodeSvc.errorCodeMapper(error, this.dataSVc.getErrorMessages());
      })
  }

  liveChatPopUp(event) {
    this.chatBotSvc.launchChatBot(event);
  }
}
