import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FilesizePipe implements PipeTransform {

  transform(fileSize: any): string {
    if (fileSize > 1024 * 1024) {
      return (Math.floor(fileSize * 100 / (1024 * 1024)) / 100).toString() + 'MB';
    } else {
      return (Math.floor(fileSize * 100 / 1024) / 100).toString() + 'KB';
    }
  }

}
