<h2 class="section-header-three margin-top-40" [innerHtml]="memberLabels?.header"></h2>

<form [formGroup]="memberInformationForm" #memberForm="ngForm" id="memberInformationForm">
    <div class="row form-without-input">
        <div class="col-md-4 margin-bottom-40">
            <label class="font-14 font-semi-bold" [innerHtml]="memberLabels?.form.firstName"></label>
            <span class="font-12" [innerHtml]="memberData?.firstName"></span>
        </div>
        <div class="col-md-4 margin-bottom-40">
            <label class="font-14 font-semi-bold" [innerHtml]="memberLabels?.form.lastName"></label>
            <span class="font-12" [innerHtml]="memberData?.lastName"></span>
        </div>
        <div class="col-md-4 margin-bottom-40">
            <label class="font-14 font-semi-bold" [innerHtml]="memberLabels?.form.memberIdNumber"></label>
            <span class="font-12" [innerHtml]="memberData?.hcid"></span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 margin-bottom-40">
            <lib-phone
                [editable]="editable"
                [id]="'phoneNumber'"
                formControlName="phoneNumber"
                [phoneLabel]="memberLabels?.form?.memberPhoneNumber"
                [errObj]="errorMessageLabels"
                [formSubmitted]="appealsAndGrivencesFormSubmitted"
            ></lib-phone>
        </div> 
        <div class="col-md-4 margin-bottom-40">
            <label class="font-14 font-semi-bold" for="authorizationNumber" [innerHtml]="memberLabels?.form.authorizationNumber"></label>
            <input type="text" formControlName="authorizationNumber" name="authorizationNumber" aria-required="false"
                class="width-100 ant-text-input margin-bottom-16" [ngClass]="editable ? 'disable-effect' : ''" id="authorizationNumber"
                aria-describedby="authNumberError">
            <span *ngIf="memberInformationForm.controls['authorizationNumber']?.errors?.hasValidChars" class="error">
                <i class="motif-icon motif-delete"></i>
                <span class="motif-margin-left-5">
                    <span class="errorMessage" [innerHtml]="errorMessageLabels?.AlphaNumericFormat"></span>
                </span>
            </span>
        </div>
    </div>
</form>