<p class="font-bold font-14" [innerHtml]="labels?.header"></p>

<form [formGroup]="healthOptionForm">
  <ul class="rfr-Form-ul">
    <li class="uxd-sidenav-wrapper" id="list{{id}}" *ngFor="let item of labels?.form?.radioButtonsGroup; index as id">
      <input class="prOption" formControlName="optionCategory" type="radio" id="optionCategory{{id}}"
        name="optionCategory" [value]="item?.name" (change)="selectItem(id)">
      <label for="optionCategory{{id}}"
        [ngClass]="[selectedRadioButton === id || healthOptionForm?.value?.optionCategory === item?.name ? 'active': '', disableEffect ? 'disable-pointer-events' : '']"
        class="margin-left-8 font-14 font-normal prLabel text-justify" for="optionCategory{{id}}">{{item.name}}</label>
    </li>
  </ul>
  <div *ngIf="healthOptionForm.controls['optionCategory']?.invalid && formSubmitted"
    class=" text-left margin-top-14">
    <span *ngIf="healthOptionForm.controls['optionCategory']?.errors.required" class="error"><i
        class="motif-icon motif-delete"></i>
      <span class="motif-margin-left-5">
        <span [innerHtml]="errorMsgs?.Required_RadioButton_err"></span>
      </span>
    </span>
  </div>
</form>