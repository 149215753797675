// Angular Imports
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { ClaimsContent } from '../claims/interfaces/claimsContent';
import { ClaimsDataService } from './claims-data.service';
import { ClaimFilterWidgetState, ClaimSummaryParam } from './interfaces/claims';
import { ClaimsService } from './services/claimsSvc';
import { DateUtility } from './util/dateUtil';

@Injectable({
  providedIn: 'root'
})
export class JsonDataResolverService {
  widgetFilterState: ClaimFilterWidgetState = {
    date: 6,
  };

  constructor(private dataSvc: ClaimsDataService ,private jsonSvc: JsonContentService,  private claimsService: ClaimsService, private dateUtil: DateUtility) { }

  private content: ClaimsContent;
  private claimsSummary: any
  private jsonResolve: any;
  private summaryResolve: any;

  resolve() {
    this.jsonResolve = this.jsonSvc.getJSON('claims')
      .pipe(
        map(this.handleJsonResponse.bind(this)),
        catchError(this.handleJsonError)
      );
      let params: ClaimSummaryParam = this.buildServiceParams();

      this.summaryResolve = this.claimsService.getClaimsSummary(params).pipe(
        map(this.handleSummaryResponse.bind(this)),
        catchError(this.handleSummaryError.bind(this))
      );

      return forkJoin([this.jsonResolve, this.summaryResolve]);
  }

  handleJsonResponse(res: any) {
    this.content = res;
    this.dataSvc.content = this.content;
    this.dataSvc.restrictions = this.content.restrictions;
  }

  handleJsonError(err: any) {
    return of(null);
  }

  handleSummaryResponse(res: any) {
    this.claimsSummary = res;
    this.dataSvc.claimsSummary = this.claimsSummary;
  }

  handleSummaryError(err: any) {
    if (err.status === 404) {
      this.dataSvc.claimsError = '404'
      return of('404');
    } else {
      this.dataSvc.claimsError = '500'
      return of('500')
    }
  }


  getJsonAsObservable(json: any): Observable<any> {
    return of(json.default);
  }

  private buildServiceParams(): ClaimSummaryParam {
    let parameters: ClaimSummaryParam = {
      clmStartDt: '',
      clmEndDt: '',
      sort: '-clmStartDt',
    };
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    {
      startDate.setMonth(
        startDate.getMonth() - this.widgetFilterState.date
      );
      parameters.clmStartDt = this.dateUtil.getDatePart(
        startDate,
        "yyyy-MM-dd"
      );
      this.widgetFilterState.fromDate = this.dateUtil.getDatePart(
        startDate,
        "MM/dd/yyyy"
      );
      parameters.clmEndDt = this.dateUtil.getDatePart(endDate, "yyyy-MM-dd");
      this.widgetFilterState.toDate = this.dateUtil.getDatePart(
        endDate,
        "MM/dd/yyyy"
      );
    }
    return parameters;
}

}
