import { Component, Input, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { Modal, ModalRef } from '@anthem/uxd/modal';
import { PaymentPortalService } from '../services/payment-portal.service';
import { PaymentHelperService } from ".././services/payment-helper.service";
import { Router } from '@angular/router';
import { PaymentInfo, PaymentStatus } from '../interfaces/PaymentInfo';
import { PaymentType, AutopayDate, AutopayActions } from '../interfaces/Autopay';
import { AlertService } from '@anthem/uxd/alert';
import { Subscription } from 'rxjs';
import { UpdateAutopayRequest } from 'gbd-models';
import { AccountType } from '../add-payment-methods/models/enum-values';

@Component({
    selector: 'app-current-bills',
    templateUrl: './current-bills.component.html',
    styleUrls: ['./current-bills.component.scss']
})
export class CurrentBillListComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    @Input() content: any;
    @Input() errorMsgs: any;
    @Input() isCoronaActive: boolean;
    isLoading: boolean = false;
    isError: boolean = false;
    paymentList: any[] = [];
    isPaymentsError: boolean = false;
    isAutopayError: boolean = false;
    currentBill: PaymentInfo;
    private _removeAutopayModalRef: ModalRef<any, any>;
    @ViewChild('removeAutopayAlert') modalTemplate: TemplateRef<any>;
    constructor(private _service: PaymentPortalService,
        private _helper: PaymentHelperService, private _modal: Modal,
        private router: Router, private _alert: AlertService) { }

    ngOnInit(): void {
        this.fetchCurrentBills();
        this.subscription = this._helper.reloadBills.subscribe(res => {
            if (res && res === true && this.isLoading === false) {
                this._helper.setReloadBills(false);
                this.paymentList = [];
                this.fetchCurrentBills();
            }
        });
    }
    fetchCurrentBills() {
        this.isLoading = true;
        this.isPaymentsError = false;
        this._service.getSummary().subscribe(res => {
            if (res) {
                if (res.body?.dueDate) {
                    this.paymentList.push(this.getCurrentBills(res.body));
                    this.fetchCurrentBillDetails();
                }
            } else
                this.isPaymentsError = true;
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
            this.isPaymentsError = true;
        });
    }
    getCurrentBills(bill) {
        const coverageEndDate = (!bill.billToDate || bill.billToDate === '0000-00-00') ? 'N/A' : (bill.billToDate || 'N/A')
        const coverageStartDate = bill.billFromDate ?? 'N/A';
        const showAdd = !(bill.recurringPaymentIndicator || bill.overdue);
        const showDue = (bill.overdue && (new Date() > new Date(bill.dueDate)))
        const showPay = bill.amount > 0;
        const paymentStatus = bill.memberPayment ? bill.memberPayment.paymentStatus : '';
        const pendingAmount = bill.memberPayment ? bill.memberPayment.paymentAmount : '';
        const obj: PaymentInfo = {
            dueDate: bill.dueDate,
            accountType: bill.planName,
            amount: bill.totalDue,
            autoPay: bill.recurringPaymentIndicator,
            paymentProcess: bill.paymentProcess,
            paidInFull: bill.totalDue === 0,
            payNowOn: bill.paymentStatus,
            memberId: bill.hcid ?? '',
            overDueDays: bill.overdue,
            coverageStartDt: coverageStartDate,
            coverageEndDt: coverageEndDate,
            planId: bill.planId,
            planName:bill.planName,
            productId: bill.productId,
            PdfBillAvailable: bill.pdfBillAvailable ?? null,
            InvoiceUrl: bill.pdfUrl ?? null,
            pdfBytes: bill.pdfBytes ?? null,
            showPay,
            showDue,
            showAdd,
            pendingPayment: bill.pendingPayment,
            memberName: bill.memberName,
            memberSequenceNumber: bill.memberSequenceNumber,
            summaryBillNo: bill.summaryBillNo,
            paymentStatus,
            pendingAmount
        }
        return obj;
    }

    onPayNowClick(billInfo: PaymentInfo) {
        this._helper.setSelectedPayment(billInfo);
        this.router.navigate(['/secure/make-payment/pay-now']);
    }

    autoPayAdd(billInfo: any) {
        this._helper.setSelectedPayment(billInfo);
        this.router.navigate(['/secure/make-payment/auto-pay-add']);
    }
    autoPayEdit(billInfo: any) {
        this._helper.setSelectedPayment(billInfo);
        this.router.navigate(['/secure/make-payment/auto-pay-edit']);
    }
    removeAutoPay(currentBill) {
        const removePayload: UpdateAutopayRequest = {
            payDate: AutopayDate.INMCD,
            paymentType: currentBill.recurringType,
            planID: currentBill.planId,
            productID: currentBill.productId,
            recurType: AutopayActions.DELETE,
            tokenId: currentBill.recurringTokenId
        }
        this._service.updateAutopay(removePayload).subscribe(res => {
            this.showGlobalSuccess(this.content.AutopayRemoveSuccess);
            this.paymentList = [];
            this.fetchCurrentBills();
        }, err => {
            this.showGlobalError(this.content.AutopayRemoveFailure);
        });
    }
    open() {
        this._removeAutopayModalRef = this._modal.open(this.modalTemplate);
    }
    handleClick(currentBill) {
        this.currentBill = currentBill;
        this.open();
    }
    confirmRemove() {
        this.removeAutoPay(this.currentBill);
        this.onClose();
    }
    onClose() {
        this._removeAutopayModalRef.close();
        this._removeAutopayModalRef.onDismiss.unsubscribe();
        this._removeAutopayModalRef.onClose.unsubscribe();
    }
    showGlobalSuccess(message) {
        this._alert.success(message, {
            regionName: 'payment-alert',
            alertListType: 'queue',
            politeness: 'polite',
            isDissmisable: true
        });
    }
    showGlobalError(message) {
        this._alert.error(message, {
            regionName: 'payment-alert',
            alertListType: 'queue',
            politeness: 'polite'
        });
    }
    fetchCurrentBillDetails() {
        this.paymentList.forEach(item => {
            if (item.autoPay) {
                this._service.getAutopayDetails().subscribe(res => {
                    item.recurringTokenId = res.body.payment?.paymentMethod.tokenId;
                    item.recurringType = res.body.payment?.paymentMethod.accountType === AccountType.BANKING ? PaymentType.BANK : PaymentType.CREDIT;
                });
            }
            item.showAdd = !(item.autoPay || item.overDueDays);
            item.showPay = !(item.paidInFull || item.autoPay || (item.paymentStatus === PaymentStatus.PENDING && parseFloat(item.pendingAmount) === item.amount));
            item.showDue = (item.overDueDays && !item.paidInFull && !item.paymentProcess && !item.autoPay);
        });
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
