import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[formControlName][libPhoneFormat]'
})
export class PhoneFormatDirective implements OnInit, OnDestroy {

  private prevValue: string;
  private isDeleteButton: boolean;

  @Input()
  set preValue(value: string) {
    this.prevValue = value;
  }

  private sub: Subscription;

  constructor(
    private readonly el: ElementRef,
    private readonly phoneControl: NgControl,
    private readonly renderer: Renderer2
  ) { }

  @HostListener('keydown', ['$event'])
  checkForDelete(event) {
    if (event.which === 46 || event.key === 'Delete') {
      this.isDeleteButton = true;
    } else {
      this.isDeleteButton = false;
    }
  }

  ngOnInit() {
    this.phoneValidate();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  phoneValidate() {

    this.sub = this.phoneControl.control.valueChanges.subscribe(data => {

      data = data ? data : '';
      // keep track of prev val
      const preInputValue: string = this.prevValue;
      // remove all chars except numbers
      let newVal = data.replace(/\D/g, '');

      let start = this.el.nativeElement.selectionStart;
      let end = this.el.nativeElement.selectionEnd;

      // when removed value from input
      if (preInputValue && (data.length < preInputValue.length)) {

        newVal = this.formatNum(newVal);

        this.phoneControl.control.setValue(newVal, { emitEvent: false });

        // keep cursor the normal position after setting the input above.
        (preInputValue.charAt(start) === '-' && this.isDeleteButton) ?
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start + 1, end + 1) :
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);

        // when typed value in input
      } else {
        const removedD = data.charAt(start);

        newVal = this.formatNum(newVal);

        // check to see if typed in middle
        if (preInputValue && (preInputValue.length >= start)) {
          // change cursor according to special chars.
          if (removedD === '-') {
            start = start + 1;
            end = end + 1;
          }
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);
        } else {
          // +2 because of wanting standard typing
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start + 2, end + 2);
        }
        this.phoneControl.control.setValue(newVal, { emitEvent: false });
      }
    });
  }

  formatNum(newVal) {
    if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
    } else {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
    }

    return newVal;
  }
}
