import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import {
  UniversalAlertType,
  AlertService,
  InlineAlertContainerComponent,
} from "@anthem/uxd/alert";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { ICheckboxInput } from "@anthem/uxd/util";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";
import { HttpClientService } from "sydmed/libs/http-client-service/src/public-api";
import { ErrorCodeMapperService } from "sydmed/libs/error-code-mapper/src/public-api";

@Component({
  selector: "app-caregiver-attestation",
  templateUrl: "./caregiver-attestation.component.html",
  styleUrls: ["./caregiver-attestation.component.css"],
})
export class CaregiverAttestationComponent implements OnInit {
  @ViewChild("memberDecisionAlert")
  memberTypeAlert: InlineAlertContainerComponent;
  @ViewChild("memberErrorAlert")
  memberErrorAlert: InlineAlertContainerComponent;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  jsonLabels: any;
  jsonErrors: any;
  careGiverDecisionForm: FormGroup;
  isDecisionTaken = false;
  isValidDecision = true;
  public checkbox: ICheckboxInput;
  caregiverDecisionRadioGroup = {
    isOptional: false,
    alignVertical: true,
    id: "memberRelationRadioBtnGrp",
    name: "memberRelationRadioBtnGrp",
    label: "",
    options: [
      {
        label: "Yes",
        value: "Yes",
        // TODO: Waiting for Analytics attribute
        // analytics: 'analytics-one'
      },
      {
        label: "No",
        value: "No",
        // TODO: Waiting for Analytics attribute
        // analytics: 'analytics-two'
      },
    ],
  };
  errorMessage = "";
  getCaregiverValue = true;
  showErrorMessage = false;
  name: string;

  constructor(
    private jsonSvc: JsonContentService,
    private formBuilder: FormBuilder,
    private alert: AlertService,
    private router: Router,
    private httpClient: HttpClientService,
    private errorCodeMapperSvc: ErrorCodeMapperService
  ) {}

  ngOnInit(): void {
    this.jsonSvc.getJSON("login").subscribe((data) => {
      this.jsonLabels = data.LoginMFA.Labels.Caregiver_Attestation;
      this.jsonErrors = data.LoginMFA.ErrorMsgs;
      this.checkbox = {
        id: "ux-guide-checkbox-agreement",
        name: "medicalDecisionAgreement",
        label: this.jsonLabels.Agreement_Checkmark_Label,
        trueValue: true,
        falseValue: false,
      };
    });
    this.careGiverDecisionForm = this.formBuilder.group({
      medicalDecision: ["", Validators.required],
      decisionAgreement: ["", Validators.requiredTrue],
    });
    this.careGiverDecisionForm.get("decisionAgreement").disable();
    let memberContext = JSON.parse(sessionStorage.getItem('sydMedMemberContext'));
    this.name = memberContext.memberName.lastName + ", " + memberContext.memberName.firstName;
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    const url = "/sydmedservices/secure/logout";
    this.httpClient.getObservable(url).subscribe((res) => {});
    sessionStorage.clear();
    this.router.navigate(["/public/login"]);
  }

  decisionChange(event) {
    this.showErrorMessage = false;
    this.getCaregiverValue =
      this.careGiverDecisionForm.value.medicalDecision === "Yes" ? true : false;
    this.careGiverDecisionForm.get("decisionAgreement").enable();
    this.careGiverDecisionForm.get("decisionAgreement").setValue(false);
    this.clearErrorAlert();
    this.isDecisionTaken = true;
    if (event.target.value === "Yes") {
      this.isValidDecision = true;
      this.agreementSubmission();
    } else {
      this.isValidDecision = false;
    }
    this.clearMemberAlert();
  }

  agreementSubmission() {
    this.clearMemberAlert();
    if (this.careGiverDecisionForm.valid && !this.isValidDecision) {
      this.alert.error(this.jsonErrors.Non_Legal_Guardian_Err, {
        regionName: "inline-alert",
        politeness: "assertive",
      });
    } else {
      this.clearErrorAlert();
      this.showErrorMessage = false;
    }
  }

  clearErrorAlert() {
    this.memberTypeAlert.dismissAlertId(0);
  }

  clearMemberAlert() {
    this.memberErrorAlert.dismissAlertId(0);
  }

  decisionSubmission() {
    const bodyParams = {
      attestedIsCaregiver: this.getCaregiverValue,
    };
    const endpoint = UrlProperties.UrlProperties.endPoints.login.attestation;
    if (this.careGiverDecisionForm.valid) {
      this.showErrorMessage = false;
      sessionStorage.removeItem('gbdsmc');
      sessionStorage.removeItem('asl-token');
      this.httpClient.postObservable(endpoint, bodyParams).subscribe(
        (res: any) => {
          this.router.navigate(["/public/login/secure-login"]);
        },
        (err: any) => {
          this.errorMessage = this.errorCodeMapperSvc.errorCodeMapper(
            err,
            this.jsonErrors
          );
          if (this.getCaregiverValue) {
            this.clearMemberAlert();
            this.alert.error(this.errorMessage, {
              regionName: "inline-error-alert",
              politeness: "assertive",
            });
          }
        }
      );
    } else {
      if (
        this.careGiverDecisionForm.get("decisionAgreement").status !==
          "DISABLED" &&
        !this.careGiverDecisionForm.valid
      ) {
        this.showErrorMessage = true;
      }
      this.careGiverDecisionForm.markAllAsTouched();
    }
  }
}
