import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.scss"],
})
export class FileInputComponent implements OnInit {
  @Output() file: EventEmitter<any> = new EventEmitter(null);
  @Input() enableFileAttachmentLabel: boolean = false;
  @Input() fileAttachmentLabel: string = '';
  
  constructor() {}

  ngOnInit(): void {}

  uploadFile(event) {
    this.file.emit(event.target.files[0]);
  }
}
