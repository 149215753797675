import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from 'sydmed/libs/auth-service/src/lib/auth.service'; // TODO : Changed path
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { environment } from 'sydmed/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private http: HttpClient, private token: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    let headers: any = {
      'Content-Type': 'application/json'
    };
    let gbdToken = '';

    if (request.url.includes('/sydmedservices/') || request.url.includes('/mobile')) {

      // pass environment selector for lower environments only
      if(!environment.production) {
        const metaSrcEnv = window.localStorage.getItem('FACENV');
        if (metaSrcEnv) {
          headers['meta-src-envrmt'] = metaSrcEnv.replace(/[^a-z0-9-]/gi, '');
        }
      }

      if (sessionStorage.getItem('gbdsmc')) {
        headers.gbdsmc = window.sessionStorage.getItem('gbdsmc');
      }
      
      if ((request.url.indexOf('member-context') <= -1) && (request.url.indexOf('/mobile') <= -1)) {
        gbdToken = window.sessionStorage.getItem('gbd-token');
        if (gbdToken && gbdToken.length > 0) {
          headers['gbd-token'] = gbdToken;
        } else {
          headers['gbd-token'] = 'gbdToken';
        }
        const aslToken = window.sessionStorage.getItem('asl-token');
        if (aslToken && aslToken.length > 0) {
            headers['asl-token'] = aslToken;
          }
      }

      headers.transactionid = uuid();

      request = request.clone({
        setHeaders: headers
      });
    }

    return next.handle(request).pipe(
      map(resp => {
        if (resp instanceof HttpResponse) {
          if (resp.headers.has('gbd-token')) {
            window.sessionStorage.setItem('gbd-token', resp.headers.get('gbd-token'));
            this.resetTokenExpireTime();
          }
          if (resp.headers.has('smsession-cookie')) {
            resp.headers.append('Set-Cookie', resp.headers.get('smsession-cookie'))
          }
          return resp;
        }
      })
    );
  }
  getObservable(url, params?, headers?, responseType?): Observable<any> {
    return this.http.get(url, { params, observe: 'response', ...responseType, headers });
  }

  postObservable(url, data, headers?): Observable<any> {
    return this.http.post(url, data, { observe: 'response', headers });
  }

  patchObservable(url, body?, params?): Observable<any> {
    return this.http.patch(url, body, { params, observe: 'response' });
  }

  // TODO - Added for shm my-profile
  putObservable(url, data): Observable<any> {
    return this.http.put(url, data, { observe: 'response' });
  }

  deleteObservable(url,body?): Observable<any> {
    return this.http.delete(url,{body});
  }

  resetTokenExpireTime() {
    window.sessionStorage.setItem('tokenExpireTime', Date.now().toString());
  }

  getTokenExpireTime() {
    return JSON.parse(window.sessionStorage.getItem('tokenExpireTime'));
  }

}
