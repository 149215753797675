import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-prescription',
  templateUrl: './new-prescription.component.html',
  styleUrls: ['./new-prescription.component.scss']
})
export class NewPrescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
