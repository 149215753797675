import { Component, OnInit, Input } from '@angular/core';
import { SortService } from './sort.service';
import { SortByOptions } from '../types/SortByOptions';

@Component( {
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: [ './sort.component.scss' ]
} )
export class SortComponent implements OnInit {

  @Input() sortByFilter;
  defaultLabel = 'Orders: Latest to Oldest';
  sortByValue: SortByOptions;

  constructor( private sortService: SortService ) { }

  ngOnInit(): void {
    if (!this.sortByFilter) {
      const title = this.sortByFilter.title;
      this.sortByFilter = {
        title,
        options: []
      };
    }

    if (this.sortByFilter.options.length > 0) {
      this.defaultLabel = this.sortByFilter.options[0].label;
    }
  }

  onSortByChange = () => {
    this.sortService.setSortBy(this.sortByValue);
  }
}
