<div class="container motif-margin-bottom-18" *ngIf="content && link">
    <h2 class="section-header-two" *ngIf="content.Heading">
        {{content.Heading}}
    </h2>
    <p class="body-lg" *ngIf="content.Description">{{content.Description}}</p>
    <span class="motif-margin-right-5 ant-font-b" *ngIf="link && content.LinkText">
        <a class="ant-action-link" href="javascript:void(0)" [routerLink]="link" attr.data-analytics="{{content.Analytics}}">
            <span class="motif-icon motif-right-round"></span>
            <span class="ant-link-text">{{content.LinkText}}</span>
        </a>
    </span>
    <p class="body-lg" *ngIf="content.Disclaimer && prescriptions.length > 0"><em>{{content.Disclaimer}}</em></p>
</div>