// Angular Imports
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

// Sydmed SPA Imports
import { AuthService } from 'sydmed/libs/auth-service/src/lib/auth.service';

// Message Center Imports
import { MessageCenterLabels } from './models/labels.interface';
import { MessageCenterErrorMessages } from './models/error-messages.interface';
import { MessageCenterDataService } from './message-center-data.service';

import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';

@Injectable({
  providedIn: 'root'
})
export class JsonDataResolverService {

  constructor(private dataSvc: MessageCenterDataService, private authSvc: AuthService, private jsonSvc: JsonContentService) { }

  private labels: MessageCenterLabels;
  private errorMessages: MessageCenterErrorMessages;
  private jsonResolve: any;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.jsonResolve =  this.jsonSvc.getJSON('message-center')
    .pipe(
      map((res: any) => {
        this.labels = res.MessageCenter.Labels;
        this.errorMessages = res.MessageCenter.ErrorMsgs;
        this.dataSvc.labels = this.labels;
        this.dataSvc.errorMsgs = this.errorMessages;
        return res;
      }),
      catchError((err) => {
        return of(null);
      })
    );
    return this.jsonResolve;
  }

  getJsonAsObservable(json: any): Observable<any> {
    return of(json.default);
  }

}
