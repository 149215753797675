import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientPayRoutingModule } from './patient-pay-routing.module';
import { PatientPayComponent } from './patient-pay/patient-pay.component';
import { TooltipModule } from '@anthem/uxd/tooltip';
import { AlertModule } from '@anthem/uxd/alert';


@NgModule({
  declarations: [PatientPayComponent],
  imports: [
    CommonModule,
    PatientPayRoutingModule,
    TooltipModule,
    AlertModule
  ],
  bootstrap: [PatientPayComponent]
})
export class PatientPayModule { }
