import { IWindow, ComponentHelper } from '@anthem/mbrportal/core';
import { UxHelper } from '@anthem/uxd/util';
import { Component, ViewContainerRef, Inject, HostListener } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { WindowEventHandler } from '@anthem/mbrportal/shared';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FocusManagementService } from './sydmed-shared/focus-management-service/focus-management.service';
import { JsonContentService } from './sydmed-shared/content-service/json-content.service';
import { AdobeAnalyticsService } from './sydmed-shared/adobe-datalayer/adobe-analytics.service';
import { Restriction } from 'gbd-models';
import { Subscription } from 'rxjs';
import { SessionStorage, StorageService } from 'sydmed/libs/storage-service/src/public-api';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  description = this.interCommunicationService.description;
  title = '';
  publicWidget = true;
  dashboard = true;
  mobileMenuButton;
  showChatIcon = false;
  private validUrls: string[] = [];
  tab = false;
  subscription: Subscription;
  constructor(
    private _viewContainerRef: ViewContainerRef,
    _componentHelper: ComponentHelper,
    _uxHlpr: UxHelper,
    @Inject('Window') private _window: IWindow,
    _winEvnt: WindowEventHandler,
    private interCommunicationService: InterCommunicationService,
    private router: Router,
    private focusManagementSvc: FocusManagementService,
    private jsonSvc: JsonContentService,
    private analyticsService: AdobeAnalyticsService,
    private storage: StorageService
  ) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      if (window.location.href.toLowerCase().indexOf('secure') >= 0) {
        this.focusManagementSvc.focusToHeader();
      }
    });
    _componentHelper.setRootViewContainerRef(_viewContainerRef);
    _uxHlpr.setRootViewContainerRef(_viewContainerRef);
    _winEvnt.init();
    this.analyticsService.initAdobeLaunchDataLayer();
    this.analyticsService.injectAdobeLaunchScript();
    this.interCommunicationService.receivedFilter.subscribe((data: any) => {
      if (data && data.title === 'HEADER_TITLE') {
        this.title = data.message;
        this.setPageTitle(this.title);
      }
    });

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit() {
    this.jsonSvc.getJSON('theme').subscribe(data => {
      document.body.className = data.theme;
    });
    this.setupRedirects();
    this.mobileMenuButton = document.getElementById('menuButton');
    this.isMobileDeepLink();
  }

  ngAfterContentChecked() {
    this.checkWidget();
    /* TODO change this temporary logic to check log in after member session is finalized */
    this.showChatIcon = sessionStorage.sydMedLoggedIn && sessionStorage.sydMedLoggedIn === 'true' && sessionStorage.sydMedMemberContext &&  !this.jsonSvc.hasRestriction(Restriction.SHM_LIVE_CHAT, this.jsonSvc.getRestrictions());
    if (sessionStorage.sydMedMemberContext && sessionStorage.sydMedLoggedIn === 'true') {
      let memberContext = JSON.parse(sessionStorage.sydMedMemberContext);
      this.analyticsService.setUserId(memberContext.analyticsUUID);
      this.analyticsService.setBrand(memberContext.marketingBrand);
      this.analyticsService.setState(memberContext.stateLob?.substring(0, 2));
    }
    this.analyticsService.setAuthStatus(sessionStorage.sydMedLoggedIn === 'true');
    this.analyticsService.setEnvironment();
  }

  @HostListener('keydown', ['$event'])
  public checkTab(event) {
    if (event.type === 'keydown' && event.code === 'Tab') {
      this.tab = true;
    }
  }

  @HostListener('focusin', ['$event'])
  public focusItem(event) {
    if (this.tab) {
      event.target.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth'
      })
      this.tab = false;
    }
  }

  public checkWidget() {
    if (window.location.href.toLowerCase().indexOf('public/') >= 0) {
      this.mobileMenuButton.hidden = true;
      this.publicWidget = true;
    } else {
      this.mobileMenuButton.removeAttribute('hidden');
      this.publicWidget = false;
    }

    if (window.location.href.toLowerCase().indexOf('secure/dashboard') >= 0) {
      this.dashboard = true;
    } else {
      this.dashboard = false;
    }

  }

  setupRedirects() {
    // For URLs that come in with a leading hash, remove it and redirect to the non-hashed URL 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigateByUrl(event.url.replace('/#', ''));
        }
      }
    });
  }

  
  public isCorrectUrl() {
    const currentUrl = window.location.pathname;
    const validRoutes = [];
    // get all routes and child routes
    this.router.config.forEach((route) => {
    const routePath: string = route.path;
    validRoutes.push(routePath);
    const routeChildren = route.children || [];
    routeChildren.forEach((routeChild) => {
      const routeChildPath: string = route.path + '/' + routeChild.path;
      validRoutes.push(routeChildPath);
    });
  });
    this.validUrls = validRoutes.map((route) => route.startsWith('/') ? route.replace('/', '') : route)
  .map((route) => route.replace(/\/:[a-zA-Z]+/g, '/[a-zA-Z0-9]+'))
  .filter((route) => route !== '' && route !== '**')
  .map((route) => '^' + route + '$');
    let match = false;
    const locationPathname = currentUrl.startsWith('/') ? currentUrl.replace('/', '') : currentUrl;
    this.validUrls.forEach((strValidRouteRegex: string) => {
const validRouteRegex = new RegExp(strValidRouteRegex);
if (validRouteRegex.test(locationPathname)) { match = true; }
});
    if (!match) {
    this.router.navigateByUrl('secure/dashboard');
   }
}

  // check if this is a deeplink from Mobile
  isMobileDeepLink() {
    if (window?.location?.href?.toLowerCase().indexOf('ismobilerequest') >= 0) {
      this.storage.setSessionItem({key: SessionStorage.IS_MOBILE, value: true});
    } else {
      this.storage.setSessionItem({key: SessionStorage.IS_MOBILE, value: false});
    }
  }

  protected setPageTitle(pageTitle: string = '') {
    let pageName = this._window.location.pathname.split('/')[2] || 'dashboard';
    if (this._window.location.pathname.includes('secure/dashboard')) {
      pageTitle = 'Member Secure Application';
    } 
    let ele = this._window.document.querySelector('title');
    if (ele && pageTitle) {
      ele.innerText = decodeURIComponent(pageTitle);
    }
  }
}