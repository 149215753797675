import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Modal, ModalRef } from "@anthem/uxd/modal";
@Component({
  selector: "app-map-info-modal",
  templateUrl: "./map-info-modal.component.html",
  styleUrls: ["./map-info-modal.component.css"],
})
export class MapInfoModalComponent implements OnInit {
  @ViewChild("modalTemplate") modalTemplate: TemplateRef<any>;
  private _modalRef: ModalRef<any, any>;

  constructor(private _modal: Modal) {}

  open() {
    this._modalRef = this._modal.open(this.modalTemplate);
  }

  onClose() {
    this._modalRef.close();
    this._modalRef.onDismiss.unsubscribe();
    this._modalRef.onClose.unsubscribe();
  }
  ngOnInit(): void {}
}
