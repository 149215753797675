import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

@Component({
  selector: 'get-security-code',
  templateUrl: './get-security-code.component.html',
  styleUrls: ['./get-security-code.component.css']
})
export class GetSecurityCodeComponent implements OnInit {

  constructor(
    private router: Router,
    private jsonSvc: JsonContentService
  ) { }

  public jsonLabels;
  public jsonErrors;
  public linkLabel;
  public url = UrlProperties.UrlProperties.endPoints.registration.sendOtp;

  ngOnInit(): void {
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Get_Security_Code;
      this.jsonErrors = data.RegistrationMFA.ErrorMsgs;
      this.linkLabel = this.jsonLabels.New_Number_Link_Text;
    });
  }

  goToAddARN(valueEmitted){
    this.router.navigate(['public/registration/account-recovery-number']);
  }

  goToHomePage() {
    this.router.navigate(['public/registration/home']);
  }

  goToEnterSecurityCodePage() {
    this.router.navigate(['public/registration/enter-security-code']);
  }

}