import { Restriction } from "gbd-models";

export class AHDUtils {

    static isAHDMember() {
        if(sessionStorage?.sydMedMemberContext) {
            return !(JSON.parse(sessionStorage?.sydMedMemberContext).restrictions.indexOf(Restriction.SHM_AHD) > -1)
        }
    }

    static isBioPlusMember() {
        if(sessionStorage?.sydMedMemberContext) {
            return (JSON.parse(sessionStorage?.sydMedMemberContext).restrictions.includes(Restriction.SHM_BIO_PLUS))
        }
    }
}