import { Component, OnInit, Input } from '@angular/core';
import { PrescriptionTile } from '../interfaces/PharmacyDashboardContent';

@Component({
  selector: 'app-prescription-tiles',
  templateUrl: './prescription-tiles.component.html',
  styleUrls: ['./prescription-tiles.component.scss']
})
export class PrescriptionTilesComponent implements OnInit {
  @Input() ptContent: PrescriptionTile;

  constructor() { }

  ngOnInit(): void {
  }

}
