import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DownloadRouteParams as Params } from '../models/ahd-download';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-ahd-download',
    templateUrl: './ahd-download.component.html',
    styleUrls: ['./ahd-download.component.scss']
})
export class AhdDownloadComponent implements OnDestroy, OnInit {
    loading: boolean = false;
    subscription: Subscription;

    constructor(
        private pharmacyservice: ahdPharmacyService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.loading = true;
        const serviceName = this.route.snapshot.paramMap.get(Params.SERVICE_NAME);
        const queryParams = this.route.snapshot.queryParamMap;
        const endDate = queryParams.get(Params.END_DATE);
        const format = queryParams.get(Params.FORMAT);
        const startDate = queryParams.get(Params.START_DATE);
        this.subscription = this.pharmacyservice.download(endDate, format, serviceName, startDate)
            .pipe(
                finalize(() => {
                    this.loading = false;
                    setTimeout(() => {
                        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 &&
                            navigator.userAgent.indexOf('Chrome') == -1;
                        if (!isSafariBrowser) {
                            window.close();
                        }
                    }, 1000)
    
                }
            ))
            .subscribe((res: HttpResponse<{data: number[]}>) =>  {
                const url = this.createDownloadUrl(res.body?.data, serviceName+'.csv');
                return url;
            });     
    }

    createDownloadUrl(data: number[], fileName: string) {
        const characters = data.map((item: any) => String.fromCharCode(item)).join('');
        const blob = new Blob(['\ufeff' + characters],{ type: 
          'text/csv;charset=utf-8;' });
          let exportLink = document.createElement('a');
          let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1;
          //if Safari open in new window to save file
          if (isSafariBrowser) {
            exportLink.setAttribute('target', '_blank');
          }
          exportLink.setAttribute('href', URL.createObjectURL(blob));
          exportLink.setAttribute('download', fileName);
          exportLink.style.visibility = 'hidden';
          document.body.appendChild(exportLink);
          exportLink.click();
          document.body.removeChild(exportLink);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
