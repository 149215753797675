import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper//src/lib/error-code-mapper.service';
import { MaskingService } from 'sydmed/libs/masking-service/src/lib/masking.service';
import { LoginModel } from './login.model';
import { LoginService } from '../services/login.service';
import { AuthEventsService } from 'sydmed/libs/authorization/authevents.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { ReferrerService } from 'sydmed/src/app/sydmed-shared/referrer-service/referrer.service';
import { Locale, LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
import { SessionStorage, StorageService } from 'sydmed/libs/storage-service/src/public-api';
import { ICheckboxInput } from '@anthem/uxd/util';
import { Modal, ModalRef } from '@anthem/uxd/modal';
import { TermsOfUseService } from '../services/termsOfUse.service';
import { TermsOfUseType, AcceptedTermsOfUseRequest,MemberType, Restriction} from 'gbd-models'
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';

declare var _satellite: any;
const CONSTANTS = {
  CAMPAIGN_PARAMETERS: {
    'CMP': 'cmp',
    'UTM_SOURCE': 'utm_source'
  }  
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [{ provide: Window, useValue: window }],
})
export class LoginComponent implements OnInit {
  inputData: {
    // Move to model
    username: any;
    password: any;
  };

  loginVisibility = true;
  preloginResponse: any;
  secureLoginResponse: any;
  username: string;
  password: string;

  memberContext: any;
  @ViewChild("touModalTemplate", { static: false })
  touModalTemplate: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private loginModel: LoginModel,
    private touModal: Modal,
    private errCodeSvc: ErrorCodeMapperService,
    private termsOfUseSvc: TermsOfUseService,
    private memberRepresentativeService: MemberRepresentativeService,
    private localeService: LocaleService,
    private loginSvc: LoginService,
    private eventSvc: AuthEventsService,
    private maskingSvc: MaskingService,
    private jsonSvc: JsonContentService,
    private referrerService: ReferrerService,
    private storageService: StorageService,
    @Inject('Window') window: Window
  ) { 
    this.setCampaignParameters(route);
  }

  LoginForm: FormGroup;
  public aslError: any;
  private requireResetTempPassword: boolean;
  require2FA: boolean;
  hasContactInfoAvailable: any
  bRequire2FA: boolean;
  public jsonLabels: any;
  public jsonErrors: any;
  public loadingSpinner = false;
  public checkbox: ICheckboxInput;
  private touModalRef: ModalRef<any, any>;
  public isChecked: boolean = false;
  public isSubmitted: boolean = false;
  public showTouModal: boolean = false;
  public loginLinks;
  private locale: string;

  ngOnInit(): void {
    if (this.referrerService.referredFromExternalSite()) {
      if (this.referrerService.referredFromSpanishSite()) {
        this.localeService.setLocaleOverride(Locale.ES_US);
        this.reload();
      } else {
        this.localeService.removeLocaleOverride();
      }
    } else {
      this.locale = this.localeService.getLocaleOverride();
      this.storageService.clearSessionExcept(new Set([SessionStorage.IS_MOBILE, SessionStorage.NEXT_URL]));

      if (this.locale) {
        this.localeService.setLocaleOverride(this.locale);
      }
    }

    this.jsonSvc.getJSON('login').subscribe((data) => {
      this.jsonLabels = data.LoginMFA.Labels.Login;
      this.jsonErrors = data.LoginMFA.ErrorMsgs;
      this.checkbox = {
        id: "tou-checkbox",
        name: "tou-checkbox",
        label: this.jsonLabels?.PrivacyPolicyandTermsofUseModal.AgreeInfo,
        isRequired: true,
        trueValue: true,
        falseValue: false,
      };
    });

    this.jsonSvc.getJSON('quick-links').subscribe((data) => {
      this.loginLinks = data;
    });

    this.LoginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      deviceFingerprint: {},
    });
    sessionStorage.removeItem('resetTempPasswordVisited');
  }
  
  /**
   * @Description setting campaign parameters in window object
   * @param  {route} ActivatedRoute 
   * @returns void
   */
  setCampaignParameters(route:ActivatedRoute): void {
    route?.queryParamMap?.subscribe(paramMap => {
      const cmpParam = CONSTANTS.CAMPAIGN_PARAMETERS.CMP;
      const utmParam = CONSTANTS.CAMPAIGN_PARAMETERS.UTM_SOURCE; 
      const cmp =  paramMap.get(cmpParam) ? `${cmpParam}=${paramMap.get(cmpParam)}` : '';
      const utm_source = paramMap.get(utmParam) ? `${utmParam}=${paramMap.get(utmParam)}`: '';
      window.digitalData.page.pageInfo.campaign = (cmp&&utm_source) ? cmp+'&'+utm_source : cmp + utm_source;
    });
  };

  openTouModal() {
    this.touModalRef = this.touModal.open(this.touModalTemplate);
  }

  onClose() {
    this.isSubmitted = false;
    this.isChecked = false;
    this.touModalRef.close();
    this.touModalRef.onDismiss.unsubscribe();
    this.touModalRef.onClose.unsubscribe();
  }

  onUpdateTou() {
    if (this.isChecked) {
      const requestObj: AcceptedTermsOfUseRequest = {
        touType: TermsOfUseType.GBD_TOU,
      };
      this.onClose();
      this.loadingSpinner = true;
      this.termsOfUseSvc.updateTOU(requestObj).subscribe(
        (res: any) => {
          this.loadingSpinner = false;
          this.router.navigate(["/public/login/secure-login"]);
        },
        (err) => {
          this.loadingSpinner = false;
          this.aslError = this.errCodeSvc.errorCodeMapperV2(
            err,
            this.jsonErrors
          );
        }
      );
    } else {
      this.isSubmitted = true;
    }
  }
  
  public login(formLogin) {
    if (formLogin.valid) {
      this.loginUser();
      this.loadingSpinner = true;
    } else {
      const invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      invalidFields[1].focus();
    }
  }

  public memberHasContactInfoAvailable(contactDetails) {
    var bHasContactInfo = true;
    if ((contactDetails === undefined) || (contactDetails === null) || (contactDetails.length < 1)) {
      bHasContactInfo = false;
    }
    return bHasContactInfo;
  }

  public onChangeInput() {
    this.inputData = { // Move to model
      "username": "~" + this.LoginForm.value.username.trim(),
      "password": this.LoginForm.value.password
    };
  }

  public showHide(element: HTMLElement, label?: string) {
    this.maskingSvc.showHide(element, label);
  }

  is2FAFlowRequired(res) {
    this.bRequire2FA = false;
    if (res.body && res.body.threatAssessment) {
      this.bRequire2FA =
        (res.body.threatAssessment.status.toUpperCase() === 'AUTHENTICATE'
          ? true
          : false) ||
        (res.body.threatAssessment.suggestedAction.toUpperCase() === '2NDFACTOR'
          ? true
          : false);
    }
    if (this.bRequire2FA) {
      sessionStorage.setItem('asl-token', res.headers.get('asl-token'));
      sessionStorage.setItem('gbdsmc', res.headers.get('gbdsmc'));
      sessionStorage.setItem(
        'contactsModel',
        JSON.stringify(this.loginModel.transformResponse(res.body))
      );
    } else {
      sessionStorage.setItem('asl-token', res.token);
    }

    return this.bRequire2FA;
  }

  loginUser() {
    window.sessionStorage.setItem('sydMedLoggedIn', 'false');
    let username = "~" + this.LoginForm.value.username.trim();
    this.loginSvc.authenticateUser(username, this.LoginForm.value.password).subscribe((res: any) => {
      if (res.body.code === '500') {
        this.aslError = this.errCodeSvc.errorCodeMapperV3(res, this.jsonErrors);
        this.loadingSpinner = false;
      } else {
        if (res.body.forceReset) {
          sessionStorage.setItem('resetTempPasswordVisited', 'true');
          this.router.navigate(['/public/login/reset-temp-password', this.LoginForm.value.username]);
          return;
        }
        // get flag to see if multi-factor authentication is required
        this.require2FA = this.is2FAFlowRequired(res);
        const { accountType, restrictions } = res?.body;
        if (!this.require2FA) {
          window.sessionStorage.setItem('sydMedMemberContext', JSON.stringify(res.body));
          sessionStorage.setItem('gbd-token', res.headers.get('gbd-token'));

          // SMSession cookie set - navigate to secure
          window.sessionStorage.setItem('sydMedLoggedIn', 'true');
          if (this.hasRestriction(Restriction.CAREGIVER_REATTEST, restrictions)) {
            this.router.navigate(['/public/login/caregiver-attestation']);
          } else {
            if (res?.body?.showTOU && accountType === MemberType.DESIGNEE) {
              this.loadingSpinner = false;
              this.openTouModal();
            } else {
              this.router.navigate(["/public/login/secure-login"]);
            }
          }

          this.secureLoginResponse = res.body;

        } else {
          this.hasContactInfoAvailable = this.memberHasContactInfoAvailable(res.body.memberContactDetails);
          window.sessionStorage.setItem('sydMedLoggedIn', 'false');

          if (this.hasContactInfoAvailable && !this.hasSecurityCodeRestriction(restrictions)) {
            this.router.navigate(['/public/login/get-security-code']);
          } else {
            this.router.navigate(['/public/login/answer-security-questions']);
          }
        }
      }
    }, err => {      
      this.loadingSpinner = false;
      this.aslError = this.errCodeSvc.errorCodeMapperV2(err, this.jsonErrors);
    });
  }

  private reload() {
    location.reload();
  }

  private isCaregiver(accountType: MemberType) {
    return accountType === MemberType.CAREGIVER;
  }

  private hasRestriction(restriction: Restriction, restrictions: string[]) {
    return this.jsonSvc.hasRestriction(restriction, restrictions);
  }

  private hasSecurityCodeRestriction(restrictions: string[]) {
    return this.hasRestriction(Restriction.SHM_LOGIN_NO_OTP_FLOW, restrictions);
  }
}
