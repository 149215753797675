import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { DeleteAccountConfirmLabels } from '../models/labels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-desingee-account',
  templateUrl: './delete-designee-account.component.html',
  styleUrls: ['./delete-designee-account.component.scss']
})
export class DeleteDesigneeAccountComponent implements OnInit {
  public labels: DeleteAccountConfirmLabels;
  private subscription: Subscription = new Subscription();
  public loading: boolean = true;

  constructor(
    private jsonSvc: JsonContentService,
  ) { }

  ngOnInit(): void {
    this.getLabels();
  }

  private getLabels() {
    this.subscription.add(
      this.jsonSvc.getJSON('delete-account-confirm').subscribe(data => {
        this.labels = data;
        this.loading = false;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
