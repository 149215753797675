import { AppealsAndGrievanceRequest,FormType,Priority } from 'gbd-models';
import { AppealsAndGrievanceServices } from '../../services/appeals-and-grievances.service';
import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DefaultLabels, GrievancesAndAppealsLabels, MemberInformationLabels,ConsentInformationLabels, ReasonForRequest, DescribeYourSituation, IssueDescription, ErrorMsgs, MemberData, AttachFiles, HealthOptions } from '../../models/labels';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { FileAttachmentService } from 'sydmed/libs/file-attachment/services/file-attachment.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HelperService } from '../../utils/helper.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { ModalRef } from '@anthem/uxd/modal';
import { RadioButtonGroupValues, AppealsAndgrivencesFormdata } from '../../models/formValues';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appeals-and-grievances-form-preview',
  templateUrl: './appeals-and-grievances-form-preview.component.html',
  styleUrls: ['./../../appeals-and-grievances.scss']
})

// TODO: remove - not used
export class AppealsAndGrievancesFormPreviewComponent implements OnInit {
  @Input() activePath: string;
  @Input() formValues: AppealsAndgrivencesFormdata;
  @Input() modalRef: ModalRef<any, any>;
  @Input("uploadAttachmentRestriction") uploadAttachmentRestriction: boolean = false;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  public appealsAndGrievancesForm: FormGroup = new FormGroup({});
  public attachFiles: AttachFiles;
  public consentInformationLabels: ConsentInformationLabels;
  public defaultLabels: DefaultLabels;
  public describeYourSituationLabels: DescribeYourSituation;
  public descriptionOfSpecificIssueLabels: IssueDescription; 
  public healthOptionLabels: HealthOptions;
  public editable: boolean = true;
  public errorMsgsLabels: any;
  public labels: GrievancesAndAppealsLabels;
  public loading: boolean = false;
  public maskChar: string = '.';
  public memberData: MemberData;
  public memberInformationLabels: MemberInformationLabels;
  public previewAttachmentFiles: string[];
  public reasonForRequestLabels: ReasonForRequest;
  public reasonForRequestValue: RadioButtonGroupValues;
  public priorityValue: any;
  public serviceError: string = '';

  constructor( 
    private appealsAndGrievanceService: AppealsAndGrievanceServices,
    private errCodeSvc: ErrorCodeMapperService,
    private formBuilder: FormBuilder,
    private jsonSvc: JsonContentService,
    private router: Router,
    public fileService: FileAttachmentService,
    public helperService: HelperService
    ) {
    // Appeals and Grievances FormGroup
    this.appealsAndGrievancesForm = this.formBuilder.group({
      memberInformation: ['', Validators.required],
      issueDescription: ['', Validators.required],
      consentInformation: [''],
    });   

    this.memberData = this.getMemberData();
   }

  ngOnInit(): void {
    // Getting Labels from Json and setting values into the variables
    this.getLabels();

    // Getting Error Labels
    this.getErrorMsgs();

    // Add or Remove Form Controllers
    this.revokeFormControls();

    this.fetchAttachmentFiles();
  }

  getLabels(): void {
    this.jsonSvc.getJSON('appeals-and-grievances').subscribe(response => {
      this.defaultLabels = response.defaults;
      this.attachFiles = response.attachFiles;
      this.reasonForRequestLabels = response.reasonForRequest;
      this.memberInformationLabels = response.memberInformation;
      this.healthOptionLabels = response.healthOption;
      this.consentInformationLabels = response.consentInformation;
      this.descriptionOfSpecificIssueLabels = response.descriptionOfSpecificIssue; 
      this.describeYourSituationLabels = response.describeYourSituation;
      this.labels = (this.activePath === FormType.APPEALS.toLowerCase()) ? response.appeals : response.grievances;
    });
  }

  getErrorMsgs(): void {
    this.jsonSvc.getJSON('appeals-and-grievances-error-messages').subscribe(data => {
      this.errorMsgsLabels = data.ErrorMsgs;
    });
  }

  /**
     * @description getMemberData() used to fetch logged in member's name and hcid from session
     * @retruns MemberData
  */
  getMemberData(): MemberData {
    let memberContext = window.sessionStorage.getItem('sydMedMemberContext') && JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    let memberData: MemberData;
    if (typeof (memberContext) !== undefined) {
      memberData = {
        firstName: this.format(memberContext?.memberName?.firstName),
        lastName: this.format(memberContext?.memberName?.lastName),
        hcid: this.format(memberContext?.hcid)
      }
    }
    return memberData;
  }

  /**
    * @description format() used to check if a value is null, undefined or blank
    * @param {any} value
    * @retruns {any}
  */
  private format(value: any): any {
    if (!value) {
      value = '';
    }
    return value;
  }

  //revokeFormControls() will be called based on active path and dynamically adds form controller into appealsAndGrievancesForm Group
  revokeFormControls(): void{
    if(this.activePath === FormType.APPEALS.toLowerCase()){
      this.appealsAndGrievancesForm.addControl('describeYourSituation', new FormControl('', Validators.required));
      this.appealsAndGrievancesForm.removeControl('reasonForRequests');
      this.appealsAndGrievancesForm.removeControl('healthOption');
    } else {
      this.appealsAndGrievancesForm.addControl('healthOption', new FormControl('', Validators.required));
      this.appealsAndGrievancesForm.addControl('reasonForRequests', new FormControl('', Validators.required));
      this.appealsAndGrievancesForm.removeControl('describeYourSituation');
    }
    this.setOtherValue();
  }

  /**
     * @description submitAppealsAndGrievancesForm() used to submit Appeals and Grivances form data into Services 
     * The service got success response will redirect to form-confirmation page
     * If the servcie got Failed the Error message will be captured throw errorCodeMapper() @return Error message
  */

 submitAppealsAndGrievancesForm() {
  if(this.appealsAndGrievancesForm.valid){
    this.loading = true;
    if(this.formValues.reasonForRequests?.requestCategory.includes(this.reasonForRequestLabels?.form?.othersRadioButtonDefaultValue)){
        this.formValues['reasonForRequests']['requestCategory'] = this.reasonForRequestLabels.form.othersRadioButtonvalue;
    }
    if((this.formValues?.healthOption?.optionCategory === this.healthOptionLabels?.form?.radioButtonsGroup[0]?.name) || (this.formValues?.describeYourSituation?.requestCategory === this.describeYourSituationLabels?.form?.radioButtonsGroup[0]?.name)){
      this.priorityValue = Priority?.EXPEDITED;
    }else{
      this.priorityValue = Priority?.STANDARD;
    }
  
    this.formValues = this.appealsAndGrievancesForm.value;
    const appealsForm =  FormType.APPEALS.toLowerCase();
    
    // Note: Once Attachment Implementation Completed in UI the value will be assigned to document.
    const requestObject : AppealsAndGrievanceRequest = { 
      authorizedRepresentativeInfo:{
        firstName:this.formValues?.consentInformation?.firstName ? this.formValues.consentInformation.firstName : '',
        lastName:this.formValues?.consentInformation?.lastName ? this.formValues.consentInformation.lastName : '',
        phoneNumber:this.formValues?.consentInformation?.phoneNumber?.phone ? this.formValues.consentInformation.phoneNumber.phone : ''
      },
      authorizationNumber : this.formValues.memberInformation.authorizationNumber ? this.formValues.memberInformation.authorizationNumber : '',
      representativeConsent: this.formValues.consentInformation.consentcheck,
      phoneNumber :  this.formValues.memberInformation.phoneNumber.phone,
      reasonForRequest : {
        reason : this.activePath === appealsForm ?  '' :  this.formValues.reasonForRequests.requestCategory,
        description : this.activePath === appealsForm ? '' : this.formValues.reasonForRequests.requestCategoryComments
      },
      priority: this.priorityValue,
      description : this.formValues.issueDescription.description,
      formType : this.activePath === appealsForm ? FormType.APPEALS : FormType.GRIEVANCE,
      documents : this.helperService.getDocumentsDetails()
    };

    const randomNumber = Math.floor(Math.random() * 10000);
    console.log("#here " + randomNumber);
    this.appealsAndGrievanceService.submitGNAFormData(requestObject).subscribe(
      (res: any) => {
        console.log("#sub " + randomNumber);
        this.loading = false;
        console.log("#beforeClose " + randomNumber);
        this.closeModal();
        console.log("#afterClose " + randomNumber);
        if (this.activePath === appealsForm) {
          this.router.navigate(['secure/form-confirmation/appeal']);
        } else {
          this.router.navigate(['secure/form-confirmation/grievances']);
        }
      },
      err => {
        this.scrollToTop();
        this.loading = false; 
        if ( err && this.errorMsgsLabels ) {
          this.serviceError = this.errCodeSvc.errorCodeMapper(err, this.errorMsgsLabels);
        }    
      }
    );
  } else {
    this.appealsAndGrievancesForm.markAllAsTouched();
  }
}

  setOtherValue(){
    this.reasonForRequestValue = this.formValues?.reasonForRequests;
    if (this.reasonForRequestValue && this.reasonForRequestValue?.requestCategory === this.reasonForRequestLabels?.form?.othersRadioButtonvalue) {
      this.formValues['reasonForRequests']['requestCategory'] = this.reasonForRequestLabels.form.othersRadioButtonDefaultValue;
      this.appealsAndGrievancesForm.setValue(this.formValues);
    } else if (this.reasonForRequestValue && this.reasonForRequestValue?.requestCategory !== this.reasonForRequestLabels?.form?.othersRadioButtonvalue) {
      this.formValues['reasonForRequests']['requestCategoryComments'] = '';
      this.appealsAndGrievancesForm.setValue(this.formValues);
    } else {
      this.appealsAndGrievancesForm.setValue(this.formValues);
    }
  }

  closeModal(){
    console.log("#before modalRef.close()")
    this.modalRef.close();
    console.log("#after modalRef.close()")
    this.modalRef.onDismiss.unsubscribe();
    this.modalRef.onClose.unsubscribe();
  }

  // ScrollToTop() used to scroll the page to the top
  scrollToTop(): void{
    this.scrollContainer.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  trackByFile(index: number, file: any) {
    return file;
  }

  /**
   * @Description trimFileName method is used to trim and mask the attachments file name if the file is >25 characters
   * @param filename parameter is the file name
   * @param limit is used to trim the character if the filename exceeds the limit
   * @returns masked file name 
   */
  trimFileName(filename, limit = 25): string {
    const nonMaskableLength =  Math.floor((filename.length*30)/100);
    const split = filename.indexOf(".");
    const name = filename.substring(0, split);
    const ext = filename.substring(split);
    let firstTrimName = name.substring(0, nonMaskableLength);
    let lastTrimName = name.slice(-4);
  
    if (name.length > limit) {
      firstTrimName = firstTrimName.padEnd(nonMaskableLength + 3, this.maskChar);
      return firstTrimName + lastTrimName + ext;
    } else {
      return filename;
    }
  }

  /**
   * @Description fetchAttachmentFiles method used to fetch file details from API and then set details into variable to preview the attachments 
   * TODO: Needs to integrate Appeals and Grievances Preview attachment API to fetch FileDetails and store it in previewAttachmentFiles variable.
   */
  fetchAttachmentFiles(): void {
    this.previewAttachmentFiles = this.fileService.fileList;
  }
}
