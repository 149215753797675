import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EmailComponent } from './email/email.component';
import { CommonModule } from '@angular/common';
import { PwdRetypePwdComponent } from './pwd-retype-pwd/pwd-retype-pwd.component';
import { PhoneComponent } from './phone/phone.component';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';
import { InputModule } from '@anthem/uxd/input';
import { SearchComponent } from './search/search.component';
import { FormGroupModule } from '@anthem/uxd/form-group'; // Needed for security-mask, eye from motif
import { CheckboxModule } from '@anthem/uxd/checkbox'; //checkbox on account details
import { AccordianModule } from '@anthem/uxd/accordion'; //Standalone accordion for account details
import { AlertModule } from '@anthem/uxd/alert'; //alerts for asl errors

import { DropdownModule } from '@anthem/uxd/dropdowns';
import { ModalModule } from '@anthem/uxd/modal';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { SecurityAnswerValidatorComponent } from './security-answer-validator/security-answer-validator.component';

@NgModule({
  declarations: [
    EmailComponent,
    PwdRetypePwdComponent,
    PhoneComponent,
    SearchComponent,
    AccountDetailsComponent,
    SecurityAnswerValidatorComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CustomValidatorsModule,
    InputModule,
    FormGroupModule,
    CheckboxModule,
    DropdownModule,
    ModalModule,
    AccordianModule,
    AlertModule,
    RouterModule
  ],
  exports: [
    EmailComponent,
    PwdRetypePwdComponent,
    PhoneComponent,
    SearchComponent,
    AccountDetailsComponent,
    SecurityAnswerValidatorComponent
  ]
})
export class SharedComponentsModule {}
