import { Component, OnInit } from '@angular/core';
import { DashboardDataService } from '../../services/dashboard-data.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';
import { MemberData } from '../../interfaces/dashboardModals';

@Component({
  selector: 'app-welcome-greeting',
  templateUrl: './welcome-greeting.component.html',
  styleUrls: ['./welcome-greeting.component.css']
})
export class WelcomeGreetingComponent implements OnInit {
  constructor(
  private dataService: DashboardDataService,
  private jsonService: JsonContentService
  ) { }
  public content: any;
  public memberData: MemberData
  public renewalData: string;
  public showMemberId: boolean = true;

  ngOnInit(): void {
    this.jsonService.getJSON('dashboard').subscribe(data => {
      this.content = data.Dashboard.Labels;
      this.showMemberId = !this.jsonService.hasRestriction(Restriction.SHM_DASHBOARD_ID, this.jsonService.getRestrictions())  
    });
    
    this.dataService.setMemberContextData(sessionStorage.getItem('sydMedMemberContext')); //Move to libs.
    if (this.dataService.isDesignee()) {
      this.dataService.getDesigneeName().subscribe(res => {
        this.memberData = res;
      });
    } else{
      this.memberData = this.dataService.getMemberName();
    }
    this.dataService.getRenewalDate().subscribe( res => {
      this.renewalData = res;
    });    
  }
}
