import { ClaimDetails, LineItems } from '../interfaces/claims';
import { ClaimsContent } from '../interfaces/claimsContent';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateUtility } from '@anthem/mbrportal/utility';
import { Observable, Subscription } from 'rxjs';
import { PageGeneratorService } from '@anthem/mbrportal/shared';

/**
 * @description
 * This component is used to print claims Details.
 * 
 * @function
 * ngOnInit()
 * private getUniqueExplanationCodesDetails(claimLineItems: LineItems[]): void
 * private printClaimDetails(): void
 * ngOnDestroy()
 *
 * @example
 * ```html
 * <data-sydmed-claim-print-cmp [content]="content" [claimDetails]="claimsDetailModel"> </data-sydmed-claim-print-cmp>
 * ```
 * @param
 * 'content': content from parent component.
 *
 * @param
 * 'claimDetails': claims details modal object, for claim we need to see claim line detail
 * @param 'printEvent': event;
 * 
 */

@Component({
  moduleId: module.id,
  selector: 'data-sydmed-claim-print-cmp',
  templateUrl: './claimsPrintDetail.html'
})
export class ClaimsPrintDetailComponent implements OnInit, OnDestroy {
  @Input()
  claimDetails: ClaimDetails;

  @Input()
  content: ClaimsContent;

  @Input()
  printEvent: Observable<void>;

  @ViewChild('claimLineDetails', { static: false })
  claimLineDetails: ElementRef;

  explationCodeList: string[];
  printSubscription: Subscription;

  constructor(
    private _pageGeneratorService: PageGeneratorService,
    public dateUtil: DateUtility
  ) {}

  ngOnInit() {
    this.printSubscription = this.printEvent.subscribe(() => {
      this.getUniqueExplanationCodesDetails(this.claimDetails.lineItems);
      setTimeout(() => {
        this.printClaimDetails();
      }, 100);
    });
  }

  /**
   * @description getUniqueExplanationCodesDetails()
   * Responsible getting unique explanation code detail list per claim line
   * @param {LineItems[]} claimLineItems
   * @returns {void}
   */
  private getUniqueExplanationCodesDetails(claimLineItems: LineItems[]): void
  {
    this.explationCodeList = []
    const explanationCodes: Set<string> = new Set();
    for (let i = 0; i < claimLineItems.length; i++) {
      for (let j = 0; j < claimLineItems[i].explanationCd.length; j++) {
        const explanationCode: string = claimLineItems[i].explanationCd[j].code;
        if (!explanationCodes.has(explanationCode)) {
          explanationCodes.add(explanationCode);
          this.explationCodeList.push(( explanationCode === this.content.notAvailable) ? this.content.notAvailable : ('*' + explanationCode + ' - ' + claimLineItems[i].explanationCd[j].description));
        }
      }
    }
  }

  /**
   * @description printClaimDetails() Responsible printing available claim line details
   * @returns {void}
   */
  private printClaimDetails(): void {
    let cssBlock = `
      <style type="text/css">

        html {
          font-size: 14px;
        }

        .ant-claim-line-detail-item-right {
          display: block;
          text-align: right;
          font-weight: 600;
        }
        .ant-font-bold{
          font-weight: bold;
          color: #333333;
        }
        .claims-case-header{
          float: none;
        }
        .ant-claim-item-total {
          text-align: right;
        }
        .ant-claims-sliding-panel-print {
          margin: .5in .75in;
          font-family: "Open Sans","open-sans",sans-serif,sans-serif;
        }

        .printLogo {
          margin-bottom: 2.5rem;
        }

        #printLogo{
          width: 175px;
        }
        .info-label {
          font-weight: 600;
          width: 50%;
          float: left;
        }
      .claims-print-mg-4{
        margin-right:4rem;
      }
        .ant-claims-detail-top {
          width: 40%;
          margin-top: 3rem;
        }

        .ant-claim-line-title .info-label {
          font-weight: normal;
          font-size: 1.2rem;
          width: 80%;
        }

        .ant-claim-line-title {
          margin-top: 2rem;
        }

        .ant-claim-item-total {
          width: 15%;
          padding-left: 5%;
          float: left;
        }

        .ant-claim-line-title .info-label,
        .ant-claim-item-total {
          font-size: 1.2rem;
        }

        .ellipsis-link {
          display: none
        }

        .marked-content-for-screen-reader-detail {
          display: inline-block;
        }
        .claims-med-border th{
          border-bottom: 1px solid #333333;
          position: relative;
          left:0;
          padding-bottom: 15px;
        }
        .claims-med-border th:nth-child(1){border-bottom: none;}
        .claims-med-border th:nth-child(2){left:0;}
        .claims-med-border th:nth-child(3){left:-2px;}
        .claims-med-border th:nth-child(4){left:-4px;}
        .claims-med-border th:nth-child(5){left:-6px;}
        .claims-med-border th:nth-child(6){left:-8px;}
        .claims-med-margin{
          margin-bottom: 2rem;
          margin-top: 4rem;
        }
        .row {
          margin-left: -15px;
          margin-right: -15px;
         }
         .col-md-12{
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          float: left;
         }
        .col-md-6 {
          width: 50%;
          float: left;
          overflow: auto;
        }

        .ant-claim-item-right {
          padding-left: 5%;
          padding-right: 0%;
        }

        .claim-line-detail {
          border: 1px solid white; /* quirk needed to clear floats */
          position: relative;
          overflow: auto;
        }

        .ant-claim-line-detail-remarks-link {
          display: none;
        }

        .print-margin-bottom {
          margin-bottom: 40px;
        }

        h2 {
          font-size: 1rem;
          font-weight: normal;
        }

        body > h2 {
          font-size: 2rem;
        }

        h3 {
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 0;
          margin-left: 1rem;
          color: #333333;
        }

        h3 + p {
          margin: 0;
        }

        .claims-rx-flex-container {
          height: 100%;
       }
        .claims-rx-flex-container dl {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          margin-bottom: 0;
       }
        .claims-rx-flex-container dl .table-row-rx {
          display: flex;
          padding: 20px 15px 15px;
       }
        .claims-rx-flex-container dl .table-row-rx dt {
          display: flex;
          align-items: center;
          font-weight: normal;
          width: 50%;
       }
        .claims-rx-flex-container dl .table-row-rx dt a {
          text-decoration: none;
          margin-left: 5px;
       }
        .claims-rx-flex-container dl .table-row-rx dd {
          width: 50%;
          text-align: right;
          font-size: 14px;
          font-family: Montserrat;
       }
       .table{
         width:100%;
       }
       .table-row td,
       .table-row th{
         text-align: right;
         color: #333333;
       }
       .table-row th:first-child,
       .table-row td:first-child{
        text-align: left;
        color: #333333;
      }
      .table-row td:first-child.claims-total-med{
        text-align: right;
        color: #333333;
        font-weight: bold;
      }
      tr th:first-child,
      tr td:first-child{
        width:50%;
      }
        .claims-rx-flex-container dl .table-row-rx:first-child {
          border-bottom: 1px solid #d9d9d9;
          padding-top: 0;
       }
        .claims-rx-flex-container dl .table-row-rx:nth-child(5) {
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
     }
        .claims-rx-flex-container dl .table-row-rx:first-child dt, .claims-rx-flex-container dl .table-row-rx:nth-child(5) dt {
          font-weight: bold;
          color:#333333;
       }
        .claims-rx-flex-container dl .table-row-rx:first-child dd, .claims-rx-flex-container dl .table-row-rx:nth-child(5) dd {
          font-weight: bold;
          color:#333333;
       }
        .claims-rx-flex-container dl .table-row-rx-sm {
          display: flex;
          justify-content: space-between;
          background: #fff;
          margin-top: 20px;
          padding: 10px 15px;
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px dotted #d9d9d9;
       }
       .claims-rx-flex-container dl .table-esi {
        border-bottom: 1px solid #d9d9d9;
     }
      .claims-rx-flex-container dl .table-esi dt {
        font-weight: bold;
     }
      .claims-rx-flex-container dl .table-esi dd {
        font-weight: 600;
     }
     .prescription-status-mg-top{
       margin-top: 2rem;
     }
     .rx-claims-content p {
      font-size: 12px;
      padding: 0 15px;
      }
      .rx-claims-style p {
        font-style: italic;
        }
        .claims-rx-flex-container dl .table-row-rx-sm dt, .claims-rx-flex-container dl .table-row-rx-sm dd {
          font-weight: 300;
          font-size: 12px;
       }
        .claims-rx-flex-container dl .table-row-rx-md {
          display: flex;
          justify-content: space-between;
          padding: 5px 15px;
       }
        .claims-rx-flex-container dl .table-row-rx-md dt, .claims-rx-flex-container dl .table-row-rx-md dd {
          font-weight: normal;
       }
        .claims-rx-flex-container dl .table-row-rx-md .rx-claims-header {
          font-weight: bold;
       }
        .claims-rx-flex-container dl .rx-claims-total-row dt {
          font-size: 18px;
       }
        .claims-rx-flex-container dl .rx-claims-total-row dd {
          font-weight: 600;
       }
       .claims-total-med{
         text-align: right;
       }
       .info-label-p {
        font-weight: 550;
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 16px;
    }
    .prescript-data-list {
      padding: 0 1.25rem;
   }
    .prescript-data-list dl {
      display: flex;
      flex-direction: column;
   }
   .prescript-data-list-medical dl{
    display: flex;
    flex-direction: row;
   }
   .prescript-data-list dd,
   .prescript-data-list-medical dd{
    margin-left: 0;
   }
  .prescript-data-list dt,
  .prescript-data-list-medical dt{
    font-weight: bold;
    margin-bottom: 5px;
    color: #333333;
  }
  .prescript-data-list .table-row-rx-sm,
  .prescript-data-list-medical .table-row-rx-sm {
    width: 48%;
    overflow-wrap: break-word;
    margin: 0px 0px 20px 0px;
  }
        @media print {
          @page { size: landscape; }
          html {
            font-size: 14px;
          }
        }

      </style>
    `;
    const printHtml = this.claimLineDetails.nativeElement;
    this._pageGeneratorService.init();
    this._pageGeneratorService.title = this.content.printDetail.title;
    this._pageGeneratorService.isPrint = true;
    this._pageGeneratorService.cssAsString = cssBlock;
    this._pageGeneratorService.body = printHtml.innerHTML;
    this._pageGeneratorService.bodyClass = 'ant-claims-sliding-panel ant-claims-sliding-panel-print';
    this._pageGeneratorService.build();
    this._pageGeneratorService.generatePageInTab();
  }

  ngOnDestroy() {
    if (this.printSubscription) {
      this.printSubscription.unsubscribe();
    }
  }
}
