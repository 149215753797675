import { Injectable } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddMemberRepresentativeRequest, DesigneeDetails, UpdateMemberRepresentativeRequest, MemberType } from 'gbd-models'
import { HttpResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class AccountAccessService {
  constructor(private httpSvc: HttpClientService) {}

  protected designeeDetailsData$: BehaviorSubject<DesigneeDetails> = new BehaviorSubject<DesigneeDetails>(null);
  public designeeDetailsData = this.designeeDetailsData$.asObservable();
  public requestUrl = UrlProperties.UrlProperties.endPoints.myProfile.memberRepDetails;

  // call to Delete designee
  public deleteDesignee(requestObj: void) {
    const url = UrlProperties.UrlProperties.endPoints.myProfile.deleteDesigneeDetails;
    return this.httpSvc.postObservable(url + '?memberRepType=' + MemberType.DESIGNEE, requestObj);
  }
  // call to Submit a new designee
  public submitDesignee(requestObj: AddMemberRepresentativeRequest | UpdateMemberRepresentativeRequest) {
    return this.httpSvc.postObservable(this.requestUrl, requestObj);
  }

  public setDesigneeDetails(dataObj: any) {
    this.designeeDetailsData$.next(dataObj);
  }

  public getDesigneeDetails() {
    return this.designeeDetailsData;
  }

  // call to get designee service
  public fetchDesigneeDetails() : Observable<HttpResponse<DesigneeDetails>> {
    return this.httpSvc.getObservable(this.requestUrl + '?memberRepType=' + MemberType.DESIGNEE);
  }

  // Member to check Username availability 
  checkUserNameAvailable(data) {
    const url = UrlProperties.UrlProperties.endPoints.myProfile.checkUsernameAvailability;
    return this.httpSvc.postObservable(url, JSON.stringify(data));
  }
}
