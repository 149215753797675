import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PharmacySearch, DistanceOption } from './PharmacySearchContent';
export enum FormControlNames {
  searchField = 'searchField',
  distance = 'distance'
}
@Injectable( {
  providedIn: 'root'
} )
export class SearchHelperService {

  constructor() { }

  getDefaultDistance( content: PharmacySearch ): DistanceOption {
    return content.distanceOptions.filter(
      distance => distance.value === content.defaultDistance )[ 0 ];
  }

  setupForm( defaultOption: DistanceOption ) {
    return new FormGroup( {
      searchField: new FormControl( '', [ Validators.required, Validators.minLength( 4 ) ] ),
      distance: new FormControl( defaultOption )
    } );
  }
}
