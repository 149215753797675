import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showCurrentTime'
})
export class ShowCurrentTimePipe implements PipeTransform {

  transform(value: string, args?: any): string {

    function formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }
    
    return formatAMPM(new Date());
  }

}
