import { Component, Input, OnInit } from "@angular/core";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { BenefitsContent } from "./interfaces/benefitsContent";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/public-api";
import { Restriction } from "gbd-models";
import { Router } from "@angular/router";
import { MyProfileDataService } from "../shm-my-profile/my-profile-data.service";
import { ViewportScroller } from "@angular/common";
import { BenefitsService } from "./services/benefits.service";

@Component({
  selector: "app-benefits",
  templateUrl: "./benefits.component.html",
  styleUrls: ["./benefits.component.scss"],
})
export class BenefitsComponent implements OnInit {
  navPage: string;
  public benefitMenu = [];
  public additionalResourcesMenu = [];
  public restrictions = [];
  public visionBenefitsEnabled: boolean = false;
  public dentalBenefitsEnabled: boolean = false;
  public transportationBenefitsEnabled: boolean = false;
  memberName: string;
  memberContext: any;
  @Input() content: BenefitsContent;
  constructor(
    private contentService: JsonContentService,
    private titleService: InterCommunicationService,
    private myProfileDataService: MyProfileDataService,
    private router: Router,
    private viewPortScroller: ViewportScroller,
    private benefitsService: BenefitsService,
  ) {}

  ngOnInit(): void {
    this.memberContext = JSON.parse(
      window.sessionStorage.getItem("sydMedMemberContext")
    );
    this.contentService
      .getJSON("benefits")
      .subscribe((content: BenefitsContent) => {
        this.content = content;
        this.benefitMenu = content.BenefitNavigation.Benefits.Menu_Headings;
        this.additionalResourcesMenu =
          content.BenefitNavigation.AdditionalResource.Menu_Headings;
        this.titleService.raiseEvent({
          message: this.content.title,
          title: "HEADER_TITLE",
        });
        this.benefitMenu.forEach((item) => {
          item.show = item.restrictions.some((restriction) =>
            this.memberContext.restrictions.includes(restriction)
          );
        });
        this.additionalResourcesMenu.forEach((item) => {
          item.show = item.restrictions.some((restriction) =>
            this.memberContext.restrictions.includes(restriction)
          );
        });
      });
    this.restrictions = this.contentService.getRestrictions();
    this.visionBenefitsEnabled = !this.contentService.hasRestriction(
      Restriction.SHM_VISION_BENEFIT,
      this.restrictions
    );
    this.dentalBenefitsEnabled = !this.contentService.hasRestriction(
      Restriction.SHM_DENTAL_BENEFIT,
      this.restrictions
    );
    this.transportationBenefitsEnabled = !this.contentService.hasRestriction(
      Restriction.SHM_TRANSPORTATION_BENEFIT,
      this.restrictions
    );
    this.memberName = this.benefitsService.getMemberFullName();
  }
  

  setNavPage(val) {
    this.navPage = val;
    this.myProfileDataService.setNavActive(val);
    this.setPageNavigation();
  }

  setPageNavigation() {
    this.router.navigate([this.navPage]);
  }
}

