import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PriorAuthorizationRoutingModule } from './prior-authorization-routing.module';
import { PriorAuthorizationComponent } from './prior-authorization.component';
import { TabsModuleV2 } from '@anthem/uxd/tabs';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { MedicalComponent } from './medical/medical.component';
import { AccordianModule } from '@anthem/uxd/accordion';
import { RadioModule } from '@anthem/uxd/radio';
import { FormsModule } from '@angular/forms';
import { FilterModule } from '@anthem/uxd/filter';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { AlertModule } from '@anthem/uxd/alert';
import { PharmacyComponent } from './pharmacy/pharmacy.component';
import { PriorAuthFaqsComponent } from './prior-auth-faqs/prior-auth-faqs.component'

@NgModule({
  declarations: [
    PriorAuthorizationComponent, 
    MedicalComponent, 
    PharmacyComponent, 
    PriorAuthFaqsComponent
  ],
  imports: [
    CommonModule,
    PriorAuthorizationRoutingModule,
    TabsModuleV2,
    DropdownModule,
    AccordianModule,
    RadioModule,
    FormsModule,
    FilterModule,
    IndicatorModule,
    AlertModule
  ]
})
export class PriorAuthorizationModule { }
