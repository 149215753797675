import { Injectable } from "@angular/core";
import { environment } from 'sydmed/src/environments/environment';
import { AdobeScriptInjectorService } from "./adobe-script-injector.service";

declare const window: Window;
declare const _satellite: any;

@Injectable({
    providedIn: 'root',
})
export class AdobeAnalyticsService {
    constructor(private scriptInjectorService: AdobeScriptInjectorService) {}

    initAdobeLaunchDataLayer() {
        (window as Window).digitalData = {
            page: {
                environment: '',
                pageInfo: {},
                search: {
                    topicsSearchQuery: '',
                    topicsSearchResults: ''
                }
            },
            customer: {
                authStatus: '',
                brand: '',
                underwritingState: '',
                webGuid: ''
            }
        };
    }

    async injectAdobeLaunchScript() {
        try {
            await this.scriptInjectorService.load('launch', environment.adobeLaunchUrl);
            _satellite.pageBottom();
        } catch (e) {
            console.error('Error while loading Adobe Launch script', e);
        }
    }

    setBrand(brand: string) {
        (window as Window).digitalData.customer.brand = brand;
    }
    setState(state: string) {
        (window as Window).digitalData.customer.underwritingState = state;
    }
    setAuthStatus(isLoggedIn: boolean) {
        (window as Window).digitalData.customer.authStatus = isLoggedIn ? 'Yes' : 'No';
    }
    setUserId(userId: string) {
        (window as Window).digitalData.customer.webGuid = userId;
    }
    setEnvironment() {
        (window as Window).digitalData.page.environment = this.detectEnvironment();
    }
    setChangePcpReason(reason: string) {
        const pageInfo = {
            changePcpReason: reason
        };
        if((window as Window)?.digitalData?.page?.pageInfo) {
            (window as Window).digitalData.page.pageInfo = pageInfo;
        }
    }
    setInterventionData(data?: InterventionAdobeAnalytics) {
        const { digitalData } = window
        const pageInfo = {
          intervention: data
        };

        if (digitalData?.page?.pageInfo) {
          digitalData.page.pageInfo = pageInfo;
        }
      }
    detectEnvironment() {
      const currentUrl = window.location.hostname;
      if (currentUrl.includes('localhost'))
        return 'local';
      else if (currentUrl.split('.').length === 5)
        return currentUrl.match(/\.([^\.]+)\./)[1];
      else
        return 'prod';
    }
}
