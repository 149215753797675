import { Component, OnInit } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { SpecialtyRequest } from '../../models/bioplus.model';

@Component({
  selector: 'app-specialty-request',
  templateUrl: './specialty-request.component.html',
  styleUrls: ['./specialty-request.component.scss']
})
export class SpecialtyRequestComponent implements OnInit {

  public labels: SpecialtyRequest;

  constructor(private jsonService: JsonContentService, private titleService: InterCommunicationService) { }

  ngOnInit(): void {
    this.getLabels();
  }

  getLabels(): void{
    this.jsonService.getJSON("bioplus-specialty-request").subscribe((data) => {
      this.titleService.raiseEvent({ message: data.pageTitle, title: 'HEADER_TITLE' });
      this.labels = data;
    })
  }

}
