<button class="btn btn-filter btn-secondary" [class.open]="filterPanelAlpha.isPanelVisible" #filterOrigin (click)="filterPanelAlpha.togglePanel($event)">
    <span [innerText]="content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.PwrAcctActFltrTitle_lbl"></span>
    <span class="motif-icon" [ngClass]="filterPanelAlpha.isPanelVisible ? 'motif-sort-up-filled' : 'motif-sort-down-filled'"></span>
</button>
    
<ant-filter-panel #filterPanelAlpha="filterPanel" [origin]="filterOrigin" [filterHeaderText]="'Data Filter'">
<!-- Start transcluded content -->
<div class="filter-container">
    <div class="filter-row">
    <div class="filter filter--date motif-margin-bottom-5">
        <p class="uxd-filter-label" id="filterByDateRange">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.PwrAcctActFltrOpt1_lbl}}</p>
        <div class="uxd-filter-button-group" role="radiogroup" aria-labelledby="filterByDateRange" uxd-select>
        <button class="uxd-filter-button" role="radio" aria-label="3 months" uxd-select-toggle [value]="'3m'"
        (click)="filterByStatus(3)" [ngClass]="{'selected' : dateByMonthsFilter === 3}">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.ThreeMonths_lbl}}</button>
        <button class="uxd-filter-button" role="radio" aria-label="6 months" uxd-select-toggle [value]="'6m'"
        (click)="filterByStatus(6)" [ngClass]="{'selected' : dateByMonthsFilter === 6}">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.SixMonths_lbl}}</button>
        <button class="uxd-filter-button" role="radio" aria-label="12 months" uxd-select-toggle [value]="'12m'"
        (click)="filterByStatus(12)" [ngClass]="{'selected' : dateByMonthsFilter === 12}">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.TwelveMonths_lbl}}</button>
        <button class="uxd-filter-button" role="radio" aria-label="24 months" uxd-select-toggle [value]="'24m'"
        (click)="filterByStatus(24)" [ngClass]="{'selected' : dateByMonthsFilter === 24}">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.TwentyFourMonths_lbl}}</button>
        </div>
    </div>

    <!-- Hiding custom date range until motif fixes the issue with date range calendar -->
    <div *ngIf="false" class="filter filter--category motif-margin-bottom-5">
        <p class="uxd-filter-label">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.PwrAcctActFltrOpt2_lbl}}</p>
        <ant-daterange-selector [ngClass]="{'dateRangeBorder' : validationErr}" #daterangeCalendar [mobileHeaderTitle]="def.mobileHeaderTitle" [calendarStartMonth]='calendarStartMonth'
        [calendarDateRange]='calendarDateRange' (calendarOpenEmit)='calendarOpen($event)'
        (dateSelectedChangeEmit)="dateSelectedChange($event)">
        </ant-daterange-selector>
      <div class="error-list motif-margin-top-18" *ngIf="validationErr">
        <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
                <span [innerHtml]="validationErr"></span>
            </span>
        </span>
      </div>
    </div>
    </div>
    <div class="filter-reset mobile motif-margin-top-9 motif-margin-bottom-9">
    <span class="motif-icon motif-recurring-appointment motif-margin-right-3"></span>
    <a href="javascript:void(0)" class="filter-reset-text" (click)="reset()">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.ResetAllFilters_lnk}}</a>
    </div>
</div>
<div class="filter-buttons">
    <div class="filter-reset desktop">
    <span class="motif-icon motif-recurring-appointment motif-margin-right-3"></span>
    <a class="filter-reset-text" href="javascript:void(0)" (click)="reset()">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.ResetAllFilters_lnk}}</a>
    </div>
    <div class="filter-apply-cancel">
    <a class="filter-cancel mobile" href="javascript:void(0)" (click)="cancel()">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.Cancel_btn}}</a>
    <button class="btn btn-secondary motif-margin-right-5 filter-cancel desktop" (click)="cancel()">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.Cancel_btn}}</button>
    <button class="btn btn-primary" (click)="applyFilters()">{{content.PwrAcctActivity_lbls.PwrAcctSearchFilter_lbls.FilterDates_btn}}</button>
    </div>
</div>
<!-- End transcluded content -->
</ant-filter-panel>