import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicModule } from '../public.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { LoginAppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { GetSecurityCodeComponent } from './get-security-code/get-security-code.component';
import { EnterSecurityCodeComponent } from './enter-security-code/enter-security-code.component';
import { ResetTempPasswordComponent } from './reset-temp-password/reset-temp-password.component';
import { AnswerSecurityQuestionsComponent } from './answer-security-questions/answer-security-questions.component';
import { SecureLoginComponent } from './secure-login/secure-login.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { AlertModule } from '@anthem/uxd/alert';
import { InputModule } from '@anthem/uxd/input';
import { CaregiverAttestationComponent } from './caregiver-attestation/caregiver-attestation.component';
import { RadioModule } from '@anthem/uxd/radio';
import { CheckboxModule } from '@anthem/uxd/checkbox';

@NgModule({
  declarations: [
    LoginAppComponent,
    LoginComponent,
    GetSecurityCodeComponent,
    EnterSecurityCodeComponent,
    ResetTempPasswordComponent,
    AnswerSecurityQuestionsComponent,
    SecureLoginComponent,
    CaregiverAttestationComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PublicModule,
    BrowserModule,
    IndicatorModule,
    AlertModule,
    InputModule,
    RadioModule,
    CheckboxModule
  ],
  providers: [],
  bootstrap: [LoginAppComponent]
})
export class LoginModule { }
