<div class="welcome-banner-background" 
     [ngClass]="{
       'welcome-background-designee': isDesignee,
       'welcome-background-termed': isTermedMember, 
       'welcome-background-switcher': isTermedSwitcherMember
     }">
  <div class="container welcome-banner flex-container">
    <div class="flex-item welcome-content-container col-md-6  col-xs-12 col-sm-12">
      <div class="welcome-content-title" data-testid="welcomeTitle">{{content?.welcome}}</div>
      <h1 class="welcome-content-name" data-testid="welcomeMemberName">{{memberData?.memberName | titlecase}}</h1>
      <h2 *ngIf="isFirstTimeLogin" class="welcome-content-subtitle" data-testid="welcomeMessageforFirstTime">
        {{content?.firstLoginMessage}}</h2>
    </div>
    <div class="flex-item col-md-6  col-xs-12 col-sm-12">
      <app-quick-connect-buttons>
      </app-quick-connect-buttons>
    </div>
  </div>

  <div *ngIf="(!isTermedUser && !isDesignee)" class="personalization-seeds-cmp container">
    <div class="action-message-container">
      <ng-container *ngIf="content && content.image" class="checkmark">
        <img [src]="content.image" alt="" />
      </ng-container>
      <span class="action-message">{{content?.actionMessage}}</span>
    </div>
    <div class="seed-container">
      <div *ngFor="let seedItem of seedItems">
        <div  [ngClass] = "!isCaregiver? ' col-lg-3 col-md-3 col-sm-3 seed-content-container' : ' col-lg-6 col-md-6 col-sm-6 seed-content-container'">
        <ng-container *ngIf="seedItem.image; else icon">
          <img [src]="seedItem.image" alt="" />
        </ng-container>
        <ng-template #icon>
          <span [ngClass]="seedItem.icon"></span>
        </ng-template>
        <div class="seed-header">{{ seedItem.header }}</div>
        <div class="seed-description">{{ seedItem.description }}</div>
        <div class="seed-link-container">
          <span [ngClass]="seedItem.isExternal ? 'motif-external-link' : 'motif-right-round'" class="linkLogo motif-icon"></span>
          <a *ngIf="!seedItem.isExternal" href="javascript:void(0)" (click)="onClick(seedItem)" class="link-button" [attr.data-analytics]="seedItem.analytics">{{ seedItem.link }}</a>
          <a *ngIf="seedItem.isExternal" href="{{ seedItem.url }}" target="_blank" class="link-button">{{ seedItem.link }}</a>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div *ngIf="isTermedSwitcherMember" class="container switcher-message-container">
      <div class="seed-container">
        <div class="seed-content-container switcher-messaging-card">
          <div class="switcher-messaging-icon col-lg-1 col-md-1 col-sm-1">
            <span class="motif-icon motif-error"></span>
          </div>
          <div class="switcher-messaging-content col-lg-7 col-md-7 col-sm-7">
            <span class="heading">{{ termedMemberCommercialEligibilityContent?.header }}</span><br>
            <span class="sub-heading">{{ termedMemberCommercialEligibilityContent?.text }}</span>
          </div>
          <button (click)="accessNewPlan()" class="btn btn-primary card-btn col-lg-3 col-md-3 col-sm-3">
            {{ termedMemberCommercialEligibilityContent?.link }}
          </button>
        </div>
    </div>       
  </div>
  <ng-template #sidePanelContent>
    <div>
      <div class="clearfix">
        <button class="close" (click)="closePreview()" aria-label="Close"><span class="close-icon"></span><span
            hidden>Close</span></button>
      </div>
      <app-seed-slideout (closeSlide)="closePreview()" [modalRef]="modalRef">
      </app-seed-slideout>
    </div>
  </ng-template>
</div>
<div *ngIf="backgroundContainer" [ngClass]= "{'non-termed': !isTermedUser, 'termed': isTermedUser }" class="content-container">

</div>
<div *ngIf="!isDesignee">
  <div class="container dashboard-card">
    <app-dashboard-benefit-card></app-dashboard-benefit-card>
  </div>
  <div class="container my-health-care-container">
    <app-my-health-care></app-my-health-care>
  </div>
</div>
<div *ngIf="(!isTermedUser && enableMyPharmacy && !isDesignee)" class="container dashboard-card">
  <app-dashboard-pharmacy-card></app-dashboard-pharmacy-card>
</div>

<!-- Designee Access -->
<div *ngIf="isDesignee" class="container">
  <app-designee-access></app-designee-access>
</div>

<!-- Modal for Interventions -->
<ng-template #interventionsModal>
  <div class="login-modal">
    <call-interventions-modal [callInterventionLabels]="callInterventionLabels" [interventionId]="interventionId" (onDismissModal) = "closeIntervention()"></call-interventions-modal>
  </div>
</ng-template>
