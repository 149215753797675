import { Injectable } from '@angular/core';
import { SessionType } from '../enums/SessionType';

@Injectable({
  providedIn: 'root'
})
export class RestrictionService {
  /* TODO: consider refactoring this service with member name
  // service into a shared member context helper */

  constructor() { }

  protected isValidSession(): boolean {
    return this.getCurrentSession() !== null ? true : false;
  }

  protected getCurrentSession(): string {
    return sessionStorage.getItem(SessionType.LOGGED_IN);
  }

  public get pbmRestriction(): boolean {
    let pbmRestriction = false;
    if (this.isValidSession()) {
      const memberContext = this.getMemberContext(sessionStorage);
      const restrictions = memberContext.restrictions;
      restrictions.filter(restriction => {
        if (restriction === 'SHM_PHARMACY_CVS_PBM') { pbmRestriction = true; }
      });
    }
    return pbmRestriction;
  }

  protected getMemberContext(sessionStorage: Storage) {
    return JSON.parse(sessionStorage.sydMedMemberContext);
  }
}
