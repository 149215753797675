import { Component, Input, OnInit } from '@angular/core';

@Component( {
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: [ './print.component.scss' ]
} )
export class PrintComponent implements OnInit {

  constructor() { }
  @Input() label: string;
  @Input() numberOfItems: number;
  ngOnInit(): void {
  }

  print() {
    window.print();
  }

}
