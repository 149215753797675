import {Observable, ReplaySubject} from 'rxjs';
export const base64toBlob = (base64Data): Blob => {
	const sliceSize = 1024;
	const byteCharacters = window.atob(base64Data);
	const bytesLength = byteCharacters.length;
	const slicesCount = Math.ceil(bytesLength / sliceSize);
	const byteArrays = new Array(slicesCount);

	for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		const begin = sliceIndex * sliceSize;
		const end = Math.min(begin + sliceSize, bytesLength);

		const bytes = new Array(end - begin);
		for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, {type: 'application/pdf'});
};

export const convertFileIntoBase64String = (file: File): Observable<string> => {
	const result = new ReplaySubject<string>(1);
	const reader = new FileReader();
	reader.readAsBinaryString(file);
	reader.onload = (event) => result.next(btoa(event.target.result.toString()));
	reader.onerror = (event) => console.error(event);
	return result;
};
