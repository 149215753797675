import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

/**
 *  Date helper utility
 *  transform and validate dates. this needs to be used for any date specific functionalities.
 */
@Injectable()
export class DateUtility {
  protected _df1 = new RegExp('^(\\d{1,2})([-\\/])(\\d{1,2})([-\\/])(\\d{4})', 'i');
  protected _df2 = new RegExp('^(\\d{4})([-\\/])(\\d{1,2})([-\\/])(\\d{1,2})', 'i');
  protected _df3 = new RegExp('^\\d{8}', 'i');

  constructor(private _datePipe: DatePipe) {}

  internalTransform(value: any, pattern?: string): string {
    try {
      return this._datePipe.transform(value, pattern) || value || '';
    } catch (e) {
      return value || '';
    }
  }
  
  getDatePart(dateObj: any, datePart: string) {
    return this.internalTransform(this.formatUnknownDates(dateObj), datePart);
  }
  
  formatUnknownDates(dateObj: any, reverse: boolean = false) {
    let dtArr: Array<any> = [];
    if (typeof dateObj === 'string' && reverse) {
      dtArr = dateObj.split('-');
      dateObj = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
    } else if (typeof dateObj === 'string' && this._df1.test(dateObj.trim())) {
      // mm-dd-yyyy, m-d-yyyy
      // mm/dd/yyyy, m/d/yyyy
      dtArr = this._df1.exec(dateObj.trim());
      dateObj = dtArr[5] + '-' + this.zeroPad(dtArr[1], 2) + '-' + this.zeroPad(dtArr[3], 2);
    } else if (typeof dateObj === 'string' && this._df2.test(dateObj.trim())) {
      // yyyy-mm-dd, yyyy-m-d
      // yyyy/mm/mm, yyyy/m/d
      dtArr = this._df2.exec(dateObj.trim());
      dateObj = dtArr[1] + '-' + this.zeroPad(dtArr[3], 2) + '-' + this.zeroPad(dtArr[5], 2);
    } else if (typeof dateObj === 'string' && this._df3.test(dateObj.trim())) {
      // 01234567
      // 20151030
      // yyyymmdd
      let dtYr = dateObj.trim().substring(0, 4);
      let dtMn = dateObj.trim().substring(4, 6);
      let dtDy = dateObj.trim().substring(6, 8);
      dateObj = dtYr + '-' + dtMn + '-' + dtDy;
    }

    return dateObj;
  }

  formatDateMedium(dateObj: any) {
    return this.internalTransform(this.formatUnknownDates(dateObj, false), 'MMM. d, y');
  }

  protected zeroPad(str: string, length: number): string {
    str = str + '';

    if (str.length === length) {
      return str;
    }

    return this.zeroPad('0' + str, length);
  }

  compareDates(dateA: Date | string, dateB: Date | string): number {
    if (typeof dateA === 'string') {
      dateA = new Date(dateA);
    }
    if (typeof dateB === 'string') {
      dateB = new Date(dateB);
    }

    if (dateA < dateB) {
      return 1; // if first date val is less than second
    } else if (dateA > dateB) {
      return -1; // else if first date val is greater than second
    } else {
      return 0; // else dates are equal
    }
  }
}
