import { Injectable } from '@angular/core';
import { SessionStorage } from './Storage';

export interface SessionItem<T = string> {
    key: SessionStorage;
    value: T;
}

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor() {}

    clearSessionExcept(itemsKeys: Set<SessionStorage>) {
        const storageItemsToSave: SessionItem[] = [...itemsKeys].map(key => {
            return {
                key,
                value: this.getSessionItem(key)
            }
        });
        sessionStorage.clear();
        storageItemsToSave.forEach(storageItem => this.setSessionItem(storageItem));
    }

    getSessionItem<T = string>(key: SessionStorage): T {
        const item: string | null =  sessionStorage.getItem(key); 
        if (item !== null) {
            return JSON.parse(item).value;
        }
        return null;
    }

    setSessionItem<T = string>({key, value}: SessionItem<T>) {
        sessionStorage.setItem(key, JSON.stringify({value}));
    }
}