<div class="container menu-wrapper" #topMenu *ngIf="userLoggedIn">
  <div class="ant-main-mob ant-main-nav menu-options">
    <ul class="ant-main-nav-ul">
      <li
        class=" menu-button-container ant-font-r ant-heading-four hidden-lg hidden-md hidden-sm tcp-bridge-link-mobile">
        <div data-tcp-switch-plan></div>
      </li>
      <li *ngIf="isLanguageChangeRestricted"
        class="menu-button-container ant-font-r ant-heading-four hidden-lg hidden-md hidden-sm tcp-language-link-mobile">
        <button id="language-toggle" class="btn btn-secondary toggle-btn-menu"
				(click)="languageToggle()" (keyup.enter)="languageToggle()" tabindex="0">{{jsonLabels?.Language}}</button>
      </li>
      <li
        class="menu-button-container ant-font-r ant-heading-four hidden-lg hidden-md hidden-sm tcp-language-link-mobile">
        <div data-tcp-switch-language></div>
      </li>
      <ng-container *ngFor="let level of menu;">
        <ng-container *ngIf="level.show">
          <li [id]="level.id" #mainItem class="menu-button-container"
             [ngClass]="level.css">
             <!-- Top Level Menu Items -->
            <a #menuLink (click)="mainHeadClick($event, mainItem, subOpt, menuLink, menuIcon)"
              (mouseover)="mainHeadClick($event, mainItem, subOpt, menuLink, menuIcon)" role="application" href="javascript:void(0)"
              [attr.aria-label]="mainItem.classList.contains('active') ? level.expAria : level.colAria" tabindex="0"
              (focus)="closeMenu()" class="menu-button">{{level.content}}
              <span #menuIcon aria-hidden="true" class="motif-icon motif-sort-down-filled"></span>
            </a>
            <ul class="sub-options-wrapper" #subOpt>
              <li #subItem class="sub-options-container show-column" [ngClass]="level.css"
                (click)="subHeadClick($event, subItem, menuIcon)">
                <!-- mobile dropdown arrow -->
                <ng-container *ngIf="level.show">
                  <a [id]="'global-nav-menu-button' + level.id" class="ant-heading-two  visible-xxs visible-xs"
                    href="javascript:void(0)">{{level.content}}<span
                      class="motif-icon motif-angle-up pull-right"></span></a>
                </ng-container>
                <ng-container>
                  <ul [id]="'global-nav-menu' + level.id" class="menu-column">
                    <!-- Sub Menu Items -->
                    <ng-container *ngFor="let subLevel of level?.subLevel">
                      <li class="nested-menu-wrapper" *ngIf="subLevel.show && !subLevel?.config?.classId; else cccPlusFeature">
                        <!-- Added query params for sydney community program on condition basis wherein routerlink will encrypt params given directly in URL. -->
                        <a #menuItem *ngIf="subLevel.type === 'generic' && !isObject(subLevel.url) && subLevel.id !== 'liveChat' && subLevel.id !== 'careCoordinatorChat' " class="ant-menu-item" [routerLink]="subLevel.url" 
                        [queryParams]="subLevel.id==='sydneyCommunity' ? {id: 'SCOM'}:''"
                          [id]="subLevel.id" [attr.data-analytics]="subLevel.analytics">{{subLevel.content}}
                          <span class="message-badge" *ngIf="subLevel.id === 'messageCenter'&& numUnreadMessages > 0">
                            {{numUnreadMessages}}
                          </span>
                        
                        </a>

                        <a #menuItem *ngIf="subLevel.type === 'generic' && !isObject(subLevel.url) && (subLevel.id === 'careCoordinatorChat')" class="ant-menu-item"
                        [id]="subLevel.id" [attr.data-analytics]="subLevel.analytics" (click)="startCareCoordinatorChat($event)">{{subLevel.content}}
                          <span class="message-badge" *ngIf="chatUnreadMessages > 0">
                            {{chatUnreadMessages}}
                          </span>
                        </a>

                        <a #menuItem *ngIf="subLevel.type === 'generic' && !isObject(subLevel.url) && (subLevel.id === 'liveChat')" class="ant-menu-item" [href]="subLevel.url"
                          [id]="subLevel.id" [attr.data-analytics]="subLevel.analytics" (click)="liveChatPopUp($event)">{{subLevel.content}}</a>
                        <a #menuItem *ngIf="subLevel.type === 'external' && !subLevel.urlsByPlanCode && !isObject(subLevel.url)" class="ant-menu-item" [attr.id]="subLevel.id" [href]="subLevel.url" target="_blank">{{subLevel.content}} <span class="motif-icon motif-external-link motif-margin-left-4"></span></a>
                        <a #menuItem *ngIf="subLevel.type === 'external' && !subLevel.urlsByPlanCode && isObject(subLevel.url)" class="ant-menu-item" [attr.id]="subLevel.id" [href]="subLevel.url[memberContext.classId.substring(0,2)] ? subLevel.url[memberContext.classId.substring(0,2)] : subLevel.url['default']" target="_blank">{{subLevel.content}} <span class="motif-icon motif-external-link motif-margin-left-4"></span></a>
                        <a #menuItem *ngIf="subLevel.type === 'external' && subLevel.urlsByPlanCode && isObject(subLevel.urlsByPlanCode)" class="ant-menu-item" [attr.id]="subLevel.id" [href]="subLevel.urlsByPlanCode[memberContext.planCode] ? subLevel.urlsByPlanCode[memberContext.planCode] : subLevel.urlsByPlanCode['default']" target="_blank">{{subLevel.content}} <span class="motif-icon motif-external-link motif-margin-left-4"></span></a>
                        <ul>
                          <ng-container *ngFor="let link of subLevel.links">
                            <ng-container *ngTemplateOutlet="lnkTemplateRef; context: { link: link }"> </ng-container>
                          </ng-container>
                        </ul>
                      </li>
                      <ng-template #cccPlusFeature>
                        <li class="nested-menu-wrapper" *ngIf="subLevel.show && subLevel?.config?.classId === memberContext.classId.substring(0,2)">
                          <a #menuItem *ngIf="subLevel.type === 'generic' && !isObject(subLevel.url)" class="ant-menu-item" [routerLink]="subLevel.url"
                            [id]="subLevel.id" [attr.data-analytics]="subLevel.analytics">{{subLevel.content}}
                          </a>
                        </li>
                      </ng-template>
                      <ng-container *ngIf="!subLevel.content">
                        <ng-container *ngFor="let link of subLevel.links">
                          <ng-container *ngTemplateOutlet="lnkTemplateRef; context: { link: link }"> </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ul>
                </ng-container>
              </li>
            </ul>
          </li>
        </ng-container>
      </ng-container>

      <li class="menu-button-container hidden-lg hidden-md hidden-sm">
        <span class="tcp-mobile-menu-divider"></span>
      </li>

      <li *ngIf="!hasLanguageRestriction && hasNewDashboardRestriction"
        class="menu-button-container data-tcp-profile-link ant-heading-four hidden-lg hidden-md hidden-sm mobile-menu-padding motif-pad-left-5 motif-pad-top-5 motif-pad-bottom-5"
        id="ant-spanish-toggle-link">
        <a class="ant-heading-two" id="language-toggle" *ngIf="userLoggedIn" (click)="languageToggle()" (keyup.enter)="languageToggle()" data-analytics="">{{jsonLabels?.Language}}</a>
      </li>

      <li *ngIf="!hasMessageCenterRestriction && !hasNewDashboardRestriction"
        class="menu-button-container data-tcp-profile-link ant-heading-four hidden-lg hidden-md hidden-sm mobile-menu-padding motif-pad-left-5 motif-pad-top-5 motif-pad-bottom-5"
        id="ant-profile-link-tb">
        <div class="message-icon" aria-hidden="true">
          <a class="ant-heading-two" (click)="messageCenterClick()" (keyup.enter)="messageCenterClick()"
           data-analytics="profileLinkTopNav">{{jsonLabels?.Messages}}</a>
          <div *ngIf="numUnreadMessages !== 0" class="message-badge">{{numUnreadMessages}}</div>
        </div>
      </li>

      <li *ngIf="!hasProfileRestriction"
        class="menu-button-container data-tcp-profile-link ant-heading-four hidden-lg hidden-md hidden-sm mobile-menu-padding motif-pad-left-5 motif-pad-top-5 motif-pad-bottom-5"
        id="ant-profile-link-tb">
        <div data-tcpo-profile-tasks-cmp class="ant-task-bar-widget"></div>
        <a class="ant-heading-two" *ngIf="userLoggedIn" (click)="profileClick()" (keyup.enter)="profileClick()" data-analytics="profileLinkTopNav">{{jsonLabels?.Profile}}</a>
      </li>

      <li
        class="menu-button-container data-tcp-logout-link ant-heading-four hidden-lg hidden-md hidden-sm mobile-menu-padding motif-pad-left-5 motif-pad-bottom-5"
        id="ant-logout-link-tb">
        <a tabindex="0" class="ant-heading-two login-menu-button" *ngIf="userLoggedIn"
          (click)="logoutModal()" (keyup.enter)="logoutModal()" data-analytics="logoutLinkTopNav">{{jsonLabels?.Log_Out}}</a>
      </li>

    </ul>
  </div>

  <div class="text-right ant-secondary-nav">
    <li>
      <a *ngIf="userLoggedIn && !hasLanguageRestriction && hasNewDashboardRestriction" id="language-toggle" class="menu-button menu-link motif-margin-right-10 profile"
        (click)="languageToggle()" (keyup.enter)="languageToggle()" data-analytics="profileLinkTopNav" tabindex="0">{{jsonLabels?.Language}}</a>
      <div *ngIf="userLoggedIn && !hasMessageCenterRestriction && !hasNewDashboardRestriction" class="message-icon" aria-hidden="true">
          <span [class.no-messages]="numUnreadMessages !== 0" class="motif-icon motif-message"></span>
          <div *ngIf="numUnreadMessages !== 0" class="message-badge">{{numUnreadMessages}}</div>
        <a class="menu-button menu-link motif-margin-right-10 profile"
        routerLink="/secure/message-center" data-analytics="messagesTopNav">{{jsonLabels?.Messages}}</a>
      </div>
      <a *ngIf="userLoggedIn && !hasProfileRestriction" class="menu-button menu-link motif-margin-right-10 profile"
        routerLink="/secure/profile" data-analytics="profileLinkTopNav">{{jsonLabels?.Profile}}</a>
      <a tabindex="0" *ngIf="userLoggedIn" class="menu-button profile logout-button" (click)="logoutModal()" (keyup.enter)="logoutModal()" data-analytics="logoutLinkTopNav">{{jsonLabels?.Log_Out}}</a>
    </li>
  </div>
</div>

<ng-template #lnkTemplateRef let-link="link">
  <ng-container *ngIf="link.type === 'generic' && link.content">
    <li *ngIf="menuLoaded">
      <a #menuItem id="{{link.content}}" class="ant-menu-item"
        [attr.target]="link.target || undefined" [routerLink]="link.url"
        [attr.data-analytics]="link.analytics">{{link.content}}
        <span class="message-badge" *ngIf="link.content === 'Message Center' && numUnreadMessages > 0">
          {{numUnreadMessages}}
        </span>
      </a>
    </li>
  </ng-container>
  <ng-container *ngIf="link.type === 'external' && link.content">
    <li *ngIf="menuLoaded">
      <a #menuItem id="{{link.content}}" class="ant-menu-item"
        [attr.target]="link.target || undefined" [href]="link.url"
        [attr.data-analytics]="link.analytics">{{link.content}} <span
          class="fa fa-external-link gray ng-star-inserted"></span></a>
    </li>
  </ng-container>
  <ng-container *ngIf="link.type === 'sso' && link.config">
    <li>
      <app-secure-sso class="ant-menu-item" [analytics]="link.analytics" [isTopNav]="true" [ssoId]="link.config.ssoid" [linkText]="link.content"></app-secure-sso>
    </li>
  </ng-container>
</ng-template>

<!-- Modal for user logout -->
<ng-template #logoutModalTemplate>
  <div class="login-modal">
    <div class="modal-header">
      <button class="close motif-icon motif-delete" aria-label="Close Modal" (click)="stayLoggedIn()">
      </button>
    </div>
    <div class="modal-title">
      <h2 class="font-26">{{logoutLabels.Title}}</h2>
    </div>
    <div class="modal-body">
      <p class="font-16">{{logoutLabels.Body}}</p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary modal-btn"
        (click)="stayLoggedIn()">{{logoutLabels.Stay_Logged_In}}</button>
      <button class="btn continue modal-btn btn-primary" (click)="logout()">{{logoutLabels.Logout}}</button>
    </div>
  </div>
</ng-template>

<!-- Modal for inactivity logout -->
<ng-template #timeLogoutModal>
  <div class="login-modal">
    <div class="modal-header">
      <button class="close motif-icon motif-delete" aria-label="Close Modal" (click)="stayLoggedIn()">
      </button>
    </div>
    <div class="modal-title">
      <h2 class="font-26">{{timedLogoutLabels.Title}}</h2>
    </div>
    <div class="modal-body">
      <p class="font-16">{{timedLogoutLabels.Inactivity}} {{timeoutTime | date: 'shortTime'}}.</p>
      <p class="font-16">{{timedLogoutLabels.Notice}}</p>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary modal-btn"
        (click)="stayLoggedIn()">{{timedLogoutLabels.Stay_Logged_In}}</button>
      <button class="btn continue btn-primary modal-btn"
        (click)="logout()">{{timedLogoutLabels.Logout}}</button>
    </div>
  </div>
</ng-template>