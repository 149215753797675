import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { FocusManagementService } from '../../sydmed-shared/focus-management-service/focus-management.service';

@Component({
  selector: 'forgot-username-password',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class FupAppComponent {
  title = 'forgot-username-password';
  router: Router;
  activeRoute: ActivatedRoute;

  constructor(router: Router, activeRoute: ActivatedRoute, private focusManagementSvc: FocusManagementService) {
    this.router = router;
    this.activeRoute = activeRoute;
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      setTimeout(() => {
        this.focusManagementSvc.focusToHeader();
      });
    });
  }
}
