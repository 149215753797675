import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FindCareService {

  constructor(private httpSvc: HttpClientService) {}

  /**
   * @description getFindCareMetaData() is fetching find care metadata
   * @retruns {Observable<GetMedicaidMetaDataAPIResponse>}
   */
  public getFindCareMetadata() {
    return this.httpSvc.getObservable(UrlProperties.UrlProperties.endPoints.findCare.findCareMetadata);
  }
}