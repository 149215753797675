import { Injectable } from '@angular/core';
import { ICartItems } from '@anthem/mbrportal-pharmacy/interfaces/iCartItem';
import { IPrescriptionTypeData } from '@anthem/mbrportal-pharmacy/interfaces/iPrescriptionv2';
import { PrescriptionDataServiceV2 } from '@anthem/mbrportal-pharmacy/services/prescriptionDataSvcv2';
import { StorageService } from 'sydmed/libs/storage-service/src/public-api';
import { SessionStorage } from 'sydmed/libs/storage-service/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class AHDPrescriptionDataService extends PrescriptionDataServiceV2 {

    constructor(private storageService: StorageService) {
        super();
    }

    getCartData(): ICartItems {
        const cartData = this.storageService.getSessionItem<ICartItems>(SessionStorage.AHD_CART_DATA);
        if (cartData) {
            return cartData;
        }
        return super.getCartData();
    }

    getPBMPrescriptionData(): IPrescriptionTypeData {
        const prescriptionData = this.storageService.getSessionItem<IPrescriptionTypeData>(SessionStorage.AHD_PBM_PRESCRIPTION_DATA);
        if (prescriptionData) {
            return prescriptionData;
        }
        return super.getPBMPrescriptionData();
    }

    getSPLPrescriptionData(): IPrescriptionTypeData {
        const prescriptionData = this.storageService.getSessionItem<IPrescriptionTypeData>(SessionStorage.AHD_SPL_PRESCRIPTION_DATA);
        if (prescriptionData) {
            return prescriptionData;
        }
        return super.getSPLPrescriptionData();
    }

    setCartData(cartData: ICartItems): void {
        this.storageService.setSessionItem<ICartItems>({
            key: SessionStorage.AHD_CART_DATA, 
            value: cartData
        });
        super.setCartData(cartData);
    }

    setPBMPrescriptionData(pbmPrescriptionData: IPrescriptionTypeData): void {
        this.storageService.setSessionItem<IPrescriptionTypeData>({
            key: SessionStorage.AHD_PBM_PRESCRIPTION_DATA, 
            value: pbmPrescriptionData
        });
        super.setPBMPrescriptionData(pbmPrescriptionData);
    }

    setSPLPrescriptionData(splPrescriptionData: IPrescriptionTypeData): void {
        this.storageService.setSessionItem<IPrescriptionTypeData>({
            key: SessionStorage.AHD_SPL_PRESCRIPTION_DATA, 
            value: splPrescriptionData
        });
        super.setSPLPrescriptionData(splPrescriptionData);
    }
}
