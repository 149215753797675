import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AccountBalanceService } from '../services/account-balance.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { PharmacyRoutes } from '../../pharmacy/PharmacyRoutes.enum';
import { RestrictionService } from '../../pharmacy/services/restrictions.service';
import { Subscription } from 'rxjs';
import { Errors, PayAccountBalance, PabOverview, PabAccounts, PabLinks } from '../interfaces/PharmacyDashboardContent';

@Component( {
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: [ './account-balance.component.scss' ]
} )
export class AccountBalanceComponent implements OnInit, OnDestroy {
  @Input() pabContent: PayAccountBalance;
  @Input() errors: Errors;
  @Input() pbmBalance: string;
  @Input() specialtyBalance: string;
  @Input() balanceError: boolean;
  @Input() hasPBM: boolean;
  pabOverview: PabOverview;
  pabAccounts: PabAccounts;
  linkKeys: PabLinks;
  jsonErrors: {};
  showContent = false;
  svcError: string;
  pbmRoute = '/' + PharmacyRoutes.PayAccountBalance;
  specialtyRoute = '/' + PharmacyRoutes.PayAccountBalance;
  private sub = new Subscription();
  constructor( private restrictionSvc: RestrictionService ) { }

  ngOnInit(): void {
    this.pabOverview = this.pabContent?.Overview;
    this.pabAccounts = this.pabContent?.Accounts;
    this.linkKeys = this.pabContent?.Links;
    this.jsonErrors = this.errors;
    this.showContent = true;
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
