import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { AuthEventsService } from './authevents.service';
import { SessionStorage, StorageService } from '../storage-service/src/public-api';

@Injectable({
    providedIn: 'root'
})

export class AuthGuardService implements CanActivate {
    private codec: HttpUrlEncodingCodec = new HttpUrlEncodingCodec();
    private requestedPath: string;
    private decodedPath: string;
    private isOidcCallbackRequestUrl: boolean;
    private currentBrowserPath: string;
    private nextRequestedPath: string;
    private LOGINURL: string = '/public/login';
    private SECUREDASHBOARDURL: string = '/secure/dashboard';
    private OIDCLOGINURL: string = '/public/oidc/login';
    private SECURELOGINURL: string = '/secure-login'

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public authorizationService: AuthorizationService,
        private eventSvc: AuthEventsService,
        private storage: StorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        this.currentBrowserPath = window.location.href.replace(window.location.origin, '');

        // Removing code that would have allowed passing the SMSESSION cookie in by querystring - 12/03/2020        
        // handle query params
        // let urlParts: string[];
        // urlParts = this.currentBrowserPath.split('?');
        // this.currentBrowserPath = urlParts[0] || this.currentBrowserPath;

        this.nextRequestedPath = this.storage.getSessionItem(SessionStorage.NEXT_URL) || '';

        // If we don't have a target for the nextUrl, set a default of the secure dashboard page
        // If there's a target for the nextUrl, route the user there instead of to the secure dashboard URL
        if (this.nextRequestedPath.length <= 0) {
            this.nextRequestedPath = this.SECUREDASHBOARDURL;
        }

        // If we are not currently on a redirection from SiteMinder and not coming from login
        // we just set the next requested path to the same value as the current path
        // ** Example** 
        // Currently logged in and browsing the application, on path /secure/dashboard
        // clicks link for /secure/pharmacy
        // we just set next requested path to /secure/pharmacy
        if ((this.currentBrowserPath.indexOf(this.OIDCLOGINURL) > -1 || (this.currentBrowserPath.indexOf(this.LOGINURL) > -1) && this.currentBrowserPath.indexOf(this.SECURELOGINURL) === -1)) {

            this.requestedPath = window.location.pathname.concat(window.location.hash || '');
            this.decodedPath = this.codec.decodeValue(this.requestedPath);
            this.isOidcCallbackRequestUrl = (this.decodedPath?.indexOf(this.OIDCLOGINURL) > -1);

            // References to oidc are for future use
            // Don't set nextUrl to public/oidc/login
            // that's just the OIDC callback URL from SiteMinder
            // it should not be a destination the user navigates to with user action

            // Code below needs to be modified to prevent the the next URL from always being set to secure dashboard URL
            if (!this.isOidcCallbackRequestUrl && typeof (this.decodedPath) !== 'undefined' && this.decodedPath?.length > 0) {
                if (this.decodedPath.indexOf(this.LOGINURL) > -1) {
                    this.nextRequestedPath = this.SECUREDASHBOARDURL;
                } else {
                    this.nextRequestedPath = this.handleHashedURLsForRouting(this.decodedPath);
                }
            }

        } else {
            this.nextRequestedPath = this.handleHashedURLsForRouting(this.currentBrowserPath);
        }
        
        // Record the next URL the user is trying to get to
        // If they don't have an active session, they will get redirected to login
        // And we want to be able to return them to the requested URL
        // This also applies if this is a deep link situation
        
        if (this.currentBrowserPath.indexOf(this.SECURELOGINURL) === -1 && this.currentBrowserPath !== '/') {
            this.storage.setSessionItem({key: SessionStorage.NEXT_URL, value: this.nextRequestedPath});
        }   

        // Check if logged into SPA. If logged in return true and allow route to complete
        if (this.authorizationService.isLoggedIn()) {
            if (this.authorizationService.hasNoRouterRestriction(state.url)) {
                return true;
            } else {
                this.router.navigateByUrl(this.SECUREDASHBOARDURL);
                return false;
            }
        } else {
            // This should be handled like a single sign on
            // If we bootstrap here, the member data will be available by the time the route is activated
            // If not logged into SPA, bootstrap application with the information from SMSESSION cookie


            // Removing code that would have allowed passing the SMSESSION cookie in by querystring - 12/03/2020

            // let cookieFromQuerystring = urlParts.find((e) => {
            //     return (e.indexOf('SMSESSION=') > -1)
            // });


            // if (typeof(cookieFromQuerystring) != 'undefined') {

            //     return this.bootstrapUserByQuerystringAsync(cookieFromQuerystring?.split('=')[1] || null)
            //     .then((resp: any) => {
            //         return resp;
            //     })
            //     .catch((error: any) => {
            //         this.router.navigateByUrl(this.LOGINURL);
            //         return false;
            //     });

            // } else {

            return this.bootstrapUserAsync()
                .then((resp: any) => {
                    return resp;
                })
                .catch((error: any) => {
                    this.router.navigateByUrl(this.LOGINURL);
                    return false;
                });

            // }

        }

    }

    private async bootstrapUserAsync(): Promise<boolean> {
        return this.authorizationService.bootstrapUser().then((responseData: any) => {
            this.setSessionStorage(responseData);
            // With no client side ODIC just return true
            this.router.navigateByUrl(this.nextRequestedPath);
            return true;
        }, ((error: any) => {
            this.router.navigateByUrl(this.LOGINURL);
            return false;
        })
        );

    }


    // Removing code that would have allowed passing the SMSESSION cookie in by querystring - 12/03/2020

    // private async bootstrapUserByQuerystringAsync(smsessionCookie?: string): Promise<boolean> {
    //     return this.authorizationService.bootstrapUser(smsessionCookie).then((responseData: any) => {
    //         this.setSessionStorage(responseData); 
    //         return true;
    //     }, ((error: any) => {
    //         this.router.navigateByUrl(this.LOGINURL);
    //         return false;
    //     })
    //     );

    // }

    private setSessionStorage(data: any) {
        const memCtxResponse: string = JSON.stringify(data.body);
        const gbdToken: string = data.headers.get('gbd-token');

        window.sessionStorage.setItem('sydMedMemberContext', memCtxResponse);
        window.sessionStorage.setItem('sydMedLoggedIn', 'true');
        window.sessionStorage.setItem('gbd-token', gbdToken);

        this.eventSvc.updateMenu(data.body.hcid);
    }

    private handleHashedURLsForRouting(url: string) {
        // With no hash routing in place, we can just route to the URL 
        // With hash routing in place, the route.navigateByUrl function needs to have the hashes stripped out 
        // if there were a part of the URL in the browser when the route was initiated
        return url.replace('/#/', '/');
    }

}