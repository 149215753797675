<div class="motif-theme">
    <ng-container *ngIf="searchTerm; else NoSearchTerm">
        <h2 class="prescription-heading section-header-two" *ngIf="isValidNumber(numberOfItems) && dateRange">
            {{numberOfItems}} <span>{{ content  | si:{member: '"' + searchTerm + '"', dateRange: dateRange} }}</span>
        </h2>
    </ng-container>
    <ng-template #NoSearchTerm>
        <h2 class="prescription-heading section-header-two" *ngIf="isValidNumber(numberOfItems) && member && dateRange">
            {{numberOfItems}} <span>{{ content  | si:{member: member, dateRange: dateRange} }}</span>
        </h2>
    </ng-template>
</div>