export enum PharmacyRoutes {
    Dashboard = 'secure/pharmacy/dashboard',
    Refills = 'secure/pharmacy/prescriptions',
    Orders = 'secure/pharmacy/prescriptions/orders',
    FindPharmacy = 'secure/find-pharmacy',
    Pharmacy = 'secure/pharmacy',
    AllPrescriptions = 'secure/pharmacy/allprescriptions',
    Onboarding = 'secure/pharmacy/welcome',
    PayAccountBalance = 'secure/pharmacy/pay-account-balance',
    PaymentHistory = 'secure/pharmacy/paymenthistory',
    PrescriptionOrder = 'secure/pharmacy/prescriptions/healthcheck',
    MedicationSearch = 'secure/pharmacy/medication-search',
    ZipDrug = 'secure/pharmacy/zipdrug',
}

// /pharmacy/dashboard --Dashboard
// /pharmacy/prescriptions --Refills
// /pharmacy/prescriptions/orders -- Order Status (My Orders)
// /pharmacy/find-pharmacy -- Pharmacy Search (Find a Pharmacy)
// /phamacy/allprescriptions - All Prescriptions
// /pharmacy/welcome - Pharmacy Onboarding
// /pharmacy/pay-account-balance - Pay Account Balance
// /PrescriptionOrder = Refill/Checkout flow

