import { Component, Input } from '@angular/core';
import { DentalBenefitsContent } from '../interfaces/benefitsContent';

@Component({
    selector: 'benefits-dental-component',
    templateUrl: './dental-benefits.component.html',
    styleUrls: ['./dental-benefits.component.scss']
})
export class DentalBenefitsComponent {
    @Input() content: DentalBenefitsContent;
}
