<div id="grievance_appeals_success" class="text-center padding-bottom-45" role="alert">
    <div class="popup-alert">
        <em class="far fa-check-circle"></em>
        <h3 [innerHtml]="confirmationLabels?.successHeader"></h3>
    </div>

    <p [innerHtml]="confirmationLabels?.message"></p>

    <button type="button" id="Go_btn" attr.data-analytics="{{labels?.sent?.returntoHomeSuccessBtnAnalytics}}" class="btn btn btn-primary" [innerHtml]="confirmationLabels?.return_btn" [routerLink]="confirmationLabels?.return_btn_url"></button>
</div>
