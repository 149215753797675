import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { ThirdPartyInsuranceComponent } from "./third-party-insurance/third-party-insurance.component";

const routes: Routes = [
  { path: 'secure/third-party-insurance', component: ThirdPartyInsuranceComponent, pathMatch: 'full', canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThirdPartyInsuranceRoutingModule { }

