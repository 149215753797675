<div class="uxd-theme-motif container">
    <div class="margin-top-20 reg-top-1 flex-column-center">
        <div class="width-100 full-width-alert-spacer" *ngIf="aslError" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{aslError}}</span>
        </div>
    </div>
    <div class="max-width-elements">
        <h1 tabindex="-1" class="text-left margin-bottom-36 font-32">{{jsonLabels?.Page_Heading}}</h1>
        <p class="text-left">{{jsonLabels?.Page_Instruction}}</p>
        <form [formGroup]="recoveryNumberForm" (ngSubmit)="sendCode()" id="recoveryForm" class="account-recovery-number-form" name="recoveryForm">
            <div class="margin-bottom-32 margin-top-30">
                <label for="phone" class="text-left font-16">{{jsonLabels?.Fields.Phone_Number}}</label>
                <input id="phone" name="phone" type="text" formControlName="phone" libPhoneFormat
                    [preValue]="recoveryNumberForm.value.phone" placeholder="XXX-XXX-XXXX"
                    class="ant-input-long ant-text-input" aria-describedby="phoneNumberError">

                <ul id="phoneNumberError" class="error" *ngIf="recoveryNumberForm.controls['phone']?.touched && recoveryNumberForm.controls['phone']?.invalid" aria-live="polite">
                    <li *ngIf="recoveryNumberForm.controls['phone']?.errors.required">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonLabels?.Fields.Phone_Number}}</span>
                        <span class="font-12">{{jsonErrors.Required_err}}</span>
                    </li>
                    <li *ngIf="recoveryNumberForm.controls['phone']?.errors.invalidPhone">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonErrors.Phone_Format}}</span>
                    </li>
                </ul>
            </div>
            <div class="no-outline info-width margin-bottom-48">
                <label for="verification" class="text-left font-16">{{jsonLabels?.Fields.Verification_Required}}</label>
                <p>{{jsonLabels?.Instructions.New_Number}}</p>
            </div>
            <div class="margin-bottom-32 margin-top-30">
                <label for="existingPhone" class="text-left font-16">{{jsonLabels?.Fields.Old_Phone_Number}}</label>
                <input id="existingPhone" name="existingPhone" type="text" formControlName="existingPhone" libPhoneFormat
                    [preValue]="recoveryNumberForm.value.existingPhone" placeholder="XXX-XXX-XXXX"
                    class="ant-input-long ant-text-input" aria-describedby="existingPhoneNumberError">

                <ul id="existingPhoneNumberError" class="error" *ngIf="recoveryNumberForm.controls['existingPhone']?.touched && recoveryNumberForm.controls['existingPhone']?.invalid" aria-live="polite">
                    <li *ngIf="recoveryNumberForm.controls['existingPhone']?.errors.required">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonLabels?.Fields.Old_Phone_Number}}</span>
                        <span class="font-12">{{jsonErrors.Required_err}}</span>
                    </li>
                    <li *ngIf="recoveryNumberForm.controls['existingPhone']?.errors.invalidPhone">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonErrors.Phone_Format}}</span>
                    </li>
                </ul>
            </div>
            <div class="margin-bottom-32 margin-top-30">
                <label for="zipCode" class="text-left font-16">{{jsonLabels?.Fields.Zip_Code}}</label>
                <input id="zipCode" name="zipCode" type="text" formControlName="zipCode" libZipCodeFormat maxlength="5"
                    class="ant-input-long ant-text-input" aria-describedby="zipCodeError">

                <ul id="zipCodeError" class="error" *ngIf="recoveryNumberForm.controls['zipCode']?.touched && recoveryNumberForm.controls['zipCode']?.invalid" aria-live="polite">
                    <li *ngIf="recoveryNumberForm.controls['zipCode']?.errors.required">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonLabels?.Fields.Zip_Code}}</span>
                        <span class="font-12">{{jsonErrors.Required_err}}</span>
                    </li>
                    <li *ngIf="recoveryNumberForm.controls['zipCode']?.errors.pattern">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonErrors.Zip_Format_err}}</span>
                    </li>
                </ul>
            </div>
            <div class="margin-bottom-32 margin-top-30">
                <label for="addressNumber" class="text-left font-16">{{jsonLabels?.Fields.Address_Number}}</label>
                <input id="addressNumber" name="addressNumber" type="text" formControlName="addressNumber" libNumbersOnly
                    class="ant-input-long ant-text-input" aria-describedby="addressNumberError">

                <ul id="addressNumberError" class="error" *ngIf="recoveryNumberForm.controls['addressNumber']?.touched && recoveryNumberForm.controls['addressNumber']?.invalid" aria-live="polite">
                    <li *ngIf="recoveryNumberForm.controls['addressNumber']?.errors.required">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonLabels?.Fields.Address_Number}}</span>
                        <span class="font-12">{{jsonErrors.Required_err}}</span>
                    </li>
                    <li *ngIf="recoveryNumberForm.controls['addressNumber']?.errors?.invalid">
                        <span class="icon fa fa-times font-14 margin-right-8"></span>
                        <span class="font-12">{{jsonErrors.AddressNumber_Format_err}}</span>
                    </li>
                </ul>
            </div>
            <div class="text-center button-group">
                <div class="button-group flex-horizontal-end margin-bottom-70">
                    <button type="button" class="btn btn-secondary margin-right-16"
                        (click)="back()" data-analytics="backAddArnNumberReg">{{jsonLabels?.Buttons.Back}}</button>
                        <button type="submit"
                        class="btn btn-primary color margin-left-0"
                        data-analytics="nextAddArnNumberReg">{{jsonLabels?.Buttons.Call_Me_Now}}</button>
                </div>
            </div>
        </form>
    </div>
</div>