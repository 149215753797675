import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable, of } from 'rxjs';

import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { CommunicationPreferencesContactInfo } from '../models/communication-preferences-contact-info.model';
import { MyProfileDataService } from '../my-profile-data.service';
import { UpdateCommPrefsRequest } from 'gbd-models';
@Injectable({
  providedIn: 'root'
})
export class CommunicationPreferencesService {

  // API URLs
  private commPrefsUrl = UrlProperties.UrlProperties.endPoints.pharmacy.commPrefs;

  constructor(
    private httpSvc: HttpClientService,
    private myProfileDataSvc: MyProfileDataService
  ) {}

  private contactInfo: CommunicationPreferencesContactInfo;

  public getCommPrefs() {      
    if (this.myProfileDataSvc.getCommPrefContactInfoData().value?.gbdContactInfo) {
      return of(this.myProfileDataSvc.getCommPrefContactInfoData().value);
    } else {
      return  this.httpSvc.getObservable(this.commPrefsUrl);
    }
  }
  
  public updateCommPrefs( requestObj: UpdateCommPrefsRequest) {
    return this.httpSvc.postObservable(this.commPrefsUrl, JSON.stringify(requestObj));
  }
}
