import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AhdPharmacyRoutes } from '../../ahd-pharmacy/ahd-pharmacy-routes.enum';
import { PharmacyRoutes } from '../PharmacyRoutes.enum';
import { AHDUtils } from '../utils/ahd-pharmacy-utils';


@Injectable({
    providedIn: 'root',
})
export class PharmacyRouteGuardService implements CanActivate {
    private PharmacyURL = 'secure/pharmacy/dashboard';
    hasVisitedPharmacyDashboard: boolean;
    hasVisitedAllPrescriptions: boolean;
    hasVisitedOrders: boolean;

    constructor(
        public router: Router,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Promise<boolean> {

        if (state.url.includes('prescriptions/confirmation')) {
            if (!sessionStorage.getItem('checkoutSuccess')) {
                this.router.navigateByUrl(this.PharmacyURL);
            } else {
                sessionStorage.removeItem('checkoutSuccess');
            }
        } else if (state.url.includes('pay-account-balance/confirmation')) {
            if (!sessionStorage.getItem('pabSubmitted')) {
                this.router.navigateByUrl(this.PharmacyURL);
            } else {
                sessionStorage.removeItem('pabSubmitted');
            }  /******** Temporary solution to route  AHD vs Non AHD member. Change the logic once we have the final solution *******/
        } else if (state.url.includes(PharmacyRoutes.Dashboard)) {
            if (AHDUtils.isAHDMember()) {
                this.router.navigateByUrl(AhdPharmacyRoutes.DASHBOARD);
            } else {
                 if (!this.hasVisitedPharmacyDashboard) {
                    this.router.navigateByUrl(PharmacyRoutes.Dashboard);
                    this.hasVisitedPharmacyDashboard = true;
                 }
            }
        } else if (state.url.includes(PharmacyRoutes.Orders)) {
            if (AHDUtils.isAHDMember()) {
                this.router.navigateByUrl(AhdPharmacyRoutes.ORDERS);
            } else {
                if (!this.hasVisitedOrders){
                    this.router.navigateByUrl(PharmacyRoutes.Orders);
                    this.hasVisitedOrders = true;
                }
            }
        } else if (state.url.includes(PharmacyRoutes.AllPrescriptions)) {
            if (AHDUtils.isAHDMember()) {
                this.router.navigateByUrl(AhdPharmacyRoutes.ALL_PRESCRIPTIONS);
            } else { 
                if(!this.hasVisitedAllPrescriptions) {
                    this.router.navigateByUrl(PharmacyRoutes.AllPrescriptions);
                    this.hasVisitedAllPrescriptions = true;
                }
            }
        }
        return true;
    }
}
