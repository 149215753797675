import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ErrorMsgs, GrievancesAndAppealsLabels, MemberData } from '../../models/labels';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { AppealsAndGrievanceServices } from '../../services/appeals-and-grievances.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { AppealsAndgrivencesFormdata } from '../../models/formValues';
import { FileAttachmentService } from 'sydmed/libs/file-attachment/services/file-attachment.service';
import { HelperService } from '../../utils/helper.service';
import { AppealsAndGrievanceRequest, FormType, Priority } from 'gbd-models';
import { ModalRef } from '@anthem/uxd/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-slideout',
  templateUrl: './review-slideout.component.html',
  styleUrls: ['./review-slideout.component.scss']
})
export class ReviewSlideoutComponent implements OnInit {
  @Input() activePath: string;
  @Input() formValues: AppealsAndgrivencesFormdata;
  @Input() modalRef: ModalRef<any, any>;
  @Output("closeSlide") closeSlide: EventEmitter<any> = new EventEmitter();
  @Input("uploadAttachmentRestriction") uploadAttachmentRestriction: boolean = false;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  public content: any;
  public labels: GrievancesAndAppealsLabels;  
  public errorMsgsLabels: ErrorMsgs;
  public memberData: MemberData;
  public maskChar: string = '.';
  public previewAttachmentFiles: string[];
  public isAppeal: boolean;
  public priorityValue: any;
  public loading: boolean = false;
  public serviceError: string = '';

  constructor(
    private appealsAndGrievanceService: AppealsAndGrievanceServices,
    private errCodeSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService,
    public fileService: FileAttachmentService,
    private router: Router,
    public helperService: HelperService) {
    this.memberData = this.getMemberData();
  }

  ngOnInit(): void {
    this.getLabels();
    this.getErrorMsgs();
    this.isAppeal = this.activePath === FormType.APPEALS.toLowerCase();
  }

  getLabels(): void {
    this.jsonSvc.getJSON('appeals-and-grievances').subscribe(response => {
      this.content = response;
      this.labels = (this.activePath === FormType.APPEALS.toLowerCase()) ? response.appeals : response.grievances;
    });
  }
  getErrorMsgs() {
    this.jsonSvc.getJSON('appeals-and-grievances-error-messages').subscribe(data => {
      this.errorMsgsLabels = data.ErrorMsgs;
    });
  }
  getMemberData(): MemberData {
    let memberContext = window.sessionStorage.getItem('sydMedMemberContext') && JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    let memberData: MemberData;
    if (typeof (memberContext) !== undefined) {
      memberData = {
        firstName: this.format(memberContext?.memberName?.firstName),
        lastName: this.format(memberContext?.memberName?.lastName),
        hcid: this.format(memberContext?.hcid)
      }
    }
    return memberData;
  }
  private format(value: any): any {
    if (!value) {
      value = '';
    }
    return value;
  }
  public closeModal() {
    this.closeSlide.emit();
  }
  submitAppealsAndGrievancesForm() {
      this.loading = true;
      if(this.formValues.reasonForRequests?.requestCategory.includes(this.content?.reasonForRequest?.form?.othersRadioButtonDefaultValue)){
          this.formValues['reasonForRequests']['requestCategory'] = this.content?.reasonForRequest.form.othersRadioButtonvalue;
      }
      if((this.formValues?.healthOption?.optionCategory === this.content?.healthOption?.form?.radioButtonsGroup[0]?.name) || 
          (this.formValues?.describeYourSituation?.requestCategory === this.content?.describeYourSituation?.form?.radioButtonsGroup[0]?.name)){
        this.priorityValue = Priority?.EXPEDITED;
      }else{
        this.priorityValue = Priority?.STANDARD;
      }
    
      const appealsForm =  FormType.APPEALS.toLowerCase();
      
      // Note: Once Attachment Implementation Completed in UI the value will be assigned to document.
      const requestObject : AppealsAndGrievanceRequest = { 
        authorizedRepresentativeInfo:{
          firstName:this.formValues?.consentInformation?.firstName ? this.formValues.consentInformation.firstName : '',
          lastName:this.formValues?.consentInformation?.lastName ? this.formValues.consentInformation.lastName : '',
          phoneNumber:this.formValues?.consentInformation?.phoneNumber?.phone ? this.formValues.consentInformation.phoneNumber.phone : ''
        },
        authorizationNumber : this.formValues.memberInformation.authorizationNumber ? this.formValues.memberInformation.authorizationNumber : '',
        representativeConsent: this.formValues.consentInformation.consentcheck,
        phoneNumber :  this.formValues.memberInformation.phoneNumber.phone,
        reasonForRequest : {
          reason : this.activePath === appealsForm ?  '' :  this.formValues.reasonForRequests.requestCategory,
          description : this.activePath === appealsForm ? '' : this.formValues.reasonForRequests.requestCategoryComments
        },
        priority: this.priorityValue,
        description : this.formValues.issueDescription.description,
        formType : this.activePath === appealsForm ? FormType.APPEALS : FormType.GRIEVANCE,
        documents : this.helperService.getDocumentsDetails()
      };

      this.appealsAndGrievanceService.submitGNAFormData(requestObject).subscribe(
        (res: any) => {
          this.loading = false;
          this.closeModal();
          if (this.activePath === appealsForm) {
            this.router.navigate(['secure/form-confirmation/appeal']);
          } else {
            this.router.navigate(['secure/form-confirmation/grievances']);
          }
        },
        err => {
          this.loading = false; 
          if ( err && this.errorMsgsLabels ) {
            this.serviceError = this.errCodeSvc.errorCodeMapper(err, this.errorMsgsLabels);
          }    
        }
      );
  }
  trackByFile(index: number, file: any) {
    return file;
  }
  /**
   * @Description trimFileName method is used to trim and mask the attachments file name if the file is >25 characters
   * @param filename parameter is the file name
   * @param limit is used to trim the character if the filename exceeds the limit
   * @returns masked file name 
   */
  trimFileName(filename, limit = 25): string {
    const nonMaskableLength =  Math.floor((filename.length*30)/100);
    const split = filename.indexOf(".");
    const name = filename.substring(0, split);
    const ext = filename.substring(split);
    let firstTrimName = name.substring(0, nonMaskableLength);
    let lastTrimName = name.slice(-4);
  
    if (name.length > limit) {
      firstTrimName = firstTrimName.padEnd(nonMaskableLength + 3, this.maskChar);
      return firstTrimName + lastTrimName + ext;
    } else {
      return filename;
    }
  }
}
