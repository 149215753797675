<div class="uxd-theme-motif container">
  <div *ngIf="!loadingSpinner" class="padding-bottom-66">
    <div *ngIf="aslError" class="full-width-alert-spacer" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
      <span [innerHTML]="aslError"></span>
    </div>
    <app-security-questions (linkClicked)="verifyQuestions($event)" (backButtonClicked)="backButtonClicked($event)"
      [jsonLabels]="jsonLabels" [jsonErrors]="jsonErrors" [endpoint]="endpoint">
      ></app-security-questions>
  </div>
  <div class="ant-margin-top-60 loading-spinner-alignment" *ngIf="loadingSpinner">
    <uxd-spinner></uxd-spinner>
  </div>
</div>
<ng-template #touModalTemplate>
  <div class="tou-modal" id="delete-account">
    <div class="modal-header">
      <button class="close motif-icon motif-delete" aria-label="Close delete account modal" (click)="onClose()">
      </button>
    </div>
    <div class="modal-title">
      <h2 class="font-26">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.ModalHeader}}</h2>
    </div>
    <div class="modal-body">
      <p class="font-16">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.ModalDescription}}</p>

      <!-- checkbox -->
      <div class="modalcheckbox">
        <div class="checkbox font-14" data-uxd-checkbox-input-cmp [definition]="checkbox" aria-required="true"
          [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}"></div>
      </div>

    </div>
    <div class="margin-top-14  errorMsg" *ngIf="isSubmitted && !isChecked">
      <div>
        <span class="fas fa-times error vertical-align-middle font-12 margin-right-8"></span>
        <span class="error font-12">{{jsonLabels?.Error.Terms_Of_Use_error}}</span>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary modal-btn" data-analytics="cancelPolicyTermsOfUseLogin"
        (click)="onClose()">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.CancelButton}}</button>
      <button class="btn continue modal-btn btn-primary" data-analytics="continuePolicyTermsOfUseLogin"
        (click)="onUpdateTou()">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.ContinueButton}}</button>
    </div>
  </div>
</ng-template>