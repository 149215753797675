import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from "sydmed/libs/url-properties/src/lib/url-properties.class";
import { AppealsAndGrievanceRequest, UploadAttachmentRequest } from 'gbd-models';

@Injectable({
  providedIn: 'root'
})
export class AppealsAndGrievanceServices {

    constructor(private httpSvc: HttpClientService) { }

  //submit Appeals and Grievance from data.
  public submitGNAFormData(requestObj: AppealsAndGrievanceRequest) {
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.appealsAndGrievance.submitANGForm, requestObj)
  }

  // upload file for virus scan
  public uploadFileAttachment(requestObj: UploadAttachmentRequest) {
    return this.httpSvc.postObservable(UrlProperties.UrlProperties.endPoints.appealsAndGrievance.uploadFileAttachment, requestObj);
  }
}