<div class="full-callout">
  <div class="container">
    <div class="row">
      <div class="claims-detail-wrapper" id="claimsDetailWrapper{{claimsModel.clmId}}">
        <div class="claims-detail-header-container">
          <div class="claims-detail-col">
            <div class="claims-detail-number">
              <!-- Claim case number on blue gradient background label -->
              <h3 class="ant-heading-five claims-case-header">
                <span [innerHTML]="content.detail.claimTrackingId"></span>&nbsp;
                <span class=" claims-case-number" [innerHTML]="claimsModel.clmId"></span>
              </h3>
            </div>
          </div>
          <div class="claims-detail-col-2">
            <div *ngIf="showPrintLink()" class="claims-detail-print-details">
              <a href="javascript:void(0)" [ngClass]="showPrintLink() ? 'showPrintBorder': ''"
                [attr.id]="'show-print-line-details' + claimsModel.clmId"
                [attr.data-analytics]="content.analytics.printClaim" (click)="printDetails()">
                <span class="ant-link-text" [innerHTML]="content.detail.printDetails"></span>
                <span class="motif-icon motif-print"></span>
              </a>
            </div>
            <div class="claims-detail-print-details">
              <a href="javascript:void(0)" class="motif-margin-left-9" *ngIf="displayEobLink"
                [attr.id]="'claimDetailEOBButtonx' + claimsModel.clmId"
                [attr.data-analytics]="content.analytics.viewPrintEOB" (click)="getEOBFile(claimsModel.eob[0].eobUid, claimsModel.clmId)"
                [ngClass]="displayEobLink ? 'showPrintBorder': ''">
                <span class="claims-eob-download-text ant-link-text" [attr.aria-label]=""
                  [attr.data-analytics]="content.analytics.viewPrintEOB" [innerHTML]="content.detail.explanationOfBenefits"></span>
                <span class="motif-icon motif-download motif-margin-left-5" aria-hidden="true"></span>
              </a>
              <!-- <p *ngIf="displayEobLink && msEdgeEobComptMsg && _window.isEdge"
                class="claims-eob-ms-edge motif-margin-top-3"
                [innerHTML]="content.EOB.msEdgeCompMesg">
              </p> -->
            </div>
          </div>
        </div>
        <div *ngIf="eobFailure"
          class="callout-block callout-light-shadow motif-claims-card error-block motif-margin-top-9">
          <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}"
            [attr.id]="'eobError-' + claimsModel.clmId + '-alert'">
            <span id="eobError-{{claimsModel.clmId}}-label" [innerHTML]="content.eob.eobLoadError"></span>
          </div>
        </div>
        <div id="medical-container" class="claims-detail-container">
          <!-- Medical Details start -->
          <!-- claim total memeber responsibility amount -->
          <div class="claims-detail-content claims-detail-math-container"
            *ngIf="claimsModel.classCode === 'M' && claimsModel.statusName !== 'Pending' && content.detail.enableShowMeTheMath === 'false'">
            <h4 class="section-header-three yourResponsibility"
              [attr.aria-label]="claimsModel.clmId + ' ' + content.detail.yourResponsibility + ' : ' + claimsModel.totalMbrResponsibilityAmt">
              <span [innerHtml]="content.detail.yourResponsibility + ' : '"></span><span
                [innerHtml]="claimsModel.totalMbrResponsibilityAmt"></span></h4>
          </div>

          <!-- Show Me The Math -->
          <!-- Show me the Math section is being hidden for Medical claims as part of SYDMED-9364. In future, to enable the section, we need to set enableShowMeTheMath to true in the content -->
          <div  *ngIf="content.detail.enableShowMeTheMath === 'true' && claimsModel.classCode === 'M'" > 
          <div id="show-me-the-math" class="pharmacy-claims-col"  *ngFor="let claimLine of claimsModel.lineItems; let _index = index;">
                      <!--service description tile-->
            <div class="description-tile" >
              <h4 class="section-header-three" [attr.id]="'claims-detail-service-header-' + claimsModel.clmId"
                [attr.aria-label]="content.detail.detailedService.header + ' ' + claimsModel.clmId"
                [innerHTML]="content.detail.detailedService.header"></h4>
              <div class="prescript-data-list">
                <dl>
                    <div 
                      [attr.id]="'claims-detail-service-date-' + claimsModel.clmId" class="font-16 margin-bottom-20">
                      <div [innerHTML]="content.detail.detailedService.date" class="margin-bottom-4 service-date"></div>
                      <div [attr.id]="'claims-detail-service-date-value-' + claimsModel.clmId"
                        [innerHTML]="claimLine.serviceStartDt | date:'MMMM dd,yyyy'"></div>
                    </div>
                    <div 
                      [attr.id]="'claims-detail-service-description-' + claimsModel.clmId" class="font-16">
                      <div [innerHTML]="content.detail.detailedService.description"  class="margin-bottom-4 service-date"></div>
                      <div [attr.id]="'claims-detail-service-description-value-' + claimsModel.clmId"
                        [innerHTML]="claimLine.procedureCd.description"></div>
                    </div>

                </dl>
              </div>
            </div>

          <!--service description tile end-->
          <div id="show-math" class="claims-detail-content claims-detail-timeline rx-claims-container " >
            <h4 class="section-header-three" [innerHTML]="content.detail.showMeTheMath"
              [attr.aria-label]="content.detail.ariaShowMeMath + ' ' + claimsModel.clmId"></h4>
            <div class="claims-rx-flex-container">
              <dl>
                <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-billed-' + claimsModel.clmId">
                  <dt [innerHTML]="content.detail.pharBilledLabel"></dt>
                  <dd [innerHTML]="claimLine.chargeAmt"></dd>
                </div>
                <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-discounted-amount-' + claimsModel.clmId">
                  <dt [innerHTML]="content.detail.discountAmt"></dt>
                  <dd [innerHTML]="claimLine.discountAmt"></dd>
                </div>
                <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-covered-amount-' + claimsModel.clmId">
                  <dt [innerHTML]="content.detail.amtCovered"></dt>
                  <dd [innerHTML]="claimLine.allowedAmt"></dd>
                </div>
                <div class="table-row-rx"
                  [attr.id]="'claims-detail-pharmacy-not-covered-amount-' + claimsModel.clmId">
                  <dt [innerHTML]="content.detail.amtNotCovered"></dt>
                  <dd [innerHTML]="claimLine.disallowedAmt"></dd>
                </div>
                <div id="amount-plan-paid"  class="table-row-rx">
                  <dt id="amount-plan-paid-dt" [innerHTML]="content.detail.amtPlanPaid"></dt>
                  <dd id="amount-plan-paid-dl" [innerHTML]="claimLine.paidAmt"></dd>
                </div>
                <div class="table-row-rx">
                  <dt id="member-responsibility-dt"   [innerHTML]="content.detail.yourResponsibility"></dt>
                  <dd id="member-responsibility-dl"  [innerHTML]="claimLine.mbrResponsibilityAmt"></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
          <div class="claims-flex-container" *ngIf="claimsModel.classCode === 'M' && content.detail.enableShowMeTheMath === 'false'">
            <!-- Claim Status Section start-->
            <div class="claims-detail-card-sm">
              <ng-container
                *ngTemplateOutlet="statusTemplateRef; context: {claimsModel: claimsModel, content: content}">
              </ng-container>
            </div>
            <!-- Claim Status Section end-->

            <!-- Claim Timeline Section start-->
            <div class="claims-detail-content claims-detail-timeline" *ngIf="content.detail.enableShowMeTheMath === 'false'">
              <h4 class="section-header-three"
                [attr.aria-label]="'claim timeline for claim with id' + ' ' + claimsModel.clmId">
                <span [innerHTML]="content.detail.timeLine"></span>
              </h4>
              <!-- claims timeline -->
              <div class="claims-details-timeline-wrapper">
                <div class="claims-detail-timeline-container">
                  <div class="claims-detail-timeline-progress timeline-progress-syd service-date-progress"></div>
                  <span class="claims-timeline-dash timeline-dash-syd"></span>
                  <div class="claims-detail-timeline-progress timeline-progress-syd claim-received-progress"></div>
                  <span class="claims-timeline-dash timeline-dash-syd"></span>
                  <div class="claims-detail-timeline-progress timeline-progress-syd claim-pending-progress"></div>
                </div>
                <div class="claims-detail-timeline-container">
                  <div class="claims-detail-dates"
                    [attr.aria-label]="content.detail.serviceDate + ' ' + content.detail.ariaOn + ' ' + dateUtil.getDatePart(claimsModel.startDate, 'longDate')">
                    <p [innerHTML]="claimsModel.startDate"> </p>
                    <span [innerHTML]="content.detail.serviceDate"></span>
                  </div>
                  <div class="claims-detail-dates clm-received"
                    [attr.aria-label]="content.detail.claimReceived + ' ' + content.detail.ariaOn + ' ' + dateUtil.getDatePart(claimsModel.endDate, 'longDate')">
                    <p [innerHTML]="claimsModel.endDate"></p>
                    <span [innerHTML]="content.detail.claimReceived"></span>
                  </div>
                  <div class="claims-detail-dates clm-approved"
                    [attr.aria-label]="content.detail.claimLbl + ' '+ claimsModel.statusName + ' ' + content.detail.ariaOn + ' ' + (claimsModel.clmProcessDt === content.notAvailable ? dateUtil.getDatePart(today, 'MM/dd/yyyy') : dateUtil.getDatePart(claimsModel.clmProcessDt, 'MM/dd/yyyy'))">
                    <p
                      [innerHTML]="claimsModel.clmProcessDt === content.notAvailable ? dateUtil.getDatePart(today, 'MM/dd/yyyy') : dateUtil.getDatePart(claimsModel.clmProcessDt, 'MM/dd/yyyy')">
                    </p>
                    <span [innerHTML]="content.detail.claimLbl +' '+ claimsModel.statusName"></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Claim Timeline Section end-->

          </div>
          <!-- Medical Details end -->

          <!-- Pharmacy Details start -->
          <div class="claims-flex-container" *ngIf="claimsModel.classCode === 'P'">
            <div class="pharmacy-claims-col ng-star-inserted">
              <div class="claims-detail-content rx-lg-card">
                <h4 class="section-header-three" [attr.id]="'claims-detail-prescription-header-' + claimsModel.clmId"
                  [attr.aria-label]="content.detail.prescription.ariaHeader + ' ' + claimsModel.clmId"
                  [innerHTML]="content.detail.prescription.header"></h4>
                <div class="prescript-data-list">
                  <dl>
                    <div class="table-row-rx">
                      <div class="table-row-rx-sm"
                        [attr.id]="'claims-detail-prescription-medication-name-' + claimsModel.clmId">
                        <dt [innerHTML]="content.detail.prescription.medicationName"></dt>
                        <dd [attr.id]="'claims-detail-prescription-medication-name-value-' + claimsModel.clmId"
                          [innerHTML]="claimsModel.medicationLabelNm"></dd>
                      </div>
                      <div class="table-row-rx-sm"
                        [attr.id]="'claims-detail-prescription-medication-quantity-' + claimsModel.clmId">
                        <dt [innerHTML]="content.detail.prescription.medicationQuantity"></dt>
                        <dd [attr.id]="'claims-detail-prescription-medication-quantity-value-' + claimsModel.clmId"
                          [innerHTML]="claimsModel.quantity"></dd>
                      </div>
                    </div>
                    <div class="table-row-rx">
                      <div class="table-row-rx-sm"
                        [attr.id]="'claims-detail-prescription-rx-number-' + claimsModel.clmId">
                        <dt [innerHTML]="content.detail.prescription.rxNumber"></dt>
                        <dd [attr.id]="'claims-detail-prescription-rx-number-value-' + claimsModel.clmId"
                          [innerHTML]="claimsModel.prescriptionNbr"></dd>
                      </div>
                      <div class="table-row-rx-sm"
                        [attr.id]="'claims-detail-prescription-days-supply-' + claimsModel.clmId">
                        <dt [innerHTML]="content.detail.prescription.daysSupply"></dt>
                        <dd [attr.id]="'claims-detail-prescription-days-supply-value-' + claimsModel.clmId"
                          [innerHTML]="claimsModel.supply"></dd>
                      </div>
                    </div>
                    <div class="table-row-rx">
                      <div class="table-row-rx-sm"
                        [attr.id]="'claims-detail-prescription-prescribed-by-' + claimsModel.clmId"
                        *ngIf="claimsModel.prescribedBy !== content.notAvailable">
                        <dt class="ng-star-inserted" [innerHTML]="content.detail.prescription.prescribedBy"></dt>
                        <dd [attr.id]="'claims-detail-prescription-prescribed-by-value-' + claimsModel.clmId"
                          class="ng-star-inserted" [innerHTML]="claimsModel.prescribedBy"></dd>
                      </div>
                      <div class="table-row-rx-sm" [attr.id]="'claims-detail-prescription-address-' + claimsModel.clmId"
                        *ngIf="claimsModel.pharmacyAddress !== content.notAvailable"
                        [attr.aria-label]="content.detail.prescription.address + ' ' + claimsModel.pharmacyAriaAddress">
                        <dt class="ng-star-inserted" [innerHTML]="content.detail.prescription.address">Address</dt>
                        <dd [attr.id]="'claims-detail-prescription-address-value-' + claimsModel.clmId"
                          class="ng-star-inserted" [innerHTML]="claimsModel.pharmacyAddress"></dd>
                      </div>
                    </div>
                  </dl>
                </div>
                <div class="pharmacy-btn-container callout-links">
                  <a class="ant-link-text" [attr.id]="'claims-detail-manage-prescriptions-link-' + claimsModel.clmId"
                    [attr.href]="content.detail.pharmacyPagelink"
                    [attr.title]="content.detail.prescription.ariaPharmacyPageLink + ' ' + claimsModel.clmId">
                    <span class="motif-icon motif-chevron-right-round"></span>
                    <span [innerHTML]="content.detail.prescription.managePrescriptionsLinkLb"></span>
                  </a>
                </div>
              </div>
              <div class="claims-detail-card-sm rx-sm-card ng-star-inserted">
                <!-- Claim Status Section start-->
                <ng-container
                  *ngTemplateOutlet="statusTemplateRef; context: {claimsModel: claimsModel, content: content}">
                </ng-container>
                <!-- Claim Status Section end-->
              </div>
            </div>

            <!-- Pharmacy Show Me The Math start-->
            <div class="claims-detail-content claims-detail-timeline rx-claims-container">
              <h4 class="section-header-three" [innerHTML]="content.detail.showMeTheMath"
                [attr.aria-label]="content.detail.ariaShowMeMath + ' ' + claimsModel.clmId"></h4>
              <div class="claims-rx-flex-container">
                <dl>
                  <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-billed-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.pharBilledLabel"></dt>
                    <dd [innerHTML]="claimsModel.billedAmountCurrency"></dd>
                  </div>
                  <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-covered-amount-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.amtCovered"></dt>
                    <dd [innerHTML]="claimsModel.coveredAmountCurrency"></dd>
                  </div>
                  <div class="table-row-rx"
                    [attr.id]="'claims-detail-pharmacy-not-covered-amount-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.amtNotCovered"></dt>
                    <dd [innerHTML]="claimsModel.notCoveredAmountCurrency"></dd>
                  </div>
                  <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-plan-paid-amount-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.amtPlanPaid"></dt>
                    <dd [innerHTML]="claimsModel.planPaidAmountCurrency"></dd>
                  </div>
                  <div class="table-row-rx"
                    [attr.id]="'claims-detail-pharmacy-your-responsibility-amount-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.yourResponsibility"></dt>
                    <dd [innerHTML]="claimsModel.yourResponsibilityAmountCurrency"></dd>
                  </div>
                  <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-ductible-amount-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.appliedTowardDed"></dt>
                    <dd [innerHTML]="claimsModel.ductibleAmountCurrency"></dd>
                  </div>
                  <div class="table-row-rx"
                    [attr.id]="'claims-detail-pharmacy-coinsurance-amount-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.amtCoinsurance"></dt>
                    <dd [innerHTML]="claimsModel.coinsuranceAmountCurrency"></dd>
                  </div>
                  <div class="table-row-rx" [attr.id]="'claims-detail-pharmacy-copay-amount-' + claimsModel.clmId">
                    <dt [innerHTML]="content.detail.amtCopay"></dt>
                    <dd [innerHTML]="claimsModel.copayAmountCurrency"></dd>
                  </div>
                </dl>
              </div>
            </div>
            <!-- Pharmacy Show Me The Math end-->
          </div>
          <!-- Pharmacy Details end -->
        </div>
        <!-- Display loader while eob is being fetched-->
        <div class="claims-detail-button-container">
          <ng-container *ngIf="isEobInProgress">
            <div class="ant-ui-widget-overlay">
            </div>
            <div class="ant-ajax-load ajax-request-loader">
              <uxd-spinner></uxd-spinner>
              <p [innerHTML]="content.eob.eobLoadingMessage"></p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div [attr.id]="'claims-line-detail-print-panel' + claimsModel.clmId" class="ant-claims-sliding-panel animated"
  [style.display]="claimsModel.showClaimsPrintDetailPanel? 'block': 'none'">
  <data-sydmed-claim-print-cmp [content]="content" [claimDetails]="claimsModel"
    [printEvent]="printSubject.asObservable()"></data-sydmed-claim-print-cmp>
</div>

<ng-template #statusTemplateRef let-claimsModel="claimsModel" let-content="content">
  <div class="claims-detail-status-content">
    <h4 class="section-header-three">
      <span class="ant-font-bold" [innerHTML]="content.detail.claimDetailHeading"></span>
    </h4>
    <p>
      <span *ngIf="claimsModel.statusName === 'Approved'" [innerHTML]="content.detail.approvedRemarks"></span>
      <span *ngIf="claimsModel.statusName === 'Pending'" [innerHTML]="content.detail.pendingRemarks"></span>
      <span *ngIf="claimsModel.statusName === 'Denied'" [innerHTML]="content.detail.deniedRemarks"></span>
    </p>
  </div>
</ng-template>