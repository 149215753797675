import { Component, ElementRef,  OnInit, ViewChild } from '@angular/core';
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { PaymentPortalService } from "./../services/payment-portal.service";
import { PaymentHelperService } from "./../services/payment-helper.service";
import { AlertService, UniversalAlertType } from "@anthem/uxd/alert";
import { PaymentHistory } from './../interfaces/PaymentHistory';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  labels: any;
  headerJson: any;
  errorMsgs: any;
  isPaymentsLoading: boolean = false;
  isPaymentsError: boolean = false;
  displaySize: number = 10;
  currentListIndex: number = this.displaySize;
  paymentList: PaymentHistory[];
  displayList: PaymentHistory[];
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  @ViewChild('paymentRecords') paymentRecords: ElementRef;
  constructor(
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private _service: PaymentPortalService,
    private _helper: PaymentHelperService,
    private _alert: AlertService
  ) {
    this.getLabels();
  }

  getLabels() {
    this.jsonSvc.getJSON('payment-portal').subscribe(data => {
      this.labels = data.paymentPortal.labels;
      this.headerJson = data.paymentPortal.HeaderNav;
      this.errorMsgs = data.paymentPortal.errors;
      this.interCommunicationService.raiseEvent({
        title: this.headerJson.HeaderTitle,
        message: this.headerJson.HeaderMessage
      });
    });
  }

  ngOnInit(): void {
    this.fetchPaymentHistory();
  }

  fetchPaymentHistory() {
    this.isPaymentsLoading = true;
    this._service.getSummary().subscribe(res => {
      let pending: PaymentHistory[] = this._helper.mapPendingToHistory(res.body);
      if (pending.length)
        this.paymentList = pending;
      this.getHistory();
    }, err => {
      this.isPaymentsError = true;
    });
  }

  getHistory() {
    this._service.getHistory().subscribe(res => {
      if (this.paymentList && this.paymentList.length)
        this.paymentList = this.paymentList.concat(this._helper.mapPaymentHistoryFromApi(res.body));
      else
        this.paymentList = this._helper.mapPaymentHistoryFromApi(res.body);
      this.displayList = this.paymentList.slice(0, this.displaySize);
      this.isPaymentsLoading = false;
    }, err => {
      this.isPaymentsError = true;
    });
  }

  loadMore(): void {
    if (this.currentListIndex < this.paymentList.length) {
      const currentIndex = this.currentListIndex;
      this.displayList = [
        ...this.displayList,
        ...this.paymentList.slice(this.currentListIndex, (this.currentListIndex += this.displaySize))
      ];
      setTimeout(() => {
        this.paymentRecords.nativeElement.querySelectorAll('.uxd-expansion-button')[currentIndex].focus();
      }, 400);
    }
  }
}
