import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DefaultLabels, ErrorMsgs, ReasonForRequest } from '../../models/labels';
import { RadioButtonGroupValues } from '../../models/formValues';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reason-for-request',
  templateUrl: './reason-for-request.component.html',
  styleUrls: ['../../appeals-and-grievances.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReasonForRequestComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReasonForRequestComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReasonForRequestComponent implements ControlValueAccessor, OnDestroy {
  @Input('formSubmitted') formSubmitted: boolean;
  @Input() defaultLabels: DefaultLabels;
  @Input() errorMsgs: ErrorMsgs;
  @Input() labels: ReasonForRequest;
  rfrForm: FormGroup;
  selectedRadioButton: string;
  inputEnabler: boolean;
  subscriptions: Subscription[] = [];
  @Input('disableEffect') disableEffect: boolean = false;
  
  constructor(public fb: FormBuilder) { 
    this.rfrForm = this.fb.group({
      requestCategory: ['', Validators.required],
      requestCategoryComments: [{value: '', disabled: true }, [Validators.required, Validators.maxLength(500)]]
    });

    this.subscriptions.push(
      this.rfrForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    ); 

  }

  get requestCategoryControl() {
    return this.rfrForm.controls.requestCategory;
  }

  get requestCategoryCommentsControl() {
    return this.rfrForm.controls.requestCategoryComments;
  }

  get value(): RadioButtonGroupValues{
    return this.rfrForm.value;
  }

  set value(value: RadioButtonGroupValues) {
    this.rfrForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (!value) {
      this.rfrForm.reset();
    }
  }
  
  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  
  selectItem(id, inputEnabled){
    this.selectedRadioButton = id;
    this.inputEnabler = inputEnabled;
    if (inputEnabled) {
      this.rfrForm.patchValue({
        requestCategory: this.labels?.form?.othersRadioButtonvalue
      });
      this.rfrForm.get('requestCategoryComments').enable();
    } else {
      this.rfrForm.get('requestCategoryComments').disable();
      this.rfrForm.patchValue({
        requestCategoryComments: ''
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.rfrForm.valid ? null : { rfrValues: { valid: false } }
  }
}
