import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { LanguageToggleLabels } from './language-toggle-labels';
import { LanguageToggleService } from './language-toggle.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'public-language-toggle',
    templateUrl: './language-toggle.component.html',
    styleUrls: ['./language-toggle.component.scss']
})
export class LanguageToggleComponent implements OnInit, OnDestroy {
    public content: LanguageToggleLabels;
    private locale: string;
    private subscription: Subscription;
    public showToggle = false;
    private allowedBrands = ['anthem'];

    constructor(
        private contentService: JsonContentService,
        private languageService: LanguageToggleService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.subscription = new Subscription();
        this.showToggle = this.brandHasLanguageToggle() && this.isPublic();
        this.getContent();
        this.subscribeToRouteChanges();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleLanguage() {
       this.languageService.toggleLanguage();
    }

    private brandHasLanguageToggle(): boolean {
        return window.location.hostname.split('.').filter(element => this.allowedBrands.includes(element)).length > 0;
    }

    private getContent() {
        this.locale = this.languageService.getLocale();
        const contentSub = this.contentService.getJSON('language-toggle', this.locale).subscribe(content => this.content = content);
        this.subscription.add(contentSub);
    }

    private isPublic(): boolean {
        const href = window.location.href.toLowerCase();
        return href.indexOf('public') >= 0;
    }

    private subscribeToRouteChanges() {
        const routerSub = this.router.events
            .pipe(filter(event => this.filterRouteEvent(event)))
            .subscribe((event: NavigationEnd) => { 
                if (!event.url.includes('/secure')) {
                    this.showToggle = this.brandHasLanguageToggle();
                    if (this.showToggle) {
                        this.getContent();
                    }
                } else {
                    this.showToggle = false;
                }
            }
        );
        this.subscription.add(routerSub);
    }

    private filterRouteEvent(event: Event) {
        return (event instanceof NavigationEnd);
    }
}
