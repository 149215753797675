const CONSTANTS = {
    ANALYTICS: {
        'benefitsquestion' : "benefitsQuesLiveChat",
        'billingquestion' : "billingQuesLiveChat",
        'changemyphonenumber' : "changePhoneNumLiveChat",
        'findorchangeadoctor' : "findChangeDrLiveChat",
        'orderanidcard' : "orderIdCardLiveChat",
        'other' : "otherLiveChat",
        'prescriptioninformation' : "prescInfoLiveChat",
        'updatemyaddress' : "updateAddrLiveChat",
        'websupport' : "webSupportLiveChat"
    },
    CHATBOT_ANALYTICS: {
        'changepcp' : "changePcpNavChatbot",
        'chatbotminimize': "minimizeIconChatbot",
        'chatwithaliveagent' : "talkAgentNavChatbot",
        'idcard' : "idCardNavChatbot",
        'myhealthdashboard' : "myHealthDashNavChatbot",
        'sydneyicon': "sydneyIconChatbot"
    },
    CHATBOT_OPTIONS: [
        {
            'keywords': ["add a doctor", "add a pcp", "add a physician", "change my doctor", "change my physician", "change pcp", "change pmp", "delete my doctor", "delete my physician", "delete pcp", "delete pcp", "find my doctor", "find my physician", "find pcp", "find pmp", "get my doctor", "get my physician", "get pcp", "get pcp", "my pcp", "my pmp", "primary care doctor", "primary care physician", "remove my doctor", "remove my physician", "show my doctor", "show my physician", "update my doctor", "update my physician", "update pcp", "update pmp"],
            'name': 'Change PCP',
            'totalWeight': 0.0,
            'url': '/secure/change-provider'
        },
        {
            'keywords': ["card download", "card lookup", "card lost", "card request", "download card", "group card", "group id", "group number", "id card", "id download", "id lookup", "id lost", "id request", "idcard", "insurance card", "", "medical card", "member card", "member number", "proof of insurance"],
            'name': 'ID Card',
            'totalWeight': 0.0,
            'url': '/secure/id-card'
        },
        {
            'keywords': ["agent", "associate", "chat", "customer service", "human", "live agent", "live person", "real person", "representative", "talk with someone"],
            'name': 'Chat with a Live Agent',
            'totalWeight': 0.0,
            'url': ''
        },
        {
            'keywords': ["badges", "dashboard", "gift cards", "health dash", "health dashboard", "incentive", "points", "rewards"],
            'name': 'My Health Dashboard',
            'totalWeight': 0.0,
            'url': '/secure/my-health-dashboard'
        }
    ],
    CHAT_REFRESH_INTERVAL: 2500,
    CHAT_TIMER_ALERT: (3 * 60) + 30,
    CHAT_TIMER_END: 4 * 60,
    CHAT_WITH_COORDINATOR:"Chat with a coordinator",
    CONVERSATION_STATES: {
        'agent_joined' : 'AGENT_JOINED',
        'agent_left' : 'AGENT_LEFT',
        'close' : 'CLOSE',
        'open' : 'OPEN',
        'survey_close' : 'SURVEY_CLOSE',
        'survey_start': 'SURVEY_START'
    },
    FOCUS_DELAY: 300,
    SPANISH_CHATOPTION: {
        'keywords': ["agente", "asociado", "chat", "humano", "agente vivo", "persona viva", "spanish chat", "servicio al cliente", "representante", "hablar con alguien"],
        'name': 'Chatear con un agente en vivo',
        'totalWeight': 0.0,
        'url': ''
    },
    SPANISH_WEEKDAYS: {
        'friday':'viernes',
        'monday':'lunes',
        'saturday':'sábado',
        'sunday':'domingo',
        'thursday':'jueves',
        'tuesday':'martes',
        'wednesday':'miércoles'
    },
    TOPICS: {
        CHAT_WITH_A_COORIDNATOR: "Chat with a coordinator",
    }
};

export default CONSTANTS;