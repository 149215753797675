import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[formControlName][libNumbersOnly]'
})
export class NumbersOnlyDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  numbersOnlyFunc(event) {
    if (event.shiftKey) {
      if (event.which === 9) { return true; }// shift+tab
      event.stopPropagation();
      return false;
    }
    if (event.ctrlKey && ([65, 67, 86, 88].indexOf(event.which) > -1)) { // ctrl+a, ctrl+c , ctrl+v, ctrl+x
        return true;
    }
    // backspace, delete, enter, escape, left arrow, right arrow, tab
    if ([8, 46, 13, 27, 37, 39, 9].indexOf(event.which) > -1) {
      return true;
    } else if (event.which >= 48 && event.which <= 57) { // numbers
      return true;
    } else if (event.which >= 96 && event.which <= 105) { // numpad number
      return true;
    } else {
      event.stopPropagation();
      return false;
    }
  }
}
