import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor() { }

  protected searchTerm$ = new BehaviorSubject<string>('');
  searchTerm = this.searchTerm$.asObservable();

  searchOrders(searchTerm) {
    this.searchTerm$.next(searchTerm);
  }

  cancelOrders() {
    this.searchTerm$.next(null);
  }
}
