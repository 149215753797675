<div [formGroup]="form" #phoneForm="ngForm">
  <div>
    <div class="form-item">
      <label for={{phoneId}} [innerHtml]="phoneLabel"></label>
      <input
        id={{phoneId}}
        formControlName="phone"
        name="phone"
        type="text"
        placeholder="{{placeHolder}}"
        libPhoneFormat
        [preValue]="form.value.phone"
        maxlength="12"
        class="ant-text-input"
        [attr.aria-describedby]="phoneId + '-errors'" aria-required="true"
        [ngClass]="editable ? 'disable-effect' : ''"
        [tabindex]="phoneTabIndex ? '0' : '-1'"
      />
    </div>
    <div
      class="error-list" id="{{phoneId + '-errors'}}"
      *ngIf="(phone.dirty || phone.touched || formSubmitted) && phone.invalid"
    >
      <div *ngIf="phone.errors.required">
        <span class="error"><i class="motif-icon motif-delete"></i>
          <span *ngIf="errorMessageOverride; else phoneLabelError" class="motif-margin-left-5">
            <span [innerHtml]="errorMessageOverride"></span>
          </span>
          <ng-template #phoneLabelError>
            <span class="motif-margin-left-5">
                <span [innerHtml]="phoneLabel"></span>
                <span [innerHtml]="errorsObj.Required_err"></span>
            </span>
        </ng-template>
        </span>
      </div>
      <div *ngIf="phone.errors.invalidPhone && !phone.errors.required">
        <span class="error"><i class="motif-icon motif-delete"></i>
          <span class="motif-margin-left-5">
              <span [innerHtml]="errorsObj.PhoneFormat"></span>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
