import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RegistrationService } from '../registration.service';
import { CreateUsernamePassword } from '../create-username-password/create-username-password.model';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { RegistrationDataService } from '../app.data.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { MaskingService } from 'sydmed/libs/masking-service/src/lib/masking.service';
@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.css']
})
export class SecurityQuestionsComponent implements OnInit {

  constructor(private regSvc: RegistrationService,
    private createUsernamePassword: CreateUsernamePassword,
    private readonly formBuilder: FormBuilder,
    private dataSvc: RegistrationDataService,
    private router: Router,
    private errSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService,
    private maskingSvc: MaskingService
  ) { }

  securityQuestionsForm: FormGroup;
  showTooltipErrOnBlur: boolean = false;
  showTooltips: boolean = false;
  showTooltips2: boolean = false;
  showTooltips3: boolean = false;
  createUsernameModel: any = [];
  public aslError;
  public jsonLabels;
  public jsonErrors;

  dropDown1: any = {
    title: '',
    options: [
    ],
    selected: '',
    previouslySelected: ''
  };

  dropDown2: any = {
    title: '',
    options: [
    ],
    selected: '',
    previouslySelected: ''
  };

  dropDown3: any = {
    title: '',
    options: [
    ],
    selected: '',
    previouslySelected: ''
  };

  async ngOnInit() {
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Security_Questions;
      this.jsonErrors = data.RegistrationMFA.ErrorMsgs;
      this.dropDown1.title = this.jsonLabels.Field_Labels.Question_1;
      this.dropDown2.title = this.jsonLabels.Field_Labels.Question_2;
      this.dropDown3.title = this.jsonLabels.Field_Labels.Question_3;
    });

    this.securityQuestionsForm = this.formBuilder.group({
      question1: ['', [Validators.required]],
      question2: ['', [Validators.required]],
      question3: ['', [Validators.required]],
      answer1: ['', [Validators.required, Validators.minLength(4), FormValidators.NameValidator]],
      answer2: ['', [Validators.required, Validators.minLength(4), FormValidators.NameValidator]],
      answer3: ['', [Validators.required, Validators.minLength(4), FormValidators.NameValidator]],
    })

    await this.regSvc.getSecurityQuestions().then(
      (res: any) => {
        if (res.status === 200) {
          this.fillDropDowns(res.body.questions);
        }
      }, (error) => {
        if (error.status === 401) {
          this.aslError = this.errSvc.errorCodeMapper(error, this.jsonErrors);
        }
      })

    if (sessionStorage.getItem('reviewVisited') === 'true') {
      await this.dataSvc.setSecurityQuestForm(this.securityQuestionsForm);
      this.dropDown1.selected = this.securityQuestionsForm.controls['question1'].value;
      this.dropDown2.selected = this.securityQuestionsForm.controls['question2'].value;
      this.dropDown3.selected = this.securityQuestionsForm.controls['question3'].value;
    }

    this.createUsernameModel = this.createUsernamePassword.getCreateUsernamePasswordModel();
    await this.regSvc.decrypt({token: sessionStorage.getItem('userModel')}).then(res => {
      this.createUsernameModel = JSON.parse(res.body)
    })

    sessionStorage.setItem('securityQuestionsVisited', 'true');

    this.fixSelectAria();
  }
  fixSelectAria() {
    document.getElementById('question1_button').setAttribute('aria-labelledby', 'question1_legend question1_button-text');
    document.getElementById('question2_button').setAttribute('aria-labelledby', 'question2_legend question2_button-text');
    document.getElementById('question3_button').setAttribute('aria-labelledby', 'question3_legend question3_button-text');
  }

  fillDropDowns(securityQuestions) {
    for (let i in securityQuestions) {
      this.dropDown1.options.push(
        {
          label: securityQuestions[i].charAt(0).toUpperCase() + securityQuestions[i].slice(1),
          value: securityQuestions[i]
        }
      )
      this.dropDown2.options.push(
        {
          label: securityQuestions[i].charAt(0).toUpperCase() + securityQuestions[i].slice(1),
          value: securityQuestions[i]
        }
      )
      this.dropDown3.options.push(
        {
          label: securityQuestions[i].charAt(0).toUpperCase() + securityQuestions[i].slice(1),
          value: securityQuestions[i]
        }
      )
    }
    this.sortQuestions();
  }

  async sendToReview() {
    this.onChangeInput(0);
    if (this.securityQuestionsForm.valid) {
      await this.regSvc.tokenize({data: JSON.stringify(this.createUsernameModel.memberInput)}).then(res => {
        sessionStorage.setItem('reviewDetails', res.body.token);
      })
      this.router.navigate(["public/registration/review"]);
    } else {
      this.securityQuestionsForm.markAllAsTouched();
      this.dataSvc.focusInvalid();
    }
  }

  onChangeInput(questionNum) {
    this.securityQuestionsForm.controls['question1'].setValue(this.dropDown1.selected);
    this.securityQuestionsForm.controls['question2'].setValue(this.dropDown2.selected);
    this.securityQuestionsForm.controls['question3'].setValue(this.dropDown3.selected);
    this.removeQuestions(questionNum);
    this.securityQuestionsForm.controls['answer1'].setValue(this.securityQuestionsForm.controls['answer1'].value?.trim());
    this.securityQuestionsForm.controls['answer2'].setValue(this.securityQuestionsForm.controls['answer2'].value?.trim());
    this.securityQuestionsForm.controls['answer3'].setValue(this.securityQuestionsForm.controls['answer3'].value?.trim());
    FormValidators.uniqueChecker(this.securityQuestionsForm);
    this.createUsernamePassword.updateSecurityQuestions(this.createUsernameModel, this.securityQuestionsForm);
  }

  removeQuestions(questionNum) {
    if (questionNum === 1 && this.dropDown1.selected && this.dropDown1.selected !== this.dropDown1.previouslySelected) {

      for (let i in this.dropDown2.options) {
        if (this.dropDown2.options[i].value === this.dropDown1.selected) {
          this.dropDown2.options.splice(i, 1);
        }
      }
      for (let i in this.dropDown3.options) {
        if (this.dropDown3.options[i].value === this.dropDown1.selected) {
          this.dropDown3.options.splice(i, 1);
        }
      }

      if (this.dropDown1.previouslySelected) {
        this.dropDown2.options.push(
          {
            label: this.dropDown1.previouslySelected.charAt(0).toUpperCase() + this.dropDown1.previouslySelected.slice(1),
            value: this.dropDown1.previouslySelected
          }
        )
        this.dropDown3.options.push(
          {
            label: this.dropDown1.previouslySelected.charAt(0).toUpperCase() + this.dropDown1.previouslySelected.slice(1),
            value: this.dropDown1.previouslySelected
          }
        )
      }
      this.dropDown1.previouslySelected = this.dropDown1.selected;
    }

    if (questionNum === 2 && this.dropDown2.selected && this.dropDown2.selected !== this.dropDown2.previouslySelected) {

      for (let i in this.dropDown1.options) {
        if (this.dropDown1.options[i].value === this.dropDown2.selected) {
          this.dropDown1.options.splice(i, 1);
        }
      }
      for (let i in this.dropDown3.options) {
        if (this.dropDown3.options[i].value === this.dropDown2.selected) {
          this.dropDown3.options.splice(i, 1);
        }
      }

      if (this.dropDown2.previouslySelected) {
        this.dropDown1.options.push(
          {
            label: this.dropDown2.previouslySelected.charAt(0).toUpperCase() + this.dropDown2.previouslySelected.slice(1),
            value: this.dropDown2.previouslySelected
          }
        )
        this.dropDown3.options.push(
          {
            label: this.dropDown2.previouslySelected.charAt(0).toUpperCase() + this.dropDown2.previouslySelected.slice(1),
            value: this.dropDown2.previouslySelected
          }
        )
      }
      this.dropDown2.previouslySelected = this.dropDown2.selected;
    }

    if (questionNum === 3 && this.dropDown3.selected && this.dropDown3.selected !== this.dropDown3.previouslySelected) {

      for (let i in this.dropDown1.options) {
        if (this.dropDown1.options[i].value === this.dropDown3.selected) {
          this.dropDown1.options.splice(i, 1);
        }
      }
      for (let i in this.dropDown2.options) {
        if (this.dropDown2.options[i].value === this.dropDown3.selected) {
          this.dropDown2.options.splice(i, 1);
        }
      }

      if (this.dropDown3.previouslySelected) {
        this.dropDown1.options.push(
          {
            label: this.dropDown3.previouslySelected.charAt(0).toUpperCase() + this.dropDown3.previouslySelected.slice(1),
            value: this.dropDown3.previouslySelected
          }
        )
        this.dropDown2.options.push(
          {
            label: this.dropDown3.previouslySelected.charAt(0).toUpperCase() + this.dropDown3.previouslySelected.slice(1),
            value: this.dropDown3.previouslySelected
          }
        )
      }
      this.dropDown3.previouslySelected = this.dropDown3.selected;
    }
    this.sortQuestions();
  }

  sortQuestions() {
    this.dropDown1.options.sort((a, b) => a.value > b.value ? -1 : a.value < b.value ? 1 : 0);
    this.dropDown2.options.sort((a, b) => a.value > b.value ? -1 : a.value < b.value ? 1 : 0);
    this.dropDown3.options.sort((a, b) => a.value > b.value ? -1 : a.value < b.value ? 1 : 0);
  }

  back() {
    this.router.navigate(['/public/registration/create-username-password']);
  }

  public showHide(id: string, label?: string) {
    let element = document.getElementById(id);
    this.maskingSvc.showHide(element, label);
  }

  onFocus() {
    this.securityQuestionsForm.updateValueAndValidity();
    this.showTooltips = true;
    this.showTooltipErrOnBlur = false;
  }

  onBlur() {
    this.showTooltips = false;
    this.showTooltipErrOnBlur = true;
  }

  onFocus2() {
    this.securityQuestionsForm.updateValueAndValidity();
    this.showTooltips2 = true;
    this.showTooltipErrOnBlur = false;
  }

  onBlur2() {
    this.showTooltips2 = false;
    this.showTooltipErrOnBlur = true;
  }

  onFocus3() {
    this.securityQuestionsForm.updateValueAndValidity();
    this.showTooltips3 = true;
    this.showTooltipErrOnBlur = false;
  }

  onBlur3() {
    this.showTooltips3 = false;
    this.showTooltipErrOnBlur = true;
  }

}
