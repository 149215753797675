import { Component,OnInit, ViewChild } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { NavigationHelper2 } from 'sydmed/src/app/mbrportal-dependencies/navHlpr';
import moment from 'moment-timezone';
import { ahdPharmacyService as AHDPharmacyService } from 'sydmed/src/app/secure/ahd-pharmacy/services/ahd-pharmacy.service';
import {
  AlertService,
  UniversalAlertType,
  InlineAlertContainerComponent,
} from '@anthem/uxd/alert';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss'],
})
export class PrescriptionsComponent implements OnInit {
  public content: any;
  public dateFormat: string = 'YYYY-MM-DD';
  public eligiblePrescriptions = [];
  public memberName: string;
  public dateOfBirth: string;
  public description: string;
  @ViewChild('noPrescriptionsAlert')
  noPrescriptionsAlert: InlineAlertContainerComponent;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  public contentSubscription: Subscription;

  constructor(
    private alertService: AlertService,
    private ahdPharmacyService: AHDPharmacyService,
    private jsonContentService: JsonContentService,
    private navigationHelper: NavigationHelper2,
    private ahdContentHelper: AHDContentHelper
  ) {}

  ngOnInit(): void {
    //Loading AHD component contents for navigation process. 
    this.contentSubscription = forkJoin([
      this.jsonContentService.getJSON('ahd-prescription-home'),
      this.jsonContentService.getJSON('ahd-my-orders-page'),
      this.jsonContentService.getJSON('new-dashboard'),
    ]).subscribe(
      ([prescriptionHomeContent, myOrdersContent, newDashboardContent]) => {
        this.content = newDashboardContent.dashboard.prescriptionData;
        this.description = this.content.prescriptionDescription;
        this.ahdContentHelper.registerContent(
          'PrescriptionHomeComponentV2',
          prescriptionHomeContent.PrescriptionHomeComponentV2
        );
        this.ahdContentHelper.registerContent(
          'PrescriberSearchComponent',
          myOrdersContent.PrescriberSearchComponent
        );
      }
    );
    //Loading prescriptions
    this.getAHDPrescriptions();
  }

  navigateToViewprescriptions() {
    this.navigationHelper.navigateTo(this.content.viewPrescriptionsPageUrl);
  }

  getAHDPrescriptions() {
    //creating fromDate and toDate
    const currentDate = moment().format(this.dateFormat);
    const fromDate = moment()
      .subtract(2, 'years')
      .add(1, 'days')
      .format(this.dateFormat);
    //Integrating with node service.
    this.ahdPharmacyService.getUserPrescriptionsV2(fromDate, currentDate).then(
      (response: any) => {
        //filtering pres based on refillEligible or renewEligible true
        this.filterEligiblePrescriptions(response?.data?.memberPrescriptions);
        this.getRecentFillPrescriptions();
        this.getUserNameAndDOB(response?.data?.memberPrescriptions[0]);
      },
      (error) => {
        this.handleErrorResponse();
      }
    );
  }

  //Filtering prescriptions
  filterEligiblePrescriptions(memberPrescriptions) {
    memberPrescriptions.forEach((memberPrescription) => {
      memberPrescription.prescriptions.forEach((prescription) => {
        this.eligiblePrescriptions.push(
          prescription.prescriptionDetails.filter((presData) => {
            return presData.refillEligible || presData.renewEligible;
          })
        );
      });
    });
  }

  //Filtering prescriptions based on lastrefillDate or rxIssueDate
  getRecentFillPrescriptions() {
    this.eligiblePrescriptions = this.eligiblePrescriptions.flat();
    if (this.eligiblePrescriptions.length) {
      this.eligiblePrescriptions = this.eligiblePrescriptions.sort((prescriptionsOne, prescriptionsTwo) => {
        prescriptionsOne.lastFillDate = prescriptionsOne.lastFillDate ? prescriptionsOne.lastFillDate : prescriptionsOne.rxIssueDate;
        prescriptionsTwo.lastFillDate = prescriptionsTwo.lastFillDate ? prescriptionsTwo.lastFillDate : prescriptionsTwo.rxIssueDate;
        return moment(prescriptionsTwo.lastFillDate).diff(moment(prescriptionsOne.lastFillDate));
      });
      //return first 3 values from eligiblePrescriptions
      this.eligiblePrescriptions = this.eligiblePrescriptions.slice(0, 3);
    } else {
      this.handleInfoAlert();
    }
  }

  //Getting the Patient Name and DOB
  getUserNameAndDOB(memberPrescription) {
    if (this.eligiblePrescriptions.length) {
      this.memberName = memberPrescription.patientName;
      this.dateOfBirth = moment(memberPrescription.dateOfBirth).format(
        'MM/DD/YYYY'
      );
    }
  }

  handleErrorResponse() {
    this.description = this.content.prescriptionDescription;
    this.showAlertInfo(this.content.serviceErrorMessage, 'error');
  }

  handleInfoAlert() {
    this.description = this.content.noPrescriptionsDescription;
    this.showAlertInfo(this.content.zeroPrescriptionInfo, 'info');
  
  }

  showAlertInfo(alertMessage: string, alertType: string) {
    if (alertType == 'error') {
      this.alertService.error(alertMessage, this.content.alertObjectProperties);
      
    } else {
      this.alertService.info(alertMessage, this.content.alertObjectProperties);
    }  
  }

  ngOnDestroy(){
    if(this.contentSubscription){
    this.contentSubscription.unsubscribe();
    }
  }

}