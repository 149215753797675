import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule2 } from "@anthem/mbrportal/common";
import { SharedModule } from "@anthem/mbrportal/shared";
import { SsoModule } from "@anthem/mbrportal/sso";
import { AlertModule } from "@anthem/uxd/alert";
import { UxDeprecatedModule } from "@anthem/uxd/deprecated";
import { DropdownModule } from "@anthem/uxd/dropdowns";
import { IndicatorModule } from "@anthem/uxd/indicators";
import { ModalModule } from "@anthem/uxd/modal";
import { TooltipModule } from "@anthem/uxd/tooltip";
import { SecureSsoLinkComponent } from './sso.component';
import { SsoService } from './sso.service';
import { SsoEventService } from './ssoEvent.service';
import { SecureSsoFormComponent } from './ssoForm.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AlertModule,
    UxDeprecatedModule,
    ModalModule,
    IndicatorModule,
    FormsModule,
    SsoModule,
    CommonModule2,
    TooltipModule,
    DropdownModule,
  ],
  declarations: [
    SecureSsoLinkComponent, SecureSsoFormComponent
  ],
  exports: [
    SecureSsoLinkComponent, SecureSsoFormComponent
  ],
})
export class SecureSsoModule {
  static entryComponents: any = [
    SecureSsoLinkComponent
  ];

  static forRoot(): ModuleWithProviders<SecureSsoModule> {
    return {
      ngModule: SecureSsoModule,
      providers: [
        SsoService, SsoEventService
      ],
    };
  }
}
