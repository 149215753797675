<div id="claims-container" class="claims-page">
	<div *ngIf="content; else loadingOrError">
		<div class="container">
			<div class="row">
				<div class="financial-year" *ngIf="hasFinancialSummary && !isMini">
					<!-- Financial header start -->
					<h2 class="section-header-two" id="financial-year-header" >
						<span [innerHTML]="content.financialHeader" [attr.aria-label]="content.financialHeader"></span>
					</h2>
					<!-- Financial header end -->
					<!--Finacial year pie chart start-->
					
					<div *ngIf="!claimSummaryLoader && !claimsSummaryError">
						<div class="cards-wrapper">
							<div class ="card large-card card-flat card-popover">
								<div class="total-container">
									<div class="donut-chart-container">
										<div class="left-container">{{countOfClaims}}</div>
										<div class="middle-container">
											<div class="ant-chart-radial" *ngIf="hasClaims">
												<uxd-pie-chart 
													[textValue]="totalAmtContent" 
													[piePercentages]="[paidByPlan, paidByYou, 0, 0, ]" 
													[pieSymbol]="'percent'"
													[pieLabels]="[paidByPlanLabel, yourResponsibilityLabel, 0, 0 ]">
	
													<ant-chart-content *ngIf="pieChartSubText" class="pie-chart-text">{{pieChartSubText}}</ant-chart-content>
												</uxd-pie-chart>
											</div>
											<div class="ant-chart-radial" *ngIf="!hasClaims">
												<uxd-pie-chart [textValue]="'$0.00'" [piePercentages]="[0]">
													<ant-chart-content *ngIf="pieChartSubText" class="pie-chart-text">{{pieChartSubText}}</ant-chart-content>
												</uxd-pie-chart>
											</div>
										</div> 
										<div class="right-container" >
											<div class="donut-chart-legend">
												<dl>
													<dt>
														<span class="key">
															<span class="key-color-one"></span>{{paidByPlanLabel}}
														</span>
													</dt>
													<dd>{{paidByPlan | currency: 'USD'}}</dd>
												</dl>
												<dl>
													<dt>
														<span class="key">
															<span class="key-color-two"></span>{{yourResponsibilityLabel}}
														</span>
													</dt>
													<dd>{{paidByYou | currency: 'USD'}}</dd>
												</dl>
											</div>
										</div>										
									</div> 
								</div>
							</div>						
						</div>
					</div>
					<div *ngIf="(!claimSummaryLoader && claimsSummaryError)">
						<div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
							<span id="claims-err" [innerHTML]="content.error.claimServerError"></span>
						  </div>
					</div>
					<!--Financial year pie chart ends-->
					<div class="row" *ngIf="claimSummaryLoader">
						<uxd-spinner></uxd-spinner>
						<div [innerHTML]="content.financialSummaryLoading"></div>
					</div>
				</div>
				<div class="col-xs-12 col-xxs-12">
				<!-- Claims header start -->
				<h2 class="section-header-two" id="claims-header" [ngClass]="isMini ? 'motif-margin-top-8' : ''">
					<span [innerHTML]="isMini? content.mini.header : content.header" [attr.aria-label]="isMini? content.mini.header : content.header"></span>
				</h2>
				<!-- Claims header end -->

				<!-- Claims filter message start-->
				<div *ngIf="!claimSummaryLoader && !claimsErrorMsg && numClaimsAvailable > 0" [attr.aria-label]="filterDisclaimerMessage" class="claims-history-content">
					<p [innerHTML]="filterDisclaimerMessage"></p>
				</div>
				<!-- Claims filter message end-->

				<!-- Claims search count message start-->
				<div *ngIf="!isMini && singleClaimMode" id="claimsDurationMessageDiv" class="claims-history-content">
					<p id="claims-filter-search-message" aria-live="assertive" aria-atomic="false" aria-relevant="additions"
					  [innerHTML]="content.summary.claimDurationPrefixMessage + ' ' + numClaimsAvailable + ' ' + (numClaimsAvailable === 1 ? content.summary.claim : content.summary.claims) + ' ' + content.summary.claimDurationSuffixMessage"></p>
				  </div>
				 <!-- Claims search count message end -->
				  
				<!-- Claim filter start -->
				<data-sydmed-claims-filter-cmp *ngIf="!isMini" [widgetState]="widgetFilterState" [filterPreviousState]="filterPreviousState"
				[allClaims]="allClaims" [claims]="exportClaimsList" [claimId]="claimId" [content]="content"
				[initClaimTypeOptions]="initialClaimTypes" [initProviderOptions]="initialProviders" [initStatusCodeOptions]="initialStatusCodes"
				(updateClaimFilter)="filterClaims($event)" (dateRangeUpdated)="onDateRangeUpdate($event)"
				(resetFilter)="resetFilter($event)" #filterCmp
				(searchClaim)="searchClaims($event)" ></data-sydmed-claims-filter-cmp>
				<!-- Claim filter end --> 

				<!-- Claims summary loader -->
				<div class="row" *ngIf="claimSummaryLoader">
					<uxd-spinner></uxd-spinner>
        			<div [innerHTML]="content.summaryLoadingMsg"></div>
				</div>

				</div>
			</div> 
		</div>
		
		<!-- Claims summary section start -->
		<data-sydmed-claims-summary-cmp *ngIf="!claimSummaryLoader && !claimsErrorMsg" #claimsSummaryCmp [allClaims]="allClaims" [isMini]="isMini" [claims]="claims" [content]="content"></data-sydmed-claims-summary-cmp>
		<!-- Claims summary section end -->
		
		<!-- Claims summary error start -->
		<div *ngIf="(!claimSummaryLoader && claimsErrorMsg)" [ngClass]="isMini? 'clms-alert-resp col-xxs-12 col-xs-12':'container'" data-uxd-alert-cmp-v2 [alert]="{alertType: claimsErrorType}">
			<span id="claims-err" [innerHTML]="claimsErrorMsg"></span>
			<a *ngIf="(isMini && claimsErrorType === 'information')" href="javascript:void()" (click)="viewMoreClaims()" class="claims-err-txt" [attr.data-analytics]="content.mini.linkAnalytics" [innerText]="content.mini.linkLabel"></a>
		</div>
		<!-- Claims summary error end -->

		<hr *ngIf="!isMini">

		<!-- Mini widget Claims summary view more claim button start -->
		<div [ngClass]="isMini ? 'claims-dashboard-view-more-btn-container' : ''" *ngIf="isMini">
			<button id="claims--mini-view-all-claims-btn"
			type="submit" class="btn btn-secondary" [innerHTML]="content.mini.buttonLabel"
			[attr.data-analytics]="content.mini.buttonAnalytics" (click)="viewMoreClaims()">
			</button>
		</div>
		<!-- Mini widget Claims summary view more claim button end -->

		<div *ngIf="!isMini" class="container claims-summary-resource-container vgr-margin-bottom-2">
			<h5 class="ant-heading-two section-header-two" [innerHTML]="content?.resourceHeader"></h5>
			<div class="ant-margin-top-20">
				<p class="ant-font-bold" [innerHTML]="content?.needHelp"></p>
				<p [innerHTML]="content?.needHelpResource"></p>
				<div href="javascript:void(0)" [innerHTML]="content?.needHelpLink" [attr.data-analytics]="content?.analytics.contactUsLink"></div>
			</div>
		</div>
		
	</div>
	<ng-template #loadingOrError>
	  <div class="container">
		<div class="row">
			<div *ngIf="genericError; else loading" class="col-xxs-12 col-xs-12" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negetive'}">
				<span id="claims-err" [innerHTML]="genericError"></span>
			</div>
			<ng-template #loading>
				<uxd-spinner class="col-xxs-12 col-xs-12"></uxd-spinner>
        		<div [innerHTML]="'Loading... please wait.'"></div>
			</ng-template>
		</div>
	  </div>
	</ng-template>
</div>