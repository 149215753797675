<div *ngIf="loading" class="row">
  <uxd-spinner class="updating-loader"></uxd-spinner>
  <div>{{loaders?.PageLoader}}</div>
</div>
<div *ngIf="!loading">
  <div class="container">
    <div class="full-width-alert-spacer error-alert-spacer" *ngIf="errorMessage" data-uxd-alert-cmp-v2
      [alert]="{alertType: 'negative'}">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</div>