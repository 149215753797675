import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  constructor(
    private router: Router,
    private jsonSvc: JsonContentService,
    private localeService: LocaleService
  ) { }

  public jsonLabels;
  private locale: string;

  ngOnInit(): void {
    this.jsonSvc.getJSON('forgot-username-password-mfa').subscribe(data => {
      this.jsonLabels = data.ForgotUsernamePasswordMFA.Labels.Confirmation;
    });
    this.locale = this.localeService.getLocaleOverride();
    sessionStorage.clear();
    if (this.locale) {
       this.localeService.setLocaleOverride(this.locale);
    }
  }

  redirectToLogin() {
    this.router.navigate(['/public/login']);
  }

}
