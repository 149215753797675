<div class="uxd-theme-motif container">
  <div *ngIf="loading" class="row">
    <uxd-spinner class="updating-loader"></uxd-spinner>
  </div>
  <div id="errorMessageAlerts" class="error width-100 margin-0">
    <inline-alert-container #memberRepRegisterAlert role="alert" [name]="'member-rep-register-alert'" [alertType]="alertPosition"></inline-alert-container>
  </div>
  <div *ngIf="!loading" class="row vertical-center">
    <ng-container *ngIf="registrationPage; else showLoginButton">
      <div class="memberRep-registration-container">
        <form [formGroup]="memberRepresentativeForm" (ngSubmit)="submitDesigneeRegistration()" id="memberRepForm" name="memberRepForm" #memberRepForm="ngForm">
          <div class="col-xxs-12" *ngIf="showSecurityQuestions">
            <app-member-rep-security-questions [jsonLabels]="labels" [jsonErrors]="errorMessages" (emitQuestions)="validateSecQuestions($event)"></app-member-rep-security-questions>
          </div>
          <div *ngIf="showRegistrationPage">
           <div class="col-xxs-12">
            <div class="row partial-row">
              <div class="col-xxs-12 margin-bottom-40">
                <h2>{{  labels?.Password_Info }}</h2>
              </div>
              <div class="col-xxs-12">
                <div class="margin-bottom-40">
                  <label for="userName" class="font-16">{{ jsonLabels?.Field_Labels.Username }}</label>
                  <p>{{ userName }}</p>
                  <div *ngIf="hideUserNameInput">
                  <input (change)="onChangeInput()" id="userName" class="ant-input-long ant-text-input" name="userName" type="text" formControlName="userName" aria-required="true" aria-describedby="userNameError" />
                  <div id="userNameError" class="errors" *ngIf="true" aria-live="assertive">
                    <ul
                      class="no-outline ant-bar-chart-group text-left margin-top-14 error"
                      *ngIf="
                        (memberRepresentativeForm.controls['userName']?.touched && memberRepresentativeForm.controls['userName']?.invalid) ||
                        (memberRepresentativeForm.controls['userName']?.touched && memberRepresentativeForm.errors?.fieldsmatch) ||
                        memberRepresentativeForm.controls['userName']?.touched
                      ">
                      <li *ngIf="memberRepresentativeForm.errors?.fieldsmatch">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonErrors.UserName_Password_err }}</span>
                        <span>{{ jsonLabels?.Field_Labels.Password }}</span>
                      </li>
                      <li *ngIf="memberRepresentativeForm.controls['userName']?.errors?.required">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonLabels?.Field_Labels.Username }}</span>
                        <span>{{ jsonErrors.Required_err }}</span>
                      </li>
                      <li *ngIf="memberRepresentativeForm.controls['userName']?.errors?.minlength || memberRepresentativeForm.controls['userName']?.errors?.maxlength">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonErrors.UserName_Len_err }}</span>
                      </li>
                      <li *ngIf="memberRepresentativeForm.controls['userName']?.errors?.unHasTwoLtrPre">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonErrors.UserName_Format_err }}</span>
                      </li>
                      <li *ngIf="memberRepresentativeForm.controls['userName']?.errors?.unHasDot">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonErrors.UserName_Dot_err }}</span>
                      </li>
                      <li *ngIf="memberRepresentativeForm.controls['userName']?.errors?.unHasDigitPrefix">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonErrors.UserName_Num_err }}</span>
                      </li>
                      <li *ngIf="memberRepresentativeForm.controls['userName']?.errors?.hasValidChars">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonErrors.UserName_Invalid_err }}</span>
                      </li>
                      <li *ngIf="memberRepresentativeForm.controls['userName']?.errors?.noSpace">
                        <span class="fas fa-times"></span>
                        <span>{{ jsonErrors.UserName_Space_err }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
               </div>
              </div>
            </div>
          </div>

          <div class="form-item margin-bottom-0">
            <lib-passwords-form
              (change)="onChangeInput()"
              formControlName="passwords"
              [pwdLabel]="jsonLabels?.Field_Labels.Password"
              [confirmPwdLabel]="jsonLabels?.Field_Labels.Confirm_Password"
              [errObj]="jsonErrors"
              [toolTipObj]="jsonLabels?.Helper_Text.Password.Content.PasswordTooltip"
              [formSubmitted]="memberRepForm.submitted"
              [userName]="userName"
              #passwordId>
            </lib-passwords-form>
          </div>

          <div class="col-xxs-12" *ngIf="!isCaregiver">
            <div class="form-item">
              <label id="lbl_txtDob" for="dateOfBirth">{{ labels?.Date_of_Birth }}</label>
              <uxd-ant-date-picker class="date-picker" required [dateFormat]="dateDisplayFormat"
              [min]="" [max]="max" formControlName="dateOfBirth" [opened]="opened" [startAt]='startAt'
              (opened)='openedStream($event)' (dateChange)='dateChanges($event)' (closed)='closedStream($event)'>
            </uxd-ant-date-picker>

              <ul id="dobError" class="ant-bar-chart-group text-left font-14 margin-top-14 error" *ngIf="memberRepresentativeForm.controls['dateOfBirth']?.invalid && memberRepresentativeForm.controls['dateOfBirth']?.touched" aria-live="polite">
                <li *ngIf="memberRepresentativeForm.controls['dateOfBirth']?.errors.required">
                  <span class="fas fa-times font-14 margin-right-8"></span>
                  <span>{{ labels?.Date_of_Birth }}</span>
                  <span>{{ jsonErrors.Required_err }}</span>
                </li>
                <li *ngIf="memberRepresentativeForm.controls['dateOfBirth']?.errors.invalidDate">
                  <span class="fas fa-times font-14 margin-right-8"></span>
                  <span>{{ jsonErrors.DOB_Format_err }}</span>
                </li>
                <li *ngIf="memberRepresentativeForm.controls['dateOfBirth']?.errors.dateMismatch">
                  <span class="fas fa-times font-14 margin-right-8"></span>
                  <span>{{ jsonErrors.DOB_Mismatch_err }}</span>
                </li>
                <li *ngIf="memberRepresentativeForm.controls['dateOfBirth']?.errors.futureDate && !memberRepresentativeForm.controls['dateOfBirth']?.errors.invalidDate">
                  <span class="fas fa-times font-14 margin-right-8"></span>
                  <span>{{ jsonErrors.DOB_Future_err }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="memberType !== MemberTypes.CAREGIVER" class="text-left col-xxs-12 margin-bottom-24 margin-top-35" id="termsCheckbox">
            <div class="row inline">
              <div data-uxd-checkbox-input-cmp [definition]="checkbox" formControlName="agreeDesignee" class="checkbox-margin" [optionalAriaLabel]="labels?.Desginee_Agree_Info"></div>
              {{ labels?.Desginee_Agree_Info }}
            </div>
            <ul id="termsOfUseError" class="ant-bar-chart-group font-14 text-center error" *ngIf="memberRepresentativeForm.controls['agreeDesignee']?.invalid && memberRepresentativeForm.controls['agreeDesignee']?.touched" role="alert">
              <li class="text-left" *ngIf="memberRepresentativeForm.controls['agreeDesignee']?.errors.required">
                <span class="fas fa-times font-14 margin-right-8"></span>
                <span>{{ errorMessages.Designee_agree_err }}</span>
              </li>
            </ul>
          </div>
          <div class="button-group text-right margin-bottom-70 col-xxs-12">
            <button type="button" class="btn btn-secondary" (click)="passwordFormBackEvent(memberRepresentativeForm)" [attr.data-analytics]="labels?.ChoosePasswordBackAnalytics">
              {{ labels?.Security_Questions.Back }}
            </button>
            <button type="submit" class="btn btn-primary" [attr.data-analytics]="labels?.ChoosePasswordNextAnalytics" [disabled]="disableNext">
              {{ labels?.Security_Questions.Next }}
            </button>
          </div>
         </div>
        </form>
      </div>
    </ng-container>
    <ng-template #showLoginButton>
      <div *ngIf ="showReviewPage; else finalRegisteredPage">
        <div class="memberRep-registration-container">
          <div class="col-xxs-12 margin-bottom-40">
            <h2>{{ labels?.ReviewAndSubmit?.Page_Heading }}</h2>
          </div>
          <div class="col-xxs-12 margin-bottom-16">
              <p class="reviewPage"> {{ labels?.ReviewAndSubmit?.Username}}</p>
              <p> {{ userName | titlecase }}</p>
          </div>
          <div *ngIf="memberType === MemberTypes.CAREGIVER" class="col-xxs-12 margin-bottom-16">
            <p class="reviewPage"> {{ labels?.ReviewAndSubmit?.Account_Recovery_Number}}</p>
            <p> {{ reviewQuestionsSelected.accountRecoveryNumber}}</p>
          </div>
          <div class="col-xxs-12 margin-bottom-16">
            <p class="reviewPage"> {{ labels?.ReviewAndSubmit?.SecurityQuestion_1}}</p>
            <p> {{ reviewQuestionsSelected.securityQuestion1 }}</p>
          </div>
          <div class="col-xxs-12 margin-bottom-16">
            <p class="reviewPage"> {{ labels?.ReviewAndSubmit?.SecurityQuestion_2}}</p>
            <p> {{ reviewQuestionsSelected.securityQuestion2 }}</p>
          </div>
          <div class="col-xxs-12 margin-bottom-16">
            <p class="reviewPage"> {{ labels?.ReviewAndSubmit?.SecurityQuestion_3}}</p>
            <p> {{ reviewQuestionsSelected.securityQuestion3 }}</p>
          </div>
          <div class="button-group text-right margin-bottom-70 col-xxs-12">
            <button type="button" class="btn btn-secondary" (click)="backToPasswordSetPage(memberRepresentativeForm)" [attr.data-analytics]="labels?.ReviewAndSubmit.Analytics?.ReviewBack">
              {{ labels?.Security_Questions.Back }}
            </button>
            <button type="submit" class="btn btn-primary" (click)="submitRegistration(memberRepresentativeForm)" [attr.data-analytics]="labels?.ReviewAndSubmit.Analytics?.ReviewSubmit">
              {{ labels?.Security_Questions.Submit }}
            </button>
          </div>
        </div>
      </div>
       <ng-template #finalRegisteredPage>
        <div class="memberRep-registration-container">
          <div class="margin-bottom-40">
            <h2> {{ confirmationMessage }} </h2>
            <p *ngIf="isCaregiver" class="font-14 font-semi-bold margin-top-30">{{ labels?.Caregiver_Registration_Update?.Registration_Confirmation_Details?.Notes }}</p>
          </div>
          <div class="button-group text-right col-xxs-12">
            <button type="button" class="btn btn-primary margin-right-70" [attr.data-analytics]="labels?.AnalyticsLoginBtn" (click)="loginPage()">
              <div>{{ labels?.Login }}</div>
            </button>
          </div>
        </div>
       </ng-template>
    </ng-template>
    
  </div>
</div>
