import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';


@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private url: string;

    constructor(private _httpClient: HttpClientService) {
        this._httpClient = _httpClient;
    }


    authenticateUser(username, password) {
 
        return this._httpClient.postObservable(UrlProperties.UrlProperties.endPoints.login.authenticateUser,
            {
                username: username,
                password: password
            });
    }
}



