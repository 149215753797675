<div class="welcome-container" id="welcome-container">
    <h1 tabindex="-1" class="no-outline welcome-heading motif-margin-bottom-5" id="greeting-widget-welcome-message"
      [attr.aria-label]="content?.WelcomeBack_lbl + ' ' + memberData?.memberName">
      <span class = "ant-heading-four welcome-back">{{content?.WelcomeBack_lbl}}</span>
      <span class="ant-heading-two">{{memberData?.memberName | titlecase}}</span>
    </h1>
    <div class="member-information">
      <span class="no-outline welcome-back" *ngIf="showMemberId">
        <span>{{content?.MemberID_lbl}}</span>&nbsp;
        <span [attr.aria-label]="content?.MemberID_lbl + ' ' + memberData?.memberId">{{memberData?.memberId}}</span>
      </span>
      <span *ngIf="renewalData" class="no-outline welcome-back">
        <span>{{content?.renewalDate_lbl}}</span>&nbsp;
        <span [attr.aria-label]="content?.renewalDate_lbl">{{renewalData | date:'MM/dd/yyyy'}}</span>
      </span>
    </div>
  </div>
  