<div class="container margin-bottom-48 margin-top-24">
    <div class="full-width-alert-spacer" *ngIf="serviceError">
        <inline-alert-container #errorAlert role="alert" [name]="'inline-alert'"></inline-alert-container>
    </div>
    <div class="cards-wrapper margin-bottom-48 margin-top-48">
        <div class="card large-card card-flat card-popover">
            <div class="text-center margin-bottom-48 margin-top-48 padding-left-12 padding-right-12">
                <div class="margin-bottom-24">
                    <span id="assail" class="font-18">{{ labels?.patientPay_Heading }}</span>
                </div>
                <div>
                    <p class="font-22 font-bold margin-bottom-24">{{ obligations?.amount }}</p>
                    <div *ngIf="(obligations?.amount !== '$0.00' && obligations?.amount !== '$0' && (obligations?.startDate || obligations?.endDate))">
                        <div>
                            <span class="font-18">{{ currentMonthYear | date:'MMMM y' }}</span>
                        </div>
                    </div>
                    <div class="margin-top-4 font-12 text-error" *ngIf ="serviceError">
                        <span class="fas fa-times vertical-align-middle font-14 margin-right-2"></span>
                        <span class="error">{{ errorMsgs?.errorPatientPay }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="margin-bottom-24">
        <p class="margin-bottom-24 font-16">{{ labels?.patientPay_info }}</p>
        <ol class="padding-left-32 patientPay_details">
            <li class="font-16 margin-bottom-8 padding-left-12" *ngFor="let info of labels?.patientPay_details">
                {{ info.key }}
            </li>
        </ol>
        <p class="font-16 margin-top-14">{{ labels?.patientPay_msg }}</p>
    </div>
</div>