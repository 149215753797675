import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { LoginResponse } from 'gbd-models';
import { SessionStorage } from 'sydmed/libs/storage-service/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class BenefitsService {

    constructor(private viewPortScroller: ViewportScroller) { }

    getEffectiveDate() { 
        const memberInfo = this.getMemberInfo();
        return memberInfo.effectiveDate;
    }

    getMemberInfo() {
        const sessionData = sessionStorage.getItem(SessionStorage.MEMBER_CONTEXT);
        return JSON.parse(sessionData);
    }

    getActiveStatus() {
        const memberInfo = this.getMemberInfo();
        return memberInfo.activeStatus;    
    }

    getMemberFullName() {
        const memberInfo = this.getMemberInfo();
        const { memberName } = memberInfo;
        const { firstName, lastName } = memberName;
        return `${firstName} ${lastName}`;
    }

    getMemberId() {
        const memberInfo = this.getMemberInfo();
        return memberInfo.hcid;
    }

    scrollToComponent(id: string, offset: number): void {
        const elementId = document.getElementById(id);
        if (elementId) {
            const rect = elementId.getBoundingClientRect();
            const scrollToPosition = rect.top + window.scrollY - offset;
            this.viewPortScroller.scrollToPosition([0, scrollToPosition]);
            window.scrollTo({
                top: scrollToPosition,
                behavior: "smooth",
            });
        }
    }

}
