import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class SecurityQuestionsModel {

    constructor(

    ) { }

    private model: any = {
        memberData: {
            dateOfBirth: "",
            hcid: "",
            lastName: "",
            zipCode: "",
            recaptchaResponse: ""
        },
        questions: {},
        answer: "",
        saveCookie: false
    };
    private inputData: any;

    public getSecurityQuestionsModel = function () {
        return this.model;
    };


    public transformQuestionsData(questions) {
        var questionsData = [];
        var random = Math.floor(Math.random() * Math.floor(3))

        if (questions && questions.length > 0) {
            for (var x = 0; x < questions.length && x < 3; x++) {
                questionsData.push({
                    "id": questions[x].id.toString() || '',
                    "question": questions[x].text || ''
                });
            }
        }
        //Show only two questions
        if (questionsData.length > 2) {
            questionsData.splice(random, 1);
        }
        return questionsData;
    }

    public transformQuestionsInputData(data) {
        var questionsData = { questionAnswers: [], saveCookie: data.saveCookie };
        if (data && data.length > 0) {
            for (var x = 0; x < data.length; x++) {
                questionsData.questionAnswers.push({
                    "questionId": data[x].id || '',
                    "answer": data[x].answer || ''
                });
            }
        }
        return questionsData;
    }

    public transformQuestionsInputDataFUP(data) {
        var questionsData = {questions: [], saveCookie: data.saveCookie };

        if (data && data.length > 0) {
            for (var x = 0; x < data.length; x++) {
                questionsData.questions.push({
                    "id": data[x].id || '',
                    "text": data[x].answer || ''
                });
            }
        }
        return questionsData;
    }

    public updateQuestionsModel(data, model) {
        for (let i = 0; i < model.length; i++) {
            model[i].answer = data[i];
        }
        model.saveCookie = data.rememberDevice === "yes";
        return model;
    }

}
