<!-- Claims Summary Table/Accordions -->
<div class="motif-table-wrapper" *ngIf="hasClaims" [ngClass]="isMini ? 'motif-table-wrapper-mini' : ''">
  <div class="claims-summary-table-header" [class.container-fluid]="!isMini">
    <div class="container">
      <!-- Desktop header -->
      <div class="col-xxs-12 col-xs-12 claims-table-header" aria-hidden="true">
        <div class="claims-table-header-item">
          <span class="claims-summary-table-label" id="col-item-1" [innerHTML]="content.summary.date"></span>
        </div>
        <div class="claims-table-header-item">
          <span class="claims-summary-table-label" id="col-item-2" [innerHTML]="content.summary.type"></span>
        </div>
        <div class="claims-table-header-item">
          <span class="claims-summary-table-label" id="col-item-3" [innerHTML]="content.summary.billed"></span>
        </div>
        <div class="claims-table-header-item">
          <span class="claims-summary-table-label" id="col-item-4" [innerHTML]="content.summary.status"></span>
        </div>
        <div class="claims-table-header-item text-right">
          <span class="claims-summary-table-label" id="col-item-5" [innerHTML]="content.summary.yourResponsibility"></span>
        </div>

      </div>
    </div>
  </div>

  <div class="claims-summary-accordion-wrapper">
    <div #accordion uxdAccordion [isAccordion]="true"
      class="accordion-wrapper uxd-expansion-table zebra-striping">
      <div role="table" [attr.aria-label]="isMini? content.mini.header : content.header" aria-describedby="claims-history-desc">
        <div id="claims-history-desc" class="sr-only" [attr.aria-hidden]="isMini">
          List of claims based on the applied filters. For additional information on a claim, select expand information button in the second column
        </div>
        <div role="rowgroup">
          <div role="row" class="sr-only">
            <span role="columnheader" aria-sort="none" [innerText]="content.summary.claimId"></span>
            <span role="columnheader" aria-sort="none" [attr.aria-hidden]="isMini" [innerText]="content.summary.additionalClaimDetails"></span>
            <span role="columnheader" aria-sort="none" [innerText]="content.summary.date"></span>
            <span role="columnheader" aria-sort="none" [innerText]="content.summary.type"></span>
            <span role="columnheader" aria-sort="none" [innerText]="content.summary.billed"></span>
            <span role="columnheader" aria-sort="none" [innerText]="content.summary.status"></span>
            <span role="columnheader" aria-sort="none" [innerText]="content.summary.yourResponsibility"></span>
          </div>
        </div>
        <div role="rowgroup">
          <div class="claims-separator" *ngIf="isMini"></div>
          <ng-container *ngFor="let claim of claims; let $index = index">
            <!-- Claims mini widget rows start-->
            <div class="clm-bkg-stripes-cont" *ngIf="isMini">
              <div class="accordion-style-two claims-summary-accordion-toggle claims-table-description" role="row">

                <div class="sr-only" role="rowheader">
                  <span [innerText]=" content.mini.ariaRowHeader + ' ' + claim.clmId"></span>
                </div>
  
                <!-- 'Service Date' column -->
                <div role="cell" class="claims-table-description-item service-on-col claims-dashboard-service-col">
                  <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.date"></span>
                  <span class="claims-table-cell-left new-dashboard-only" [innerHTML]="content.summary.colon"></span>
                  <span [attr.id]="'claims-mini-date-' + $index" class="claims-table-cell-right" [innerHTML]="claim.startDate" [attr.aria-label]="dateUtil.getDatePart(claim.startDate, 'longDate')">
                  </span>
                </div>
  
                 <!-- 'Type' column -->
                 <div role="cell" class="claims-table-description-item description-col">
                  <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.type"></span>
                  <span class="claims-table-cell-left new-dashboard-only" [innerHTML]="content.summary.colon"></span>
                  <span class="claims-table-cell-right" [attr.id]="'claims-type-id-' + $index">
                    <span [attr.id]="'claims-mini-type-' + $index" class="text-bold"  [innerHTML]="claim.claimType"></span> <br />
                    <span [attr.id]="'claim-mini-provider-name-' + $index"  class="claims-medical-description" [innerHTML]="claim.providerName"></span>
                  </span>
                </div>
  
                <!-- 'Billed' indicator column -->
                <div role="cell" class="claims-table-description-item">
                  <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.billed"></span>
                  <span class="claims-table-cell-left new-dashboard-only" [innerHTML]="content.summary.colon"></span>
                  <span [attr.id]="'claims-mini-billed-' + $index" class="claims-table-cell-right" [innerHTML]="claim.billedAmountCurrency">
                  </span>
                </div>
  
                <!-- 'Status' column -->
                <div role="cell" class="claims-table-description-item">
                  <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.status"></span>
                  <span class="claims-table-cell-left new-dashboard-only" [innerHTML]="content.summary.colon"></span>
                  <span [attr.id]="'claims-mini-status-' + $index" class="claims-table-cell-right"
                  [innerHTML]="claim.statusName" [attr.arrowkey-focus-selectors]="true"></span>
                </div>
  
                  <!-- 'Your responsibility' column -->
                  <div role="cell" id="claims-mini-responsibility" class="claims-table-description-item claims-mini-responsibility">
                    <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.yourResponsibility"></span>
                    <span class="claims-table-cell-left new-dashboard-only" [innerHTML]="content.summary.colon"></span>
                    <span [attr.id]="'claims-your-responsibility-' + $index" class="claims-table-cell-right"
                    [innerHTML]="(claim.classCode === 'M' && claim.statusName !== 'Pending' && claim.yourResponsibilityAmountCurrency === content.notAvailable)? content.summary.yourResponsibilityInstructions : claim.yourResponsibilityAmountCurrency"></span>
                  </div>
  
                  <div class="claims-separator" *ngIf="isMini"></div>
  
              </div>
            </div>
            
            <!-- Claims mini widget rows start-->

             <!-- Claims widget rows start-->
            <uxd-expansion-panel #panel [attr.id]="'claim-' + $index" [attr.data-claim-id]="claim.clmId" *ngIf="!isMini"
              class="accordion-style-two claims-summary-table-accordion"
              [expanded]="searchSingleClaim && claims.length === 1"
              (opened)="!searchSingleClaim? loadClaimsDetail($index, claim): ''">

              <uxd-expansion-header [tabindex]="-1">
                <div class="claims-summary-accordion-toggle claims-table-description container" role="row">

                  <!-- Toggle column -->
                  <div class="sr-only" role="rowheader">
                    <span [innerHTML]="claim.clmId"></span>
                  </div>

                  <uxd-expansion-toggle role="cell"
                      [aria-label]="(panel.expanded ? 'Collapse' : 'Expand') + ' information for claim with ID ' + claim.clmId"
                      class="claims-table-description-item toggle-col hidden-xxs hidden-xs hidden-sm" [ngClass]="isMini ? 'hidden-md hidden-lg' : ''">
                    <span class="motif-icon" [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'"
                      [attr.id]="'claims-button-icon-desktop-' + $index"
                      [attr.data-analytics]="panel.expanded ? content.analytics.collapseClaim : content.analytics.expandClaim">
                    </span>
                  </uxd-expansion-toggle>

                  <!-- 'Service Date' column -->
                  <div role="cell" class="claims-table-description-item service-on-col">
                    <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.date"></span>
                    <span [attr.id]="'claims-date-' + $index" class="claims-table-cell-right"
                      [innerHTML]="claim.startDate" [attr.aria-label]="dateUtil.getDatePart(claim.startDate, 'longDate')">
                    </span>
                  </div>

                  <!-- 'Type' column -->
                  <div role="cell" class="claims-table-description-item description-col">
                    <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.type"></span>
                    <span class="claims-table-cell-right" [attr.id]="'claims-type-id-' + $index" >
                      <span [attr.id]="'claims-type-' + $index" class="text-bold" [attr.arrowkey-focus-selectors]="true" [innerHTML]="claim.claimType"></span>
                      <br />
                      <span [attr.id]="'claim-provider-name-' + $index" class="claims-medical-description" [innerHTML]="claim.providerName"></span>
                    </span>
                  </div>

                  <!-- 'Billed' indicator column -->
                  <div role="cell" class="claims-table-description-item">
                    <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.billed"></span>
                    <span [attr.id]="'claims-billed-' + $index" class="claims-table-cell-right" [innerHTML]="claim.billedAmountCurrency">
                    </span>
                  </div>

                  <!-- 'Status' column -->
                  <div role="cell" class="claims-table-description-item">
                    <span class="claims-summary-table-label claims-table-cell-left hidden-md hidden-lg" [innerHTML]="content.summary.status"></span>
                    <span [attr.id]="'claims-status-' + $index" class="claims-table-cell-right" [innerHTML]="claim.statusName"></span>
                  </div>

                  <!-- 'Your responsibility' column -->
                  <div role="cell" class="claims-table-description-item responsibility-col">

                    <uxd-expansion-toggle role="cell"
                      [aria-label]="(panel.expanded ? 'Collapse' : 'Expand') + ' information for claim with id ' + claim.clmId"
                      class="claims-table-cell-left hidden-md hidden-lg" [ngClass]="isMini ? 'hidden-xxs hidden-xs hidden-sm' : ''">
                      <span class="motif-icon" [ngClass]="panel.expanded ? 'motif-angle-up' : 'motif-angle-down'"
                        [id]="'claims-button-icon-mobile-'+ $index"
                        [attr.data-analytics]="panel.expanded ? content.analytics.collapseClaim : content.analytics.expandClaim">
                      </span>
                    </uxd-expansion-toggle>

                    <span class="claims-table-cell-right">
                      <span class="text-bold hidden-md hidden-lg" [innerHTML]="content.summary.yourResponsibility + ' : '"></span>
                      <span class="hidden-md hidden-lg">&nbsp;</span>
                      <span [innerHTML]="(claim.classCode === 'M' && claim.statusName !== 'Pending' && claim.yourResponsibilityAmountCurrency === content.notAvailable)? content.summary.yourResponsibilityInstructions : claim.yourResponsibilityAmountCurrency"></span>
                    </span>
                  </div>
                </div>
              </uxd-expansion-header>
              <div class="claims-summary-panel-body" role="row">
                <div class="container claims-list" *ngIf="claimsDetailLoadingSpinner" id="single-claims-detail-spinner">
                  <uxd-spinner></uxd-spinner>
                  <div [innerHTML]="content.detail.detailLoadingMsg"></div>
                </div>

                <!-- Claim Detail Start-->
                <data-sydmed-claims-detail-cmp *ngIf="!claimsDetailLoadingSpinner && !claimsErrorMsg" [content]="content" [claimsModel]="claims[$index]"></data-sydmed-claims-detail-cmp>

                <!-- Claim Detail API Error-->
                <div *ngIf="(!claimsDetailLoadingSpinner && claimsErrorMsg)" class="container" data-uxd-alert-cmp-v2 [alert]="{alertType: claimsErrorType}">
                  <span id="claims-err" [innerHTML]="claimsErrorMsg"></span>
                </div>
                <!-- Claim Detail End-->

              </div>
            </uxd-expansion-panel>
             <!-- Claims mini widget rows start-->
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
