<div class="row">
    <div class="col-sm-12 motif-margin-bottom-22">
        <div class="cards-wrapper">
            <div class="cards-container container">
                <div class="card large-card card-flat card-popover">
                    <div class="row motif-margin-top-21">
                        <div class="col-sm-12 motif-margin-bottom-23" *ngIf="!loading; else showLoading">
                            <span class="delete-success">
                                <img src="./assets/images/success.svg" alt="success">
                            </span>
                            <span class="deleted-account" [innerHtml]="labels?.AccountDeleted"></span>
                            <span class="paragraph">
                                <span [innerHtml]="labels?.Paragrah1"></span>
                                <button class="btn btn-primary motif-margin-top-10" id="goToRegister" (click)="goToRegister()">
                                    {{labels?.GoToRegister}}
                                </button>
                            </span>
                        </div>
                        <ng-template #showLoading>
                            <uxd-spinner class="delete-account-confirm-spinner" *ngIf="loading"></uxd-spinner>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>