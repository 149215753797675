import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FocusManagementService {

  constructor() { }

  focusToHeader() {
    let pageHeading: HTMLElement = document.querySelector('h1[tabindex="-1"], h2[tabindex="-1"], h3[tabindex="-1"], h4[tabindex="-1"], h5[tabindex="-1"], h6[tabindex="-1"]');
    if (pageHeading) {
        pageHeading.focus();
    }
  }
}
