import { Injectable, OnDestroy } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { Locale, LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentHubService {

  constructor(
    private httpSvc: HttpClientService,
    private localeService: LocaleService
  ) {}
   headers = { 'meta-senderapp': 'gbdmbr' };

  public getNOAData() {
    const locale = this.localeService.getLocaleOverride();
    this.headers['x-madt-language'] = locale !== Locale.ES_US ? Locale.EN_US : locale;
    const url = UrlProperties.UrlProperties.endPoints.documentHub.noa;
    return this.httpSvc.getObservable(url, {}, this.headers);
  }
}

