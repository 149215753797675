// Angular Imports
import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';

// Sydmed SPA Imports
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';

// Message Center Imports
import { MessageCenterLabels } from './../models/labels.interface';
import { MessageCenterErrorMessages } from './../models/error-messages.interface';
import { MessageCenterDataService } from './../message-center-data.service';

// Motif Imports
import { SidePanel, Modal, ModalRef } from '@anthem/uxd/modal';
import { AlertService, UniversalAlertType, InlineAlertContainerComponent  } from '@anthem/uxd/alert';
import { MessageCategoryType, MessageCenterExceptions, UpdateMessage } from 'gbd-models';
import { MemberRepresentativeService } from 'sydmed/libs/member-representative-service/memberRepresentative-service';
import { HelperService } from '../../appeals-and-grievances/utils/helper.service';
import { FolderId, FolderType } from '../models/folderDetails';

@Component({
  selector: 'messages-view',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessagesComponent implements OnInit, OnDestroy {
  @Input() errorAlert: InlineAlertContainerComponent;

  constructor( 
    private dataSvc: MessageCenterDataService,
    private httpSvc: HttpClientService,
    private errCodeMap: ErrorCodeMapperService,
    private sidePanel: SidePanel,
    public alert: AlertService,
    private modal: Modal,
    private changeDetectionRef: ChangeDetectorRef,
    private memberRepresentativeService: MemberRepresentativeService,
    public helperService: HelperService
  ) { }

  @ViewChild('sidePanelContent', { static: false })
  sidePanelContent: TemplateRef<HTMLElement>;

  @ViewChild('modalTemplate', { static: false })
  modalTemplate: TemplateRef<any>;

  /** Default slide-in direction  */
  public slideDirection: any = 'right';

  /** A reference to the open side panel  */
  public sidePanelRef: ModalRef<any, any>;

  public loading: boolean;
  public noMessages: boolean;
  public labels: MessageCenterLabels;
  public emptyBox: string;
  private subscriptions: Subscription[];
  private messageSubscription: Subscription;
  public messages: Array<any>;
  public messageCount: number;
  public aslErr: boolean;
  public errorMsgs: MessageCenterErrorMessages;
  public folder: string;
  public sortBy: any;
  public loadIndex: number;
  private modalRef: ModalRef<any, any>;
  public isMessageDeleting: boolean;
  public selectedMessage: any;
  public showNoEmailContent : boolean = false;
  public userEmailExist : boolean = true;
  public isDesignee: boolean = false;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  public isTermed: boolean = false;

  ngOnInit(): void {
    this.loading = true;
    this.aslErr = false;
    this.isMessageDeleting = false;
    this.loadIndex = 10;
    this.labels = this.dataSvc.labels;
    this.errorMsgs = this.dataSvc.errorMsgs;
    this.subscriptions = [];
    this.isDesignee = this.memberRepresentativeService.isDesignee;
    this.isTermed =  this.dataSvc.checkForTermedRestrictions;
    this.subscriptions.push(this.dataSvc.getSortBy().subscribe(
      sortBy => {
        this.sortBy = sortBy;
      }
    ));

    this.subscriptions.push(this.dataSvc.getfolder().subscribe(
      folder => {
        if (this.messageSubscription !== undefined) {
          this.messageSubscription.unsubscribe();
        }
        this.getMessages(folder);
        this.folder = folder;
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.messageSubscription.unsubscribe();
  }
  public getMessageDetails (body, folderId) {
    let messageDetails: Array<any> = body.messages.filter((message) => {
      if (folderId === FolderId.CHAT_TRANSCRIPTS) {
        return message.subject === 'Chat Transcript';
      } else if (folderId === FolderId.INBOX) {
        return message.subject !== 'Chat Transcript';
      } else {
        return true;
      }
    });
    return messageDetails;
  }

  public getMessages(folderId) {
    this.noMessages = false;
    this.loading = true;
    this.aslErr = false;

    let folderType = FolderType.INBOX;

    switch (folderId) {
      case FolderId.INBOX:
        folderType = FolderType.INBOX;
        this.emptyBox = this.labels.MsgCntrInboxNoMsg_lbl;
        break;
      case FolderId.SENT:
        folderType = FolderType.SENT;
        this.emptyBox = this.labels.MsgCntrSentNoMsg_lbl;
        break;
      case FolderId.ARCHIVED:
        folderType = FolderType.SAVED;
        this.emptyBox = this.labels.MsgCntrArchivedNoMsg_lbl;
        break;
      case FolderId.CHAT_TRANSCRIPTS:
        folderType = FolderType.INBOX;
        this.emptyBox = this.labels.MessageCenterChatTranscripts;
        break;
      default:
        folderType = FolderType.INBOX;
        this.emptyBox = this.labels.MsgCntrInboxNoMsg_lbl;
    }

    const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.messages + '?folder=' + folderType;
    this.messageSubscription = this.httpSvc.getObservable(endpoint).subscribe(
      (res: any) => {
        this.dataSvc.setShowSortByDropdown(true);
        if (res.body.name === folderType && res.body.totalCount === 0) {
          this.noMessages = true;
        }

        if (!this.noMessages) {
          this.messages = this.getMessageDetails(res.body, folderId);
          this.messageCount = this.messages.length

        if (folderId === FolderId.INBOX ) {
          this.dataSvc.setUnreadCount(this.messages.filter((message) => {return !message.read && message.messageCategoryType !== MessageCategoryType.CHAT_TRANSCRIPT}).length);
      } else if (folderId === FolderId.CHAT_TRANSCRIPTS) {
          this.dataSvc.setTranscriptUnreadCount(this.messages.filter((message) => {return !message.read && message.messageCategoryType === MessageCategoryType.CHAT_TRANSCRIPT}).length);
      }
      }

        this.loading = false;
      },
      err => {
        this.loading = false;
        this.aslErr = true;
        if (err && err.error && err.error.code ) {
          this.showHideSortDropDown(err.error.code)
        }
 
        if (err && err.error && err.error.errorCode && err.error.errorCode.code ) {
          this.showHideSortDropDown(err.error.errorCode.code)
        }

        this.changeDetectionRef.detectChanges();

        if(!this.showNoEmailContent && this.userEmailExist) {
          this.callErrorAlert(this.errCodeMap.errorCodeMapper(err, this.errorMsgs));
        }
      }
    );
  }

  private showHideSortDropDown(err) {
    if(err === MessageCenterExceptions.EMAIL_NOT_AVAILABLE || err === MessageCenterExceptions.EMAIL_TEXT_NOT_AVAILABLE) {
      this.showNoEmailContent = true;
    }
    this.dataSvc.setShowSortByDropdown(false);
  }

  public moveMessage(folderType, message) {
    message.messageLoading = true;
    const messageUid = message.uid;
    const params = {
      folder: folderType,
      uids: [messageUid]
    };

    if (folderType === FolderType.DELETED) {
      this.isMessageDeleting = true;
    }

    const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.updateMessage;
    this.httpSvc.putObservable(endpoint, params).subscribe(
      res => {
        this.dataSvc.setfolder(this.folder);
        this.loading = false;
        this.aslErr = false;
        message.messageLoading = false;
        this.isMessageDeleting = false;
        if (folderType === FolderType.DELETED) {
          this.onModalClose();
        }
      },
      err => {
        this.loading = false;
        message.messageLoading = false;
        this.isMessageDeleting = false;
        if (folderType === FolderType.DELETED) {
          this.onModalClose();
        }
        this.changeDetectionRef.detectChanges();
        this.callErrorAlert(this.errCodeMap.errorCodeMapper(err, this.errorMsgs));
      }
    );
  }

  public selectMessage(message) {
    message.messageLoading = true;

    const endpoint = UrlProperties.UrlProperties.endPoints.secureMessaging.messages;
    this.httpSvc.getObservable(endpoint + '/' + message.uid).subscribe(
      res => {
        if (!message.read) {
          const updateMessage: UpdateMessage = {
            read: true,
            uids: [message.uid]
          };
          this.httpSvc.putObservable(endpoint + '/update', updateMessage).subscribe(
            response => {
              this.dataSvc.messageDetails = res.body;
              this.sidePanelRef = this.sidePanel.open(this.slideDirection, this.sidePanelContent);
              this.dataSvc.replyMessageSidePanel = this.sidePanelRef;
              message.messageLoading = false;
              this.dataSvc.setfolder(this.folder);
            },
            error => {
            }
          );
        } else {
          this.dataSvc.messageDetails = res.body;
          this.sidePanelRef = this.sidePanel.open(this.slideDirection, this.sidePanelContent);
          this.dataSvc.replyMessageSidePanel = this.sidePanelRef;
          message.messageLoading = false;
        }
      },
      err => {
        message.messageLoading = false;
        this.changeDetectionRef.detectChanges();
        this.callErrorAlert(this.errCodeMap.errorCodeMapper(err, this.errorMsgs));
      }
    );
  }

  /** Event handler for click of close button  */
  onClose() {
    this.helperService.clearFileAttachments();
    this.sidePanelRef.close();
  }

  showMore() {
    this.loadIndex = this.loadIndex + 10;
  }

  open() {
    this.modalRef = this.modal.open(this.modalTemplate);
  }

  handleClick(message) {
    this.selectedMessage = message;
    this.open();
  }

  public deleteMessage() {
    this.moveMessage(FolderType.DELETED, this.selectedMessage);
    this.selectedMessage = null;
  }

  onModalClose() {
    this.modalRef.close();
    this.modalRef.onDismiss.unsubscribe();
    this.modalRef.onClose.unsubscribe();
  }

  callErrorAlert(serverErr) {
    if (this.errorAlert) {
      this.errorAlert.dismissAlertId(0);
      this.alert.error(serverErr, {
        regionName: 'inline-alerts',
        politeness: 'assertive',
        isDissmisable: true
      });
    }
  }

}
