import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { oneTimePasscodeModel } from '../one-time-passcode.model';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { MaskingService } from '../../masking-service/src/lib/masking.service';

import { ErrorCodeMapperService } from './../../error-code-mapper/src/lib/error-code-mapper.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { RegistrationService } from 'sydmed/src/app/public/registration/registration.service';


@Component({
  selector: 'validate-one-time-passcode',
  templateUrl: './validate-one-time-passcode.component.html',
  styleUrls: ['./validate-one-time-passcode.component.css']
})
export class ValidateOneTimePasscodeComponent implements OnInit {

  @Output()
  linkClicked: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  backButtonClicked: EventEmitter<any> = new EventEmitter<any>()
  @Input()
  jsonErrors: any;
  @Input()
  jsonLabels: any;
  @Input()
  endpoint: string;
  @Input() 
  feature: string;

  ValidateOneTimePasscodeForm: FormGroup;

  public codeResent: boolean = false;
  public aslError: string;
  private otpValidationData: any;
  public incorrectSecurityCode: boolean = false;
  public securityCodeModel: any;
  isLogin: boolean;

  constructor(
    private readonly formBuilder: FormBuilder,
    private _oneTimePasscodeModel: oneTimePasscodeModel,
    private http: HttpClientService,
    private errCodeSvc: ErrorCodeMapperService,
    private maskingSvc: MaskingService,
    private regSvc: RegistrationService
  ) { }

  ngOnInit(): void {

    this.ValidateOneTimePasscodeForm = this.formBuilder.group({
      secCode: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      rememberDevice: ["", [Validators.required]]
    });
    this.securityCodeModel = sessionStorage.getItem("recoveryModel"); //*** TODO Handle Better
    this.securityCodeModel = JSON.parse(this.securityCodeModel);
  }

  public resendSecurityCode() {
   this.ValidateOneTimePasscodeForm.reset();
   const otpRequest = (this.feature === 'registration') ? this._oneTimePasscodeModel.transformToOTPRequest(this.securityCodeModel,"ARN") : this._oneTimePasscodeModel.transformToOTPRequest(this.securityCodeModel,'');
    let url = '';
    if (this.endpoint === UrlProperties.UrlProperties.endPoints.login.validateOTP) {
      url = UrlProperties.UrlProperties.endPoints.login.sendOtp;
    } else if (this.endpoint === UrlProperties.UrlProperties.endPoints.registration.validateOTP) {
      url = UrlProperties.UrlProperties.endPoints.registration.sendOtp;
    } else {
      url = UrlProperties.UrlProperties.endPoints.forgotUsernamePassword.sendOtp;
    }
    this.http.postObservable(url, otpRequest).subscribe(
      (response: any) => {
        this.codeResent = true;
      },
      error => {
        this.aslError = this.errCodeSvc.errorCodeMapper(error, this.jsonErrors);
      }
    );
  }

  public back() {
    this.backButtonClicked.emit();
  }

  public validateCode() {

    if (this.ValidateOneTimePasscodeForm.valid) {
      this.codeResent = false;
      this.otpValidationData = this.getOtpValidationData(this.ValidateOneTimePasscodeForm);

      if (this.endpoint === UrlProperties.UrlProperties.endPoints.login.validateOTP) {
        this.http.postObservable(this.endpoint, this.otpValidationData).subscribe(
          (response: any) => {
            if (response.status === 200) {
              sessionStorage.setItem('asl-token', response.body.token);
              this.linkClicked.emit(response);
            } else if (!this.isLogin) {
              this.aslError = this.jsonErrors['validateOtp.401.00'];
            }
          },
          error => {
            this.aslError = this.errCodeSvc.errorCodeMapperV2(error, this.jsonErrors);
          }
        )
      } else {
        this.http.postObservable(this.endpoint, this.otpValidationData).subscribe(
        async (response: any) => {
          if(response?.body?.username) {
            await this.regSvc.tokenize({data: response.body.username}).then(response => {
              sessionStorage.setItem('username', response.body.token);
            })
          }
          this.linkClicked.emit(response);
        },
        error => {
          this.aslError = this.errCodeSvc.errorCodeMapper(error, this.jsonErrors);
        }
        )
      }
    } else {
      this.ValidateOneTimePasscodeForm.markAllAsTouched();
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();
    }
  }

  private getOtpValidationData(formData) {
    return {
      otp: formData.value.secCode,
      saveCookie: formData.value.rememberDevice === 'yes'
    }
  }

  public onChangeInput() {
    if (this.incorrectSecurityCode) {
      this.incorrectSecurityCode = false;
    }
  }

  showHide(element: HTMLElement, label?: string) {
    this.maskingSvc.showHide(element, label);
  }
}