import { Component, OnInit, Input, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { FilterPanelComponent } from '@anthem/uxd/filter';
import { FilterState } from '../interfaces/power-account';
import { DateUtility } from "@anthem/mbrportal/utility/services/dateUtil";
import { AntDateRangeSelectorComponent } from '@anthem/uxd/ant-datepicker';
import { PowerAccountContent } from "../interfaces/power-account-content";
import moment from 'moment-timezone';

@Component({
  selector: 'app-power-account-date-filter',
  templateUrl: './power-account-date-filter.component.html',
  styleUrls: ['./power-account-date-filter.component.scss']
})
export class PowerAccountDateFilterComponent implements OnInit {

    public calendarDateRange: any;
    public calendarStartMonth: any;
      
    constructor(
      private dateUtil: DateUtility
    ) {     }
  
    @ViewChild(FilterPanelComponent) filterPanelAlpha: FilterPanelComponent;
    @ViewChild('daterangeCalendar') daterangeCalendar: AntDateRangeSelectorComponent;
    @Input('widgetFilterState') widgetFilterState : FilterState;
    @Output() newFilterState: EventEmitter<any> = new EventEmitter<any>();
    @Input("content") content: PowerAccountContent;

    selectedDateLeft: string | '' = '';
    selectedDateRight: string | '' = '';
    dateByMonthsFilter: number;
    dateDisplayFormat: string = 'MM/DD/YYYY';
    minDate: Date;
    maxDate: Date;
    validationErr: string = '';
    initialWidgetState: any;
    customDate: boolean = false;
    def = { mobileHeaderTitle: 'Custom Date Range' };
  
    ngAfterViewInit() {
    }
  
    reset(): void {
      this.dateByMonthsFilter = this.initialWidgetState.date;
      this.validationErr = '';
      this.calendarDateRange = [moment(this.initialWidgetState.customDateLeft).format('MM/DD/YYYY'), moment(this.initialWidgetState.customDateRight).format('MM/DD/YYYY')];
      this.calendarStartMonth = moment(this.initialWidgetState.customDateLeft);
      this.newFilterState.emit(this.initialWidgetState);  
    }
  
    filterByStatus(status: any): void {
      this.dateByMonthsFilter = status;
      this.customDate = false;
     }
  
    applyFilters(): void {
      this.filterPanelAlpha.hidePanel();
      this.setWidgetState();
    }
  
    cancel(): void {
      this.filterPanelAlpha.hidePanel();
      // this.dateByMonthsFilter = this.widgetFilterState.date;
      this.validationErr = '';
    }
  
    ngOnInit(): void {
      this.initialWidgetState = this.widgetFilterState;
      this.calendarDateRange = [moment(this.widgetFilterState.customDateLeft).format('MM/DD/YYYY'), moment(this.widgetFilterState.customDateRight).format('MM/DD/YYYY')];
      this.calendarStartMonth = moment(this.widgetFilterState.customDateLeft);
      this.setInitialMinMaxDates();
      this.dateByMonthsFilter = this.widgetFilterState.date;
     }

    setInitialMinMaxDates() {
      let minDate = new Date();
      minDate.setMonth(
        minDate.getMonth() - 24
      );
      // commenting for now
      // if(minDate.getFullYear() < 2021) {
      //   minDate = new Date('01-01-2021');
      // }
      this.minDate = minDate;
      this.maxDate = new Date();
    }

    setWidgetState() {
      if(this.customDate) {
        // this.widgetFilterState.date = null;
        this.selectedDateLeft = this.daterangeCalendar.calendarDateRange[0];
        this.selectedDateRight = this.daterangeCalendar.calendarDateRange[1];
      } else {
        this.setChooseRangeDates();
      }

      this.widgetFilterState = {
        date: this.dateByMonthsFilter,
        isCustomDate: this.customDate,
        customDateLeft: this.selectedDateLeft,
        customDateRight: this.selectedDateRight
      }
      this.newFilterState.emit(this.widgetFilterState);            
    }

    calendarOpen(event: any) {
    }

    dateSelectedChange(event: any) {
      this.customDate = true;
      if(this.daterangeCalendar.calendarDateRange.length > 0) {
        if(this.minDate > (new Date(this.daterangeCalendar.calendarDateRange[0]) ||  this.maxDate < (new Date(this.daterangeCalendar.calendarDateRange[1])))) {
          this.validationErr = this.content.ErrorMsgs.PwrAcctActFltr24_err;
        } else {
          this.validationErr = '';
        }
      }
    }

    setChooseRangeDates() {
      let left = new Date();
      left.setMonth(
        left.getMonth() - this.dateByMonthsFilter
      );
      // if(left.getFullYear() < 2021) {
      //   left = new Date('01-01-2021');
      // }
      let right = new Date();

      this.selectedDateLeft = this.dateUtil.getDatePart(left, "yyyy-MM-dd");
      this.selectedDateRight = this.dateUtil.getDatePart(right, "yyyy-MM-dd");
    }
  }


