// Angular Imports
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], propertyName: string, orderBy: string): any[] {
    if (propertyName) {
      if (orderBy === 'asc') {
        if(value) {
          value.sort((a: any, b: any) => {
            if (a[propertyName] > b[propertyName]) {
              return 1;
            }
            if (a[propertyName] < b[propertyName]) {
              return -1;
            }
            if (a[propertyName] === b[propertyName]) {
              return 0;
            }
          });
        }
        return value;
      } else if (orderBy === 'desc') {
        if(value) {
          value.sort((a: any, b: any) => {
            if (a[propertyName] > b[propertyName]) {
              return -1;
            }
            if (a[propertyName] < b[propertyName]) {
              return 1;
            }
            if (a[propertyName] === b[propertyName]) {
              return 0;
            }
          });
        }
        return value;
      }
    } else {
      return value;
    }
  }

}
