<h2 class="section-header-three margin-top-40 padding-bottom-10" [innerHtml]="descriptionLabels?.header"></h2>
<p class="font-14"
    [innerHtml]="!editable ? labels?.issueDescription.subHeading : labels?.issueDescription.preview?.subHeading"></p>

<form [formGroup]="descriptionForSpecificForm">
    <div class="input-group-lg rfr">
        <textarea rows="5" class="border-0 rfr-textarea description-textarea-height" 
        [ngClass]="formSubmitted && descriptionForSpecificForm.controls['description']?.invalid ? 'error-border' : ''"
        [placeholder]="descriptionLabels?.form?.placeHolder" id="description" name="description" [readonly]="editable"
        formControlName="description" [maxlength]="descriptionLabels?.characterLimit + 1" required
        aria-required="true"></textarea>
    </div>
    <span [innerHtml]="defaultLabels?.form?.characterLimitlabel"></span>
    <span class="motif-margin-left-2" [innerHtml]="descriptionLabels?.characterLimit"></span>
    <div *ngIf="(descriptionControl?.dirty || descriptionControl?.touched) || formSubmitted && descriptionControl?.invalid" class=" text-left margin-top-14">
        <span *ngIf="descriptionControl?.errors?.required" class="error">
            <i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
                <span [innerHtml]="descriptionLabels?.describeOfSpecificLabel"></span>
                <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
            </span>
        </span>
        <span *ngIf="descriptionControl.errors?.maxlength" class="error">
            <i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
                <span [innerHtml]="descriptionLabels?.describeOfSpecificLabel"></span>
                <span class="errorMessage" [innerHtml]="errorMsgs?.IssueDescriptionNumLengthErr"></span>
            </span>
        </span>
    </div>
</form>