import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable, throwError, from } from 'rxjs';
import { SortService } from 'sydmed/src/app/secure/pharmacy/sort/sort.service';
import { ErrorMessageService } from 'sydmed/src/app/secure/pharmacy/error-message/error-message.service';
import { PharmacyApiService } from 'sydmed/src/app/secure/pharmacy/services/pharmacy-api.service';
import { PrescriptionsSortBy } from 'sydmed/src/app/secure/pharmacy/enums/PrescriptionsSortBy';
import { map, catchError } from 'rxjs/operators';
import { MockDataService } from 'sydmed/src/app/secure/pharmacy/services/mock-data.service';
import { SearchService } from 'sydmed/src/app/secure/pharmacy/search/search.service';
import { CartService } from 'sydmed/src/app/secure/pharmacy/services/cart.service';
import { PrescriptionsResponse, NonActionablePrescription,  Prescription, AllPrescriptionsInfo, PharmacyPartialError} from 'gbd-models';


@Injectable( {
  providedIn: 'root'
} )
export class PrescriptionsService {
  protected prescriptions$: BehaviorSubject<PrescriptionsResponse> = new BehaviorSubject<PrescriptionsResponse>( null );
  public prescriptions: Observable<PrescriptionsResponse> = this.prescriptions$.asObservable();

  protected totalEligiblePrescriptions$: BehaviorSubject<number> = new BehaviorSubject<number>( 0 );
  public totalEligiblePrescriptions: Observable<number> = this.totalEligiblePrescriptions$.asObservable();

  public currentSortOption: PrescriptionsSortBy;
  protected loadingSpinner$: BehaviorSubject<boolean> = new BehaviorSubject( false );
  public loadingSpinner: Observable<boolean> = this.loadingSpinner$.asObservable();
  protected originalPrescriptions$: BehaviorSubject<PrescriptionsResponse> = new BehaviorSubject<PrescriptionsResponse>( null );
  public originalPrescriptions: Observable<PrescriptionsResponse> = this.originalPrescriptions$.asObservable();

  public searchTerm: string;
  constructor(
    protected api: PharmacyApiService,
    protected datePipe: DatePipe,
    protected mockApi: MockDataService,
    protected sortService: SortService,
    protected searchService: SearchService,
    protected errorMessageService: ErrorMessageService,
    protected cartService: CartService ) { }

  public setPrescriptions( prescriptions: PrescriptionsResponse ) {
    this.prescriptions$.next( prescriptions );
  }

  public getPrescriptions() {
    return this.prescriptions;
  }

  syncWithCart( prescriptions: Prescription[] ) {
    return prescriptions.map( ( prescription: Prescription ) => {
      prescription.isSelected = this.cartService.isInCart( prescription );
      return prescription;
    } );
  }

  public fetchPrescriptions( startDate: string, endDate: string, isActionable: boolean ) {
    this.loadingSpinner$.next( true );
    return this.api.getPrescriptions( startDate, endDate, isActionable ).pipe(
      // return this.mockApi.getMockActionable().pipe(
      map( ( response: AllPrescriptionsInfo ) => {
        const memberPrescription = response;
        if ( memberPrescription?.actionablePrescriptions?.prescriptions ) {
          return this.mapActionable(memberPrescription.actionablePrescriptions );
        } else if ( memberPrescription?.nonActionablePrescriptions?.prescriptions ) {
          return this.mapNonActionable( memberPrescription.nonActionablePrescriptions );
        }
      } ),
      catchError( err => {
        console.log('ERROR FROM SERVICE::', err);
        return throwError(err);
      }));
  }

  mapPartialError(pharmacyPartialError: PharmacyPartialError ){
    if ( pharmacyPartialError?.isPartialError ) {
      this.errorMessageService.mapPartialError( pharmacyPartialError );
      this.loadingSpinner$.next( false );
    }
  }

  mapActionable( memberPrescription: PrescriptionsResponse ) {
    this.mapPartialError(memberPrescription?.pharmacyPartialError);
    let { prescriptions: rxList } = memberPrescription;
    rxList = this.checkFillDate( rxList );

    const numberOfEligiblePrescriptions = this.getNumberOfEligiblePrescriptions( rxList );
    this.totalEligiblePrescriptions$.next( numberOfEligiblePrescriptions );
    rxList = this.syncWithCart( rxList );
    memberPrescription.prescriptions = rxList;

    this.setPrescriptions( memberPrescription );
    this.checkForSortAndSearch( memberPrescription );
    this.loadingSpinner$.next( false );
    return this.prescriptions$.getValue();
  }

  checkForSortAndSearch( memberPrescription: PrescriptionsResponse ) {
    this.originalPrescriptions$.next( memberPrescription );
    if ( this.currentSortOption ) {
      this.sortBy( this.currentSortOption );
    }

    if ( this.searchTerm ) {
      this.search( this.searchTerm );
    }
  }

  checkEligibility( prescription: Prescription ) {
    return ( prescription.eligibility.refill || prescription.eligibility.renew )
      && ( prescription.eligibility.digitalRefill === undefined || prescription.eligibility.digitalRefill );
  }

  checkFillDate( rxList: Prescription[] ) {
    return rxList.map( ( prescription: Prescription ) => {
      if ( prescription.lastFillDate ) {
        prescription.lastFillDate = this.formatDate( prescription.lastFillDate );
      }
      if ( prescription.nextFillDate ) {
        prescription.nextFillDate = this.formatDate( prescription.nextFillDate );
      }
      if ( prescription.expirationDate ) {
        prescription.expirationDate = this.formatDate( prescription.expirationDate );
      }

      if ( prescription.estimatedCopay ) {
        prescription.estimatedCopay = prescription.estimatedCopay.replace(',', '');
      }
      return prescription;
    } );
  }

  getNumberOfEligiblePrescriptions( rxList: Prescription[] ) {
    return rxList.filter(
      ( prescription: Prescription ) => this.checkEligibility( prescription ) ).length;

  }

  public getMockData( isActionable = true ) {
    return this.mockApi.getMockData( isActionable );
  }

  public sortBy( option: PrescriptionsSortBy ) {
    this.currentSortOption = option;
    const memberPrescription = this.prescriptions$.getValue();
    let sortedPrescriptions: Prescription[];
    if ( memberPrescription ) {
      const { prescriptions } = memberPrescription;
      if ( option === PrescriptionsSortBy.LATEST ) {

        sortedPrescriptions = this.sortService.sortDescending( prescriptions,
          prescriptionItem => prescriptionItem.lastFillDate, 'date' );

      } else if ( option === PrescriptionsSortBy.OLDEST ) {

        sortedPrescriptions = this.sortService.sortAscending( prescriptions,
          prescriptionItem => prescriptionItem.lastFillDate, 'date' );

      } else if ( option === PrescriptionsSortBy.DRUG_NAME_A_TO_Z ) {

        sortedPrescriptions = this.sortService.sortAscending( prescriptions,
          prescriptionItem => prescriptionItem.drug.name );

      } else if ( option === PrescriptionsSortBy.DRUG_NAME_Z_TO_A ) {

        sortedPrescriptions = this.sortService.sortDescending( prescriptions,
          prescriptionItem => prescriptionItem.drug.name );

      } else if ( option === PrescriptionsSortBy.FUL_FILLED_BY_A_TO_Z ) {

        sortedPrescriptions = this.sortService.sortAscending( prescriptions,
          prescriptionItem => prescriptionItem.fulfilledBy );

      } else if ( option === PrescriptionsSortBy.FUL_FILLED_BY_Z_TO_A ) {

        sortedPrescriptions = this.sortService.sortDescending( prescriptions,
          prescriptionItem => prescriptionItem.fulfilledBy );

      }
      this.setPrescriptions( {
        ...memberPrescription,
        prescriptions: sortedPrescriptions
      } );
    }
  }

  protected mapNonActionable( memberPrescription: PrescriptionsResponse ) {
    this.mapPartialError(memberPrescription?.pharmacyPartialError);
    if ( memberPrescription?.prescriptions ) {
      const  prescriptions  = memberPrescription?.prescriptions as NonActionablePrescription[] ;
      const nonActionablePrescriptions: NonActionablePrescription[] = prescriptions .map(
        ( prescription: NonActionablePrescription ) => {
          return this.getPrescriptionDataInNonActionable( prescription);
        } );
      const newMemberPrescription: PrescriptionsResponse = {
        ...memberPrescription,
        prescriptions: nonActionablePrescriptions
      };
      this.setPrescriptions( newMemberPrescription );
      this.checkForSortAndSearch( newMemberPrescription );
      return this.prescriptions$.getValue();
    }
    return memberPrescription;
  }

  protected getPrescriptionDataInNonActionable( memberClaim: NonActionablePrescription ): NonActionablePrescription {
    const nonactionableResponse = {
      payAmount: memberClaim?.totalCopayAmount,
      fulfilledBy: memberClaim?.fulfilledBy,
       lastFillDate: this.formatDate( memberClaim?.lastFillDate ),
       orderNumber: memberClaim.claimNumber,
      prescriber: {
        firstName: memberClaim?.prescriber?.firstName,
        lastName: memberClaim?.prescriber?.lastName
      },
      eligibility: {
        refill: memberClaim?.eligibility?.refill,
        renew: memberClaim?.eligibility?.renew
      },
      remainingRefills: memberClaim?.remainingRefills,
      drug: {
        name : memberClaim?.drug?.name,
        form : memberClaim?.drug?.form ,
        dosage: memberClaim?.drug?.dosage,
        ndcNumber: memberClaim?.drug?.ndcNumber
      },
      supply: {
        duration: memberClaim?.supply?.duration,
        quantity: memberClaim?.supply?.quantity
      },
      dosageDirections: memberClaim?.dosageDirections,
      rxNumber: memberClaim?.rxNumber,
      expirationDate: memberClaim?.expirationDate
    };
    return nonactionableResponse;
  }

  getFirstName(fullName: string){
    return ( fullName === '' || fullName === 'undefined' ) ? '' : fullName.split(',')[1];
  }

  getLastName(fullName: string){
    return ( fullName === '' || fullName === 'undefined' ) ? '' : fullName.split(',')[0];
  }

  protected formatDate( dateString: string ) {
    return ( dateString === '' || dateString === 'undefined' ) ? '' : this.datePipe.transform( dateString, 'MM/dd/yyyy' ).toString();
  }

  public search( text: string ) {
    text = text.toLowerCase();
    this.searchTerm = text;
    const memberPrescription = this.prescriptions$.getValue();
    let filteredPrescriptions: Prescription[];

    if ( memberPrescription && memberPrescription.prescriptions && text ) {
      filteredPrescriptions = memberPrescription?.prescriptions?.filter( ( prescription: Prescription ) => {
        const drugNameOnly = prescription?.drug?.name.split( prescription?.drug?.dosage )[ 0 ];
        const drugMatch = drugNameOnly.toLowerCase().includes( text );

        const rxNumberMatch = prescription.rxNumber.includes( text );

        const prescriberFullName = prescription?.prescriber?.firstName + ' ' + prescription?.prescriber?.lastName;
        const prescriberMatch = prescriberFullName.toLowerCase().includes( text );

        if ( drugMatch || rxNumberMatch || prescriberMatch ) {
          return prescription;
        }
      } );
      const newMemberPrescription: PrescriptionsResponse = { ...memberPrescription, prescriptions: filteredPrescriptions };
      this.setPrescriptions( newMemberPrescription );
    }
  }

  public restoreOriginalList() {
    if ( this.originalPrescriptions$.getValue() ) {
      this.searchTerm = null;
      this.setPrescriptions( this.originalPrescriptions$.getValue() );
    }
    if ( this.currentSortOption ) {
      this.sortBy( this.currentSortOption );
    }
  }

  public setOriginalPrescriptions() {
    if ( this.prescriptions$.getValue() ) {
      this.originalPrescriptions$.next( this.prescriptions$.getValue() );
    }
  }
}
