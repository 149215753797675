import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";

@Component({
  selector: "app-document-forms",
  templateUrl: "./document-forms.component.html",
  styleUrls: ["./document-forms.component.scss"],
})
export class DocumentFormsComponent implements OnInit {
  constructor(private jsonSvc: JsonContentService, private router: Router) {}

  content: any;

  ngOnInit(): void {
    this.jsonSvc.getJSON("document-hub").subscribe((res) => {
      this.content = res.supportForms;
    });
  }

  onMemberServiceClick() {
    this.router.navigate(["/secure/contact-us/live-chat"]);
  }
}
