import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateRangeService } from './date-range.service';

@Component( {
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: [ './date-range.component.scss' ]
} )
export class DateRangeComponent implements OnInit {

  @Input() dateRangeFilters;
  dateRangeValue: string;
  startDate: string;
  defaultLabel = '';

  constructor(
    private datepipe: DatePipe,
    private dateRangeService: DateRangeService ) { }

  ngOnInit(): void {
    if (!this.dateRangeFilters) {
      this.dateRangeFilters = {
        title: '',
        options: []
      };
    }

    if (this.dateRangeFilters.options.length > 0) {
      this.defaultLabel = this.dateRangeFilters.options[2]?.label;
    }
  }

  getDateFormat = ( date: Date ) => {
    return this.datepipe.transform( date, 'yyyy-MM-dd' );
  }

  getDateRangename = ( dateRangeVal: string ) => {
    return this.dateRangeFilters.options.filter( object => object.value == dateRangeVal )[ 0 ].label.toLowerCase();
  }

  onDateRangeChange = () => {
    this.startDate = this.getDateFormat( this.setstartDate( this.dateRangeValue ) );
    this.dateRangeService.setDateRangeName( this.getDateRangename( this.dateRangeValue ) );
    this.dateRangeService.setStartDateParam( this.startDate );
  }

  setstartDate = ( selectedrange: string ) => {
    const startDt = new Date();
    switch ( selectedrange ) {
      case 'Past7Days':
        startDt.setDate( startDt.getDate() - 7 );
        break;
      case 'Past90Days':
        startDt.setMonth( startDt.getMonth() - 3 );
        break;
      case 'Past6Months':
        startDt.setMonth( startDt.getMonth() - 6 );
        break;
      case 'Past12Months':
        startDt.setMonth( startDt.getMonth() - 12 );
        break;
      case 'Past18Months':
        startDt.setMonth( startDt.getMonth() - 18 );
        break;
      case 'Past24Months':
        startDt.setMonth( startDt.getMonth() - 24 );
        break;
      default:
        startDt.setMonth( startDt.getMonth() - 6 );
        break;
    }

    // Leap year adjustment
    if ( startDt.getDate() === 29 && startDt.getMonth() === 2 ) {
      startDt.setDate( 28 );
    }

    return startDt;
  }

}
