import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datestring'
})
  // TODO: move this out to a shared library
export class DateStringPipe implements PipeTransform {
  transform(str: string, targetLength: number, padString: string): string {
    padString = String((typeof padString !== 'undefined' ? padString : ' '));
    if (str.length >= targetLength) {
      return str;
    }
    else {
      return padString + str;
    }
  }
}

