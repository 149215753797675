import { Component, ElementRef, OnInit, ViewChild, TemplateRef, ViewEncapsulation, HostListener} from '@angular/core';
import { DefaultPageNumber, DefaultSearchRadius, DefaultSearchResultPerPage, DefaultSortOn, TotalSearchResultAllowed } from '../values/pcpConstants';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { loadMoreParam, ProviderSearchRequestData } from '../interfaces/providerSearch';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { NgForm } from '@angular/forms';
import { Option, PcpContent, SearchProviderDetail} from '../interfaces/pcpDetail';
import { OrderBy } from '@anthem/mbrportal/shared/pipes/orderBy';
import { PcpModel } from '../models/pcpModel';
import { PcpService } from '../services/pcpSvc';
import { QueryList } from '@angular/core';
import { ViewChildren } from '@angular/core';
import { default as pcpContactInfoMockData } from '../assets/data/mock/contact-info.json';
import { default as searchProvidersMockData } from '../assets/data/mock/providers.json';

import { ScrollToViewSvc } from '@anthem/mbrportal/shared/services/scrollToViewSvc';
import { Observable } from 'rxjs';
import { AdobeAnalyticsService } from '../../../sydmed-shared/adobe-datalayer/adobe-analytics.service';
import { Restriction } from 'gbd-models';

/**
 * @description
 * This component is responsible for loading all change pcp page
 * This is an entry component
 *
 * @function
 * ngOnInit()
 * assignPCP(): void
 * backToAssignPCP(): void
 * clearSpecialtyFilter(): void
 * filterProviders(): void
 * getFormErrorElementFocused():
 * onLoadMoreClick(): void
 * onReasonChange(reasonCode: string): void - removing this method as it is no longer needed,NC to restrict changing PCP without reason has been handled in node
 * searchProviders(): void
 * setSearchResultMessage(): void
 * sortProviders(sortby = DefaultSortOn): SearchProviderDetail[]
 * toggleFilterPanel(expanded = true): void
 * toggleSpecialtySelect(): void
 * toggleSpecialtySelectAll(): void
 * private fetchChangePCPReason(): void
 * private initializeSearchParams(): void
 * private setContent(): void
 * private setSpecialities(specialities: string[]): void
 *  @example
 * ```html
 * 	<data-sydmed-pcp-change-cmp></data-sydmed-pcp-change-cmp>
 * ```
 */

declare let _satellite: any;
@Component({
  selector: 'data-sydmed-pcp-change-cmp',
  templateUrl: './pcpChangeCmp.html',
  styleUrls: ['../pcpSyd.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ScrollToViewSvc]
})
export class PcpChangeComponent implements OnInit {
  @ViewChild('searchProviderForm', { static: false })
  searchProviderForm: NgForm;

  @ViewChild('sidePanelContent', { static: false })
  sidePanelContent: TemplateRef<HTMLElement>;

  @ViewChild('specialtyFilterPanel', { static: false })
  specialtyFilterPanel: ElementRef;

  @ViewChildren('providers') providers: QueryList<ElementRef>;

  assignPcpLoader: boolean = false;
  changePcpLoader: boolean = false;
  changeReasonsList: Option[];
  content: PcpContent;
  displaySearchRadius: boolean = false;
  displaySpecialtyFilterPanel: boolean = false;
  enableContinue: boolean = false;
  fetchReasonLoader: boolean = false;
  genericError: string;
  hasPcp = true;
  initialProviderList: SearchProviderDetail[] = [];
  isAssignPcpSuccess: boolean = false;
  isMoreDataAvailable: boolean;
  isReasonError: boolean = false;
  isServerError: boolean = false;
  loadMoreLoader: boolean;
  loadMoreParam: loadMoreParam;
  mailingAddress: string = '';
  pcpUpdatedTwiceError: boolean = false;
  pcpUpdatedError: boolean = false;
  providerList: SearchProviderDetail[];
  reasonCode: string = '';
  reasonError: string = '';
  searchProviderAPIErrorMessage: string = '';
  searchProviderErrorMessage: string = '';
  searchProviderLoader: boolean;
  searchResultMessage: string;
  searchResultAriaMessage: string;
  fetchReasonError: boolean = false;
  selectAllSpecialities: boolean;
  selectedProvider: SearchProviderDetail;
  sidePanelRef: ModalRef<any, any>;
  sortByDropdown: string;
  specialtyList: Option[] = [];
  successMessage: string = '';
  searchProvider: ProviderSearchRequestData = {
    ascending: true,
    lineOfBusiness: '',
    mailingAddress: '',
    onlyEligible: true,
    pageNumber: DefaultPageNumber,
    providerName: '',
    range: DefaultSearchRadius,
    resultsPerPage: DefaultSearchResultPerPage,
    sortBy: DefaultSortOn,
    zipCode: '',
  };
  zipCodeErrorMessage: string = '';
  isLockedIn = false;

  constructor(
    private elementRef: ElementRef,
    private interCommunicationService: InterCommunicationService,
    private jsonSvc: JsonContentService,
    private orderBy: OrderBy,
    private pcpModel: PcpModel,
    private pcpService: PcpService,
    private sidePanel: SidePanel,
    private scrollToViewSvc: ScrollToViewSvc,
    private adobeAnalyticsSvc: AdobeAnalyticsService
  ) {
    if(this.jsonSvc.market === 'INMCD') {
      this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: 'Primary Medical Provider',
      });
    } else {
      this.interCommunicationService.raiseEvent({
        title: 'HEADER_TITLE',
        message: 'Primary Care Provider',
      });
    }
  }

  ngOnInit() {
    this.setContent();
  }

  /**
   * @description assignPCP() assigns the PCP to the member
   * @retrun {void}
   */
  assignPCP(): void {
    this.assignPcpLoader = true;
    if (this.content.isLocal) {
      this.assignPcpLoader = false;
      this.sidePanelRef.close();
      this.isAssignPcpSuccess = true;
      this.successMessage = this.content.assignPcp.successMessage.replace(
        '[[providerName]]',
        this.selectedProvider.providerName + ''
      );
      window.scrollTo(0, 0);
    } else {
      this.pcpService.assignPCP(this.selectedProvider.providerId,this.reasonCode).subscribe(
        (data: any) => {
          this.assignPcpLoader = false;
          this.sidePanelRef.close();
          this.isAssignPcpSuccess = true;
          this.successMessage = this.content.assignPcp.successMessage.replace(
            '[[providerName]]',
            this.selectedProvider.providerName + ''
          );
          window.scrollTo(0, 0);
          const reasonString = this.changeReasonsList.find(reason => reason.value === this.reasonCode).label;
          this.adobeAnalyticsSvc.setChangePcpReason(reasonString);
          if (typeof _satellite !== 'undefined') {
            _satellite.track(this.content.changePcp.analytics.updatePcpSuccess);
          }
        },
        (error: any) => {
          this.assignPcpLoader = false;
          if (error?.error?.code) {
            if (error.error.code === 'pcp.422.09') {
              this.pcpUpdatedTwiceError = true;
            } else if (error.error.code === 'pcp.422.00') {
              this.pcpUpdatedError = true;
            } else if (error.error.code === 'pcp.422.06') {
              this.isReasonError = true;
            } else {
              this.isServerError = true;
            }
          }
          else {
            this.isServerError = true;
          }
          if (typeof _satellite !== 'undefined') {
            _satellite.track(this.content.changePcp.analytics.updatePcpFailure);
          }
        }
      );
    }
  }

  /**
   * @description backToAssignPCP() takes the member back to the assign pcp page from the error page
   * @retrun {void}
   */
  backToAssignPCP(): void {
    this.reasonCode = '';
    this.enableContinue = false;
    this.isReasonError = false;
    this.isServerError = false;
    this.pcpUpdatedTwiceError = false;
    this.pcpUpdatedError = false;
  }

  /**
   * @description clearSpecialtyFilter() to clear specialities selected
   * @returns {void}
   */
  clearSpecialtyFilter(): void {
    this.selectAllSpecialities = false;
    this.specialtyList.forEach(
      (Specialty: Option) => (Specialty.isSelected = this.selectAllSpecialities)
    );
  }

  updateIsLockedIn(event): void{
    this.isLockedIn = event;
  }

  @HostListener('click', ['$event'])
  closeSpecialtyFlilterPanel(event) {
    if (event.path) {
      if (event.path.filter((item) => { return "pcp-change-specialty-filter-button" === item.id; }).length > 0) {
        return;
      }
      if (event.path.filter((item) => { return "pcp-spl-filter-panel" === item.id; }).length > 0) {
        return;
      }
      this.displaySpecialtyFilterPanel = false;
    }
  }

  /**
   * @description filterProviders() to filter provider list based on specialities selected
   * @returns {void}
   */
  filterProviders(): void {
    this.displaySpecialtyFilterPanel = false;
    const specialtyOptionsselected: boolean = this.specialtyList.some(
      (specialty: Option) => specialty.isSelected
    );
    if (!specialtyOptionsselected) {
      this.providerList = this.initialProviderList;
    } else {
      let filtertedList: SearchProviderDetail[] = [];
      for (let i = 0; i < this.specialtyList.length; i++) {
        if (this.specialtyList[i].isSelected) {
          filtertedList = [
            ...filtertedList,
            ...this.initialProviderList.filter(
              (provider: SearchProviderDetail) =>
                provider.specialties.includes(this.specialtyList[i].value)
            ),
          ];
        }
      }
      this.providerList = this.setCurrentPCPOrder(
        Array.from(new Set([...filtertedList]))
      );
    }

    this.sortProviders();
    this.setSearchResultMessage();
  }

  /**
   * @description getProviderList() get list of providers based on criteria
   * @returns {void}
   */
  getProviderList(searchParameters: ProviderSearchRequestData): void {
    this.searchProviderAPIErrorMessage = '';
    this.displaySpecialtyFilterPanel = false;
    this.searchProviderErrorMessage = null;
    this.searchProviderAPIErrorMessage = null;
    this.zipCodeErrorMessage = null;

    if (searchParameters.pageNumber === DefaultPageNumber) {
      this.initialProviderList = this.providerList = [];
    }

    if (this.content.isLocal) {
      this.searchProviderLoader = false;
      this.initialProviderList = this.providerList = this.pcpModel.buildProviderList(
        searchProvidersMockData.providerSearchResults,
        this.content
      );

      this.providerList = this.setCurrentPCPOrder(this.initialProviderList);
      this.setSpecialities(this.pcpModel.specialities);
      this.setSearchResultMessage();
      this.selectAllSpecialities = true;
      this.specialtyList.forEach(
        (specialty: Option) => (specialty.isSelected = true)
      );
    } else {
      
      /* 
       * WGS markets currently have a different API to use for finding providers,
       * this will be consolidate in the future as we move more functionality to node
       */
      let proivdersObservable: Observable<any>;
      
    // Calling search providers for CAMMP until alphaPrefix is configured!
      if(this.content.enableFindProivdersAPI) {
        proivdersObservable = this.pcpService.findProviders(searchParameters);
      } else {
        proivdersObservable = this.pcpService.searchProviders(searchParameters);
      }
     
      proivdersObservable.subscribe(
        (data: any) => {
          try {
            this.providerList = this.initialProviderList = this.initialProviderList.concat(
              this.pcpModel.buildProviderList(
                data.body.providerSearchResults,
                this.content
              )
            );

            this.providerList = this.setCurrentPCPOrder(this.providerList);
            this.isMoreDataAvailable =
              this.searchProvider.pageNumber < data.body.totalPages &&
              this.providerList.length !== TotalSearchResultAllowed;
            this.setSpecialities(this.pcpModel.specialities);
            this.setSearchResultMessage();
            this.selectAllSpecialities = true;
            this.specialtyList.forEach(
              (specialty: Option) => (specialty.isSelected = true)
            );
            this.searchProviderLoader = this.loadMoreLoader = false;
            setTimeout(() => {
              this.scrollToViewSvc.scrollDivToView('pcp-change-row-0');
            });
          } catch (error) {
            this.searchProviderAPIErrorMessage = this.content.error.genericError;
            this.searchProviderLoader = this.loadMoreLoader = false;
          }
        },
        (error: any) => {
          if (error.status === 404) {
            this.searchProviderErrorMessage = this.content.error.noPcpAvailable;
          } else {
            if (
              error &&
              error.error &&
              error.error.code &&
              error.error.detail &&
              error.error.code === '9999' &&
              error.error.detail.includes(
                this.content.error.invalidZipCodeError
              )
            ) {
              this.zipCodeErrorMessage = this.content.error.zipCodeErrorMessage;
            } else {
              this.searchProviderAPIErrorMessage = this.content.error.genericError;
            }
          }
          this.searchProviderLoader = this.loadMoreLoader = false;
        }
      );
    }
  }

  /**
   * @description onLoadMoreClick() to fetch more provider result
   * @returns {void}
   */
  onLoadMoreClick(): void {
    this.loadMoreLoader = true;
    const totalResultToBeFetch =
      ++this.searchProvider.pageNumber * DefaultSearchResultPerPage;
    if (totalResultToBeFetch > TotalSearchResultAllowed) {
      this.searchProvider.resultsPerPage =
        totalResultToBeFetch - TotalSearchResultAllowed;
    }
    let searchParam: ProviderSearchRequestData = { ...this.searchProvider };
    searchParam.providerName = this.loadMoreParam.providerName;
    searchParam.range = this.loadMoreParam.range;
    searchParam.zipCode = this.loadMoreParam.zipCode;
    this.getProviderList(searchParam);

    //Focus on latest row after load more
    this.providers?.changes.subscribe(() => {
      this.providers
        .toArray()
        [
          totalResultToBeFetch - this.searchProvider.resultsPerPage
        ].nativeElement.focus();
    });
  }


  /**
   * @description openAssignPcpPanel() opens a panel on the right side to assign a new pcp
   * @param {string[]} direction,
   * @param {SearchProviderDetail} selectedProvider
   */
  openAssignPcpPanel(
    direction: 'left' | 'right' | 'top',
    selectedProvider: SearchProviderDetail
  ): void {
    this.isServerError = false;
    this.isReasonError = false;
    this.pcpUpdatedError = false;
    this.reasonCode = '';
    this.enableContinue = false;
    this.selectedProvider = selectedProvider;
    this.sidePanelRef = this.sidePanel.open(direction, this.sidePanelContent);
    this.fetchChangePCPReason();
  }

  /**
   * @description searchProviders() to update the search result based on search criteria
   * @returns {void}
   */
  searchProviders(): void {
    if (this.searchProviderForm.valid) {
      this.searchProviderLoader = true;
      setTimeout(() => {
        this.scrollToViewSvc.scrollDivToView(
          'pcp-change-search-loading-message'
        );
      });
      this.searchProvider.pageNumber = DefaultPageNumber;
      this.searchProvider.sortBy = this.sortByDropdown = DefaultSortOn;
      this.searchProvider.resultsPerPage = DefaultSearchResultPerPage;
      this.pcpModel.specialities = [];
      this.loadMoreParam = {
        providerName: this.searchProvider.providerName,
        range: this.searchProvider.range,
        zipCode: this.searchProvider.zipCode,
      };
      this.getProviderList(this.searchProvider);
    } else {
      setTimeout(() => {
        const invalidControl = this.elementRef.nativeElement.querySelector(
          '.ng-invalid .ant-error-state'
        );
        if (invalidControl) {
          invalidControl.focus();
        }
      });
    }
  }

  /**
   * @description setSearchResultMessage() to set serach result message
   * @returns {void}
   */
  setSearchResultMessage(): void {
    if (this.providerList.length >= 1) {
      this.searchResultMessage = this.content.changePcp.searchResultMessage
        .replace('[[count]]', this.providerList.length + '')
        .replace(
          '[[results]]',
          this.providerList.length === 1
            ? this.content.changePcp.searchResultLabel
            : this.content.changePcp.searchResultsLabel
        )
        .replace('[[miles]]', this.searchProvider.range + '')
        .replace('[[zip]]', this.searchProvider.zipCode);
      this.searchResultAriaMessage = this.pcpModel.removeHtmlFromString(
        this.searchResultMessage
      );
    } else {
      this.searchResultMessage = this.searchResultAriaMessage = '';
      this.searchProviderErrorMessage = this.content.error.noPcpAvailable;
    }
  }

  /**
   * @description searchProviders() get list of providers based on search criteria
   * @param {string} [sortby = '+distance']
   * @returns {void}
   */
  sortProviders(sortby = DefaultSortOn): SearchProviderDetail[] {
    this.displaySpecialtyFilterPanel = false;
    return this.orderBy.transform(this.providerList, [sortby]);
  }

  /**
   * @description toggleFilterPanel() show/hide filter panel on click
   * @param {boolean} [expanded = true]
   * @returns {void}
   */
  toggleFilterPanel(expanded = true): void {
    this.displaySpecialtyFilterPanel = !this.displaySpecialtyFilterPanel;
  }

  /**
   * @description toggleSpecialtySelect() Responsible for selecting/deselcting select all checkbox based on current checkbox status
   * @return {void}
   */
  toggleSpecialtySelect(): void {
    setTimeout(() => {
      this.selectAllSpecialities = !this.specialtyList.some(
        (specialty: Option) => !specialty.isSelected
      );
    });
  }

  /**
   * @description togglespecialtySelectAll() Responsible for selecting/deselcting all specalities on click
   * @return {void}
   */
  toggleSpecialtySelectAll(): void {
    this.specialtyList.forEach(
      (specialty: Option) => (specialty.isSelected = this.selectAllSpecialities)
    );
  }

  /**
   * @description fetchChangePCPReason() to get the list of reasons to change pcp
   * @retrun {void}
   */
  private fetchChangePCPReason(): void {
    this.fetchReasonLoader = true;
    if (this.content.isLocal) {
      this.fetchReasonLoader = false;
    } else {
      this.pcpService.getChangePCPReasons().subscribe(
        (data: any) => {
          this.fetchReasonLoader = false;
          this.changeReasonsList = this.pcpModel.buildReasonCodeList(
            data.body.termReasons
          );
        },
        (error: any) => {
          this.fetchReasonLoader = false;
          this.fetchReasonError = true;
        }
      );
    }
  }

  /**
   * @description initializeSearchParams() set the default values for provider search
   * @retrun {void}
   */
  private initializeSearchParams(): void {
    this.changePcpLoader = true;
    this.searchProvider.providerName = '';
    this.searchProvider.range = DefaultSearchRadius;
    if (this.content.isLocal) {
      this.searchProvider = this.pcpModel.buildSearchParams(
        this.searchProvider,
        pcpContactInfoMockData.memberAddresses
      );
      this.changePcpLoader = false;
    } else {
      this.pcpService.getAddresses().subscribe(
        (data: any) => {
           this.changePcpLoader = false;
          this.searchProvider = this.pcpModel.buildSearchParams(
            this.searchProvider,
            data?.body
          );
          this.mailingAddress = this.searchProvider.mailingAddress;
        },
        (error: any) => {
          this.changePcpLoader = false;
          this.searchProvider.zipCode = '';
        }
      );
    }
  }

  private setCurrentPCPOrder(
    providerList: SearchProviderDetail[]
  ): SearchProviderDetail[] {
    let [pass, fail] = providerList.reduce(
      ([p, f], e: SearchProviderDetail) =>
        e.isCurrentPCP ? [[...p, e], f] : [p, [...f, e]],
      [[], []]
    );
    return [
      ...this.orderBy.transform(pass, [this.searchProvider.sortBy]),
      ...this.orderBy.transform(fail, [this.searchProvider.sortBy]),
    ];
  }

  /**
   * @description setContent() set the content within component attribute
   * @retrun {void}
   */
  private setContent(): void {
    this.genericError = '';
    this.jsonSvc.getJSON('pcp').subscribe(
      (content: PcpContent) => {
        this.content = content;
        this.hasPcp = !this.jsonSvc.hasRestriction(Restriction.SHM_CHANGE_PCP, content?.restrictions);
        this.initializeSearchParams();
      },
      (error: any) => {
        this.genericError =
          'We are unable to complete your request at this time. Please contact member services.';
      }
    );
  }

  /**
   * @description getSpecialities() to set specilitiies
   * @param {string[]} specialities
   * @retruns {void}
   */
  private setSpecialities(specialities: string[]): void {
    this.specialtyList = [];
    let counts: { [key: string]: number } = {};
    for (let i = 0; i < specialities.length; i++) {
      const providerCount: number = 1 + (counts[specialities[i]] || 0);
      const option: Option = {
        label: specialities[i]
          .concat(' (')
          .concat(providerCount.toString())
          .concat(')'),
        value: specialities[i],
        ariaLabel:
          providerCount +
          ' ' +
          this.content.changePcp.specialty.checkboxAria +
          ' ' +
          specialities[i],
        isSelected: false,
      };

      if (!(specialities[i] in counts)) {
        this.specialtyList.push(option);
      } else {
        const index = this.specialtyList.findIndex(
          (item) => item.value === specialities[i]
        );
        this.specialtyList[index] = option;
      }
      counts[specialities[i]] = 1 + (counts[specialities[i]] || 0);
    }

    this.specialtyList = this.orderBy.transform(this.specialtyList, 'label');
  }
}