<ng-container>
    <div class="cc-and-subheader">
        <div class="width-100">
            <span class="motif-icon motif-cc-visa" aria-label="visa" role="img"></span>
            <span class="motif-icon motif-cc-mastercard" aria-label="mastercard" role="img"></span>
            <span class="motif-icon motif-cc-amex" aria-label="amex" role="img"></span>
            <span class="motif-icon motif-cc-discover" aria-label="discover" role="img"></span>
        </div>
    
        <div class="width-100">
            <h6>{{labels.Headings.PageSubtitle}}</h6>
        </div>
    </div>

    <ng-container *ngIf="loader; else showCardForm">
        <div class="row">
            <div class="tabbed-view-wrapper col-xs-12 loader">
                <uxd-spinner class="updating-loader"></uxd-spinner>
                <div>{{labels.SavingLoaderText}}</div>
            </div>
        </div>
    </ng-container>

    <ng-template #showCardForm>
        <form [formGroup]="addCardForm" (ngSubmit)="addCard(addCardForm)" #cardForm="ngForm" name="cardForm">
            <div class="grid">

                <!-- EDIT: Card name - disabled -->
                <ng-container *ngIf="editDataObj; else showCardNumberForm">
                    <div class="grid-item width-100">
                        <label for="textfield">{{ labels.Labels.CardNumber }}</label>
                    </div>
                    <div class="grid-item width-100">
                        <div class="width-100">
                            <input id="textfield" type="text" [ngModelOptions]="{standalone: true}" disabled [ngModel]="accountName" class="ant-text-input ant-input-long width-100" />
                        </div>
                    </div>
                </ng-container>

                <!-- ADD: card number -->
                <ng-template #showCardNumberForm>
                    <div class="grid-item width-100">
                        <label for="textfield">{{ labels.Labels.CardNumber }}</label>
                    </div>
                    <div class="grid-item width-100">
                        <div class="width-100">
                            <input #cardnumber id="textfield" type="text" formControlName="cardNumber" 
                            [attr.aria-describedby]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-card-num-errors' : 'add-new-specialty-payment-card-num-errors'" 
                            aria-required="true"
                            maxlength="16" class="ant-text-input ant-input-long width-100" libNumbersOnly/>                
                            <div class="error-list" [id]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-card-num-errors' : 'add-new-specialty-payment-card-num-errors'" 
                            *ngIf="(cardNumber.dirty || cardNumber.touched || cardForm.submitted) && cardNumber.invalid">
                                <div *ngIf="cardNumber.errors.required; else showOtherCardErrs">
                                    <span class="success"><i class="motif-icon motif-delete"></i>
                                    <span class="motif-margin-left-5 ">
                                        <span>{{ labels.Labels.CardNumber }}</span
                                        ><span>{{ errObj.Required_err }}</span>
                                    </span>
                                    </span>
                                </div>
                                <ng-template #showOtherCardErrs>
                                    <div *ngIf="cardNumber.errors.minlength || cardNumber.errors.maxlength">
                                        <span class="success"><i class="motif-icon motif-delete"></i>
                                        <span class="motif-margin-left-5">
                                            <span>{{ errObj.Card_Invalid_err }}</span>
                                        </span>
                                        </span>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
            </ng-template>

            <!-- card expiration -->
            <div class="grid-item width-100">
                <label>{{ labels.Labels.CardExpiration }}</label>
            </div>

            <div class="grid-item width-100">
                <div class="half">
                    <div class="width-100">
                        <div
                        #monSelect="ngModel"
                        data-uxd-dropdown-cmp
                        [(ngModel)]="month"
                        class="ant-md-select form-item ng-untouched ng-pristine ng-valid width-100"
                        [labelText]=""
                        name="monSelect"
                        id="monSelect"
                        [items]="selectMonth.options"
                        required
                        [attr.aria-describedby]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-month-errors' : 'add-new-specialty-payment-month-errors'" 
                        aria-required="true"
                        [defaultText]="'Month'"
                        [ngModelOptions]="{ standalone: true }"
                        (ngModelChange)="checkValidExpiry()"
                        [ngClass]="{'redBC': (monSelect.dirty || monSelect.touched || cardForm.submitted) 
                        && (monSelect.invalid || (monSelect.value === ''))}"
                        ></div>
                        <div [id]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-month-errors' : 'add-new-specialty-payment-month-errors'"
                        class="error-list motif-margin-top-2"
                        *ngIf="(monSelect.touched || cardForm.submitted) && monSelect.errors?.required">
                        <div>
                            <span class="motif-icon motif-delete"></span>
                            <span class="motif-margin-left-5">{{ labels.Labels.Month }}</span
                            ><span>{{ errObj.Required_err }}</span>
                        </div>
                        </div>
                    </div>
                </div>
                
                <div class="grid-item half">
                    <div class="width-100">
                        <div
                        #yearSelect="ngModel"
                        data-uxd-dropdown-cmp
                        [(ngModel)]="year"
                        class="ant-md-select form-item ng-untouched ng-pristine ng-valid width-100"
                        [labelText]=""
                        name="yearSelect"
                        id="yearSelect"
                        [items]="selectYear.options"
                        [ngModelOptions]="{ standalone: true }"
                        required
                        [attr.aria-describedby]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-year-errors' : 'add-new-specialty-payment-year-errors'" 
                        aria-required="true"
                        [defaultText]="'Year'"
                        (ngModelChange)="checkValidExpiry()"
                        [ngClass]="{'redBC': (yearSelect.dirty || yearSelect.touched || cardForm.submitted) 
                        && (yearSelect.invalid || (yearSelect.value === ''))}"
                        ></div>
                        <div class="error-list motif-margin-top-2" *ngIf="(yearSelect.touched || cardForm.submitted) && yearSelect.errors?.required"
                        [id]="(lineOfBusiness === 'PBM') ? 'add-new-pharmacy-payment-year-errors' : 'add-new-specialty-payment-year-errors'"><div>
                            <span class="motif-icon motif-delete"></span>
                            <span class="motif-margin-left-5">{{ labels.Labels.Year }}</span
                            ><span>{{ errObj.Required_err }}</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="error-list motif-margin-top-2 form-item clearfix expiry-error" *ngIf="yearSelect.touched && monSelect.touched && expiredDate">
                <div><span class="motif-icon motif-delete"></span><span class="motif-margin-left-5">{{ errObj.Date_Not_Valid }}</span></div>
            </div>

            <!-- show checkbox based on the showCheckBox input passed -->
            <ng-container *ngIf="showCheckBox">
                <div class="grid-item width-100">
                    <div data-uxd-checkbox-input-cmp [definition]="checkbox" formControlName="textCheckbox"></div>
                </div>
            </ng-container>

            <div class="error" *ngIf="errMsg">
                <div data-uxd-alert-cmp-v2 [alert]="{ alertType: 'negative' }">
                    <span [innerHTML]="errMsg"></span>
                </div>
            </div>
            
            <div id="addCardBtnGroup" class="grid-item width-100 text-right">
                <!-- show buttons based on the showButtons input passed -->
                <ng-container *ngIf="showButtons">
                    <div class="button-group width-100">
                        <button type="button" (click)="onCancel()" class="btn btn-secondary">{{ labels.Buttons.Cancel }}</button>
                        <button type="submit" [attr.data-analytics]="editDataObj?'updateSelectPayMethodRx':'addNewPayMethodRx'" class="btn btn-primary">{{editDataObj ? labels.Buttons.Update: labels.Buttons.Add}}</button>
                    </div>
                </ng-container>
            </div>
            </div>
        </form>
    </ng-template>
</ng-container>