<div class="msg-cntr-main uxd-theme-motif container" *ngIf="!showError">
  <inline-alert-container #errorAlert [name]="'inline-alerts'" [alertType]="alertPosition"></inline-alert-container>
  <div class="overlay" [ngClass]="{showMenu: showMenu}"></div>
  <div class="msg-cntr-menu" [ngClass]="{showMenu: showMenu}">
    <a href="javascript:void(0)" class="open-menu" [attr.aria-label]="'Open menu'" [ngClass]="{active: !showMenu}" (click)="openMenu()">
      <span class="motif-icon motif-right-arrow"></span>
    </a>
    <a href="javascript:void(0)" class="close-menu" [attr.aria-label]="'Close menu'" [ngClass]="{active: showMenu}" (click)="closeMenu()">
      <span class="motif-icon motif-delete"></span>
    </a>
    <div [class.isDisabled]="!showSortByDropDown">
      <compose-button></compose-button>
      <folder-menu></folder-menu>
    </div>
  </div>
  <div class="msg-cntr-content" [ngClass]="{showMenu: showMenu}">
    <sort-messages *ngIf="showSortByDropDown"></sort-messages>
    <messages-view [errorAlert]="errorAlertRef"></messages-view>
  </div>
</div>
