import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardContainerComponent } from './dashboard-container/dashboard-container/dashboard-container.component';
import { JsonDataResolverService } from './json-data-resolver.service';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { NewDashboardContainerComponent } from '../new-dashboard/new-dashboard-container/new-dashboard.component';
import { Restriction } from 'gbd-models';

const routes: Routes = [
  { path: '', redirectTo: 'secure/message-center', pathMatch: 'full' },
  { matcher: (url) => {
      if (isRouteMatch(url) && !isNewDashboard()) {
        return {consumed: url};
      }
      return null;
    }, component: DashboardContainerComponent, resolve: {jsonData: JsonDataResolverService}, canActivate: [AuthGuardService]
  },
  { matcher: (url) => {
    if (isRouteMatch(url) && isNewDashboard()) {
        return {consumed: url};
      }
      return null;
    }, component: NewDashboardContainerComponent, resolve: {jsonData: JsonDataResolverService}, canActivate: [AuthGuardService]
  },
  { matcher: (url) => {
      if (isRouteMatch(url)) {
        return {consumed: url};
      }
      return null;
    }, redirectTo: 'public/login'
  }
];

export function isRouteMatch(urlSegment): boolean {
  return urlSegment.length === 2 && urlSegment[0].path === 'secure' && urlSegment[1].path === 'dashboard';
}

export function isNewDashboard(): boolean {
  const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
  return memberContext && !memberContext?.restrictions?.includes(Restriction.SHM_NEW_DASHBOARD);
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
