<div class="uxd-theme-motif container">
    <div *ngIf="!loadingSpinner" class="padding-bottom-66">
        <div *ngIf="aslError" class="full-width-alert-spacer" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
            <span>{{aslError}}</span>
        </div>
        <div *ngIf="jsonLabels?.Headings?.Decommissioned_Message" class="full-width-alert-spacer" data-uxd-alert-cmp-v2 [alert]="{alertType: 'info'}">
            <span>{{jsonLabels?.Headings?.Decommissioned_Message?.message}}</span><a href="https://dchp-member.com/" target="_blank"><span>{{jsonLabels?.Headings?.Decommissioned_Message?.hyperlink}}</span></a><span>{{jsonLabels?.Headings?.Decommissioned_Message?.period}}</span>
        </div>
        <div class="max-width-elements">
            <h1 tabindex="-1" class="font-32 margin-bottom-24">{{jsonLabels?.Headings.Login}}</h1>
            <div class="margin-bottom-56">
                <p class="font-16">{{jsonLabels?.Headings.SignUp}}
                    <a class="padding-left-8" routerLink="/public/registration/home"
                        data-analytics="registerButtonLogin">
                        <span
                            class="font-16 font-bold">{{jsonLabels?.Headings.RegisterNow}}</span>
                    </a>
                </p>
                <div *ngIf="jsonLabels?.BrandingChange_Message" class="branding-change">
                    <span>{{jsonLabels?.BrandingChange_Message}}</span>
                </div> 
                <div *ngIf="jsonLabels?.ExistingAccount_Message" class="margin-top-50" data-uxd-alert-cmp-v2 [alert]="{alertType: 'info'}">
                    <span [innerHTML]="jsonLabels?.ExistingAccount_Message"></span>
                </div>
            </div>
            <form #formLogin="ngForm" [formGroup]="LoginForm" (ngSubmit)="login(formLogin)" id="LoginForm"
                name="LoginForm">
                <div class="margin-bottom-40">
                    <label class="font-16 font-semi-bold" for="username">{{jsonLabels?.Fields.Username}}</label>
                    <input type="text" name="username" formControlName="username" (change)="onChangeInput()"
                        aria-required="true" class="width-100 ant-text-input" id="username" aria-describedby="usernameError">
                    <div id="usernameError" class="margin-top-14 font-12 text-error"
                        *ngIf="(formLogin.submitted || LoginForm.controls['username']?.touched) && LoginForm.controls['username']?.invalid" aria-live="polite">
                        <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                        <span class="error">{{jsonLabels?.Error.NameRequired}}</span>
                    </div>
                </div>
                <div class="margin-bottom-48">
                    <label class="font-16 font-semi-bold" for="password">{{jsonLabels?.Fields.Password}}</label>
                    <div class="mask-wrapper ant-alphabet-tabs-container">
                        <input #password (change)="onChangeInput()" aria-required="true" type="password"
                            id="password" class="width-100 ant-text-input"
                            [ngClass]="{'invalid-input-field': (formLogin.submitted || LoginForm.controls['password']?.touched)  && LoginForm.controls['password']?.invalid}"
                            formControlName="password" name="password" aria-describedby="passwordError">
                        <span (click)="showHide(password, jsonLabels?.Fields.Password)" (keyup.enter)="showHide(password, jsonLabels?.Fields.Password)" id="btnidmask"
                            tabindex="0" role="button" aria-pressed="false" [attr.aria-label]="'unmask ' + jsonLabels?.Fields.Password" 
                            class="fa md q ant-info-icon ant-data-security-mask fa-eye-slash"><span
                                class="sr-only">{{jsonLabels?.Fields.MaskPassword}}</span>
                        </span>
                    </div>
                    <div id="passwordError" class="margin-top-14 font-12 text-error"
                        *ngIf="(formLogin.submitted || LoginForm.controls['password']?.touched)  && LoginForm.controls['password']?.invalid" aria-live="polite">
                        <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                        <span class="error">{{jsonLabels?.Error.PasswordRequired}}</span>
                    </div>
                </div>
                <div class="flex-horizontal-space-between margin-bottom-56">
                    <div class="forget-password-link">
                        <a class="flex-vertical-start text-decoration-none"
                            routerLink="/public/forgot-username-or-password/home" data-analytics="forgotUserPassLogin">
                            <i class="motif-icon motif-right-round vertical-center font-24 go-to-link-icon"></i>
                            <span class="margin-left-8 font-16 font-bold">{{jsonLabels?.Forgot_Link.Text}}</span>
                        </a>
                    </div>
                    <div class="login-submit text-right">
                        <button type="submit" id="btnNext" class="btn btn-primary login-submit-btn"
                            data-analytics="submitCredLogin">{{jsonLabels?.Buttons.Submit}}</button>
                    </div>
                </div>
            </form>
            <div class="margin-top-16 margin-bottom-24" *ngIf="!loadingSpinner">
                <h2 class="font-26 font-normal">{{loginLinks?.Feature_List.Header}}</h2>
            </div>
            <div class="login-feature-list-wrapper" *ngIf="!loadingSpinner">
                <ul class="login-feature-list">
                    <li *ngFor="let item of loginLinks?.Feature_List.list">{{item}}</li>
                </ul>
                <p class="margin-top-24">{{jsonLabels?.Member_Card}}</p>
            </div>
        </div>
    </div>
    <div class="ant-margin-top-60 loading-spinner-alignment" *ngIf="loadingSpinner">
        <uxd-spinner></uxd-spinner>
    </div>
</div>
  <ng-template  #touModalTemplate>
    <div class="tou-modal" id="delete-account">
      <div class="modal-header">
        <button class="close motif-icon motif-delete" aria-label="Close delete account modal" (click)="onClose()">
        </button>
      </div>
      <div class="modal-title">
        <h2 class="font-26">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.ModalHeader}}</h2>
      </div>
      <div class="modal-body">
        <p class="font-16">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.ModalDescription}}</p>

        <!-- checkbox -->      
        <div class="modalcheckbox">
          <div class="checkbox font-14" data-uxd-checkbox-input-cmp [definition]="checkbox" aria-required="true" [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}"></div>
        </div>

      </div>
      <div class="margin-top-14  errorMsg" *ngIf="isSubmitted && !isChecked">
        <div>
            <span class="fas fa-times error vertical-align-middle font-12 margin-right-8"></span>
            <span class="error font-12">{{jsonLabels?.Error.Terms_Of_Use_error}}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary modal-btn" data-analytics= "cancelPolicyTermsOfUseLogin"
          (click)="onClose()">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.CancelButton}}</button>
        <button  class="btn continue modal-btn btn-primary" data-analytics= "continuePolicyTermsOfUseLogin"
          (click)="onUpdateTou()">{{jsonLabels?.PrivacyPolicyandTermsofUseModal.ContinueButton}}</button>
      </div>
    </div>
  </ng-template> 