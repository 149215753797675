<div class="alert">
    <div class="icon-message">
        <span class="motif-icon motif-info" aria-hidden="true"></span>
    <p class="alert-text">
        <span class="p-message" [innerHTML]="labels?.message"></span>
        <a href="javascript:void(0)" [attr.data-analytics]="labels?.urlLinkAnalytics" (click)="onUrlClick()">{{labels?.urlText}}</a>
    </p>
    <span
        [attr.data-analytics]="labels?.closeIconAnalytics"
        class="a-close motif-icon motif-delete closeIcon"
        (click)="close()"
        type="button"
    ></span>
    </div>
    
</div>