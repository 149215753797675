
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { ShippingRequestObject } from '../interfaces/ShippingRequestObject';
import { SpecialtyShippingRequest } from '../interfaces/SpecialtyShippingRequest';
import { ValidateSpecialtyAddress } from '../interfaces/ValidateSpecialtyAddress';
import { ValidAddressResponse } from '../interfaces/ValidAddressResponse';
import { AddressType, RxOrderRequest, ShippingAddress, ShippingResponse, UpdateAddressRequest } from 'gbd-models';
const endPoints = UrlProperties.UrlProperties.endPoints;

@Injectable( {
  providedIn: 'root'
} )
export class PharmacyApiService {

  protected pharmacyEndpoints = endPoints.pharmacy;
  protected otherEndpoints = endPoints;

  constructor( protected http: HttpClientService ) { }
  getPrescriptions( startDate: string, endDate: string, isActionable: boolean = true ) {
    const params = {
      endDate,
      startDate,
      nonActionable : !isActionable
    };
    const  {prescriptions} = this.pharmacyEndpoints;
    const url = prescriptions;

    return this.http.getObservable( url, params ).pipe( map( res => {
      return res.body;
    } ),
      catchError( err => throwError( err ) ) );
  }

  getMedicalProfile() {
    const url = this.otherEndpoints.medicalProfile.medicalProfile;
    return this.http.getObservable(url).pipe(map(res => {
      return res.body;
    }),
      catchError(err => throwError(err)));
  }

  getShippingInfo( requestObject: ShippingRequestObject ): Observable<ShippingResponse>{
    const { shipping } = this.pharmacyEndpoints;
    return this.http.postObservable( shipping, requestObject ).pipe(
      map( res => {
        return res.body;
      } ), catchError( err => throwError( err ) ) );
  }

  getSpecialtyShippingInfo( requestObject: SpecialtyShippingRequest ): Observable<ShippingResponse> {
    const { specialtyShipping } = this.pharmacyEndpoints;
    return this.http.postObservable( specialtyShipping, requestObject ).pipe(
      map( res => {
        return res.body;
      } ), catchError( err => throwError( err ) ) );
  }

  getAccounts() {
    const { accounts: url } = this.pharmacyEndpoints;
    return this.http.getObservable( url ).pipe(
      map( res => {
        return res.body;
      } ), catchError( error => throwError( error ) ) );
  }

  getSpecialtyAccounts() {
    const { specialtyAccounts: url } = this.pharmacyEndpoints;
    return this.http.getObservable( url ).pipe(
      map( res => {
        return res.body;
      } ), catchError( error => throwError( error ) ) );
  }



  addNewAddress( address: ShippingAddress ) {
    const { addNewAddress: url } = this.pharmacyEndpoints;
    const { city, county, state, line1, line2, zipCode } = address;
    const requestObject: UpdateAddressRequest = {
      address: {
        city,
        county,
        state,
        streetAddress1: line1,
        streetAddress2: line2,
        zipCode,
      },
      type: AddressType.PHARMACY,
    };
    return this.http.postObservable( url, requestObject ).pipe(
      map( res => {
        return res.body;
      } ),
      catchError( error => throwError( error ) )
    );
  }

  validateAddress( params ) {
    const { validateAddress: url } = this.pharmacyEndpoints;
    return this.http.postObservable( url, params ).pipe(
      map( res => {
        return res.body as ValidAddressResponse;
      } ),
      catchError( error => throwError( error ) ) );
  }

  validateSpecialtyAddress( requestObject: ValidateSpecialtyAddress ) {
    const { validateSpecialtyAddress: url } = this.pharmacyEndpoints;
    return this.http.postObservable( url, requestObject ).pipe(
      map( res => {
        const responseBody = res.body as ValidAddressResponse;
        responseBody.status = res.status;
        return responseBody;
      } ),
      catchError( error => throwError( error ) )
    );

  }

  checkoutRxOrder( requestObject: RxOrderRequest ) {
    const { checkout: url } = this.pharmacyEndpoints;
    return this.http.postObservable( url, requestObject ).pipe(
      map( res => {
        return res.body;
      } ),
      catchError( error => throwError( error ) )
    );
  }


  searchPharmacy( requestObject ) {
    const { pharmacySearch: url } = this.pharmacyEndpoints;
    return this.http.postObservable( url, requestObject ).pipe(
      map( res => {
        return res.body;
      } ),
      catchError( error => throwError( error ) )
    );
  }
}
