export enum PharmacyLineOfBusiness {
    PBM = 'PBM',
    SPECIALTY = 'SPECIALTY'
  }

export enum SuccessRegion {
    REGION_NAME = 'success-alert'
}

export enum CheckBoxNames {
    CARD_CHECKBOX = 'addcard-checkbox',
    CHECKING_CHECKBOX = 'addchecking-checkbox'
}

export const DEFAULT_NUMBER_OF_MONTHS = 12;

export const DEFAULT_NUMBER_OF_YEARS = 20;