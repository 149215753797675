import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../registration.service';
import { ReviewModel } from './review.model';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service'
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';

declare var _satellite: any;

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {

  public reviewModel: any;
  public aslError: any;
  public jsonLabels;
  public jsonErrors;
  public isCaregiver: boolean = false;
  public loadingSpinner: boolean = false;
  memberDetails: any;

  constructor(
    private router: Router,
    private regSvc: RegistrationService,
    private rvwModel: ReviewModel,
    private errSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService
  ) { }

  async ngOnInit() {
    this.rvwModel.initModel();
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Review;
      this.jsonErrors = data.RegistrationMFA.ErrorMsgs;
    });
    
    this.isCaregiver = JSON.parse(sessionStorage.getItem('contactsModel')).memberResponse.caregiverRequired;
    
    await this.regSvc.decrypt({token: sessionStorage.getItem('reviewDetails')}).then(res => {
      this.rvwModel.transformMemberInput(JSON.parse(res.body), JSON.parse(sessionStorage.getItem('contactsModel')).memberInput);
    })
    this.reviewModel = this.rvwModel.getReviewModel();
    sessionStorage.setItem('reviewVisited', 'true');
    this.memberDetails = JSON.parse(window.sessionStorage.getItem('careGiverInfo'));
    if (this.memberDetails) {
      this.rvwModel.transformCaregiverInput(this.memberDetails)
      this.reviewModel = this.rvwModel.getReviewModel();
    }
  }

  public submit() {
    this.loadingSpinner = true;
    this.regSvc.submitRegistration(this.reviewModel.registration).subscribe(
      response => {
        if (typeof _satellite !== 'undefined') {
          _satellite.track('registrationSuccess');
        }
        this.router.navigate(['public/registration/confirmation']);
      },
      error => {
        this.loadingSpinner = false;
        this.aslError = this.errSvc.errorCodeMapper(error, this.jsonErrors);
      }
    );
  }

  public back() {
    this.router.navigate(['public/registration/security-questions']);
  }

}
