import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeftNavigationService {

  receivedOrderData: EventEmitter<any> = new EventEmitter<any>();
  public isMini: boolean = false;
  constructor() { }
  
  private navActive: BehaviorSubject<string> = new BehaviorSubject<string>('0');

  public setNavActive(nav) {
    this.navActive.next(nav);
  }

  public getNavActive() {
    return this.navActive;
  }

  public emitOrderData(data) {
    this.receivedOrderData.emit(data);
  }

}
