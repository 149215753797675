import { APP_BASE_HREF } from "@angular/common";
import { NgModule } from "@angular/core";
import { EmulationRoutingModule } from "./emulation-routing.module";
import { EmulationComponent } from "./emulation.component";
import { IndicatorModule } from "@anthem/uxd/indicators";

@NgModule({
  declarations: [EmulationComponent],
  imports: [EmulationRoutingModule, IndicatorModule],
  exports: [],
  providers: [{ provide: APP_BASE_HREF, useValue: "/" }],
  bootstrap: [],
  schemas: [],
})
export class EmulationModule {}
