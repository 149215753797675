import { ChangeDetectionStrategy, Component, Input, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ErrorMsgs, MemberData, MemberInformationLabels } from '../../models/labels';
import { MemberInformationValues } from '../../models/formValues';
import { Subscription } from 'rxjs';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';

@Component({
  selector: 'app-member-information',
  templateUrl: './member-information.component.html',
  styleUrls: ['../../appeals-and-grievances.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MemberInformationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MemberInformationComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MemberInformationComponent implements ControlValueAccessor, OnDestroy {

  @Input('appealsAndGrivencesFormSubmitted') appealsAndGrivencesFormSubmitted: boolean;
  @Input() errorMessageLabels: ErrorMsgs;
  @Input() memberLabels: MemberInformationLabels;
  memberContext: Array<Object> = [];
  memberInformationForm: FormGroup;
  public memberData: MemberData;
  subscriptions: Subscription[] = [];
  @Input('editable') editable: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.memberInformationForm = this.formBuilder.group({
      phoneNumber: [],
      authorizationNumber: ['', [FormValidators.AlphaNumericCharsValidator]]
    });

    this.subscriptions.push(
      this.memberInformationForm.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    )

    this.memberData = this.getMemberData();
  }

  get value(): MemberInformationValues {
    return this.memberInformationForm.value;
  }

  /**
     * @description getMemberData() used to fetch logged in member's name and hcid from session
     * @retruns MemberData
  */
  getMemberData(): MemberData {
    let memberContext = window.sessionStorage.getItem('sydMedMemberContext') && JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    let memberData: MemberData;
    if (typeof (memberContext) !== undefined) {
      memberData = {
        firstName: this.format(memberContext?.memberName?.firstName),
        lastName: this.format(memberContext?.memberName?.lastName),
        hcid: this.format(memberContext?.hcid)
      }
    }
    return memberData;
  }

  /**
    * @description format() used to check if a value is null, undefined or blank
    * @param {any} value
    * @retruns {any}
  */
  private format(value: any): any {
    if (!value) {
      value = '';
    }
    return value;
  }

  set value(value: MemberInformationValues) {
    this.memberInformationForm.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (!value) {
      this.memberInformationForm.reset();
    }
  }
  

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.memberInformationForm.valid ? null : { memberInformation: { valid: false } }
  }
}
