import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class PharmacyPriorAuthorizationService {
  priorAuthApi = UrlProperties.UrlProperties.endPoints.authorizationStatus.rxPreAuth;

  constructor( protected http: HttpClientService ) { }

  getRxPriorAuth(startDate, endDate) {
    return this.http.getObservable(this.priorAuthApi + '?startDate=' + startDate + '&endDate=' + endDate);
  }

  getDecisionLetter(decisionLetterId: string): Observable<any> {
    const url = UrlProperties.UrlProperties.endPoints.authorizationStatus.decisionLetter
    const param =  { 
      contentKey: decisionLetterId
    };
    return this.http.getObservable(url, param, { responseType: 'arraybuffer' });
  }
}  