// Angular Imports
import { Injectable } from '@angular/core';

// Motif Imports
import { Restriction } from 'gbd-models';
import { JsonContentService } from '../../sydmed-shared/content-service/json-content.service';
import { ClaimsContent } from './interfaces/claimsContent';

@Injectable({
  providedIn: 'root'
})

export class ClaimsDataService {

  constructor( private jsonSvc: JsonContentService) { }

  private _content: ClaimsContent;
  private _restrictions: Array<string>;
  private _claimsSummary: any;
  private _claimsError: any;

  public set restrictions(restrictions: Array<string>) {
    this._restrictions = restrictions;
  }

  public get restrictions(): Array<string> {
    return this._restrictions;
  }

   public set claimsSummary(claimsSummary: any) {
   this._claimsSummary = claimsSummary;
  }

  public get claimsSummary() {
    return this._claimsSummary
  }

   public set claimsError(claimsError: any) {
   this._claimsError = claimsError;
  }

  public get claimsError() {
    return this._claimsError;
  }

  public set content(content: ClaimsContent) {
    this._content = content;
  }

  public get content(): ClaimsContent {
    return this._content;
  }

  public get hasFinancialSummary(): boolean {
    return this.jsonSvc.hasRestriction(Restriction.SHM_CLAIMS_FINANCIAL, this._content.restrictions);
  }

  public get hasPharmacy(): boolean {
    return !this.jsonSvc.hasRestriction(Restriction.SHM_NO_PHARMACY, this._content.restrictions);
  }
  public get hasHistoricalClaims(): boolean {
    return this.jsonSvc.hasRestriction(Restriction.SHM_HISTORICAL_CLAIMS, this._content.restrictions);
  }
}
