import { PharmacyLineOfBusiness } from "./enum-values";
import { FormGroup } from '@angular/forms';

export class Utils {
  static getPayloadForAddCard(pharmacyLineOfBusiness: string, form: FormGroup, month: string, year: string, paymentMethodId: string) {
    if (this.isPbm(pharmacyLineOfBusiness)) {
      // pbm card payload
      return {
        creditCardExpiryMonth: month,
        creditCardExpiryYear: year.slice(-2),
        creditCardNumber: form.value.cardNumber,
        exclusiveIndicator: true,
        preferredAccount: form.value.textCheckbox
      };
    } else {
      // specialty card payload
      return {
        paymentMethodDetails: {
          payment: {
            method: "CCARD",
            type: "ONETIME"
          },
          accountNumber: form.value.cardNumber,
          paymentMethodId: paymentMethodId,
          expiryDate: {
            month: month,
            year: year
          }
        }
      };
    }
  }

  static getPayloadForChecking(pharmacyLineOfBusiness: string, formValues) {
    
    if (this.isPbm(pharmacyLineOfBusiness)) {
      // pbm checking payload
      return {
        bankAccountNumber: formValues.accountNumber,
        bankAccountType: 'Checking',
        bankRoutingNumber: formValues.routingNumber,
        exclusiveToMember: true,
        preferredAccount: formValues.textCheckbox,
        firstName: formValues.firstName,
        lastName: formValues.lastName
      };
    } else {
      // specialty checking payload
      return {
        paymentMethodDetails: {
          payment: {
            method: "CHECK",
            type: "ONETIME"
          },
          accountNumber: formValues.accountNumber,
          routingNumber: formValues.routingNumber,
          expiryDate: {
            month: "",
            year: ""
          }
        }
      };
    }
  }

  static getPayloadForEditCard(pharmacyLineOfBusiness: string, form: FormGroup, accountName: string, month: string, year: string, paymentMethodId: string ) {
    if (this.isPbm(pharmacyLineOfBusiness)) {
      // edit pbm card payload
      return {
        creditCardExpiryMonth: month,
        creditCardExpiryYear: year,
        accountName: accountName,
        exclusiveIndicator: true,
        preferredAccount: form.value.textCheckbox
      };
    } else {
      // edit specialty card payload
      return {
        paymentMethodDetails: {
          payment: {
            method: "CCARD",
            type: "ONETIME"
          },
          paymentMethodId: paymentMethodId,
          expiryDate: {
            month: month,
            year: year
          }
        }
      };
    }
  }

  // get the formatted account name for edit card
  static getAccountNameForEdit(accountName: string) {
    if(accountName) {
      return accountName.replace(/.(?=.{4,}$)/g, '*');
    }    
  }

  static isPbm(pharmacyLineOfBusiness) {
    if(pharmacyLineOfBusiness === PharmacyLineOfBusiness.PBM) {
        return true;
    } else if(pharmacyLineOfBusiness === PharmacyLineOfBusiness.SPECIALTY) {
      // for LineOfBusiness specialty
        return false;
    }
  }
}
