import { AccordianModule } from '@anthem/uxd/accordion';
import { AlertModule } from '@anthem/uxd/alert';
import { ButtonModule } from '@anthem/uxd/button';
import { CartIconComponent } from './cart-icon/cart-icon.component';
import { CartService } from './services/cart.service';
import { CartSessionService } from './services/cart-session.service';
import { CheckboxModule } from '@anthem/uxd/checkbox';
import { CommonModule } from '@angular/common';
import { CommonPipesModule } from 'sydmed/libs/common-pipes/src/lib/common-pipes.module';
import { DateRangeComponent } from './date-range/date-range.component';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { FormGroupModule } from '@anthem/uxd/form-group';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { MedicalProfileService } from 'sydmed/src/app/secure/pharmacy/services/medical-profile.service';
import { MockDataService } from 'sydmed/src/app/secure/pharmacy/services/mock-data.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { PaymentMethodBlockComponent } from './payment-method-block/payment-method-block.component';
import { PaymentMethodDataService } from './services/payment-method-data.service';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PaymentMethodsModule } from 'sydmed/libs/pharmacy-components/payment-methods/payment-methods.module';
import { PaymentMethodsService } from 'sydmed/libs/pharmacy-components/payment-methods/services/payment-methods.service';
import { PharmacyApiService } from '../pharmacy/services/pharmacy-api.service';
import { PharmacyContentService } from './services/pharmacy-content.service';
import { PharmacyRoutes } from './PharmacyRoutes.enum';
import { PharmacySessionService } from './services/pharmacy-session.service';
import { PrescriptionDetailsComponent } from './prescription-details/prescription-details.component';
import { PrescriptionFooterComponent } from './prescription-footer/prescription-footer.component';
import { PrescriptionListHeaderComponent } from './prescription-list-header/prescription-list-header.component';
import { PrescriptionsListComponent } from './prescriptions-list/prescriptions-list.component';
import { PrescriptionsService } from 'sydmed/src/app/secure/pharmacy/services/prescriptions.service';
import { PrintComponent } from './print/print.component';
import { ProgressBarService } from 'sydmed/src/app/secure/pharmacy-prescription-order/progress-bar/progress-bar.service';
import { RadioModule } from '@anthem/uxd/radio';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from 'sydmed/src/app/secure/pharmacy/search/search.component';
import { SortComponent } from './sort/sort.component';
import { TabsModule } from '@anthem/uxd/tabs';
import { TextHighlightPipe } from './pipes/text-highlight.pipe';
import { PharmacySearchModule } from '../pharmacy-search/pharmacy-search.module';
import { DismissibleAlertComponent } from './dismissible-alert/dismissible-alert.component';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { PrescriptionPreAuthAlertComponent } from './prescription-pre-auth-alert/prescription-pre-auth-alert.component';
import { PharmacyRouteGuardService } from './services/pharmacy-route-guards.service';
import { AhdLeftRailNavComponent } from '../ahd-pharmacy/ahd-left-rail-nav/ahd-left-rail-nav.component';
import { PharmacyLandingPageComponent } from '../ahd-pharmacy/ahd-pharmacy-landing/ahd-pharmacy-landing.component';
import { AhdPharmacyRoutes } from '../ahd-pharmacy/ahd-pharmacy-routes.enum';
import { AhdPharmacyRouteGuardService } from '../ahd-pharmacy/ahd-pharmacy-route-gaurd.service';
import { AhdPrescriptionHomeComponentContainer } from '../ahd-pharmacy/ahd-prescription-home-container/ahd-prescription-home-container.component';
import { AhdMyOrdersPageComponentContainer } from '../ahd-pharmacy/ahd-my-orders-page-container/ahd-my-orders-page-container.component';

const routes: Routes = [
  {
    path: PharmacyRoutes.Pharmacy,
    pathMatch: 'full',
    redirectTo: PharmacyRoutes.Dashboard
  },
  {
    path: PharmacyRoutes.Dashboard,
    canActivate: [AuthGuardService, PharmacyRouteGuardService],
    loadChildren: () => import( '../pharmacy-dashboard/pharmacy-dashboard.module' )
      .then( m => m.PharmacyDashboardModule )
  },
  {
    path: PharmacyRoutes.Refills,
    canActivate: [AuthGuardService, PharmacyRouteGuardService],
    loadChildren: () =>
      import( '../pharmacy-prescription-order/pharmacy-prescription-order.module' )
        .then( m => m.PharmacyPrescriptionOrderModule )
  },
  {
    path: PharmacyRoutes.Orders,
    canActivate: [AuthGuardService, PharmacyRouteGuardService],
    loadChildren: () =>
      import( '../pharmacy-order-status/pharmacy-order-status.module' )
        .then( m => m.PharmacyOrderStatusModule )
  },
  {
    path: PharmacyRoutes.AllPrescriptions,
    canActivate: [AuthGuardService, PharmacyRouteGuardService],
    loadChildren: () =>
      import( '../pharmacy-all-prescriptions/pharmacy-all-prescriptions.module' )
        .then( m => m.PharmacyAllPrescriptionsModule )
  },
  {
    path: PharmacyRoutes.PayAccountBalance,
    canActivate: [AuthGuardService, PharmacyRouteGuardService],
    loadChildren: () =>
      import( '../pharmacy-pay-account-balance/pharmacy-pay-account-balance.module' )
        .then( m => m.PharmacyPayAccountBalanceModule )
  },
  {
    path: PharmacyRoutes.Onboarding,
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import( '../pharmacy-onboarding/pharmacy-onboarding.module' )
        .then( m => m.PharmacyOnboardingModule )
  },
  {
    path: PharmacyRoutes.PaymentHistory,
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import( '../pharmacy-payment-history/pharmacy-payment-history.module' )
        .then( m => m.PharmacyPaymentHistoryModule )
  },
  {
    path: PharmacyRoutes.MedicationSearch,
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import( '../pharmacy-medication-search/pharmacy-medication-search.module' )
        .then( m => m.PharmacyMedicationSearchModule )
  },
  {
    path: PharmacyRoutes.FindPharmacy,
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import( '../find-pharmacy/find-pharmacy.module' )
        .then( m => m.FindPharmacyModule )
  },
  {
    path: PharmacyRoutes.ZipDrug,
    canActivate: [AuthGuardService],
    loadChildren: () => 
      import( '../pharmacy-zipdrug/pharmacy-zipdrug.module' )
        .then( m => m.PharmacyZipdrugModule )
  },
  { path: AhdPharmacyRoutes.PHARMACY, redirectTo: AhdPharmacyRoutes.DASHBOARD, pathMatch: 'full' },
  { path: AhdPharmacyRoutes.PRESCRIPTIONS, redirectTo: AhdPharmacyRoutes.ALL_PRESCRIPTIONS, pathMatch: 'full' },
  {
    path: AhdPharmacyRoutes.PHARMACY,
    component: AhdLeftRailNavComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: "dashboard",
        canActivate: [AuthGuardService, AhdPharmacyRouteGuardService],
        component: PharmacyLandingPageComponent,
      },
      {
        path: "allprescriptions",
        canActivate: [AuthGuardService, AhdPharmacyRouteGuardService],
        component: AhdPrescriptionHomeComponentContainer,
      },
      {
        path: "prescriptions/orders",
        canActivate: [AuthGuardService, AhdPharmacyRouteGuardService],
        component: AhdMyOrdersPageComponentContainer
      }
    ],
  },
];

@NgModule( {
  imports: [
    CommonModule,
    CommonPipesModule,
    FormsModule,
    ReactiveFormsModule,
    FormGroupModule,
    DropdownModule,
    AccordianModule,
    CheckboxModule,
    IndicatorModule,
    RadioModule,
    AlertModule,
    ButtonModule,
    RouterModule.forChild( routes ),
    PaymentMethodsModule,
    TabsModule,
    PharmacySearchModule
  ],
  exports: [
    RouterModule,
    CartIconComponent,
    DateRangeComponent,
    SortComponent,
    ErrorMessageComponent,
    PrescriptionsListComponent,
    PrescriptionListHeaderComponent,
    HeaderNavComponent,
    SearchComponent,
    PrescriptionFooterComponent,
    PrintComponent,
    PaymentMethodBlockComponent,
    PaymentMethodsComponent,
    PharmacySearchModule,
    DismissibleAlertComponent,
    PrescriptionPreAuthAlertComponent
  ],
  declarations: [
    CartIconComponent,
    DateRangeComponent,
    ErrorMessageComponent,
    HeaderNavComponent,
    PrescriptionsListComponent,
    PrescriptionListHeaderComponent,
    PrescriptionDetailsComponent,
    PrescriptionFooterComponent,
    PrintComponent,
    SearchComponent,
    SortComponent,
    TextHighlightPipe,
    PaymentMethodBlockComponent,
    PaymentMethodsComponent,
    DismissibleAlertComponent,
    PrescriptionPreAuthAlertComponent
  ],
  providers: [
    PharmacyContentService,
  ]

} )
export class PharmacyModule {
  static forRoot(): ModuleWithProviders<PharmacyModule> {
    return {
      ngModule: PharmacyModule,
      providers: [
        CartService,
        CartSessionService,
        PharmacySessionService,
        MockDataService,
        PrescriptionsService,
        PaymentMethodsService,
        PaymentMethodDataService,
        PharmacyApiService,
        MedicalProfileService,
        ProgressBarService,
        AuthGuardService
       ]
    };
  }
}
