import { MonthRangeFilterOptions } from './../interfaces/claimsContent';
import { AfterContentInit, Component, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { ClaimsContent } from '../interfaces/claimsContent';
import { ClaimsList } from "../models/claimsList";
import { ClaimSummary, ClaimFilterWidgetState, FilterAttribues } from "../interfaces/claims";
import { DateUtility } from '../util/dateUtil';
import { NgForm } from '@angular/forms';
import { NgModel } from "@angular/forms";
import { OrderBy } from "@anthem/mbrportal/shared/pipes/orderBy";

 /**
 * @description
 * This component is used to display list of claims in the user selected date range, filter, serach input and exporting claims
 * 
 * @function
 * applyFilter(dateRange: NgForm): void
 * exportClaimClick(): void
 * handleOutsideClick(event: any): void
 * keypressHandlingEvent(event: KeyboardEvent): void
 * onClaimTypeChange(claimType: string): void
 * onDateRangeChange(month: number, dateIndex?: number): void
 * onProviderChange(providerName: string): void
 * removeFilterOption(): void
 * searchClaims(): void
 * toggleFilterPanel(expanded = true): void
 * validateDates(fromDateRange: NgModel, toDateRange: NgModel): void
 * 
 * @example
 * ```html
 * 	<data-sydmed-claims-filter-cmp [widgetState]="widgetFilterState" [filterPreviousState]="filterPreviousState"
				[allClaims]="allClaims" [claims]="exportClaimsList" [claimId]="claimId" [content]="content"
				[initClaimTypeOptions]="initialClaimTypes" [initProviderOptions]="initialProviders" [initStatusCodeOptions]="initialStatusCodes"
				(updateClaimFilter)="filterClaims($event)" (dateRangeUpdated)="onDateRangeUpdate($event)"
				(resetFilter)="resetFilter($event)"
				(searchClaim)="searchClaims($event)" ></data-sydmed-claims-filter-cmp>
 * ```
 */
@Component({
  selector: "data-sydmed-claims-filter-cmp",
  templateUrl: "./claimsFilter.html",
  host: {
    "(document:click)": "handleOutsideClick($event)",
  },
  styleUrls: ["../claimsSyd.scss"],
})
export class ClaimsFilterComponent implements AfterContentInit {
  @ViewChild("filterButton", { static: false })
  filterButton: ElementRef;

  @ViewChild("filterPanelContainer", { static: false })
  filterPanelContainer: ElementRef;

  @ViewChild('dateRangeForm', { static: false })
  dateRangeForm: NgForm;

  allUserClaims: ClaimSummary[];
  claimsDetailServiceError: any = {};
  claimFilter: ClaimFilterWidgetState = {
    date: 6,
    selectedClaimsType: [],
    selectedProvider: '',
    selectedStatuses: [],
  };
  claimTypes: FilterAttribues[] = [];
  dateRange: MonthRangeFilterOptions[] = [];
  displayFilter: boolean = false;
  elementRef: ElementRef;
  exportClaimsList: ClaimSummary[];
  fromMaxDate: Date;
  hasClaims: boolean = false;
  inMemClaimDetailCache: any = {};
  isClaimTypeSelected: boolean = false;
  isMonthRangeUpdated: boolean = false;
  isCustomDateUpdated: boolean = false;
  isInitialProviderOption: boolean = false;
  isProviderSelected: boolean = false;
  isStatusCodeSelected: boolean = false;
  providerModel: string = "";
  providers: FilterAttribues[] = [];
  providerSelectedFlag: boolean = false;
  selectedClaimsType: string[] = [];
  selectedDateIndex: number = 1;
  selectedStatuses: string[] = [];
  statusCodes: FilterAttribues[] = [];
  toMinDate: Date;
  today: Date;
  twoYearAgo: Date;

  @Input()
  set allClaims(allClaims: ClaimSummary[]) {
    this.allUserClaims = allClaims;
    this.claimsDetailServiceError = {};
    this.inMemClaimDetailCache = {};
  }

  @Input()
  content: ClaimsContent;

  @Input()
  set claims(claims: ClaimSummary[]) {
    this.exportClaimsList = claims;
    if (typeof claims !== "undefined" && claims !== null && claims.length > 0) {
      this.hasClaims = true;
    } else {
      this.hasClaims = false;
    }
  }

  @Input()
  claimId: string;

  @Input()
  set initClaimTypeOptions(options: FilterAttribues[]) {
    if (!this.isClaimTypeSelected) {
      this.claimsList.sortArray(
        options,
        this.content.filter.claimTypeCustomSortOn,
        this.content.filter.claimTypeCustomSortOrder
      );
      this.claimTypes = options;
    }
  }

  @Input()
  set initProviderOptions(options: FilterAttribues[]) {
    if (!this.isProviderSelected) {
      this.providers = this.orderBy.transform(options, ["+label"]);
    }
  }

  @Input()
  set initStatusCodeOptions(options: FilterAttribues[]) {
    if (!this.isStatusCodeSelected) {
      this.claimsList.sortArray(
        options,
        this.content.filter.claimStatusCustomSortOn,
        this.content.filter.claimStatusCustomSortOrder
      );
      this.statusCodes = options;
    }
  }

  @Input()
  set widgetState(state: ClaimFilterWidgetState) {
    if (state !== null) {
      this.claimFilter = state;
      if (this.claimFilter.selectedProvider) {
        this.providerModel = this.claimFilter.selectedProvider;
      }
    }
  }

  get widgetState() {
    return this.claimFilter;
  }

  @Input()
  filterPreviousState: ClaimFilterWidgetState;

  @Output()
  updateClaimFilter: EventEmitter<ClaimFilterWidgetState> = new EventEmitter<
    ClaimFilterWidgetState
  >();

  @Output()
  dateRangeUpdated: EventEmitter<ClaimFilterWidgetState> = new EventEmitter<
    ClaimFilterWidgetState
  >();

  @Output()
  resetFilter: EventEmitter<ClaimFilterWidgetState> = new EventEmitter<
    ClaimFilterWidgetState
  >();

  @Output()
  searchClaim: EventEmitter<string> = new EventEmitter<string>();

  private regex = new RegExp(
    "(0?[1-9]|1[012])/(0?[1-9]|[12][0-9]|3[01])/((19|20)\\d\\d)",
    "i"
  );

  constructor(
    private claimsList: ClaimsList,
    private dateUtil: DateUtility,
    private orderBy: OrderBy
  ) {}

  ngOnInit() {
    this.setDateRange();
  }

  /**
   *  @description Setting min and max date for date range
   */
  ngAfterContentInit() {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
    this.fromMaxDate = this.today;

    this.twoYearAgo = new Date();
    this.twoYearAgo.setHours(0, 0, 0, 0);
    this.twoYearAgo.setFullYear(this.twoYearAgo.getFullYear() - 2);
    this.toMinDate = new Date(this.claimFilter.fromDate);
  }

  /**
   * @description applyFilter() update claims display based on selected filters
   * @param {NgForm} dateRange
   * @returns {void}
   */
  applyFilter(dateRange: NgForm): void {
    //update claims display with selected filters
    const updateFilterState: ClaimFilterWidgetState = {
      date: dateRange.value,
      selectedClaimsType: this.selectedClaimsType,
      selectedProvider: this.claimFilter.selectedProvider
        ? this.claimFilter.selectedProvider
        : '',
      selectedStatuses: this.selectedStatuses,
    };

    if (this.isCustomDateUpdated || this.isMonthRangeUpdated) {
      if (this.isCustomDateUpdated) {
        this.selectedDateIndex = null;
        this.dateRangeUpdated.emit(updateFilterState);
      } else if (this.isMonthRangeUpdated) {
        updateFilterState.date = this.claimFilter.date;
        this.dateRangeUpdated.emit(updateFilterState);
      }
      this.claimFilter.date = updateFilterState.date;
    } else {
      this.updateClaimFilter.emit(updateFilterState);
    }
    this.isMonthRangeUpdated = false;
    this.toggleFilterPanel(false);
  }

  /**
   * @description exportClaimClick() Handle Export claims button click
   * @returns {void}
   */
  /**  */
  exportClaimClick(): void {
    //Show me the Math section is being hidden for Medical claims as part of SYDMED-9364. All amounts except Amount Billed and Your Cost will be displayed as N/A.
    //In future, to display the values, we need to set enableShowMeTheMath to true in the content
    this.claimsList.downloadClaims(
      this.exportClaimsList,
      this.content,
      this.content.filter.exportFileName,
      this.content.detail.enableShowMeTheMath
    );
  }

  /**
   * @description handleOutsideClick() close the filter panel if user
   * clicks anywhere on the page other than the panel
   * @param {any} event
   * {void}
   */
  handleOutsideClick(event: any): void {
    const clickedComponent: any = event.target || event.srcElement;
    const clickedInside: boolean = this.filterPanelContainer?.nativeElement?.contains(
      clickedComponent
    );
    if (
      !this.filterButton?.nativeElement.contains(clickedComponent) &&
      !clickedInside &&
      !clickedComponent.classList.contains("ui-corner-all") &&
      !document.getElementById("ui-datepicker-div")?.contains(clickedComponent)
    ) {
      this.displayFilter = false;
    }
  }

  /**
   * @description keypressHandlingEvent() responsible for calling search claim on key press event
   * @param {KeyboardEvent} event
   * @returns {void}
   */
  keypressHandlingEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.searchClaims();
    }
  }

  /**
   * @description onClaimTypeChange() Responsble for updating claim list based on claim types selected
   * @param {string} claimType
   * @returns {void}
   */
  onClaimTypeChange(claimType: string): void {
    const claimTypeIndex = this.selectedClaimsType.indexOf(claimType);
    if (claimTypeIndex === -1) {
      this.selectedClaimsType.push(claimType);
    } else {
      this.selectedClaimsType.splice(claimTypeIndex, 1);
    }

    this.isClaimTypeSelected = this.selectedClaimsType.length > 0;
    this.claimFilter.selectedClaimsType = this.selectedClaimsType;
  }

  /**
   * @description onClaimStatusChange() Responsble for updating claim list based on claim status selected
   * @param {string} statusCode
   * @returns {void}
   */
  onClaimStatusChange(statusCode: string) {
    const claimStatusIndex = this.selectedStatuses.indexOf(statusCode);
    if (claimStatusIndex === -1) {
      this.selectedStatuses.push(statusCode);
    } else {
      this.selectedStatuses.splice(claimStatusIndex, 1);
    }
    this.isStatusCodeSelected = this.selectedStatuses.length > 0;
    this.claimFilter.selectedStatuses = this.selectedStatuses;
  }

  /**
   * @description onDateRangeChange() update selected Date Range filter
   * @param {number} month
   * @param {number} [dateIndex]
   * @returns {void}
   */
  onDateRangeChange(month: number, dateIndex?: number): void {
    //handle selected date range
    if (this.claimFilter.date !== month) {
      let todayDate: Date = new Date();
      this.dateRangeForm.form.get('toDateRange').setValue(this.dateUtil.getDatePart(todayDate, "MM/dd/yyyy"));
      todayDate.setMonth(todayDate.getMonth() - month);
      //Leap year adjustment
      if (todayDate.getDate() === 29 && todayDate.getMonth() === 2) {
        todayDate.setDate(28);
      }
      this.dateRangeForm.form.get('fromDateRange').setValue(this.dateUtil.getDatePart(todayDate, "MM/dd/yyyy"));

      this.claimFilter.date = month;
      this.selectedDateIndex = dateIndex;
      this.isMonthRangeUpdated = true;
      this.isCustomDateUpdated = false;
    }
  }

  /**
   * @description onProviderChange() Responsble for updating claim list based on provider selected
   * @param {string} providerId
   * @return {void}
   */
  onProviderChange(providerName: string): void {
    this.claimFilter.selectedProvider = providerName;
    this.providerSelectedFlag = true;
  }

  /**
   * @description removeFilterOption() Removes all the filter options and call parent component
   * @returns {void}
   */
  removeFilterOption(): void {
    this.claimId = '';
    this.providerSelectedFlag = false;
    this.isClaimTypeSelected = false;
    this.isStatusCodeSelected = false;
    this.selectedClaimsType = [];
    this.selectedStatuses = [];
    this.providerModel = null;
    this.selectedDateIndex = 1;


    const startDate: Date = new Date();
    startDate.setMonth(
      startDate.getMonth() - this.content.filter.defaultDateRangeMonth
    );
    this.claimFilter = {
      date: this.claimFilter.date,
      fromDate: this.dateUtil.getDatePart(startDate, "MM/dd/yyyy"),
      selectedClaimsType: [],
      selectedProvider: "",
      selectedStatuses: [],
      toDate: this.dateUtil.getDatePart(new Date(), "MM/dd/yyyy"),
    };
    this.resetFilter.emit(this.claimFilter);
    this.displayFilter = false;
  }

  /**
   * @description searchClaims() responsible for emitting search event
   * @returns {void}
   */
  searchClaims(): void {
    this.searchClaim.emit(this.claimId);
  }

  /**
   * @description toggleFilterPanel() show/hide filter panel on click
   * @param {boolean} [expanded = true]
   * @returns {void}
   */
  toggleFilterPanel(expanded = true): void {
    this.claimId = '';
    if (this.displayFilter && expanded) {
      if (this.filterPreviousState) {
        let previousState: ClaimFilterWidgetState = {
          ...this.filterPreviousState,
        };
        this.claimFilter = previousState;
        this.selectedDateIndex = this.dateRange.findIndex(
          (date) => date.value === previousState.date
        );
        //TODO: Need to handle claim type and status reset correctly
        this.selectedStatuses = previousState.selectedStatuses?.length
          ? previousState.selectedStatuses
          : [];
        this.selectedClaimsType = previousState.selectedClaimsType?.length
          ? previousState.selectedClaimsType
          : [];
        this.providerModel = previousState.selectedProvider;
      }
    }
    this.displayFilter = !this.displayFilter;

    if (!this.displayFilter) {
      this.filterButton.nativeElement.focus();
    }
  }

  /**
   * @description validateDates() validates claim date range based on from date and to date model
   * @param {NgModel} fromDateRange from date
   * @param {NgModel} toDateRange to date
   * @returns {void}
   */
  validateDates(fromDateRange: NgModel, toDateRange: NgModel): void {
    fromDateRange.control.setErrors(null);
    toDateRange.control.setErrors(null);

    const fromDateString = this.changeYearFormat(fromDateRange.model);
    const fromDate = new Date(fromDateString);
    const toDateString = this.changeYearFormat(toDateRange.model);
    const toDate = new Date(toDateString);

    if (!this.isValidDate(fromDateString, fromDate)) {
      fromDateRange.control.setErrors({ invalidDate: true });
    } else if (!this.isValidDate(toDateString, toDate)) {
      toDateRange.control.setErrors({ invalidDate: true });
    }
    
    if (fromDateRange.valid && toDateRange.valid) {
      if (fromDate > this.today) {
        fromDateRange.control.setErrors({ futureDate: true });
      } else if (fromDate > toDate && fromDate <= this.today) {
        fromDateRange.control.setErrors({ fromDateAfterToDate: true });
      } else if (fromDate < this.twoYearAgo) {
        fromDateRange.control.setErrors({ fromDateBeyondTwoYears: true });
      } else if (toDate > this.today) {
        toDateRange.control.setErrors({ futureToDate: true });
      } else if (fromDate > toDate && fromDate < this.today) {
        toDateRange.control.setErrors({ toDateBeforeFromDate: true });
      }
    }

    if (fromDateRange.valid && toDateRange.valid) {
      this.isCustomDateUpdated = true;
      this.isMonthRangeUpdated = false;
    }

    // Setting min date for to date range
    if (fromDate > this.twoYearAgo) {
      this.toMinDate = fromDate;
    } else {
      this.toMinDate = this.twoYearAgo;
    }

    // Setting max date for from date range
    if (toDate < this.today) {
      this.fromMaxDate = toDate;
    } else {
      this.fromMaxDate = this.today;
    }
  }

  /**
   * @description changeYearFormat() Responsble for converting YY format to YYYY based on date string passed
   * @param {string} [value]
   * @returns {string}
   */
  private changeYearFormat(value?: string): string {
    let val = (value || "").split("/");

    if (val.length === 3) {
      const yy = val[2];

      if (yy.length === 2) {
        const newdate = parseInt(yy) < 90 ? "20" + yy : "19" + yy;
        value = val[0] + "/" + val[1] + "/" + newdate;
      }
    }
    return value;
  }

  /**
   * @description isValidDate() Responsble for checking date format and leap year based on date string and Date passed
   * @param {string} input
   * @param {Date} date
   * @returns {boolean}
   */
  private isValidDate(input: string, date: Date): boolean {
    const split = input.split("/");
    return (
      this.regex.test(input) &&
      date.getMonth() + 1 === +split[0] &&
      date.getDate() === +split[1] &&
      date.getFullYear() === +split[2]
    );
  }

  /**
   * @description setDateRange() Responsble for setting date range from contebt to class variable
   * @returns {void}
   */
  private setDateRange(): void {
    this.dateRange = this.content.filter.monthRangeFilters;
  }
}
