<div class="uxd-theme-motif container">
    <div class="width-100 margin-bottom-40" *ngIf="aslError && !showPasswordSuccessView" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <span>{{aslError}}</span>
    </div>
    <div class="max-width-elements">
        <div class="text-left padding-bottom-48" *ngIf="!showPasswordSuccessView">
            <div class="margin-top-20 reg-top-1 margin-bottom-48 flex-column-center">
                <h1 tabindex="-1" class="font-32 text-left">{{jsonLabels?.Page_Heading}}</h1>
            </div>

            <form #pwdForm="ngForm" [formGroup]="resetTempPasswordForm" (ngSubmit)="updatePassword()" class="reset-temp-password-container">
                <div class="margin-bottom-48">
                    <div class="form-item">
                        <label for="tempPassword" class="font-bold font-16">{{jsonLabels?.Field_Labels.Temp_Password}}</label>
                        <div class="mask-wrapper margin-bottom-40">
                            <input class="ant-text-input" id="tempPassword"
                                aria-describedby="temp-password-errors" aria-required="true" #input
                                #temppwdMask #temppwd formControlName="tempPassword" name="tempPassword"
                                data-tcp-password-valid-dir type="password" required aria-describedby="temporaryPasswordError"/>
                            <span id="temppwd-mask" class="align-eye" data-uxd-security-mask-cmp 
                                [definition]="{maskedInput: temppwdMask, ariaLabelMasked: ('Unmask '+jsonLabels?.Field_Labels.Temp_Password), ariaLabelUnMasked: ('Mask '+jsonLabels?.Field_Labels.Temp_Password)}"></span>
                                    <ul id="temporaryPasswordError" class="margin-top-8 font-12 error"
                                        *ngIf="(resetTempPasswordForm.controls['tempPassword']?.touched)  && resetTempPasswordForm.controls['tempPassword']?.invalid" aria-live="polite">
                                        <li *ngIf="resetTempPasswordForm.controls['tempPassword']?.errors?.required">
                                            <span class="fas fa-times"></span>
                                            <span>{{jsonLabels?.Field_Labels.Temp_Password}}</span>
                                            <span>{{jsonErrors.Required_err}}</span>
                                        </li>
                                    </ul>
                        </div>
                        <div class="margin-bottom-48">
                            <lib-passwords-form formControlName="passwords"
                            [pwdLabel]="jsonLabels?.Field_Labels.New_Password"
                            [confirmPwdLabel]="jsonLabels?.Field_Labels.ReType_Password" [errObj]="jsonErrors"
                            [toolTipObj]="jsonLabels?.Helper_Text.Password.Content.PasswordTooltip"
                            [formSubmitted]="(pwdForm.submitted)" [userName]="userName" #passwordId>
                        </lib-passwords-form>
                        </div>

                        <div class="text-center max-width-elements">
                            <div class="flex-horizontal-end">
                                <button type="submit" class="btn btn-primary"
                                data-analytics="updateTempLogin">{{jsonLabels?.Buttons.Update}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="showPasswordSuccessView"
            class="margin-top-48 margin-bottom-48">
            <h2 class="text-left font-30 margin-bottom-48">{{jsonLabels?.Confirmation.Page_Heading}}</h2>
            <div class="flex-horizontal-end">
                <button (click)="goHome()" class="btn btn-primary alt-btn" data-analytics="loginTempLogin">{{jsonLabels?.Confirmation.Continue_Button.Text}}</button>
            </div>
        </div>
    </div>
</div>