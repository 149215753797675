import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from './app.component';
import { CallUsComponent } from './call-us/call-us.component';
import { MessageUsComponent } from './message-us/message-us.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { CallMeComponent } from "./call-me/call-me.component";
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
  { path: "secure/contact-us", redirectTo: "secure/contact-us", pathMatch: "full"},
  {
    path: 'secure/contact-us',
    component: AppComponent,
    data: { moduleId: "json_my-profile_id" },
    canActivate: [AuthGuardService],    
    children: [
      {
      path:  'call-us',
      component:  CallUsComponent,
      data: {
        emergencyAlert: true
      },
      canActivate: [AuthGuardService],
      },
      {
        path: 'call-us/call-me',
        component: CallMeComponent,
        data: {
          emergencyAlert: false
        },
        canActivate: [AuthGuardService],
      },
      {
      path:  'message-us',
      component:  MessageUsComponent,
      data: {
        emergencyAlert: true
      },
      canActivate: [AuthGuardService],
      },
      {
      path:  'live-chat',
      component:  LiveChatComponent,
        data: {
          emergencyAlert: true
        },
      canActivate: [AuthGuardService],
      }
    ]
  }
  
];

@NgModule({
  imports: [
    
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
