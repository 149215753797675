<h2 class="margin-top-30 margin-bottom-30">{{labels.title}}</h2>
<p class="font-14" [innerHtml]="labels.description"></p>

<!-- Table of content -->
<section class="table-of-content padding-left-10 margin-bottom-50">
    <div class="table-of-content_questions">
        <h4 class="margin-top-30 margin-bottom-30">{{labels.tableOfContentTitle}}</h4>
        <!-- list of questions -->
        <ul class="margin-0">
            <li class="list-style-type-none" *ngFor="let list of labels.tableOfContent">
                <a class="font-14" fragment="list.id" (click)="benefitsService.scrollToComponent(list.id, 200)">{{list.question}}</a>
            </li>
        </ul>
    </div>
    <div class="table-of-content_questions__description" *ngFor="let content of labels.tableOfContent" [id]="content.id">
        <!-- question and description -->
        <h4 class="margin-top-30 margin-bottom-30">{{ content.question }}</h4>
        <p class="font-14">{{ content.description }}</p>
        <ul *ngIf="content?.listItem && content?.listItem?.length">
            <li *ngFor="let list of content.listItem">{{list}}</li>
        </ul>
        <p *ngIf="content?.description" class="font-14">{{ content.description2 }}</p>
    </div>
</section>
