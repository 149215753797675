import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { Restriction } from 'gbd-models';


@Component({
  selector: 'app-enter-security-code',
  templateUrl: './enter-security-code.component.html',
  styleUrls: ['./enter-security-code.component.css']
})
export class EnterSecurityCodeComponent implements OnInit {

  constructor(
    private router: Router,
    private jsonSvc: JsonContentService
  ) { }

  GetSecurityCodeForm: FormGroup;
  public jsonLabels;
  public jsonErrors;
  public endpoint = UrlProperties.UrlProperties.endPoints.login.validateOTP;

  ngOnInit(): void {
    this.jsonSvc.getJSON('login').subscribe(data => {
      this.jsonLabels = data.LoginMFA.Labels.Enter_Security_Code;
      this.jsonErrors = data.LoginMFA.ErrorMsgs;
    });
  }

  public goToSecureLogin(response) {
    window.sessionStorage.setItem('sydMedMemberContext', JSON.stringify(response.body));
    window.sessionStorage.setItem('sydMedLoggedIn', 'true');
    window.sessionStorage.setItem('gbd-token', response.headers.get('gbd-token'));
    if (response && response.body && response?.body?.restrictions?.find(restriction => restriction === Restriction.CAREGIVER_REATTEST)) {
      this.router.navigate(['/public/login/caregiver-attestation']);
    } else {
      this.router.navigate(['/public/login/secure-login']);
    }
    
  }

  public goToSecurityQuestionsPage() {
    this.router.navigate(['/public/login/get-security-code']);
  }

}
