import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

import { ForgotUsernamePasswordDataService } from '../app.data.service';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { RegistrationService } from '../../registration/registration.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private dataSvc: ForgotUsernamePasswordDataService,
    private readonly formBuilder: FormBuilder,
    private router: Router,
    private errCodeSvc: ErrorCodeMapperService,
    private http: HttpClientService,
    private jsonSvc: JsonContentService,
    private regSvc: RegistrationService
  ) { }

  passwordForm: FormGroup;
  public showNew = false;
  public showConfirm = false;
  public jsonLabels;
  public jsonErrors;
  public aslError = '';
  private httpOptions;
  public username: string;
  public loading: boolean = false;

  ngOnInit(): void {
    this.loading = true;
    this.jsonSvc.getJSON('forgot-username-password-mfa').subscribe(data => {
      this.jsonLabels = data.ForgotUsernamePasswordMFA.Labels.Change_Password;
      this.jsonErrors = data.ForgotUsernamePasswordMFA.ErrorMsgs;
    });
    this.regSvc.decrypt({token: sessionStorage.getItem('username')}).then(res => {
      this.username = res.body;
      if (this.username.charAt(0) === '~') {
        this.username = this.username.substring(1);
      }
      this.loading = false;
    });
    this.passwordForm = this.formBuilder.group({
      passwords: []
    })
  }

  resetPassword() {
    if (this.passwordForm.valid) {
      let passwordParams = this.dataSvc.transformPasswordParams(this.passwordForm.controls['passwords'].value.password);
      const url = UrlProperties.UrlProperties.endPoints.forgotUsernamePassword.resetPassword;

      this.http.postObservable(url, passwordParams).subscribe((res: any) => {
          this.router.navigate(['public/forgot-username-or-password/confirmation'])
      }, error => {
        this.aslError = this.errCodeSvc.errorCodeMapper(error, this.jsonErrors);
      });
    } else {
      this.passwordForm.markAllAsTouched();
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus(); 
    }
  }
}
