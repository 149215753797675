<div id="grievance_id" class="uxd-theme-motif container" *ngIf="formLabels">
  <h2 class="section-header-two" [innerHtml]="formLabels?.Header"></h2>
  
  <br>
  <p [innerHtml]="formLabels?.Intro1"></p>
  <br>
  <p [innerHtml]="formLabels?.Intro2"></p>
  <br>

  <button type="button" id="ReturnToProfile_btn" class="btn" [innerHtml]="formLabels?.ReturnToProfile_btn" (click)="returnToProfile()"></button>
  <br>
  
  <br>
  <h3 [innerHtml]="formLabels?.IntroHeader"></h3>
  <br>
  <p [innerHtml]="formLabels?.Intro3"></p>
  <br>

  <form [formGroup]="grievanceForm" (ngSubmit)="previewForm()" libInvalidInputFocus>
    <div class="ctrlHolder">
      <label for="memberName" [innerHtml]="formLabels?.Name"></label>
      <input id="memberName" name="memberName" type="text" formControlName="memberName" class="ant-text-input">

      <div class="error-list" *ngIf="(memberName.dirty || memberName.touched) && memberName.invalid">
        <div *ngIf="memberName.errors.required || memberName.errors.whitespaces || memberName.errors.invalidnamechar">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="formLabels?.Name"></span>
              <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="ctrlHolder">
      <label for="memberId" [innerHtml]="formLabels?.MemberId"></label>
      <input id="memberId" name="memberId" type="text" formControlName="memberId" class="ant-text-input">

      <div class="error-list" *ngIf="(memberId.dirty || memberId.touched) && memberId.invalid">
        <div *ngIf="memberId.errors.required || memberId.errors.whitespaces">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="formLabels?.MemberId"></span>
              <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="ctrlHolder">
      <label for="memberDob" [innerHtml]="formLabels?.MemberDob"></label>
      <input id="memberDob" name="memberDob" type="text" formControlName="memberDob" class="ant-text-input" libDateFormat [preValue]="grievanceForm.value.memberDob" maxlength="10">

      <div class="error-list" *ngIf="(memberDob.dirty || memberDob.touched) && memberDob.invalid">
        <div *ngIf="memberDob.errors.required">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="formLabels?.MemberDob"></span>
              <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
            </span>
          </span>
        </div>
        <div *ngIf="memberDob.errors.invalidDate">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="errorMsgs.Date_Format_err"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="radio-btn-group" [(ngModel)]="contactRadioVal" (ngModelChange)="contactValChanged()" [ngModelOptions]="{standalone: true}" name="contactRadioValue" data-uxd-radio-input-group-cmp [radioGroup]="contactRadioGroup"></div>

    <br>

    <div class="ctrlHolder">
      <label for="emailAddress" [innerHtml]="formLabels?.EmailAddress"></label>
      <input id="emailAddress" name="email" formControlName="email" type="text" class="ant-text-input">

      <div class="error-list" *ngIf="(email.dirty || email.touched) && email.invalid">
        <div *ngIf="email.errors.required">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="formLabels?.EmailAddress"></span>
              <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
            </span>
          </span>
        </div>
        <div *ngIf="email.errors.invalidEmail">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="errorMsgs.Email_Format_err"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="ctrlHolder">
      <label for="phoneNumber" [innerHtml]="formLabels?.PhoneNumber"></label>
      <input id="phoneNumber" formControlName = "phoneNumber" name="phoneNumber" type="text" class="ant-text-input" libPhoneFormat [preValue]="grievanceForm.value.phoneNumber" maxlength="12">
      <div class="error-list" *ngIf="(phoneNumber.dirty || phoneNumber.touched) && phoneNumber.invalid">
        <div *ngIf="phoneNumber.errors.required">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="formLabels?.PhoneNumber"></span>
              <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
            </span>
          </span>
        </div>
        <div *ngIf="phoneNumber.errors.invalidPhone">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="errorMsgs.PhoneFormat"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="radio-btn-group" [(ngModel)]="grievanceForRadioVal" (ngModelChange)="grievanceForValChanged()" [ngModelOptions]="{standalone: true}" name="grievanceForRadioValue" data-uxd-radio-input-group-cmp [radioGroup]="grievanceForRadioGroup"></div>

    <br>

    <div class="ctrlHolder">
      <label for="grievanceForPerson" [innerHtml]="formLabels?.OtherName"></label>
      <input id="grievanceForPerson" formControlName="grievanceForPerson" name="grievanceForPerson" type="text" class="ant-text-input">

      <div class="error-list" *ngIf="(grievanceForPerson.dirty || grievanceForPerson.touched) && grievanceForPerson.invalid">
        <div *ngIf="grievanceForPerson.errors.required || grievanceForPerson.errors.whitespaces || grievanceForPerson.errors.invalidnamechar">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="formLabels?.OtherName"></span>
              <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="ctrlHolder">
      <label for="claimNumber">{{ formLabels?.ClaimNumber }} <span class="optional">{{ formLabels?.Optional }}</span></label>
      <input id="claimNumber" name="claimNumber" type="text" formControlName="claimNumber" class="ant-text-input">
    </div>

    <br>

    <div class="ctrlHolder">
      <label for="providerName">{{ formLabels?.Doctor }} <span class="optional">{{ formLabels?.Optional }}</span></label>
      <input id="providerName" name="providerName" type="text" formControlName="providerName" class="ant-text-input">
    </div>

    <br>

    <div class="ctrlHolder">
      <label for="dateOfService">{{ formLabels?.ServiceDate }} <span class="optional">{{ formLabels?.Optional }}</span></label>
      <input id="dateOfService" name="dateOfService" type="text" formControlName="dateOfService" class="ant-text-input" libDateFormat [preValue]="grievanceForm.value.dateOfService" maxlength="10">

      <div class="error-list" *ngIf="(dateOfService.dirty || dateOfService.touched) && dateOfService.invalid">
        <div *ngIf="dateOfService.errors.invalidDate">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="errorMsgs.Date_Format_err"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="ctrlHolder bigger">
      <label for="grievanceDetail">{{ formLabels?.MoreInformation }} <span class="optional">{{ formLabels?.Optional }}</span></label>
      <textarea id="grievanceDetail" name="grievanceDetail" formControlName="grievanceDetail" maxlength="2000"></textarea>

      <div class="error-list" *ngIf="(grievanceDetail.dirty || grievanceDetail.touched) && grievanceDetail.invalid">
        <div *ngIf="grievanceDetail.errors.maxlength">
          <span class="error"><i class="motif-icon motif-delete"></i>
            <span class="motif-margin-left-5">
              <span class="errorMessage" [innerHtml]="errorMsgs.Additional_info_err"></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <br>

    <div class="button-group text-right">
      <button type="button" id="Cancel_btn" class="btn btn-secondary" [innerHtml]="formLabels?.Cancel_btn" (click)="cancelForm()"></button>
      <button type="submit" id="Sent_btn" class="btn" [innerHtml]="formLabels?.Next_btn"></button>
    </div>
  </form>
  <br><br>
  <p class="{{stateLob === 'CAMCD' ? 'grievanceDisclaimer' : '' }}">{{ formLabels?.DisclaimerPt1 }}<a class="{{stateLob === 'CAMCD' ? 'grievanceDisclaimer-link' : '' }}" href="{{ formLabels?.DisclaimerUrl }}" target="_blank">{{ formLabels?.DisclaimerUrlText }}</a>{{ formLabels?.DisclaimerPt2 }}</p>
  <br><br>
</div>