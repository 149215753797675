import { BrowserModule } from "@angular/platform-browser";
import { APP_BASE_HREF } from "@angular/common";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { UpgradeModule } from "@angular/upgrade/static";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PublicModule } from "./public/public.module";
import { RegistrationModule } from "./public/registration/app.module";
import { LoginModule } from "./public/login/login.module";
import { ShmMyProfileModule } from "./secure/shm-my-profile/shm-my-profile.module";
import { ContactUsModule } from "./secure/contact-us/contact-us.module";
import { SupportFormsModule } from "./secure/support-forms/support-forms.module";
import { ForgotUsernamePasswordModule } from "./public/forgot-username-password/forgot-username-password.module";
import { DashboardModule } from "./secure/dashboard/dashboard.module";
import { SecureFooterComponent } from "./sydmed-shared/secure-footer/secure-footer.component";
import { SecureHeaderComponent } from "./sydmed-shared/secure-header/secure-header.component";
import { SecureNavigationComponent } from "./sydmed-shared/secure-navigation/secure-navigation.component";
import { MessageCenterModule } from "./secure/message-center/message-center.module";
import { PharmacyModule } from "./secure/pharmacy/pharmacy.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MbrportalRootModule } from "./mbrportal-dependencies/mbrportal.root.module";
import { ChatModule } from "./secure/chat/chat.module";
import { PatientPayModule } from './secure/patient-pay/patient-pay.module';
import { OnlineGrievancesModule } from './secure/online-grievances/online-grievances.module';
import { FormsModule } from "@angular/forms";
import { PCPModule } from "./secure/pcp/pcpModule";
import { CareTeamModule } from "./secure/care-team/care-team.module";
import { IdCardsModule } from "./secure/idcards/idcardsModule";
import { SecureSsoModule } from "./secure/sso/sso.module";
import { TextTermsAndConditionsModule } from "./secure/text-terms-and-conditions/text-terms-and-conditions.module";
import { AlertHelper, AlertModule } from "@anthem/uxd/alert";
import { MyFhrModule } from "./secure/myfhr/myFhrModule";
import { AuthGuardService } from "sydmed/libs/authorization/route-guard.service";
import { AuthorizationService } from "sydmed/libs/authorization/authorization.service";
import { PaymentPortalModule } from "./secure/payment-portal/payment-portal.module";
import { EmulationModule } from "./secure/emulation/emulation.module";
import { PowerAccountModule } from './secure/power-account/power-account.module';
import { CostShareModule } from './secure/cost-share/cost-share.module';
import { TrizettoCostCalculatorModule } from './secure/trizetto-cost-calculator/trizetto-cost-calculator.module';
import { ThirdPartyInsuranceModule } from './secure/third-party-insurance/third-party-insurance.module';
import { PriorAuthorizationModule } from './secure/prior-authorization/prior-authorization.module';
import { CommunityResourcesModule } from './secure/community-resources/community-resources.module';
import { AppealsAndGrievancesModule } from './secure/appeals-and-grievances/appeals-and-grievances.module';
import { DeleteAccountConfirmModule } from "./public/delete-account-confirm/delete-account-confirm.module";
import { MaintenanceModule } from "./public/maintenance/maintenance.module";
import { ClaimsModule } from "./secure/claims/claimsModule";
import { AhdPharmacyModule } from "./secure/ahd-pharmacy/ahd-pharmacy.module";
import { FindCareModule } from "./secure/find-care/find-care.module";
import { AppointmentAssistanceModule } from "./secure/appointment-assistance/appoinment-assistance.module";
import { BenefitsModule } from "sydmed/src/app/secure/benefits/benefits.module";
import { NewDashboardModule } from "./secure/new-dashboard/new-dashboard.module";
import { DocumentHubModule } from "./secure/document-hub/document-hub.module";
@NgModule({
  declarations: [
    AppComponent,
    SecureFooterComponent,
    SecureHeaderComponent,
    SecureNavigationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BenefitsModule,
    ClaimsModule,
    CostShareModule,
    DeleteAccountConfirmModule,
    MaintenanceModule,
    PublicModule,
    UpgradeModule,
    RegistrationModule,
    LoginModule,
    MessageCenterModule,
    ThirdPartyInsuranceModule,
    TrizettoCostCalculatorModule,
    PriorAuthorizationModule,
    ShmMyProfileModule,
    ContactUsModule,
    ForgotUsernamePasswordModule,
    DashboardModule,
    MbrportalRootModule,
    IdCardsModule,
    MyFhrModule,
    PharmacyModule.forRoot(),
    ChatModule,
    FormsModule,
    CareTeamModule,
    FindCareModule,
    SecureSsoModule,
    PCPModule,
    AlertModule,
    TextTermsAndConditionsModule,
    SupportFormsModule,
    PaymentPortalModule,
    EmulationModule,
    PowerAccountModule,
    PatientPayModule,
    OnlineGrievancesModule,
    AppealsAndGrievancesModule,
    CommunityResourcesModule,
    AppointmentAssistanceModule,
    AhdPharmacyModule,
    NewDashboardModule,
    DocumentHubModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientService, multi: true },
    { provide: APP_BASE_HREF, useValue: "/" },
    InterCommunicationService,
    AlertHelper,
    AuthGuardService,
    AuthorizationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
