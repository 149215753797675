export enum channelTypeEnum {
  ASYNC = 'ASYNC',
  SYNC = 'SYNC'
}

export enum ChatTranscriptFormatEnum {
  BLOCKKIT = 'BLOCKKIT',
  STANDARD = 'STANDARD'
}

export enum ClaimStatusEnum {
  APPROVED = 'Approved',
  DENIED = 'Denied',
  PAID = 'Paid',
  PENDING = 'Pending'
}

export enum conversationStateEnum {
  AGENT_JOINED = 'AGENT_JOINED',
  AGENT_LEFT = 'AGENT_LEFT',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
  SURVEY_END = 'SURVEY_END',
  SURVEY_START = 'SURVEY_START',
  TYPING_STARTED = 'TYPINGSTARTED',
  TYPING_STOPPED = 'TYPINGSTOPPED'
}

export enum interactionSrcEnum {
  MOBILE = 'MOBILE',
  VIDEO_IVR = 'VIVR',
  WEB = 'WEB'
}

export enum MessageTypeEnum {
  AGENT = 'AGENT',
  BOT = 'BOT',
  PRE_AGENT = 'PRE_AGENT',
  SURVEY = 'SURVEY',
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}

export enum postConversationSurveyEnum {
  CLOSE = 'CLOSE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  OPEN = 'OPEN'
}

export enum SetFieldStatusEnum {
  CLOSE = 'CLOSE',
  DISCONNECT = 'DISCONNECT',
  OPEN = 'OPEN',
  OUTSIDEHOURS = 'OUTSIDEHOURS',
  TRANSCRIPTSENT = 'TRANSCRIPTSENT'
}

export enum targetedSrcEnum {
  BOT = 'BOT',
  LIVEAGENT = 'LIVEAGENT'
}

export enum targetedVendorSrcEnum {
  GENESYS = 'GENESYS',
  LP = 'LP',
  RASA = 'RASA',
  WATSON = 'WATSON'
}

export enum TypeOfOptionEnum {
  ACCORDIAN = 'accordian',
  AGENT_CONVERSATION = 'agentConversation',
  BUBBLE = 'bubble',
  BUBBLE_TEXT = 'bubble_text',
  CONVERSATION_START = 'conversationStart',
  INLINE_CLAIMS = 'InlineClaims',
  INLINE_EOB = 'inlineEob',
  INLINE_IDCARD = 'inlineIdcard',
  INLINE_SYDNEY_BANNER = 'inlineSydneyBanner',
  RENDER_IN_MODAL = 'renderInModal',
  TEXT = 'text',
  ERROR_TEXT = 'error_text',
}