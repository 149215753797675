<div class="transportation-benefits">
    <h2 class="section-header-two">{{content.title}}</h2>
    <div class="description">
        <p [innerHTML]="content.short"></p>
        <ng-container *ngFor="let item of content.instructions">
            <p class="instruction" [innerHTML]="item" [attr.data-analytics]="content.analytics.transportationPlanLink"></p>
        </ng-container>
        
    </div>
    
</div>