import { MemberType } from 'gbd-models';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import {
  PhoneVerificationDesignee,
  ErrorMsgs,
  Loaders,
} from 'sydmed/src/app/secure/shm-my-profile/models/labels';
import { ICheckboxInput } from '@anthem/uxd/util';
import { MemberRepRegistrationService } from '../service/memberRepRegistration.service';
import {
  AlertService,
  UniversalAlertType,
  InlineAlertContainerComponent,
} from '@anthem/uxd/alert';
import { RepresentativeSendOtpRequest } from 'gbd-models';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service';
import { MemberRepSession } from '../service/memberRepSession.service';
import { SessionStorage, StorageService } from 'sydmed/libs/storage-service/src/public-api';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss'],
})
export class PhoneVerificationComponent implements OnInit {
  public errorMessage: string;
  public errorMessages: ErrorMsgs;
  public labels: PhoneVerificationDesignee;
  public loaders: Loaders;
  public codeResent: boolean = false;
  public isCaregiver: boolean = false;
  public loading: boolean = false;
  public showPhoneVerificationPage: boolean = false;
  public alertPosition: UniversalAlertType = UniversalAlertType.UNIVERSAL;
  validatePhoneForm: FormGroup;
  alreadyHaveACode: string;
  @ViewChild('memberRepSendOtpAlert')
  memberRepSendOtpAlert: InlineAlertContainerComponent;
  memberType: string;
  public checkbox: ICheckboxInput = {
    id: 'ux-guide-checkbox-1',
    name: 'agreeDesignee',
    label: '',
    isRequired: true,
    trueValue: true,
    falseValue: false,
  };
  public checkbox1: ICheckboxInput = {
    id: 'ux-guide-checkbox-2',
    name: 'agreeDesignee2',
    label: '',
    isRequired: true,
    trueValue: true,
    falseValue: false,
  };

  constructor(
    private alertService: AlertService,
    private errorCodeMapperService: ErrorCodeMapperService,
    private fb: FormBuilder,
    private router: Router,
    private jsonSvc: JsonContentService,
    private memberRepRegistrationService: MemberRepRegistrationService,
    private memberRepSession: MemberRepSession,
    private storage: StorageService
  ) {}

  ngOnInit(): void {
    this.showPhoneVerificationPage = !!this.memberRepSession.getMemberRepToken()
    // Need to Move this to Validate token page while integration and pull the value from service
    this.memberType = this.memberRepSession.getMemberType();
    this.setLabels();
    this.setFormDetails();
  }

  ngAfterViewInit(): void {
    if(this.memberType === MemberType.CAREGIVER && sessionStorage.getItem('alreadyHaveSecurityCode') === 'true'){
      this.isCaregiver = true;
    }
  }
  setLabels = () => {
    this.jsonSvc.getJSON('member-representative').subscribe((jsonResults) => {
      const { Labels } = jsonResults?.MemberRepRegistration;
      this.errorMessages = jsonResults.MemberRepRegistration.ErrorMsgs;
      this.labels = Labels.Phone_Verification;
      this.loaders = Labels.MemberRep_Registration.Loaders;
      this.alreadyHaveACode = Labels.Already_Have_A_Code;
    });
  };

  setFormDetails() {
    this.validatePhoneForm = this.fb.group({
      textNumber: [],
      agreeInfoDesignee: [false, [Validators.requiredTrue]],
    });
  }

  /**
   * @Description sendOtp method used to send OTP number to respective phone numbers
   * @returns void
   */
  sendOtp(form: FormGroup): void {
    if (form.valid) {
      const requestBody: RepresentativeSendOtpRequest = {
        linkToken: sessionStorage.getItem('memberRepToken'),
        phone: form?.value?.textNumber?.phone
      };      
      this.loading = true;
      if (this.errorMessage) {
        this.clearAlert(this.memberRepSendOtpAlert);
      }
      this.memberRepRegistrationService.sendOtp(requestBody).subscribe(
        (res) => {
          this.loading = false;
          this.storage.setSessionItem({key: SessionStorage.REGISTERED_PHONE_NUMBER, value: form.value.textNumber.phone});
          this.router.navigate([
            'public/registration/member-representative/security-code',
          ]);
        },
        (error) => {
          this.handleErrorResponse(error);
        }
      );
    } else {
      this.validatePhoneForm.markAllAsTouched();
      let invalidFields = [].slice.call(
        document.getElementsByClassName('ng-invalid')
      );
      invalidFields[1].focus();
    }
  }

  /**
   * @Description handleErrorResponse method used to get a error message based on error code from service.
   * @param  {any} error
   * @returns void
   */
  handleErrorResponse(error: any): void {
    this.loading = false;
    this.errorMessage = this.errorCodeMapperService.errorCodeMapper(
      error,
      this.errorMessages
    );
    this.sendOtpErrorAlert(this.errorMessage);
  }

  /**
   * @Description sendOtpErrorAlert method used to alert error message
   * @param  {string} error
   * @returns void
   */
  sendOtpErrorAlert(error: string): void {
    if (error && this.errorMessages) {
      this.alertService.error(error, {
        regionName: 'member-rep-send-otp-error-alert',
        politeness: 'assertive',
        isDissmisable: true,
      });
      this.scrollToTop();
    }
  }

  // Method used to scroll top of the page
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  /**
   * @Description clearErrorAlert method used to clear error stack throw from service failure
   * @returns void
   */
  clearAlert(alertType: InlineAlertContainerComponent): void {
    alertType.dismissAlertId(0);
  }
  showAlreadyHaveACode() { 
    this.router.navigate([
      'public/registration/member-representative/security-code'
    ]);
  } 
}
