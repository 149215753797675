import { Component, OnInit } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';

@Component({
  selector: 'app-community-resources',
  templateUrl: './community-resources.component.html',
  styleUrls: ['./community-resources.component.css']
})
export class CommunityResourcesComponent implements OnInit {

  constructor(
    private interCommunicationService: InterCommunicationService
  ) {
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "Community Resources",
    });
   }

  ngOnInit(): void {
  }

}
