import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { DeleteAccountConfirmLabels } from './models/labels';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-delete-account-confirm',
  templateUrl: './delete-account-confirm.component.html',
  styleUrls: ['./delete-account-confirm.component.scss']
})
export class DeleteAccountConfirmComponent implements OnInit {
  public labels: DeleteAccountConfirmLabels;
  private subscription: Subscription = new Subscription();
  public loading: boolean = true;

  constructor(
    private router: Router,
    private jsonSvc: JsonContentService,
  ) { }

  ngOnInit(): void {
    this.getLabels();
  }

  private getLabels() {
    this.subscription.add(
      this.jsonSvc.getJSON('delete-account-confirm').subscribe(data => {
        this.labels = data;
        this.loading = false;
      })
    )
  }

  goToRegister() {
    this.router.navigate(['public/registration/home']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
