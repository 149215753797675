import { Component, OnInit, Input } from '@angular/core';
import { PrescriptionFooterContent } from './PrescriptionFooterContent';

@Component( {
  selector: 'app-prescription-footer',
  templateUrl: './prescription-footer.component.html',
  styleUrls: [ './prescription-footer.component.scss' ]
} )
export class PrescriptionFooterComponent implements OnInit {

  @Input() content: PrescriptionFooterContent;
  @Input() link: string;
  @Input() prescriptions: string;
  constructor() { }

  ngOnInit(): void {
  }

}
