import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccordianModule } from '@anthem/uxd/accordion';
import { DocumentHubComponent } from './document-hub.component';
import { DocumentHubRoutingModule } from './document-hub-routing.module';
import {BenefitsModule} from '../benefits/benefits.module';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentFormsComponent } from './document-forms/document-forms.component';
import { NoticesOfActionComponent } from './notices-of-action/notices-of-action.component';
import { SydmedSharedModule } from '../../sydmed-shared/sydmed-shared.module';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { FormsModule } from '@angular/forms';
import {AlertModule} from '@anthem/uxd/alert';
import {IndicatorModule} from '@anthem/uxd/indicators';
import {TooltipModule} from '@anthem/uxd/tooltip';

@NgModule({
  declarations: [
    DocumentHubComponent,
    NoticesOfActionComponent,
    DocumentsComponent,
    DocumentFormsComponent,
  ],
  imports: [
    AccordianModule,
    AlertModule,
    BenefitsModule,
    CommonModule,
    DropdownModule,
    DocumentHubRoutingModule,
    FormsModule,
    IndicatorModule,
    SydmedSharedModule,
    TooltipModule,
  ],
  exports: [DocumentHubComponent, NoticesOfActionComponent]
})
export class DocumentHubModule { }