import { DOCUMENT } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, Input, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ModalComponent } from "@anthem/uxd/deprecated";
import { ModalRef, SidePanel } from "@anthem/uxd/modal";
import { UxHelper } from "@anthem/uxd/util";
import { Subject } from "rxjs";
import { base64toBlob } from '../../../../../utils/base64-blob/base64toblob';
import { ClaimDetails, ClaimGenericCode } from "../interfaces/claims";
import { ClaimsContent } from '../interfaces/claimsContent';
import { ClaimsService } from "../services/claimsSvc";
import { DateUtility } from "../util/dateUtil";

/**
 * @description
 * This component is responsible for loading claim detail
 *
 * @function
 * expandServiceDescription(serviceDescription: string, direction: "left" | "right" | "top"): void
 * getEOBFile(eobUid: string, clmId: string): void
 * handleClick(claimLine: any): void
 * onClose(): void
 * printDetails(): void
 * showPrintLink(): boolean
 *
 * @example
 * ```html
 * <data-sydmed-claims-detail-cmp *ngIf="!claimsDetailLoadingSpinner && !claimsErrorMsg" [content]="content" [claimsModel]="claims[$index]"></data-sydmed-claims-detail-cmp>
 * ```
 */

@Component({
  selector: "data-sydmed-claims-detail-cmp",
  templateUrl: "./claimsDetailCmp.html",
  styleUrls: ["../claimsSyd.scss"],
})
export class ClaimsDetailComponent implements OnInit {
  public sidePanelRef: ModalRef<any, any>;
  @ViewChild("codeModalCmp", { static: false }) codeModalCmp: ModalComponent;
  @ViewChild("sidePanelContent", { static: false })
  sidePanelContent: TemplateRef<HTMLElement>;

  @Input()
  claimsModel: ClaimDetails;

  @Input()
  content: ClaimsContent;

  displayEobLink: boolean = false;
  explanationCodes: ClaimGenericCode[];
  today: Date = new Date();
  eobFailure: boolean = false;
  isEobInProgress: boolean = false;
  selectedDescription: string;
  printSubject: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private claimsService: ClaimsService,
    private renderer: Renderer2,
    private viewContainerReference: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private sidePanel: SidePanel,
    public dateUtil: DateUtility,
    uxHelper: UxHelper
  ) {
    uxHelper.setRootViewContainerRef(viewContainerReference);
  }

  ngOnInit(): void {
    if (this.claimsModel.eob && this.claimsModel.eob[0]?.eobUid) {
      this.displayEobLink = true;
    }
    this.changeDetector.detectChanges();
  }

  /**
   * @description expandServiceDescription() Responsible for displaying the service description in the slide in panel
   * @param {string} serviceDescription
   * @param {'left' | 'right' | 'top'} direction
   * @returns {void}
   */
  expandServiceDescription(
    serviceDescription: string,
    direction: "left" | "right" | "top"
  ): void {
    this.selectedDescription = serviceDescription;
    this.sidePanelRef = this.sidePanel.open(direction, this.sidePanelContent);
  }

  /**
   * @description getEOBFile() Responsible for downloading EOB in PDF format
   * @param  {string} eobUid
   * @param {string} clmId
   * @returns {void}
   */
  getEOBFile(eobUid: string, clmId: string): void {
    this.isEobInProgress = true;
    this.claimsService.getEOBFile(eobUid).subscribe(
      (result: any) => {
        if (result) {
          const blob = base64toBlob(result.content);

          this.eobFailure = false;
          this.isEobInProgress = false;

          let ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
            ie11 =
              navigator.userAgent.match(/Trident\/7.0/) &&
              navigator.userAgent.match(/rv:11/),
            ieEDGE = navigator.userAgent.match(/Edge/g),
            ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;
          let ipadIphone =
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPhone/i);

          let fileName = "EOB_" + clmId + ".pdf";
          let pdfBlob = new Blob([blob], { type: "application/pdf" });

          //for rest of the browsers - we don't support IE now
          var link = document.createElement("a");
          let url = URL.createObjectURL(pdfBlob);
          if (ipadIphone) {
            var openWindow = window.open(url);
            //Showing EOB Concierge Modal due to popup blocker
            if (!openWindow) {
              this.isEobInProgress = false;
              alert("Please disable the popup blocker");
            }
          } else if ("download" in link) {
            link.href = url;
            link.download = fileName;
            link.click();
          }
        }
      },
      (error: any) => {
        this.isEobInProgress = false;
        this.eobFailure = true;
      }
    );
  }

  /**
   * @description handleClick() Responsible to display explanation codes modal popup
   * @param {any} claimLine claim service line
   * @returns {void}
   */
  handleClick(claimLine: any): void {
    this.explanationCodes = claimLine.explanationCd;
    this.attachClassToRoot();
    this.codeModalCmp.show();
  }

  /**
   * @description onClose() Responsible for closing the Reason Codes modal
   * @returns {void}
   */
  onClose(): void {
    this.codeModalCmp.hide();
  }

  /**
   * @description printDetails() Responsible for printing claims details
   * @returns {void}
   */
  printDetails(): void {
    this.printSubject.next();
  }

  /**
   * @description showPrintLink() Responsible for hiding Print Claims link for Pending claims
   * @returns {boolean}
   */
  showPrintLink(): boolean {
    return this.claimsModel?.statusName?.toUpperCase() !== "PENDING";
  }

  /**
   * @description attachClassToRoot() Responsible for attaching motif theme class to document body
   * @returns {void}
   */
  private attachClassToRoot(): void {
    if (this._document) {
      this.renderer.addClass(this._document.body, "uxd-motif-theme");
    }
  }
}
