import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateRangeService {

  private dateRangeName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private startDateParam$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public dateRange: Observable<string> = this.dateRangeName$.asObservable();
  public startDateObs: Observable<string> = this.startDateParam$.asObservable();

  constructor() { }

  setDateRangeName(dateRangeName: string) {
    this.dateRangeName$.next(dateRangeName);
  }

  setStartDateParam(startDate: string) {
    this.startDateParam$.next(startDate);
  }

}
