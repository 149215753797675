import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { RegistrationAppComponent } from './app.component';
import { GetSecurityCodeComponent } from './get-security-code/get-security-code.component';
import { AccountRecoveryNumberComponent } from './account-recovery-number/account-recovery-number.component'
import { EnterSecurityCodeComponent } from './enter-security-code/enter-security-code.component';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { ReviewComponent } from './review/review.component';
import { CreateUsernamePasswordComponent } from './create-username-password/create-username-password.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CustomValidatorsModule } from 'sydmed/libs/custom-validators/src/lib/custom-validators.module';

import { PublicModule } from '../public.module';
import { AccordianModule } from '@anthem/uxd/accordion'; //Standalone accordion for account details
import { AlertModule } from '@anthem/uxd/alert'; //alerts for asl errors
import { RadioModule } from '@anthem/uxd/radio'; //radio buttons on create username
import { CheckboxModule } from '@anthem/uxd/checkbox'; //checkbox on account details
import { TooltipModule } from '@anthem/uxd/tooltip'; //tooltip in create username password
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { CaregiverRelationTypeComponent } from './caregiver-relation-type/caregiver-relation-type.component';
import { CaregiverInfoComponent } from './caregiver-info/caregiver-info.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { InputModule } from '@anthem/uxd/input';
import { PhoneVerificationComponent } from './member-representative/phone-verification/phone-verification.component';
import { SecurityCodeComponent } from './member-representative/security-code/security-code.component';
import { RegistrationComponent as MemberRepRegistrationComponent } from './member-representative/registration/registration.component';
import { SecurityQuestionsComponent as MemberRepSecurityQuestionsComponent } from './member-representative/security-questions/security-questions.component';
import { AntDatepickerModule } from '@anthem/uxd/ant-datepicker';
import { ValidateTokenComponent } from './validate-token/validate-token.component';

@NgModule({
  declarations: [
    RegistrationAppComponent,
    GetSecurityCodeComponent,
    AccountRecoveryNumberComponent,
    EnterSecurityCodeComponent,
    SecurityQuestionsComponent,
    ReviewComponent,
    CreateUsernamePasswordComponent,
    ConfirmationComponent,
    CaregiverRelationTypeComponent,
    CaregiverInfoComponent,
    PhoneVerificationComponent,
    SecurityCodeComponent,
    MemberRepRegistrationComponent, 
    MemberRepSecurityQuestionsComponent,
    ValidateTokenComponent
  ],
  imports: [
    AntDatepickerModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CustomValidatorsModule,
    BrowserAnimationsModule,
    PublicModule,
    AccordianModule,
    AlertModule,
    RadioModule,
    CheckboxModule,
    TooltipModule,
    DropdownModule,
    IndicatorModule,
    InputModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [RegistrationAppComponent]
})
export class RegistrationModule { }
