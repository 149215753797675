import { Component, Input } from '@angular/core';
import { TransportationBenefitsContent } from '../interfaces/benefitsContent';

@Component({
    selector: 'benefits-transportation-component',
    templateUrl: './transportation-benefits.component.html',
    styleUrls: ['./transportation-benefits.component.scss']
})
export class TransportationBenefitsComponent {
    @Input() content: TransportationBenefitsContent;
    constructor() { }
}
