import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PcpViewComponent } from './view/pcpViewCmp';
import { PcpChangeComponent } from './change/pcpChangeCmp';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';

const routes: Routes = [
	{ path: 'secure/pcp', component: PcpViewComponent , pathMatch: 'full', canActivate: [AuthGuardService] },
	{ path: 'secure/change-provider', component: PcpChangeComponent , pathMatch: 'full', canActivate: [AuthGuardService] }
];
  
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PcpRoutingModule { }