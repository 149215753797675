    
<div class="uxd-theme-motif container" *ngIf="showPhoneVerificationPage">
<div *ngIf="loading" class="row">
  <uxd-spinner class="updating-loader"></uxd-spinner> 
  <div>{{loaders?.PageLoader}}</div>
</div>
<div id="errorMessageAlerts" class="error">
  <inline-alert-container #memberRepSendOtpAlert role="alert" [name]="'member-rep-send-otp-error-alert'" [alertType]="alertPosition"></inline-alert-container>
</div>
<div *ngIf="!loading" class="uxd-theme-motif container">
    <div class="max-width-elements">
        <h1 tabindex="-1" class="font-32 text-left margin-bottom-24">
            {{ labels?.Page_Heading }}
        </h1>
        <p>
            {{ labels?.Phone_Info }}
        </p>
        <div class="phone-verification margin-top-24">
            <form #validateTextNumber="ngForm" [formGroup]="validatePhoneForm" (ngSubmit)="sendOtp(validatePhoneForm)" id="validatePhone" name="validatePhone">
                <lib-phone
                [id]="'textNumber'"
                formControlName="textNumber"
                [phoneLabel]="labels?.Text_Number_Info"
                [errorMessageOverride]="labels?.Text_Number_Error"
                [errObj]="errorMessages"
                [formSubmitted]="validateTextNumber.submitted"
                [placeHolder]="labels?.PlaceHolder"
               ></lib-phone>
                <div  id="termsCheckbox">
                    <div class="row inline">
                      <div data-uxd-checkbox-input-cmp [definition]="checkbox" formControlName="agreeInfoDesignee" class="checkboxAlign" [optionalAriaLabel]="labels?.Agree_Info" ></div>
                    <div class="agreeInfoContent font-16" [innerHtml]="labels?.Agree_Info"></div> 
                    </div>
                    <ul id="termsOfUseError" class="ant-bar-chart-group font-14 text-center error" *ngIf="validatePhoneForm.controls['agreeInfoDesignee']?.touched && validatePhoneForm.controls['agreeInfoDesignee']?.invalid" role="alert">
                      <li class="text-left" *ngIf="validatePhoneForm.controls['agreeInfoDesignee']?.errors.required">
                        <span class="motif-icon motif-delete font-14 margin-right-8"></span>
                        <span>{{ errorMessages.Terms_Of_Use_error }}</span>
                      </li>
                    </ul>
                    <div class="flex-horizontal-start margin-bottom-40 margin-top-24" *ngIf="isCaregiver" (click)= "showAlreadyHaveACode()">
                      <span class="margin-right-6">
                      <a class="ant-action-link" href="javascript:void(0)">
                        <span class="font-16 already-have-a-code font-semi-bold" >{{ alreadyHaveACode }}<i
                            class="fas fa-angle-right"></i></span>
                          </a>
                        </span>
                    </div>
                </div>
                <div class="form-item flex-horizontal-end margin-top-30">
                    <button [disabled] = "!validatePhoneForm.valid" type="submit" class="btn btn-primary" [attr.data-analytics]="labels?.Analytics?.Verify">
                    {{ labels?.Buttons?.Verify }}
                  </button>
                </div>
            </form>
        </div>
    </div>
</div>
</div>