import { Injectable } from "@angular/core";
import { Restriction } from "gbd-models";
import { LoginContactUpdateValues } from "../models/enum-values";

export interface Phone {
  number: string;
  numberType: string;
  numberSubType?:string;
}

export class ContactData {
  name: string;
  address: any;
  dob: string;
  userName: string;
  email?: string;
  phone?: Phone;
  secondaryPhone?: Phone;
  textPhone?: Phone;
  pharmacyEmail?: string;
  pharmacyPhone?: Phone;
  pharmacyText?: Phone;
  genSmsStatus?: string;
  pharSmsStatus?: string;
  emailList?: string;
  mailList?: any;
  smsList?: any;
  acctRecoveryNumber?: Phone;
  cellPhone?: Phone;
}

@Injectable({
  providedIn: "root"
})
export class MyProfileModel {

  public contactObj = new ContactData();
  private _showSecondaryPhone: boolean = false;

  public setContactInfo = function(contactInfo) {
    this.contactObj = {
      name: contactInfo.name ? (contactInfo.name.firstName).toUpperCase() + ' ' + (contactInfo.name.lastName).toUpperCase() : '',
      address: this.getAddressList(contactInfo.addressList),
      dob: contactInfo.memberDOB ? this.getDOB(contactInfo.memberDOB) : '',
      userName: (contactInfo.userName) ? (contactInfo.userName).substr(1) : '',
      email: this.getEmail(contactInfo, LoginContactUpdateValues.SECURITY), // primary email
      phone: this.getPhoneNumber(contactInfo, LoginContactUpdateValues.HOME), // primary/home phone
      secondaryPhone: this.getPhoneNumber(contactInfo, LoginContactUpdateValues.MAILING), // mailing/secondary phone
      textPhone: this.getGenPharTxtNumber(contactInfo.smsList, LoginContactUpdateValues.GENERAL), // primary text number
      pharmacyEmail: this.getEmail(contactInfo, LoginContactUpdateValues.PHARMACY), // pharmacy email
      pharmacyPhone: this.getPhoneNumber(contactInfo, LoginContactUpdateValues.PHARMACY), // pharmacy phone number
      pharmacyText: this.getGenPharTxtNumber(contactInfo.smsList, LoginContactUpdateValues.PHARMACY), // pharmacy text number
      genSmsStatus: this.getSmsStatus(contactInfo.smsList, LoginContactUpdateValues.GENERAL),
      pharSmsStatus: this.getSmsStatus(contactInfo.smsList, LoginContactUpdateValues.PHARMACY),
      emailList: this.getOptOptions(contactInfo),
      mailList: contactInfo.mail.optInOptions,
      smsList: this.getSmsList(contactInfo.sms) || '',
      acctRecoveryNumber: this.getAcctRecoveryNumber(contactInfo) || ''      
    };

    this.hasMailingAddress(contactInfo.addressList);
  };

  public getContactInfo = function() {
    return this.contactObj;
  };

  public getSmsList(smsList) {
      if(smsList){
          smsList.smsNumber = this.phoneNumberViewFormat(smsList.smsNumber);
          return smsList;
      }
  }

  public phoneNumberViewFormat(phoneNumber) {
    if(phoneNumber) {
        phoneNumber = phoneNumber.replace(/-/g, "");
        return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, phoneNumber.length);
    }
  }

  public getDOB = (dob: string) => {
    const d = dob.split('-');
    return (d[1] + "/" + d[2] + "/" + d[0]);
  }

  public getEmail = (contactInfo: any, type: string) => {
    for (const emailObj of contactInfo.emailList) {
      if (emailObj.emailType === type) {
        return emailObj.emailAddress;
      }
    }
    return null;
  };

  public getPhoneNumber = (contactInfo, type) => {
    for (let i = 0; i < contactInfo.phoneNumberList.length; i++) {
      if (
        contactInfo.phoneNumberList[i] &&
        contactInfo.phoneNumberList[i].numberType === type
      ) {
        if (contactInfo.phoneNumberList[i].number) {
          contactInfo.phoneNumberList[i].number = this.phoneNumberViewFormat(contactInfo.phoneNumberList[i].number);
          return contactInfo.phoneNumberList[i];
        }
      }
    }   
  };

  public hasMailingAddress(addressList) {
    for (let i = 0; i < addressList.length; i++) {
      if(addressList[i] && addressList[i].addressType === LoginContactUpdateValues.MAILING) {
        this.showSecondaryPhone = true;
      }
    }
  }

  public getGenPharTxtNumber = (smsList, type) => {
    for (const smsObj of smsList) {
      if (smsObj && (smsObj.numberType === type)) {
        if(smsObj.number) {
          smsObj.number = this.phoneNumberViewFormat(smsObj.number);
          return smsObj;
        }
      }
    }
  };

  public getAddressList = (addressList) => {
    let sortedList = [];
    let addressTypes = ['home', 'mailing', 'alternate', 'pharmacy'];
    if(addressList) {
      for (const addrObj of addressList) {
        let zip = addrObj.zipCode;
        if(zip.length === 9)
          addrObj.zipCode = zip.substring(0,5) + '-' + zip.substring(5, zip.length);
      }
      for (let i = 0; i < addressTypes.length; i++) {
          const address = addressList.find(function (element) {
              return element.addressType == addressTypes[i];
          });
          if (address) {
              sortedList.push(address);
          }
      }
      return sortedList;
    }
}

public getOptOptions = (contactInfo: any) => {
  for (const emailObj of contactInfo.emailList) {
    if(emailObj.emailType === LoginContactUpdateValues.SECURITY) {
      return emailObj.optInOptions;
    }
  }
};

public getAcctRecoveryNumber = (contactInfo) => {
  for (let phoneObj of contactInfo.phoneNumberList) {
    if (phoneObj && phoneObj.numberType === LoginContactUpdateValues.ARN) {
      if(phoneObj.number)  {
        phoneObj.number = this.phoneNumberViewFormat(phoneObj.number);
        phoneObj.numberSubType = this.capitalizeFirstLetter(phoneObj.numberSubType);
      }
      return phoneObj;
    }
  }
};

public capitalizeFirstLetter = (data: string) => {
  data = data ? data[0].toUpperCase() + data.substr(1).toLowerCase() : "";
  return data;
}

public checkIsEmptyAlternatePharAddr = (addressList: any, type: string) => {
  let isEmpty = true;
  addressList.forEach((address) => {
    if (address.addressType === type) { //type="alternate", "pharmacy"
      isEmpty = false;
    }
  })
  return isEmpty;
}

  public restrictions = function(restrictionArr: any, stateLob) {
    let restrictions: any;
    restrictions = restrictionArr.replace(/\[|\]|\s/g, '').split(',');
    //Implement the restriction logic to show/hide the "edit functionality
    let restrictChg: any = {};
    restrictChg.home = true;
    restrictChg.mail = true;
    restrictChg.cp = true;
    restrictChg.designeeCommPref = true; //Designee Communication Preferences
    restrictChg.pa = true;
    restrictChg.phone = true;
    restrictChg.eob = true;
    restrictChg.pharmacy = true;
    restrictChg.pharmacyPBM = true;
    restrictChg.altAddress = true; // Alternate Address 
    restrictChg.arn = true;
    restrictChg.accountSettings = true; // Account Settings
    restrictChg.accountDetails = true; // account Details
    restrictChg.communicationPref = true; //Communication Preferences 
    restrictChg.prescriptionNotifications = true;//Prescription Notifications
    restrictChg.arnTextNumber = true; // ARN text number
    restrictChg.writtenLanguagePref = true; // Written Language preference 

    if (stateLob === "DCMCD") {
        restrictChg.eob = false;
    }

    if (restrictions != undefined && restrictions != '') {
      if (restrictions.indexOf('RVHC') !== -1) //HOME
      { restrictChg.home = false; }
      if (restrictions.indexOf('RVMC') !== -1) //MAILING
      { restrictChg.mail = false; }
      if (
        restrictions.indexOf("RVCP") !== -1 ||
        restrictions.indexOf(Restriction.SHM_PLAN_NOTIFICATIONS) !== -1
      ) {
        //COMM PREF
        restrictChg.cp = false;
      }
      if (restrictions.indexOf('RVPA') !== -1) //POWER ACCOUNT
      { restrictChg.pa = false; }
      if (restrictions.indexOf('RVTC') !== -1) // TELEPHONE
      { restrictChg.phone = false; }
      if (
        restrictions.indexOf('SHM_NO_PHARMACY') !== -1 || 
        restrictions.indexOf('SHM_PARTIAL_PHARMACY') !== -1
      ) {
        //PHARMACY HOME, EMAIL, PHONE, COMM PREF 
        restrictChg.pharmacy = false;
        restrictChg.pharmacyPBM = false;
        restrictChg.prescriptionNotifications = false;
      }
      // when AHD is added to profile, new profile specific restrictions should be created for AHD and BioPlus to replace this CVS specific one
      if (restrictions.includes('SHM_PHARMACY_CVS_PBM')) {
        restrictChg.pharmacyPBM = false;
        restrictChg.prescriptionNotifications = false;
      }
      if (restrictions.indexOf('SHM_NO_ALT_ADDRESS') !== -1) // Alternate Address 
      { restrictChg.altAddress = false; }
      if (restrictions.indexOf('NO_ARN') !== -1) // Account Recovery Number 
      { restrictChg.arn = false; }
      if (restrictions.includes('SHM_DESIGNEE_COMM_PREFERENCES'))// Designee Comm Pref
      { restrictChg.designeeCommPref = false;}      
      if (restrictions.includes('SHM_PROFILE_SETTINGS')) // Account Settings
      { restrictChg.accountSettings = false;}
      if (restrictions.includes('SHM_PROFILE_DETAILS'))  // account Details  
      { restrictChg.accountDetails = false; }
      if (restrictions.includes('SHM_COMM_PREFERENCES'))  // Communication Preferences  
      { restrictChg.communicationPref = false; }
      if (restrictions.includes('SHM_RX_COMM_PREFERENCES'))  //   Prescription Notifications
      { restrictChg.prescriptionNotifications = false; }
      if(restrictions.includes('SHM_NO_SMS')) { // ARN text number
        restrictChg.arnTextNumber = false;
      } 
      if(restrictions.includes('SHM_LANGUAGE_PREFERENCE')) { // Written Language preference
        restrictChg.writtenLanguagePref = false;
      }
    }
    return restrictChg;
  }

  public getSmsStatus = (smsList, type) => {
    let ret = 'NA'; //not available
    const smsObj = this.getGenPharTxtNumber(smsList, type);

    if (typeof (smsObj) != 'undefined' && smsObj != '') {
        if (typeof (smsObj.number) != 'undefined' && smsObj.number != '') {
            if (typeof (smsObj.confirmed) != 'undefined' && smsObj.confirmed != '') {
                if (smsObj.confirmed) {
                    ret = 'SC'; //sms available and confirmed
                }
                else {
                    ret = 'SP'; //sms available and pending confirmation
                }
            }
            else {
                ret = 'SP';
            }
        }
        else {
            ret = 'SN'; //sms not set
        }
    }

    return ret;
  };

  commPrefShowHide(contactData) {
    //The logic to show/hide comm pref 
    var showCommPref = true;

    if (contactData.emailList && contactData.emailList.length > 0) {
        showCommPref = true;
    }
    else {
        showCommPref = false;
    }
    return showCommPref;
};

  // getters and setters
  get showSecondaryPhone():boolean{
    return this._showSecondaryPhone;
  }
  set showSecondaryPhone(showSecondary:boolean) {
    this._showSecondaryPhone = showSecondary;
  }
}
