import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { OVERLAY_PROVIDERS } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { MessageCenterAppComponent } from './app.component';
import { FolderMenuComponent } from './folder-menu/folder-menu.component';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { JsonDataResolverService } from './json-data-resolver.service';
import { ComposeButtonComponent } from './compose-button/compose-button.component';
import { SortMessagesComponent } from './sort-messages/sort-messages.component';
import { MessagesComponent } from './messages/messages.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { ComposeMessageComponent } from './compose-message/compose-message.component';
import { OrderByPipe } from './order-by.pipe';

import { DropdownModule } from '@anthem/uxd/dropdowns';
import { OverlayService, OverlayContainerService } from '@anthem/uxd/overlay';
import { AlertModule } from '@anthem/uxd/alert';
import { SidePanel } from '@anthem/uxd/modal';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { SharedModule } from 'sydmed/libs/file-attachment/shared.module';
import { MessageDatePipe } from './message-date.pipe';
import { AppealsAndGrievancesModule } from '../appeals-and-grievances/appeals-and-grievances.module';
import { SydmedSharedModule } from '../../sydmed-shared/sydmed-shared.module';

@NgModule({
  declarations: [
    MessageCenterAppComponent,
    FolderMenuComponent,
    ComposeButtonComponent,
    SortMessagesComponent,
    MessagesComponent,
    MessageDetailComponent,
    ComposeMessageComponent,
    OrderByPipe,
    MessageDatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AppealsAndGrievancesModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DropdownModule,
    AlertModule,
    IndicatorModule,
    SharedModule,
    SydmedSharedModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    JsonDataResolverService,
    OVERLAY_PROVIDERS,
    OverlayService,
    OverlayContainerService,
    SidePanel
  ],
  bootstrap: [MessageCenterAppComponent]
})
export class MessageCenterModule { }
