<div *ngIf="loading" class="row">
    <uxd-spinner class="updating-loader"></uxd-spinner>
  </div>
<div *ngIf="!loading" class="ant-theme-primary">
    <div id="errorMessageAlerts" class="error width-100 margin-0">
        <inline-alert-container #memberRepSecurityQuestionsAlert role="alert" [name]="'member-rep-security-questions-alert'" [alertType]="alertPosition"></inline-alert-container>
      </div>
        <div class="ant-content-wrapper" role="region">
            <h2 class="margin-bottom-24">{{ labels?.Security_Questions?.SecurityQuestion_PageHeading}}</h2>
            <form [formGroup]="securityQuestionsForm" class="designee-security-questions-form" (ngSubmit)="submitSecurityQuestions(securityQuestionsForm, secQuesPanel, secQuesForm)" #secQuesForm="ngForm">
               
                <div class="partial-row sec-q-and-a">
                    <div class="form-item  margin-bottom-40">
                        <div class="form-item">
                            <div #lgSelect="ngModel" data-uxd-dropdown-cmp #question1Div #question1
                                [(ngModel)]="dropDown1.selected" class="ant-lg-select form-item"
                                [labelText]="dropDown1.title" name="question1" id="question1"
                                [items]="dropDown1.options" [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'selectQuestionBC': (securityQuestionsForm.controls['question1']?.dirty || securityQuestionsForm.controls['question1']?.touched || secQuesForm.submitted) 
                                && (securityQuestionsForm.controls['question1']?.invalid || (securityQuestionsForm.controls['question1']?.value === 'Select Question'))}"
                                (click)="onChangeInput(1)" required [defaultText]="labels?.Security_Questions.SelectQuestionDefaultText">
                            </div>
                        </div>
                        <div class="error-list error motif-margin-top-0" *ngIf="securityQuestionsForm.controls['question1']?.touched && securityQuestionsForm.controls['question1']?.invalid">
                            <div *ngIf="securityQuestionsForm.controls['question1']?.errors?.required">
                                <span class="fas fa-times font-14 margin-right-8"></span>
                                <span [innerHTML]="labels?.Security_Questions.Field_Labels.Question_1"></span>
                                <span [innerHTML]="errorMsgs?.Required_err"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-item margin-bottom-40">
                        <label for="secAns1" class="ant-font-b" [innerHTML]="labels?.Security_Questions.Field_Labels.Answer_1"></label>
                        <div class="mask-wrapper">
                            <input class="ant-input-long select-answer" 
                            [ngClass]="{'border-color': (ans1.touched || secQuesForm.submitted) && ans1.invalid }" (focus)="onFocus()" (blur)="onBlur()" 
                            #input #ans1Mask #ans1 formControlName="answer1" id="secAns1" name="ans1" data-tcp-password-valid-dir type="password" minlength="4" (change)="onChangeInput(1)" required />
                            <span id="btnnewpassmask_profile" class="align-eye" data-uxd-security-mask-cmp [definition]="{maskedInput: ans1Mask, ariaLabelMasked: ('Unmask '+labels?.Security_Questions.Field_Labels.Answer_1), ariaLabelUnMasked: ('Mask '+labels?.Security_Questions.Field_Labels.Answer_1)}"></span>
                        </div>
                        <app-security-answer-validator [answer1Id]="'answer1Error'"
                            [Showanswer1]="(answer1.dirty || answer1.touched || secQuesForm.submitted) && answer1.invalid"
                            [Showanswer1Required]="(answer1.errors?.required)" [Showanswer1Requirement]="!answer1.errors?.required && (answer1?.errors?.hasDuplicate || answer1.errors?.secanslength || answer1?.errors?.invalidnamechar)"
                            [answer1RequiredLabel]="errorMsgs?.Sec_Required_err" [answer1RequirementLabel]="errorMsgs?.SecAns_requirement"
                            [showTooltip1]="(showTooltips || answer1.dirty || answer1?.touched ) && (answer1.errors )"
                            [minLengthCss]="(!securityQuestionsForm.controls['answer1'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer1']?.errors?.minlength ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                            [invalidNameCharCss]="(!securityQuestionsForm.controls['answer1'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer1']?.errors?.invalidnamechar ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                            [duplciateCss]="(!securityQuestionsForm.controls['answer1'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer1']?.errors?.hasDuplicate? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                            [securityAnswerLengtherror]="errorMsgs?.SecAns_Len_err"
                            [securityAnswerInvaliderror]="errorMsgs?.SecAns_Invalid_err"
                            [securityAnswerDuplicateerror]="errorMsgs?.Unique_answer"
                            >
                        </app-security-answer-validator>
                    </div>
                </div>
    
                <div class="partial-row sec-q-and-a">
                    <div class="form-item margin-bottom-40">
                        <div class="form-item">
                            <div #lgSelect="ngModel" data-uxd-dropdown-cmp #question2Div
                                [(ngModel)]="dropDown2.selected" class="ant-lg-select form-item"
                                [labelText]="dropDown2.title" name="question2" id="question2"
                                [items]="dropDown2.options" [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'selectQuestionBC': (securityQuestionsForm.controls['question2']?.dirty || securityQuestionsForm.controls['question2']?.touched || secQuesForm.submitted) 
                                && (securityQuestionsForm.controls['question2']?.invalid || (securityQuestionsForm.controls['question2']?.value === 'Select Question'))}"
                                (click)="onChangeInput(2)" required [defaultText]="labels?.Security_Questions.SelectQuestionDefaultText">
                            </div>
                        </div>
                        <div class="error-list error motif-margin-top-2" *ngIf="securityQuestionsForm.controls['question2']?.touched && securityQuestionsForm.controls['question2']?.invalid">
                            <div *ngIf="securityQuestionsForm.controls['question2']?.errors?.required">
                                <span class="fas fa-times font-14 margin-right-8"></span>
                                <span [innerHTML]="labels?.Security_Questions.Field_Labels.Question_2"></span>
                                <span [innerHTML]="errorMsgs?.Required_err"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-item margin-bottom-40">
                            <label for="secAns2" class="answers ant-font-b"
                            [innerHTML]="labels?.Security_Questions.Field_Labels.Answer_2"></label>
                            <div class="mask-wrapper">
                                <input class="ant-input-long select-answer"  (focus)="onFocus2()" (blur)="onBlur2()" 
                                [ngClass]="{'border-color': (ans2.touched || secQuesForm.submitted) && ans2.invalid }"
                                    #input #ans2Mask #ans2 id="secAns2" name="ans2" data-tcp-password-valid-dir type="password" formControlName="answer2" minlength="4" (change)="onChangeInput(2)" required />
                                <span id="ans2_input" class="align-eye" data-uxd-security-mask-cmp [definition]="{maskedInput: ans2Mask, ariaLabelMasked: ('Unmask '+labels?.Security_Questions.Field_Labels.Answer_2), ariaLabelUnMasked: ('Mask '+labels?.Security_Questions.Field_Labels.Answer_2)}"></span>
                            </div>
                            <app-security-answer-validator [answer1Id]="'answer2Error'"
                                [Showanswer1]="(answer2.dirty || answer2.touched || secQuesForm.submitted) && answer2.invalid"
                                [Showanswer1Required]="(answer2.errors?.required || answer2.errors?.whitespaces)"
                                [Showanswer1Requirement]="!answer2.errors?.required && (answer2?.errors?.hasDuplicate || answer2.errors?.secanslength || answer2?.errors?.invalidnamechar)"
                                [answer1RequiredLabel]="errorMsgs?.Sec_Required_err" [answer1RequirementLabel]="errorMsgs?.SecAns_requirement"
                                [showTooltip1]="(showTooltips2 || answer2.dirty || answer2?.touched ) && (answer2.errors )"
                                [minLengthCss]="(!securityQuestionsForm.controls['answer2'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer2']?.errors?.minlength ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                                [invalidNameCharCss]="(!securityQuestionsForm.controls['answer2'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer2']?.errors?.invalidnamechar ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                                [duplciateCss]="(!securityQuestionsForm.controls['answer2'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer2']?.errors?.hasDuplicate? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                                [securityAnswerLengtherror]="errorMsgs?.SecAns_Len_err" [securityAnswerInvaliderror]="errorMsgs?.SecAns_Invalid_err"
                                [securityAnswerDuplicateerror]="errorMsgs?.Unique_answer">
                            </app-security-answer-validator>
                    </div>
                </div>
    
                <div class="partial-row sec-q-and-a">
                    <div class="form-item  margin-bottom-40">
                        <div class="form-item">
                            <div #lgSelect="ngModel" data-uxd-dropdown-cmp #question3Div
                                [(ngModel)]="dropDown3.selected" class="ant-lg-select form-item"
                                [labelText]="dropDown3.title" name="question3" id="question3"
                                [items]="dropDown3.options" [ngModelOptions]="{standalone: true}"
                                [ngClass]="{'selectQuestionBC': (securityQuestionsForm.controls['question3']?.dirty || securityQuestionsForm.controls['question3']?.touched || secQuesForm.submitted) 
                                && (securityQuestionsForm.controls['question3']?.invalid || (securityQuestionsForm.controls['question3']?.value === 'Select Question'))}"
                                (click)="onChangeInput(3)" required [defaultText]="labels?.Security_Questions.SelectQuestionDefaultText">
                            </div>
                        </div>
                        <div class="error-list error motif-margin-top-2" *ngIf="securityQuestionsForm.controls['question3']?.touched && securityQuestionsForm.controls['question3']?.invalid">
                            <div *ngIf="securityQuestionsForm.controls['question3']?.errors?.required">
                                <span class="fas fa-times font-14 margin-right-8"></span>
                                <span [innerHTML]="labels?.Security_Questions.Field_Labels.Question_3"></span>
                                <span [innerHTML]="errorMsgs?.Required_err"></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-item margin-bottom-40">
                        <label for="secAns3" class="answers ant-font-b"
                            [innerHTML]="labels?.Security_Questions.Field_Labels.Answer_3"></label>
                        <div class="mask-wrapper">
                            <input class="ant-input-long select-answer" (focus)="onFocus3()" (blur)="onBlur3()" 
                            [ngClass]="{'border-color': (ans3.touched || secQuesForm.submitted) && ans3.invalid }"
                            formControlName="answer3" #input #ans3Mask #ans3 id="secAns3" minlength="4" (change)="onChangeInput(3)" name="ans3" data-tcp-password-valid-dir type="password" required />
                            <span id="ans2_input" class="align-eye" data-uxd-security-mask-cmp [definition]="{maskedInput: ans3Mask, ariaLabelMasked: ('Unmask '+labels?.Security_Questions.Field_Labels.Answer_3), ariaLabelUnMasked: ('Mask '+labels?.Security_Questions.Field_Labels.Answer_3)}"></span>
                        </div>
                        <app-security-answer-validator [answer1Id]="'answer3Error'"
                        [Showanswer1]="(answer3.dirty || answer3.touched || secQuesForm.submitted) && answer3.invalid"
                        [Showanswer1Required]="(answer3.errors?.required || answer3.errors?.whitespaces)"
                        [Showanswer1Requirement]="!answer3.errors?.required && (answer3?.errors?.hasDuplicate || answer3.errors?.secanslength || answer3?.errors?.invalidnamechar)"
                        [answer1RequiredLabel]="errorMsgs?.Sec_Required_err" [answer1RequirementLabel]="errorMsgs?.SecAns_requirement"
                        [showTooltip1]="(showTooltips3 || answer3.dirty || answer3?.touched ) && (answer3.errors )"
                        [minLengthCss]="(!securityQuestionsForm.controls['answer3'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer3']?.errors?.minlength ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                        [invalidNameCharCss]="(!securityQuestionsForm.controls['answer3'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer3']?.errors?.invalidnamechar ? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                        [duplciateCss]="(!securityQuestionsForm.controls['answer3'].value)  ? 'plainText' : (securityQuestionsForm.controls['answer3']?.errors?.hasDuplicate? (showTooltipErrOnBlur ? 'invalid' : 'plainText') : 'valid')"
                        [securityAnswerLengtherror]="errorMsgs?.SecAns_Len_err" [securityAnswerInvaliderror]="errorMsgs?.SecAns_Invalid_err"
                        [securityAnswerDuplicateerror]="errorMsgs?.Unique_answer">
                        </app-security-answer-validator>
                    </div>
                </div>
                <div class="error" *ngIf="securityQuestionsErr && securityQuestionsErr !== 'undefined'">
                    <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
                        <span [innerHTML]="securityQuestionsErr"></span>
                    </div>
                </div>
               <div class="button-group text-right margin-bottom-70 col-xxs-12">
                <button type="button" class="btn btn-secondary" (click)="cancelSecurityQuestion()" [attr.data-analytics]="labels?.Security_Questions.Analytics?.Back">
                  {{ labels?.Security_Questions.Back }}
                </button>
                <button type="submit" class="btn btn-primary margin-rig" [attr.data-analytics]="labels?.Security_Questions.Analytics?.Next">
                  {{ labels?.Security_Questions.Next }}
                </button>
              </div>
            </form>
        </div>
</div>