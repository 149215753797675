import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup, Validators, FormBuilder, AbstractControl, FormControl} from '@angular/forms';
import {FormValidators} from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import {JsonContentService} from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import {
  AppointmentAssistanceAnalyticsTags,
  AppointmentAssistanceFormLabels,
  AppointmentAssistanceSuccessMessages,
  MemberData,
} from '../models/labels';
import {InterCommunicationService} from 'sydmed/libs/inter-communication-service/src/public-api';
import {Router} from '@angular/router';
import { AppointmentAssistanceServices } from '../services/appointment-assistance.service';
import { AppointmentAssistance} from 'gbd-models';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/public-api';
import { AppointmentAssistanceFormat } from '../models/appointment-assistance-format';
import {OpenAttachment} from 'sydmed/src/app/sydmed-shared/open-attachment-helper/open-attachment-helper';
@Component({
  selector: 'app-appointment-assistance-form',
  templateUrl: './appointment-assistance-form.component.html',
  styleUrls: ['./appointment-assistance-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppointmentAssistanceFormComponent implements OnInit {

  appointmentAssistanceForm: FormGroup;
  public memberData: MemberData;
  dateDisplayFormat = AppointmentAssistanceFormat.DATE_DISPLAY_FORMAT;
  isCalendarOpen: boolean = false;
  max: Date = new Date();
  public analyticsTags: AppointmentAssistanceAnalyticsTags;
  public contactedDate: any;
  public formLabels: AppointmentAssistanceFormLabels;
  public errorMessages: any;
  public transportationArrangementRadioVal: string;
  public phoneNumber: string;
  public transportationArrangementRadioGroup: any;
  public alreadyContactedRadioVal: string;
  public alreadyContactedRadioGroup: any;
  public successMessages: AppointmentAssistanceSuccessMessages;
  public isSubmitted = false;
  public error: string;
  public showError: boolean;
  public isMobile: boolean;

  constructor(
    private appointmentAssistanceServices: AppointmentAssistanceServices,
    private readonly formBuilder: FormBuilder,
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService,
    private router: Router,
    private errCodeSvc: ErrorCodeMapperService,
    private openAttachment: OpenAttachment
  ) {
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Appointment Assistance Request',
    });
    this.memberData = this.getMemberData();
  }

  ngOnInit(): void {
    this.isMobile = this.openAttachment.isMobileDevice();
    this.showError = false;
    this.jsonSvc.getJSON('appointment-assistance').subscribe((res) => {
      this.formLabels = res.onlineAppointmentAssistance.form;
      this.errorMessages = res.onlineAppointmentAssistance.errorMessages;
      this.successMessages = res.onlineAppointmentAssistance.successMessages;
      this.analyticsTags = res.onlineAppointmentAssistance.analyticsTags;
      this.initRadioGroup();
    });

    // reactive formgroup
    this.appointmentAssistanceForm = this.formBuilder.group({
      firstName: [this.memberData.firstName, [Validators.required, FormValidators.WhiteSpace, FormValidators.NameValidator]],
      lastName: [this.memberData.lastName, [Validators.required, FormValidators.WhiteSpace, FormValidators.NameValidator]],
      memberId: [this.memberData.hcid, [Validators.required, FormValidators.WhiteSpace]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, FormValidators.WhiteSpace, FormValidators.EmailValidator]],
      providerType: [''],
      location: [''],
      transportationArrangementRadioVal: [''],
      alreadyContactedRadioVal: [''],
      contactedDate: [''],
    });
  }

  // getter method to access form control
  getField(field: string) {
    return this.appointmentAssistanceForm.get(field);
  }

  //get error message for passed in field, if any
  getErrorMessage(field: string) {
    let control = this.getField(field);
    if(control.errors?.['required']) {
      return this.formLabels[field] + this.errorMessages.requiredError;
    }
    if(control.errors?.['whitespace'] || control.errors?.['invalidnamechar'] || control.errors?.['pattern'] || control.errors?.['invalidEmail']) {
      return this.errorMessages.formatError + this.formLabels[field];
    }
    return undefined
  }

  public initRadioGroup() {
    this.transportationArrangementRadioGroup = {
      isOptional: false,
      alignVertical: false,
      id: 'transportationArrangementRadioBtnGrp',
      name: 'transportationArrangementBtnGrp',
      label: this.formLabels?.transportationRadioLabels?.description,
      options: [
        {
          label: this.formLabels.transportationRadioLabels.yes,
          value: true,
        },
        {
          label: this.formLabels.transportationRadioLabels.no,
          value: false,
        },
      ],
    };
    this.alreadyContactedRadioGroup = {
      isOptional: false,
      alignVertical: false,
      id: 'alreadyContactedRadioBtnGrp',
      name: 'alreadyContactedBtnGrp',
      label: this.formLabels?.alreadyContactedRadioLabels?.description,
      options: [
        {
          label: this.formLabels.alreadyContactedRadioLabels.yes,
          value: true,
        },
        {
          label: this.formLabels.alreadyContactedRadioLabels.no,
          value: false,
        },
      ],
    };
  }

  onChangeTransportationArrangementRadio() {
    this.appointmentAssistanceForm
      .get('transportationArrangementRadioVal')
      .setValue(this.transportationArrangementRadioVal ? this.formLabels.transportationRadioLabels.yes : this.formLabels.transportationRadioLabels.no);
  }

  onChangeAlreadyContactedRadioValRadio() {
    this.appointmentAssistanceForm
      .get('alreadyContactedRadioVal')
      .setValue(this.alreadyContactedRadioVal ? this.formLabels.alreadyContactedRadioLabels.yes : this.formLabels.alreadyContactedRadioLabels.no);
  }

  getMemberData(): MemberData {
      let memberContext = window.sessionStorage.getItem('sydMedMemberContext') && JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
      let memberData: MemberData;
      if (typeof (memberContext) !== undefined) {
        memberData = {
          firstName: this.format(memberContext?.memberName?.firstName),
          lastName: this.format(memberContext?.memberName?.lastName),
          hcid: this.format(memberContext?.hcid)
        }
      }
      return memberData;
    }

  private format(value: any): any {
    return value ? value : '';
  }

  onReturnHomeClick() {
    this.router.navigate(['/secure/dashboard']);
  }

  getEmailBodyContent(value: string | undefined) {
    if(value === undefined || value.length === 0) {
      return this.formLabels.noInfoSubmitted;
    }
    return value;
  }

  onSubmitClick() {
    if(this.appointmentAssistanceForm.valid) {
      const dateValue = this.getField('contactedDate')?.value ? this.getField('contactedDate')?.value.format("MM/DD/YYYY") : undefined;
      const requestObject: AppointmentAssistance = {
        address: this.getEmailBodyContent(this.getField('location')?.value),
        contactedDate: this.getEmailBodyContent(dateValue),
        emailAddress: this.getEmailBodyContent(this.getField('email')?.value),
        firstName: this.getEmailBodyContent(this.getField('firstName')?.value),
        hasTransportationArrangement: this.getEmailBodyContent(this.getField('transportationArrangementRadioVal')?.value),
        isAlreadyContacted: this.getEmailBodyContent(this.getField('alreadyContactedRadioVal')?.value),
        lastName: this.getEmailBodyContent(this.getField('lastName')?.value),
        memberId: this.getEmailBodyContent(this.getField('memberId')?.value),
        phoneNumber: this.getEmailBodyContent(this.getField('phoneNumber')?.value.phone),
        providerType: this.getEmailBodyContent(this.getField('providerType')?.value)
      }

      this.appointmentAssistanceServices.submitAppointmentAssistanceRequest(requestObject).subscribe(
        (res: any) => {
          this.isSubmitted = true;
          this.showError = false;
          this.scrollToTop();
        },
        err => {
          if (this.error === undefined && err !== undefined && this.errorMessages !== undefined) {
            this.error = this.errCodeSvc.errorCodeMapper(err, this.errorMessages);
          }
          this.showError = true;
          this.scrollToTop();
        }
      );
    } else {
      this.appointmentAssistanceForm.markAllAsTouched();
    }
  }
  // Method used to scroll top of the page
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
