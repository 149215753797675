import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PharmacyProfileComponent } from '@anthem/mbrportal-pharmacy/components/pharmacyProfileCmp';
import { VendorMedicalProfileService } from '@anthem/mbrportal-profile/services/vendorMedicalProfileSvc';
import { AjaxRequest, Features, checkFeatureFlag } from '@anthem/mbrportal/common';
import { ContentHelper, EntryComponent, EventHandler } from '@anthem/mbrportal/core';
import { IHttpResponse } from '@anthem/mbrportal/http';
import { AppSession, IAppSession } from '@anthem/mbrportal/main';
import { BaseComponent, ScrollToViewSvc, SidePanelComponent } from '@anthem/mbrportal/shared';
import { Logger, NavigationHelper } from '@anthem/mbrportal/utility';
import { DeliveryAddress, Profile, State } from '@sydney/models';
import { Subscription } from 'rxjs';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { SessionStorage } from 'sydmed/libs/storage-service/src/public-api';
import { PatientDetails } from '@anthem/mbrportal-pharmacy/interfaces/pharmacyProfile';
import { TitleCasePipe } from '@angular/common';
import { DeliveryAddressSummaryState, LayoutType, PharmacyProfileAddressSvc, VendorDeliveryAddressSummaryComponent } from '@anthem/mbrportal-profile';
import { CommunicationPreferencesSvc } from '@anthem/mbrportal-profile/services/communicationPreferencesSvc';


@Component({
    providers: [
        TitleCasePipe,
        { provide: VendorMedicalProfileService, useClass: ahdPharmacyService },
        { provide: ContentHelper, useClass: AHDContentHelper },
    ],
    selector: 'app-ahd-pharmacy-profile',
    styleUrls: [
        '../../../../../../node_modules/@anthem/mbrportal-pharmacy/assets/styles/partials/pharmacyProfile.scss',
        './ahd-pharmacy-profile.component.scss'
    ],
    templateUrl: `../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/pharmacyProfile.html`
})
export class AhdPharmacyProfileComponent extends PharmacyProfileComponent implements OnInit {
    constructor(
        logger: Logger,
        contentHelper: ContentHelper,
        navHelper: NavigationHelper,
        vendorMedicalService: VendorMedicalProfileService,
        @Inject(AppSession) public appSession: IAppSession,
        eventHandler: EventHandler,
        scrollToViewSvc: ScrollToViewSvc,
        protected titleCasePipe: TitleCasePipe,
        // protected pharmacyProfileAddressSvc: PharmacyProfileAddressSvc
    ) {
        super(
            logger,
            contentHelper,
            navHelper,
            vendorMedicalService,
            appSession,
            eventHandler,
            scrollToViewSvc
        );
    }


    ngOnInit() {
        // isAhdEligible has been replaced by showPharmacyProfile on commercial
        this.showPharmacyProfile = true;
        this.pharmacyProfileAjaxLoader = this.ajaxRequest.GET_SUCCESS;
        this.patientDetails = this.getMemberInfo();

        this.deliveryAddressSummaryState = {
            content: this.content.deliveryAddress,
            isEditMode: true,
            layout: LayoutType.COLUMN
        };

        this.communicationPreferencesRestrictions = {
            showCommunicationChannelCode: true,
            layout: LayoutType.COLUMN
        }
    }


    protected getMemberInfo(): PatientDetails {
        const { memberName, mbrUid } = JSON.parse(sessionStorage.getItem(SessionStorage.MEMBER_CONTEXT));
        const { firstName, lastName } = memberName;
        return {
            memberUid: mbrUid,
            fullName: this.titleCasePipe.transform(`${firstName} ${lastName}`),
            firstName,
            lastName
        };
    }

}