import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "sydmed/libs/authorization/route-guard.service";
import { PatientPayComponent } from "./patient-pay/patient-pay.component";

const routes: Routes = [
  {
    path: "secure/patient-pay",
    component: PatientPayComponent,
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientPayRoutingModule {}
