<div class="container liveChatContainer">
    <div>
        <p class="margin-bottom-40">{{ labels?.liveChatContentLine }} <strong>{{labels?.liveChatHeader}}</strong></p>
        <div class="uxd-theme-motif motif-margin-top-7 motif-margin-bottom-7" *ngIf="showError;else Loading">
            <div data-uxd-alert-cmp-v2 class="a-message" [alert]="{ alertType: 'negative' }">
              <span [innerHTML]="labels?.LiveChatApiFail"></span>
            </div>
        </div>  
        <ng-template #Loading>
            <uxd-spinner class="updating-loader" *ngIf="isLoading"></uxd-spinner>
        </ng-template>
        <div class="availableTopics-grid">
            <ng-container *ngFor="let availableTopic of availableTopics;">
                <div class="availableTopics-grid-item">
                    <div class="custom-grid-tile padding-bottom-20">
                        <a (click)="startChat($event, availableTopic)" [ngClass]="{'disabled': hasTopicsDisabled}"
                        [attr.data-analytics] = "availableTopic.analytics">{{ availableTopic.topic }}</a>
                            <p class="motif-margin-top-2">{{availableTopic.chatHours.regularHoursOfOperation[0].scheduledDay}}: {{availableTopic.chatHours.regularHoursOfOperation[0].openTime|timeFormatTO12Hours}} {{availableTopic.chatHours.regularHoursOfOperation[0].timeZone}} - {{availableTopic.chatHours.regularHoursOfOperation[0].closeTime|timeFormatTO12Hours}} {{availableTopic.chatHours.regularHoursOfOperation[0].timeZone}}</p>
                            <p *ngIf="showCarelonRxTopic && availableTopic.topic === 'CarelonRx Pharmacy'">{{labels?.carelonRxPharmacyDescription}}</p>
                            <p *ngIf="showCarelonRxTopic && availableTopic.topic === 'Pharmacy Benefits and Claims'">{{labels?.benefitsQuestionDescription}}</p>
                    </div>
                </div>     
            </ng-container>
        </div>
    </div>  
</div>      