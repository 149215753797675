import { Router } from '@angular/router';
import { Component, Inject, OnInit } from "@angular/core";
import { checkFeatureFlag, Features } from "@anthem/mbrportal/common";
import {
  ContentHelper,
  EntryComponent,
  EventHandler,
} from "@anthem/mbrportal/core";
import { AppSession, IAppSession } from "@anthem/mbrportal/main";
import { BaseComponent, PersonalizationRuleHelper } from "@anthem/mbrportal/shared";
import { Logger, NavigationHelper } from "@anthem/mbrportal/utility";
import {
  PrescriptionContent,
  BenefitOrdersContent,
} from "@anthem/mbrportal-pharmacy/components/prescriptionBenefitOrdersCmp.content";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { forkJoin } from "rxjs";
import { PrescriptionBenefitOrdersComponent } from "@anthem/mbrportal-pharmacy/components/prescriptionBenefitOrdersCmp";
import { SessionStorage } from "sydmed/libs/storage-service/src/public-api";
import { PrescriptionBenefitOrdersContent, UrlsByPlanCode, BenefitOrdersContentKey, AHDBenefitOrderContent } from "../models/prescriptionBenefitOrdersContent";
import { LeftNavigation, UrlType } from "../models/LeftNavigation";
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';

@Component({
  selector: "app-ahd-prescription-benefit-orders",
  templateUrl:
    "../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/prescriptionBenefitOrdersCmp.html",
  styleUrls: [
    '../../../../../../node_modules/@anthem/mbrportal-pharmacy/assets/styles/partials/prescriptionBenefitOrders.scss','./ahd-prescription-benefit-orders.component.scss'
  ],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }],
})
export class AhdPrescriptionBenefitOrdersComponent extends PrescriptionBenefitOrdersComponent {
  benefitsUrl: string;
  urlType: UrlType;

  constructor(
    protected router: Router,
    navhelper: NavigationHelper,
    logger: Logger,
    contentHelper: ContentHelper,
    protected jsonSvc: JsonContentService,
    personalHelper: PersonalizationRuleHelper,
    @Inject(AppSession) public appSession: IAppSession,
    protected eventHandler: EventHandler
  ) {
    super(navhelper, logger, contentHelper, personalHelper, appSession, eventHandler);
  }

  ngOnInit(): void {
    this.getLabels();
    //  checkFeatureFlag(this.appSession, Features.HOME_DELIVERY);
  }

  getPharmacyBenefitsUrl(urlsByPlanCode: UrlsByPlanCode) {
    const memberContext = JSON.parse(sessionStorage.getItem(SessionStorage.MEMBER_CONTEXT));
    const planCode = memberContext.planCode;
    const url = urlsByPlanCode[planCode];
    return url;
  }
  getLabels(): void {
    this.jsonSvc
      .getJSON("ahd-prescription-benefit-orders")
      .subscribe((data) => {
        const {urlsByPlanCode, urlType } = data;
        this.urlType = urlType;
        this.benefitsUrl = this.getPharmacyBenefitsUrl(urlsByPlanCode);
        data.benefitOrdersContent.forEach((benefitOrderContent: AHDBenefitOrderContent) => {
          if (benefitOrderContent.key === BenefitOrdersContentKey.PHARMACY_BENEFITS) {
            benefitOrderContent.callToAction.redirectionUrl = this.benefitsUrl;
          }
        })
        this.benefitOrdersContent = data.benefitOrdersContent;
        this.isAhdEligibleMember = true;
      });
  }
  navigatePbm(url: string) {
    if(this.urlType === UrlType.EXTERNAL && url === this.benefitsUrl) {
        window.open(url, "_blank");
    } else {
      this.router.navigateByUrl(url);
    } 
  }
}

