import { Component, OnInit, Input } from '@angular/core';
import { PabOverview } from '../interfaces/PharmacyDashboardContent';


@Component({
  selector: 'app-account-balance-overview',
  templateUrl: './account-balance-overview.component.html',
  styleUrls: ['./account-balance-overview.component.scss']
})
export class AccountBalanceOverviewComponent implements OnInit {
  @Input() labels: PabOverview;

  constructor() { }

  ngOnInit(): void {
    if (typeof(this.labels.MemberServicePhone) === 'object'){
      this.labels.MemberServicePhone = this.labels.MemberServicePhone[JSON.parse(sessionStorage.getItem('sydMedMemberContext')).planCode];
    }

  }

}
