<div class="support-forms-container" *ngIf='!loading'>
		<div class="container">
			<div class="row">
                <div class="support-forms-top">
                    <div class="text-style-1">
                        {{content.labels.support}}<a href="{{ content.labels.memberservicesurl }}" >{{content.labels.memberservices}}</a>
                    </div>
                </div>
                <div class="support-forms-headline">
                    {{content.labels.heading}}
                </div>
                <ul>
                     <li><a href='{{ content.links.url1 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link1 }}</a></li>
                     <li><a href='{{ content.links.url2 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link2 }}</a></li>
                     <li><a href='{{ content.links.url3 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link3 }}</a></li>
                     <li><a href='{{ content.links.url4 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link4 }}</a></li>
                     <li><a href='{{ content.links.url5 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link5 }}</a></li>
                     <li><a href='{{ content.links.url6 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link6 }}</a></li>
                     <li><a href='{{ content.links.url7 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link7 }}</a></li>
                     <li><a href='{{ content.links.url8 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link8 }}</a></li>
                     <li><a href='{{ content.links.url9 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link9 }}</a></li>
                     <li><a href='{{ content.links.url10 }}' class="support-forms-doc" target="_blank" rel="noopener noreferrer">{{ content.links.link10 }}</a></li>
                </ul>                     
                <div class=support-forms-end-links>&nbsp;</div>
        </div>
    </div>
</div>