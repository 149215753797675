<div class="expand-bg">
    <div class="container ant-content-wrapper" role="region">
        <div class="container" *ngIf="isLoading">
            <div id="search-loader">
                <uxd-spinner></uxd-spinner>
            </div>
        </div>
        <div class="details-head">
            <div class="print-box">
                <button class="btn btn-secondary" *ngIf="paymentDetails.ConfirmationNumber" [attr.aria-label]="content.CancelPayment" (click)="handleClick(paymentDetails)">{{content.CancelPayment}}
                    <span class="motif-icon motif-cancel" aria-hidden="true"></span>
                </button>
                <button *ngIf="!isMobile" class="btn btn-secondary" [attr.aria-label]="content.PrintDetails" (click)="printOrDownload()">{{content.PrintDetails}}
                    <span class="motif-icon motif-print" aria-hidden="true"></span>
                </button>
            </div>
        </div> 
        <div class="payment-print-details" #paymentDetailsBlock>
            <div class="print-only" aria-hidden="true">
                <h3>{{content.HeaderMessage}}</h3>
                <div class="heading-row">
                    <span class="paid">{{content.PaidOn}}</span>
                    <span class="account">{{content.AccountType}}</span>
                    <span class="amount">{{content.Amount}}</span>
                    <span class="status">{{content.Status}}</span>
                </div>
                <div class="details-row">
                    <span class="paid">{{ paymentDetails.PaymentDate }}</span>
                    <span class="account">{{ paymentDetails.AccountType }}</span>
                    <span class="amount">{{ paymentDetails.PaymentAmount | currency:'USD' }}</span>
                    <span class="status">{{ paymentDetails.Status }}</span>
                </div>
            </div>
            <div class="detail-box">
                <h4 class="section-header-three" [attr.aria-label]="content.PaymentDetails">{{content.PaymentDetails}}</h4>                
                <div class="inline-alert-grid-container no-print" *ngIf="!paymentDetails?.PdfBillAvailable && showInvoice">
                    <span class="a-icon motif-icon motif-delete"></span>
                    <p class="a-message">{{content.InvoiceMissingWarning1}} <a routerLink="{{content.InvoiceMissingLink}}">{{content.InvoiceMissingWarning2}}</a> {{content.InvoiceMissingWarning3}}</p>
                </div>
                <div class="payment-detail-grid-container" role="row">
                    <div class="benefit-period" role="cell">
                        <h4 class="" role="columnheader">{{content.BenefitPeriod}}</h4>
                        <p class="ant-font-r mb-lh" [attr.aria-label]="paymentDetails.BenefitPeriod">{{paymentDetails.BenefitPeriod}}</p>
                    </div>
                    <div class="member-id" role="cell">
                        <h4 class="" role="columnheader">{{content.MemberId}}</h4>
                        <p class="ant-font-r mb-lh" [attr.aria-label]="paymentDetails.MemberId">{{paymentDetails.MemberId}}</p>
                    </div>
                    <div class="payment-source" role="cell">
                        <h4 class="" role="columnheader">{{content.PaymentSource}}</h4>
                        <p class="ant-font-r mb-lh" [attr.aria-label]="paymentDetails.PaymentMode">{{paymentDetails.PaymentMode}}</p>
                    </div>
                    <div *ngIf="showInvoice" class="view-invoice" role="cell">
                        <button class="btn btn-secondary no-print" [attr.aria-label]="content.ViewInvoice" [disabled]="!paymentDetails?.PdfBillAvailable || isLoading" (click)="viewInvoice(paymentDetails.PdfBillAvailable)">{{content.ViewInvoice}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
<ng-template #cancelPaymentAlert>
    <div class="delete-modal">
        <button class="close motif-icon motif-delete" aria-label="Close Modal" (click)="onClose()">            
        </button>
        <div class="modal-header">
            <h2>{{content.CancelPayment}}</h2>
        </div>
        <div class="modal-body">
            <p>{{content.CancelPaymentConfirm}}</p>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" [attr.aria-label]="content.NoDontCancel" (click)="onClose()">{{content.NoDontCancel}}</button>
            <button class="btn continue" [attr.aria-label]="content.YesCancel" (click)="confirmRemove()">{{content.YesCancel}}</button>
        </div>
    </div>
</ng-template>