import { Confirmation } from './../../pharmacy-pay-account-balance/interfaces/PayAccountBalanceContent';
import { labels } from './../../support-forms/support-forms-content';
import { SpecialtyQuestion } from './../models/SpecialtyQuestion';
import { Injectable } from '@angular/core';
import { Step } from './progress-bar-step';
import { Observable, BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { PharmacySessionService } from 'sydmed/src/app/secure/pharmacy/services/pharmacy-session.service';
import { ProgressBarStates } from 'sydmed/src/app/secure/pharmacy/enums/ProgressBarStates';
import { ProgressSteps as Labels } from 'sydmed/src/app/secure/pharmacy/interfaces/PrescriptionOrderContent';

export const enum PharmacyPaths {
  Confirmation = 'confirmation',
  MedicalProfile = 'healthcheck',
  ShipmentReview = 'pbm',
  SpecialtyReview = 'specialty',
  Questionnaire = 'specialtyquestionnaire'
}
@Injectable( {
  providedIn: 'root'
} )
export class ProgressBarService {
  protected readonly sessionKey = 'pharmacy-checkout-steps';
  private currentPath: string;
  private steps$: BehaviorSubject<Step[]> = new BehaviorSubject<Step[]>( null );
  public progressSteps: Observable<Step[]> = this.steps$.asObservable();
  medicalProfileLabel: string;
  specialtyQuestionsLabel: string;
  shipmentReviewLabel: string;
  specialtyReviewLabel: string;
  confirmationLabel: string;
  content: Labels;
  constructor(
    protected location: Location,
    protected pharmacySession: PharmacySessionService
  ) {}

  protected addStepsToSession(steps: Step[]) {
    if ( this.pharmacySession.isValidSession() ) {
      sessionStorage.setItem( this.sessionKey, JSON.stringify( steps ) );
    }
  }
  public getStepKeyByValue(value: string) {
    return Object.keys(this.content).find(key => this.content[key] === value);
  }
  protected buildNewStepArray(content: Labels, medicalProfileComplete: boolean, lineOfBusiness: string) {
    let steps: Step[] = [];
    switch ( lineOfBusiness ) {
      case 'PBM':
        if (medicalProfileComplete === false || medicalProfileComplete === null) {
          steps.push( new Step( this.medicalProfileLabel, ProgressBarStates.ACTIVE, this.getStepKeyByValue(this.medicalProfileLabel) ) );
          steps.push( new Step( this.shipmentReviewLabel, ProgressBarStates.INACTIVE,this.getStepKeyByValue(this.shipmentReviewLabel) ) );
          steps.push( new Step( this.confirmationLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.confirmationLabel) ) );
        } else {
          steps.push( new Step( this.shipmentReviewLabel, ProgressBarStates.ACTIVE, this.getStepKeyByValue(this.shipmentReviewLabel) ) );
          steps.push( new Step( this.confirmationLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.confirmationLabel) ) );
        }
        return steps;
      case 'SPECIALTY':
        steps.push( new Step( this.specialtyQuestionsLabel, ProgressBarStates.ACTIVE, this.getStepKeyByValue(this.specialtyQuestionsLabel)) );
        steps.push( new Step( this.specialtyReviewLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.specialtyReviewLabel) ) );
        steps.push( new Step( this.confirmationLabel, ProgressBarStates.INACTIVE,this.getStepKeyByValue(this.confirmationLabel)) );
        return steps;
      case 'BOTH':
        if (medicalProfileComplete === false || medicalProfileComplete === null) {
          steps.push( new Step( this.medicalProfileLabel, ProgressBarStates.ACTIVE, this.getStepKeyByValue(this.medicalProfileLabel) ) );
          steps.push( new Step( this.specialtyQuestionsLabel, ProgressBarStates.INACTIVE,this.getStepKeyByValue(this.specialtyQuestionsLabel) ) );
          steps.push( new Step( this.shipmentReviewLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.shipmentReviewLabel) ) );
          steps.push( new Step( this.specialtyReviewLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.specialtyReviewLabel) ) );
          steps.push( new Step( this.confirmationLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.confirmationLabel) ) );
        } else {
          steps.push( new Step( this.specialtyQuestionsLabel, ProgressBarStates.ACTIVE, this.getStepKeyByValue(this.specialtyQuestionsLabel) ) );
          steps.push( new Step( this.shipmentReviewLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.shipmentReviewLabel) ) );
          steps.push( new Step( this.specialtyReviewLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.specialtyReviewLabel) ) );
          steps.push( new Step( this.confirmationLabel, ProgressBarStates.INACTIVE, this.getStepKeyByValue(this.confirmationLabel) ) );
        }
        return steps;
      default:
       return steps = [];
    }
  }
  protected getPath(): string {
    this.currentPath = this.location.path();
    return this.currentPath.split( '/' ).pop();
  }
  protected setStepLabels(content: Labels) {
    this.content = content;
    this.medicalProfileLabel = content.MedicalProfile;
    this.specialtyQuestionsLabel = content.SpecialtyQuestionnaire;
    this.shipmentReviewLabel = content.ShipmentReview;
    this.specialtyReviewLabel = content.SpecialtyShipmentReview;
    this.confirmationLabel = content.Confirmation;
  }
  buildProgressArray(content: Labels, medicalProfileComplete: boolean, lineOfBusiness: string ): Step[] {
    this.setStepLabels(content);
    let steps: Step[] = this.getStepsInSession();
    if (!steps || steps.length === 0) {
      steps = this.buildNewStepArray(content, medicalProfileComplete, lineOfBusiness);
    }
    this.setSteps( steps );
    return this.updateProgressBar();
  }
  updateProgressBar() {
    if ( !this.steps$.getValue() ) {
      return [] as Step[];
    }
    const steps = this.steps$.getValue();
    const path = this.getPath();
    const currentLabel = this.getLabelFromPath( path );
    const currentStepIndex = steps.findIndex( step => step.label === currentLabel );
    const updatedSteps = this.updateStepStates( steps, currentStepIndex );
    this.setSteps( updatedSteps );
    return updatedSteps;
  }

  protected updateStepStates( steps: Step[], currentStepIndex: number ) {
    if (currentStepIndex === steps.length - 1) {
      return this.completeProgress(steps);
    }
    return this.update(steps, currentStepIndex);
  }

  protected getLabelFromPath( path: string ) {
    switch ( path ) {
      case PharmacyPaths.Confirmation: return this.confirmationLabel;
      case PharmacyPaths.MedicalProfile: return this.medicalProfileLabel;
      case PharmacyPaths.ShipmentReview: return this.shipmentReviewLabel;
      case PharmacyPaths.SpecialtyReview: return this.specialtyReviewLabel;
      case PharmacyPaths.Questionnaire: return this.specialtyQuestionsLabel;
      default: return '';
    }
  }

  protected getStepsInSession(): Step[] {
    const sessionArray = JSON.parse( sessionStorage.getItem( this.sessionKey ) ) || [];
    return sessionArray.map(step => new Step(this.content[step._id], step._state, step._id));
  }

  protected setSteps( steps: Step[] ) {
    this.steps$.next( steps );
    this.addStepsToSession(steps);
  }
  resetProgressSteps() {
    this.steps$.next( null );
  }

  protected completeProgress(steps: Step[]) {
    return steps.map(step => {
      step.setState(ProgressBarStates.COMPLETE);
      return step;
    });
  }

  public removeStep(stepPath: string) {
    const label = this.getLabelFromPath(stepPath);
    const steps = this.steps$.getValue() || [];
    const updatedSteps = steps.filter(step => step.label !== label);
    this.setSteps(updatedSteps);
    this.addStepsToSession(updatedSteps);
  }

  protected update(steps: Step[], currentStepIndex: number) {
    return steps.map( ( step, index ) => {
      if ( index === currentStepIndex ) {
        step.setState( ProgressBarStates.ACTIVE );
      } else if ( index < currentStepIndex ) {
        step.setState( ProgressBarStates.COMPLETE );
      } else {
        step.setState( ProgressBarStates.INACTIVE );
      }
      return step;
    } );
  }

  clearProgressBar() {
    this.setSteps([]);
    sessionStorage.removeItem(this.sessionKey);
  }


}
