<div class="vision-benefits">
    <h2 class="section-header-two">{{content.title}}</h2>
    <div class="benefits-description">
        <p class="vision-subtitle">{{content.subtitle}}</p>
        <ul *ngIf="content.benefitList" class="vision-benefit-list">
            <li *ngFor="let benefit of content.benefitList">
                {{benefit}}
            </li>
        </ul>
    </div>
    <p class="contact-info" [innerHTML]="content.contactInformation" [attr.data-analytics]="content.analytics.visionPlanLink"></p>
</div>