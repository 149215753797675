import { Component, OnInit } from "@angular/core";
import { AHDLandingPageContent } from "../models/ahd-landing-page.model";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { Subscription, forkJoin } from "rxjs";
import { LeftNavigationService } from "../services/left-navigation.service";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/public-api";
import { AHDContentHelper } from "sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper";
import { IPrescriberSearchCmpContent } from "@anthem/mbrportal-pharmacy/interfaces/iPrescriptionv2";
import { ContentHelper } from "@anthem/mbrportal/core";
import { AppSession } from "@anthem/mbrportal/main";
import { BioPlusBannerContent } from "../models/ahd-landing-page.model";
import { Features } from "@anthem/mbrportal/common";
import { Restriction } from "gbd-models";
//import { PharmacyTabbedViewComponent } from '@anthem/mbrportal-pharmacy/components/pharmacyTabbedViewCmp'
@Component({
  selector: "app-pharmacy-landing-page",
  templateUrl: "./ahd-pharmacy-landing.component.html",
  styleUrls: ["./ahd-pharmacy-landing.component.scss"],
  providers: [{ provide: ContentHelper, useClass: AHDContentHelper }]
})
export class PharmacyLandingPageComponent implements OnInit {
  bioPlusBanner: BioPlusBannerContent;
  isLoading: boolean = true;
  profileContent: any;
  content: any;
  landingPageContent: any;
  contentSubscription: Subscription;
  showBioPlusBanner: boolean = false;
  bioPlusAlertMessage: string;
  showBioPlusPaymentAlert: boolean;

  constructor( 
    private jsonSvc: JsonContentService,
    private leftNavigationService: LeftNavigationService,
    private titleService: InterCommunicationService,
    private contentHelper: AHDContentHelper,
    private _appSession: AppSession,
  ) {}

  ngOnInit(): void {
    //Adding this as appSession is coming undefined when coming to ahd-pharmacy from Engagement programs. 
    this._appSession.metaData = {
      mbrUid: '',
      brandCd: '',
      features: [Features.AHD_VIEW_PRESCRIPTION_NAVIGATION],
      eligibility: [],
    } as any;
  
    this.leftNavigationService.isMini = true;
    this.leftNavigationService.receivedOrderData.subscribe((data) => {
      if (data) {
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
    });
    this.getLabels();
  }

  getLabels() {
    this.contentSubscription = forkJoin([
      this.jsonSvc.getJSON('ahd-prescription-home'), 
      this.jsonSvc.getJSON('ahd-order-status'), 
      this.jsonSvc.getJSON('ahd-pharmacy-landing-page'),
      this.jsonSvc.getJSON('ahd-my-orders-page'),
      this.jsonSvc.getJSON('ahd-review-orders-page'),
      this.jsonSvc.getJSON('ahd-pharmacy-profile')
    ]).subscribe(([prescriptionHomeContent, orderStatusContent, landingPageContent, myOrdersContent, orderDetailsPageContent, profileContent]) => {
          this.content = prescriptionHomeContent;
          this.landingPageContent = landingPageContent;
          this.bioPlusAlertMessage = this.landingPageContent.bioPlus.alertMessage
          this.bioPlusBanner = this.landingPageContent.bioPlusBanner;
          if (this.bioPlusBanner) {
            this.showBioPlusBanner = true;
          }
          this.showBioPlusPaymentAlert = !this.jsonSvc.hasRestriction(Restriction.SHM_BIO_PLUS, this.jsonSvc.getRestrictions());
          this.profileContent = profileContent.pharmacyProfileCmp;
          const deliveryAddressContent = this.profileContent.deliveryAddress;
          this.titleService.raiseEvent({ message: landingPageContent.pageTitle, title: 'HEADER_TITLE'});
          this.contentHelper.registerContent('PharmacyProfileComponent', this.profileContent);
          this.contentHelper.registerContent('PrescriptionHomeComponentV2', this.content.PrescriptionHomeComponentV2);
          this.contentHelper.registerContent('CheckOrderStatusComponent', myOrdersContent.CheckOrderStatusComponent);
          this.contentHelper.registerContent('PrescriberSearchComponent', myOrdersContent.PrescriberSearchComponent);
          this.contentHelper.registerContent('ReviewOrderComponent', orderDetailsPageContent.ReviewOrderComponent);
          this.contentHelper.registerContent('VendorCurrentOrderStatusComponent', orderStatusContent);
          this.contentHelper.registerContent('VendorContactDetailsComponent', this.profileContent.contactDetails);
          this.contentHelper.registerContent('VendorManageSettingsComponent', this.profileContent.manageCommunicationSettings);
          this.contentHelper.registerContent('VendorAddressComponent', deliveryAddressContent.deliveryAddressSlider);
          this.contentHelper.registerContent('VendorPageHeaderComponent', landingPageContent.VendorPageHeaderComponent);        
        });
  }

  ngOnDestroy(){
    this.leftNavigationService.isMini = false;
    this.contentSubscription.unsubscribe();
  }

  closeAlert() {
    this.showBioPlusBanner = false;
  } 

  closeBioAlert() {
    this.showBioPlusPaymentAlert = false;
  }

}