import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CareTeamService {

  constructor(private httpSvc: HttpClientService) {}

  /**
   * @description getCareCoordinator() is fetching care coordinator details
   * @retruns {Observable<GetCareCoordinatorAPIResponse[]>}
   */
  public getCareCoordinator(): Observable<any[]>  {
    return this.httpSvc.getObservable(UrlProperties.UrlProperties.endPoints.careCoordinator.getCareCoordinator)
  }

  public getCurrentPCP(): Observable<any[]>  {
    return this.httpSvc.getObservable(UrlProperties.UrlProperties.endPoints.pcp.viewPcp)
  }

}