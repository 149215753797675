import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/lib/inter-communication.service";
import { JsonContentService } from "sydmed/src/app/sydmed-shared/content-service/json-content.service";
import { AlertService, InlineAlertContainerComponent } from "@anthem/uxd/alert";
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { UrlProperties } from "sydmed/libs/url-properties/src/public-api";
import { MemberObligationResponse } from "gbd-models";

@Component({
  selector: "app-patient-pay",
  templateUrl: "./patient-pay.component.html",
  styleUrls: ["./patient-pay.component.css"],
})
export class PatientPayComponent implements OnInit {
  public labels: any;
  public errorMsgs: any;
  @ViewChild("errorAlert") errorAlert: InlineAlertContainerComponent;
  public obligations: MemberObligationResponse;
  public serviceError: boolean = false;
  public currentMonthYear: Date = new Date();

  constructor(
    private interCommunicationService: InterCommunicationService,
    private dataSvc: JsonContentService,
    public alert: AlertService,
    private cdrRef: ChangeDetectorRef,
    private httpClient: HttpClientService
  ) {
    this.interCommunicationService.raiseEvent({
      title: "HEADER_TITLE",
      message: "Patient Pay",
    });
  }

  ngOnInit(): void {
    this.dataSvc.getJSON("patient-pay").subscribe((data) => {
      this.labels = data.patientPay.labels;
      this.errorMsgs = data.patientPay.labels.errorMessages;
      this.callPatientPayService(data.patientPay.labels.errorMessages);
    });
  }

  callPatientPayService(jsonErrorMessage) {
    this.httpClient
      .getObservable(UrlProperties.UrlProperties.endPoints.patientPay)
      .subscribe(
        (res) => {
          if (res) {
            this.obligations = res.body;
            this.serviceError = false;
          }
        },
        (err: any) => {
          this.serviceError = true;
          this.cdrRef.detectChanges();
          if (this.errorAlert) {
            this.errorAlert.dismissAlertId(0);
            this.alert.error(jsonErrorMessage.errorScheduleLater, {
              regionName: "inline-alert",
            });
          }
        }
      );
  }
}
