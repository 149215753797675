export const TITLES = [
    {
        englishTitle: "Appeal Form",
        spanishTitle: "Formulario de Apelación"
    },
    {
        englishTitle: "Grievance Form",
        spanishTitle: "Formulario de Reclamo"
    },
    {
        englishTitle: "Grievance / Appeal Form",
        spanishTitle: "Formulario de Reclamo / Apelación"
    },
    {
        englishTitle: "My Care Team",
        spanishTitle: "Mi Equipo de Atención"
    },
    {
        englishTitle: "Claims",
        spanishTitle: "Reclamación"
    },
    {
        englishTitle: "Community Resources",
        spanishTitle: "Recursos Comunitarios"
    },
    {
        englishTitle: "Contact Us",
        spanishTitle: "Contacto"
    },
    {
        englishTitle: "My Family Health Record",
        spanishTitle: "Expediente Médico Familiar"
    },
    {
        englishTitle: "Aim High, Score Big",
        spanishTitle: "Apunta Alto, Anota Grande"
    },
    {
        englishTitle: "Action Plans",
        spanishTitle: "Planes de Acción"
    },
    {
        englishTitle: "Activity Tracking",
        spanishTitle: "Seguimiento de Actividad"
    },
    {
        englishTitle: "Programs",
        spanishTitle: "Programa"
    },
    {
        englishTitle: "My Health Dashboard",
        spanishTitle: "Mi Tablero de Control de la Salud"
    },
    {
        englishTitle: "My Health News",
        spanishTitle: "Mis Noticias de Salud"
    },
    {
        englishTitle: "Benefit Reward Hub",
        spanishTitle: "Centro de recompensas de beneficios"
    },
    {
        englishTitle: "Find a Pharmacy",
        spanishTitle: "Encontrar una Farmacia"
    },
    {
        englishTitle: "Member ID Card",
        spanishTitle: "Tarjeta de Identificación"
    },
    {
        englishTitle: "Message Center",
        spanishTitle: "Centro de Mensajes"
    },
    {
        englishTitle: "Patient Pay",
        spanishTitle: "Pago del Paciente"
    },
    {
        englishTitle: "Primary Medical Provider",
        spanishTitle: "Proveedor Médico Primario"
    },
    {
        englishTitle: "Primary Care Provider",
        spanishTitle: "Proveedor de Cuidado Primario"
    },
    {
        englishTitle: "Primary Care Provider/Primary Care Clinic",
        spanishTitle: "Proveedor de Cuidado Primario/Clínica de Atención Primaria"
    },
    {
        englishTitle: "All Prescriptions",
        spanishTitle: "Todos las Recetas"
    },
    {
        englishTitle: "Pharmacy",
        spanishTitle: "Farmacia"
    },
    {
        englishTitle: "Order Status",
        spanishTitle: "Estado del Pedido"
    },
    {
        englishTitle: "Order Refills",
        spanishTitle: "Pedir Recetas"
    },
    {
        englishTitle: "ZipDrug",
        spanishTitle: ""
    },
    {
        englishTitle: "POWER Account Activity",
        spanishTitle: "Cuenta POWER"
    },
    {
        englishTitle: "Prior Authorization",
        spanishTitle: "Autorizaciones Previa"
    },
    {
        englishTitle: "Profile",
        spanishTitle: "Perfil"
    },
    {
        englishTitle: "Forms",
        spanishTitle: "Formularios"
    },
    {
        englishTitle: "Pharmacy text messaging program",
        spanishTitle: "Programa de mensajes de texto de farmacia"
    },
    {
        englishTitle: "Third Party Insurance",
        spanishTitle: "Seguro de tercero"
    },
    {
        englishTitle: "Treatment Cost Calculator",
        spanishTitle: "Calculadora de costos de tratamiento"
    },
    {
        englishTitle: "Appointment Assistance Request",
        spanishTitle: "Solicitud de asistencia de cita"
    }
];