import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";

import { PowerAccountRoutingModule } from './power-account-routing.module';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { AlertModule } from '@anthem/uxd/alert';
import { TooltipModule } from '@anthem/uxd/tooltip';
import { DropdownModule } from '@anthem/uxd/dropdowns';
import { FilterModule } from '@anthem/uxd/filter';
import { AntDatepickerModule } from '@anthem/uxd/ant-datepicker';
import { AntMomentDateModule } from '@anthem/uxd/ant-datepicker';

import { PowerAccountViewComponent } from './power-account-view/power-account-view.component';
import { PowerAccountSummaryComponent } from './power-account-summary/power-account-summary.component';

import { PowerAccountService } from './services/power-account.service';
import { PowerAccountActivityComponent } from './power-account-activity/power-account-activity.component';
import { PowerAccountMonthlyStmtComponent } from './power-account-monthly-stmt/power-account-monthly-stmt.component';
import { PowerAccountDateFilterComponent } from './power-account-date-filter/power-account-date-filter.component';

@NgModule({
  declarations: [PowerAccountViewComponent, PowerAccountSummaryComponent, PowerAccountActivityComponent, PowerAccountMonthlyStmtComponent, PowerAccountDateFilterComponent],
  imports: [
    CommonModule,
    PowerAccountRoutingModule,
    IndicatorModule,
    AlertModule,
    TooltipModule,
    DropdownModule,
    FormsModule,
    FilterModule,
    AntDatepickerModule,
    AntMomentDateModule
  ],
  exports: [],
  providers: [PowerAccountService]
})
export class PowerAccountModule { }
