import { Component, forwardRef, OnDestroy, ChangeDetectionStrategy, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormBuilder, ControlValueAccessor, NG_VALIDATORS, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { SearchService } from './search.service';

@Component({
  selector: 'lib-search',
  templateUrl: './search.component.html',
  styleUrls: [ './search.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SearchComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements ControlValueAccessor, OnDestroy {

  // TODO: Move this to the pharmacy module

  form: FormGroup;
  subscriptions: Subscription[] = [];
  showSearchIcon = true;
  @Input() searchLabel: string; // Search
  @Input() placeholder: string; // Rx#, Prescriber, Drug Name, Order#
  @Input() searchId: string;
  @Input() errorMessage: any;
  @Input() searchAnalytics: string;

  get value() {
    return this.form.value;
  }

  set value(value) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get search() {
    return this.form.controls.search;
  }

  constructor(private formBuilder: FormBuilder, private searchSvc: SearchService) {
    this.form = this.formBuilder.group({
      search: ['', [FormValidators.MinimumTwoCharValidator]]
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null || value === '') {
      this.clearFilter();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  searchOrders($event) {
    if (this.search.value && this.form.valid) {
      this.searchSvc.searchOrders(this.search.value);
      this.showSearchIcon = false;
    }
  }

  cancelSearch($event) {
    this.clearFilter();
  }

  clearFilter() {
    this.form.reset();
    this.searchSvc.cancelOrders();
    this.showSearchIcon = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  validate(_: FormControl) {
    return this.form.valid ? null : { search: { valid: false, }, };
  }

  onBlur() {
    this.onTouched();
  }

  public resetSearchFilter() {
    if (!this.search.value || this.search.value === '') {
      this.clearFilter();
    }
  }
}
