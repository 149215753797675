<div class="search-results-contact-details-wrapper">
    <h5 class="font-18 font-bold">Food Pantry & Emergency Aid Ministry</h5>
    <div class="search-results-contact-details margin-top-22">
        <div class="result-details-container">
            <address class="margin-0">
                <div class="location-distance-info">
                    <span class="font-20 motif-icon motif-marker margin-right-8"></span>
                    <span>8.08 mi away - </span>
                    <a href="javascript:void(0)" class="font-14 font-bold">{{labels?.direction_link}}</a>
                </div> 
                <div class="margin-top-16">2990 West 71st Street, Indianapolis, IN 46268</div>
                <div class="margin-top-16">
                    <span>{{labels?.phone_label}}</span>
                    <a href="tel:(222) 555-1212" class="font-bold font-14">(222) 555-1212 </a>
                </div>
                <div class="margin-top-5">
                    <span>{{labels?.fax_label}}</span>
                    <a href="fax:(222) 555-1212" class="font-bold font-14">(222) 555-1212</a>
                </div>
                <div class="margin-top-5">
                    <span>{{labels?.email_label}}</span>
                    <a href="mailto:info@fpeam.org" class="font-bold font-14">info@fpeam.org</a>
                </div>
                <div class="margin-top-5">
                    <span>{{labels?.website_label}}</span>
                    <a href="javascript:void(0)" class="font-bold font-14">fpeam.org</a>
                </div>
            </address>
        </div>
        <div class="result-details-container schedule-container">
            <div class="font-bold font-16">
                <span>{{labels?.open_label}}</span>
                <span>8:00am - 4:30pm EST</span>
            </div>
            <ul class="weekly-schedule-list margin-0">
                <li class="font-16">
                  <span>{{labels?.days?.sunday_label}}</span>
                  <span>Closed</span>
                </li>
                <li class="font-16">
                  <span>{{labels?.days?.monday_label}}</span>
                  <span>1:00pm - 4:30pm</span>
                </li>
                <li class="font-16">
                  <span>{{labels?.days?.tuesday_label}}</span>
                  <span>9:00pm - 4:30pm</span>
                </li>
                <li class="font-16">
                  <span>{{labels?.days?.wednesday_label}}</span>
                  <span>9:00pm - 4:30pm</span>
                </li>
                <li class="font-16">
                    <span>{{labels?.days?.thursday_label}}</span>
                    <span>9:00pm - 4:30pm</span>
                </li>
                <li class="font-16">
                    <span>{{labels?.days?.friday_label}}</span>
                    <span>9:00pm - 4:30pm</span>
                </li>
                <li class="font-16">
                    <span>{{labels?.days?.saturday_label}}</span>
                    <span>Closed</span>
                </li>
          </ul> 
        </div>
    </div>
</div>
<div class="search-results-info-card-wrapper margin-top-32">
    <ul class="services-list">
        <li>
            <div class="font-18 font-bold">{{labels?.services_info?.mainServices_label}}</div>
            <p class="font-14 margin-top-8">Help pay for housing, help pay for utilities, emergency food</p>
        </li>
        <li>
            <div class="font-18 font-bold">{{labels?.services_info?.serving_label}}</div>
            <p class="font-14 margin-top-8">Anyone in need, all ages</p>
        </li>
        <li>
            <div class="font-18 font-bold">{{labels?.services_info?.language_label}}</div>
            <p class="font-14 margin-top-8">English</p>
        </li>
        <li>
            <div class="font-18 font-bold">{{labels?.services_info?.cost_label}}</div>
            <p class="font-14 margin-top-8">Free</p>
        </li>
        <li>
            <div class="font-18 font-bold">{{labels?.services_info?.coverage_label}}</div>
            <p class="font-14 margin-top-8">This program covers residents of the following cities: Virginia Beach, VA</p>
        </li>
        <li>
            <div class="font-18 font-bold">{{labels?.services_info?.programs_label}}</div>
            <p class="font-14 margin-top-8"></p>
        </li>
    </ul>
    <div class="font-14 display-flex search-results-notes">
        <span class="font-bold">{{labels?.services_info?.notes_label}}</span>
        <p class="margin-left-4">{{labels?.services_info?.notes_description}}</p>
    </div>
</div>
<div class="width-100 margin-top-32" data-uxd-alert-cmp-v2 [alert]="{alertType: 'info'}">
    <span class="font-bold">{{labels?.alert_lastUpdate_label}}</span>
    <div class="font-16">Jan 19, 2022</div>
</div>