<div uxd-chat-cmp #chatView class="uxd-chat uxd-chat-cmp" 
    showChatbotBool={{showChatbotBool}} unreadMessages="{{unreadMessages}}" role="region" attr.aria-label="{{ labels?.chatHeaderTitle }}"
    ariaLabelChatLauncher="{{ labels?.chatbotIconLabel }}" [style.bottom.px]="chatBotBottomBoundary || 24">
    <div class="uxd-chat-messages" chat-messages-container> 
        <div [ngClass]="{'ant-message-center': isFullHeight(), 'move-end-chat-container': isBottomBar()}" #chatBody
         tabindex="0" (scroll)='onChatBoxScroll($event)' role="log" aria-label="Sydney chat conversation">       
            <app-chatbot [content]="content" *ngIf="!watsonChatbotEnabled" (chatbotOptionSelected)="chatbotRedirect($event)" [isOptionSelected]="isOptionSelected"
            (chatbotFocusInput)="chatbotFocusInput()"></app-chatbot>
            <app-chatbot-watson [content]="content" *ngIf="watsonChatbotEnabled" (transferToLiveChat)="initiateLiveChatFromWatsonChatbot($event)" (disableWatsonChatBot)="disableWatsonChatBot($event)" (chatbotOptionSelected)="chatbotRedirect($event)" [isOptionSelected]="isOptionSelected"
            (chatbotFocusInput)="chatbotFocusInput()" (chatTopicSelected)="initiateDirectLiveChat($event)" (chatbotOptionClicked)="chatbotBubbleClicked($event)"></app-chatbot-watson>
            <app-chat-topics [content]="content" [memberName]="getMemberName()" [refreshCategories]="refreshCategories" 
            (chatTopicSelected)="initiateChat($event)" [locale]="locale" [isChatInitiated]="isChatInitiated" 
            [isTopicSelected]="isTopicSelected" [isRestore]="isRestoreSession" *ngIf="isLiveAgent && !watsonChatbotEnabled"></app-chat-topics>
            
            <div class="back-button-box" *ngIf="(!liveChatWithSelectedTopic || (isLiveAgent && !isChatInitiated))">
                <button class="back-to-topics motif-icon motif-arrows-long-up" (click)="goBackToCategories()">
                    <span>{{ labels?.chatBack }}</span>
                </button>
            </div>
            
            <div class="chat-body" *ngIf="liveChatWithSelectedTopic && isChatInitiated && labels">
                <div class="timestamp">{{ '' | showCurrentTime }}</div>
                <div class="ant-chat-bubble ant-chat-bubble-bot">
                    <p>{{ labels?.chatWelcomeMessage }}</p>
                </div>
                <p class="emergency-info">{{labels?.chatTermsAndConditions}}
                    <br /><a href='{{labels?.chatPrivacyLinkURL}}' target='_blank' rel="noopener noreferrer">{{labels?.chatPrivacyLinkText}}</a>
                     {{labels?.chatPrivacyTermsAnd}} 
                     <a href='{{labels?.chatTermsLinkURL}}' target='_blank' rel="noopener noreferrer">{{labels?.chatTermsLinkText}}</a>
                </p>
                <div *ngIf="showError">
                    <div class="ant-chat-bubble ant-chat-bubble-bot">
                        <div class="error message agent">{{ errorMessage | si:{ errorCode:errorCode } }}</div>
                    </div>
                </div>
                <!-- agent profile box -->
                <div class="agent-box-grid-container agent-box" *ngIf="!showError && agentName">
                    <div class="time-grid-area timestamp">
                        {{ '' | showCurrentTime }}
                    </div>
                    <div class="joined-grid-area">
                        <p>{{agentName}} {{labels?.chatHasJoined}}</p>
                    </div>
                    <div class="logo-grid-area">
                        <div class="name-letter" aria-hidden="true">
                            <div class="name-letter-inner">{{ agentName?.[0]?.toUpperCase()}}</div>
                        </div>
                    </div>
                    <div class="name-grid-area name">
                        {{agentName}}
                    </div>
                    <div class="title-grid-area">
                        <p>{{getChatTitle()}}</p>
                    </div>
                </div>
                <!-- chat messages -->
                <div class="messages">
                    <ng-template ngFor let-message [ngForOf]="messages" let-i="index">
                        <div
                            class="chat-aria-hidden"
                            attr.aria-label="{{message.isAgent ? 'agent' : 'you'}}">
                            {{message.isAgent ? 'agent' : 'you'}}
                        </div>
                        <div class="timestamp" *ngIf="i && messages[i-1].isAgent !== message.isAgent">{{ '' | showCurrentTime }}</div>
                        <div
                            class="ant-chat-bubble"
                            [ngClass]="{'ant-chat-bubble-bot': message.isAgent, 'ant-chat-bubble-user': !message.isAgent, 'middle':isMiddleBubble(i, messages), 'last':isLastBubble(i, messages)}">
                            {{message.text}}
                        </div>
                    </ng-template>
                    <div class="ant-chat-bubble ant-chat-bubble-bot typing" aria-label="agent typing"
                    *ngIf="chatResponse?.conversationMetadata?.isAgentTyping"><div></div></div>
                </div>
                <!-- agent left box -->
                <p class="agent-left" attr.aria-label="{{agentName}} {{labels.chatHasEnded}}"
                    *ngIf="!showError && hasAgentLeft">{{agentName}} {{labels?.chatHasEnded}}
                </p>
                <!-- survey -->
                <div class="survey" role="log" *ngIf="hasSurveyStarted">
                    <ng-template ngFor let-survey [ngForOf]="surveyQuestions" let-i="index">
                        <div
                            class="chat-aria-hidden"
                            attr.aria-label="{{survey.isAgent ? 'agent' : 'you'}}">
                            {{survey.isAgent ? 'agent' : 'you'}}
                        </div>
                        <ng-container *ngIf="survey?.isAgent">
                            <div *ngIf="survey.question"
                                class="ant-chat-bubble ant-chat-bubble-bot" tabindex="0" #surveyQn
                                [ngClass]="{'middle':isMiddleBubble(i, surveyQuestions), 'last':isLastBubble(i, surveyQuestions)}">
                                {{survey.question}}
                            </div>
                            <div class="chat-pill-wrapper" *ngIf="survey?.answers?.length>2">
                                <button class="ant-chat-pill" *ngFor="let ansObj of survey.answers" attr.aria-label="{{ansObj.text}}"
                                    (click)="handleSurveyAnswer(ansObj, survey)"
                                    [disabled]="survey.isDisabled">{{ansObj.text}}
                                </button>
                            </div>                        
                            <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" role="group" attr.aria-labelledby="survey-question-{{i}}" 
                                *ngIf="survey?.answers?.length==2" uxd-select tabindex="0" #surveyQn>
                                <button class="ant-action-list-button" uxd-select-toggle [disabled]="survey.isDisabled"
                                 (click)="handleSurveyAnswer(survey.answers[0], survey)">{{survey.answers[0].text}}</button>
                                <button class="ant-action-list-button" uxd-select-toggle [disabled]="survey.isDisabled"
                                 (click)="handleSurveyAnswer(survey.answers[1], survey)">{{survey.answers[1].text}}</button>
                            </div>
                        </ng-container>
                        <div *ngIf="!survey?.isAgent && survey.userAnswer"
                            class="ant-chat-bubble ant-chat-bubble-user" 
                            [ngClass]="{'middle':isMiddleBubble(i, surveyQuestions), 'last':isLastBubble(i, surveyQuestions)}">
                            {{survey.userAnswer}}
                        </div>
   
                    </ng-template>
                    <div class="ant-chat-bubble ant-chat-bubble-bot typing" aria-label="survey loading"
                    *ngIf="isSurveyQuestionLoading"><div></div></div>
                </div>
                <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" #agentCancel role="group" 
                    aria-labelledby="agent-cancel" *ngIf="showCancelConfirm" uxd-select tabindex="0">
                    <p class="ant-action-list-label" id="agent-cancel">{{labels?.chatCancelConnectConfirm}}</p>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="confirmCancel()">{{labels?.chatYes}}</button>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="dismissCancel()">{{labels?.chatNo}}</button>
                </div>
                <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" #agentDisconnect role="group" 
                    aria-labelledby="agent-disconnect" *ngIf="showEndConfirm" uxd-select tabindex="0">
                    <p class="ant-action-list-label" id="agent-disconnect">{{labels?.chatEndConfirm | si:{ agentName: agentName } }}</p>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="confirmEnd()"
                    aria-label="yes" data-analytics = "endSessionYesLiveChat">{{labels?.chatYes}}</button>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="dismissEnd()"
                    aria-label="no" data-analytics = "endSessionNoLiveChat">{{labels?.chatNo}}</button>
                </div>
                <div class="ant-action-list ant-chat-bubble ant-chat-bubble-bot" role="group" aria-labelledby="session-expiry" *ngIf="shouldShowSessionConfirmation()" uxd-select>
                    <p class="ant-action-list-label" id="session-expiry">{{labels?.chatSessionExpiryMessage}}</p>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="continueWithTheChat()"
                    aria-label="yes" data-analytics = "endSessionYesLiveChat">{{labels?.chatYes}}</button>
                    <button class="ant-action-list-button" uxd-select-toggle (click)="endTheChat()"
                    aria-label="no" data-analytics = "endSessionNoLiveChat">{{labels?.chatNo}}</button>
                </div>
            </div>
        </div>
        <!-- cancel agent connect -->
        <div class="end-chat" *ngIf="!showError && showConnectCancel">
            <div
                class="motif-icon motif-chat-bubble"
                aria-hidden="true">
            </div>
            <div class="agent-info">
                <span class="agent-first-name" attr.aria-label="{{ labels?.chatPleaseWait }}">
                    {{ labels?.chatPleaseWait }}</span>
                <br />
               <span class="agent-title">{{ labels?.chatAgentConnecting }}</span>
            </div>
            <div class="end-chat-btn">
                <span
                    class="motif-icon motif-cancel" role="button" tabindex="0"
                    aria-label="cancel connecting to agent" aria-pressed="false"
                    (click)="cancelConnect()" (keydown.enter)="cancelConnect()">
                </span>
            </div>
        </div>
        <!-- end chat with agent (user end) -->
        <div class="end-chat" aria-label="agent info"
        *ngIf="!hasSurveyStarted && !showError && isChatInitiated && agentName">
            <div
                class="agent-logo"
                aria-hidden="true">
                <div class="agent-logo-inner">
                    <span class="agent-init">{{ agentName?.[0].toUpperCase()}}</span>
                </div>
            </div>
            <div class="agent-info">
                <span class="agent-first-name">{{agentName}}</span>
                <br />
                <span class="agent-title">{{getChatTitle()}}</span>
            </div>
            <div  class="end-chat-btn">
                <span
                    class="motif-icon motif-cancel" role="button" tabindex="0"
                    aria-label="end chat with agent" aria-pressed="false"
                    (click)="disconnectChat()" (keydown.enter)="disconnectChat()">
                </span>
            </div>
        </div>
        <div class="end-chat" aria-label="agent info"
        *ngIf="hasSurveyStarted && !showError && isChatInitiated && surveyName">
            <div
                class="agent-logo"
                aria-hidden="true">
                <div class="agent-logo-inner">
                    <span class="agent-init">{{ surveyName?.[0].toUpperCase()}}</span>
                </div>
            </div>
            <div class="agent-info">
                <span class="agent-first-name">{{surveyName}}</span>
                <br />
                <span class="agent-title">{{getChatTitle()}}</span>
            </div>
            <div class="end-chat-btn">
                <span
                    class="motif-icon motif-cancel" role="button" tabindex="0"
                    aria-label="end chat with agent" aria-pressed="false"
                    (click)="disconnectChat()" (keydown.enter)="disconnectChat()">
                </span>
            </div>
        </div>
        <!-- end of survey confirmation dialog -->
        <div class="end-chat survey-end-chat" *ngIf="hasChatClosed && !watsonChatbotEnabled">

            <div class="motif-icon motif-chat-bubble" aria-hidden="true"></div>
            <div class="survey-end">
                <span class="agent-title">{{labels?.chatDisconnectMessage}}</span>
            </div>
            <div class="end-chat-btn">
                <span
                    class="motif-icon motif-cancel" role="button" tabindex="0"
                    aria-label="close end chat" aria-pressed="false"
                    (click)="goBackToCategories()" (keydown.enter)="goBackToCategories()">
                </span>
            </div>
        </div>
        <div class="ant-message-center-text-area">
            <input class="text-area" placeholder="{{labels?.chatPlaceHolder}}" #chatInput
                attr.aria-label="{{labels?.chatTextBoxLabel}}" [(ngModel)]="userText" (keyup.enter)="$event.stopPropagation(); sendChat()"
                [disabled]="!isReplyAllowed">
            <button class="send" title="{{labels?.chatSendThisMessage}}" attr.aria-label="{{labels?.chatSendThisMessage}}" (keyup.enter)="$event.stopPropagation(); sendChat()" 
            (click)="sendChat()" [disabled]="!isReplyAllowed || userText.trim().length == 0" data-analytics="sendButtonChatbot"></button>
        </div>
        <div class="cards-wrapper" *ngIf="loadingSpinner">
            <div class="card small-card card-flat card-popover">
                <uxd-spinner></uxd-spinner>
                <span class="load-message" [innerHTML]="loaderSpinnerMessage"></span>
            </div>
        </div>
    </div>
</div>
<app-chatbot-toast [showChatToast]="showChatToast" [chatToastBottomBoundary]="chatToastBottomBoundary"
    (chatbotToastDismissed)="dismissToast()" [toastMessage]="toastMessage"></app-chatbot-toast>