import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class oneTimePasscodeModel {

    constructor() { }

    private model: any = {
        contactInfo: {
            text: [{
                id: "",
                unmasked: "",
                display: "",
            }],
            voice: [{
                id: "",
                unmasked: "",
                display: "",
            }],
            email: [{
                id: "",
                unmasked: "",
                display: "",
            }]
        },
        selected: {
            contactId: "",
            contactType: "",
            contactSubType: "",
            contactData: "",
            newPhoneNumber: "",
            maskedData: "",
            zipCode: "",
            addressNumber: ""
        },
        input: {
            securityCode: "",
            rememberDevice: ""
        },
        display: {
            showText: true,
            showVoice: true,
            showEmail: true,
            noContacts: false,
            onlyEmail: false,
        },

        // transformContactInfo: this.transformContactInfo(contachInfo),
        // transformContactInfoV2: this.transformContactInfoV2,
        // setContactInfo: this.setContactInfo,
        // maskEmailAddress: this.maskEmailAddress(contactInfo),
        // maskPhoneNumber: this.maskPhoneNumber,
        // transformToOTPRequest: this.transformToOTPRequest
    };
    private otpRequestDataForArn: any = {
            addressNumber: "",
            phoneNumber: "",
            subType: "",
            type: "",
            value: "",
            zipCode: "",
    }

    public transformContactInfo(contactData, contactId?) {
        let contactInformation: any = [];
        let emailContacts: any = [];
        let textContacts: any = [];
        let voiceContacts: any = [];
        let displayAddress: string = '';
        let displayNumber: string = '';
        let emailIndex: number = 0;

        if (contactData.memberResponse) { //check this properly  ***
            contactInformation = contactData.memberResponse.memberContactDetails;
            contactInformation.forEach((contactInfo: any, x) => {
                if (contactInfo.type === "EMAIL") {
                    displayAddress = this.maskEmailAddress(contactInfo.value);

                    emailContacts.push({
                        id: x + 1,
                        display: (contactId === "ARN") ? contactInfo.maskedValue : displayAddress,
                        unmasked: contactInfo.value,
                        type: contactInfo.type,
                        subType: contactInfo.subType,
                        source: contactInfo.source
                    });
                }
                if (contactInfo.subtype === "TEXT" || contactInfo.subtype === "VOICE" || contactInfo.subType === "TEXT" || contactInfo.subType === "VOICE") {
                    displayNumber = this.maskPhoneNumber(contactInfo.value);

                    if (contactInfo.subtype === "TEXT" || contactInfo.subType === "TEXT") {
                        textContacts.push({
                            id: x + 1,
                            display: (contactId === "ARN") ? contactInfo.maskedValue : displayNumber,
                            unmasked: contactInfo.value,
                            type: contactInfo.type,
                            subType: contactInfo.subtype ?? contactInfo.subType,
                        });
                    } else {
                        voiceContacts.push({
                            id: x + 1,
                            display: (contactId === "ARN") ? contactInfo.maskedValue : displayNumber,
                            unmasked: contactInfo.value,
                            type: contactInfo.type,
                            subType: contactInfo.subtype ?? contactInfo.subType,
                        });
                    }
                }
            });

            this.model.contactInfo.email = emailContacts;
            this.model.contactInfo.text = textContacts;
            this.model.contactInfo.voice = voiceContacts;

            this.model.display.showText = this.model.contactInfo.text.length === 0 ? false : true;
            this.model.display.showEmail = this.model.contactInfo.email.length === 0 ? false : true;
            this.model.display.showVoice = this.model.contactInfo.voice.length === 0 ? false : true;
            this.model.display.noContacts = (!this.model.display.showText && !this.model.display.showEmail && !this.model.display.showVoice);
            this.model.display.onlyEmail = (!this.model.display.showText && !this.model.display.showVoice);
        }
        return this.model;

    }

    private maskEmailAddress(emailAddress) {
        let displayEmailAddress: string;
        let emailIndex: string;
        if (emailAddress !== 'undefined' || emailAddress !== null) {
            displayEmailAddress = emailAddress.substring(0, 1);
            displayEmailAddress = displayEmailAddress + "*****@";
            emailIndex = emailAddress.indexOf("@") + 1;
            displayEmailAddress = displayEmailAddress + emailAddress.substring(emailIndex, emailAddress.length);
        }
        return displayEmailAddress;

    }
    public maskPhoneNumber(phoneNumber) {
        let displayPhoneNumber: string;

        if (phoneNumber !== 'undefined' || phoneNumber !== null) {
            displayPhoneNumber = "***-***-" + phoneNumber.substring(phoneNumber.length - 4, phoneNumber.length);
        }
        return displayPhoneNumber;
    }
    public transformToOTPRequest(contact, contactId?) {
        var otpRequestData = {
            subType: "",
            type: "",
            value: "",
        };
        this.transformCommonRequest(otpRequestData, contact)
        if (contactId === "ARN" && contact.selected?.addressNumber) {
            this.otpRequestDataForArn.addressNumber = contact.selected.addressNumber;
            this.otpRequestDataForArn.phoneNumber = contact.selected.newPhoneNumber;
            this.otpRequestDataForArn.zipCode = contact.selected.zipCode;
            this.otpRequestDataForArn.value = otpRequestData.value;
            this.otpRequestDataForArn.type = otpRequestData.type
            this.otpRequestDataForArn.subType = otpRequestData.subType
            return this.otpRequestDataForArn;
        }
        return otpRequestData;
    }

    public transformCommonRequest(otpRequestData, contact){
        otpRequestData.value = contact.selected.contactData;
        otpRequestData.type = ((contact.selected.contactSubType.toUpperCase() === "TEXT") || (contact.selected.contactSubType.toUpperCase() === "VOICE")) ? "PHONE" : "EMAIL";
        otpRequestData.subType = (contact.selected.contactType !== undefined) ? contact.selected.contactSubType.toUpperCase() : "EMAIL";
        return otpRequestData
    }

    public setSelectedInfoForARN(otpRequest){
            this.model.selected.contactId = "ARN";
            this.model.selected.contactData = otpRequest.value.replaceAll('-', '');;
            this.model.selected.maskedData = this.maskPhoneNumber(otpRequest.phone);
            this.model.selected.contactType = "phone";
            this.model.selected.newPhoneNumber = otpRequest.phone.replaceAll('-', '');;
            this.model.selected.contactSubType = otpRequest.subType;
            this.model.selected.zipCode = otpRequest.zipCode,
            this.model.selected.addressNumber = otpRequest.addressNumber;
            return this.model;
    }

    public setSelectedInfo(modelContactInfo, contactId, contactType) {
        for (let x = 0; x < modelContactInfo.email.length; x++) {
            if (modelContactInfo.email[x].id == contactId) {
                this.model.selected.contactId = modelContactInfo.email[x].id
                this.model.selected.contactType = modelContactInfo.email[x].type;
                this.model.selected.contactSubType = 'EMAIL';
                this.model.selected.contactData = modelContactInfo.email[x].unmasked;
                this.model.selected.maskedData = modelContactInfo.email[x].display;
            }
        }

        for (let x = 0; x < modelContactInfo.text.length; x++) {
            if (modelContactInfo.text[x].id == contactId) {
                this.model.selected.contactId = modelContactInfo.text[x].id
                this.model.selected.contactType = modelContactInfo.text[x].type;
                this.model.selected.contactSubType = modelContactInfo.text[x].subType;
                this.model.selected.contactData = modelContactInfo.text[x].unmasked;
                this.model.selected.maskedData = modelContactInfo.text[x].display;
            }
        }

        for (let x = 0; x < modelContactInfo.voice.length; x++) {
            if (modelContactInfo.voice[x].id == contactId) {
                this.model.selected.contactId = modelContactInfo.voice[x].id
                this.model.selected.contactType = modelContactInfo.voice[x].type;
                this.model.selected.contactSubType = modelContactInfo.voice[x].subType;
                this.model.selected.contactData = modelContactInfo.voice[x].unmasked;
                this.model.selected.maskedData = modelContactInfo.voice[x].display;
            }
        }
        return this.model;
    }
}