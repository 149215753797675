import { Inject, Injectable } from '@angular/core';
import { DtmDigitalDataTcp } from './digitalData';

declare let _satellite: any;

@Injectable()
export class DTMUtilityTcp {
  private _digitalData = new DtmDigitalDataTcp();
  constructor(@Inject('Window') private _window: any) {
    this._digitalData.setSessionData();
      _window.digitalData = this._digitalData.data;
  }

  pageChanged() {
    this._digitalData.setPageData();
    this._window.digitalData = this._digitalData.data;
    if (typeof _satellite !== 'undefined') {
      _satellite.track('URLchanged');
    }
  }

  setBenefitData(data: any) {
    this._window.digitalData.setBenefitData(data);
  }

  setInterventionData(data: InterventionAdobeAnalytics) {
    this._window.digitalData.setInterventionData(data);
  }

  set assetId(value: string) {
    this._window.digitalData.page.pageInfo.assetId = value;
  }

  getUrl() {
    return this._window.digitalData.page.pageInfo.destinationURL;
  }

  getDocumentUrl() {
    return this._window.document.URL;
  }
}
