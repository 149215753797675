<div class="row">
  <div class="row ml-16 motif-margin-top-10">
    <div class="col-sm-12">
      <h4 class="section-header-two">{{ labels.Heading }}</h4>
    </div>
  </div>
  <div class="row ml-16 motif-margin-bottom-5">
    <div class="col-sm-12">
      <span>{{ labels.BodyStart }}</span>
      <span class="desktop">{{ labels.MemberServicePhone }}</span>
      <span class="mobile" [innerHTML]="labels.MemberServicePhoneMobile"></span>
      <span class="desktop">{{ labels.MemberServiceTTY }}</span>
      <span class="mobile" [innerHTML]="labels.MemberServiceTTYMobile"></span>
      <span>{{ labels.BodyEnd }}</span>
    </div>
  </div>
  <div class="row ml-16 motif-margin-bottom-15">
    <div class="col-sm-12">
      <a href="javascript:void(0)" class="ant-action-link" routerLink="{{ labels.PaymentHistoryLink }}" [attr.data-analytics]="labels.PaymentHistoryAnalytics">
        <span class="{{ labels.PaymentHistoryIcon }}"></span><span class="ant-link-text">{{ labels.PaymentHistoryText }}</span>
      </a>
    </div>
  </div>
</div>