
import { AlertModule } from "@anthem/uxd/alert";
import { AppointmentAssistanceComponent } from "./appoinment-assistance.component";
import { AppointmentAssistanceFormComponent } from "./appointment-assistance-form/appointment-assistance-form.component";
import { AppointmentAssistanceRoutingModule } from "./appoinment-assistance-routing.module";
import { CommonModule } from "@angular/common";
import { CustomValidatorsModule } from "sydmed/libs/custom-validators/src/lib/custom-validators.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { RadioModule } from "@anthem/uxd/radio";
import {AntDatepickerModule} from '@anthem/uxd/ant-datepicker';
import { SharedComponentsModule } from "sydmed/libs/shared-components/src/lib/shared-components.module";

@NgModule({
  declarations: [
    AppointmentAssistanceComponent,
    AppointmentAssistanceFormComponent,
  ],
  imports: [
    AlertModule,
    AntDatepickerModule,
    AppointmentAssistanceRoutingModule,
    CommonModule,
    CustomValidatorsModule,
    FormsModule,
    RadioModule,
    ReactiveFormsModule,
    SharedComponentsModule,
  ],
})
export class AppointmentAssistanceModule {}
