<div class="row"  *ngIf="showNewPrescriptionAlert && alertContent">
  <div class="col-sm-1"></div>
  <div class="col-sm-1"></div>
</div>
<div [ngClass]="showMobileView" class="container">
  <div id="tab-controls-container-sidebar">
    <sidebar-rail>
      <div *ngFor="let label of labels; let $index = index" class="side-nav-pharmacy-menu">
        <siderail-nav>
          <h2 siderail-header class="siderail-header" [innerHTML]="label.Header" tabIndex="0"></h2>
          <button *ngFor="let level of label.Menu_Headings" siderail-link [id]="'navid'+level.Label" [innerHTML]="level.Label"
            (click)="setNavPage(level, { isClicked: true })" [ngClass]="{'isActive': (navPage === level.Url && level.target !== '_blank')}"
            [attr.data-analytics]="level.analyticsTag"></button>
        </siderail-nav>
      </div>
    </sidebar-rail>
  </div>
  <div id="tab-content-container" tabindex="-1" class="accordian-container tab-content-container">
    <a href="javascript:void(0)" class="back-arrow" (click)="goToMenu()"><span
        class="motif-icon motif-left-arrow"></span> Menu</a>
    <ng-container>
      <dashboard-dismissable-alert (onCloseAlertClick)="onCloseDismissableAlertClick()" [labels]="drugAlertMessageLabel" *ngIf="showDismissableAlert"></dashboard-dismissable-alert>
      <router-outlet></router-outlet>
      <div class="hippa-rules">{{ showSensitiveDrugsLabel }}</div>  
    </ng-container>
  </div>
</div>
<app-ahd-cart class="rcp-motif-footer"></app-ahd-cart>