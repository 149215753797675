export interface DownloadOptions {
    endDate: string;
    format: string;
    startDate: string;
    serviceName: string;
}

export enum DownloadRouteParams {
    END_DATE = 'endDate',
    FORMAT = 'format',
    SERVICE_NAME = 'serviceName',
    START_DATE = 'startDate',
}

export enum DownloadServiceName {
    ORDERS = 'orders',
    PRESCRIPTIONS = 'prescriptions'
}