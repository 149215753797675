import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { default as AHDAddressesMock } from "../assets/data/mock/profile-ahd-addresses.json";
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { UrlProperties } from 'sydmed/libs/url-properties/src/lib/url-properties.class';
import { DeliveryAddress } from '@sydney/models';
import { ServiceableResponse } from '@anthem/mbrportal-profile/interfaces/vendorDeliveryAddress';
import { EventHandler } from '@anthem/mbrportal/core/services/eventHandler';
import * as shippingAddress from '../assets/data/mock/ahd-delivery-address';
import { ShippingResponse } from 'gbd-models/src/features/pharmacy';
const { deliveryAddress, ahdAddressServiceability,validateAddress } = UrlProperties.UrlProperties.endPoints.ahdPharmacy;



@Injectable({
  providedIn: 'root'
})
export class AhdDeliveryAddressesService {
  shippingAddressMock = shippingAddress.shippingAddress;
  shippingOptionsMock = shippingAddress.shippingOptions;
  constructor(
    protected http: HttpClientService
  ) { }

  /**
    Represents addressServiceableCheck function.
    @description This function checks get all vendor delivery address
  */
  async getDeliveryAddress() {
    return await this.http.getObservable(deliveryAddress).pipe(
        map((res: HttpResponse<DeliveryAddress[]>) => {
            return { data: res?.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
    )
    .toPromise();
  }

  /**
    Represents deleteVendorAddress function.
    @description This function deletes the vendor address
  */
  deleteVendorAddress(addressId: string) {
    let deleteURL = `${deliveryAddress}?addressKey=${addressId}`;
    return this.http.deleteObservable(deleteURL).pipe(
      map((res: HttpResponse<any>) => {
        return { data: res }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  };

  /**
   Represents addressServiceableCheck function.
   @description This function checks address is serviceable or not
 */
  addressServiceableCheck(data: DeliveryAddress) {
    return this.http.postObservable(ahdAddressServiceability, data).pipe(
      map((res: HttpResponse<ServiceableResponse>) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  /**
    Represents create pharmacy delivery address function.
    @description This function created pharmacy delivery address
  */
    createPharmacyAddress(address: DeliveryAddress) {
      return this.http.postObservable(deliveryAddress, address).pipe(
        map((res: HttpResponse<DeliveryAddress>) => {
          return { data: res?.body }
        }),
        catchError((error: HttpErrorResponse) => throwError(error.status))
      )
        .toPromise();
    }

    /**
    Represents updatePharmacyAddress function.
    @description This function updates delivery address info of the pharmacy
  */
  updatePharmacyAddress(address: DeliveryAddress) {
    return this.http.putObservable(deliveryAddress, address).pipe(
      map((res: HttpResponse<DeliveryAddress>) => {
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  /**
    Represents validateAddress function.
    @description This function calls smarty-street api and validate address
  */
  validateAddress(address: DeliveryAddress) {
    return this.http.postObservable(validateAddress, address).pipe(
      map((res: HttpResponse<DeliveryAddress>) => { 
        return { data: res?.body }
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    )
      .toPromise();
  }

  async validateSpecialtyPharmacyAddress(address: DeliveryAddress) {
    return this.http.postObservable(validateAddress, address).pipe(
      map((res: HttpResponse<DeliveryAddress>) => { 
        const { instructions } = res?.body;     
        return { 
          data: { 
            validated: true, 
            validationDescription: instructions 
          }, 
          status: res?.status 
        };
      }),
      catchError((error: HttpErrorResponse) => throwError(error.status))
    ).toPromise();
  }
}
