import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { PriorAuthFaqsComponent } from './prior-auth-faqs/prior-auth-faqs.component';
import { PriorAuthorizationComponent } from './prior-authorization.component';

const routes: Routes = [
  {path: 'secure/prior-authorization', component: PriorAuthorizationComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
  {path: 'secure/prior-authorization/faqs', component: PriorAuthFaqsComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PriorAuthorizationRoutingModule { }
