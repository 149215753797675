import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ContentHelper, EventHandler, IWindow, WCS_ATTRIBUTES } from '@anthem/mbrportal/core';
import { AppConfig, AppSession, IAppSession } from '@anthem/mbrportal/main';
import { CheckOrderStatusComponent } from '@anthem/mbrportal-pharmacy/components/checkOrderStatusCmp';
import { SearchProviderModel } from '@anthem/mbrportal-search-provider/models/searchProviderModel';
import { SearchProviderSvc } from '@anthem/mbrportal-search-provider/services/searchProviderSvc';
import { ScrollToViewSvc, TruePatientResponsibilityService, WcsContentFormatter } from '@anthem/mbrportal/shared';
import { DateUtility, Logger, NavigationHelper } from '@anthem/mbrportal/utility';
import { OrderModel } from '@anthem/mbrportal-pharmacy/models/orderModel';
import { OrderPrescriptionService } from '@anthem/mbrportal-pharmacy/services/orderPrescriptionService';
import { PharmacyNavigationUtility } from '@anthem/mbrportal-pharmacy/services/pharmacyNavigationUtility';
import { OrdersListModel } from '@anthem/mbrportal-pharmacy/models/ordersListModel';
import { OrdersSortModel } from '@anthem/mbrportal-pharmacy/models/ordersSortModel';
import { CvsPharmacyService } from '@anthem/mbrportal-pharmacy/services/cvsPharmacySvc';
import { IPrescriberSearchCmpContent } from '@anthem/mbrportal-pharmacy/interfaces/iPrescriptionv2';
import { VendorCurrentOrders } from '@anthem/mbrportal-pharmacy/enums/pharmacyEvents';
import { AHDContentHelper } from 'sydmed/src/app/sydmed-shared/ahd-content-helper/ahd-content-helper';
import { ahdPharmacyService } from '../services/ahd-pharmacy.service';
import { Router } from '@angular/router';
import { AjaxRequest, Features } from '@anthem/mbrportal/common';
import { PHARMACY_HOME } from '@anthem/mbrportal-pharmacy/values/pharmacyConstants';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { IOrder } from '@anthem/mbrportal-pharmacy/interfaces/iOrders';
import { Subscription, forkJoin } from 'rxjs';
import { InterCommunicationService } from "sydmed/libs/inter-communication-service/src/public-api";
@Component({
  moduleId: module.id,
  selector: 'app-ahd-my-orders-page',
  templateUrl:"../../../../../../node_modules/@anthem/mbrportal-pharmacy/views/checkOrderStatus.html",
  styleUrls: ['../../../../../../node_modules/@anthem/mbrportal-pharmacy/assets/styles/partials/checkOrderStatus.scss','./ahd-my-orders-page.component.scss'],
  
   providers: [ { provide: ContentHelper, useClass: AHDContentHelper},{ provide: CvsPharmacyService, useClass: ahdPharmacyService }, { provide: OrderPrescriptionService, useClass: ahdPharmacyService }, {provide: SearchProviderSvc, useClass: ahdPharmacyService} ],
   host: {
    '(document:click)': 'scrollToViewElement($event)'
  }
})
export class AhdMyOrdersPageComponent extends CheckOrderStatusComponent {
  contentSubscription: Subscription;
  navigator: NavigationHelper;
  constructor(
    logger: Logger,
    contentHelper: ContentHelper,
    orderModel: OrderModel,
    @Inject(AppSession) appSession: AppSession,
    @Inject(WCS_ATTRIBUTES) attributes: { [k: string]: string },
    dateUtility: DateUtility,
    cvsPharmacySvc: CvsPharmacyService,
    orderPharmacyService: OrderPrescriptionService,
    responsibilityService: TruePatientResponsibilityService,
    ordersListModel: OrdersListModel,
    ordersSortModel: OrdersSortModel,
    wcsContentFormatter: WcsContentFormatter,
    appConfig: AppConfig,
    renderer: Renderer2,
    scrollToViewSvc: ScrollToViewSvc,
    searchProviderSvc: SearchProviderSvc,
    searchProviderModel: SearchProviderModel,
    navigationHelper: NavigationHelper,
    pharmacyNavigationUtility: PharmacyNavigationUtility,
    private titleService: InterCommunicationService,
    @Inject(DOCUMENT) document: Document,
    @Inject('Window') window: IWindow,
    eveHandler: EventHandler,
    private router: Router,
    jsonSvc: JsonContentService
  ) { 
    super(
      logger,
      contentHelper,
      orderModel,
      appSession,
      dateUtility,
      cvsPharmacySvc,
      orderPharmacyService,
      responsibilityService,
      ordersListModel,
      ordersSortModel,
      wcsContentFormatter, 
      appConfig,
      renderer,
      scrollToViewSvc,
      searchProviderSvc,
      searchProviderModel,
      navigationHelper,
      pharmacyNavigationUtility,
      document,
      window,
      eveHandler,
      scrollToViewSvc
    );
    appSession.timeStamp = new Date();
    this.navigator = navigationHelper;
    this._appSession.metaData.features.push(Features.HOME_DELIVERY, Features.AHD_ORDERS);
    this.contentSubscription = forkJoin([
      jsonSvc.getJSON('ahd-order-status'), 
      jsonSvc.getJSON('ahd-review-orders-page'),
      jsonSvc.getJSON('ahd-review-processing')
    ]).subscribe(([ orderStatusContent, orderDetailsContent, reviewProcessingContent]) => {

        this.titleService.raiseEvent({ message: orderStatusContent.pageTitle, title: 'HEADER_TITLE'});
         contentHelper.registerContent('VendorCurrentOrderStatusComponent', orderStatusContent);
         contentHelper.registerContent('ReviewOrderComponent', orderDetailsContent.ReviewOrderComponent);
         contentHelper.registerContent('ProcessOrderComponent', reviewProcessingContent.ProcessOrderComponent);
     
    });
    
     
  }

  ngOnInit(): void {
    super.initializeWidget();

  }

  
  /**Navigate to order details page*/
  handleNavigation(order: IOrder) {
    this.navigator.navigateTo('/ahd-pharmacy/prescriptions/orders/review-orders/' + `${order.orderNumber}`);
  }
  ngOnDestroy() {
    this.contentSubscription.unsubscribe();
  }
 

}
