<inline-alert-container
  #errorAlert
  role="alert"
  id="replyMessageAlerts"
  [name]="'reply-message-alerts'"
></inline-alert-container>

<!-- Modal for Interventions -->
<ng-template #interventionsModal>
  <call-interventions-modal [callInterventionLabels]="callInterventionLabels" [interventionId]="messageDetails.uid"  (onDismissModal) = "closeModalRef()"></call-interventions-modal>
</ng-template>

<div id="message-center">
  <div *ngIf="!loading && !showSuccessMessage && !isEnterpriseMessage">
    <div class="message-detail-header">
      <h3 class="subheadBold messageTitle">{{ messageDetails.subject }}</h3>
      <div class="message-options">
        <div class="ctrlHolder" *ngIf="inboxOptions">
          <button
            type="button"
            [attr.aria-label]="'Delete ' + messageDetails.subject"
            (click)="handleClick()"
            id="MsgCntrDeleteCan1_btn"
            class="deleteBtn"
          >
            <span class="motif-icon motif-trash" aria-hidden="true"></span>
          </button>
          <button *ngIf="!isTermedUser"
            type="button"
            [attr.aria-label]="'Archive ' + messageDetails.subject"
            (click)="updateDetails('saved')"
            class="deleteBtn"
          >
            <span class="motif-icon motif-archived" aria-hidden="true"></span>
          </button>
        </div>
        <div class="ctrlHolder" *ngIf="archivedOptions">
          <button
            type="button"
            [attr.aria-label]="'Delete ' + messageDetails.subject"
            (click)="handleClick()"
            id="MsgCntrDeleteCan1_btn"
            class="deleteBtn"
          >
            <span class="motif-icon motif-trash" aria-hidden="true"></span>
          </button>
          <button
            type="button"
            [attr.aria-label]="'Move to inbox ' + messageDetails.subject"
            class="deleteBtn"
            (click)="updateDetails('inbox')"
          >
            <span class="motif-icon motif-message" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="message-thread"
      [ngClass]="{ 'show-less': selectedIndex !== i }"
      *ngFor="let message of replyMsgs; let i = index"
    >
      <div class="message-header">
        <div class="ctrlHolder">
          <label class="dataDisplay">{{ message.messageFrom }}</label>
        </div>
        <div class="ctrlHolder">
          <label class="dataDisplay newLineText">{{
            message.messageDate | messageDate: true
          }}</label>
        </div>
      </div>
      <div class="message-body">
        <p
          #messageBody
          [ngClass]="{
            'message-content-limit':
              messageBodyHeight[i] > 25 && selectedIndex !== i
          }"
        >
        <ng-bind-html *ngIf="messageDetails.messageCategoryType === transcriptCategoryType" [innerHTML]="message.messageBody"></ng-bind-html>
        <span *ngIf="messageDetails.messageCategoryType !== transcriptCategoryType" [innerHTML]="message.messageBody"></span>
        </p>
      </div>
      <ng-container *ngIf="!downloadAttachmentRestriction">
        <div *ngIf="messageDetails.hasAttachments && selectedIndex === i">
          <app-file-details
            [fileData]="file"
            *ngFor="let file of message.messageAttachments"
            (attachedFile)="downloadFile($event)"
          ></app-file-details>
        </div>
      </ng-container>
      <button
        [attr.aria-label]="'Show more'"
        class="expander-button"
        *ngIf="
          (messageBodyHeight[i] > 25 ||
            (message.messageAttachments && !downloadAttachmentRestriction)) &&
          selectedIndex !== i
        "
        (click)="showmore(i)"
      >
        <span class="motif-icon motif-menu-kebab"></span>
      </button>

      <form
        class="careGapReply"
        *ngIf="showDispositionReply"
        [formGroup]="careGapReplyForm"
        (ngSubmit)="onSubmit(careGapReplyForm.value)"
      >
        <div
          class="fwc-flex"
          *ngFor="let item of labels?.replyMessages; let i = index"
        >
          <div class="fwc-radio fwc-flex care-gap-radio">
            <input
              type="radio"
              name="careGap"
              id="careGap-{{ i }}"
              value="{{ item }}"
              attr.aria-label="careGap"
              formControlName="careGap"
              required
            />
            <label for="careGap-{{ i }}" class="care-gap-reply font-16">{{
              item
            }}</label>
          </div>
        </div>
        <button
          type="submit"
          [disabled]="careGap?.value === ''"
          class="btn submit-care-reply"
        >
          {{ labels?.submit }}
        </button>
      </form>
    </div>
    <form
      [formGroup]="messageReplyForm"
      #msgReply="ngForm"
      name="msgReply"
      id="msgReply"
      class="new-message-form"
      *ngIf="hasMessageReply"
      novalidate
    >
      <div class="msgBody" *ngIf="!viewOnly">
        <div class="message-input-cont">
          <div class="message-input">
            <div class="ant-form-field">
              <textarea
                formControlName="MsgCntrMsgReply"
                title="Message body"
                name="MsgCntrMsgReply"
                [(ngModel)]="messageReply"
                minlength="1"
                maxlength="2000"
                required
                aria-invalid="false"
                placeholder="{{ labels.MsgCntrMsgBodyPlaceholder_lbl }}"
                required
                [ngClass]="{
                  invalidInput:
                    (MsgCntrMsgReply.dirty ||
                      MsgCntrMsgReply.touched ||
                      msgReply.submitted) &&
                    MsgCntrMsgReply.invalid
                }"
              ></textarea>
              <div
                class="error-list"
                *ngIf="
                  (MsgCntrMsgReply.dirty ||
                    MsgCntrMsgReply.touched ||
                    msgReply.submitted) &&
                  MsgCntrMsgReply.invalid
                "
              >
                <div *ngIf="MsgCntrMsgReply.errors.required">
                  <span class="success"
                    ><i class="fas fa-times"></i>
                    <span class="motif-margin-left-5">
                      <span [innerHtml]="errorMsgs.Required_Message_err"></span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <app-file-upload class = "attachmentUploadErrorAlert" [attachmentActionType]="attachmentActionType" [fileTypes]="fileTypes" *ngIf="!UploadAttachmentsEnabled" [attachFiles]="attachFiles" [attachmentErrorMsgs]="attachmentErrorMsgslabels"  [errorMsgs]="errorMsgsLabels"></app-file-upload>
            <div class="message-input-actions">
              <div class="message-input-actions-holder">
                <button
                  type="button"
                  class="deleteBtn"
                  [attr.aria-label]="'Cancel'"
                  (click)="delete()"
                >
                  <span class="motif-icon motif-trash"></span>
                </button>
              </div>
              <div class="msgSubmit">
                <button
                  type="submit"
                  id="MsgCntrReplySendMsg_btn"
                  class="btn orangeBtn"
                  [disabled]="isSubmitDisabled"
                  (click)="sendReplyMsg(msgReply.form.valid)"
                >
                  {{
                    isSubmitDisabled
                      ? labels.MsgCntrSendingMsg_btn
                      : labels.MsgCntrSendMsg_btn
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
<!-- Enterprises Message details-->
  <div *ngIf="!loading && !showSuccessMessage && isEnterpriseMessage">
    <div class="message-detail-header">
      <div class="message-thread" [ngClass]="{ 'show-less': selectedIndex !== i }"
        *ngFor="let message of replyMsgs; let i = index">
        <div class="message-header">
          <div class="ctrlHolder">
            <label class="dataDisplay">{{ messageDetails?.subject }}</label>
          </div>
          <div class="ctrlHolder">
            <label class="dataDisplay newLineText">{{
              message?.messageDate | messageDate: true
              }}</label>
          </div>
        </div>
        <div class="message-body">
          <p #messageBody [ngClass]="{
              'message-content-limit':
                messageBodyHeight[i] > 25 && selectedIndex !== i
            }" [innerHtml]="message?.messageBody">
          </p>
        </div>
        <div *ngIf="messageDetails.hasAttachments">
          <app-file-details [fileData]="file" *ngFor="let file of message.messageAttachments"
            (attachedFile)="downloadFile($event)"></app-file-details>
        </div>
        <div *ngIf="!isTermedUser">
          <p [innerHTML]='!isDesignee ? labels.MessageDetails?.MemberAttachmentDescription : labels.MessageDetails?.DesigneeAttachmentDescription'></p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #alertRef>
  <inline-alert-container
    #attachment
    role="alert"
    [name]="'inline-alert'"
  ></inline-alert-container>
</ng-template>

<div *ngIf="loading && !showSuccessMessage">
  <uxd-spinner class="message-details-spinner"></uxd-spinner>
  <div class="text-center">{{ labels.Loading_lbl }}</div>
</div>

<div id="successMessage" *ngIf="showSuccessMessage" class="text-center">
  <div><span class="motif-icon motif-checked"></span></div>
  <h2>{{ labels.MsgCntrSentSuccessHeading_lbl }}</h2>
  <p>
    {{ labels.MsgCntrSentSuccessBody_lbl }}
    <a href="{{ labels.MsgCntrContactUsLink }}">{{
      labels.MsgCntrSentSuccessLink_lbl
    }}</a>
  </p>
  <button class="btn" (click)="closeSidePanel()">
    {{ labels.MsgCntrSentSuccessClose_btn }}
  </button>
</div>

<ng-template #modalTemplate>
  <div class="delete-message-modal">
    <button
      class="close-button"
      [attr.aria-label]="'Close Modal'"
      (click)="onModalClose()"
    >
      <span aria-hidden="true" class="motif-icon motif-delete"></span>
    </button>
    <div class="delete-message-modal-header">
      <h2>{{ labels.DeleteMsgModalHeading_lbl }}</h2>
    </div>
    <div class="delete-message-modal-body">
      <p>{{ labels.DeleteMsgModalBody_lbl }}</p>
    </div>
    <div class="delete-modal-alert">
      <inline-alert-container
        id="deleteMessageAlerts"
        [name]="'delete-message-alerts'"
      ></inline-alert-container>
    </div>
    <div class="delete-message-modal-footer button-group">
      <button class="btn btn-secondary" (click)="onModalClose()">
        {{ labels.DeleteMsgModalCancel_btn }}
      </button>
      <button
        class="btn continue"
        (click)="updateDetails('deleted')"
        [ngClass]="{ 'submit-disabled': isMessageDeleting }"
      >
        {{ isMessageDeleting ? "Deleting" : "Delete" }}
      </button>
    </div>
  </div>
</ng-template>
