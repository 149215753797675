export class Name {
    firstName:string;
	lastName:string;
	middleName?:string;
	prefix?:string;
}

export class MemberAddress {
    poBox?:boolean;
	lineOne:string;
	lineTwo?:string;
	city:string;
	state:string;
	county?:string;
	zipCode:string;
    addressType:string;
    addressUsageTypes?:string[];
}

export class MemberPhoneNumber {
    number:string;
    numberType:string;
    numberSubType?:string;
}

export class OptInOption {
    commType:string;
    optIn:boolean;
    enabled:boolean;
}

export class MemberEmail {
    emailType:string;
    emailAddress:string;
    optInOptions:OptInOption[];
}

export class MemberSmsNumber {
    number:string;
    numberType:string;
    confirmed?: boolean;
    optInOptions:OptInOption[];
}

export class MemberMail {
    optInOptions:OptInOption[];
}

export enum AddressType {
    HOME="HOME",
	MAILING="MAILING",
	ALTERNATE="ALTERNATE",
	PHARMACY="PHARMACY" 
}

// This model represents - V3 members contact-info server response
export class MemberContactInfo {
    name: Name;
	userName:string;
	memberDOB:string; 
    addressList:MemberAddress[];
    phoneNumberList:MemberPhoneNumber[];
    emailList: MemberEmail[];
    smsList: MemberSmsNumber[];
    mail: MemberMail;
}