<div #scrollContainer class="modal-body border-bottom-0 padding-top-0">
    <h2 class="font-24 margin-bottom-24">{{content?.slideOutHeader}}</h2>
    <div>
        <p class="font-14 margin-top-4 margin-bottom-20">{{content?.description}}</p>
    </div>
    <div class="ant-theme-primary" *ngFor="let accordian of content?.accordians">
        <uxd-expansion-panel #panel="uxdExpansionPanel">
            <uxd-expansion-header [aria-label]="accordian?.id" id="accordian?.id" 
            [attr.aria-expanded]="{'close': panel.expanded}">
                <uxd-expansion-toggle [aria-label]="accordian?.id" [attr.data-analytics]="accordian?.analytics?.expandIconAnalytics">
                    <div class="uxd-expansion-toggle-row">
                        <span class="motif-icon motif-angle-down uxd-expansion-button" [id]="'button-${accordian.id}'"
                            [ngClass]="{'open': panel.expanded}"></span>
                        <h5 class="expansion-title collapsible-heading-text">{{accordian?.header}}</h5>
                    </div>
                </uxd-expansion-toggle>
            </uxd-expansion-header>
            <section class="ant-content-wrapper margin-left-46">
                <div class="ant-content">
                    <p class="font-14 margin-top-4">{{accordian?.description}}</p>
                    <div>
                        <a (click)="navigate($event, accordian);" id="accordian?.id" class="syd-link syd-program-link" [attr.data-analytics]="accordian?.analytics?.labelAnalytics">
                            <span [ngClass]="accordian?.icon"></span>
                            <span>{{accordian?.label}}</span></a>
                    </div>
                    <p class="font-14 margin-top-4">{{accordian?.description2}}</p>
                    <ul class="a font-14 margin-top-4 padding-left-18">
                        <li class="margin-top-8" *ngFor="let item of accordian?.list" [innerHtml] = "item"></li>
                    </ul>
                </div>
            </section>
        </uxd-expansion-panel>
    </div>
    <div class="modal-footer sticky-footer">
        <button class="btn btn-primary text-align-right" id="id-modal-close-btn" data-analytics="closeAccountSliderDb" 
            [attr.aria-label]="'content?.closeBtnLabel'" (click)="onClose()">{{content?.closeBtnLabel}}</button>
    </div>
</div>