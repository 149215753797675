import { Injectable, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'sydmed/src/app/secure/pharmacy/services/cart.service';
import { AuthEventsService } from 'sydmed/libs/authorization/authevents.service';
import { JsonContentService } from '../../content-service/json-content.service';
import { ProfileDetailService } from 'sydmed/src/app/secure/shm-my-profile/services/profile-details.service';
import { ModalRef, Modal } from '@anthem/uxd/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { AdobeAnalyticsService } from '../../adobe-datalayer/adobe-analytics.service';
import { MyProfileDataService } from 'sydmed/src/app/secure/shm-my-profile/my-profile-data.service';
import { LocaleService } from 'sydmed/src/app/sydmed-shared/locale-service/locale.service';
const MINUTES_UNTIL_AUTO_OPEN_MODAL = 15 // in minutes
const CHECK_INTERVAL = 1000 // in milliseconds
const AUTO_TIMEOUT_LOGOUT = 120000; // in milliseconds
const AUTO_USER_INITIATED_LOGOUT = 60000 // in milliseconds

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private router: Router,
    private cartSvc: CartService,
    private eventSvc: AuthEventsService,
    private jsonSvc: JsonContentService,
    private _modal: Modal,
    private http: HttpClientService,
    private profileSvc: ProfileDetailService,
    private profileDataSvc: MyProfileDataService,
    private adobeAnalyticsSvc: AdobeAnalyticsService,
    private localeService: LocaleService
  ) {
  }

  lastAction: number;
  private _modalRef: ModalRef<any, any>;
  private modalOpen: boolean = false;
  private interval;
  private timeout;
  private tokenExpireTime: number;
  private runOnce: boolean = true;
  @ViewChild('timeLogoutModal', { static: false }) timeLogoutModal: TemplateRef<any>;
  @ViewChild('logoutModalTemplate', { static: false }) logoutModalTemplate: TemplateRef<any>;

  // protected tokenExpire$: BehaviorSubject<Date> = new BehaviorSubject<Date>( null );
  // public tokenExpire: Observable<Date> = this.tokenExpire$.asObservable();

  protected userLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( null );
  public userLoggedIn: Observable<boolean> = this.userLoggedIn$.asObservable();

  protected timeoutTime$: BehaviorSubject<Date> = new BehaviorSubject<Date>( null );
  public timeoutTime: Observable<Date> = this.timeoutTime$.asObservable();

  public setTimeoutTime(timeoutTime) {
    this.timeoutTime$.next(timeoutTime);
  }

  public getTimeoutTime() {
    return this.timeoutTime;
  }

  public setUserLoggedIn(userLoggedIn) {
    this.userLoggedIn$.next(userLoggedIn);
  }

  public getUserLoggedIn() {
    return this.userLoggedIn;
  }

  initListener(logoutModal, timeLogoutModal) {
    this.logoutModalTemplate = logoutModal;
    this.timeLogoutModal = timeLogoutModal;
    window.document.body.addEventListener('wheel', () => this.reset());
    window.document.body.addEventListener('mousedown', () => this.reset());
    window.document.body.addEventListener('touchmove', () => this.reset());
    window.document.body.addEventListener('touchstart', () => this.reset());
    window.onbeforeunload = function() { this.logout(); }
  }

  initInterval() {
    this.lastAction = Date.now();
    this.runOnce = true;
    if(!this.interval){
      this.interval = setInterval(() => {
        this.check();
      }, CHECK_INTERVAL);
    }
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNTIL_AUTO_OPEN_MODAL * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    const tokenExpireTimeDiff = Date.now() - this.http.getTokenExpireTime();
    if (tokenExpireTimeDiff > 600000 && this.runOnce) {
      this.runOnce = false;
      this.setKeepalive();
    }

    if (isTimeout && !this.modalOpen) {
      this.setTimeoutTime(new Date);
      this._modalRef = this._modal.open(this.timeLogoutModal);
      this.modalOpen = true;
      this.logoutInTime(AUTO_TIMEOUT_LOGOUT);
    }
  }

  setKeepalive()
  {
    const url = '/sydmedservices/secure/keepalive';
    this.http.getObservable(url).subscribe(res => {
      this.http.resetTokenExpireTime();
      this.runOnce = true;
    });
  }

  logoutModal() {
    this._modalRef = this._modal.open(this.logoutModalTemplate);
    this.modalOpen = true;
    this.logoutInTime(AUTO_USER_INITIATED_LOGOUT);
  }

  logoutInTime(milliseconds) {
    this.timeout = setTimeout(() => {
      if (this.modalOpen) {
        this.logout();
      }
    }, milliseconds)
  }

  reset() {
    this.lastAction = Date.now();
  }

  stayLoggedIn() {
    this._modalRef.close();
    this.modalOpen = false;
    this._modalRef.onDismiss.unsubscribe();
    this._modalRef.onClose.unsubscribe();
    clearTimeout(this.timeout);
  }

  logout() {
    this._modalRef.close();
    this._modalRef.onDismiss.unsubscribe();
    this._modalRef.onClose.unsubscribe();
    this.modalOpen = false;
    this.clearData();
    this.router.navigate(['/public/login']);
  }

  clearData() {
    clearInterval(this.interval);
    window.document.querySelector('title').innerText = decodeURIComponent('Member Secure Application');
    this.interval = null;
    this.invalidateToken();
    this.setUserLoggedIn(false);
    const locale = this.localeService.getLocaleOverride();
    window.sessionStorage.clear();
    if (locale) {
      this.localeService.setLocaleOverride(locale);
    }
    indexedDB.deleteDatabase('LiveChatDB');
    this.cartSvc.removeAll();
    this.eventSvc.updateMenu("");
    this.jsonSvc.clearContents();
    this.profileSvc.clearContents();
    this.adobeAnalyticsSvc.initAdobeLaunchDataLayer();
    this.profileDataSvc.setCommPrefContactInfoData({});
  }

  invalidateToken() {
    const url = '/sydmedservices/secure/logout';
    this.http.getObservable(url).subscribe(res => {});
  }

}



