import { PaymentMethod } from "gbd-models";

export interface PaymentInfo {
    dueDate: string;
    accountType: string;
    amount: string;
    autoPay: boolean;
    paymentProcess: boolean;
    paidInFull: boolean;
    payNowOn: boolean;
    memberId: string;
    overDueDays: boolean;
    coverageStartDt: string;
    coverageEndDt: string;
    planId: string;
    productId: string;
    planName: string;
    recurringTokenId?: string;
    recurringType?: string;
    InvoiceUrl?: string;
    PdfBillAvailable?: string;
    confirmationNumber?: string;
    showPay: boolean;
    showDue: boolean;
    showAdd: boolean;
    pendingPayment: PaymentMethod;
    memberName: string;
    memberSequenceNumber: string;
    summaryBillNo: string;
    pendingAmount?: string;
    paymentStatus?: PaymentStatus;
    pdfBytes?: string;
}

export enum PaymentStatus {
    PENDING = 'PENDING',
    SUBMITTED = 'SUBMITTED'
}