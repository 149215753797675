import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PharmacyLandingPageComponent } from "./ahd-pharmacy-landing/ahd-pharmacy-landing.component";
import { AhdLeftRailNavComponent } from "./ahd-left-rail-nav/ahd-left-rail-nav.component";
// import { AHDPrescriptionHomeComponent } from "./ahd-prescription-home/ahd-prescription-home.component";
// import { AhdMyOrdersPageComponent } from './ahd-my-orders-page/ahd-my-orders-page.component';
import { AuthGuardService } from "sydmed/libs/authorization/route-guard.service";
import { AhdPharmacyRoutes } from "./ahd-pharmacy-routes.enum";
import { AhdReviewOrderLandingComponent } from "./ahd-review-order-landing/ahd-review-order-landing.component";
import { AhdPrescriptionHomeComponentContainer } from "./ahd-prescription-home-container/ahd-prescription-home-container.component";
import { AhdMyOrdersPageComponentContainer } from "./ahd-my-orders-page-container/ahd-my-orders-page-container.component";
import { AhdReviewProcessingComponent } from "./ahd-review-processing/ahd-review-processing.component";
import { AhdTrackOrderDetailsLandingComponent } from "./ahd-track-order-details-landing/ahd-track-order-details-landing.component";
import { AhdDownloadComponent } from "./ahd-download/ahd-download.component";
import { SpecialtyInformationComponent } from "./bio-plus/specialty-information/specialty-information.component";
import { SpecialtyRequestComponent } from "./bio-plus/specialty-request/specialty-request.component";

const routes: Routes = [
  { path: AhdPharmacyRoutes.PHARMACY, redirectTo: AhdPharmacyRoutes.DASHBOARD, pathMatch: 'full' },
  { path: AhdPharmacyRoutes.PRESCRIPTIONS, redirectTo: 'secure/ahd-pharmacy/allprescriptions', pathMatch: 'full' },
  {path: AhdPharmacyRoutes.REVIEW_ORDERS, redirectTo:  AhdPharmacyRoutes.REVIEW_ORDER_DETAILS, pathMatch: 'full'},
  {path: AhdPharmacyRoutes.CONTACT_US, redirectTo: 'secure/contact-us', pathMatch: 'full'},
  {path: AhdPharmacyRoutes.CONTACT_US_NEW, redirectTo: 'secure/contact-us', pathMatch: 'full'},
 
  {
    path: AhdPharmacyRoutes.PHARMACY,
    component: AhdLeftRailNavComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: AhdPharmacyRoutes.LANDING_PAGE,
        component: PharmacyLandingPageComponent,
      },
      {
        path: AhdPharmacyRoutes.ALL_PRESCRIPTIONS,
        component: AhdPrescriptionHomeComponentContainer,
      },
      {
        path: AhdPharmacyRoutes.ORDERS,
        component: AhdMyOrdersPageComponentContainer
      },
      {
        path: AhdPharmacyRoutes.ORDER_REVIEW,
        component: AhdReviewOrderLandingComponent
      },
      {
        path: AhdPharmacyRoutes.REVIEW_ORDER_DETAILS,
        component: AhdTrackOrderDetailsLandingComponent,
      },
      {
        path: AhdPharmacyRoutes.PROCESSING_ORDER_DETAILS,
        component: AhdReviewProcessingComponent,
      },
      {
        path: AhdPharmacyRoutes.DOWNLOAD,
        component: AhdDownloadComponent      
      },
      {
        path: AhdPharmacyRoutes.SPECIALTY_INFO,
        component: SpecialtyInformationComponent
      },
      {
        path: AhdPharmacyRoutes.SPECIALTY_REQUEST,
        component: SpecialtyRequestComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AhdPharmacyRoutingModule {}
