import { Injectable } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClientService } from "sydmed/libs/http-client-service/src/lib/http-client.service";
import { of, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ProfileDetailsResponse, Address } from '../models/profile-detail';
import { MemberAddress, AddressType } from '../models/v3-member-contact-info.model';
import { ContactData, MyProfileModel, Phone } from '../models/my-profile.model';
import moment from 'moment-timezone';
import { DesigneeDetails } from 'gbd-models';

@Injectable()
export class ProfileDetailService  {

  public profileDetail:ProfileDetailsResponse;
  public contactInfo: ContactData;
  public addresses: MemberAddress[];
  public isDirty: boolean = false; // set this to true after any update operation
  public isFetching: boolean = false;

  constructor(private httpSvc: HttpClientService, private profileModel: MyProfileModel) {
  }
  public getProfileDetails(force: boolean = false): Observable<ProfileDetailsResponse> {
    if (this.profileDetail && !force && !this.isDirty) {
      return of(this.profileDetail);
    } else {
      this.isFetching = true;
      if (this.isDirty) this.isDirty = false;
      return this.httpSvc.getObservable(UrlProperties.UrlProperties.endPoints.myProfile.profileDetails).pipe(map(data => {
        return this.profileDetail = data.body;
      }), share());      
    }
  }
  mapAddress(address: Address, type): MemberAddress {
    if (address) {
      const addressObj: MemberAddress = {
        poBox: false,
        lineOne: address.streetAddress1,
        lineTwo: address.streetAddress2,
        city: address.city,
        state: address.state,
        county: address.county,
        zipCode: address.zipCode,
        addressType: type
      };
      return addressObj;
    }
  }
  mapToContactObj(profileDetails: ProfileDetailsResponse, returnObj: boolean = false) {
    this.contactInfo = {
      name: profileDetails.firstName ? (profileDetails.firstName).toUpperCase() + ' ' + (profileDetails.lastName).toUpperCase() : '',
      address: this.setAddresses(profileDetails.addresses),
      dob: profileDetails.dateOfBirth ? this.formatDate(profileDetails.dateOfBirth) : '',
      userName: (profileDetails.username) ? (profileDetails.username) : '',
      email: profileDetails.emailAddresses.primary, // primary email
      phone: this.assignPhone(profileDetails.phoneNumbers.primary, 'home'),
      secondaryPhone: this.assignPhone(profileDetails.phoneNumbers.secondary, 'mail'),
      textPhone: this.assignPhone(profileDetails.smsNumbers['gbd'], 'general'),
      pharmacyEmail: profileDetails.emailAddresses.pharmacy ? profileDetails.emailAddresses.pharmacy : null, // pharmacy email
      pharmacyPhone: this.assignPhone(profileDetails.phoneNumbers.pharmacy, 'pharmacy'),
      acctRecoveryNumber: this.assignPhone(profileDetails.accountRecoveryNumber.phoneNumber, profileDetails.accountRecoveryNumber.type),
      pharmacyText: this.assignPhone(profileDetails.smsNumbers.pharmacy, 'pharmacy'), // pharmacy text number
      genSmsStatus: this.setSmsStatus(this.assignPhone(profileDetails.smsNumbers['gbd'], 'general'), profileDetails.smsNumbers.primarySmsRegistrationStatus)
    };
    if (returnObj) {
      return this.contactInfo;
    }
  }
  mapToDesigneeContactInfo(designeeInfo: DesigneeDetails, returnObj: boolean = false) {
    this.contactInfo = {
      name: designeeInfo.personInfo.firstName ? (designeeInfo.personInfo.firstName).toUpperCase() + ' ' + (designeeInfo.personInfo.lastName).toUpperCase() : '',
      email:designeeInfo.additionalContactInfo.email,
      address: this.mapAddress(designeeInfo.address,AddressType.HOME),
      userName: designeeInfo.username,
      phone: this.assignPhone(designeeInfo.additionalContactInfo.homePhone, 'home'),
      dob: '',
      secondaryPhone: this.assignPhone(designeeInfo.additionalContactInfo.cellPhone, 'home'),
      textPhone: this.assignPhone(designeeInfo.additionalContactInfo.cellPhone, 'general'),
      pharmacyEmail: '',
      pharmacyPhone: this.assignPhone(designeeInfo.additionalContactInfo.cellPhone, 'pharmacy'),
      acctRecoveryNumber: this.assignPhone(designeeInfo.additionalContactInfo.cellPhone, 'pharmacy'),
      pharmacyText: this.assignPhone(designeeInfo.additionalContactInfo.cellPhone, 'pharmacy'),
      genSmsStatus: this.setSmsStatus(this.assignPhone(designeeInfo.additionalContactInfo.cellPhone, 'general'), true)
    }
    if (returnObj) {
      return this.contactInfo;
    }
  }

  formatDate(dateValue: string, format: string = 'MM/DD/yyyy') {
    return moment.utc(dateValue).format(format);
  }
  setAddresses(addresses) {    
    let addressArray: Array<MemberAddress> = [];
    if(addresses.home){
      addressArray.push(this.mapAddress(addresses.home, AddressType.HOME));
    }
    if(addresses.mailing){
      addressArray.push(this.mapAddress(addresses.mailing, AddressType.MAILING));
    }
    if(addresses.alternate){
      addressArray.push(this.mapAddress(addresses.alternate, AddressType.ALTERNATE));
    }
    if(addresses.pharmacy){
      addressArray.push(this.mapAddress(addresses.pharmacy, AddressType.PHARMACY));
    }   
    return addressArray;
  }
  clearContents(): void {
    this.profileDetail = null;
    this.contactInfo = null;
  }
  assignPhone(phoneNumber: string, numberType): Phone {
    return phoneNumber ? { number: this.phoneNumberViewFormat(phoneNumber), numberType: numberType } : null;
  }
  setSmsStatus(phone: Phone, flag: boolean): string {
  
    let status = 'NA';
    
    if(phone != null){     
    if (phone && !phone.number) {
      status = 'SN';
    } else {
      status = flag ? 'SC' : 'SP';
    }
   }
   return status;
  }
  
  phoneNumberViewFormat(phoneNumber) {
    if(phoneNumber) {
        phoneNumber = phoneNumber.replace(/-/g, "");
        return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6, phoneNumber.length);
    }
  }

  isSpanish(): boolean {
    return sessionStorage.getItem("localeOverride") === 'es-US';
  }
}
