import { Injectable } from '@angular/core';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { HttpClient } from '@angular/common/http';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/lib/http-client.service';
import { Observable } from "rxjs";
import moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class CallMeService {
    apptDateTimes = []

    constructor(
        private httpSvc: HttpClientService,
        private httpClient: HttpClient
    ) { }


    public getCallMeInquiryTypes() {

        return new Observable((observer) => {

            this.getContactUsInquiryTypes().subscribe(
                (res) => {
                    let inquiryTypes = res.body["inquiryTypes"];

                    observer.next((inquiryTypes || []).map((it) => {
                        return {
                            label: it.inquiryType,
                            value: (it.inquiryType || "").split(" ").join("+"),
                            analytics: "",
                            queueId: it.queueId
                        }
                    }))
                    observer.complete();
                },
                error => {
                    observer.next(error);
                    observer.complete();
                }
            )
        })
    }

    public getContactUsInquiryTypes() {
        let url = UrlProperties.UrlProperties.endPoints.requestCallBack.inquiries;
        return this.httpSvc.getObservable(url, { callbackLanguage: "English" });
    }

    public getApproximateCallTime(inquiryType = "") {
        let url = UrlProperties.UrlProperties.endPoints.requestCallBack.callTime;
        return this.httpSvc.getObservable(
            url,
            { callbackLanguage: "English", inquiryType: inquiryType });
    }

    public callMe(requestPayload) {
        let url = UrlProperties.UrlProperties.endPoints.requestCallBack.scheduleCall;
        return this.httpSvc.postObservable(
            url, requestPayload);
    }

    public getApptDateTime(inquiryType) {
        return new Observable((observer) => {
            this.getApptDateTimeApi(inquiryType).subscribe(
                (data) => {
                    let dateTimes = data.body["apptDateTimes"];

                    if ((dateTimes || []).length) {
                        dateTimes = dateTimes.map((date) => moment(date));
                        let dates = this.getCallDateData(dateTimes);

                        this.apptDateTimes = dateTimes;

                        observer.next({ dates: this.setDatesForOptions(dates) });
                        observer.complete();
                    } else {
                        observer.next({ dates: [] });
                        observer.complete();
                    }
                },
                (err) => {
                    observer.next(err);
                    observer.complete();
                }
            )
        })
    }

    getTimeZoneName(date) {
        let tZ = moment.tz.guess();
        let abbr = moment.utc(date).tz(tZ).format('z');
        let arrZone = abbr.split('');
        let timeZone = '';
        arrZone.forEach((element, index) => {
            if (index != 1)
                timeZone = timeZone + element.charAt(0);
        });
        return timeZone;
    }

    public getApptTimesByDate(date) {
        if (!date || !this.apptDateTimes) {
            return [];
        }
        let dateTimes = [];
        let res = [];

        for (let i = 0; i < this.apptDateTimes.length; i++) {
            if (this.timeStampAreOnSameDay(date, this.apptDateTimes[i])) {
                dateTimes.push(this.apptDateTimes[i]);
            }
        }
        dateTimes = dateTimes.sort((x, y) => parseInt(x.format('x')) - parseInt(y.format('x')));
        for (let i = 0; i < dateTimes.length; i++) {
            res.push({
                label: `${this.getTime(dateTimes[i], false)} - ${this.getTime(dateTimes[i], true)} ${this.getTimeZoneName(date)}`,
                value: `${this.getTime(dateTimes[i], false)} - ${this.getTime(dateTimes[i], true)}`,
                date: dateTimes[i]
            })
        }

        return res;
    }

    public getApptDateTimeApi(inquiryType) {
        let url = UrlProperties.UrlProperties.endPoints.requestCallBack.apptTime;
        return this.httpSvc.getObservable(url,
            {
                callbackLanguage: "English",
                inquiryType: inquiryType
            }
        )
    }

    public getCallDateData(dates) {
        let uniqDates = [];

        for (var i = 0; i < dates.length; i++) {
            let date = dates[i];
            let dateFound = false;

            for (var j = 0; j < uniqDates.length; j++) {
                if (this.timeStampAreOnSameDay(date, uniqDates[j])) {
                    dateFound = true;
                }
            }

            if (!dateFound)
                uniqDates.push(date);
        }

        return uniqDates;
    }

    public timeStampAreOnSameDay(d1, d2) {
        try {
            return moment(d1).format("YYYY-MM-DD") === moment(d2).format("YYYY-MM-DD");
        } catch (error) {
            return false;
        }
    }

    public setDatesForOptions(dates = []) {
        return dates.map(date => {
            return {
                label: this.getDate(date),
                value: date
            }
        });
    }

    public getDate(date) {
        return date ? moment(date).format('dddd, MMM D') : '';
    }

    public getTime(date, isAdd: boolean) {
        if (isAdd)
            return moment(date).add("30", "minutes").format('LT');
        return date ? moment(date).format('LT') : '';
    }
}