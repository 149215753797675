<div class="chat-body" aria-label="chat-body"
    [ngClass]="{'resize-chat-body-height-before': !isChatInitiated}" *ngIf="labels">
    <div class="ant-chat-bubble ant-chat-bubble-bot">
        <p>{{ labels.chatGreeting | si:{ firstName: memberName } }}</p>
    </div>
    <div class="ant-chat-bubble ant-chat-bubble-bot last" #helpToday tabindex="0">
        <p>{{ labels.chatHelpToday }}</p>
    </div>
    <!-- categories that are available now -->
    <div class="category padding"></div>
    <div *ngIf='liveChatWithSelectedTopic'>
    <div class="category messages clear" *ngFor="let category of available">
        <div class="message" [ngClass]="{'selected': category.isSelected}">
            <button class="ant-chat-pill" [disabled]="isTopicSelected"
                attr.aria-label="{{getTopics(category.topic)}}"
                (click)="initiateChat(category)"
                [attr.data-analytics] = "getAnalyticsString(category.topic)">{{getTopics(category.topic)}}
            </button>
        </div>
    </div>
</div>
    <!-- categories that are not available and showing hours of availability -->
    <div class="category messages clear" *ngFor="let category of unavailable">
        <div class="not-available">
            <button class="ant-chat-pill"
                attr.aria-label="{{getTopics(category.topic)}}"
                [disabled]="!category.availableNow">{{getTopics(category.topic)}}
            </button>
        </div>
        <div class="ant-chat-bubble ant-chat-bubble-bot available-hours">
            {{getTopics(category.topic)}} {{labels.chatIsAvailable}} {{showTopicAvailability(category)}}
        </div>
    </div>
    <div class="ant-chat-bubble ant-chat-bubble-user" *ngIf="isTopicSelected && liveChatWithSelectedTopic">
        <p *ngIf="isRestore">{{ getTopics(restoredCategory) }}</p>
        <p *ngIf="!isRestore">{{ getTopics(selectedCategory.topic) }}</p>
    </div>
    <div *ngIf="showError">
        <div class="ant-chat-bubble ant-chat-bubble-bot">
            <div class="error message agent">{{ errorMessage | si:{ errorCode:errorCode } }}</div>
        </div>
    </div>
    <p class="emergency-info" *ngIf="!isChatInitiated">{{labels.chatEmergencyMessage}}</p>
</div>