import { AuthGuardService } from 'sydmed/libs/authorization/route-guard.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CostShareComponent } from './cost-share/cost-share.component';
import { Routes, RouterModule } from '@angular/router';
import { OutOfPocketComponent } from './out-of-pocket/out-of-pocket.component';
import { MemberLiabilityComponent } from './member-liability/member-liability.component';
import { IndicatorModule } from '@anthem/uxd/indicators';
import { CostShareService } from './services/cost-share.service';
import { AlertModule } from '@anthem/uxd/alert';

const routes: Routes = [
  {
    path: 'secure/cost-share',
    canActivate: [AuthGuardService],
    component: CostShareComponent
  }
];

@NgModule({
  declarations: [
    CostShareComponent,
    OutOfPocketComponent,
    MemberLiabilityComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    IndicatorModule,
    AlertModule
  ],
  providers: [CostShareService]
})
export class CostShareModule {
  static forRoot(): ModuleWithProviders<CostShareModule> {
    return {
      ngModule: CostShareModule,
      providers: [AuthGuardService]
    };
  }
 }
