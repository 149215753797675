import { Component, Input, OnInit } from '@angular/core';
import { Footer, FooterTile, FooterTiles } from '../interfaces/PharmacyDashboardContent';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Restriction } from 'gbd-models';

@Component({
  selector: 'app-footer-wrapper',
  templateUrl: './footer-wrapper.component.html',
  styleUrls: ['./footer-wrapper.component.scss']
})
export class FooterWrapperComponent implements OnInit {
  @Input() content: Footer;
  @Input() restrictions: string[];
  footerContent: FooterTiles;
  footerTopRight: FooterTile;
  footerBottomLeft: FooterTile;
  footerBottomRight: FooterTile;
  copayDisclaimer: string;

  constructor(private jsonSvc: JsonContentService) { }

  ngOnInit(): void {
    this.copayDisclaimer = this.content.CopayDisclaimer;
    this.footerContent = this.content.Tiles;
    this.footerTopRight = this.footerContent.TopRight;
    this.footerBottomLeft = this.footerContent.BottomLeft;
    this.footerBottomRight = this.footerContent.BottomRight;
  }

  /**
   * @description displayClaimsTile() responsible to hide claims tile on SHM_CLAIMS restriction
   * @returns {boolean}
   */
  displayClaimsTile() {
    return !this.jsonSvc.hasRestriction(Restriction.SHM_CLAIMS, this.restrictions);
  }
}
