import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RegistrationService } from '../registration.service';
import { oneTimePasscodeModel } from 'sydmed/libs/one-time-passcode/one-time-passcode.model';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { ErrorCodeMapperService } from 'sydmed/libs/error-code-mapper/src/lib/error-code-mapper.service'
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { RegistrationDataService } from '../app.data.service';

@Component({
  selector: 'app-account-recovery-number',
  templateUrl: './account-recovery-number.component.html',
  styleUrls: ['./account-recovery-number.component.css']
})
export class AccountRecoveryNumberComponent implements OnInit {

  constructor(
    private readonly formBuilder: FormBuilder,
    private regSvc: RegistrationService,
    private router: Router,
    private _getSecurityCodeModel: oneTimePasscodeModel,
    private errSvc: ErrorCodeMapperService,
    private jsonSvc: JsonContentService,
    private dataSvc: RegistrationDataService
  ) { }

  recoveryNumberForm: FormGroup;
  public accountRecoveryModel: any = [];
  public memberInputHolder: any = [];
  public acctDetails: any = [];
  public aslError: string;
  public jsonLabels;
  public jsonErrors;
  public radioGroup = {
    isOptional: false,
    alignVertical: false, //set to true to align vertically
    id: 'rbtnGrp1',
    name: 'rbtnGrp1',
    options: [
      {
        label: 'Text',
        value: 'text',
        analytics: 'analytics-one'
      },
      {
        label: 'Call',
        value: 'voice',
        analytics: 'analytics-two'
      },
    ]
  };
  
  ngOnInit(): void {
    this.jsonSvc.getJSON('registration-mfa').subscribe(data => {
      this.jsonLabels = data.RegistrationMFA.Labels.Account_Recovery_Number;
      this.jsonErrors = data.RegistrationMFA.ErrorMsgs;
    });
    // zipCode: ['',[Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
    this.recoveryNumberForm = this.formBuilder.group({
      phone: ['', [Validators.required, FormValidators.PhoneNumberValidator]],
      zipCode: ['',[Validators.required,  Validators.pattern("^(\\d{5})$")]],
      addressNumber: ['',[Validators.required]],
      existingPhone:  ['', [Validators.required, FormValidators.PhoneNumberValidator]]
    })
    
    this.accountRecoveryModel = sessionStorage.getItem('contactsModel');
    this.accountRecoveryModel = JSON.parse(this.accountRecoveryModel);
    this.accountRecoveryModel = this._getSecurityCodeModel.transformContactInfo(this.accountRecoveryModel, "ARN");
    
  }

  isBCBSMNMarket()
  {
    if(this.jsonSvc.getHostMarket() === 'BCBSMN')
    {
      this.recoveryNumberForm.get("option").setValue("voice");
      return true;
    }
    return false;
  }

  sendCode(){
    if(this.recoveryNumberForm.valid) {
      const otpRequest = {
        addressNumber: this.recoveryNumberForm.value.addressNumber,
        phone: this.recoveryNumberForm.value.phone,
        subType: "voice",
        type: "" ,
        value: this.recoveryNumberForm.value.existingPhone,
        zipCode: this.recoveryNumberForm.value.zipCode,
     }
     this.accountRecoveryModel = this._getSecurityCodeModel.setSelectedInfoForARN(otpRequest);
     this.regSvc.callOtpAPI(this.accountRecoveryModel).subscribe((res: any) => {
        this.router.navigate(['public/registration/enter-security-code']);
      },
      error => {
        this.aslError = this.errSvc.errorCodeMapper(error, this.jsonErrors);
      })
    } else {
      this.recoveryNumberForm.markAllAsTouched();
      this.dataSvc.focusInvalid();
    }
  }

  back() {
    this.router.navigate(['public/registration/get-security-code']);
  }
}
