import { Injectable } from '@angular/core';
import { throwError, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';
import { CommunicationPreference, PreferenceChannel, PreferenceLOB } from 'gbd-models/src/features/profile/commPrefs';

const endPoints = UrlProperties.UrlProperties.endPoints;

@Injectable( {
  providedIn: 'root'
} )
export class HeaderAlertsService {

  protected pharmacyEndpoints = endPoints.pharmacy;
  contactInfo: any;
  commPrefs: CommunicationPreference[];

  constructor( protected http: HttpClientService ) { }

  // get contact info
  protected getContactInfo() {
    const { contactInfoApi } = this.pharmacyEndpoints;
    return this.http.getObservable( contactInfoApi ).pipe( map( res => {
      return res;
    } ), catchError(error => throwError(error)) );
  }

  protected getCommPrefs() {
    const { commPrefs } = this.pharmacyEndpoints;
    return this.http.getObservable(commPrefs).pipe(map(res => {
      return res;
    }), catchError(error => throwError(error)));
  }

  // process email alert
  getEmailAddressExists() {
    if ( this.contactInfo ) {
      return of( this.checkForEmail( this.contactInfo ) );
    }
    return this.getContactInfo().pipe( map( contactInfo => {
      this.contactInfo = contactInfo;
      return this.checkForEmail( contactInfo );
    } ), catchError(error => throwError(error)) );
  }

  protected checkForEmail(contactInfo): boolean {
    const emails = contactInfo && contactInfo.body && contactInfo.body.emailAddresses;
    return emails && emails.pharmacy && emails.pharmacy.length > 0;
  }

  getCommPreferencesExist() {
    if (this.contactInfo && this.commPrefs && this.commPrefs.length > 0) {
      return of(this.checkComPreferences(this.contactInfo));
    } else if (this.contactInfo) {
      return this.getCommPrefs().pipe(map(response => {
        this.commPrefs = response && response.body && response.body.preferences;
        return this.checkComPreferences(this.contactInfo);
      }), catchError(error => throwError(error)));
    } else if (this.commPrefs && this.commPrefs.length > 0) {
      return this.getContactInfo().pipe(map(contactInfo => {
        this.contactInfo = contactInfo;
        return this.checkComPreferences(contactInfo);
      }), catchError(error => throwError(error)));
    }

    return this.getCommPrefs().pipe(
      mergeMap((response) => {
        this.commPrefs = response && response.body && response.body.preferences;
        return this.getContactInfo().pipe(map(contactInfo => {
          this.contactInfo = contactInfo;
          return this.checkComPreferences(contactInfo);
        }), catchError(error => throwError(error)));
      }));
  }



  protected checkComPreferences(contactInfo): boolean {
    let exists;
    let emailOptionsExist = true;
    let emailOptions;
    let phoneNumOptionsExist = true;
    let phoneOptions;

    const emails = contactInfo && contactInfo.body && contactInfo.body.emailAddresses;
    if (emails && emails.pharmacy) {
      if (this.commPrefs && this.commPrefs.length > 0) {
        emailOptions = this.commPrefs.filter(pref => {
          return pref.lineOfBusiness === PreferenceLOB.PHARMACY && pref.channels.includes(PreferenceChannel.EMAIL); // MAIL
        });
      }
    }
    emailOptionsExist = emailOptions && emailOptions.length > 0 ? true : false;

    const phoneNumbers = contactInfo && contactInfo.body &&  contactInfo.body.phoneNumbers;
    if (phoneNumbers && phoneNumbers.pharmacy) {
      if (this.commPrefs && this.commPrefs && this.commPrefs.length > 0) {
        phoneOptions = this.commPrefs.filter(pref => {
          return pref.lineOfBusiness === PreferenceLOB.PHARMACY && pref.channels.includes(PreferenceChannel.VOICE); // SMS
        });
      }
    }
    phoneNumOptionsExist = phoneOptions && phoneOptions.length > 0 ? true : false;

    exists = (emailOptionsExist && phoneNumOptionsExist);
    return exists;
  }
}
