import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientService } from 'sydmed/libs/http-client-service/src/public-api';
import { UrlProperties } from 'sydmed/libs/url-properties/src/public-api';

const apiUrl = UrlProperties.UrlProperties.endPoints.pharmacy.acknowledgment;

@Injectable({
  providedIn: 'root'
})
export class AcknowledgementService {

  constructor( protected http: HttpClientService ) { }

  getAcknowledgement( name: string ) {
    const params = {
      name
    };
    return this.http.getObservable( apiUrl, params ).pipe( map( res => {
      return res.body;
    } ),
      catchError( err => throwError( err ) ) );
  }

  setAcknowledgement() {
    const params = {
        name: 'PHARMACY_ONBOARDING',
        accepted: true
    };
    return this.http.postObservable(apiUrl, params).pipe(map(res => {
      return res;
    }),
      catchError(err => throwError(err)));
  }
}
