import { PaymentWalletDetails } from '@anthem/mbrportal-pharmacy/interfaces/vendorPaymentWallet';
import { BankAccountTypeCode, BankPaymentType, CardType, PaymentNetwork, PaymentWallet } from '@sydney/models';

export const paymentWallet: PaymentWalletDetails = {
  bankAccounts: [
    {
      accountHolderName: 'TEST',
      accountName: 'bank',
      accountNumber: '*****8010',
      bankAccountType: BankAccountTypeCode.PERSONAL_SAVINGS,
      billingAddress: {
        streetAddress1: 'Yamuna',
        streetAddress2: 'DELHI',
        city: 'city',
        zipCode: '12345',
        country: 'country',
        state: 'CA'
      },
      isDefaultPayment: false,
      paymentType: BankPaymentType.BANKING_ACCOUNT,
      routingNumber: '071103473',
      tokenId: '83c92318ca574ba5a0bf4f21d63ceb1a'
    }
  ],

  cards: [
    {
      active: true,
      billingAddress: {
        streetAddress1: '6913 Tyler St',
        streetAddress2: 'test',
        city: 'Merrillville',
        zipCode: '46410',
        country: 'country',
        state: 'IN'
      },
      cardHolderName: 'ABC XYZ',
      cardLastFourDigits: '1443',
      cardNumber: '************1443',
      cardType: CardType.CREDIT,
      expirationMonth: 10,
      expirationYear: 2026,
      isDefaultPayment: true,
      paymentNetwork: PaymentNetwork.MASTER_CARD,
      tokenId: '4eb144565bfb40a4a3b94fc11c973f6d'
    },
    {
      active: true,
      billingAddress: {
        streetAddress1: '6913 Tyler St',
        city: 'Merrillville',
        zipCode: '46410',
        country: 'country',
        state: 'IN'
      },
      cardHolderName: 'ABC XYZ',
      cardLastFourDigits: '1443',
      cardNumber: '************1443',
      cardType: CardType.CREDIT,
      expirationMonth: 10,
      expirationYear: 2026,
      isDefaultPayment: false,
      paymentNetwork: PaymentNetwork.MASTER_CARD,
      tokenId: '4eb144565bfb40a4a3b94fc11c973f6d'
    }
  ],
  restrictions: {
          bankAccount: true,
          defaultPaymentMethodSelection: false,
          deleteSlider: true
        },
};

export const addPaymentRequest = {
  cards: [
    {
      cardHolderName: 'sv',
      cardLastFourDigits: '1881',
      cardNumber: '4012887839071881',
      cardType: 'CREDITDEBITCARD',
      paymentNetwork: 'VISA',
      expirationMonth: 2,
      expirationYear: 2025,
      integrityCheck: '389eaf4f6dc25e47',
      keyId: '4d8c9ab6',
      phaseId: 0,
      securityCode: '034',
      billingAddress: {
        streetAddress1: '3501 Chesterbrook Ct Apt 631',
        city: 'Henrico',
        state: 'VA',
        zipCode: '232331297',
        country: 'United States'
      },
      isDefaultPayment: false
    }
  ]
};

export const addPaymentResponse = {
  tokenId: '123'
};

export const deleteAccountRequest = {
  bankAccounts: [{
    tokenId: '123'
  }]
};

export const deleteAccountResponse = {
  tokenId: '123'
};

export const routingNumberResponse = {
  memberPayException: {
    error: ''
  },
  routingNoDetails: {
    customerName: 'test'
  },
  valid: true
};

export const routingNumberRequest = {
  routingNumber: '12345678'
};

export const paymentWalletDetails: PaymentWallet = {
  bankAccounts: [
    {
      accountHolderName: 'TEST',
      accountName: 'bank',
      accountNumber: '*****8010',
      bankAccountType: BankAccountTypeCode.PERSONAL_SAVINGS,
      billingAddress: {
        streetAddress1: 'Yamuna',
        streetAddress2: 'DELHI',
        city: 'city',
        zipCode: '12345',
        country: 'country',
        state: 'CA'
      },
      isDefaultPayment: false,
      paymentType: BankPaymentType.BANKING_ACCOUNT,
      routingNumber: '071103473',
      tokenId: '83c92318ca574ba5a0bf4f21d63ceb1a'
    },
    {
      accountHolderName: 'TEST1',
      accountName: 'bank',
      accountNumber: '*****8089',
      bankAccountType: BankAccountTypeCode.PERSONAL_SAVINGS,
      billingAddress: {
        streetAddress1: 'Yamuna',
        streetAddress2: 'DELHI',
        city: 'city',
        zipCode: '12345',
        country: 'country',
        state: 'CA'
      },
      isDefaultPayment: false,
      paymentType: BankPaymentType.BANKING_ACCOUNT,
      routingNumber: '071103473',
      tokenId: '83c92318ca574ba5a0bf4f21d63bea1g'
    }
  ],

  cards: [
    {
      active: true,
      billingAddress: {
        streetAddress1: '6913 Tyler St',
        streetAddress2: 'test',
        city: 'Merrillville',
        zipCode: '46410',
        country: 'country',
        state: 'IN'
      },
      cardHolderName: 'ABC XYZ',
      cardLastFourDigits: '1443',
      cardNumber: '************1443',
      cardType: CardType.CREDIT,
      expirationMonth: 10,
      expirationYear: 2026,
      isDefaultPayment: false,
      paymentNetwork: PaymentNetwork.MASTER_CARD,
      tokenId: '4eb144565bfb40a4a3b94fc11c973f6d'
    },
    {
      active: true,
      billingAddress: {
        streetAddress1: '6913 Tyler St',
        city: 'Merrillville',
        zipCode: '46410',
        country: 'country',
        state: 'IN'
      },
      cardHolderName: 'ABC XYZ',
      cardLastFourDigits: '1443',
      cardNumber: '************1443',
      cardType: CardType.CREDIT,
      expirationMonth: 10,
      expirationYear: 2026,
      isDefaultPayment: false,
      paymentNetwork: PaymentNetwork.MASTER_CARD,
      tokenId: '4eb144565bfb40a4a3b94fc11c973f6d'
    },
    {
      active: true,
      billingAddress: {
        streetAddress1: '6913 Tyler St',
        city: 'Merrillville',
        zipCode: '46410',
        country: 'country',
        state: 'IN'
      },
      cardHolderName: 'ABC XYZ',
      cardLastFourDigits: '1444',
      cardNumber: '************1445',
      cardType: CardType.CREDIT,
      expirationMonth: 10,
      expirationYear: 2026,
      isDefaultPayment: false,
      paymentNetwork: PaymentNetwork.MASTER_CARD,
      tokenId: '4eb144565bfb40a4a3b94fc11c972k0d'
    },
    {
      active: true,
      billingAddress: {
        streetAddress1: '6913 Tyler St',
        city: 'Merrillville',
        zipCode: '46410',
        country: 'country',
        state: 'IN'
      },
      cardHolderName: 'ABC XYZ',
      cardLastFourDigits: '1444',
      cardNumber: '************1234',
      cardType: CardType.CREDIT,
      expirationMonth: 10,
      expirationYear: 2026,
      isDefaultPayment: false,
      paymentNetwork: PaymentNetwork.MASTER_CARD,
      tokenId: '4eb144565bfb40a4a3b94fc11c972k0d'
    }
  ]
};

export const bankAccoutTypeCode = {
  BUSCHECKING: "BUSINESSCHECKING",
  BUSSAVINGS: "BUSINESSSAVING",
  CHECKING: "CHECKING",
  PERSONALCHECKING: "PERSONALCHECKING",
  PERSONALSAVINGS: "PERSONALSAVING",
  SAVINGS: "SAVINGS"
};

export const mockVendorPaymentWalletContent = {
  paymentWallet: {
    bankAccountTitle: 'Bank Accounts',
    cardsTitle: 'Credit and Debit Cards',
    labels: {
      accountNumber: 'Acct#:',
      autoRefill: 'Used for Auto Refills',
      cardExpired: 'Card Expired',
      expiryDate: 'Expires ',
      primaryCard: 'Primary'
    },
    links: {
      edit: 'Edit',
      delete: 'Delete',
      setAsDefault: 'Set as Default',
      setAsPrimary: 'Set as Primary',
      newPaymentLinkTxt: 'Add payment method for home delivery'
    },
    addCard: {
      defaultPayment: 'Testing',
      address: {
        country: 'United States'
      },
      buttons: {
        update: 'Update',
        save: 'Save',
        add: 'Add'
      }
    },
    bankAccount: {
      bankAccountType: {
        BUSCHECKING: 'Business Checking',
        BUSSAVINGS: 'Business Saving',
        CHECKING: 'Checking',
        PERSONALCHECKING: 'Personal Checking',
        PERSONALSAVINGS: 'Personal Saving',
        SAVINGS: 'Saving'
      },
      dateToPay: 'This bank account will be used for your payment as of today, 08/21/2023'
    },
    deleteCardOrAccountModel: {
      cardDetail: {
        expires: 'Expires'
      },
      errorMessage: "Something went wrong!"
    },
    defaultPayment: {
      cardNumber: '#value-#value',
      billingAddress: '#address, #address, #address #address',
      expiryDate: 'Expires #date/#date',
    }
  }
};

export const defaultCard = {
  cardNumber: '************1443',
  expiryDate: '10/26'
};

export const defaultPaymentCards = [{
  active: true,
  billingAddress: {
    streetAddress1: '6913 Tyler St',
    streetAddress2: 'test',
    city: 'Merrillville',
    zipCode: '46410',
    country: 'country',
    state: 'IN'
  },
  cardHolderName: 'ABC XYZ',
  cardLastFourDigits: '1443',
  cardNumber: '************1443',
  cardType: CardType.CREDIT,
  expirationMonth: 10,
  expirationYear: 2026,
  isDefaultPayment: true,
  paymentNetwork: PaymentNetwork.MASTER_CARD,
  tokenId: '4eb144565bfb40a4a3b94fc11c973f6d'
}];



export const specialityQuestionsMock: any = {
  "memberInfo": [
    {
      "dateOfBirth": "2002-04-21",
      "firstName": "ZARIA",
      "lastName": "MACK",
      "signatureRequiredForDeliveryIndicator": true,
      "refillPrescriptionQuestions": {
        "prescriptionQuestions": [
          {
            "nonClinicalIndicator": false,
            "questionId": 3040,
            "questionId2": 40310840,
            "questionText": "Are you currently being followed by a dietitian?",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "answerOption": [
              {
                "answerOptionId": 3041,
                "answerOptionId2": 40310841,
                "answerText": "Yes",
                "isChecked": false
              },
              {
                "answerOptionId": 3042,
                "answerOptionId2": 40310842,
                "answerText": "No",
                "subQuestion": [
                  {
                    "nonClinicalIndicator": false,
                    "questionId": 3043,
                    "questionId2": 40310843,
                    "questionText": "ARE YOU INTERESTED IN RECEIVING NUTRITIONAL SUPPORT COUNSELING ON ORAL SUPPLEMENTS, TUBE FEEDINGS OR TPN?",
                    "answerId": 0,
                    "answerType": "SINGLE_SELECT",
                    "answerOption": [
                      {
                        "answerOptionId": 3044,
                        "answerOptionId2": 40310844,
                        "answerText": "Yes",
                        "isChecked": false
                      },
                      {
                        "answerOptionId": 3045,
                        "answerOptionId2": 40310845,
                        "answerText": "No",
                        "isChecked": false
                      }
                    ]
                  }
                ],
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": false,
            "questionId": 12322,
            "questionId2": 40310846,
            "questionText": " If you currently work or attend school, have you missed any days in the past month?",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "answerOption": [
              {
                "answerOptionId": 12323,
                "answerOptionId2": 40310847,
                "answerText": "Yes",
                "subQuestion": [
                  {
                    "nonClinicalIndicator": false,
                    "questionId": 12327,
                    "questionId2": 40310848,
                    "questionText": "Approximately how many days of work or school have you missed in the past month? Please enter a number from 1 to 31.",
                    "answerId": 0,
                    "answerType": "TEXT_NUMBER"
                  }
                ],
                "isChecked": false
              },
              {
                "answerOptionId": 12324,
                "answerOptionId2": 40310849,
                "answerText": "No",
                "isChecked": false
              },
              {
                "answerOptionId": 12325,
                "answerOptionId2": 40310850,
                "answerText": "Refuse to answer",
                "isChecked": false
              },
              {
                "answerOptionId": 12326,
                "answerOptionId2": 40310851,
                "answerText": "The patient does not work or attend school",
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": false,
            "questionId": 12524,
            "questionId2": 40310852,
            "questionText": "OVER THE PAST MONTH, HAVE YOU HAD A FLARE UP OR INCREASE IN BOTHERSOME ABDOMINAL AND BOWEL SYMPTOMS?",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "answerOption": [
              {
                "answerOptionId": 12525,
                "answerOptionId2": 40310853,
                "answerText": "YES",
                "isChecked": false
              },
              {
                "answerOptionId": 12526,
                "answerOptionId2": 40310854,
                "answerText": "NO",
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": false,
            "questionId": 12528,
            "questionId2": 40310856,
            "questionText": "OVER THE PAST MONTH, HAVE YOU HAD AN INCREASE IN BOTHERSOME SYMPTOMS SUCH AS NEW SWOLLEN JOINTS OR WORSENING JOINT PAIN?",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "answerOption": [
              {
                "answerOptionId": 12529,
                "answerOptionId2": 40310857,
                "answerText": "yes",
                "isChecked": false
              },
              {
                "answerOptionId": 12530,
                "answerOptionId2": 40310858,
                "answerText": "no",
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": false,
            "questionId": 12520,
            "questionId2": 40310860,
            "questionText": "OVER THE PAST MONTH, HAVE YOU HAD ANY NEW RED PATCHES OR AN INCREASE IN SKIN ITCHING OR BURNING THAT ARE RELATED TO YOUR SKIN CONDITION?",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "answerOption": [
              {
                "answerOptionId": 12521,
                "answerOptionId2": 40310861,
                "answerText": "YES",
                "isChecked": false
              },
              {
                "answerOptionId": 12522,
                "answerOptionId2": 40310862,
                "answerText": "NO",
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": true,
            "questionId": 2554,
            "questionId2": 40310875,
            "questionText": "Since your last refill, have you had any new allergies?",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "responseFieldIdentifier": "ALLERGY IND",
            "answerOption": [
              {
                "answerOptionId": 2555,
                "answerOptionId2": 40310876,
                "answerText": "Yes",
                "subQuestion": [
                  {
                    "nonClinicalIndicator": true,
                    "questionId": 2556,
                    "questionId2": 40310877,
                    "questionText": "Please enter allergy details",
                    "answerId": 0,
                    "answerType": "TEXT",
                    "responseFieldIdentifier": "ALLERGY DETAILS"
                  }
                ],
                "isChecked": false
              },
              {
                "answerOptionId": 2557,
                "answerOptionId2": 40310878,
                "answerText": "No",
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": true,
            "questionId": 2558,
            "questionId2": 40310879,
            "questionText": "How much of your medication(s) do you have remaining? For insurance purposes, you must provide the exact number of individual pills, ampules, or injections left.",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "responseFieldIdentifier": "DOSE LEFT IND",
            "answerOption": [
              {
                "answerOptionId": 2559,
                "answerOptionId2": 40310880,
                "answerText": "Yes",
                "subQuestion": [
                  {
                    "question": [
                      {
                        "nonClinicalIndicator": true,
                        "questionId": 2560,
                        "questionId2": 40310881,
                        "questionText": "How many pills, ampules or injections do you have remaining?",
                        "answerId": 0,
                        "answerType": "TEXT_NUMBER",
                        "responseFieldIdentifier": "DOSE LEFT"
                      },
                      {
                        "nonClinicalIndicator": true,
                        "questionId": 2561,
                        "questionId2": 40310882,
                        "questionText": "When will you take your next dose?",
                        "answerId": 0,
                        "answerType": "TEXT_DATE",
                        "responseFieldIdentifier": "NEXT DOSE"
                      }
                    ],
                    "rxNumber": "15514555",
                    "drugName": "TWINRIX SUSP",
                    "drugNdc": "58160081511"
                  }
                ],
                "isChecked": false
              },
              {
                "answerOptionId": 2562,
                "answerOptionId2": 40310883,
                "answerText": "No",
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": false,
            "questionId": 2788,
            "questionId2": 40310884,
            "questionText": "Since your last refill, do you feel that your medication has improved your symptoms, helped them to stay the same, or is not helping?",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "answerOption": [
              {
                "answerOptionId": 2789,
                "answerOptionId2": 40310885,
                "answerText": "Improved symptoms",
                "isChecked": false
              },
              {
                "answerOptionId": 2790,
                "answerOptionId2": 40310886,
                "answerText": "Helped symptoms stay the same",
                "isChecked": false
              },
              {
                "answerOptionId": 2791,
                "answerOptionId2": 40310887,
                "answerText": "Is not helping",
                "isChecked": false
              }
            ]
          },
          {
            "nonClinicalIndicator": false,
            "questionId": 12652,
            "questionId2": 40310888,
            "questionText": "END_GIRI_BG2",
            "answerId": 0,
            "answerType": "SINGLE_SELECT",
            "answerOption": [
              {
                "answerOptionId": 12653,
                "answerOptionId2": 40310889,
                "answerText": "yes",
                "isChecked": false
              },
              {
                "answerOptionId": 12654,
                "answerOptionId2": 40310890,
                "answerText": "no",
                "isChecked": false
              }
            ]
          }
        ],
        "uniqueRxId": [
          "1810158815514555"
        ]
      },
      "memberUid": "358616433"
    }
  ]
};
