import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Form } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor() { }

  protected searchTerm$ = new Subject<string>();
  searchTerm = this.searchTerm$.asObservable();

  protected resetSearch$ = new BehaviorSubject<boolean>(false);
  public resetSearch = this.resetSearch$.asObservable();

  searchForTerm(searchTerm) {
    this.searchTerm$.next(searchTerm);
  }

  cancelSearch() {
    this.searchTerm$.next(null);
  }

  setResetSearch(value: boolean) {
    this.resetSearch$.next(value);
  }
}
