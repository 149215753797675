import { Component, OnInit, OnDestroy } from '@angular/core';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/lib/inter-communication.service';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { CostShareContent } from '../models/CostShareContent';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CostShareService } from '../services/cost-share.service';
import { LtssResponse, OutOfPocketResponse, Restriction } from 'gbd-models';
import { Quarter } from '../models/Quarter';

@Component( {
  selector: 'app-cost-share',
  templateUrl: './cost-share.component.html',
  styleUrls: [ './cost-share.component.scss' ]
} )
export class CostShareComponent implements OnDestroy, OnInit {
  annualTotal: number;
  annualCopay: number;
  currentQuarter: number;
  currentQuarterAmount: number;
  content: CostShareContent;
  hasCostShare: boolean = false;
  hasOnlyCopay: boolean = false;
  hasOutOfPocketData = this.costShareService.hasOutOfPocketData;
  memberLiability: LtssResponse;
  memberLiabilityError: boolean;
  outOfPocket: OutOfPocketResponse;
  outOfPocketError: boolean = false;
  loadingSpinner = false;
  loadingLiabilitySpinner = false;
  quarters: Quarter[];
  protected subscriptions = new Subscription();
  constructor(
    protected contentService: JsonContentService,
    protected costShareService: CostShareService,
    protected titleService: InterCommunicationService
  ) { }

  ngOnInit(): void {
    this.getContent();
    this.getMemberLiability();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected getContent() {
    this.loadingSpinner = true;
    const sub = this.contentService.getJSON( 'cost-share' )
      .pipe(mergeMap(( content: CostShareContent ) => {
          this.content = content;
          const { titleType, titleValue } = content.title;
          this.titleService.raiseEvent( { title: titleType, message: titleValue  } );
          this.hasCostShare = !this.contentService.hasRestriction( Restriction.SHM_COST_SHARE, content.restrictions );
          this.hasOnlyCopay = this.contentService.hasRestriction(Restriction.SHM_COST_SHARE_COPAY_ONLY, content.restrictions);
          return this.getQuarters(this.content.outOfPocket.quarterTitles);
        } ))
        .subscribe((quarters: Quarter[]) => {
          this.outOfPocketError = false;
          this.quarters = quarters;
          this.currentQuarter = this.getCurrentQuarter();
          this.currentQuarterAmount = this.quarters[this.currentQuarter - 1].amount;
          this.loadingSpinner = false;
      },
      error => {
        this.loadingSpinner = false;
        this.outOfPocketError = true;
      });
    this.subscriptions.add(sub);
  }
  protected getMemberLiability() {
    this.loadingLiabilitySpinner = true;
    const sub = this.costShareService.getMemberLiability().subscribe(memberLiability => {
      this.memberLiability = memberLiability;
      this.memberLiabilityError = false;
      this.loadingLiabilitySpinner = false;
    },
    error => {
      this.memberLiabilityError = true;
      this.loadingLiabilitySpinner = false;
    });
    this.subscriptions.add(sub);
  }

  protected getQuarters(titles: string[]): Observable<Quarter[]> {
    return this.costShareService.getOutOfPocket().pipe(map((outOfPocket: OutOfPocketResponse) => {
      this.outOfPocket = outOfPocket;
      const { 
        currentYearAnnualAccums, 
        totalAmountForYear, 
        quarterOneAmount, 
        quarterTwoAmount, 
        quarterThreeAmount, 
        quarterFourAmount 
      } = this.outOfPocket;

      this.annualTotal = totalAmountForYear;
      this.annualCopay = currentYearAnnualAccums;

      const outOfPocketAmounts = [quarterOneAmount, quarterTwoAmount, quarterThreeAmount, quarterFourAmount];
      const quarters = outOfPocketAmounts.map((amount, index) => {
        return {amount, title: titles[index]};
      });
      return quarters;
    }, catchError(error => throwError(error))));
  }

  protected getCurrentQuarter() {
    const currentDate = new Date();
    return Math.floor((currentDate.getMonth() + 3) / 3);
  }
}
