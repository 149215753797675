<div class="form-row">
  <div class="form-item">
    <div
      data-uxd-dropdown-cmp
      class="ant-lg-select form-item"
      [items]="sortByFilter?.options"
      required
      [defaultText]="defaultLabel"
      [labelText]="sortByFilter?.title"
      [(ngModel)]="sortByValue"
      (ngModelChange)="onSortByChange()"></div>
  </div>
</div>