import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GrievancesDataService } from '../grievances-data.service';
import { Router } from '@angular/router';
import { FormValidators } from 'sydmed/libs/custom-validators/src/lib/form-validators.class';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { OnlineGrievancesLabels, OnlineGrievancesErrorMsgs } from '../models/labels';
import { InterCommunicationService } from 'sydmed/libs/inter-communication-service/src/public-api';
import { ContactInfoType, GrievanceType, GrievanceRequest } from 'gbd-models';

@Component({
  selector: 'app-grievances-form',
  templateUrl: './grievances-form.component.html',
  styleUrls: ['./grievances-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GrievancesFormComponent implements OnInit {
  public stateLob: string;

  constructor(
    private dataSvc: GrievancesDataService,
    private router: Router,
    private readonly formBuilder: FormBuilder,
    private jsonSvc: JsonContentService,
    private interCommunicationService: InterCommunicationService
  ) { 
    this.interCommunicationService.raiseEvent({
      title: 'HEADER_TITLE',
      message: 'Grievance Form'
    });
    const memberContext = JSON.parse(window.sessionStorage.getItem('sydMedMemberContext'));
    this.stateLob = memberContext.stateLob;
  }

  // Grievance form object
  grievanceForm: FormGroup;

  // Form Labels
  public formLabels: OnlineGrievancesLabels;
  public errorMsgs: OnlineGrievancesErrorMsgs;

  public contactRadioGroup: any;
  public contactRadioVal: ContactInfoType;

  public grievanceForRadioGroup: any;
  public grievanceForRadioVal: GrievanceType;

  ngOnInit(): void {
    this.jsonSvc.getJSON('online-grievances').subscribe(
      (res) => {
        this.formLabels = res.OnlineGrievances.Form;
        this.errorMsgs = res.OnlineGrievances.ErrorMsgs;
        this.initRadioGroup();
      }
    );

    // reactive formgroup
    this.grievanceForm = this.formBuilder.group({
      memberName: ['', [Validators.required, FormValidators.WhiteSpace, FormValidators.NameValidator]],
      memberId: ['', [Validators.required, FormValidators.WhiteSpace]],
      memberDob: ['', [Validators.required, FormValidators.ValidDateValidator]],
      preferredModeOfCommunication: [ContactInfoType.PHONE],
      phoneNumber: ['', [Validators.required, FormValidators.PhoneNumberValidator]],
      email: ['', [FormValidators.EmailValidator]],
      grievanceFor: [GrievanceType.GRIEVANCE_FOR_ME],
      grievanceForPerson: [''],
      claimNumber: [''],
      providerName: [''],
      dateOfService: ['', [FormValidators.ValidDateValidator]],
      grievanceDetail: ['', [Validators.maxLength(2000)]]
    });

    this.checkForFormData();
  }

  public initRadioGroup() {
    this.contactRadioVal =  ContactInfoType.PHONE;
    this.contactRadioGroup = {
      isOptional: false,
      alignVertical: false,
      id: 'contactRadioBtnGrp',
      name: 'contactBtnGrp',
      label: this.formLabels?.Contact,
      options: [
        {
          label: this.formLabels?.ContactOptions.Phone,
          value: ContactInfoType.PHONE,
          analytics: 'analytics-one'
        },
        {
          label: this.formLabels?.ContactOptions.Email,
          value: ContactInfoType.EMAIL,
          analytics: 'analytics-two'
        }
      ]
    };

    this.grievanceForRadioVal = GrievanceType.GRIEVANCE_FOR_ME;
    this.grievanceForRadioGroup = {
      isOptional: false,
      alignVertical: false,
      id: 'grievanceForRadioBtnGrp',
      name: 'grievanceForBtnGrp',
      label: this.formLabels?.Party,
      options: [
        {
          label: this.formLabels?.PartyOptions.Me,
          value: GrievanceType.GRIEVANCE_FOR_ME,
          analytics: 'analytics-one'
        },
        {
          label: this.formLabels?.PartyOptions.SomeoneElse,
          value: GrievanceType.GRIEVANCE_FOR_SOMEONE_ELSE,
          analytics: 'analytics-two'
        }
      ]
    };
  }

  private checkForFormData() {
    const formData: GrievanceRequest = this.dataSvc.getFormData();

    if (formData) {
      this.grievanceForm.get('memberName').setValue(formData.memberName);
      this.grievanceForm.get('memberId').setValue(formData.memberId);
      this.grievanceForm.get('memberDob').setValue(formData.memberDob);
      this.grievanceForm.get('email').setValue(formData.email);
      this.grievanceForm.get('phoneNumber').setValue(formData.phoneNumber);
      this.grievanceForm.get('grievanceForPerson').setValue(formData.grievanceForPerson);
      this.grievanceForm.get('claimNumber').setValue(formData.claimNumber);
      this.grievanceForm.get('providerName').setValue(formData.providerName);
      this.grievanceForm.get('dateOfService').setValue(formData.dateOfService);
      this.grievanceForm.get('grievanceDetail').setValue(formData.grievanceDetail);
      this.contactRadioVal = this.dataSvc.getContactRadioVal();
      this.grievanceForRadioVal = this.dataSvc.getGrievanceForRadioVal();

      if (this.contactRadioVal === ContactInfoType.EMAIL) {
        const phoneControl = this.grievanceForm.get('phoneNumber');
        phoneControl.setValidators([FormValidators.PhoneNumberValidator]);
        phoneControl.updateValueAndValidity();
      }

      if (this.grievanceForRadioVal === GrievanceType.GRIEVANCE_FOR_SOMEONE_ELSE) {
        const memberIdControl = this.grievanceForm.get('memberId');
        const memberDobControl = this.grievanceForm.get('memberDob');
        const grievanceForPersonControl = this.grievanceForm.get('grievanceForPerson');

        memberIdControl.clearValidators();
        memberDobControl.setValidators([FormValidators.ValidDateValidator]);
        grievanceForPersonControl.setValidators([Validators.required, FormValidators.WhiteSpace, FormValidators.NameValidator]);
        memberIdControl.updateValueAndValidity();
        memberDobControl.updateValueAndValidity();
        grievanceForPersonControl.updateValueAndValidity();
      }
    }
  }

  public previewForm() {
    if (this.grievanceForm.valid) {
      Object.keys(this.grievanceForm.controls).forEach((key) =>
        this.grievanceForm.get(key).setValue(this.grievanceForm.get(key).value.trim()));
      this.dataSvc.setFormData(this.grievanceForm.value);
      this.dataSvc.setContactRadioVal(this.contactRadioVal);
      this.dataSvc.setGrievanceForRadioVal(this.grievanceForRadioVal);
      this.router.navigate(['/secure/grievances-form-preview']);
    } else {
      Object.keys(this.grievanceForm.controls).forEach(field => {
        const control = this.grievanceForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public cancelForm() {
    const cancelUrlKey = 'Cancel_btn_url';
    location.href = this.formLabels[cancelUrlKey];
  }

  public returnToProfile() {
    const returnToProfileUrlKey = 'ReturnToProfile_btn_url';
    location.href = this.formLabels[returnToProfileUrlKey];
  }

  public contactValChanged() {
    const phoneControl = this.grievanceForm.get('phoneNumber');
    const emailControl = this.grievanceForm.get('email');
    if (this.contactRadioVal === ContactInfoType.PHONE) {
      phoneControl.setValidators([Validators.required, FormValidators.PhoneNumberValidator]);
      emailControl.setValidators([FormValidators.EmailValidator]);
    } else if (this.contactRadioVal === ContactInfoType.EMAIL) {
      emailControl.setValidators([Validators.required, FormValidators.EmailValidator]);
      phoneControl.setValidators([FormValidators.PhoneNumberValidator]);
    }

    phoneControl.updateValueAndValidity();
    emailControl.updateValueAndValidity();
  }

  public grievanceForValChanged() {
    const grievanceForPersonControl = this.grievanceForm.get('grievanceForPerson');
    const memberIdControl = this.grievanceForm.get('memberId');
    const memberDobControl = this.grievanceForm.get('memberDob');
    if (this.grievanceForRadioVal === GrievanceType.GRIEVANCE_FOR_ME) {
      grievanceForPersonControl.setValue('');
      grievanceForPersonControl.clearValidators();

      memberIdControl.setValidators([Validators.required, FormValidators.WhiteSpace]);
      memberDobControl.setValidators([Validators.required, FormValidators.ValidDateValidator]);
    } else if (this.grievanceForRadioVal === GrievanceType.GRIEVANCE_FOR_SOMEONE_ELSE) {
      grievanceForPersonControl.setValidators([Validators.required, FormValidators.WhiteSpace, FormValidators.NameValidator]);

      memberIdControl.clearValidators();

      memberDobControl.clearValidators();
      memberDobControl.setValidators([FormValidators.ValidDateValidator]);
    }

    grievanceForPersonControl.updateValueAndValidity();
    memberIdControl.updateValueAndValidity();
    memberDobControl.updateValueAndValidity();
  }

  // getter methods to access form control
  get memberName() {
    return this.grievanceForm.get('memberName');
  }

  get memberId() {
    return this.grievanceForm.get('memberId');
  }

  get memberDob() {
    return this.grievanceForm.get('memberDob');
  }

  get phoneNumber() {
    return this.grievanceForm.get('phoneNumber');
  }

  get email() {
    return this.grievanceForm.get('email');
  }

  get grievanceForPerson() {
    return this.grievanceForm.get('grievanceForPerson');
  }

  get dateOfService() {
    return this.grievanceForm.get('dateOfService');
  }

  get grievanceDetail() {
    return this.grievanceForm.get('grievanceDetail');
  }
}