import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { PharmacySessionService } from 'sydmed/src/app/secure/pharmacy/services/pharmacy-session.service';
import { PharmacyModules } from 'sydmed/src/app/secure/pharmacy/enums/PharmacyModules';
@Injectable( {
  providedIn: 'root'
} )
export class PharmacyContentService<T> {
  content: T;
  constructor(
    protected contentService: JsonContentService,
    protected pharmacySession: PharmacySessionService ) { }

  getContent( module: PharmacyModules ) {
    if ( this.validSession() ) {
      if ( this.content ) {
        return of( this.content );
      }
      return this.fetchContent( module );
    } else {
      this.content = null;
      return of( this.content );
    }
  }
  protected fetchContent( module: PharmacyModules ): Observable<T> {
    return this.contentService.getJSON( module ).pipe(
      map( ( content: T ) => this.content = content ),
      catchError( error => throwError( error ) ) );
  }

  public validSession() {
    return this.pharmacySession.isValidSession();
  }

}
