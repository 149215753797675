import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNum'
})
export class PhoneNumPipe implements PipeTransform {

  transform(phoneNumber: string, args?: any): string {

    if (phoneNumber.length === 10) {
      phoneNumber = phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6, 10);
    }

    return phoneNumber;
  }
}
