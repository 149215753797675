<div class="view-prescription-grid-container">
  <div class="icon">
    <span class="motif-icon motif-pill" aria-hidden="true"></span>
  </div>
  <div class="title">
    <h2 class="motif-pad-left-3 font-family">{{ this.headingLabel }}</h2>
  </div>
  <div class="desc">
      <div class="motif-pad-left-3 motif-pad-bottom-5">{{ this.textLabel }}</div>
  </div>
  <div class="link">
    <a href="javascript:void(0)" class="ant-action-link" routerLink="{{ link }}" [attr.data-analytics]="analytics">
      <span class="motif-pad-left-3 motif-icon motif-right-round"></span><span class="ant-link-text">{{ linkText }}</span>
    </a>
  </div>
</div>