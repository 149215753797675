<div *ngIf="loading" class="loading-spinner">
  <uxd-spinner  class="messages-spinner"></uxd-spinner>
  <div class="text-center">{{ labels.Loading_lbl }}</div>
</div>

<div class="messageCenter-messages" *ngIf="!loading">
  <ng-container *ngIf="showNoEmailContent">
    <div class="motif-margin-left-9">
      <h2 class="motif-margin-top-5">{{labels.NoEmail_Header}}</h2>
      <div class="messageBody motif-margin-top-9">{{labels.NoUserEmail}}<a class="ant-link-text" [routerLink]='labels.UpdateEmailLink'> {{labels.SetupNow}} </a></div>
    </div>
  </ng-container>
  <!--The below template may be needed later as per business needs so keeping it for now-->
  <!-- <ng-container *ngIf="showNoEmailContent && aslErr; else showContentWhenNoAslErr">
    <div class="motif-margin-left-9">
      <h2 class="motif-margin-top-5">{{labels.NoEmail_Header}}</h2>
      <div class="messageBody motif-margin-top-9">{{labels.NoEmailAddress_content}}</div>
      <a href="{{ labels.UpdateEmailLink }}"><button class="btn btn-primary continue motif-margin-top-9">{{ labels.UpdateEmailButton }}</button></a>
    </div>
  </ng-container> -->

  <ng-container>
    <ng-container *ngIf="noMessages">
        <div class="noMessages text-center">
          <img src="assets/images/message-center/components-empty-states-illustrations-mailbox.svg" alt="mailbox icon">
          <h2>{{ labels.NoMessages_lbl }}</h2>
          <span id="MsgCntrInboxNoMsg_lbl">{{ emptyBox }}</span>
        </div>
    </ng-container>
    
    <ng-container *ngIf="!noMessages">
      <ng-container *ngFor="let message of messages | orderBy: sortBy.sort : sortBy.order, let i = index">
        <div class="message" [ngClass]="{unreadMessage: !message.read}" *ngIf="i < loadIndex">
          <button *ngIf="!message.messageLoading" class="messageContent" (click)="selectMessage(message)">
            <div [ngClass]="{messageTitle: !message.read}">{{ message.subject }}</div>
            <div class="messageBody" [innerHtml]="message.preview"></div>
          </button>
          <div *ngIf="!message.messageLoading" class="messageDate">
            <label>{{ message.received | messageDate:false }}</label>
          </div>
          <div *ngIf="!message.messageLoading" class="moveMessages">
            <button class="deleteBtn" *ngIf="folder === 'inbox' || folder === 'archived' || folder === 'chatTranscripts'" [attr.aria-label]="'Delete ' + message.subject + ' message from ' + message.received" (click)="handleClick(message)"><span class="motif-icon motif-trash" aria-hidden="true"></span></button>
            <button class="archiveBtn" *ngIf="(folder === 'inbox' || folder === 'chatTranscripts') && !isDesignee && !isTermed" [attr.aria-label]="'Archive ' + message.subject + ' message from ' + message.received" (click)="moveMessage('saved', message)"><span class="motif-icon motif-archived" aria-hidden="true"></span></button>
            <button class="restoreBtn" *ngIf="folder === 'archived'" [attr.aria-label]="'Move to inbox ' + message.subject + ' message from ' + message.received" (click)="moveMessage('inbox', message)"><span class="motif-icon motif-message" aria-hidden="true"></span></button>
          </div>
          <div *ngIf="message.messageLoading">
            <uxd-spinner  class="message-spinner"></uxd-spinner>
            <div class="text-center">{{ labels.Loading_lbl }}</div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="loadIndex < messageCount" class="text-center show-more-button"><button class="btn" (click)="showMore()">Show More</button></div>
    </ng-container>
  </ng-container>
</div>

<ng-template #sidePanelContent>
  <div class="modal-example">
      <div class="clearfix">
          <button class="close" [attr.aria-label]="'Close Modal'" (click)="onClose()">
              <span aria-hidden="true" class="close-icon"></span>
          </button>
      </div>
  </div>
  <app-message-detail></app-message-detail>
</ng-template>

<ng-template #modalTemplate>
  <div class="delete-message-modal">
    <button class="close-button" [attr.aria-label]="'Close Modal'" (click)="onModalClose()">
      <span aria-hidden="true" class="motif-icon motif-delete"></span>
    </button>
    <div class="delete-message-modal-header">
      <h2 *ngIf="folder !== 'chatTranscripts' else deleteTranscriptLabel">{{ labels.DeleteMsgModalHeading_lbl }}</h2>
      <ng-template #deleteTranscriptLabel>
        <h2>{{ labels.DeleteTranscriptModalHeading_lbl }}</h2>
      </ng-template>
    </div>
    <div class="delete-message-modal-body">
      <p>{{ labels.DeleteMsgModalBody_lbl }}</p>
    </div>
    <div class="delete-message-modal-footer button-group">
      <button class="btn btn-secondary" (click)="onModalClose()">{{ labels.DeleteMsgModalCancel_btn }}</button>
      <button class="btn continue" (click)="deleteMessage()" [ngClass]="{'submit-disabled': isMessageDeleting}">{{ isMessageDeleting ? "Deleting" : "Delete" }}</button>
    </div>
  </div>
</ng-template>