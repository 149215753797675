import { Component, OnInit } from '@angular/core';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  public maintenanceJsonLabels: any;
  public jsonLabels: any;
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private jsonSvc: JsonContentService,
  ) { }

  ngOnInit(): void {
    this.getLabels();
  }

  private getLabels() {
    this.subscription.add(
      this.jsonSvc.getJSON('maintenance').subscribe(data => {
        this.jsonLabels =data;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
