<div id="prior-authorization_id" class="uxd-theme-motif container">
  <div class="row preAuthNav">
    <button class="preAuthTab" *ngIf="showMedicalTab" [ngClass]="{'half-width' : showMedicalTab && showPharmacyTab, 'active' : selectedTab === 'medical'}"
      (click)="changeTab(tabs.MEDICAL)" aria-label="medical">{{labels.MedicalHeader}}</button>
    <button class="preAuthTab" *ngIf="showPharmacyTab" [ngClass]="{'half-width' : showMedicalTab && showPharmacyTab, 'active' : selectedTab === 'pharmacy'}"
      (click)="changeTab(tabs.PHARMACY)" aria-label="pharmacy">{{labels.PharmacyHeader}}</button>
  </div>
  <div class="row">
    <ng-template #loadingOrError>
      <div *ngIf="error; else loading" data-uxd-alert-cmp-v2 [alert]="{alertType: 'negative'}">
        <div>Failed to load JSON content.</div>
      </div>
      <ng-template #loading>
        <uxd-spinner class="col-xxs-12 col-xs-12"></uxd-spinner>
        <div [innerHTML]="labels?.loading"></div>
      </ng-template>
    </ng-template>
    <div *ngIf="selectedTab === tabs.PHARMACY">
      <!-- start tab content -->
      <div *ngIf="labels; else loadingOrError">
        <app-pharmacy [labels]="labels" [analytics]="analytics"></app-pharmacy>
      </div>      
      <!-- /end tab content -->
    </div>
    <div *ngIf="selectedTab === tabs.MEDICAL">
      <!-- start tab content -->
      <div *ngIf="labels; else loadingOrError">
        <p>{{ labels.PriorAuthIntro }}</p>
        <app-medical></app-medical>
        <p>{{ labels.PriorAuthOutro }}</p>
      </div>
      <!-- /end tab content -->
    </div>
  </div>
</div>