<div class="uxd-theme-motif container margin-top-15 margin-bottom-40 caregiver-attestation-container">
    <div class="margin-bottom-24">
        <inline-alert-container #memberErrorAlert role="alert" [name]="'inline-error-alert'" [alertType]="'universalInlineAlert'"></inline-alert-container>
        <div data-uxd-alert-cmp-v2 [alert]="{alertType: 'info'}">
            <span>{{jsonLabels?.info}}</span>
        </div>
    </div>
    <div class="max-width-elements margin-top-64">
        <div class="margin-bottom-24">
            <h5 tabindex="-1" class="font-18 font-bold margin-bottom-8">{{jsonLabels?.Fields?.Membername}}</h5>
            <span class="font-16">{{name}}</span>
        </div>
        <div class="margin-top-48 margin-bottom-48">
            <p class="font-32 font-montserrat font-normal attestation-line">{{jsonLabels?.Caregiver_Attestation_query}}</p>
        </div>
        <inline-alert-container #memberDecisionAlert role="alert" [name]="'inline-alert'" [alertType]="'universalInlineAlert'"></inline-alert-container>
        <form #medicalDecisionForm="ngForm" name="basic-radio-button-form" [formGroup]="careGiverDecisionForm" (ngSubmit)="decisionSubmission()">
            <div class="caregiver-medical-decision-buttons" formControlName="medicalDecision" name="radioValue" data-uxd-radio-input-group-cmp [radioGroup]="caregiverDecisionRadioGroup" [ngClass]="{'invalid-input-field': medicalDecisionForm.submitted && !isDecisionTaken}" (change)="decisionChange($event)"></div>
            <div class="font-12 text-error" *ngIf="medicalDecisionForm.submitted && !isDecisionTaken" role="alert">
                <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                <span>{{jsonErrors?.Selection_Required_Err}}</span>
            </div>
            <div class="row margin-left-0 margin-right-0 margin-bottom-2">
                <input type="checkbox" class="pcOption" name="medicalDecisionAgreement" id="ux-guide-checkbox-agreement" formControlName="decisionAgreement" (change)="agreementSubmission()">
                <label class="pcLabel" id="lbl_ux-guide-checkbox-agreement" for="ux-guide-checkbox-agreement">{{jsonLabels?.Agreement_Checkmark_Label}}</label>
            </div>
            <div class="font-12 text-error" *ngIf="showErrorMessage" role="alert">
                <span class="fas fa-times vertical-align-middle font-14 margin-right-8"></span>
                <span>{{jsonErrors?.Selection_Required_Err}}</span>
            </div>
            <div class="flex-horizontal-end margin-top-20">
                <button type="submit" [disabled]="!getCaregiverValue" class="btn btn-primary">{{jsonLabels?.Buttons?.Continue}}</button>
            </div>
        </form>
    </div>
</div>