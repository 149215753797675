export interface PriorAuthorizationLabels {
    ApplyFiltersButtonLabel: string;
    CancelButtonLabel: string;
    CareTypeTableHeader: string;
    DateOfService: string;
    EffectiveEndDate: string;
    filter12Months: string;
    filter18Months: string;
    filter24Months: string;
    filter3Months: string;
    filter6Months: string;
    filter9Months: string;
    FilterCareTypeLabel: string;
    filterDateRange: string;
    FilterDescriptionLabel: string;
    filterResults: string;
    InpatientCareTypeLabel: string;
    loading: string;
    MedicalHeader: string;
    OutpatientCareTypeLabel: string;
    pharmacyApply: string;
    PharmacyHeader: string;
    pharmacyLearnMoreDesc: string;
    pharmacyLearnMoreLinkText: string;
    pharmacyLearnMoreLinkUrl: string;
    pharmacyLoadMore: string;
    pharmacyPreAuthFaqs: any;
    pharmacyPrescriptionHeader: string;
    pharmacyReasonHeader: string;
    pharmacyViewFaqs: string;
    pharmacyViewLetter: string;
    PriorAuthIntro: string;
    PriorAuthOutro: string;
    RequestDateTableHeader: string;
    RequestingProvider: string;
    RequestingProviderNPI: string;
    ResetFilterLabel: string;
    rxAnalyticsfilter12Months: string;
    rxAnalyticsfilter18Months: string;
    rxAnalyticsfilter24Months: string;
    rxAnalyticsfilter6Months: string;
    ServiceInformation: string;
    ServiceProvider: string;
    ServiceProviderNPI: string;
    ServiceRequested: string;
    SRAccordionToggleTableHeader: string;
    SRFilterLabel: string;
    Status: string;
    TrackingIdTableHeader: string;
    UnitsApproved: string;
    UnitsPaid: string;
}

export interface PriorAuthorizationAnalytics {
    applyButtonAuth: string,
    azRefLinkAuth: string,
    downloadPdfAuth: string,
    footerNotePharmacyLink: string,
    goBackLinkText: string,
    loadMoreAuthText: string,
    viewPrescriptionsAuth: string
}

export interface PriorAuthorizationErrorMessages {
    300: string;
    9999: string;
    GeneralSySError: string;
    NoResults: string;
    ServiceError: string;
}

export enum PRIOR_AUTH_TABS {
    MEDICAL = 'medical',
    PHARMACY = 'pharmacy'
}

export enum RX_STATUSES {
    APPROVED = 'Approved',
    CANCELLED = 'Cancelled',
    DENIED = 'Denied',
    MULTIPLE = 'Partially Approved',
    PARTIALAPPRVD = 'Partially Approved',
    PENDED = 'Pended',
    RECEIVED = 'Received'
}