import { Injectable } from '@angular/core';
import { from, throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable( {
  providedIn: 'root'
} )
export class DataService {

  constructor() { }

  getData<T>( fileName: string ): Observable<T> {
    return from( import( `../assets/data/${ fileName }.json` ) ).pipe(
      catchError( err => throwError( err ) )
    );
  }
}
