import { Injectable } from '@angular/core';
import { LoginResponse, MemberType, VisitType } from 'gbd-models';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Alert } from '../interfaces/iAlerts'
import { AccountAccessService } from 'sydmed/src/app/secure/shm-my-profile/account-access/services/account-access.service';
import { catchError, map } from 'rxjs/operators';
import { MemberData } from '../interfaces/dashboardModals';

@Injectable({
  providedIn: 'root'
})
export class DashboardDataService {

  constructor(private accountService: AccountAccessService) { }

  private _labels: any;
  private errorMessages: string;
  private memberContextData: LoginResponse;
  private memberData: MemberData;
  private designeeData: MemberData;
  private ridWhitelist = ['INMCDWP0'];
  private na = 'Not Available';
  public alertsList: any;

  protected renewalDate$: BehaviorSubject<string> = new BehaviorSubject<string>( null );
  public renewalDate: Observable<string> = this.renewalDate$.asObservable(); 

  public setLabels(labels: any) {
    this._labels = labels;
  }

  public getLabels() {
    return this._labels;
  }

  public setErrorMessages(errorMessages: any) {
    this.errorMessages = errorMessages;
  }

  public getErrorMessages() {
    return this.errorMessages;
  }

  public setMemberContextData(data) {
    this.memberContextData = JSON.parse(data);
  }

  public setRenewalDate(date) {
    this.renewalDate$.next(date);
  }

  public getRenewalDate() {
    return this.renewalDate;
  }

  public isDesignee() {
    return this.memberContextData?.accountType === MemberType.DESIGNEE;
  }

  public isMemberFirstTimeLogin() {
    return this.memberContextData.visitType  === VisitType.FIRST_LOGIN;
  }

  public getMemberName(firstNameOverride?: string, lastNameOverride?: string) {
    if (typeof (this.memberContextData) !== undefined) {
      const memberId = this.ridWhitelist.includes(this.memberContextData.groupId) ? this.memberContextData?.medicaidId : this.memberContextData.hcid;
      const firstName = firstNameOverride ?? this.memberContextData.memberName.firstName;
      const lastName = lastNameOverride ?? this.memberContextData.memberName.lastName;
      this.memberData = {
        "memberName": this.fmt(firstName + ' ' + lastName),
        "memberId": this.fmt(memberId)
      }
    }
    return this.memberData;
  }

  public getDesigneeName(): Observable<MemberData> {
    return this.accountService.fetchDesigneeDetails().pipe(
      map((res) => {
        const { firstName, lastName } = res?.body?.personInfo;
        this.designeeData = {
          "memberName": this.fmt(firstName + ' ' + lastName)
        };
        return this.designeeData;
      }),
      catchError((error) => throwError(error))
    );
  }
  

  public transformAlerts(alertsData, apiData) {
    alertsData.forEach(function (alerts: Alert, i) {
      if (apiData.includes(alerts.alertKey)) {
        alerts.enabled = true;
      }
    });
  }

  public trimAlerts(alertsData) {
    let alertsList =[];
    alertsData.forEach(function (alerts: Alert) {
      if (alerts.enabled) {
        alertsList.push(alerts);
      }
    });
    return alertsList;
  }

  fmt(ret: any) {
    if (!ret) {
      ret = this.na;
    }
    return ret;
  }
}
