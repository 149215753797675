<form #daterangeForm="ngForm" name="daterangeForm">
  <div class="form-row">
    <div class="form-item">
      <div
        data-uxd-dropdown-cmp
        class="ant-lg-select form-item"
        name="drSelect"
        id="drSelect"
        [items]="dateRangeFilters?.options"
        required
        [defaultText]="defaultLabel"
        [labelText]="dateRangeFilters?.title"
        [(ngModel)]="dateRangeValue"
        (ngModelChange)="onDateRangeChange()"
      ></div>
    </div>
  </div>
</form>