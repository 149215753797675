import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GetSecurityCodeComponent } from './get-security-code/get-security-code.component';
import { AccountRecoveryNumberComponent } from './account-recovery-number/account-recovery-number.component';
import { EnterSecurityCodeComponent } from './enter-security-code/enter-security-code.component';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { CreateUsernamePasswordComponent } from './create-username-password/create-username-password.component';
import { ReviewComponent } from './review/review.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CaregiverRelationTypeComponent } from './caregiver-relation-type/caregiver-relation-type.component';
import { CaregiverInfoComponent } from './caregiver-info/caregiver-info.component';
import { PublicAuthGuardService } from 'sydmed/libs/authorization/public-route-guard.service';
import { RegistrationAppComponent } from './app.component';
import { AccountDetailsComponent } from 'sydmed/libs/shared-components/src/lib/account-details/account-details.component';
import { PhoneVerificationComponent } from './member-representative/phone-verification/phone-verification.component';
import { SecurityCodeComponent } from './member-representative/security-code/security-code.component';
import { RegistrationComponent as MemberRepRegistrationComponent } from './member-representative/registration/registration.component';
import { ValidateTokenComponent } from './validate-token/validate-token.component';


const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '', component: RegistrationAppComponent,
    children: [
      { path: 'public/registration/home', component: AccountDetailsComponent },
      { path: 'public/registration/get-security-code', component: GetSecurityCodeComponent, canActivate: [PublicAuthGuardService] },
      { path: 'public/registration/account-recovery-number', component: AccountRecoveryNumberComponent, canActivate: [PublicAuthGuardService]},
      { path: 'public/registration/enter-security-code', component: EnterSecurityCodeComponent, canActivate: [PublicAuthGuardService] },
      { path: 'public/registration/security-questions', component: SecurityQuestionsComponent, canActivate: [PublicAuthGuardService] },
      { path: 'public/registration/create-username-password', component: CreateUsernamePasswordComponent, canActivate: [PublicAuthGuardService]},
      { path: 'public/registration/review', component: ReviewComponent, canActivate: [PublicAuthGuardService] },
      { path: 'public/registration/confirmation', component: ConfirmationComponent },
      { path: 'public/registration/error-page', component: CreateUsernamePasswordComponent, canActivate: [PublicAuthGuardService]},
      { path: 'public/registration/caregiver-relation-type', component: CaregiverRelationTypeComponent, canActivate: [PublicAuthGuardService]},
      { path: 'public/registration/caregiver-info', component: CaregiverInfoComponent, canActivate: [PublicAuthGuardService]},
      { path: 'public/registration/member-representative/registration', component: MemberRepRegistrationComponent, canActivate: [PublicAuthGuardService]},
      { path: 'public/registration/member-representative/phone-verification', component: PhoneVerificationComponent, canActivate: [PublicAuthGuardService]},
      { path: 'public/registration/member-representative/security-code', component: SecurityCodeComponent, canActivate: [PublicAuthGuardService]},
      {
        path: 'public/registration/member-rep', component: ValidateTokenComponent, canActivate: [PublicAuthGuardService],
        children: [
          {
            path: 'error-page',
            component: ValidateTokenComponent, canActivate: [PublicAuthGuardService]
          }
        ]
      },
      { path: 'public/registration/invite', redirectTo: 'public/registration/home', pathMatch: 'full' },
      { path:'', redirectTo: 'public/registration/home', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
