<p class="font-bold margin-top-45 font-14 margin-bottom-3" [innerHtml]="labels?.header"></p>

<form [formGroup]="rfrForm">
  <ul class="rfr-Form-ul">
    <li class="uxd-sidenav-wrapper" id="list{{id}}" *ngFor="let item of labels?.form?.radioButtonsGroup; index as id">
      <input class="prOption" formControlName="requestCategory" type="radio" id="requestCategory{{id}}"
        name="requestCategory" value="{{item.name}}" (click)="selectItem(id, item?.inputEnabled)">
      <label for="requestCategory{{id}}"
        [ngClass]="[selectedRadioButton === id || rfrForm.value?.requestCategory === item?.name ? 'active': '', disableEffect ? 'disable-pointer-events' : '']"
        class="margin-left-8 font-14 font-normal prLabel" for="requestCategory{{id}}">{{item.name}}</label>
    </li>
  </ul>
  <div class="input-group-lg rfr">
    <textarea rows="5" class="border-0 rfr-textarea rfr-textarea-height" [placeholder]="labels?.form?.placeHolder"
    [ngClass]="formSubmitted && requestCategoryCommentsControl?.invalid ? 'error-border' : ''"
    id="requestCategoryComments" name="requestCategoryComments" formControlName="requestCategoryComments"
    aria-required="true"  [maxlength]="labels?.form?.characterLimit + 1" required></textarea>
  </div>
  <span [innerHtml]="defaultLabels?.form?.characterLimitlabel"></span>
  <span class="motif-margin-left-2" [innerHtml]="labels?.form?.characterLimit"></span>
  <div *ngIf="requestCategoryControl?.invalid && formSubmitted" class=" text-left margin-top-14">
    <span *ngIf="requestCategoryControl?.errors.required" class="error"><i class="motif-icon motif-delete"></i>
      <span class="motif-margin-left-5">
        <span class="errorMessage" [innerHtml]="errorMsgs?.Required_RadioButton_err"></span>
      </span>
    </span>
  </div>
  <div *ngIf="requestCategoryCommentsControl?.invalid && (requestCategoryCommentsControl?.dirty || requestCategoryCommentsControl?.touched) || formSubmitted"  class=" text-left margin-top-14">
    <span *ngIf="requestCategoryCommentsControl?.errors?.required" class="error"><i class="motif-icon motif-delete"></i>
      <span class="motif-margin-left-5">
        <span [innerHtml]="labels?.form?.describeOtherReasonLabel"></span>
        <span class="errorMessage" [innerHtml]="errorMsgs.Required_err"></span>
      </span>
    </span>
    <span *ngIf="requestCategoryCommentsControl.errors?.maxlength" class="error">
      <i class="motif-icon motif-delete"></i>
      <span class="motif-margin-left-5">
        <span [innerHtml]="labels?.form?.describeOtherReasonLabel"></span>
        <span class="errorMessage" [innerHtml]="errorMsgs?.ReasonDescriptionNumLengthErr"></span>
      </span>
    </span>
  </div>
</form>