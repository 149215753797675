<div class="parent-div">
  <img
    [src]="imageSource"
    alt="apiFailureIcon"
    class="margin-bottom-30 margin-top-30"
  />
  <h3 class="font-22">{{header}}</h3>
  <p class="margin-30 description" [innerHTML]="description"></p>
  <button
    class="btn btn-primary margin-bottom-30"
    (click)="onPrimaryButtonClick()"
  >
    {{primaryButtonText}}
  </button>
</div>
