import { Component, OnInit } from '@angular/core';
import { DesigneeAccessItem } from '../model/labels';
import { JsonContentService } from 'sydmed/src/app/sydmed-shared/content-service/json-content.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-designee-access',
  templateUrl: './designee-access.component.html',
  styleUrls: ['./designee-access.component.scss']
})
export class DesigneeAccessComponent implements OnInit {
  designeeAccessLabels: DesigneeAccessItem;
  constructor( private jsonService: JsonContentService, public router: Router ) { }

  ngOnInit(): void {
    this.jsonService.getJSON("new-dashboard").subscribe((data) => {
      this.designeeAccessLabels = data?.dashboard?.designeeAccessData;
    });
  }

  onViewMessageBtnClick():void {
    this.router.navigate(['/secure/message-center']);
  }

  onUpdateProfileBtnClick():void {
    this.router.navigate(['/secure/profile'])
  }
}
