import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FocusManagementService } from '../../sydmed-shared/focus-management-service/focus-management.service';

@Component({
  selector: 'login',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class LoginAppComponent {
  title = 'login';
  constructor(private router: Router, private focusManagementSvc: FocusManagementService) {
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      setTimeout(() => {
        this.focusManagementSvc.focusToHeader();
      });
    });
  }
}
