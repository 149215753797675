<div class="motif-theme cost-share container" *ngIf="hasCostShare">
    <p *ngIf="content" class="learn-more">{{content.description}}
        <span><a href="{{content.linkUrl}}" rel="noreferrer" target="_blank"  [attr.data-analytics]="content.linkAnalyticsTag">{{content.linkText}}</a></span>
    </p>
    <ng-container *ngIf="(quarters || outOfPocketError) && !loadingSpinner ; else Loading">
        <app-out-of-pocket
        [content]="content"
        [quarters]="quarters"
        [annualTotal]="annualTotal"
        [annualCopay]="annualCopay"
        [quarterTotal]="currentQuarterAmount"
        [isError]="outOfPocketError"
        [hasData]="hasOutOfPocketData | async"
        [hasOnlyCopay]="hasOnlyCopay"
        >
        ></app-out-of-pocket>
    </ng-container>
    <ng-template #Loading>
        <div>
            <uxd-spinner></uxd-spinner>
        </div>
    </ng-template>
    <div *ngIf="!hasOnlyCopay">
    <ng-container *ngIf="(memberLiability || memberLiabilityError) && !loadingLiabilitySpinner; else Loading">
        <app-member-liability 
        [content]="content" 
            [memberLiability]="memberLiability"
            [isError]="memberLiabilityError">
        </app-member-liability>
    </ng-container>
    </div>
</div>
