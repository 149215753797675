<div class="uxd-theme-motif wrapper">
  <h3 *ngIf="content">{{content.Headings.PageSubtitle}}</h3>
  <p *ngIf="content">{{content.Headings.PageSubheading}}</p>
  <br/>
  <br/>
  <div *ngIf="showCreditList; else loadCreditList">
    <form name="credit-card-payment-form">
      <fieldset>
        <legend><h4 class="motif-margin-bottom-5" *ngIf="content">{{content.Headings.FormCardsHeading}}</h4></legend>
        <label *ngFor="let paymentOption of creditPaymentRadioGroup?.options" [attr.disabled]="paymentOption?.payment?.isExpired"
          [ngClass]="{'radio-disabled': paymentOption?.payment?.isExpired}">
          <input 
            type="radio" 
            name="creditPaymentOption" 
            [(ngModel)]="selectedId" 
            [value]="paymentOption.value" 
            [attr.disabled]="paymentOption?.payment?.isExpired"
            />
          <span *ngIf="paymentOption.payment && !paymentOption.payment.isBankAcc; else addOption">
            <span *ngIf="!paymentOption?.payment?.isExpired; else Expired">
              <span class="motif-margin-left-3 font-16">{{paymentOption.label }}</span><br>
              <span class="motif-margin-left-7 font-16">{{content.Labels.Expires}} </span>
              <span class="font-16">{{paymentOption?.payment.expirationDate }}</span>
            </span>
            <ng-template #Expired>
              <span class="motif-margin-left-3 red-text">{{ paymentOption?.payment.accountName }}</span><br>
              <span class="motif-margin-left-7 red-text">{{content.Labels.ExpiredDate}}</span>
              <span class="red-text">{{paymentOption?.payment.expirationDate}}</span>
            </ng-template>
          </span>
          <ng-template #addOption>
            <span *ngIf="paymentOption.label">
              <span class="motif-margin-left-3 font-16">{{ paymentOption.label }}</span>
            </span>
          </ng-template>
        </label>
      </fieldset>
    </form>
  </div>
  <ng-template #loadCreditList>
    <uxd-spinner></uxd-spinner>
  </ng-template>
  <div class="motif-margin-top-10">
    <div *ngIf="selectedId === 'addNewCard'">
      <app-add-credit-paynow #addCreditPayment id="addCard" [showButtons]="true" (closePanel)="onCancel()" [addressPaymentRadioGroup]="addressPaymentRadioGroup"
        [creditPaymentRadioGroup]="creditPaymentRadioGroup" [autopay]="autopay">
      </app-add-credit-paynow>
      <br/>
    </div>
    <br/>
    <br/>
    <div *ngIf="showBankList">
      <form name="banking-payment-form">
        <fieldset>
          <legend><h4 class="motif-margin-bottom-5" *ngIf="content">{{content.Labels.BankAccountsHeading}}</h4></legend>
          <label *ngFor="let paymentOption of bankPaymentRadioGroup?.options">
            <input 
              type="radio" 
              name="bankPaymentOption" 
              [(ngModel)]="selectedId" 
              [value]="paymentOption.value" 
            />
            <span *ngIf="paymentOption.payment && paymentOption.payment.accountName; else addOption">
              <span class="motif-margin-left-3 font-16">{{ paymentOption?.label }}</span>
            </span>
            <ng-template #addOption>
              <span *ngIf="paymentOption.label">
                <span class="motif-margin-left-3 font-16">{{ paymentOption.label }}</span>
              </span>
            </ng-template>
          </label>
        </fieldset>
      </form>
    </div>

    <div *ngIf="selectedId === 'addNewBank'">
      <app-bank-paynow id="addCard" [showButtons]="true" (closePanel)="onCancel()" [addressPaymentRadioGroup]="addressPaymentRadioGroup"
        [bankPaymentRadioGroup]="bankPaymentRadioGroup" [autopay]="autopay">
      </app-bank-paynow>
    </div>

    <div class="motif-margin-top-10 motif-margin-bottom-10">
      <ng-container *ngIf="content">
        <div *ngIf="selectedId !== 'addNewCard' && selectedId !== 'addNewBank'" class="button-group">
          <button type="button" (click)="onCancel()" class="btn btn-secondary">{{content.Labels.Cancel}}</button>
          <button [class.disabled]="!selectedId || selectedId === ''" type="button" class="btn btn-primary" (click)="updatePaymentMethod()"
            data-analytics="" [disabled]="!selectedId || selectedId === ''">{{content.Labels.Update}}</button>
        </div>
      </ng-container>
    </div>
  </div>