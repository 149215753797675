<ng-container *ngIf="!uploadAttachmentRestriction" class="file-details">
    <div class="file-details__attachments margin-top-12 row ">
        <div  id = "fileDetails" class="message-center-file file-details__singleAttachment col-xs-6 col-sm-6 col-md-4 col-lg-3"
            *ngFor="let fileData of helperService.scannedFiles; trackBy:trackByFile">
            <div class="message-center-file-description" *ngIf="fileData">
                <div aria-hidden="true" class="file-details_attachment__icon margin-top-4" [ngSwitch]="true">
                    <span *ngSwitchCase="fileData?.fileType === 'pdf'" class="motif-icon motif-pdf"></span>
                    <span *ngSwitchCase="(fileData?.fileType ==='docx' || fileData?.fileType ==='doc')"
                        class="motif-icon motif-document"></span>
                    <span *ngSwitchCase="(fileData?.fileType ==='xls' || fileData?.fileType ==='xlsx')"
                        class="motif-icon motif-document"></span>
                </div>
                <div class="file-details_attachment">
                    <a class="font-12 file-details_attachment__filename text-primary" title="Download {{ fileData?.fileName }}" [innerHTML]="trimFileName(fileData?.fileName)" (click)="downloadFileAttachment(fileData)"></a>
                    <span class="font-12 file-details_attachment__filesize"> {{ fileData?.fileSize | filesize }}</span>
                </div>
            </div>
            <button [attr.aria-label]="'Remove attached '+ fileData?.fileName" role="button" [disabled]="fileLoader || downloadFileLoader"
                class="message-center-file-delete" (click)="deleteFile(fileData)" data-analytics="deleteTrashMessages">
                <span class="motif-icon motif-delete"></span>
            </button>          
        </div>
    </div>
    <!-- Loader -->
    <ng-container *ngIf="fileLoader">
        <div class="row">
            <div class="tabbed-view-wrapper col-xs-12 loader">
                <uxd-spinner class="scanning-loader"></uxd-spinner>
                <p class="text-center font-12" [innerHtml]="attachFiles.uploadingFileLoader"></p>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="downloadFileLoader">
        <div class="row">
            <div class="tabbed-view-wrapper col-xs-12 loader">
                <uxd-spinner class="scanning-loader"></uxd-spinner>
                <p class="text-center font-12" [innerHtml]="attachFiles.downloadingFileLoader"></p>
            </div>
        </div>
    </ng-container>
    <div class="attach-file margin-top-12" [class.disabled]="fileLoader || downloadFileLoader">
        <app-file-input *ngIf="fileService.enableAttachment" (file)="checkForPasswordProtected($event)"
            [enableFileAttachmentLabel]="enableFileAttachmentLabel" [fileAttachmentLabel]="attachFiles?.title"></app-file-input>
    </div>
    <div class="file-description">
        <p *ngIf="fileService.enableAttachment" class="font-14" id="fileDescription">{{ attachFiles?.noteDescription }}</p>
        
        <!-- File attachments error handling -->
        <ng-template #alertRef>
            <inline-alert-container #attachment role="alert" [name]="'inline-alert'" [alertType]="alertPosition"></inline-alert-container>
        </ng-template>
        <ng-container *ngIf="fileService.isFileTypeError">
            <ng-template [ngTemplateOutlet]="alertRef"></ng-template>
        </ng-container>
        <ng-container *ngIf="fileService.isFileSizeExceeds">
            <ng-template [ngTemplateOutlet]="alertRef"></ng-template>
        </ng-container>
        <ng-container *ngIf="fileService.isFileLimitExceeds">
            <ng-template [ngTemplateOutlet]="alertRef"></ng-template>
        </ng-container>
        <ng-container *ngIf="fileService.isPasswordProtectedFile">
            <ng-template [ngTemplateOutlet]="alertRef"></ng-template>
        </ng-container>   
    </div>    
    
</ng-container>

