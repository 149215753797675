<div class="message-center-file" [ngClass]="(messageType==='new') ? 'show-border' : 'hide-border'" *ngIf="fileData" data-analytics="attachNewMessages" (keyup.enter)="attchmentClick(fileData)" (click)="attchmentClick(fileData)">
    <div class="message-center-file-description" tabindex="0">
        <div aria-hidden="true" class="message-center-file-description-icon" [ngSwitch]="true">
            <span *ngSwitchCase="fileData?.fileType === 'pdf'" class="motif-icon motif-pdf"></span>
            <span *ngSwitchCase="(fileData?.fileType ==='docx' || fileData?.fileType ==='doc')" class="motif-icon motif-document"></span>
            <span *ngSwitchCase="(fileData?.fileType ==='xls' || fileData?.fileType ==='xlsx')" class="motif-icon motif-document"></span>
        </div>
        <div class="message-center-file-description-data">
            <p title="{{ fileData?.fileName }}">{{ fileData?.fileName }}</p>
            <span> {{ fileData?.fileSize ? (fileData?.fileSize | filesize) : '' }}</span>
        </div>
    </div>
    <button [attr.aria-label]="'Remove attached '+ fileData?.fileName" role="button" class="message-center-file-delete" (click)="deleteFile(fileData)" 
        data-analytics="deleteTrashMessages" *ngIf="messageType">
        <span class="motif-icon motif-delete"></span>
    </button>
</div>